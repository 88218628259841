import React, { ReactNode, useEffect } from "react";
import { StoredValue, StoredValueEntries } from "./storage";
import {
  Config,
  ResolveSharedValue,
  SharedMemoRealm,
  StoredValuesById,
} from "./types";

const sharedMemoRealms: Map<string, SharedMemoRealm> = new Map();

function createSharedMemo(realm: string): SharedMemoRealm {
  const existingRealm = sharedMemoRealms.get(realm);
  if (existingRealm) {
    return existingRealm;
  }
  const storedValuesById: StoredValuesById = new Map();

  const resolveSharedValue: ResolveSharedValue = <T,>({
    id,
    dependencies,
    initialize,
    cleanup,
    areDependenciesEqual,
    maxValues,
  }: Config<T>): StoredValue<T> => {
    const existingEntries = storedValuesById.get(id) as
      | StoredValueEntries<T>
      | undefined;
    const entries =
      existingEntries ??
      new StoredValueEntries(maxValues, areDependenciesEqual);

    if (!existingEntries) {
      storedValuesById.set(id, entries as StoredValueEntries<unknown>);
    }

    return (
      // Otherwise create a new value
      entries.ensure({
        initialize,
        dependencies: dependencies ?? [],
        onCleanup: cleanup,
      })
    );
  };

  const memoRealm = {
    memory: storedValuesById,
    resolve: resolveSharedValue,
    cleanup() {
      // Run the cleanup functions for everything we have cached
      for (const entries of storedValuesById.values()) {
        entries.cleanup();
      }

      sharedMemoRealms.delete(realm);
    },
  };

  sharedMemoRealms.set(realm, memoRealm);

  return memoRealm;
}

export const SharedMemoContext = React.createContext<ResolveSharedValue>(() => {
  throw new Error("Missing SharedMemoContext");
});

export function SharedMemoContextProvider({
  realm,
  children,
}: {
  realm: string;
  children: ReactNode;
}) {
  const { resolve, cleanup } = createSharedMemo(realm);

  useEffect(() => cleanup, [cleanup]);

  return (
    <SharedMemoContext.Provider value={resolve}>
      {children}
    </SharedMemoContext.Provider>
  );
}
