/**
 * Component to render a selector for "multiSelect" filter conditions.
 */
import { css } from "aphrodite";
import { useRef, useState } from "react";
import { Overlay } from "react-overlays";
import DownCaret from "../../../icons/DownCaret.svg";
import { shared } from "../../../megamap-styles";
import DropdownList from "../../Dropdown/DropdownList";
import { OverlayBody } from "../OverlayParent";
import { MultiSelectCondition } from "../operations/filter-by";

function getTitle(c: MultiSelectCondition): string {
  switch (c) {
    case MultiSelectCondition.IS_EMPTY:
    case MultiSelectCondition.IS_NOT_EMPTY:
      return c;

    case MultiSelectCondition.HAS_ANY_OF:
    case MultiSelectCondition.HAS_ALL_OF:
    case MultiSelectCondition.IS_EXACTLY:
    case MultiSelectCondition.HAS_NONE_OF:
    case MultiSelectCondition.HAS_AT_LEAST_TWO_OF:
    case MultiSelectCondition.HAS_AT_LEAST_THREE_OF:
      return `${c}...`;
  }
}

const CONDITIONS: Array<{ id: MultiSelectCondition; title: string }> =
  Object.values(MultiSelectCondition).map((value) => ({
    id: value as MultiSelectCondition,
    title: getTitle(value as MultiSelectCondition),
  }));

export function MultiSelectConditionSelector({
  condition,
  onChange,
}: {
  condition: MultiSelectCondition;
  onChange: (condition: MultiSelectCondition) => void;
}) {
  const triggerRef = useRef(null);
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div
      className={"tw-flex tw-flex-none tw-overflow-visible tw-relative"}
      ref={containerRef}
    >
      <div
        className={
          "tw-flex tw-items-center tw-px-0.5 tw-mr-0.5 tw-rounded tw-cursor-pointer " +
          css(shared.textBlueFocus, shared.linkQuiet, shared.darken1Hover)
        }
        role={"button"}
        onClick={() => setIsOpen(true)}
        ref={triggerRef}
        aria-expanded={isOpen}
        tabIndex={0}
      >
        <div className={"tw-truncate tw-flex-auto"}>{condition}</div>
        <div className={"tw-flex-none tw-flex tw-items-center tw-ml-0.5"}>
          <DownCaret />
        </div>
      </div>
      <Overlay
        placement={"bottom-start"}
        show={isOpen}
        target={triggerRef}
        onHide={() => setIsOpen(false)}
        rootClose
      >
        {({ props }) => (
          <OverlayBody {...props} minWidth={80}>
            <DropdownList
              items={CONDITIONS}
              onClick={(item) => {
                onChange(item.id);
                setIsOpen(false);
              }}
              defaultValue={condition}
              searchable
              placeholder={"Find a filter operator"}
            />
          </OverlayBody>
        )}
      </Overlay>
    </div>
  );
}
