import cx from "classnames";
import * as d3 from "d3";
import lodashMax from "lodash.max";
import { useMemo } from "react";
import {
  CHART_AXIS_HEIGHT_PX,
  CHART_HEIGHT_PX,
} from "src/PhenoFinder/constants";
import { ClusterFeatureData, Clusters } from "src/PhenoFinder/types";
import invariant from "tiny-invariant";
import { FeatureBoxPlots } from "./FeatureBoxPlots";
import { useChartDimensions } from "./hooks";
import { getVisualizationHeight } from "./utils";

export function FeatureCharts({
  className,
  clusters,
  data,
  selectedFeature,
  shouldHighlightMeaningfulMetadata,
}: {
  className?: string;
  clusters: Clusters;
  data: ClusterFeatureData[];
  selectedFeature: string;
  shouldHighlightMeaningfulMetadata: boolean;
}) {
  const maxValue = useMemo(() => lodashMax(data.map((row) => row.max)), [data]);
  invariant(maxValue);

  const dimensionsConfig = useMemo(
    () => ({
      margin: {
        // 36px corresponds to the rubberband scrolling buffer in PhenoFinder component
        // 8px is padding
        top: 36 + 8,
        left: 24,
        // Right is double left (margin of chart within axis, and margin from axis to page)
        right: 48,
      },
    }),
    [],
  );
  const [setContainerRef, chartDimensions] =
    useChartDimensions(dimensionsConfig);
  const { width, boundedWidth, margin } = chartDimensions;
  const [boundedHeight, height] = useMemo(() => {
    return getVisualizationHeight(clusters.length, margin);
  }, [clusters, margin]);

  const xScale = useMemo(
    () => d3.scaleLinear().domain([0, maxValue]).range([0, boundedWidth]),
    [boundedWidth, maxValue],
  );
  const yScale = useMemo(
    () =>
      d3
        .scaleOrdinal<number>()
        .domain(clusters.map((cluster) => cluster.name))
        .range(clusters.map((cluster) => cluster.y - CHART_HEIGHT_PX / 2)),
    [clusters],
  );

  const axis = useMemo(() => {
    return (
      <svg style={{ width: width }}>
        {xScale.ticks(5).map((value, i) => (
          <g key={i}>
            <text
              className="tw-fill-slate-500"
              // The axis isn't constrained to the bounded width, so drawn elements need to be
              // shifted over by the left margin
              x={xScale(value) + margin.left}
              y={8}
              textAnchor="middle"
              alignmentBaseline="central"
              fontSize={14}
            >
              {value}
            </text>
          </g>
        ))}

        <g>
          <text
            className="tw-fill-slate-700 tw-font-bold"
            x={boundedWidth / 2 + margin.left}
            y={36}
            textAnchor="middle"
            alignmentBaseline="central"
            fontSize={14}
          >
            {selectedFeature} per cluster
          </text>
        </g>
      </svg>
    );
  }, [xScale, width, boundedWidth, margin, selectedFeature]);

  return (
    <div
      className={cx(className, "tw-relative tw-w-full tw-h-full")}
      ref={setContainerRef}
    >
      <div className="tw-absolute tw-w-full tw-h-full">
        <div
          className="tw-relative"
          style={{
            width: boundedWidth,
            height: boundedHeight,
            transform: `translateX(${margin.left}px) translateY(${margin.top}px)`,
          }}
        >
          <FeatureBoxPlots
            data={data}
            xScale={xScale}
            yScale={yScale}
            numTotalRows={clusters.length}
            shouldHighlightMeaningfulMetadata={
              shouldHighlightMeaningfulMetadata
            }
          />
        </div>
      </div>

      <div
        className={cx(
          "tw-fixed tw-w-full",
          "tw-pt-sm tw-rounded tw-shadow-sm",
          "tw-bg-white tw-border tw-border-slate-300",
        )}
        style={{
          width: boundedWidth + margin.left * 2,
          height: CHART_AXIS_HEIGHT_PX,
          // The axis sticks to the bottom of the page if there are enough charts that they scroll,
          // or render close to the bottom of the last chart if not
          bottom:
            chartDimensions.height -
              height -
              CHART_AXIS_HEIGHT_PX -
              // We need to account for the extra margin in the math, but we don't actually want
              // to render this much space between the last chart and the axis, hence the condition
              margin.top >
            0
              ? chartDimensions.height - height - CHART_AXIS_HEIGHT_PX - 8
              : 0,
        }}
      >
        {axis}
      </div>
    </div>
  );
}
