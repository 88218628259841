import cx from "classnames";
import { useMemo, useState } from "react";
import { ImageSet } from "../../imaging/types";
import { DatasetId, LabeledCellSampleMetadata } from "../../types";
import { Classification, useLabeledSetContext } from "../Context";
import { SelectableExampleGridViewV2 } from "../SelectableExampleGridView";
import {
  NARROW_CARD_EXAMPLES_PER_ROW,
  WIDE_CARD_EXAMPLES_PER_ROW,
} from "../constants";
import { addSampleState } from "../util";
import { ClassHeader } from "./ClassHeader";

interface Props {
  className?: string;
  classification: Classification;
  dataset: DatasetId;
  imageSet: ImageSet | null;
  onExpand: () => void;
  wide: boolean;
}

const range = new Array(WIDE_CARD_EXAMPLES_PER_ROW)
  .fill(0)
  .map((_, index) => index);

export function ClassCard({
  className,
  classification,
  dataset,
  imageSet,
  onExpand,
  wide,
}: Props) {
  const { state: labeledSetState } = useLabeledSetContext();
  const { cropSize } = labeledSetState;
  const [selection, setSelection] = useState<LabeledCellSampleMetadata[]>([]);
  const examples = classification.examples;

  // TODO(trisorus): We can't select from the unexpanded class card anymore – we should
  // pull out the image grid view and deprecate the usage of the selectable here
  const examplesWithSelection = useMemo(() => {
    // With the current wide/narrow widths set via the containing Sidebar
    // we can fit either 5 or 6 of the small images in a row
    const visibleExamples = examples.slice(
      0,
      wide ? WIDE_CARD_EXAMPLES_PER_ROW : NARROW_CARD_EXAMPLES_PER_ROW,
    );

    return addSampleState(visibleExamples, selection);
  }, [examples, selection, wide]);

  const minColumns = useMemo(
    () => (wide ? WIDE_CARD_EXAMPLES_PER_ROW : NARROW_CARD_EXAMPLES_PER_ROW),
    [wide],
  );

  const placeholders = useMemo(
    () =>
      range
        .slice(0, minColumns)
        .slice(examples.length)
        .map((key) => (
          <div
            key={key}
            className="tw-inline-block tw-p-1"
            style={{ aspectRatio: "1 / 1" }}
          >
            <div className="tw-h-full tw-border tw-border-black tw-border-dashed" />
          </div>
        )),
    [examples.length, minColumns],
  );

  return (
    <div
      className={cx(
        "tw-border-2 tw-rounded-md tw-shadow-sm hover:tw-shadow-lg tw-p-4",
        className,
      )}
    >
      <ClassHeader
        classification={classification}
        isExpanded={false}
        onToggleExpanded={onExpand}
      />

      <button className="tw-cursor-pointer" onClick={onExpand}>
        <SelectableExampleGridViewV2
          dataset={dataset}
          imageSet={imageSet}
          cropSize={cropSize}
          size={60}
          metadata={examplesWithSelection}
          onSetSelection={setSelection}
          predictionMap={null}
          placeholders={placeholders}
          minColumns={minColumns}
          disabled
        />
      </button>
    </div>
  );
}
