import * as SliderPrimitive from "@radix-ui/react-slider";
import cx from "classnames";
import * as React from "react";

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  // Radix supports multiple thumbs; our usage only requires one, so abstract that away to simplify
  // downstream usage
  Omit<SliderPrimitive.SliderProps, "value" | "onValueChange"> & {
    value: number;
    onValueChange: (value: number) => void;
    onClickCenterResetButton?: () => void;
  }
>(
  (
    { className, value, onValueChange, onClickCenterResetButton, ...props },
    ref,
  ) => {
    const handleValueChange = React.useCallback(
      (newValues) => onValueChange?.(newValues[0]),
      [onValueChange],
    );

    const handleClickCenter = React.useCallback(() => {
      if (props.max === undefined || props.min === undefined) {
        return;
      }

      const centerValue = props.min + Math.round((props.max - props.min) / 2);
      onValueChange?.(centerValue);
    }, [onValueChange, props.max, props.min]);

    return (
      <SliderPrimitive.Root
        ref={ref}
        className={cx(
          "tw-relative tw-flex tw-w-full tw-touch-none tw-select-none tw-items-center",
          className,
        )}
        value={[value]}
        onValueChange={handleValueChange}
        {...props}
      >
        <SliderPrimitive.Track
          className={cx(
            "tw-relative tw-h-1 tw-w-full tw-grow tw-overflow-hidden tw-rounded-full tw-bg-gray-200",
          )}
        >
          <SliderPrimitive.Range className="tw-absolute tw-h-full tw-bg-primary" />
        </SliderPrimitive.Track>

        {onClickCenterResetButton ? (
          <div className="tw-absolute tw-w-full tw-flex tw-justify-center">
            <button className="tw-px-1.5 tw-py-1" onClick={handleClickCenter}>
              <div className="tw-bg-gray-300 tw-h-5 tw-w-0.5 tw-rounded-full" />
            </button>
          </div>
        ) : null}

        <SliderPrimitive.Thumb
          className={cx(
            "tw-block tw-h-3 tw-w-3 tw-rounded-full",
            "tw-border tw-border-blue tw-bg-blue tw-shadow",
            "tw-transition-colors",
            "tw-focus-visible:outline-none tw-focus-visible:ring-1 tw-focus-visible:ring-ring",
            "tw-disabled:pointer-events-none tw-disabled:opacity-50",
            "tw-cursor-grab active:tw-cursor-grabbing",
          )}
        />
      </SliderPrimitive.Root>
    );
  },
);
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
