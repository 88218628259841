import { useCallback, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { DatasetId, WorkspaceId } from "src/types";
import { Button } from "@spring/ui/Button";
import { FullScreenContainer } from "../../Common/FullScreenContainer";
import Loader from "../../Common/Loader";
import { useDialog } from "../../Common/useDialog";
import { useFetchIngestionManifests } from "../hooks";
import { DuckDBTable } from "./DuckDBTable";
import { Column, ColumnType, Row } from "./EditableTable";
import { GenerateManifest } from "./GenerateManifest";
import { ImageValidator } from "./ImageValidator";

export function ImageNameParsing() {
  const match = useRouteMatch<{
    workspaceId: WorkspaceId;
    datasetId: DatasetId;
  }>();
  const { workspaceId, datasetId } = match.params;
  const [manifestDBReq, refetchManifest] = useFetchIngestionManifests(
    workspaceId,
    datasetId,
  );
  const [isRegenerating, setIsRegenerating] = useState(false);

  const handleCloseDialog = useCallback(() => {
    setIsRegenerating(false);
  }, []);

  const handleGenerationComplete = useCallback(async () => {
    handleCloseDialog();
    await refetchManifest();
    setIsRegenerating(false);
  }, [handleCloseDialog, refetchManifest]);

  if (!manifestDBReq) {
    return (
      <FullScreenContainer center>
        <Loader />
      </FullScreenContainer>
    );
  }

  if (
    !manifestDBReq.successful &&
    manifestDBReq.error.message !== "File not found"
  ) {
    console.error(manifestDBReq.error);
    return <div>Oops. Something went wrong fetching the manifest.</div>;
  }

  const showGenerateModal =
    isRegenerating ||
    (!manifestDBReq.successful &&
      manifestDBReq.error.message === "File not found");

  const manifestDB = manifestDBReq.successful ? manifestDBReq.value : null;

  return (
    <>
      <GenerateManifestModal
        open={showGenerateModal}
        workspaceId={workspaceId}
        datasetId={datasetId}
        isRegenerating={isRegenerating}
        onClose={isRegenerating ? handleCloseDialog : undefined}
        onGenerationComplete={handleGenerationComplete}
      />
      {isRegenerating ? (
        <FullScreenContainer center>
          <Loader />
        </FullScreenContainer>
      ) : (
        manifestDB && (
          <div className="tw-mt-2">
            <DuckDBTable
              db={manifestDB}
              tableName="manifest"
              createColumnsFromRow={createColumnsFromRow}
              headerComponent={
                <>
                  <Button
                    onClick={() => setIsRegenerating(true)}
                    variant="primary"
                  >
                    Regenerate
                  </Button>
                  <div className="tw-mx-2">
                    <ImageValidator
                      workspaceId={workspaceId}
                      datasetId={datasetId}
                    />
                  </div>
                </>
              }
            />
          </div>
        )
      )}
    </>
  );
}

function GenerateManifestModal({
  open,
  workspaceId,
  datasetId,
  isRegenerating,
  onClose,
  onGenerationComplete,
}: {
  open: boolean;
  workspaceId: WorkspaceId;
  datasetId: DatasetId;
  isRegenerating: boolean;
  onClose?: () => void;
  onGenerationComplete?: () => void;
}) {
  const { DialogComponent, setOpenDialog } = useDialog({
    onClose,
  });
  useEffect(() => {
    setOpenDialog(open);
  }, [open, setOpenDialog]);

  return (
    <DialogComponent disableClose={!onClose}>
      <div className="tw-p-4 tw-w-[350px] tw-min-h-[350px] tw-max-h-[calc(100vh-200px)] tw-overflow-auto">
        <GenerateManifest
          workspaceId={workspaceId}
          datasetId={datasetId}
          canUseCache={isRegenerating}
          onGenerationComplete={onGenerationComplete}
        />
      </div>
    </DialogComponent>
  );
}

function createColumnsFromRow(row: Row): Column[] {
  return Object.keys(row).map((field) => {
    let type: ColumnType;
    const valueType = typeof row[field];
    switch (valueType) {
      case "number":
        type = ColumnType.Number;
        break;
      case "boolean":
        type = ColumnType.Checkbox;
        break;
      case "string":
        type = ColumnType.Text;
        break;
      default:
        // The backend returns null for unkown number values.
        if (row[field] === null) {
          type = ColumnType.Number;
          break;
        }
        throw new Error(
          `Unsupported data type ${valueType} for column: ${field}`,
        );
    }
    // TODO(davidsharff): this fn will eventually take the errors array and use it to
    //   1. Display whatever indicator we want in the headerContent (it's a ReactNode type)
    //   2. Use the cellFormatter cb to do the same for cells
    //   3. Add a synthezied first column for row level errors?
    return {
      headerContent: field,
      field,
      type: type,
      isReadOnly: field === "path",
      //   cellFormatter: (value, field, row) => {
      //     return value;
      //   },
    };
  });
}
