export const REQUIRED_SAMPLES = 10;
export const SAMPLE_BATCH_SIZE = 100;
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const PREFETCH_BATCH_SIZE = 5;
export const REQUIRED_CLASSES = 2;
export const SAMPLE_REFILL_SIZE = 1000;
export const MAX_NEIGHBOR_LEVELS = 2;
export const NEIGHBOR_COUNT = 11;
export const IN_DEGREE_THRESHOLD = 6;
export const MAX_PARALLEL_GET_LABELED_SETS = 4;
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const SHOW_ALL_CARD_EXAMPLES_PER_ROW = 3;
export const NARROW_CARD_EXAMPLES_PER_ROW = 5;
export const WIDE_CARD_EXAMPLES_PER_ROW = 6;
export const APPLY_MODEL_REQUIRED_SAMPLES = 100;

// Hacky class-card specific parameters.
export const CLASS_CARD_TOP_N = 20;
export const CLASS_CARD_TO_FIND_SIMILAR = 5;

export const MIN_PREDICTION_CONFIDENCE = 0.75;

export const AUTO_SAVE_DELTA = 1;
