import cx from "classnames";
import { FC, useCallback, useState } from "react";
import {
  ArrowLeft,
  ArrowRight,
  IconProps,
  Maximize2,
  Minimize2,
  X,
} from "react-feather";
import { Button } from "src/Common/Button";
import Markdown from "../Common/Markdown";
import Strut from "../imaging/Strut";
import { Finding } from "./types";

export default function FindingPopup({
  finding,
  onGoToPrevFinding,
  onGoToNextFinding,
  onClose,
}: {
  finding: Finding;
  onGoToPrevFinding?: () => void;
  onGoToNextFinding?: () => void;
  onClose: () => void;
}) {
  const [isMinimized, setIsMinimized] = useState(false);

  const IconButton = useCallback(
    ({
      Icon,
      title,
      onClick,
    }: {
      Icon: FC<IconProps>;
      title: string;
      onClick: () => void;
    }) => {
      return (
        <button
          className={"tw-text-gray-500 hover:tw-text-gray-900"}
          title={title}
          onClick={onClick}
        >
          <Icon className={"tw-w-[20px]"} />
        </button>
      );
    },
    [],
  );

  return (
    <div
      className={cx(
        "tw-fixed tw-bottom-0 tw-z-popup",
        "tw-border tw-border-purple tw-border-b-0 tw-rounded-t tw-bg-white",
        "tw-flex tw-flex-col",
        finding.side === "right" ? "tw-right-[50px]" : "tw-left-[50px]",
        isMinimized ? "tw-w-[300px]" : "tw-w-[490px]",
      )}
      style={{
        // Custom box shadow because tailwind's doesn't support having it drop
        // "upwards"
        boxShadow:
          "-4px -2px 6px -1px rgb(0 0 0 / 0.1), 0 -2px 4px -2px rgb(0 0 0 / 0.1)",
      }}
    >
      <div className={"tw-px-md tw-py-sm tw-border-b tw-flex"}>
        <div className={"tw-flex-1 tw-truncate tw-pr-md "}>{finding.title}</div>
        <div className={"tw-flex tw-items-center"}>
          <IconButton
            title={"Minimize"}
            onClick={() => setIsMinimized(!isMinimized)}
            Icon={isMinimized ? Maximize2 : Minimize2}
          />
          <Strut size={2} />
          <IconButton title={"Close"} onClick={onClose} Icon={X} />
        </div>
      </div>
      {!isMinimized && (
        <>
          <div
            className={"tw-p-md tw-flex-1 tw-overflow-y-auto tw-max-h-[300px]"}
          >
            <Markdown className={"tw-text-sm tw-text-slate-700"}>
              {finding.markdownContent}
            </Markdown>
          </div>
          {(onGoToPrevFinding || onGoToNextFinding) && (
            <div className={"tw-flex tw-p-md"}>
              {onGoToPrevFinding && (
                <Button
                  variant={"secondary"}
                  size={"sm"}
                  onClick={onGoToPrevFinding}
                  disableTracking={true}
                >
                  <ArrowLeft className={"tw-w-4"} />
                  Prev
                </Button>
              )}

              <div className={"tw-flex-1"}></div>

              {onGoToNextFinding && (
                <Button
                  variant={"primary"}
                  size={"sm"}
                  onClick={onGoToNextFinding}
                  disableTracking={true}
                >
                  Next
                  <ArrowRight className={"tw-w-4"} />
                </Button>
              )}
              {!onGoToNextFinding && (
                <Button
                  name="Finish tour"
                  variant={"primary"}
                  size={"sm"}
                  onClick={onClose}
                >
                  Got it. Let me explore!
                </Button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
