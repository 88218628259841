import { FilterSqlClause } from "../../Control/FilterSelector/types";
import { createSelectQueryForFilteredFeatures } from "../../hooks/features";
import { UntypedFeatures } from "../../types";
import {
  DB,
  ValidatedSQL,
  queryDBAsRecords,
  sql,
  sqlUnion,
} from "../../util/sql";
import { Entry, ExampleSpec } from "./BucketedExampleViewer/types";
import { convertRawFeatureToEntry } from "./BucketedExampleViewer/utils";

/**
 * Select feature rows with an extra "bucket" attribute for the buckets in the spec.
 */
export function queryDataForExampleSpec(
  db: DB,
  filterClause: FilterSqlClause,
  column: string,
  spec: ExampleSpec,
): Promise<UntypedFeatures> {
  return queryDBAsRecords<{ N: bigint }>(
    db,
    createSelectQueryForFilteredFeatures("COUNT(1) as N", filterClause),
  ).then((countRaw) => {
    const count = Number(countRaw[0].N);
    const start = spec.omitLowPercentile
      ? Math.floor(count * 0.01 * spec.omitLowPercentile)
      : 0;
    const end =
      count -
      (spec.omitHighPercentile
        ? Math.floor(count * 0.01 * spec.omitHighPercentile)
        : 0);

    // We want to break up the range into (numBuckets - 1) intervals, and also
    // be mindful of the bucket width (examplesPerBucket) as well.
    // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
    //   | b-1 |           | b-2 |          | b-3 |          | b-4 |
    //   ^_________________^
    //      intervalWidth
    const intervalWidth = Math.max(
      0, // guard against degenerate case where bucket width spans range.
      (end - spec.examplesPerBucket - start) / (spec.numBuckets - 1),
    );

    const subQueries: ValidatedSQL[] = [];
    for (let i = 0; i < spec.numBuckets; i++) {
      const offset = start + Math.floor(i * intervalWidth);
      const innerQuery = createSelectQueryForFilteredFeatures(
        `*, 'b-${i}' AS bucket`,
        filterClause,
      );
      subQueries.push(
        sql`(SELECT * FROM (${innerQuery})
            ORDER BY "${column}" 
            ${spec.ascending ? "" : "DESC"}
            LIMIT ${spec.examplesPerBucket}
            OFFSET ${offset})`,
      );
    }

    return queryDBAsRecords<UntypedFeatures[number]>(db, sqlUnion(subQueries));
  });
}

export async function queryGroupFromValue(
  db: DB,
  filterClause: FilterSqlClause,
  column: string,
  start: number,
  count: number,
  ascending: boolean,
): Promise<Entry[]> {
  const query = sql`
    SELECT * FROM (
        ${createSelectQueryForFilteredFeatures("*", filterClause)}
    )
    WHERE "${column}" >= ${start}
    ORDER BY "${column}" ${ascending ? "" : "DESC"} LIMIT ${count}
  `;
  const rawFeatureRows = await queryDBAsRecords<UntypedFeatures[number]>(
    db,
    query,
  );
  return rawFeatureRows.map((row) => convertRawFeatureToEntry(row, column));
}
