import cx from "classnames";
import pluralize from "pluralize";
import { ReactNode, useMemo } from "react";
import { Download, ExternalLink, X } from "react-feather";
import Select, { OptionProps, components } from "react-select";
import { DeprecatedButton } from "src/Common/DeprecatedButton";
import { Operator } from "src/Control/FilterSelector/operations/filter-by";
import {
  createAnyOfFilter,
  createFilterForType,
  createFilterSet,
} from "src/Control/FilterSelector/utils";
import { MetadataColumnValue, UntypedWellSampleMetadataRow } from "src/types";
import { generateId } from "src/util/function-util";
import { sql, useQueryAsRecords } from "src/util/sql";
import { Checkbox } from "@spring/ui/Checkbox";
import { useFeatureSetManagementContext } from "../context";
import { MetadataColors } from "../types";
import LeftNavSectionTitle from "./LeftNavSectionTitle";
import { ScatterplotSelection, SelectionKind } from "./types";
import { getSampledFilterUrl, useGetWellIdForPoint } from "./utils";

type SelectOption = {
  value: MetadataColumnValue;
  note: string;
  isDisabled: boolean;
};

function SelectionRow({
  children,
  onRemove,
}: {
  children: ReactNode;
  onRemove: () => void;
}) {
  return (
    <div className="tw-flex tw-justify-between tw-text-sm tw-mt-1">
      <div className="tw-truncate">{children}</div>
      <div
        className="tw-ml-2 tw-cursor-pointer tw-flex tw-items-center"
        onClick={onRemove}
      >
        <X size={14} />
      </div>
    </div>
  );
}

function AddMetadataCondition({
  className,
  coloringMetadataColumn,
  selections,
  metadata,
  onAddSelection,
}: {
  className?: string;
  coloringMetadataColumn: string | null;
  selections: SelectionKind[];
  metadata: UntypedWellSampleMetadataRow[];
  onAddSelection: (columnId: string, value: MetadataColumnValue) => void;
}) {
  const { filterColumns } = useFeatureSetManagementContext();

  // The base options only change when we change the coloringMetadataColumn, so we calculate it
  // separately from the selected/disabled state
  const baseOptions = useMemo(() => {
    const counts = new Map(
      filterColumns.map((column) => [
        column.id,
        new Map<MetadataColumnValue, number>(),
      ]),
    );

    metadata.forEach((sample) => {
      filterColumns.forEach((column) => {
        const columnCounts = counts.get(column.id);
        if (columnCounts) {
          const value = sample[column.id];
          // TODO(you): Fix this no-unnecessary-condition rule violation
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (value !== undefined) {
            const existingCount = columnCounts.get(value) ?? 0;
            columnCounts.set(value, existingCount + 1);
          }
        }
      });
    });

    const valuesByCount = coloringMetadataColumn
      ? counts.get(coloringMetadataColumn)
      : undefined;

    return valuesByCount !== undefined
      ? Array.from(valuesByCount.entries())
          .sort((a, b) => {
            const aValue = a[0];
            const bValue = b[0];

            if (typeof aValue === "number" && typeof bValue === "number") {
              return aValue - bValue;
            } else {
              return String(aValue).localeCompare(String(bValue), undefined, {
                sensitivity: "base",
              });
            }
          })
          .map(([value, count]) => ({
            value,
            label: String(value),
            note: String(count),
          }))
      : [];
  }, [filterColumns, metadata, coloringMetadataColumn]);

  const options = useMemo(
    () =>
      baseOptions.map((option) => ({
        ...option,
        isDisabled: selections.some(
          (existing) =>
            existing.kind === "metadata" &&
            existing.key === coloringMetadataColumn &&
            existing.value === option.value,
        ),
      })),
    [baseOptions, coloringMetadataColumn, selections],
  );

  return (
    <Select
      placeholder="Search for a group..."
      className={className}
      styles={{
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 100,
        }),
      }}
      components={{ Option }}
      options={options}
      onChange={(option: SelectOption | null) => {
        if (coloringMetadataColumn && option?.value) {
          onAddSelection(coloringMetadataColumn, option.value);
        }
      }}
      value={null}
      isDisabled={coloringMetadataColumn === null}
      isMulti={false}
      closeMenuOnSelect={false}
    />
  );
}

const Option = (props: OptionProps<SelectOption, false>) => {
  return (
    <components.Option {...props}>
      <div className="tw-flex tw-items-center tw-text-start">
        <div className="tw-flex-1 tw-truncate">{props.label}</div>
        {/* TODO(you): Fix this no-unnecessary-condition rule violation */}
        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
        {props.data.note !== undefined && (
          <div className="tw-text-gray-400">{props.data.note}</div>
        )}
      </div>
    </components.Option>
  );
};

export default function SidebarSelectionView({
  coloringMetadataColumn,
  selections,
  selectedPoints,
  hideUnselectedPoints,
  metadataColors,
  metadata,
  onAddSelection,
  onRemoveSelection,
  setHideUnselectedPoints,
  onDownloadSelectedPoints,
}: {
  coloringMetadataColumn: string | null;
  selections: SelectionKind[];
  selectedPoints: Set<string>;
  hideUnselectedPoints: boolean;
  metadataColors: MetadataColors;
  metadata: UntypedWellSampleMetadataRow[];
  onRemoveSelection: (index: number) => void;
  onAddSelection: (columnId: string, value: MetadataColumnValue) => void;
  setHideUnselectedPoints: (hide: boolean) => void;
  onDownloadSelectedPoints: () => void;
}) {
  // The metadata prop could have used sampling. We need the entire table to
  // to derive the syntehtic well_id  use to link clusters to compare images.
  const { metadataDB: fullMetadataDB } = useFeatureSetManagementContext();

  const uniquePlatesQuery = useQueryAsRecords<{ plate: string }>(
    fullMetadataDB,
    sql`SELECT DISTINCT plate FROM sample_metadata ORDER BY plate ASC`,
  );

  const getWellId = useGetWellIdForPoint();

  const mouseSelectionPrefix = "Mouse selection of";

  const handleViewImages = () => {
    if (selections.length === 0 || !uniquePlatesQuery?.successful) {
      return;
    }

    const groups = selections.map((selection) => {
      if (selection.kind === "scatterplot") {
        const { points } = selection;

        const selectedWellIds = Array.from(points).map((point) => {
          const [plate, well] = point.split("##");
          return getWellId(plate, well);
        });

        const filters = [createAnyOfFilter("well_id", selectedWellIds)];

        const filterSet = createFilterSet(filters, Operator.AND);
        return {
          id: generateId("umap"),
          label: getScatterPlotSelectionLabel(selection),
          filterSet,
        };
      } else {
        const { key, value } = selection;
        const filter = createFilterForType(key, value);
        const filterSet = createFilterSet([filter], Operator.AND);
        return {
          id: generateId("umap"),
          label: `${key} selection`,
          filterSet,
        };
      }
    });

    const viewImagesUrl = getSampledFilterUrl(groups, location.pathname);

    if (viewImagesUrl) {
      window.open(viewImagesUrl, "_blank");
    }
  };

  const getScatterPlotSelectionLabel = (selection: ScatterplotSelection) =>
    `${mouseSelectionPrefix} ${pluralize(
      "point",
      selection.points.size,
      true,
    )}`;

  return (
    <div className="tw-p-md tw-pl-lg ">
      <div className="tw-flex tw-justify-between tw-items-center">
        <LeftNavSectionTitle className="tw-flex-1">
          Selected points
        </LeftNavSectionTitle>
        {selectedPoints.size > 0 ? (
          <>
            <DeprecatedButton
              borderless
              variant="primary"
              size="sm"
              onClick={handleViewImages}
              className="tw-mr-sm"
            >
              <ExternalLink size={16} className="tw-mr-xs" />
              View Images
            </DeprecatedButton>

            <DeprecatedButton
              size="sm"
              variant="primary"
              borderless={true}
              onClick={onDownloadSelectedPoints}
            >
              <Download size={16} className="tw-mr-1" />
              Download
            </DeprecatedButton>
          </>
        ) : null}
      </div>
      <div className="tw-text-slate-500 tw-text-sm tw-normal-case tw-mt-sm">
        {selectedPoints.size} {selectedPoints.size === 1 ? "point" : "points"}{" "}
        selected
      </div>

      <div className="tw-my-md">
        {selections.map((selection, i) => {
          if (selection.kind === "scatterplot") {
            return (
              <SelectionRow key={i} onRemove={() => onRemoveSelection(i)}>
                {getScatterPlotSelectionLabel(selection)}
              </SelectionRow>
            );
            // TODO(you): Fix this no-unnecessary-condition rule violation
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          } else if (selection.kind === "metadata") {
            return (
              <SelectionRow key={i} onRemove={() => onRemoveSelection(i)}>
                {selection.key === coloringMetadataColumn ? (
                  <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
                    <div
                      className="tw-inline-block tw-w-[16px] tw-h-[16px] tw-rounded"
                      style={{
                        backgroundColor: metadataColors[selection.value],
                      }}
                    />{" "}
                    {selection.value}
                  </div>
                ) : (
                  <>
                    {" "}
                    Selection where{" "}
                    <span className="tw-text-blue tw-bg-gray-100">
                      {selection.key}
                    </span>{" "}
                    = {selection.value}
                  </>
                )}
              </SelectionRow>
            );
          }
        })}
      </div>

      <AddMetadataCondition
        className="tw-w-full tw-mt-2"
        coloringMetadataColumn={coloringMetadataColumn}
        metadata={metadata}
        onAddSelection={onAddSelection}
        selections={selections}
      />
      <label
        className={cx(
          "tw-mt-sm tw-text-sm tw-flex tw-items-center tw-space-x-2",
          selectedPoints.size === 0 ? "tw-text-gray-500" : "tw-text-gray-900",
        )}
      >
        <Checkbox
          checked={hideUnselectedPoints}
          disabled={selectedPoints.size === 0}
          onCheckedChange={setHideUnselectedPoints}
        />
        <span>Only show selected points</span>
      </label>
    </div>
  );
}
