import * as Popover from "@radix-ui/react-popover";
import cx from "classnames";
import { ReactElement, ReactNode, useCallback, useState } from "react";
import { SpinningChevron } from "../Common/SpinningChevron";
import { AnalyzeView, DEFAULT_IF_ANALYSIS_VIEWS } from "./views";

function getViewThumbnail(view: AnalyzeView): ReactElement {
  return (
    <div
      className={cx(
        "tw-rounded-lg tw-bg-cover tw-h-[220px] tw-border tw-border-[#ccc]",
      )}
      style={{ backgroundImage: `url(${view.previewUrl})` }}
    />
  );
}

function ViewMenuItem({
  icon,
  text,
  onClick,
  selected,
  setHovered,
  disabled,
}: {
  icon: ReactElement;
  text: ReactNode;
  selected: boolean;
  setHovered: (hovered: boolean) => void;
  disabled: boolean;
  onClick?: () => void;
}) {
  return (
    <Popover.Close asChild>
      <button
        className={cx(
          "tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-2",
          "tw-px-4 tw-py-2 tw-text-base",
          disabled
            ? "tw-text-slate-300 tw-cursor-default"
            : selected
              ? "tw-text-purple tw-bg-purple-100"
              : "hover:tw-bg-gray-100/[0.5] active:tw-bg-gray-100/[0.75]",
        )}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={
          disabled
            ? (e) => {
                e.preventDefault();
              }
            : onClick
        }
      >
        <div className="tw-min-w-[24px] tw-flex tw-flex-row tw-items-center tw-justify-center">
          {icon}
        </div>
        <div className="tw-flex-1 tw-text-left">{text}</div>
      </button>
    </Popover.Close>
  );
}

function ViewPreviewPane({ view }: { view: AnalyzeView }) {
  return (
    <div className={"tw-w-[400px] tw-border-r tw-p-6 tw-flex tw-flex-col"}>
      <div className={"tw-text-xs tw-text-slate-500 tw-mb-4"}>
        {view.description}
      </div>
      <div className={"tw-relative tw-rounded tw-overflow-hidden"}>
        {getViewThumbnail(view)}
      </div>
    </div>
  );
}

export default function ViewSelector({
  selectedView,
  trigger,
  showTitle = true,
  onChange,
}: {
  selectedView: AnalyzeView | undefined;
  // Optionally provide a different node to use as the trigger (e.g. this gets used
  // when we use this as the "Analyze" menu in the top bar)
  trigger?: ReactNode;
  showTitle?: boolean;
  onChange: (view: AnalyzeView) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredView, setHoveredView] = useState<AnalyzeView | null>(null);

  const sectionTitle = useCallback((title: string) => {
    return (
      <div
        className={"tw-uppercase tw-text-slate-500 tw-text-sm tw-px-4 tw-py-2"}
      >
        {title}
      </div>
    );
  }, []);

  const currentView = selectedView;

  return (
    <Popover.Root onOpenChange={setIsOpen} open={isOpen}>
      {trigger ?? (
        <Popover.Trigger
          className={cx(
            "tw-h-full tw-w-[240px]",
            "tw-pl-4 tw-pr-2 tw-min-h-[36px] tw-text-start",
            "tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-2",
            "tw-border tw-rounded  tw-border-[#ccc]",
            "hover:tw-border-gray-300",
          )}
        >
          {currentView ? (
            <>
              {currentView.icon}
              <div className="tw-flex-1">{currentView.name}</div>
            </>
          ) : (
            <div className="tw-flex-1">Choose...</div>
          )}
          <SpinningChevron isOpen={isOpen} />
        </Popover.Trigger>
      )}
      <Popover.Portal>
        <Popover.Content
          className={cx(
            "tw-z-dialog tw-bg-white tw-border tw-rounded tw-shadow-lg",
            "tw-flex tw-box-border tw-min-h-[320px]",
          )}
          align={"end"}
          onMouseLeave={() => setHoveredView(null)}
        >
          {hoveredView && <ViewPreviewPane view={hoveredView} />}
          <div className={"tw-w-[280px] tw-py-4 tw-flex tw-flex-col"}>
            {showTitle && sectionTitle("Views")}
            {DEFAULT_IF_ANALYSIS_VIEWS.map((view) => (
              <ViewMenuItem
                key={view.id}
                selected={view === selectedView}
                onClick={() => onChange(view)}
                text={view.name}
                icon={view.icon}
                setHovered={(hovered) => {
                  if (hovered) {
                    setHoveredView(view);
                  }
                }}
                disabled={false}
              />
            ))}
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
