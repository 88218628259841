export const DEFAULT_NUM_CLUSTERS = 10;
export const DEFAULT_CUT_TREE_NAME: string = "generate_cut_trees";

export const CLUSTER_CARD_HEIGHT_PX = 64;

export const CHART_HEIGHT_PX = CLUSTER_CARD_HEIGHT_PX * (3 / 5);
export const CHART_AXIS_HEIGHT_PX = 72;
export const METADATA_LEGEND_WIDTH_PX = 160;

export const OTHER_COLUMN_NAME = "<other>";

export const MIN_METADATA_PERCENT = 0.5;

export const MAX_BAR_COUNT = 50;

export const METADATA_TABLE_CREATION_QUERY = `
    Select * from cut_trees ct join sample_metadata sm on
    sm.plate = ct.plate and sm.well = ct.well`;

export const FEATURE_TABLE_CREATION_QUERY = `
    Select * from cut_trees ct join features sm on
    sm.plate = ct.plate and sm.well = ct.well and sm.field = ct.field and sm.row = ct.row and sm.column = ct.column`;

export const PVALUE_SIGNIFICANCE_THRESHOLD = 0.01;

export const CELLS_FEATURES_PREFIXES: string[] = [
  "Area",
  "MeanIntensity",
  "AreaShape_Area",
  "Intensity_MeanIntensity",
];
