import { DatasetId, WorkspaceId } from "src/types";
import {
  AdminRoutes,
  DatasetRoutes,
  ExamplesRoutes,
  ImmunofluorescenceRoutes,
  PerfRoutes,
  WorkspaceRoutes,
} from "./types";
import { clientOrBuilder, urlPath } from "./util";

export { handleAborted, jsonPostInit } from "./util";
export type { ApiResponse, ApiClient } from "./client";
export type {
  Falsy,
  QueryString,
  ClientOrBuilder,
  ApiPrefix,
  ApiFetchable,
} from "./types";

export const adminApi = clientOrBuilder<AdminRoutes>(
  () => urlPath`admin/user/`,
);

export const perfApi = clientOrBuilder<PerfRoutes>(() => urlPath`perf/`);

export const workspaceApi = clientOrBuilder<
  WorkspaceRoutes,
  { workspace: WorkspaceId }
>(({ workspace }) => urlPath`workspace/${workspace}/`);

export const datasetApi = clientOrBuilder<
  DatasetRoutes,
  {
    workspace: WorkspaceId;
    dataset: DatasetId;
  }
>(
  ({ workspace, dataset }) =>
    urlPath`workspace/${workspace}/dataset/${dataset}/`,
);

export const examplesApi = clientOrBuilder<
  ExamplesRoutes,
  {
    workspace: WorkspaceId;
    dataset: DatasetId;
  }
>(
  ({ workspace, dataset }) =>
    urlPath`workspace/${workspace}/examples/${dataset}/`,
);

export const immunofluorescenceApi = clientOrBuilder<
  ImmunofluorescenceRoutes,
  { workspace: WorkspaceId; dataset: DatasetId }
>(
  ({ workspace, dataset }) =>
    urlPath`workspace/${workspace}/immunofluorescence/${dataset}/`,
);
