/**
 * Component to manage feature presentation options. At present, includes the
 * display mode ('numeric' or 'binary') along with optional values to filter
 * out.
 */
import Slider, { Range } from "rc-slider";
import Select from "react-select-plus";
import Strut from "../Common/Strut";
import { FeaturePresentation } from "../imaging/types";
import { Features } from "../types";
import Histogram, { Highlight } from "./Histogram";

const RangeWithTooltip = (Slider as any).createSliderWithTooltip(Range);

function FeatureValueSlider({
  minValue,
  maxValue,
  filterParameters,
  onChangeFilterParameters,
}: {
  minValue: number | bigint;
  maxValue: number | bigint;
  filterParameters: FilterParameters;
  onChangeFilterParameters: (filterParameters: FilterParameters) => void;
}) {
  // Possibly could make this a parameter, but for now this is probably fine.
  const numSteps = 200;
  const rangeDiff: number = Number(maxValue) - Number(minValue);
  const stepSize: number = Math.ceil(rangeDiff) / numSteps;

  const upperValue = filterParameters.upperBound
    ? Number(filterParameters.upperBound)
    : Number(maxValue);
  const lowerValue = filterParameters.lowerBound
    ? Number(filterParameters.lowerBound)
    : Number(minValue);

  // If we don't have a valid slider to show then don't return anything yet.
  return (
    <RangeWithTooltip
      tipProps={{ zIndex: 1, placement: "bottom" }}
      handleStyle={[
        {
          width: 18,
          height: 18,
          marginTop: -8,
          borderColor: "#AA00FF",
        },
      ]}
      trackStyle={[
        {
          background: "#AA00FF",
        },
      ]}
      min={Number(minValue)}
      max={Number(maxValue)}
      value={[lowerValue, upperValue]}
      defaultValue={[Number(minValue), Number(maxValue)]}
      allowCross={false}
      pushable={2 * stepSize}
      step={stepSize}
      onChange={([newMin, newMax]: [number, number]) =>
        onChangeFilterParameters({
          upperBound: Number(newMax),
          lowerBound: Number(newMin),
        })
      }
    />
  );
}

const PRESENTATIONS: {
  [K in FeaturePresentation]: {
    key: string;
    title: string;
    description: string;
  };
} = {
  // Display a numeric value for each object.
  numeric: {
    key: "numeric",
    title: "Numeric",
    description: "Display a numeric value over each cell.",
  },
  // Highlight each object. Useful in combination with a feature filter.
  box: {
    key: "box",
    title: "Box",
    description:
      "Draw a box around each cell, after applying the filters on the right.",
  },
  // Circle each object. Useful in combination with a feature filter.
  dot: {
    key: "dot",
    title: "Dot",
    description:
      "Draw a circle around each cell, after applying the filters on the right.",
  },
};

export type FilterParameters = {
  lowerBound: number | null;
  upperBound: number | null;
};

export default function FeaturePresentationOptions({
  featureName,
  featurePresentation,
  filterParameters,
  maxValue,
  minValue,
  typedFeatures,
  featureSetColumn,
  onChangeFeaturePresentation,
  onChangeFilterParameters,
  isCellSelected,
  onClearSelectedCell,
}: {
  featureName: string;
  featurePresentation: FeaturePresentation;
  filterParameters: FilterParameters;
  minValue: number | null;
  maxValue: number | null;
  typedFeatures: Features | null;
  featureSetColumn: string;
  onChangeFeaturePresentation: (
    featurePresentation: FeaturePresentation,
  ) => void;
  onChangeFilterParameters: (filterParameters: FilterParameters) => void;
  isCellSelected: boolean;
  onClearSelectedCell: () => void;
}) {
  const binColorRange: Highlight | null =
    filterParameters.lowerBound != null && filterParameters.upperBound != null
      ? {
          valueStart: filterParameters.lowerBound,
          valueEnd: filterParameters.upperBound,
          color: "#AA00FF",
          label: "Active range",
        }
      : null;

  // TODO(michaelwiest): Add these color definitions to a shared palette.
  const highlights = [
    {
      valueStart: Number(filterParameters.lowerBound),
      valueEnd: Number(filterParameters.lowerBound),
      color: "#AA00FF",
      label: "Active range",
    },
    {
      valueStart: Number(filterParameters.upperBound),
      valueEnd: Number(filterParameters.upperBound),
      color: "#AA00FF",
      label: "Active range",
    },
  ];
  const overlayMessage = isCellSelected ? (
    <>
      <div>Showing Selected Cell</div>
      <div>(click to see full distribution)</div>
    </>
  ) : null;

  return (
    <div className={"tw-flex tw-flex-col"}>
      {minValue != null &&
        maxValue != null &&
        typedFeatures != null &&
        typedFeatures.length > 0 && (
          <div onMouseDown={onClearSelectedCell}>
            <div className={"tw-text-slate-500 tw-text-sm"}>
              Distribution of{" "}
              <span className={"tw-font-mono"}>{featureName}</span>
            </div>
            <Strut size={30} />
            <Histogram
              // Change on field change too
              key={`ControlHist_${featureSetColumn}`}
              data={typedFeatures}
              column={"value"}
              // See if there is a way to get the width to fit the container.
              width={300}
              height={200}
              binColorRange={binColorRange}
              highlights={highlights}
              lowValDefault={Number(minValue)}
              highValDefault={Number(maxValue)}
              overlay={
                overlayMessage && (
                  <span className="tw-cursor-pointer tw-text-center">
                    {overlayMessage}
                  </span>
                )
              }
            />
            {/*Hack: 30px offset to get the slider to line up with the hist.*/}
            <div className={"tw-pl-[30px] tw-pt-3"}>
              <FeatureValueSlider
                minValue={minValue}
                maxValue={maxValue}
                filterParameters={filterParameters}
                onChangeFilterParameters={onChangeFilterParameters}
              />
            </div>
          </div>
        )}
      <Strut size={16} />
      <div className={"tw-text-slate-500 tw-flex tw-flex-row tw-items-center"}>
        Display overlays as
        <Select<FeaturePresentation>
          className={"tw-inline-block tw-ml-2 tw-flex-1"}
          simpleValue={true}
          clearable={false}
          options={Object.values(PRESENTATIONS).map(({ key, title }) => ({
            value: key,
            label: title,
          }))}
          value={featurePresentation}
          onChange={(value: FeaturePresentation) => {
            onChangeFeaturePresentation(value);
          }}
        />
      </div>
    </div>
  );
}
