import { createContext, useContext } from "react";
import { DatasetId, DatasetListing, WorkspaceId } from "src/types";
import { Fetchable } from "@spring/core/result";
import { Workspace } from "./types";

export interface WorkspaceContextValue {
  workspaceId?: WorkspaceId;
  datasetId?: DatasetId;
  workspacesFetchable?: Fetchable<Workspace[]>;
  workspaceFetchable?: Fetchable<Workspace | null>;
  datasetFetchable?: Fetchable<DatasetListing | null>;
}

export const WorkspaceContext = createContext<WorkspaceContextValue | null>(
  null,
);

export function useActiveWorkspaceFetchable(): Fetchable<Workspace | null> {
  const context = useContext(WorkspaceContext);

  return context?.workspaceFetchable;
}

export function useActiveWorkspace(): Workspace;
export function useActiveWorkspace(options: { optional: false }): Workspace;
export function useActiveWorkspace(options: {
  optional: boolean;
}): Workspace | undefined;
export function useActiveWorkspace(
  options: {
    optional: boolean;
  } = { optional: false },
): Workspace | undefined {
  const context = useContext(WorkspaceContext);

  if (
    context?.workspaceFetchable?.successful &&
    context.workspaceFetchable.value
  ) {
    return context.workspaceFetchable.value;
  } else if (options.optional) {
    return undefined;
  } else if (!context) {
    throw new Error(
      "Cannot get active workspace outside of a WorkspaceContext",
    );
  } else if (!context.workspaceId) {
    throw new Error("Unable to extract workspace from URL");
  } else if (!context.workspaceFetchable) {
    throw new Error("Workspace has not yet finished loading");
  } else {
    throw new Error("Workspace failed to load");
  }
}

export function useActiveWorkspaceId(): WorkspaceId;
export function useActiveWorkspaceId(options: { optional: false }): WorkspaceId;
export function useActiveWorkspaceId(optional: {
  optional: boolean;
}): WorkspaceId | undefined;
export function useActiveWorkspaceId(
  options: { optional: boolean } = { optional: false },
): WorkspaceId | undefined {
  const context = useContext(WorkspaceContext);
  if (context?.workspaceId) {
    return context.workspaceId;
  } else if (options.optional) {
    return undefined;
  } else if (!context) {
    throw new Error(
      "Cannot get active workspace id outside of a WorkspaceContext",
    );
  } else {
    throw new Error("Unable to extract workspace from URL");
  }
}

export function useActiveExperimentFetchable(): Fetchable<DatasetListing | null> {
  const context = useContext(WorkspaceContext);

  return context?.datasetFetchable;
}

export function useActiveExperiment(): DatasetListing;
export function useActiveExperiment(options: {
  optional: false;
}): DatasetListing;
export function useActiveExperiment(optional: {
  optional: boolean;
}): DatasetListing | undefined;
export function useActiveExperiment(
  options: { optional: boolean } = { optional: false },
): DatasetListing | undefined {
  const context = useContext(WorkspaceContext);

  if (context?.datasetFetchable?.successful && context.datasetFetchable.value) {
    return context.datasetFetchable.value;
  } else if (options.optional) {
    return undefined;
  } else if (!context) {
    throw new Error(
      "Cannot get active experiment outside of a WorkspaceContext",
    );
  } else if (!context.datasetId) {
    throw new Error("Unable to extract experiment from URL");
  } else if (!context.datasetFetchable) {
    throw new Error("Dataset has not yet finished loading");
  } else {
    throw new Error("Dataset failed to load");
  }
}

export function useActiveExperimentId(): DatasetId;
export function useActiveExperimentId(options: { optional: false }): DatasetId;
export function useActiveExperimentId(optional: {
  optional: boolean;
}): DatasetId | undefined;
export function useActiveExperimentId(
  options: { optional: boolean } = { optional: false },
): DatasetId | undefined {
  const context = useContext(WorkspaceContext);
  if (context?.datasetId) {
    return context.datasetId;
  } else if (options.optional) {
    return undefined;
  } else if (!context) {
    throw new Error(
      "Cannot get active experiment id outside of a WorkspaceContext",
    );
  } else {
    throw new Error("Unable to extract experiment from URL");
  }
}
