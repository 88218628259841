import cx from "classnames";
import { useEffect, useState } from "react";
import { CheckCircle, Lock, XCircle } from "react-feather";
import { useRouteMatch } from "react-router-dom";
import { DatasetId, WorkspaceId } from "src/types";
import { Caption, Title } from "@spring/ui/typography";
import { FullScreenContainer } from "../../Common/FullScreenContainer";
import Loader from "../../Common/Loader";
import { DB, copyTableAcrossDB, sql } from "../../util/sql";
import { useFetchManifestErrors, useFetchMetadataErrors } from "../hooks";
import { DuckDBTable } from "./DuckDBTable";
import { Column, ColumnType, Row } from "./EditableTable";
import { SplashScreenContainer } from "./helpers";

export function Validate() {
  const match = useRouteMatch<{
    workspaceId: WorkspaceId;
    datasetId: DatasetId;
  }>();
  const { workspaceId, datasetId } = match.params;
  const manifestErrorDBReq = useFetchManifestErrors(workspaceId, datasetId);
  const metadataErrorDBReq = useFetchMetadataErrors(workspaceId, datasetId);

  const [allErrorsDB, setAllErrorsDB] = useState<DB | null>(null);

  useEffect(() => {
    async function createMergedTable() {
      if (
        manifestErrorDBReq?.successful &&
        metadataErrorDBReq?.successful &&
        !allErrorsDB
      ) {
        const mergedDb = await copyTableAcrossDB(
          metadataErrorDBReq.value,
          manifestErrorDBReq.value,
          "metadata_errors",
        );

        const conn = await mergedDb.connect();

        try {
          const result = await conn.query(sql`
            SELECT 
              plate,
              well,
              'manifest' as source,
              in_metadata as has_match,
              missing_fields,
              path
            FROM manifest_errors
            UNION ALL
            SELECT 
                plate,
                well,
                'metadata' as source,
                in_manifest as has_match,
                missing_fields,
                NULL as path
            FROM metadata_errors;
          `);
          await conn.insertArrowTable(result, {
            name: "all_errors",
            create: true,
          });
        } finally {
          conn.close();
        }

        setAllErrorsDB(mergedDb);
      }
    }
    createMergedTable();
  }, [allErrorsDB, manifestErrorDBReq, metadataErrorDBReq]);

  const noFileFound =
    (!manifestErrorDBReq?.successful &&
      manifestErrorDBReq?.error.message === "File not found") ||
    (!metadataErrorDBReq?.successful &&
      metadataErrorDBReq?.error.message === "File not found");

  if (noFileFound) {
    return (
      <SplashScreenContainer classNames="tw-text-gray-500">
        <Lock className="tw-w-12 tw-h-12" />
        <Title className="tw-my-md">Missing Data</Title>
        <Caption>
          Both the manifest and metadata are required for validation.
        </Caption>
      </SplashScreenContainer>
    );
  }

  if (!manifestErrorDBReq || !metadataErrorDBReq || !allErrorsDB) {
    return (
      <FullScreenContainer center>
        <Loader />
      </FullScreenContainer>
    );
  }

  if (!manifestErrorDBReq.successful || !metadataErrorDBReq.successful) {
    const fetchableError = !manifestErrorDBReq.successful
      ? manifestErrorDBReq.error
      : !metadataErrorDBReq.successful
        ? metadataErrorDBReq.error
        : null;
    console.error(fetchableError);
    return <div>Oops. Something went wrong fetching validation data.</div>;
  }

  return (
    <div className="tw-p-lg">
      <DuckDBTable
        db={allErrorsDB}
        tableName="all_errors"
        createColumnsFromRow={createColumnsFromRow}
      />
    </div>
  );
}

function createColumnsFromRow(row: Row): Column[] {
  return Object.keys(row).map((field) => {
    let type: ColumnType;
    switch (typeof row[field]) {
      case "number":
        type = ColumnType.Number;
        break;
      case "boolean":
        type = ColumnType.Checkbox;
        break;
      default:
        type = ColumnType.Text;
    }

    return {
      headerContent: field,
      field,
      type: type,
      cellFormatter: (value) => {
        if (field === "has_match") {
          return value ? (
            <CheckCircle className="tw-text-purple tw-h-5 tw-w-5 tw-mx-auto" />
          ) : (
            <XCircle className="tw-text-red-500 tw-h-5 tw-w-5 tw-mx-auto" />
          );
        }

        if (field === "source") {
          return (
            <div
              className={cx(
                "tw-px-2 tw-py-1 tw-rounded-full tw-leading-none tw-max-w-full",
                "tw-text-xs tw-my-sm tw-uppercase tw-text-white",
                value === "metadata" ? "tw-bg-blue" : "tw-bg-magenta",
              )}
            >
              {value}
            </div>
          );
        }

        return value;
      },
      ...(field === "source" ? { align: "center" } : {}),
    };
  });
}
