/**
 * Query input for "checkbox"-type columns.
 */
import CheckboxCell from "./CheckboxCell";

export default function QueryCheckboxInput({
  queryText,
  options,
  onChange,
}: {
  queryText: boolean;
  options: Record<string, never>;
  onChange: (queryText: boolean) => void;
}) {
  return (
    <div className={"tw-flex tw-items-center tw-pl-2"}>
      <CheckboxCell {...options} onChange={onChange}>
        {queryText}
      </CheckboxCell>
    </div>
  );
}
