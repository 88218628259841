/**
 * Component to contain a list of items in a dropdown.
 */
import { css } from "aphrodite";
import { ReactNode } from "react";
import { shared } from "../../megamap-styles";

export default function List({ children }: { children: ReactNode }) {
  return (
    <ul
      role="listbox"
      className={
        "tw-overflow-auto tw-relative tw-bg-white " +
        css(shared.listBase, shared.lightScrollbar)
      }
      style={{ maxHeight: 400 }}
    >
      {children}
    </ul>
  );
}
