/**
 * Utilities for preprocessing and validating MegaMap data.
 */
import {
  isGroupedSimilaritiesScores,
  isSimilaritiesScores,
} from "src/util/dataset-util";
import { FilterSqlClause } from "../Control/FilterSelector/types";
import { ComparisonsConfig } from "../immunofluorescence/metadata";
import { MetadataColumnValue } from "../types";
import {
  DB,
  columnMatchesValueClause,
  queryDBAsRecords,
  sql,
} from "../util/sql";
import {
  ColumnGroup,
  ReferenceRowSingle,
  Row,
  RowGroup,
  RowSingle,
  VariableSourceColumn,
} from "./Table/types";
import {
  FeatureKind,
  FeatureMetadata,
  Features,
  HitKindCounts,
  HitKinds,
  KNOWN_FEATURE_KINDS,
  MetadataColumnID,
  RowMetadata,
  TypedColumn,
} from "./backend/types";
import { SavedScoringMethodology } from "./scoring/types";
import {
  FeatureScales,
  MultiSimilarityScoreResult,
  MultiSimilarityScores,
  ViewState,
} from "./types";
import { HitKind } from "./utils/hit-kind";

export type FeatureIndex = string[];
export type ReverseFeatureIndex = { [featureName: string]: number };

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const HIT_KINDS: HitKind[] = [
  "HitKind.BENEFICIAL",
  "HitKind.DETRIMENTAL",
  "HitKind.NEAR_BENEFICIAL",
  "HitKind.NEAR_DETRIMENTAL",
  "HitKind.PARTIAL_BENEFICIAL",
  "HitKind.PARTIAL_DETRIMENTAL",
  "HitKind.MISS",
];

/**
 * Map a FeatureKind to null if it doesn't match a known value.
 *
 * This is helpful for avoiding runtime errors due to mismatches between types
 * and real-world data.
 */
function validateFeatureKind(
  featureKind: FeatureKind | null,
): FeatureKind | null {
  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (featureKind == null || KNOWN_FEATURE_KINDS[featureKind]) {
    return featureKind;
  } else {
    throw new Error(`Invalid FeatureKind: ${featureKind}`);
  }
}

/**
 * Remove any invalid or unsupported values from a FeatureMetadata object.
 */
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function validateFeatureMetadata(
  featureMetadata: FeatureMetadata,
): FeatureMetadata {
  return Object.fromEntries(
    Object.entries(featureMetadata).map(([name, metadata]) => [
      name,
      { ...metadata, kind: validateFeatureKind(metadata.kind) },
    ]),
  );
}

/**
 * Compute the minimum and maximum change in each feature.
 */
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function computeFeatureScales(
  rows: (RowSingle | ReferenceRowSingle | RowGroup)[],
  columns: ColumnGroup[],
  featureData: Features,
): FeatureScales {
  const featureScales: FeatureScales = {};
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    if ("children" in row || row.isReference) {
      continue;
    }
    for (let j = 0; j < columns.length; j++) {
      for (let k = 0; k < columns[j].columnIds.length; k++) {
        const featureName = columns[j].columnIds[k];
        const datum = expandFeature(row, featureName, featureData);
        if (datum == null) {
          continue;
        }
        const { mean_diff } = datum;
        // TODO(you): Fix this no-unnecessary-condition rule violation
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (mean_diff == null) {
          continue;
        }
        // TODO(you): Fix this no-unnecessary-condition rule violation
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!featureScales[featureName]) {
          featureScales[featureName] = {
            minDiff: mean_diff,
            maxDiff: mean_diff,
          };
        } else {
          featureScales[featureName] = {
            minDiff: Math.min(featureScales[featureName].minDiff, mean_diff),
            maxDiff: Math.max(featureScales[featureName].maxDiff, mean_diff),
          };
        }
      }
    }
  }
  return featureScales;
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function findColumn<T extends TypedColumn>(
  columns: T[],
  id: string,
): T | undefined {
  return columns.find((column) => column.id === id);
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function toColumn<T extends TypedColumn>(columns: T[], id: string): T {
  const found = findColumn(columns, id);
  if (found == null) {
    throw new Error(`Unable to find column: ${id}`);
  }
  return found;
}

/**
 * Render a display name for a compound based on its row metadata.
 */
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function toCompoundDisplayName(metadata: RowMetadata): string {
  const { compoundName, compoundId } = metadata;

  // If we have both a name and ID available (and they're not identical), render
  // both.
  if (compoundName && compoundId && compoundName !== compoundId) {
    return `${compoundName} (${compoundId})`;
  }

  // Otherwise, as long as we have an ID, return that.
  if (compoundId) {
    return compoundId;
  }

  throw Error("Metadata is missing compound ID.");
}

export function expandHitKind(
  row: ReferenceRowSingle | RowSingle,
  columnName: string,
  hitKinds: HitKinds,
): HitKind | null {
  const features = hitKinds.features[row.featureId];
  const index = hitKinds.reverseIndex[columnName];
  const hitKindIndex = features[index];
  return hitKindIndex == null ? hitKindIndex : HIT_KINDS[hitKindIndex];
}

function expandFeature(
  row: Row,
  columnName: string,
  featureData: Features,
): {
  mean_diff: number;
  p_value: number;
} | null {
  const features = featureData.features[row.featureId];

  return features[featureData.reverseIndex[columnName]];
}

export function expandNonReferenceFeature(
  row: Row,
  columnName: string,
  featureData: Features,
): {
  mean_diff: number;
  p_value: number;
} | null {
  return expandFeature(row, columnName, featureData);
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function expandHitKindCounts(
  row: RowSingle | RowGroup,
  columnName: string,
  hitKindCounts: HitKindCounts,
): HitKind[] | null {
  // Intentionally use `id` here as opposed to `featureId`. We compute hit kind
  // counts (which are tallied across all replicates) at the group level.
  const features = hitKindCounts.features[row.id];
  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (features == null) {
    return null;
  }
  const featureIndex = hitKindCounts.reverseIndex[columnName];
  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (featureIndex == null) {
    return null;
  }
  const hitKindIndexes = features[featureIndex];
  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (hitKindIndexes == null) {
    return null;
  }
  return hitKindIndexes.map((hitKindIndex: number) => HIT_KINDS[hitKindIndex]);
}

/**
 * @returns {@code true} if a string matches the structure of an SC ID.
 */
export function isSCID(compoundId: string): boolean {
  return compoundId.startsWith("SC");
}

/**
 * @returns the index of the SC ID (e.g., "SC123" -> 123).
 */
export function toSCIndex(compoundId: string): number | null {
  const match = /SC(\d+)/.exec(compoundId);
  if (match) {
    const [, index] = match;
    return Number.parseInt(index, 10);
  } else {
    return null;
  }
}

/**
 * @returns {@code true} if the string is valid given the query text.
 */
export function matches(value: string, queryText: string): boolean {
  return value.toLowerCase().includes(queryText.toLowerCase());
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function withoutGroup(column: string, group: string) {
  return column.startsWith(`${group}_`)
    ? column.substring(group.length + 1)
    : column;
}

export function selectFirstInterestingValue(
  values: MetadataColumnValue[],
): MetadataColumnValue {
  if (!values.every((value) => value === null || typeof value === "string")) {
    return values[0];
  }

  const asStrings = values as (string | null)[];
  // First pass: just try all interesting tokens.
  const find = asStrings.find((value) =>
    value?.toLowerCase().includes("control"),
  );
  if (find) {
    return find;
  }

  // TODO(benkomalo): we can probably do something easy with determining a common
  // prefix, then seeing if anything doesn't match that prefix.
  return asStrings[0];
}

/**
 * Generates an internal ID for a feature column that's unique across multiple features.
 *
 * Most MegaMap code refers to columns as "column names", but semantically treat them
 * as ID. To resolve to the user-visible text representing each column, you have to
 * go through `FeatureMetadata` with these column values.
 */
export function featureColumnId(feature: string, column: string): string {
  // NOTE: We currently have an expectation that
  // featureColumnId("feature", "column") + "_suffix"
  // will be the same as
  // featureColumnId("feature", "column_suffix")
  // (see e.g. how we add and lookup entries with the  _meandiff and _p suffixes)
  return ["_f_", feature, column].join("\n");
}

export function isFeatureColumnId(id: string): boolean {
  return id.startsWith("_f_\n");
}

export function parseFeatureColumnId(id: string): {
  feature: string;
  column: string;
} | null {
  const [prefix, feature, column] = id.split("\n");
  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (prefix === "_f_" && feature !== undefined && column !== undefined) {
    return {
      feature,
      column,
    };
  } else {
    return null;
  }
}

export function displayFeatureId(id: string, showFeatureset: boolean = true) {
  const parsed = parseFeatureColumnId(id);
  if (parsed) {
    if (showFeatureset) {
      return `${parsed.column} (${decodeURIComponent(parsed.feature)})`;
    } else {
      return parsed.column;
    }
  } else {
    return id;
  }
}

export function metadataColumnId(id: string): string {
  return ["_m", id].join("_");
}

// NOTE: Copied from FORMATTERS.score in TableMetadataValue.tsx
export function formatScore(value: number): string {
  return String(Math.sign(value) * Math.floor(Math.abs(value)));
}

export function scoreColumnId(
  scoringMethod: Pick<SavedScoringMethodology, "id">,
): MetadataColumnID {
  return ["_s", scoringMethod.id].join("_") as MetadataColumnID;
}

export function isScoreColumnId(id: string): boolean {
  return id.startsWith("_s_");
}

export function buildRowId(...pieces: unknown[]): string {
  return pieces.join("::");
}

export function rowId(
  row: { [key: string]: unknown } & { metadata?: { [key: string]: unknown } },
  treatmentColumn: string,
  groupBy: string | null,
  subgroupBy: string | null,
) {
  const pieces: unknown[] = [];
  if (groupBy) {
    pieces.push(row[groupBy] ?? row.metadata?.[groupBy] ?? "<null>");
  }
  pieces.push(
    row[treatmentColumn] ?? row.metadata?.[treatmentColumn] ?? "<null>",
  );
  if (subgroupBy) {
    pieces.push(row[subgroupBy] ?? row.metadata?.[subgroupBy] ?? "<null>");
  }
  return buildRowId(...pieces);
}

/**
 * Given a filter, potentially modify it to ensure negative controls are included.
 *
 * This is useful since MegaMap operations/visuals typically operate as "comparisons"
 * with respect to a negative control. Therefore, filters that are naively applied
 * could remove all negative controls, resulting in non-sensical / non-computable
 * results.
 */
export async function maybeModifyFilterToEnsureNegativeControls(
  config: ComparisonsConfig,
  filterSerialized: FilterSqlClause,
  metadataDB: DB,
): Promise<FilterSqlClause> {
  if (filterSerialized === "TRUE") {
    return filterSerialized;
  }

  const plates = (
    await queryDBAsRecords(
      metadataDB,
      sql`SELECT DISTINCT plate FROM sample_Metadata WHERE ${filterSerialized} ORDER BY plate`,
    )
  ).map(({ plate }) => plate);

  if (!plates.length) {
    // Unexpectedly empty filter; not much we can do here -- just return things
    // unmodified?
    return filterSerialized;
  }

  const allPlates = (
    await queryDBAsRecords(
      metadataDB,
      sql`SELECT DISTINCT plate FROM sample_Metadata ORDER BY plate`,
    )
  ).map(({ plate }) => plate);

  const includesAllPlates = allPlates.length === plates.length;
  const plateFilter = includesAllPlates
    ? "TRUE"
    : `plate IN (${plates.map((p) => `'${p}'`).join(",")})`;
  return (
    `(${filterSerialized}) OR (` +
    `${plateFilter} AND (${columnMatchesValueClause(
      config.treatmentColumn,
      config.controlValue,
    )})` +
    `)`
  );
}

export function parseConcentration(concentration: string): number {
  if (concentration.endsWith("%")) {
    return (
      Number.parseFloat(concentration.substring(0, concentration.length - 1)) /
      100
    );
  } else if (concentration.endsWith("uM")) {
    return Number.parseFloat(
      concentration.substring(0, concentration.length - 2),
    );
  } else if (concentration.endsWith("nM")) {
    return Number.parseFloat(
      concentration.substring(0, concentration.length - 2),
    );
  } else if (concentration.endsWith("U/mL")) {
    return Number.parseFloat(
      concentration.substring(0, concentration.length - 4),
    );
  } else if (concentration.endsWith("ug/mL")) {
    return Number.parseFloat(
      concentration.substring(0, concentration.length - 5),
    );
  } else if (concentration.startsWith("1:")) {
    return 1.0 / Number.parseFloat(concentration.substring(2));
  } else if (concentration.endsWith("ng/mL")) {
    return Number.parseFloat(
      concentration.substring(0, concentration.length - 5),
    );
  } else {
    throw Error(`Invalid concentration: ${concentration}`);
  }
}

// NOTE(danlec): We can only properly sort by values that
// we know how to parse (see the implementation of parseConcentration)
export function sortByConcentration(a: string, b: string) {
  try {
    return parseConcentration(b) - parseConcentration(a);
  } catch (ex) {
    // It's possible the concentrations are in a format we don't understand
    return b.localeCompare(a);
  }
}

export function isComparisonsConfig(
  config: Partial<ComparisonsConfig>,
): config is ComparisonsConfig {
  return (
    config.controlValue !== undefined &&
    config.stratifyColumns !== undefined &&
    config.treatmentColumn !== undefined
  );
}

export function combineScores<T extends MultiSimilarityScores>(
  initial: T,
  added: T,
): T {
  if (isSimilaritiesScores(initial) && isSimilaritiesScores(added)) {
    return { ...initial, ...added };
  } else if (
    isGroupedSimilaritiesScores(initial) &&
    isGroupedSimilaritiesScores(added)
  ) {
    return Object.fromEntries(
      Array.from(new Set([...Object.keys(initial), ...Object.keys(added)])).map(
        (group) => [
          group,
          // TODO(you): Fix this no-unnecessary-condition rule violation
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          combineScores(initial[group] ?? {}, added[group] ?? {}),
        ],
      ),
    ) as T;
  } else {
    throw new Error("expected both collections of scores to be the same type");
  }
}

export function removeScores<T extends MultiSimilarityScores>(
  initial: T,
  removed: MetadataColumnValue[],
): T {
  if (isSimilaritiesScores(initial)) {
    return Object.fromEntries(
      Object.entries(initial).filter(([key]) => !removed.includes(key)),
    ) as T;
  } else {
    return Object.fromEntries(
      Object.entries(initial).map(
        ([group, scores]: [
          string,
          {
            [referenceValue: string]: MultiSimilarityScoreResult;
          },
        ]) => [group, removeScores(scores, removed)],
      ),
    ) as T;
  }
}

export function normalizeOverviewColumns(
  columns: VariableSourceColumn[],
): ViewState["overviewColumns"] {
  const [xColumn, yColumn]: (VariableSourceColumn | undefined)[] = columns;

  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return xColumn && yColumn ? [xColumn, yColumn] : xColumn ? [xColumn] : [];
}
