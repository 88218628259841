import { StyleSheet, css } from "aphrodite";
import classNames from "classnames";
import { useCallback, useLayoutEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { shared } from "../../../megamap-styles";
import { BACKGROUND_COLORS } from "../../../util/generic-color-palette";

export default function QueryTextInput({
  queryText,
  onChange,
}: {
  queryText: string;
  onChange: (queryText: string) => void;
}) {
  const [rawText, setRawText] = useState<string>(queryText);
  const onChangeDebounced = useDebouncedCallback(onChange, 250);
  const handleChange = useCallback(
    (queryText: string) => {
      if (queryText !== rawText) {
        setRawText(queryText);
        onChangeDebounced(queryText);
      }
    },
    [rawText, onChangeDebounced],
  );
  useLayoutEffect(() => {
    if (!onChangeDebounced.isPending() && queryText !== rawText) {
      setRawText(queryText);
    }
  }, [rawText, queryText, onChangeDebounced]);
  return (
    <span
      className={
        "tw-flex tw-flex-auto tw-relative " + css(styles.textInputContainer)
      }
    >
      <input
        type={"text"}
        className={
          "tw-col-12 tw-px-1 tw-py-0.5 tw-rounded tw-truncate " +
          css(shared.darken1, shared.borderDarken3Focus, styles.textInput)
        }
        value={rawText}
        onChange={(e) => handleChange(e.target.value)}
      />
      <i
        className={
          classNames(
            "tw-pointer-events-none",
            onChangeDebounced.isPending() ? "tw-visible" : "tw-invisible",
          ) +
          " " +
          css(styles.spinnerContainer)
        }
      >
        {/* TODO(benkomalo): this used to show a circular spinner because the
        onChange is debounced, so there's a split second/delay from the filter state
        being passed to clients. Restore that when the UI is stable. */}
        ...
      </i>
    </span>
  );
}

const styles = StyleSheet.create({
  textInputContainer: {
    display: "inline-block",
    position: "relative",
    height: "auto",
  },
  textInput: {
    height: 30,
    color: BACKGROUND_COLORS.grayDark1,
    borderColor: "rgba(0, 0, 0, 0)",
    appearance: "none",
    borderWidth: 2,
    borderStyle: "solid",
    outline: 0,
  },
  spinnerContainer: {
    color: "#888888",
    fill: "#888888",
    position: "absolute",
    top: 8,
    right: 6,
    zIndex: 100,
  },
});
