import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Failure, Fetchable, Success } from "@spring/core/result";
import { SharedMemoContext } from "./context";
import { StoredValue } from "./storage";
import { Config } from "./types";

// Use a value from the nearest shared cache (or compute and cache the value if we
// don't already have it).  Passing a null config will skip the computation of a value.
export function useSharedMemo<T, D extends unknown[] = unknown[]>(
  config: Config<T, D>,
): T;
export function useSharedMemo(config: null): null;
export function useSharedMemo<T, D extends unknown[] = unknown[]>(
  config: Config<T, D> | null,
): T | null;
export function useSharedMemo<T, D extends unknown[] = unknown[]>(
  config: Config<T, D> | null,
): T | null {
  const resolve = useContext(SharedMemoContext);
  const lastValue = useRef<StoredValue<T> | null>(null);

  const updateLastValue = useCallback((update: StoredValue<T> | null) => {
    lastValue.current?.removeReference();
    lastValue.current = update;
    update?.addReference();
  }, []);

  useEffect(() => () => updateLastValue(null), [updateLastValue]);

  if (!config) {
    updateLastValue(null);
    return null;
  }

  const latestValue = resolve(config);

  if (!lastValue.current || lastValue.current !== latestValue) {
    updateLastValue(latestValue);
  }

  return latestValue.value;
}

type WrappedPromise<T> = { promise: Promise<T> } & (
  | { state: "pending" }
  | { state: "fulfilled"; value: T }
  | { state: "rejected"; error: Error }
);

// Returns an object that keeps track of the state of a promise (so we can use the
// value immediately if the promise has already been fulfilled)
function wrapPromise<T>(promise: Promise<T>): WrappedPromise<T> {
  const wrapped: WrappedPromise<T> = {
    promise,
    state: "pending",
  };

  promise.then(
    (value) => Object.assign(wrapped, { state: "fulfilled", value }),
    (error) => Object.assign(wrapped, { state: "rejected", error }),
  );

  return wrapped;
}

function fetchableFromWrappedPromise<T>(
  wrapped: WrappedPromise<T> | null | undefined,
): Fetchable<T> {
  switch (wrapped?.state) {
    case "fulfilled":
      return Success.of(wrapped.value);
    case "rejected":
      return Failure.of(wrapped.error);
    default:
      return undefined;
  }
}

// Get an asynchronously generated value from the nearest shared cache (or compute and
// cache the value if we don't already have it).  The value is returned as a
// Fetchable.  Passing a null config will skip the computation of a value.
export function useSharedMemoFetchable<T, D extends unknown[] = unknown[]>(
  config: Config<Promise<T>, D> | null,
): Fetchable<T> {
  // Create a new config that allows us to store a wrapped version of the promise
  // that knows whether the value has been resolved or not
  const promiseConfig = useMemo<Config<WrappedPromise<T>, D> | null>(
    () =>
      config && {
        ...config,
        initialize(...dependencies: D) {
          return wrapPromise(config.initialize(...dependencies));
        },
        cleanup(value) {
          config.cleanup?.(value.promise);
        },
      },
    [config],
  );

  const wrappedPromise = useSharedMemo(promiseConfig);

  const [value, setValue] = useState<Fetchable<T>>(
    fetchableFromWrappedPromise(wrappedPromise),
  );

  useEffect(() => {
    setValue(fetchableFromWrappedPromise(wrappedPromise));

    if (wrappedPromise?.state === "pending") {
      let cancelled = false;

      wrappedPromise.promise.then(
        (computed) => !cancelled && setValue(Success.of(computed)),
        (error) => !cancelled && setValue(Failure.of(error)),
      );

      return () => {
        cancelled = true;
      };
    }
  }, [wrappedPromise]);

  return value;
}

// Get an asynchronously generated value from the nearest shared cache (or compute and
// cache the value if we don't already have it).  While the value is being computed for
// the first time, the return value will be undefined.
export function useSharedMemoAsyncValue<T, D extends unknown[] = unknown[]>(
  config: (Config<Promise<T>, D> & { onError?: (error: Error) => void }) | null,
): T | undefined {
  const fetchable = useSharedMemoFetchable(config);

  const error =
    fetchable && !fetchable.successful ? fetchable.error : undefined;

  useEffect(() => {
    if (!error) {
      return;
    } else if (config?.onError) {
      config.onError(error);
    } else {
      throw error;
    }
  }, [config, error]);

  return fetchable?.successful ? fetchable.value : undefined;
}
