/**
 * Component and utilities used to render overlays outside of their parents.
 */
import { StyleDeclarationValue } from "aphrodite";
import { ReactNode, Ref, forwardRef } from "react";

// eslint-disable-next-line react/display-name
export const OverlayBody = forwardRef(function (
  {
    children,
    style,
    minWidth,
    ...rest
  }: {
    children: ReactNode;
    minWidth: number;
    style: StyleDeclarationValue;
  },
  ref: Ref<HTMLDivElement>,
) {
  return (
    <div
      {...rest}
      ref={ref}
      className={"tw-z-popup"}
      style={{ ...style, minWidth }}
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </div>
  );
});
