import { useAuth0 } from "@auth0/auth0-react";
import { ReactNode, createContext, createElement } from "react";
import { useTestUser } from "src/util/test-user";
import { User } from "./types";

export const UserContext = createContext<User | null>(null);

export function UserContextProvider(props: { children: ReactNode }) {
  const { user: auth0User } = useAuth0<User>();
  const testUser = useTestUser();
  const user = testUser ?? auth0User;
  return createElement(UserContext.Provider, { ...props, value: user || null });
}
