// These correspond to files docs/methods/<methodSectionKey>.md and represent the docs available
// for the useMethods hook
export enum MethodSectionKey {
  analyze = "analyze",
  imageViewer = "imageViewer",
  phenosorter = "phenosorter",
  pipeline = "pipeline",
  qualityControl = "qualityControl",
  phenofinder = "phenofinder",
  supervisedLearner = "supervisedLearner",
}

const markdown = import.meta.glob("./docs/methods/*.md", {
  as: "raw",
  eager: true,
});

const attributes = import.meta.glob("./docs/methods/*.md", {
  import: "attributes",
  eager: true,
});

type MethodsDocEntry = {
  title: string;
  markdownContent: string;
};

export const methodsDocs: Record<
  MethodSectionKey,
  MethodsDocEntry | undefined
> = Object.fromEntries(
  Object.entries(markdown)
    .map(([path, markdownContent]) => {
      const key = /([^/]*)\.md$/.exec(path)?.[1];
      const title = (attributes[path] as { title?: string } | undefined)?.title;
      if (key) {
        return [key, { title, markdownContent }];
      }
    })
    .filter(
      (entry): entry is [MethodSectionKey, MethodsDocEntry] =>
        entry !== undefined,
    ),
) as Record<MethodSectionKey, MethodsDocEntry>;

export function getMethodSectionContent(
  sectionKey: MethodSectionKey,
): MethodsDocEntry | null {
  return methodsDocs[sectionKey] ?? null;
}
