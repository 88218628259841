import { Info } from "react-feather";
import { Select } from "@spring/ui/Select";
import { Tooltip } from "@spring/ui/Tooltip";
import LeftNavSectionTitle from "./LeftNavSectionTitle";

export default function NormalizationOptions({
  metadataColumns,
  normalizationColumns,
  onChangeNormalizationColumns,
}: {
  metadataColumns: string[];
  normalizationColumns: string[];
  onChangeNormalizationColumns: (columns: string[]) => void;
}) {
  return (
    <div className="tw-inline-block  tw-px-md tw-pl-lg  tw-w-full">
      <div className="tw-flex tw-flex-row tw-justify-between tw-text-gray-200">
        <LeftNavSectionTitle className="tw-mb-sm">
          Normalization
        </LeftNavSectionTitle>
      </div>

      <div>
        <div className="tw-flex tw-flex-row tw-items-center  tw-w-full tw-text-gray-500">
          Normalize By
          <Tooltip
            contents={
              <div className={"tw-p-md tw-w-[300px]"}>
                <div className={"tw-text-base"}>
                  Experimental confounders can skew the results of your
                  analysis. By selecting a metadata field your data will be
                  segmented by each value in that field, z-scored, then
                  re-combined, so that each sub-group is more comparable.
                </div>
              </div>
            }
            showArrow={true}
            asChild={true}
          >
            <div className={"tw-text-gray-400 tw-pl-md"}>
              <Info style={{ width: 18, height: 18 }} />
            </div>
          </Tooltip>
        </div>
        <Select
          className="tw-min-w-[200px] tw-mt-sm tw-text-slate-500 tw-text-right"
          placeholder=""
          multi
          values={normalizationColumns}
          items={metadataColumns}
          onChange={onChangeNormalizationColumns}
        />
      </div>
    </div>
  );
}
