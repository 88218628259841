import { useMemo } from "react";
import {
  getPerWorkspaceId,
  useLocalModifications,
} from "src/LocalModifications";
import { Cover } from "src/util/cover";
import { Failure, Fetchable, Success } from "@spring/core/result";
import {
  DatasetId,
  DatasetListing,
  DatasetMetadata,
  DatasetSampleMetadata,
  DatasetStatus,
  DatasetType,
  HasSingleCellDataset,
  UntypedSampleMetadataRow,
  WorkspaceId,
} from "../types";
import { buildSort } from "../util/build-sort";
import { DB, makeSampleMetadataDB } from "../util/sql";
import {
  CanSkip,
  makeDatasetApi,
  makeWorkspaceApi,
  useWorkspaceIdFromContext,
} from "./api";

if (typeof makeWorkspaceApi !== "function") {
  throw new Error(`it was a ${typeof makeWorkspaceApi}`);
}
interface DatasetDisplayResponse {
  dataset_id: DatasetId;
  display_name: string;
  description: string;
  cover: Cover;
  status: DatasetStatus;

  // TODO(benkomalo): make non-optional once we've added full support on the backend.
  dataset_type?: DatasetType;
}

const useDatasetsDisplay = makeWorkspaceApi("dataset_display")<
  DatasetDisplayResponse[],
  { includePrerelease?: boolean }
>(({ includePrerelease = false }) => ({
  include_prerelease: includePrerelease,
}));

/**
 * Hook to fetch list of datasets.
 */
export function useDatasets(
  apiOptions: Parameters<typeof useDatasetsDisplay>[0] = {},
): Fetchable<DatasetListing[]> {
  const workspaceId = useWorkspaceIdFromContext(apiOptions);
  const datasets = useDatasetsDisplay(apiOptions);

  // NOTE(danlec): Currently fetching the /dataset_display is somewhat expensive, so
  // we both have it cached server-side (for 15 minutes) and we instruct the client to
  // cache the response (for 15 minutes) which means it can take up to 30 minutes for
  // a user's change to be reflected in the response we get back.
  //
  // We don't want it to look like we've ignored the user's change, so we keep a list of
  // known modifications, and patch the (likely cached) response to include those changes
  const { modifications } = useLocalModifications("dataset-display");
  const localModificationsById = useMemo(
    () =>
      new Map(
        modifications.map((modification) => [modification.id, modification]),
      ),
    [modifications],
  );

  return useMemo(() => {
    if (!datasets || !workspaceId || apiOptions.skip) {
      return undefined;
    }

    return datasets.map((items) => {
      const transformed = items.map(
        ({
          dataset_type,
          dataset_id,
          display_name,
          description,
          cover,
          status,
        }) => {
          const localModification = localModificationsById.get(
            getPerWorkspaceId(workspaceId, dataset_id),
          );
          return {
            id: dataset_id,
            status,
            type: dataset_type ?? "if_bf_plates",
            name: localModification?.name ?? display_name,
            description: localModification?.description ?? description,
            cover: localModification?.cover ?? cover,
          };
        },
      );
      // Sort things such that non-customized names comes first (to make it obvious
      // we need to specify a name/desc). Within those two sub-groups, sort by the
      // name.
      transformed.sort(
        buildSort((ds: DatasetListing) => !hasCustomName(ds), "name"),
      );
      return transformed;
    });
  }, [apiOptions.skip, datasets, localModificationsById, workspaceId]);
}

function hasCustomName(dataset: DatasetListing) {
  return dataset.name !== dataset.id;
}

/**
 * Hook to fetch a single Dataset.
 *
 * Note: as with all `Fetchable` results, `undefined` indicates an incomplete request,
 * whereas a `null` indicates no Dataset was found with the given ID.
 */
export function useDataset({
  dataset: datasetIn,
  workspace,
  skip,
}: CanSkip<{
  dataset: DatasetId;
  workspace?: WorkspaceId;
}>): Fetchable<DatasetListing | null> {
  const datasets = useDatasets({
    includePrerelease: true,
    workspace,
    skip,
  });
  return useMemo(() => {
    if (!datasets) {
      return undefined;
    } else if (!datasets.successful) {
      return Failure.of(datasets.error);
    }

    const dataset = datasets.value.find((entry) => entry.id === datasetIn);

    if (dataset) {
      return Success.of(dataset);
    } else {
      return Failure.of(new Error("Dataset not found"));
    }
  }, [datasets, datasetIn]);
}

/**
 * Hook to fetch dataset metadata.
 */
export const useDatasetMetadata = makeDatasetApi(
  "metadata",
)<DatasetMetadata | null>();

/**
 * Hook to fetch dataset sample metadata.
 */
export const useDatasetSampleMetadata = makeDatasetApi("sample_metadata")<
  DatasetSampleMetadata,
  { plate?: string | null; dataset: string }
>(
  ({ plate }) => ({ plate: plate || undefined }),
  ({ dataset }) => ({
    transform: (sampleMetadata: UntypedSampleMetadataRow[]) => ({
      type: "immunofluorescence",
      name: dataset,
      id: dataset,
      sampleMetadata,
    }),
  }),
);

/**
 * Return sample_metadata for a Dataset (grouped on well by default).
 */
export const useDatasetSampleMetadataDB = makeDatasetApi("sample_metadata")<
  DB,
  { groupBy?: "well" | null; transform?: (blob: Blob) => Promise<DB> }
>(
  (options) => {
    const { groupBy } = {
      groupBy: "well",
      ...options,
    };

    return {
      format: "arrow",
      group_by: groupBy ?? undefined,
    };
  },
  (options) => {
    const { transform } = {
      transform: makeSampleMetadataDB,
      ...options,
    };

    return { transform, fetchKind: "blob" };
  },
);

/**
 * Returns a boolean denoting whether or not the dataset has any single cell datasets.
 */
export const useHasSingleCellDataset =
  makeDatasetApi("has_single_cell")<HasSingleCellDataset>();
