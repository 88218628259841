import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Loader from "../../Common/Loader";

type PlotLoadingMessageProps = {
  className: string;
  estimatedFinishTime?: Dayjs;
  onOpenFilterSelector: () => void;
};

/**
 * Component shown on the ScatterPlot while the UMAP data is loading.
 * It provides a spinner with a time estimate and a message to the user informing them
 * they can reduce loading time by modifying their data filters.
 */
export function PlotLoadingMessage({
  className,
  estimatedFinishTime,
  onOpenFilterSelector,
}: PlotLoadingMessageProps) {
  const [estimatedTimeRemaining, setEstimatedTimeRemaining] =
    useState<string>("Calculating…");

  useEffect(() => {
    if (estimatedFinishTime === undefined) {
      setEstimatedTimeRemaining("Calculating…");
      return;
    }

    const interval = setInterval(() => {
      // The time estimate isn't precise; add a buffer of 10 seconds
      const diffSeconds = estimatedFinishTime.diff(dayjs(), "s") + 10;
      if (diffSeconds >= 60) {
        setEstimatedTimeRemaining(estimatedFinishTime.fromNow(true));
      } else if (diffSeconds >= 30) {
        setEstimatedTimeRemaining(`Less than a minute`);
      } else if (diffSeconds > 0) {
        setEstimatedTimeRemaining(`Less than 30 seconds`);
      } else {
        setEstimatedTimeRemaining("Almost done…");
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [estimatedFinishTime]);

  return (
    <div className={className}>
      <Loader size={"large"} />
      <div className="tw-pt-4 tw-pb-1 tw-text-gray-700 tw-font-bold">
        Estimated time remaining: {estimatedTimeRemaining}
      </div>
      <div className="tw-text-sm tw-text-gray-600">
        You can{" "}
        <button
          className="tw-font-bold tw-text-purple-700 hover:tw-text-purple-500 tw-transition-colors"
          onClick={onOpenFilterSelector}
        >
          filter your data
        </button>{" "}
        to reduce analysis time.
      </div>
    </div>
  );
}
