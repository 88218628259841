import {
  LabeledCellSampleMetadata,
  UnlabeledCellSampleMetadata,
} from "../types";
import { NeighborsMap } from "./Context";

type Sample = UnlabeledCellSampleMetadata | LabeledCellSampleMetadata;
interface Remaining {
  sample: Sample;
  inDegreeScore: number;
  neighborsSet: Set<string>;
  neighborOutputCount: number;
}

const sortRemaining = (a: Remaining, b: Remaining) =>
  // Prefer samples that have the most neighbors already output
  b.neighborOutputCount - a.neighborOutputCount ||
  // Next prefer samples with high inDegreeScore
  b.inDegreeScore - a.inDegreeScore ||
  // Otherwise sort by id (so we have a consistent sort)
  a.sample.id.localeCompare(b.sample.id);

/**
 * Sorts a list of samples, grouping neighbors together
 *
 * @param samples List of samples to be sorted
 * @param neighborsData Information about the neighbors and inDegreeScore of the samples
 * @returns The samples sorted by most neighbors already output, then inDegreeScore,
 *   then id
 */
export function sortSamples(
  samples: Sample[],
  neighborsData: NeighborsMap,
): Sample[] {
  // Need to filter samples that aren't actually in the graph due to histotical
  // omission.
  const remaining = samples
    .filter((sample) => neighborsData.get(sample.id) != null)
    .map((sample): Remaining => {
      const neighbor = neighborsData.get(sample.id)!;
      const { inDegreeScore, neighbors } = neighbor;

      return {
        sample,
        inDegreeScore,
        neighborsSet: new Set(neighbors),
        neighborOutputCount: 0,
      };
    });

  const sorted: Sample[] = [];

  let nextSample: Sample | undefined;
  while ((nextSample = remaining.sort(sortRemaining).shift()?.sample)) {
    sorted.push(nextSample);

    remaining
      .filter(({ neighborsSet }) => neighborsSet.has(nextSample!.id))
      .forEach((other) => (other.neighborOutputCount += 1));
  }

  return sorted;
}
