import cx from "classnames";
import { ReactElement } from "react";
import { useUserAndLocalStorageBackedState } from "src/util/state-pool-store";
import { Label } from "@spring/ui/typography";
import { SpinningChevron } from "../../../Common/SpinningChevron";

export function ExpandableSection({
  title,
  section,
  children,
  defaultOpen = true,
}: {
  title: string;
  section: string;
  children: ReactElement;
  defaultOpen: boolean;
}) {
  const [expanded, setExpanded] = useUserAndLocalStorageBackedState(
    `${section}_expanded`,
    defaultOpen,
  );

  return (
    <div className={"tw-border-b tw-w-full tw-flex tw-flex-col"}>
      <button
        className={cx(
          "tw-w-full tw-px-xl tw-p-md tw-text-start tw-text-slate-500",
          "tw-flex tw-flex-row tw-items-start tw-justify-center tw-gap-1",
        )}
        onClick={() => setExpanded(!expanded)}
      >
        <Label className="tw-flex-1">{title}</Label>
        <SpinningChevron isOpen={expanded} />
      </button>
      {expanded && (
        <div className="tw-p-md tw-pt-0 tw-text-slate-500">{children}</div>
      )}
    </div>
  );
}
