import { useEffect } from "react";
import type { AccessToken } from "src/Auth0/accessToken";
import { useAccessTokenContext } from "src/Auth0/context";

/**
 * Hook to retrieve Auth0 access token for the current user if logged in.
 */
export function useAccessToken(): AccessToken {
  const { accessToken, refreshAccessToken } = useAccessTokenContext();

  // Refresh the access token whenever this hook is called to help ensure that
  // we're cacheing a valid one
  useEffect(() => refreshAccessToken(), [refreshAccessToken]);

  return accessToken;
}
