/**
 * Aphrodite styles imported from MegaMap.
 *
 * DO NOT ADD to this file -- this is considered deprecated and only dropped in for the
 * convenience of porting components from MegaMap. We should migrate things to our
 * tailwind theme over time.
 */
import { StyleSheet } from "aphrodite";
import { BACKGROUND_COLORS } from "./util/generic-color-palette";

export const WHITE = "#FFFFFF";
export const GRAY = "#666666"; // Equivalent to HSL(0, 0%, 40%)
export const DARK_GREY = "#4D4D4D"; // Equivalent to HSL(0, 0%, 30%)
export const BLACK = "#000000";
export const PURPLE = "#6210FF";
export const OFF_WHITE = "#F7F7F7";

/**
 * Colors derived from the Airtable palette.
 */
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const GREEN = "#20c933";
export const BLUE = "#2d7ff9";

// Bootstrap cutoffs.
// That is, the screen-size cutoffs used by Bootstrap (and thus implicitly
// used in our layout via our use of Bootstrap).
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const SM = 576;
export const MD = 768;
export const LG = 992;
export const XL = 1200;

// Corresponding content widths for each cutoff.
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const SM_CONTENT = 540;
export const MD_CONTENT = 720;
export const LG_CONTENT = 960;
export const XL_CONTENT = 1140;

// Page padding.
export const PADDING_X = 15;

// Copied from src/utils/layout.ts in MegaMap.
export const layoutStyles = StyleSheet.create({
  mdOnly: {
    [`@media (max-width: ${MD - 1}px)`]: {
      display: "none",
    },
  },
  lgOnly: {
    [`@media (max-width: ${LG - 1}px)`]: {
      display: "none",
    },
  },
  xlOnly: {
    [`@media (max-width: ${XL - 1}px)`]: {
      display: "none",
    },
  },
  noLg: {
    [`@media (min-width: ${LG}px) and (max-width: ${XL - 1}px)`]: {
      display: "none",
    },
  },
  noXl: {
    [`@media (min-width: ${XL}px)`]: {
      display: "none",
    },
  },
});

/**
 * Shared styles that are more opinionated than the above.
 */
export const shared = StyleSheet.create({
  foreignKeyBlue: {
    backgroundColor: "#e9eef9",
  },
  animate: {
    transition: ".085s all ease-in",
  },
  animateBackgroundColor: {
    transition: ".085s background-color ease-in",
  },
  big: {
    fontSize: 14,
  },
  borderBlue: {
    borderColor: BLUE,
  },
  borderBottom: {
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
  },
  borderBottomThick: {
    borderBottomStyle: "solid",
    borderBottomWidth: 2,
  },
  borderLeft: {
    borderLeftStyle: "solid",
    borderLeftWidth: 1,
  },
  borderTop: {
    borderTopStyle: "solid",
    borderTopWidth: 1,
  },
  borderDarken1: {
    borderColor: "rgba(0, 0, 0, 0.05)",
  },
  borderDarken2: {
    borderColor: "rgba(0, 0, 0, 0.1)",
  },
  borderDarken3: {
    borderColor: "rgba(0, 0, 0, 0.25)",
  },
  borderDarken2Focus: {
    ":focus": {
      borderColor: "rgba(0, 0, 0, 0.1)",
    },
  },
  borderDarken3Focus: {
    ":focus": {
      borderColor: "rgba(0, 0, 0, 0.25)",
    },
  },
  borderDarken3Hover: {
    ":hover": {
      borderColor: "rgba(0, 0, 0, 0.25)",
    },
  },
  borderBlueFocus: {
    ":focus": {
      borderColor: BLUE,
    },
  },
  borderRight: {
    borderRightStyle: "solid",
    borderRightWidth: 1,
  },
  borderThick: {
    borderStyle: "solid",
    borderWidth: 2,
  },
  borderTopThick: {
    borderTopStyle: "solid",
    borderTopWidth: 2,
  },
  borderTransparent: {
    borderColor: "transparent",
  },
  buttonBase: {
    appearance: "none",
    borderWidth: 2,
    borderColor: "rgba(0, 0, 0, 0)",
    borderStyle: "solid",
    fontFamily: "inherit",
    fontSize: "inherit",
    fontStyle: "inherit",
    fontWeight: "inherit",
    margin: 0,
    padding: 0,
  },
  caps: {
    textTransform: "uppercase",
    letterSpacing: "0.1em",
  },
  circle: {
    borderRadius: "50%",
  },
  dark: {
    backgroundColor: "hsl(0, 0%, 20%)",
  },
  darkHover: {
    ":hover": {
      backgroundColor: "hsl(0, 0%, 20%)",
    },
  },
  darken1: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
  },
  darken1Focus: {
    ":focus": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
  },
  darken1Hover: {
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
  },
  cardDarken1Hover: {
    ":hover": {
      boxShadow: "0 0 0 10px rgb(0 0 0 / 5%)",
    },
  },
  darken2: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  darken2Hover: {
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  },
  darken3: {
    backgroundColor: "rgba(0, 0, 0, 0.25)",
  },
  darken3Hover: {
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.25)",
    },
  },
  display: {
    letterSpacing: ".01em",
    WebkitFontSmoothing: "antialiased",
    fontFeatureSettings: "'calt' 0",
  },
  focusContainer: {
    ":focus": {
      opacity: 1,
      outline: "none",
    },
  },
  focusVisible: {
    ":focus-visible": {
      outline: `2px solid ${BLUE}`,
      outlineOffset: 2,
    },
  },
  gray: {
    backgroundColor: GRAY,
  },
  green: {
    backgroundColor: GREEN,
  },
  huge: {
    fontSize: "2rem",
  },
  red: {
    backgroundColor: BACKGROUND_COLORS.redBright,
  },
  inputBase: {
    appearance: "none",
    outline: 0,
    color: BACKGROUND_COLORS.grayDark1,
    border: 0,
  },
  lightScrollbar: {
    "::-webkit-scrollbar": {
      width: 12,
      height: 12,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    "::-webkit-scrollbar-button": {
      display: "none",
      height: 0,
      width: 12,
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "hsla(0, 0%, 0%, 0.35)",
      backgroundClip: "padding-box",
      border: "3px solid rgba(0, 0, 0, 0)",
      borderRadius: 6,
      minHeight: 36,
    },
  },
  lighten2Focus: {
    ":focus": {
      backgroundColor: "hsla(0, 0%, 100%, 0.1)",
      outline: "none",
    },
  },
  lighten2Hover: {
    ":hover": {
      backgroundColor: "hsla(0, 0%, 100%, 0.1)",
    },
  },
  lineHeight0: {
    lineHeight: 0,
  },
  lineHeight2: {
    lineHeight: 1.125,
  },
  lineHeight3: {
    lineHeight: 1.25,
  },
  lineHeight4: {
    lineHeight: 1.5,
  },
  linkQuiet: {
    ":hover": {
      opacity: 0.85,
    },
  },
  linkQuieter: {
    ":hover": {
      opacity: 0.7,
    },
  },
  linkUnquiet: {
    ":hover": {
      opacity: 1,
    },
    ":focus": {
      outline: "none",
    },
  },
  linkUnquietFocusable: {
    ":hover": {
      opacity: 1,
    },
    ":focus": {
      opacity: 1,
    },
  },
  listBase: {
    margin: 0,
    padding: 0,
  },
  micro: {
    fontSize: 9,
  },
  noOutline: {
    outline: "none",
  },
  pill: {
    borderRadius: 9999,
  },
  quiet: {
    opacity: 0.75,
  },
  quieter: {
    opacity: 0.5,
  },
  quietest: {
    opacity: 0.25,
  },
  roundedBig: {
    borderRadius: 6,
  },
  roundedBigTop: {
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
  },
  popover: {
    // TODO(benkomalo): integrate with z-values of tailwind.config.js
    zIndex: 10,
  },
  small: {
    fontSize: 12,
  },
  stroked1: {
    boxShadow: "0 0 0 2px rgba(0, 0, 0, 0.1)",
  },
  borderLightGray2: {
    borderColor: "hsl(0,0%,95%)",
  },
  bgLightGray1: {
    backgroundColor: "hsl(0,0%,98%)",
  },
  textBlue: {
    color: BLUE,
    fill: BLUE,
  },
  textBlueFocus: {
    ":focus": {
      color: BLUE,
      fill: BLUE,
      outline: "none",
    },
  },
  textBlueFocusWithin: {
    ":focus-within": {
      color: BLUE,
      fill: BLUE,
      outline: "none",
    },
  },
  textColorQuiet: {
    color: "hsl(0, 0%, 40%)",
  },
  textDark: {
    color: "hsl(0, 0%, 20%)",
    fill: "hsl(0, 0%, 20%)",
  },
  textDarken2: {
    color: "rgba(0, 0, 0, 0.1)",
    fill: "rgba(0, 0, 0, 0.1)",
  },
  textGray: {
    color: GRAY,
    fill: GRAY,
  },
  textDarkGray: {
    color: DARK_GREY,
    fill: DARK_GREY,
  },
  textWhite: {
    color: WHITE,
    fill: WHITE,
  },
  truncateBlock2Lines: {
    position: "relative",
    WebkitLineClamp: 2,
  },
  understroke: {
    paddingBottom: "0.14rem",
    borderBottom: "2px solid rgba(0,0,0,0.1)",
  },
  link: {
    ":hover": {
      borderColor: "currentColor",
    },
  },
  unquiet: {
    opacity: 1,
  },
});

/**
 * Tailwind CSS-like shared styles underlying the design system.
 */
export const tw = StyleSheet.create({
  all0: {
    padding: 0,
  },
  absolute: {
    position: "absolute",
  },
  backgroundTransparent: {
    backgroundColor: "transparent",
  },
  borderTransparent: {
    borderColor: "transparent",
  },
  block: {
    display: "block",
  },
  borderBox: {
    boxSizing: "border-box",
  },
  breakWord: {
    wordWrap: "break-word",
  },
  centerAlign: {
    textAlign: "center",
  },
  clearfix: {
    ":before": {
      content: "''",
      display: "table",
    },
    ":after": {
      content: "''",
      display: "table",
      clear: "both",
    },
  },
  col1: {
    width: `${(100 * 1) / 12}%`,
  },
  col10: {
    width: `${(100 * 10) / 12}%`,
  },
  col11: {
    width: `${(100 * 11) / 12}%`,
  },
  col12: {
    width: `${(100 * 12) / 12}%`,
  },
  col2: {
    width: `${(100 * 2) / 12}%`,
  },
  col3: {
    width: `${(100 * 3) / 12}%`,
  },
  col4: {
    width: `${(100 * 4) / 12}%`,
  },
  col5: {
    width: `${(100 * 5) / 12}%`,
  },
  col6: {
    width: `${(100 * 6) / 12}%`,
  },
  col7: {
    width: `${(100 * 7) / 12}%`,
  },
  col8: {
    width: `${(100 * 8) / 12}%`,
  },
  col9: {
    width: `${(100 * 9) / 12}%`,
  },
  lgCol1: {
    [`@media (min-width: ${LG}px)`]: { width: `${(100 * 1) / 12}%` },
  },
  lgCol10: {
    [`@media (min-width: ${LG}px)`]: { width: `${(100 * 10) / 12}%` },
  },
  lgCol11: {
    [`@media (min-width: ${LG}px)`]: { width: `${(100 * 11) / 12}%` },
  },
  lgCol12: {
    [`@media (min-width: ${LG}px)`]: { width: `${(100 * 12) / 12}%` },
  },
  lgCol2: {
    [`@media (min-width: ${LG}px)`]: { width: `${(100 * 2) / 12}%` },
  },
  lgCol3: {
    [`@media (min-width: ${LG}px)`]: { width: `${(100 * 3) / 12}%` },
  },
  lgCol4: {
    [`@media (min-width: ${LG}px)`]: { width: `${(100 * 4) / 12}%` },
  },
  lgCol5: {
    [`@media (min-width: ${LG}px)`]: { width: `${(100 * 5) / 12}%` },
  },
  lgCol6: {
    [`@media (min-width: ${LG}px)`]: { width: `${(100 * 6) / 12}%` },
  },
  lgCol7: {
    [`@media (min-width: ${LG}px)`]: { width: `${(100 * 7) / 12}%` },
  },
  lgCol8: {
    [`@media (min-width: ${LG}px)`]: { width: `${(100 * 8) / 12}%` },
  },
  lgCol9: {
    [`@media (min-width: ${LG}px)`]: { width: `${(100 * 9) / 12}%` },
  },
  mdCol1: {
    [`@media (min-width: ${MD}px)`]: { width: `${(100 * 1) / 12}%` },
  },
  mdCol10: {
    [`@media (min-width: ${MD}px)`]: { width: `${(100 * 10) / 12}%` },
  },
  mdCol11: {
    [`@media (min-width: ${MD}px)`]: { width: `${(100 * 11) / 12}%` },
  },
  mdCol12: {
    [`@media (min-width: ${MD}px)`]: { width: `${(100 * 12) / 12}%` },
  },
  mdCol2: {
    [`@media (min-width: ${MD}px)`]: { width: `${(100 * 2) / 12}%` },
  },
  mdCol3: {
    [`@media (min-width: ${MD}px)`]: { width: `${(100 * 3) / 12}%` },
  },
  mdCol4: {
    [`@media (min-width: ${MD}px)`]: { width: `${(100 * 4) / 12}%` },
  },
  mdCol5: {
    [`@media (min-width: ${MD}px)`]: { width: `${(100 * 5) / 12}%` },
  },
  mdCol6: {
    [`@media (min-width: ${MD}px)`]: { width: `${(100 * 6) / 12}%` },
  },
  mdCol7: {
    [`@media (min-width: ${MD}px)`]: { width: `${(100 * 7) / 12}%` },
  },
  mdCol8: {
    [`@media (min-width: ${MD}px)`]: { width: `${(100 * 8) / 12}%` },
  },
  mdCol9: {
    [`@media (min-width: ${MD}px)`]: { width: `${(100 * 9) / 12}%` },
  },
  smCol1: {
    [`@media (min-width: ${SM}px)`]: { width: `${(100 * 1) / 12}%` },
  },
  smCol10: {
    [`@media (min-width: ${SM}px)`]: { width: `${(100 * 10) / 12}%` },
  },
  smCol11: {
    [`@media (min-width: ${SM}px)`]: { width: `${(100 * 11) / 12}%` },
  },
  smCol12: {
    [`@media (min-width: ${SM}px)`]: { width: `${(100 * 12) / 12}%` },
  },
  smCol2: {
    [`@media (min-width: ${SM}px)`]: { width: `${(100 * 2) / 12}%` },
  },
  smCol3: {
    [`@media (min-width: ${SM}px)`]: { width: `${(100 * 3) / 12}%` },
  },
  smCol4: {
    [`@media (min-width: ${SM}px)`]: { width: `${(100 * 4) / 12}%` },
  },
  smCol5: {
    [`@media (min-width: ${SM}px)`]: { width: `${(100 * 5) / 12}%` },
  },
  smCol6: {
    [`@media (min-width: ${SM}px)`]: { width: `${(100 * 6) / 12}%` },
  },
  smCol7: {
    [`@media (min-width: ${SM}px)`]: { width: `${(100 * 7) / 12}%` },
  },
  smCol8: {
    [`@media (min-width: ${SM}px)`]: { width: `${(100 * 8) / 12}%` },
  },
  smCol9: {
    [`@media (min-width: ${SM}px)`]: { width: `${(100 * 9) / 12}%` },
  },
  contentStart: {
    alignContent: "flex-start",
  },
  cursorDefault: {
    cursor: "default",
  },
  cursorGrab: {
    cursor: "grab",
  },
  cursorGrabbing: {
    cursor: "grabbing",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  cursorText: {
    cursor: "text",
  },
  events: {
    pointerEvents: "all",
  },
  fit: {
    maxWidth: "100%",
  },
  fixed: {
    position: "fixed",
  },
  flex: {
    display: "flex",
  },
  flexAuto: {
    flex: "1 1 auto",
    minWidth: 0,
    minHeight: 0,
  },
  flexColumn: {
    flexDirection: "column",
  },
  flexInline: {
    display: "inline-flex",
  },
  flexNone: {
    flex: "none",
  },
  flexWrap: {
    flexWrap: "wrap",
  },
  focusable: {
    ":focus": {
      boxShadow: "inset 0 0 0 2px rgba(0, 0, 0, 0.1)",
    },
  },
  gray: {
    backgroundColor: GRAY,
  },
  heightFull: {
    height: "100%",
  },
  hidden: {
    display: "none",
  },
  inlineBlock: {
    display: "inline-block",
  },
  invisible: {
    visibility: "hidden",
  },
  itemsBaseline: {
    alignItems: "baseline",
  },
  itemsCenter: {
    alignItems: "center",
  },
  itemsEnd: {
    alignItems: "flex-end",
  },
  itemsStart: {
    alignItems: "flex-start",
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  justifyEvenly: {
    justifyContent: "space-evenly",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  justifyEnd: {
    justifyContent: "flex-end",
  },
  justifyStart: {
    justifyContent: "flex-start",
  },
  leftAlign: { textAlign: "left" },
  maxWidth4: {
    maxWidth: "72rem",
  },
  mHalf: {
    margin: "0.25rem",
  },
  mb1: {
    marginBottom: "0.5rem",
  },
  mb2: {
    marginBottom: "1rem",
  },
  mb3: {
    marginBottom: "2rem",
  },
  mb4: {
    marginBottom: "4rem",
  },
  mbHalf: {
    marginBottom: "0.25rem",
  },
  minHeightFull: {
    minHeight: "100%",
  },
  minWidthFull: {
    minWidth: "100%",
  },
  ml1: {
    marginLeft: "0.5rem",
  },
  ml2: {
    marginLeft: "1rem",
  },
  ml3: {
    marginLeft: "2rem",
  },
  mlHalf: {
    marginLeft: "0.25rem",
  },
  mln1: {
    marginLeft: "-0.5rem",
  },
  mr1: {
    marginRight: "0.5rem",
  },
  mr2: {
    marginRight: "1rem",
  },
  mr3: {
    marginRight: "2rem",
  },
  mrHalf: {
    marginRight: "0.25rem",
  },
  mrnHalf: {
    marginRight: "-0.25rem",
  },
  mrn1: {
    marginRight: "-0.5rem",
  },
  mrn2: {
    marginRight: "-1rem",
  },
  mt1: {
    marginTop: "0.5rem",
  },
  mt2: {
    marginTop: "1rem",
  },
  mtHalf: {
    marginTop: "0.25rem",
  },
  mtn2: {
    marginTop: "-1rem",
  },
  mxAuto: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  mx1: {
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
  },
  mx2: {
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  mxHalf: {
    marginRight: "0.25rem",
    marginLeft: "0.25rem",
  },
  mxn1: {
    marginRight: "-0.5rem",
    marginLeft: "-0.5rem",
  },
  mxn2: {
    marginRight: "-1rem",
    marginLeft: "-1rem",
  },
  mxnHalf: {
    marginRight: "-0.25rem",
    marginLeft: "-0.25rem",
  },
  my1: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
  my2: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  myHalf: {
    marginTop: "0.25rem",
    marginBottom: "0.25rem",
  },
  myn1: {
    marginTop: "-0.5rem",
    marginBottom: "-0.5rem",
  },
  noEvents: {
    pointerEvents: "none",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  overflowAuto: {
    overflow: "auto",
  },
  overflowHidden: {
    overflow: "hidden",
  },
  overflowScroll: {
    overflow: "scroll",
  },
  overflowVisible: {
    overflow: "visible",
  },
  p1: {
    padding: "0.5rem",
  },
  p2: {
    padding: "1rem",
  },
  p3: {
    padding: "2rem",
  },
  pHalf: {
    padding: "0.25rem",
  },
  pbHalf: {
    paddingBottom: "0.25rem",
  },
  pb1: {
    paddingBottom: "0.5rem",
  },
  pb2: {
    paddingBottom: "1rem",
  },
  pb3: {
    paddingBottom: "2rem",
  },
  pl1: {
    paddingLeft: "0.5rem",
  },
  pl2: {
    paddingLeft: "1rem",
  },
  pl3: {
    paddingLeft: "2rem",
  },
  pl4: {
    paddingLeft: "4rem",
  },
  plHalf: {
    paddingLeft: "0.25rem",
  },
  pr1: {
    paddingRight: "0.5rem",
  },
  pr2: {
    paddingRight: "1rem",
  },
  prHalf: {
    paddingRight: "0.25rem",
  },
  pt1: {
    paddingTop: "0.5rem",
  },
  pt2: {
    paddingTop: "1rem",
  },
  pt3: {
    paddingTop: "2rem",
  },
  ptHalf: {
    paddingTop: "0.25rem",
  },
  px0: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  px1: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  },
  px1AndHalf: {
    paddingLeft: "0.75rem",
    paddingRight: "0.75rem",
  },
  px2: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  pxHalf: {
    paddingLeft: "0.25rem",
    paddingRight: "0.25rem",
  },
  py1: {
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  },
  py2: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  py3: {
    paddingTop: "2rem",
    paddingBottom: "2rem",
  },
  pyHalf: {
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
  },
  relative: {
    position: "relative",
  },
  rightAlign: {
    textAlign: "right",
  },
  rounded: {
    borderRadius: 3,
  },
  roundedBig: {
    borderRadius: 6,
  },
  roundedTop: {
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  selectNone: {
    userSelect: "none",
  },
  selfCenter: {
    alignSelf: "center",
  },
  selfEnd: {
    alignSelf: "flex-end",
  },
  selfStart: {
    alignSelf: "flex-start",
  },
  selfStretch: {
    alignSelf: "stretch",
  },
  strong: {
    // TODO(charlie): This has no effect. (It needs to be `fontWeight: "bold"`.)
    fontWeight: 500,
  },
  textBlack: {
    color: BLACK,
    fill: BLACK,
  },
  textDecorationNone: {
    textDecoration: "none",
  },
  textWhite: {
    color: WHITE,
    fill: WHITE,
  },
  truncate: {
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  visible: {
    visibility: "visible",
  },
  white: {
    backgroundColor: WHITE,
  },
  whiteSpacePre: {
    whiteSpace: "pre",
  },
  whiteSpacePreWrap: {
    whiteSpace: "pre-wrap",
  },
  widthFull: {
    width: "100%",
  },
});
