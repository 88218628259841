import { useAccessToken } from "src/hooks/auth0";
import { DatasetId, WorkspaceId } from "src/types";
import { HOST_URL } from "../env";
import {
  fetchJson,
  useAuthenticatedFetchable,
  useAuthenticatedFetchableWithMutate,
} from "../hooks/fetch";
import { DB, getBlobToDBConverter } from "../util/sql";
import { Row } from "./components/EditableTable";
import {
  EditIngestionMetadataBody,
  FileParsers,
  IngestionMetadata,
  PalettesResponse,
  PipelineStatus,
  PlatePathMap,
  PublishPostBody,
} from "./types";
import { gzipJsonForPost } from "./utils";

const SHOEBILL_API_ROOT = `${HOST_URL}/api/v0/__internal_only/shoebill`;

/**
 * Fetch the all_manifests file for the given dataset and return it as a DB.
 * */
export function useFetchIngestionManifests(
  workspaceId: WorkspaceId,
  datasetId: DatasetId,
  columns?: string[],
) {
  const params = new URLSearchParams();
  if (columns) {
    for (const col of columns) {
      params.append("columns", col);
    }
  }
  return useAuthenticatedFetchableWithMutate<DB>(
    `${SHOEBILL_API_ROOT}/workspace/${workspaceId}/dataset/${datasetId}/manifest?${params}`,
    undefined,
    undefined,
    "blob",
    getBlobToDBConverter("manifest"),
  );
}

/**
 * Fetch available parsers for generating a manifest.
 * */
export function useFetchParsers() {
  return useAuthenticatedFetchable<FileParsers>(
    `${SHOEBILL_API_ROOT}/manifest/parsers`,
    undefined,
    undefined,
  );
}

/**
 * Sample N images paths and validate the image files.
 * */
export function useValidateImages(
  workspaceId: WorkspaceId,
  datasetId: DatasetId,
) {
  const mutate = useAuthenticatedMutatation(
    `${SHOEBILL_API_ROOT}/workspace/${workspaceId}/dataset/${datasetId}/manifest/validate_images`,
    "POST",
  );

  return (sampleSize: number) =>
    mutate({
      sample_size: sampleSize,
    });
}

// TODO(davidsharff): No status endpoint; devs must check server logs for process
// completion in the current local-only version.
/**
 * Kick-off the generation of the all_manifests file for the given dataset.
 *
 * The call back is not intended to be awaited as it could take a long time to complete.
 */
export function useCreateAllManifestsFile(
  workspaceId: WorkspaceId,
  datasetId: DatasetId,
) {
  const mutate = useAuthenticatedMutatation(
    `${SHOEBILL_API_ROOT}/workspace/${workspaceId}/dataset/${datasetId}/manifest/create`,
    "POST",
  );

  return (
    sourceUrls: string[],
    fileParser: string | null,
    cacheEnabled: boolean,
  ) =>
    mutate({
      gcs_urls: sourceUrls,
      file_parser_kind: fileParser,
      cache_enabled: cacheEnabled,
    });
}

/**
 * Kick off publish step from the all_manifests file that will create the plate-level manifiest,
 * the metadata.json, and sample_metadata.parquet.
 * */
export function usePublish(workspaceId: WorkspaceId, datasetId: DatasetId) {
  return useAuthenticatedMutatation<PublishPostBody>(
    `${SHOEBILL_API_ROOT}/workspace/${workspaceId}/dataset/${datasetId}/manifest/publish`,
    "POST",
  );
}

export function useUpdateManifestPlates(
  workspaceId: WorkspaceId,
  datasetId: DatasetId,
) {
  return useAuthenticatedMutatation<PlatePathMap[]>(
    `${SHOEBILL_API_ROOT}/workspace/${workspaceId}/dataset/${datasetId}/manifest/update_plates`,
    "POST",
    {
      "Content-Type": "application/octet-stream",
    },
  );
}

// TODO(davidsharff): revisit when we create sample_metadata. It is may be better to go ahead and
// always operate on the sample_metadata.parquet file.
/**
 * Send the uploaded client metadata csv file for the server to update/create
 * metadata.parquet (staging file for evetual creation of sample_metadata.parquet)
 * */
export function useUploadClientMetadata(
  workspaceId: WorkspaceId,
  datasetId: DatasetId,
) {
  return useAuthenticatedMutatation<Record<string, string>[]>(
    `${SHOEBILL_API_ROOT}/workspace/${workspaceId}/dataset/${datasetId}/client_metadata/upload`,
    "POST",
    {
      "Content-Type": "application/octet-stream",
    },
  );
}

export function useFetchClientMetadata(
  workspaceId: WorkspaceId,
  datasetId: DatasetId,
  columns?: string[],
) {
  const params = new URLSearchParams();
  if (columns) {
    for (const col of columns) {
      params.append("columns", col);
    }
  }

  return useAuthenticatedFetchableWithMutate<DB>(
    `${SHOEBILL_API_ROOT}/workspace/${workspaceId}/dataset/${datasetId}/client_metadata?${params}`,
    undefined,
    undefined,
    "blob",
    getBlobToDBConverter("client_metadata"),
  );
}

export function useFetchPalettes(
  workspaceId: WorkspaceId,
  datasetId: DatasetId,
) {
  return useAuthenticatedFetchable<PalettesResponse>(
    `${SHOEBILL_API_ROOT}/workspace/${workspaceId}/dataset/${datasetId}/palettes`,
    undefined,
    undefined,
  );
}

/**
 * Send an updated metadata row to the server to update metadata.parquet
 * */
export function useEditClientMetadata(
  workspaceId: WorkspaceId,
  datasetId: DatasetId,
) {
  const mutate = useAuthenticatedMutatation(
    `${SHOEBILL_API_ROOT}/workspace/${workspaceId}/dataset/${datasetId}/client_metadata/update`,
    "PATCH",
  );

  return (row: Row) =>
    mutate({
      updated_row: row,
    });
}

/**
 * Fetch plates with aggregated details from the all_manifests file.
 * */
export function useFetchManifestPlates(
  workspaceId: WorkspaceId,
  datasetId: DatasetId,
) {
  return useAuthenticatedFetchableWithMutate<DB>(
    `${SHOEBILL_API_ROOT}/workspace/${workspaceId}/dataset/${datasetId}/manifest/plates`,
    undefined,
    undefined,
    "blob",
    getBlobToDBConverter("manifest_plates"),
  );
}

/**
 * Fetch the errors in the all_manifests file.
 * */
export function useFetchManifestErrors(
  workspaceId: WorkspaceId,
  datasetId: DatasetId,
) {
  return useAuthenticatedFetchable<DB>(
    `${SHOEBILL_API_ROOT}/workspace/${workspaceId}/dataset/${datasetId}/manifest/errors`,
    undefined,
    undefined,
    "blob",
    getBlobToDBConverter("manifest_errors"),
  );
}

/**
 * Fetch the errors in the metadata file.
 * */
export function useFetchMetadataErrors(
  workspaceId: WorkspaceId,
  datasetId: DatasetId,
) {
  return useAuthenticatedFetchable<DB>(
    `${SHOEBILL_API_ROOT}/workspace/${workspaceId}/dataset/${datasetId}/client_metadata/errors`,
    undefined,
    undefined,
    "blob",
    getBlobToDBConverter("metadata_errors"),
  );
}

export function useRunCopyImagesAndCreateDataset(
  workspaceId: WorkspaceId,
  datasetId: DatasetId,
) {
  const mutate = useAuthenticatedMutatation(
    `${SHOEBILL_API_ROOT}/workspace/${workspaceId}/dataset/${datasetId}/pipeline/copy_images_and_create_dataset`,
    "POST",
  );

  return (sourceImageSize: number, imageSize: number, singleCellSize: number) =>
    mutate({
      source_image_size: sourceImageSize,
      image_size: imageSize,
      single_cell_size: singleCellSize,
    });
}

export function useSingleCellIngestionGraph(
  workspaceId: WorkspaceId,
  datasetId: DatasetId,
) {
  const mutate = useAuthenticatedMutatation(
    `${SHOEBILL_API_ROOT}/workspace/${workspaceId}/dataset/${datasetId}/pipeline/single_cell_ingestion_graph`,
    "POST",
  );

  return (
    sourceImageSize: number,
    singleCellSize: number,
    nuclearStainName: string,
  ) =>
    mutate({
      source_image_size: sourceImageSize,
      single_cell_size: singleCellSize,
      nuclear_stain_name: nuclearStainName,
    });
}

export function useFetchPipelineStatuses(
  workspaceId: string,
  datasetId: string,
) {
  return useAuthenticatedFetchableWithMutate<PipelineStatus[]>(
    `${SHOEBILL_API_ROOT}/workspace/${workspaceId}/dataset/${datasetId}/pipeline/statuses`,
    undefined,
    undefined,
  );
}

export function useFetchIngestionMetadata(
  workspaceId: string,
  datasetId: string,
) {
  return useAuthenticatedFetchableWithMutate<IngestionMetadata>(
    `${SHOEBILL_API_ROOT}/workspace/${workspaceId}/dataset/${datasetId}/pipeline/metadata`,
    undefined,
    undefined,
  );
}

export function useEditIngestionMetadata(
  workspaceId: string,
  datasetId: string,
) {
  const mutate = useAuthenticatedMutatation(
    `${SHOEBILL_API_ROOT}/workspace/${workspaceId}/dataset/${datasetId}/pipeline/metadata/update`,
    "PATCH",
  );

  return (patch: EditIngestionMetadataBody) => mutate(patch);
}

/**
 * Returns a function to make an authenticated mutation request (POST, PATCH, PUT, or DELETE).
 * */
function useAuthenticatedMutatation<T>(
  url: string,
  method: "POST" | "PATCH" | "PUT" | "DELETE" = "POST",
  headers: Record<string, string> = { "Content-Type": "application/json" },
) {
  const accessToken = useAccessToken();

  return (bodyData: T | null) =>
    fetchJson(url, accessToken, {
      method,
      headers,
      body:
        headers["Content-Type"] === "application/octet-stream"
          ? gzipJsonForPost(bodyData)
          : JSON.stringify(bodyData),
    });
}
