import * as ContextMenu from "@radix-ui/react-context-menu";
import chroma from "chroma-js";
import cx from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BarChart, HelpCircle, Image, MoreVertical } from "react-feather";
import { Link, useLocation } from "react-router-dom";
import { Button } from "src/Common/Button";
import Spinner from "src/Common/Spinner";
import { Operator } from "src/Control/FilterSelector/operations/filter-by";
import {
  createFilterForType,
  createFilterSet,
} from "src/Control/FilterSelector/utils";
import { getViewImagesUrlForGroups } from "src/ImageViewerNew/utils";
import { SortBy } from "src/MegaMap/Controls/SortBySelector/types";
import { useActiveWorkspaceId } from "src/Workspace/hooks";
import { useDatasetApi } from "src/hooks/api";
import { ApiResponse, handleAborted } from "src/util/api-client";
import { LinkMenuItem, Menu } from "@spring/ui/Menu";
import { Tooltip } from "@spring/ui/Tooltip";
import { Caption, Label } from "@spring/ui/typography";
import { FilterSqlClause } from "../../../Control/FilterSelector/types";
import { cssGradient } from "../../../util/colors";
import { normalizeSimilarityScores } from "../../../util/dataset-util";
import { MetadataColors, UmapRow } from "../../types";
import { Similarities } from "../types";
import { LabMateLogo } from "./LabMateLogo";

interface Props {
  className?: string;
  idColumn: string;
  comparisonValue: string | undefined;
  referenceValue: string;
  metadataColors: MetadataColors;
  filterSerialized?: FilterSqlClause;
  umapData?: UmapRow[];
  plates: string[];
  features: string[];
  hoveredKey: string | null;
  onHover?: (key: string | null) => void;
  onToggle?: (key: string) => void;
  isLoading: boolean;
  onLoading: (loading: boolean) => void;
}

const NORMALIZED_MAX_VALUE = 1;
const NORMALIZED_MIN_VALUE = 0.1;

const SIMILARITY_WIDTH = 340;

const GRADIENT_WIDTH = 30;
const GRADIENT_HEIGHT = 320;

const LABEL_WIDTH = SIMILARITY_WIDTH - GRADIENT_WIDTH;
const LABEL_HEIGHT = 20;

const DOT_SIZE = 16;
const MINIMUM_DOT_SIZE = 4;
const ELLIPSIS_HEIGHT = LABEL_HEIGHT;

const MAX_SIMILAR_HIGH_COUNT = 5;
const MAX_SIMILAR_LOW_COUNT = 5;

// Returns the pixel position for a value from 0.0 - 1.0,
// which is being represented by a block
function pxForValue(value: number, height: number): number {
  return (GRADIENT_HEIGHT - height) * (1 - value);
}

function median<T>(list: T[], map: (value: T) => number): number {
  if (list.length % 2 === 0) {
    // even number of elements
    const rightIndex = list.length / 2;
    const leftIndex = rightIndex - 1;
    return (map(list[leftIndex]) + map(list[rightIndex])) / 2;
  } else {
    const centerIndex = (list.length - 1) / 2;
    return map(list[centerIndex]);
  }
}

interface SimilarityEntry {
  name: string;
  value: number;
  label?: string;
}

function SimilarityLabel({
  name,
  top,
  highlight,
  muted,
  value,
  label,
  color,
  hovered,
  onHover,
  onToggle,
}: {
  name: string;
  top: number;
  highlight: boolean;
  muted: boolean;
  value: number;
  label?: string;
  color: string;
  hovered: boolean;
  onHover?: (key: string | null) => void;
  onToggle?: (key: string) => void;
}) {
  const backgroundColor = highlight
    ? chroma(color).alpha(0.2).css()
    : undefined;

  return (
    <div
      key={name}
      className={cx(
        "tw-w-full tw-ml-1",
        "tw-absolute tw-text-sm",
        "tw-flex tw-items-center tw-justify-between tw-gap-2",
      )}
      role="button"
      style={{
        height: LABEL_HEIGHT,
        width: LABEL_WIDTH,
        top,
        backgroundColor,
      }}
      onMouseEnter={useCallback(() => {
        onHover?.(name);
      }, [name, onHover])}
      onMouseLeave={useCallback(() => {
        onHover?.(null);
      }, [onHover])}
      onClick={useCallback(() => {
        onToggle?.(name);
      }, [name, onToggle])}
    >
      <div
        className={cx(
          "tw-flex tw-items-center tw-truncate",
          "tw-gap-1",
          "tw-z-10",
          !highlight && "tw-bg-slate-50",
        )}
      >
        <div
          className="tw-flex tw-items-center tw-justify-center"
          style={{ width: DOT_SIZE, height: DOT_SIZE }}
        >
          <div
            className="tw-inline-block tw-border tw-rounded-full"
            style={{
              width: MINIMUM_DOT_SIZE + (DOT_SIZE - MINIMUM_DOT_SIZE) * value,
              height: MINIMUM_DOT_SIZE + (DOT_SIZE - MINIMUM_DOT_SIZE) * value,
              backgroundColor: color,
            }}
          ></div>
        </div>
        <div
          className={cx(
            "tw-truncate",
            "hover:tw-underline",
            highlight || !muted ? "tw-text-gray-700" : "tw-text-gray-400",
            highlight && "tw-font-bold",
            hovered && "tw-underline",
          )}
          title={name}
        >
          {name}
        </div>
      </div>
      {label && (
        <div
          className={cx(
            "tw-p-0.5 tw-z-10 tw-whitespace-nowrap tw-font-mono tw-text-xs",
            highlight ? "tw-text-gray-600" : "tw-text-gray-400 tw-bg-slate-50",
          )}
        >
          {label}
        </div>
      )}
    </div>
  );
}

function SimilarityValue({
  className,
  name,
  hovered,
  onHover,
  onToggle,
}: {
  className?: string;
  name: string;
  hovered: boolean;
  onHover?: (key: string | null) => void;
  onToggle?: (key: string) => void;
}) {
  return (
    <span
      className={cx("hover:tw-underline", hovered && "tw-underline", className)}
      role={onToggle ? "button" : undefined}
      onMouseEnter={useCallback(() => {
        onHover?.(name);
      }, [name, onHover])}
      onMouseLeave={useCallback(() => {
        onHover?.(null);
      }, [onHover])}
      onClick={useCallback(() => {
        onToggle?.(name);
      }, [name, onToggle])}
    >
      {name}
    </span>
  );
}

function SimilaritySpectrumPlot({
  selectedColumn,
  referenceValue,
  comparisonValue,
  unmutedValues,
  values,
  hoveredValue,
  metadataColors,
  onHover,
  onToggle,
}: {
  selectedColumn: string;
  referenceValue: string;
  comparisonValue: string | undefined;
  unmutedValues: string[];
  values: SimilarityEntry[];
  hoveredValue: string | null;
  metadataColors: MetadataColors;
  onHover?: (key: string | null) => void;
  onToggle?: (key: string) => void;
}) {
  const location = useLocation();
  const includeAll =
    values.length <= MAX_SIMILAR_HIGH_COUNT + MAX_SIMILAR_LOW_COUNT;
  const truncated = !includeAll && !comparisonValue;

  const leftValues = useMemo(() => {
    if (comparisonValue) {
      const comparisonValues: SimilarityEntry[] = [];

      const indexOfComparison = values.findIndex(
        (entry) => entry.name === comparisonValue,
      );

      if (indexOfComparison === -1) {
        return [];
      } else {
        comparisonValues.push({
          ...values[indexOfComparison],
          label: "Selected",
        });

        if (indexOfComparison >= 1) {
          if (indexOfComparison >= 2) {
            // We also have room to add the left neighbor
            comparisonValues.unshift({
              ...values[indexOfComparison - 1],
              label: "Neighbor",
            });
          }
          comparisonValues.unshift({
            ...values[0],
            label: "Most Similar",
          });
        }

        if (indexOfComparison < values.length - 1) {
          if (indexOfComparison < values.length - 2) {
            comparisonValues.push({
              ...values[indexOfComparison + 1],
              label: "Neighbor",
            });
          }
          comparisonValues.push({
            ...values[values.length - 1],
            label: "Least Similar",
          });
        }
      }

      return comparisonValues;
    }
    return includeAll ? values : values.slice(0, MAX_SIMILAR_HIGH_COUNT);
  }, [comparisonValue, includeAll, values]);

  const rightValues = useMemo(() => {
    return comparisonValue || includeAll
      ? []
      : values.slice(-MAX_SIMILAR_LOW_COUNT);
  }, [comparisonValue, includeAll, values]);

  const allValues = useMemo(() => {
    const ellipsis: SimilarityEntry[] = truncated
      ? [
          {
            name: "",
            label: "...",
            value:
              (leftValues[leftValues.length - 1].value + rightValues[0].value) /
              2,
          },
        ]
      : [];

    type ValueWithPosition = SimilarityEntry & { height: number; top: number };

    const valuesWithPosition: ValueWithPosition[] = [
      // Add the reference value to the top
      {
        name: referenceValue,
        value: 1,
      },
      ...leftValues,
      ...ellipsis,
      ...rightValues,
    ].map((value) => ({
      ...value,
      height: (value.label === "..." ? ELLIPSIS_HEIGHT : LABEL_HEIGHT) + 2,
      top: pxForValue(value.value, LABEL_HEIGHT),
    }));

    // Max sure the entries aren't placed too closely to each other
    let groups: {
      center: number;
      height: number;
      values: ValueWithPosition[];
    }[] = valuesWithPosition.map((entry) => ({
      height: entry.height,
      center: entry.top + entry.height / 2,
      values: [entry],
    }));

    let changed = true;
    while (changed) {
      changed = false;

      for (let i = 1; i < groups.length; i++) {
        const group = groups[i];
        const prev = groups[i - 1];

        if (group.height / 2 + prev.height / 2 > group.center - prev.center) {
          // The two groups overlap, combine them
          const newHeight = group.height + prev.height;

          if (newHeight > GRADIENT_HEIGHT) {
            // We're too wide for the area available, so just use all the area we have
            group.height = GRADIENT_HEIGHT;
            group.center = GRADIENT_HEIGHT / 2;
          } else {
            group.height = newHeight;

            // Find a new center by doing a weighted average of the centers of the
            // groups being combined
            const newCenter =
              (group.center * group.values.length +
                prev.center * prev.values.length) /
              (group.values.length + prev.values.length);

            group.center =
              // Can't extend past the top side
              Math.max(
                newHeight / 2,
                // Can't extend past the bottom side
                Math.min(GRADIENT_HEIGHT - newHeight / 2, newCenter),
              );
          }

          group.values = [...prev.values, ...group.values];
          prev.values = [];

          changed = true;
        }
      }

      groups = groups.filter((group) => group.values.length > 0);
    }

    return groups.flatMap((group) => {
      const ratio =
        group.values.reduce((sum, value) => sum + value.height, 0) /
        group.height;

      let top = group.center - group.height / 2;
      group.values.forEach((value) => {
        value.top = top;
        top += value.height * ratio;
      });

      return group.values;
    });
  }, [referenceValue, leftValues, rightValues, truncated]);

  const medianPx = useMemo(() => {
    return pxForValue(
      median(values, ({ value }) => value),
      1,
    );
  }, [values]);

  const medianLabelPosition =
    medianPx > GRADIENT_HEIGHT / 2 ? "above" : "below";

  // TODO(trisorus): Should we apply the global filter here as well? We don't in other places
  // that we link out to the image viewer.
  const getComparisonGroupsForImageViewer = (
    compareToValue: string | number,
  ) => {
    return [
      {
        id: "reference",
        filterSet: createFilterSet(
          [createFilterForType(selectedColumn, referenceValue)],
          Operator.AND,
        ),
      },
      {
        id: "compareTo",
        filterSet: createFilterSet(
          [createFilterForType(selectedColumn, compareToValue)],
          Operator.AND,
        ),
      },
    ];
  };

  return (
    <div className="tw-flex tw-flex-row tw-w-full">
      <div
        className={cx(
          "tw-flex tw-flex-col tw-items-center tw-justify-center",
          "tw-text-xs tw-text-white",
          "tw-relative",
        )}
        style={{
          background: cssGradient(
            [
              chroma(metadataColors[referenceValue]).rgb(),
              chroma("#fff").rgb(),
            ],
            "180deg",
          ),
          height: GRADIENT_HEIGHT,
          width: GRADIENT_WIDTH,
        }}
      >
        <div className="tw-text-sm tw-font-bold tw-drop-shadow">
          {NORMALIZED_MAX_VALUE.toFixed(1)}
        </div>
        <div className="tw-flex-1"></div>
        <div className="tw-text-sm tw-font-bold tw-text-gray-400 tw-drop-shadow-sm">
          {NORMALIZED_MIN_VALUE.toFixed(1)}
        </div>
        <div
          className={cx(
            "tw-absolute tw-w-full tw-border-dashed tw-left-0 tw-drop-shadow",
            "tw-text-center tw-font-mono",
            medianLabelPosition === "below"
              ? "tw-border-t tw-border-white tw-text-white"
              : "tw-border-b tw-border-gray-300 tw-text-gray-400",
          )}
          style={
            medianLabelPosition === "below"
              ? { top: medianPx }
              : { bottom: GRADIENT_HEIGHT - medianPx }
          }
        >
          Mdn
        </div>
      </div>
      <div className="tw-relative tw-flex-1">
        {allValues.map(({ name, value, label, top }) =>
          label === "..." ? (
            <div
              key="..."
              className="tw-absolute tw-flex tw-flex-row tw-items-center tw-ml-1"
              style={{
                height: LABEL_HEIGHT,
                width: LABEL_WIDTH,
                top,
              }}
            >
              <div
                className="tw-flex tw-items-center tw-justify-center tw-text-gray-400"
                style={{ width: DOT_SIZE, height: ELLIPSIS_HEIGHT }}
              >
                <MoreVertical />
              </div>
            </div>
          ) : (
            <ContextMenu.Root key={name}>
              <ContextMenu.Trigger>
                <SimilarityLabel
                  name={name}
                  value={value}
                  top={top}
                  highlight={
                    name === referenceValue || name === comparisonValue
                  }
                  muted={!unmutedValues.includes(name)}
                  hovered={name === hoveredValue}
                  color={metadataColors[name]}
                  onHover={onHover}
                  onToggle={onToggle}
                  label={label}
                />
              </ContextMenu.Trigger>

              <ContextMenu.Portal>
                <ContextMenu.Content asChild>
                  <Menu>
                    <ContextMenu.Item asChild>
                      <LinkMenuItem
                        icon={Image}
                        href={getViewImagesUrlForGroups(
                          location.pathname,
                          getComparisonGroupsForImageViewer(name),
                        )}
                      >
                        View Images
                      </LinkMenuItem>
                    </ContextMenu.Item>
                  </Menu>
                </ContextMenu.Content>
              </ContextMenu.Portal>
            </ContextMenu.Root>
          ),
        )}
        <div
          className={cx(
            "tw-absolute tw-w-full tw-border-dashed tw-border-slate-300 tw-left-0",
            medianLabelPosition === "above" ? "tw-border-b" : "tw-border-t",
          )}
          style={
            medianLabelPosition === "below"
              ? { top: medianPx }
              : { bottom: GRADIENT_HEIGHT - medianPx }
          }
        />
      </div>
    </div>
  );
}

function ColorDotLabel({
  children,
  color,
}: {
  children: React.ReactNode;
  color: string;
}) {
  return (
    <div className="tw-inline-flex tw-items-baseline tw-space-x-1">
      <div className="tw-flex tw-items-center tw-justify-center">
        <div
          className="tw-w-2.5 tw-h-2.5 tw-rounded-full"
          style={{
            backgroundColor: color,
          }}
        ></div>
      </div>
      <div>{children}</div>
    </div>
  );
}

export function LabMateSimilarity({
  filterSerialized,
  umapData,
  className,
  idColumn,
  comparisonValue,
  referenceValue,
  metadataColors,
  plates,
  features,
  hoveredKey,
  onHover,
  onToggle,
  isLoading,
  onLoading,
}: Props) {
  const workspaceId = useActiveWorkspaceId();
  const [similarities, setSimilarities] = useState<Similarities | null>(null);
  const [failed, setFailed] = useState(false);

  const location = useLocation();

  const api = useDatasetApi();

  useEffect(() => {
    if (!umapData) {
      return;
    }

    setSimilarities(null);
    setFailed(false);
    onLoading(true);
    let distanceMetric = "euclidean";
    // change the value of reference_value if workspaceId is Gilead
    if (workspaceId === "gilead") {
      distanceMetric = "cosine";
    }

    let latestRequest: ApiResponse | null = null;

    const update = async () => {
      latestRequest?.abort();

      latestRequest = api.route("similarity").post({
        sql_filter: filterSerialized,
        id_column: idColumn,
        reference_value: referenceValue,
        distance_metric: distanceMetric,
        umap_result: umapData,
        features,
        plates,
      });

      await latestRequest
        .json<Similarities>()
        .then(setSimilarities)
        .catch(handleAborted)
        .catch(() => {
          // The API request failed (possibly because there weren't enough other values to
          // compare to)
          setFailed(true);
        });

      onLoading(false);
    };

    update();

    return () => {
      latestRequest?.abort();
    };
  }, [
    api,
    features,
    filterSerialized,
    idColumn,
    onLoading,
    plates,
    referenceValue,
    umapData,
    workspaceId,
  ]);

  useEffect(() => () => onLoading(false), [onLoading]);

  const normalized = useMemo(
    () =>
      // The similarity response includes a number for the referenceValue
      // We kept it in the list so it would be a candidate for the max computation,
      // but we don't actually want to display it so we can remove it now
      normalizeSimilarityScores(similarities)?.filter(
        ({ name }) => name !== referenceValue,
      ) ?? null,
    [similarities, referenceValue],
  );

  const comparisonSimilarityDescription = useMemo(() => {
    if (comparisonValue && normalized) {
      const entry = normalized.find((entry) => entry.name === comparisonValue);
      if (entry) {
        const pct = entry.value;

        if (pct > 0.9) {
          return "very similar";
        } else if (pct > 0.7) {
          return "moderately similar";
        } else if (pct > 0.5) {
          return "somewhat similar";
        } else if (pct > 0.3) {
          return "somewhat dissimilar";
        } else if (pct > 0.1) {
          return "moderately dissimilar";
        } else {
          return "very dissimilar";
        }
      }
    }

    return null;
  }, [comparisonValue, normalized]);

  const similarityValue = useCallback(
    (name: string, className?: string) => {
      return (
        <ColorDotLabel color={metadataColors[name]}>
          <SimilarityValue
            className={className}
            name={name}
            hovered={name === hoveredKey}
            onHover={onHover}
            onToggle={onToggle}
          />
        </ColorDotLabel>
      );
    },
    [hoveredKey, metadataColors, onHover, onToggle],
  );

  // Keep this consistent with the summary text below; it's used in the spectrum plot to make sure
  // values in the summary are not muted
  const valuesInSummary: string[] = useMemo(() => {
    if (comparisonValue && comparisonSimilarityDescription) {
      return [comparisonValue];
    }

    if (!comparisonValue && normalized) {
      return normalized.length >= 3
        ? [
            normalized[0].name,
            normalized[1].name,
            normalized[normalized.length - 1].name,
            normalized[normalized.length - 2].name,
          ]
        : [normalized[0].name, normalized[normalized.length - 1].name];
    }

    return [];
  }, [comparisonValue, comparisonSimilarityDescription, normalized]);

  const megamapPath = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);

    // Switch to the ranking tab
    searchParams.set("tab", "ranking");

    // Set the relevant columns for MegaMap
    searchParams.set("treatmentColumn", idColumn);
    searchParams.set("similarTo", referenceValue);
    searchParams.delete("sortBy");
    searchParams.append(
      "sortBy",
      JSON.stringify({
        column: { id: referenceValue, source: "similarity" },
        ascending: false,
      } as SortBy),
    );

    // Let MegaMap decide the controlValue using smart defaults
    searchParams.delete("controlValue");

    return `${location.pathname}?${searchParams.toString()}`;
  }, [idColumn, referenceValue, location]);

  return (
    <div className={cx("tw-flex tw-flex-col tw-gap-2", className)}>
      <div className="tw-flex tw-items-baseline tw-justify-between">
        <div className="tw-h-[50px] tw-flex tw-items-baseline">
          <LabMateLogo animate={isLoading ? "always" : "off"} />

          <Tooltip
            className="tw-w-[400px]"
            contents={
              comparisonValue ? (
                <div>
                  We are showing the similarity between{" "}
                  <span className="tw-font-semibold">{referenceValue}</span> and{" "}
                  <span className="tw-font-semibold">{comparisonValue}</span>.
                  We are also showing the most and least similar values, and
                  neighboring values for reference.
                </div>
              ) : (
                <div>
                  We are showing the similarity between{" "}
                  <span className="tw-font-semibold">{referenceValue}</span> and
                  others
                  {Object.keys(metadataColors).length >
                  MAX_SIMILAR_HIGH_COUNT + MAX_SIMILAR_LOW_COUNT
                    ? ", displaying only the highest and lowest ends"
                    : ""}
                  .
                </div>
              )
            }
            side={"right"}
          >
            <HelpCircle className="tw-text-slate-500" size={16} />
          </Tooltip>
        </div>

        <Link to={megamapPath}>
          <Button size="sm" icon={BarChart} variant="primary" borderless={true}>
            View ranking
          </Button>
        </Link>
      </div>

      {comparisonValue && comparisonSimilarityDescription && (
        <div className="tw-my-4">
          <Caption className="tw-inline" weight="bold">
            {similarityValue(referenceValue)}
          </Caption>
          <Caption className="tw-inline">{` is ${comparisonSimilarityDescription} to `}</Caption>
          <Caption className="tw-inline" weight="bold">
            {similarityValue(comparisonValue)}
          </Caption>
        </div>
      )}
      {!comparisonValue && normalized && (
        <div className="tw-my-4 tw-space-y-4">
          <div>
            <Label className="tw-text-slate-500">Comparing to</Label>
            <Caption weight="bold">{similarityValue(referenceValue)}</Caption>
          </div>
          <div>
            <Label className="tw-text-slate-500">Most similar</Label>
            <Caption className="tw-inline">
              {similarityValue(normalized[0].name)}
            </Caption>
            {normalized.length >= 3 && (
              <>
                {", "}
                <Caption className="tw-inline">
                  {similarityValue(normalized[1].name)}
                </Caption>
              </>
            )}
          </div>
          <div>
            <Label className="tw-text-slate-500">Least similar</Label>
            <Caption className="tw-inline">
              {similarityValue(normalized[normalized.length - 1].name)}
            </Caption>
            {normalized.length >= 4 && (
              <>
                {", "}
                <Caption className="tw-inline">
                  {similarityValue(normalized[normalized.length - 2].name)}
                </Caption>
              </>
            )}
          </div>
        </div>
      )}
      <div>
        {failed ? (
          <div className="tw-mt-2 tw-text-slate-500">
            Not enough other values to compute similarities
          </div>
        ) : !normalized ? (
          <div
            className={cx(
              "tw-h-[64px]",
              "tw-flex tw-items-center tw-justify-center",
            )}
          >
            {/* The spring loader we use elsewhere currently has a white background*/}
            <Spinner />
          </div>
        ) : (
          <SimilaritySpectrumPlot
            selectedColumn={idColumn}
            referenceValue={referenceValue}
            comparisonValue={comparisonValue}
            unmutedValues={valuesInSummary}
            values={normalized}
            hoveredValue={hoveredKey}
            metadataColors={metadataColors}
            onHover={onHover}
            onToggle={onToggle}
          />
        )}
      </div>
    </div>
  );
}
