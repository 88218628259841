import { useCallback } from "react";

type Point = [number, number];

// Math from https://stackoverflow.com/a/2049593
function sign(p1: Point, p2: Point, p3: Point) {
  return (p1[0] - p3[0]) * (p2[1] - p3[1]) - (p2[0] - p3[0]) * (p1[1] - p3[1]);
}

// Math from https://stackoverflow.com/a/2049593
function pointInTriangle(
  point: Point,
  corner1: Point,
  corner2: Point,
  corner3: Point,
): boolean {
  const d1 = sign(point, corner1, corner2);
  const d2 = sign(point, corner2, corner3);
  const d3 = sign(point, corner3, corner1);

  const hasNeg = d1 < 0 || d2 < 0 || d3 < 0;
  const hasPos = d1 > 0 || d2 > 0 || d3 > 0;

  return !(hasNeg && hasPos);
}

/**
 * Create a mousemove handler that considers elements to be hovered over if it
 * doesn't appear that the user is trying to move the mouse towards something to the
 * right of the list of items
 *
 * The concept is outlined here:
 * https://bjk5.com/post/44698559168/breaking-down-amazons-mega-dropdown
 *
 * @param options - Configuration options
 * @param options.containerRef - A reference to the container holding the list of things
 * being hovered over.  We expect there's something immediately to the right of the
 * container that users might move the mouse towards
 * @param options.onHover - A callback called when we've determined that the user is
 * trying to hover over the element
 * @returns A mousemove handler
 */
export function useHoverDetector({
  containerRef,
  onHover,
}: {
  containerRef: React.MutableRefObject<HTMLElement | null>;
  onHover: () => void;
}): React.MouseEventHandler {
  return useCallback<React.MouseEventHandler>(
    ({ movementX, movementY, clientX, clientY }) => {
      if (
        // We stopped moving the mouse
        (movementX === 0 && movementY === 0) ||
        // We don't have a parent container
        !containerRef.current
      ) {
        onHover();
        return;
      }

      const { left, top, width, height } =
        containerRef.current.getBoundingClientRect();

      const x = clientX - left;
      const y = clientY - top;
      const prevX = x - movementX;
      const prevY = y - movementY;

      // If our current position is inside the triangle formed by the previous
      // position and the NE and SE corners of the container, we're probably trying
      // to get to the details.  If we're moving outside the triangle then we're trying
      // to hover over this element
      if (
        !pointInTriangle([x, y], [prevX, prevY], [width, 0], [width, height])
      ) {
        onHover();
        return;
      }
    },
    [containerRef, onHover],
  );
}
