import { useCallback, useContext, useMemo, useRef } from "react";
import type { AccessToken } from "src/Auth0/accessToken";
import useSWR, { SWRResponse } from "swr";
import { KeyedMutator, SWRConfiguration } from "swr/dist/types";
import { FetchError } from "@spring/core/fetch";
import { Failure, Fetchable, Success } from "@spring/core/result";
import { recordedFetch } from "../util/recordedFetch";
import { TraceInfo, TracingContext } from "../util/tracing";
import { useAccessToken } from "./auth0";

export async function sprFetch(
  url: string,
  accessToken: AccessToken,
  requestInit?: RequestInit,
) {
  const response = await recordedFetch(url, {
    ...requestInit,
    headers: {
      ...(requestInit?.headers ?? {}),
      Authorization: accessToken.header(),
    },
  });

  if (!response.ok) {
    if (response.headers.get("content-type") === "application/json") {
      const errorObj: { Error: string } = await response.json();
      throw new FetchError(response.status, errorObj["Error"]);
    } else {
      const errorText = await response.text();
      throw new FetchError(response.status, errorText);
    }
  }

  return response;
}

export async function fetchJson(
  url: string,
  accessToken: AccessToken,
  requestInit?: RequestInit,
) {
  const response = await sprFetch(url, accessToken, requestInit);
  return await response.json();
}

export async function fetchBlob(
  url: string,
  accessToken: AccessToken,
  requestInit?: RequestInit,
) {
  const response = await sprFetch(url, accessToken, requestInit);
  return await response.blob();
}

/**
 * Merge tracing headers into the requestInit object as applicable.
 */
export function mergeTraceInfo(
  requestInit: RequestInit | undefined,
  traceInfo: TraceInfo | null,
): RequestInit {
  return {
    ...requestInit,
    headers: {
      ...requestInit?.headers,
      ...(traceInfo
        ? {
            "X-Spring-Trace-Id": traceInfo.id,
            "X-Spring-Trace-Url": traceInfo.clientURL,
          }
        : {}),
    },
  };
}

export function useAuthenticatedFetch<T>(
  url: string | null,
  requestInit?: RequestInit,
  configuration?: SWRConfiguration<T>,
  fetchKind?: "json" | "blob",
  transform?: (raw: any, response: Response) => Promise<T> | T,
): SWRResponse<T> {
  const traceInfo = useContext(TracingContext);
  // NOTE(colin): we use a ref here because it has a stable object identity.
  // Thus, when we capture it in the fetcher closure below, we can still access
  // the current value later. We cannot include the trace info in the deps of
  // the useCallback or the key of useSWR because that will change rendering
  // behavior, and the point is to instrument the render without affecting it.
  const traceInfoRef = useRef<TraceInfo | null>(traceInfo);
  traceInfoRef.current = traceInfo;
  const fetcher = useCallback(
    async (
      url: string,
      accessToken: AccessToken,
      method: string | undefined,
      body: string | undefined,
    ) => {
      const requestInitMod = {
        ...mergeTraceInfo(requestInit, traceInfoRef.current),
        ...(method ? { method: method } : null),
        ...(body ? { body: body } : null),
      };
      const response = await sprFetch(url, accessToken, requestInitMod);
      const result =
        (fetchKind ?? "json") === "json"
          ? await response.json()
          : await response.blob();
      if (transform != null) {
        return await transform(result, response);
      }
      return result;
    },
    [transform, fetchKind, requestInit],
  );

  const accessToken = useAccessToken();
  return useSWR<T>(
    url ? [url, accessToken, requestInit?.method, requestInit?.body] : null,
    fetcher,
    configuration,
  );
}

export function useAuthenticatedFetchable<T>(
  url: string | null,
  requestInit?: RequestInit,
  configuration?: SWRConfiguration<T>,
  fetchKind?: "json" | "blob",
  transform?: (raw: any, response: Response) => Promise<T>,
): Fetchable<T> {
  const { data, error } = useAuthenticatedFetch<T>(
    url,
    requestInit,
    configuration,
    fetchKind,
    transform,
  );

  // This is memoized because the Failure.of and Success.of constructors return
  // a new object each time, so fetchables can't otherwise be used in effects
  // dependencies lists without constantly retriggering the effect.
  const value = useMemo(
    () =>
      (error
        ? Failure.of(error)
        : data
          ? Success.of(data)
          : undefined) as Fetchable<T>,
    [error, data],
  );
  return value;
}

/**
 * Like {@link useAuthenticatedFetchable}, but also returns a function for refreshing.
 *
 * Sometimes it's useful to imperatively force a refresh on certain events (e.g.
 * a user action). This helps facilitate that by delegating to SWR's bound mutate()
 * function.
 */
export function useAuthenticatedFetchableWithMutate<T>(
  url: string | null,
  requestInit?: RequestInit,
  configuration?: SWRConfiguration<T>,
  fetchKind?: "json" | "blob",
  transform?: (raw: any, response: Response) => Promise<T> | T,
): [Fetchable<T>, KeyedMutator<T>] {
  const { data, error, mutate } = useAuthenticatedFetch<T>(
    url,
    requestInit,
    configuration,
    fetchKind,
    transform,
  );

  // This is memoized because the Failure.of and Success.of constructors return
  // a new object each time, so fetchables can't otherwise be used in effects
  // dependencies lists without constantly retriggering the effect.
  const value = useMemo(
    () =>
      (error
        ? Failure.of(error)
        : data
          ? Success.of(data)
          : undefined) as Fetchable<T>,
    [error, data],
  );
  return [value, mutate];
}
