import * as Popover from "@radix-ui/react-popover";
import cx from "classnames";
import React, { JSXElementConstructor, ReactElement } from "react";
import { Link } from "react-router-dom";

type BaseProps = {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  triggerElement: ReactElement;
  sections: ReactElement[];
  align: "start" | "center" | "end";
};

// PopoverTrigger must use Popover.Trigger and include the trigger element as children.
type Props =
  | BaseProps
  | (Omit<BaseProps, "triggerElement"> & { PopoverTrigger: ReactElement });
export default function DropDownMenu(props: Props) {
  const { open, onOpen, onClose, sections, align } = props;
  const triggerElement =
    "triggerElement" in props ? props.triggerElement : null;
  const PopoverTrigger =
    "PopoverTrigger" in props ? props.PopoverTrigger : null;

  return (
    <Popover.Root
      open={open}
      onOpenChange={(isOpening) => {
        if (isOpening) {
          onOpen();
        } else {
          onClose();
        }
      }}
    >
      {PopoverTrigger || (
        <Popover.Trigger
          className={"tw-flex tw-flex-row tw-items-center tw-h-full tw-px-4"}
        >
          {triggerElement}
        </Popover.Trigger>
      )}
      <Popover.Content align={align}>
        <div className={"tw-border tw-bg-white"}>
          {sections.flatMap((s, i) =>
            i !== 0
              ? [<hr className={"tw-m-0"} key={`separator-${i}`} />, s]
              : s,
          )}
        </div>
      </Popover.Content>
    </Popover.Root>
  );
}

function makeDropDownElement<
  T extends JSXElementConstructor<any> | keyof JSX.IntrinsicElements,
>(
  type: string | JSXElementConstructor<any>,
  displayName: string,
  config: { interactive?: boolean; extraClasses?: string },
) {
  const component = ({
    className,
    ...props
  }: React.ComponentProps<T> & { className?: string }) =>
    React.createElement(type, {
      className: cx(
        "tw-p-4 tw-block tw-w-full",
        config.interactive && "hover:tw-bg-purple-100",
        config.extraClasses,
        className,
      ),
      ...props,
    });
  component.displayName = displayName;
  return component;
}

export const DropDownMenuButton = makeDropDownElement(
  "button",
  "DropDownMenuButton",
  { interactive: true, extraClasses: "tw-text-left" },
);

export const DropDownMenuExternalLink = makeDropDownElement(
  "a",
  "DropDownMenuExternalLink",
  { interactive: true },
);

export const DropDownMenuLink = makeDropDownElement(Link, "DropDownMenuLink", {
  interactive: true,
});

export const DropDownText = makeDropDownElement("div", "DropDownText", {
  interactive: false,
});
