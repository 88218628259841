import { useCallback, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  TrackingContext,
  useEventTrackingContext,
} from "src/Common/EventTracker/Context";
import { TrackAppLocationContext } from "src/TrackAppLocation/TrackAppLocation";
import { AppLocation } from "src/TrackAppLocation/types";
import { EventDefinition, trackEvent } from "../analytics";

export function useTrackPageview(appLocation: AppLocation | undefined) {
  const location = useLocation();

  useTrackPageViewDurations(appLocation);

  useEffect(() => {
    const { pathname, search, hash } = location;
    trackEvent({ id: "Page view", pathname, search, hash });
    // We only want this effect to run once, when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

// Tracks the amount of time a user is looking at a particular area of the app
//
// Note that this doesn't attempt to track whether the current tab is visible or if
// the user is actively using it
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function useTrackPageViewDurations(
  newAppLocation: AppLocation | undefined,
) {
  const tracker = useContext(TrackAppLocationContext);
  if (newAppLocation) {
    tracker(newAppLocation);
  }
}

// Provides a track() method that fires an event with all tracking context provided
// Optionally, additional context can be provided when the hook is called
// Duplicate keys passed in this way will overwrite any parent context's
export function useEventTracker(context: TrackingContext = {}) {
  const trackingContext = useEventTrackingContext();
  const track = useCallback(
    (event: EventDefinition) => {
      trackEvent({
        ...trackingContext,
        ...context,
        ...event,
      });
    },
    [context, trackingContext],
  );

  return track;
}
