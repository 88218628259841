import "rc-slider/assets/index.css";
import { useCallback, useMemo, useRef } from "react";
import { ZoomIn, ZoomOut } from "react-feather";
import { Slider } from "@spring/ui/Slider";
import { Tooltip } from "@spring/ui/Tooltip";
import Strut from "../Strut";

const TOOLTIP_TEXT =
  "The center value is the zoom level the model uses when training.";

export default function CropSizeSlider({
  size,
  defaultSize,
  onChange,
}: {
  size: number;
  defaultSize?: number;
  onChange: (size: number) => void;
}) {
  //TODO(trisorus): Hack to support webapp_frontend; in general, we should require defaultSize
  const initialCropSize = useRef(size);
  const defaultCropSize = useMemo(
    () => defaultSize ?? initialCropSize.current,
    [defaultSize],
  );

  const [minCropSize, maxCropSize] = useMemo(() => {
    return [
      Math.round(defaultCropSize * 0.5),
      Math.round(defaultCropSize * 1.5),
    ];
  }, [defaultCropSize]);

  const handleReset = useCallback(() => {
    onChange(defaultCropSize);
  }, [onChange, defaultCropSize]);

  return (
    <div className={"tw-flex tw-justify-center tw-items-center"}>
      <Tooltip className="tw-w-64" side="bottom" contents={TOOLTIP_TEXT}>
        <ZoomOut className="tw-text-slate-500" size={18} />
      </Tooltip>
      <Strut size={16} />
      <Slider
        min={minCropSize}
        max={maxCropSize}
        inverted={true}
        value={size}
        onValueChange={onChange}
        onClickCenterResetButton={handleReset}
      />
      <Strut size={16} />
      <Tooltip className="tw-w-64" side="bottom" contents={TOOLTIP_TEXT}>
        <ZoomIn className="tw-text-slate-500" size={18} />
      </Tooltip>
    </div>
  );
}
