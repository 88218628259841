import cx from "classnames";
import * as d3 from "d3";
import * as React from "react";

function _MetadataLegend({
  colorScale,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  colorScale: d3.ScaleOrdinal<string, string, string>;
}) {
  return (
    <div {...props}>
      <div
        className={cx(
          "tw-flex tw-flex-col tw-overflow-y-auto",
          "tw-h-1/2 tw-w-full tw-py-lg tw-px-md",
        )}
      >
        {colorScale.domain().map((value) => (
          <div
            key={value}
            className={cx("tw-flex tw-gap-sm tw-items-center")}
            title={value}
          >
            <div
              className="tw-flex-none tw-h-3 tw-w-3 tw-rounded-sm"
              style={{ backgroundColor: colorScale(value) }}
            />
            <div className="tw-truncate">{value}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
export const MetadataLegend = React.memo(_MetadataLegend);
