import { useCallback, useEffect, useRef, useState } from "react";

interface QueueEntry<T = unknown> {
  id: number;
  task: () => Promise<T>;
  state: "idle" | "running" | "complete";
  callback: (value: T) => void;
}

export function usePromiseQueue({
  onEmpty,
  waitMs,
}: {
  onEmpty: () => void;
  waitMs: number;
}) {
  const nextIdRef = useRef(0);
  const timeoutRef = useRef<number | undefined>();

  const [queue, setQueue] = useState<QueueEntry[]>([]);

  useEffect(() => {
    const currentTask = queue.at(0);

    if (currentTask && currentTask.state === "idle") {
      const activeTaskId = currentTask.id;

      setQueue((current) =>
        current.map((entry) =>
          entry.id === activeTaskId ? { ...entry, state: "running" } : entry,
        ),
      );

      currentTask
        .task()
        .catch((ex) => {
          console.error(ex);
        })
        .then((result) => {
          setQueue((current) => {
            const newQueue = current.filter(
              (entry) => entry.id !== activeTaskId,
            );
            if (newQueue.length === 0) {
              window.clearTimeout(timeoutRef.current);
              timeoutRef.current = window.setTimeout(onEmpty, waitMs);
            }
            return newQueue;
          });

          currentTask.callback(result);
        });
    }
  }, [onEmpty, queue, waitMs]);

  const addToQueue = useCallback(<T>(task: () => Promise<T>) => {
    return new Promise<T>((resolve) => {
      const entry: QueueEntry<T> = {
        id: nextIdRef.current++,
        task,
        state: "idle",
        callback: resolve,
      };

      setQueue((current) => [...current, entry as QueueEntry<unknown>]);
    });
  }, []);

  return {
    busy: queue.length > 0,
    addToQueue,
  };
}
