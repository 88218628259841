import mixpanel, { Dict, RequestOptions } from "mixpanel-browser";
import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useEventTrackingContext } from "src/Common/EventTracker/Context";
import { ANALYTICS_ENABLED, waitForAnalytics } from "src/analytics";
import { DatasetId, WorkspaceId } from "src/types";
import { AppLocation } from "./types";

const VIEWED_PAGE_EVENT = "Viewed page";
const VIEWED_SUB_PAGE_EVENT = "Viewed sub page";

type Tracker = (appLocation: AppLocation) => void;
type EnrichedAppLocation = AppLocation & {
  workspace: WorkspaceId;
  dataset?: DatasetId;
};

export const TrackAppLocationContext = createContext<Tracker>(() => {});

function isSameAppLocation(
  a: EnrichedAppLocation,
  b: EnrichedAppLocation,
  { checkSubPage }: { checkSubPage: boolean },
) {
  return (
    a.id === b.id &&
    a.workspace === b.workspace &&
    a.dataset === b.dataset &&
    (!checkSubPage || a.subPage === b.subPage)
  );
}

const TRACKS_EMOJI = "\ud83d\udc63";

function track(event: string, properties: Dict, options?: RequestOptions) {
  if (ANALYTICS_ENABLED) {
    waitForAnalytics().then(() => mixpanel.track(event, properties, options));
  } else {
    // eslint-disable-next-line no-console
    console.log(`${TRACKS_EMOJI} Sending: ${event}`, properties);
  }
}

function time_event(event: string) {
  if (ANALYTICS_ENABLED) {
    waitForAnalytics().then(() => mixpanel.time_event(event));
  } else {
    // eslint-disable-next-line no-console
    console.log(`${TRACKS_EMOJI} Starting timer for: ${event}`);
  }
}

// Provides context for the TrackedRoute and useTrackPageview / useTrackPageViewDurations
// to send information about how long a page in the app was viewed for
export function TrackAppLocation({ children }: { children: ReactNode }) {
  const { workspace, dataset } = useEventTrackingContext();
  const unsentAppLocationRef = useRef<EnrichedAppLocation | null>(null);
  const unsentAppSubLocationRef = useRef<EnrichedAppLocation | null>(null);

  const sendAppLocation = useCallback(
    (transport: "xhr" | "sendBeacon" = "xhr") => {
      const unsentAppLocation = unsentAppLocationRef.current;
      if (unsentAppLocation) {
        track(VIEWED_PAGE_EVENT, unsentAppLocation, { transport });

        unsentAppLocationRef.current = null;
      }
    },
    [],
  );

  const sendAppSubLocation = useCallback(
    (transport: "xhr" | "sendBeacon" = "xhr") => {
      const unsentAppSubLocation = unsentAppSubLocationRef.current;
      const subPage = unsentAppSubLocation?.subPage;
      if (unsentAppSubLocation && subPage !== undefined) {
        track(VIEWED_SUB_PAGE_EVENT, unsentAppSubLocation, { transport });

        unsentAppSubLocationRef.current = null;
      }
    },
    [],
  );

  // If we're unloading the page, end any active durations
  useEffect(() => {
    // sendBeacon transport is more likely to actually be sent while the page is being
    // unloaded
    // NOTE(danlec): It's possible that the user will cancel the unload, and we'll have
    // recorded the end of the page view too early
    window.addEventListener("beforeunload", () => {
      sendAppSubLocation("sendBeacon");
      sendAppLocation("sendBeacon");
    });
  }, [sendAppLocation, sendAppSubLocation]);

  const tracker = useCallback(
    (appLocation: AppLocation) => {
      // JoinPublicWorkspace has workspace information provided directly by appLocation
      const eventWorkspace = workspace ?? appLocation.workspace;

      // Initial load – wait until we have this information to log the first event
      if (!eventWorkspace) {
        return;
      }

      const newAppLocation = {
        ...appLocation,
        workspace: eventWorkspace,
        dataset,
      };
      const unsentAppLocation = unsentAppLocationRef.current;

      // Update our top level tracking
      if (
        !unsentAppLocation ||
        !isSameAppLocation(newAppLocation, unsentAppLocation, {
          checkSubPage: false,
        })
      ) {
        // Report that we're done viewing the last page
        sendAppLocation();

        // Start timing the view of the page
        time_event(VIEWED_PAGE_EVENT);

        unsentAppLocationRef.current = newAppLocation;
      }

      // Update our subPage level tracking
      const unsentAppSubLocation = unsentAppSubLocationRef.current;

      if (
        !unsentAppSubLocation ||
        !isSameAppLocation(newAppLocation, unsentAppSubLocation, {
          checkSubPage: true,
        })
      ) {
        // Report that we're done viewing the last sub-page
        sendAppSubLocation();

        const subPage = newAppLocation.subPage;

        if (subPage !== undefined) {
          time_event(VIEWED_SUB_PAGE_EVENT);

          unsentAppSubLocationRef.current = newAppLocation;
        } else {
          unsentAppSubLocationRef.current = null;
        }
      }
    },
    [sendAppLocation, sendAppSubLocation, workspace, dataset],
  );

  return (
    <TrackAppLocationContext.Provider value={tracker}>
      {children}
    </TrackAppLocationContext.Provider>
  );
}
