// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function setIntersection<T>(a: Set<T>, b: Set<T>): Set<T> {
  const result: Set<T> = new Set();
  for (const value of a) {
    if (b.has(value)) {
      result.add(value);
    }
  }
  return result;
}

export function setUnion<T>(a: Set<T>, b: Set<T>): Set<T> {
  return new Set([...a, ...b]);
}

export function elementsInEverySet<T>(sets: Set<T>[]): Set<T> {
  if (sets.length === 0) {
    return new Set();
  }

  return sets.reduce(setIntersection);
}
