import { AsyncDuckDB } from "@duckdb/duckdb-wasm";
import * as Sentry from "@sentry/react";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FullScreenLoader } from "src/Common/FullScreenLoader";
import { TypedColumn } from "src/Control/FilterSelector/backend-types";
import { Failure, Fetchable } from "@spring/core/result";
import { FullScreenContainer } from "../Common/FullScreenContainer";
import { Operator } from "../Control/FilterSelector/operations/filter-by";
import { Filter, FilterSet } from "../Control/FilterSelector/types";
import {
  EMPTY_FILTER_SET,
  deserializeFromUrlParam,
  serializeToSqlClause,
  serializeToUrlParam,
  useMetadataFilterColumns,
  validateFilterFetchable,
} from "../Control/FilterSelector/utils";
import { useFeatureFlag } from "../Workspace/feature-flags";
import { useDataset, useDatasetSampleMetadataDB } from "../hooks/datasets";
import { useDatasetTimepoints } from "../hooks/immunofluorescence";
import { useTypedQueryParams } from "../routing";
import {
  DatasetId,
  PragmaQueryRecord,
  UntypedWellSampleMetadataRow,
} from "../types";
import {
  CachedResult,
  sql,
  useCacheableQueryAsRecords,
  useQueryAsRecords,
} from "../util/sql";
import { useFeatureFilteringParams } from "./hooks";
import { FeatureFilter, FeatureSetManagementContext } from "./types";

const Context = createContext<
  Record<string, never> | FeatureSetManagementContext
>({});

export function useFeatureSetManagementContext():
  | Record<string, never>
  | FeatureSetManagementContext {
  return useContext(Context);
}

function useDatasetSampleMetadataDBWithDefaultAggregation({
  dataset,
}: {
  dataset: DatasetId;
}): Fetchable<AsyncDuckDB> {
  const datasetListing = useDataset({ dataset });
  const timepoints = useDatasetTimepoints(
    datasetListing?.successful && datasetListing.value?.type === "histology"
      ? { skip: true }
      : { dataset },
  );
  const hasPendingRequestsForAggregationInfo = !datasetListing && !timepoints;

  let groupBy: "well" | null;
  if (datasetListing?.successful && datasetListing.value?.type == "histology") {
    groupBy = null;
  } else if (timepoints?.successful && timepoints.value.length > 1) {
    groupBy = null;
  } else {
    groupBy = "well";
  }

  return useDatasetSampleMetadataDB({
    skip: hasPendingRequestsForAggregationInfo,
    dataset,
    groupBy,
  });
}

/**
 * Context wrapper for the root FeatureSetManagementPage.
 */
export function FeatureSetManagementContextProvider({
  dataset,
  children,
}: {
  dataset: DatasetId;
  children: ReactNode;
}) {
  const metadataDB = useDatasetSampleMetadataDBWithDefaultAggregation({
    dataset,
  });
  const filterColumns = useMetadataFilterColumns(dataset, metadataDB, null);

  const [queryParams, setQueryParams] = useTypedQueryParams({
    serializedFilter: (value) => value,
  });

  // Track ignored filters for local display only (don't waste url space or persist when sharing)
  const [ignoredFilters, setIgnoredFilters] = useState<Filter[]>([]);

  const filter: FilterSet = useMemo(() => {
    if (queryParams.serializedFilter && filterColumns?.successful) {
      const filter = deserializeFromUrlParam(
        queryParams.serializedFilter,
        filterColumns.value,
      );
      if (filter) {
        return {
          ...filter,
          ignoredFilters,
        };
      }
      console.error(
        `Could not deserialize filter param ${queryParams.serializedFilter}`,
      );
    }
    return EMPTY_FILTER_SET;
  }, [queryParams.serializedFilter, filterColumns, ignoredFilters]);

  const handleSetFilter = useCallback(
    (filter: FilterSet) => {
      setQueryParams({
        ...queryParams,
        serializedFilter: serializeToUrlParam(filter),
      });
      // Only "AND" filters can be ignored (i.e. result in 0 rows)
      setIgnoredFilters(
        filter.operator === Operator.AND ? filter.ignoredFilters : [],
      );
    },
    [queryParams, setQueryParams],
  );

  const validateAndSetFilter = useCallback(
    (filter: FilterSet) => {
      validateFilterFetchable(metadataDB, filter).then(handleSetFilter);
    },
    [handleSetFilter, metadataDB],
  );

  const featureFilteringEnabled = useFeatureFlag("feature-filtering-enabled");
  const availableFetchedFeatureFilters = useFeatureFilteringParams({ dataset });
  const availableFeatureFilters = useMemo(
    () =>
      featureFilteringEnabled
        ? availableFetchedFeatureFilters?.orElse(() => ({})) ?? {}
        : {},
    [featureFilteringEnabled, availableFetchedFeatureFilters],
  );

  const [featureFilter, validateAndSetFeatureFilter] =
    useState<FeatureFilter | null>(null);

  const [onDownload, _setOnDownload] = useState<(() => Promise<void>) | null>(
    null,
  );

  // Abstract away the special-casing of React's useState when a function is passed in.
  const setOnDownload = useCallback((fn: (() => Promise<void>) | null) => {
    _setOnDownload(() => fn);
  }, []);

  const filterSerialized = useMemo(
    () => serializeToSqlClause(filter),
    [filter],
  );
  const filteredMetadata = useCacheableQueryAsRecords(
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    metadataDB?.successful ? metadataDB?.value : null,
    sql`SELECT * FROM sample_metadata WHERE ${filterSerialized}`,
  ) as CachedResult<Fetchable<UntypedWellSampleMetadataRow[]>>;

  // TODO(davidsharff): consider adding a useTableSchema hook that consolidates the following two operations.
  const metadataSchemaResult = useQueryAsRecords(
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    metadataDB?.successful ? metadataDB?.value : null,
    sql`PRAGMA table_info('sample_metadata')`,
  );
  const metadataSchema = metadataSchemaResult?.successful
    ? (metadataSchemaResult.value as PragmaQueryRecord[]).reduce(
        (acc, record) => ({ ...acc, [record.name]: record.type }),
        {} as { [columnName: string]: string },
      )
    : null;

  // Note: some complexities ahead!
  // useCaceableQueryAsRecords can emit "stale" results since it's designed to not emit
  // loading states in between quick user interactions that might affect the data
  // filter. However, the filter itself might be used in child modules to query other
  // data (like the feature values themselves). Child components could get into trouble
  // if the metadata and the data using the new filter doesn't match up. So to take
  // some care to keep the metadata and the filter consistent, we keep track of the
  // filter that was used for the actual instance of the (potentially cached) metadata
  // we have, and ensure to only emit that.
  const lastAppliedFilter = useRef<string>(filterSerialized);
  useEffect(() => {
    if (
      filterSerialized != lastAppliedFilter.current &&
      !filteredMetadata.cached
    ) {
      lastAppliedFilter.current = filterSerialized;
    }
  }, [filterSerialized, filteredMetadata.cached]);

  const result = useMemo(():
    | { state: "loading" }
    | { state: "load-failed"; errors: Error[] }
    | { state: "ready"; value: FeatureSetManagementContext } => {
    if (!metadataDB || !filteredMetadata.result || !filterColumns) {
      return { state: "loading" };
    }

    if (
      !metadataDB.successful ||
      !filteredMetadata.result.successful ||
      !filterColumns.successful
    ) {
      const errors: Error[] = [
        metadataDB,
        filteredMetadata.result,
        filterColumns,
      ]
        .filter(
          (
            value,
          ): value is
            | Failure<AsyncDuckDB>
            | Failure<TypedColumn[]>
            | Failure<UntypedWellSampleMetadataRow[]> => !value.successful,
        )
        .map((value) => value.error);

      return { state: "load-failed", errors };
    }

    return {
      state: "ready",
      value: {
        metadataDB: metadataDB.value,
        metadataSchema,
        filter,
        filterColumns: filterColumns.value,
        validateAndSetFilter,
        filterSerialized: filteredMetadata.cached
          ? lastAppliedFilter.current
          : filterSerialized,
        filteredMetadata: filteredMetadata.result.value,
        availableFeatureFilters,
        featureFilter,
        validateAndSetFeatureFilter,
        onDownload,
        setOnDownload,
      },
    };
  }, [
    filter,
    filterColumns,
    filterSerialized,
    filteredMetadata.cached,
    filteredMetadata.result,
    metadataDB,
    metadataSchema,
    onDownload,
    setOnDownload,
    validateAndSetFilter,
    availableFeatureFilters,
    featureFilter,
    validateAndSetFeatureFilter,
  ]);

  if (result.state === "loading") {
    return <FullScreenLoader />;
  }

  if (result.state === "load-failed") {
    result.errors.forEach((error) => Sentry.captureException(error));

    return (
      <FullScreenContainer center>
        <div className={"tw-text-red-error"}>
          Oops. Something went wrong loading your data. Please refresh and try
          again.
        </div>
      </FullScreenContainer>
    );
  }

  return <Context.Provider value={result.value}>{children}</Context.Provider>;
}
