import { Color } from "src/imaging/types";

/**
 * Returns the Multichannelview props needed to render a mask overlay of an individual cell within a crop.
 *
 * WARNING: Assumes the center pixel of a crop will container the target mask id. The assumption simplifies the process
 * and is less resource intensive by letting us fetch cropped mask files. However, it also breaks down if the center of
 * the nuclei isn't within the cell mask area, or multiple masks were used to generate the cell.
 *
 * Revisit if/when we upgrade to dynamic crop sizes.
 */
export function getCellMaskPropsForCrop(
  cropSize: number,
  maskColor: Color = {
    r: 0,
    g: 0,
    b: 0,
    a: 0.9,
  },
): {
  showMasks: true;
  maskOptions: {
    subset: "cells";
    maskColor: Color;
    targetMaskCoordinates: {
      row: number;
      col: number;
    };
    invertBackground: true;
  };
} {
  return {
    showMasks: true,
    maskOptions: {
      subset: "cells",
      maskColor,
      targetMaskCoordinates: {
        row: Math.floor(cropSize / 2),
        col: Math.floor(cropSize / 2),
      },
      invertBackground: true,
    },
  };
}
