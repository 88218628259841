import { AsyncDuckDB } from "@duckdb/duckdb-wasm";
import { ReactNode } from "react";
import { FilterSqlClause } from "../Control/FilterSelector/types";
import { queryDBAsRecords, sql } from "../util/sql";

type FeatureType = {
  prefix: string;
  // Function from specific featureName to presentation title.
  title: (featureName: string) => ReactNode;
  // Function from specific featureName to presentation description.
  description: (featureName: string) => ReactNode;
  // Function from specific featureName to relevant stain.
  stain: (featureName: string) => string | null;
};

// Types of features that should be included in quality control reporting, along
// with logic for formatting specific features for presentation.
// (The specific features will often be suffixed with a stain name.)
// The full list of features measured by CellProfiler's MeasureImageQuality
// module (along with extended descriptions) can be found here:
//  http://cellprofiler-manual.s3.amazonaws.com/CellProfiler-3.0.0/modules/measurement.html#measureimagequality
const FEATURES: FeatureType[] = [
  {
    prefix: "Count_Nuclei",
    title: () => "Nuclei Count",
    description: () => "Number of nuclei (cells) identified.",
    stain: () => null,
  },
  {
    prefix: "ImageQuality_MeanIntensity",
    // Convert, e.g., "ImageQuality_MeanIntensity_Hoechst" to
    // "Mean Intensity [Hoechst]".
    title: (featureName) =>
      `Mean Intensity [${featureName.substring(
        "ImageQuality_MeanIntensity".length + 1,
      )}]`,
    description: () => "Mean of pixel intensity values.",
    stain: (featureName) =>
      featureName.substring("ImageQuality_MeanIntensity".length + 1),
  },
  {
    prefix: "ImageQuality_PowerLogLogSlope",
    title: (featureName) =>
      `PowerLogLogSlope [${featureName.substring(
        "ImageQuality_PowerLogLogSlope".length + 1,
      )}]`,
    description: () => (
      <span>
        A measure of the blur of the image. Measures the slope of the image
        log-log power spectrum.
        <br />
        <br />A larger magnitude slope (more negative) indicates more lower
        frequency components, and hence more blur.
      </span>
    ),
    stain: (featureName) =>
      featureName.substring("ImageQuality_PowerLogLogSlope".length + 1),
  },
  {
    prefix: "ImageQuality_FocusScore",
    title: (featureName) =>
      `Focus Score [${featureName.substring(
        "ImageQuality_FocusScore".length + 1,
      )}]`,
    description: () => (
      <span>
        A measure of the intensity variance across the image.
        <br />
        <br />
        Higher focus scores correspond to lower blurriness. Performs well for
        distinguishing extremely blurry images.
      </span>
    ),
    stain: (featureName) =>
      featureName.substring("ImageQuality_FocusScore".length + 1),
  },
  {
    prefix: "ImageQuality_Correlation",
    title: (featureName) =>
      `Correlation [${featureName.substring(
        "ImageQuality_Correlation".length + 1,
        featureName.lastIndexOf("_"),
      )}]`,
    description: () => (
      <span>
        A measure of the correlation of the image for a given spatial scale.
        <br />
        <br />
        If an image is blurred, the correlation between neighboring pixels
        becomes high, producing a high correlation value.
      </span>
    ),
    stain: (featureName) =>
      featureName.substring(
        "ImageQuality_Correlation".length + 1,
        featureName.lastIndexOf("_"),
      ),
  },
  {
    prefix: "ImageQuality_MADIntensity",
    title: (featureName) =>
      `Mean Average Deviation [${featureName.substring(
        "ImageQuality_MADIntensity".length + 1,
      )}]`,
    description: () => (
      <span>
        Median absolute deviation of pixel intensity values. A measure of the
        amount of variance in the image.
        <br />
        <br />
        The magnitude of the values is less important. The goal is to have an
        even distribution across the plate.
      </span>
    ),
    stain: (featureName) =>
      featureName.substring("ImageQuality_MADIntensity".length + 1),
  },
  {
    prefix: "ImageQuality_MaxIntensity",
    title: (featureName) =>
      `Maximum Intensity [${featureName.substring(
        "ImageQuality_MaxIntensity".length + 1,
      )}]`,
    description: () => "Maximum of pixel intensity values.",
    stain: (featureName) =>
      featureName.substring("ImageQuality_MaxIntensity".length + 1),
  },
  {
    prefix: "Correlation_Correlation",
    title: (featureName) =>
      `Cross-correlation: ${featureName.substring(
        "Correlation_Correlation_".length,
      )}`,
    description: () =>
      "Two-channel cross correlation for measuring bleed through.",
    stain: () => "Not a single stain measurement",
  },
  {
    prefix: "Bleed_through",
    title: (featureName) => featureName.split("_").join(" "),
    description: () => (
      <span>
        Bleed through between two channels (measured as the cross-correlation
        between the channels minus the cross-correlation with one of the
        channels rotated.)
        <br />
        <br />
        As a rule of thumb, values larger than 0.1 typically indicate
        bleed-through that's visible to the eye and may be concerning.
      </span>
    ),
    stain: () => "Not a single stain measurement",
  },
  {
    prefix: "Nuclear-to-Non-nuclear",
    title: (featureName) => featureName.split("_").join(" "),
    description: () => (
      <span>
        Nuclear to non-nuclear ratio of staining between channels (using the
        median intensity).
        <br />
        This can function as a very basic measure of signal to noise for nuclear
        stains.
      </span>
    ),
    stain: (featureName) =>
      featureName.slice(
        "Nuclear-to-Non-nuclear_Intensity_MedianIntensity_".length,
      ),
  },
];

/**
 * Find the {@code FeatureType} for a given feature name.
 *
 * @param featureName - Name of a specific feature (e.g., "Count_Nuclei" or
 *                      "ImageQuality_MaxIntensity_Hoechst").
 * @returns {FeatureType} - the higher-level FeatureType (e.g., "Count_Nuclei"
 *                          or "ImageQuality_MaxIntensity"), if that feature
 *                          maps to a valid type.
 */
const findFeatureType = (featureName: string): FeatureType | null => {
  for (const featureType of FEATURES) {
    if (featureName.startsWith(featureType.prefix)) {
      return featureType;
    }
  }
  return null;
};

/**
 * Get the {@code FeatureType} for a given feature name.
 *
 * @param featureName - Name of a specific feature (e.g., "Count_Nuclei" or
 *                      "ImageQuality_MaxIntensity_Hoechst").
 * @returns {FeatureType} - the higher-level FeatureType (e.g., "Count_Nuclei"
 *                          or "ImageQuality_MaxIntensity"). Raises if the
 *                          feature does not map to a valid type.
 */
export const getFeatureType = (featureName: string): FeatureType => {
  const featureType = findFeatureType(featureName);
  if (featureType == null) {
    throw Error(`Unable to find FeatureType for ${featureName}`);
  }

  return featureType;
};

/**
 * Sort two features by importance based on their respective types.
 */
export const featureImportanceComparator = (x: string, y: string) => {
  const xFeatureType = findFeatureType(x);
  if (xFeatureType == null) {
    throw Error(`Invalid feature passed to comparator: ${x}`);
  }

  const yFeatureType = findFeatureType(y);
  if (yFeatureType == null) {
    throw Error(`Invalid feature passed to comparator: ${y}`);
  }

  return FEATURES.indexOf(xFeatureType) - FEATURES.indexOf(yFeatureType);
};

/**
 * @param featureName - Name of the feature to test.
 * @returns {boolean} - {@code true} if the feature in question should be
 * included for quality control testing.
 */
export const isProdQualityControlFeature = (featureName: string): boolean => {
  return !!findFeatureType(featureName);
};

/**
 * A comparator used to sort plate names within an experiment.
 *
 * Attempts to order by plate index such that, e.g., `plate-12-confocal-1`
 * comes after `plate-1-confocal-1`, then by replicate index such that, e.g.,
 * `plate-1-confocal-2` comes after `plate-1-confocal-1`.
 */
export const sortPlates = (x: string, y: string): number => {
  const xParts = x.split("-");
  const yParts = y.split("-");

  if (xParts[0] !== "plate" || xParts.length < 4) {
    return -1;
  } else if (yParts[0] !== "plate" || yParts.length < 4) {
    return 1;
  }

  const xIndex = parseInt(xParts[1]);
  if (isNaN(xIndex)) {
    return -1;
  }
  const yIndex = parseInt(yParts[1]);
  if (isNaN(yIndex)) {
    return 1;
  }
  if (xIndex > yIndex) {
    return 1;
  } else if (xIndex < yIndex) {
    return -1;
  }

  const xType = xParts[2];
  const yType = yParts[2];
  if (!["widefield", "confocal"].includes(xType)) {
    return -1;
  }
  if (!["widefield", "confocal"].includes(yType)) {
    return 1;
  }
  if (xType > yType) {
    return 1;
  } else if (xType < yType) {
    return -1;
  }

  const xReplicateIndex = parseInt(xParts[3]);
  if (isNaN(xReplicateIndex)) {
    return -1;
  }
  const yReplicateIndex = parseInt(yParts[3]);
  if (isNaN(yReplicateIndex)) {
    return 1;
  }
  if (xReplicateIndex > yReplicateIndex) {
    return 1;
  } else if (xReplicateIndex < yReplicateIndex) {
    return -1;
  }

  if (xParts.length > yParts.length) {
    return 1;
  } else if (xParts.length < yParts.length) {
    return -1;
  }

  return 0;
};

type Size = "sm" | "md" | "lg";

// Size parameters for rendering heatmap components
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const SIZES: {
  [K in Size]: { title: string; value: number };
} = {
  sm: {
    title: "Small",
    value: 16,
  },
  md: {
    title: "Medium",
    value: 32,
  },
  lg: {
    title: "Large",
    value: 64,
  },
};

export async function queryWells(
  metadata: AsyncDuckDB,
  prefilter: FilterSqlClause,
): Promise<string[]> {
  const wells = await queryDBAsRecords<{ well: string }>(
    metadata,
    sql`SELECT DISTINCT well
        FROM sample_metadata
        WHERE (${prefilter})`,
  );
  return wells.map((well) => well.well);
}
