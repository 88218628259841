import classNames from "classnames";
import { ReactNode } from "react";

export default function LeftNavSectionTitle({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <div className={classNames("tw-uppercase tw-text-purple", className)}>
      {children}
    </div>
  );
}
