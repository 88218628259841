import { STATISTICAL_CORRECTIONS, STATISTICAL_TESTS } from "./constants";
import { StatisticalCorrectionType, StatisticalTestType } from "./types";

export type SignificanceResult =
  | {
      type: "not-significant";
      pValue: number;
    }
  | {
      type: "significant";
      pValue: number;
      threshold: number;
    };

export function calculateSignificance(pvalue: number): SignificanceResult {
  for (const threshold of [0.0001, 0.001, 0.01, 0.05]) {
    if (pvalue < threshold) {
      return { type: "significant", pValue: pvalue, threshold: threshold };
    }
  }
  return { type: "not-significant", pValue: pvalue };
}

export function significanceStars(result: SignificanceResult): string {
  if (result.type === "not-significant") {
    return " ";
  } else {
    switch (result.threshold) {
      case 0.0001:
        return "****";
      case 0.001:
        return "***";
      case 0.01:
        return "**";
      case 0.05:
        return "*";
      default:
        throw new Error(`Unexpected threshold: ${result.threshold}`);
    }
  }
}

export function getStatisticalCorrectionOrDefault(
  type: StatisticalCorrectionType,
) {
  const defaultRecord = STATISTICAL_CORRECTIONS[0];
  const statCorrection = STATISTICAL_CORRECTIONS.find((o) => o.type === type);
  if (!statCorrection) {
    console.error(
      `Invalid statistical correction type: ${type}. Returning default.`,
    );
    return defaultRecord;
  }

  return statCorrection;
}

export function getStatisticalTestOrDefault(type: StatisticalTestType) {
  const defaultRecord = STATISTICAL_TESTS[0];
  const statTest = STATISTICAL_TESTS.find((o) => o.type === type);
  if (!statTest) {
    console.error(
      `Invalid statistical correction type: ${type}. Returning default.`,
    );
    return defaultRecord;
  }

  return statTest;
}
