import { useCallback, useEffect, useRef, useState } from "react";
import { Failure, Fetchable, Result, Success } from "./result";

export class FetchError extends Error {
  status: number;

  constructor(status: number, ...params: any) {
    super(...params);
    this.name = "FetchError";
    this.status = status;
  }

  toString(): string {
    return `${this.name}(status=${this.status}, message=${this.message})`;
  }
}

export function isFetchError(error: Error): error is FetchError {
  return typeof error === "object" && error.name === "FetchError";
}

// TODO(davidsharff): it would be tighter to use the Fetchable logic where there is always a value if it is not loading and it succeeded.
/**
 * Generic hook for fetching typed data from a URL.
 *
 * Returns: a tuple of [loading, data], indicating whether the fetch is still
 * ongoing, and the data of type T, respectively. Data will be null if it
 * hasn't yet loaded.
 *
 * Prefer {@code useFetchable} instead.
 */
export const useFetch = <T>(
  url: string | null,
  requestInit?: RequestInit,
): [boolean, T | null, FetchError | null, () => void] => {
  // Ref to track the URL that to which the current state is attached.
  const currentUrl = useRef(url);
  const [loading, setLoading] = useState(true);
  const [isRefetching, setIsRefetching] = useState(false);
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<FetchError | null>(null);

  useEffect(() => {
    currentUrl.current = url;
    if (url === null) {
      setLoading(false);
      setError(null);
      setData(null);
      return;
    }
    if (!isRefetching && data) {
      return;
    }

    setError(null);

    if (!isRefetching) {
      setLoading(true);
      setData(null);
    }

    const abortController = new AbortController();
    fetchData(url, abortController, requestInit);
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, requestInit?.method, isRefetching]);

  const fetchData = useCallback(
    (
      url: string,
      abortController?: AbortController,
      requestInit?: RequestInit,
    ) =>
      fetch(url, {
        ...requestInit,
        // TODO(charlie): Omit these when fetching via access token (as opposed to session cookie).
        mode: "cors",
        credentials: "include",
        signal: abortController?.signal,
      })
        .then((resp) => {
          if (resp.ok) {
            return resp;
          } else {
            return resp.json().then((errorObj: { Error: string }) => {
              throw new FetchError(resp.status, errorObj["Error"]);
            });
          }
        })
        .then((resp) => resp.json())
        .then(setData)
        .then(() => {
          setLoading(false);
          setIsRefetching(false);
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            return;
          } else if (error instanceof FetchError) {
            setLoading(false);
            setIsRefetching(false);
            setError(error);
            setData(null);
          } else {
            throw error;
          }
        }),
    [],
  );

  // TODO(davidsharff): one day we would want to make this awaitable.
  const refetch = useCallback(() => {
    setIsRefetching(true);
  }, []);

  // TODO(davidsharff): loading is still true the first render when data is returned.
  return currentUrl.current === url
    ? [loading, data, error, refetch]
    : [false, null, null, refetch];
};

/**
 * Like useFetch, but returns a Fetchable<T>.
 */
export const useFetchable = <T>(
  url: string | null,
  requestInit?: RequestInit,
): Fetchable<T> => {
  // Ref to track the URL that to which the current state is attached.
  const currentUrl = useRef(url);
  const [result, setResult] = useState<Result<T> | undefined>(undefined);
  useEffect(() => {
    currentUrl.current = url;
    setResult(undefined);
    if (url === null) {
      return;
    }
    const abortController = new AbortController();
    fetch(url, {
      ...requestInit,
      // TODO(charlie): Omit these when fetching via access token (as opposed to session cookie).
      mode: "cors",
      credentials: "include",
      signal: abortController.signal,
    })
      .then((resp) => {
        if (resp.ok) {
          return resp;
        } else {
          return resp.json().then((errorObj: { Error: string }) => {
            throw new FetchError(resp.status, errorObj["Error"]);
          });
        }
      })
      .then((resp) => resp.json())
      .then((resp) => setResult(Success.of(resp)))
      .catch((error) => {
        if (error.name === "AbortError") {
          return;
        } else if (error instanceof FetchError) {
          setResult(Failure.of(error));
        } else {
          throw error;
        }
      });
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, requestInit?.method]);
  return currentUrl.current === url ? result : undefined;
};
