/**
 * Component to render a 384-well plate (one field per well).
 */
import cx from "classnames";
import { useEffect, useMemo, useState } from "react";
import { Tooltip } from "@spring/ui/Tooltip";
import Loader, { Center } from "../Common/Loader";
import Strut from "../Common/Strut";
import {
  colorSchemeByWellMetadata,
  colorValuesByScheme,
} from "../Control/ColorSchemeSelector";
import DatasetColumnSelector from "../Control/DatasetColumnSelector";
import FieldSelector from "../Control/FieldSelector";
import ErrorMessage from "../Error/ErrorMessage";
import { useDatasetSampleMetadata } from "../hooks/datasets";
import { useWells } from "../hooks/immunofluorescence";
import {
  DatasetPlate,
  DatasetPlateWellField,
  Field,
  ImageSet,
  ProcessingTechnique,
} from "../imaging/types";
import { toNumericField } from "../imaging/util";
import MultiChannelView from "../immunofluorescence/MultiChannelView";
import {
  DatasetId,
  FieldSampleMetadata,
  MetadataColumnValue,
  UntypedWellSampleMetadataRow,
} from "../types";
import { inferInterestingColumns } from "../util/dataset-util";
import { normalizeDomain } from "../util/vega-util";
import PlateMap, {
  EmptyWellView,
  PlateKind,
  columnPosition,
  rowPosition,
} from "./PlateMap";
import { PlateMetadataLegendMap } from "./PlateMetadataLegend";
import { formatField } from "./WellGridView";

/**
 * Find an entry within an array of metadata.
 */
function findEntry<T extends FieldSampleMetadata>(
  metadata: T[],
  plate: string,
  well: string,
  field: Field,
): T | null {
  for (const sample of metadata) {
    if (
      sample.plate === plate &&
      sample.well === well &&
      sample.field === field
    ) {
      return sample;
    }
  }
  return null;
}

export function inferPlateKind(metadata: { well: string }[]): PlateKind {
  const wells = metadata.map(({ well }) => well);
  if (
    wells.map(rowPosition).some((value) => value > 7) ||
    wells.map(columnPosition).some((value) => value > 11)
  ) {
    return "384-well";
  } else {
    return "96-well";
  }
}

function OptionalFieldView({
  index,
  imageSet,
  metadata,
  size,
  onSelectWell,
}: {
  index: DatasetPlateWellField;
  imageSet: ImageSet | null;
  metadata: FieldSampleMetadata[];
  size: number;
  onSelectWell: (well: string) => void;
}) {
  const fullIndex = {
    ...index,
    t: 0,
    z: 0,
  };
  const match = findEntry(
    metadata,
    index.plate,
    index.well,
    formatField(index.field),
  );
  return match ? (
    <MultiChannelView
      index={fullIndex}
      imageSet={imageSet}
      crop={null}
      size={size}
      onClick={() => onSelectWell(index.well)}
      showMagnification
    />
  ) : (
    <EmptyWellView />
  );
}

/**
 * Component to render an image for each well in a plate.
 */
function PlateImageView({
  index,
  imageSet,
  field,
  metadata,
  size,
  onSelectWell,
}: {
  index: DatasetPlate;
  imageSet: ImageSet | null;
  field: Field;
  metadata: FieldSampleMetadata[];
  size: number;
  onSelectWell: (well: string) => void;
}) {
  return (
    <PlateMap size={size} plateKind={inferPlateKind(metadata)}>
      {(well) => (
        <OptionalFieldView
          key={well}
          index={{ ...index, well, field: toNumericField(field) }}
          imageSet={imageSet}
          metadata={metadata}
          size={size}
          onSelectWell={onSelectWell}
        />
      )}
    </PlateMap>
  );
}

function PlateImageViewWithFetch(props: {
  index: DatasetPlate;
  imageSet: ImageSet | null;
  field: Field;
  size: number;
  onSelectWell: (well: string) => void;
}) {
  const wells = useWells({
    dataset: props.index.dataset,
    acquisition: props.index.plate,
  });
  return (
    wells
      ?.map((unwrapped) => (
        // eslint-disable-next-line react/jsx-key
        <PlateImageView
          {...props}
          metadata={unwrapped.map((well: string) => ({
            type: "field",
            plate: props.index.plate,
            well,
            field: props.field,
          }))}
        />
      ))
      .orElse((e) => <ErrorMessage error={e} />) || null
  );
}

/**
 * Component to render a metadata heatmap in a plate representation.
 */
function PlateMetadataView({
  dataset,
  plate,
  primaryColumn,
  size,
  onSelectWell,
  onMetadataReady,
  filteredLegendValues,
}: {
  dataset: DatasetId;
  plate: string;
  // The column used to color the heat map, whose values are depicted in the legend.
  primaryColumn: string;
  size: number;
  onSelectWell: (well: string) => void;
  onMetadataReady: (data: PlateMetadataLegendMap | null) => void;
  filteredLegendValues: MetadataColumnValue[] | null;
}) {
  const metadata = useDatasetSampleMetadata({ dataset, plate });
  const metadataByWell: { [key: string]: UntypedWellSampleMetadataRow } =
    useMemo(() => {
      if (!metadata?.successful) {
        return {};
      }
      return (
        metadata
          // TODO(you): Fix this no-unnecessary-condition rule violation
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          ?.map((unwrapped) =>
            unwrapped.sampleMetadata.reduce<{
              [well: string]: UntypedWellSampleMetadataRow;
            }>((mapping, record) => {
              return {
                ...mapping,
                [record.well as string]: record,
              };
            }, {}),
          )
          .unwrap()
      );
      // "sampleMetadata" doesn't change; we just care if it goes from not-loaded to
      // loaded.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataset, plate, metadata?.successful]);

  const colorMap = useMemo(() => {
    const values = normalizeDomain(
      Object.values(metadataByWell).map((record) => record[primaryColumn]),
    );
    const colorScheme = colorSchemeByWellMetadata(values);
    return colorValuesByScheme(values, colorScheme);
  }, [metadataByWell, primaryColumn]);

  const tooltipColumns: string[] = useMemo(() => {
    if (!metadata?.successful) {
      return [];
    }

    return inferInterestingColumns(metadata.value.sampleMetadata)
      .filter((columnName) => columnName !== primaryColumn)
      .slice(0, 10);

    // "metadata" doesn't change; we just care if it goes from not-loaded to
    // loaded.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataset, plate, metadata?.successful, primaryColumn]);

  // HACK(benkomalo): because this component is the one that renders metadata, but
  // parent components render the _legend_ for the metadata, we have to signal up
  // when the metadata is cleared/ready. Eventually, we can/should replace this
  // with a store or context.
  useEffect(() => {
    onMetadataReady({
      column: primaryColumn,
      legend: colorMap,
    });
  }, [colorMap, onMetadataReady, primaryColumn]);

  if (!metadata) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }
  if (!metadata.successful) {
    return (
      <Center>
        <ErrorMessage error={metadata.error} />
      </Center>
    );
  }

  // TODO(benkomalo): restore CSV download?
  return (
    <PlateMap
      size={size}
      plateKind={inferPlateKind(metadata.value.sampleMetadata)}
    >
      {(well) => {
        // TODO(you): Fix this no-unnecessary-condition rule violation
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const record = metadataByWell?.[well];
        // TODO(you): Fix this no-unnecessary-condition rule violation
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const primaryValue = record?.[primaryColumn];
        // TODO(you): Fix this no-unnecessary-condition rule violation
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const color = record ? colorMap.get(primaryValue) : "#ccc";
        const opacity =
          filteredLegendValues != null &&
          !filteredLegendValues.includes(primaryValue)
            ? 0.2
            : 1.0;

        // TODO(you): Fix this no-unnecessary-condition rule violation
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (record) {
          return (
            <Tooltip
              key={well}
              side="right"
              className="tw-w-auto"
              contents={
                <div className="tw-text-base tw-text-slate-800">
                  <div className="tw-truncate">
                    <span className="tw-text-slate-500">{primaryColumn}:</span>{" "}
                    <span>
                      {primaryValue == null ? "<null>" : String(primaryValue)}
                    </span>
                  </div>
                  <hr className="tw-my-1" />
                  {tooltipColumns.map((columnName) => (
                    <div
                      key={columnName}
                      className="tw-truncate"
                      style={{ opacity }}
                    >
                      <span className="tw-text-slate-500">{columnName}:</span>{" "}
                      {/* TODO(you): Fix this no-unnecessary-condition rule violation */}
                      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                      <span>{record?.[columnName] ?? "<null>"}</span>
                    </div>
                  ))}
                </div>
              }
            >
              <div
                className={"tw-cursor-pointer"}
                style={{
                  width: size,
                  height: size,
                  backgroundColor: color,
                  opacity,
                }}
                onClick={() => onSelectWell(well)}
              />
            </Tooltip>
          );
        } else {
          // Empty well.
          return (
            <div
              key={well}
              style={{ width: size, height: size, backgroundColor: color }}
            />
          );
        }
      }}
    </PlateMap>
  );
}

function PlateImageViewWithControls({
  className,
  index,
  imageSet,
  size,
  ...rest
}: {
  className?: string;
  index: DatasetPlate;
  imageSet: ImageSet | null;
  size: number;
  preprocessingMode?: ProcessingTechnique | null;
  onSelectWell: (well: string) => void;
}) {
  const [field, setField] = useState<Field>("f00");
  return (
    <div className={cx("tw-flex tw-flex-col", className)}>
      <div className={"tw-self-start tw-w-[128px]"}>
        <FieldSelector
          dataset={index.dataset}
          plate={index.plate}
          field={field}
          onSelectField={setField}
        />
      </div>
      <Strut />
      <PlateImageViewWithFetch
        index={index}
        imageSet={imageSet}
        field={field}
        size={size}
        {...rest}
      />
    </div>
  );
}

function PlateMetadataViewWithControls({
  className,
  index,
  size,
  onSelectWell,
  onMetadataReady,
  filteredLegendValues,
}: {
  className?: string;
  index: DatasetPlate;
  size: number;
  onSelectWell: (well: string) => void;
  onMetadataReady: (data: PlateMetadataLegendMap | null) => void;
  filteredLegendValues: MetadataColumnValue[] | null;
}) {
  const [primaryColumn, setPrimaryColumn] = useState<string | null>(null);
  // HACK(benkomalo): because this component is the one that renders metadata, but
  // parent components render the _legend_ for the metadata, we have to signal up
  // when the metadata is cleared/ready.
  useEffect(() => {
    if (!primaryColumn) {
      onMetadataReady(null);
    }
  }, [primaryColumn, onMetadataReady]);

  return (
    <div className={cx("tw-flex tw-flex-col", className)}>
      <div className={"tw-flex tw-self-start"} style={{ width: 250 }}>
        <div className={"tw-flex-1"}>
          <DatasetColumnSelector
            dataset={index.dataset}
            plate={index.plate}
            column={primaryColumn}
            onSelectColumn={setPrimaryColumn}
            autoSelect="first-non-unique"
            omitColumns={[
              "plate",
              "well",
              "field",
              "z_layer",
              "palette_number",
            ]}
          />
        </div>
        <Strut />
      </div>
      {primaryColumn && (
        <>
          <Strut />
          <PlateMetadataView
            dataset={index.dataset}
            plate={index.plate}
            primaryColumn={primaryColumn}
            size={size}
            onSelectWell={onSelectWell}
            onMetadataReady={onMetadataReady}
            filteredLegendValues={filteredLegendValues}
          />
        </>
      )}
    </div>
  );
}

export default function FullPlateView(props: {
  mode: "images" | "metadata";
  index: DatasetPlate;
  imageSet: ImageSet | null;
  size: number;
  onSelectWell: (well: string) => void;
  onMetadataReady: (data: PlateMetadataLegendMap | null) => void;
  filteredLegendValues: MetadataColumnValue[] | null;
}) {
  const keyBase = `${props.index.dataset}_${props.index.plate}`;
  const [renderMetadata, setRenderMetadata] = useState(false);

  useEffect(() => {
    // Don't render the metadata component until the user switches to it,
    // but once we render it keep it in the DOM so we don't have to re-rerender it
    if (!renderMetadata && props.mode === "metadata") {
      setRenderMetadata(true);
    }
  }, [renderMetadata, props.mode]);

  return (
    <>
      {/* Keep the plate images rendered, even if we're looking at the metadata tab
    so we have instant transitions when switching between tabs */}
      <PlateImageViewWithControls
        key={`${keyBase}i`}
        className={props.mode !== "images" ? "tw-hidden" : ""}
        {...props}
      />
      {renderMetadata && (
        <PlateMetadataViewWithControls
          key={`${keyBase}m`}
          className={props.mode !== "metadata" ? "tw-hidden" : ""}
          index={props.index}
          size={props.size}
          onSelectWell={props.onSelectWell}
          onMetadataReady={props.onMetadataReady}
          filteredLegendValues={props.filteredLegendValues}
        />
      )}
    </>
  );
}
