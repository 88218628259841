import { ReactNode, useCallback } from "react";
import { DatasetListing, WorkspaceId } from "src/types";
import { experimentDefaultUrl } from "src/util/urls";
import { getStateInQueryParams } from "../imaging/state/store";
import { ExperimentResultGroup } from "./types";

export function tokenize(query: string): string[] {
  return query
    .trim()
    .split(" ")
    .filter((token) => token.trim().length > 0);
}

function escapeRegExp(s: string): string {
  // From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
  return s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export function applyToSubstringMatches(
  s: string,
  tokens: string[],
  fn: (segment: string) => ReactNode,
): ReactNode {
  tokens = tokens.map((token) => token.toLowerCase());

  const re = new RegExp(tokens.map(escapeRegExp).join("|"), "ig");
  const buffer = [];
  let i = 0;
  const matches = s.matchAll(re);
  for (const match of matches) {
    if (match.index !== undefined) {
      // Consume the segment before the match and move pointer up to the match start.
      buffer.push(s.substring(i, match.index));
      i = match.index;

      // Consume the token matched.
      const tokenLength = match[0].length;
      const matchedToken = s.substring(i, i + tokenLength);
      buffer.push(fn(matchedToken));
      i += tokenLength;
    }
  }

  if (i < s.length) {
    buffer.push(s.substring(i));
  }
  return buffer;
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type LinkEnhancerFn = (link: string) => string;

/**
 * Given search result links, "enhance them" with state depending on context.
 *
 * Since we often store "state" in the URL of our app, navigating to a search result
 * from a page with state in it may inadvertently clear state if we're not careful.
 * This helps deal with those situations.
 */
export function useSearchLinkEnhancer(): LinkEnhancerFn {
  // Since right now we only can navigate to image viewer pages, we just have to make
  // sure when navigating _from_ image viewer pages, we keep that state.
  const urlParams = getStateInQueryParams();

  return useCallback(
    (link: string) => {
      if (urlParams) {
        if (link.includes("?")) {
          return `${link}&${urlParams}`;
        } else {
          return `${link}?${urlParams}`;
        }
      } else {
        return link;
      }
    },
    [urlParams],
  );
}

/**
 * Given some dataset listings, returns a search group with any datasets that match the
 * query
 */
export function experimentsGroup({
  query,
  datasets,
  workspaceId,
}: {
  query: string;
  datasets: DatasetListing[];
  workspaceId: WorkspaceId;
}): ExperimentResultGroup {
  const matchingDatasets = datasets
    .filter((dataset) =>
      dataset.name.toLowerCase().includes(query.toLowerCase()),
    )
    .map((dataset) => ({
      url: experimentDefaultUrl(workspaceId, dataset.id),
      name: dataset.name,
    }));

  return {
    type: "experiment",
    resultType: "Experiment",
    entries: matchingDatasets.map((entry) => entry.name),
    urls: matchingDatasets.map((entry) => entry.url),
  };
}
