import cx from "classnames";
import { useCallback, useEffect, useState } from "react";
import { Field, ImageSet } from "src/imaging/types";
import { metadataToKey, toNumericField } from "src/imaging/util";
import MultiChannelView from "src/immunofluorescence/MultiChannelView";
import { useTimeSeriesContext } from "src/timeseries/Context";
import { DatasetId } from "src/types";

/**
 * A wrapper around MultiChannelView that handles timeseries data
 * Maintains a cache by prerendering the underlying MultiChannelView for given timepoints
 */
export function TimeSeriesMultiChannelView({
  dataset,
  plate,
  well,
  field,
  imageSet,
  size,
}: {
  dataset: DatasetId;
  plate: string;
  well: string;
  field: Field;
  imageSet: ImageSet | null;
  size: number;
}) {
  const {
    timepoints,
    currentTimepoint,
    cachedTimepoints,
    isLoaded,
    setIsLoaded,
    isPlaying,
  } = useTimeSeriesContext();

  const [loadedTimepoints, setLoadedTimepoints] = useState(
    new Array(timepoints.length).fill(false),
  );

  useEffect(() => {
    // If it's already loaded, we don't have to redo the calculation
    if (isLoaded) {
      return;
    }

    const isCacheLoaded = loadedTimepoints.every(
      (isTimepointLoaded, timepoint) =>
        cachedTimepoints[timepoint] === isTimepointLoaded,
    );
    if (isCacheLoaded) {
      setIsLoaded(true);
    }
  }, [isLoaded, cachedTimepoints, loadedTimepoints, setIsLoaded]);

  const setTimepointLoaded = useCallback(
    (timepoint: number) => {
      const newLoadedTimepoints = [...loadedTimepoints];
      newLoadedTimepoints[timepoint] = true;
      setLoadedTimepoints(newLoadedTimepoints);
    },
    [loadedTimepoints, setLoadedTimepoints],
  );

  return (
    <>
      {cachedTimepoints.map((cached, timepoint) => {
        if (!cached) {
          return null;
        }

        const index = {
          dataset,
          plate,
          well,
          field: toNumericField(field),
          t: timepoint,
          z: 0,
        };

        return (
          <div
            key={metadataToKey({
              ...index,
              field,
              timepoint,
            })}
            className={cx(
              currentTimepoint === timepoint ? "tw-visible" : "tw-hidden",
            )}
          >
            <MultiChannelView
              index={index}
              imageSet={imageSet}
              crop={null}
              size={size}
              showContextMenu={!isPlaying}
              onReady={() => {
                setTimepointLoaded(timepoint);
              }}
            />
          </div>
        );
      })}
    </>
  );
}
