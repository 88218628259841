import { ReactElement, useCallback } from "react";
import { BiScatterChart } from "react-icons/bi";
import { BsSortUp } from "react-icons/bs";
import { enumChecker } from "src/util/enum";
import Comparisons from "../icons/Comparisons.svg";
import Distribution from "../icons/Distribution.svg";
import Overlay from "../icons/Overlay.svg";
import PreviewComparisonsUrl from "../img/preview-comparisons.png?url";
import PreviewImageDistributionsUrl from "../img/preview-image-distributions.png?url";
import PreviewMegaMapUrl from "../img/preview-megamap.png?url";
import PreviewOverlaysUrl from "../img/preview-overlays.png?url";
import PreviewUmapUrl from "../img/preview-umap.png?url";
import { useTypedQueryParams } from "../routing";

export enum MultiFeatureView {
  Similarities = "similarities",
  Ranking = "ranking",
}

export enum SingleFeatureView {
  Comparisons = "comparisons",
  ImageDistribution = "image distribution",
  Overlays = "overlays",
}

export type ViewId = MultiFeatureView | SingleFeatureView;

const isViewId = enumChecker<ViewId>({
  ...SingleFeatureView,
  ...MultiFeatureView,
});

enum ViewMode {
  SingleFeature = "single feature",
  MultiFeature = "multi feature",
  MixedMultiFeature = "mixed multi feature",
}

export interface MenuView {
  description: string;
  name: string;
  previewUrl: string;
  internalOnly?: boolean | undefined;
}

export interface AnalyzeView extends MenuView {
  id: ViewId;
  modes: ViewMode[];
  description: string;
  icon: ReactElement;
  name: string;
  previewUrl: string;
  supportsEmbeddings: boolean;
  requiresCellLevelFeature?: boolean;
}

const ALL_ANALYSIS_VIEWS: AnalyzeView[] = [
  {
    id: MultiFeatureView.Similarities,
    name: "Similarities",
    modes: [ViewMode.MultiFeature],
    description:
      "Gain insights into relationships within your data by visualizing in a 2D space.",
    previewUrl: PreviewUmapUrl,
    icon: <BiScatterChart />,
    supportsEmbeddings: true,
  },
  {
    id: MultiFeatureView.Ranking,
    name: "Ranking",
    modes: [
      ViewMode.SingleFeature,
      ViewMode.MultiFeature,
      ViewMode.MixedMultiFeature,
    ],
    description:
      "Compare multiple measurements simultaneously to rank and sort your data.",
    previewUrl: PreviewMegaMapUrl,
    icon: <BsSortUp />,
    supportsEmbeddings: true,
  },
  {
    id: SingleFeatureView.Comparisons,
    name: "Comparisons",
    modes: [ViewMode.SingleFeature],
    description:
      "Compare the distribution of a measurements across different groups.",
    previewUrl: PreviewComparisonsUrl,
    icon: <Comparisons className="tw-min-w-[24px]" fill="currentColor" />,
    supportsEmbeddings: false,
  },
  {
    id: SingleFeatureView.ImageDistribution,
    name: "Image Distribution",
    modes: [ViewMode.SingleFeature],
    description:
      "Visualize the distribution of a measurement by sampling images from your dataset.",
    previewUrl: PreviewImageDistributionsUrl,
    icon: <Distribution className="tw-min-w-[24px]" fill="currentColor" />,
    supportsEmbeddings: false,
  },
  {
    id: SingleFeatureView.Overlays,
    name: "Overlays",
    modes: [ViewMode.SingleFeature],
    description:
      "View single-cell measurements overlaid on top of your images.",
    previewUrl: PreviewOverlaysUrl,
    icon: <Overlay className="tw-min-w-[24px]" fill="currentColor" />,
    supportsEmbeddings: false,
    requiresCellLevelFeature: true,
  },
];

export const DEFAULT_IF_ANALYSIS_VIEWS = ALL_ANALYSIS_VIEWS;
export const DEFAULT_HISTO_ANALYSIS_VIEWS = ALL_ANALYSIS_VIEWS.filter(
  ({ id }) => ([SingleFeatureView.Comparisons] as ViewId[]).includes(id),
);

const viewsById = new Map(ALL_ANALYSIS_VIEWS.map((view) => [view.id, view]));

function getView(id: ViewId): AnalyzeView {
  return viewsById.get(id)!;
}

export const DEFAULT_VIEW = getView(SingleFeatureView.Comparisons);

export function useActiveView(): [
  AnalyzeView | undefined,
  (viewId: ViewId) => void,
] {
  const [{ tab }, setQueryParams] = useTypedQueryParams({
    tab: (value) => (isViewId(value) ? value : undefined),
  });

  const setView = useCallback(
    (view: ViewId) => {
      setQueryParams({ tab: view });
    },
    [setQueryParams],
  );

  return [tab && getView(tab), setView];
}

export function viewSupportsMultipleFeatures(view: AnalyzeView | undefined) {
  return (
    view === undefined ||
    view.modes.some(
      (mode) =>
        mode === ViewMode.MultiFeature || mode === ViewMode.MixedMultiFeature,
    )
  );
}
