/**
 * Component for rendering a multi-channel image in HTML Canvas.
 *
 * The component accepts a manifest of source URLs, one for each potential
 * input channel, along with a map from output RGB channel to input channel that
 * should be rendered into it.
 */
import isEqual from "lodash.isequal";
import React, { Component, createRef, useContext, useMemo } from "react";
import { Download, DownloadCloud } from "react-feather";
import { WorkspaceId } from "src/types";
import { workspaceApi } from "src/util/api-client";
import UTIF from "utif";
import { Button } from "@spring/ui/Button";
import ImageRescaler, { getWorker, releaseWorker } from "./ImageRescaler";
import {
  CtxWithHash,
  Prefetch,
  PrefetchContext,
  waitForImage,
} from "./Prefetch";
import Strut from "./Strut";
import { applyAutoScaling } from "./auto-scaling";
import { ALL_CHANNEL_INDEXES } from "./constants";
import { CropContext, toOffset, toTransform } from "./cropping";
import { DownloadHelpers } from "./download-helpers";
import {
  ChannelIndex,
  ChannelMap,
  CropLocation,
  CropOptions,
  DatasetPlateWellFieldTZ,
  DatasetPlateWellFieldTZC,
  DisplayRange,
  FeaturePresentation,
  ImageFetchItem,
  ImageFetchResult,
  ImageFetchSpec,
  ImageMetadata,
  ImageSet,
  RenderableImage,
} from "./types";

const NUMBER_FORMATTER = new Intl.NumberFormat(undefined, {
  compactDisplay: "short",
});

type Props = {
  channelMap: ChannelMap;
  displayRanges: [
    DisplayRange | null,
    DisplayRange | null,
    DisplayRange | null,
    DisplayRange | null,
    DisplayRange | null,
    DisplayRange | null,
    DisplayRange | null,
  ];
  autoScale: boolean;
  // Size at which the image should be rendered.
  size: number;
  imageSet: ImageSet;
  index: DatasetPlateWellFieldTZ;
  crop: CropOptions | null;
  workspaceId: WorkspaceId;
  // Callback triggered when an image is loaded into a channel (one of R, G, or
  // B).
  onLoadChannel?: (imageMetadata: ImageMetadata, channel: ChannelIndex) => void;
  // TODO(charlie): Come up with a better API for adding these annotations to
  // the image. This component as-is is agnostic to what's displayed, apart
  // from this prop. Can we do it through composition somehow?
  annotations?: ReadonlyArray<{
    row: number;
    column: number;
    value: number;
  }> | null;
  annotationStyle?: FeaturePresentation | null;
  // Whether to render controls to allow the user to download the individual channels and composited
  // image.
  showDownloadControls?: boolean;
  // If specified, will use this filename as the downloaded file when downloading composited
  // images.
  filename?: string;
  // Whether to render the cursor location and individual channel intensity values on-hover.
  showCursorAnnotations?: boolean;
  // Handler for downloading individual channels (which need to hit the server)
  onDownloadChannel?: (
    imageSet: ImageSet,
    index: DatasetPlateWellFieldTZC,
  ) => void;
  // Handle for when all of the channels have downloaded
  onReady?: () => void;
  // TODO(davidsharff): there is a type def in the FeatureSetManagementPage dir. How best to reconcile?
  // Highlights a specific cell
  selectedCell?: { column: number; row: number } | null;
  // Handler for overriding the default context menu
  onContextMenu?: React.MouseEventHandler<HTMLCanvasElement>;
  forwardedRef?: React.ForwardedRef<HTMLCanvasElement>;
};

type State = {
  // Map from the channel index (as the server / microscope knows it, not
  // remapped to a color) to the loaded image data.
  imageDatas: Map<number, RenderableImage | null>;
  abortController: AbortController;
  // Size of the underlying Canvas.
  canvasSize: number | null;
  // Offset between the image center and the center of the object of interest.
  offset: CropLocation | null;
  isHovered: boolean;
  cursorLocation: [number, number] | null;
  ready: boolean;
};

const rangeEquals = (
  a: DisplayRange | null,
  b: DisplayRange | null,
): boolean => {
  if (a === b) {
    return true;
  }
  if (a == null || b == null) {
    return false;
  }
  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (a.length !== b.length) {
    return false;
  }

  return a[0] === b[0] && a[1] === b[1];
};

class MultiChannelImageWithoutPrefetch extends Component<
  Props & {
    expectPrefetchedImages?: boolean | undefined;
    prefetchContext?: CtxWithHash | undefined;
  },
  State
> {
  _canvasRef: { current: HTMLCanvasElement | null };
  _isMounted: boolean | null;
  _worker: ImageRescaler | null;
  _workerTaskId: string;
  _downloadHelpers: DownloadHelpers;

  static defaultProps = {
    showDownloadControls: true,
    showCursorAnnotations: true,
  };

  static contextType = CropContext;

  constructor(
    props: Props & {
      expectPrefetchedImages?: boolean | undefined;
      prefetchContext?: CtxWithHash | undefined;
    },
  ) {
    super(props);

    this._canvasRef = createRef();
    this._isMounted = false;
    this._worker = null;
    this._workerTaskId = "" + Math.random();
    this._downloadHelpers = new DownloadHelpers({
      canvasRef: this._canvasRef,
      imageSet: props.imageSet,
      index: props.index,
      filename: props.filename,
      onDownloadChannel: props.onDownloadChannel,
      channelMap: props.channelMap,
    });

    this.state = {
      imageDatas: new Map(),
      abortController: new window.AbortController(),
      canvasSize: null,
      offset: null,
      isHovered: false,
      cursorLocation: null,
      ready: false,
    };
  }

  componentDidMount() {
    this._worker = getWorker();

    this._isMounted = true;
    this.fetchChannels();
  }

  componentWillUnmount() {
    this._isMounted = false;

    const { abortController } = this.state;
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (abortController) {
      abortController.abort();
    }

    if (this._worker) {
      releaseWorker(this._worker);
    }

    this._downloadHelpers.close();
  }

  /**
   * Handle the actual fetching of a single channel images's data from GCS.
   */
  fetchChannelData = async (
    item: ImageFetchItem,
  ): Promise<ImageFetchResult> => {
    const { imageSet, workspaceId } = this.props;
    const url = workspaceApi({ workspace: workspaceId }).url("fetch_image", {
      index: JSON.stringify(item.index),
      image_set: JSON.stringify({
        processing: imageSet.processing,
        subset: imageSet.subset,
        image_size_px: imageSet.size,
      }),
      ...(item.cropOptions
        ? { crop_options: JSON.stringify(item.cropOptions) }
        : {}),
    });

    const response = await fetch(url, {
      mode: "cors",
      credentials: "include",
      signal: this.state.abortController.signal,
    });
    const buffer = await response.arrayBuffer();

    const rawDefaultMinValue = response.headers.get("x-min-value");
    const rawDefaultMaxValue = response.headers.get("x-max-value");
    if (rawDefaultMinValue == null || rawDefaultMaxValue == null) {
      throw Error("Expected to receive default display range from the server.");
    }
    const defaultDisplayRange: DisplayRange = [
      Number.parseInt(rawDefaultMinValue),
      Number.parseInt(rawDefaultMaxValue),
    ];

    const rawAutoMinValue = response.headers.get("x-auto-min-value");
    const rawAutoMaxValue = response.headers.get("x-auto-max-value");
    if (rawAutoMinValue == null || rawAutoMaxValue == null) {
      throw Error("Expected to receive auto display range from the server.");
    }
    const autoDisplayRange: DisplayRange = [
      Number.parseInt(rawAutoMinValue),
      Number.parseInt(rawAutoMaxValue),
    ];

    return {
      type: "ok",
      data: new Uint8Array(buffer),
      autoRange: autoDisplayRange,
      defaultRange: defaultDisplayRange,
    };
  };

  /**
   * Load an input image (a single channel) into an output channel (one of R, G,
   * or B).
   *
   * @param inputChannel - The index of the channel to load, in the manifest.
   * @param outputChannel - The output channel (R, G, or B) into which the input
   *                        should be loaded.
   *
   * TODO(colin): this doesn't properly handle byte order for 16-bit tiffs.
   * This will use a platform-dependent byte order and assume the tiff data
   * comes in that same byte order.
   */
  fetchChannel = (inputChannel: number, outputChannel: ChannelIndex) => {
    // If we've already loaded the image, short-circuit.
    const { imageDatas } = this.state;

    const channelImage = imageDatas.get(inputChannel);
    if (channelImage) {
      const { defaultDisplayRange, autoDisplayRange } = channelImage;
      this.handleLoadChannel(
        { defaultDisplayRange, autoDisplayRange },
        outputChannel,
      );
      return;
    }

    // Otherwise, we need to fetch the data.
    const { expectPrefetchedImages, prefetchContext, index, imageSet, crop } =
      this.props;

    if (prefetchContext == null && expectPrefetchedImages) {
      throw new Error(
        "Expected prefetched images, but no prefetch context found.",
      );
    }

    const item: ImageFetchItem = {
      index: { ...index, c: inputChannel },
      cropOptions: crop,
    };

    const imageFetchPromise = expectPrefetchedImages
      ? waitForImage(item, imageSet, null, prefetchContext!.ctx)
      : this.fetchChannelData(item);

    imageFetchPromise
      .then((response) => {
        // TODO(you): Fix this no-unnecessary-condition rule violation
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (response === undefined || response.type !== "ok") {
          let message;
          // TODO(you): Fix this no-unnecessary-condition rule violation
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (response === undefined) {
            message = "Image not loaded";
          } else if (response.type === "error") {
            if (response.err.name === "AbortError") {
              return;
            } else {
              throw response.err;
            }
          } else {
            message = "still in progress";
          }
          throw new Error(message);
        }
        const arrayBuffer = response.data;
        // If the channel map has changed since we made the request, abort.
        const { channelMap } = this.props;
        if (channelMap[outputChannel] !== inputChannel || !this._isMounted) {
          return;
        }

        // Decode the raw TIFF to pixel data.
        const ifd = UTIF.decode(arrayBuffer)[0];
        UTIF.decodeImage(arrayBuffer, ifd);

        const imageArray = new Uint16Array(ifd.data.buffer);
        const canvasSize = ifd.width;

        // Depending on the imager, we may have a different number of bits per
        // pixel; we need to figure that out in order to convert image data to
        // an appropriate type.
        // TODO(colin): this is a total hack to get 8-bit images working.
        // Refactor to correctly handle the many possible underlying tiff data
        // types and byte order.
        if (ifd.data.length === ifd.width * ifd.height) {
          ifd.data = Uint16Array.from(Array.from(ifd.data)) as any;
        }

        // If this is a croppable image, determine the offset between the
        // location at which the crop was taken and the center-point of the
        // cropped object.
        let offset;
        if (crop) {
          offset = toOffset(crop.location, {
            sourceImageSize: imageSet.size,
            sourceCropSize: this.context.sourceCropSize,
          });
        } else {
          offset = null;
        }

        const defaultDisplayRange = response.defaultRange;
        const autoDisplayRange = response.autoRange;

        const imageMetadata: ImageMetadata = {
          defaultDisplayRange,
          autoDisplayRange,
        };

        const { imageDatas } = this.state;
        imageDatas.set(inputChannel, {
          array: imageArray,
          ...imageMetadata,
        });
        this.setState({ imageDatas, canvasSize, offset }, () => {
          if (this._isMounted) {
            this.handleLoadChannel(imageMetadata, outputChannel);
          }
        });
      })

      .catch((error) => {
        if (error.name === "AbortError") {
          return;
        }
        console.warn(error);
        if (this._isMounted) {
          const { imageDatas } = this.state;
          imageDatas.delete(inputChannel);
          this.setState({ imageDatas });
        }
      });
  };

  /**
   * Fetch all necessary input channels (i.e., an image for each channel).
   */
  fetchChannels = () => {
    const { channelMap } = this.props;
    const { imageDatas } = this.state;

    for (
      let outputChannel = 0;
      outputChannel < channelMap.length;
      outputChannel++
    ) {
      const inputChannel = channelMap[outputChannel];

      if (inputChannel == null) {
        continue;
      }

      if (!imageDatas.get(inputChannel)) {
        this.fetchChannel(inputChannel, outputChannel as ChannelIndex);
      }
    }
  };

  /**
   * Handle the successful loading of an input channel.
   *
   * @param imageMetadata - Metadata for the raw image of the input channel.
   * @param channel - The output channel into which the image will be rendered
   *                  (one of R, G, or B).
   */
  handleLoadChannel = (imageMetadata: ImageMetadata, channel: ChannelIndex) => {
    const { onLoadChannel } = this.props;
    if (onLoadChannel) {
      onLoadChannel(imageMetadata, channel);
    }

    this.maybeDraw();
  };

  /**
   * Draw to the HTML Canvas, if all requisite images have been loaded.
   */
  maybeDraw = () => {
    const { channelMap } = this.props;
    const { canvasSize } = this.state;

    // Do we have all the data we need?
    if (!this.hasLoadedData()) {
      return;
    }

    // If we don't yet have a canvas size, then we haven't actually loaded any channels. This can
    // happen if the initial channel map is empty.
    if (canvasSize == null) {
      if (channelMap.some((inputChannel) => inputChannel != null)) {
        throw Error(
          "Attempted to render with no canvas size and non-empty channel map.",
        );
      }
      return;
    }

    this.draw();
  };

  /**
   * Draw to the HTML Canvas.
   *
   * Assumes that all requisite images have been loaded.
   */
  draw = () => {
    const { channelMap, displayRanges, autoScale } = this.props;
    const { imageDatas } = this.state;

    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!this._canvasRef || !this._canvasRef.current) {
      throw Error("Cannot draw without Canvas reference.");
    }

    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const canvas = this._canvasRef?.current;
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const ctx = canvas?.getContext("2d");
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!canvas || !ctx) {
      throw new Error("Cannot draw without Canvas reference.");
    }

    const dstData = new Uint8ClampedArray(canvas.width * canvas.width * 4);

    if (!this._worker) {
      throw Error("Expected ImageRescaler to be available.");
    }

    this._worker
      .execute(
        this._workerTaskId,
        dstData,
        imageDatas,
        channelMap,
        autoScale
          ? applyAutoScaling(imageDatas, displayRanges, channelMap)
          : displayRanges,
      )
      .then((dstData) => {
        if (this._isMounted) {
          this.flush(dstData);
        }
      });
  };

  /**
   * Flush pixel data to HTML Canvas.
   */
  flush = (imageData: Uint8ClampedArray) => {
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!this._canvasRef || !this._canvasRef.current) {
      throw Error("Cannot draw without Canvas reference.");
    }

    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const canvas = this._canvasRef?.current;
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const ctx = canvas?.getContext("2d");
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!canvas || !ctx) {
      throw new Error("Cannot draw without Canvas reference.");
    }

    ctx.putImageData(
      new ImageData(imageData, canvas.width, canvas.height),
      0,
      0,
    );

    // Render any annotations.
    const { annotations, annotationStyle } = this.props;
    if (annotations) {
      if (annotationStyle === "box") {
        ctx.strokeStyle = "white";
        ctx.lineWidth = 2;
      } else if (annotationStyle === "dot") {
        ctx.strokeStyle = "white";
        ctx.lineWidth = 4;
      } else {
        ctx.font = canvas.width > 1080 ? "30px Monaco" : "16px Monaco";
        ctx.fillStyle = "white";
      }

      const { selectedCell } = this.props;
      for (const a of annotations) {
        const { row, column, value } = a;
        if (
          selectedCell &&
          (row !== selectedCell.row || column !== selectedCell.column)
        ) {
          continue;
        }
        if (annotationStyle === "box") {
          ctx.strokeRect(row - 64, column - 64, 128, 128);
        } else if (annotationStyle === "dot") {
          ctx.beginPath();
          ctx.arc(row, column, 16, 0, 2 * Math.PI);
          ctx.stroke();
        } else {
          ctx.fillText(NUMBER_FORMATTER.format(value), row, column);
        }
      }

      // HACK(benkomalo): There appears to be a bug in Chrome with 2D hardware
      // acceleration on (which is the default, as per this writing) that results
      // in the canvas being blanked out.
      // For reasons I don't understand, retrieving the image data in a noop-fashion
      // like this appears to "flush" the compositor and it avoids the issue.
      // https://app.asana.com/0/1202506452233422/1202692323026557
      ctx.getImageData(0, 0, canvas.width, canvas.height);
    }

    if (!this.state.ready) {
      this.setState({ ready: true });
      this.props.onReady?.();
    }
  };

  componentDidUpdate(
    prevProps: Props & {
      expectPrefetchedImages?: boolean | undefined;
      prefetchContext?: CtxWithHash | undefined;
    },
  ) {
    const imageParamsChanged =
      !isEqual(prevProps.imageSet, this.props.imageSet) ||
      !isEqual(prevProps.crop, this.props.crop) ||
      !isEqual(prevProps.index, this.props.index) ||
      prevProps.workspaceId !== this.props.workspaceId;
    const didChange =
      imageParamsChanged ||
      ALL_CHANNEL_INDEXES.some(
        (i) => prevProps.channelMap[i] !== this.props.channelMap[i],
      ) ||
      ALL_CHANNEL_INDEXES.some(
        (i) =>
          !rangeEquals(prevProps.displayRanges[i], this.props.displayRanges[i]),
      ) ||
      prevProps.autoScale !== this.props.autoScale ||
      prevProps.annotations !== this.props.annotations ||
      prevProps.prefetchContext?.hash !== this.props.prefetchContext?.hash;

    if (didChange) {
      // Fetch any updated channels.
      ALL_CHANNEL_INDEXES.forEach((i) => {
        const mappedIndex = this.props.channelMap[i];
        if (
          mappedIndex != null &&
          (imageParamsChanged || mappedIndex !== prevProps.channelMap[i])
        ) {
          this.fetchChannel(mappedIndex, i);
        }
      });
      if (
        prevProps.prefetchContext?.hash !== this.props.prefetchContext?.hash
      ) {
        // TODO(colin): this may be called more than necessary because there
        // might be changes in the cache that aren't relevant to what we're
        // going to render. At present, we only batch all channels in one image
        // together via prefetch, so it's not a problem, but doing further
        // batching might merit a different strategy here.
        this.fetchChannels();
      }
      this.maybeDraw();
    }
  }

  /**
   * Format the annotation based on provided cursor location.
   *
   * Renders the current cursor location, along with the individual pixel intensity values for the
   * channels, at that location.
   */
  formatCursorAnnotation = (
    cursorLocation: [number, number],
  ): string | null => {
    const { size: viewPortSize, channelMap } = this.props;
    const { canvasSize, imageDatas, offset } = this.state;
    if (!canvasSize) {
      return null;
    }

    if (offset) {
      // If we have an offset, don't show the annotations for now. That only happens
      // for single-cell images, in practice, and those are often too small for
      // annotations.
      return null;
    }

    const segments = [`X: ${cursorLocation[0]}`, `Y: ${cursorLocation[1]}`];
    const initials = ["R", "G", "B", "C", "M", "Y", "W"];
    const rescaleFactor = canvasSize / viewPortSize;
    const pixelX = Math.round(cursorLocation[0] * rescaleFactor);
    const pixelY = Math.round(cursorLocation[1] * rescaleFactor);

    for (
      let outputChannel = 0;
      outputChannel < channelMap.length;
      outputChannel++
    ) {
      const inputChannel = channelMap[outputChannel];
      if (inputChannel == null) {
        continue;
      }

      const channelData = imageDatas.get(inputChannel);
      if (!channelData || !channelData.array.length) {
        return null;
      } else {
        const pixelValue = channelData.array[pixelX + canvasSize * pixelY];
        segments.push(`${initials[outputChannel]}: ${pixelValue}`);
      }
    }

    return segments.join(", ");
  };

  hasLoadedData() {
    const { channelMap, displayRanges } = this.props;
    const { imageDatas } = this.state;
    for (
      let outputChannel = 0;
      outputChannel < channelMap.length;
      outputChannel++
    ) {
      const inputChannel = channelMap[outputChannel];

      if (inputChannel == null) {
        continue;
      }

      // Don't render until we have all the images we need, and the display
      // ranges for them.
      if (!imageDatas.get(inputChannel) || !displayRanges[outputChannel]) {
        return false;
      }
    }

    return true;
  }

  render() {
    const {
      size,
      crop,
      showDownloadControls,
      showCursorAnnotations,
      onContextMenu,
    } = this.props;
    const { cursorLocation, canvasSize, offset, isHovered } = this.state;

    // TODO(charlie): It's a shame that this component has to be aware of all
    // the location-specific cropping. Try to abstract out.
    let transform;
    let transformOrigin;
    if (canvasSize != null && crop) {
      // Transform the image to: (1) "crop" by zooming in to the right level of
      // magnification, and (2) "center" by translating based on the offset
      // between the center of the rendered image and the object location.
      // Note that while the offset is computed in source image pixel space,
      // the transform operates in the rescaled pixel space.
      if (offset) {
        const cropTransform = toTransform(offset, {
          size,
          cropSize: crop.size,
          canvasSize,
          sourceCropSize: this.context.sourceCropSize,
        });
        transform = cropTransform.transform;
        transformOrigin = cropTransform.transformOrigin;
      } else {
        transform = `scale(${this.context.sourceCropSize / crop.size})`;
      }
    } else {
      transform = null;
      transformOrigin = null;
    }

    // Disable cursor tracking if (1) the user has explicitly disabled it, (2) the image is too
    // small, or (3) we're using dynamic cropping, in which case our logic is not sufficiently
    // general.
    const trackCursorLocation =
      showCursorAnnotations && size >= 512 && crop == null;

    const iconClassnames = "tw-text-black tw-h-[16px] tw-w-[16px]";
    return (
      <div
        style={{
          width: size,
          height: size,
        }}
        className={"tw-relative tw-overflow-hidden"}
        onMouseEnter={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false })}
        onMouseMove={
          trackCursorLocation
            ? (e) => {
                // This handler invalidates layout, and so should only be set when necessary.
                const boundingRect = e.currentTarget.getBoundingClientRect();
                this.setState({
                  cursorLocation: [
                    Math.max(0, Math.floor(e.clientX - boundingRect.left)),
                    Math.max(0, Math.floor(e.clientY - boundingRect.top)),
                  ],
                });
              }
            : undefined
        }
      >
        {showDownloadControls && size >= 512 && isHovered && (
          <div
            className={"tw-flex tw-justify-end tw-absolute"}
            style={{
              right: 8,
              top: 8,
              zIndex: 1,
            }}
          >
            <Button
              size="sm"
              title={"Download composite image"}
              className="tw-opacity-50 hover:tw-opacity-100"
              onClick={(e) => {
                e.stopPropagation();
                this._downloadHelpers.handleDownloadCanvas();
              }}
            >
              <Download className={iconClassnames} />
            </Button>
            {this.props.onDownloadChannel && (
              <>
                <Strut size={8} />
                <Button
                  size="sm"
                  title={"Download channels as individual images"}
                  className="tw-opacity-50 hover:tw-opacity-100"
                  onClick={(e) => {
                    e.stopPropagation();
                    this._downloadHelpers.handleDownloadRawImages();
                  }}
                >
                  <DownloadCloud className={iconClassnames} />
                </Button>
              </>
            )}
          </div>
        )}
        {showCursorAnnotations && isHovered && cursorLocation ? (
          <div
            className={"tw-absolute tw-text-white"}
            style={{
              left: 8,
              top: 8,
              zIndex: 1,
            }}
          >
            {this.formatCursorAnnotation(cursorLocation)}
          </div>
        ) : null}
        <canvas
          width={canvasSize?.toString()}
          height={canvasSize?.toString()}
          style={{
            width: size,
            height: size,
            transform: transform ?? undefined,
            transformOrigin: transformOrigin ?? undefined,
          }}
          ref={(canvas: HTMLCanvasElement) => {
            this._canvasRef.current = canvas;
            const forwardedRef = this.props.forwardedRef;

            if (forwardedRef) {
              if (typeof forwardedRef === "function") {
                forwardedRef(canvas);
              } else {
                forwardedRef.current = canvas;
              }
            }
          }}
          onContextMenu={onContextMenu}
        />
        {!this.hasLoadedData() &&
          // The loading bar is a fixed size of 100px, so we avoid showing it in really
          // small situations (which are likely high-density visuals like plate views
          // that hopefully have the individual images load much faster)
          size > 128 && (
            <div
              className={
                "tw-absolute tw-inset-0 tw-top-[75%] tw-flex tw-items-center tw-justify-center"
              }
            >
              <AnimatingBar />
            </div>
          )}
      </div>
    );
  }
}

function AnimatingBar() {
  return (
    <div
      className={
        "tw-w-[100px] tw-h-[4px] tw-overflow-hidden tw-bg-gray-500 tw-rounded-lg"
      }
    >
      <div
        className={
          "tw-w-[200px] tw-h-[4px] tw-bg-gradient-to-r tw-from-gray-500 tw-via-purple tw-to-gray-500 tw-animate-progress"
        }
      >
        x
      </div>
    </div>
  );
}

export default function MultiChannelImageWithFetch(props: Props) {
  const sharedKeyArray = [
    props.imageSet.processing,
    props.imageSet.size,
    props.imageSet.subset,
    props.index.dataset,
    props.index.plate,
    props.index.well,
    props.index.field,
    props.index.t,
    props.index.z,
    props.crop?.location.x,
    props.crop?.location.y,
    ...props.channelMap,
  ];
  // TODO(michaelwiest): use the actual single cell size stored in the dataset
  //  metadata to figure this out.
  const defaultSize = useContext(CropContext).sourceCropSize;
  // We don't include size in the component key since it forces a remount if we
  // do, and it's supposed to adjust dynamically.
  const prefetchKeyArray = [...sharedKeyArray, defaultSize];
  const prefetchManifest: ImageFetchSpec = useMemo(
    () => {
      const indexes: number[] = props.channelMap.filter(
        (it): it is number => it != null,
      );
      return {
        indexes: indexes.map((c) => ({
          index: { ...props.index, c: c },
          cropOptions: props.crop
            ? { ...props.crop, size: defaultSize }
            : props.crop,
        })),
        imageSet: props.imageSet,
        resizeTo: null,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    prefetchKeyArray,
  );
  return (
    <Prefetch manifest={prefetchManifest}>
      <PrefetchContext.Consumer>
        {(ctx) => (
          <MultiChannelImageWithoutPrefetch
            key={sharedKeyArray
              .map((it) => it?.toString() ?? "null")
              .join("::")}
            {...props}
            expectPrefetchedImages
            prefetchContext={ctx}
          />
        )}
      </PrefetchContext.Consumer>
    </Prefetch>
  );
}
