import cx from "classnames";
import { useCallback, useState } from "react";
import { MetadataColumnValue } from "../types";

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type ColorHex = string;
export type PlateMetadataLegendMap = {
  column: string;
  legend: Map<MetadataColumnValue, ColorHex>;
};

export default function PlateMetadataLegend({
  legend,
  onFilterChanged,
}: {
  legend: PlateMetadataLegendMap;
  onFilterChanged?: (values: MetadataColumnValue[]) => void;
}) {
  const [filterText, setFilterText] = useState<string>("");
  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const legendPairs = Array.from(legend.legend.entries() || []);
  const applyFilter = useCallback(
    (filter: string) => {
      return legendPairs.filter(([legendValue]) => {
        if (filter) {
          return String(legendValue)
            .toLowerCase()
            .includes(filter.trim().toLocaleLowerCase());
        } else {
          return true;
        }
      });
    },
    [legendPairs],
  );

  // Arbitrarily shrink labels when the list goes over a certain size.
  const hasMany = legendPairs.length > 8;

  // Go to two columns when the list is _really_ long.
  const hasTons = legendPairs.length > 30;

  return (
    <>
      {hasMany && (
        <input
          value={filterText}
          onChange={(e) => {
            const newFilter = e.target.value;
            setFilterText(newFilter);
            onFilterChanged &&
              onFilterChanged(applyFilter(newFilter).map(([value]) => value));
          }}
          className={"tw-border tw-w-full tw-p-2 tw-rounded tw-mb-4"}
          placeholder={`Search`}
        />
      )}
      <div className={hasTons ? "tw-grid tw-grid-cols-2" : ""}>
        {applyFilter(filterText).map(([legendValue, legendColor]) => (
          <div
            key={String(legendValue)}
            className={cx(
              "tw-flex tw-flex-row tw-items-center",
              hasMany ? "tw-my-1" : "tw-my-2",
            )}
          >
            <div
              style={{ backgroundColor: legendColor }}
              className={"tw-w-[20px] tw-min-w-[20px] tw-h-[20px] tw-rounded"}
            />
            <span
              className={cx(
                "tw-truncate tw-ml-4 tw-font-mono",
                hasMany ? "tw-text-xs" : "tw-text-sm",
              )}
              title={String(legendValue)}
            >
              {legendValue === null ? "<null>" : String(legendValue)}
            </span>
          </div>
        ))}
      </div>
    </>
  );
}
