import { useCallback, useState } from "react";
import { Select } from "src/Common/Select";
import { useActiveWorkspaceId } from "src/Workspace/hooks";
import { useAccessToken } from "src/hooks/auth0";
import { DatasetId } from "src/types";
import { Button } from "@spring/ui/Button";
import { FullScreenContainer } from "../../Common/FullScreenContainer";
import Spinner from "../../Common/Spinner";
import { DEFAULT_CUT_TREE_NAME } from "../constants";
import { ClusteringStatus } from "../types";
import { requestClustering } from "../utils";

function SuccessModal() {
  const confettiEmoji = String.fromCodePoint(0x1f38a);
  return (
    <FullScreenContainer center>
      <p className={"tw-text-2xl tw-text-purple-500 tw-m-lg"}>
        {confettiEmoji} Your Single Cells are Being Clustered {confettiEmoji}
      </p>
      <p className={"tw-text-slate-500"}>
        Refresh this page in approximately 15 minutes to interact with your
        clustered data!
      </p>
    </FullScreenContainer>
  );
}

export function CreateCluster({
  stains,
  dataset,
}: {
  stains: string[];
  dataset: DatasetId;
}) {
  const [selectedStains, setSelectedStains] = useState<string[]>([]);
  const [status, setStatus] = useState<ClusteringStatus>(ClusteringStatus.NULL);
  const accessToken = useAccessToken();
  const workspaceId = useActiveWorkspaceId();
  const onSubmit = useCallback(async () => {
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!accessToken || workspaceId === null || !workspaceId) {
      setStatus(ClusteringStatus.FAILURE);
      return;
    }
    setStatus(ClusteringStatus.SENDING);
    try {
      await requestClustering(
        accessToken,
        workspaceId,
        DEFAULT_CUT_TREE_NAME,
        dataset,
        selectedStains,
      );
      setStatus(ClusteringStatus.SUCCESS);
    } catch (ex) {
      setStatus(ClusteringStatus.FAILURE);
    }
  }, [dataset, workspaceId, accessToken, selectedStains]);
  if (status === ClusteringStatus.SUCCESS) {
    return <SuccessModal />;
  }
  return (
    <FullScreenContainer center className="tw-text-xl">
      <p className={"tw-text-2xl tw-text-purple-500 tw-m-lg"}>
        No clustering data present yet...
      </p>
      <p className={"tw-text-slate-500"}>
        {" "}
        To begin, select stains to use for clustering.
      </p>
      <div className={"tw-flex tw-justify-center tw-items-center"}>
        <Select
          name="Select stains for clustering"
          className="tw-w-[300px] tw-m-md tw-text-slate-500"
          placeholder="Select stains"
          multi
          values={selectedStains}
          items={stains}
          onChange={setSelectedStains}
        />
        <Button
          className={
            "tw-h-2xl tw-w-[300px] tw-flex tw-items-center tw-justify-center"
          }
          variant="primary"
          disabled={
            selectedStains.length === 0 || status === ClusteringStatus.SENDING
          }
          onClick={() => onSubmit()}
        >
          {status === ClusteringStatus.SENDING ? (
            <Spinner />
          ) : status === ClusteringStatus.FAILURE ? (
            "Something went wrong... Try again!"
          ) : (
            "Cluster Single Cells!"
          )}
        </Button>
      </div>
    </FullScreenContainer>
  );
}
