import { Field, Timepoint } from "./imaging/types";
import { Cover } from "./util/cover";

// Nominal type which also makes the string truthy
export type TypedString<T extends string = string> = string & {
  __idType: T;
};

export type WorkspaceId = TypedString<"workspace">;
export type DatasetId = TypedString<"dataset">;

// Corresponds to DatasetType in data_db.rs
export type DatasetType = "if_bf_plates" | "histology";

// Corresponds to DatasetStatus in data_db.rs
export type DatasetStatus =
  | "prerelease"
  | "live"
  | "archived"
  | "deletion_requested";

export type DatasetListing = {
  type: DatasetType;
  description: string | null;
  cover?: Cover;
  name: string;
  id: DatasetId;
  status: DatasetStatus;
};

export type HasSingleCellDataset = {
  exists: boolean;
};

/**
 * Raw features in (key, value) dict format.
 *
 * This is analogous to pd.DataFrame.to_json(orient="records"), where each key is a
 * column name in the FeatureSet, and the value is the value of that column.
 * e.g. [
 *    {"plate": "plate-1", "well": "A03", "Area": "123", "Eccentricity", 456},
 *    {"plate": "plate-1", "well": "C12", "Area": "789", "Eccentricity", 738},
 *    {"plate": "plate-1", "well": "H09", "Area": "928", "Eccentricity", 928},
 *    ...
 * ]
 */
export type UntypedFeatures = Array<{
  [key: string]: string | number | null;
}>;

export type Features = Array<{
  well: string;
  field: Field;
  row: number;
  column: number;
  value: number;
}>;

export type FieldSampleMetadata = {
  type: "field";
  plate: string;
  well: string;
  field: Field;
};

export type CellSampleMetadata = {
  type: "cell";
  plate: string;
  well: string;
  field: Field;
  row: number;
  column: number;
};

export interface UnlabeledCellSampleMetadata extends CellSampleMetadata {
  id: string;
}
export interface LabeledCellSampleMetadata extends UnlabeledCellSampleMetadata {
  class: string;
}

export type NeighborData = {
  inDegreeScore: number;
  neighbors: number[];
};

export type PredictionData = {
  predictions: Record<string, number>;
};

export type MetadataColumnValue = string | number | boolean | null;

export type UntypedWellSampleMetadataRow = {
  plate: string;
  well: string;
  [column: string]: MetadataColumnValue;
};

export type UntypedTimepointSampleMetadataRow = UntypedWellSampleMetadataRow & {
  timepoint: Timepoint;
};

export type UntypedSampleMetadataRow =
  | UntypedWellSampleMetadataRow
  | UntypedTimepointSampleMetadataRow;

export function isTimeSeriesMetadata(
  row: UntypedSampleMetadataRow,
): row is UntypedTimepointSampleMetadataRow {
  return "timepoint" in row;
}

export type PragmaQueryRecord = {
  cid: number;
  dflt_value: MetadataColumnValue;
  name: string;
  notnull: boolean;
  pk: boolean;
  type: string;
};

export type DatasetMetadata = {
  palettes: string[][];
  dyes: { name: string; emission_nm: number }[];
  source_image_size: number;
  image_size: number;
  single_cell_size: number;
};

/**
 * sample_metadata about the Dataset.
 */
export type DatasetSampleMetadata = {
  type: "immunofluorescence" | "single-cell-immunofluorescence" | "unknown";
  name: string;
  id: string;
  sampleMetadata: UntypedWellSampleMetadataRow[];
};

// The 'level' at which an immunofluorescence dataset should be visualized.
// Note that our front-end interfaces typically do not draw a distinction
// between single-cell and non-single-cell (i.e., field-level) datasets, instead
// surfacing just the field-level versions, which maps more straightforwardly to
// user expectations. As such, we typically end up passing around a field-level
// dataset identifier and inferring field vs. single-cell from the use-case.
// A dataset 'level' can be useful for situations in which the use-case is
// ambiguous, and thus a 'level' must be selected by the user.
export type DatasetLevel = "field" | "cell";
export type FeatureLevel = "well" | "field" | "cell";

/**
 * An {@code AtLeastOneKeyOf} type ensures that at least one property of a given type is present.
 * It constructs a type consisting of all properties of T set to optional and the union of all single
 * property types in T.
 */
export type AtLeastOneKeyOf<
  T,
  U = { [K in keyof T]: Pick<T, K> },
> = Partial<T> & U[keyof U];
