import cx from "classnames";
import { ReactNode } from "react";

export default function ControlSection({
  children,
  extraClasses,
}: {
  children: ReactNode;
  extraClasses?: string;
}) {
  return (
    <div className={cx("tw-flex tw-flex-col tw-h-full tw-p-4", extraClasses)}>
      {children}
    </div>
  );
}

export function VerticalControlSection({
  children,
  extraClasses,
}: {
  children: ReactNode;
  extraClasses?: string;
}) {
  return (
    <div
      className={cx(
        "tw-flex tw-flex-col tw-h-full tw-pb-md tw-px-md",
        extraClasses,
      )}
    >
      {children}
    </div>
  );
}
