/**
 * Utilities for handling errors in a monadic-style way, rather than via
 * exceptions.
 *
 * The result of some (potentially failing) operation is represented as either
 * a Success<T> (where T is the result of the operation), or as a Failure,
 * which encapsulates an Error. (While Failure does not actually need to be
 * paramaterized in theory, typescript complains about mismatched function
 * signatures if I leave it off.)
 *
 * .map allows you to do an operation on a value, only if the result was
 * successful, and .orElse allows you to unwrap a value, providing a default in
 * the case of a failed operation.
 *
 * This is loosely based on rust's result type, which has great docs:
 * https://doc.rust-lang.org/std/result/
 */

/**
 * A non-external interface that helps us ensure consistency in this file.
 *
 * Use `Result<T>` everywhere else.
 */
interface ResultBranch<T> {
  readonly successful: boolean;

  readonly map: <U>(f: (t: T) => U) => Result<U>;
  readonly orElse: (failureDefault: (e: Error) => T) => T;
}

/**
 * A successful operation, resulting in a value of type T.
 */
export class Success<T> implements ResultBranch<T> {
  readonly successful = true as const;
  readonly value: T;

  constructor(value: T) {
    this.value = value;
  }

  static of<T>(value: T): Success<T> {
    return new Success(value);
  }

  map<U>(f: (t: T) => U): Result<U> {
    return Success.of(f(this.value));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  orElse(failureDefault: (e: Error) => T): T {
    return this.value;
  }

  unwrap(): T {
    return this.value;
  }
}

export class TimestampedSuccess<T> extends Success<T> {
  readonly timestamp: number;
  constructor(value: T, timestamp: number) {
    super(value);
    this.timestamp = timestamp;
  }

  static of<T>(value: T): TimestampedSuccess<T> {
    return new TimestampedSuccess(value, Date.now());
  }

  map<U>(f: (t: T) => U): TimestampedResult<U> {
    return new TimestampedSuccess(f(this.value), this.timestamp);
  }
}

/**
 * An operation that failed with the given error.
 */
export class Failure<T> implements ResultBranch<T> {
  readonly successful = false as const;
  readonly error: Error;

  constructor(error: Error) {
    this.error = error;
  }

  static of<T>(error: Error): Failure<T> {
    return new Failure(error);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  map<U>(f: (t: T) => U): Result<U> {
    return Failure.of(this.error);
  }

  orElse(failureDefault: (e: Error) => T): T {
    return failureDefault(this.error);
  }

  unwrap(): never {
    throw this.error;
  }
}

/**
 * The result of an operation that may fail.
 */
export type Result<T> = Success<T> | Failure<T>;
export type TimestampedResult<T> = TimestampedSuccess<T> | Failure<T>;

/**
 * A fetchable result, which might also be in a not-yet-finished state.
 */
export type Fetchable<T> = Result<T> | undefined;

/**
 * Are all the provided results successful?
 */
export function all<T>(
  results: Result<T | undefined>[],
): results is Success<T>[] {
  return results.every((it) => it?.successful);
}

/**
 * Combine several fetchables (i.e. Result<T> | undefined).
 *
 * If all fetchables were successful, we return all successful values in an
 * object matching the format of the input.
 *
 * If any fetchables were undefined (i.e. not started), we return undefined.
 *
 * If any fetchables failed, we return the first failure.
 */
export function combine<T>(fetchables: {
  [K in keyof T]: Fetchable<T[K]>;
}): Fetchable<T> {
  for (const _f of Object.values(fetchables)) {
    const f = _f as Fetchable<unknown>;
    if (f && !f.successful) {
      return Failure.of(f.error);
    }
  }
  if (Object.values(fetchables).some((f) => f === undefined)) {
    return undefined;
  }
  return Success.of<T>(
    Object.fromEntries(
      Object.entries(fetchables).map(([k, v]) => [
        k,
        (v as Success<unknown>).value,
      ]),
    ) as unknown as T,
  );
}
