import { useCallback, useEffect, useState } from "react";
import { jsonPostInit } from "src/util/api-client";
import { Failure, Fetchable, Success } from "@spring/core/result";
import { FilterSqlClause } from "../Control/FilterSelector/types";
import { KSTestEnrichmentRecord } from "../FeatureSetManagementPage/MultiFeature/LabMate/types";
import { FeatureFilter } from "../FeatureSetManagementPage/types";
import { NormalizationConfig } from "../SupervisedLearning/types";
import { DatasetId, UntypedFeatures } from "../types";
import {
  DB,
  ValidatedSQL,
  copyTableAcrossDB,
  makeFeatureDb,
  makeUmapDB,
  sql,
  useFetchableQueryAsRecords,
} from "../util/sql";
import { CanSkip, makeDatasetApi, useDatasetApi } from "./api";
import { useAuthenticatedFetchable } from "./fetch";

export type FeatureSetGroup = {
  // null indicates the feature is at the "top level" of the dataset and part of the
  // "ungrouped group".
  plate: string | null;
  featureSets: string[];
};

type FeatureSetMetadata = {
  description: string;
  experiment_group: string;
  extra_args: object;
  name: string;
  timestamp: number;
  title: string;
  uuid: string;
};

/**
 * Hook to fetch the list of FeatureSets for a given Dataset.
 *
 * Results will be grouped by plate.
 * @see FeatureSetGroup
 */
export const useFeatureSets = makeDatasetApi("features")<
  FeatureSetGroup[],
  { allowCached?: boolean }
>(({ allowCached = true }) => ({
  __spr_cache: allowCached ? undefined : "no-store",
}));

/**
 * Hook to fetch the list of FeatureSets for a given Dataset and plate.
 */
export const useFeatureSetsForPlate = makeDatasetApi("plates/<plate>/features")<
  string[],
  { allowCached?: boolean }
>(({ allowCached = true }) => ({
  __spr_cache: allowCached ? undefined : "no-store",
}));

const useFeatureSetColumnsForPlate = makeDatasetApi(
  "plates/<plate>/features/<featureSet>/columns",
)<string[]>();

const useFeatureSetColumnsSansPlate = makeDatasetApi(
  "features/<featureSet>/columns",
)<string[]>();

/**
 * Hook to fetch the list of columns in a FeatureSet.
 */
export function useFeatureSetColumns({
  dataset,
  plate,
  featureSet,
  skip,
}: CanSkip<{
  dataset: DatasetId;
  plate?: string;
  featureSet: string;
}>): Fetchable<string[]> {
  const plateResponse = useFeatureSetColumnsForPlate(
    !skip && plate
      ? {
          dataset,
          plate,
          featureSet,
        }
      : { skip: true },
  );
  const sansPlateResponse = useFeatureSetColumnsSansPlate(
    !skip && !plate
      ? {
          dataset,
          featureSet,
        }
      : { skip: true },
  );

  return plateResponse ?? sansPlateResponse;
}

/**
 * Hook to fetch raw feature data from a FeatureSet.
 */
export function usePlateLevelFeatures(
  dataset: DatasetId | null,
  plate: string | null,
  featureSet: string | null,
  {
    featureSetColumns,
    groupByColumns,
    metadataColumns,
    start,
    end,
  }: {
    featureSetColumns?: string[];
    groupByColumns?: string[];
    metadataColumns?: string[];
    start?: number;
    end?: number;
  },
): Fetchable<UntypedFeatures> {
  const datasetApi = useDatasetApi(dataset ? { dataset } : { skip: true });

  return _useRecordFeatures(
    (plate &&
      featureSet &&
      datasetApi?.url("plates/<plate>/features/<featureSet>", {
        plate,
        featureSet,
      })) ??
      null,
    {
      featureSetColumns,
      groupByColumns,
      metadataColumns,
      start,
      end,
      plate,
    },
  );
}

export type UmapResult = {
  umapDB: DB;
};

export const useUmap = makeDatasetApi(({ useBatch }: { useBatch: boolean }) =>
  useBatch ? "umap_batch" : "umap",
)<
  UmapResult,
  {
    sqlFilter: FilterSqlClause;
    features: string[];
    plates: string[];
    cacheInteractionPolicy?: "read-required" | "read-write" | "none";
    columns?: string[];
    groupByColumns?: string[];
    zScore?: boolean;
    useBatch?: boolean;
    distanceMetric?: "euclidean" | "cosine";
    useGPU?: boolean;
    normalizationConfig?: NormalizationConfig;
  }
>(undefined, (options) => {
  const params: { [key: string]: string[] | string | NormalizationConfig } = {
    sql_filter: options.sqlFilter,
    features: options.features,
    plates: options.plates,
  };
  if (options.cacheInteractionPolicy) {
    params["cache_interaction_policy"] = options.cacheInteractionPolicy;
  }
  if (options.columns) {
    params["columns"] = options.columns;
  }
  if (options.groupByColumns) {
    params["group_by"] = options.groupByColumns;
  }
  if (options.distanceMetric) {
    params["distance_metric"] = options.distanceMetric;
  }
  if (options.normalizationConfig) {
    params["normalization_config"] = options.normalizationConfig;
  } else if (options.zScore) {
    params["z_score"] = "1";
  }

  return {
    requestInit: jsonPostInit(params),
    fetchKind: "blob",
    transform: async (blob: Blob) => ({ umapDB: await makeUmapDB(blob) }),
    // For batch processing, we revert to CPU-based processing.
    gpu: options.useGPU && !options.useBatch,
  };
});

/**
 * Hook to fetch raw feature data from a FeatureSet as a DuckDB DB.
 */
export function useFetchFeaturesAsDB({
  dataset,
  plate,
  featureSet,
  featureSetColumns,
  groupByColumns,
  metadataColumns,
  start,
  end,
  skip,
}: CanSkip<{
  dataset: DatasetId;
  plate: string | null;
  featureSet: string;
  featureSetColumns?: string[];
  groupByColumns?: string[];
  metadataColumns?: string[];
  start?: number;
  end?: number;
}>): Fetchable<DB> {
  const datasetApi = useDatasetApi(!skip ? { dataset } : { skip: true });

  const plateBasedFeatures = _useFeatures(
    !skip && plate && datasetApi
      ? datasetApi.url("plates/<plate>/features/<featureSet>", {
          plate,
          featureSet,
        })
      : null,
    {
      featureSetColumns,
      groupByColumns,
      metadataColumns,
      start,
      end,
      plate: plate ?? null,
    },
  );

  const nonPlateBasedFeatures = _useFeatures(
    !skip && datasetApi
      ? datasetApi.url("features/<featureSet>", {
          featureSet,
        })
      : null,
    {
      featureSetColumns,
      groupByColumns,
      metadataColumns,
      start,
      end,
      plate: null,
    },
  );

  return plateBasedFeatures ?? nonPlateBasedFeatures;
}

/**
 * Fetch "WellAggregated" features for a specific column for records matching the
 * data filter criteria.
 */
export const useWellAggregatedFeature = makeDatasetApi(
  "well-aggregated/<feature>/<column>",
)<
  DB,
  {
    sqlFilter: FilterSqlClause;
    feature: `WellAggregated${string}`;
  }
>(
  ({ sqlFilter }) => ({
    // TODO(colin): find where we're sometimes getting an `undefined` sqlFilter that isn't
    // getting picked up by type checking and fix.
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    sql_filter: sqlFilter ?? "TRUE",
  }),
  () => {
    return {
      // Note(davidsharff): this is a band-aid. SWR is caching individual/indistinguishable DBs for every feature request.
      // The secondary DB emission after revalidation causes various issues (like double loading states) because components
      // can't inspect the DB to detect if the values are new.
      configuration: {
        revalidateOnReconnect: false,
        revalidateIfStale: false,
        revalidateOnFocus: false,
      },
      fetchKind: "blob",
      transform: (blob: Blob) => makeFeatureDb(blob, null),
    };
  },
);

/**
 * Fetch "WellAggregated" features for a specific column for records matching the
 * data filter criteria.
 */
export const useFeatureFilteredWellAggregatedFeature = makeDatasetApi(
  "features/<feature>/column/<featureColumn>/well_agg/filtered_by/<filterFeature>/column/<filterFeatureColumn>",
)<
  DB,
  {
    filter?: FeatureFilter;
  }
>(
  ({ filter }) => ({ filter: JSON.stringify(filter?.params ?? null) }),
  () => ({
    // Note(davidsharff): this is a band-aid. SWR is caching individual/indistinguishable DBs for every feature request.
    // The secondary DB emission after revalidation causes various issues (like double loading states) because components
    // can't inspect the DB to detect if the values are new.
    configuration: {
      revalidateOnReconnect: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
    fetchKind: "blob",
    transform: (blob: Blob) => makeFeatureDb(blob, null),
  }),
);

/**
 * Fetch "WellAggregated" features for all columns for records matching the
 * data filter criteria.
 */
export const useSelectedPointEnrichment = makeDatasetApi(
  "enrichment/<feature>",
)<
  KSTestEnrichmentRecord[],
  {
    sqlFilter: FilterSqlClause;
    selectedPoints: Set<string>;
    feature?: `WellAggregated${string}`;
  }
>(
  ({ sqlFilter }) => ({
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    sqlFilter: sqlFilter ?? "TRUE",
  }),
  ({ sqlFilter, selectedPoints }) => ({
    requestInit: jsonPostInit({
      // TODO(you): Fix this no-unnecessary-condition rule violation
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      sql_filter: sqlFilter ?? "TRUE",
      selected_points: Array.from(selectedPoints),
    }),
    skip: selectedPoints.size === 0,
  }),
);

/**
 * Internal hook to fetch raw feature data from a FeatureSet.
 */
function _useRecordFeatures(
  baseUrl: string | null,
  {
    featureSetColumns,
    groupByColumns,
    metadataColumns,
    start,
    end,
    plate,
  }: {
    featureSetColumns?: string[];
    groupByColumns?: string[];
    metadataColumns?: string[];
    start?: number;
    end?: number;
    plate: string | null;
  },
): Fetchable<UntypedFeatures> {
  const db = _useFeatures(baseUrl, {
    featureSetColumns,
    groupByColumns,
    metadataColumns,
    start,
    end,
    plate,
  });
  return useFetchableQueryAsRecords<UntypedFeatures[number]>(
    db,
    sql`SELECT * FROM features`,
  );
}

/**
 * Internal hook to fetch raw feature data from a FeatureSet as a DuckDB database.
 */
function _useFeatures(
  baseUrl: string | null,
  {
    featureSetColumns,
    groupByColumns,
    metadataColumns,
    start,
    end,
    plate,
  }: {
    featureSetColumns?: string[];
    groupByColumns?: string[];
    metadataColumns?: string[];
    start?: number;
    end?: number;
    plate: string | null;
  },
): Fetchable<DB> {
  const queryParams = [];
  if (groupByColumns) {
    queryParams.push(
      `groupBy=${encodeURIComponent(JSON.stringify(groupByColumns))}`,
    );
  }
  if (metadataColumns) {
    queryParams.push(
      `metadataColumns=${encodeURIComponent(JSON.stringify(metadataColumns))}`,
    );
  }
  if (featureSetColumns) {
    queryParams.push(
      `featureSetColumns=${encodeURIComponent(
        JSON.stringify(featureSetColumns),
      )}`,
    );
  }
  if (start) {
    queryParams.push(`start=${encodeURIComponent(start)}`);
  }
  if (end) {
    queryParams.push(`end=${encodeURIComponent(end)}`);
  }

  const urlAsGet = baseUrl && `${baseUrl}?${queryParams.join("&")}`;

  // TODO(benkomalo): consolidate this "use-get-but-fallback-to-post" logic in a shared
  // utility.
  // TODO(benkomalo): the server endpoint for fetching features no longer supports POST?
  // it was re-written to Rust and I think the POST version got dropped?

  // These urls can sometimes get longer than the max that appengine permits. In
  // this case, we have a workaround fallback of submitting via post request
  // with the url params in the request body.
  let method: "GET" | "POST" = "GET";
  let url = urlAsGet;
  let body: string | null = null;
  let headers = {};
  if (urlAsGet && urlAsGet.length > 4094) {
    method = "POST";
    url = baseUrl;
    body = JSON.stringify({
      groupBy: groupByColumns,
      metadataColumns: metadataColumns,
      featureSetColumns: featureSetColumns,
      start: start,
      end: end,
    });
    headers = { "Content-Type": "application/json" };
  }
  const transform = useCallback((blob) => makeFeatureDb(blob, plate), [plate]);
  return useAuthenticatedFetchable<DB>(
    url,
    {
      method: method,
      headers: headers,
      ...(body ? { body } : {}),
    },
    // Note(davidsharff): this is a band-aid. SWR is caching individual/indistinguishable DBs for every feature request.
    // The secondary DB emission after revalidation causes various issues (like double loading states) because components
    // can't inspect the DB to detect if the values are new.
    {
      revalidateOnReconnect: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
    "blob",
    transform,
  );
}

/**
 * Consolidate a features DB with a sample_metadata DB so they can be joined/filtered.
 *
 * This is a semi-hacky way our app can do JOINs across feature and metadata tables,
 * while still keeping the existing, organic "bottoms up" approach of having individual
 * components request the data and create individual DB instances all over the place.
 * Over time, as our app matures, we should move towards shared contexts and
 * consolidated DBs to not have to do this post-hoc merge.
 */
export function useFeaturesWithMetadata(
  featuresDB: DB | null,
  metadataDB: DB | null,
): Fetchable<DB> {
  const [results, setResults] = useState<Fetchable<DB>>(undefined);

  useEffect(() => {
    if (featuresDB && metadataDB) {
      let cancelled = false;
      copyTableAcrossDB(metadataDB, featuresDB, "sample_metadata").then(
        (result) => !cancelled && setResults(Success.of(result)),
        (err) => !cancelled && setResults(Failure.of(err)),
      );

      return () => {
        cancelled = true;
      };
    }
  }, [featuresDB, metadataDB]);

  return results;
}

/**
 * Generate a SELECT query to select rows of a FeatureSet with a filter applied.
 * @param selectClause The select clause (the part between SELECT and FROM). Defaults
 *    to just "*" to select all columns
 * @param filter The filter as a SQL Where clause
 */
export function createSelectQueryForFilteredFeatures(
  selectClause: string,
  filter: FilterSqlClause,
): ValidatedSQL {
  // TODO(benkomalo): we should pre-aggregate the metadata on a well level to not have
  // to do the group by on each query.
  return sql`
    SELECT ${selectClause} FROM (
        SELECT plate, well
        FROM sample_metadata
        WHERE ${filter}
        GROUP BY plate, well
    ) metadata
    JOIN features
    ON metadata.plate = features.plate AND metadata.well = features.well
  `;
}

/**
 * Hook to fetch metadata for a given FeatureSet
 */
export const useFeatureSetMetadata = makeDatasetApi(
  "feature/<featureSet>/metadata",
)<FeatureSetMetadata>(undefined, () => ({ requestInit: jsonPostInit({}) }));
