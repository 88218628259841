/**
 * Component to render the filter selection UI.
 *
 * Allows the user to filter out sections.
 */
import { AsyncDuckDB } from "@duckdb/duckdb-wasm";
import * as Popover from "@radix-ui/react-popover";
import { css } from "aphrodite";
import cx from "classnames";
import {
  ForwardedRef,
  ReactNode,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { Filter as FilterIcon, Plus } from "react-feather";
import * as vega from "vega";
import { shared } from "../../megamap-styles";
import { FilterPreview } from "./FilterPreview";
import FilterRow from "./FilterRow";
import { TypedColumn } from "./backend-types";
import { Operator } from "./operations/filter-by";
import { Filter, FilterSet, isNestedFilter } from "./types";
import {
  clausesFromFilterSet,
  getDefaultFilter,
  getDefaultFilterColumn,
  isActive,
  serializeToSqlClause,
} from "./utils";

type FilterProps = {
  columns: TypedColumn[];
  // Whatever filtering is being applied before this row
  preFilter?: FilterSet;
  filterSet: FilterSet;
  maxDepth?: number;
  placeholder?: ReactNode;
  showColors?: boolean;
  showPreview?: boolean;
  depth?: number;
  colorIndexOffset?: number;
  onChangeFilters: (filters: Filter[]) => void;
  onChangeOperator: (operator: Operator, newFilters?: Filter[]) => void;
  onChangeSelected?: (index: number | null) => void;
  metadata: AsyncDuckDB;
};

export default function FilterSelector({
  columns,
  colorIndexOffset,
  depth,
  preFilter,
  filterSet,
  maxDepth,
  placeholder,
  showColors,
  showPreview,
  onChangeFilters,
  onChangeOperator,
  onChangeSelected,
  metadata,
}: FilterProps) {
  const { filters, operator } = filterSet;
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const ignoredFilters =
    operator === Operator.AND ? filterSet.ignoredFilters : [];

  // When signalling a change in filters, the flow is such that we try to put everything
  // into a single, consolidated filter list. Clients that are interested in preventing
  // invalid states will segment appropriately and pass down the correctly segmented
  // value in the FilterSet, if it's a conjunction that can't be satisfied.
  const handleChangingFilters = useCallback(
    (filters: Filter[], ignored: Filter[]) => {
      onChangeFilters([...filters, ...ignored]);
    },
    [onChangeFilters],
  );

  const sql = useMemo(() => {
    if (showPreview) {
      return serializeToSqlClause(filterSet);
    }
  }, [filterSet, showPreview]);

  const clauses = useMemo(() => {
    if (showPreview) {
      return clausesFromFilterSet(filterSet);
    } else {
      return [];
    }
  }, [filterSet, showPreview]);

  const getColor = useMemo(() => {
    const colorScheme = vega.scheme(
      clauses.length > 10 ? "tableau20" : "tableau10",
    );

    return (index: number) => {
      return colorScheme[index % colorScheme.length];
    };
  }, [clauses.length]);

  // Compute the filter that's being applied before this one is; used to color the
  // options to indicate values that won't result in any rows being selected
  const rowPreFilter = useCallback(
    (previous: Filter[]): FilterSet | undefined => {
      return operator === Operator.AND && previous.length > 0
        ? preFilter
          ? {
              operator: Operator.AND,
              filters: [{ type: "nested", filterSet: preFilter }, ...previous],
              ignoredFilters: [],
            }
          : {
              operator: Operator.AND,
              filters: previous,
              ignoredFilters: [],
            }
        : preFilter;
    },
    [operator, preFilter],
  );

  const inverseOperator =
    operator === Operator.AND ? Operator.OR : Operator.AND;

  let colorIndex = colorIndexOffset ?? 0;

  return (
    <div>
      {filters.length > 0 ? (
        <div className={"tw-px-2 tw-pt-md"}>
          {filters.map((filter, index, all) => {
            const row = (
              <FilterRow
                key={index}
                index={index}
                depth={depth}
                maxDepth={maxDepth}
                preFilter={rowPreFilter(all.slice(0, index))}
                filter={filter}
                operator={operator}
                columns={columns}
                showColors={showColors || showPreview}
                onRemove={() => {
                  const newFilters = [...filters];
                  newFilters.splice(index, 1);
                  handleChangingFilters(newFilters, ignoredFilters);
                }}
                onChange={(filter) => {
                  const newFilters = [...filters];
                  newFilters[index] = filter;
                  handleChangingFilters(newFilters, ignoredFilters);
                }}
                onChangeOperator={onChangeOperator}
                colorIndex={colorIndex}
                color={
                  showColors || showPreview ? getColor(colorIndex) : undefined
                }
                onChangeSelected={onChangeSelected ?? setSelectedIndex}
              />
            );

            colorIndex +=
              1 +
              (isNestedFilter(filter)
                ? filter.filterSet.operator === Operator.AND
                  ? filter.filterSet.filters.length +
                    filter.filterSet.ignoredFilters.length
                  : filter.filterSet.filters.length
                : 0);

            return row;
          })}
        </div>
      ) : (
        <div className={"tw-p-2 " + css(shared.quieter)}>
          {placeholder || "No filters applied."}
        </div>
      )}
      {ignoredFilters.length > 0 && (
        <>
          <div
            className={
              "tw-px-2 tw-pt-sm tw-text-red-500 tw-text-sm tw-max-w-full"
            }
          >
            Filters below cannot be applied since there there is no matching
            data. Please try adjusting your criteria.
          </div>
          <div className={"tw-p-2 tw-pt-1 tw-opacity-60"}>
            {ignoredFilters.map((filter, index) => (
              <FilterRow
                key={index}
                index={filters.length + index}
                depth={depth}
                maxDepth={maxDepth}
                filter={filter}
                operator={operator}
                columns={columns}
                onRemove={() => {
                  const newFilters = [...filters];
                  newFilters.splice(index, 1);
                  handleChangingFilters(filters, newFilters);
                }}
                onChange={(filter) => {
                  const newFilters = [...ignoredFilters];
                  newFilters[index] = filter;
                  handleChangingFilters(filters, newFilters);
                }}
                onChangeOperator={onChangeOperator}
                color={getColor(filters.length + index)}
                onChangeSelected={setSelectedIndex}
              />
            ))}
          </div>
        </>
      )}
      {columns.length > 0 && (
        <div
          className={cx(
            "tw-flex tw-flex-row",
            "tw-pl-xs",
            (depth === 0 || depth === undefined) && "tw-pb-sm",
          )}
        >
          <button
            className={cx(
              "tw-mx-sm tw-px-xs tw-rounded",
              "tw-flex tw-flex-row tw-gap-sm tw-items-center",
              "tw-text-slate-500",
              depth === 0 ? "hover:tw-bg-gray-100" : "hover:tw-bg-gray-200",
            )}
            onClick={() => {
              onChangeFilters([
                ...filters,
                getDefaultFilter(getDefaultFilterColumn(columns, filters)),
              ]);
            }}
          >
            <Plus size={12} />{" "}
            <span className="tw-min-w-[41px] tw-inline-block tw-text-right">
              {filters.length > 0 ? operator : "Add Filter"}...
            </span>
          </button>
          {filters.length === 1 && ignoredFilters.length === 0 && (
            <button
              className={cx(
                "tw-mx-sm tw-px-xs tw-rounded",
                "tw-flex tw-flex-row tw-gap-sm tw-items-center",
                "tw-text-slate-500",
                depth === 0 ? "hover:tw-bg-gray-100" : "hover:tw-bg-gray-200",
              )}
              onClick={() => {
                onChangeOperator(inverseOperator, [
                  getDefaultFilter(getDefaultFilterColumn(columns, filters)),
                ]);
              }}
            >
              <Plus size={12} />{" "}
              <span className="tw-min-w-[41px] tw-inline-block tw-text-right">
                {inverseOperator}...
              </span>
            </button>
          )}
        </div>
      )}
      {/* TODO(you): Fix this no-unnecessary-condition rule violation */}
      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
      {sql && clauses && clauses.some((clause) => clause !== undefined) && (
        <div className="tw-border-t">
          <FilterPreview
            sql={sql}
            clauses={clauses}
            operator={operator}
            getColor={getColor}
            selectedIndex={selectedIndex}
            metadata={metadata}
          />
        </div>
      )}
    </div>
  );
}

export type CollapsibleFilterSelectorRef = {
  openFilterSelector: () => void;
  /**
   * Useful for injecting arbitrary UI above the filter.
   */
  setFilterHeader: (header: ReactNode) => void;
};

export const CollapsibleFilterSelector = forwardRef(_CollapsibleFilterSelector);
function _CollapsibleFilterSelector(
  props: FilterProps & {
    triggerClasses?: string;
    placeholder?: string;
  },
  ref: ForwardedRef<CollapsibleFilterSelectorRef>,
) {
  const [isOpen, setIsOpen] = useState(false);
  const [header, setHeader] = useState<ReactNode>(null);
  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useImperativeHandle(ref, () => ({
    openFilterSelector() {
      setIsOpen(true);
    },
    setFilterHeader(header: ReactNode) {
      setHeader(header);
    },
  }));

  const activeFilters = props.filterSet.filters.filter(isActive);

  return (
    <Popover.Root open={isOpen} onOpenChange={handleToggleOpen}>
      <Popover.Trigger className={props.triggerClasses}>
        <div className={"tw-flex tw-items-center"}>
          <FilterIcon size={16} />
          <span className={"tw-ml-1 tw-truncate"}>
            {activeFilters.length > 0
              ? summarizeFilters(activeFilters)
              : props.placeholder || "Filter"}
          </span>
        </div>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content align={"start"} className="tw-z-dialog">
          <div
            className={"tw-border tw-bg-white tw-shadow-lg tw-min-w-[320px]"}
          >
            {header}
            <FilterSelector {...props} />
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

function summarizeFilters(filters: Filter[]): string {
  if (filters.length === 1 && !isNestedFilter(filters[0])) {
    return `Filtering on ${filters[0].column.name}`;
  } else {
    return `${filters.length} filters applied`;
  }
}
