export type ColumnType =
  | "text"
  | "multilinetext"
  | "number"
  | "checkbox"
  | "select"
  | "multiselect";

export type TextColumn = {
  id: string;
  name: string;
  description?: string;
  type: "text";
  typeOptions: Record<string, never>;
};

export type MultilineTextColumn = {
  id: string;
  name: string;
  description?: string;
  type: "multilineText";
  typeOptions: Record<string, never>;
};

export type NumberColumn = {
  id: string;
  name: string;
  description?: string;
  type: "number";
  typeOptions: Record<string, never>;
};

export type CheckboxColumn = {
  id: string;
  name: string;
  description?: string;
  type: "checkbox";
  typeOptions: Record<string, never>;
};

type Color =
  | "blue"
  | "cyan"
  | "teal"
  | "green"
  | "yellow"
  | "orange"
  | "red"
  | "pink"
  | "purple"
  | "gray";

type ColorSuffix = "" | "Dark" | "Darker" | "Medium";

export type ColorTheme = `${Color}${ColorSuffix}` | "foreignKey" | "generic";
export type Choice = { id: string; name: string; color: ColorTheme };

export type SelectColumn = {
  id: string;
  name: string;
  description?: string;
  type: "select";
  typeOptions: {
    choices: Choice[];
  };
};

export type MultiSelectColumn = {
  id: string;
  name: string;
  description?: string;
  type: "multiSelect";
  typeOptions: {
    choices: Choice[];
  };
};

export type TypedColumn =
  | TextColumn
  | MultilineTextColumn
  | NumberColumn
  | CheckboxColumn
  | SelectColumn
  | MultiSelectColumn;

type ColumnWithType<
  T extends TypedColumn["type"],
  All = TypedColumn,
> = All extends { type: T } ? All : never;

export function checkColumnType<T extends TypedColumn["type"]>(
  column: TypedColumn,
  ...types: T[]
): column is ColumnWithType<T> {
  return types.includes(column.type as T);
}
