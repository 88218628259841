export class AccessToken {
  private token: string;

  constructor(token: string) {
    this.token = token;
  }

  header() {
    return `Bearer ${this.token}`;
  }
}
