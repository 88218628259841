import cx from "classnames";
import * as React from "react";
import { WrapperProps } from "src/types";

const Menu = React.forwardRef<HTMLDivElement, WrapperProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cx(
          "tw-flex tw-flex-col tw-z-dialog",
          "tw-py-sm tw-rounded",
          "tw-bg-white tw-border tw-border-slate-200 tw-shadow-lg",
          className,
        )}
        {...props}
      >
        {children}
      </div>
    );
  },
);
Menu.displayName = "Menu";

export { Menu };
