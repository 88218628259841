import { createStore } from "redux";
import { AppChromeNavState, AppChromeNavStyle, Tab } from "./types";

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type SetTabsAction = {
  readonly type: "set-tabs";
  tabs: Tab[];
};

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type SetStyleAction = {
  readonly type: "set-style";
  style: AppChromeNavStyle;
};

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type Action = SetTabsAction | SetStyleAction;

function navStateReducer(
  inputState: AppChromeNavState | undefined,
  action: Action,
): AppChromeNavState {
  if (inputState == undefined) {
    // Default state.
    inputState = {
      tabs: [],
      activeExperiment: null,
      style: "raised",
    };
  }

  switch (action.type) {
    case "set-tabs":
      return {
        ...inputState,
        tabs: action.tabs,
      };
    case "set-style":
      return {
        ...inputState,
        style: action.style,
      };
  }
}

export default createStore(navStateReducer);
