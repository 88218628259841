import { ReactNode } from "react";
import { PopupTargetText } from "./PopupTargetText";
import {
  GroupSignificanceRecordsType,
  LabMateView,
  SignificanceRecord,
} from "./types";
import { sortBySignificance } from "./util";

export function LabMateRepresentationSummary({
  groupedSignificanceRecords,
  onChangeView,
  onShowPopup,
  onClosePopup,
}: {
  groupedSignificanceRecords: GroupSignificanceRecordsType;
  onChangeView: (view: LabMateView) => void;
  onShowPopup: (
    e: HTMLElement,
    significanceRecords: SignificanceRecord[],
  ) => void;
  onClosePopup: () => void;
}) {
  return (
    <>
      {/* TODO(you): Fix this no-unnecessary-condition rule violation */}
      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
      {groupedSignificanceRecords.over?.length && (
        <LabMateSummarySentence
          significanceRecords={groupedSignificanceRecords.over}
          onViewDetails={() => onChangeView("over")}
          onShowPopup={onShowPopup}
          onClosePopup={onClosePopup}
        />
      )}
      {/* TODO(you): Fix this no-unnecessary-condition rule violation */}
      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
      {groupedSignificanceRecords.under?.length && (
        <LabMateSummarySentence
          significanceRecords={groupedSignificanceRecords.under}
          onViewDetails={() => onChangeView("under")}
          onShowPopup={onShowPopup}
          onClosePopup={onClosePopup}
        />
      )}
    </>
  );
}

const SpacedWord = ({
  children,
  style,
}: {
  children: ReactNode;
  style?: Record<string, string>;
}) => (
  <span
    style={{
      marginRight: "2px",
      ...(style || {}),
    }}
  >
    {children}
  </span>
);

function LabMateSummarySentence({
  significanceRecords,
  onViewDetails,
  onShowPopup,
  onClosePopup,
}: {
  significanceRecords: SignificanceRecord[];
  onViewDetails: () => void;
  onShowPopup: (
    e: HTMLElement,
    significanceRecords: SignificanceRecord[],
  ) => void;
  onClosePopup: () => void;
}) {
  // Sanity sort (ostensibly they are)
  const sortedMetadata = significanceRecords.sort(sortBySignificance);
  // TODO(davidsharff) consider replacing enum values with over/under and updating var names if we never use more/less.
  const direction = sortedMetadata[0].representationType;

  const metadataLabels = Array.from(
    new Set(sortedMetadata.map(({ metadataColumn }) => metadataColumn)),
  );
  const maxVisible = 3;
  const visibleLabels = metadataLabels.slice(0, maxVisible);
  const remainderLabels = metadataLabels.slice(maxVisible);

  const getRecordsByLabel = (label: string) =>
    sortedMetadata.filter(({ metadataColumn }) => metadataColumn === label);

  // TODO(davidsharff): refactor so that the user can hover on the popup and only set onMouseEnter once with wrapped component
  const metadataList = remainderLabels.length ? (
    <>
      {visibleLabels.map((label) => (
        <PopupTargetText
          key={label}
          text={label}
          onMouseEnter={(e) =>
            onShowPopup(
              e.currentTarget as HTMLElement,
              getRecordsByLabel(label),
            )
          }
          onMouseLeave={onClosePopup}
        />
      ))}
      <SpacedWord>and</SpacedWord>
      <span>
        <PopupTargetText
          text={
            remainderLabels.length === 1
              ? remainderLabels[0]
              : `${remainderLabels.length} More`
          }
          onMouseEnter={(e) =>
            onShowPopup(
              e.currentTarget as HTMLElement,
              remainderLabels.flatMap((label) => getRecordsByLabel(label)),
            )
          }
          onMouseLeave={onClosePopup}
        />
      </span>
    </>
  ) : visibleLabels.length === 1 ? (
    <PopupTargetText
      text={visibleLabels[0]}
      onMouseEnter={(e) =>
        onShowPopup(
          e.currentTarget as HTMLElement,
          getRecordsByLabel(visibleLabels[0]),
        )
      }
      onMouseLeave={onClosePopup}
    />
  ) : (
    <>
      {visibleLabels.slice(0, -1).map((label) => (
        <PopupTargetText
          key={label}
          text={label}
          onMouseEnter={(e) =>
            onShowPopup(
              e.currentTarget as HTMLElement,
              getRecordsByLabel(label),
            )
          }
          onMouseLeave={onClosePopup}
        />
      ))}
      <SpacedWord>and</SpacedWord>
      <PopupTargetText
        text={visibleLabels.slice(-1)[0]}
        onMouseEnter={(e) =>
          onShowPopup(
            e.currentTarget as HTMLElement,
            getRecordsByLabel(visibleLabels.slice(-1)[0]),
          )
        }
        onMouseLeave={onClosePopup}
      />
    </>
  );

  return (
    <div className="tw-mb-4">
      <div className="tw-flex tw-text-sm tw-items-start tw-flex-wrap tw-mb-1">
        <SpacedWord>Values</SpacedWord>
        <SpacedWord>for</SpacedWord>
        {metadataList}
        <SpacedWord>are</SpacedWord>
        <SpacedWord>significantly</SpacedWord>
        <SpacedWord style={{ fontWeight: "500" }}>
          {direction}represented
        </SpacedWord>
        <SpacedWord>in</SpacedWord>
        <SpacedWord>your</SpacedWord>
        <span>selection.</span>
      </div>
      <div
        onClick={onViewDetails}
        style={{
          fontSize: "14px",
        }}
        className="tw-cursor-pointer tw-inline-flex tw-text-blue"
      >
        Details
      </div>
    </div>
  );
}
