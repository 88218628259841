import { ZoomIn, ZoomOut } from "react-feather";
import type { Icon } from "react-feather";
import { Slider } from "@spring/ui/Slider";
import Strut from "../Strut";

export default function RenderSizeSlider({
  min,
  max,
  size,
  onChange,
  icons = [ZoomOut, ZoomIn],
}: {
  min: number;
  max: number;
  size: number;
  onChange: (size: number) => void;
  icons?: [Icon, Icon];
}) {
  const [IconDecrease, IconIncrease] = icons;
  return (
    <div className={"tw-flex tw-items-center tw-justify-center"}>
      <span className={"tw-text-lg tw-text-slate-500"}>
        {<IconDecrease size={18} />}
      </span>
      <Strut size={16} />
      <Slider min={min} max={max} value={size} onValueChange={onChange} />
      <Strut size={16} />
      <span className={"tw-text-lg tw-text-slate-500"}>
        {<IconIncrease size={18} />}
      </span>
    </div>
  );
}
