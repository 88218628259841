/**
 * A component which renders FeatureSets and allows users to select features/columns.
 *
 * This abstracts away many of the quirks of the FeatureSet concept, and collates
 * certain features together that should conceptually be together, and enforces
 * some constraints about selection.
 */
import * as Popover from "@radix-ui/react-popover";
import cx from "classnames";
import { useCallback, useEffect, useState } from "react";
import {
  AnalyzeView,
  viewSupportsMultipleFeatures,
} from "src/FeatureSetManagementPage/views";
import { DatasetId } from "src/types";
import { usePrevious } from "../hooks/utils";
import CollapsedFeatureSelectorInfo from "./CollapsedFeatureSelectorInfo";
import FeatureSelectorMenuContents from "./FeatureSelectorMenuContents";
import { Context } from "./context";
import { ColumnsLookupFn, FeatureSetSelection } from "./types";
import { FeatureSetsByType, areSelectionsEqual } from "./utils";

export default function FeatureSelector({
  dataset,
  features,
  selections,
  onChangeSelections,
  isOpen,
  onOpenChange,
  selectedView,
  expandedWidth = 920,
  multi = true,
}: {
  dataset: DatasetId;
  features: FeatureSetsByType;
  selections: FeatureSetSelection[];
  onChangeSelections: (
    selections: FeatureSetSelection[],
    getColumns: ColumnsLookupFn,
  ) => void;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  selectedView?: AnalyzeView;
  expandedWidth?: number;
  multi?: boolean;
}) {
  const [columnsCache, setColumnsCache] = useState<{ [key: string]: string[] }>(
    {},
  );
  const isSelectMultiple = viewSupportsMultipleFeatures(selectedView);

  const updateColumnsCache = useCallback(
    (key: string, columns: string[]) => {
      setColumnsCache((cache) => ({
        ...cache,
        [key]: columns,
      }));
    },
    [setColumnsCache],
  );

  const updateSelections = useCallback(
    (selections: FeatureSetSelection[]) => {
      onChangeSelections(
        selections,
        (featureName: string) => columnsCache[featureName],
      );
    },
    [onChangeSelections, columnsCache],
  );

  // Note: we maintain two sets of state here:
  //   - the "real" selections, as viewed by the callers, and
  //   - a "pending" state, which is applied only on menu dismissal.
  const [_pendingSelections, setPendingSelections] =
    useState<FeatureSetSelection[]>(selections);

  // If the selections have been cleared out externally (e.g. we've navigated to a URL
  // that has no selections via the Analyze menu) reset the pending selection
  useEffect(() => {
    if (!isSelectMultiple || selections.length === 0) {
      setPendingSelections([]);
    }
  }, [isSelectMultiple, selections.length]);

  const previousOpen = usePrevious(isOpen);
  const setIsOpen = useCallback(
    (open: boolean) => {
      onOpenChange(open);
      if (!previousOpen && open) {
        setPendingSelections(selections);
      } else if (previousOpen && !open) {
        updateSelections(_pendingSelections);
      }
    },
    [
      onOpenChange,
      previousOpen,
      selections,
      updateSelections,
      _pendingSelections,
    ],
  );

  // If we're not allowing the selection of multiple features, ensure
  // that we're just writing one feature back and immediately closing the menu
  const setSingleFeature = useCallback(
    (newSelections: FeatureSetSelection[]) => {
      if (newSelections.length > 0) {
        updateSelections([newSelections[0]]);
        onOpenChange(false);
      }
    },
    [onOpenChange, updateSelections],
  );

  const handleRemoveSelection = useCallback(
    (selection: FeatureSetSelection) => {
      if (isOpen) {
        setPendingSelections((selections) =>
          selections.filter((s) => !areSelectionsEqual(s, selection)),
        );
      } else {
        updateSelections(
          selections.filter((s) => !areSelectionsEqual(s, selection)),
        );
      }
    },
    [updateSelections, selections, setPendingSelections, isOpen],
  );

  return (
    <Context.Provider
      value={{
        dataset,
        columnsCache,
        updateColumnsCache,
      }}
    >
      <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
        <Popover.Trigger
          className={cx(
            "tw-block tw-relative tw-h-full tw-w-full tw-text-left",
            isOpen && "tw-invisible",
          )}
          asChild
        >
          <span>
            <CollapsedFeatureSelectorInfo
              allFeatures={features}
              selections={isOpen ? _pendingSelections : selections}
              onRemoveSelection={handleRemoveSelection}
              renderMode={"compact"}
              multi={multi}
            />
          </span>
        </Popover.Trigger>
        <Popover.Content
          align={"start"}
          style={{ width: expandedWidth }}
          className={cx(
            // HACK(benkomalo): we pull the content vertically up so that the top
            // of the content lines up with the top of the anchor, which is the
            // collapsed feature selector state. We then render a _second_ copy
            // of the collapsed selector state with slightly different behaviour
            // (notably: it renders in "full" mode to show more information), so
            // the positioning here is done to have the second copy overlap the
            // initial, collapsed version.
            "tw-border tw-border-[#ccc] tw-rounded-lg tw-shadow-lg tw-flex tw-flex-col tw-bg-white",
            "tw-mt-[calc(-38px-theme(spacing.1))] -tw-ml-[1px]",
            "tw-max-h-[calc(100vh-theme(spacing.16)-theme(spacing.global-nav-height))]",
          )}
        >
          <div className={"tw-border-b tw-py-1"}>
            <CollapsedFeatureSelectorInfo
              allFeatures={features}
              selections={isOpen ? _pendingSelections : selections}
              onRemoveSelection={handleRemoveSelection}
              renderMode={"full"}
              multi={multi}
            />
          </div>
          <FeatureSelectorMenuContents
            className="tw-flex-1 tw-overflow-hidden"
            dataset={dataset}
            features={features}
            selections={_pendingSelections}
            onChangeSelections={
              isSelectMultiple ? setPendingSelections : setSingleFeature
            }
            multi={isSelectMultiple}
            selectedView={selectedView}
          />
        </Popover.Content>
      </Popover.Root>
    </Context.Provider>
  );
}
