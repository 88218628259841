import { useEffect, useRef, useState } from "react";
import { Overlay } from "react-overlays";
import { useRouteMatch } from "react-router-dom";
import { DatasetId, WorkspaceId } from "src/types";
import { omit } from "vega-lite";
import { Label, Subtitle } from "../../../../../workspaces/ui/src/typography";
import { Button } from "../../Common/Button";
import { FullScreenContainer } from "../../Common/FullScreenContainer";
import Loader from "../../Common/Loader";
import Pill from "../../Common/Pill";
import Strut from "../../Common/Strut";
// TODO(davidsharff): seems like our multi-select list should already be abstracted
import DropdownList from "../../Control/Dropdown/DropdownList";
import ChoiceToken from "../../Control/FilterSelector/FilterRow/ChoiceToken";
import { OverlayBody } from "../../Control/FilterSelector/OverlayParent";
import { useToastContext } from "../../Toast/context";
import { generateId } from "../../util/function-util";
import { ColorTheme } from "../../util/generic-color-palette";
import { useFetchPalettes, usePublish } from "../hooks";
import { Palette, PalettesResponse, PublishPostBody } from "../types";
import { Input } from "./helpers";

// TODO(davidsharff): make original channel names read only when they are available
type PendingPalette = { palette: Palette; plates: string[] };

export function Publish() {
  const match = useRouteMatch<{
    workspaceId: WorkspaceId;
    datasetId: DatasetId;
  }>();
  const { workspaceId, datasetId } = match.params;
  const [pendingPalettes, setPendingPalettes] = useState<
    PendingPalette[] | null
  >(null);
  const [isPublishing, setIsPublishing] = useState(false);

  const palettesReq = useFetchPalettes(workspaceId, datasetId);

  const publishManifest = usePublish(workspaceId, datasetId);

  const { setToast } = useToastContext();

  useEffect(() => {
    if (palettesReq?.successful) {
      const palettes = createPalettes(palettesReq.value);
      setPendingPalettes(
        palettes.map((palette, i) => ({
          palette,
          plates: palettesReq.value.plates
            .filter(({ palette_number }) => palette_number === i)
            .map(({ plate }) => plate),
        })),
      );
    }
  }, [palettesReq]);

  if (!palettesReq) {
    return (
      <FullScreenContainer center>
        <Loader />
      </FullScreenContainer>
    );
  }

  if (!palettesReq.successful) {
    const msg =
      palettesReq.error.message === "File not found"
        ? "No file found. Refresh and try again."
        : "Oops. Something went wrong fetching data.";

    return <div>{msg}</div>;
  }

  // TODO(davidsharff): this should save the palette
  const handleSubmitPalettes = (updatedPalettes: Palette[]) => {
    setPendingPalettes((prev) => {
      if (!prev) {
        return null;
      }

      return updatedPalettes.map((palette, index) => ({
        plates: prev[index] ? prev[index].plates : [],
        palette,
      }));
    });
  };

  const handleChangePalettePlates = (
    paletteIndex: number,
    updatedPlates: string[],
  ) => {
    setPendingPalettes(
      (prev) =>
        prev?.map((item, index) =>
          index === paletteIndex ? { ...item, plates: updatedPlates } : item,
        ) || prev,
    );
  };

  const handlePublish = async () => {
    if (!pendingPalettes) {
      throw new Error("Missing palette definition");
    }
    try {
      setIsPublishing(true);
      await publishManifest(createPublishPostBody(pendingPalettes));
      setToast("publish", "Publish complete");
    } catch (e) {
      console.error(e);
      setToast("publish", "Publish failed! See console for more details");
    } finally {
      setIsPublishing(false);
    }
  };

  const plates = palettesReq.value.plates.map(({ plate }) => plate);

  const assignedPlates = pendingPalettes?.flatMap((p) => p.plates) || [];
  const canPublish = !isPublishing && assignedPlates.length === plates.length;

  return (
    <div>
      <Palettes
        palettes={pendingPalettes?.map((p) => p.palette) || []}
        onSubmit={handleSubmitPalettes}
      />

      {!pendingPalettes ? (
        <Loader />
      ) : (
        <PlatePaletteMap
          pendingPalettes={pendingPalettes}
          plates={plates}
          onChange={handleChangePalettePlates}
        />
      )}
      <Button
        variant="primary"
        className="tw-mr-sm tw-mt-md"
        disabled={!canPublish}
        onClick={handlePublish}
      >
        Publish
      </Button>
    </div>
  );
}

function Palettes({
  palettes,
  onSubmit,
}: {
  palettes: Palette[];
  onSubmit: (updatedPalettes: Palette[]) => void;
}) {
  const paletteIds = useRef(new Map()).current;

  // Note(davidsharff): we need to use a stable id for the react key
  // since relying on index causes removing a palette to be buggy.
  const getPaletteId = (palette: Palette) => {
    // New row: generate and return an id
    if (!paletteIds.has(palette)) {
      const newId = generateId("p");
      paletteIds.set(palette, newId);
      return newId;
    }
    return paletteIds.get(palette);
  };

  const handleSubmitPalette = (
    updatedPalette: Palette,
    paletteIndex: number,
  ) => {
    onSubmit(palettes.map((p, i) => (i === paletteIndex ? updatedPalette : p)));
  };

  const handleAddPalette = () => {
    const newPalette = new Array(palettes[0].length).fill({
      dye_name: "",
      emission_nm: 0,
    });
    onSubmit([...palettes, newPalette]);
  };

  const handleRemovePalette = (paletteIndex: number) => {
    const newPalettes = palettes.filter((_, i) => i !== paletteIndex);
    onSubmit(newPalettes);
  };

  return (
    <div className="tw-max-w-[500px]">
      <Subtitle>Palettes</Subtitle>
      {palettes.map((palette, i) => (
        <div key={getPaletteId(palette)}>
          <PaletteWidget
            palette={palette}
            title={`Palette ${i + 1}`}
            onSubmit={(updatedPalette: Palette) =>
              handleSubmitPalette(updatedPalette, i)
            }
            onRemove={i > 0 ? () => handleRemovePalette(i) : undefined}
          />
        </div>
      ))}
      <Button onClick={handleAddPalette}>Add Palette</Button>
    </div>
  );
}

function PaletteWidget({
  palette,
  title,
  onSubmit,
  onRemove,
}: {
  palette: Palette;
  title: string;
  onSubmit: (updatedPalette: Palette) => void;
  onRemove?: () => void;
}) {
  const [isEditing, setIsEditing] = useState(
    palette.every((o) => Object.values(o).every((v) => !v)),
  );

  const handleSubmit = (updatedPalette: Palette) => {
    onSubmit(updatedPalette);
    setIsEditing(false);
  };

  return (
    <div className="tw-my-sm tw-flex tw-flex-col">
      <div className="tw-flex tw-items-center tw-justify-between tw-my-sm">
        <Label>{title}</Label>
        <div className="tw-flex">
          {onRemove && (
            <Button
              borderless
              variant="secondary"
              className="tw-mr-sm"
              onClick={onRemove}
            >
              Remove
            </Button>
          )}
          {!isEditing && (
            <Button borderless onClick={() => setIsEditing(true)}>
              Edit
            </Button>
          )}
        </div>
      </div>
      {isEditing ? (
        <EditablePalette
          palette={palette}
          onSubmit={handleSubmit}
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <ReadOnlyPalette palette={palette} />
      )}
    </div>
  );
}

function ReadOnlyPalette({ palette }: { palette: Palette }) {
  return (
    <div className="tw-border tw-border-slate-300 tw-p-sm">
      {palette.map((dye, index) => (
        <div key={index} className="tw-flex tw-mb-sm">
          <span className="tw-mr-sm tw-flex-1">
            {dye.dye_name || "Missing name!"}
          </span>
          <span className="tw-flex-1">{dye.emission_nm}</span>
        </div>
      ))}
    </div>
  );
}

function EditablePalette({
  palette,
  onSubmit,
  onCancel,
}: {
  palette: Palette;
  onSubmit: (updatedPalette: Palette) => void;
  onCancel: () => void;
}) {
  const [pendingPalette, setPendingPalette] = useState<Palette>(palette);

  const handleSubmit = () => {
    onSubmit(pendingPalette);
  };

  return (
    <div className="tw-mb-md tw-flex tw-flex-col tw-border tw-border-slate-300 tw-p-sm">
      {pendingPalette.map((dye, index) => (
        <div key={index} className="tw-flex tw-mb-sm">
          <Input
            type="text"
            value={dye.dye_name}
            onChange={(e) => {
              const updatedDye = { ...dye, dye_name: e.target.value };
              const newPalette = [...pendingPalette];
              newPalette[index] = updatedDye;
              setPendingPalette(newPalette);
            }}
            placeholder="Dye Name"
            className="tw-mr-sm tw-flex-1"
          />
          <Input
            type="number"
            value={dye.emission_nm + ""}
            onChange={(e) => {
              const updatedDye = {
                ...dye,
                emission_nm: parseInt(e.target.value, 10),
              };
              const newPalette = [...pendingPalette];
              newPalette[index] = updatedDye;
              setPendingPalette(newPalette);
            }}
            placeholder="Emission"
            className="tw-flex-1"
          />
        </div>
      ))}
      <div className="tw-flex tw-mt-sm tw-justify-end">
        <Button className="tw-mr-sm" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
}
function PlatePaletteMap({
  pendingPalettes,
  plates,
  onChange,
}: {
  pendingPalettes: PendingPalette[];
  plates: string[];
  onChange: (paletteIndex: number, updatedPlates: string[]) => void;
}) {
  return (
    <div className="tw-mt-8">
      <Subtitle>Map Palettes/Plates</Subtitle>
      {pendingPalettes.map((item, index) => {
        const claimedPlates = pendingPalettes
          .filter((_, idx) => idx !== index)
          .flatMap((ppm) => ppm.plates);

        const availablePlates = plates.filter(
          (plate) => !claimedPlates.includes(plate),
        );

        return (
          <PaletteRow
            key={index}
            paletteIndex={index}
            plates={availablePlates}
            selectedPlates={item.plates}
            onChange={(updatedPlates) => onChange(index, updatedPlates)}
          />
        );
      })}
    </div>
  );
}

type PaletteRowProps = {
  paletteIndex: number;
  plates: string[];
  selectedPlates: string[];
  onChange: (updatedPlates: string[]) => void;
};

function PaletteRow({
  paletteIndex,
  plates,
  selectedPlates,
  onChange,
}: PaletteRowProps) {
  const plateChoices = plates.map((plate) => ({
    id: plate,
    name: plate,
    color: "blue" as ColorTheme,
  }));

  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef(null);

  const handlePlateSelection = (plateId: string) => {
    const updatedSelection = selectedPlates.includes(plateId)
      ? selectedPlates.filter((p) => p !== plateId)
      : [...selectedPlates, plateId];

    onChange(updatedSelection);
  };

  const createPlateItems = () => {
    return plateChoices.map((choice) => ({
      id: choice.id,
      title: choice.name,
      node: (
        <>
          <Pill
            state={selectedPlates.includes(choice.id) ? "on" : "off"}
            size="large"
          />
          <Strut size={"0.25rem"} />
          <ChoiceToken>{choice}</ChoiceToken>
        </>
      ),
    }));
  };

  return (
    <div className="tw-my-8 tw-max-w-[300px]">
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-mr-8">Palette {paletteIndex + 1}</div>
        <div
          className="tw-border tw-px-2 tw-py-1 tw-cursor-pointer"
          ref={triggerRef}
          onClick={() => setIsOpen(!isOpen)}
        >
          Select Plates
        </div>
      </div>
      <div>
        <div className="tw-pl-2">
          {selectedPlates.map((plate) => (
            <div key={plate} className="tw-my-2">
              {plate}
            </div>
          ))}
        </div>
        <Overlay
          placement={"bottom-start"}
          show={isOpen}
          target={triggerRef}
          onHide={() => setIsOpen(false)}
          rootClose
        >
          {({ props }) => (
            <OverlayBody {...props} minWidth={80}>
              <DropdownList<string>
                items={createPlateItems()}
                searchable
                placeholder={"Find a plate"}
                onClick={(item) => {
                  handlePlateSelection(item.id);
                }}
              />
            </OverlayBody>
          )}
        </Overlay>
      </div>
    </div>
  );
}

// TODO(davidsharff): do we have other FE code that does something similiar?
function createPalettes(response: PalettesResponse): Palette[] {
  return response.palettes.map((palette) => {
    return palette.map((dyeName) => {
      const dyeObj = response.dyes.find((dye) => dye.name === dyeName);
      if (!dyeObj) {
        throw new Error(`Could not find dye with name ${dyeName}`);
      }
      return {
        ...omit(dyeObj, ["name"]),
        dye_name: dyeObj.name,
      };
    });
  });
}

function createPublishPostBody(
  pendingPalettes: { palette: Palette; plates: string[] }[],
): PublishPostBody {
  const palettes = pendingPalettes.map((p) => p.palette);
  const plates = pendingPalettes.reduce(
    (acc, item, index) => [
      ...acc,
      ...item.plates.map((plate) => ({ plate, palette_number: index })),
    ],
    [] as { plate: string; palette_number: number }[],
  );

  return { palettes, plates };
}
