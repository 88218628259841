import * as ContextMenu from "@radix-ui/react-context-menu";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import cx from "classnames";
import { useCallback, useMemo, useState } from "react";
import { Code, MinusSquare } from "react-feather";
import { IoSparkles } from "react-icons/io5";
import { DeprecatedButton } from "../../Common/DeprecatedButton";
import { ImageSet } from "../../imaging/types";
import { DatasetId, LabeledCellSampleMetadata } from "../../types";
import { Classification, useLabeledSetContext } from "../Context";
import { LabelClassMenu } from "../LabelClassMenu";
import { SelectableExampleGridViewV2 } from "../SelectableExampleGridView";
import { addSampleState, findSimilar, removeSamples, sortQueue } from "../util";

type ClassImagesProps = {
  dataset: DatasetId;
  imageSet: ImageSet | null;
  classification: Classification;
  className?: string;
};

export function ClassImages({
  dataset,
  imageSet,
  classification,
  className,
}: ClassImagesProps) {
  const {
    state: labeledSetState,
    updateState: updateLabeledSetState,
    setState: setLabeledSetState,
  } = useLabeledSetContext();
  const { cropSize, displayed, neighbors, queue, skipped, size } =
    labeledSetState;

  const [selection, setSelection] = useState<LabeledCellSampleMetadata[]>([]);
  const hasSelection = selection.length > 0;

  const examples = classification.examples;
  const examplesWithSelection = useMemo(
    () => addSampleState(examples, selection),
    [examples, selection],
  );

  const handleClearSelection = useCallback(() => {
    setSelection([]);
  }, []);

  const handleFindSimilarToSelection = useCallback(() => {
    // There's a chance our find similar doesn't return any values in which case we
    // automatically clear the displayed area and show new unrelated cells. Perhaps
    // we need a message here in that case?
    const candidates = [...displayed, ...queue, ...skipped];
    const similarCells = findSimilar(selection, candidates, neighbors);

    updateLabeledSetState((labeledSetState) => {
      const newQueue = sortQueue(
        removeSamples([...displayed, ...queue], [...similarCells, ...skipped]),
        neighbors,
      );

      const newSkipped = removeSamples(skipped, similarCells);

      return {
        ...labeledSetState,
        displayed: similarCells,
        skipped: newSkipped,
        queue: newQueue,
      };
    });
  }, [selection, neighbors, displayed, queue, skipped, updateLabeledSetState]);

  const handleRemoveSelection = useCallback(() => {
    // TODO (michaelwiest): Add the removed sample back to the display.
    setLabeledSetState({
      ...labeledSetState,
      classifications: labeledSetState.classifications.map((other) => ({
        ...other,
        examples: removeSamples(other.examples, selection),
      })),
      unsavedChanges: true,
    });
    handleClearSelection();
  }, [labeledSetState, selection, setLabeledSetState, handleClearSelection]);

  const handleRelabelSelection = useCallback(
    (newClassificationName: string) => {
      setLabeledSetState({
        ...labeledSetState,
        classifications: labeledSetState.classifications.map(
          (otherClassification) => {
            const isCurrentClassification =
              otherClassification.name === classification.name;
            const isClassificationToRelabelAs =
              otherClassification.name === newClassificationName;

            const updatedExamples = isCurrentClassification
              ? removeSamples(otherClassification.examples, selection)
              : isClassificationToRelabelAs
                ? [...otherClassification.examples, ...selection]
                : otherClassification.examples;

            return {
              ...otherClassification,
              examples: updatedExamples,
            };
          },
        ),
        unsavedChanges: true,
      });
      handleClearSelection();
    },
    [
      classification,
      labeledSetState,
      selection,
      setLabeledSetState,
      handleClearSelection,
    ],
  );

  return (
    <div className={cx(className, "tw-flex tw-flex-col tw-overflow-hidden")}>
      <div className="tw-flex-none tw-flex tw-justify-between tw-mb-4 tw-px-8">
        <DeprecatedButton
          onClick={handleClearSelection}
          disabled={!hasSelection}
          borderless
        >
          <MinusSquare size={16} className="tw-mr-2" />
          <span>Clear Selection</span>
        </DeprecatedButton>

        <div className="tw-flex tw-items-center">
          <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild disabled={!hasSelection}>
              <DeprecatedButton
                className="tw-mr-6"
                disabled={!hasSelection}
                borderless
              >
                <Code size={12} className="tw-mr-1 tw-rotate-90" />
                <span>Move to</span>
              </DeprecatedButton>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
              <LabelClassMenu
                as={DropdownMenu}
                excludedClassificationName={classification.name}
                onLabel={handleRelabelSelection}
                onRemoveLabel={handleRemoveSelection}
              />
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
          <DeprecatedButton
            onClick={handleFindSimilarToSelection}
            disabled={!hasSelection}
            borderless
          >
            <IoSparkles className="tw-text-sm tw-mr-1" />
            <span>Find Similar</span>
          </DeprecatedButton>
        </div>
      </div>

      <ContextMenu.Root>
        <ContextMenu.Trigger className="tw-px-8 tw-flex-1 tw-overflow-y-auto tw-flex tw-flex-col">
          <SelectableExampleGridViewV2
            dataset={dataset}
            imageSet={imageSet}
            cropSize={cropSize}
            size={size}
            metadata={examplesWithSelection}
            onSetSelection={setSelection}
            predictionMap={null}
          />
        </ContextMenu.Trigger>

        <ContextMenu.Portal>
          <LabelClassMenu
            as={ContextMenu}
            excludedClassificationName={classification.name}
            onLabel={handleRelabelSelection}
            onRemoveLabel={handleRemoveSelection}
            showHeader={true}
          />
        </ContextMenu.Portal>
      </ContextMenu.Root>
    </div>
  );
}
