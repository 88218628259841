/**
 * Component for building and loading sets of labeled example images.
 *
 * Delegates to a lower-level, dataset type-specific component based on the
 * dataset selected by the user.
 */
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Provider } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { FullScreenLoader } from "src/Common/FullScreenLoader";
import { useActiveWorkspace } from "src/Workspace/hooks";
import { useAccessToken } from "src/hooks/auth0";
import { useDatasetMetadata } from "src/hooks/datasets";
import { DatasetId } from "src/types";
import { FullScreenContainer } from "../Common/FullScreenContainer";
import Loader from "../Common/Loader";
import { useMethods } from "../Methods/hooks";
import { MethodSectionKey } from "../Methods/utils";
import history from "../history";
import { useLabeledSet, useModelMetrics } from "../hooks/examples";
import { useAutoImageSet, useSourceSize } from "../hooks/immunofluorescence";
import { VisualizationContextProvider } from "../imaging/context";
import { CropContext } from "../imaging/cropping";
import configureStore from "../imaging/state/store";
import { useNestedRoute } from "../routing";
import { useComponentSpan } from "../util/tracing";
import {
  LabeledSet,
  LabeledSetContextProvider,
  LabeledSetsContextProvider,
  useLabeledSetContext,
} from "./Context";
import { Header } from "./Header";
import { useAutoSave } from "./LabeledSetSaver";
import { LandingPage } from "./LandingPage";
import { MainContent } from "./MainContent";
import { Sidebar } from "./Sidebar";
import { AUTO_SAVE_DELTA } from "./constants";
import {
  addIdToMetadata,
  augmentClassificationsWithModelMetrics,
  groupClassifications,
  latestStainsFromFeatures,
  saveLabeledSet,
} from "./util";

interface Props {
  dataset: DatasetId;
  labeledSet: LabeledSet;
  sourceImageSize: number;
}

function PhenotypicLearnerInternal({
  dataset,
  labeledSet,
  sourceImageSize,
}: Props) {
  const [deleting, setDeleting] = useState(false);

  useMethods(MethodSectionKey.phenosorter);

  // Hook to handle save state of the labeledSet.
  useAutoSave(dataset, labeledSet.id, AUTO_SAVE_DELTA);

  const onDeleteStart = useCallback(() => setDeleting(true), []);

  const imageSet = useAutoImageSet({
    dataset,
    params: {
      imageSize: sourceImageSize,
      processingMode: "illumination-corrected",
    },
  });

  return (
    <FullScreenContainer>
      {deleting ? (
        <div className="tw-h-full tw-max-h-full tw-flex tw-items-center tw-justify-center">
          <Loader size={"large"} />
        </div>
      ) : (
        <VisualizationContextProvider>
          <div className="tw-h-full tw-max-h-full tw-flex tw-flex-row tw-overflow-hidden">
            <Sidebar
              dataset={dataset}
              imageSet={imageSet}
              labeledSet={labeledSet}
              onDeleteStart={onDeleteStart}
            />
            <div className="tw-flex-1 tw-flex tw-flex-col">
              <Header dataset={dataset} />
              <MainContent
                className="tw-flex-1 tw-overflow-auto"
                dataset={dataset}
                imageSet={imageSet}
              />
            </div>
          </div>
        </VisualizationContextProvider>
      )}
    </FullScreenContainer>
  );
}

function LabeledSetLoader({
  dataset,
  labeledSetId,
}: {
  dataset: DatasetId;
  labeledSetId: string;
}) {
  useComponentSpan("LabeledSetLoader", [dataset, labeledSetId]);
  const { state: labeledSet, updateState } = useLabeledSetContext();

  const datasetMetadata = useDatasetMetadata({ dataset });
  const maxCropSize = useContext(CropContext).sourceCropSize;
  const cropSize = datasetMetadata?.successful
    ? datasetMetadata.value?.single_cell_size
    : undefined;

  const workspace = useActiveWorkspace();

  const req = useLabeledSet(labeledSetId);

  useEffect(() => {
    if (req.loading || labeledSet.loaded || datasetMetadata === undefined) {
      return;
    }

    const stains = req.value.stains;
    const defaultCropSize = cropSize ?? Math.round(maxCropSize / 1.5);
    updateState((labeledSet) => ({
      ...labeledSet,
      defaultCropSize,
      cropSize: defaultCropSize,
      stains,
      selectedStains: stains,
      stainChannelIndices: req.value.stainChannelIndices,
      stainDisplayRanges: req.value.stainDisplayRanges,
      displayName: req.value.displayName,
      classifications: groupClassifications(
        req.value.labels.map(addIdToMetadata),
        req.value.classNames,
        req.value.accuracies,
      ),
      latestModelPath: req.value.latestModelPath,
      loaded: true,
    }));
  }, [
    labeledSet.loaded,
    req,
    updateState,
    datasetMetadata,
    cropSize,
    maxCropSize,
  ]);

  const modelReq = useModelMetrics({
    modelPath: labeledSet.latestModelPath,
    skip: !labeledSet.latestModelPath,
  });

  useEffect(() => {
    if (!modelReq?.successful) {
      return;
    }
    const latestFeatureSets = modelReq.value.latestFeatureSets;
    updateState((labeledSet) => ({
      ...labeledSet,
      confusionMatrix: modelReq.value.confusion_matrix,
      latestFeatureSets: latestFeatureSets,
      selectedStains: latestStainsFromFeatures(
        labeledSet.stains,
        latestFeatureSets,
      ),
      classifications: augmentClassificationsWithModelMetrics(
        labeledSet.classifications,
        {
          precisions: modelReq.value.precisions,
          recalls: modelReq.value.recalls,
        },
      ),
    }));
  }, [labeledSet.loaded, modelReq, updateState]);

  const saveOnExitRef = useRef<() => boolean>(() => false);
  const accessToken = useAccessToken();

  const saveOnExit = useCallback(() => {
    if (labeledSet.loaded && labeledSet.unsavedChanges && !labeledSet.deleted) {
      updateState((state) => ({
        ...state,
        unsavedChanges: false,
        lastSaved: new Date(),
      }));
      saveLabeledSet({ accessToken, workspace, dataset, labeledSet });
      return true;
    } else {
      return false;
    }
  }, [accessToken, dataset, labeledSet, updateState, workspace]);

  saveOnExitRef.current = saveOnExit;

  useEffect(() => {
    const beforeUnload = (e: BeforeUnloadEvent) => {
      if (saveOnExitRef.current()) {
        e.preventDefault();
        return (e.returnValue = "");
      }
    };

    window.addEventListener("beforeunload", beforeUnload, { capture: true });

    return () => {
      window.removeEventListener("beforeunload", beforeUnload, {
        capture: true,
      });
    };
  }, []);

  useEffect(() => {
    // Save if needed on unmount
    return () => {
      saveOnExitRef.current();
    };
  }, []);

  const sourceSize = useSourceSize({ dataset });

  return labeledSet.loaded && sourceSize ? (
    <PhenotypicLearnerInternal
      dataset={dataset}
      labeledSet={labeledSet}
      sourceImageSize={sourceSize.unwrap()}
    />
  ) : (
    <FullScreenLoader />
  );
}

const store = configureStore(history);

export default function PhenotypicLearner({ dataset }: { dataset: DatasetId }) {
  type Params = {
    labeledSetId: string;
  };
  const match = useRouteMatch();
  const [params] = useNestedRoute<Params>(`${match.url}/:labeledSetId?`);

  const labeledSetId = params.labeledSetId;

  return (
    <Provider store={store}>
      <LabeledSetsContextProvider value={{ labeledSets: [], loaded: false }}>
        {labeledSetId ? (
          <LabeledSetContextProvider id={labeledSetId}>
            <LabeledSetLoader
              key={labeledSetId}
              dataset={dataset}
              labeledSetId={labeledSetId}
            />
          </LabeledSetContextProvider>
        ) : (
          <LandingPage dataset={dataset} />
        )}
      </LabeledSetsContextProvider>
    </Provider>
  );
}
