/**
 * Component to render a 384-well plate.
 *
 * Accepts, as a prop, a child component to render in each well.
 */
import cx from "classnames";
import { ReactElement, ReactNode, useMemo } from "react";

export type PlateKind = "96-well" | "384-well";

const ROWS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
];

const COLUMNS = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
];

const formatWell = (row: string, column: string) => {
  return `${row}${column.padStart(2, "0")}`;
};

export function rowPosition(well: string) {
  return ROWS.indexOf(well[0]);
}

export function columnPosition(well: string) {
  return COLUMNS.indexOf(well.slice(1, 3));
}

/**
 * An empty well component that can be returned by the client Component prop
 * to render placeholders for wells with no contents.
 */
export const EmptyWellView = () => (
  <div className={"tw-border tw-border-gray-300"} />
);

type LabelKind = "normal" | "small" | "extra-small" | "none";

const ColumnLabel = ({
  kind,
  children,
}: {
  kind: LabelKind;
  children: ReactElement | ReactNode;
}) =>
  kind === "none" ? null : (
    <div
      className={cx(
        "tw-flex tw-flex-row tw-justify-center tw-items-center tw-text-slate-500",
        kind === "extra-small" && "tw-text-[0.5rem]",
        kind === "small" && "tw-text-xs",
        kind === "normal" && "tw-text-sm",
      )}
    >
      {children}
    </div>
  );

const RowLabel = ({
  kind,
  children,
}: {
  kind: LabelKind;
  children: ReactElement | ReactNode;
}) =>
  kind === "none" ? null : (
    <div
      className={cx(
        "tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-slate-500",
        kind === "extra-small" && "tw-text-[0.5rem]",
        kind === "small" && "tw-text-xs",
        kind === "normal" && "tw-text-sm",
      )}
    >
      {children}
    </div>
  );

export default function PlateMap({
  size,
  children,
  labelKind = "normal",
  plateKind,
}: {
  size: number;
  children: (well: string) => ReactNode;
  labelKind?: LabelKind;
  plateKind?: PlateKind;
}) {
  plateKind = plateKind ?? "384-well";
  let rows;
  let columns;
  if (plateKind === "384-well") {
    rows = ROWS;
    columns = COLUMNS;
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (plateKind === "96-well") {
    rows = ROWS.slice(0, 8);
    columns = COLUMNS.slice(0, 12);
  } else {
    throw new Error(`Unexpected plate kind: ${plateKind}`);
  }

  const nodes = [];
  nodes.push(<span key={"empty"} />);
  for (const column of columns) {
    nodes.push(
      <ColumnLabel key={column} kind={labelKind}>
        {column}
      </ColumnLabel>,
    );
  }
  for (const row of rows) {
    nodes.push(
      <RowLabel key={row} kind={labelKind}>
        {row}
      </RowLabel>,
    );
    for (const column of columns) {
      const well = formatWell(row, column);
      nodes.push(children(well));
    }
  }

  const [columnLabelSpace, rowLabelSpace] = useMemo(() => {
    switch (labelKind) {
      case "none":
        return ["", ""];
      case "extra-small":
        return ["8px", "10px"];
      case "small":
      case "normal":
        return ["16px", "22px"];
    }
  }, [labelKind]);
  return (
    <div
      className={"tw-grid tw-gap-[2px]"}
      style={{
        gridTemplateColumns: `${columnLabelSpace} repeat(${columns.length}, ${size}px)`,
        gridTemplateRows: `${rowLabelSpace} repeat(${rows.length}, ${size}px)`,
      }}
    >
      {nodes}
    </div>
  );
}
