import { ConfirmDelete, MainMenu, OverflowMenu } from "src/Common/PopoverMenu";

export function ColumnMenu({
  column,
  onDelete,
}: {
  column: string;
  onDelete: (column: string) => Promise<void>;
}) {
  return (
    <OverflowMenu>
      <MainMenu
        items={{
          "Delete Column…": (
            <ConfirmDelete
              message="Are you sure you want to delete this column?"
              onDelete={() => onDelete(column)}
            />
          ),
        }}
      ></MainMenu>
    </OverflowMenu>
  );
}
