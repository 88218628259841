import {
  Condition,
  RowWithHistograms,
  SqlColumnType,
  TypeFromSqlColumnType,
} from "./types";

export function toSqlColumnType(
  value: string,
  columnType: SqlColumnType,
): TypeFromSqlColumnType<SqlColumnType> {
  switch (columnType) {
    case SqlColumnType.varchar:
      return value;
    case SqlColumnType.double: {
      const number = parseFloat(value);
      return Number.isNaN(number) ? 0 : number;
    }
    case SqlColumnType.integer: {
      const number = parseInt(value);
      return Number.isNaN(number) ? 0 : number;
    }
    case SqlColumnType.boolean:
      return Boolean(value);
    case SqlColumnType.bigint: {
      try {
        return BigInt(value);
      } catch (ex) {
        return BigInt(0);
      }
    }
  }
}

export function conditionForRow(
  row: RowWithHistograms,
  groupBy: string[],
): Condition {
  const groupBySet = groupBy.length > 0 ? new Set(groupBy) : undefined;

  return Object.fromEntries(
    Object.entries(row).filter(
      groupBySet
        ? ([key]) => groupBySet.has(key)
        : ([key]) => !key.startsWith("histogram_"),
    ),
  );
}
