/**
 * Component to enumerate all FeatureSet's associated with a Dataset.
 */
import cx from "classnames";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Track } from "src/Common/EventTracker/Track";
import { FullScreenLoader } from "src/Common/FullScreenLoader";
import ErrorBoundary from "src/Error/ErrorBoundary";
import ColumnsResolver from "src/FeatureSelector/ColumnsResolver";
import { useLevel } from "src/FeatureSelector/hooks";
import { AppLocationId } from "src/TrackAppLocation/types";
import { useActiveWorkspace } from "src/Workspace/hooks";
import { useTrackPageview } from "src/hooks/analytics";
import { useAccessToken } from "src/hooks/auth0";
import { DatasetId } from "src/types";
import { useDebouncedCallback } from "use-debounce";
import { FullScreenContainer } from "../Common/FullScreenContainer";
import ControlHeader from "../Control/ControlHeader";
import ControlSection from "../Control/ControlSection";
import {
  CollapsibleFilterSelector,
  CollapsibleFilterSelectorRef,
} from "../Control/FilterSelector";
import { updateFilters, updateOperator } from "../Control/FilterSelector/utils";
import FeatureSelector from "../FeatureSelector";
import {
  ColumnsLookupFn,
  FeatureSetSelection,
  NormalFeatureSetSelection,
  isPlateBasedFeature,
} from "../FeatureSelector/types";
import {
  FeatureSetsByType,
  extractWellAggregatedNamesFromSelection,
  useFeatureSetsGrouped,
} from "../FeatureSelector/utils";
import { PulseGuiderRoot } from "../Insights/PulseGuider";
import { useMethods } from "../Methods/hooks";
import { MethodSectionKey } from "../Methods/utils";
import { SharedMemoContextProvider } from "../SharedMemo";
import { useFeatureFlag } from "../Workspace/feature-flags";
import { useQueryParams, useTypedQueryParams } from "../routing";
import { useComponentSpan } from "../util/tracing";
import FeatureFilter, { validateFeatureFilter } from "./FeatureFilter";
import { FeatureSelectionContextProvider } from "./FeatureSelectionContext";
import FeatureSetColumnDetails from "./FeatureSetColumnDetails";
import { DownloadMenu } from "./MultiFeature/DownloadMenu";
import { handleDownloadFeatureData } from "./MultiFeature/utils";
import MultiFeatureSetDetails, {
  MegaMapContainer,
} from "./MultiFeatureSetDetails";
import ViewSelector from "./ViewSelector";
import { ViewSkeleton, ViewSkeletonReason } from "./ViewSkeleton";
import {
  FeatureSetManagementContextProvider,
  useFeatureSetManagementContext,
} from "./context";
import {
  useAutoSelectView,
  useFeatureSelectionParams,
  useFilteredPlates,
} from "./hooks";
import { FeatureFilterBuilder, FeatureSetInfo } from "./types";
import {
  AnalyzeView,
  MultiFeatureView,
  SingleFeatureView,
  useActiveView,
  viewSupportsMultipleFeatures,
} from "./views";

const NORMALIZATION_DECODER = {
  normalizationColumns: {
    defaultValue: [],
    fromArray: (value: string[]) => value,
  },
};

function FeatureSetManagementPage({ dataset }: { dataset: DatasetId }) {
  useMethods(MethodSectionKey.analyze);

  const allFeatures = useFeatureSetsGrouped(dataset);
  const [unreifiedFeatureSelections, setFeatureSelection] =
    useFeatureSelectionParams(dataset, allFeatures);

  const [reifiedSelections, setReifiedSelections] = useState<
    FeatureSetSelection[] | null
  >(null);

  useComponentSpan("FeatureSetManagementPage", [
    dataset,
    unreifiedFeatureSelections,
  ]);

  // TODO(danlec): When the unreifiedFeatureSelections changes, we should immediately
  // clear the reifiedSelections since those won't be accurate anymore.  For now, just
  // handle the case where we switch to nothing being selected (so we don't automatically
  // choose a view based on the previous feature selection)
  const featureSelection = useMemo(
    () => (unreifiedFeatureSelections.length === 0 ? [] : reifiedSelections),
    [reifiedSelections, unreifiedFeatureSelections.length],
  );

  if (!allFeatures) {
    return <FullScreenLoader />;
  }

  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (allFeatures && !allFeatures.successful) {
    return (
      <FullScreenContainer center>
        <div className={"tw-text-red-error"}>
          There was a problem loading your data. Please reload and try again.
        </div>
      </FullScreenContainer>
    );
  }

  return (
    <>
      <ColumnsResolver
        dataset={dataset}
        unreifiedFeatureSelections={unreifiedFeatureSelections}
        onComplete={setReifiedSelections}
      />
      {!featureSelection && <FullScreenLoader />}
      {featureSelection && (
        <FeatureSetManagementPageInner
          dataset={dataset}
          allFeatures={allFeatures.value}
          featureSelection={featureSelection}
          setFeatureSelection={setFeatureSelection}
        />
      )}
    </>
  );
}

function FeatureSetManagementPageInner({
  dataset,
  allFeatures,
  featureSelection,
  setFeatureSelection,
}: {
  dataset: DatasetId;
  allFeatures: FeatureSetsByType;
  featureSelection: FeatureSetSelection[];
  setFeatureSelection: (
    selections: FeatureSetSelection[],
    getColumns: ColumnsLookupFn,
  ) => void;
}) {
  const {
    metadataDB,
    filter,
    filterColumns,
    validateAndSetFilter,
    onDownload,
    featureFilter,
    availableFeatureFilters,
    validateAndSetFeatureFilter,
  } = useFeatureSetManagementContext();
  const featureFilteringEnabled = useFeatureFlag("feature-filtering-enabled");

  const filterSelectorRef = useRef<CollapsibleFilterSelectorRef>(null);
  const handleOpenFilterSelector = () => {
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    filterSelectorRef?.current?.openFilterSelector();
  };
  const handleSetFilterHeader = (header: ReactNode) => {
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    filterSelectorRef?.current?.setFilterHeader(header);
  };
  const [isFeatureSelectorOpen, _setIsFeatureSelectorOpen] = useState(false);
  const [queryParams, setQueryParams] = useTypedQueryParams(
    NORMALIZATION_DECODER,
  );
  const normalizationColumns = queryParams.normalizationColumns;
  const handleNormalizationQueryChange = (newColumns: string[]) => {
    setQueryParams((prevState) => ({
      ...prevState,
      normalizationColumns: newColumns,
    }));
  };
  // TODO(danlec): We shouldn't need to debounce this, but we currently have a case
  // where the feature selector indicates that it's been closed immediately after
  // being opened.
  const setIsFeatureSelectorOpen = useDebouncedCallback(
    _setIsFeatureSelectorOpen,
    100,
    { leading: true, trailing: false },
  );

  const [view, setView] = useActiveView();

  useTrackPageview({ id: AppLocationId.Measurements, subPage: view?.id });

  // Automatically choose a view if we haven't selected on but have a featureSelection
  // that isn't empty
  useAutoSelectView({ view, featureSelection, setView });

  const featureSelectionContext = useMemo(
    () => ({
      selections: featureSelection,
      updateSelections: setFeatureSelection,
      allFeatures,
    }),
    [allFeatures, featureSelection, setFeatureSelection],
  );

  const isSelectMultiple = viewSupportsMultipleFeatures(view);
  const firstFeature = isSelectMultiple ? undefined : featureSelection[0];
  const firstFeatureSet =
    firstFeature !== undefined && firstFeature.type !== "embedding"
      ? firstFeature.featureSets[0]
      : undefined;

  const level = useLevel(
    firstFeatureSet !== undefined
      ? {
          dataset,
          firstFeatureSet,
          skip: !firstFeatureSet,
        }
      : { skip: true },
  );

  // If we've entered a situation where the current feature selection isn't compatible
  // with the selected view, pop the feature selector open.  This can happen e.g. if
  // you start a new view via the Analyze menu.
  useEffect(() => {
    if (
      // If they haven't got any measurements selected
      featureSelection.length === 0 ||
      // Or if they have multiple measurements selected, and the view doesn't support
      // multiple measurements
      (!isSelectMultiple &&
        (featureSelection.length > 1 ||
          (featureSelection[0].type !== "embedding" &&
            featureSelection[0].columns.length > 1))) ||
      // They have an embedding selected on a view that doesn't support embeddings
      (view &&
        !view.supportsEmbeddings &&
        featureSelection.some((selection) => selection.type === "embedding")) ||
      // The view requires cell level and it isn't
      (view &&
        view.requiresCellLevelFeature &&
        level !== undefined &&
        level !== "cell")
    ) {
      setIsFeatureSelectorOpen(true);
    }
  }, [
    featureSelection,
    featureSelection.length,
    isSelectMultiple,
    level,
    setIsFeatureSelectorOpen,
    view,
  ]);

  const accessToken = useAccessToken();
  const workspace = useActiveWorkspace();

  const filteredPlates = useFilteredPlates();
  const [featureNames, columns] = useMemo(() => {
    const featureNames = featureSelection
      .map((feature) => extractWellAggregatedNamesFromSelection(feature))
      .flat();

    // Embeddings implicitly contain all columns
    const columns = featureSelection.map((feature) =>
      feature.type === "embedding" ? [] : feature.columns,
    );

    return [featureNames, columns.flat()];
  }, [featureSelection]);

  const [featureBuilder, setFeatureBuilder] =
    useState<FeatureFilterBuilder | null>(featureFilter);

  const setPartialBuilder = useCallback(
    (builder: FeatureFilterBuilder | null) => {
      // If we've updated the feature set, clear other parameters since they
      // might no longer be valid.
      const newBuilder =
        builder == null
          ? null
          : builder.featureSet != null
            ? builder
            : { ...featureBuilder, ...builder };
      setFeatureBuilder(newBuilder);
    },
    [featureBuilder, setFeatureBuilder],
  );

  const commitBuilder = useCallback(() => {
    if (featureBuilder != null && validateFeatureFilter(featureBuilder)) {
      validateAndSetFeatureFilter(featureBuilder);
    }
  }, [featureBuilder, validateAndSetFeatureFilter]);

  const handleExportFeature = useCallback(async () => {
    return handleDownloadFeatureData(accessToken, workspace.id, dataset, {
      features: featureNames,
      columns,
      plates: filteredPlates,
    });
  }, [
    accessToken,
    workspace.id,
    dataset,
    featureNames,
    columns,
    filteredPlates,
  ]);

  return (
    <Track
      // FeatureSetManagementPage manages its own route outside of TrackedRoute,
      // so we need to set the global tracking context manually
      global={{
        page: AppLocationId.Measurements,
        subPage: view?.id,
      }}
    >
      <div
        className={
          "tw-h-analyze-toolbar-height tw-shadow tw-border-b tw-flex tw-flex-row tw-relative tw-px-4"
        }
      >
        <ControlSection
          extraClasses={
            // Cascading need for z-index: single cell histogram overlay is elevated so must any dropdowns above
            // it that could overlap it or each other.
            "tw-flex-1 tw-z-30 tw-overflow-hidden"
          }
        >
          <ControlHeader>Measurement</ControlHeader>
          <FeatureSelector
            dataset={dataset}
            features={allFeatures}
            selections={featureSelection}
            onChangeSelections={setFeatureSelection}
            isOpen={isFeatureSelectorOpen}
            onOpenChange={setIsFeatureSelectorOpen}
            selectedView={view}
            multi={isSelectMultiple}
          />
        </ControlSection>
        <ControlSection
          extraClasses={cx(
            "tw-w-[280px]",
            isFeatureSelectorOpen && "tw-opacity-20",
          )}
        >
          <ControlHeader>Data selection</ControlHeader>
          <PulseGuiderRoot
            guiderKey={"dataset-filter"}
            position={{
              corner: "top-left",
            }}
            tooltipSide={"left"}
          >
            <CollapsibleFilterSelector
              ref={filterSelectorRef}
              triggerClasses={cx(
                "tw-px-2 tw-py-1.5 tw-text-slate-500 tw-h-full tw-w-full",

                // TODO(benkomalo): copied from CollapsedFeatureSelectorInfo.tsx
                // and manually specified to mirror our react-select-plus borders.
                "tw-border tw-rounded hover:tw-border-gray-300 tw-border-[#ccc]",
              )}
              depth={0}
              maxDepth={1}
              columns={filterColumns}
              filterSet={filter}
              showPreview
              metadata={metadataDB}
              onChangeFilters={(filters) =>
                validateAndSetFilter(updateFilters(filter, filters))
              }
              onChangeOperator={(operator, newFilters) =>
                validateAndSetFilter(
                  updateOperator(filter, operator, newFilters),
                )
              }
            />
          </PulseGuiderRoot>
        </ControlSection>
        {featureFilteringEnabled ? (
          <ControlSection
            extraClasses={cx(isFeatureSelectorOpen && "tw-opacity-20")}
          >
            <ControlHeader>Feature filtering</ControlHeader>
            <FeatureFilter
              filter={featureBuilder}
              availableFilters={availableFeatureFilters}
              onChangeFilter={setPartialBuilder}
              onCommit={commitBuilder}
            />
          </ControlSection>
        ) : null}
        <ControlSection
          extraClasses={cx(isFeatureSelectorOpen && "tw-opacity-20")}
        >
          <ControlHeader>View</ControlHeader>
          <ViewSelector
            selectedView={view}
            onChange={(view) => setView(view.id)}
          />
        </ControlSection>
        <ControlSection
          extraClasses={cx(
            "tw-w-[240px]",
            isFeatureSelectorOpen && "tw-opacity-20",
          )}
        >
          <ControlHeader>Export</ControlHeader>
          <div className={"tw-h-full tw-flex tw-items-center"}>
            <PulseGuiderRoot
              guiderKey={"download-button"}
              position={{ corner: "top-left" }}
              tooltipSide={"left"}
            >
              <DownloadMenu
                onExportFeature={handleExportFeature}
                onDownloadAnalysis={onDownload}
                canExportFeature={featureNames.length === 1}
              />
            </PulseGuiderRoot>
          </div>
        </ControlSection>
      </div>
      <div
        className={
          "tw-h-[calc(100vh-theme(spacing.24)-theme(spacing.global-nav-height))]"
        }
      >
        <ErrorBoundary>
          <FeatureSelectionContextProvider value={featureSelectionContext}>
            <FeatureSetViewSwitcher
              dataset={dataset}
              features={featureSelection}
              view={view}
              onOpenFilterSelector={handleOpenFilterSelector}
              onOpenFeatureSelector={() => {
                setIsFeatureSelectorOpen(true);
              }}
              onSetFilterHeader={handleSetFilterHeader}
              normalizationColumns={normalizationColumns}
              onChangeNormalizationColumns={handleNormalizationQueryChange}
            />
          </FeatureSelectionContextProvider>
        </ErrorBoundary>
      </div>
    </Track>
  );
}

function FeatureSetViewSwitcher({
  dataset,
  features,
  view,
  onOpenFilterSelector,
  onOpenFeatureSelector,
  onSetFilterHeader,
  normalizationColumns,
  onChangeNormalizationColumns,
}: {
  dataset: DatasetId;
  features: FeatureSetSelection[];
  view: AnalyzeView | undefined;
  onOpenFilterSelector: () => void;
  onOpenFeatureSelector: () => void;
  onSetFilterHeader: (header: ReactNode) => void;
  normalizationColumns: string[];
  onChangeNormalizationColumns: (columns: string[]) => void;
}) {
  // Single-plate selection only needed for some single-feature views, but hooks must
  // be called unconditionally.
  const [queryParams] = useQueryParams<{ plate: string | null }>();
  const selectedPlate = queryParams.plate || null;

  const filteredPlates = useFilteredPlates();

  // Handle invalid configurations by showing a picture of the view the user wants to
  // use along with a reason why the configuration isn't valid
  const skeletonFor = useCallback(
    (reason: ViewSkeletonReason) => {
      return (
        <ViewSkeleton
          view={view}
          reason={reason}
          onOpenFeatureSelector={onOpenFeatureSelector}
        />
      );
    },
    [onOpenFeatureSelector, view],
  );

  if (features.length === 0 || !view) {
    return skeletonFor(ViewSkeletonReason.NoFeaturesSelected);
  }

  const singleSelection = features.length === 1 ? features[0] : undefined;
  if (view.id === MultiFeatureView.Ranking) {
    return (
      <MegaMapContainer
        dataset={dataset}
        features={features}
        plates={filteredPlates}
      />
    );
  } else if (!singleSelection) {
    return skeletonFor(ViewSkeletonReason.MultipleFeaturesSelected);
  } else if (view.id === MultiFeatureView.Similarities) {
    return (
      <MultiFeatureSetDetails
        data-track="test"
        dataset={dataset}
        plates={filteredPlates}
        feature={singleSelection}
        selectedTab={view.id}
        onOpenFilterSelector={onOpenFilterSelector}
        onSetFilterHeader={onSetFilterHeader}
        normalizationColumns={normalizationColumns}
        onChangeNormalizationColumns={onChangeNormalizationColumns}
      />
    );
  } else if (singleSelection.type === "embedding") {
    return skeletonFor(ViewSkeletonReason.EmbeddingSelected);
  } else if (singleSelection.columns.length > 1) {
    return skeletonFor(ViewSkeletonReason.MultipleColumnsSelected);
  } else {
    return (
      <FeatureSetColumnDetailsWithStableFeatureSetInfo
        dataset={dataset}
        selectedPlate={selectedPlate}
        featureSelection={singleSelection}
        viewId={view.id}
      />
    );
  }
}

function FeatureSetColumnDetailsWithStableFeatureSetInfo({
  dataset,
  selectedPlate,
  featureSelection,
  viewId,
}: {
  dataset: DatasetId;
  selectedPlate: string | null;
  featureSelection: NormalFeatureSetSelection;
  viewId: SingleFeatureView;
}) {
  // For those views that may need to sample/filter to a single plate:
  const targetPlate = selectedPlate;
  const featureSetInfo: FeatureSetInfo = useMemo(() => {
    if (targetPlate) {
      const singlePlateFeatureInstance = featureSelection.featureSets
        .filter(isPlateBasedFeature)
        .find(({ plate }) => plate === targetPlate);
      if (!singlePlateFeatureInstance) {
        throw new Error(
          "Unexpectedly no features for plate: " +
            `${featureSelection.name} ${targetPlate}`,
        );
      }
      return singlePlateFeatureInstance;
    } else {
      const firstFeatureSet = featureSelection.featureSets[0];
      return {
        ...firstFeatureSet,
        plate: isPlateBasedFeature(firstFeatureSet)
          ? firstFeatureSet.plate
          : null,
      };
    }
  }, [featureSelection, targetPlate]);

  return (
    <FeatureSetColumnDetails
      dataset={dataset}
      featureSetInfo={featureSetInfo}
      column={featureSelection.columns[0]}
      selectedTab={viewId}
    />
  );
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function FeatureSetManagementPagePlaceholder() {
  return (
    <FullScreenContainer center>
      <div className={"tw-flex tw-flex-row tw-w-full"}>
        <div className={"tw-p-8 tw-flex-1 tw-flex tw-flex-col tw-items-center"}>
          <div>
            <div className={"tw-mt-8 tw-text-slate-700 tw-text-[32px]"}>
              Coming soon
            </div>
            <div className={"tw-mt-2 tw-text-slate-500"}>
              Manage, view, and explore your single-cell or aggregated
              measurements.
            </div>
          </div>
        </div>
        <div className={"tw-max-w-[800px]"}>
          <img
            src="/measurements-screenshot.png"
            className={
              "tw-shadow-xl tw-h-[600px] tw-max-w-[800px] tw-object-cover tw-object-left tw-border tw-rounded-md"
            }
          />
        </div>
      </div>
    </FullScreenContainer>
  );
}

export default function FeatureSetManagementPageWithContexts({
  dataset,
}: {
  dataset: DatasetId;
}) {
  const { path } = useRouteMatch();
  return (
    <FeatureSetManagementContextProvider dataset={dataset}>
      <SharedMemoContextProvider realm="featureSetManagementPage">
        <Switch>
          <Route path={path}>
            <FeatureSetManagementPage dataset={dataset} />
          </Route>
        </Switch>
      </SharedMemoContextProvider>
    </FeatureSetManagementContextProvider>
  );
}
