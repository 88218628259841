import { User } from "@auth0/auth0-react";
import * as Popover from "@radix-ui/react-popover";
import cx from "classnames";
import { ReactElement, ReactNode, useState } from "react";
import { ChevronDown } from "react-feather";
import { Link } from "react-router-dom";
import { useIsAdminConsoleEnabled } from "src/AdminConsole/hooks";
import { PrereleaseLabel } from "src/Common/PrereleaseLabel";
import { useActiveExperiment, useActiveWorkspace } from "src/Workspace/hooks";
import { adminConsoleUrl, workspaceRootUrl } from "src/util/urls";
import DropDownMenu, {
  DropDownMenuButton,
  DropDownMenuLink,
} from "../Common/DropDownMenu";
import LoginButton from "../Home/LoginButton";
import ProfileDropDown from "../ProfileDropDown";
import { Workspace } from "../Workspace/types";
import { useWorkspaces } from "../hooks/workspace";
import SpringWordmarkPurple from "../img/SpringWordmarkPurple.svg";
import { SearchControlArea } from "../search/SearchControlArea";
import { DatasetListing } from "../types";
import { dispatchTabSelection, useAppNavStyle, useTabState } from "./hooks";
import { AppChromeNavStyle, Tab } from "./types";

function ExperimentInfoPopdown({
  workspace,
  experiment,
}: {
  workspace: Workspace | undefined | null;
  experiment: DatasetListing;
}) {
  const isAdminConsoleEnabled = useIsAdminConsoleEnabled();

  const content = (
    <>
      {workspace && workspace.logo_url && (
        <img
          src={workspace.logo_url}
          className={"tw-max-h-[40px]"}
          alt=""
          aria-hidden={true}
        />
      )}
      {experiment.name}
      {experiment.status === "prerelease" ? (
        <PrereleaseLabel className="tw-ml-sm" />
      ) : null}
    </>
  );

  const className = cx("tw-h-full tw-px-8 tw-flex tw-items-center tw-truncate");

  return workspace && isAdminConsoleEnabled ? (
    <Link
      to={adminConsoleUrl(workspace.id, experiment.id)}
      className={className}
    >
      {content}
    </Link>
  ) : (
    <div className={className}>{content}</div>
  );
}

export function AppChromeNavBase({
  style = "raised",
  homeUrl = "/",
  children,
}: {
  style?: AppChromeNavStyle;
  homeUrl?: string;
  children?: ReactNode;
}) {
  return (
    <div
      className={cx(
        "tw-h-global-nav-height tw-fixed tw-w-full tw-z-global-nav tw-bg-white",
        "tw-flex tw-items-center",
        style === "raised" && "tw-shadow-md",
      )}
    >
      <Link className={"tw-w-40 tw-px-8"} to={homeUrl} aria-label={"Home"}>
        <SpringWordmarkPurple />
      </Link>
      {children}
    </div>
  );
}

export default function AppChromeNav({ user }: { user: User }) {
  const [tabs] = useTabState();
  const [style] = useAppNavStyle();
  const workspaces = useWorkspaces();
  const workspace = useActiveWorkspace({ optional: true });
  const dataset = useActiveExperiment({ optional: true });

  const isSearchAvailable =
    workspace?.config.active_feature_flags.includes("search-enabled");

  return (
    <AppChromeNavBase
      homeUrl={workspace ? workspaceRootUrl(workspace.id) : "/"}
      style={style}
    >
      {dataset && workspace && isSearchAvailable && (
        <div>
          <SearchControlArea
            workspaceId={workspace.id}
            renderMode={"icon-with-floating-bar"}
          />
        </div>
      )}

      {dataset && (
        <ExperimentInfoPopdown workspace={workspace} experiment={dataset} />
      )}

      <div className={"tw-flex-1 tw-flex tw-flex-row tw-justify-end tw-h-full"}>
        {tabs.map((t) => (
          <TabLinkOrMenu key={t.id} tab={t} />
        ))}
      </div>
      <ProfileDropDown
        user={user}
        workspaces={workspaces?.orElse(() => [])}
        showName={tabs.length === 0}
      />
    </AppChromeNavBase>
  );
}

export function AppChromeNavLoggedOut() {
  return (
    <AppChromeNavBase>
      <div className={"tw-flex-1"} />

      <LoginButton
        className={"tw-px-8 hover:tw-bg-purple-100 tw-h-full tw-cursor-pointer"}
      />
    </AppChromeNavBase>
  );
}

function TabLinkOrMenu({ tab }: { tab: Tab }): ReactElement {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const getMenuItemClassName = (t: Tab) =>
    cx(
      "tw-flex tw-px-8 tw-h-full tw-items-center hover:tw-bg-purple-200 hover:tw-text-purple",
      t.active ? "tw-bg-purple-100 tw-text-purple" : "tw-text-inherit",
    );

  const getDropdownItemClassName = (t: Tab) =>
    cx(
      "hover:tw-text-purple-500",
      t.active ? "tw-text-purple" : "tw-text-inherit",
    );

  return tab.menuItems ? (
    <DropDownMenu
      open={isMenuOpen}
      align="start"
      onOpen={() => setIsMenuOpen(true)}
      onClose={() => setIsMenuOpen(false)}
      PopoverTrigger={
        <Popover.Trigger
          className={cx(
            "tw-flex tw-px-8 tw-h-full tw-items-center hover:tw-bg-purple-200 hover:tw-text-purple tw-cursor-pointer",
            isMenuOpen && "tw-bg-purple-200 tw-text-purple",
            tab.active && "tw-bg-purple-100 tw-text-purple",
          )}
        >
          <span className="tw-flex tw-items-center">
            <span>{tab.displayText}</span>
            <ChevronDown className="tw-ml-xs -tw-mb-0.5" size={16} />
          </span>
        </Popover.Trigger>
      }
      sections={tab.menuItems.map((t) =>
        t.path ? (
          <DropDownMenuLink
            key={t.id}
            to={t.path}
            onClick={() => {
              setIsMenuOpen(false);
            }}
            className={getDropdownItemClassName(t)}
          >
            {t.displayText}
          </DropDownMenuLink>
        ) : (
          <DropDownMenuButton
            key={t.id}
            onClick={() => {
              setIsMenuOpen(false);
              dispatchTabSelection(t.id);
            }}
            className={getDropdownItemClassName(t)}
          >
            {t.displayText}
          </DropDownMenuButton>
        ),
      )}
    />
  ) : tab.render ? (
    tab.render({
      trigger: (
        <Popover.Trigger
          className={cx(
            "tw-flex tw-px-8 tw-h-full tw-items-center hover:tw-bg-purple-200 hover:tw-text-purple tw-cursor-pointer",
            isMenuOpen && "tw-bg-purple-200 tw-text-purple",
            tab.active && "tw-bg-purple-100 tw-text-purple",
          )}
        >
          <span className="tw-flex tw-items-center">
            <span>{tab.displayText}</span>
            <ChevronDown className="tw-ml-xs -tw-mb-0.5" size={16} />
          </span>
        </Popover.Trigger>
      ),
      onToggle: setIsMenuOpen,
    })
  ) : tab.path ? (
    <Link className={getMenuItemClassName(tab)} to={tab.path}>
      {tab.displayText}
    </Link>
  ) : (
    <button
      className={getMenuItemClassName(tab)}
      onClick={() => dispatchTabSelection(tab.id)}
    >
      {tab.displayText}
    </button>
  );
}
