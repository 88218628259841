import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useMemo } from "react";
import { makeDatasetApi } from "src/hooks/api";
import { DatasetId } from "src/types";
import { uniquify } from "src/util/function-util";
import { defaultComparator } from "src/util/sorting";
import { Failure, Fetchable, Result, Success } from "@spring/core/result";
import {
  ColumnsLookupFn,
  FeatureSetSelection,
  UnreifiedFeatureSetSelection,
  UnvalidatedFeatureParams,
} from "../FeatureSelector/types";
import {
  FeatureSetsByType,
  deserializeFromQueryParams,
  isSerializedColumnIndices,
  serializeToQueryParams,
} from "../FeatureSelector/utils";
import { useTypedQueryParams } from "../routing";
import { useFeatureSetManagementContext } from "./context";
import { AvailableFeatureFilters } from "./types";
import {
  AnalyzeView,
  MultiFeatureView,
  SingleFeatureView,
  ViewId,
} from "./views";

/**
 * Parse and dispatch the selection parameters from and to the URL.
 */
export function useFeatureSelectionParams(
  dataset: DatasetId,
  features: Fetchable<FeatureSetsByType>,
): [
  UnreifiedFeatureSetSelection[],
  (selections: FeatureSetSelection[], getColumns: ColumnsLookupFn) => void,
] {
  // Features can be encoded one of two ways:
  //  - as a single selection, in which case attributes are splatted out
  //    as query params individually (featureType, etc...)
  //  - as multiple selections, in which case the values are globbed up as a single
  //    JSON string in the "features" query param.
  // If the latter is present, it overrides any values in the former.
  const [queryParams, setQueryParams] = useTypedQueryParams<
    UnvalidatedFeatureParams & { features?: string }
  >({
    // The superset of attributes between all FeatureSetSelection types.
    featureType: (v) => v,
    featureName: (v) => v,
    featureNames: {
      defaultValue: undefined,
      fromString: (v) => [v],
      fromArray: (v) => v,
    },
    featureColumns: {
      defaultValue: undefined,
      fromArray: (v) => v,
      // Can be the sentinel "all", or SerializedColumnsIndices
      fromString: (v) => v,
    },
    features: (v) => v,
  });

  const parsedSelection: UnreifiedFeatureSetSelection[] = useMemo(() => {
    if (!features?.successful) {
      return [];
    }

    // Prioritize any multi-feature selection over the single feature selection.
    if (queryParams.features) {
      const parseResult = parseMultiFeatureBlob(
        features.value,
        queryParams.features,
      );
      if (parseResult.successful) {
        return parseResult.value;
      } else {
        Sentry.captureMessage(
          `Invalid params for feature selection: ${parseResult.error.message}`,
        );
        return [];
      }
    } else {
      const parseResult = deserializeFromQueryParams(
        features.value,
        queryParams,
      );
      if (parseResult.successful) {
        return parseResult.value ? [parseResult.value] : [];
      } else {
        Sentry.captureMessage(
          `Invalid params for feature selection: ${parseResult.error.message}`,
        );
        return [];
      }
    }
  }, [features, queryParams]);

  const setFeatureSelection = useCallback(
    (selections: FeatureSetSelection[], getColumns: ColumnsLookupFn) => {
      if (selections.length === 0) {
        setQueryParams({
          ...queryParams,
          featureType: undefined,
          featureName: undefined,
          featureNames: undefined,
          featureColumns: undefined,
          features: undefined,
        });
      } else {
        const serialized =
          selections.length === 1
            ? serializeToQueryParams(selections[0], getColumns)
            : {
                features: JSON.stringify(
                  selections.map((s) => serializeToQueryParams(s, getColumns)),
                ),
              };

        setQueryParams({
          ...queryParams,
          // Clear out all feature-related params, since serialized will over-write it.
          // (i.e. if we switch between embedding and numerical features, we want
          //  to ensure we clear out the attributes related to the old type, too).
          featureName: undefined,
          featureNames: undefined,
          featureColumns: undefined,
          features: undefined,
          ...serialized,
        });
      }
    },
    [queryParams, setQueryParams],
  );

  return [parsedSelection, setFeatureSelection];
}

function parseMultiFeatureBlob(
  allFeatures: FeatureSetsByType,
  blob: string,
): Result<UnreifiedFeatureSetSelection[]> {
  try {
    const preParsed = JSON.parse(blob);
    if (!Array.isArray(preParsed)) {
      return Failure.of(
        new Error("Invalid features blob; could not extract list"),
      );
    }
    if (!preParsed.every(preValidateParams)) {
      return Failure.of(new Error("Invalid features blob; invalid entries"));
    }
    const parsed = preParsed.map((blob) =>
      deserializeFromQueryParams(allFeatures, blob),
    );

    const firstError = parsed.find(
      (r): r is Failure<UnreifiedFeatureSetSelection> => !r.successful,
    );

    return firstError
      ? Failure.of(firstError.error)
      : Success.of(
          parsed
            .filter(
              (r): r is Success<UnreifiedFeatureSetSelection> => r.successful,
            )
            .map((success) => success.value),
        );
  } catch (e) {
    return Failure.of(e as Error);
  }
}

// Exported only for testing.
export function preValidateParams(
  blob: unknown,
): blob is UnvalidatedFeatureParams {
  if (typeof blob !== "object" || blob === null) {
    return false;
  }
  const params = blob as Record<string, unknown>;
  if (typeof params.featureType !== "string") {
    return false;
  }

  const isPossibleNormalSelection = (params: Record<string, unknown>) => {
    if (typeof params.featureName !== "string") {
      return false;
    }

    if (Array.isArray(params.featureColumns)) {
      return params.featureColumns.every((v) => typeof v === "string");
    } else if (typeof params.featureColumns === "string") {
      return (
        params.featureColumns === "all" ||
        isSerializedColumnIndices(params.featureColumns)
      );
    } else {
      return false;
    }
  };

  const isPossibleEmbeddingSelection = (params: Record<string, unknown>) => {
    return (
      Array.isArray(params.featureNames) &&
      params.featureNames.every((v) => typeof v === "string")
    );
  };

  return (
    isPossibleNormalSelection(params) || isPossibleEmbeddingSelection(params)
  );
}

// If there is no view selected, but some features have been chosen, then choose a
// view that best fits the selected features
export function useAutoSelectView({
  featureSelection,
  setView,
  view,
}: {
  featureSelection: FeatureSetSelection[];
  setView: (newViewId: ViewId) => void;
  view: AnalyzeView | undefined;
}) {
  useEffect(() => {
    if (!view && featureSelection.length > 0) {
      if (featureSelection.length === 1) {
        const singleSelection = featureSelection[0];
        if (
          singleSelection.type !== "embedding" &&
          singleSelection.columns.length === 1
        ) {
          setView(SingleFeatureView.Comparisons);
        } else {
          setView(MultiFeatureView.Similarities);
        }
      } else {
        setView(MultiFeatureView.Ranking);
      }
    }
  }, [featureSelection, setView, view]);
}

// Returns the subset of plates contained in the filtered metadata
export function useFilteredPlates() {
  const { filteredMetadata } = useFeatureSetManagementContext();
  return useMemo(() => {
    const plates = uniquify(filteredMetadata.map(({ plate }) => plate));
    plates.sort(defaultComparator);
    return plates;
  }, [filteredMetadata]);
}

// Return the feature sets and columns that can be used for filtering.
// export function useFeatureFilteringParams(
export const useFeatureFilteringParams = makeDatasetApi(
  "features_for_filtering/columns",
)<AvailableFeatureFilters>();
