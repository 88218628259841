import cx from "classnames";
import React, { useCallback, useState } from "react";
import { DeprecatedButton } from "../Common/DeprecatedButton";
import { PopoverMenu } from "../Common/PopoverMenu";
import { useLabeledSetContext } from "./Context";
import { MagnifyingTwinkle } from "./MagnifyingTwinkle";
import { StainSelection } from "./StainSelection";

const LabMateSettingsButton = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<"button"> & { isActive: boolean }
>(function LabMateSettingsButton(props, ref) {
  const { isActive, ...domProps } = props;
  return (
    <DeprecatedButton
      variant="secondary"
      {...domProps}
      className="tw-group"
      ref={ref}
    >
      <div className={cx("tw-flex tw-flex-row tw-items-center")}>
        <MagnifyingTwinkle
          className={cx("tw-text-white tw-mr-2 tw-h-[24px]")}
          animate={"hover"}
          mode="fill"
        />
        LabMate
      </div>
    </DeprecatedButton>
  );
});

export function LabMateSettings() {
  const {
    state: { stains, selectedStains },
    updateState,
  } = useLabeledSetContext();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [newSelectedStains, setNewSelectedStains] = useState<string[]>([]);

  const reset = useCallback(() => {
    setNewSelectedStains(selectedStains);
    setIsPopoverOpen(true);
  }, [selectedStains]);

  const onClose = useCallback(() => {
    if (
      newSelectedStains.length !== selectedStains.length ||
      newSelectedStains.some((s) => !selectedStains.includes(s)) ||
      selectedStains.some((s) => !newSelectedStains.includes(s))
    ) {
      updateState((state) => ({
        ...state,
        selectedStains: newSelectedStains,
        queue: [],
        displayed: [],
        selected: [],
      }));
    }
    setIsPopoverOpen(false);
  }, [newSelectedStains, selectedStains, updateState]);

  return (
    <PopoverMenu
      trigger={<LabMateSettingsButton isActive={isPopoverOpen} />}
      triggerIsButton={true}
      className="tw-p-6 tw-font-sans tw-leading-tight"
      onClose={onClose}
      reset={reset}
    >
      Select stains to use as inputs to{" "}
      <span className="tw-font-bold tw-text-purple-700">
        find similar cells
      </span>{" "}
      and <span className="tw-font-bold tw-text-purple-700">train model</span>:
      <div className="tw-pt-4 tw-leading-normal">
        <StainSelection
          stains={stains}
          minCount={1}
          selection={newSelectedStains}
          onChangeSelection={setNewSelectedStains}
        />
      </div>
    </PopoverMenu>
  );
}
