// Checks whether an object has a list of keys, and if it does returns a type assertion
// indicating that the keys exist
export function hasKeys<K extends string>(
  value: unknown,
  ...keys: K[]
): value is object & Record<K, unknown> {
  return (
    typeof value === "object" &&
    value !== null &&
    keys.every((key) => key in value)
  );
}
