import classNames from "classnames";
import Slider, { Range } from "rc-slider";
import { Collapse } from "react-collapse";
import { Eye, EyeOff, Lock, Unlock } from "react-feather";
import Select from "react-select";
import { DEFAULT_DISPLAY_SETTING } from "../constants";
import {
  ChannelAssignment,
  ChannelIndex,
  ChannelMap,
  DisplayRange,
  DisplaySettings,
  Palette,
} from "../types";

const RangeWithTooltip = (Slider as any).createSliderWithTooltip(Range);
const RED = "#F1786E";
const RED_DARK = "#bd5850";
const GREEN = "#70F134";
const GREEN_DARK = "#43981c";
const BLUE = "#354EFF";
const BLUE_DARK = "#343be1";
const CYAN = "#1ED6DA";
const CYAN_DARK = "#13aaad";
const MAGENTA = "#D415FD";
const MAGENTA_DARK = "#b50bb4";
const YELLOW = "#FFDA58";
const YELLOW_DARK = "#c4a331";
const WHITE = "#A6A6A6";
const WHITE_DARK = "#5E5E5E";

const COLORS = [RED, GREEN, BLUE, CYAN, MAGENTA, YELLOW, WHITE];
const COLORS_DARK = [
  RED_DARK,
  GREEN_DARK,
  BLUE_DARK,
  CYAN_DARK,
  MAGENTA_DARK,
  YELLOW_DARK,
  WHITE_DARK,
];

type Option = {
  // The RGBCMYW slot this option refers to (not the stain/dye index in the dataset).
  readonly value: ChannelAssignment;
  readonly hex: string;
};
const BLANK_OPTION = { value: null, hex: "#000000" };

/**
 * A unified control that shows a vertical stacking of individual channel controls.
 *
 * Each sub-component shows a channel (i.e. a staining) from the source dataset, and
 * that channel can be assigned to a set of colors and rescaled independently.
 */
export function ChannelControls({
  palette,
  channelMap,
  displaySettings,
  onChangeActiveRange,
  enableChannelSettings,
  enableChannel,
  lockChannel,
  onToggleChannel,
  onLockChannel,
  onChangeChannel,
  stainSubset,
}: {
  palette: Palette | null;
  channelMap: ChannelMap;
  displaySettings: DisplaySettings[];
  onChangeActiveRange: (activeRange: DisplayRange, index: ChannelIndex) => void;
  enableChannelSettings?: [
    boolean,
    boolean,
    boolean,
    boolean,
    boolean,
    boolean,
    boolean,
  ];
  enableChannel?: [
    boolean,
    boolean,
    boolean,
    boolean,
    boolean,
    boolean,
    boolean,
  ];
  lockChannel?: [boolean, boolean, boolean, boolean, boolean, boolean, boolean];
  onToggleChannel: (index: ChannelIndex) => void;
  onLockChannel?: null | ((index: ChannelIndex) => void);
  onChangeChannel: (selectedValue: number | null, index: ChannelIndex) => void;
  stainSubset?: null | string[];
}) {
  const colorSelectOptions: Option[] = [
    BLANK_OPTION,
    ...COLORS.map((value, ix) => ({
      value: ix as ChannelAssignment,
      hex: value,
    })),
  ];
  return (
    <div className={"tw-flex tw-flex-col tw-text-slate-600 tw-mb-6"}>
      {palette?.stains.map((stain, stainIndex) => {
        const index: ChannelAssignment = channelMap.includes(stainIndex)
          ? (channelMap.indexOf(stainIndex) as ChannelIndex)
          : null;

        const channelSettings = index !== null ? displaySettings[index] : null;
        const disableSlider =
          index === null ||
          !enableChannel?.[index] ||
          !enableChannelSettings?.[index] ||
          lockChannel?.[index];
        return stainSubset === undefined ||
          stainSubset === null ||
          // TODO(you): Fix this no-unnecessary-condition rule violation
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          stainSubset?.includes(stain) ? (
          <div className={"tw-mb-2"} key={stain}>
            <div
              className={"tw-flex tw-flex-row tw-mt-1 tw-items-center tw-gap-2"}
            >
              <div
                className={"tw-flex-1 tw-truncate"}
                style={{
                  color: index !== null ? COLORS_DARK[index] : undefined,
                }}
                title={stain}
              >
                {stain}
              </div>
              <div className={"tw-flex tw-flex-row tw-items-center"}>
                <span
                  className={classNames(
                    "tw-text-gray-400 hover:tw-text-purple tw-mr-1 tw-cursor-pointer",
                    index === null && "tw-invisible",
                  )}
                  onClick={() => index !== null && onToggleChannel(index)}
                >
                  {index !== null && enableChannel?.[index] ? (
                    <Eye size={14} />
                  ) : (
                    <EyeOff size={14} />
                  )}
                </span>
                <span
                  className={classNames(
                    "tw-text-gray-400 hover:tw-text-purple tw-mr-2 tw-cursor-pointer",
                    index === null && "tw-invisible",
                  )}
                  onClick={() =>
                    onLockChannel && index !== null && onLockChannel(index)
                  }
                >
                  {index !== null && lockChannel?.[index] ? (
                    <Lock size={14} />
                  ) : (
                    <Unlock size={14} />
                  )}
                </span>
                <Select
                  className={""}
                  placeholder={""}
                  options={colorSelectOptions}
                  value={
                    index === null
                      ? BLANK_OPTION
                      : { value: index, hex: COLORS[index] }
                  }
                  onChange={(option) => {
                    if (index !== null) {
                      // Whatever this stain was assigned to before needs to be cleared
                      // out first.
                      onChangeChannel(null, index);
                    }
                    // TODO(you): Fix this no-unnecessary-condition rule violation
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    if (option && option?.value !== null) {
                      // If we have a new assignment, then immediately fire it.
                      onChangeChannel(stainIndex, option.value);
                    }
                  }}
                  formatOptionLabel={(option) => {
                    return option === BLANK_OPTION ? (
                      <div
                        className={"tw-w-[16px] tw-h-[16px] tw-rounded-[8px]"}
                      />
                    ) : (
                      <div
                        className={"tw-w-[16px] tw-h-[16px] tw-rounded-[8px]"}
                        style={{ backgroundColor: option.hex }}
                      />
                    );
                  }}
                  isMulti={false}
                  isSearchable={false}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minHeight: 32,
                      height: 32,
                      maxHeight: 32,
                      borderColor: "#dedede",
                    }),
                    indicatorSeparator: () => ({
                      display: "none",
                    }),
                    indicatorsContainer: (provided) => ({
                      ...provided,
                      width: 28,
                      height: 28,
                      marginLeft: -8,
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      paddingRight: 0,
                    }),
                  }}
                />
              </div>
            </div>
            <Collapse
              isOpened={index !== null}
              theme={{ collapse: "tw-transition-[height] tw-duration-300" }}
            >
              <div className={"tw-px-2 tw-pb-6 tw-pt-2"}>
                <ChannelSlider
                  index={index}
                  disabled={disableSlider}
                  onChangeActiveRange={onChangeActiveRange}
                  channelSettings={channelSettings}
                />
              </div>
            </Collapse>
          </div>
        ) : (
          <div key={stain}></div>
        );
      })}
    </div>
  );
}

function ChannelSlider({
  index,
  disabled,
  onChangeActiveRange,
  channelSettings,
}: {
  index: ChannelAssignment;
  disabled?: boolean;
  onChangeActiveRange: (activeRange: DisplayRange, index: ChannelIndex) => void;
  channelSettings: DisplaySettings | null;
}) {
  if (index !== null && channelSettings === null) {
    throw new Error(
      "Channel settings must be specified if channel is assigned",
    );
  }
  if (!disabled && index === null) {
    throw new Error(
      "Non-disabled sliders must be assigned to a channel index.",
    );
  }

  // TODO(benkomalo): replace with radix.
  return (
    <RangeWithTooltip
      tipProps={{ zIndex: 1 }}
      trackStyle={
        disabled || index === null
          ? undefined
          : [
              {
                background: `linear-gradient(90deg, ${COLORS_DARK[index]}, ${COLORS[index]})`,
              },
            ]
      }
      handleStyle={[
        {
          width: 18,
          height: 18,
          marginTop: -8,
          borderColor: index == null ? undefined : COLORS[index],
          filter:
            disabled || index === null
              ? undefined
              : "drop-shadow(2px 1px 1px #999)",
        },
      ]}
      disabled={disabled}
      min={
        channelSettings
          ? Math.min(
              channelSettings.boundaryRange[0],
              channelSettings.activeRange[0],
              channelSettings.activeRange[1],
            )
          : DEFAULT_DISPLAY_SETTING.boundaryRange[0]
      }
      max={
        channelSettings
          ? Math.max(
              channelSettings.boundaryRange[1],
              channelSettings.activeRange[0],
              channelSettings.activeRange[1],
            )
          : DEFAULT_DISPLAY_SETTING.boundaryRange[1]
      }
      value={
        channelSettings
          ? channelSettings.activeRange
          : DEFAULT_DISPLAY_SETTING.activeRange
      }
      defaultValue={DEFAULT_DISPLAY_SETTING.activeRange}
      allowCross={false}
      onChange={([minValue, maxValue]: [number, number]) =>
        // TODO(colin): Can't know that array is of length 3.
        onChangeActiveRange([minValue, maxValue], index as ChannelIndex)
      }
    />
  );
}
