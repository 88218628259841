import { useEffect, useMemo } from "react";
import { useFetchFeaturesAsDB } from "src/hooks/features";
import { DatasetId } from "src/types";
import { sql, useQueryAsRecords } from "src/util/sql";
import { useUserAndLocalStorageBackedState } from "src/util/state-pool-store";
import { useCachedColumnsFetch } from "./columns-cache";
import {
  FeatureSetLevel,
  FeatureSetListing,
  isPlateBasedFeature,
} from "./types";
import { inferLevelFromFeatureSetRows } from "./utils";

type DatasetLevels = Record<
  string,
  Record<string, FeatureSetLevel | undefined> | undefined
>;

// TODO(danlec): Hopefully we'll be able to rip all this out and replace it with info
// we get from postgres
export function useLevel(
  options:
    | {
        dataset: DatasetId;
        firstFeatureSet: FeatureSetListing;
        skip?: boolean;
      }
    | { dataset?: unknown; firstFeatureSet?: unknown; skip: true },
) {
  const [datasetLevels, , updateDatasetLevels] =
    useUserAndLocalStorageBackedState<DatasetLevels>("dataset_levels", {});

  const cachedLevel = options.skip
    ? undefined
    : datasetLevels[options.dataset]?.[options.firstFeatureSet.id];

  const columns = useCachedColumnsFetch(
    options.skip
      ? { skip: true }
      : isPlateBasedFeature(options.firstFeatureSet)
        ? {
            dataset: options.dataset,
            plate: options.firstFeatureSet.plate,
            featureSet: options.firstFeatureSet.id,
          }
        : {
            dataset: options.dataset,
            featureSet: options.firstFeatureSet.id,
          },
  );

  const firstColumn = columns?.successful ? columns.value[0] : undefined;
  const plateFeatures = useFetchFeaturesAsDB(
    options.skip ||
      !firstColumn ||
      !isPlateBasedFeature(options.firstFeatureSet)
      ? { skip: true }
      : {
          dataset: options.dataset,
          featureSet: options.firstFeatureSet.id,
          plate: options.firstFeatureSet.plate,
          featureSetColumns: [firstColumn],
        },
  );

  const firstRow = useQueryAsRecords(
    !options.skip && plateFeatures?.successful ? plateFeatures.value : null,
    sql`SELECT * FROM features LIMIT 1`,
  );

  const level = useMemo(() => {
    if (options.skip) {
      return undefined;
    } else if (cachedLevel !== undefined) {
      return cachedLevel;
    } else if (firstRow?.successful) {
      return inferLevelFromFeatureSetRows(firstRow.value);
    } else {
      return undefined;
    }
  }, [cachedLevel, firstRow, options.skip]);

  useEffect(() => {
    if (!options.skip && cachedLevel === undefined && level !== undefined) {
      updateDatasetLevels((datasetLevels) => ({
        ...datasetLevels,
        [options.dataset]: {
          ...datasetLevels[options.dataset],
          [options.firstFeatureSet.id]: level,
        },
      }));
    }
  }, [cachedLevel, level, options, updateDatasetLevels]);

  return level;
}
