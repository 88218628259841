import { notNull } from "@spring/core/utils";
import { ImageSet, ProcessingTechnique } from "./types";

// TODO(colin): include subset here and throughout this file.
export function chooseImageSet(
  imageSets: ImageSet[],
  {
    imageSize,
    processingMode,
  }: {
    imageSize?: number | null;
    processingMode?: ProcessingTechnique | null;
  },
): ImageSet | null {
  const matches = imageSets
    .filter(
      (imageSet) =>
        (imageSet.processing == null && processingMode == null) ||
        imageSet.processing === processingMode,
    )
    .sort((a, b) => a.size - b.size);
  if (matches.length === 0 && processingMode === "illumination-corrected") {
    // As a special case, for datasets that don't have corrected images, fall back to null.
    return chooseImageSet(imageSets, { imageSize, processingMode: null });
  } else if (matches.length === 0) {
    throw Error(
      `No image set found for size=${imageSize}, ` +
        `preprocessing=${processingMode}`,
    );
  }

  if (imageSize == null) {
    return matches[matches.length - 1];
  } else {
    // Iterate over the matches and find the smallest set that is still larger than
    // the specified size, falling back to the largest size we have available.
    for (let i = 0; i < matches.length; i++) {
      if (matches[i].size >= imageSize) {
        return matches[i];
      }
    }
    return matches[matches.length - 1];
  }
}

export function processingModes(imageSets: ImageSet[]): ProcessingTechnique[] {
  return imageSets.map((imageSet) => imageSet.processing).filter(notNull);
}

export function defaultProcessingMode(
  preprocessingModes: ProcessingTechnique[],
): ProcessingTechnique | null {
  if (preprocessingModes.includes("illumination-corrected")) {
    return "illumination-corrected";
  } else {
    return null;
  }
}
