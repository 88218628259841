import { HOST_URL } from "../env";
import { isTestUser } from "./test-user";

const windowFetch = window.fetch;

// We'll stick the values into window.apiRequests
const API_REQUESTS_PROPERTY = "apiRequests";

interface ApiRequestPending {
  state: "pending";
}

interface ApiRequestComplete {
  state: "complete";
  statusCode: number;
}
interface ApiRequestError {
  state: "error";
  error: Error;
}

type ApiRequestId = {
  id: number;
};

type ApiRequestUrl = {
  url: string;
};

type ApiRequest = ApiRequestId &
  ApiRequestUrl &
  (ApiRequestPending | ApiRequestComplete | ApiRequestError);

function updateRecordedRequests(
  entry:
    | (ApiRequestUrl & ApiRequestPending)
    | (ApiRequestId & (ApiRequestComplete | ApiRequestError)),
): number {
  const apiRequests = ((window as { [API_REQUESTS_PROPERTY]?: ApiRequest[] })[
    API_REQUESTS_PROPERTY
  ] ??= []);

  if ("id" in entry) {
    // Update an existing entry
    const index = apiRequests.findIndex(({ id }) => id === entry.id);
    const existingEntry = apiRequests[index];
    apiRequests.splice(index, 1, {
      url: existingEntry.url,
      ...entry,
    });
    return entry.id;
  } else {
    const id = apiRequests.length;
    apiRequests.push({ id, ...entry });
    return id;
  }
}

/**
 * A version of fetch that will (for the smoketest user only) record all of the API
 * requests that are made.  See the allRequests method in
 * dev/tools/ci/jobs/saas-smoke-test/tests/helpers.ts for how the saved values are
 * read back out
 */
export async function recordedFetch(
  input: Parameters<typeof windowFetch>[0],
  init?: Parameters<typeof windowFetch>[1],
): Promise<Response> {
  if (isTestUser()) {
    // The test user wants a record of every request they've made (so they can make
    // sure the right requests were sent)
    const url =
      input instanceof URL
        ? input.toString()
        : input instanceof Request
          ? input.url
          : input;

    if (url.startsWith(HOST_URL)) {
      // Looks like an API request
      const id = updateRecordedRequests({ url, state: "pending" });
      try {
        const response = await windowFetch(input, init);
        updateRecordedRequests({
          id,
          state: "complete",
          statusCode: response.status,
        });
        return response;
      } catch (ex) {
        updateRecordedRequests({
          id,
          state: "error",
          error: ex instanceof Error ? ex : new Error(String(ex)),
        });
        throw ex;
      }
    }
  }

  return windowFetch(input, init);
}
