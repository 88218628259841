import { AsyncDuckDB } from "@duckdb/duckdb-wasm";
import cx from "classnames";
import {
  ReactNode,
  forwardRef,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Cloud,
  Download,
  FilePlus,
  List as ListIcon,
  UploadCloud,
  Upload as UploadIcon,
} from "react-feather";
import { usePapaParse } from "react-papaparse";
import { FixedSizeList as List, areEqual } from "react-window";
import { downloadRecordsAsCSV } from "src/Common/DownloadLink";
import { Upload, readFileAsText } from "src/Common/Upload";
import { CollapsibleFilterSelector } from "src/Control/FilterSelector";
import { FilterSet } from "src/Control/FilterSelector/types";
import {
  EMPTY_FILTER_SET,
  serializeToSqlClause,
  updateFilters,
  updateOperator,
  useFilterColumns,
  validateFilter,
} from "src/Control/FilterSelector/utils";
import { useDatasetApi } from "src/hooks/api";
import { buildSort } from "src/util/build-sort";
import { enumChecker } from "src/util/enum";
import { useLastDefinedValue, useSize } from "src/util/hooks";
import {
  DefaultRecord,
  queryDBAsRecords,
  sanitizedColumn,
  sql,
  useQueryAsRecordsValue,
} from "src/util/sql";
import { useAreInternalFeaturesEnabled } from "src/util/users";
import invariant from "tiny-invariant";
import { Button } from "@spring/ui/Button";
import { Cell } from "./Cell";
import { ColumnMenu } from "./ColumnMenu";
import { GroupBySelector } from "./GroupBySelector";
import { DEFAULT_COLUMN_WIDTH, MAX_ROWS, ROW_HEIGHT } from "./constants";
import { usePromiseQueue } from "./hooks";
import { RowWithHistograms, SqlColumnType, TableUpload, Update } from "./types";
import { conditionForRow, toSqlColumnType } from "./utils";

interface ActiveCell {
  rowIndex: number;
  columnName: string;
}

type NestedUpdate = Record<string, unknown>;

const isSqlColumnType = enumChecker<SqlColumnType>(SqlColumnType);

const keyForRow = (groupBy: string[], record: DefaultRecord) => {
  return groupBy.map((g) => record[g]).join("\n");
};

const Row = memo(
  ({
    index,
    style,
    data,
  }: {
    index: number;
    style: React.CSSProperties;
    data: {
      rows: RowWithHistograms[];
      columns: { name: string; columnType: SqlColumnType }[];
      activeCell: ActiveCell | null;
      setActiveCell: (update: ActiveCell | null) => void;
      groupBy: string[];
      indexedUploads?: {
        newColumns: string[];
        index: Map<string, DefaultRecord>;
      };
      onUpdate(condition: RowWithHistograms, update: Update): void;
    };
  }) => {
    const { rows, columns } = data;
    const row = rows[index];
    const canEdit = useAreInternalFeaturesEnabled();

    const handleActivate = useCallback(
      (columnName: string, isActive: boolean) => {
        if (isActive) {
          if (canEdit) {
            data.setActiveCell({ rowIndex: index, columnName });
          }
        } else if (
          data.activeCell &&
          data.activeCell.rowIndex === index &&
          data.activeCell.columnName === columnName
        ) {
          data.setActiveCell(null);
        }
      },
      [canEdit, data, index],
    );

    const handleSave = useCallback(
      (name: string, stringValue: string) => {
        const column = columns.find((col) => col.name === name);
        invariant(column);

        data.onUpdate(row, {
          [name]: toSqlColumnType(stringValue, column.columnType),
        });
      },
      [columns, data, row],
    );

    const key = keyForRow(data.groupBy, row);
    const csvValues = data.indexedUploads?.index.get(key) ?? {};

    return (
      <div
        data-key={String(index)}
        key={String(index)}
        style={{
          ...style,
          top: `${
            (typeof style.top === "number"
              ? style.top
              : typeof style.top === "undefined"
                ? 0
                : parseFloat(style.top)) + ROW_HEIGHT
          }px`,
          width:
            (columns.length + (data.indexedUploads?.newColumns.length ?? 0)) *
            DEFAULT_COLUMN_WIDTH,
        }}
        className="tw-flex tw-flex-row tw-border-b"
      >
        {columns.map(({ name }) => {
          const editing =
            canEdit &&
            data.activeCell &&
            data.activeCell.rowIndex === index &&
            data.activeCell.columnName === name;

          const modifiedByCSV =
            !data.groupBy.includes(name) && csvValues.hasOwnProperty(name);

          return (
            <Cell
              key={name}
              name={name}
              value={String(modifiedByCSV ? csvValues[name] : row[name])}
              histogram={row[`histogram_${name}`]}
              mode={modifiedByCSV ? "pending" : editing ? "edit" : "view"}
              onActivate={handleActivate}
              onSave={handleSave}
            />
          );
        })}
        {data.indexedUploads?.newColumns.map((name) => {
          return (
            <Cell
              key={name}
              name={name}
              value={String(csvValues[name] ?? "")}
              histogram={row[`histogram_${name}`]}
              mode={"pending"}
              onActivate={handleActivate}
              onSave={handleSave}
            />
          );
        })}
      </div>
    );
  },
  areEqual,
);
Row.displayName = "Row";

function isIgnoredColumn(column: string) {
  // The parquet file may include a __index_level_0__ column which isn't useful for
  // filtering
  return column === "__index_level_0__";
}

export function DBTable({
  className,
  db,
  tableName,
  defaultGroupBy,
  downloadFileName,
  onReload,
}: {
  className?: string;
  db: AsyncDuckDB;
  tableName: string;
  defaultGroupBy?: string[];
  downloadFileName: string;
  onReload: () => void;
}) {
  const refDisplayArea = useRef<HTMLDivElement>(null);
  const displayAreaSize = useSize(refDisplayArea, "border-box");
  const [filterSet, setFilterSet] = useState<FilterSet>(EMPTY_FILTER_SET);
  const [groupBy, setGroupBy] = useState<string[]>(defaultGroupBy ?? []);
  const [activeCell, setActiveCell] = useState<ActiveCell | null>(null);

  const [uploads, setUploads] = useState<TableUpload[]>([]);

  const filterColumns = useFilterColumns(db, tableName);

  const filteredFilterColumns = useMemo(
    () => filterColumns?.filter((entry) => !isIgnoredColumn(entry.id)),
    [filterColumns],
  );

  const filterSerialized = useMemo(
    () => serializeToSqlClause(filterSet),
    [filterSet],
  );

  const validateAndSetFilter = (filter: FilterSet) => {
    validateFilter(db, filter, tableName).then((filterSet) => {
      setFilterSet(filterSet);
    });
  };

  const rawColumnsFromQuery = useQueryAsRecordsValue<
    {
      column_name: string;
      column_type: string;
    },
    undefined
  >(db, sql`DESCRIBE SELECT * FROM '${tableName}'`, undefined);

  const columnsRef = useRef<
    { column_name: string; column_type: string }[] | undefined
  >(rawColumnsFromQuery);

  const prevColumns = columnsRef.current;

  if (
    rawColumnsFromQuery &&
    (!prevColumns ||
      rawColumnsFromQuery.length !== prevColumns.length ||
      rawColumnsFromQuery.some(
        ({ column_name, column_type }, index) =>
          column_name !== prevColumns[index].column_name ||
          column_type !== prevColumns[index].column_type,
      ))
  ) {
    columnsRef.current = rawColumnsFromQuery;
  }

  const rawColumns = columnsRef.current;

  const visibleColumns = useMemo(
    () =>
      (rawColumns ?? [])
        .filter(
          (
            column,
          ): column is { column_name: string; column_type: SqlColumnType } => {
            if (isIgnoredColumn(column.column_name)) {
              return false;
            }
            if (isSqlColumnType(column.column_type)) {
              return true;
            } else {
              console.error(
                `Ignoring column ${column.column_name} with type ${column.column_type}`,
              );
              return false;
            }
          },
        )
        .map(({ column_name, column_type }) => ({
          name: column_name,
          columnType: column_type,
        })),
    [rawColumns],
  );

  const columns = useMemo(
    () =>
      visibleColumns.sort(
        // Sort the columns that we're grouping by to the front
        buildSort(
          ({ name }: { name: string }) => groupBy.includes(name),
          ({ name }: { name: string }) => groupBy.indexOf(name),
          "name",
        ),
      ),
    [groupBy, visibleColumns],
  );

  const columnNames = useMemo(() => columns.map(({ name }) => name), [columns]);

  const filteredGroupBy = useMemo(() => {
    return groupBy.filter((column) => columnNames.includes(column));
  }, [columnNames, groupBy]);

  const rowsCountRows = useLastDefinedValue(
    useQueryAsRecordsValue<{ count: bigint }, undefined>(
      db,
      sql`SELECT COUNT(*) AS count FROM (
      SELECT ${
        filteredGroupBy.length > 0
          ? filteredGroupBy.map(sanitizedColumn).join(", ")
          : "*"
      } FROM ${tableName}
      WHERE ${filterSerialized}
      ${
        filteredGroupBy.length > 0
          ? ` GROUP BY ${filteredGroupBy.map(sanitizedColumn).join(", ")} `
          : ""
      }
    )`,
      undefined,
    ),
  );

  // If we haven't yet got anything back for rowsCountRows, we don't know the number
  // matching rows
  const rowsCount =
    rowsCountRows && rowsCountRows.length === 1
      ? rowsCountRows[0].count
      : undefined;

  const getQuery = useCallback(
    (limit?: number) =>
      columns.length > 0
        ? sql`SELECT ${columns
            .map(({ name }) =>
              filteredGroupBy.length === 0 || filteredGroupBy.includes(name)
                ? sanitizedColumn(name)
                : // If we're using grouping, for the columns that we aren't grouping
                  // by we need to reduce down to a single value to display in a row
                  // Here we get the first value we find, and then also a histogram of
                  // all values
                  sql`FIRST(${sanitizedColumn(name)}) AS ${sanitizedColumn(
                    name,
                  )}, HISTOGRAM(COALESCE(${sanitizedColumn(
                    name,
                  )}, '<null>')) AS ${sanitizedColumn(`histogram_${name}`)}`,
            )
            .join(", ")} FROM ${tableName} 
    WHERE ${filterSerialized}
    ${
      filteredGroupBy.length > 0
        ? ` GROUP BY ${filteredGroupBy.map(sanitizedColumn).join(", ")} 
            ORDER BY ${filteredGroupBy.map(sanitizedColumn).join(", ")} `
        : ""
    }
    ${limit !== undefined ? ` LIMIT ${MAX_ROWS}` : ""}`
        : undefined,
    [columns, filterSerialized, filteredGroupBy, tableName],
  );

  const rows =
    useLastDefinedValue(
      useQueryAsRecordsValue<RowWithHistograms, undefined>(
        db,
        getQuery(MAX_ROWS),
        undefined,
      ),
    ) ?? [];

  // Keep a mapping from the uploads to new/modified cells to show on the table
  const indexedUploads = useMemo(():
    | {
        newColumns: string[];
        index: Map<string, DefaultRecord>;
      }
    | undefined => {
    const map: Map<string, DefaultRecord> = new Map();

    // If we aren't grouping by anything then we won't know what to join on
    if (groupBy.length === 0) {
      return undefined;
    }

    const columns = Array.from(
      new Set(
        uploads.flatMap((upload) =>
          upload.data.flatMap((row) => Object.keys(row)),
        ),
      ),
    ).filter((c) => !groupBy.includes(c));

    uploads.forEach((upload) => {
      upload.data.forEach((row) => {
        const key = keyForRow(groupBy, row);
        map.set(key, { ...map.get(key), ...row });
      });
    });

    return {
      newColumns: columns.filter((column) => !columnNames.includes(column)),
      index: map,
    };
  }, [columnNames, groupBy, uploads]);

  const { readString } = usePapaParse();

  const handleUpload = useCallback(
    async (files: FileList) => {
      const contents = await Promise.all(
        Array.from(files).map(async (file) => ({
          name: file.name,
          size: file.size,
          text: await readFileAsText(file),
        })),
      );

      const parsed = await Promise.all(
        contents.map(async (entry) => ({
          ...entry,
          csv: await new Promise<Record<string, unknown>[]>((resolve) => {
            readString(entry.text, {
              worker: true,
              header: true,
              complete: (result) =>
                resolve(result.data as Record<string, unknown>[]),
            });
          }),
        })),
      );

      setUploads((uploads) => [
        ...uploads,
        ...parsed.map(
          (entry): TableUpload => ({
            source: entry.name,
            data: entry.csv,
          }),
        ),
      ]);
    },
    [readString],
  );

  const handleEmptyQueue = useCallback(() => {
    onReload();
  }, [onReload]);

  const { addToQueue, busy } = usePromiseQueue({
    onEmpty: handleEmptyQueue,
    waitMs: 1000,
  });

  const api = useDatasetApi();

  const handleMerge = useCallback(async () => {
    if (!indexedUploads) {
      return;
    }
    const initialGroups = groupBy.slice(0, groupBy.length - 1);
    const finalGroup = groupBy.at(-1);

    if (!finalGroup) {
      return;
    }

    const update: NestedUpdate = {};

    for (const [, record] of indexedUploads.index.entries()) {
      let target = update;

      initialGroups.forEach((column) => {
        target = (target[String(record[column])] ??= {}) as NestedUpdate;
      });

      target[String(record[finalGroup])] = record;
    }

    await addToQueue(() =>
      api.route("metadata/join").post({ on: groupBy, update }).json(),
    );

    setUploads([]);
  }, [addToQueue, api, groupBy, indexedUploads]);

  const handleDownload = useCallback(() => {
    const query = getQuery();
    if (!query) {
      return;
    }

    queryDBAsRecords<RowWithHistograms>(db, query).then((rows) => {
      const withoutHistograms = rows.map((row) => {
        return Object.fromEntries(
          columnNames.map((column) => {
            const histogram = row[`histogram_${column}`];
            const value =
              histogram && histogram.size > 1
                ? // There are multiple entries for this cell
                  Object.keys(histogram.toJSON()).join(", ")
                : row[column];
            return [column, value];
          }),
        );
      });
      downloadRecordsAsCSV(withoutHistograms, downloadFileName);
    });
  }, [getQuery, db, downloadFileName, columnNames]);

  const handleDeleteColumn = useCallback(
    (column: string) =>
      addToQueue(async () => {
        api.route("metadata/column/<column>", { column }).delete().json();
      }),
    [addToQueue, api],
  );

  const innerElement = useMemo(() => {
    const Component = forwardRef<
      HTMLDivElement,
      { style: React.CSSProperties; children: ReactNode }
    >(({ style, children, ...rest }, ref) => (
      <div ref={ref} style={style} {...rest}>
        <div
          className="tw-flex tw-flex-row tw-bg-gray-100 tw-border-b tw-sticky tw-top-0 tw-z-[1]"
          style={{
            height: ROW_HEIGHT,
            width:
              (columns.length + (indexedUploads?.newColumns.length ?? 0)) *
              DEFAULT_COLUMN_WIDTH,
          }}
        >
          {columns.map(({ name }) => (
            <div
              key={name}
              className="tw-p-sm tw-truncate tw-border-r tw-flex tw-flex-row tw-items-center"
              style={{ width: DEFAULT_COLUMN_WIDTH }}
            >
              <div className="tw-flex-1">{name}</div>
              {groupBy.includes(name) ? (
                <ListIcon size={12} />
              ) : (
                <ColumnMenu column={name} onDelete={handleDeleteColumn} />
              )}
            </div>
          ))}
          {indexedUploads?.newColumns.map((column) => (
            <div
              key={column}
              className="tw-p-sm tw-truncate tw-border-r tw-flex tw-flex-row tw-items-center"
              style={{ width: DEFAULT_COLUMN_WIDTH }}
            >
              <div className="tw-flex-1">{column}</div>
            </div>
          ))}
        </div>

        {children}
      </div>
    ));
    Component.displayName = "innerElement";

    return Component;
  }, [columns, groupBy, handleDeleteColumn, indexedUploads?.newColumns]);

  const handleUpdate = useCallback(
    (row: RowWithHistograms, update: Update) => {
      const condition = conditionForRow(row, groupBy);
      addToQueue(async () => {
        // Send the update to the server
        await api.route("metadata/update").post({ condition, update }).finish();
      });
    },
    [addToQueue, api, groupBy],
  );

  return (
    <div className={cx("tw-flex tw-flex-col tw-overflow-hidden", className)}>
      <div className="tw-py-md tw-min-h-[56px] tw-flex tw-flex-row tw-gap-md tw-items-center">
        <GroupBySelector
          value={groupBy}
          onChange={setGroupBy}
          columns={columnNames}
        />
        <CollapsibleFilterSelector
          columns={filteredFilterColumns ?? []}
          filterSet={filterSet}
          onChangeFilters={(filters) =>
            validateAndSetFilter(updateFilters(filterSet, filters))
          }
          onChangeOperator={(operator, newFilters) =>
            validateAndSetFilter(
              updateOperator(filterSet, operator, newFilters),
            )
          }
          triggerClasses="tw-flex-1"
          depth={0}
          maxDepth={1}
          metadata={db}
        />
        <div className="tw-flex-1" />
        {uploads.length > 0 && (
          <Button
            onClick={handleMerge}
            className="tw-flex tw-flex-row tw-items-center tw-gap-xs"
          >
            <FilePlus size={16} /> <span>Merge CSVs</span>
          </Button>
        )}
        <Upload
          onUpload={handleUpload}
          className="tw-flex tw-flex-row tw-items-center tw-gap-xs"
          accept=".csv"
        >
          <UploadIcon size={16} /> <span>Upload CSV</span>
        </Upload>
        <Button
          onClick={handleDownload}
          className="tw-flex tw-flex-row tw-items-center tw-gap-xs"
        >
          <Download size={16} /> <span>Download as CSV</span>
        </Button>
        <div className="tw-text-xs tw-text-slate-500">
          Showing {rows.length.toLocaleString()} of{" "}
          {rowsCount?.toLocaleString() ?? "..."} rows
        </div>
        <div className="tw-text-slate-500">
          {busy ? <UploadCloud size={16} /> : <Cloud size={16} />}
        </div>
      </div>
      <div
        ref={refDisplayArea}
        className="tw-flex-1 tw-border tw-overflow-hidden"
      >
        {displayAreaSize && (
          <List
            itemData={{
              rows,
              columns,
              groupBy,
              indexedUploads,
              activeCell,
              setActiveCell,
              onUpdate: handleUpdate,
            }}
            itemCount={rows.length}
            itemSize={ROW_HEIGHT}
            width={displayAreaSize.width}
            height={displayAreaSize.height}
            innerElementType={innerElement}
            overscanCount={10}
          >
            {Row}
          </List>
        )}
      </div>
    </div>
  );
}
