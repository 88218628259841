import {
  ReactNode,
  Reducer,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import {
  useDatasetTimepoints,
  useTimepoints,
} from "src/hooks/immunofluorescence";
import { usePrevious } from "src/hooks/utils";
import { Field, Timepoint } from "src/imaging/types";
import { QS, useTypedQueryParams } from "src/routing";
import { DatasetId } from "src/types";
import {
  TimeSeriesState,
  TimeSeriesStateReducerAction,
  initialTimeSeriesState,
  timeSeriesStateReducer,
} from "./state";

type TimeSeriesContextValue = {
  timepoints: Array<Timepoint>;
  maxTimepoint: number;
  isLoaded: boolean;
  isPlaying: boolean;
  isLooping: boolean;
  currentTimepoint: number;
  cachedTimepoints: Array<boolean>;
  imagesPerSecond: number;
  startPlayback: () => void;
  pausePlayback: () => void;
  toggleLooping: () => void;
  stepNext: () => void;
  stepPrevious: () => void;
  jumpToTimepoint: (timepoint: number) => void;
  setIsLoaded: (isLoaded: boolean) => void;
  setImagesPerSecond: (imagesPerSecond: number) => void;
};

const TimeSeriesContext = createContext<TimeSeriesContextValue | null>(null);
TimeSeriesContext.displayName = "TimeSeriesContext";

/**
 * Provides navigation and playback for timeseries data
 * It's up to the consumer to handle missing time series data (that is, if the currentTimepoint
 * doesn't exist in timepoints for the given plate/well/field).
 */
export function TimeSeriesContextProvider({
  children,
  dataset,
  plate,
  well,
  field,
}: {
  children: ReactNode;
  dataset: DatasetId;
  plate: string | null;
  well: string | null;
  field: Field | null;
}) {
  const previousWell = usePrevious(well);
  const previousField = usePrevious(field);

  // Get timepoint from URL param if any
  const [{ t: urlTimepoint }, setQueryParams] = useTypedQueryParams<{
    t: number | undefined;
  }>({
    t: QS.int(),
  });

  const [state, dispatch] = useReducer<
    Reducer<TimeSeriesState, TimeSeriesStateReducerAction>
  >(timeSeriesStateReducer, {
    ...initialTimeSeriesState,
    currentTimepoint: urlTimepoint ?? 0,
  });

  // Sync current timepoint to URL param
  useEffect(() => {
    setQueryParams({ t: state.currentTimepoint });
  }, [setQueryParams, state.currentTimepoint]);

  const datasetTimepoints = useDatasetTimepoints({ dataset });
  useEffect(() => {
    if (datasetTimepoints?.successful) {
      dispatch({
        type: "setMaxTimepoint",
        maxTimepoint:
          datasetTimepoints.value[datasetTimepoints.value.length - 1],
      });
    }
  }, [datasetTimepoints]);

  const timepoints = useTimepoints(
    plate && well && field
      ? {
          dataset,
          acquisition: plate,
          well,
          field,
        }
      : { skip: true },
  );
  useEffect(() => {
    if (timepoints?.successful) {
      dispatch({
        type: "setTimepoints",
        timepoints: timepoints.value,
      });
    }
  }, [timepoints]);

  // If we navigate away from the current well/field, reset
  useEffect(() => {
    if (
      (previousWell !== null && well !== previousWell) ||
      (previousField !== null && field !== previousField)
    ) {
      dispatch({ type: "resetState" });
    }
  }, [dispatch, field, previousField, well, previousWell]);

  const playbackTimer = useRef<number | null>(null);

  const clearPlaybackTimerIfNeeded = useCallback(() => {
    if (playbackTimer.current === null) {
      return;
    }
    window.clearInterval(playbackTimer.current);
    playbackTimer.current = null;
  }, []);

  const setPlaybackTimer = useCallback(
    (imagesPerSecond: number) => {
      clearPlaybackTimerIfNeeded();
      const delayMs = 1000 / imagesPerSecond;
      playbackTimer.current = window.setInterval(() => {
        dispatch({ type: "stepNext" });
      }, delayMs);
    },
    [clearPlaybackTimerIfNeeded],
  );

  // If we reach the end of the time series or change wells/fields,
  // playback will automatically stop
  // If that happens, we should also clear the interval
  useEffect(() => {
    if (!state.isPlaying) {
      clearPlaybackTimerIfNeeded();
    }
  }, [state.isPlaying, clearPlaybackTimerIfNeeded]);

  const startPlayback = useCallback(() => {
    dispatch({ type: "setIsPlaying", isPlaying: true });
    setPlaybackTimer(state.imagesPerSecond);
  }, [dispatch, setPlaybackTimer, state.imagesPerSecond]);

  const pausePlayback = useCallback(() => {
    clearPlaybackTimerIfNeeded();
    dispatch({ type: "setIsPlaying", isPlaying: false });
  }, [dispatch, clearPlaybackTimerIfNeeded]);

  const toggleLooping = useCallback(() => {
    dispatch({ type: "toggleIsLooping" });
  }, [dispatch]);

  const stepNext = useCallback(() => {
    dispatch({ type: "stepNext", manual: true });
  }, [dispatch]);

  const stepPrevious = useCallback(() => {
    dispatch({ type: "stepPrevious" });
  }, [dispatch]);

  const jumpToTimepoint = useCallback(
    (timepoint: number) => {
      dispatch({ type: "jumpToTimepoint", timepoint });
    },
    [dispatch],
  );

  const setIsLoaded = useCallback(
    (isLoaded: boolean) => {
      dispatch({ type: "setIsLoaded", isLoaded });
    },
    [dispatch],
  );

  const setImagesPerSecond = useCallback(
    (imagesPerSecond: number) => {
      dispatch({ type: "setImagesPerSecond", imagesPerSecond });
      setPlaybackTimer(imagesPerSecond);
    },
    [dispatch, setPlaybackTimer],
  );

  return (
    <TimeSeriesContext.Provider
      value={{
        isLoaded: state.isLoaded,
        isPlaying: state.isPlaying,
        isLooping: state.isLooping,
        currentTimepoint: state.currentTimepoint,
        timepoints: state.timepoints,
        maxTimepoint: state.maxTimepoint,
        cachedTimepoints: state.cachedTimepoints,
        imagesPerSecond: state.imagesPerSecond,
        startPlayback,
        pausePlayback,
        toggleLooping,
        stepNext,
        stepPrevious,
        jumpToTimepoint: jumpToTimepoint,
        setIsLoaded,
        setImagesPerSecond,
      }}
    >
      {children}
    </TimeSeriesContext.Provider>
  );
}

export function useTimeSeriesContext() {
  const context = useContext(TimeSeriesContext);

  if (context === null) {
    throw new Error(
      "useTimeSeriesContext must be used within <TimeSeriesContextProvider />",
    );
  }

  return context;
}
