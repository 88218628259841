import { AsyncDuckDB } from "@duckdb/duckdb-wasm";
import { useCallback, useState } from "react";
import { FullScreenLoader } from "src/Common/FullScreenLoader";
import { DBTable } from "src/DBTable";
import { useSharedMemoAsyncValue } from "src/SharedMemo";
import { useDatasetApi } from "src/hooks/api";
import { DatasetId, WorkspaceId } from "src/types";
import { useLastDefinedValue } from "src/util/hooks";
import { addParquetBlobToDb, terminate } from "src/util/sql";

const TABLE_NAME = "sample_metadata.parquet";

export function MetadataTable({
  className,
  workspace,
  dataset,
}: {
  className?: string;
  workspace: WorkspaceId;
  dataset: DatasetId;
}) {
  const [updateCount, setUpdateCount] = useState(0);
  const api = useDatasetApi({
    workspace,
    dataset,
  });

  const db = useLastDefinedValue(
    useSharedMemoAsyncValue<AsyncDuckDB, [number]>({
      id: `metadata-${workspace}-${dataset}`,
      dependencies: [updateCount],
      async initialize() {
        const blob = await api.route("sample_metadata_parquet").get().blob();

        return addParquetBlobToDb(blob, TABLE_NAME);
      },
      async cleanup(value) {
        const db = await value;
        terminate(db);
      },
    }),
  );

  const handleReload = useCallback(() => {
    setUpdateCount((lastCount) => lastCount + 1);
  }, []);

  return (
    <>
      {db ? (
        <DBTable
          className={className}
          db={db}
          tableName={TABLE_NAME}
          defaultGroupBy={["plate", "well"]}
          downloadFileName={`${dataset}_sample_metadata.csv`}
          onReload={handleReload}
        />
      ) : (
        <FullScreenLoader />
      )}
    </>
  );
}
