import cx from "classnames";
import { ChevronDown } from "react-feather";

export type Variant = "UpOpenDownClose" | "DownOpenRightClose";

export function SpinningChevron({
  isOpen,
  variant = "UpOpenDownClose",
}: {
  isOpen: boolean;
  variant?: Variant;
}) {
  let rotationClasses: string;
  switch (variant) {
    case "UpOpenDownClose":
      rotationClasses = isOpen ? "tw-rotate-180" : "";
      break;
    case "DownOpenRightClose":
      rotationClasses = isOpen ? "" : "-tw-rotate-90";
      break;
  }
  return (
    <div
      className={cx("tw-text-sm", "tw-transition-transform", rotationClasses)}
    >
      <ChevronDown size={16} />
    </div>
  );
}
