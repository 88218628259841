import cx from "classnames";
import { ChevronDown } from "react-feather";

export function SpinningChevron({ isOpen }: { isOpen: boolean }) {
  return (
    <div
      className={cx(
        "tw-text-gray-400 tw-text-sm",
        "group-hover:tw-text-gray-500 tw-transition-transform",
        isOpen && "tw-rotate-180",
      )}
    >
      <ChevronDown size={16} />
    </div>
  );
}
