import { ReactNode, useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import { useDataset } from "src/hooks/datasets";
import { useWorkspaces } from "src/hooks/workspace";
import { DatasetId, WorkspaceId } from "src/types";
import { WorkspaceContext, WorkspaceContextValue } from "./hooks";

export function WorkspaceContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const match = useRouteMatch<{
    id: WorkspaceId;
    section?: "e";
    dataset?: DatasetId;
  }>(`/workspace/:id/:section(e)?/:dataset?`);

  const workspaceId = match?.params.id;
  const datasetId = match?.params.dataset;

  const workspacesFetchable = useWorkspaces();
  const workspaceFetchable = workspaceId
    ? workspacesFetchable?.map(
        (workspaces) => workspaces.find((ws) => ws.id === workspaceId) ?? null,
      )
    : undefined;

  const datasetFetchable = useDataset(
    workspaceId && datasetId
      ? { dataset: datasetId, workspace: workspaceId }
      : { skip: true },
  );

  const value = useMemo(() => {
    return {
      workspaceId,
      datasetId,
      workspacesFetchable,
      workspaceFetchable,
      datasetFetchable,
    };
  }, [
    datasetFetchable,
    datasetId,
    workspaceFetchable,
    workspaceId,
    workspacesFetchable,
  ]);

  return (
    <WorkspaceContext.Provider value={value}>
      {children}
    </WorkspaceContext.Provider>
  );
}

export function ManualWorkspaceContextProvider({
  value,
  children,
}: {
  children: ReactNode;
  value: WorkspaceContextValue;
}) {
  return (
    <WorkspaceContext.Provider value={value}>
      {children}
    </WorkspaceContext.Provider>
  );
}
