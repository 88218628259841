import { ReactNode } from "react";
import { Tooltip } from "@spring/ui/Tooltip";

/**
 * Optionally wrap contents with tooltip about the well it's from.
 */
export default function MaybeWithTooltip({
  // Note: metadata is from the item itself, and so may have other information like
  // the field or cell coordinate.
  metadata,
  wellSampleMetadata,
  children,
}: {
  metadata: { [key: string]: any };
  wellSampleMetadata: { [key: string]: any } | undefined;
  children: ReactNode;
}) {
  if (!wellSampleMetadata) {
    return <>{children}</>;
  }

  const primaryId =
    "row" in metadata && "column" in metadata
      ? `${metadata.well} ${metadata.field} @ ${metadata.row | 0},${
          metadata.column | 0
        }`
      : "field" in metadata
        ? `${metadata.well} ${metadata.field}`
        : metadata.well;
  return (
    <Tooltip
      side="right"
      solid={true}
      contents={
        <div className="tw-max-w-md">
          <div className="tw-truncate">{primaryId}</div>
          <hr className="tw-my-1" />
          {Object.keys(wellSampleMetadata).map((columnName) => (
            <div key={columnName} className="tw-truncate">
              <span className="tw-text-slate-500">{columnName}:</span>{" "}
              <span>{wellSampleMetadata[columnName] ?? "<null>"}</span>
            </div>
          ))}
        </div>
      }
    >
      {children}
    </Tooltip>
  );
}
