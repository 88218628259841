import { Timepoint } from "src/imaging/types";
import { getNewCacheState } from "./utils";

export type TimeSeriesState = {
  isLoaded: boolean;
  // Timepoints existing on the specific plate/well/field
  timepoints: Array<Timepoint>;
  // Max timepoint from the dataset – we assume a complete set is 0 – maxTimepoint
  maxTimepoint: number;
  currentTimepoint: number;
  cachedTimepoints: Array<boolean>;
  areAllTimepointsCached: boolean;
  isPlaying: boolean;
  isLooping: boolean;
  imagesPerSecond: number;
};

export const initialTimeSeriesState: TimeSeriesState = {
  isLoaded: false,
  currentTimepoint: 0,
  timepoints: [0],
  maxTimepoint: 0,
  cachedTimepoints: [],
  areAllTimepointsCached: false,
  isPlaying: false,
  isLooping: false,
  imagesPerSecond: 3,
};

export type TimeSeriesStateReducerAction =
  | { type: "setIsLoaded"; isLoaded: boolean }
  | { type: "setTimepoints"; timepoints: Array<Timepoint> }
  | { type: "setMaxTimepoint"; maxTimepoint: number }
  | { type: "setIsPlaying"; isPlaying: boolean }
  | { type: "resetState" }
  | { type: "toggleIsLooping" }
  | { type: "setImagesPerSecond"; imagesPerSecond: number }
  | { type: "stepNext"; manual?: boolean }
  | { type: "stepPrevious" }
  | { type: "jumpToTimepoint"; timepoint: number };

export function timeSeriesStateReducer(
  state: TimeSeriesState,
  action: TimeSeriesStateReducerAction,
): TimeSeriesState {
  switch (action.type) {
    case "setIsLoaded": {
      return {
        ...state,
        isLoaded: action.isLoaded,
      };
    }

    case "setTimepoints": {
      const newState = { ...state, timepoints: action.timepoints };
      const newCacheState = getNewCacheState(newState);
      return {
        ...newState,
        ...newCacheState,
      };
    }

    case "setMaxTimepoint": {
      const newState = { ...state, maxTimepoint: action.maxTimepoint };
      const newCacheState = getNewCacheState(newState);
      return {
        ...newState,
        ...newCacheState,
      };
    }

    case "setIsPlaying": {
      return {
        ...state,
        isPlaying: action.isPlaying,
        // Move immediately to the next image when starting playback
        // If at the end, restart from the beginning
        currentTimepoint: action.isPlaying
          ? state.currentTimepoint < state.maxTimepoint
            ? state.currentTimepoint + 1
            : 0
          : state.currentTimepoint,
      };
    }

    case "resetState": {
      const newState = {
        ...state,
        currentTimepoint: 0,
        cachedTimepoints: [],
      };
      const newCacheState = getNewCacheState(newState);
      return {
        ...newState,
        ...newCacheState,
        isLoaded: false,
        isPlaying: false,
      };
    }

    case "toggleIsLooping": {
      return {
        ...state,
        isLooping: !state.isLooping,
      };
    }

    case "setImagesPerSecond": {
      return {
        ...state,
        imagesPerSecond: Math.max(action.imagesPerSecond, 1),
      };
    }

    case "stepNext": {
      if (state.currentTimepoint >= state.maxTimepoint) {
        if (!state.isPlaying) {
          return state;
        }

        if (!state.isLooping) {
          return { ...state, isPlaying: false };
        }
      }

      const newTimepoint =
        state.currentTimepoint >= state.maxTimepoint
          ? 0
          : state.currentTimepoint + 1;
      const newCacheState = getNewCacheState({
        ...state,
        currentTimepoint: newTimepoint,
      });

      return {
        ...state,
        ...newCacheState,
        currentTimepoint: newTimepoint,
        // If user manually stepped, stop playback
        isPlaying: action.manual ? false : state.isPlaying,
      };
    }

    case "stepPrevious": {
      if (state.currentTimepoint <= 0) {
        return state;
      }

      const newTimepoint = state.currentTimepoint - 1;
      const newCachedState = getNewCacheState({
        ...state,
        currentTimepoint: newTimepoint,
      });

      return {
        ...state,
        ...newCachedState,
        currentTimepoint: newTimepoint,
        isPlaying: false,
      };
    }

    case "jumpToTimepoint": {
      return {
        ...state,
        currentTimepoint: Math.min(
          Math.max(0, action.timepoint),
          state.maxTimepoint,
        ),
      };
    }
  }
}
