import type { VariableSourceColumn } from "../Table/types";

export const HASH_WIDTH = 2;
export const HASH_HEIGHT = 8;
export const HASH_GAP = 4;
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const AXIS_LABEL_HEIGHT = 16;
export const X_AXIS_HEIGHT = 70;
export const Y_AXIS_LABEL_WIDTH = 32;
export const AXIS_TICK_LABEL_DEFAULT_WIDTH = 48;
// The least amount of space we can have available and still render a label
export const X_AXIS_MINIMUM_LABEL_WIDTH = 32;
export const X_PADDING = 80;
export const Y_PADDING = 32;
// We split the viewable area up into squares with sides of BLOCK_SIZE length and then
// index each point by the id of the block that it's in.  (We use this in the blocksNear
// utility function)
export const BLOCK_SIZE = 32;
export const CURSOR_NEAR_THRESHOLD = 16;
// Number of bins we split the x axis into, before computing the per-bin density that
// we use to determine the spread of the y values (when we're putting the y values
// into "Random" positions)
export const DENSITY_BIN_COUNT = 128;
// When zooming one step, the new size of the area can be at most (1 + MAX_ZOOM) and
// at least (1 - MAX_ZOOM) times as large as the current size
export const MAX_ZOOM_STEP = 0.2;
// When panning, the largest number of pixels we'll move in a step
export const MAX_PAN_STEP = 20;
// How far outside the visible bounds we'll still draw points (so we can see e.g.
// partially drawn points)
export const VISIBILITY_MARGIN = 16;
export const DEFAULT_COLOR = "#666";
export const POINT_SIZE = 8;
export const POINT_MARGIN = 2;
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const MAX_HISTOGRAM_BARS = 16;
export const HISTOGRAM_BAR_MARGIN = 32;

export const AXIS_COLOR = "rgb(100, 116, 139)"; // slate-500
export const FONT_FAMILY =
  "Apercu, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji";
export const AXIS_TICK_LABEL_FONT = `10px ${FONT_FAMILY}`;

export const RANDOM_COLUMN: VariableSourceColumn = {
  id: "random",
  source: "features",
};
