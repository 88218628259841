import { MouseEventHandler, useState } from "react";

export function PopupTargetText({
  text,
  style,
  onMouseEnter,
  onMouseLeave,
}: {
  text: string | number;
  style?: Record<string, string | number>;
  onMouseEnter: MouseEventHandler<HTMLSpanElement>;
  onMouseLeave: MouseEventHandler<HTMLSpanElement>;
}) {
  // TODO(davidsharff): I worked around this issue in the comparisons view by switching onMouseEnter to onMouseOver.
  // It may work here as well.
  // TODO(davidsharff): hopefully this hack goes away with the refactor to support persisting the popup if they hover onto it
  //  The Mouse enter event isn't always called in weird cases like hovering from one target elem to the next
  //  or when hovering off the elem onto the popup and then landing on a new target elem when the popup closes.
  const [isHovering, setIsHovering] = useState<boolean>(false);

  // TODO(davidsharff): I ripped and adapted from MegaMap. Convert to tw
  const baseStyle: Record<string, string> = {
    fontSize: "13px",
    // color: "rgb(77, 77, 77)",
    // color: "#5A88FF"
    padding: "0.5px 1.5px",
    marginRight: "3px",
    borderRadius: "3px",
    maxWidth: "140px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  const handleMouseEnter: MouseEventHandler<HTMLSpanElement> = (e) => {
    setIsHovering(true);
    onMouseEnter(e);
  };
  // Part of the hack described above the state definition.
  const handleMouseMove: MouseEventHandler<HTMLSpanElement> = (e) => {
    if (!isHovering) {
      onMouseEnter(e);
    }
    setIsHovering(true);
  };

  const handleMouseLeave: MouseEventHandler<HTMLSpanElement> = (e) => {
    setIsHovering(false);
    onMouseLeave(e);
  };

  return (
    <span
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        ...baseStyle,
        ...style,
      }}
      className="tw-text-blue tw-bg-gray-100 hover:tw-bg-slate-200"
    >
      {" "}
      {text}{" "}
    </span>
  );
}
