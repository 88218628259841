import { useMemo } from "react";
import { Select } from "@spring/ui/Select";
import { UntypedWellSampleMetadataRow } from "../../types";
import { columnComparator } from "../../util/sorting";
import { elementsInEverySet } from "./set-utils";

export default function MetadataSelector({
  metadata,
  plates,
  disabled,
  interestingMetadataColumns,
  selectedMetadata,
  onSelectMetadata,
}: {
  metadata: UntypedWellSampleMetadataRow[];
  plates: string[];
  disabled?: boolean;
  interestingMetadataColumns: string[];
  selectedMetadata: string | null;
  onSelectMetadata: (metadata: string | null) => void;
}) {
  // TODO(benkomalo): this can run slow (over 100ms on reasonably sized datasets)
  // and it's unclear to me it's doing anything? Won't all columns be the same for
  // all rows here (though some will be null/empty?) Either way: it's allocating
  // 2 sets per row in the metadata, so can be re-written to be _much_ more efficient.
  const availableColumns = useMemo(
    () =>
      Array.from(
        elementsInEverySet(
          metadata
            .filter((row: UntypedWellSampleMetadataRow) =>
              plates.includes(row.plate),
            )
            .map(
              (row: UntypedWellSampleMetadataRow) => new Set(Object.keys(row)),
            ),
        ),
      ).filter((column) => interestingMetadataColumns.includes(column)),
    [metadata, plates, interestingMetadataColumns],
  );

  return (
    <Select
      items={availableColumns
        .sort(columnComparator)
        .map((col) => ({ label: col, value: col }))}
      value={selectedMetadata && !disabled ? selectedMetadata : null}
      onChange={onSelectMetadata}
      className={"tw-min-w-[200px] tw-bg-slate-50 tw-mt-sm"}
    />
  );
}
