/**
 * Component to render the filter selection UI.
 *
 * Allows the user to filter out sections.
 */
import * as Popover from "@radix-ui/react-popover";
import { css } from "aphrodite";
import cx from "classnames";
import { useCallback, useRef, useState } from "react";
import { Overlay } from "react-overlays";
import { SpinningChevron } from "../Common/SpinningChevron";
import DropdownList from "../Control/Dropdown/DropdownList";
import { Item } from "../Control/Dropdown/types";
import { OverlayBody } from "../Control/FilterSelector/OverlayParent";
import CheckmarkSmall from "../icons/CheckmarkSmall.svg";
import Close from "../icons/Close.svg";
import DownCaret from "../icons/DownCaret.svg";
import { shared } from "../megamap-styles";
import {
  AvailableFeatureFilters,
  FeatureFilter,
  FeatureFilterBuilder,
  FeatureFilterOp,
} from "./types";

export function validateFeatureFilter(
  builder: FeatureFilterBuilder,
): builder is FeatureFilter {
  return (
    builder.featureSet != null &&
    builder.column != null &&
    builder.params?.op != null &&
    builder.params.value != null
  );
}

function filterDisplayText(filter: FeatureFilterBuilder | null): string {
  if (filter && validateFeatureFilter(filter)) {
    const op = operatorItems.find((it) => it.id === filter.params.op)?.title;
    return `${filter.column} ${op} ${filter.params.value}`;
  } else {
    return "Feature filter";
  }
}

type FilterProps = {
  filter: FeatureFilterBuilder | null;
  availableFilters: AvailableFeatureFilters;
  onChangeFilter: (filter: FeatureFilterBuilder | null) => void;
  onCommit: () => void;
};

function FilterableSelector({
  item,
  items,
  onChange,
  name,
}: {
  item: string | null;
  items:
    | { type: "simple"; items: string[] }
    | { type: "full"; items: Item<string>[] };
  onChange: (column: string | null) => void;
  name: string;
}) {
  const triggerRef = useRef(null);
  const containerRef = useRef(null);
  const overlayContainerRef = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div className={"tw-flex-auto tw-flex"}>
      <div
        ref={containerRef}
        className={
          "tw-flex-auto tw-flex tw-flex-col tw-relative tw-overflow-visible"
        }
      >
        <div
          className={
            "tw-cursor-pointer tw-flex tw-flex-auto tw-items-center tw-justify-end tw-px-1 tw-rounded " +
            css(shared.darken1Hover, shared.linkQuiet, shared.textBlueFocus)
          }
          onClick={() => setIsOpen(true)}
          ref={triggerRef}
          role={"button"}
          aria-expanded={isOpen}
          tabIndex={0}
        >
          <div className={"tw-truncate tw-flex-auto"}>{item ?? name}</div>
          <div className={"tw-flex-none tw-items-center tw-ml-1"}>
            <DownCaret />
          </div>
        </div>
        <div className="tw-relative" ref={overlayContainerRef}>
          <Overlay
            placement={"bottom-start"}
            show={isOpen}
            target={triggerRef}
            onHide={() => setIsOpen(false)}
            container={overlayContainerRef}
            rootClose
          >
            {({ props }) => (
              <OverlayBody {...props} minWidth={80}>
                <DropdownList
                  items={
                    items.type === "simple"
                      ? items.items.map((item) => ({
                          id: item,
                          title: item,
                        }))
                      : items.items
                  }
                  onClick={(i) => {
                    onChange(i.id);
                    setIsOpen(false);
                  }}
                  defaultValue={null}
                  searchable
                  placeholder={name}
                />
              </OverlayBody>
            )}
          </Overlay>
        </div>
      </div>
    </div>
  );
}

const operatorItems = [
  { title: "≥", id: "geq" },
  { title: "≤", id: "leq" },
  { title: "=", id: "eq" },
  { title: "≠", id: "neq" },
  { title: ">", id: "gt" },
  { title: "<", id: "lt" },
];

function assertOp(s: string | null): FeatureFilterOp | null {
  if (s === null) {
    return s;
  }
  if (!operatorItems.some((item) => item.id === s)) {
    throw new Error(`Expected filter operator, got ${s}`);
  }
  return s as FeatureFilterOp;
}

function FeatureFilterSelect({
  filter,
  availableFilters,
  onChangeFilter,
  onCommit,
}: FilterProps) {
  const onChangeFeatureSet = useCallback(
    (featureSet: string | null) =>
      onChangeFilter({ featureSet: featureSet ?? undefined }),
    [onChangeFilter],
  );
  const onChangeColumn = useCallback(
    (column: string | null) => onChangeFilter({ column: column ?? undefined }),
    [onChangeFilter],
  );
  const onChangeOp = useCallback(
    (op: string | null) =>
      onChangeFilter({
        params: {
          ...(filter?.params ?? {}),
          ...{ op: assertOp(op) ?? undefined },
        },
      }),
    [onChangeFilter, filter],
  );
  const onChangeValue = useCallback(
    (e) =>
      onChangeFilter({
        params: {
          ...(filter?.params ?? {}),
          ...{
            value:
              e.target.value !== "" && e.target.value != null
                ? Number.parseFloat(e.target.value)
                : undefined,
          },
        },
      }),
    [onChangeFilter, filter],
  );
  const commitDisabled = filter == null || !validateFeatureFilter(filter);
  return (
    <>
      <FilterableSelector
        name="feature set"
        item={filter?.featureSet ?? null}
        items={{ type: "simple", items: Object.keys(availableFilters) }}
        onChange={onChangeFeatureSet}
      />
      <span>.</span>
      <FilterableSelector
        name="column"
        item={filter?.column ?? null}
        items={{
          type: "simple",
          items: availableFilters[filter?.featureSet ?? ""] ?? [],
        }}
        onChange={onChangeColumn}
      />
      <FilterableSelector
        name="op"
        item={
          operatorItems.find((it) => it.id === filter?.params?.op)?.title ??
          null
        }
        items={{ type: "full", items: operatorItems }}
        onChange={onChangeOp}
      />
      <input
        type="number"
        onChange={onChangeValue}
        placeholder="number"
        value={filter?.params?.value ?? ""}
      ></input>
      <button
        className={cx("tw-px-2", commitDisabled ? "tw-text-gray-400" : null)}
        onClick={onCommit}
        disabled={commitDisabled}
      >
        <CheckmarkSmall />
      </button>
      <button
        onClick={() => {
          onChangeFeatureSet(null);
          onCommit();
        }}
      >
        <Close />
      </button>
    </>
  );
}

export default function FeatureFilterDropdown(props: FilterProps) {
  const { filter, onCommit } = props;
  const [isOpen, setIsOpen] = useState(false);
  const onCommitWrapped = useCallback(() => {
    setIsOpen(false);
    onCommit();
  }, [onCommit, setIsOpen]);
  return (
    <Popover.Root onOpenChange={setIsOpen} open={isOpen}>
      <Popover.Trigger
        className={cx(
          "tw-px-2 tw-py-1.5 tw-text-slate-500 tw-h-full tw-min-w-[150px] tw-flex",
          "tw-items-center tw-justify-between",

          // TODO(benkomalo): copied from CollapsedFeatureSelectorInfo.tsx
          // and manually specified to mirror our react-select-plus borders.
          "tw-border tw-rounded hover:tw-border-gray-300 tw-border-[#ccc]",
        )}
      >
        <span>{filterDisplayText(filter)}</span>
        <SpinningChevron isOpen={isOpen} />
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          className={cx(
            "tw-z-dialog tw-bg-white tw-border tw-rounded tw-shadow-lg",
            "tw-flex tw-box-border",
          )}
          align={"end"}
        >
          <FeatureFilterSelect {...props} onCommit={onCommitWrapped} />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
