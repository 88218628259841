// TODO(benkomalo): consider sharing with webapp
// Hard-code the list of rows (and columns, below) to ensure that
// we don't omit any rows or columns that were left empty. We want
// to always render a 384-well plate, even if rows or columns were
// left empty.
import { FeatureLevel, UntypedFeatures } from "../types";

export const ROWS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
];

export const COLUMNS = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
];

// Numbers denote the distance from edge wells with 1 being the edge wells.
export const RINGS = ["1", "2", "3", "4", "5", "6", "7", "8"];

/**
 * @param featureSet - Name of the FeatureSet to test.
 * @returns {boolean} - {@code true} if the FeatureSet is an Images FeatureSet.
 */
const isImagesFeatureSet = (featureSet: string): boolean => {
  return featureSet.startsWith("Images-");
};

/**
 *
 * @param featureSets - List of FeatureSets to test.
 * @returns {string|null} - Latest Images FeatureSet in the list.
 */
export const selectLatestImagesFeatureSet = (
  featureSets: string[] | null,
): string | null => {
  for (const featureSet of featureSets || []) {
    if (isImagesFeatureSet(featureSet)) {
      return featureSet;
    }
  }

  return null;
};

export function inferLevelFromFeatures(
  features: UntypedFeatures,
): FeatureLevel {
  if (features.length && "row" in features[0] && "column" in features[0]) {
    return "cell";
  } else if (
    features.length &&
    "well" in features[0] &&
    !("field" in features[0])
  ) {
    return "well";
  } else {
    return "field";
  }
}
