import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  BoundingRect,
  BoundingRectRef,
  Renderer,
  RendererOptions,
} from "./types";
import { sameRenderOptions } from "./util";

// Keeps track of the bounding box of an element using a ref. Returns the
// bounding box (width/height) of the element, and a function to pass into the
// ref of an element.
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function useBoundingRectByRef(): [BoundingRect | null, BoundingRectRef] {
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [boundingRect, setBoundingRect] = useState<BoundingRect | null>(null);

  useEffect(() => {
    if (ref) {
      const rect = ref.getBoundingClientRect();
      setBoundingRect({
        width: rect.width,
        height: rect.height,
      });
    } else {
      setBoundingRect(null);
    }
  }, [ref]);

  return [boundingRect, setRef];
}

// Sets up a render loop where we request new animation frames when we detect that the
// dependencies of a given frame have changed
export function useRenderLoop<T>(
  initialOptions: T,
  renderers: Renderer<T>[],
  additionalUpdate?: (options: T, update: Partial<T>) => Partial<T>,
): [React.MutableRefObject<T>, (update: Partial<T>) => void] {
  const frame = useRef<number>(0);
  // Keep the options in a ref instead of state, to reduce the number of frames in
  // between a change and when it's rendered
  const renderOptions = useRef<T>(initialOptions);
  const lastRenderOptions = useRef<Array<RendererOptions | null>>([]);

  const renderAll = useCallback(() => {
    // NOTE(danlec): Using 'as boolean' is the recommended way to keep TS from assuming
    // narrowing requestNewFrame to false
    // See https://typescript-eslint.io/rules/no-unnecessary-condition/#when-not-to-use-it
    let requestNewFrame = false as boolean;

    renderers.forEach(({ options, render }, index) => {
      const newOptions = options(renderOptions.current);
      if (newOptions !== null) {
        const lastOptions = lastRenderOptions.current[index];

        if (!sameRenderOptions(newOptions, lastOptions)) {
          render(newOptions);
          lastRenderOptions.current[index] = newOptions;
        }
      } else {
        // We weren't able to render this canvas yet, try on the next frame
        requestNewFrame = true;
      }
    });

    if (requestNewFrame) {
      frame.current = requestAnimationFrame(renderAll);
    } else {
      frame.current = 0;
    }
  }, [renderers]);

  const updateRenderOptions = useCallback(
    (update: Partial<T>) => {
      renderOptions.current = {
        ...renderOptions.current,
        ...update,
        ...(additionalUpdate
          ? additionalUpdate(renderOptions.current, update)
          : {}),
      };

      if (!frame.current) {
        frame.current = requestAnimationFrame(renderAll);
      }
    },
    [additionalUpdate, renderAll],
  );

  useEffect(() => {
    // We reference lastRenderOptions entries by index, so reset them if the list of renderers
    // have changed to avoid getting out of sync
    lastRenderOptions.current = new Array(renderers.length).fill(null);
    renderAll();

    return () => {
      if (frame.current) {
        cancelAnimationFrame(frame.current);
        frame.current = 0;
      }
    };
  }, [renderAll, renderers.length]);

  return [renderOptions, updateRenderOptions];
}
