import { Field } from "src/imaging/types";
import { toNumericField } from "src/imaging/util";
import { DatasetId } from "src/types";

type ScanPattern = Array<Array<number | null>>;

export function getGridLengthForFields(numFields: number) {
  return Math.ceil(Math.sqrt(numFields));
}

/**
 * Lay out fields in a naive NxN grid.
 */
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function getNaiveGridPattern(fields: Field[]): ScanPattern {
  const fieldIndexes: Array<number | null> = fields.map(toNumericField).sort();
  const dimension = getGridLengthForFields(fields.length);
  while (fieldIndexes.length < dimension * dimension) {
    fieldIndexes.push(null);
  }

  const scanPattern: Array<Array<number | null>> = [];
  for (let i = 0; i < fieldIndexes.length; i += dimension) {
    scanPattern.push(fieldIndexes.slice(i, i + dimension));
  }
  return scanPattern;
}

export function getScanPattern(
  dataset: DatasetId,
  fields: Field[],
): ScanPattern {
  if (dataset === "jump_cp-u2os-pilot" || dataset === "jump_cp-u2os-20221101") {
    // From https://docs.google.com/document/d/13N9qGnYfqqmWukqC6kSDwzI0b2t8I-WK1e5STc8uBJE/edit?pli=1#
    if (fields.length == 9) {
      return [
        [1, 2, 3],
        [5, 0, 4],
        [6, 7, 8],
      ];
    } else if (fields.length === 16) {
      return [
        [1, 2, 3, 4],
        [8, 7, 6, 5],
        [9, 0, 10, 11],
        [15, 14, 13, 12],
      ];
    } else {
      return getNaiveGridPattern(fields);
    }
  } else {
    return getNaiveGridPattern(fields);
  }
}
