import cx from "classnames";
import lodashMax from "lodash.max";
import lodashMin from "lodash.min";
import { ReactNode, useContext, useEffect, useState } from "react";
import { HelpCircle, Sliders } from "react-feather";
import { DatasetPlateWellFieldTZ, Field } from "src/imaging/types";
import * as vega from "vega";
import { Failure, Fetchable, Success } from "@spring/core/result";
import { Tooltip } from "@spring/ui/Tooltip";
import Loader, { Center } from "../../Common/Loader";
import { PopoverMessage } from "../../Common/PopoverMessage";
import ControlsSidebar from "../../Control/ControlsSidebar";
import FieldSelector from "../../Control/FieldSelector";
import ErrorMessage from "../../Error/ErrorMessage";
import { useFeaturesWithMetadata } from "../../hooks/features";
import { useAutoImageSet, useSourceSize } from "../../hooks/immunofluorescence";
import { CropContext } from "../../imaging/cropping";
import { toNumericField } from "../../imaging/util";
import MultiChannelView from "../../immunofluorescence/MultiChannelView";
import VisualizationControls from "../../immunofluorescence/VisualizationControls";
import { useQueryParams } from "../../routing";
import { DatasetId, FeatureLevel } from "../../types";
import {
  GroupedResult,
  getValuesByWellDB,
  inferInterestingColumnsDB,
} from "../../util/dataset-util";
import { DB } from "../../util/sql";
import { useUserAndLocalStorageBackedState } from "../../util/state-pool-store";
import { Highlight } from "../Histogram";
import { useFeatureSetManagementContext } from "../context";
import { FeatureSetInfo } from "../types";
import {
  GroupedImageGrid,
  groupEntries,
} from "./BucketedExampleViewer/GenericImageGrid";
import {
  CellSampleMetadata,
  Entry,
  FieldSampleMetadata,
  GroupedEntries,
  WellSampleMetadata,
} from "./BucketedExampleViewer/types";
import { convertRawFeatureToEntry } from "./BucketedExampleViewer/utils";
import DistributionHistogram from "./DistributionHistogram";
import MaybeWithTooltip from "./MaybeWithTooltip";
import { queryDataForExampleSpec, queryGroupFromValue } from "./queries";

const DEFAULT_EXAMPLE_SPEC = {
  numBuckets: 4,
  examplesPerBucket: 8,
  omitLowPercentile: 0.1,
  omitHighPercentile: 0.1,
  ascending: true,
};

const PREDICTION_EXAMPLE_SPEC = {
  numBuckets: 4,
  examplesPerBucket: 8,
  omitLowPercentile: 99.0,
  omitHighPercentile: 0.0,
  ascending: true,
};

function highlightsFromBuckets(grouped: GroupedEntries): Highlight[] {
  return grouped.map((group) => {
    return {
      valueStart:
        lodashMin(
          group.entries.map(({ entry }) => entry["metadata"]["value"]),
        ) ?? Infinity,
      valueEnd:
        lodashMax(
          group.entries.map(({ entry }) => entry["metadata"]["value"]),
        ) ?? -Infinity,
      color: group.color as string,
    };
  });
}

function dimHighlight(highlight: Highlight): Highlight {
  if (highlight.color.length !== 7) {
    return highlight;
  }

  return {
    ...highlight,
    color: "#cfcfcf",
  };
}

function createSingleAccent(
  highlights: Highlight[],
  index: number,
): Highlight[] {
  // We can get something out of bounds here if we've removed a group from the
  // bucketer and the hover state hasn't updated yet.
  if (index < 0 || index >= highlights.length) {
    return highlights;
  }
  return [
    ...highlights.slice(0, index).map(dimHighlight),
    highlights[index],
    ...highlights.slice(index + 1).map(dimHighlight),
  ];
}

const FORMATTER = new Intl.NumberFormat(undefined, { compactDisplay: "short" });

const formatValue = (value: number) => {
  if (value < 0) {
    // Using the number formatter for very small values truncates it to 0, which is
    // probably undesirable if the measurement domain is legitimately really small.
    return value.toPrecision(3);
  } else {
    return FORMATTER.format(value);
  }
};

/**
 * Shows a tooltip over top of the contents the first time it's rendered.
 */
function WithIntroTooltip({
  children,
  contents,
}: {
  children: ReactNode;
  contents: ReactNode;
}) {
  const [hasSeenPopover, setHasSeenPopover] =
    useUserAndLocalStorageBackedState<boolean>("histogram-popover", false);
  const [isOpen, setIsOpen] = useState<boolean>(!hasSeenPopover);

  useEffect(() => {
    if (!hasSeenPopover) {
      setHasSeenPopover(true);
    }
  }, [hasSeenPopover, setHasSeenPopover]);

  return (
    <PopoverMessage
      isOpen={isOpen}
      onOpenChange={() => setIsOpen(false)}
      contents={contents}
      sideOffset={-20}
    >
      {children}
    </PopoverMessage>
  );
}

const BUCKET_COLOR_SCHEME = vega.scheme("tableau10");

const insertHoverGroup = (
  groups: GroupedEntries,
  entriesForHover: Entry[],
): GroupedEntries => {
  // The bucket ID's aren't super meaningful but they do have to be unique.
  const bucketId = `b-${Date.now()}`;
  const hoverGroup = {
    key: bucketId,
    entries: entriesForHover.map((entry) => ({
      rowKey: [bucketId],
      colKey: [entry.metadata["value"]],
      entry: entry,
    })),
    color: BUCKET_COLOR_SCHEME[groups.length % BUCKET_COLOR_SCHEME.length],
  };

  const hoverValue = entriesForHover[0].metadata.value;
  const result: GroupedEntries = [];
  let hasInserted = false;
  groups.forEach((group) => {
    if (!hasInserted && group.entries[0].entry.metadata.value > hoverValue) {
      hasInserted = true;
      result.push(hoverGroup);
    }

    result.push(group);
  });

  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!hasInserted) {
    result.push(hoverGroup);
  }
  return result;
};

const useWellMetadataForTooltips = (
  metadata: Fetchable<DB>,
  plate: string,
): Fetchable<GroupedResult> => {
  const [results, setResults] = useState<Fetchable<GroupedResult>>(undefined);
  const prefilter = `plate = '${plate}'`;

  useEffect(() => {
    let mounted = true;
    if (metadata) {
      if (metadata.successful) {
        inferInterestingColumnsDB(metadata.value, prefilter).then(
          (columns) => {
            getValuesByWellDB(metadata.value, columns, prefilter).then(
              (results) => (mounted ? setResults(Success.of(results)) : null),
              (err) => (mounted ? setResults(Failure.of(err)) : null),
            );
          },
          (err) => (mounted ? setResults(Failure.of(err)) : null),
        );
      } else {
        setResults(Failure.of(metadata.error));
      }
    }
    return () => {
      mounted = false;
    };
  }, [metadata, setResults, prefilter]);

  return results;
};

function LoadingState() {
  return (
    <Center extraClasses={"tw-min-h-[600px]"}>
      <Loader />
    </Center>
  );
}

export default function FeatureColumnDistributionsView({
  dataset,
  featureSetInfo,
  column,
  featuresDB: unfilteredFeaturesDB,
  featureLevel,
}: {
  dataset: DatasetId;
  featureSetInfo: FeatureSetInfo;
  column: string;
  featuresDB: DB;
  featureLevel: FeatureLevel;
  onChangeField: (field: Field) => void;
}) {
  // TODO: make a master type of all params
  const [queryParams, setQueryParams] = useQueryParams<{
    distributionField?: Field | null;
    well?: string | null;
    overlaysField?: Field | null;
    featureType?: string | null;
    tab?: string | null;
    cellColumn?: number;
    cellRow?: number;
  }>();
  const cropSize = useContext(CropContext).sourceCropSize;

  const { metadataDB, filterSerialized } = useFeatureSetManagementContext();

  const featuresWithMetadataDB = useFeaturesWithMetadata(
    unfilteredFeaturesDB,
    metadataDB,
  );

  const metadataByWell = useWellMetadataForTooltips(
    featuresWithMetadataDB,
    featureSetInfo.plate as string,
  );

  const imageSize = featureLevel === "cell" ? 80 : 160;

  const sourceImageSize = useSourceSize({ dataset });

  const imageSet = useAutoImageSet({
    dataset,
    params: {
      imageSize: sourceImageSize?.unwrap() ?? null,
      processingMode: "illumination-corrected",
    },
  });

  // The underlying groups to render images for.
  const [groupedExamples, setGroupedExamples] = useState<GroupedEntries | null>(
    null,
  );
  const fieldHighlightForWellView = queryParams.distributionField || null;

  const exampleSpec =
    queryParams.featureType === "prediction"
      ? PREDICTION_EXAMPLE_SPEC
      : DEFAULT_EXAMPLE_SPEC;

  useEffect(() => {
    // The first time we load the (or anytime the filters change), reset it with
    // the default set of buckets.
    if (featuresWithMetadataDB?.successful) {
      queryDataForExampleSpec(
        featuresWithMetadataDB.value,
        filterSerialized,
        column,
        exampleSpec,
      ).then((entries) => {
        const groups = groupEntries(
          entries
            .map((entry) => convertRawFeatureToEntry(entry, column))
            .filter((entry) => entry.metadata.type === featureLevel),
          ["bucket"],
          ["value"],
        );
        const withColors = groups.map((group, i) => ({
          ...group,
          color: BUCKET_COLOR_SCHEME[i % BUCKET_COLOR_SCHEME.length],
        }));
        setGroupedExamples(withColors);
      });
    }
  }, [
    featuresWithMetadataDB,
    column,
    filterSerialized,
    exampleSpec,
    featureLevel,
  ]);

  const [hoverGroup, setHoverGroup] = useState<string | null>(null);
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);

  // We want the histogram to animate when the plate or feature changes, but
  // not other times when we might want to rerender it (such as when the
  // viewport size changes when the settings are opened). Manually keep track
  // of this and reset it to true when something changes.
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(true);
  useEffect(() => {
    setShouldAnimate(true);
  }, [featureSetInfo.id, column, filterSerialized]);

  if (!featuresWithMetadataDB) {
    return <LoadingState />;
  } else if (!featuresWithMetadataDB.successful) {
    return <ErrorMessage error={featuresWithMetadataDB.error} />;
  }

  if (!groupedExamples) {
    return <LoadingState />;
  }

  const removeGroup = (keyToRemove: any) => {
    setGroupedExamples((examples) =>
      examples ? examples.filter(({ key }) => key !== keyToRemove) : null,
    );
  };
  let highlights = highlightsFromBuckets(groupedExamples);
  if (hoverGroup) {
    highlights = createSingleAccent(
      highlights,
      groupedExamples.findIndex(({ key }) => key == hoverGroup),
    );
  }

  const handleViewCellInOverlay = (metadata: CellSampleMetadata) => {
    const { well, field, column, row } = metadata;
    setQueryParams({
      ...queryParams,
      tab: "overlays",
      well,
      overlaysField: field,
      cellColumn: column,
      cellRow: row,
    });
  };

  const makeIndex = (
    metadata: FieldSampleMetadata | CellSampleMetadata,
  ): DatasetPlateWellFieldTZ => {
    return {
      dataset: dataset,
      plate: metadata.plate,
      well: metadata.well,
      field: toNumericField(metadata.field),
      t: 0,
      z: 0,
    };
  };

  const makeWellIndex = (
    metadata: WellSampleMetadata,
    field: Field,
  ): DatasetPlateWellFieldTZ => {
    return {
      dataset: dataset,
      plate: metadata.plate,
      well: metadata.well,
      field: toNumericField(field),
      t: 0,
      z: 0,
    };
  };

  return (
    <div className={"tw-flex tw-flex-row tw-w-full"}>
      <div
        className={
          "tw-flex-1 tw-flex tw-flex-col tw-items-center tw-relative tw-overflow-x-hidden"
        }
      >
        <WithIntroTooltip
          contents={
            <div className={"tw-max-w-[280px] tw-p-md"}>
              Click on the histogram above to see data from that part of the
              distribution
            </div>
          }
        >
          <div className={"tw-p-8 tw-self-stretch"}>
            <div className="tw-flex tw-justify-between tw-mb-4">
              <div
                className={
                  "tw-uppercase tw-text-slate-500 tw-text-sm tw-flex-1"
                }
              >
                Distribution within "{featureSetInfo.plate}"
              </div>
              <button
                className={cx(
                  "tw-appearance-none tw-flex tw-items-center hover:tw-bg-slate-300 tw-rounded tw-p-2 tw-cursor-pointer",
                  settingsOpen ? "tw-text-slate-700" : "tw-text-slate-500",
                )}
                onClick={() => {
                  setShouldAnimate(false);
                  setSettingsOpen(!settingsOpen);
                }}
              >
                Image Settings <Sliders size={16} className="tw-ml-2" />
              </button>
            </div>
            <DistributionHistogram
              key={`${featureSetInfo.id}_${column}_${filterSerialized}`}
              featuresDB={featuresWithMetadataDB}
              filter={filterSerialized}
              column={column}
              highlights={highlights}
              shouldAnimate={shouldAnimate}
              onClick={(value) => {
                queryGroupFromValue(
                  featuresWithMetadataDB.value,
                  filterSerialized,
                  column,
                  value,
                  exampleSpec.examplesPerBucket,
                  exampleSpec.ascending,
                ).then((entries) => {
                  setGroupedExamples((existing) =>
                    insertHoverGroup(existing || [], entries),
                  );
                });
              }}
            />
          </div>
        </WithIntroTooltip>
        <div
          className={"tw-p-8 tw-pb-0 tw-self-stretch tw-flex tw-text-slate-500"}
        >
          <span className={"tw-text-sm tw-uppercase "}>
            Sample images from above distribution
          </span>
          <Tooltip
            className="tw-w-64"
            contents="Click on the histogram to view more samples of data across your measurement distribution."
            side={"right"}
          >
            <HelpCircle size={16} className={"tw-ml-2"} />
          </Tooltip>
        </div>
        <div className={"tw-p-8 tw-overflow-x-auto tw-max-w-full"}>
          {featureLevel === "well" && (
            <div
              className={"tw-w-40 tw-mb-4 tw-flex tw-flex-row tw-items-center"}
            >
              <FieldSelector
                plate={featureSetInfo.plate as string}
                dataset={dataset}
                field={fieldHighlightForWellView}
                onSelectField={(field) =>
                  setQueryParams({
                    ...queryParams,
                    distributionField: field,
                  })
                }
                autoSelect
              />
              <span className={"tw-ml-2 tw-text-gray-500"}>
                <Tooltip
                  className="tw-w-[500px]"
                  side={"right"}
                  contents={
                    <p>
                      The measurement{" "}
                      <span className={"tw-text-purple-500 tw-font-mono"}>
                        {featureSetInfo.name}
                      </span>{" "}
                      is calculated at a per-well level and the groups below are
                      defined by wells with specific measurement values.
                      However, there may be variation within each well; select
                      the field to view different different images from each
                      well.
                    </p>
                  }
                >
                  <HelpCircle size={16} />
                </Tooltip>
              </span>
            </div>
          )}

          <GroupedImageGrid
            valueFormatter={(values) => formatValue(values[0])}
            groupedEntries={groupedExamples}
            valueFields={["value"]}
            groupKeyFields={["bucket"]}
            showValueLabels={false}
            showGroupLabels={true}
            onGroupClose={(key) => removeGroup(key)}
            onGroupMouseEnter={setHoverGroup}
            onGroupMouseLeave={() => setHoverGroup(null)}
            itemWidth={imageSize}
            columnsPerGroup={2}
          >
            {({ metadata }) => {
              const index: DatasetPlateWellFieldTZ | null =
                metadata.type === "well"
                  ? fieldHighlightForWellView
                    ? makeWellIndex(metadata, fieldHighlightForWellView)
                    : null
                  : makeIndex(metadata);
              const crop =
                metadata.type === "cell"
                  ? {
                      size: cropSize,
                      location: {
                        x: metadata.row,
                        y: metadata.column,
                      },
                    }
                  : null;
              const onClick =
                metadata.type === "cell"
                  ? () => handleViewCellInOverlay(metadata)
                  : undefined;

              if (!index) {
                // This happens briefly for well metadata, while it's waiting for the
                // default field to resolve and be set by the <FieldSelector>
                return undefined;
              }

              return (
                <MaybeWithTooltip
                  metadata={metadata}
                  wellSampleMetadata={
                    metadataByWell?.successful
                      ? metadataByWell.value[metadata.well]
                      : undefined
                  }
                >
                  <MultiChannelView
                    index={index}
                    imageSet={imageSet}
                    crop={crop}
                    size={imageSize}
                    onClick={onClick}
                  />
                </MaybeWithTooltip>
              );
            }}
          </GroupedImageGrid>
        </div>
      </div>
      <div className={cx("tw-border-l", !settingsOpen && "tw-hidden")}>
        <ControlsSidebar>
          <div className={"tw-p-8"}>
            <VisualizationControls
              key={"vis-controls"}
              dataset={dataset}
              plate={featureSetInfo.plate}
            />
          </div>
        </ControlsSidebar>
      </div>
    </div>
  );
}
