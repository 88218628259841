import cx from "classnames";
import { useCallback } from "react";
import { Check, Download } from "react-feather";
import type { AccessToken } from "src/Auth0/accessToken";
import { useAccessToken } from "src/hooks/auth0";
import { fetchBlob } from "src/hooks/fetch";
import { useAsyncStatus } from "src/util/hooks";
import Spinner from "../Common/Spinner";

function saveBlob(blob: Blob, filename: string) {
  const a = document.createElement("a");
  a.href = window.URL.createObjectURL(blob);
  a.download = filename;
  a.dispatchEvent(new MouseEvent("click"));
}

async function fetchAndSaveBlob(
  href: string,
  accessToken: AccessToken,
  downloadLocation: string,
  requestInit?: RequestInit,
) {
  const result = await fetchBlob(href, accessToken, requestInit);
  saveBlob(result, downloadLocation);
}

function Container(
  props: React.HTMLAttributes<HTMLButtonElement | HTMLDivElement> & {
    icon?: React.ElementType;
  },
) {
  const { children, icon: Icon, className, ...rest } = props;
  const isClickable = rest.onClick !== undefined;
  const Element = isClickable ? "button" : "div";
  return (
    <Element
      className={cx(
        "tw-flex tw-items-center",
        isClickable && "hover:tw-opacity-60",
        className,
      )}
      {...rest}
    >
      {Icon && (
        <div className={"tw-w-6 tw-flex tw-items-center tw-mb-[2px]"}>
          <Icon size={16} aria-hidden={true} />
        </div>
      )}
      {children}
    </Element>
  );
}

export default function DownloadLink({
  onStartDownload,
  text,
}: {
  onStartDownload: () => Promise<void>;
  text?: string;
}) {
  const state = useAsyncStatus();

  switch (state.status) {
    case "idle":
      return (
        <Container onClick={() => state.start(onStartDownload)} icon={Download}>
          {text ?? "Download"}
        </Container>
      );
    case "in-progress":
      return <Container icon={Spinner}>Downloading...</Container>;
    case "complete":
      return (
        <Container onClick={() => state.reset()} icon={Check}>
          Download complete
        </Container>
      );
    case "error":
      return (
        <Container
          onClick={() => state.reset()}
          className="tw-text-red-error"
          title={`Error while downloading: ${state.error.message}`}
        >
          Download failed
        </Container>
      );
  }
}

/**
 * Hackily convert a list of POJSOs to a CSV blob and download it as a file.
 */
export function downloadRecordsAsCSV(
  untypedRecords: Record<string, any>[],
  filename: string,
) {
  const buffer: string[] = [];

  const keys = Object.keys(untypedRecords[0]);
  buffer.push(keys.map((column) => `"${column}"`).join(","));

  const sanitizedValue = (value: any): string => {
    if (typeof value === "string") {
      return `"${value}"`;
    } else {
      return String(value);
    }
  };

  for (const row of untypedRecords) {
    buffer.push(
      keys.map((column) => `${sanitizedValue(row[column])}`).join(","),
    );
  }

  saveBlob(
    new Blob([buffer.join("\r\n")], { type: "text/csv;charset=utf-8;" }),
    filename,
  );
}

export function DownloadUrl({
  href,
  filename,
  text,
}: {
  href: string;
  filename: string;
  text?: string;
}) {
  const accessToken = useAccessToken();

  const handleStartDownload = useCallback(
    async () => fetchAndSaveBlob(href, accessToken, filename),
    [accessToken, filename, href],
  );

  return <DownloadLink onStartDownload={handleStartDownload} text={text} />;
}
