/**
 * Utilities to support croppable (single-cell) images.
 *
 * As an optimization, the GCS proxy pre-crops images down to 128px-by-128px; on the client, we can
 * then render those crops at any (receptive field) size <= 128px-by-128px, and at any effective
 * size via rescaling.
 */
import { createContext } from "react";
import { CropLocation } from "./types";

export const CropContext = createContext<{ sourceCropSize: number }>({
  // The maximum supported crop size. For croppable images, we pre-crop at this size in the GCS
  // proxy, then resize on the client based on the client-defined crop size. This allows us to
  // dynamically crop on the client while avoiding sending down entire images, which is a useful
  // optimization when rendering hundreds of cells.
  sourceCropSize: 128,
});

export function toOffset(
  location: CropLocation,
  {
    sourceImageSize,
    sourceCropSize,
  }: {
    sourceImageSize: number;
    sourceCropSize: number;
  },
): CropLocation {
  // Determine the location at which the crop was taken. We assume that the image was cropped such
  // that the object of interest was centered, but the crop location was then adjusted to ensure a
  // square image.
  const adjustedLocation = {
    x: Math.max(
      sourceCropSize / 2,
      Math.min(sourceImageSize - 1 - sourceCropSize / 2, location.x),
    ),
    y: Math.max(
      sourceCropSize / 2,
      Math.min(sourceImageSize - 1 - sourceCropSize / 2, location.y),
    ),
  };
  return {
    x: location.x - adjustedLocation.x,
    y: location.y - adjustedLocation.y,
  };
}

/**
 * Compute a `transform` and `transformOrigin` to render a pre-cropped image.
 *
 * @param offset - the offset (computed via `toOffset`) of the center of the crop.
 * @param size - the size at which the final crop will be rendered.
 * @param cropSize - the desired crop size, in the coordinate system of the source image.
 * @param sourceCropSize - the size of the crops taken from the source images.
 * @param canvasSize - the size of the Canvas into which the transformed image will be rendered.
 */
export function toTransform(
  offset: CropLocation,
  {
    size,
    cropSize,
    sourceCropSize,
    canvasSize,
  }: {
    size: number;
    cropSize: number;
    canvasSize: number;
    sourceCropSize: number;
  },
): { transform: string; transformOrigin: string } {
  const transform = `translate(${-offset.x * (size / canvasSize)}px, ${
    -offset.y * (size / canvasSize)
  }px) scale(${sourceCropSize / cropSize})`;
  const transformOrigin = `${size / 2 + offset.x * (size / canvasSize)}px ${
    size / 2 + offset.y * (size / canvasSize)
  }px`;
  return { transform, transformOrigin };
}
