import useEventListener from "@use-it/event-listener";
import cx from "classnames";
import pluralize from "pluralize";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { ChevronRight, CornerDownLeft } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import { DatasetId, WorkspaceId } from "src/types";
import { toTypedString } from "src/util/typed-string";
import { trackEvent } from "../analytics";
import PlateIcon from "../icons/plate-icon.svg";
import { DB, sql, useCacheableQueryAsRecords } from "../util/sql";
import { ReadonlySearchBox } from "./SearchInputBox";
import { SearchResultGroup, SearchResults } from "./types";
import {
  applyToSubstringMatches,
  tokenize,
  useSearchLinkEnhancer,
} from "./utils";

function ListingGroup({
  resultsGroup,
  groupId,
  query,
  focusIndex,
  onRequestFocus,
  onOpenDetails,
}: {
  resultsGroup: SearchResultGroup;
  groupId: string;
  query: string;
  focusIndex: number | null;
  onRequestFocus: (index: number) => void;
  onOpenDetails: (index: number) => void;
}) {
  const maxPerGroup = 24;
  const { resultType, entries } = resultsGroup;
  const tokensToUnderline = [query.trim(), ...tokenize(query)];
  const underlinedEntries = entries.slice(0, maxPerGroup).map((entry) => {
    let i = 0;
    return applyToSubstringMatches(entry, tokensToUnderline, (match) => (
      <span key={`${i++}`} className={"tw-underline tw-font-bold"}>
        {match}
      </span>
    ));
  });
  return (
    <div className={"tw-my-4 tw-flex tw-flex-col"} role={"group"}>
      <div className={"tw-text-sm tw-text-slate-500 tw-mb-2 tw-px-4"}>
        {resultType} - {pluralize("match", entries.length, true)}
      </div>
      <>
        {underlinedEntries.map((value, i) => (
          <div
            id={`search-result-${groupId}-${i}`}
            role={"option"}
            key={entries[i]}
            onMouseEnter={() => onRequestFocus(i)}
            onClick={() => onOpenDetails(i)}
            className={cx(
              "tw-flex tw-flex-row tw-items-center",
              "tw-cursor-default",
              "tw-text-sm tw-text-slate-700",
              "tw-pl-8 tw-pr-4 tw-py-1",
              i === focusIndex && "tw-bg-purple-200",
            )}
          >
            <div className={"tw-truncate tw-flex-1"}>{value}</div>
            {i === focusIndex && (
              <span className={"tw-text-slate-500"}>
                <ChevronRight size={16} />
              </span>
            )}
          </div>
        ))}
        {entries.length > maxPerGroup && (
          <div className={"tw-text-slate-500 tw-text-sm tw-mt-1 tw-px-4"}>
            ...and {entries.length - maxPerGroup} more matches
          </div>
        )}
      </>
    </div>
  );
}

function nextFocus(
  results: SearchResults,
  focus: TopLevelIndex | null,
): TopLevelIndex {
  if (!focus) {
    // Find the first non-empty group.
    for (let i = 0; i < results.groups.length; i++) {
      if (results.groups[i].entries.length > 0) {
        return {
          groupIndex: i,
          itemIndex: 0,
        };
      }
    }

    throw new Error("Unexpectedly trying to get focus on empty result");
  }

  const { groupIndex, itemIndex } = focus;
  if (itemIndex < results.groups[groupIndex].entries.length - 1) {
    return {
      groupIndex,
      itemIndex: itemIndex + 1,
    };
  } else if (groupIndex < results.groups.length - 1) {
    return {
      groupIndex: groupIndex + 1,
      itemIndex: 0,
    };
  } else {
    return focus;
  }
}

function previousFocus(
  results: SearchResults,
  focus: TopLevelIndex | null,
): TopLevelIndex | null {
  if (!focus) {
    return null;
  }

  const { groupIndex, itemIndex } = focus;
  if (itemIndex > 0) {
    return {
      groupIndex,
      itemIndex: itemIndex - 1,
    };
  } else if (groupIndex > 0) {
    for (let i = groupIndex - 1; i >= 0; i--) {
      if (results.groups[i].entries.length > 0) {
        return {
          groupIndex: i,
          itemIndex: results.groups[i].entries.length - 1,
        };
      }
    }
    // No previous non-empty group.
    return focus;
  } else {
    return focus;
  }
}

function getDescriptionAt(focus: TopLevelIndex, results: SearchResults) {
  const group = results.groups[focus.groupIndex];
  const value = group.entries[focus.itemIndex];
  switch (group.type) {
    case "experiment": {
      return (
        <>
          open <span className={"tw-font-bold tw-ml-1"}>{value}</span>
        </>
      );
    }
    case "metadata": {
      return (
        <>
          open details for
          <span className={"tw-font-bold tw-ml-1"}>{value}</span>
        </>
      );
    }
  }
}

function TopLevelListing({
  results,
  focus,
  onRequestFocus,
  onOpenDetails,
}: {
  results: SearchResults;
  focus: TopLevelIndex | null;
  onRequestFocus: (focus: TopLevelIndex) => void;
  onOpenDetails: (item: TopLevelIndex) => void;
}) {
  const keyHandler = useCallback(
    (e) => {
      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          onRequestFocus(nextFocus(results, focus));
          break;
        case "ArrowUp": {
          e.preventDefault();
          const newFocus = previousFocus(results, focus);
          if (newFocus) {
            onRequestFocus(newFocus);
          }
          break;
        }
        case "Enter":
        case "ArrowRight":
          if (focus) {
            e.preventDefault();
            onOpenDetails(focus);
          }
          break;
      }
    },
    [onRequestFocus, focus, results, onOpenDetails],
  );
  useEventListener("keydown", keyHandler, document.body, { capture: true });

  // TODO(benkomalo): make an empty results view.
  const hasMultipleGroups = results.groups.length > 1;
  const isEmptyResult = results.groups.every(
    (group) => group.entries.length === 0,
  );
  const totalResults = results.groups.reduce(
    (total, group) => total + group.entries.length,
    0,
  );
  return (
    <>
      <SearchResultsHeader>
        <div className={"tw-font-bold tw-text-sm tw-text-purple"}>
          {totalResults} matching results
        </div>
      </SearchResultsHeader>
      <SearchResultsMainPanel>
        {results.groups.map(
          (group, i) =>
            // Skip empty groups if we have multiple groups to search over.
            (group.entries.length > 0 || !hasMultipleGroups) && (
              <ListingGroup
                key={group.resultType}
                groupId={String(i)}
                query={results.query}
                resultsGroup={group}
                focusIndex={
                  focus && focus.groupIndex === i ? focus.itemIndex : null
                }
                onRequestFocus={(j) =>
                  onRequestFocus({
                    groupIndex: i,
                    itemIndex: j,
                  })
                }
                onOpenDetails={(j) =>
                  onOpenDetails({
                    groupIndex: i,
                    itemIndex: j,
                  })
                }
              />
            ),
        )}
      </SearchResultsMainPanel>
      {focus && !isEmptyResult && (
        <SearchResultsFooter>
          <>
            <HotKeyMarker>
              <CornerDownLeft size={12} />
              <span>Enter</span>
            </HotKeyMarker>{" "}
            or <HotKeyMarker>&rarr;</HotKeyMarker> to{" "}
            {getDescriptionAt(focus, results)}
          </>
        </SearchResultsFooter>
      )}
    </>
  );
}

type DatasetGroup = {
  dataset: DatasetId;
  plates: PlateGroup[];
};
type PlateGroup = {
  plate: string;
  wells: string[];
};

function DetailedListing({
  workspaceId,
  column,
  value,
  onCloseDetails,
  onResultItemSelected,
  db,
}: {
  workspaceId: WorkspaceId;
  column: string;
  value: string;
  onCloseDetails: () => void;
  onResultItemSelected: () => void;
  db: DB;
}) {
  const history = useHistory();
  const sendResultNavigateEvent = useCallback(() => {
    trackEvent({ id: "Search result navigate", term: value });
    onResultItemSelected();
  }, [value, onResultItemSelected]);
  const linkEnhancer = useSearchLinkEnhancer();

  const [focus, setFocus] = useState<number>(0);
  const results = useCacheableQueryAsRecords(
    db,
    sql`SELECT dataset, plate, well
        FROM search_index
        WHERE "${column}" = '${value}'
        ORDER BY dataset, plate, well
        `,
  ).result?.map((flatResults) => {
    const datasetGroups: DatasetGroup[] = [];
    for (const { dataset, plate, well } of flatResults) {
      let activeGroup: DatasetGroup | undefined =
        datasetGroups[datasetGroups.length - 1];
      if (datasetGroups.length === 0 || activeGroup.dataset !== dataset) {
        activeGroup = {
          dataset: toTypedString<DatasetId>(dataset as string),
          plates: [
            {
              plate: plate as string,
              wells: [],
            },
          ],
        };
        datasetGroups.push(activeGroup);
      } else if (
        activeGroup.plates[activeGroup.plates.length - 1].plate !== plate
      ) {
        activeGroup.plates.push({
          plate: plate as string,
          wells: [],
        });
      }

      activeGroup.plates[activeGroup.plates.length - 1].wells.push(
        well as string,
      );
    }
    return datasetGroups;
  });

  const totalItemCount = results?.map((datasetGroups) =>
    datasetGroups.reduce(
      (count, group) =>
        count +
        group.plates.reduce(
          (count, plate) => count + 1 + plate.wells.length,
          0,
        ),
      0,
    ),
  );

  const openItemAt = useCallback(
    (openIndex: number) => {
      if (results?.successful) {
        let i = 0;
        // Unfortunately, we do have to traverse the grouped items to know which one
        // is the i'th one.
        results.value.forEach((group) => {
          group.plates.forEach((plate) => {
            if (i === openIndex) {
              sendResultNavigateEvent();
              history.push(
                linkEnhancer(
                  `/workspace/${workspaceId}/e/${group.dataset}/data/${plate.plate}`,
                ),
              );
            }
            i += 1;
            plate.wells.forEach((well) => {
              if (i === openIndex) {
                sendResultNavigateEvent();
                history.push(
                  linkEnhancer(
                    `/workspace/${workspaceId}/e/${group.dataset}/data/${plate.plate}/${well}`,
                  ),
                );
              }
              i += 1;
            });
          });
        });
      }
    },
    [results, history, workspaceId, sendResultNavigateEvent, linkEnhancer],
  );

  const getDescriptionAt = (index: number) => {
    if (results?.successful) {
      let i = 0;
      for (const group of results.value) {
        for (const plate of group.plates) {
          if (i === index) {
            return `view plate ${plate.plate}`;
          }
          i += 1;
          for (const well of plate.wells) {
            if (i === index) {
              return `view well ${well} on plate ${plate.plate}`;
            }
            i += 1;
          }
        }
      }
    }
  };

  const keyHandler = useCallback(
    (e) => {
      switch (e.key) {
        case "ArrowDown":
          if (totalItemCount?.successful && focus < totalItemCount.value - 1) {
            setFocus(focus + 1);
            e.preventDefault();
          }
          break;
        case "ArrowUp":
          if (focus > 0) {
            setFocus(focus - 1);
            e.preventDefault();
          }
          break;
        case "Enter":
          if (focus) {
            openItemAt(focus);
            e.preventDefault();
          }
          break;
        case "ArrowLeft":
        case "Escape":
          onCloseDetails();
          e.preventDefault();
          break;
      }
    },
    [focus, setFocus, totalItemCount, onCloseDetails, openItemAt],
  );
  useEventListener("keydown", keyHandler, document.body, { capture: true });

  if (!results) {
    // Don't bother with a spinner because in practice this is so fast that a spinner
    // would flicker;
    return null;
  }

  if (!results.successful) {
    return (
      <div>
        Oops. Something went wrong trying to find details on {value}. Please try
        again later.
      </div>
    );
  }

  const totals = {
    datasets: 0,
    plates: 0,
    wells: 0,
  };
  results.value.forEach(({ plates }) => {
    totals.datasets += 1;
    totals.plates += plates.length;
    plates.forEach(({ wells }) => {
      totals.wells += wells.length;
    });
  });

  // TODO(benkomalo): do a better job with limits here. Some search results could
  // return thousands of results, slowing DOM operations, so we do need to limit.
  // And practically, in the detailed view, you're just scrolling through well
  // replicates, so it's not useful to render many.
  const MAX_ITEMS_TO_RENDER = 50;
  let hackyFocusableItemCounter = 0;
  return (
    <>
      <SearchResultsHeader>
        <ReadonlySearchBox value={value} onBack={onCloseDetails} />
        <div className={"tw-text-slate-500 tw-text-sm"}>
          {pluralize("experiment", totals.datasets, true)} •{" "}
          {pluralize("plate", totals.plates, true)} •{" "}
          {pluralize("well", totals.wells, true)}
        </div>
      </SearchResultsHeader>
      <SearchResultsMainPanel>
        <div className={"tw-text-sm tw-py-4"} role={"menu"}>
          {results.value.map(({ dataset, plates }) => {
            return (
              hackyFocusableItemCounter <= MAX_ITEMS_TO_RENDER && (
                // TODO(benkomalo): use the dataset title, not just the dataset ID.
                <div key={dataset}>
                  <div className={"tw-text-slate-500 tw-mb-1 tw-px-4"}>
                    {dataset}
                  </div>
                  <>
                    {plates.map(({ plate, wells }) => {
                      const i = hackyFocusableItemCounter++;
                      return (
                        hackyFocusableItemCounter <= MAX_ITEMS_TO_RENDER && (
                          <div key={plate} className={"tw-mb-2"}>
                            <Link
                              role={"option"}
                              className={cx(
                                "tw-flex tw-flex-row tw-items-center tw-no-underline tw-text-black hover:tw-text-black",
                                "tw-px-8 tw-py-1",
                                i === focus && "tw-bg-purple-200",
                              )}
                              onMouseEnter={() => setFocus(i)}
                              onClick={() => sendResultNavigateEvent()}
                              to={linkEnhancer(
                                `/workspace/${workspaceId}/e/${dataset}/data/${plate}`,
                              )}
                            >
                              <span className={"tw-text-slate-500"}>
                                <PlateIcon />
                              </span>
                              <div className={"tw-flex-1"}>Plate {plate}</div>
                              {i === focus && (
                                <div
                                  className={"tw-text-slate-500"}
                                  aria-hidden={true}
                                >
                                  <ChevronRight size={16} />
                                </div>
                              )}
                            </Link>
                            {wells.map((well) => {
                              const i = hackyFocusableItemCounter++;
                              return (
                                hackyFocusableItemCounter <=
                                  MAX_ITEMS_TO_RENDER && (
                                  <Link
                                    key={`${plate}-${well}`}
                                    role={"option"}
                                    className={cx(
                                      "tw-flex tw-flex-row tw-items-center",
                                      "tw-pl-16 tw-pr-8 tw-py-1",
                                      "tw-no-underline tw-text-black hover:tw-text-black",
                                      i === focus && "tw-bg-purple-200",
                                    )}
                                    onMouseEnter={() => setFocus(i)}
                                    onClick={() => sendResultNavigateEvent()}
                                    to={linkEnhancer(
                                      `/workspace/${workspaceId}/e/${dataset}/data/${plate}/${well}`,
                                    )}
                                  >
                                    <div className={"tw-flex-1"}>
                                      <div>Well {well}</div>
                                      <div
                                        className={
                                          "tw-text-slate-500 tw-text-xs"
                                        }
                                      >
                                        {value}
                                      </div>
                                    </div>

                                    {i === focus && (
                                      <div
                                        className={"tw-text-slate-500"}
                                        aria-hidden={true}
                                      >
                                        <ChevronRight size={16} />
                                      </div>
                                    )}
                                  </Link>
                                )
                              );
                            })}
                          </div>
                        )
                      );
                    })}
                  </>
                </div>
              )
            );
          })}
        </div>
      </SearchResultsMainPanel>
      {/* TODO(you): Fix this no-unnecessary-condition rule violation */}
      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
      {focus !== null && (
        <SearchResultsFooter>
          <div className={"tw-flex tw-flex-row tw-items-center tw-w-full"}>
            <div className={"tw-flex-1 tw-flex tw-items-center"}>
              <HotKeyMarker>
                <CornerDownLeft size={12} />
                <span>Enter</span>
              </HotKeyMarker>{" "}
              to {getDescriptionAt(focus)}
            </div>
            <div>
              <HotKeyMarker>ESC</HotKeyMarker> to go back
            </div>
          </div>
        </SearchResultsFooter>
      )}
    </>
  );
}

function HotKeyMarker({ children }: { children: ReactNode }) {
  return (
    <div
      className={
        "tw-inline-flex tw-items-center tw-space-x-xs tw-bg-gray-100 tw-mx-1 tw-p-1 tw-rounded tw-text-purple"
      }
    >
      {children}
    </div>
  );
}

type TopLevelIndex = {
  groupIndex: number;
  itemIndex: number;
};

/**
 * A two-level listing of search results.
 *
 * The first, or "top level" shows the results from the search query grouped by the
 * metadata column that they match against. Each entry in this top level listing is
 * considered an entity of a specific metadata kind (e.g. if we're searching in
 * "treatment_name", each entry in the result could be a drug, whereas if we're
 * searching in "sample_id", each entry in the result is a sample).
 *
 * Each entity can then be "opened" to show more details on that entity. Notably: all
 * the imaging data associated with that entity.
 */
export default function SearchResultsListing({
  results,
  workspaceId,
  db,
  onResultFocused,
  onResultDetailOpened,
  onResultItemSelected,
}: {
  results: SearchResults;
  workspaceId: WorkspaceId;
  db: DB;
  onResultFocused: (id: string | null) => void;
  onResultDetailOpened: (id: string | null) => void;
  onResultItemSelected: () => void;
}) {
  const [topLevelFocus, setTopLevelFocus] = useState<TopLevelIndex | null>({
    groupIndex: 0,
    itemIndex: 0,
  });
  const history = useHistory();

  // TODO(benkomalo): this is a bit hacky – we keep track of whether or not the details
  // are opened internally but we _also_ invoke a callback to the parent to let them
  // know so they can hide the search input box. Consolidate the two states.
  const [detailOpened, setDetailOpened] = useState<boolean>(false);
  const onOpenDetails = useCallback(
    (i: TopLevelIndex) => {
      const group = results.groups[i.groupIndex];
      const entry = group.entries[i.itemIndex];

      switch (group.type) {
        case "experiment": {
          history.push(group.urls[i.itemIndex]);
          break;
        }
        case "metadata": {
          setTopLevelFocus(i);
          setDetailOpened(true);
          onResultDetailOpened(entry);
          break;
        }
      }
    },
    [results.groups, history, onResultDetailOpened],
  );
  const onCloseDetails = useCallback(() => {
    setDetailOpened(false);
    onResultDetailOpened(null);
  }, [setDetailOpened, onResultDetailOpened]);

  useEffect(() => {
    if (topLevelFocus) {
      onResultFocused(
        `search-result-${topLevelFocus.groupIndex}-${topLevelFocus.itemIndex}`,
      );
    } else {
      onResultFocused(null);
    }
  }, [topLevelFocus, onResultFocused]);

  const group =
    detailOpened && topLevelFocus
      ? results.groups[topLevelFocus.groupIndex]
      : undefined;

  return (
    <div
      className={
        "tw-max-h-[440px] tw-flex tw-flex-col tw-z-10 tw-relative tw-bg-white"
      }
    >
      {!detailOpened && (
        <TopLevelListing
          results={results}
          focus={topLevelFocus}
          onRequestFocus={setTopLevelFocus}
          onOpenDetails={onOpenDetails}
        />
      )}
      {topLevelFocus && group && group.type === "metadata" && (
        <DetailedListing
          key={group.entries[topLevelFocus.itemIndex]}
          workspaceId={workspaceId}
          column={group.column}
          value={group.entries[topLevelFocus.itemIndex]}
          onCloseDetails={onCloseDetails}
          onResultItemSelected={onResultItemSelected}
          db={db}
        />
      )}
    </div>
  );
}

function SearchResultsHeader({ children }: { children: ReactNode }) {
  return <div className={"tw-px-4 tw-pb-2 tw-border-b"}>{children}</div>;
}

function SearchResultsMainPanel({ children }: { children: ReactNode }) {
  return <div className={"tw-overflow-auto tw-flex-1"}>{children}</div>;
}

function SearchResultsFooter({ children }: { children: ReactNode }) {
  return (
    <div className={"tw-border-t tw-px-4 tw-py-2"}>
      <div
        className={
          "tw-text-xs tw-flex tw-flex-row tw-items-center tw-overflow-hidden tw-truncate"
        }
      >
        {children}
      </div>
    </div>
  );
}
