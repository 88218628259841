import { Provider } from "react-redux";
import history from "src/history";
import { DatasetId } from "src/types";
import configureStore from "../imaging/state/store";
import { PhenoFinderContextProvider } from "./Context";
import { PhenoFinder as PhenoFinderCore } from "./components/PhenoFinder";

const store = configureStore(history);

/**
 * Wrapper around the PhenoFinder product
 * - ManifestProvider makes manifest information available for displaying images
 * - PhenoFinderContextProvider makes the PhenoFinder state and dispatch available to all
 *   child components (the initial state and actions can be found in ./state.ts)
 * - PhenoFinderCore contains the actual UI elements
 */
export default function PhenoFinder({ dataset }: { dataset: DatasetId }) {
  return (
    <Provider store={store}>
      <PhenoFinderContextProvider>
        <PhenoFinderCore dataset={dataset} />
      </PhenoFinderContextProvider>
    </Provider>
  );
}
