/**
 * Root component for an experiment page.
 */
import {
  ComponentType,
  ReactElement,
  ReactNode,
  createElement,
  useEffect,
} from "react";
import {
  Route,
  Switch,
  generatePath,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { AdminConsole } from "src/AdminConsole";
import { Track } from "src/Common/EventTracker/Track";
import { AppLocation, AppLocationId } from "src/TrackAppLocation/types";
import {
  useActiveExperiment,
  useActiveExperimentId,
} from "src/Workspace/hooks";
import {
  useAppNavStyle,
  useTabListener,
  useTabState,
} from "../AppChrome/hooks";
import TrackAppLocation from "../Common/TrackAppLocation";
import FeatureSetManagementPage from "../FeatureSetManagementPage";
import {
  DEFAULT_HISTO_ANALYSIS_VIEWS,
  DEFAULT_IF_ANALYSIS_VIEWS,
} from "../FeatureSetManagementPage/views";
import ImageViewer from "../ImageViewer";
import ImageViewerNew from "../ImageViewerNew";
import QualityControlReport from "../QualityControl/QualityControlReport";
import SlideViewer from "../SlideViewer";
import { sourceCropSizeForDataset } from "../env";
import { CropContext } from "../imaging/cropping";
import { DatasetId, DatasetType, WorkspaceId } from "../types";
import { useAreInternalFeaturesEnabled } from "../util/users";
import {
  AnalyzeViewSelectorMenu,
  CreateViewSelectorMenu,
} from "./ViewSelectorMenu";
import { CREATE_VIEWS } from "./createViews";

interface ModuleBase {
  name: string;
  type: string;
  internalOnly?: boolean | undefined;
}

interface ModuleTrackable extends ModuleBase {
  path: string;
  appLocation: AppLocation | undefined;
}

interface ModuleLink extends ModuleTrackable {
  component: ComponentType<{ dataset: DatasetId }>;
  type: "link";
}

interface ModuleMenu extends ModuleBase {
  modules: ModuleLink[];
  type: "menu";
}

// A customizable popover, that can take a trigger element and render an arbitary
// popover.  Currently used for the Analyze menu.
interface ModuleCustom extends ModuleBase {
  components: {
    path: string;
    component: ComponentType<{ dataset: DatasetId }>;
    name: string;
    appLocation: AppLocation | undefined;
    internalOnly?: boolean | undefined;
  }[];
  render: (options: {
    trigger: ReactNode;
    onToggle: (isOpen: boolean) => void;
    workspaceId: WorkspaceId;
    dataset: DatasetId;
  }) => ReactElement;
  type: "custom";
}

type Module = ModuleLink | ModuleMenu | ModuleCustom;

// TODO(benkomalo): the list of available modules for a given experiment should vary
// based on information about that dataset (e.g. does it have any featuresets?) and
// what the user has subscribed to in their plan, so this will eventually be
// dynamically determined by information from the server.

const MODULES_IF: Module[] = [
  {
    name: "View",
    type: "menu",
    modules: [
      {
        name: "Explore Plates",
        type: "link",
        component: ImageViewer,
        path: "/data",
        appLocation: { id: AppLocationId.ExplorePlates },
      },
      {
        name: "Compare Images",
        type: "link",
        component: ImageViewerNew,
        path: "/images",
        appLocation: { id: AppLocationId.CompareImages },
      },
      {
        name: "Quality Control Report",
        type: "link",
        component: QualityControlReport,
        path: "/qc",
        appLocation: { id: AppLocationId.QualityControlReport },
      },
    ],
  },
  {
    name: "Create",
    type: "custom",
    components: CREATE_VIEWS.map((view) => {
      return {
        path: view.path,
        component: view.component,
        name: view.name,
        appLocation: view.appLocation,
        internalOnly: view.internalOnly,
      };
    }),
    render: (props) => <CreateViewSelectorMenu {...props} />,
  },
  {
    name: "Analyze",
    type: "custom",
    components: [
      {
        path: "/measurements",
        component: FeatureSetManagementPage,
        name: "Measurements",
        // The FeatureSetManagementPage tracks the App Location internally
        appLocation: undefined,
        internalOnly: false,
      },
    ],
    render: (props) => (
      <AnalyzeViewSelectorMenu {...props} views={DEFAULT_IF_ANALYSIS_VIEWS} />
    ),
  },
];

const MODULES_HISTOLOGY: Module[] = [
  {
    name: "View",
    type: "menu",
    modules: [
      {
        name: "Explore Slides",
        type: "link",
        component: SlideViewer,
        path: "/data",
        appLocation: { id: AppLocationId.ExploreSlides },
      },
    ],
  },
  {
    name: "Analyze",
    type: "custom",
    components: [
      {
        path: "/measurements",
        component: FeatureSetManagementPage,
        name: "Measurements",
        // The FeatureSetManagementPage tracks the App Location internally
        appLocation: undefined,
        internalOnly: false,
      },
    ],
    render: (props) => (
      <AnalyzeViewSelectorMenu
        {...props}
        views={DEFAULT_HISTO_ANALYSIS_VIEWS}
      />
    ),
  },
];

function getAllGroupedModules(type: DatasetType): Module[] {
  switch (type) {
    case "if_bf_plates":
      return MODULES_IF;
    case "histology":
      return MODULES_HISTOLOGY;
  }
}

function getAllModules(type: DatasetType) {
  return getAllGroupedModules(type).flatMap((m) => {
    switch (m.type) {
      case "menu":
        return m.modules;
      case "custom":
        // Note that for a custom module we are passing the module name as the name
        // Because this is what ultimately gets used to determine the active tab.
        return m.components.map((c) => ({
          ...c,
          name: m.name,
        }));
      case "link":
        return m;
    }
  });
}

export default function ExperimentPage({
  workspaceId,
}: {
  workspaceId: WorkspaceId;
}) {
  const { path: basePath, params } = useRouteMatch<{
    id: string;
    dataset: string;
  }>();
  const datasetId = useActiveExperimentId();
  const dataset = useActiveExperiment();
  const history = useHistory();
  const { pathname: currentLocation } = useLocation();
  const [, setTabs] = useTabState();
  const [, setStyle] = useAppNavStyle();
  const datasetType: DatasetType = dataset.type;
  const activeTabName = getAllModules(datasetType).find(({ path }) =>
    currentLocation.startsWith(generatePath(`${basePath}${path}`, params)),
  )?.name;

  useEffect(() => {
    const gatedPaths = {};

    const rejectedPaths = Object.entries(gatedPaths)
      .filter(([, enabled]) => !enabled)
      .map(([path]) => path);
    const modules = rejectModulesWithPaths(
      getAllGroupedModules(datasetType),
      rejectedPaths,
    );
    setStyle("raised");
    setTabs(
      modules.map((m) => ({
        id: m.name,
        displayText: m.name,
        active:
          m.name === activeTabName ||
          (m.type === "menu" &&
            m.modules.some(({ name }) => name === activeTabName)),
        ...(m.type === "menu" && m.modules.length
          ? {
              menuItems: m.modules.map(({ name, type, path }) => ({
                id: name,
                displayText: name,
                active: name === activeTabName,
                path:
                  // TODO(you): Fix this no-unnecessary-condition rule violation
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  type === "link"
                    ? generatePath(`${basePath}${path}`, params)
                    : undefined,
              })),
            }
          : {}),
        ...(m.type === "custom"
          ? {
              render: ({ trigger, onToggle }) =>
                m.render({
                  trigger,
                  onToggle,
                  workspaceId: workspaceId,
                  dataset: datasetId,
                }),
            }
          : {}),
        path:
          m.type === "link"
            ? generatePath(`${basePath}${m.path}`, params)
            : undefined,
      })),
    );
  }, [
    activeTabName,
    setTabs,
    datasetId,
    datasetType,
    basePath,
    params,
    setStyle,
    workspaceId,
  ]);

  useTabListener((selectedTabId: string | undefined) => {
    const path = getAllModules(datasetType).find(
      ({ name }) => name === selectedTabId,
    )?.path;
    if (path) {
      history.push(generatePath(`${basePath}${path}`, params));
    }
  });
  const internalFeatures =
    useAreInternalFeaturesEnabled() && workspaceId !== "public-demo";

  return (
    <div>
      <CropContext.Provider
        value={{
          sourceCropSize: sourceCropSizeForDataset(workspaceId, datasetId),
        }}
      >
        <Switch>
          {getAllModules(datasetType)
            .filter((view) => !view.internalOnly || internalFeatures)
            .map(({ path, component, appLocation }) => (
              <Route key={path} path={`${basePath}${path}`}>
                <TrackAppLocation appLocation={appLocation}>
                  <Track global={{ dataset: datasetId }}>
                    {createElement(component, { dataset: datasetId })}
                  </Track>
                </TrackAppLocation>
              </Route>
            ))}
          <Route key="admin" path={`${basePath}/admin`}>
            <TrackAppLocation appLocation={{ id: AppLocationId.AdminConsole }}>
              <AdminConsole workspaceId={workspaceId} datasetId={datasetId} />
            </TrackAppLocation>
          </Route>
        </Switch>
      </CropContext.Provider>
    </div>
  );
}

function rejectModulesWithPaths<T extends Module>(
  modules: T[],
  rejectPaths: string[],
): T[] {
  return modules.reduce((acc: T[], m: T): T[] => {
    switch (m.type) {
      case "link":
        if (rejectPaths.includes(m.path)) {
          return acc;
        } else {
          return [...acc, m];
        }
      case "custom": {
        // This is a no-op because the filtering for these happens in SelectMenu
        // components.
        return [...acc, m];
      }
      case "menu": {
        const filteredMenuModules = rejectModulesWithPaths(
          m.modules,
          rejectPaths,
        );
        return [
          ...acc,
          ...(filteredMenuModules.length > 0
            ? [
                {
                  ...m,
                  modules: filteredMenuModules,
                },
              ]
            : []),
        ];
      }
    }
  }, []);
}
