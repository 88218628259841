import { BLOCK_SIZE } from "./constants";
import { Point } from "./types";

// Convert a pixel location into a value that we use in a block id
// (the idea being that we can have several values placed in the same block)
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function blockValue(x: number) {
  return Math.max(
    0,
    Math.floor(x / BLOCK_SIZE) +
      // Allow one blocks worth of negative values
      1,
  );
}

// Get the block id for a given pixel location
export function getBlockId(x: number, y: number): string {
  return [blockValue(x), blockValue(y)].join("-");
}

// Create an index for a list of points (mapping block ids to the list of points in the
// block)
export function indexPoints(points: Point[]) {
  const index: Record<string, Point[] | undefined> = {};

  points.forEach((point) => {
    (index[getBlockId(point.x, point.y)] ??= []).push(point);
  }, []);

  return index;
}

// Find the ids of the blocks that might contain points within the given distance
// from the given x/y location
function blocksNear(x: number, y: number, distance: number): string[] {
  const minX = blockValue(x - distance);
  const maxX = blockValue(x + distance);
  const minY = blockValue(y - distance);
  const maxY = blockValue(y + distance);

  const blocks: string[] = [];

  for (let bx = minX; bx <= maxX; bx += 1) {
    for (let by = minY; by <= maxY; by += 1) {
      blocks.push([bx, by].join("-"));
    }
  }

  return blocks;
}

// Gets a list of points within a given distance of a location, using an index
// built by the indexPoints function
export function getPointsNear(
  x: number,
  y: number,
  distance: number,
  index: Record<string, Point[] | undefined>,
): Point[] {
  return blocksNear(x, y, distance).flatMap((blockId) => index[blockId] ?? []);
}

// The distance^2 between two points
export function distance2(
  a: { x: number; y: number },
  b: { x: number; y: number },
): number {
  return Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2);
}

export function distance(
  a: { x: number; y: number },
  b: { x: number; y: number },
): number {
  return Math.sqrt(distance2(a, b));
}

// Try to format numbers so they have enough decimal places to be useful, but also
// try to be compact (i.e. we remove trailing 0s in the fractional part of the number)
export function formatNumberForLegend(value: number): string {
  const absValue = Math.abs(value);

  return absValue === 0
    ? "0"
    : (absValue <= 0.01 ? value.toPrecision(3) : value.toFixed(3)).replace(
        /\.?0+$/,
        "",
      );
}

export function getOffset(e: React.MouseEvent<HTMLElement>) {
  const rect = e.currentTarget.getBoundingClientRect();
  return {
    offsetX: e.clientX - rect.left,
    offsetY: e.clientY - rect.top,
  };
}
