type TaggedStringParts = {
  baseName: string;
  datestamp: string | null;
  timestamp: string | null;
  uuid: string | null;
};

/**
 * Parse a string that has been suffixed with a timestamp + UUID.
 *
 * A client-side re-implementation of TaggedString#parse.
 */
export function parseTaggedString(s: string): TaggedStringParts | null {
  // V1 ("CytokineModelPredictions-20220505-1514970306-b129c89d")
  // V0 ("CytokineModelPredictions-20220505-1651789795")
  const uuidMatch = /^(.+)-(\d{8})-(\d{10})(?:-([0-9a-f]{8}))?$/.exec(s);
  if (uuidMatch) {
    return {
      baseName: uuidMatch[1],
      datestamp: uuidMatch[2],
      timestamp: uuidMatch[3],
      uuid: uuidMatch[4] || null,
    };
  }
  return null;
}

function joinTaggedString(parts: TaggedStringParts) {
  const { baseName, datestamp, timestamp, uuid } = parts;
  return [baseName, datestamp, timestamp, uuid]
    .filter((part) => part !== null)
    .join("-");
}

/**
 * Get an un-timestamped version of a name.
 */
export function getUntimestamped(s: string): string {
  const parsed = parseTaggedString(s);
  return parsed ? parsed.baseName : s;
}

/**
 * Filter a set of paths to only the 'latest' versions of each path.
 */
export function filterToLatest(featureSets: string[]): string[] {
  const latestFeatureSets: { [name: string]: TaggedStringParts } = {};
  for (const featureSet of featureSets) {
    let parsed = parseTaggedString(featureSet);
    if (!parsed) {
      // Oddly unsuffixed FeatureSet -- treat as its own
      parsed = {
        baseName: featureSet,
        datestamp: null,
        timestamp: null,
        uuid: null,
      };
    }

    const { baseName, datestamp, timestamp } = parsed;
    if (baseName in latestFeatureSets) {
      const { datestamp: prevDate, timestamp: prevTime } =
        latestFeatureSets[baseName];
      if (
        prevDate === null ||
        (datestamp && prevDate < datestamp) ||
        prevTime === null ||
        (timestamp && prevTime < timestamp)
      ) {
        latestFeatureSets[baseName] = parsed;
      }
    } else {
      latestFeatureSets[baseName] = parsed;
    }
  }
  return Object.values(latestFeatureSets).map(joinTaggedString);
}
