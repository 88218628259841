import React, { ComponentType, Suspense } from "react";
import { FullScreenLoader } from "../Common/FullScreenLoader";

export default function createDelayedComponent<P>(
  Component: React.LazyExoticComponent<ComponentType<P>>,
) {
  const Wrapped = (props: P & JSX.IntrinsicAttributes) => (
    <Suspense fallback={<FullScreenLoader />}>
      <Component {...props} />
    </Suspense>
  );
  Wrapped.displayName = `Delayed(${Component.name})`;
  return Wrapped;
}
