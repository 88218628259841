import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import cx from "classnames";
import * as React from "react";
import { Check, Minus } from "react-feather";

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxPrimitive.CheckboxProps
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cx(
      "tw-peer",
      "tw-h-4 tw-w-4 tw-shrink-0",
      "tw-rounded tw-border tw-bg-slate-50 tw-border-slate-400 tw-text-white",
      "focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-ring focus-visible:tw-ring-primary-500",
      "disabled:tw-cursor-not-allowed disabled:tw-bg-slate-200 disabled:tw-text-slate-500",
      {
        "data-[state=indeterminate]:tw-text-slate-400": !props.disabled,
        "data-[state=checked]:tw-bg-primary-500": !props.disabled,
      },
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cx(
        "tw-flex tw-items-center tw-justify-center tw-text-current",
      )}
    >
      {props.checked === "indeterminate" ? (
        <Minus className="tw-stroke-current" size={14} />
      ) : (
        <Check className="tw-stroke-current" size={12} />
      )}
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
