import cx from "classnames";
import { useRef, useState } from "react";
import { X } from "react-feather";
import Select from "react-select-plus";
import { Checkbox } from "@spring/ui/Checkbox";
import { Button } from "../../../Common/Button";
import ControlHeader from "../../../Control/ControlHeader";
import { VerticalControlSection } from "../../../Control/ControlSection";
import { XAxisSort, XAxisSortDirection } from "../types";

interface OverflowSettings {
  yAxisMin: number | undefined;
  yAxisMax: number | undefined;
  xAxisSort: XAxisSort;
  xAxisSortDirection: XAxisSortDirection;
}

function ClearableNumberInput({
  className,
  value,
  onChange,
}: {
  className?: string;
  value: number | undefined;
  onChange: (newValue: number | undefined) => void;
}) {
  const [stringValue, setStringValue] = useState(String(value ?? ""));
  const lastValueRef = useRef<number | undefined>(value);

  return (
    <div
      className={cx(
        "tw-border tw-rounded tw-flex tw-flex-row tw-gap-sm tw-relative",
        className,
      )}
    >
      <input
        type="text"
        className="tw-w-full tw-p-sm tw-rounded tw-pr-[36px]"
        value={stringValue}
        onChange={(e) => {
          const value = e.currentTarget.value;
          setStringValue(value);

          const parsedValue = value !== "" ? parseFloat(value) : undefined;

          if (parsedValue === undefined || !Number.isNaN(parsedValue)) {
            if (parsedValue !== lastValueRef.current) {
              onChange(parsedValue);
            }
          }
        }}
      />
      {stringValue !== "" && (
        <button
          className="tw-absolute tw-right-0 tw-inset-y-0 tw-px-sm tw-rounded"
          onClick={() => {
            setStringValue("");
            lastValueRef.current = undefined;
            onChange(undefined);
          }}
        >
          <X size={16} />
        </button>
      )}
    </div>
  );
}

export function VisualizationSettings({
  showPoints,

  onToggleShowPoints,
  isLoading,
  settings,
  onUpdateSettings,
}: {
  showPoints: boolean;
  onToggleShowPoints: () => void;
  settings: OverflowSettings;
  isLoading: boolean;
  onUpdateSettings: (newSettings: OverflowSettings) => void;
}) {
  const [pendingSettings, setPendingSettings] = useState<{
    yAxisMin: number | undefined;
    yAxisMax: number | undefined;
    xAxisSort: XAxisSort;
    xAxisSortDirection: XAxisSortDirection;
  }>({
    yAxisMin: settings.yAxisMin,
    yAxisMax: settings.yAxisMax,
    xAxisSort: settings.xAxisSort,
    xAxisSortDirection: settings.xAxisSortDirection,
  });

  // TODO: are there props or another library which can better handle winnowing huge search/selects?
  // TODO: rotate angle of labels in single grouping
  return (
    <div className="tw-flex tw-flex-col">
      <label
        className={"tw-flex tw-flex-row tw-items-center tw-pl-lg tw-py-sm"}
      >
        <ControlHeader>Show Points</ControlHeader>
        <Checkbox
          className={"tw-ml-md"}
          checked={showPoints}
          disabled={isLoading}
          onCheckedChange={onToggleShowPoints}
        />
      </label>
      <div className={"tw-flex tw-flex-row tw-px-md"}>
        <div className={"tw-flex tw-flex-col tw-h-full tw-px-sm tw-pt-sm"}>
          <ControlHeader>Y-Axis Minimum</ControlHeader>
          <ClearableNumberInput
            value={pendingSettings.yAxisMin}
            onChange={(yAxisMin) =>
              setPendingSettings((settings) => ({ ...settings, yAxisMin }))
            }
          />
        </div>
        <div className={"tw-flex tw-flex-col tw-h-full tw-pl-sm tw-pt-sm"}>
          <ControlHeader>Y-Axis Maximum</ControlHeader>
          <ClearableNumberInput
            value={pendingSettings.yAxisMax}
            onChange={(yAxisMax) =>
              setPendingSettings((settings) => ({ ...settings, yAxisMax }))
            }
          />
        </div>
      </div>
      <div className={"tw-pl-sm tw-pt-sm"}>
        <VerticalControlSection>
          <ControlHeader>X-Axis Sorting</ControlHeader>
          <div className="tw-flex">
            <Select
              className="tw-flex-1"
              simpleValue={true}
              value={pendingSettings.xAxisSort}
              clearable={false}
              options={[
                { value: XAxisSort.Median, label: "Median Value" },
                { value: XAxisSort.AbsoluteDelta, label: "Absolute Delta" },
                { value: XAxisSort.Alphabetical, label: "Alphabetical" },
              ]}
              onChange={(newSort: XAxisSort) =>
                setPendingSettings((settings) => ({
                  ...settings,
                  xAxisSort: newSort,
                }))
              }
            />
            <Select
              className="tw-min-w-[75px] tw-ml-sm"
              simpleValue={true}
              value={pendingSettings.xAxisSortDirection}
              clearable={false}
              options={[
                { value: XAxisSortDirection.Desc, label: "DESC" },
                { value: XAxisSortDirection.Asc, label: "ASC" },
              ]}
              onChange={(newSortDirection: XAxisSortDirection) =>
                setPendingSettings((settings) => ({
                  ...settings,
                  xAxisSortDirection: newSortDirection,
                }))
              }
            />
          </div>
        </VerticalControlSection>
      </div>
      <div className="tw-px-md tw-pb-md tw-grid tw-grid-cols-1 tw-pl-lg">
        <Button
          className="tw-w-full tw-py-xs"
          variant="secondary"
          onClick={() => {
            if (
              pendingSettings.yAxisMin !== undefined &&
              pendingSettings.yAxisMax !== undefined &&
              pendingSettings.yAxisMin > pendingSettings.yAxisMax
            ) {
              // They've mixed up the min/max so we'll put them back in order
              onUpdateSettings({
                ...pendingSettings,
                yAxisMin: pendingSettings.yAxisMax,
                yAxisMax: pendingSettings.yAxisMin,
              });
            } else {
              onUpdateSettings(pendingSettings);
            }
          }}
          disableTracking={true}
        >
          <div className="tw-w-full tw-text-center">Save Settings</div>
        </Button>
      </div>
    </div>
  );
}
