import { isField } from "src/imaging/util";
import { hasKeys } from "src/util/has-keys";
import { inferLevelFromFeatures } from "src/util/immunofluorescence-util";
import { Entry } from "./types";

export function convertRawFeatureToEntry(
  entry: { [key: string]: string | number | null },
  column: string,
): Entry {
  if (
    !hasKeys(entry, "plate", "well") ||
    typeof entry.plate !== "string" ||
    typeof entry.well !== "string"
  ) {
    throw new Error("Expected entry to have a plate and well");
  }
  const { plate, well } = entry;
  const type = inferLevelFromFeatures([entry]);
  const value = Number(entry[column]);
  const common = { plate, well, value };

  switch (type) {
    case "cell":
      if (!hasKeys(entry, "row", "column", "field") || !isField(entry.field)) {
        throw new Error("Invalid cell entry");
      }
      return {
        metadata: {
          type: "cell",
          row: Number(entry.row),
          column: Number(entry.column),
          field: entry.field,
          ...common,
        },
      };
    case "field":
      if (!hasKeys(entry, "field") || !isField(entry.field)) {
        throw new Error("Invalid field entry");
      }
      return {
        metadata: {
          type: "field",
          field: entry.field,
          ...common,
        },
      };
    case "well":
      return {
        metadata: {
          type: "well",
          ...common,
        },
      };
  }
}
