import type { MapRow } from "apache-arrow";
import cx from "classnames";
import { useCallback, useState } from "react";
import { DEFAULT_COLUMN_WIDTH } from "./constants";

function Value({
  initialValue,
  histogram,
  mode,
  onMouseDown,
  onSave,
}: {
  initialValue: string;
  histogram?: MapRow<any, any>;
  mode: "view" | "edit";
  onMouseDown: React.MouseEventHandler<HTMLDivElement>;
  onSave: (value: string) => void;
}) {
  const multipleValues = histogram && histogram.size > 1;
  const [value, setValue] = useState(String(initialValue));

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => setValue(e.currentTarget.value),
    [],
  );

  const handleBlur = useCallback(() => onSave(value), [onSave, value]);

  const handleKeyDown = useCallback<
    React.KeyboardEventHandler<HTMLInputElement>
  >((e) => {
    if (e.key === "Enter") {
      e.currentTarget.blur();
    }
  }, []);

  return (
    <div className="tw-w-full tw-h-full tw-relative">
      {multipleValues ? (
        <span className="tw-inline-flex tw-flex-row tw-items-center tw-gap-sm tw-max-w-full tw-h-full tw-px-sm tw-overflow-x-auto">
          {Object.entries(histogram.toJSON()).map(([value, count]) => (
            <span
              key={value}
              title={String(count)}
              className="tw-border tw-px-sm tw-text-sm tw-rounded"
            >
              {value}
            </span>
          ))}
        </span>
      ) : (
        <input
          type="text"
          className={cx(
            "tw-absolute tw-inset-0 tw-px-sm tw-py-0",
            "tw-text-black tw-bg-transparent",
            mode === "edit" && "tw-bg-blue-200",
          )}
          readOnly={mode !== "edit"}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onMouseDown={onMouseDown}
        />
      )}
    </div>
  );
}

export function Cell({
  name,
  value,
  mode,
  histogram,
  onActivate,
  onSave,
}: {
  name: string;
  value: string;
  mode: "view" | "edit" | "pending";
  histogram?: MapRow<any, any>;
  onActivate: (name: string, isActive: boolean) => void;
  onSave: (name: string, value: string) => void;
}) {
  const multipleValues = histogram && histogram.size > 1;

  const handleSave = useCallback(
    (newValue: string) => {
      if (newValue !== value) {
        onSave(name, newValue);
      }
      onActivate(name, false);
    },
    [name, onActivate, onSave, value],
  );

  const handleActivate = useCallback(() => {
    onActivate(name, true);
  }, [name, onActivate]);

  return (
    <div
      className={cx(
        "tw-truncate tw-border-r",
        mode === "edit" && "tw-cursor-text",
        mode === "pending"
          ? "tw-bg-green-100"
          : multipleValues && "tw-bg-gray-50",
        !multipleValues && "tw-text-gray-400",
        "tw-relative",
      )}
      style={{ width: DEFAULT_COLUMN_WIDTH }}
    >
      <Value
        initialValue={value}
        histogram={histogram}
        mode={mode === "edit" ? "edit" : "view"}
        onMouseDown={handleActivate}
        onSave={handleSave}
      />
    </div>
  );
}
