import { useCallback, useMemo } from "react";
import { useEventTracker } from "src/hooks/analytics";
import { Select as UiSelect } from "@spring/ui/Select/Select";
import type { SelectProps } from "@spring/ui/Select/Select";
import { ENV } from "../env";

export function Select<T>({
  disableTracking = false,
  name,
  ...props
}: SelectProps<T> &
  (
    | { disableTracking: true; name?: string }
    | { disableTracking?: false; name: string }
  )) {
  const track = useEventTracker();

  const addTracking = useCallback(
    <U extends Parameters<SelectProps<T>["onChange"]>>(
      fn: (...args: U) => void,
    ) =>
      (...args: U) => {
        const selectName = name ?? "unknown";
        if (!disableTracking) {
          if (ENV === "development" && selectName === "unknown") {
            console.error(
              "Tracking unknown select! Either provide a name or set the disableTracking flag if you don't wish to track clicks on this select.",
            );
          }
          track({ id: "Change select option", name: selectName });
        }

        fn(...args);
      },
    [disableTracking, name, track],
  );

  // NOTE: The Select component accepts a different number of params in onChange depending
  // on if it's multi/grouped. The typing of SelectProps<T> and addTracking guarantee that
  // the onChange should work; we cast it to a "function that can handle any number of unknown
  // inputs" so the types will pass. Otherwise we'd have to handle each of the cases separately.
  const modifiedProps = useMemo(
    (): SelectProps<T> => ({
      ...props,
      onChange: addTracking(props.onChange as (...args: unknown[]) => unknown),
    }),
    [addTracking, props],
  );

  return <UiSelect {...modifiedProps} />;
}
