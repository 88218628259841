import type { TypedString } from "src/types";

export function toTypedString(s: "" | undefined): undefined;
export function toTypedString<T extends TypedString>(s: `${string}`): T;
export function toTypedString<T extends TypedString>(
  s: string | undefined,
): T | undefined;
export function toTypedString<T extends TypedString>(
  s: string | undefined,
): T | undefined {
  return s ? (s as T) : undefined;
}
