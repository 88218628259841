import { ReactNode, createContext, useContext, useState } from "react";
import { MethodSectionKey } from "./utils";

type MethodsContext = {
  activeSectionKey: MethodSectionKey | null;
  setActiveSectionKey: (sectionKey: MethodSectionKey | null) => void;
};

const Context = createContext<MethodsContext | null>(null);

export function useMethodsContext(): MethodsContext {
  const context = useContext(Context);
  if (context === null) {
    throw new Error(
      "useMethodsContext must be used within a <MethodsContextProvider />",
    );
  }

  return context;
}

export function MethodsContextProvider({ children }: { children: ReactNode }) {
  const [activeSectionKey, setActiveSectionKey] =
    useState<MethodSectionKey | null>(null);

  return (
    <Context.Provider
      value={{
        activeSectionKey,
        setActiveSectionKey,
      }}
    >
      {children}
    </Context.Provider>
  );
}
