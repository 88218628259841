import * as d3 from "d3";
import { useCallback, useMemo, useRef, useState } from "react";
import {
  ChartDimensions,
  ChartDimensionsConfig,
  ChartMargin,
  ClusterMetadata,
  TreeModificationStep,
} from "src/PhenoFinder/types";
import invariant from "tiny-invariant";
import { usePhenoFinderContext } from "../Context";
import {
  cleanUserInputString,
  consolidateDisplayNameModifications,
  sortStringsOtherLast,
} from "../utils";
import { getDefaultClusterDisplayName } from "./utils";

function calculateChartDimensions(
  dimensions: ChartDimensionsConfig,
): ChartDimensions {
  const parsedDimensions = {
    ...dimensions,
    margin: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      ...dimensions.margin,
    },
  };

  return {
    ...parsedDimensions,
    boundedHeight: Math.max(
      parsedDimensions.height -
        parsedDimensions.margin.top -
        parsedDimensions.margin.bottom,
      0,
    ),
    boundedWidth: Math.max(
      parsedDimensions.width -
        parsedDimensions.margin.left -
        parsedDimensions.margin.right,
      0,
    ),
  };
}

export const useChartDimensions = ({
  margin,
}: {
  margin?: Partial<ChartMargin>;
} = {}): [(el: HTMLDivElement) => void, ChartDimensions] => {
  const refContainer = useRef<HTMLDivElement | null>(null);
  const refResizeObserver = useRef<ResizeObserver | null>(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const setContainerRef = useCallback(
    (el: HTMLDivElement) => {
      if (refContainer.current !== el) {
        refContainer.current = el;

        refResizeObserver.current?.disconnect();

        // TODO(you): Fix this no-unnecessary-condition rule violation
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!el) {
          refResizeObserver.current = null;
          return;
        }

        refResizeObserver.current = new ResizeObserver((entries) => {
          if (!Array.isArray(entries)) return;
          if (!entries.length) return;

          const entry = entries[0];

          if (width !== entry.contentRect.width)
            setWidth(entry.contentRect.width);
          if (height !== entry.contentRect.height)
            setHeight(entry.contentRect.height);
        });

        refResizeObserver.current.observe(el);
      }
    },
    [height, width],
  );

  const chartDimensions = useMemo(
    () =>
      calculateChartDimensions({
        width,
        height,
        margin,
      }),
    [width, height, margin],
  );

  return [setContainerRef, chartDimensions];
};

export function useDataStacks(
  data: ClusterMetadata[],
): d3.Series<[string, d3.InternMap<string, ClusterMetadata>], string>[] {
  return useMemo(() => {
    const metadataValues = d3.union(data.map((d) => d.value));
    const valuesArray = [...metadataValues.keys()].sort(sortStringsOtherLast);

    const stackGenerator = d3
      .stack<[string, d3.InternMap<string, ClusterMetadata>]>()
      .keys(valuesArray)
      .value(([, group], key) => group.get(key)?.count ?? 0)
      .offset(d3.stackOffsetExpand);

    const groupedData = d3.index(
      data,
      (d) => d.clusterName,
      (d) => d.value,
    );

    return stackGenerator(groupedData);
  }, [data]);
}

export function useEditClusterDisplayNames({
  // If false, will save a list of pendingDisplayNameModifications instead
  autoSaveState = true,
}: { autoSaveState?: boolean } = {}) {
  const [state, dispatch] = usePhenoFinderContext();
  const { clusters } = state;
  const [pendingDisplayNameModifications, setPendingDisplayNameModifications] =
    useState<TreeModificationStep[]>([]);

  const [displayNames, setDisplayNames] = useState<string[]>(
    (clusters ?? []).map(
      (cluster, i) => cluster.displayName ?? getDefaultClusterDisplayName(i),
    ),
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const validateDisplayName = useCallback((name: string | undefined) => {
    return null;
  }, []);

  const [displayNamesErrors, setDisplayNameErrors] = useState(
    displayNames.map((displayName) => validateDisplayName(displayName)),
  );

  const onChangeDisplayName = useCallback(
    (clusterIndex: number, displayName: string) => {
      invariant(
        clusters,
        "setDisplayName can only be called when clusters exist",
      );

      // The user input cluster has a different state because it's allowed to take on invalid
      // values (like "        " which will be saved as undefined)
      const newDisplayNames = [...displayNames];
      newDisplayNames.splice(clusterIndex, 1, displayName);
      setDisplayNames(newDisplayNames);

      const cleanedDisplayName = cleanUserInputString(displayName);
      const validationError = validateDisplayName(displayName);
      // TODO(you): Fix this no-unnecessary-condition rule violation
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (validationError !== displayNamesErrors[clusterIndex]) {
        const newDisplayNamesErrors = [...displayNamesErrors];
        newDisplayNamesErrors.splice(clusterIndex, 1, validationError);
        setDisplayNameErrors(newDisplayNamesErrors);
      }

      if (
        autoSaveState === true &&
        // TODO(you): Fix this no-unnecessary-condition rule violation
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        validationError === null &&
        cleanedDisplayName !== clusters[clusterIndex].displayName
      ) {
        dispatch({
          type: "updateClusterDisplayName",
          clusterName: clusters[clusterIndex].name,
          displayName: cleanedDisplayName,
        });
      } else {
        setPendingDisplayNameModifications(
          consolidateDisplayNameModifications([
            ...pendingDisplayNameModifications,
            {
              type: "changeDisplayName",
              nodeName: clusters[clusterIndex].name,
              displayName: cleanedDisplayName,
            },
          ]),
        );
      }
    },
    [
      dispatch,
      autoSaveState,
      clusters,
      displayNames,
      displayNamesErrors,
      validateDisplayName,
      pendingDisplayNameModifications,
    ],
  );

  const onBlurDisplayNameInput = useCallback(
    (clusterIndex: number) => {
      // The display name will already have been validated and saved on the change event
      // On blur, we just clean up what the user has typed (we can't do it while they're typing, or it
      // would feel strange that things are changing underneath them):
      // - If it's something like "  Cluster A     " we'll change it to "Cluster A"
      // - If it's an empty string, we'll change it back to the default
      const cleanDisplayName = cleanUserInputString(displayNames[clusterIndex]);
      const newDisplayNames = [...displayNames];
      newDisplayNames.splice(
        clusterIndex,
        1,
        cleanDisplayName ?? getDefaultClusterDisplayName(clusterIndex),
      );
      setDisplayNames(newDisplayNames);
    },
    [displayNames],
  );

  const saveDisplayNameModifications = useCallback(() => {
    if (pendingDisplayNameModifications.length === 0) {
      return;
    }
    dispatch({
      type: "applyDisplayNameModifications",
      modifications: pendingDisplayNameModifications,
    });
  }, [dispatch, pendingDisplayNameModifications]);

  return {
    displayNames,
    displayNamesErrors,
    onChangeDisplayName,
    onBlurDisplayNameInput,
    pendingDisplayNameModifications,
    saveDisplayNameModifications,
  };
}
