import cx from "classnames";
import isEqual from "lodash.isequal";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { Grid, Sliders, Square } from "react-feather";
import { DatasetId } from "src/types";
import { DeprecatedButton, buttonClasses } from "../Common/DeprecatedButton";
import { PulseGuiderRoot } from "../Insights/PulseGuider";
import { usePalette, usePalettes } from "../hooks/immunofluorescence";
import BaseVisualizationControls from "../imaging/Control/ConnectedVisualizationControls";
import CropSizeSlider from "../imaging/Control/CropSizeSlider";
import RenderSizeSlider from "../imaging/Control/RenderSizeSlider";
import Strut from "../imaging/Strut";
import { useVisualizationState } from "../imaging/state/hooks";
import { DisplayRange, ImageSet } from "../imaging/types";
import { useModelTrainingAndInferenceAreAllowed } from "../util/users";
import { useLabeledSetContext } from "./Context";
import { ImageFilter } from "./ImageFilter";
import { LabMateSettings } from "./LabMateSettings";
import { SampleViewer, SampleViewerInstance } from "./SampleViewer";
import { getPlatesWithStains, reformatPalettes } from "./util";

interface Props {
  className?: string;
  dataset: DatasetId;
  imageSet: ImageSet | null;
}

export function MainContent({ className, dataset, imageSet }: Props) {
  // We inherit the stains from the landing page which makes it available via Context.
  // This is because we often only want to make a subset of stains available given
  // potentially mixed palettes.
  const {
    state: {
      unsavedChanges,
      showVisualizationControls,
      stains,
      stainChannelIndices,
      stainDisplayRanges,
      size,
      cropSize,
      defaultCropSize,
    },
    updateState,
  } = useLabeledSetContext();
  const refSampleViewer = useRef<SampleViewerInstance>(null);
  const { channelMap, channelLoaded, displaySettings } =
    useVisualizationState();

  // Get the default multipliers for the image controls subset to our stains.
  // TODO (michaelwiest): we should be storing the plates in the labeledsetcontext
  // and then using those directly here and in useFilteredPlates.
  const plates = usePalettes({ dataset })?.map((palettes) => {
    const platesByStain = reformatPalettes(palettes);
    return [...new Set(getPlatesWithStains(platesByStain, stains))];
  });
  const plateToUse = plates?.successful ? plates.value[0] : undefined;
  const maybePalette = usePalette(
    plateToUse ? { dataset, acquisition: plateToUse } : { skip: true },
  );
  const palette = useMemo(() => {
    return maybePalette?.successful
      ? maybePalette.value
      : { stains: [], multipliers: [] };
  }, [maybePalette]);
  const writesAllowed = useModelTrainingAndInferenceAreAllowed();

  useEffect(() => {
    // Don't update any visualization settings until after channels have been loaded
    if (!channelLoaded.some((loaded) => loaded)) {
      return;
    }

    const newStainChannelIndices: Record<string, number> = {};
    const newStainDisplayRanges: Record<string, DisplayRange> = {};
    for (
      let channelIndex = 0;
      channelIndex < channelMap.length;
      channelIndex++
    ) {
      const stainIndex = channelMap[channelIndex];
      if (stainIndex === null) {
        continue;
      }

      const stain = palette.stains[stainIndex];
      newStainChannelIndices[stain] = channelIndex;
      const stainDisplayRange = displaySettings[channelIndex].activeRange;
      // TODO(you): Fix this no-unnecessary-condition rule violation
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      newStainDisplayRanges[stain] = stainDisplayRange ?? undefined;
    }

    if (
      !isEqual(stainChannelIndices, newStainChannelIndices) ||
      !isEqual(stainDisplayRanges, newStainDisplayRanges)
    ) {
      updateState((state) => ({
        ...state,
        stainChannelIndices: newStainChannelIndices,
        stainDisplayRanges: newStainDisplayRanges,
        unsavedChanges: true,
      }));
    }
  }, [
    channelLoaded,
    channelMap,
    displaySettings,
    stainChannelIndices,
    stainDisplayRanges,
    palette,
    updateState,
  ]);

  const setSize = useCallback(
    (size: number) => {
      updateState((state) => ({ ...state, size }));
    },
    [updateState],
  );

  const setCropSize = useCallback(
    (cropSize: number) => {
      updateState((state) => ({ ...state, cropSize }));
    },
    [updateState],
  );

  const onToggleStainSettings = useCallback(() => {
    updateState((state) => ({
      ...state,
      showVisualizationControls: !state.showVisualizationControls,
    }));
  }, [updateState]);

  return (
    <div className={cx("tw-flex tw-flex-row", className)}>
      <div className="tw-flex-1 tw-flex tw-flex-col tw-overflow-hidden">
        {unsavedChanges && !writesAllowed && (
          <div className={"tw-bg-yellow-400 tw-py-sm tw-p-xl tw-text-sm"}>
            Changes being applied locally for demo purposes. Contact
            support@springscience.com to get your own workspace and train your
            own models.
          </div>
        )}
        <SampleViewer
          className="tw-flex-1 tw-overflow-hidden"
          dataset={dataset}
          imageSet={imageSet}
          otherMenuItems={
            <>
              <ImageFilter className={buttonClasses()} dataset={dataset} />

              <PulseGuiderRoot
                guiderKey={"phenosorter-stains-for-training"}
                position={{ corner: "top-left", offset: { x: 16, y: 0 } }}
                tooltipSide={"left"}
              >
                <LabMateSettings />
              </PulseGuiderRoot>
              <DeprecatedButton
                onClick={onToggleStainSettings}
                className="tw-h-full"
                variant="secondary"
              >
                <Sliders size={16} className="tw-mr-2" />
                Image
              </DeprecatedButton>
            </>
          }
          ref={refSampleViewer}
        />
      </div>
      <div
        className={cx(
          "tw-p-4 tw-border-l tw-min-w-[300px] tw-flex tw-flex-col tw-pt-8 tw-ml-4",
          !showVisualizationControls && "tw-hidden",
        )}
      >
        <BaseVisualizationControls
          palette={palette}
          stainSubset={stains}
          stainChannelIndices={stainChannelIndices}
          stainDisplayRanges={stainDisplayRanges}
        />
        <Strut size={10} />
        <RenderSizeSlider
          min={64}
          max={192}
          size={size}
          onChange={setSize}
          icons={[Grid, Square]}
        />
        <Strut size={20} />
        <CropSizeSlider
          size={cropSize}
          defaultSize={defaultCropSize}
          onChange={setCropSize}
        />
      </div>
    </div>
  );
}
