import cx from "classnames";
import { Ref, forwardRef, memo, useMemo } from "react";
import { CLUSTER_CARD_HEIGHT_PX } from "src/PhenoFinder/constants";
import MultiChannelView from "src/immunofluorescence/MultiChannelView";
import { CellSampleMetadata, DatasetId } from "src/types";
import { getCellMaskPropsForCrop } from "src/util/get-mask-props-for-crop";
import { Label, Subtitle } from "@spring/ui/typography";
import { ImageSet } from "../../imaging/types";
import { toNumericField } from "../../imaging/util";
import { getCellKey } from "./utils";

interface ClusterCardProps extends React.ComponentPropsWithoutRef<"button"> {
  id: string;
  name: string;
  dataset: DatasetId;
  imageSet: ImageSet | null;
  cells: CellSampleMetadata[];
  populationPercent: string;
  cropSize: number;
  width: number;
  highlighted?: boolean;
  selected?: boolean;
}

function BaseClusterCard(
  {
    id,
    name,
    dataset,
    imageSet,
    cells,
    populationPercent,
    cropSize,
    onClick,
    style,
    width,
    highlighted = false,
    selected = false,
  }: ClusterCardProps,
  ref: Ref<HTMLButtonElement>,
) {
  const numPreviews = useMemo(
    () =>
      Math.min(
        Math.max(Math.floor((width - 200) / (48 + 8)) + 1, 0),
        cells.length,
      ),
    [width, cells],
  );

  return (
    <button
      ref={ref}
      key={id}
      className={cx(
        "tw-absolute tw-rounded-md tw-px-md",
        "tw-border tw-transition-colors",
        highlighted || selected
          ? "tw-bg-primary-200 tw-border-primary-500 tw-border-dashed"
          : "tw-bg-white tw-border-slate-300",
        selected ? "tw-border-2" : "tw-border",
        "hover:tw-bg-primary-200 hover:tw-border-primary-500",
      )}
      style={{
        height: CLUSTER_CARD_HEIGHT_PX,
        width,
        ...style,
      }}
      onClick={onClick}
    >
      <div className="tw-flex tw-items-center tw-justify-between">
        <div
          className={cx("tw-w-[148px]", "tw-flex tw-flex-col tw-items-start")}
        >
          <Subtitle
            className={cx(
              "tw-w-full tw-text-left tw-overflow-hidden",
              "tw-whitespace-nowrap tw-text-ellipsis",
            )}
          >
            {name}
          </Subtitle>
          <Label className="tw-text-slate-500">{populationPercent}%</Label>
        </div>

        {/* Memoize to avoid updating if just the cluster name changes */}
        <ImagePreviews
          dataset={dataset}
          imageSet={imageSet}
          cells={cells}
          numPreviews={numPreviews}
          cropSize={cropSize}
        />
      </div>
    </button>
  );
}
export const ClusterCard = forwardRef(BaseClusterCard);

function BaseImagePreviews({
  dataset,
  imageSet,
  cells,
  numPreviews,
  cropSize,
}: {
  dataset: DatasetId;
  imageSet: ImageSet | null;
  cells: CellSampleMetadata[];
  numPreviews: number;
  cropSize: number;
}) {
  if (numPreviews === 0) {
    return <div />;
  }

  const cellForOverlay = cells[numPreviews - 1];
  const numMoreCells = cells.length - numPreviews;

  return (
    <div className="tw-flex tw-gap-x-xs">
      {cells.slice(0, numPreviews - 1).map((metadata) => {
        const index = {
          dataset,
          plate: metadata.plate,
          well: metadata.well,
          field: toNumericField(metadata.field),
          z: 0,
          t: 0,
        };

        const crop = {
          size: cropSize,
          location: { x: metadata.row, y: metadata.column },
        };

        return (
          <div key={getCellKey(metadata)} className="tw-relative">
            <MultiChannelView
              index={index}
              imageSet={imageSet}
              crop={crop}
              size={48}
              {...getCellMaskPropsForCrop(cropSize)}
            />
          </div>
        );
      })}

      {/* The last image might have an overlay on it, so we render it separately */}
      <div className="tw-relative">
        <MultiChannelView
          index={{
            dataset,
            plate: cellForOverlay.plate,
            well: cellForOverlay.well,
            field: toNumericField(cellForOverlay.field),
            t: 0,
            z: 0,
          }}
          imageSet={imageSet}
          crop={{
            size: cropSize,
            location: { x: cellForOverlay.row, y: cellForOverlay.column },
          }}
          key={getCellKey(cellForOverlay)}
          size={48}
          {...getCellMaskPropsForCrop(cropSize)}
        />
        {numMoreCells > 0 ? (
          <>
            {/* Overlay */}
            <div
              className={cx(
                "tw-absolute tw-left-0 tw-top-0",
                "tw-w-full tw-h-full",
                "tw-bg-slate-900 tw-opacity-60",
              )}
            />
            {/* Number of cells not shown */}
            <div
              className={cx(
                "tw-absolute tw-left-0 tw-top-0",
                "tw-w-full tw-h-full",
                "tw-flex tw-items-center tw-justify-center",
                "tw-text-white tw-text-sm",
              )}
            >
              +{numMoreCells}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
const ImagePreviews = memo(BaseImagePreviews);
