/**
 * Code for working with feature data as duckdb in-memory databases.
 *
 * This allows us to query feature data using SQL entirely on the client!
 */
import * as duckdb from "@duckdb/duckdb-wasm";
import { AsyncDuckDB } from "@duckdb/duckdb-wasm";
import duckdbEhWorker from "@duckdb/duckdb-wasm/dist/duckdb-browser-eh.worker.js?url";
import mvpWorker from "@duckdb/duckdb-wasm/dist/duckdb-browser-mvp.worker.js?url";
import duckdbWasmNext from "@duckdb/duckdb-wasm/dist/duckdb-eh.wasm?url";
import duckdbWasm from "@duckdb/duckdb-wasm/dist/duckdb-mvp.wasm?url";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ENV } from "src/env";
import {
  Failure,
  Fetchable,
  Success,
  TimestampedResult,
  TimestampedSuccess,
} from "@spring/core/result";
import { usePrevious } from "../hooks/utils";
import { MetadataColumnValue, PragmaQueryRecord } from "../types";
import { closeDatabase, exposeDatabase, recordQuery } from "./broadcast-db";

function enableLogging() {
  return ENV === "development";
}

// Utility that requires all the values inserted into a template to be strings
interface Validated {
  // We don't actually use/set this property, but it keeps arbitrary strings from
  // qualifying as ValidatedSQL
  _valid: true;
}

/**
 * Forms an SQL query string that has only used strings or numbers for interpolated
 * values.  This can be used to generate queries that can be used by functions that
 * require a query of type ValidatedSQL.
 *
 * The function is intended to be used like this:
 *
 * queryDBAsRecords(db, sql`SELECT * FROM some_table WHERE ${filter}')
 *
 * and will have a type error if filter isn't guaranteed to be a string or number
 */
export type ValidatedSQL = string & Validated;
export function sql(
  text: TemplateStringsArray,
  ...values: (string | number)[]
): ValidatedSQL {
  return text
    .flatMap((text, index) => {
      if (index >= values.length) {
        return text;
      }
      const value = values[index];
      // TODO(danlec): Sanitize the values at this point
      return [text, value];
    })
    .join("")
    .trim() as ValidatedSQL;
}

export function sqlUnion(queries: ValidatedSQL[]): ValidatedSQL {
  return queries.join(" UNION ") as ValidatedSQL;
}

export function sqlOr(queries: ValidatedSQL[]): ValidatedSQL {
  return `(${queries.join(" OR ")})` as ValidatedSQL;
}

export function sqlAnd(queries: ValidatedSQL[]): ValidatedSQL {
  return `(${queries.join(" AND ")})` as ValidatedSQL;
}

/**
 * Bundle selection allows this code to work on older browsers that don't
 * support wasm exception handling.
 *
 * TODO(colin): we don't need this, as all modern-enough browsers now support
 * exception handling. However, bundle selection also creats a pthreadWorker
 * instance, which I'm not sure how to get otherwise.
 */
const MANUAL_BUNDLES = {
  mvp: {
    mainModule: duckdbWasm,
    mainWorker: mvpWorker,
  },
  eh: {
    mainModule: duckdbWasmNext,
    mainWorker: duckdbEhWorker,
  },
};

const bundle = await duckdb.selectBundle(MANUAL_BUNDLES);

export type DB = AsyncDuckDB;

export const makeFeatureDb = async (
  blob: Blob,
  plate: string | null,
): Promise<DB> => {
  const { db, conn } = await createTypeSafeTableFromBlob(blob, "features");

  if (plate != null) {
    await conn.query(
      sql`ALTER TABLE features ADD COLUMN plate TEXT DEFAULT '${plate}'`,
    );
  }

  return db;
};

export const makeSampleMetadataDB = async (blob: Blob): Promise<DB> => {
  const { db, conn } = await createTypeSafeTableFromBlob(
    blob,
    "sample_metadata",
  );
  await conn.close();
  return db;
};

export const makeCutTreesDB = async (blob: Blob): Promise<DB> => {
  const { db, conn } = await createTypeSafeTableFromBlob(blob, "cut_trees");
  await conn.close();
  return db;
};

export const makeSearchIndexDB = async (blob: Blob): Promise<DB> => {
  const { db, conn } = await createTypeSafeTableFromBlob(blob, "search_index");
  await conn.close();
  return db;
};

export const makeUmapDB = async (blob: Blob): Promise<DB> => {
  const { db, conn } = await createTypeSafeTableFromBlob(blob, "umap");
  await conn.close();
  return db;
};

export const getBlobToDBConverter = (
  tableName: string,
): ((blob: Blob) => Promise<DB>) => {
  return async (blob: Blob) => {
    const { db, conn } = await createTypeSafeTableFromBlob(blob, tableName);
    await conn.close();
    return db;
  };
};

export const insertModelEvalTableDB = async (
  existingDB: DB,
  blob: Blob,
  tableName: string,
  dropIfExists: boolean = false,
): Promise<DB> => {
  if (dropIfExists) {
    await dropTable(existingDB, tableName);
  }
  const { db, conn } = await createTypeSafeTableFromBlob(
    blob,
    tableName,
    existingDB,
  );
  await conn.close();
  return db;
};

async function initializeDb(): Promise<duckdb.AsyncDuckDB> {
  // TODO(colin): lots of open questions here. Should we be sharing instances of
  // these for perf? (It's easier not to share the db instance to avoid having
  // to manage a global namespace.) Could we use the same logger and worker for
  // multiple db instances?
  if (bundle.mainWorker === null) {
    throw new Error("Failed to select DB bundle.");
  }
  const worker = new Worker(bundle.mainWorker);
  const logger = new duckdb.VoidLogger();
  const db = new duckdb.AsyncDuckDB(logger, worker);
  await db.instantiate(bundle.mainModule, bundle.pthreadWorker);
  return db;
}

/**
 * Creates a table in a new or existing DuckDB database.
 */
export const createTypeSafeTableFromBlob = async (
  blob: Blob,
  tableName: string,
  // Will create a new DB if not provided.
  db?: DB,
): Promise<{
  db: DB;
  conn: duckdb.AsyncDuckDBConnection;
}> => {
  const instantiatedNewDB = !db;
  if (!db) {
    db = await initializeDb();
  }

  const conn = await db.connect();

  try {
    await conn.insertArrowFromIPCStream(
      new Uint8Array(await blob.arrayBuffer()),
      { name: tableName, create: true },
    );

    // Get the schema and convert BigInt columns to doubles
    const schemaResult = await conn.query(
      sql`PRAGMA table_info('${tableName}')`,
    );
    const schemaArray: PragmaQueryRecord[] = schemaResult.toArray();
    const bigintColumns = schemaArray.filter(
      (row: any) => row.type === "BIGINT",
    );
    if (bigintColumns.length > 0) {
      const alterStatement = bigintColumns
        .map(
          (row) =>
            sql`ALTER TABLE ${tableName} ALTER COLUMN "${row.name}" TYPE DOUBLE;`,
        )
        .join("");
      await conn.query(alterStatement);
    }
  } catch (e) {
    await conn.close();
    throw e;
  }

  if (ENV === "development" && instantiatedNewDB) {
    // TODO(benkomalo): we can probably do a better job naming these if we're going
    // to consolidate to using fewer DBs with multiple tables.
    exposeDatabase(db, tableName);
  }

  return {
    db,
    conn,
  };
};

/**
 * Registers a parquet file in a new or existing DuckDB database.
 */
export const addParquetBlobToDb = async (
  blob: Blob,
  tableName: string,
  // Will create a new DB if not provided.
  db?: DB,
): Promise<AsyncDuckDB> => {
  const instantiatedNewDB = !db;
  if (!db) {
    db = await initializeDb();
  }

  db.registerFileBuffer(tableName, new Uint8Array(await blob.arrayBuffer()));

  if (ENV === "development" && instantiatedNewDB) {
    // TODO(benkomalo): we can probably do a better job naming these if we're going
    // to consolidate to using fewer DBs with multiple tables.
    exposeDatabase(db, tableName);
  }

  return db;
};

export function terminate(db: DB) {
  db.terminate();

  if (ENV === "development") {
    closeDatabase(db);
  }
}

export type DefaultRecord = { [key: string]: unknown };
export type RecordsArray<T = DefaultRecord> = T[];
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type ColumnsArray = Map<string, unknown[]>;

export const queryDBAsRecords = async <Record = DefaultRecord>(
  db: DB,
  query: ValidatedSQL,
): Promise<RecordsArray<Record>> => {
  if (ENV === "development") {
    recordQuery(db, query);
  }
  const conn = await db.connect();
  const output = [];
  try {
    const result = await conn.query(query);
    for (const row of result) {
      output.push(row.toJSON());
    }
  } finally {
    await conn.close();
  }
  return output as Record[];
};

const dropTable = async (db: DB, tableName: string): Promise<void> => {
  const conn = await db.connect();
  try {
    await conn.query(sql`DROP TABLE IF EXISTS ${tableName}`);
  } finally {
    await conn.close();
  }
};

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const queryDBAsColumns = async (
  db: DB,
  query: ValidatedSQL,
): Promise<ColumnsArray> => {
  const conn = await db.connect();
  const output: ColumnsArray = new Map<string, unknown[]>();
  try {
    const result = await conn.query(query);
    let first = true;
    for (const row of result) {
      const rowJSON = row.toJSON();
      if (first) {
        for (const key of Object.keys(rowJSON)) {
          output.set(key, []);
        }
      }
      Object.keys(rowJSON).forEach((key: string) => {
        output.get(key)?.push(rowJSON[key]);
      });
      first = false;
    }
  } finally {
    await conn.close();
  }
  return output;
};

export const getTableColumns = async (
  db: DB,
  table: string,
  filter?: (row: any) => boolean,
): Promise<string[]> => {
  const conn = await db.connect();
  const output: string[] = [];
  try {
    const result = await conn.query(
      table.endsWith(".parquet")
        ? sql`DESCRIBE SELECT * FROM ${table}`
        : sql`DESCRIBE ${table}`,
    );
    for (const row of result) {
      const rowJSON = row.toJSON();
      if (!filter || filter(rowJSON)) {
        output.push(rowJSON["column_name"]);
      }
    }
  } finally {
    await conn.close();
  }

  return output;
};

/**
 * Copy one table from a source DB to a destination DB.
 *
 * Modifies the destination database directly.
 *
 * If a table of the same name is already present, noops.
 */
export const copyTableAcrossDB = async (
  dbSource: DB,
  dbDestination: DB,
  table: string,
): Promise<DB> => {
  const connDest = await dbDestination.connect();
  let connSource = null;
  try {
    const tables = await connDest.query(sql`SHOW TABLES`);
    const hasExisting =
      Array.from(tables).findIndex(({ name }) => name === table) !== -1;
    if (hasExisting) {
      return dbDestination;
    }

    connSource = await dbSource.connect();
    const result = await connSource.query(sql`SELECT * FROM ${table}`);
    await connDest.insertArrowTable(result, { name: table, create: true });
    return dbDestination;
  } finally {
    await connDest.close();
    if (connSource) {
      await connSource.close();
    }
  }
};

/**
 * Create a new table in the DB given a SQL query. Can be useful if there are common
 * joins across tables that you want to only do once to then use in multiple queries.
 * If a table of specified  name already exists then this noops.
 */
export const createTableFromQuery = async (
  dbSource: DB,
  table: string,
  query: string,
): Promise<DB> => {
  const connSource = await dbSource.connect();
  try {
    const tables = await connSource.query(sql`SHOW TABLES`);
    const hasExisting =
      Array.from(tables).findIndex(({ name }) => name === table) !== -1;
    if (hasExisting) {
      return dbSource;
    }
    const result = await connSource.query(sql`${query}`);
    await connSource.insertArrowTable(result, { name: table, create: true });
    return dbSource;
  } finally {
    await connSource.close();
  }
};

/**
 * Emit cached values from an async fetcher even as new versions are pending.
 *
 * This is akin to a stale-while-revalidate pattern, where clients can change the
 * fetcher function, and while the new fetcher is fetching data, the client can still
 * get emissions of the old/stale data instead of seeing a loading state.
 * This is useful to avoid flickers or intermittent loading states.
 */
export function useThrottledCacheState<T>(
  key: string,
  fetcherFn: () => Promise<T | undefined>,
  // TODO(benkomalo): add a timeout threshold so which will invalidate the internal
  // cached state and emit undefined.
): CachedResult<Fetchable<T>> {
  const [state, setState] = useState<TimestampedResult<T> | undefined>(
    undefined,
  );
  const lastKey = useRef<string | null>(null);

  // The fetcher is assumed to be tied to the key and only change when the key changes.
  // (don't require strict stability).
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetcher = useCallback(fetcherFn, [key]);

  useEffect(() => {
    memoizedFetcher()
      .then((value) => {
        lastKey.current = key;
        setState(value ? TimestampedSuccess.of(value) : undefined);
      })
      .catch((error) => {
        lastKey.current = key;
        setState(Failure.of(error));
      });
  }, [key, memoizedFetcher]);

  // TODO(benkomalo): if this is "stale", we should check the timestamp against
  // the current one and emit undefined if it's "too stale".
  // Note: useMemo() because the wrapping into a CachedResult could otherwise create
  // a new object everytime, making it unfriendly to be used in hook deps.
  return useMemo(
    () => mapFetchableToCachedResult(lastKey.current !== key, state),
    [key, state],
  );
}

export type CachedResult<T> = {
  cached: boolean;
  result: T;
};

function mapFetchableToCachedResult<T>(
  cached: boolean,
  underlying: Fetchable<T>,
): CachedResult<Fetchable<T>> {
  if (underlying?.successful) {
    return {
      cached,
      result: Success.of(underlying.value),
    };
  } else if (underlying) {
    return {
      cached,
      result: Failure.of(underlying.error),
    };
  } else {
    return {
      cached,
      result: undefined,
    };
  }
}

export const useCacheableQueryAsRecords = <T = DefaultRecord>(
  db: DB | undefined | null,
  query: ValidatedSQL | undefined | null,
): CachedResult<Fetchable<T[]>> => {
  const hasDb = !!db;
  return useThrottledCacheState<T[]>(`${query};;${hasDb}`, () => {
    if (db && query) {
      return queryDBAsRecords<T>(db, query);
    } else {
      return Promise.resolve(undefined);
    }
  });
};

// React hook version of `queryDBAsRecords`.
// TODO(colin): this can actually be typed instead and return an arrow table
// with known key/value types?
// DB is required
export function useQueryAsRecords(
  db: undefined | null,
  query: ValidatedSQL | undefined,
): undefined;
// query is required is required
export function useQueryAsRecords(
  db: DB | undefined | null,
  query: undefined,
): undefined;
export function useQueryAsRecords<T = DefaultRecord>(
  db: DB | undefined | null,
  query: ValidatedSQL | undefined,
): Fetchable<T[]>;
export function useQueryAsRecords<T = DefaultRecord>(
  db: DB | undefined | null,
  query: ValidatedSQL | undefined,
): Fetchable<T[]> {
  const [records, setRecords] = useState<Fetchable<T[]>>(undefined);
  const prevQuery = usePrevious<string | undefined>(query);
  const prevDB = useRef<DB | null>(null);

  useEffect(() => {
    if (db && query !== undefined) {
      queryDBAsRecords<T>(db, query)
        .then((recs) => {
          prevDB.current = db;
          setRecords(Success.of(recs));
        })
        .catch((e) => {
          prevDB.current = db;
          setRecords(Failure.of(e));
        });
    }
  }, [db, query, setRecords]);

  // If the DB or the query changed, this means any downstream features are
  // potentially out of sync with a new user selection. Clear out back to the
  // loading state.
  return prevQuery !== query || (prevDB.current && prevDB.current !== db)
    ? undefined
    : records;
}

export function useQueryAsRecordsValue<T = DefaultRecord, U = T[]>(
  db: DB | undefined | null,
  query: ValidatedSQL | undefined,
  defaultValue: U,
): T[] | U {
  const records = useQueryAsRecords<T>(db, query);

  return useMemo(
    () => (records?.successful ? records.value : defaultValue),
    [defaultValue, records],
  );
}

export const useFetchableQueryAsRecords = <T = DefaultRecord>(
  maybeDB: Fetchable<DB>,
  query: ValidatedSQL,
): Fetchable<T[]> => {
  const [records, setRecords] = useState<Fetchable<T[]>>(undefined);
  useEffect(() => {
    // If the DB or the query changed, this means any downstream features are
    // potentially out of sync with a new user selection. Clear out back to the
    // loading state.
    setRecords(undefined);
    if (maybeDB?.successful === false) {
      if (enableLogging()) {
        console.error(maybeDB.error);
      }
      setRecords(Failure.of(maybeDB.error));
    }

    maybeDB?.map((db) => {
      queryDBAsRecords<T>(db, query)
        .then((recs) => setRecords(Success.of(recs)))
        .catch((e) => {
          if (enableLogging()) {
            console.error(e);
          }
          setRecords(Failure.of(e));
        });
    });
  }, [maybeDB, query, setRecords]);
  return records;
};

// Mostly from https://stackoverflow.com/questions/7744912/making-a-javascript-string-sql-friendly
export function sanitizedTextValue(value: string): string {
  const escaped = value.replace(/[\n\r"'\\%]/g, (c) => {
    switch (c) {
      case "'":
        return "''";
      case "\n":
        return "\\n";
      case "\r":
        return "\\r";
      case '"':
      case "\\":
        return "\\" + c;
      default:
        return c;
    }
  });
  return `'${escaped}'`;
}

export function sanitizedColumn(name: string): string {
  return `"${name.replace(/"/g, '""')}"`;
}

export function sanitizedMetadataValue(
  value: Exclude<MetadataColumnValue, null>,
): string {
  switch (typeof value) {
    case "string":
      return sanitizedTextValue(value);
    case "number":
      return String(value);
    case "boolean":
      return String(value).toUpperCase();
  }
}

export function columnMatchesValueClause(
  column: string,
  value: MetadataColumnValue,
) {
  if (value === null) {
    return sql`${sanitizedColumn(column)} IS NULL`;
  } else {
    return sql`${sanitizedColumn(column)} = ${sanitizedMetadataValue(value)}`;
  }
}
