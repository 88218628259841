/**
 * Table to render metadata for a well.
 */
import cx from "classnames";
import { useMemo } from "react";
import { ExternalLink } from "react-feather";
import { useDatasetSampleMetadata } from "../hooks/datasets";
import {
  DatasetId,
  MetadataColumnValue,
  UntypedSampleMetadataRow,
  isTimeSeriesMetadata,
} from "../types";
import { getUniqueValuesByColumn } from "../util/dataset-util";

type Props = {
  dataset: DatasetId;
  plate: string;
  well: string;
};

const IGNORE_KEYS = [
  // These are usually visible in the rest of the UI and the user already navigated
  // to it.
  "plate",
  "well",
  "field",

  // We don't actually support anything interesting 3D-like in our app, so this is
  // often a dummy value.
  "z_layer",

  // This is mostly for internal use.
  "palette_number",
];

const extractValuesForwell = (
  sampleMetadata: UntypedSampleMetadataRow[],
  well: string,
): [column: string, value: MetadataColumnValue][] => {
  const values = getUniqueValuesByColumn(
    sampleMetadata.filter((row) => row.well === well),
  );
  const isTimeseries = isTimeSeriesMetadata(sampleMetadata[0]);
  // TODO(michaelwiest): Add in filtering logic depending on which timepoint
  //  we're viewing.
  return (
    Object.entries(values)
      // Only accept values that don't vary per well unless it is temporal.
      .filter(([, values]) => isTimeseries || values.size === 1)
      .filter(([key]) => !IGNORE_KEYS.includes(key))
      .map(([key, values]) => [key, [...values][0]])
  );
};

function formatValue(key: string, value: MetadataColumnValue) {
  if (key === "pert_iname" && value && value !== "DMSO") {
    // HACK(benkomalo): for the JUMP-CP demo, link out to their repurposing app
    // for their specific column.
    // We should figure out a more general way to encode linkable metadata columns
    // in the data schema so this can generalize.
    const clueUrl = `https://clue.io/repurposing-app?q=Name:${encodeURIComponent(
      value,
    )}`;
    return (
      <a href={clueUrl} target="_blank">
        {value} <ExternalLink size={16} className={"tw-inline"} />
      </a>
    );
  } else {
    return value === null ? "<null>" : value;
  }
}

export default function WellMetadataTable({ dataset, plate, well }: Props) {
  const plateMetadata = useDatasetSampleMetadata({ dataset, plate });
  const filtered = useMemo(() => {
    if (!plateMetadata?.successful) {
      return null;
    }
    return extractValuesForwell(plateMetadata.value.sampleMetadata, well);
  }, [plateMetadata, well]);

  if (filtered) {
    return (
      <div className={"tw-overflow-auto"}>
        <table className={"tw-text-sm"}>
          <tbody>
            {filtered.map(([key, value], i) => {
              return (
                <tr key={key}>
                  <td
                    className={cx(
                      "tw-truncate tw-text-gray-500 tw-max-w-[132px]",
                      "tw-p-1 tw-pl-0",
                      i === 0 && "tw-pt-0",
                    )}
                    title={key}
                  >
                    {key}
                  </td>
                  <td className={"tw-whitespace-nowrap tw-p-1 tw-pr-0"}>
                    {formatValue(key, value)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  } else {
    return null;
  }
}
