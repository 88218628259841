import {
  ChannelIndex,
  ChannelMap,
  DisplayRange,
  DisplayRanges,
  DisplaySettings,
  VisualizationState,
} from "./types";

export const DISPLAY_RANGE: DisplayRange = [0, 65535];

export const DEFAULT_DISPLAY_SETTING: DisplaySettings = {
  boundaryRange: DISPLAY_RANGE,
  activeRange: DISPLAY_RANGE,
  autoRange: DISPLAY_RANGE,
  numImagesProcessed: 0,
  isLoadedRange: false,
};

export const EMPTY_CHANNEL_MAP: ChannelMap = [
  null,
  null,
  null,
  null,
  null,
  null,
  null,
];

export const EMPTY_DISPLAY_RANGES: DisplayRanges = [
  null,
  null,
  null,
  null,
  null,
  null,
  null,
];

export const DEFAULT_VISUALIZATION_STATE: VisualizationState = {
  displaySettings: [
    DEFAULT_DISPLAY_SETTING,
    DEFAULT_DISPLAY_SETTING,
    DEFAULT_DISPLAY_SETTING,
    DEFAULT_DISPLAY_SETTING,
    DEFAULT_DISPLAY_SETTING,
    DEFAULT_DISPLAY_SETTING,
    DEFAULT_DISPLAY_SETTING,
  ],
  channelLoaded: [false, false, false, false, false, false, false],
  showChannel: [true, true, true, true, true, true, true],
  lockChannel: [false, false, false, false, false, false, false],
  channelMap: EMPTY_CHANNEL_MAP,
  renderMode: "uniform",
};

// We don't want to continuously update display settings when we fetch new data, in case the user
// made unlocked adjustments. Since we can't determine when a "first load" completes, we set a smallish
// max number of images to process instead.
export const MAX_NUM_IMAGES_TO_PROCESS_FOR_DISPLAY_SETTINGS = 15;

// An enumeration of all possible channel index values.
export const RED_INDEX: ChannelIndex = 0;
export const GREEN_INDEX: ChannelIndex = 1;
export const BLUE_INDEX: ChannelIndex = 2;
export const CYAN_INDEX: ChannelIndex = 3;
export const MAGENTA_INDEX: ChannelIndex = 4;
export const YELLOW_INDEX: ChannelIndex = 5;
export const WHITE_INDEX: ChannelIndex = 6;
export const ALL_CHANNEL_INDEXES: ChannelIndex[] = [
  RED_INDEX,
  GREEN_INDEX,
  BLUE_INDEX,
  CYAN_INDEX,
  MAGENTA_INDEX,
  YELLOW_INDEX,
  WHITE_INDEX,
];

// The maximum number of image channels that we'll ever attempt to load.
// TODO(colin): we'll filter this down afterwards, so it's not a big deal to just choose an arbitrarily large number,
// but we should perhaps figure out the possible number of stains we might need to fetch for a given experiment from the
// palette metadata instead.
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const MAX_SUPPORTED_STAINS = 10;
