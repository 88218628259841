/**
 * Component used to space elements in a Flexbox-based layout.
 */
import { Component } from "react";

export default class Strut extends Component<{
  size: (number | string) | null;
}> {
  static defaultProps = {
    size: "0.5rem",
  };

  render() {
    return <div style={flexBasis(this.props.size) as any} />;
  }
}

const flexBasis = (size: number | string | null) => {
  return {
    MsFlexBasis: size,
    MsFlexPreferredSize: size,
    WebkitFlexBasis: size,
    flexBasis: size,
    flexShrink: 0,
  };
};
