import * as Sentry from "@sentry/react";
import cx from "classnames";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ChevronRight, HelpCircle } from "react-feather";
import { AnalyzeView } from "src/FeatureSetManagementPage/views";
import { DatasetId } from "src/types";
import { Tooltip } from "@spring/ui/Tooltip";
import { PHENOFINDER_FEATURE_SET_NAME } from "../FeatureSetManagementPage/MultiFeature/LabMate/constants";
import { useFeatureFlag } from "../Workspace/feature-flags";
import { useHoverDetector } from "../hooks/events";
import EmbeddingDetails from "./EmbeddingDetails";
import MeasurementsDetails from "./MeasurementsDetails";
import SelectAllCheckbox from "./SelectAllCheckbox";
import { useLevel } from "./hooks";
import {
  EmbeddingSelection,
  FeatureSetPlateGroup,
  FeatureSetSelection,
  FeatureSetType,
  NormalFeatureSetSelection,
} from "./types";
import {
  FeatureSetsByType,
  baseNameFromName,
  cleanPredictionName,
  excludeHiddenFeatures,
  groupFeaturesByPrefix,
  maybePrettifyEmbedding,
} from "./utils";

const TYPE_DISPLAY_NAME: { [key in FeatureSetType]: string } = {
  embedding: "AI-powered embeddings",
  numerical: "Measurements",
  prediction: "Phenotypic predictions",
};

type TopLevelListItem =
  // Embedding features are collated so that the different channels are collapsed into
  // a single entry at the top level (even though each channel is a separate FeatureSet)
  | {
      type: "embedding";
      name: string;
      displayName: string;
      underlying: FeatureSetPlateGroup[];
    }
  // ...other FeatureSets are not grouped like this.
  | {
      type: Exclude<FeatureSetType, "embedding">;
      name: string;
      underlying: FeatureSetPlateGroup;
    };

type TopLevelEntries = { [type in FeatureSetType]: TopLevelListItem[] };

function sortStringsPhenoFinderFirst(
  a: TopLevelListItem,
  b: TopLevelListItem,
): number {
  return (
    Number(
      b["name"].includes(encodeURIComponent(PHENOFINDER_FEATURE_SET_NAME)),
    ) -
      Number(
        a["name"].includes(encodeURIComponent(PHENOFINDER_FEATURE_SET_NAME)),
      ) || compareNamedItem(a, b)
  );
}

/**
 * Given the raw FeatureSet's grouped by their type, collate them once more for display.
 */
function convertToTopLevelEntries(
  features: FeatureSetsByType,
  alwaysShowEmbeddingDetails: boolean,
) {
  const results: TopLevelEntries = {
    embedding: [],
    numerical: [],
    prediction: [],
  };
  Object.entries(features).forEach(([featureType, plateGroups]) => {
    if (featureType === "embedding") {
      // Embeddings need to coalesce the same kinds of embeddings from every channel
      // into one logical listing.
      const resultsByPrefix = groupFeaturesByPrefix(plateGroups);

      // Most customers will have a single embedding type we select for them.
      // If that's the case, abstract away some of the underlying complexities of
      // the embeddings by choosing a hardcoded name. If they have multiple,
      // be conservative and just use the underlying name so that we can
      // disambiguate them.
      const allEmbeddings = Object.keys(resultsByPrefix);
      results["embedding"] = Object.entries(resultsByPrefix).map(
        ([prefix, matchedGroups]) => ({
          type: "embedding",
          name: prefix,
          displayName: alwaysShowEmbeddingDetails
            ? prefix
            : maybePrettifyEmbedding(prefix, allEmbeddings),
          underlying: matchedGroups,
        }),
      );
    } else {
      // TypeScript should know this from the if above, but alas...
      const typedType = featureType as Exclude<FeatureSetType, "embedding">;
      results[typedType] = excludeHiddenFeatures(plateGroups).map(
        (plateGroup) => ({
          type: typedType,
          name: plateGroup.name,
          underlying: plateGroup,
        }),
      );
    }
  });
  return results;
}

function findTopLevelEntryForSelection(
  selection: FeatureSetSelection,
  entries: TopLevelEntries,
): TopLevelListItem {
  const nameToMatch =
    selection.type === "embedding"
      ? baseNameFromName(selection.names[0])
      : selection.name;

  for (const [type, subEntries] of Object.entries(entries)) {
    if (selection.type === type) {
      for (const item of subEntries) {
        if (item.name === nameToMatch) {
          return item;
        }
      }
    }
  }
  throw new Error(`Unexpectedly missing entry: ${selection}`);
}

function TypeHeader({
  featureType,
  allSelected,
  multi,
  disabledReason,
  onSelectAll,
  onDeselectAll,
}: {
  featureType: FeatureSetType;
  allSelected: boolean;
  multi: boolean;
  disabledReason?: string;
  onSelectAll: () => void;
  onDeselectAll: () => void;
}) {
  const disabled = disabledReason !== undefined;

  return (
    <div
      className={cx(
        "tw-text-slate-400 tw-ml-8 tw-mr-5 tw-my-4",
        "tw-flex tw-flex-row tw-items-center tw-gap-sm",
      )}
    >
      <div className={"tw-flex-1 tw-uppercase tw-flex tw-items-center"}>
        {TYPE_DISPLAY_NAME[featureType as FeatureSetType]}
      </div>
      {multi && !disabled && (
        <div
          className={cx(
            "tw-text-sm",
            !allSelected && "tw-opacity-0 group-hover:tw-opacity-100",
          )}
        >
          <SelectAllCheckbox
            value={allSelected}
            onChange={(selected) =>
              selected ? onSelectAll() : onDeselectAll()
            }
          />
        </div>
      )}
      {disabledReason && (
        <Tooltip
          className="tw-max-w-[400px]"
          contents={disabledReason}
          side="top"
        >
          <HelpCircle className="tw-text-slate-500" size={16} />
        </Tooltip>
      )}
    </div>
  );
}

function compareNamedItem<T extends { name: string }>(a: T, b: T): number {
  const { name: nameA } = a;
  const { name: nameB } = b;
  if (nameA > nameB) {
    return 1;
  } else if (nameA < nameB) {
    return -1;
  } else {
    return 0;
  }
}

function TopLevelFeatureListItem({
  dataset,
  isHovered,
  isSelected,
  item,
  onHoveredItemChange,
  onReportDisabled,
  containerRef,
  selectedView,
  disabled,
}: {
  dataset: DatasetId;
  isHovered: boolean;
  isSelected: boolean;
  item: TopLevelListItem;
  onHoveredItemChange: (item: TopLevelListItem | null) => void;
  onReportDisabled?: (
    item: TopLevelListItem,
    disabledReason: string | null,
  ) => void;
  containerRef: React.MutableRefObject<HTMLElement | null>;
  selectedView: AnalyzeView | undefined;
  disabled?: boolean;
}) {
  const firstFeatureSet = (
    Array.isArray(item.underlying) ? item.underlying[0] : item.underlying
  ).featureSets[0];

  const requiresCellLevel =
    !disabled && selectedView?.requiresCellLevelFeature === true;

  const level = useLevel({
    dataset,
    firstFeatureSet,
    skip: !requiresCellLevel,
  });

  const disabledForLevel =
    requiresCellLevel && level !== undefined && level !== "cell";

  useEffect(() => {
    onReportDisabled?.(
      item,
      disabledForLevel ? "they are not single cell measurements" : null,
    );
  }, [disabledForLevel, item, onReportDisabled]);

  const onMouseMove = useHoverDetector({
    containerRef,
    onHover: useCallback(
      () => onHoveredItemChange(item),
      [item, onHoveredItemChange],
    ),
  });
  return (
    <div
      className={cx(
        "tw-flex tw-items-center tw-pl-8 tw-pr-4 tw-py-4 tw-cursor-pointer",
        "tw-border-t last:tw-border-b tw-overflow-x-hidden",
        isHovered && "tw-bg-purple-100",
        isSelected && "tw-text-purple",
        disabled || disabledForLevel
          ? "tw-text-slate-300"
          : "tw-text-slate-600",
      )}
      onMouseMove={isHovered ? undefined : onMouseMove}
    >
      <div className={"tw-flex-1 tw-truncate"} title={item.name}>
        {item.type === "embedding"
          ? item.displayName
          : decodeURIComponent(cleanPredictionName(item.name))}
      </div>
      <div className={cx(isSelected ? "tw-text-purple" : "tw-text-slate-500")}>
        <ChevronRight size={16} />
      </div>
    </div>
  );
}

function TopLevelFeatureList({
  dataset,
  entries,
  selectedItems,
  hoveredItem,
  selections,
  multi,
  selectedView,
  onHoveredItemChange,
  onSelectAll,
  onDeselectAll,
}: {
  dataset: DatasetId;
  entries: TopLevelEntries;
  selectedItems: TopLevelListItem[];
  hoveredItem: TopLevelListItem | null;
  selections: FeatureSetSelection[];
  multi: boolean;
  selectedView: AnalyzeView | undefined;
  onHoveredItemChange: (item: TopLevelListItem | null) => void;
  onSelectAll: (featureType: FeatureSetType, item: TopLevelListItem[]) => void;
  onDeselectAll: (
    featureType: FeatureSetType,
    item: TopLevelListItem[],
  ) => void;
}) {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [disabledItems, setDisabledItems] = useState<
    Record<string, string | null>
  >({});

  const handleReportDisabled = useCallback(
    (item: TopLevelListItem, disabledReason: string | null) => {
      setDisabledItems((disabledItems) => ({
        ...disabledItems,
        [item.name]: disabledReason,
      }));
    },
    [],
  );

  return (
    <div
      className={"tw-py-4 tw-flex tw-flex-col tw-space-y-4"}
      ref={containerRef}
    >
      {Object.entries(entries).map(([featureType, items]) => {
        if (!items.length) {
          return null;
        }
        items.sort(sortStringsPhenoFinderFirst);

        const itemDisabledReason =
          Object.entries(disabledItems).find(
            ([name, disabledReason]) =>
              disabledReason !== null &&
              items.some((item) => item.name === name),
          )?.[1] ?? undefined;

        const allDisabledReason =
          selectedView &&
          featureType === "embedding" &&
          !selectedView.supportsEmbeddings
            ? `Embeddings aren't supported for ${selectedView.name}.  Please select a different measurement.`
            : undefined;

        const someDisabledReason =
          selectedView &&
          itemDisabledReason &&
          `Some of these measurements are not available for ${selectedView.name} because ${itemDisabledReason}.`;

        return (
          <div key={featureType} className="tw-group">
            <TypeHeader
              featureType={featureType as FeatureSetType}
              disabledReason={allDisabledReason ?? someDisabledReason}
              allSelected={items.every((item) =>
                item.type === "embedding"
                  ? item.underlying.every(({ name }) =>
                      selections.some(
                        (selection) =>
                          selection.type === "embedding" &&
                          selection.names.includes(name),
                      ),
                    )
                  : selections.some(
                      (selection) =>
                        selection.type !== "embedding" &&
                        selection.type === item.type &&
                        selection.name === item.name &&
                        selection.includesAllColumns,
                    ),
              )}
              multi={multi}
              onSelectAll={() =>
                onSelectAll(featureType as FeatureSetType, items)
              }
              onDeselectAll={() =>
                onDeselectAll(featureType as FeatureSetType, items)
              }
            />
            <>
              {items.map((item) => {
                const { name } = item;
                const isHovered =
                  hoveredItem !== null && hoveredItem.name === name;
                if (isHovered && hoveredItem.type !== featureType) {
                  Sentry.captureMessage(
                    `Collision in FeatureSet name with diff types: ${name} ` +
                      `${hoveredItem.type} ${featureType}`,
                  );
                }
                const isSelected = selectedItems.some(
                  (item) => item.name === name && item.type === featureType,
                );
                return (
                  <TopLevelFeatureListItem
                    dataset={dataset}
                    key={name}
                    isHovered={isHovered}
                    isSelected={isSelected}
                    item={item}
                    onHoveredItemChange={onHoveredItemChange}
                    onReportDisabled={handleReportDisabled}
                    containerRef={containerRef}
                    disabled={allDisabledReason !== undefined}
                    selectedView={selectedView}
                  />
                );
              })}
            </>
          </div>
        );
      })}
    </div>
  );
}

export default function FeatureSelectorMenuContents({
  className,
  dataset,
  features,
  selections,
  selectedView,
  multi,
  onChangeSelections,
}: {
  className?: string;
  dataset: DatasetId;
  features: FeatureSetsByType;
  selections: FeatureSetSelection[];
  multi: boolean;
  selectedView?: AnalyzeView;
  onChangeSelections: (selection: FeatureSetSelection[]) => void;
}) {
  const alwaysShowEmbeddingDetails = useFeatureFlag(
    "always-show-full-embedding-name",
  );

  const topLevelEntries = useMemo(
    () => convertToTopLevelEntries(features, alwaysShowEmbeddingDetails),
    [features, alwaysShowEmbeddingDetails],
  );
  const selectedItems = useMemo(
    () =>
      selections.map((selection) =>
        findTopLevelEntryForSelection(selection, topLevelEntries),
      ),
    [selections, topLevelEntries],
  );

  // Update the selection details for a single "top level" item.
  // This handles merging the mutation of the columns/sub-item selection in the actively
  // hovered/active menu with the other selection values of other features at the
  // top level.
  const updateSingleSelection = useCallback(
    (topLevelItem: TopLevelListItem, selection: FeatureSetSelection | null) => {
      if (!multi) {
        if (selection) {
          onChangeSelections([selection]);
        }
        return;
      }

      if (!selection) {
        switch (topLevelItem.type) {
          case "embedding":
            onChangeSelections(
              selections.filter(
                (s) =>
                  s.type !== "embedding" ||
                  baseNameFromName(s.names[0]) !== topLevelItem.name,
              ),
            );
            break;
          case "numerical":
          case "prediction":
            onChangeSelections(
              selections.filter(
                (s) => s.type === "embedding" || s.name !== topLevelItem.name,
              ),
            );
            break;
        }
        return;
      }

      switch (selection.type) {
        case "embedding":
          onChangeSelections([
            ...selections.filter(
              (s) =>
                s.type !== "embedding" ||
                baseNameFromName(s.names[0]) !== topLevelItem.name,
            ),
            selection,
          ]);
          break;

        case "numerical":
        case "prediction":
          onChangeSelections([
            ...selections.filter(
              (s) => s.type === "embedding" || s.name !== selection.name,
            ),
            selection,
          ]);
          break;
      }
    },
    [multi, onChangeSelections, selections],
  );

  const [hoveredItem, setHoveredItem] = useState<TopLevelListItem | null>(
    selectedItems.length ? selectedItems[0] : null,
  );
  return (
    <div className={cx(className, "tw-flex tw-flex-col")}>
      <div className={"tw-flex tw-flex-1 tw-overflow-y-hidden"}>
        <div className={"tw-overflow-y-auto tw-flex-[2]"}>
          <TopLevelFeatureList
            dataset={dataset}
            entries={topLevelEntries}
            selectedItems={selectedItems}
            hoveredItem={hoveredItem}
            selections={multi ? selections : []}
            multi={multi}
            selectedView={selectedView}
            onHoveredItemChange={(item) => {
              setHoveredItem(item);
            }}
            onSelectAll={(featureType, items) => {
              if (featureType === "embedding") {
                onChangeSelections([
                  ...selections.filter((s) => s.type !== "embedding"),
                  ...items
                    .filter(
                      (
                        item,
                      ): item is {
                        type: "embedding";
                        name: string;
                        displayName: string;
                        underlying: FeatureSetPlateGroup[];
                      } => item.type === "embedding",
                    )
                    .map(
                      (item): EmbeddingSelection => ({
                        type: "embedding",
                        names: item.underlying.map(
                          (plateGroup) => plateGroup.name,
                        ),
                      }),
                    ),
                ]);
              } else {
                onChangeSelections([
                  ...selections.filter((s) => s.type !== featureType),
                  ...items.map(
                    (item): NormalFeatureSetSelection => ({
                      type: featureType,
                      name: item.name,
                      featureSets: [],
                      columns: [],
                      includesAllColumns: true,
                    }),
                  ),
                ]);
              }
            }}
            onDeselectAll={(featureType) => {
              onChangeSelections(
                selections.filter((s) => s.type !== featureType),
              );
            }}
          />
        </div>
        <div
          className={cx(
            "tw-border-l tw-overflow-y-auto tw-overflow-x-hidden",
            "tw-flex-[3]",
          )}
        >
          {hoveredItem && hoveredItem.type === "embedding" && (
            <EmbeddingDetails
              key={hoveredItem.name}
              name={hoveredItem.name}
              displayName={hoveredItem.displayName}
              featureSets={hoveredItem.underlying as FeatureSetPlateGroup[]}
              selection={
                multi
                  ? (selections.find(
                      (s) =>
                        s.type === "embedding" &&
                        baseNameFromName(s.names[0]) === hoveredItem.name,
                    ) as EmbeddingSelection | undefined) || null
                  : null
              }
              multi={multi}
              onChangeSelection={(updated) =>
                updateSingleSelection(hoveredItem, updated)
              }
              disabled={selectedView && !selectedView.supportsEmbeddings}
            />
          )}
          {hoveredItem && hoveredItem.type !== "embedding" && (
            <MeasurementsDetails
              featureType={hoveredItem.type}
              dataset={dataset}
              features={hoveredItem.underlying as FeatureSetPlateGroup}
              selection={
                multi
                  ? (selections.find(
                      (s) =>
                        s.type !== "embedding" && s.name === hoveredItem.name,
                    ) as NormalFeatureSetSelection | undefined) || null
                  : null
              }
              selectedView={selectedView}
              onChangeSelection={(updated) =>
                updateSingleSelection(hoveredItem, updated)
              }
              multi={multi}
            />
          )}
        </div>
      </div>
    </div>
  );
}
