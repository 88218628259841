import { serializeToUrlParam } from "src/Control/FilterSelector/utils";
import { getParentRoute } from "src/util/route";
import { GROUP_PARAM_DELIM } from "./constants";
import { Group } from "./types";

export function convertFilterToGroupParam(group: Group) {
  return (
    group.id +
    GROUP_PARAM_DELIM +
    serializeToUrlParam(group.filterSet) +
    (group.label ? GROUP_PARAM_DELIM + group.label : "")
  );
}

export function getViewImagesUrlForGroups(
  currentPathname: string,
  groups: Array<Group>,
): string {
  const searchParams = new URLSearchParams();

  groups.forEach((group) => {
    searchParams.append("serializedGroups", convertFilterToGroupParam(group));
  });

  const parentRoute = getParentRoute(currentPathname);
  return `${parentRoute}/images?${searchParams}`;
}
