import { css } from "aphrodite";
import { useRef, useState } from "react";
import { Overlay } from "react-overlays";
import DownCaret from "../../../icons/DownCaret.svg";
import { shared } from "../../../megamap-styles";
import DropdownList from "../../Dropdown/DropdownList";
import { OverlayBody } from "../OverlayParent";
import { Operator } from "../operations/filter-by";

const OPERATORS: { id: Operator; title: string }[] = Object.values(
  Operator,
).map((value) => ({
  id: value,
  title: value,
}));

export function OperatorSelector({
  index,
  operator,
  onChange,
}: {
  index: number;
  operator: Operator;
  onChange: (operator: Operator) => void;
}) {
  const triggerRef = useRef(null);
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div
      className={
        "tw-flex tw-items-center tw-justify-end tw-flex-auto tw-px-0.5 tw-min-w-[50px]"
      }
      ref={containerRef}
    >
      {index === 0 ? (
        "Where"
      ) : index === 1 ? (
        <>
          {" "}
          <span
            className={
              "tw-cursor-pointer tw-flex tw-flex-auto tw-items-center tw-justify-end tw-pl-0.5 tw-pr-0.5 tw-rounded " +
              css(shared.darken1Hover, shared.textBlueFocus)
            }
            onClick={() => setIsOpen(true)}
            ref={triggerRef}
            aria-expanded={isOpen}
            role={"button"}
            tabIndex={0}
          >
            <div
              className={
                "tw-cursor-pointer tw-flex tw-items-center " +
                css(shared.linkQuiet)
              }
            >
              {operator}
              <DownCaret className={"tw-ml-0.5"} />
            </div>
          </span>
          <Overlay
            placement={"bottom-start"}
            show={isOpen}
            target={triggerRef}
            onHide={() => setIsOpen(false)}
            rootClose
          >
            {({ props }) => (
              <OverlayBody {...props} minWidth={56}>
                <DropdownList
                  items={OPERATORS}
                  defaultValue={operator}
                  onClick={(item) => {
                    onChange(item.id);
                    setIsOpen(false);
                  }}
                />
              </OverlayBody>
            )}
          </Overlay>
        </>
      ) : (
        <span className="tw-mr-md">{operator}</span>
      )}
    </div>
  );
}
