import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { downloadRecordsAsCSV } from "src/Common/DownloadLink";
import { Fetchable } from "@spring/core/result";
import Loader, { Center } from "../../Common/Loader";
import { FilterSqlClause } from "../../Control/FilterSelector/types";
import { ExpandableSection } from "../../MegaMap/Controls/ConfigurationSidebar/ExpandableSection";
import { useFeaturesWithMetadata } from "../../hooks/features";
import { inferLikelyNegativeControl } from "../../immunofluorescence/metadata";
import { QS, useTypedQueryParams } from "../../routing";
import {
  FeatureLevel,
  MetadataColumnValue,
  UntypedWellSampleMetadataRow,
} from "../../types";
import { inferInterestingColumns } from "../../util/dataset-util";
import { columnComparator } from "../../util/sorting";
import {
  DB,
  ValidatedSQL,
  queryDBAsRecords,
  sql,
  sqlAnd,
  useQueryAsRecords,
} from "../../util/sql";
import LeftNavSectionTitle from "../MultiFeature/LeftNavSectionTitle";
import { useFeatureSetManagementContext } from "../context";
import { FeatureSetInfo, isPlateBasedFeatureSetId } from "../types";
import GraphBodyWrapper from "./components/GraphBody";
import { GroupVisualization } from "./components/GroupVisualization";
import { StatisticalSettings } from "./components/StatisticalSettings";
import { VisualizationSettings } from "./components/VisualizationSettings";
import {
  GroupSelectionKey,
  MetadataAndValue,
  StatisticalCorrectionType,
  StatisticalTestType,
  XAxisSort,
  XAxisSortDirection,
} from "./types";
import {
  getStatisticalCorrectionOrDefault,
  getStatisticalTestOrDefault,
} from "./utils";

async function queryAggregatedData(
  featuresWithMetadataDB: Fetchable<DB>,
  column: string,
  filterSerialized: FilterSqlClause,
  indexColumns: string[],
): Promise<MetadataAndValue[]> {
  const firstMetadataRecordQuery = featuresWithMetadataDB?.successful
    ? await queryDBAsRecords<UntypedWellSampleMetadataRow>(
        featuresWithMetadataDB.value,
        sql`SELECT * FROM sample_metadata LIMIT 1`,
      )
    : [];

  const aggregationQueryString = createJoinMetadataAndFeatureQuery(
    firstMetadataRecordQuery[0],
    column,
    filterSerialized,
    indexColumns,
  );

  return featuresWithMetadataDB?.successful
    ? await queryDBAsRecords<MetadataAndValue>(
        featuresWithMetadataDB.value,
        aggregationQueryString,
      )
    : [];
}

export default function ComparisonsView({
  featuresDB,
  column,
  featureSetInfo,
  level,
}: {
  featuresDB: DB;
  column: string;
  featureSetInfo: FeatureSetInfo;
  level: FeatureLevel;
}) {
  const {
    metadataDB,
    metadataSchema,
    filterSerialized,
    setOnDownload,
    featureFilter,
  } = useFeatureSetManagementContext();

  const [groupQueryParam] = useTypedQueryParams({
    selectedGroup: (value) => value || null,
  });

  const { selectedGroup } = groupQueryParam;

  const [queryParams, setQueryParams] = useTypedQueryParams({
    selectedGroup: (value) => value || null,
    selectedSubgroup: (value) => value || null,
    selectedCompareTo: (value) => {
      // Note: the compareTo dropdown uses the <null> sentinel for the label _and_ value since using
      // null directly is not supported by select inputs.
      return value === undefined || selectedGroup === null || !metadataSchema // Waiting on the default group or schema to initialize
        ? undefined
        : value === "<null>"
          ? null
          : decodeParamForSqlType(value, metadataSchema[selectedGroup]);
    },
    showPoints: (value) => value !== "false",
    statisticalCorrectionType: QS.enum(
      StatisticalCorrectionType,
      StatisticalCorrectionType.Bonferroni,
    ),
    statisticalTestType: QS.enum(
      StatisticalTestType,
      StatisticalTestType.MannWhitney,
    ),
    userAddedGroups: {
      defaultValue: [],
      fromArray: (value) => value,
    },
    userAddedSubgroups: {
      defaultValue: [],
      fromArray: (value) => value,
    },
    yAxisMin: QS.number(undefined),
    yAxisMax: QS.number(undefined),
    xAxisSort: QS.enum(XAxisSort, XAxisSort.Median),
    xAxisSortDirection: QS.enum(XAxisSortDirection, XAxisSortDirection.Desc),
  });

  const {
    selectedSubgroup,
    selectedCompareTo,
    showPoints,
    userAddedGroups,
    userAddedSubgroups,
    statisticalCorrectionType,
    statisticalTestType,
  } = queryParams;
  const statisticalCorrection = getStatisticalCorrectionOrDefault(
    statisticalCorrectionType,
  );
  const statisticalTest = getStatisticalTestOrDefault(statisticalTestType);

  const [hasQueryError, setHasQueryError] = useState<boolean>(false);
  const [graphData, setGraphData] = useState<MetadataAndValue[]>([]);

  const featuresWithMetadataDB = useFeaturesWithMetadata(
    featuresDB,
    metadataDB,
  );

  if (featureFilter != null) {
    // TODO(colin): apply any metadata filter here on the client since it didn't
    // get applied server-side. (Or pass the metadata filter to the server and apply it there?)
  }

  // TODO(benkomalo): HACK HACK HACK -- this needs to be generalized.
  const indexColumns = useMemo(
    () =>
      isPlateBasedFeatureSetId(featureSetInfo) ? ["plate", "well"] : ["slide"],
    [featureSetInfo],
  );
  useEffect(() => {
    async function createGraphDataAsync() {
      try {
        const aggregatedData = await queryAggregatedData(
          featuresWithMetadataDB,
          column,
          filterSerialized,
          indexColumns,
        );

        setGraphData(aggregatedData);
      } catch (e) {
        setHasQueryError(true);
        Sentry.captureException(e);
        console.error(`Failed to query metadata: ${e}`);
      }
    }

    if (featuresWithMetadataDB) {
      createGraphDataAsync();
    }
  }, [featuresWithMetadataDB, column, filterSerialized, indexColumns]);

  const compareToOptionsResults = useQueryAsRecords<{
    group_value: MetadataColumnValue;
  }>(
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    featuresWithMetadataDB?.successful ? featuresWithMetadataDB?.value : null,
    selectedGroup
      ? getCompareToOptionsQueryString(
          selectedGroup,
          filterSerialized,
          column,
          indexColumns,
        )
      : undefined,
  );

  const compareToOptions = useMemo(() => {
    if (!compareToOptionsResults?.successful) {
      return [];
    }

    const rawValues = compareToOptionsResults.value;
    return rawValues.map(({ group_value }) => ({
      label: group_value === null ? "<null>" : group_value + "",
      value: group_value === null ? "<null>" : group_value,
    }));
  }, [compareToOptionsResults]);

  const handleDownload = useCallback(() => {
    if (!featuresWithMetadataDB) {
      return new Promise<void>((resolve, reject) =>
        reject(new Error("Started download prior to data ready")),
      );
    }
    return new Promise<void>((resolve, reject) => {
      const filename = `${featureSetInfo.name}-${column}.csv`;
      queryAggregatedData(
        featuresWithMetadataDB,
        column,
        filterSerialized,
        indexColumns,
      ).then(
        (data) => {
          downloadRecordsAsCSV(
            // Rename the actual column from "value" back to the real column name.
            data.map((record) => {
              const { value, ...rest } = record;
              return {
                ...rest,
                [column]: value,
              };
            }),
            filename,
          );
          resolve();
        },
        (error) => reject(error),
      );
    });
  }, [
    featuresWithMetadataDB,
    featureSetInfo.name,
    column,
    filterSerialized,
    indexColumns,
  ]);

  useEffect(() => {
    setOnDownload(handleDownload);
    return () => setOnDownload(null);
  }, [handleDownload, setOnDownload]);

  const handleChangeGrouping = useCallback(
    (groupType: GroupSelectionKey, val: string | null) => {
      const basePatch = {
        ...queryParams,
        [groupType]: val,
        userAddedSubgroups: [],
      };

      let patch = basePatch;
      if (groupType === "selectedGroup") {
        patch = {
          ...basePatch,
          selectedSubgroup: null,
          // Always reset the compareTo here. This will kick off the get compareTo options query and
          // show the loading indicator when re-rendering large datasets.
          selectedCompareTo: null,
          userAddedGroups: [],
        };
      }
      setQueryParams(patch);
    },
    [queryParams, setQueryParams],
  );

  const handleChangeCompareTo = useCallback(
    (newCompareTo: string | null) => {
      setQueryParams({
        ...queryParams,
        selectedCompareTo:
          newCompareTo === null ? selectedCompareTo : newCompareTo,
      });
    },
    [queryParams, selectedCompareTo, setQueryParams],
  );

  const handleChangeStatisticalCorrection = useCallback(
    (statisticalCorrectionType: StatisticalCorrectionType) => {
      setQueryParams({ statisticalCorrectionType });
    },
    [setQueryParams],
  );

  const handleChangeStatisticalTest = useCallback(
    (statisticalTestType: StatisticalTestType) => {
      setQueryParams({ statisticalTestType });
    },
    [setQueryParams],
  );

  const interestingColumns = useMemo(
    () => (graphData.length ? inferInterestingColumns(graphData) : []),
    [graphData],
  );

  const groupOptions = useMemo(
    () =>
      graphData.length ? getGroupOptions(graphData, interestingColumns) : [],
    [graphData, interestingColumns],
  );

  // TODO(benkomalo): this triggers multiple times on dataset filter modifications,
  // since the dataset filter changes the URL and setQueryParams changes whenever that
  // happens, even though nothing should be affected in this component. Fortunately,
  // it's a fast check, and nothing really happens, since all of the checks should fail,
  // but it's a ticking time bomb.
  useEffect(() => {
    // If any piece of state evaluated below is invalid then the query param doesn't make sense or
    // the data filter changed and the group|subgroup|compareTo doesn't exist in the metadata.
    // For the compare to this will additionally set a new value when the group changes.
    // Note: a missing groupBy or compareTo is also a bad state and will receive default treatment.

    const isInvalidGroup =
      groupOptions.length > 0 &&
      selectedGroup &&
      !groupOptions.some(({ value }) => value === selectedGroup);

    const isInvalidSubgroup =
      groupOptions.length > 0 &&
      selectedSubgroup &&
      !groupOptions.some(({ value }) => value === selectedSubgroup);

    const isInvalidCompareTo =
      compareToOptions.length > 0 &&
      !compareToOptions.some(
        ({ value }) =>
          value === selectedCompareTo ||
          (value === "<null>" && selectedCompareTo === null),
      );

    if (isInvalidGroup || (groupOptions.length > 0 && !selectedGroup)) {
      setQueryParams({
        ...queryParams,
        selectedGroup: getDefaultGroupOption(groupOptions).value,
        selectedSubgroup: null,
        // We can't trust the compareTo if the group is changing.
        selectedCompareTo: null,
      });
    } else if (isInvalidSubgroup) {
      setQueryParams({
        ...queryParams,
        selectedSubgroup: null,
      });
      // No need to reset until the group is valid
    } else if (
      isInvalidCompareTo ||
      (compareToOptions.length > 0 && selectedCompareTo === undefined)
    ) {
      setQueryParams({
        ...queryParams,
        selectedCompareTo: getDefaultCompareTo(compareToOptions),
      });
    }
  }, [
    selectedGroup,
    selectedSubgroup,
    selectedCompareTo,
    groupOptions,
    compareToOptions,
    queryParams,
    setQueryParams,
  ]);

  const handleAddUserGroup = useCallback(
    (newGroup) => {
      setQueryParams({
        ...queryParams,
        userAddedGroups: [...userAddedGroups, newGroup],
      });
    },
    [userAddedGroups, queryParams, setQueryParams],
  );

  const handleAddUserSubgroup = useCallback(
    (newGroup) => {
      setQueryParams({
        ...queryParams,
        userAddedSubgroups: [...userAddedSubgroups, newGroup],
      });
    },
    [userAddedSubgroups, queryParams, setQueryParams],
  );

  if (
    (featuresWithMetadataDB && !featuresWithMetadataDB.successful) ||
    hasQueryError
  ) {
    return (
      <div
        className={
          "tw-min-w-full tw-h-[240px] tw-flex tw-items-center tw-justify-center"
        }
      >
        Oops. Something went wrong. Please refresh and try again.
      </div>
    );
  }

  const mismatchedCompareTo =
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    graphData?.length > 0 &&
    !!selectedGroup &&
    !graphData.some((o) => o[selectedGroup] === selectedCompareTo);
  const isLoading = !featuresWithMetadataDB?.value || mismatchedCompareTo;
  // TODO: force a groupby selection and we are failing on bigInt parsing now (replicate?)
  return (
    <div className="tw-flex">
      <div className="tw-basis-96 tw-shrink-0 tw-border-r tw-flex tw-flex-col tw-overflow-hidden tw-bg-slate-50">
        <div className="tw-pt-md tw-pl-lg tw-relative tw-inline-block tw-w-full">
          <LeftNavSectionTitle>View Options</LeftNavSectionTitle>
        </div>
        <div className={"tw-pl-sm tw-pt-sm"}>
          <GroupVisualization
            groupOptions={groupOptions}
            selectedGroup={selectedGroup}
            selectedSubgroup={selectedSubgroup}
            onChangeGrouping={handleChangeGrouping}
            compareToOptions={compareToOptions}
            selectedCompareTo={
              // Don't show the compareTo while loading since it could be mismatched with the in-progress UI update or
              // render before the group selection.
              isLoading
                ? null
                : selectedCompareTo === null
                  ? "<null>"
                  : selectedCompareTo
            }
            onChangeCompareTo={handleChangeCompareTo}
            featureName={featureSetInfo.name}
            column={column}
            isLoading={isLoading}
          />
        </div>
        <ExpandableSection
          key={"Settings"}
          section={"Settings"}
          title={"Settings"}
          defaultOpen={false}
        >
          <VisualizationSettings
            showPoints={showPoints}
            onToggleShowPoints={() =>
              setQueryParams({
                ...queryParams,
                showPoints: !showPoints,
              })
            }
            settings={queryParams}
            isLoading={isLoading}
            onUpdateSettings={setQueryParams}
          />
        </ExpandableSection>

        <div className="tw-pt-md tw-pl-lg tw-relative tw-inline-block tw-w-full">
          <LeftNavSectionTitle>Statistical Options</LeftNavSectionTitle>
        </div>
        <StatisticalSettings
          statisticalCorrection={statisticalCorrection}
          statisticalTest={statisticalTest}
          onChangeStatisticalCorrection={handleChangeStatisticalCorrection}
          onChangeStatisticalTest={handleChangeStatisticalTest}
          isLoading={isLoading}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-h-[calc(100vh-theme(spacing.global-nav-height))] tw-overflow-x-hidden">
        {isLoading ? (
          <Center extraClasses="tw-w-full tw-h-full">
            <Loader />
          </Center>
        ) : (
          selectedGroup &&
          selectedCompareTo !== undefined &&
          graphData.length > 0 &&
          graphData.some((o) => o[selectedGroup] === selectedCompareTo) && (
            <div className={"tw-flex-1 tw-pt-md tw-pl-lg"}>
              <GraphBodyWrapper
                data={graphData}
                groupKey={selectedGroup}
                subgroupKey={selectedSubgroup}
                compareTo={selectedCompareTo}
                showPoints={showPoints}
                featureName={featureSetInfo.name}
                featureLevel={level}
                column={column}
                interestingColumns={interestingColumns}
                userAddedGroups={userAddedGroups}
                userAddedSubgroups={userAddedSubgroups}
                onAddUserGroup={handleAddUserGroup}
                onAddUserSubgroup={handleAddUserSubgroup}
                yAxisMin={queryParams.yAxisMin}
                yAxisMax={queryParams.yAxisMax}
                xAxisSort={queryParams.xAxisSort}
                xAxisSortDirection={queryParams.xAxisSortDirection}
                statisticalCorrection={statisticalCorrection}
                statisticalTest={statisticalTest}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
}

function getDefaultCompareTo(
  compareToOptions: { label: string; value: MetadataColumnValue }[],
): MetadataColumnValue {
  return inferLikelyNegativeControl(compareToOptions.map(({ value }) => value));
}

function createJoinMetadataAndFeatureQuery(
  sampleMetadata: UntypedWellSampleMetadataRow,
  featureColumn: string,
  sqlFilter: FilterSqlClause,
  indexColumns: string[],
) {
  const metadataAlias = "sm";
  const featuresAlias = "f";

  const selectColumns = Object.keys(sampleMetadata)
    .filter((key) => key !== "field")
    .map((key) => `${metadataAlias}."${key}"`)
    .join(", ");

  const joinConditions = sqlAnd(
    indexColumns.map(
      (indexColumn) =>
        sql`${metadataAlias}.${indexColumn} = ${featuresAlias}.${indexColumn}`,
    ),
  );
  // Use an inner query to only apply the data filter to the metadata table (avoid column collisions)
  return sql`
    SELECT 
      ${selectColumns},
      ${featuresAlias}."${featureColumn}" as value,
      random() AS jitter_value
    FROM 
      (SELECT * FROM sample_metadata WHERE ${
        sqlFilter || "TRUE"
      }) ${metadataAlias}  
    JOIN
       features ${featuresAlias}
    ON 
      ${joinConditions}
    WHERE ${featuresAlias}."${featureColumn}" IS NOT NULL
  `;
}

/**
 * Get unique values of the selectedGroup column, ordered by replicate count descending.
 */
function getCompareToOptionsQueryString(
  selectedGroup: string,
  filterSerialized: FilterSqlClause,
  featureColumn: string,
  indexColumns: string[],
): ValidatedSQL {
  // Use an inner query to only apply the data filter to the metadata table (avoid column collisions)
  const joinConditions = sqlAnd(
    indexColumns.map(
      (indexColumn) => sql`f.${indexColumn} = filtered_sm.${indexColumn}`,
    ),
  );
  return sql`
    SELECT filtered_sm."${selectedGroup}" as group_value, COUNT(1) as n
    FROM (
      SELECT *
      FROM sample_metadata sm
      WHERE ${filterSerialized}
    ) filtered_sm
    JOIN features f ON ${joinConditions}
    WHERE f."${featureColumn}" IS NOT NULL
    GROUP BY filtered_sm."${selectedGroup}"
    ORDER BY n DESC, group_value ASC
  `;
}

function getGroupOptions(
  metadata: UntypedWellSampleMetadataRow[],
  interestingColumns: string[],
): { value: string; label: string }[] {
  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return Object.keys(metadata?.[0] || {})
    .filter(
      (k) =>
        k !== "value" &&
        k !== "jitter_value" &&
        interestingColumns.some((c) => c === k),
    )
    .sort(columnComparator)
    .map((k) => ({
      value: k,
      label: k,
    }));
}

// TODO(davidsharff): consolidate with MegaMap's heuristic?
function getDefaultGroupOption(
  groupOptions: { value: string; label: string }[],
) {
  return (
    groupOptions.find(({ value }) => value === "treatment_name") ||
    groupOptions.find(({ value }) => value === "compound_name") ||
    groupOptions.find(({ value }) => value === "treatment_id") ||
    groupOptions[0]
  );
}

function decodeParamForSqlType(value: string, sqlType: string) {
  // Safe-guarding the case but it should already be upper if it came from a DuckDB schema inspection.
  switch (sqlType.toUpperCase()) {
    case "CHAR":
    case "VARCHAR":
    case "TEXT":
      return value;
    case "INT":
    case "INTEGER":
    case "BIGINT":
    case "TINYINT":
    case "SMALLINT":
    case "MEDIUMINT":
    case "DECIMAL":
    case "NUMERIC":
    case "FLOAT":
    case "DOUBLE":
    case "REAL": {
      const ret = Number(value);
      if (isNaN(ret)) {
        // Don't return a malformed value. Let the logic default selection logic kick in instead.
        return undefined;
      }

      return ret;
    }
    case "BOOLEAN":
      return value === "true";
    default:
      throw new Error(`Unsupported SQL type: ${sqlType}`);
  }
}
