import { useMemo } from "react";
import { List, X } from "react-feather";
import { PopoverMenu } from "src/Common/PopoverMenu";
import { Select } from "@spring/ui/Select";
import { Item } from "@spring/ui/Select/types";

/**
 * Choose a list of columns to group the rows in a table by
 */
export function GroupBySelector({
  value,
  onChange,
  columns,
}: {
  value: string[];
  onChange: (newValue: string[]) => void;
  columns: string[];
}) {
  const items = useMemo(
    () =>
      columns.map(
        (column): Item<string> => ({
          value: column,
          disabled: value.includes(column),
        }),
      ),
    [columns, value],
  );
  return (
    <PopoverMenu
      trigger={
        <div className="tw-flex tw-flex-row tw-gap-sm tw-items-center">
          <List size={20} />{" "}
          {value.length > 0 ? `Grouping by ${value.join(", ")}` : "Group"}
        </div>
      }
      className="tw-min-w-[200px] tw-p-md"
    >
      <div className="tw-flex tw-flex-col">
        {value.map((column) => (
          <div
            key={column}
            className="tw-p-sm tw-flex tw-flex-row tw-items-center"
          >
            <div className="tw-flex-1">{column}</div>
            <button
              className="tw-p-xs"
              onClick={() =>
                onChange(
                  value.filter((includedColumn) => includedColumn !== column),
                )
              }
            >
              <X size={12} />
            </button>
          </div>
        ))}
        <Select
          items={items}
          onChange={(valueToAppend) => onChange([...value, valueToAppend])}
          placeholder="Add..."
          searchable
        />
      </div>
    </PopoverMenu>
  );
}
