import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FullScreenContainer } from "./Common/FullScreenContainer";
import { trackEvent } from "./analytics";

export default function PageNotFound() {
  const { pathname } = useLocation();

  useEffect(() => {
    trackEvent({ id: "Land on 404 page", pathname });
  }, [pathname]);

  return (
    <FullScreenContainer center>
      <div className={"tw-text-lg"}>
        Sorry, The page you're looking for doesn't exist.
      </div>
      <a className="tw-text-purple tw-mt-4" href={"/"}>
        Go Home
      </a>
    </FullScreenContainer>
  );
}
