import cx from "classnames";
import pluralize from "pluralize";
import { ReactNode, useMemo } from "react";
import { ChevronDown } from "react-feather";
import Close from "src/icons/Close.svg";
import {
  EmbeddingSelection,
  FeatureSetSelection,
  NormalFeatureSetSelection,
} from "./types";
import {
  FeatureSetsByType,
  baseNameFromName,
  groupFeaturesByPrefix,
  maybePrettifyEmbedding,
  stainFromName,
} from "./utils";

function Pill({
  children,
  title,
  truncate,
  onRemove,
}: {
  children: ReactNode;
  title?: string;
  truncate?: boolean;
  onRemove?: () => void;
}) {
  return (
    <div
      className={cx(
        "tw-relative tw-inline-flex tw-items-center",
        "tw-border tw-bg-purple-100 tw-border-purple tw-text-purple",
        "tw-py-0.5 tw-px-2 tw-mr-1 tw-rounded-md tw-text-sm",
        "tw-select-none",
      )}
      title={title}
    >
      <div
        className={cx(
          "tw-overflow-hidden",
          "tw-whitespace-nowrap",
          // Make room for the "X" button if present.
          onRemove && "tw-mr-4",
          truncate && "tw-truncate tw-max-w-[220px]",
        )}
      >
        {children}
      </div>
      {onRemove && (
        <button
          className={
            "tw-absolute tw-top-0 tw-right-2 tw-flex tw-items-center tw-h-full tw-text-gray-400"
          }
          onClick={(e) => {
            e.stopPropagation();
            onRemove();
          }}
          title={"Remove"}
        >
          <Close />
        </button>
      )}
    </div>
  );
}

export function SelectedEmbeddingInfo({
  selection,
  allEmbeddingPrefixes,
  onRemove,
}: {
  selection: EmbeddingSelection;
  allEmbeddingPrefixes: string[];
  onRemove?: () => void;
}) {
  const first = selection.names[0];
  const baseName = baseNameFromName(first);
  const displayName = maybePrettifyEmbedding(baseName, allEmbeddingPrefixes);
  let text;
  if (selection.names.length > 1) {
    text = `${displayName} (${pluralize(
      "channel",
      selection.names.length,
      true,
    )})`;
  } else {
    const stain = stainFromName(first);
    text = `${displayName} – ${stain}`;
  }

  return (
    <Pill truncate title={text} onRemove={onRemove}>
      {text}
    </Pill>
  );
}

function formatNamedColumns(
  baseName: string,
  columns: string[],
  includesAllColumns: boolean,
): string {
  if (columns.length > 1) {
    return `${baseName} (${pluralize("value", columns.length, true)})`;
  } else if (includesAllColumns || columns.length === 0) {
    // This case assumes a top level feature set selection, where all columns are
    // selected e.g. nuclei or images.
    return `${baseName} (all columns)`;
  } else {
    // We just have one column
    return `${baseName} – ${columns[0]}`;
  }
}

export function SelectedMeasurementInfo({
  selection,
  onRemove,
}: {
  selection: NormalFeatureSetSelection;
  onRemove?: () => void;
}) {
  const { name, columns, includesAllColumns } = selection;
  const displayName = decodeURIComponent(name);

  const text = formatNamedColumns(displayName, columns, includesAllColumns);
  return (
    <Pill truncate title={text} onRemove={onRemove}>
      {text}
    </Pill>
  );
}

type RenderMode = "compact" | "full";

/**
 * Renders the selections for a feature selector as "pills".
 *
 * Can be rendered in one of two modes:
 *  - "compact" - where the container truncates the pills and scrolls when it's out of
 *    space. This is used for the closed/collapsed state of the feature selector.
 *  - "full" - where the container wraps and ensures all pills are fully visible
 */
export default function CollapsedFeatureSelectorInfo({
  allFeatures,
  selections,
  renderMode,
  placeholder,
  multi,
  onRemoveSelection,
}: {
  allFeatures: FeatureSetsByType;
  selections: FeatureSetSelection[];
  renderMode: RenderMode;
  placeholder?: string;
  multi: boolean;
  onRemoveSelection?: (selection: FeatureSetSelection) => void;
}) {
  const allEmbeddingPrefixes = useMemo(() => {
    return Object.keys(groupFeaturesByPrefix(allFeatures["embedding"]));
  }, [allFeatures]);

  const isCompactMode = renderMode === "compact";
  const isFullMode = renderMode === "full";

  return (
    <div
      className={cx(
        "tw-flex tw-items-center tw-relative tw-h-full tw-py-1 tw-px-4 tw-mx-[1px]",
        // TODO(benkomalo): #ccc manually specified to mirror our
        //  react-select-plus borders.
        "tw-border tw-rounded",
        isCompactMode && "hover:tw-border-gray-300 tw-border-[#ccc]",
        isFullMode && "tw-border-[#fff]",
      )}
    >
      <div
        className={cx(
          "tw-flex tw-items-center",
          isCompactMode && "tw-overflow-x-auto",
          isFullMode &&
            // NOTE(danlec): The 96px is meant to enough space to fit three rows of
            // pills plus a little but more so we can see that the area will scroll
            // vertically
            "tw-flex-wrap tw-max-h-[96px] tw-overflow-y-auto tw-overflow-hidden",
        )}
      >
        <div
          className={cx(
            "tw-flex tw-flex-row",
            // Make room for the chevron when in compact mode.
            isCompactMode && "tw-mr-6",
            // Make the pills wrap when in full mode.
            isFullMode && "tw-flex-wrap tw-gap-y-1",
          )}
        >
          {selections.length === 0 && (
            <div
              className={cx(
                "tw-text-slate-400",
                // Make this the same height as a <Pill>
                "tw-leading-[26px]",
              )}
            >
              {placeholder ||
                (multi ? "Select measurements" : "Select a measurement")}
            </div>
          )}
          {selections.length > 0 && (
            <>
              {selections.map((selection) => {
                const key = keyForSelection(selection);
                const onRemove =
                  onRemoveSelection && (() => onRemoveSelection(selection));
                switch (selection.type) {
                  case "embedding":
                    return (
                      <SelectedEmbeddingInfo
                        key={key}
                        selection={selection}
                        onRemove={onRemove}
                        allEmbeddingPrefixes={allEmbeddingPrefixes}
                      />
                    );
                  case "numerical":
                  case "prediction":
                    return (
                      <SelectedMeasurementInfo
                        key={key}
                        selection={selection}
                        onRemove={onRemove}
                      />
                    );
                }
              })}
            </>
          )}
        </div>
      </div>
      {isCompactMode && (
        <div
          className={cx(
            "tw-absolute tw-top-0 tw-right-0 tw-px-2 tw-rounded-md tw-bg-white tw-h-full tw-flex tw-items-center tw-text-gray-400 hover:tw-text-gray-500",
            "tw-text-sm",
          )}
        >
          <ChevronDown size={16} />
        </div>
      )}
    </div>
  );
}

function keyForSelection(selection: FeatureSetSelection) {
  switch (selection.type) {
    case "embedding":
      return `embedding:${selection.names.join(":")}`;
    case "numerical":
    case "prediction":
      return `${selection.type}:${selection.name}`;
  }
}
