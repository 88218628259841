/**
 * Utilities for prefetching multiple images in batches from the server.
 *
 * When fetching lots of small images, we become limited by the browser's limit
 * on the number of concurrent requests to the same domain. Up to a point, by
 * batching N images, we can get an almost N times speedup in image fetching.
 */
import * as queryString from "query-string";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useActiveWorkspace } from "src/Workspace/hooks";
import { mergeTraceInfo } from "src/hooks/fetch";
import { WorkspaceId } from "src/types";
import { TraceInfo, TracingContext } from "src/util/tracing";
import { RequestContext } from "./context";
import { CropContext } from "./cropping";
import {
  DisplayRange,
  ImageFetchItem,
  ImageFetchResult,
  ImageFetchSpec,
  ImageSet,
} from "./types";

type Cache = Map<string, ImageFetchResult>;
type Refs = Map<string, Set<number>>;
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type Ctx = { cache: Cache; refs: Refs };
export type CtxWithHash = { ctx: Ctx; hash: string };

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function stringifyContext(ctx: Ctx): string {
  const obj = {
    cache: [...ctx.cache.entries()].map(([k, v]) => [k, v.type]),
    refs: [...ctx.refs.entries()].map(([k, v]) => [k, [...v].sort()]),
  };
  return JSON.stringify(obj);
}

function toKey(
  item: ImageFetchItem,
  imageSet: ImageSet,
  resizeTo: number | null,
): string {
  return [
    item.index.dataset,
    item.index.plate,
    item.index.well,
    item.index.field,
    item.index.t,
    item.index.z,
    item.index.c,
    imageSet.size,
    imageSet.processing,
    imageSet.subset,
    item.cropOptions?.location.x,
    item.cropOptions?.location.y,
    resizeTo,
  ].join("::");
}

// React context to avoid possibly problematic nesting of prefetch components.
// Only the outermost context will actually fetch any images.
export const PrefetchContext = createContext<CtxWithHash | undefined>(
  undefined,
);

/**
 * Wait for the provided image to become available in cache.
 */
export async function waitForImage(
  index: ImageFetchItem,
  imageSet: ImageSet,
  resizeTo: number | null,
  ctx: Ctx,
  waitedOnUndefined?: boolean | undefined,
  waitedOnProgress?: boolean | undefined,
): Promise<ImageFetchResult> {
  const im = getImage(index, imageSet, resizeTo, ctx);
  if (im === undefined) {
    if (!waitedOnUndefined) {
      // This is an unfortunate hack because the effect hook in the Prefetch
      // component will not run until we get back to the event loop, but the
      // rendering of the child image components can happen before we get back
      // there. Waiting one time on a 0-length timeout is sufficient to get to
      // the effect hook, which then puts an in-progress value in the cache.
      // (Note that we can't just immediately put an in-progress value in the
      // cache because we need a promise that we can wait on for the fetch to
      // complete.)
      await new Promise((resolve) => setTimeout(resolve, 0));
      return await waitForImage(
        index,
        imageSet,
        resizeTo,
        ctx,
        true,
        waitedOnProgress,
      );
    }
    throw new Error(
      `Image ${toKey(index, imageSet, resizeTo)} was not prefetched`,
    );
  }
  if (im.type === "in-progress") {
    // Unlike above, this should be correctly resolved to the response after
    // im.promise resolves, so we don't need this "one time only" check for
    // correct functioning. However, several times during development I
    // introduced bugs that turned this into an infinite loop without this
    // check, so I left this in as a defensive measure against future bugs.
    if (!waitedOnProgress) {
      await im.promise;
      return await waitForImage(
        index,
        imageSet,
        resizeTo,
        ctx,
        waitedOnUndefined,
        true,
      );
    }
    throw new Error("Still in progress after fetch promise resolved");
  }
  return im;
}

async function fetchImagesWithMultipleRequests(
  workspaceId: WorkspaceId,
  images: ImageFetchSpec,
  ctx: Ctx,
  forceRender: () => void,
  hostUrl: string,
  requestInit: RequestInit,
  abort: AbortController,
): Promise<void> {
  await Promise.all(
    images.indexes.map(async (im) => {
      const url =
        `${hostUrl}/api/v0/workspace/${workspaceId}/fetch_image?` +
        queryString.stringify({
          index: JSON.stringify(im.index),
          image_set: JSON.stringify({
            processing: images.imageSet.processing,
            subset: images.imageSet.subset,
            image_size_px: images.imageSet.size,
          }),
          ...(im.cropOptions
            ? { crop_options: JSON.stringify(im.cropOptions) }
            : {}),
          ...(images.resizeTo ? { size: images.resizeTo } : {}),
        });

      try {
        const resp = await fetch(url, {
          ...requestInit,
          signal: abort.signal,
        });
        const arrayBuffer = await resp.arrayBuffer();
        const rawAutoMinValue = resp.headers.get("x-auto-min-value");
        const rawAutoMaxValue = resp.headers.get("x-auto-max-value");
        const rawDefaultMinValue = resp.headers.get("x-min-value");
        const rawDefaultMaxValue = resp.headers.get("x-max-value");

        if (rawDefaultMinValue == null || rawDefaultMaxValue == null) {
          throw new Error(
            "Expected to receive default display range from the server.",
          );
        }
        if (rawAutoMinValue == null || rawAutoMaxValue == null) {
          throw new Error(
            "Expected to receive auto display range from the server.",
          );
        }

        const autoRange: DisplayRange = [
          Number.parseInt(rawAutoMinValue, 10),
          Number.parseInt(rawAutoMaxValue, 10),
        ];
        const defaultRange: DisplayRange = [
          Number.parseInt(rawDefaultMinValue, 10),
          Number.parseInt(rawDefaultMaxValue, 10),
        ];

        const myView = new Uint8Array(arrayBuffer);
        ctx.cache.set(toKey(im, images.imageSet, images.resizeTo), {
          type: "ok",
          data: myView,
          autoRange,
          defaultRange,
        });
        forceRender();
      } catch (e) {
        if (e instanceof Error) {
          const currStatus = ctx.cache.get(
            toKey(im, images.imageSet, images.resizeTo),
          )?.type;
          if (currStatus === "in-progress" || currStatus === "error") {
            ctx.cache.set(toKey(im, images.imageSet, images.resizeTo), {
              type: "error",
              err: e,
            });
          }
        }
      }
    }),
  );
}

async function fetchImagesWithSingleRequest(
  workspaceId: WorkspaceId,
  images: ImageFetchSpec,
  ctx: Ctx,
  forceRender: () => void,
  hostUrl: string,
  requestInit: RequestInit,
  abort: AbortController,
): Promise<void> {
  const url =
    `${hostUrl}/api/v0/workspace/${workspaceId}/fetch_images?` +
    queryString.stringify({
      indexes_with_options: JSON.stringify(
        images.indexes.map((idx) => [idx.index, idx.cropOptions]),
      ),
      image_set: JSON.stringify({
        processing: images.imageSet.processing,
        subset: images.imageSet.subset,
        image_size_px: images.imageSet.size,
      }),
      ...(images.resizeTo ? { size: images.resizeTo } : {}),
    });

  await fetch(url, {
    ...requestInit,
    signal: abort.signal,
  })
    .then((response) =>
      response.arrayBuffer().then((arrayBuffer) => {
        const offsets = response.headers
          .get("x-image-byte-offsets")
          ?.split(",")
          .map((it) => Number.parseInt(it, 10));

        if (offsets == null) {
          throw new Error("Server did not send an offsets header.");
        }

        if (!(offsets.length === images.indexes.length)) {
          throw new Error(
            `Unexpected number of offsets from header. ` +
              `Expected ${images.indexes.length}; got ${offsets.length}`,
          );
        }

        const rawDefaultMinValues = response.headers.get(
          "x-default-min-values",
        );
        const rawDefaultMaxValues = response.headers.get(
          "x-default-max-values",
        );
        if (rawDefaultMinValues == null || rawDefaultMaxValues == null) {
          throw Error(
            "Expected to receive default display range from the server.",
          );
        }
        const defaultMinValues = rawDefaultMinValues
          .split(",")
          .map((it) => Number.parseInt(it, 10));
        const defaultMaxValues = rawDefaultMaxValues
          .split(",")
          .map((it) => Number.parseInt(it, 10));

        if (!(defaultMinValues.length === images.indexes.length)) {
          throw new Error(
            `Unexpected number of default min values from header. ` +
              `Expected ${images.indexes.length}; got ${defaultMinValues.length}`,
          );
        }

        if (!(defaultMaxValues.length === images.indexes.length)) {
          throw new Error(
            `Unexpected number of default max values from header. ` +
              `Expected ${images.indexes.length}; got ${defaultMaxValues.length}`,
          );
        }

        const rawAutoMinValues = response.headers.get("x-auto-min-values");
        const rawAutoMaxValues = response.headers.get("x-auto-max-values");
        if (rawAutoMinValues == null || rawAutoMaxValues == null) {
          throw new Error(
            "Expected to receive auto display range from the server.",
          );
        }
        const autoMinValues = rawAutoMinValues
          .split(",")
          .map((it) => Number.parseInt(it, 10));
        const autoMaxValues = rawAutoMaxValues
          .split(",")
          .map((it) => Number.parseInt(it, 10));

        if (!(autoMinValues.length === images.indexes.length)) {
          throw new Error(
            `Unexpected number of auto min values from header. ` +
              `Expected ${images.indexes.length}; got ${autoMinValues.length}`,
          );
        }

        if (!(autoMaxValues.length === images.indexes.length)) {
          throw new Error(
            `Unexpected number of auto max values from header. ` +
              `Expected ${images.indexes.length}; got ${autoMaxValues.length}`,
          );
        }

        images.indexes.forEach((imIdx, i) => {
          const defaultDisplayRange: DisplayRange = [
            defaultMinValues[i],
            defaultMaxValues[i],
          ];
          const autoDisplayRange: DisplayRange = [
            autoMinValues[i],
            autoMaxValues[i],
          ];

          const myOffset = offsets[i];
          const nextOffset =
            offsets.length > i + 1 ? offsets[i + 1] : arrayBuffer.byteLength;
          const len = nextOffset - myOffset;
          const myView = new Uint8Array(arrayBuffer, myOffset, len);
          ctx.cache.set(toKey(imIdx, images.imageSet, images.resizeTo), {
            type: "ok",
            data: myView,
            autoRange: autoDisplayRange,
            defaultRange: defaultDisplayRange,
          });
          forceRender();
        });
      }),
    )
    .catch((e) => {
      images.indexes.forEach((im) => {
        const currStatus = ctx.cache.get(
          toKey(im, images.imageSet, images.resizeTo),
        )?.type;
        if (currStatus === "in-progress" || currStatus === "error") {
          ctx.cache.set(toKey(im, images.imageSet, images.resizeTo), {
            type: "error",
            err: e,
          });
        }
      });
    });
}

function prefetchImages(
  workspaceId: WorkspaceId,
  images: ImageFetchSpec,
  ctx: Ctx,
  forceRender: () => void,
  hostUrl: string,
  requestInit: RequestInit,
  abort: AbortController,
) {
  const filtImages = images.indexes.filter((im) => {
    return (
      !ctx.cache.has(toKey(im, images.imageSet, images.resizeTo)) ||
      ctx.cache.get(toKey(im, images.imageSet, images.resizeTo))?.type ===
        "error"
    );
  });
  if (filtImages.length === 0) {
    return;
  }

  const params = new URLSearchParams(window.location.search);
  const fetcher = params.has("s-multi-request")
    ? fetchImagesWithMultipleRequests
    : fetchImagesWithSingleRequest;

  const promise = fetcher(
    workspaceId,
    { ...images, indexes: filtImages },
    ctx,
    forceRender,
    hostUrl,
    requestInit,
    abort,
  );
  filtImages.forEach((im) => {
    if (
      !ctx.cache.has(toKey(im, images.imageSet, images.resizeTo)) ||
      ctx.cache.get(toKey(im, images.imageSet, images.resizeTo))?.type !== "ok"
    ) {
      ctx.cache.set(toKey(im, images.imageSet, images.resizeTo), {
        type: "in-progress",
        promise: promise,
      });
      forceRender();
    }
  });
}

/**
 * Get an image from the prefetch cache.
 *
 * Returns undefined if the image was not prefetched.
 */
function getImage(
  index: ImageFetchItem,
  imageSet: ImageSet,
  resizeTo: number | null,
  ctx: Ctx,
): ImageFetchResult | undefined {
  return ctx.cache.get(toKey(index, imageSet, resizeTo));
}

/**
 * Component that initiates a prefetch of an image manifest in batches and then
 * renders its children normally.
 *
 * Note that if there are multiple prefetch components in the component tree,
 * only the outermost one will do any fetching. This allows us to default to
 * prefetching all channels in a MultiChannelImage, but also tune performance by
 * prefetching at a higher level when needed.
 *
 * Args:
 *  - manifest: the (flattened) manifest of images to fetch from the server
 *  - chunkSize: the number of images to fetch in a single request. (You
 *    probably want to leave this unspecified unless you're doing careful
 *    performance tuning.)
 *  - children: the child components to render normally. They can access prefetched
 *    images via `getImage`.
 */
export function Prefetch(props: {
  manifest: ImageFetchSpec;
  chunkSize?: number | undefined;
  children: any;
}) {
  const { hostUrl, requestInit } = useContext(RequestContext);
  const { sourceCropSize } = useContext(CropContext);
  const existingCtx = useContext(PrefetchContext);
  const workspace = useActiveWorkspace();
  const myId = useRef<number>(Math.random());
  const myCtx = useRef<Ctx>({ cache: new Map(), refs: new Map() });
  const [, setCounter] = useState<number>(0);
  const { manifest, chunkSize: maybeChunkSize, children } = props;
  const ctx = existingCtx?.ctx ?? myCtx.current;
  const forceRender = () => setCounter((x) => x + 1);

  const traceInfo = useContext(TracingContext);
  const traceInfoRef = useRef<TraceInfo | null>(traceInfo);
  traceInfoRef.current = traceInfo;

  useEffect(() => {
    if (!existingCtx) {
      const abort = new AbortController();
      const chunkSize =
        maybeChunkSize ?? Math.max(6, Math.ceil(manifest.indexes.length / 6));
      const chunkedManifests: ImageFetchItem[][] = [];
      manifest.indexes.forEach((item, i) => {
        if (i % chunkSize === 0) {
          chunkedManifests.push([]);
        }
        const key = toKey(item, manifest.imageSet, manifest.resizeTo);
        if (!ctx.refs.has(key)) {
          ctx.refs.set(key, new Set());
        }
        ctx.refs.get(key)?.add(myId.current);
        chunkedManifests[chunkedManifests.length - 1].push(item);
      });

      // Clear out any unreferenced cache entries references. We do this here, rather than in the
      // hook clean-up, so that if an image is included in a manifest, and then the manifest changes
      // but still includes that image, we don't pre-emptively remove it from the cache. (The hook
      // clean-up occurs before the next hook executes, so the image would be temporarily without
      // references.)
      ctx.cache.forEach((_, key) => {
        if ((ctx.refs.get(key)?.size ?? 0) === 0) {
          ctx.cache.delete(key);
        }
      });

      chunkedManifests.forEach((m) =>
        prefetchImages(
          workspace.id,
          {
            ...manifest,
            indexes: m,
          },
          ctx,
          forceRender,
          hostUrl,
          mergeTraceInfo(requestInit, traceInfoRef.current),
          abort,
        ),
      );
      const myCurrId = myId.current;
      return () => {
        abort.abort();
        manifest.indexes.forEach((entry) => {
          const key = toKey(entry, manifest.imageSet, manifest.resizeTo);
          ctx.refs.get(key)?.delete(myCurrId);
          const curr = ctx.cache.get(key);
          if (curr && curr.type !== "ok") {
            ctx.cache.set(key, {
              type: "error",
              err: new Error("Abort signal sent..."),
            });
          }
        });
      };
    }
  }, [
    workspace.id,
    manifest,
    hostUrl,
    requestInit,
    maybeChunkSize,
    sourceCropSize,
    existingCtx,
    ctx,
  ]);

  return (
    <PrefetchContext.Provider value={{ ctx, hash: stringifyContext(ctx) }}>
      {children}
    </PrefetchContext.Provider>
  );
}
