import * as Dialog from "@radix-ui/react-dialog";
import cx from "classnames";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { X } from "react-feather";
import { useHistory } from "react-router-dom";
import { Button } from "src/Common/Button";
import { useActiveWorkspaceId } from "src/Workspace/hooks";
import Markdown from "../Common/Markdown";
import { useDataset } from "../hooks/datasets";
import FindingPopup from "./FindingPopup";
import { Guider, InsightsEntry, InsightsKey } from "./types";
import { initializeAllInsights } from "./utils";

type InsightsContext = {
  _allInsights: Map<InsightsKey, InsightsEntry>;
  activeInsight: InsightsEntry | null;
  setActiveInsight: (key: InsightsKey | null) => void;
  activeGuiders: Guider[];
  dismissGuider: (key: string) => void;
};

type ActiveFindingState = {
  index: number;
  state: "open" | "collapsed";
};

type ActiveInsightState = null | {
  insight: InsightsEntry;
  state: "main-dialog" | ActiveFindingState;
};

const Context = createContext<InsightsContext | Record<string, never>>({});

/**
 * Maintain and provide context for state related to insights.
 *
 * Insights are guided stories, and the current "active story" is held in this context.
 * Each step in the story is a "finding", and each finding is associated with a
 * different page, so navigating them will navigate you through the app accordingly.
 */
export function InsightsContextProvider({ children }: { children: ReactNode }) {
  const history = useHistory();
  const workspaceId = useActiveWorkspaceId();
  const allInsights = useMemo(() => initializeAllInsights(), []);
  const [state, setState] = useState<ActiveInsightState>(null);

  const [dismissedGuiders, setDismissedGuiders] = useState<string[]>([]);
  const dismissGuider = useCallback((key: string) => {
    setDismissedGuiders((prev) => [...prev, key]);
  }, []);
  const activeGuiders = useMemo(() => {
    if (state === null || state.state === "main-dialog") {
      return [];
    }
    const finding = state.insight.findings[state.state.index];
    return (finding.guiders ?? []).filter(
      (guider) => !dismissedGuiders.includes(guider.key),
    );
  }, [state, dismissedGuiders]);

  const setActiveInsight = useCallback(
    (key: InsightsKey | null) => {
      const insight = key ? allInsights.get(key) ?? null : null;
      if (insight) {
        setState({
          insight,
          state: "main-dialog",
        });
      } else {
        setState(null);
      }
    },
    [allInsights],
  );

  const goToFinding = useCallback(
    (index: number) => {
      if (state === null) {
        return;
      }

      const { insight } = state;
      const finding = insight.findings[index];
      // TODO(you): Fix this no-unnecessary-condition rule violation
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (finding) {
        // Reset the dismissed guiders everytime we navigate to a new finding.
        setDismissedGuiders([]);
        const { path } = finding;
        history.push(`/workspace/${workspaceId}${path}`);
        setState({
          insight,
          state: {
            index,
            state: "open",
          },
        });
      }
    },
    [state, history, workspaceId],
  );

  const currentFindingIndex = useMemo(() => {
    if (state === null) {
      return null;
    }
    let index: number;
    if (state.state === "main-dialog") {
      index = -1;
    } else {
      index = (state.state as ActiveFindingState).index;
    }
    return index;
  }, [state]);
  const goToNextFinding = useCallback(() => {
    if (
      currentFindingIndex !== null &&
      state &&
      currentFindingIndex < state.insight.findings.length - 1
    ) {
      goToFinding(currentFindingIndex + 1);
    }
  }, [state, goToFinding, currentFindingIndex]);
  const goToPrevFinding = useCallback(() => {
    if (currentFindingIndex !== null && state && currentFindingIndex > 0) {
      goToFinding(currentFindingIndex - 1);
    }
  }, [state, goToFinding, currentFindingIndex]);

  return (
    <Context.Provider
      value={{
        _allInsights: allInsights,
        activeInsight: state?.insight ?? null,
        setActiveInsight,
        activeGuiders,
        dismissGuider,
      }}
    >
      <>{children}</>
      {state && state.state === "main-dialog" && (
        <MainAbstractDialog
          insight={state.insight}
          onClose={() => setState(null)}
          onStart={() => goToNextFinding()}
        />
      )}
      {state && state.state !== "main-dialog" && (
        <FindingPopup
          finding={state.insight.findings[state.state.index]}
          onGoToPrevFinding={
            currentFindingIndex !== null && currentFindingIndex > 0
              ? goToPrevFinding
              : undefined
          }
          onGoToNextFinding={
            currentFindingIndex !== null &&
            currentFindingIndex < state.insight.findings.length - 1
              ? goToNextFinding
              : undefined
          }
          onClose={() => setState(null)}
        />
      )}
    </Context.Provider>
  );
}

function MainAbstractDialog({
  insight,
  onClose,
  onStart,
}: {
  insight: InsightsEntry;
  onClose: () => void;
  onStart: () => void;
}) {
  const dataset = insight.dataset;
  const datasetInfo = useDataset({ dataset });
  const datasetName = datasetInfo?.successful
    ? datasetInfo.value?.name ?? dataset
    : dataset;
  return (
    <Dialog.Root
      open={true}
      onOpenChange={(open) => {
        if (!open) {
          // Closing the dialog will dismiss the active insight
          onClose();
        }
      }}
    >
      <Dialog.Portal>
        <div
          className={cx(
            "tw-fixed tw-left-0 tw-top-0 tw-w-full tw-h-full tw-z-popup-overlay",
            "tw-bg-slate-400 tw-opacity-70",
          )}
        />
        <Dialog.Overlay
          className={cx(
            "tw-fixed tw-left-0 tw-top-0 tw-w-full tw-h-full tw-z-popup",
            "tw-flex tw-flex-col tw-justify-center",
          )}
        >
          <Dialog.Content
            className={cx(
              "tw-relative tw-mx-auto tw-my-12 tw-w-1/2 tw-py-lg",
              "tw-flex tw-flex-col tw-justify-between tw-overflow-hidden",
              "tw-font-sans tw-break-words tw-rounded-lg tw-bg-white tw-shadow-lg",
            )}
          >
            <div className={"tw-flex-1 tw-overflow-y-auto tw-px-xl"}>
              <div className={"tw-mb-xl"}>
                <div className={"tw-text-2xl tw-text-slate-700 tw-mb-lg"}>
                  {insight.title}
                </div>
                <div className={"tw-flex tw-my-sm"}>
                  <div className={"tw-w-[140px] tw-text-slate-500"}>
                    Experiment
                  </div>
                  <div className={"tw-flex-1"}>{datasetName}</div>
                </div>
                <div className={"tw-flex tw-my-sm"}>
                  <div className={"tw-w-[140px] tw-text-slate-500"}>Date</div>
                  <div className={"tw-flex-1"}>
                    {insight.date.toDateString()}
                  </div>
                </div>
              </div>
              <Markdown className={"tw-text-slate-700"}>
                {insight.markdownContent}
              </Markdown>
            </div>

            <div className="tw-flex-none tw-flex tw-flex-col">
              <hr className="tw-border-gray-200 tw-mt-0 tw-mb-lg" />

              <Button
                name="Start tour"
                variant="primary"
                size="sm"
                onClick={onStart}
                className={"tw-self-end tw-mx-lg"}
              >
                Let's get started
              </Button>
            </div>

            <button
              className={
                "tw-absolute tw-right-lg tw-top-lg tw-text-xl hover:tw-text-gray-500"
              }
              title={"Close"}
              onClick={onClose}
            >
              <X />
            </button>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export function useInsightsContext() {
  return useContext(Context);
}
