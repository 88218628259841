import cx from "classnames";
import { SignificanceRecord } from "./types";
import { formatFractionAsPct } from "./util";

export function LabMatePopup({
  // TODO: once this is reused for other kinds of popups in Labmate or the left bar, a better interface would make the caller
  //  construct and provide a single prop: an array of strings to display where each element is a section.
  significanceRecords,
  shouldPrefixPct = false,
}: {
  significanceRecords: SignificanceRecord[];
  shouldPrefixPct?: boolean;
}) {
  return (
    <div>
      {significanceRecords.map((r, i) => {
        const roundedSignificance = Math.round(r.significance * 100) / 100;
        return (
          <div
            key={r.value}
            className={cx(
              i !== significanceRecords.length - 1 && "tw-pb-2",
              i > 0 && "tw-border-t tw-border-t-slate-400 tw-pt-2",
            )}
          >
            {shouldPrefixPct && (
              <>
                <span className="tw-text-slate-500 tw-font-bold">
                  {r.relativeChangePct}
                </span>
                <span> change in </span>
              </>
            )}
            <span className="tw-font-bold">{r.value}</span>
            {` ${shouldPrefixPct ? "" : "changed "}from ${formatFractionAsPct(
              r.globalFraction,
            )} globally to ${formatFractionAsPct(
              r.selectionFraction,
            )} in your selected points.`}
            <div className="tw-mt-1">
              <span className="tw-bg-gray-100 tw-p-1 tw-text-sm">
                {roundedSignificance === 0
                  ? `p < 0.001`
                  : `p = ${roundedSignificance}`}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
