import { metadataToKey } from "src/imaging/util";
import {
  MetadataColumnValue,
  UntypedWellSampleMetadataRow,
} from "../../../types";
import fishersExactTest from "../../fishersExactTest";
import { SignificanceRecord } from "./types";

/**
 * The metadata we get back has one row per field of the original data, not per
 * well. This filters down the data to one row per well (with a semi-random
 * field chosen).
 */
export function removeDuplicateFields(
  metadata: UntypedWellSampleMetadataRow[],
) {
  const hasSeenKey = new Set();
  return metadata.filter((row) => {
    const rowKey = metadataToKey(row);
    if (hasSeenKey.has(rowKey)) {
      return false;
    }
    hasSeenKey.add(rowKey);
    return true;
  });
}

export function formatFractionAsPct(num: number): string {
  const convertedVal = num < 1 ? Math.round(num * 100) : num === 1 ? 100 : 0;

  return convertedVal + "%";
}

export function formatSignificance(p: number): string {
  if (p < 0.01) {
    return "0.01";
  } else if (p < 0.05) {
    return "0.05";
  } else {
    return p.toFixed(2);
  }
}

export function sortBySignificance(
  a: SignificanceRecord,
  b: SignificanceRecord,
) {
  return a.significance < b.significance ? -1 : 1;
}

export function calculateSignificanceRecords({
  metadataColumns,
  uniqueValuesByColumn,
  selectedMetadata,
  allShownMetadata,
}: {
  metadataColumns: string[];
  uniqueValuesByColumn: { [key: string]: Set<MetadataColumnValue> };
  selectedMetadata: UntypedWellSampleMetadataRow[];
  allShownMetadata: UntypedWellSampleMetadataRow[];
}): SignificanceRecord[] {
  const significanceRecords: SignificanceRecord[] = [];

  for (const metadataColumn of metadataColumns) {
    const allMetadataValues = uniqueValuesByColumn[metadataColumn];

    const selectedCountByValue: { [key: string]: number } = {};
    for (const row of selectedMetadata) {
      const value = `${row[metadataColumn]}`;
      selectedCountByValue[value] = selectedCountByValue[value] || 0;
      selectedCountByValue[value] += 1;
    }

    const allCountByValue: { [key: string]: number } = {};
    for (const row of allShownMetadata) {
      const value = `${row[metadataColumn]}`;
      allCountByValue[value] = allCountByValue[value] || 0;
      allCountByValue[value] += 1;
    }

    for (const metadataValue of allMetadataValues) {
      const selectedMatchCount = selectedCountByValue[`${metadataValue}`];
      const selectedTotalCount = selectedMetadata.length;

      const overallMatchCount = allCountByValue[`${metadataValue}`];
      const overallTotalCount = allShownMetadata.length;
      const notSelectedTotalCount = overallTotalCount - selectedTotalCount;

      const selectedNotMatchCount = selectedTotalCount - selectedMatchCount;
      const notSelectedMatchCount = overallMatchCount - selectedMatchCount;
      const notSelectedNotMatchCount =
        notSelectedTotalCount - notSelectedMatchCount;

      const prob = fishersExactTest(
        selectedMatchCount,
        selectedNotMatchCount,
        notSelectedMatchCount,
        notSelectedNotMatchCount,
      );

      if (prob < 0.05) {
        const selectionFraction = selectedMatchCount / selectedTotalCount;
        const globalFraction = overallMatchCount / overallTotalCount;
        const representationType =
          selectionFraction > globalFraction ? "over" : "under";

        const relativeChange =
          (selectionFraction - globalFraction) / globalFraction;

        significanceRecords.push({
          metadataColumn,
          value: `${metadataValue}`,
          significance: prob,
          representationType,
          globalFraction,
          selectionFraction,
          relativeChangePct:
            (relativeChange > 0 ? "+" : "") +
            Math.round(relativeChange * 100) +
            "%",
        });
      }
    }
  }

  return significanceRecords;
}

export function renderMetadataValue(value: any): string {
  if (value === null) {
    return "<null>";
  } else {
    return `${value}`;
  }
}
