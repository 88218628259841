/**
 * Component to render its children in a sticky sidebar.
 */
import cx from "classnames";
import { ReactNode } from "react";

export default function ControlsSidebar({ children }: { children: ReactNode }) {
  return (
    <div
      className={cx(
        "tw-min-w-[360px] tw-max-w-[360px] tw-w-[360px]",
        "tw-flex tw-flex-col",
        "tw-border-r",
      )}
    >
      {children}
    </div>
  );
}

export function StickyControlsSidebar({ children }: { children: ReactNode }) {
  return (
    <div
      className={cx(
        "tw-sticky tw-top-global-nav-height",
        "tw-overflow-y-auto",
        "tw-h-[calc(100vh-theme(spacing.global-nav-height))]",
        "tw-flex",
      )}
    >
      <ControlsSidebar>{children}</ControlsSidebar>
    </div>
  );
}
