// TODO(davidsharff): as of writing, this legend is reused in the MegaMap score overview. Consider moving it to a shared location.
// Ideally, MegaMap would live in the MultiFeature directory but perhaps it is intended to be a standalone feature as well?
import classNames from "classnames";
import { useCallback, useMemo, useState } from "react";
import { ArrowDownLeft, ArrowUpRight } from "react-feather";
import { formatNumberForLegend } from "src/MegaMap/Overview/utils";

interface BaseLegendEntry {
  key: string;
  label: string;
  disabled: boolean;
  color: string;
  count?: number;
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export interface DiscreteLegendEntry extends BaseLegendEntry {
  value: unknown;
}

export interface RangeLegendEntry extends BaseLegendEntry {
  value: unknown;
  lower: number;
  upper: number;
}

export type LegendEntry = DiscreteLegendEntry | RangeLegendEntry;

export interface DiscreteLegend {
  type: "discrete";
  entries: DiscreteLegendEntry[];
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export interface RangeLegend {
  source: string | undefined;
  type: "ranges";
  entries: RangeLegendEntry[];
  min: number;
  max: number;
  getColor: (value: number) => string;
}

export type Legend = DiscreteLegend | RangeLegend;

type Props = {
  legend: Legend;
  hoveredKey: unknown | null;
  onHover?: (key: string | null, value?: unknown | null) => void;
  onClick?: (key: string, value: unknown) => void;
  onToggleCollapse?: (collapsed: boolean) => void;
  displayMode?: "default" | "compact";
};

export default function PlotLegend({
  legend,
  hoveredKey: hoveredKey,
  onHover,
  onClick,
  onToggleCollapse,
  displayMode = "default",
}: Props) {
  const [collapsed, _setCollapsed] = useState<boolean>(false);
  const isCompact = displayMode === "compact";

  const setCollapsed = useCallback(
    (newCollapsed: boolean) => {
      _setCollapsed(newCollapsed);
      onToggleCollapse?.(newCollapsed);
    },
    [onToggleCollapse],
  );

  const legendEntries = legend.entries;

  // We want to use two columns if we've got more than a few values, and the value
  // names are short enough that we probably won't need to truncate them
  const useTwoColumns = useMemo(
    () =>
      legend.type === "discrete" &&
      legendEntries.length >= 5 &&
      legendEntries.every((entry) => entry.label.length < 12),
    [legend.type, legendEntries],
  );

  return (
    <>
      {collapsed && (
        <div
          className="tw-flex tw-flex-col tw-border tw-rounded-lg tw-max-w-[300px] tw-bg-slate-50/80 tw-max-h-[200px] tw-relative tw-overflow-hidden"
          onMouseLeave={() => onHover && onHover(null)}
        >
          <div
            onClick={() => setCollapsed(!collapsed)}
            className={classNames(
              "tw-flex tw-items-center tw-justify-end tw-p-2 tw-select-none tw-cursor-pointer",
            )}
          >
            <ArrowDownLeft className="tw-mr-xs" size={16} />
            {" Show Legend"}
          </div>
        </div>
      )}
      {/* Always render the full legend but hidden if collapsed, so the scatter
       * plot can accurately determine point overlaps */}
      <div className={classNames(collapsed && "tw-invisible tw-h-0")}>
        <div
          // Re-render when the legend entries change, so the scatter plot gets
          // an updated size
          key={JSON.stringify(legendEntries)}
          className={classNames(
            "tw-flex tw-flex-col tw-border tw-rounded-lg tw-max-w-[300px] tw-bg-slate-50/80 tw-relative tw-overflow-hidden",
            isCompact
              ? "tw-max-h-[120px]"
              : legend.type === "ranges"
                ? "tw-max-h-[300px]"
                : "tw-max-h-[200px]",
          )}
          onMouseLeave={() => onHover && onHover(null)}
        >
          {!isCompact && (
            <div
              onClick={() => setCollapsed(!collapsed)}
              className={classNames(
                "tw-flex tw-items-center tw-justify-end tw-p-2 tw-pb-1 tw-border-b tw-select-none tw-cursor-pointer",
              )}
            >
              {"Collapse Legend"}
              <ArrowUpRight className="tw-ml-xs" size={16} />
            </div>
          )}
          <div className="tw-overflow-y-auto">
            {legend.type === "ranges" && (
              <div className="tw-px-sm tw-flex tw-flex-col tw-gap-xs">
                <div
                  className="tw-pt-sm tw-text-sm tw-truncate"
                  title={legend.source}
                >
                  {legend.source}
                </div>
                <div
                  className="tw-h-sm"
                  style={{
                    background: `linear-gradient(90deg, ${[
                      // Try to have enough values to capture whatever the gradient
                      // is doing
                      0,
                      0.25, 0.5, 0.75, 1,
                    ].map((value) => legend.getColor(value))})`,
                  }}
                ></div>
                <div className="tw-flex tw-flex-row tw-text-xs">
                  <div>{formatNumberForLegend(legend.min)}</div>
                  <div className="tw-flex-1" />
                  <div>{formatNumberForLegend(legend.max)}</div>
                </div>
              </div>
            )}
            <div
              className={classNames(
                "tw-p-2 tw-grid tw-gap-x-md tw-gap-y-1",
                useTwoColumns ? "tw-grid-cols-2" : "tw-grid-cols-1",
              )}
            >
              {legendEntries.map((entry) => {
                return (
                  <div
                    key={entry.key}
                    className={classNames(
                      "tw-flex tw-items-center tw-cursor-pointer tw-overflow-hidden",
                      "tw-gap-sm",
                    )}
                    onMouseOver={() =>
                      onHover && onHover(entry.key, entry.value)
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      onClick && onClick(entry.key, entry.value);
                    }}
                  >
                    <div
                      className="tw-w-3 tw-h-3 tw-rounded tw-shrink-0 tw-border"
                      style={{
                        backgroundColor: entry.disabled ? "#bbb" : entry.color,
                      }}
                    />
                    <div
                      className={classNames(
                        "tw-flex-1",
                        "tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis tw-select-none",
                        isCompact && "tw-text-sm",
                        entry.key === hoveredKey && "tw-underline",
                        entry.disabled && "tw-text-slate-500",
                      )}
                    >
                      {entry.label}
                    </div>
                    {entry.count !== undefined && (
                      <div className="tw-text-xs tw-text-slate-500">
                        {entry.count}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
