import cx from "classnames";
import { ReactNode } from "react";
import { BirdEatsBugButton } from "src/BirdEatsBugButton";
import { MethodsButton } from "src/Methods/MethodsButton";

interface Props {
  className: string;
}

export const CORNER_MENU_WIDTH = 32;
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const CORNER_MENU_HEIGHT = 120;

function SlidingButton({ children }: { children: ReactNode }) {
  return (
    <div
      className={cx(
        "tw-overflow-hidden tw-relative",
        "tw-transition-all",
        "tw-left-[4px] tw-w-[200px]",
        "hover:tw-left-[-116px]",
      )}
    >
      <div className="tw-w-[142px] tw-h-[40px]">{children}</div>
    </div>
  );
}

export function CornerMenu({ className }: Props) {
  return (
    <div
      className={cx(
        "tw-flex tw-flex-col tw-justify-end tw-gap-2 tw-box-border tw-pb-lg",
        className,
      )}
      style={{ width: CORNER_MENU_WIDTH, height: CORNER_MENU_HEIGHT }}
    >
      <SlidingButton>
        <MethodsButton className="tw-w-full tw-px-[6px]" />
      </SlidingButton>
      <SlidingButton>
        <BirdEatsBugButton className="tw-w-full tw-px-[6px]" />
      </SlidingButton>
    </div>
  );
}
