import * as RadixSwitch from "@radix-ui/react-switch";
import cx from "classnames";
import { useMemo } from "react";

let _counter = 0;

export function Switch({
  checked,
  disabled,
  onChange,
  extraClasses,
  label,
}: {
  checked?: boolean;
  disabled?: boolean;
  onChange?: () => void;
  extraClasses?: string;
  label?: string;
}) {
  const id = useMemo(() => `s${_counter++}`, []);

  return (
    <div className={`tw-pr-4 tw-flex tw-items-center ${extraClasses}`}>
      <RadixSwitch.Root
        id={id}
        className={cx(
          "tw-h-[28px] tw-w-[48px] tw-rounded-[14px]",
          "tw-inline-flex tw-items-center",
          disabled
            ? checked
              ? "tw-bg-blue-control-disabled"
              : "tw-bg-gray-control-disabled"
            : checked
            ? "tw-bg-blue"
            : "tw-bg-gray-control-off",
        )}
        disabled={disabled}
        checked={checked}
        onClick={onChange}
      >
        <RadixSwitch.Thumb
          className={cx(
            "tw-inline-block",
            "tw-w-[24px] tw-h-[24px] tw-rounded-[12px] tw-m-[2px]",
            "tw-bg-white",
            "tw-transition-transform",
            checked && "tw-translate-x-[20px]",
          )}
        />
      </RadixSwitch.Root>
      {label && (
        <label className={`tw-pl-2 tw-cursor-pointer`} htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
}
