import cx from "classnames";
import pluralize from "pluralize";
import { ReactNode, useMemo, useState } from "react";
import { Search } from "react-feather";
import { AnalyzeView } from "src/FeatureSetManagementPage/views";
import { DatasetId } from "src/types";
import { useFeatureSetMetadata } from "../hooks/features";
import SelectAllCheckbox from "./SelectAllCheckbox";
import SelectableGroup from "./SelectableGroup";
import { useCachedColumnsFetch } from "./columns-cache";
import { useLevel } from "./hooks";
import {
  FeatureSetPlateGroup,
  FeatureSetType,
  NormalFeatureSetSelection,
  isPlateBasedFeature,
} from "./types";
import {
  cleanPredictionName,
  hasStructuredLabelSetInformation,
  hasStructuredSupervisedLearnerInfo,
  maybeGroupCellProfilerColumns,
} from "./utils";

function maybeGetHackilyHardcodedDescription(name: string): string | null {
  if (name === "Nuclei") {
    return (
      "Single-cell measurements automatically extracted from individual cells. " +
      "Nuclear staining is used to localize and segment the cells, and the range of " +
      "measurements extracted on a per-cell basis can be configured."
    );
  } else if (name == "CellCount") {
    return (
      "Standard cell count values, as measured by automatic segmentation using " +
      "nuclear staining."
    );
  } else if (name == "Cytoplasm") {
    return (
      "Single-cell measurements automatically extracted from individual cells. " +
      "Nuclear staining is used to localize the cells, and a secondary membrane " +
      "is configured to segment the boundaries of the cell. A mask is then " +
      "computed by subtracting the nuclear portion from the total cell area, and " +
      "measurements calculated on the resulting mask."
    );
  } else if (name == "Cells") {
    return (
      "Single-cell measurements automatically extracted from individual cells. " +
      "Nuclear staining is used to localize the cells, and a secondary membrane " +
      "can be configured to segment the boundaries of the cell. Measurements are " +
      "then extracted on a per-cell basis and the set of measurements can be " +
      "configured."
    );
  } else if (name == "Images") {
    return (
      "Field-level measurements automatically extracted to capture a variety of " +
      "rudimentary metrics like cell count and intensity values. "
    );
  } else {
    return null;
  }
}

function ColumnList({
  columns,
  multi,
  selectedColumns,
  disabled,
  onChangeSelectedColumns,
}: {
  columns: string[];
  multi: boolean;
  selectedColumns: string[];
  disabled: boolean;
  onChangeSelectedColumns: (columns: string[]) => void;
}) {
  const [filterText, setFilterText] = useState<string | null>(null);
  const showFilter = columns.length > 10;

  const filteredColumns = useMemo(() => {
    const loweredFilterText = (filterText || "").trim().toLowerCase();
    if (!loweredFilterText) {
      return columns;
    }
    return columns.filter((c) => c.toLowerCase().includes(loweredFilterText));
  }, [columns, filterText]);

  const grouped = maybeGroupCellProfilerColumns(columns, filteredColumns);
  return (
    <>
      {showFilter && (
        <div className={"tw-flex tw-px-8 tw-text-slate-500 tw-mb-4"}>
          <label className={"tw-flex tw-items-center tw-flex-1"}>
            <div className={"tw-relative tw-flex-1"}>
              <input
                value={filterText || ""}
                placeholder={"Search..."}
                onChange={(e) => setFilterText(e.target.value)}
                className={
                  "tw-border tw-rounded tw-p-2 tw-pl-8 tw-text-sm tw-w-full"
                }
              />
              <Search
                size={16}
                className={
                  "tw-absolute tw-left-3 tw-top-[50%] tw-mt-[-8px] tw-text-gray-400"
                }
              />
            </div>
          </label>
        </div>
      )}
      {filterText && (
        <div className={"tw-px-8 tw-text-sm tw-text-slate-500"}>
          {filteredColumns.length > 0 ? (
            <>
              {filteredColumns.length} of{" "}
              {pluralize("measurement", columns.length, true)}
            </>
          ) : (
            <>No matching measurements.</>
          )}
        </div>
      )}

      {Object.entries(grouped).map(([key, columns]) => (
        <SelectableGroup
          key={key}
          groupTitle={key === "all" ? null : key}
          items={columns}
          allSelectedItems={selectedColumns}
          onChangeAllSelectedItems={onChangeSelectedColumns}
          unprefixGroupTitleFromItems={key !== "Other"}
          multi={multi}
          disabled={disabled}
        />
      ))}
    </>
  );
}

function AnimatingGrayBar() {
  return (
    <div className={"tw-relative tw-overflow-hidden tw-bg-gray-200 tw-rounded"}>
      <div
        className={cx(
          "tw-w-[300%] tw-h-[8px]",
          "tw-bg-gradient-to-r tw-from-gray-100 tw-via-gray-200 tw-to-gray-200",
          "tw-animate-progress",
        )}
      />
    </div>
  );
}
export function styledStains({ stains }: { stains: string[] }): JSX.Element {
  return (
    <div>
      {stains.map((stain) => (
        <div
          key={stain}
          className={
            "tw-p-1 tw-mb-1 tw-mx-1 tw-text-slate-400 tw-font-mono tw-inline-grid tw-bg-slate-100 tw-rounded tw-border-2 tw-border-slate-200"
          }
        >
          {stain}
        </div>
      ))}
    </div>
  );
}
function LabelSetFeatureDescriptionStructured({
  description,
  stains,
  labeledSetID,
}: {
  description: string;
  stains: string[];
  labeledSetID: string | null;
}) {
  // If we have a description and specified stains then we can show that information
  // in a clean way. Further if the Feature Set has a labeledSetID then we can link
  // out to that.
  const labeledSetPath = `pl/${labeledSetID}`;
  return (
    <div className={"tw-px-8"}>
      <div className={"tw-pb-8 tw-text-sm tw-text-slate-500 tw-mb-2"}>
        {description}
      </div>

      <div className={"tw-pb-8 tw-text-slate-400"}>
        <div className={" tw-text-sm tw-mb-2"}>
          This model was trained using the following stains:
        </div>
        <div className={"tw-text-xs tw-mb-4"}>{styledStains({ stains })}</div>
        {labeledSetID ? (
          <div>
            <div className={"tw-text-slate-400 tw-text-xs"}>
              Continue labeling cells in{"  "}
              <a
                className={"tw-text-purple"}
                href={labeledSetPath}
                target="_blank"
                rel="noreferrer noopener"
              >
                PhenoSorter.
              </a>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}
export default function MeasurementsDetails({
  dataset,
  featureType,
  features,
  multi,
  selection,
  selectedView,
  onChangeSelection,
}: {
  dataset: DatasetId;
  featureType: Exclude<FeatureSetType, "embedding">;
  features: FeatureSetPlateGroup;
  multi: boolean;
  selectedView: AnalyzeView | undefined;
  selection: NormalFeatureSetSelection | null;
  onChangeSelection: (selection: NormalFeatureSetSelection | null) => void;
}) {
  let { name } = features;
  // It's assumed that while the FeatureSets for each plate are generated independently,
  // they must all have the same set of columns.
  const firstFeatureSet = features.featureSets[0];

  const columns = useCachedColumnsFetch(
    isPlateBasedFeature(firstFeatureSet)
      ? {
          dataset,
          plate: firstFeatureSet.plate,
          featureSet: firstFeatureSet.id,
        }
      : {
          dataset,
          featureSet: firstFeatureSet.id,
        },
  );
  const containsAllColumns = useMemo(() => {
    if (!columns?.successful) {
      return () => false;
    }

    const asSet = new Set(columns.value);
    return (cols: string[]) => {
      return cols.length === asSet.size && cols.every((col) => asSet.has(col));
    };
  }, [columns]);

  const requiresCellLevel = selectedView?.requiresCellLevelFeature === true;
  const level = useLevel({
    dataset,
    firstFeatureSet,
    skip: !requiresCellLevel,
  });

  const disabled = requiresCellLevel === true && level !== "cell";

  const simpleDescriptionElement = (contents: ReactNode) => {
    return (
      <div
        className={cx(
          "tw-px-8 tw-pb-8 tw-text-sm tw-whitespace-pre-line",
          disabled ? "tw-text-slate-300" : "tw-text-slate-500",
        )}
      >
        {contents}
      </div>
    );
  };

  const preDefinedDescription = maybeGetHackilyHardcodedDescription(name);
  const metadataFetch = useFeatureSetMetadata(
    !preDefinedDescription
      ? {
          dataset,
          featureSet: name,
        }
      : { skip: true },
  );
  let descriptionElement: JSX.Element;
  if (preDefinedDescription) {
    descriptionElement = simpleDescriptionElement(preDefinedDescription);
  } else {
    if (metadataFetch?.successful) {
      const extraArgs = metadataFetch.value.extra_args;
      if (hasStructuredLabelSetInformation(extraArgs)) {
        descriptionElement = (
          <LabelSetFeatureDescriptionStructured
            description={metadataFetch.value.description}
            stains={extraArgs.stains}
            labeledSetID={extraArgs.labeled_set_id}
          />
        );
      } else if (hasStructuredSupervisedLearnerInfo(extraArgs)) {
        descriptionElement = simpleDescriptionElement(
          <div>
            <p>{metadataFetch.value.description}</p>
            <div className={"tw-pt-md"}>
              See your{" "}
              <a
                href={`sl/edit/${extraArgs.model_id}`}
                className={"tw-text-purple"}
                target={"_blank"}
              >
                model configuration
              </a>
              .
            </div>
          </div>,
        );
      } else {
        descriptionElement = simpleDescriptionElement(
          metadataFetch.value.description,
        );
      }
    } else {
      descriptionElement = (
        <div className={"tw-p-8 tw-grid tw-grid-cols-1 tw-gap-4"}>
          {Array(4)
            .fill(null)
            .map((_, i) => (
              <AnimatingGrayBar key={i} />
            ))}
        </div>
      );
    }
  }

  name = cleanPredictionName(name);

  return (
    <div className={"tw-py-4"}>
      <h1
        className={
          "tw-text-purple tw-text-xl tw-max-w-full tw-px-8 tw-py-4 tw-break-words"
        }
      >
        {decodeURIComponent(name)}
      </h1>
      {descriptionElement}

      {!columns && (
        <div className={"tw-px-8 tw-py-2"}>
          {Array(8)
            .fill(null)
            .map((_, i) => (
              <div className={"tw-my-6"} key={i}>
                <AnimatingGrayBar />
              </div>
            ))}
        </div>
      )}

      {columns && !columns.successful && (
        <div className={"tw-p-8 tw-text-red-error"}>
          Oops. There was an issue loading the details for <code>{name}</code>.
          <br />
          Please reload the page and try again.
        </div>
      )}

      {columns?.successful && (
        <>
          <div
            className={
              "tw-text-sm tw-text-slate-500 tw-px-8 tw-py-2 tw-border-b tw-mb-4 tw-flex"
            }
          >
            <div className={"tw-flex-1"}>
              {pluralize("measurement", columns.value.length, true)}
            </div>
            {multi && !disabled && (
              <SelectAllCheckbox
                value={
                  selection?.includesAllColumns ||
                  selection?.columns === columns.value
                }
                onChange={(selectAll) => {
                  onChangeSelection(
                    selectAll
                      ? {
                          type: featureType,
                          ...features,
                          columns: columns.value,
                          includesAllColumns: true,
                        }
                      : null,
                  );
                }}
              />
            )}
          </div>
          <ColumnList
            key={name}
            columns={columns.value}
            selectedColumns={selection?.columns ?? []}
            onChangeSelectedColumns={(columns) => {
              onChangeSelection(
                columns.length === 0
                  ? null
                  : {
                      type: featureType,
                      ...features,
                      columns,
                      includesAllColumns: containsAllColumns(columns),
                    },
              );
            }}
            multi={multi}
            disabled={disabled}
          />
        </>
      )}
    </div>
  );
}
