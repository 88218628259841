import { Timepoint } from "src/imaging/types";
import { TypedColumn } from "../Control/FilterSelector/backend-types";
import { FilterSet } from "../Control/FilterSelector/types";
import { UntypedWellSampleMetadataRow } from "../types";
import { DB } from "../util/sql";

/***
 * For a given Dataset, defines a FeatureSet.
 */
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type FeatureSetId = {
  // FeatureSet names are timestamped, UUID-suffixed strings.
  // See log-utils.ts#parseTaggedString for details.
  id: string;

  // For any given Dataset, a FeatureSet can belong to a constituent plate, or
  // can be defined at the "top level" (i.e. not associated with a single plate).
  plate: string | null;
};

export type FeatureSetInfo = FeatureSetId & {
  name: string;
  created: Date;
};

export function isPlateBasedFeatureSetId(
  featureSet: FeatureSetId,
): featureSet is { id: string; plate: string } {
  return featureSet.plate !== null;
}

export type SelectedCell = { column: number; row: number };

export type FeatureSetManagementContext = {
  /** The unfiltered, well-level metadata for the Dataset. */
  metadataDB: DB;
  metadataSchema: { [columnName: string]: string } | null;
  filterSerialized: string;
  filter: FilterSet;
  filterColumns: TypedColumn[];
  validateAndSetFilter: (filter: FilterSet) => void;

  /** Parameters for filtering based on feature values. */
  availableFeatureFilters: AvailableFeatureFilters;
  featureFilter: FeatureFilter | null;
  validateAndSetFeatureFilter: (filter: FeatureFilter | null) => void;

  /**
   * A cached copy of the filtered metadata.
   *
   * Note: uses useCacheableQueryRecords under the hood to ensure that quick,
   * successive updates to the filter do not result in intermediate "loading" states
   * emitted to this value. That means that child components may temporarily get
   * a stale copy of this metadata while we fetch the new, filtered version. However,
   * any copy emitted here will always be a "valid" value.
   */
  filteredMetadata: UntypedWellSampleMetadataRow[];

  onDownload: (() => Promise<void>) | null;
  setOnDownload: (fn: (() => Promise<void>) | null) => void;
};

export type UmapRow = {
  plate: string;
  well: string;
  timepoint?: Timepoint;
  "0": number;
  "1": number;
  cluster_label?: number;
};

export interface PointsByKey {
  [key: string]:
    | {
        x: number;
        y: number;
        clusterLabel?: number;
        metadata: UntypedWellSampleMetadataRow;
      }
    | undefined;
}

// Map from a metadata value to the color we use to represent it
export interface MetadataColors {
  [key: string]: string;
}

// Map from feature set names to columns available for filtering.
export type AvailableFeatureFilters = {
  [featureSet: string]: string[] | undefined;
};

export type FeatureFilterOp = "eq" | "neq" | "geq" | "leq" | "gt" | "lt";

type FeatureFilterParams = {
  op: FeatureFilterOp;
  value: number;
};

export type FeatureFilter = {
  featureSet: string;
  column: string;
  params: FeatureFilterParams;
};

type RecursivePartial<T> = {
  [K in keyof T]?: T[K] extends (infer E)[]
    ? RecursivePartial<E>[]
    : T[K] extends object | undefined
      ? RecursivePartial<T[K]>
      : T[K];
};

export type FeatureFilterBuilder = RecursivePartial<FeatureFilter>;
