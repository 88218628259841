/**
 * Shared colors and styles.
 */
import { StyleSheet } from "aphrodite";
import apercuBold from "../fonts/apercu_bold_pro.otf";
import apercuMedium from "../fonts/apercu_medium_pro.otf";
import apercuMono from "../fonts/apercu_mono_pro.otf";
import apercu from "../fonts/apercu_regular_pro.otf";

// Note(davidsharff): "hex" type is required by react-countdown-circle-timer and
// potentially other libs that accept color props.
export const colors: { [color: string]: `#${string}` } = {
  // Core color palette.
  white: "#ffffff",
  purple: "#6210ff",
  magenta: "#aa00ff",
  coral: "#ffa89a",
  yellow: "#ffbc0b",

  slate500: "#64748b",

  // Additional accent colors used consistently, but not defined in the core
  // brand guide.
  gray: "#949494",
  black: "#313131",
  green: "#31c972",
  red: "#ce3672",
};

export const fonts = {
  apercuRegular: {
    fontFamily: "Apercu",
    fontStyle: "normal",
    fontWeight: "normal",
    src: `url("${apercu}")`,
  },
  apercuBold: {
    fontFamily: "Apercu",
    fontStyle: "normal",
    fontWeight: "bold",
    src: `url("${apercuBold}")`,
  },
  apercuMedium: {
    fontFamily: "Apercu",
    fontStyle: "normal",
    fontWeight: 500,
    src: `url("${apercuMedium}")`,
  },
  apercuMono: {
    fontFamily: "Apercu Mono",
    fontStyle: "normal",
    fontWeight: "normal",
    src: `url("${apercuMono}")`,
  },
};

// TODO(colin): leading / tracking?
export const typography = StyleSheet.create({
  largeHeadline: {
    fontFamily: [fonts.apercuRegular, "sans-serif"],
    fontSize: 72,
    fontWeight: "normal",
  },
  mediumHeadline: {
    fontFamily: [fonts.apercuRegular, "sans-serif"],
    fontSize: 42,
    fontWeight: "normal",
  },
  smallHeadline: {
    fontFamily: [fonts.apercuRegular, "sans-serif"],
    fontSize: 24,
    fontWeight: "normal",
  },
  superHeading: {
    fontFamily: [fonts.apercuMono, "monospace"],
    fontWeight: "normal",
    fontSize: 15,
    textTransform: "uppercase",
  },
  bodyCopy: {
    fontFamily: [fonts.apercuRegular, "sans-serif"],
    fontSize: 18,
    fontWeight: "normal",
  },
  smallBodyCopy: {
    fontFamily: [fonts.apercuRegular, "sans-serif"],
    fontSize: 16,
    fontWeight: "normal",
  },
  caption: {
    fontFamily: [fonts.apercuMono, "monospace"],
    fontWeight: "normal",
    fontSize: 14,
  },
  bold: {
    fontFamily: [fonts.apercuBold, "sans-serif"],
    fontWeight: "bold",
  },
  medium: {
    fontFamily: [fonts.apercuMedium, "sans-serif"],
    fontWeight: 500,
  },
});
