import { DatasetId } from "src/types";

export type FeatureSetType =
  /** A high-dimensional embedding in a single "channel". */
  | "embedding"
  /** A softmax'ed prediction where each row sums to 1. */
  | "prediction"
  /** A "normal" FeatureSet with named columns where all values are numerical (or empty). */
  | "numerical";

// TODO(davidsharff): FeatureLevel already exists in our root types file.
export type FeatureSetLevel = "cell" | "field" | "well";

/**
 * A FeatureSet is an artifact that holds "feature data" at a dataset/plate level.
 *
 * These are the building blocks of all quantitative features/measurements, but we
 * try to provide higher-level concepts to "group" them together.
 */
export interface FeatureSetListing {
  // FeatureSet ID (paths) are timestamped, UUID-suffixed strings.
  // See log-utils.ts#parseTaggedString for details.
  id: string;
  name: string;
  type: FeatureSetType;
  created: Date;
}

export interface PlateBasedFeatureSetListing extends FeatureSetListing {
  plate: string;
  level: FeatureSetLevel;
}

export type FeatureSelectorContext = {
  dataset: DatasetId | null;
  columnsCache: { [key: string]: string[] };
  updateColumnsCache: (key: string, columns: string[]) => void;
};

export function isPlateBasedFeature(
  listing: FeatureSetListing,
): listing is PlateBasedFeatureSetListing {
  return "plate" in listing;
}

/**
 * A set of FeatureSets with the same name, but for different plates.
 *
 * e.g. name: "Cells" might indicate a CellProfiler output, but backed by a distinct
 * FeatureSet for each plate.
 */
export type FeatureSetPlateGroup = {
  name: string;
  featureSets: FeatureSetListing[];
};

export type EmbeddingSelection = {
  type: "embedding";
  // Note: these are the un-suffixed names. e.g. "Vgg5Embeddings - MitoTracker"
  names: string[];
};

export type NormalFeatureSetSelection = FeatureSetPlateGroup & {
  type: Exclude<FeatureSetType, "embedding">;
  columns: string[];
  includesAllColumns: boolean;
};

export type FeatureSetSelection =
  | EmbeddingSelection
  | NormalFeatureSetSelection;

export type ColumnsLookupFn = (featureName: string) => string[];

type SerializedIndexValues = string;
export type SerializedColumnIndices = `::${SerializedIndexValues}`;

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type UnreifiedNormalFeatureSetSelection = FeatureSetPlateGroup & {
  type: Exclude<FeatureSetType, "embedding">;
  columns: string[] | "all" | SerializedColumnIndices;
};

export type UnreifiedFeatureSetSelection =
  | EmbeddingSelection
  | UnreifiedNormalFeatureSetSelection;

export type SerializedSelection =
  | {
      featureType: "embedding";
      featureNames: string[];
    }
  | {
      featureType: Exclude<FeatureSetType, "embedding">;
      featureName: string;
      featureColumns: string[] | "all" | SerializedColumnIndices;
    };

export type UnvalidatedFeatureParams = {
  featureType?: string;
  featureName?: string;
  featureNames?: string | string[];
  featureColumns?: string | string[];
};

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type ModuleInfo = {
  id: string;
  name: string;
  previewUrl: string;
  enabled: boolean;
};
