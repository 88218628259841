import cx from "classnames";
import { useCallback, useMemo, useState } from "react";
import { ChevronLeft, Plus } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import { DatasetId } from "src/types";
import { DeprecatedButton } from "../../Common/DeprecatedButton";
import { PulseGuiderRoot } from "../../Insights/PulseGuider";
import { ImageSet } from "../../imaging/types";
import { AddEditClass } from "../AddEditClass";
import { Classification, LabeledSet, useLabeledSetContext } from "../Context";
import { LabeledSetMenu } from "../LabeledSetMenu";
import { useClassDemographicData } from "../hooks";
import { ClassCard } from "./ClassCard";
import { ExpandedClassSidebar } from "./ExpandedClassSidebar";

type Props = React.HTMLProps<HTMLDivElement> & {
  dataset: DatasetId;
  imageSet: ImageSet | null;
  labeledSet: LabeledSet;
  onDeleteStart?: () => void;
};

export function Sidebar({
  className,
  dataset,
  imageSet,
  labeledSet,
  onDeleteStart,
}: Props) {
  const { state: labeledSetState } = useLabeledSetContext();
  const { classifications } = labeledSetState;
  const [expandedClassificationName, setExpandedClassificationName] = useState<
    string | null
  >(null);

  const handleExpandClassification = useCallback((name: string) => {
    setExpandedClassificationName(name);
  }, []);

  const [previousClassificationName, nextClassificationName] = useMemo(() => {
    const shouldLoop = classifications.length >= 3;
    const classificationIndex = classifications.findIndex(
      (classification) => classification.name === expandedClassificationName,
    );

    const previousClassificationName =
      classificationIndex <= 0
        ? shouldLoop
          ? classifications[classifications.length - 1].name // If we're beyond the beginning, loop back to the end
          : null
        : classifications[classificationIndex - 1].name;

    const nextClassificationName =
      classificationIndex >= classifications.length - 1
        ? shouldLoop
          ? classifications[0].name // If we're beyond the end, loop back to the first
          : null
        : classifications[classificationIndex + 1].name;

    return [previousClassificationName, nextClassificationName];
  }, [classifications, expandedClassificationName]);

  const handleCloseClassification = useCallback(() => {
    setExpandedClassificationName(null);
  }, []);

  const expandedClassification: Classification | null = useMemo(
    () =>
      expandedClassificationName === null
        ? null
        : classifications.find(
            (classification) =>
              classification.name === expandedClassificationName,
          ) ?? null,
    [classifications, expandedClassificationName],
  );

  const {
    demographicColumnOptions,
    selectedDemographicColumn,
    handleChangeDemographicColumn,
    demographicData,
  } = useClassDemographicData(dataset);

  const history = useHistory();

  const onDeleteFinish = useCallback(() => {
    history.push("../pl");
  }, [history]);

  return (
    <div className="tw-flex tw-flex-col tw-border-r tw-bg-gray-50">
      <div className="tw-flex tw-flex-row tw-border-b">
        <Link
          to={"../pl"}
          className={cx(
            "tw-text-black tw-text-2xl",
            "tw-pr-4 tw-h-full tw-flex tw-items-center tw-pl-7",
          )}
        >
          <ChevronLeft size={16} />
        </Link>
        <div
          className={cx(
            "tw-text-xl",
            "tw-flex-1",
            "tw-text-gray-600 tw-py-md tw-pr-lg",
            "tw-flex tw-flex-row tw-items-center tw-justify-start tw-max-w-[450px]",
          )}
        >
          <div className="tw-mr-2 tw-flex-1 tw-truncate">
            <span className={"tw-text-gray-400"}>PhenoSorter /</span>{" "}
            {labeledSet.displayName}
          </div>
          <LabeledSetMenu
            className="tw-text-base"
            dataset={dataset}
            onDeleteStart={onDeleteStart}
            onDeleteFinish={onDeleteFinish}
          />
        </div>
      </div>

      <div className="tw-flex-1 tw-overflow-hidden">
        {expandedClassification === null ? (
          <ClassListSidebar
            className={className}
            classifications={classifications}
            dataset={dataset}
            imageSet={imageSet}
            onExpandClassification={handleExpandClassification}
            onCollapseClassification={handleCloseClassification}
          />
        ) : (
          <ExpandedClassSidebar
            classification={expandedClassification}
            dataset={dataset}
            imageSet={imageSet}
            onClose={handleCloseClassification}
            demographicColumnOptions={demographicColumnOptions}
            selectedDemographicColumn={selectedDemographicColumn}
            onChangeDemographicColumn={handleChangeDemographicColumn}
            demographicData={demographicData}
            previousClassificationName={previousClassificationName}
            nextClassificationName={nextClassificationName}
            onSelectClassification={setExpandedClassificationName}
          />
        )}
      </div>
    </div>
  );
}

type ClassListSidebarProps = React.HTMLProps<HTMLDivElement> & {
  classifications: Array<Classification>;
  dataset: DatasetId;
  imageSet: ImageSet | null;
  onExpandClassification: (classification: string) => void;
  onCollapseClassification: () => void;
};

function ClassListSidebar({
  classifications,
  className,
  dataset,
  imageSet,
  onExpandClassification,
}: ClassListSidebarProps) {
  return (
    <div
      className={cx(
        "tw-w-[450px] tw-h-full tw-flex-none tw-flex tw-flex-col tw-pb-4",
        "tw-bg-gray-50",
        className,
      )}
    >
      <div className="tw-px-8 tw-py-4">
        <PulseGuiderRoot
          guiderKey={"phenosorter-classes"}
          position={{
            corner: "bottom-right",
            offset: { x: 0, y: 0 },
          }}
          tooltipSide={"bottom"}
        >
          <AddEditClass>
            <DeprecatedButton className="tw-flex-none tw-w-full tw-bg-white">
              <Plus size={16} className="tw-mr-1" /> Add Class
            </DeprecatedButton>
          </AddEditClass>
        </PulseGuiderRoot>
      </div>

      <div className="tw-flex-1 tw-px-8 tw-flex tw-flex-col tw-gap-md tw-overflow-auto">
        {classifications.map((classification) => (
          <ClassCard
            key={classification.name}
            className="tw-bg-white"
            classification={classification}
            dataset={dataset}
            imageSet={imageSet}
            wide={false}
            onExpand={() => onExpandClassification(classification.name)}
          />
        ))}
      </div>
    </div>
  );
}
