import { useAuth0 } from "@auth0/auth0-react";
import cx from "classnames";
import { useCallback } from "react";
import { Circle, X } from "react-feather";
import { FullScreenContainer } from "./Common/FullScreenContainer";
import SpringCellsBackground from "./img/SpringCellsBackground.svg";
import SpringWordmarkWhite from "./img/SpringWordmarkWhite.svg";

const PURPLE = "#6210FF";
const CORAL = "#FFA89A";

export default function SplashScreen() {
  const { loginWithRedirect } = useAuth0();
  const onLogin = useCallback(() => loginWithRedirect(), [loginWithRedirect]);

  return (
    <FullScreenContainer center>
      <div className={"tw-flex tw-flex-row tw-w-full"}>
        <div className={"tw-p-8 tw-flex-1 tw-flex tw-flex-col tw-items-center"}>
          <div>
            <div
              style={{
                background: `linear-gradient(-50deg, ${CORAL} -10%, ${PURPLE} 60%)`,
              }}
            >
              <div className={"tw-mix-blend-screen tw-bg-white"}>
                <h1
                  className={
                    "tw-text-[48px] tw-font-medium tw-mb-sm sm:tw-hidden tw-w-full"
                  }
                >
                  Giving
                  <br />
                  scientists
                  <br />
                  superpowers.
                </h1>
                <h1
                  className={
                    "tw-text-[36px] tw-font-medium tw-mb-sm tw-hidden sm:tw-inline"
                  }
                >
                  Giving scientists superpowers.
                </h1>
              </div>
            </div>
            <div className={"tw-mt-8 tw-text-slate-700"}>
              Beautiful tools backed by powerful models for your scientific
              data.
            </div>
            <div className={"tw-mt-12"}>
              <a
                className={cx(
                  "tw-rounded",
                  "tw-w-32 tw-px-4 tw-py-2",
                  "tw-bg-purple tw-text-white hover:tw-text-white",
                  "tw-no-underline",
                )}
                href="https://docs.google.com/forms/d/e/1FAIpQLSeise84TO-LfQ6EcTXoroqUHutJGXD23HaA0IMeBOR0rF2DFQ/viewform?usp=sf_link"
                target="_blank"
              >
                Request access
              </a>
              <div className={"tw-mt-4 tw-text-slate-500 tw-text-sm"}>
                Already have an account?
                <button onClick={onLogin} className={"tw-underline tw-ml-1"}>
                  Sign in here
                </button>
                .
              </div>
            </div>
          </div>
        </div>
        <div className={"tw-max-w-[800px]"}>
          <img
            src="/appscreen.png"
            className={
              "tw-shadow-xl tw-h-[600px] tw-w-[800px] tw-max-w-[800px] tw-object-cover tw-object-left sm:tw-hidden"
            }
          />
        </div>
      </div>
    </FullScreenContainer>
  );
}

export function MobileSplashScreen({ onDismiss }: { onDismiss: () => void }) {
  return (
    <div className={"tw-h-screen tw-relative tw-overflow-hidden"}>
      <div className="tw-absolute tw-inset-4 tw-bg-gradient-to-b tw-from-coral tw-to-purple tw-rounded-xl tw-flex tw-flex-col">
        <div
          className={
            "tw-flex tw-text-white tw-py-3 tw-border-b tw-items-center"
          }
        >
          <Circle size={12} className={"tw-ml-md"} />
          <Circle size={12} className={"tw-ml-sm"} />
          <Circle size={12} className={"tw-ml-sm"} />
          <div className={"tw-flex-1"} />
          <X size={24} className={"tw-mr-2"} onClick={onDismiss} />
        </div>

        <div className="tw-flex-1" />
        <div>
          <div className={"tw-justify-center  tw-flex tw-mt-5"}>
            <SpringWordmarkWhite className="sm:tw-h-[48px] tw-h-[64px] tw-w-full tw-m-5 tw-pt-4" />
          </div>
          <div className={"tw-text-center tw-p-4"}>
            <div className={"tw-text-white sm:tw-text-sm tw-text-lg tw-mb-10"}>
              Please access this page from a desktop browser for the best
              viewing experience.
            </div>
            <a
              className="tw-text-white sm:tw-text-sm tw-text-base"
              href={"https://www.springdiscovery.com/"}
            >
              Go to Homepage
            </a>
          </div>
        </div>
        <div className="tw-flex-[2]" />

        <SpringCellsBackground className="tw-h-[140px] tw-absolute tw-bottom-0 -tw-right-[48px]" />
      </div>
    </div>
  );
}
