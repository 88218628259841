import { AsyncDuckDB } from "@duckdb/duckdb-wasm";
import cx from "classnames";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Grid, Square, ZoomIn } from "react-feather";
import { FullScreenContainer } from "src/Common/FullScreenContainer";
import Loader from "src/Common/Loader";
import Strut from "src/Common/Strut";
import { usePhenoFinderContext } from "src/PhenoFinder/Context";
import { getChartData, getMetadataForColumn } from "src/PhenoFinder/utils";
import { DEFAULT_CROP_SIZE_PX } from "src/env";
import {
  useAutoImageSet,
  usePalette,
  usePalettes,
} from "src/hooks/immunofluorescence";
import { VisualizationContextProvider } from "src/imaging/context";
import { DatasetId } from "src/types";
import invariant from "tiny-invariant";
import { FetchError } from "@spring/core/fetch";
import { arrayEquals } from "@spring/core/utils";
import { FilterSet } from "../../Control/FilterSelector/types";
import { filterColumnSpecsFromDatasetMetadataDB } from "../../Control/FilterSelector/utils";
import { useMethods } from "../../Methods/hooks";
import { MethodSectionKey } from "../../Methods/utils";
import {
  useDatasetMetadata,
  useDatasetSampleMetadataDB,
  useHasSingleCellDataset,
} from "../../hooks/datasets";
import BaseVisualizationControls from "../../imaging/Control/ConnectedVisualizationControls";
import CropSizeSlider from "../../imaging/Control/CropSizeSlider";
import RenderSizeSlider from "../../imaging/Control/RenderSizeSlider";
import { CropContext } from "../../imaging/cropping";
import { inferInterestingColumnsDB } from "../../util/dataset-util";
import { copyTableAcrossDB, createTableFromQuery } from "../../util/sql";
import {
  CELLS_FEATURES_PREFIXES,
  DEFAULT_CUT_TREE_NAME,
  FEATURE_TABLE_CREATION_QUERY,
  METADATA_LEGEND_WIDTH_PX,
  METADATA_TABLE_CREATION_QUERY,
} from "../constants";
import { useCellsFeatures, useCutTrees, useTree } from "../hooks";
import { buildTreeToLevel, getTreeWithModifications } from "../treeUtils";
import {
  ClusterFeatureData,
  ClusterMetadata,
  DataNode,
  TreeModificationStep,
  VisualizationColumn,
} from "../types";
import { ControlBar } from "./ControlBar";
import { CreateCluster } from "./CreateCluster";
import Dendrogram from "./Dendrogram";
import { FeatureCharts } from "./FeatureCharts";
import { MetadataCharts } from "./MetadataCharts";
import { MetadataLegend } from "./MetadataLegend";
import { SelectedClusterPanel } from "./SelectedClusterPanel";

function PhenoFinderPlaceholder({ dataset }: { dataset: DatasetId }) {
  return (
    <FullScreenContainer>
      <div className={"tw-w-full tw-h-full tw-text-center tw-text-slate-500"}>
        <div className={"tw-text-xl tw-mt-8"}>
          Explore visual phenotypes in your data with PhenoFinder!
        </div>
        <div className={"tw-leading-loose"}>
          ({dataset} does not have any single cell locations available)
        </div>
        <div
          className={
            "tw-flex tw-flex-row tw-items-end tw-justify-center tw-mt-12"
          }
        >
          <ZoomIn
            size={128}
            className={
              "tw-text-purple-500 tw-mr-[-100px] tw-scale-x-[-1] tw-relative"
            }
          />
          <img
            src={"/phenofinder-example.png"}
            width={600}
            height={600}
            className={"tw-shadow-[10px_35px_60px_0px_rgba(0,0,0,0.7)]"}
          />
        </div>
      </div>
    </FullScreenContainer>
  );
}

export function PhenoFinder({ dataset }: { dataset: DatasetId }) {
  const [isImageSettingsOpen, setIsImageSettingsOpen] = useState(false);
  const [
    shouldHighlightMeaningfulMetadata,
    setShouldHighlightMeaningfulMetadata,
  ] = useState(false);
  const [renderSize, setRenderSize] = useState(128);

  const [state, dispatch] = usePhenoFinderContext();
  useMethods(MethodSectionKey.phenofinder);
  const {
    loaded,
    numClusters,
    fullTree,
    currentTree,
    sampleMetadataDB,
    featureDB,
    selectedClusterName,
    clusters,
    metadataColorScale,
    chartData,
    selectedVisualizationColumn,
    modifications,
    defaultCropSize,
    filterSet,
  } = state;

  const [cropSize, setCropSize] = useState(
    defaultCropSize ?? DEFAULT_CROP_SIZE_PX,
  );

  const datasetMetadata = useDatasetMetadata({ dataset });
  const maxCropSize = useContext(CropContext).sourceCropSize;

  useEffect(() => {
    if (!datasetMetadata?.successful) {
      return;
    }

    const datasetCropSize = datasetMetadata.value?.single_cell_size;
    const tempDefaultCropSize = datasetCropSize ?? maxCropSize / 2;
    dispatch({
      type: "loadDefaultCropSize",
      defaultCropSize: tempDefaultCropSize,
    });

    // TODO(trisorus): This could potentially cause a flicker, but it takes longer to load the
    // cut trees than it does to load this setting, so most likely images won't be shown by the
    // time this loads anyway. There's another TODO in the MultiChannelImage component itself to
    // use the dataset's single cell size as the default, in which case this code won't be needed
    // anymore.
    setCropSize(tempDefaultCropSize);
  }, [dispatch, datasetMetadata, maxCropSize]);

  // TODO: is it really possible not to have an image size?
  const imageSize = datasetMetadata?.successful
    ? datasetMetadata.value?.source_image_size
    : null;

  const imageSet = useAutoImageSet({
    dataset,
    params: {
      imageSize,
      processingMode: "illumination-corrected",
    },
  });

  // TODO: The cluster tree has metadata about the stains used for clustering
  // – we should query for that and use those stains specifically
  const maybePlate = usePalettes({ dataset })?.map((palettes) => {
    // Just use the first plate to get the palette
    return Object.keys(palettes)[0];
  });
  const plate = maybePlate?.successful ? maybePlate.value : undefined;
  const maybePalette = usePalette(
    plate ? { dataset, acquisition: plate } : { skip: true },
  );
  const maybePalettes = usePalettes({ dataset });
  const hasSinglePalette = maybePalettes?.successful
    ? Object.values(maybePalettes.value).every((val, i, arr) => {
        return arrayEquals(val, arr[0]);
      })
    : false;
  const palette = useMemo(() => {
    return maybePalette?.successful
      ? maybePalette.value
      : { stains: [], multipliers: [] };
  }, [maybePalette]);

  const treeBlob = useTree({ dataset, cutTree: DEFAULT_CUT_TREE_NAME });
  const tempSampleMetadataDB = useDatasetSampleMetadataDB({
    dataset,
    groupBy: "well",
  });
  const tempFeaturesDB = useCellsFeatures({
    dataset,
    cutTree: DEFAULT_CUT_TREE_NAME,
    prefixes: CELLS_FEATURES_PREFIXES,
  });
  const tempCutTreesDB = useCutTrees({
    dataset,
    cutTree: DEFAULT_CUT_TREE_NAME,
  });

  const loadInitialTree = useCallback(
    async (fullTree: DataNode) => {
      const currentBaseTree = await buildTreeToLevel(fullTree, numClusters);

      dispatch({
        type: "loadInitialTree",
        fullTree,
        currentBaseTree,
      });
    },
    [dispatch, numClusters],
  );
  useEffect(() => {
    if (fullTree === null && treeBlob?.successful) {
      loadInitialTree(treeBlob.value);
    }
  }, [fullTree, treeBlob, dispatch, loadInitialTree]);

  const loadMetadata = useCallback(
    async (sampleMetadataDB: AsyncDuckDB, cutTreesDB: AsyncDuckDB) => {
      const combinedDB = await copyTableAcrossDB(
        sampleMetadataDB,
        cutTreesDB,
        "sample_metadata",
      );
      await createTableFromQuery(
        combinedDB,
        "single_cell_metadata",
        METADATA_TABLE_CREATION_QUERY,
      );
      const filterColumns = await filterColumnSpecsFromDatasetMetadataDB(
        dataset,
        combinedDB,
      );
      dispatch({
        type: "loadSampleMetadata",
        sampleMetadataDB: combinedDB,
        filterColumns,
      });
    },
    [dispatch, dataset],
  );

  const loadFeatures = useCallback(
    async (featuresDB: AsyncDuckDB, cutTreesDB: AsyncDuckDB) => {
      const combinedDB = await copyTableAcrossDB(
        featuresDB,
        cutTreesDB,
        "features",
      );
      await createTableFromQuery(
        combinedDB,
        "single_cell_features",
        FEATURE_TABLE_CREATION_QUERY,
      );

      const featureColumns = await inferInterestingColumnsDB(
        featuresDB,
        undefined,
        {
          tableName: "features",
        },
      );
      const filteredFeatureColumss = featureColumns.filter(
        (col) => !["plate", "well", "field", "row", "column"].includes(col),
      );
      dispatch({
        type: "loadFeatures",
        featureDB: combinedDB,
        featureColumns: filteredFeatureColumss,
      });
    },
    [dispatch],
  );

  useEffect(() => {
    if (tempSampleMetadataDB?.successful && tempCutTreesDB?.successful) {
      loadMetadata(tempSampleMetadataDB.value, tempCutTreesDB.value);
    }
  }, [dispatch, tempSampleMetadataDB, tempCutTreesDB, loadMetadata]);
  useEffect(() => {
    if (tempFeaturesDB !== undefined && tempCutTreesDB?.successful) {
      if (tempFeaturesDB.successful) {
        loadFeatures(tempFeaturesDB.value, tempCutTreesDB.value);
      } else {
        dispatch({
          type: "loadFeaturesFailure",
        });
      }
    }
  }, [dispatch, tempFeaturesDB, tempCutTreesDB, loadFeatures]);

  const loadDefaultMetadataByClusterName = useCallback(
    async (db: AsyncDuckDB, column: VisualizationColumn, data: DataNode) => {
      const metadata = await getMetadataForColumn(
        db,
        column.name,
        data,
        filterSet,
      );

      dispatch({
        type: "loadDefaultMetadataForSelectedColumn",
        chartData: metadata,
      });
    },
    [dispatch, filterSet],
  );

  useEffect(() => {
    if (
      chartData !== null || // Only use this for initial load
      sampleMetadataDB === null ||
      selectedVisualizationColumn === null ||
      selectedVisualizationColumn.type !== "metadata" ||
      currentTree === null
    ) {
      return;
    }

    loadDefaultMetadataByClusterName(
      sampleMetadataDB,
      selectedVisualizationColumn,
      currentTree,
    );
  }, [
    dispatch,
    chartData,
    sampleMetadataDB,
    selectedVisualizationColumn,
    currentTree,
    loadDefaultMetadataByClusterName,
  ]);

  const handleSetHoveredDataNode = useCallback(
    (node: DataNode | null) => {
      dispatch({
        type: "setHoveredDataNode",
        node: node,
      });
    },
    [dispatch],
  );

  const handleChangeFilter = useCallback(
    async (filter: FilterSet) => {
      if (!loaded) {
        return;
      }
      const chartData = await getChartData(
        sampleMetadataDB,
        featureDB,
        selectedVisualizationColumn,
        currentTree,
        filter,
      );
      dispatch({
        type: "changeFilter",
        filter,
        chartData,
      });
    },
    [
      dispatch,
      sampleMetadataDB,
      featureDB,
      selectedVisualizationColumn,
      currentTree,
      loaded,
    ],
  );
  const handleModifyTree = useCallback(
    async (
      modification: TreeModificationStep,
      shouldScrollToNewClusters: boolean = false,
    ) => {
      invariant(loaded, "Unexpectedly modifying tree when data hasn't loaded");

      // Apply just this new modification on top of the current tree
      const newTree = getTreeWithModifications(
        structuredClone(currentTree),
        fullTree,
        [modification],
      );

      const chartData = await getChartData(
        sampleMetadataDB,
        featureDB,
        selectedVisualizationColumn,
        newTree,
        filterSet,
      );
      dispatch({
        type: "updateCurrentTree",
        modifications: [...modifications, modification],
        newTree,
        shouldScrollToNewClusters,
        chartData,
      });
    },
    [
      dispatch,
      currentTree,
      modifications,
      filterSet,
      fullTree,
      loaded,
      sampleMetadataDB,
      featureDB,
      selectedVisualizationColumn,
    ],
  );
  const handleClearSelectedCluster = useCallback(() => {
    dispatch({
      type: "clearSelectedCluster",
    });
  }, [dispatch]);

  const toggleImageSettings = useCallback(() => {
    setIsImageSettingsOpen(!isImageSettingsOpen);
  }, [isImageSettingsOpen]);
  const maybeHasSingleCellDataset = useHasSingleCellDataset({
    dataset,
    skip: treeBlob?.successful === true,
  });
  const hasSingleCellDataset = maybeHasSingleCellDataset?.successful
    ? maybeHasSingleCellDataset.value.exists
    : undefined;

  if (
    maybePalettes?.successful === false &&
    maybePalettes.error instanceof FetchError &&
    maybePalettes.error.status === 400
  ) {
    return <PhenoFinderPlaceholder dataset={dataset} />;
  } else if (
    treeBlob?.successful === false &&
    datasetMetadata?.successful === true &&
    palette["stains"].length > 0 &&
    hasSinglePalette &&
    hasSingleCellDataset !== undefined
  ) {
    return hasSingleCellDataset ? (
      <CreateCluster stains={palette.stains} dataset={dataset} />
    ) : (
      <PhenoFinderPlaceholder dataset={dataset} />
    );
  } else if (
    (treeBlob?.successful === false || datasetMetadata?.successful === false) &&
    maybePalette?.successful === true &&
    maybePalettes?.successful === true &&
    hasSingleCellDataset !== undefined
  ) {
    return (
      <FullScreenContainer center className="tw-text-xl">
        <p>Error loading PhenoFinder – please try again later.</p>
        <p>
          If you continue to see this, contact{" "}
          <a href={"mailto:support@springdisc.com"}>support@springdisc.com.</a>
        </p>
      </FullScreenContainer>
    );
  }

  if (!loaded) {
    return (
      <FullScreenContainer center>
        <Loader />
        Loading clusters…
      </FullScreenContainer>
    );
  }
  return (
    <div className={cx("tw-relative tw-w-full")}>
      {/* Empty div used to make sure rubberband scroll has the correct background color */}
      <div
        className={cx(
          "tw-fixed tw-top-0 tw-left-0",
          "tw-w-full tw-h-[100vh] tw-overflow-hidden",
          "tw-bg-slate-100",
        )}
      />

      <VisualizationContextProvider>
        <ControlBar
          dataset={dataset}
          imageSet={imageSet}
          // NOTE: If changing the height, find+replace all instances in this file
          className="tw-h-[146px] tw-z-20"
          onToggleImageSettings={toggleImageSettings}
          onToggleHighlightMetadata={setShouldHighlightMeaningfulMetadata}
          shouldHighlightMetadata={shouldHighlightMeaningfulMetadata}
          onModifyTree={handleModifyTree}
          onHoverDataNode={handleSetHoveredDataNode}
          onChangeFilter={handleChangeFilter}
        />

        <div
          className={cx(
            "tw-fixed tw-left-0 tw-z-10",
            "tw-top-[calc(theme(spacing.global-nav-height)+146px)]",
            "tw-h-[calc(100vh-theme(spacing.global-nav-height)-146px)]",
            // There's a spacer that needs to be the same width lower in this file; if updating
            // max width be sure to find+replace to change in both places
            isImageSettingsOpen ? "tw-w-1/5 tw-max-w-[320px]" : "tw-hidden",
            "tw-flex",
          )}
        >
          <div className={cx("tw-w-full")}>
            <div
              className={cx(
                "tw-w-full tw-h-full tw-p-lg",
                "tw-flex tw-flex-col",
                "tw-bg-white tw-border tw-border-slate-300 tw-overflow-y-auto",
              )}
            >
              <BaseVisualizationControls palette={palette} />
              <Strut size={10} />
              <RenderSizeSlider
                min={64}
                max={192}
                size={renderSize}
                onChange={setRenderSize}
                icons={[Grid, Square]}
              />
              <Strut size={20} />
              <CropSizeSlider
                size={cropSize}
                defaultSize={defaultCropSize}
                onChange={setCropSize}
              />
            </div>
          </div>
        </div>

        <div className="tw-flex">
          <div
            className={cx(
              "tw-flex tw-overflow-y-auto",
              // 36px is a buffer for rubberband scrolling the grid into the header
              // If changing, also update margin.top in Dendrogram and MetadataCharts
              "tw-w-full tw-min-h-[calc(100vh-theme(spacing.global-nav-height)-146px+36px)]",
              "tw-bg-slate-100",
              "tw-absolute tw-top-[calc(146px-36px)] tw-left-0",
            )}
          >
            {/* Spacer for image settings sidebar (which is outside this div so it doesn't scroll) */}
            <div
              className={cx(
                isImageSettingsOpen ? "tw-w-1/5 tw-max-w-[320px]" : "tw-hidden",
              )}
            />

            <Dendrogram
              dataset={dataset}
              imageSet={imageSet}
              data={currentTree}
              className={cx(
                "tw-flex-1",
                isImageSettingsOpen ? "tw-w-2/5" : "tw-w-3/5",
                "tw-flex tw-flex-col tw-overflow-y-auto",
              )}
              onModifyTree={handleModifyTree}
              cropSize={cropSize}
              onHoverDataNode={handleSetHoveredDataNode}
            />

            <div className={cx("tw-w-2/5")}>
              {/* TODO(you): Fix this no-unnecessary-condition rule violation */}
              {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
              {clusters === null || chartData === null ? (
                <Loader />
              ) : selectedClusterName === null ? (
                selectedVisualizationColumn.type === "metadata" ? (
                  <MetadataCharts
                    className={cx("tw-flex tw-flex-col tw-overflow-y-auto", {
                      // TODO(you): Fix this no-unnecessary-condition rule violation
                      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                      "tw-invisible": selectedClusterName !== null,
                    })}
                    clusters={clusters}
                    data={chartData as ClusterMetadata[]}
                    colorScale={metadataColorScale}
                    selectedMetadataColumn={selectedVisualizationColumn.name}
                    shouldHighlightMeaningfulMetadata={
                      shouldHighlightMeaningfulMetadata
                    }
                  />
                ) : (
                  <FeatureCharts
                    className={cx("tw-flex tw-flex-col tw-overflow-y-auto", {
                      // TODO(you): Fix this no-unnecessary-condition rule violation
                      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                      "tw-invisible": selectedClusterName !== null,
                    })}
                    clusters={clusters}
                    data={chartData as ClusterFeatureData[]}
                    selectedFeature={selectedVisualizationColumn.name}
                    shouldHighlightMeaningfulMetadata={
                      shouldHighlightMeaningfulMetadata
                    }
                  />
                )
              ) : null}
            </div>
          </div>
        </div>

        <div
          className={cx(
            "tw-fixed tw-right-0 tw-z-20",
            "tw-top-[calc(theme(spacing.global-nav-height)+146px)]",
            "tw-h-[calc(100vh-theme(spacing.global-nav-height)-146px)]",
            "tw-flex",
            selectedClusterName !== null && "tw-w-2/5",
          )}
        >
          {selectedClusterName !== null ? (
            <SelectedClusterPanel
              className={cx(
                "tw-w-full",
                // Only the metadata visualization has a legend; add padding for others
                selectedVisualizationColumn.type !== "metadata" && "tw-pr-xl",
              )}
              dataset={dataset}
              onClosePanel={handleClearSelectedCluster}
              imageSet={imageSet}
              imageSize={renderSize}
              cropSize={cropSize}
              dataForSelectedColumn={chartData}
              shouldHighlightMeaningfulMetadata={
                shouldHighlightMeaningfulMetadata
              }
            />
          ) : null}

          {selectedVisualizationColumn.type === "metadata" ? (
            <MetadataLegend
              className={cx("tw-flex-none")}
              style={{ width: METADATA_LEGEND_WIDTH_PX }}
              colorScale={metadataColorScale}
            />
          ) : null}
        </div>
      </VisualizationContextProvider>
    </div>
  );
}
