import { useState } from "react";
import { usePopper } from "react-popper";
import Select from "react-select-plus";
import { VerticalControlSection } from "../../../Control/ControlSection";
import { useFeatureFlag } from "../../../Workspace/feature-flags";
import HelpIcon from "../../../icons/HelpIcon.svg";
import { MetadataColumnValue } from "../../../types";
import HoverPopup from "../../HoverPopup";
import { GroupSelectionKey } from "../types";

export function GroupVisualization({
  groupOptions,
  selectedGroup,
  selectedSubgroup,
  onChangeGrouping,
  compareToOptions,
  selectedCompareTo,
  onChangeCompareTo,
  featureName,
  column,
  isLoading,
}: {
  groupOptions: { value: string; label: string }[];
  selectedGroup: string | null;
  selectedSubgroup: string | null;
  onChangeGrouping: (groupType: GroupSelectionKey, val: string | null) => void;
  compareToOptions: {
    value: Exclude<MetadataColumnValue, null> | "<null>";
    label: string;
  }[];
  selectedCompareTo: MetadataColumnValue | undefined;
  onChangeCompareTo: (selectedCompareTo: string) => void;
  featureName: string;
  column: string;
  isLoading: boolean;
}) {
  // TODO: are there props or another library which can better handle winnowing huge search/selects?
  // TODO: rotate angle of labels in single grouping
  return (
    // Hack(davidsharff): Cancel out container padding because control sections are already padded
    <div className="tw-flex tw-flex-col">
      <VerticalControlSection>
        <div className=" tw-text-gray-500 tw-pb-sm">Group By</div>
        <Select
          simpleValue={true}
          options={groupOptions}
          value={selectedGroup}
          onChange={(metadataKey: string) => {
            onChangeGrouping("selectedGroup", metadataKey);
          }}
          clearable={false}
          disabled={isLoading}
        />
      </VerticalControlSection>
      <VerticalControlSection>
        <div className=" tw-text-gray-500 tw-pb-sm">Subgroup By</div>
        <Select
          simpleValue={true}
          options={groupOptions.filter(({ value }) => value !== selectedGroup)}
          value={selectedSubgroup}
          onChange={(metadataKey: string) => {
            onChangeGrouping("selectedSubgroup", metadataKey);
          }}
          disabled={!selectedGroup || isLoading}
        />
      </VerticalControlSection>
      <VerticalControlSection>
        <CompareToHeader
          selectedCompareTo={selectedCompareTo}
          featureName={featureName}
          column={column}
        />
        <Select
          simpleValue={true}
          options={compareToOptions}
          value={selectedCompareTo}
          onChange={onChangeCompareTo}
          clearable={false}
          disabled={!selectedGroup || isLoading}
        />
      </VerticalControlSection>

      <div className="tw-flex-1" />
    </div>
  );
}

function CompareToHeader({
  selectedCompareTo,
  featureName,
  column,
}: {
  selectedCompareTo: MetadataColumnValue | undefined;
  featureName: string;
  column: string;
}) {
  const hideSignifianceStars = useFeatureFlag(
    "hide-significance-stars-comparisons-view",
  );
  const [compareToHelpElem, setCompareToHelpElem] =
    useState<HTMLElement | null>(null);

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [popperArrowElement, setPopperArrowElement] =
    useState<HTMLDivElement | null>(null);

  const popper = usePopper(compareToHelpElem, popperElement, {
    placement: "right",
    modifiers: [
      {
        name: "flip",
        options: {
          fallbackPlacements: ["left"],
        },
      },
      {
        name: "preventOverflow",
        options: {
          padding: 4,
        },
      },
      {
        name: "arrow",
        options: {
          element: popperArrowElement,
          padding: 12,
        },
      },
    ],
  });

  return (
    <>
      <div className="tw-flex tw-flex-row tw-items-center  tw-w-full tw-text-gray-500 tw-pb-sm">
        <div className="tw-flex tw-items-center">
          <span>Compare To</span>
          {!hideSignifianceStars && (
            <span
              onMouseOver={(e) => setCompareToHelpElem(e.currentTarget)}
              onMouseLeave={() => setCompareToHelpElem(null)}
            >
              <HelpIcon className={"tw-ml-md"} />
            </span>
          )}
        </div>
      </div>
      {compareToHelpElem && (
        <HoverPopup
          popperStyles={{
            ...popper.styles,
            popper: {
              ...popper.styles.popper,
              left: "-5px",
              zIndex: 1, // Don't let the underlying points poke through
            },
          }}
          popperAttributes={popper.attributes}
          popperState={popper.state}
          setPopperElement={setPopperElement}
          setPopperArrowElement={setPopperArrowElement}
        >
          <div>
            <span>Each group will be compared against</span>
            <span className="tw-text-purple">
              {" " + selectedCompareTo + " "}
            </span>
            <span>to see if their distribution of</span>
            <span className="tw-text-purple">
              {" " + featureName} - {column + " "}
            </span>
            <span>differs according to a two-tailed Mann Whitney U Test.</span>
          </div>
        </HoverPopup>
      )}
    </>
  );
}
