import { useCallback } from "react";
import { usePrefsContext } from "src/Common/Prefs/Context";
import {
  UserDatasetPrefsWithDefaults,
  UserPrefsWithDefaults,
  UserWorkspacePrefsWithDefaults,
} from "src/Common/Prefs/types";
import invariant from "tiny-invariant";

export function useIsLoadingPrefs() {
  const { isLoadingPrefs } = usePrefsContext();
  return isLoadingPrefs;
}

/**
 * Hook that returns the value of the given pref and a setter function.
 *
 * Prefs are required to be loaded before this hook can be used. Loading state
 * can be checked by hooking into useIsLoadingPrefs. In most cases, manually
 * checking should not be necessary since the AppChrome is already checking.
 */
export function useUserPref<T extends keyof UserPrefsWithDefaults>(
  key: T,
): [
  UserPrefsWithDefaults[T] | null,
  (value: UserPrefsWithDefaults[T]) => void,
] {
  const { userPrefs, setUserPref } = usePrefsContext();
  invariant(userPrefs, "called useUserPref before prefs have finished loading");

  const pref = userPrefs[key];
  const setPref = useCallback(
    (value) => {
      setUserPref(key, value);
    },
    [key, setUserPref],
  );

  return [pref, setPref];
}

/**
 * Hook that returns the value of the given user workspace pref and a setter function.
 *
 * Prefs are required to be loaded before this hook can be used. Loading state
 * can be checked by hooking into useIsLoadingPrefs. In most cases, manually
 * checking should not be necessary since the AppChrome is already checking.
 */
// TODO: Remove rule disable after first usage
// ts-unused-exports:disable-next-line
export function useUserWorkspacePref<
  T extends keyof UserWorkspacePrefsWithDefaults,
>(
  key: T,
): [
  UserWorkspacePrefsWithDefaults[T] | null,
  (value: UserWorkspacePrefsWithDefaults[T]) => void,
] {
  const { userWorkspacePrefs, setUserWorkspacePref } = usePrefsContext();
  invariant(
    userWorkspacePrefs,
    "called useUserWorkspacePref before prefs have finished loading",
  );

  const pref = userWorkspacePrefs[key];
  const setPref = useCallback(
    (value) => {
      setUserWorkspacePref(key, value);
    },
    [key, setUserWorkspacePref],
  );

  return [pref, setPref];
}

/**
 * Hook that returns the value of the given pref and a setter function.
 *
 * Prefs are required to be loaded before this hook can be used. Loading state
 * can be checked by hooking into useIsLoadingPrefs. In most cases, manually
 * checking should not be necessary since the AppChrome is already checking.
 */
// TODO: Remove rule disable after first usage
// ts-unused-exports:disable-next-line
export function useUserDatasetPref<
  T extends keyof UserDatasetPrefsWithDefaults,
>(
  key: T,
): [
  UserDatasetPrefsWithDefaults[T] | null,
  (value: UserDatasetPrefsWithDefaults[T]) => void,
] {
  const { userDatasetPrefs, setUserDatasetPref } = usePrefsContext();
  invariant(
    userDatasetPrefs,
    "called useUserDatasetPref before prefs have finished loading",
  );

  const pref = userDatasetPrefs[key];
  const setPref = useCallback(
    (value) => {
      setUserDatasetPref(key, value);
    },
    [key, setUserDatasetPref],
  );

  return [pref, setPref];
}
