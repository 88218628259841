import ttest2 from "@stdlib/stats-ttest2";
import { uTest } from "statly";
import {
  GetCorrectedPValueOptions,
  StatisticalCorrection,
  StatisticalCorrectionType,
  StatisticalTest,
  StatisticalTestOptions,
  StatisticalTestType,
} from "./types";

export const STATISTICAL_CORRECTIONS: StatisticalCorrection[] = [
  {
    type: StatisticalCorrectionType.Bonferroni,
    displayName: "Bonferroni",
    getCorrectedPValue: (options: GetCorrectedPValueOptions) =>
      options.numberOfTests && options.numberOfTests > 0
        ? Math.min(options.uncorrectedPValue * options.numberOfTests, 1)
        : options.uncorrectedPValue,
  },
  {
    type: StatisticalCorrectionType.None,
    displayName: "None",
    getCorrectedPValue: (options: GetCorrectedPValueOptions) =>
      options.uncorrectedPValue,
  },
];

export const STATISTICAL_TESTS: StatisticalTest[] = [
  {
    type: StatisticalTestType.MannWhitney,
    displayName: "Mann-Whitney U Test",
    getTestValue: (options: StatisticalTestOptions) =>
      uTest(options.values, options.controlValues).p,
  },
  {
    type: StatisticalTestType.TTest,
    displayName: "Student's t-test",
    getTestValue: (options: StatisticalTestOptions) =>
      ttest2(options.values, options.controlValues).pValue,
  },
];
