import { useState } from "react";
import { UploadCloud } from "react-feather";
import { useRouteMatch } from "react-router-dom";
import { DatasetId, WorkspaceId } from "src/types";
import { Caption, Title } from "@spring/ui/typography";
import { FullScreenContainer } from "../../Common/FullScreenContainer";
import Loader from "../../Common/Loader";
import { useToastContext } from "../../Toast/context";
import {
  useEditClientMetadata,
  useFetchClientMetadata,
  useUploadClientMetadata,
} from "../hooks";
import { CSVReaderResults } from "../types";
import { CSVUploader } from "./CSVUploader";
import { DuckDBTable } from "./DuckDBTable";
import { CellValue, Column, ColumnType, Row } from "./EditableTable";
import { SplashScreenContainer } from "./helpers";

export function ClientMetadataTable() {
  // TODO: also fetch the de-duped manifest plate+wells and combine dbs
  const match = useRouteMatch<{
    workspaceId: WorkspaceId;
    datasetId: DatasetId;
  }>();
  const { workspaceId, datasetId } = match.params;
  const [metadataDB, refetch] = useFetchClientMetadata(workspaceId, datasetId);
  const [isUploading, setIsUploading] = useState(false);

  const editClientMetadata = useEditClientMetadata(workspaceId, datasetId);
  const uploadClientMetadata = useUploadClientMetadata(workspaceId, datasetId);

  const { setToast } = useToastContext();

  if (!metadataDB || isUploading) {
    return (
      <FullScreenContainer center>
        <Loader />
      </FullScreenContainer>
    );
  }

  const handleUploadAccepted = async (results: CSVReaderResults) => {
    try {
      setIsUploading(true);
      // TODO(davidsharff): POC. Cleanup in separte PR for error handling.
      if (results.errors.length > 0) {
        console.error("Error parsing upload:");
        console.error(results);
        setToast(
          "parse-metadata-upload",
          "Error parsing upload. View console for more details.",
        );
        return;
      }
      try {
        const responseMsg = await uploadClientMetadata(results.data);

        // Note(davidsharff): I don't like locally caught errors but oh well. It makes sense to me here.
        if (responseMsg !== "success") {
          throw new Error(`Unexpected response from upload: ${responseMsg}`);
        }

        setToast("upload-metadata", "Metadata sucessfully uploaded.");
      } catch (e) {
        setToast(
          "upload-metadata",
          "Failed to upload metadata. View console for more details.",
        );
        console.error(e);
        return; // We don't need to refetch if the upload failed.
      }

      await handleRefetchMetadata();
    } finally {
      setIsUploading(false);
    }
  };

  // TODO(davidsharff): only allow edits for rows with valid plate/wells
  const handleSaveCellEdit = async (
    newValue: CellValue,
    column: string,
    row: Row,
  ) => {
    if (row[column] === newValue) {
      return;
    }
    try {
      await editClientMetadata({
        ...row,
        [column]: newValue,
      });
    } catch (e) {
      console.error(e);
      setToast(
        "edit-metadata",
        "Failed to edit metadata. View console for more details.",
      );
    }

    await handleRefetchMetadata();
  };

  const handleRefetchMetadata = async () => {
    try {
      await refetch();
    } catch (e) {
      console.error(e);
      setToast(
        "refetch-metadata",
        "Failed to refetch metadata. View console for more details.",
      );
    }
  };

  if (!metadataDB.successful) {
    if (metadataDB.error.message === "File not found") {
      return (
        <SplashScreenContainer>
          <UploadCloud className="tw-mb-md tw-text-gray-400 tw-w-12 tw-h-12" />
          <Title className="tw-mb-md tw-text-gray-900">No file found</Title>
          <Caption className="tw-mb-md tw-text-gray-500">
            Please upload a client metadata file to proceed.
          </Caption>
          <CSVUploader
            onUploadAccepted={handleUploadAccepted}
            buttonText="Upload"
            buttonProps={{
              variant: "primary",
              className: "tw-mt-md tw-mb-md",
            }}
          />
        </SplashScreenContainer>
      );
    }
    console.error(metadataDB.error);
    return <div>Oops. Something went wrong fetching client metadata.</div>;
  }

  return (
    <div className="tw-mt-2">
      <DuckDBTable
        db={metadataDB.value}
        tableName="client_metadata"
        createColumnsFromRow={createColumnsFromRow}
        editableTableOptions={{
          onCommitChange: handleSaveCellEdit,
        }}
        headerComponent={
          <CSVUploader
            onUploadAccepted={handleUploadAccepted}
            buttonText="Upload"
            buttonProps={{
              variant: "primary",
              className: "tw-mx-2",
            }}
          />
        }
      />
    </div>
  );
}

// Callback that dynamically creates the column config object used by EditableTable
// from the contents of the first row.
function createColumnsFromRow(row: Row): Column[] {
  return Object.keys(row).map((field) => {
    let type: ColumnType;
    switch (typeof row[field]) {
      case "number":
        type = ColumnType.Number;
        break;
      case "boolean":
        type = ColumnType.Checkbox;
        break;
      case "string":
        type = ColumnType.Text;
        break;
      default:
        throw new Error(`Unsupported data type for column: ${field}`);
    }

    return {
      headerContent: field,
      field,
      type: type,
      isReadOnly: field === "plate" || field === "well",
    };
  });
}
