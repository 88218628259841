/**
 * Redux store definition, state type, and reducer for image visualization.
 */
import { History } from "history";
import * as queryString from "query-string";
import { StoreEnhancer, createStore } from "redux";
import {
  DEFAULT_DISPLAY_SETTING,
  DEFAULT_VISUALIZATION_STATE,
  DISPLAY_RANGE,
  EMPTY_CHANNEL_MAP,
  MAX_NUM_IMAGES_TO_PROCESS_FOR_DISPLAY_SETTINGS,
} from "../constants";
import makeStoreEnhancer from "../query-sync";
import {
  ChannelMap,
  DisplayRange,
  FetchState,
  MultiChannelDisplaySettings,
  MultiChannelFlags,
  Palette,
  VisualizationState,
} from "../types";
import { defaultChannelMap } from "../visualization";
import { Action } from "./actions";

export type State = VisualizationState &
  FetchState & {
    palette: Palette | null;
  };

const defaultState: State = {
  ...DEFAULT_VISUALIZATION_STATE,
  palette: null,
};

/**
 * Pad a display range by a percentage.
 *
 * This is useful when updating the boundaryRange since lots of pages render
 * multiple images and therefore cause many updates in rapid sequence. Putting a
 * padding on the range updates throttles the downstream updates to some degree,
 * since images loaded together will often have similar/comparable ranges.
 */
const getPaddedRange = (
  range: DisplayRange,
  paddingFraction: number,
): DisplayRange => {
  const padding = paddingFraction * (range[1] - range[0]);
  return [
    Math.max(0, Math.round(range[0] - padding)),
    Math.min(65536, Math.round(range[1] + padding)),
  ];
};

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const reducer = (
  inputState: State | undefined,
  action: Action,
): State => {
  if (inputState === undefined) {
    return defaultState;
  }

  switch (action.type) {
    case "reset": {
      return {
        ...inputState,
        channelLoaded: [false, false, false, false, false, false, false],
      };
    }
    case "set-channel-map": {
      return {
        ...defaultState,
        renderMode: inputState.renderMode,
        processingMode: inputState.processingMode,
        channelMap: action.channelMap,
      };
    }
    case "load-palette": {
      const getDisplaySettings = (
        channelMap: ChannelMap,
      ): MultiChannelDisplaySettings => {
        const hasStainDisplayRanges =
          Object.keys(action.stainDisplayRanges ?? {}).length !== 0;

        if (!hasStainDisplayRanges) {
          return inputState.displaySettings;
        }

        // For each channel, check if the stain set to that channel has a saved displayRange
        // that we should load
        return channelMap.map((stainIndex) => {
          if (stainIndex === null) {
            return DEFAULT_DISPLAY_SETTING;
          }

          const stain = action.palette.stains[stainIndex];
          const stainDisplayRange = action.stainDisplayRanges?.[stain];
          return {
            ...DEFAULT_DISPLAY_SETTING,
            activeRange: stainDisplayRange ?? DISPLAY_RANGE,
            autoRange: stainDisplayRange ?? DISPLAY_RANGE,
            isLoadedRange: stainDisplayRange !== undefined,
          };
        }) as MultiChannelDisplaySettings;
      };

      if (inputState.channelMap.every((index) => index == null)) {
        const channelMap = defaultChannelMap(action.palette, {
          stainSubset: action.stainSubset,
          stainChannelIndices: action.stainChannelIndices,
        });

        return {
          ...defaultState,
          palette: action.palette,
          displaySettings: getDisplaySettings(channelMap),
          processingMode: inputState.processingMode,
          renderMode: inputState.renderMode,
          channelMap,
        };
      } else {
        return {
          ...inputState,
          palette: action.palette,
          displaySettings: getDisplaySettings(inputState.channelMap),
        };
      }
    }
    case "set-channel": {
      if (inputState.channelMap[action.index] === action.selectedValue) {
        return inputState;
      }

      const channelMap: ChannelMap = [...inputState.channelMap];
      channelMap[action.index] = action.selectedValue;

      // Reset the channel.
      const displaySettings: MultiChannelDisplaySettings = [
        ...inputState.displaySettings,
      ];
      const channelLoaded: MultiChannelFlags = [...inputState.channelLoaded];
      const showChannel: MultiChannelFlags = [...inputState.showChannel];
      const lockChannel: MultiChannelFlags = [...inputState.lockChannel];

      displaySettings[action.index] = DEFAULT_DISPLAY_SETTING;
      channelLoaded[action.index] = false;
      showChannel[action.index] = true;
      lockChannel[action.index] = false;

      // Check if we should load a saved display range for this stain
      if (inputState.palette !== null && action.selectedValue !== null) {
        // TODO(you): Fix this no-unnecessary-condition rule violation
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const stain = inputState.palette?.stains[action.selectedValue];
        const stainDisplayRange = action.stainDisplayRanges?.[stain];
        displaySettings[action.index] = {
          ...DEFAULT_DISPLAY_SETTING,
          activeRange: stainDisplayRange ?? DISPLAY_RANGE,
          autoRange: stainDisplayRange ?? DISPLAY_RANGE,
          isLoadedRange: stainDisplayRange !== undefined,
        };
      }

      return {
        ...inputState,
        displaySettings,
        channelLoaded,
        showChannel,
        lockChannel,
        channelMap,
      };
    }
    case "load-channel": {
      const channelLoaded: MultiChannelFlags = [...inputState.channelLoaded];
      if (channelLoaded[action.index]) {
        if (inputState.renderMode === "uniform") {
          const {
            defaultDisplayRange: imageDisplayRange,
            autoDisplayRange: imageAutoRange,
          } = action.imageMetadata;

          const displaySettings: MultiChannelDisplaySettings =
            inputState.displaySettings;
          const {
            boundaryRange: currentDisplayRange,
            autoRange: currentAutoRange,
            numImagesProcessed: currentNumImagesProcessed,
            isLoadedRange,
          } = displaySettings[action.index];

          // Expand the boundary range to ensure that it captures the full dynamic range of all loaded
          // images.
          let boundaryRange = currentDisplayRange;
          if (
            imageDisplayRange[0] < currentDisplayRange[0] ||
            imageDisplayRange[1] > currentDisplayRange[1]
          ) {
            const paddedDisplayRange = getPaddedRange(imageDisplayRange, 0.1);
            boundaryRange = [
              Math.min(currentDisplayRange[0], paddedDisplayRange[0]),
              Math.max(currentDisplayRange[1], paddedDisplayRange[1]),
            ];
          }

          // Expand the auto range to capture the full dynamic range of the given number of images we
          // want to process.
          let autoRange = currentAutoRange;
          let numImagesProcessed = currentNumImagesProcessed;
          if (
            !isLoadedRange &&
            numImagesProcessed < MAX_NUM_IMAGES_TO_PROCESS_FOR_DISPLAY_SETTINGS
          ) {
            if (
              imageAutoRange[0] < currentAutoRange[0] ||
              imageAutoRange[1] > currentAutoRange[1]
            ) {
              const paddedAutoRange = getPaddedRange(imageAutoRange, 0.1);
              autoRange = [
                Math.min(currentAutoRange[0], paddedAutoRange[0]),
                Math.max(currentAutoRange[1], paddedAutoRange[1]),
              ];
            }
            numImagesProcessed += 1;
          }

          if (
            numImagesProcessed !== currentNumImagesProcessed ||
            boundaryRange !== currentDisplayRange ||
            autoRange !== currentAutoRange
          ) {
            return {
              ...inputState,
              displaySettings: [
                ...displaySettings.slice(0, action.index),
                {
                  ...displaySettings[action.index],
                  boundaryRange,
                  activeRange:
                    inputState.lockChannel[action.index] || isLoadedRange
                      ? displaySettings[action.index].activeRange
                      : autoRange,
                  autoRange,
                  numImagesProcessed,
                },
                ...displaySettings.slice(action.index + 1),
              ] as MultiChannelDisplaySettings,
            };
          }
        }

        return inputState;
      } else {
        channelLoaded[action.index] = true;
        const displaySettings: MultiChannelDisplaySettings = [
          ...inputState.displaySettings,
        ];
        if (inputState.renderMode === "uniform") {
          const { isLoadedRange, activeRange: currentDisplayRange } =
            displaySettings[action.index];

          const { defaultDisplayRange, autoDisplayRange } =
            action.imageMetadata;

          displaySettings[action.index] = {
            ...displaySettings[action.index],
            boundaryRange: getPaddedRange(defaultDisplayRange, 0.2),
            activeRange: isLoadedRange
              ? [...currentDisplayRange]
              : [...autoDisplayRange],
            autoRange: isLoadedRange
              ? [...currentDisplayRange]
              : [...autoDisplayRange],
          };
          // TODO(you): Fix this no-unnecessary-condition rule violation
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        } else if (inputState.renderMode === "per-image") {
          displaySettings[action.index] = { ...DEFAULT_DISPLAY_SETTING };
        } else {
          throw Error(`Invalid render mode: ${inputState.renderMode}`);
        }

        // If the channel is locked, avoid modifying the active range.
        if (inputState.lockChannel[action.index]) {
          displaySettings[action.index].activeRange =
            inputState.displaySettings[action.index].activeRange;
        }

        return { ...inputState, displaySettings, channelLoaded };
      }
    }
    case "set-active-range": {
      const displaySettings: MultiChannelDisplaySettings = [
        ...inputState.displaySettings,
      ];
      displaySettings[action.index] = {
        ...displaySettings[action.index],
        activeRange: [...action.displayRange],
      };
      return { ...inputState, displaySettings };
    }
    case "toggle-channel": {
      const showChannel: MultiChannelFlags = [...inputState.showChannel];
      showChannel[action.index] = !inputState.showChannel[action.index];
      return { ...inputState, showChannel };
    }
    case "lock-channel": {
      const lockChannel: MultiChannelFlags = [...inputState.lockChannel];
      lockChannel[action.index] = !inputState.lockChannel[action.index];
      return { ...inputState, lockChannel };
    }
    case "enable-auto-range": {
      const displaySettings: MultiChannelDisplaySettings = [
        ...inputState.displaySettings,
      ];
      displaySettings[action.index] = {
        ...displaySettings[action.index],
        activeRange: [...displaySettings[action.index].autoRange],
      };
      return { ...inputState, displaySettings };
    }
    case "reset-range": {
      const displaySettings: MultiChannelDisplaySettings = [
        ...inputState.displaySettings,
      ];
      displaySettings[action.index] = {
        ...displaySettings[action.index],
        activeRange: [...displaySettings[action.index].boundaryRange],
      };
      return { ...inputState, displaySettings };
    }

    // No default case; this enforces that the switch is exhaustive via the type system.
  }
};

export function clearChannelMap(query: { [k: string]: unknown }): {
  [k: string]: unknown;
} {
  delete query["c"];
  return query;
}

export function getStateInQueryParams(): string {
  const params = queryString.parse(window.location.search);
  const relevantParams = Object.fromEntries(
    Object.entries(params).filter(([k]) => ["c"].includes(k)),
  );
  return queryString.stringify(relevantParams);
}

export default function configureStore(history?: History) {
  // Store enhancer to manage bidirectional syncing of the channel map to the URL.
  const storeEnhancer: StoreEnhancer | null = history
    ? makeStoreEnhancer({
        params: {
          c: {
            selector: (state: State) => state.channelMap,
            action: (channelMap: ChannelMap): Action => ({
              type: "set-channel-map",
              channelMap,
            }),
            arrayToValue: (array: string[]): ChannelMap => [
              array[0] === "" ? null : Number.parseInt(array[0]),
              array[1] === "" ? null : Number.parseInt(array[1]),
              array[2] === "" ? null : Number.parseInt(array[2]),
              array[3] === "" ? null : Number.parseInt(array[3]),
              array[4] === "" ? null : Number.parseInt(array[4]),
              array[5] === "" ? null : Number.parseInt(array[5]),
              array[6] === "" ? null : Number.parseInt(array[6]),
            ],
            valueToArray: (channelMap: ChannelMap): string[] =>
              channelMap.map((value) => (value == null ? "" : "" + value)),
            defaultValue: EMPTY_CHANNEL_MAP,
            multiple: true,
          },
        },
        initialTruth: "location",
        replaceState: true,
        history,
      })
    : null;

  return createStore(reducer, storeEnhancer ?? undefined);
}
