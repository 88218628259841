import { useCallback } from "react";
import {
  LOCAL_CACHE_TTL,
  SERVER_CACHE_TTL,
} from "src/ExperimentsDashboard/constants";
import {
  getPerWorkspaceId,
  useLocalModifications,
} from "src/LocalModifications";
import { useFeatureFlag } from "src/Workspace/feature-flags";
import { useActiveWorkspace } from "src/Workspace/hooks";
import { CanSkip, useDatasetApi } from "src/hooks/api";
import { useDataset } from "src/hooks/datasets";
import { ChannelMap, DisplayRanges, ImageSet } from "src/imaging/types";
import { DatasetId } from "src/types";
import { hasKeys } from "./has-keys";
import { useAreInternalFeaturesEnabled } from "./users";

export interface WellCover {
  plate: string;
  well: string;
  field: number;
  imageSet: ImageSet;
  x: number;
  y: number;
  color: string;
  channelMap: ChannelMap;
  displayRanges: DisplayRanges;
}

type UnsupportedCover = Record<string, unknown>;

export type Cover = WellCover | UnsupportedCover;

export const DEFAULT_COVER_COLOR = "#000";

export function useCanSetDatasetCover() {
  const areInternalFeaturesEnabled = useAreInternalFeaturesEnabled();
  // NOTE(danlec): Disabling write for public experiments is enforced by the server,
  // we're currently using this flag to tell the frontend which experiments aren't
  // allowed to be edited
  const canEditExperiment = !useFeatureFlag("disable-experiment-renaming");
  // TODO(danlec): Let people other than internal edit/set dataset covers
  return areInternalFeaturesEnabled && canEditExperiment;
}

export function isWellCover(cover: Cover): cover is WellCover {
  if (
    hasKeys(
      cover,
      "plate",
      "well",
      "field",
      "x",
      "y",
      "color",
      "imageSet",
      "channelMap",
      "displayRanges",
    )
  ) {
    const {
      plate,
      well,
      field,
      imageSet,
      channelMap,
      displayRanges,
      x,
      y,
      color,
    } = cover;

    return (
      typeof plate === "string" &&
      typeof well === "string" &&
      typeof field === "number" &&
      typeof imageSet === "object" &&
      typeof x === "number" &&
      typeof y === "number" &&
      typeof color === "string" &&
      Array.isArray(channelMap) &&
      Array.isArray(displayRanges)
    );
  }

  return false;
}

export function useSetCover(
  options: CanSkip<{
    dataset: DatasetId;
  }>,
) {
  const canSetDatasetCover = useCanSetDatasetCover();
  const { addModification } = useLocalModifications("dataset-display");
  const fetchListing = useDataset(options);
  const listing = fetchListing?.successful ? fetchListing.value : undefined;

  const api = useDatasetApi(options);

  const workspace = useActiveWorkspace();

  return useCallback(
    (newCover: WellCover) => {
      if (!listing || options.skip || !canSetDatasetCover || !api) {
        return;
      }

      // We don't have a cover saved, but we fetched a cover
      const dataset = options.dataset;

      addModification({
        id: getPerWorkspaceId(workspace.id, dataset),
        type: "dataset-display",
        description: listing.description ?? "",
        name: listing.name,
        cover: newCover,
        expires: Date.now() + SERVER_CACHE_TTL + LOCAL_CACHE_TTL,
      });
      api.route("dataset_display").put({ cover: newCover });
    },
    [addModification, api, canSetDatasetCover, listing, options, workspace.id],
  );
}

// Compute a representative color to use in place of a dataset cover, while we're waiting
// for it to load
export function colorForCover({
  x = 0.5,
  y = 0.5,
  canvasRef,
}: {
  x?: number;
  y?: number;
  canvasRef: React.RefObject<HTMLElement>;
}): string {
  const canvas = canvasRef.current?.querySelector("canvas");

  if (canvas) {
    const ctx = canvas.getContext("2d");
    if (ctx) {
      const px = canvas.width * x;
      const py = canvas.height * y;
      const sampleWidth = canvas.width / 16;
      const sampleHeight = canvas.height / 16;
      const x1 = Math.max(0, px - sampleWidth / 2);
      const y1 = Math.max(0, py - sampleHeight / 2);
      const x2 = Math.min(canvas.width, px + sampleWidth / 2);
      const y2 = Math.min(canvas.height, py + sampleHeight / 2);

      const imageData = ctx.getImageData(x1, y1, x2 - x1, y2 - y1).data;

      let rTotal = 0;
      let gTotal = 0;
      let bTotal = 0;
      let samples = 0;

      for (let i = 0; i < imageData.byteLength; i += 4) {
        rTotal += imageData[i + 0];
        gTotal += imageData[i + 1];
        bTotal += imageData[i + 2];
        samples += 1;
      }

      if (samples > 0) {
        return `rgb(${Math.round(rTotal / samples)}, ${Math.round(
          gTotal / samples,
        )}, ${Math.round(bTotal / samples)})`;
      }
    }
  }
  return DEFAULT_COVER_COLOR;
}
