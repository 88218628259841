import { DatasetId } from "src/types";
import { usePalette } from "../hooks/immunofluorescence";
import BaseVisualizationControls from "../imaging/Control/ConnectedVisualizationControls";

export default function VisualizationControls({
  dataset,
  plate,
}: {
  dataset: DatasetId;
  plate: string | null;
}) {
  const palette =
    usePalette(
      plate ? { dataset, acquisition: plate } : { skip: true },
    )?.unwrap() ?? null;
  // Disable preprocessing mode for now -- this is a historical implementation where
  // Spring data usually had both raw and processed forms, but most customers will
  // likely only give us one and not both, so it's often confusing to even show
  // the option.
  // Similarly, the render mode is potentially useful, but is mostly confusing without
  // more information and also sort of lonely/weird looking on its own. Hide it until
  // we get explicit feedback it's needed.
  return <BaseVisualizationControls palette={palette} />;
}
