import { ALL_CHANNEL_INDEXES } from "./constants";
import {
  ChannelMap,
  DisplayRange,
  DisplayRanges,
  RenderableImage,
} from "./types";

/**
 * Reconcile the internal and external display ranges.
 *
 * The internal display ranges are computed on the image itself and are
 * equivalent to the image's auto-scaling display range. The external ranges are
 * provided by a parent, and are treated as percentages relative to the internal
 * range. In this way, we can apply reasonable rescaling to each individual
 * image but allow a mechanism by which to brighten or dim _all_ images via
 * external controls.
 */
function reconcileDisplayRanges(
  internalDisplayRange: DisplayRange | null,
  externalDisplayRange: DisplayRange | null,
): DisplayRange | null {
  if (externalDisplayRange == null) {
    return null;
  } else if (internalDisplayRange == null) {
    return null;
  }

  const lowerPct = externalDisplayRange[0] / 65535;
  const upperPct = externalDisplayRange[1] / 65535;
  return [
    internalDisplayRange[0] +
      (internalDisplayRange[1] - internalDisplayRange[0]) * lowerPct,
    internalDisplayRange[1] -
      (internalDisplayRange[1] - internalDisplayRange[0]) * (1 - upperPct),
  ];
}

/**
 * Apply image auto-scaling to modify a set of display ranges.
 */
export function applyAutoScaling(
  imageDatas: Map<number, RenderableImage | null>,
  displayRanges: DisplayRanges,
  channelMap: ChannelMap,
): DisplayRanges {
  return ALL_CHANNEL_INDEXES.map((i) => {
    const mappedIndex = channelMap[i];
    return reconcileDisplayRanges(
      mappedIndex == null
        ? null
        : imageDatas.get(mappedIndex)?.autoDisplayRange ?? null,
      displayRanges[i],
    );
  }) as DisplayRanges;
}
