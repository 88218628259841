import cx from "classnames";
import { useCallback, useRef, useState } from "react";
import { VisualizationContextProvider } from "src/imaging/context";
import MultiChannelView from "src/immunofluorescence/MultiChannelView";
import { useSize } from "src/util/hooks";
import { DatasetListing } from "../types";
import { useCover } from "./hooks";

// For the "zoom" hover effect, we apply a bit of offset to the image and then remove
// it when the nearest tw-group is hovered over.  This constant represents the biggest
// this offset might be (so we can account for it when positioning the image and not
// end up with the image not covering the whole space)
const MAX_ZOOM_EFFECT_OFFSET = 32;

// Renders the "cover" (i.e. representative image) for a dataset
export function DatasetCover({
  className,
  dataset,
  index = 0,
  hoverEffect = "none",
}: {
  className?: string;
  dataset: DatasetListing;
  // If the cover is being displayed in a list of covers, this cover's index in the
  // list.  This is used to select different hover effects for adjacent covers.
  index?: number;
  hoverEffect?: "zoom" | "none";
}) {
  const refPreviewImage = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const previewImageSize = useSize(refPreviewImage, "border-box");

  const size = previewImageSize
    ? previewImageSize.width * (hoverEffect === "zoom" ? 2 : 1)
    : undefined;

  const [cover, handleChannel, handleDefaultReady] = useCover({
    dataset: dataset.id,
    datasetType: dataset.type,
    size,
    canvasRef,
  });
  const [ready, setReady] = useState(false);

  const handleReady = useCallback(() => {
    setReady(true);
    handleDefaultReady?.();
  }, [handleDefaultReady]);

  const maxOffset = hoverEffect === "zoom" ? MAX_ZOOM_EFFECT_OFFSET : 0;

  return (
    <div ref={refPreviewImage} className={cx("tw-relative", className)}>
      {previewImageSize && cover && size && (
        <div
          style={{
            width: previewImageSize.width,
            height: previewImageSize.height,
            backgroundColor: cover.color,
          }}
        >
          <div
            className={cx(
              "tw-overflow-hidden tw-transition-all tw-duration-200",
              hoverEffect === "zoom" &&
                cx(
                  "group-hover:tw-scale-125 group-hover:tw-duration-[3s] group-hover:tw-delay-100",
                  "group-hover:tw-translate-x-0 group-hover:tw-translate-y-0",
                  // NOTE(danlec): These should be at most MAX_OFFSET pixels
                  [
                    "tw-translate-x-8",
                    "-tw-translate-x-4",
                    "-tw-translate-x-8",
                    "tw-translate-x-4",
                  ][index % 4],
                  [
                    "tw-translate-y-8",
                    "-tw-translate-y-4",
                    "tw-translate-y-0",
                    "-tw-translate-y-8",
                    "tw-translate-y-4",
                  ][index % 5],
                ),
              ready ? "tw-opacity-100" : "tw-opacity-0",
            )}
            style={{
              width: size,
              height: size,
              transformOrigin: `${cover.x * size}px ${cover.y * size}px`,
              translate: `${Math.min(
                -maxOffset,
                Math.max(
                  -size + previewImageSize.width + maxOffset,
                  -cover.x * size + previewImageSize.width / 2,
                ),
              )}px ${Math.min(
                -maxOffset,
                Math.max(
                  -size + previewImageSize.height + maxOffset,
                  -cover.y * size + previewImageSize.height / 2,
                ),
              )}px`,
            }}
          >
            <VisualizationContextProvider
              value={{
                visualizationSettings: {
                  channelMap: cover.channelMap,
                  displayRanges: cover.displayRanges,
                  autoScale: false,
                },
                onLoadChannel: handleChannel,
              }}
            >
              <MultiChannelView
                forwardRef={canvasRef}
                crop={null}
                size={size}
                imageSet={cover.imageSet}
                index={{
                  dataset: dataset.id,
                  plate: cover.plate,
                  well: cover.well,
                  field: cover.field,
                  t: 0,
                  z: 0,
                }}
                showContextMenu={false}
                showCursorAnnotations={false}
                showDownloadControls={false}
                showMagnification={false}
                onReady={handleReady}
              />
            </VisualizationContextProvider>
          </div>
        </div>
      )}
    </div>
  );
}
