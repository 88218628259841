import React, { ChangeEvent, useState } from "react";
import { DatasetId, WorkspaceId } from "src/types";
import { Button } from "@spring/ui/Button";
import { Checkbox } from "@spring/ui/Checkbox";
import { Subtitle } from "@spring/ui/typography";
import { FullScreenContainer } from "../../Common/FullScreenContainer";
import Loader from "../../Common/Loader";
import { useTypedQueryParams } from "../../routing";
import { useCreateAllManifestsFile, useFetchParsers } from "../hooks";
import { Block, Input } from "./helpers";

const PARAM_DECODERS = {
  numImageSources: (value: string | undefined) => (value ? parseInt(value) : 1),
  imageSourceUrls: {
    defaultValue: [""],
    fromArray: (value: string[]) => value,
  },
  selectedParser: (value: string | undefined) => value || null,
  cacheEnabled: {
    defaultValue: true,
    fromString: (value: string | undefined) => value !== "false",
  },
};

export function GenerateManifest({
  workspaceId,
  datasetId,
  canUseCache,
  onGenerationComplete,
}: {
  workspaceId: WorkspaceId;
  datasetId: DatasetId;
  canUseCache: boolean;
  onGenerationComplete?: () => void;
}) {
  const [queryParams, setQueryParams] = useTypedQueryParams(PARAM_DECODERS);

  const [isGeneratingManifest, setIsGeneratingManifest] = useState(false);

  const parsersReq = useFetchParsers();

  const createManifest = useCreateAllManifestsFile(workspaceId, datasetId);

  const { numImageSources, imageSourceUrls, selectedParser, cacheEnabled } =
    queryParams;

  const handleNumImageSourcesChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newValue = parseInt(e.target.value, 10);
    const currentLength = imageSourceUrls.length;

    if (newValue > currentLength) {
      setQueryParams((prevState) => ({
        ...prevState,
        numImageSources: newValue,
        imageSourceUrls: [
          ...prevState.imageSourceUrls,
          ...new Array(newValue - currentLength).fill(""),
        ],
      }));
    } else {
      setQueryParams((prevState) => ({
        ...prevState,
        numImageSources: newValue,
        imageSourceUrls: prevState.imageSourceUrls.slice(0, newValue),
      }));
    }
  };

  const handleImageSourceUrlChange = (index: number, value: string) => {
    setQueryParams((prevState) => ({
      ...prevState,
      imageSourceUrls: prevState.imageSourceUrls.map((url, i) =>
        i === index ? value : url,
      ),
    }));
  };

  const handleParserChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setQueryParams((prevState) => ({
      ...prevState,
      selectedParser: e.target.value,
    }));
  };

  const handleToggleEnableCache = (checked: boolean) => {
    setQueryParams((prevState) => ({
      ...prevState,
      cacheEnabled: checked,
    }));
  };

  const handleCreateManifest = async () => {
    try {
      // TODO: cleanup generate and create names
      setIsGeneratingManifest(true);
      await createManifest(imageSourceUrls, selectedParser, cacheEnabled);
      if (onGenerationComplete) {
        onGenerationComplete();
      }
    } finally {
      setIsGeneratingManifest(false);
    }
  };

  if (!parsersReq) {
    return (
      <FullScreenContainer center>
        <Loader />
      </FullScreenContainer>
    );
  }

  if (!parsersReq.successful) {
    console.error(parsersReq.error);
    return (
      <div>
        Oops. Something went wrong fetching available parsers. See console for
        more details.
      </div>
    );
  }
  const parsers = parsersReq.value;

  const canCreateManifest =
    (canUseCache && cacheEnabled) ||
    (imageSourceUrls.length > 0 && imageSourceUrls.every((url) => url !== ""));

  return (
    <>
      <Block>
        <Subtitle>Image Sources</Subtitle>
        {canUseCache && (
          <label className="tw-cursor-pointer tw-flex tw-items-center tw-my-sm">
            <Checkbox
              checked={cacheEnabled}
              onCheckedChange={handleToggleEnableCache}
              defaultChecked={true}
            />
            <span className="tw-ml-xs">Use Cached Paths</span>
          </label>
        )}
        {(!canUseCache || !cacheEnabled) && (
          <Block>
            <Subtitle>Number of Image Sources</Subtitle>
            <input
              type="number"
              min="1"
              value={numImageSources}
              onChange={handleNumImageSourcesChange}
              className="tw-border tw-outline-none tw-w-[50px] tw-my-sm"
            />
            {imageSourceUrls.map((url, index) => (
              <div key={index} className="tw-flex">
                <Input
                  type="text"
                  value={url}
                  onChange={(e) =>
                    handleImageSourceUrlChange(index, e.target.value)
                  }
                  className="tw-border tw-outline-none tw-w-[300px] tw-my-sm"
                  placeholder={`Image Source URL ${index + 1}`}
                />
              </div>
            ))}
          </Block>
        )}
      </Block>
      <Block>
        <Subtitle>File Parser (optional)</Subtitle>
        <select
          value={selectedParser || ""}
          onChange={handleParserChange}
          className="tw-border tw-outline-none tw-mt-sm"
        >
          <option value="" />
          {parsers.map((parser) => (
            <option key={parser} value={parser}>
              {parser}
            </option>
          ))}
        </select>
      </Block>
      <Block>
        {isGeneratingManifest ? (
          <div className="tw-flex tw-justify-center">
            <Loader />
          </div>
        ) : (
          <Button disabled={!canCreateManifest} onClick={handleCreateManifest}>
            Generate Manifest
          </Button>
        )}
      </Block>
    </>
  );
}
