/**
 * Generic component to render a mask encoded as a .tiff.
 *
 * This is misleadingly named "Binary", but in practice, the masks we produce for
 * multi-channel TIFFS have an encoding such that each discrete object gets a unique
 * ordinal value.
 */
import { useContext, useEffect, useRef, useState } from "react";
import { Color } from "src/imaging/types";
import { recordedFetch } from "src/util/recordedFetch";
import UTIF from "utif";
import { RequestContext } from "../context";

export default function BinaryTiff({
  href,
  className,
  style,
  onFetchError,
  // TOOD comment these props or move to type def and comment there
  targetMaskCoordinates,
  invertBackground,
}: {
  href: string | null;
  className?: string;
  style?: { [K: string]: any };
  onFetchError?: (error: Error) => void;
  targetMaskCoordinates?: { row: number; col: number };
  invertBackground?: boolean;
}) {
  const canvasEl = useRef<HTMLCanvasElement>(null);
  const [imageFileDescriptor, setImageFileDescriptor] =
    useState<UTIF.IFD | null>(null);

  const { hostUrl, requestInit } = useContext(RequestContext);

  useEffect(() => {
    const abortController = new window.AbortController();
    async function fetchData() {
      const url = `${hostUrl}${href}`;

      await recordedFetch(url, {
        mode: "cors",
        credentials: "include",
        signal: abortController.signal,
        ...requestInit,
      })
        .then((response) => {
          if (response.status !== 200) {
            console.error(
              `Could not fetch binary tiff at ${url}. Response:`,
              response,
            );
            throw new Error(
              `Could not fetch binary tiff: ${response.statusText} at ${url}`,
            );
          }

          return response.arrayBuffer();
        })
        .then((arrayBuffer) => {
          // Decode the raw TIFF to pixel data.
          const imageFileDescriptor = UTIF.decode(arrayBuffer)[0];
          UTIF.decodeImage(arrayBuffer, imageFileDescriptor);
          setImageFileDescriptor(imageFileDescriptor);
        })
        .catch((e: Error) => {
          if (onFetchError) {
            onFetchError(e);
          }
          throw e;
        });
    }

    if (href) {
      fetchData();
    }
    return () => abortController.abort();
  }, [href, requestInit, onFetchError, hostUrl]);

  useEffect(() => {
    const elt = canvasEl.current;
    if (elt && imageFileDescriptor) {
      const image = new Uint16Array(imageFileDescriptor.data.buffer);

      const canvasSize = imageFileDescriptor.width;
      elt.width = canvasSize;
      elt.height = canvasSize;

      const ctx = elt.getContext("2d")!;
      const dstImageData = ctx.createImageData(canvasSize, canvasSize),
        dstData = dstImageData.data;

      // Canvas offsets.
      const R = 0;
      const G = 1;
      const B = 2;
      const A = 3;

      const targetMaskId = targetMaskCoordinates
        ? getMaskIdAtCoordinates(image, canvasSize, targetMaskCoordinates)
        : null;

      for (let i = 0; i < image.length; i += 1) {
        const value = image[i];

        // Convert pixel index into image coordinates.
        const x = i % canvasSize;
        const y = Math.floor(i / canvasSize);

        const isTargetMask =
          value !== 0 && (!targetMaskId || value === targetMaskId);

        // 1. Draw either a single pixel border around the mask(s), or fill the background
        // around them.
        // 2. Mask colors are maxed so that each channel is picked up by the color filter controlled
        // by the caller.
        let color: Color;
        if (invertBackground) {
          color = isTargetMask
            ? { r: 0, g: 0, b: 0, a: 0 } // Transparent for target mask pixels
            : { r: 255, g: 255, b: 255, a: 1 };
        } else {
          if (isTargetMask && isCellBorderPixel(image, canvasSize, x, y)) {
            color = { r: 255, g: 255, b: 255, a: 1 };
          } else {
            color = { r: 0, g: 0, b: 0, a: 0 }; // Transparent for non-border or non-target mask pixels
          }
        }

        dstData[i * 4 + R] = color.r;
        dstData[i * 4 + G] = color.g;
        dstData[i * 4 + B] = color.b;
        dstData[i * 4 + A] = color.a;
      }
      ctx.putImageData(dstImageData, 0, 0);
    }
  }, [canvasEl, imageFileDescriptor, targetMaskCoordinates, invertBackground]);

  return <canvas className={className} style={style} ref={canvasEl} />;
}

// TODO(davidsharff): this isn't optimized at all, but it's not a bottleneck to my knowledge.
/**
 * Determine if the current pixel's neighbors have a different value, if so, it's a border pixel.
 */
function isCellBorderPixel(
  image: Uint16Array,
  width: number,
  x: number,
  y: number,
) {
  const currentValue = image[y * width + x];

  // Check if any adjacent pixels have a different value or are background pixels
  for (let dy = -1; dy <= 1; dy++) {
    for (let dx = -1; dx <= 1; dx++) {
      if (dy === 0 && dx === 0) {
        continue;
      }

      const newX = x + dx;
      const newY = y + dy;

      // Check if the adjacent pixel is within the image bounds
      if (newX >= 0 && newY >= 0 && newX < width && newY < width) {
        const adjacentValue = image[newY * width + newX];

        // We can't return false here because we want to check all adjacent pixels.
        if (adjacentValue !== currentValue) {
          return true;
        }
      }
    }
  }
  return false;
}

/**
 * Given a set of coordinates (row/col indices), return the value at the corresponding
 * pixel (e.g. mask id for a mask image).
 */
function getMaskIdAtCoordinates(
  image: Uint16Array,
  width: number,
  coordinates: { row: number; col: number },
): number | null {
  const id = image[coordinates.row * width + coordinates.col];

  if (!id) {
    // TODO(davidsharff): send to sentry?
    console.error(`No mask id found at coordinates: ${coordinates}`);
    return null;
  }

  return id;
}
