import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WorkspaceId } from "src/types";
import { useCurrentUser } from "src/user/hooks";
import { Cover } from "src/util/cover";
import { useUserAndLocalStorageBackedState } from "src/util/state-pool-store";

interface LocalModificationBase {
  type: string;
  id: string;
  expires: number;
}

type PerWorkspaceId = `${string}-${string}`;
interface DatasetDisplayModification extends LocalModificationBase {
  id: PerWorkspaceId;
  type: "dataset-display";
  name: string;
  description: string;
  cover: Cover;
}

export function getPerWorkspaceId(
  workspaceId: WorkspaceId,
  id: string,
): PerWorkspaceId {
  return `${String(workspaceId)}-${id}`;
}

type LocalModification = DatasetDisplayModification;

interface LocalModificationsValue {
  modifications: LocalModification[];
  addModification: (modification: LocalModification) => void;
}

const LocalModificationsContext = createContext<LocalModificationsValue>({
  modifications: [],
  addModification: () => {
    throw new Error("Missing LocalModificationsProvider");
  },
});

function LocalModificationsProviderWithUser({
  children,
}: {
  children: ReactNode;
}) {
  const [modifications, , updateModifications] =
    useUserAndLocalStorageBackedState<LocalModification[]>(
      "local-modifications",
      [],
    );

  const addModification = useCallback(
    (modification: LocalModification) => {
      const now = Date.now();

      updateModifications((existing) => [
        ...existing.filter(
          ({ id, type, expires }) =>
            // TODO(danlec): The disable below won't be necessary after we add a second
            // modification type
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            (type !== modification.type || id !== modification.id) &&
            expires >= now,
        ),
        modification,
      ]);
    },
    [updateModifications],
  );

  const value = useMemo(
    () => ({ modifications, addModification }),
    [addModification, modifications],
  );

  return (
    <LocalModificationsContext.Provider value={value}>
      {children}
    </LocalModificationsContext.Provider>
  );
}

// Provides read/write access to a list of modifications that have been made locally,
// which the server may not be telling us about yet due to caching
export function LocalModificationsProvider({
  children,
}: {
  children: ReactNode;
}) {
  const user = useCurrentUser();

  if (user) {
    return (
      <LocalModificationsProviderWithUser>
        {children}
      </LocalModificationsProviderWithUser>
    );
  } else {
    return <>{children}</>;
  }
}

// Gets all current modifications of a given type
export function useLocalModifications<T extends LocalModification>(
  type: T["type"],
): {
  modifications: T[];
  addModification: (modification: T) => void;
} {
  const { modifications, addModification } = useContext(
    LocalModificationsContext,
  );
  const [now, setNow] = useState(() => Date.now());

  // Get any modifications that haven't expired yet
  const getValidModifications = useCallback(
    (now: number) =>
      modifications.filter(
        (modification): modification is T =>
          // TODO(danlec): The disable below won't be necessary after we add a second
          // modification type
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          modification.type === type && modification.expires >= now,
      ),
    [modifications, type],
  );

  const [activeModifications, setActiveModifications] = useState<T[]>(() =>
    getValidModifications(now),
  );

  // If either our current "now" or the list of modifications changes, re-calculate
  // which ones are active
  useEffect(() => {
    setActiveModifications(getValidModifications(now));
  }, [now, getValidModifications]);

  // Periodically update what we think "now" is, so we eventually drop local modifications
  // that expired
  useEffect(() => {
    const interval = setInterval(() => setNow(Date.now()), 60_000);

    return () => clearInterval(interval);
  }, []);

  return { modifications: activeModifications, addModification };
}
