import { z } from "zod";

// Prefs for the current user, irrespective of the current workspace or dataset
export const userPrefsSchema = z
  .object({
    hasSeenPhenofinderDendrogramPopover: z.boolean().default(false),
  })
  .default({});

// Prefs for the current user in the current workspace, irrespective of the current dataset
export const userWorkspacePrefsSchema = z.object({}).default({});

// Prefs for the current user in the current dataset
export const userDatasetPrefsSchema = z.object({}).default({});

export type UserPrefs = z.input<typeof userPrefsSchema>;
export type UserPrefsWithDefaults = z.infer<typeof userPrefsSchema>;

export type UserWorkspacePrefs = z.input<typeof userWorkspacePrefsSchema>;
export type UserWorkspacePrefsWithDefaults = z.infer<
  typeof userWorkspacePrefsSchema
>;

export type UserDatasetPrefs = z.input<typeof userDatasetPrefsSchema>;
export type UserDatasetPrefsWithDefaults = z.infer<
  typeof userDatasetPrefsSchema
>;
