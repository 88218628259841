import { Provider } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { DatasetId } from "src/types";
import { useMethods } from "../Methods/hooks";
import { MethodSectionKey } from "../Methods/utils";
import ToastContainer from "../Toast/ToastContainer";
import history from "../history";
import { ImageLoadEventBatchingContext } from "../imaging/state/hooks";
import configureStore from "../imaging/state/store";
import LandingPage from "./LandingPage";
import ModelEditor from "./ModelEditor";
import { SUPERVISED_LEARNING_TOAST_ID } from "./constants";
import { SupervisedLearningContextProvider } from "./context";

export default function SupervisedLearning({
  dataset,
}: {
  dataset: DatasetId;
}) {
  const { path } = useRouteMatch();
  const store = configureStore(history);
  useMethods(MethodSectionKey.supervisedLearner);
  return (
    <Provider store={store}>
      <ImageLoadEventBatchingContext.Provider value={false}>
        <SupervisedLearningContextProvider dataset={dataset}>
          <div className={"tw-relative"}>
            <Switch>
              <Route exact path={path}>
                <LandingPage dataset={dataset} />
              </Route>
              <Route path={`${path}/edit/:modelId`}>
                <ModelEditor dataset={dataset} />
              </Route>
              <Route path={`${path}/edit`}>
                <ModelEditor dataset={dataset} />
              </Route>
            </Switch>
            <ToastContainer
              id={SUPERVISED_LEARNING_TOAST_ID}
              position={"top"}
            />
          </div>
        </SupervisedLearningContextProvider>
      </ImageLoadEventBatchingContext.Provider>
    </Provider>
  );
}
