import { createContext, useContext } from "react";
import { FeatureSelectorContext } from "./types";

export const Context = createContext<FeatureSelectorContext>({
  dataset: null,
  columnsCache: {},
  updateColumnsCache: () => {},
});

export function useFeatureSelectorContext(): FeatureSelectorContext {
  return useContext(Context);
}
