import cx from "classnames";

/**
 * This mimic's Bootstraps Alert component, which is a big div that's either
 * red yellow or blue.  We generally put these at the top of the content area, under
 * the header.  The original one does a fade in effect; this one doesn't
 */
export function Alert({
  color,
  ...props
}: { color: "danger" | "warning" | "success" } & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) {
  return (
    <div
      className={cx(
        "tw-p-md tw-border",
        color === "danger"
          ? "tw-border-[#f5c2c7] tw-bg-[#f8d7da] tw-text-[#842029]"
          : color === "warning"
            ? "tw-border-[#ffecb5] tw-bg-[#fff3cd] tw-text-[#664d03]"
            : "tw-border-[#badbcc] tw-bg-[#d1e7dd] tw-text-[#0f5132]",
      )}
      {...props}
    />
  );
}
