import React, { ReactNode, useState } from "react";

export interface InMemoryReadWrite<T> {
  state: T;
  setState: (value: T) => void;
  updateState: (updater: (currentState: T) => T) => void;
}

export function InMemoryContext<T>(initialValue: T) {
  const defaultValue: InMemoryReadWrite<T> = {
    state: initialValue,
    setState: () => {
      throw new Error("no state provided");
    },
    updateState: () => {
      throw new Error("no state provided");
    },
  };

  const Context = React.createContext<InMemoryReadWrite<T>>({
    ...defaultValue,
  });

  const Provider = ({
    value,
    children,
  }: {
    value?: Partial<T>;
    children: ReactNode;
  }) => {
    const [state, setState] = useState<T>({ ...initialValue, ...value });

    const sp: InMemoryReadWrite<T> = { state, setState, updateState: setState };
    return React.createElement(Context.Provider, { value: sp }, children);
  };

  return { Provider, Context };
}
