import { css } from "aphrodite";
import { memo, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { VegaLite } from "react-vega";
import { getViewImagesUrlForGroups } from "src/ImageViewerNew/utils";
import { getParentRoute } from "src/util/route";
import { toTitleCase } from "@spring/core/utils";
import { Operator } from "../../../Control/FilterSelector/operations/filter-by";
import {
  createFilterForType,
  createFilterSet,
} from "../../../Control/FilterSelector/utils";
import {
  cleanPredictionName,
  inferTypeFromFeatureSetName,
} from "../../../FeatureSelector/utils";
import { isIndexColumn } from "../../../immunofluorescence/metadata";
import { FeatureLevel, MetadataColumnValue } from "../../../types";
import { fonts, typography } from "../../../util/styles";
import {
  MetadataAndValue,
  StatisticalCorrection,
  StatisticalCorrectionType,
} from "../types";
import {
  SignificanceResult,
  calculateSignificance,
  significanceStars,
} from "../utils";

const MemoizedVega = memo(VegaLite);

const TOOLTIP_KEY_ORDER = [
  "value",
  "Mean",
  "Q1 of value",
  "Median of value",
  "Q3 of value",
];

const OMITTED_TOOLTIP_FIELDS = [
  "overlaysQueryString",
  "Max of value",
  "Min of value",
  "jitter_value",
];

interface Props {
  data: MetadataAndValue[];
  groupKey: string;
  domain: MetadataColumnValue[];
  colors: string[];
  showPoints: boolean;
  yAxisMinValue: number;
  yAxisMaxValue: number;
  yAxisIsVisible: boolean; // If false, it hides ticks, labels, and the line
  yAxisTitle: string | null;
  xAxisLabelRotationDeg: number; // e.g 0 === parallel labels / -90 === perpendicular
  significanceValue: number | null; // Ranked sum p-value
  statisticalCorrection: StatisticalCorrection;
  compareTo: MetadataColumnValue; // Pinned group used for the comparison that produces the significanceValue
  // Mean of the entire compare to group (i.e. ignores subgroups for now). It will be null when rendering the compareTo group.
  compareToMean: number | null;
  mean: number | null; // This group's mean
  featureName: string;
  featureLevel: FeatureLevel;
  column: string;
  // Metadata to show in tooltip
  metadataColumns: string[];
  graphContainerHeight: number;
  boxPlotWidth: number;
  parentGroupData: null | {
    groupKey: string;
    groupValue: MetadataColumnValue;
  };
}
/**
 * Renders box plots, with optional jittered points, with the display optimized for a single plot. We use it to
 * have more flexibility with grouping than can be done cleanly with our current version of vega-lite (by rendering
 * individual plots and organizing the layout in the DOM).
 */
export default function BoxPlot({
  data,
  groupKey,
  domain,
  colors,
  showPoints,
  yAxisMinValue,
  yAxisMaxValue,
  yAxisIsVisible,
  yAxisTitle,
  xAxisLabelRotationDeg,
  significanceValue,
  statisticalCorrection,
  compareTo,
  compareToMean,
  mean,
  featureName,
  featureLevel,
  column,
  metadataColumns,
  graphContainerHeight,
  boxPlotWidth,
  parentGroupData,
}: Props) {
  // TODO(benkomalo): this use of featureLevel doesn't make sense for histology.
  // TODO(davidsharff): PhenoFinder, PhenoSorter, and SSC features all end in Prediction. Since they
  // can't be disambiguated by name to determine the underlying level it's safest to assume a field
  // level and always link to the plate viewer for now.
  featureLevel = featureName.endsWith("Prediction") ? "field" : featureLevel;

  const location = useLocation();
  // TODO(davidsharff): we could do this sanitization with an "as" alias in the original query
  // but the first time I tried it broke the compareTo selection. Sanitizing here instead for safety
  // ahead of a major launch.
  const sanitizedData = useMemo(() => {
    const keyMapping: { [key: string]: string } = Object.fromEntries(
      [groupKey, ...metadataColumns].map((column) => [
        column,
        sanitizeKeyForVega(column),
      ]),
    );

    const imageBasePath = showPoints
      ? getImageBasePath(location.pathname, featureLevel)
      : "";

    return data.map((value) => {
      const sanitized: MetadataAndValue = {
        plate: value.plate,
        well: value.well,
        value: Number(value.value),
        jitter_value: value.jitter_value,
      };

      for (const [key, sanitizedKey] of Object.entries(keyMapping)) {
        sanitized[sanitizedKey] = value[key];
      }

      if (showPoints) {
        sanitized["imageLinkUrl"] = linkWellToImage(
          value,
          imageBasePath,
          featureLevel,
        );
      }

      return sanitized;
    });
  }, [
    groupKey,
    data,
    featureLevel,
    showPoints,
    location.pathname,
    metadataColumns,
  ]);

  const linkBoxplotToImagesUrl = linkBoxplotToImages(
    location.pathname,
    compareTo,
    data[0][groupKey],
    groupKey,
    parentGroupData,
  );

  const sanitizedGroupKey = sanitizeKeyForVega(groupKey);
  const fontFamily = fonts.apercuRegular.fontFamily;

  // TODO(benkomalo): sad this doesn't typecheck. We should put an explicit type
  // to either TopLevelSpec from "vega-lite" or VisualizationSpec from "vega-embed".
  // TODO(davidsharff): there isn't enough horizontal space if they compare to something with a lot of subgroups
  const significanceResult = significanceValue
    ? calculateSignificance(significanceValue)
    : null;
  const vegaSpec = useMemo(
    () => ({
      $schema: "https://vega.github.io/schema/vega-lite/v4.json",
      width: boxPlotWidth + 36,
      padding: 0,
      data: {
        values: sanitizedData,
      },
      layer: [
        {
          mark: {
            type: "boxplot",
            extent: "min-max",
            size: boxPlotWidth,
            opacity: 0.5,
          },
          encoding: {
            x: {
              field: sanitizedGroupKey,
              type: "nominal",
              axis: {
                title: null,
                labelFontSize: 12,
                labelAngle: xAxisLabelRotationDeg,
                labelLimit:
                  // Prevent the label from overflowing the box plot and increasing its total width.
                  // This is necessary to keep subgrouped boxplots consistently spaced.
                  Math.abs(xAxisLabelRotationDeg / 90) * boxPlotWidth +
                  boxPlotWidth,
              },
            },
            y: {
              field: "value",
              type: "quantitative",
              axis: yAxisIsVisible
                ? {
                    ticks: false,
                    grid: false,
                    title: yAxisTitle,
                  }
                : {
                    title: null,
                    labelExpr: "",
                    ticks: false,
                    grid: false,
                    domainColor: "white", // Hides the border/anchor line for the axis
                  },
              scale: { domain: [yAxisMinValue, yAxisMaxValue], clamp: true },
            },
            color: {
              field: sanitizedGroupKey,
              type: "nominal",
              scale: {
                domain,
                range: colors,
              },
              legend: null,
            },
            ...(linkBoxplotToImagesUrl
              ? { href: { value: linkBoxplotToImagesUrl } }
              : {}),
          },
        },
        ...(showPoints
          ? [
              {
                transform: [
                  {
                    filter: {
                      field: "value",
                      range: [yAxisMinValue, yAxisMaxValue],
                    },
                  },
                  {
                    joinaggregate: [
                      {
                        op: "q1",
                        field: "value",
                        as: "q1",
                      },
                      {
                        op: "q3",
                        field: "value",
                        as: "q3",
                      },
                      {
                        op: "median",
                        field: "value",
                        as: "median",
                      },
                    ],
                    groupby: [sanitizedGroupKey],
                  },
                ],
                mark: {
                  type: "point",
                  filled: true,
                  size: 40,
                },
                encoding: {
                  x: {
                    field: "jitter_value",
                    type: "quantitative",
                    axis: {
                      title: null,
                      labels: false,
                      ticks: false,
                      domainColor: "white",
                    },
                    scale: { domain: [-1, 2] },
                  },
                  y: {
                    field: "value",
                    type: "quantitative",
                    scale: { domain: [yAxisMinValue, yAxisMaxValue] },
                  },
                  color: {
                    field: sanitizedGroupKey,
                    type: "nominal",
                    scale: {
                      domain,
                      range: colors,
                    },
                    legend: null,
                  },
                  tooltip: [
                    { field: "q1", title: "Q1 of value" },
                    { field: "q3", title: "Q3 of value" },
                    { field: "median", title: "Median of value" },
                    ...Object.keys(sanitizedData[0]).map((key) => ({
                      field: key,
                    })),
                  ],
                  href: { field: "imageLinkUrl" },
                },
              },
            ]
          : []),
        {
          mark: {
            type: "text",
            lineHeight: 0,
            fontWeight: 500,
            fontSize: 18,
            fill: "#555",
            // TODO: update appearance to match MegaMap stars
            text:
              significanceResult == null
                ? " " // Always render something so all graph heights are constant.
                : significanceStars(significanceResult),
            yOffset: calculateSigStartOffset(graphContainerHeight),
            // Hack(davidsharff): vega won't supply aggregate data (quartile/median) for this kind of mark,
            // and if we do turn on its default tooltip, it will grab an arbitrary record and display its content
            // as if it was a point/well. We override this behavior by supplying a hardcoded string which
            // our formatter will convert into a tooltip containing only the significance description.
            tooltip: "significanceStars",
          },
        },
      ],
      config: {
        view: {
          stroke: "transparent",
        },
        axis: { labelFont: fontFamily, titleFont: fontFamily },
        legend: { labelFont: fontFamily, titleFont: fontFamily },
        header: { labelFont: fontFamily, titleFont: fontFamily },
        mark: { font: fontFamily },
        title: { font: fontFamily, subtitleFont: fontFamily },
      },
    }),
    [
      boxPlotWidth,
      colors,
      domain,
      fontFamily,
      graphContainerHeight,
      linkBoxplotToImagesUrl,
      sanitizedData,
      sanitizedGroupKey,
      showPoints,
      significanceResult,
      xAxisLabelRotationDeg,
      yAxisIsVisible,
      yAxisMaxValue,
      yAxisMinValue,
      yAxisTitle,
    ],
  );

  const loader = useMemo(() => ({ target: "_blank" }), []);

  const formatTooltip = useCallback(
    (
      tooltipData: Record<string, string | number> | "significanceStars",
      valueToHtml: (value: any) => string,
    ) => {
      // Unfortunate. Tooltips for sig stars are turned on with a string value because setting it
      // to an object causes it to create tooltip fields for all content.
      tooltipData =
        typeof tooltipData === "string" ? { type: tooltipData } : tooltipData;
      const isProbabilityRange = yAxisMinValue >= 0 && yAxisMaxValue < 1;

      const decimalPointsSourceNumber = isProbabilityRange
        ? // If the range includes only real numbers between 0 and 1, use the min value
          // but never actually 0 since the log() (used below) would resolve to infinity.
          Math.max(yAxisMinValue, 0.0001)
        : // Else, we only care to increase precision if the range spread is a real number < 1
          yAxisMaxValue - yAxisMinValue;

      const baseDecimalPoints = 1;
      const extraDecimalPoints =
        Math.abs(decimalPointsSourceNumber) > 1
          ? 0
          : -Math.ceil(
              Math.log(Math.abs(decimalPointsSourceNumber)) / Math.log(10),
            );

      const decimalPoints = baseDecimalPoints + extraDecimalPoints;

      const meanDifference =
        compareToMean !== null && mean !== null ? mean - compareToMean : null;

      const groupData = data[0];
      const groupValue = groupData[groupKey];

      const entries = createTooltipEntries(tooltipData, mean);

      const sharedEntries = entries.filter(
        ([key]) => TOOLTIP_KEY_ORDER.includes(key + "") && key !== groupKey,
      );

      const sharedTooltips = sharedEntries
        .map(([field, val]) =>
          createTooltipRowHTML(
            field,
            isNaN(Number(val)) ? val : roundTo(Number(val), decimalPoints),
            valueToHtml,
          ),
        )
        .join("");

      const metadataEntries = entries.filter(
        ([key]) => !TOOLTIP_KEY_ORDER.includes(key + ""),
      );

      const metadataTooltips = metadataEntries
        .filter(
          ([field]) => !isIndexColumn(field) && metadataColumns.includes(field),
        )
        .map(([field, val]) => createTooltipRowHTML(field, val, valueToHtml))
        .join("");

      const maxMetadataCharacters = metadataEntries.reduce(
        (maxCount, [key, value]) => {
          const keyCount = key.length;
          const valueCount = value.toString().length;
          return Math.max(maxCount, keyCount, valueCount);
        },
        0,
      );

      const sectionStyleStr =
        "'border-top-color:#CCC; border-top-width: 1px; border-top-style: solid; margin-top: 20px;'";

      const spacerHTML = '<div style="margin-top: 20px;" />';

      const plate = metadataEntries.find(([field]) => field === "plate")?.[1];
      const well = metadataEntries.find(([field]) => field === "well")?.[1];

      // The box and stars tooltips aren't supplied the metadata so they won't append plate/well.
      const mainSectionLabel =
        groupValue + (plate && well ? ` - ${plate} ${well}` : "");

      const mainTooltipSectionsHTML = `
      <span>
        ${createTooltipSectionHeaderHTML(valueToHtml(mainSectionLabel))}
        ${sharedTooltips}
        ${
          metadataTooltips &&
          `
            <div style=${sectionStyleStr}>
              ${spacerHTML}
              ${metadataTooltips}
            </div>
          `
        }
      </span>
    `;

      return `
      <div 
      style="width: ${
        decimalPoints > 3 ||
        maxMetadataCharacters > 11 ||
        mainSectionLabel.length > 25
          ? 325
          : 265
      }px; padding: 0 20px;"
      class="${css(typography.caption)}"
      >
        ${
          tooltipData.type !== "significanceStars"
            ? mainTooltipSectionsHTML
            : ""
        }
        ${
          meanDifference && significanceResult?.type === "significant"
            ? `<div
                class="${css(typography.smallBodyCopy)}"
                style=${
                  tooltipData.type !== "significanceStars"
                    ? sectionStyleStr
                    : ""
                }
                >
                 ${spacerHTML}
                 <span style="font-size: 14px;">
                   ${createSignificanceDesc(
                     groupValue as string | number, // We can't create a tooltip for data that doesn't exist
                     meanDifference,
                     featureName,
                     compareTo,
                     significanceResult,
                     column,
                     valueToHtml,
                     statisticalCorrection,
                   )}
                 </span>
               </div>`
            : ""
        }
        ${spacerHTML}
      </div>
    `;
    },
    [
      column,
      compareTo,
      compareToMean,
      data,
      featureName,
      groupKey,
      mean,
      metadataColumns,
      significanceResult,
      yAxisMaxValue,
      yAxisMinValue,
      statisticalCorrection,
    ],
  );

  const toolTipSpec = useMemo(
    () => ({
      theme: "dark",
      // This function is called (only) onHover for any mark with a tooltip. It is handed the tooltip data and is
      // responsible for the HTML display (which it returns as a string). We use it for both formatting and injecting
      // our own calculated values (like the mean).
      formatTooltip,
    }),
    [formatTooltip],
  );

  return (
    <MemoizedVega
      // TODO(davidsharff): I think it is angry about the dynamic variables not maching string unions and such.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      spec={vegaSpec}
      height={graphContainerHeight}
      actions={false}
      loader={loader}
      tooltip={toolTipSpec}
    />
  );
}

/**
 * Transform the tooltip data into an array of entries with the tooltip field in the first position,
 * and value in the second. The array is sorted based on a hard coded order.
 */
function createTooltipEntries(
  tooltipData: Record<string, string | number>,
  mean: number | null,
): [string, string | number][] {
  return [
    ...Object.entries(tooltipData),
    ...(tooltipData.type !== "significanceStars" && mean !== null
      ? [["Mean", mean]]
      : []),
  ]
    .filter(([field]) => !OMITTED_TOOLTIP_FIELDS.some((f) => f === field))
    .sort(([fieldA], [fieldB]) => {
      const sortValA = TOOLTIP_KEY_ORDER.some((f) => f === fieldA)
        ? TOOLTIP_KEY_ORDER.indexOf(fieldA + "")
        : Infinity;

      const sortValB = TOOLTIP_KEY_ORDER.some((f) => f === fieldB)
        ? TOOLTIP_KEY_ORDER.indexOf(fieldB + "")
        : Infinity;

      return sortValA < sortValB ? -1 : 1;
    })
    .map(([field, value]) => [field + "", value]);
}

/**
 * Concatenate a human-friendly sentence of the uTest significance.
 */
function createSignificanceDesc(
  groupValue: string | number,
  meanDifference: number,
  featureName: string,
  compareToGroup: MetadataColumnValue,
  significanceResult: SignificanceResult,
  column: string,
  sanitizer: (rawVal: string | number) => string,
  statisticalCorrection: StatisticalCorrection,
): string {
  if (significanceResult.type !== "significant") {
    return "";
  }
  const correctedStr =
    statisticalCorrection.type === StatisticalCorrectionType.None
      ? "uncorrected"
      : "corrected";
  const sanitizedFeatureName =
    inferTypeFromFeatureSetName(featureName) === "prediction"
      ? cleanPredictionName(decodeURIComponent(featureName))
      : featureName;
  const pValueLabel = `${correctedStr}-p&nbsp;&lt;&nbsp;${significanceResult.threshold}`;
  return (
    sanitizer(
      `${groupValue} ${
        meanDifference > 0 ? "increased" : "decreased"
      } in "${sanitizedFeatureName} - ${column}" compared to ${compareToGroup}`,
    ) +
    // Don't sanitize. We need to preserve the non-breaking spaces
    ` (${pValueLabel}).`
  );
}

/**
 * Create HTML string for a tooltip section header.
 */
function createTooltipSectionHeaderHTML(label: string): string {
  return `
  <div style="margin-bottom: 15px;" class="${css(typography.smallBodyCopy)}">
    <span style="font-weight: bold; margin-bottom: 15px; border-bottom: white 1px solid; flex: 0; font-size: 14px;">
      ${label}
    </span>
  </div>
`;
}
/**
 * Create an HTML string of a tooltip key/val pair.
 */
function createTooltipRowHTML(
  field: string,
  value: MetadataColumnValue,
  sanitizer: (rawVal: string | number) => string,
): string {
  field = field.replace(" of value", "");
  return `
    <div style="display: flex; flex-direction: row; justify-content: space-between;">
      <div style="margin-right: 5px; flex: 1; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
        ${sanitizer(toTitleCase(field))}
      </div>
      <div style="text-align: left; flex: 1; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
        ${sanitizer(value + "")}
      </div>
    </div>
  `;
}

function roundTo(value: number, decimalsToShow: number) {
  return parseFloat(value.toFixed(decimalsToShow));
}

function sanitizeKeyForVega(key: string) {
  key = key.replace(".", "_"); // We know dots in the domain break vega
  return key;
}

/**
 * Concatenate the plate & well on to the base url for the targeted image view.
 */
function linkWellToImage(
  record: MetadataAndValue,
  imageBasePath: string,
  featureLevel: string,
) {
  const plate = encodeURIComponent(record.plate);
  const well = encodeURIComponent(record.well);

  // Switch to overlays tab
  if (featureLevel === "cell") {
    const searchParams = new URLSearchParams(window.location.search);
    // TODO(benkomalo): hack hack hack -- the dataset filter does not apply to the
    // overlays tab, so we forcefully and hackily remove it here because the dataset
    // filter encodes itself in the URL param, and that param changes as the user
    // interacts with the filter, even in incomplete states. Those interactions,
    // unfortunately cause massive re-renders which make the interaction very sluggish,
    // so we remove the param here to not encode it in the data we send to vega, to
    // avoid re-renders.
    // See https://github.com/spring-discovery/spring-experiments/pull/7744 for
    // more details.
    searchParams.delete("serializedFilter");
    searchParams.append("plate", plate);
    searchParams.append("well", well);

    searchParams.delete("tab");
    searchParams.append("tab", "overlays");
    return imageBasePath + `?${searchParams.toString()}`;
  } else {
    // Show in image viewer
    return imageBasePath + `/${plate}/${well}`;
  }
}

/**
 * Create the base path for linking points to an image view target based on the feature level. This is seperated
 * from the final ta creation primarily for performance reasons.
 */
function getImageBasePath(currentPathname: string, featureLevel: string) {
  // Overlays is a different tab in the same view
  if (featureLevel === "cell") {
    return `${currentPathname}`;
  } else {
    // Image Viewer is on a different route
    const parentRoute = getParentRoute(currentPathname);

    return `${parentRoute}/data`;
  }
}

function linkBoxplotToImages(
  currentPathname: string,
  compareToValue: MetadataColumnValue,
  groupValue: MetadataColumnValue,
  groupKey: string,
  parentGroupData: null | { groupKey: string; groupValue: MetadataColumnValue },
) {
  // TODO(davidsharff): null filter values aren't supported so we can't
  // set the image group filter.
  if (groupValue === null || compareToValue === null) {
    return;
  }

  const groups = [
    {
      id: "compareTo",
      filterSet: createFilterSet(
        [
          createFilterForType(
            // We can only use the parent for comparisons (compareToValue is the parent group value)
            parentGroupData?.groupKey || groupKey,
            compareToValue,
          ),
        ],
        Operator.AND,
      ),
    },
  ];

  if (groupValue !== compareToValue) {
    const filterSet = createFilterSet(
      [
        createFilterForType(groupKey, groupValue),
        ...(parentGroupData
          ? [
              createFilterForType(
                parentGroupData.groupKey,
                parentGroupData.groupValue,
              ),
            ]
          : []),
      ],
      Operator.AND,
    );

    groups.push({
      id: "target",
      filterSet,
    });
  }

  return getViewImagesUrlForGroups(currentPathname, groups);
}

/**
 * Returns the vertical offset in pixels for aligning the sig stars at the top
 * of the graph. It starts with a known offset for a set graph height and adjusts
 * from there.
 */
function calculateSigStartOffset(graphContainerHeight: number) {
  const defaultHeight = 500;
  const defaultOffset = -275;

  const pctSizeChange = (defaultHeight - graphContainerHeight) / defaultHeight;
  const offsetChange = defaultOffset * pctSizeChange;

  return defaultOffset - offsetChange;
}
