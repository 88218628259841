import { ReactNode, useEffect } from "react";
import {
  EventTrackingContext,
  TrackingContext,
  useEventTrackingContext,
  useGlobalEventTrackingContext,
} from "./Context";

function removeUndefinedKeys(
  object: Partial<TrackingContext>,
): TrackingContext {
  return Object.fromEntries(
    Object.entries(object).filter(([, value]) => value !== undefined),
  ) as TrackingContext;
}

/**
 * Wrapper to provide additional context to any events fired by useEventTracker().
 *
 * @param global - Global context such as page/subpage information that should be available to
 *                 any component of the app, including those that exist further up the tree.
 *                 A given key can only be defined once within the global context.
 *
 * @param context - Context specific to the part of the app that should only be available to
 *                  any children of this <Track /> component. <Track /> components can be
 *                  nested. In the case of duplicate keys, useEventTracker() will use the value
 *                  provided by the most direct parent.
 */
export function Track({
  children,
  global,
  context,
}: {
  children: ReactNode;
  global?: Partial<TrackingContext>;
  context?: Partial<TrackingContext>;
}) {
  const { pushGlobalContext } = useGlobalEventTrackingContext();
  const trackingContext = useEventTrackingContext();

  // Manage pushing and popping to global context if needed
  useEffect(() => {
    if (global === undefined) {
      return;
    }

    return pushGlobalContext(removeUndefinedKeys(global));
  }, [global, pushGlobalContext]);

  return (
    <EventTrackingContext.Provider
      value={{
        ...trackingContext,
        ...removeUndefinedKeys(context ?? {}),
      }}
    >
      {children}
    </EventTrackingContext.Provider>
  );
}
