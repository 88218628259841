/**
 * Component for rendering the standard prod quality control report.
 */
import { DatasetId } from "src/types";
import PlateDistributionsView from "./PlateDistributionsView";
import { RegressionModel } from "./types";

export type Tab = "plate";

export default function StandardQualityControlReport({
  dataset,
  plate,
  wells,
  tab,
  regressionModel,
}: {
  dataset: DatasetId;
  plate: string;
  wells: string[];
  tab: Tab;
  onSelectTab: (tab: Tab) => void;
  regressionModel: RegressionModel;
}) {
  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return tab === "plate" ? (
    <PlateDistributionsView
      dataset={dataset}
      plate={plate}
      regressionModel={regressionModel}
      wells={wells}
    />
  ) : (
    <></>
  );
}
