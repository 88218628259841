import { AsyncDuckDB } from "@duckdb/duckdb-wasm";
import cx from "classnames";
import isEqual from "lodash.isequal";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DatasetId } from "src/types";
import * as vega from "vega";
import { Success } from "@spring/core/result";
import { Button } from "../Common/Button";
import FilterSelector from "../Control/FilterSelector";
import { FilterPreview } from "../Control/FilterSelector/FilterPreview";
import { Operator } from "../Control/FilterSelector/operations/filter-by";
import { FilterSet } from "../Control/FilterSelector/types";
import {
  clausesFromFilterSet,
  serializeToSqlClause,
  updateFilters,
  updateOperator,
  useMetadataFilterColumns,
  validateFilter,
} from "../Control/FilterSelector/utils";
import { EditorStepCommonProps, ModelTrainingConfig } from "./types";

const EMPTY_FILTER = {
  filters: [],
  operator: Operator.AND,
  ignoredFilters: [],
};

export default function FilterConfigurator({
  dataset,
  metadata,
  config,
  setConfig,
  onReadyToAdvanceChanged,
}: EditorStepCommonProps & {
  dataset: DatasetId;
  metadata: AsyncDuckDB;
  config: Partial<ModelTrainingConfig>;
  setConfig: Dispatch<SetStateAction<Partial<ModelTrainingConfig>>>;
  onReadyToAdvanceChanged: (ready: boolean) => void;
}) {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const [topLevelFilterType, setTopLevelFilterType] = useState<
    "all_wells" | "some_wells"
  >("all_wells");

  interface FilterType {
    title: string;
    state: "all_wells" | "some_wells";
    description: string;
  }

  const filterTypes: FilterType[] = [
    {
      title: "All wells",
      state: "all_wells",
      description:
        "Best for simple experiments, where there are few experimental variables.",
    },
    {
      title: "A subset of your experiment",
      state: "some_wells",
      description:
        "Best if you want to target certain wells or if you want to exclude" +
        " a confounding variable.",
    },
  ];

  // TODO (Hosny): The default state should try reading the config.data is there is a
  //  filter there. Otherwise, use an empty filter. This will allow the filters to
  //  persist across steps. Should use sqlToFilterSet(config.data.filter).

  const [filter, setFilter] = useState<FilterSet>(EMPTY_FILTER);

  const filterColumns = useMetadataFilterColumns(
    dataset,
    Success.of(metadata),
    null,
  );

  const validateAndSetFilter = useCallback(
    (filter: FilterSet) => {
      validateFilter(metadata, filter).then(setFilter);
      setConfig({
        ...config,
        data: {
          dataset,
          filter: serializeToSqlClause(filter),
          classes: [],
        },
      });
    },
    [metadata, dataset, config, setConfig],
  );

  useEffect(() => {
    topLevelFilterType == "all_wells" && onReadyToAdvanceChanged(true);
  }, [onReadyToAdvanceChanged, topLevelFilterType]);

  useEffect(() => {
    if (topLevelFilterType == "all_wells") {
      setFilter(EMPTY_FILTER);
    }
  }, [topLevelFilterType]);

  useEffect(() => {
    topLevelFilterType == "some_wells" &&
      filter.filters.length == 0 &&
      onReadyToAdvanceChanged(false);
  }, [onReadyToAdvanceChanged, topLevelFilterType, filter]);

  useEffect(() => {
    topLevelFilterType == "some_wells" &&
      filter.filters.length >= 1 &&
      onReadyToAdvanceChanged(true);
  }, [onReadyToAdvanceChanged, topLevelFilterType, filter]);

  const clauses = useMemo(() => {
    return clausesFromFilterSet(filter);
  }, [filter]);

  const getColor = useMemo(() => {
    const colorScheme = vega.scheme(
      clauses.length > 10 ? "tableau20" : "tableau10",
    );

    return (index: number) => {
      return colorScheme[index % colorScheme.length];
    };
  }, [clauses.length]);

  const SuggestionButton = useCallback(
    ({
      title,
      selected,
      onSelect,
      description,
    }: {
      title: string;
      selected: boolean;
      onSelect: () => void;
      description: string;
    }) => {
      return (
        <Button
          name={title}
          className={cx(
            "tw-w-full tw-relative",
            selected && "tw-border-purple tw-border-2 tw-text-purple",
          )}
          onClick={onSelect}
        >
          <div
            aria-hidden
            className={cx(
              "tw-absolute tw-right-4 tw-top-[50%] -tw-mt-[15px]",
              "tw-w-[28px] tw-h-[28px]",
              "tw-flex tw-items-center tw-justify-center",
              "tw-bg-purple tw-rounded-full tw-text-white tw-font-bold",
              "tw-transition-opacity tw-duration-[50ms]",
              selected ? "tw-opacity-100" : "tw-opacity-0",
            )}
          >
            ✓
          </div>
          <div
            className={
              "tw-p-2 tw-flex tw-flex-col tw-items-start tw-text-left tw-pr-8"
            }
          >
            <div>{title}</div>
            <div className={"tw-text-sm tw-opacity-60"}>{description}</div>
          </div>
        </Button>
      );
    },
    [],
  );

  return (
    <div className={"tw-flex tw-bg-slate-100 tw-h-full tw-overflow-hidden"}>
      <div className={"tw-flex-1 tw-p-8 tw-bg-white tw-flex tw-flex-col"}>
        {topLevelFilterType == "all_wells" && (
          <>
            <div className={"tw-text-lg tw-mb-4"}>
              Which wells do you want the model to learn from?
            </div>
            <div className="tw-mt-4 tw-grid tw-grid-cols-1 tw-gap-4">
              {filterTypes.map((filterType) => (
                <SuggestionButton
                  key={filterType.state}
                  title={filterType.title}
                  selected={isEqual(topLevelFilterType, filterType.state)}
                  onSelect={() => {
                    setTopLevelFilterType(filterType.state);
                  }}
                  description={filterType.description}
                />
              ))}
            </div>
          </>
        )}
        {filterColumns?.successful && topLevelFilterType == "some_wells" && (
          <div
            className={
              "tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto tw-p-2 -tw-m-2"
            }
          >
            <div className={"tw-flex tw-mb-sm"}>
              <button
                className={"tw-text-sm tw-text-gray-500"}
                onClick={() => setTopLevelFilterType("all_wells")}
              >
                Filtering on{" "}
                <span className={"tw-text-black tw-underline"}>
                  {"Some Wells"}
                </span>
              </button>
            </div>
            <FilterSelector
              columns={filterColumns.value}
              metadata={metadata}
              filterSet={filter}
              showColors
              onChangeFilters={(filters) =>
                validateAndSetFilter(updateFilters(filter, filters))
              }
              onChangeOperator={(operator, newFilters) =>
                validateAndSetFilter(
                  updateOperator(filter, operator, newFilters),
                )
              }
              onChangeSelected={setSelectedIndex}
            />
          </div>
        )}
      </div>
      <div className={"tw-flex-1 tw-p-8 tw-flex tw-flex-col"}>
        {filterColumns?.successful && topLevelFilterType == "some_wells" && (
          // TODO (Hosny): Expand the div to fill the parent. Currently has ugly
          //  horizontal and vertical scroll bars.
          <FilterPreview
            sql={serializeToSqlClause(filter)}
            clauses={clausesFromFilterSet(filter)}
            operator={filter.operator}
            getColor={getColor}
            selectedIndex={selectedIndex}
            metadata={metadata}
          />
        )}
      </div>
    </div>
  );
}
