// The components in this file are for a UI for editing the name of an existing class,
// deleting said class, or for adding a new class.
import * as Dialog from "@radix-ui/react-dialog";
import cx from "classnames";
import React, { ReactNode, useCallback, useState } from "react";
import { DeprecatedButton } from "../Common/DeprecatedButton";
import { useLabeledSetContext } from "./Context";
import { REQUIRED_CLASSES } from "./constants";

export function AddEditClass({
  initialName,
  children,
}: {
  initialName?: string;
  children: ReactNode;
}) {
  const { state: labeledSetState, setState: setLabeledSetState } =
    useLabeledSetContext();
  const { classifications } = labeledSetState;
  const [name, setName] = useState(initialName ?? "");
  const [open, setOpen] = useState(false);

  const onInput = useCallback<React.FormEventHandler<HTMLInputElement>>((e) => {
    setName(e.currentTarget.value);
  }, []);

  const saveDisabled =
    !/\S/.test(name) ||
    (name !== initialName &&
      labeledSetState.classifications.some(
        (classification) => classification.name === name,
      ));

  const deleteDisabled =
    labeledSetState.classifications.length <= REQUIRED_CLASSES;

  const onSave = useCallback(() => {
    if (saveDisabled) {
      return;
    }
    setLabeledSetState({
      ...labeledSetState,
      classifications: initialName
        ? classifications.map((classification) =>
            classification.name === initialName
              ? {
                  ...classification,
                  name,
                }
              : classification,
          )
        : [
            ...classifications,
            {
              name,
              examples: [],
            },
          ],
    });

    setOpen(false);
    setName("");
  }, [
    classifications,
    initialName,
    labeledSetState,
    name,
    saveDisabled,
    setLabeledSetState,
  ]);

  const onDelete = useCallback(() => {
    setLabeledSetState({
      ...labeledSetState,
      classifications: classifications.filter(
        (classification) => classification.name !== initialName,
      ),
    });
    setOpen(false);
    setName("");
  }, [classifications, initialName, labeledSetState, setLabeledSetState]);

  const onKeyDown = useCallback<React.KeyboardEventHandler<HTMLInputElement>>(
    (e) => {
      if (e.key === "Enter") {
        onSave();
      }
    },
    [onSave],
  );

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay
          className={cx(
            "tw-bg-slate-500/[0.5] tw-z-dialog tw-absolute tw-inset-0",
            "tw-overflow-auto",
            "tw-flex",
          )}
        >
          <Dialog.Content
            className={cx(
              "tw-p-4 tw-w-[640px] tw-bg-white tw-shadow-lg tw-rounded-lg tw-m-auto",
            )}
          >
            <h6 className="tw-font-medium tw-mb-sm">
              {initialName ? "Edit Class" : "Create New Class"}
            </h6>
            <input
              className="tw-w-full tw-bg-gray-100 tw-p-2"
              type="text"
              placeholder="Class Name..."
              value={name}
              onInput={onInput}
              onKeyDown={onKeyDown}
              autoFocus
            ></input>

            <hr className="tw-my-md" />

            <div
              className={cx(
                "tw-grid tw-gap-2",
                initialName ? "tw-grid-cols-2" : "tw-grid-cols-1",
              )}
            >
              <DeprecatedButton
                className={cx("tw-w-full")}
                variant="primary"
                disabled={saveDisabled}
                onClick={onSave}
              >
                Save
              </DeprecatedButton>
              {initialName && (
                <DeprecatedButton
                  className={cx("tw-w-full")}
                  variant="secondary"
                  disabled={deleteDisabled}
                  onClick={onDelete}
                >
                  Delete
                </DeprecatedButton>
              )}
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
