/**
 * React hooks specific to image visualization.
 */
import { createContext, useContext } from "react";
import {
  batch,
  useDispatch as useDispatchParent,
  useSelector,
} from "react-redux";
import { defaultProcessingMode, processingModes } from "../image-sets";
import {
  FetchSettings,
  ImageSet,
  Palette,
  VisualizationSettings,
  VisualizationState,
} from "../types";
import { toVisualizationSettings } from "../util";
import { Action } from "./actions";
import { State } from "./store";

let _QUEUE: Action[] = [];
let timer: ReturnType<typeof setTimeout> | null = null;

// Context to allow turning of image load event batching to optimize specific
// cases. Possible gotcha: the context provider must wrap the call to
// `useDispatch`, not just the site from which the message is dispatched.
// TODO(charlie): This doesn't behave correctly in the presence of multiple
// independent stores being used concurrently.
export const ImageLoadEventBatchingContext = createContext(true);

export const useDispatch = (): ((action: Action) => void) => {
  const dispatch: (action: Action) => void = useDispatchParent();
  const shouldBatch = useContext(ImageLoadEventBatchingContext);

  function dispatchQueue(): void {
    const myQueue = _QUEUE;
    _QUEUE = [];
    batch(() => {
      myQueue.forEach((it) => dispatch(it));
    });
  }

  function modDispatch(action: Action): void {
    if (action.type === "load-channel" && shouldBatch) {
      _QUEUE.push(action);
      if (timer === null) {
        timer = setTimeout(() => {
          timer = null;
          dispatchQueue();
        }, 2000);
      }
    } else {
      dispatch(action);
    }
    if (_QUEUE.length > 200) {
      dispatchQueue();
    }
  }
  return modDispatch;
};

export const useVisualizationState = (): VisualizationState =>
  useSelector<State, VisualizationState>(
    ({
      channelMap,
      displaySettings,
      channelLoaded,
      showChannel,
      lockChannel,
      renderMode,
    }: State) => ({
      channelMap,
      displaySettings,
      channelLoaded,
      showChannel,
      lockChannel,
      renderMode,
    }),
  );

export const useVisualizationSettings = (): VisualizationSettings =>
  useSelector<State, VisualizationSettings>((state) =>
    toVisualizationSettings(state),
  );

export const useFetchSettings = (imageSets: ImageSet[] | null): FetchSettings =>
  useSelector<State, FetchSettings>(({ processingMode }) => ({
    processingMode:
      processingMode === undefined
        ? imageSets
          ? defaultProcessingMode(processingModes(imageSets))
          : null
        : processingMode,
    processingModes: imageSets ? processingModes(imageSets) : null,
  }));

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const usePalette = (): Palette | null =>
  useSelector<State, Palette | null>(({ palette }) => palette);
