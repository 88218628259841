import { inferPlateKind } from "../ImageViewer/FullPlateView";
import { Datum } from "../QualityControl/types";
import { DatasetId, MetadataColumnValue } from "../types";
import { defaultComparator } from "./sorting";

function compareStringOrNull(a: string | null, b: string | null): number {
  return a === null
    ? b === null
      ? 0
      : 1
    : b === null
      ? -1
      : defaultComparator(a, b);
}

/**
 * Normalize an array of values to a fixed domain.
 *
 * This function expects to receive, as input, a set of categorical (ordinal
 * or nominal) values, and returns a normalized array in which the values have
 * been deduplicated and reordered.
 *
 * @param values - an array of string and/or number values.
 * @returns - an array representing the domain of the values.
 */
export function normalizeDomain(
  values: MetadataColumnValue[],
): MetadataColumnValue[] {
  // Deduplicate.
  const domain = [...new Set(values)];

  if (
    domain.every((value) => value === true || value === false || value === null)
  ) {
    return domain.sort() as (boolean | null)[];
  } else if (domain.every((it) => typeof it === "number" || it === null)) {
    // Numerical.
    return domain.sort((a, b) => Number(a) - Number(b)) as (number | null)[];
  } else {
    return (domain as (string | null)[]).sort(compareStringOrNull);
  }
}

/**
 * Extract a row and column from a well.
 */
const toRowColumn = (well: string): [string, string] => {
  const row = well.substring(0, 1);
  const column = well.substring(1);

  return [row, column];
};

/**
 * A mapping from well to ring. For 384-well plates only.
 */
function wellToRingMapping(allWells: string[]): { [key: string]: string } {
  // Given a ring number, return the letters for those wells e.g. 1 -> [A, P]
  function ringToRow(ring: number): string[] {
    return [String.fromCharCode(64 + ring), String.fromCharCode(81 - ring)];
  }
  const zeroPad = (num: string, places: number) =>
    String(num).padStart(places, "0");

  function ringToColumn(ring: number): string[] {
    return [zeroPad(String(ring), 2), zeroPad(String(25 - ring), 2)];
  }

  const wellToRing: { [key: string]: string } = {};
  const seenWells: string[] = [];
  // 8 concentric rings for 384 well plates.
  for (let ring = 1; ring <= 8; ring++) {
    const _ringToRow = ringToRow(ring);
    const _ringToColumn = ringToColumn(ring);
    const wells = allWells.filter(
      (well) =>
        !seenWells.includes(well) &&
        (_ringToRow.includes(well[0]) ||
          _ringToColumn.includes(well.substring(1))),
    );
    seenWells.push(...wells);
    for (const well of wells) {
      wellToRing[well] = ring.toString();
    }
  }

  return wellToRing;
}

/**
 * Convert features to a format that's more amenable to Vega.
 *
 * (Namely, need to split up well into separate row and column.)
 * @param dataset The ID of the dataset of the datum
 * @param plate The acquisition name of the datum
 * @param metadataColumns Additional metadata columns
 * @param features The source feature values to transform
 * @param featureSetColumn The column to transform
 * @param hrefBase If specified, will create an href for each datum that's plate/well
 *     specific and joined to this base.
 */
export function dataToVega(
  dataset: DatasetId,
  plate: string,
  metadataColumns: string[],
  features: { well: string; [key: string]: string | number | undefined }[],
  featureSetColumn: string,
  hrefBase?: string,
): Datum[] {
  if (hrefBase && hrefBase.endsWith("/")) {
    hrefBase = hrefBase.slice(0, hrefBase.length - 1);
  }
  const allWells: string[] = features.map((datum) => datum.well);

  // TODO: Extend to other plate size. Only 384 well plates are supported for now.
  let wellToRing: { [key: string]: string } = {};
  if (inferPlateKind(features) === "384-well") {
    wellToRing = wellToRingMapping(allWells);
  }

  return features.map((datum) => {
    const well: string = datum.well;
    const distanceFromEdge: string = wellToRing[well];
    const value = datum[featureSetColumn];
    const [row, column] = toRowColumn(well);

    const metadata: { [key: string]: any } = { well };
    for (const metadataColumn of metadataColumns) {
      if (datum.hasOwnProperty(metadataColumn)) {
        metadata[metadataColumn] = datum[metadataColumn];
      }
    }

    const maybeHrefSpecs = hrefBase
      ? {
          href: `${hrefBase}/e/${dataset}/data/${plate}/${well}?displayMode=well`,
        }
      : {};

    return {
      row,
      column,
      distanceFromEdge,
      well,
      value,
      metadata,
      ...maybeHrefSpecs,
    };
  });
}

/**
 * Extract metadata columns from data formatted for Vega.
 */
export function toMetadataColumns(data: Datum[]): string[] {
  return Object.keys(data[0].metadata);
}
