import { useCallback, useEffect, useMemo, useState } from "react";
import { DatasetId } from "src/types";
import { useDatasetSampleMetadataDB } from "../hooks/datasets";
import {
  SubGroupedResult,
  getUniqueValuesByColumnDB,
  getValuesByPlateAndWellDB,
  inferInterestingColumnsDB,
} from "../util/dataset-util";
import { defaultComparator } from "../util/sorting";
import { Classification, useLabeledSetContext } from "./Context";
import { APPLY_MODEL_REQUIRED_SAMPLES } from "./constants";
import {
  DemographicColumnOption,
  DemographicData,
  LabeledSetStatus,
} from "./types";
import { removeSamples } from "./util";

// TODO(trisorus): Similar logic exists for the Comparisons view and MegaMap. We may
// want to consolidate these in the future if all views share the same opinion
function getDefaultDemographicColumnOption(
  options: DemographicColumnOption[],
): DemographicColumnOption | null {
  const defaultOptionNames = [
    "treatment_id",
    "treatment_name",
    "treatment",
    "compound_id",
    "compound_name",
    "compound",
  ];
  return options.length > 0
    ? options.find(({ value }) => defaultOptionNames.includes(value)) ??
        options[0]
    : null;
}

export function useClassDemographicData(dataset: DatasetId) {
  const { state: labeledSetState } = useLabeledSetContext();
  const { classifications } = labeledSetState;
  const metadataDB = useDatasetSampleMetadataDB({ dataset });
  const [metadataByPlateAndWell, setMetadataByPlateAndWell] =
    useState<SubGroupedResult | null>(null);
  const [uniqueMetadataValuesByColumn, setUniqueMetadataValuesByColumn] =
    useState<{ [column: string]: Set<number | string | null> } | null>(null);

  const [demographicColumnOptions, setDemographicColumnOptions] = useState<
    DemographicColumnOption[]
  >([]);
  const [selectedDemographicColumn, setSelectedDemographicColumn] =
    useState<DemographicColumnOption | null>(
      getDefaultDemographicColumnOption(demographicColumnOptions),
    );

  const handleChangeDemographicColumn = (
    columnOption: DemographicColumnOption | null,
  ) => {
    // The select shouldn't allow selecting a null value, but fallback to default just in case
    setSelectedDemographicColumn(
      columnOption ??
        getDefaultDemographicColumnOption(demographicColumnOptions),
    );
  };

  // Demographic information depends on plate/well, so aggregate the cells by plate/well
  const cellDataPerPlateWell = useMemo(() => {
    return classifications.reduce(
      (cellData, otherClass) => {
        otherClass.examples.forEach((sample) => {
          const { plate, well } = sample;
          cellData[plate] = {
            // TODO(you): Fix this no-unnecessary-condition rule violation
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            ...(cellData[plate] ?? {}),
            [well]: {
              // TODO(you): Fix this no-unnecessary-condition rule violation
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              count: (cellData[plate]?.[well]?.count ?? 0) + 1,
              classificationName: otherClass.name,
            },
          };
        });
        return cellData;
      },
      {} as Record<
        string,
        Record<string, { count: number; classificationName: string }>
      >,
    );
  }, [classifications]);

  useEffect(() => {
    if (!metadataDB?.successful) {
      return;
    }

    const updateMetadata = async () => {
      // We're only concerned with the labeled cell metadata, so get the plates and wells
      // to use for pre-filtering all queries
      const platesAndWells: string[] = [];
      Object.keys(cellDataPerPlateWell).forEach((plate) => {
        Object.keys(cellDataPerPlateWell[plate]).forEach((well) => {
          platesAndWells.push(`('${plate}', '${well}')`);
        });
      });

      // Exit early if there are no examples
      if (platesAndWells.length === 0) {
        return;
      }

      const prefilter = `(plate, well) IN (${platesAndWells.join(", ")})`;

      // Get the columns that the user can select from
      let interestingColumns = await inferInterestingColumnsDB(
        metadataDB.value,
        prefilter,
      );

      if (interestingColumns.length === 0) {
        // There isn't enough variation in the data; all columns are the same level of interesting
        interestingColumns = await inferInterestingColumnsDB(
          metadataDB.value,
          prefilter,
          { filterLowVariationColumns: false },
        );
      }

      const demographicColumnOptions = interestingColumns.map(
        (columnName: string) => ({
          label: columnName,
          value: columnName,
        }),
      );

      setDemographicColumnOptions(demographicColumnOptions);
      setSelectedDemographicColumn(
        getDefaultDemographicColumnOption(demographicColumnOptions),
      );

      // Find the unique values to represent in the chart
      const uniqueValues = await getUniqueValuesByColumnDB(
        metadataDB.value,
        prefilter,
      );
      setUniqueMetadataValuesByColumn(uniqueValues);

      // Enable looking up the metadata by plate/well in order to aggregate later
      const valuesByPlateAndWell = await getValuesByPlateAndWellDB(
        metadataDB.value,
        interestingColumns,
        prefilter,
      );
      setMetadataByPlateAndWell(valuesByPlateAndWell);
    };

    updateMetadata().catch(console.error);
  }, [metadataDB, cellDataPerPlateWell]);

  // Prepare the data for passing into vega-lite
  const demographicData: DemographicData | null = useMemo(() => {
    if (
      selectedDemographicColumn === null ||
      uniqueMetadataValuesByColumn === null ||
      metadataByPlateAndWell === null
    ) {
      return null;
    }

    const column = selectedDemographicColumn.value;
    const uniqueValuesForSelectedColumn = uniqueMetadataValuesByColumn[column];

    const cellCountsByClassificationAndValue = Object.fromEntries(
      classifications.map((otherClassification) => [
        otherClassification.name,
        Object.fromEntries(
          Array.from(uniqueValuesForSelectedColumn)
            .map((value) => `${value}`)
            .sort(defaultComparator)
            .map((value) => [value, 0]),
        ),
      ]),
    );

    Object.keys(cellDataPerPlateWell).forEach((plate) => {
      Object.keys(cellDataPerPlateWell[plate]).forEach((well) => {
        // Newly-labeled cells won't be in the metadata immediately
        // TODO(you): Fix this no-unnecessary-condition rule violation
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (metadataByPlateAndWell[plate]?.[well]?.[column] === undefined) {
          return;
        }

        const { count, classificationName } = cellDataPerPlateWell[plate][well];
        const value = `${metadataByPlateAndWell[plate][well][column]}`;
        cellCountsByClassificationAndValue[classificationName][value] += count;
      });
    });

    const data: DemographicData = [];
    Object.keys(cellCountsByClassificationAndValue).forEach(
      (classificationName) => {
        Object.entries(
          cellCountsByClassificationAndValue[classificationName],
        ).forEach((valueAndCount) => {
          const [value, count] = valueAndCount;
          data.push({
            value,
            classificationName,
            count,
          });
        });
      },
    );

    return data;
  }, [
    classifications,
    cellDataPerPlateWell,
    metadataByPlateAndWell,
    selectedDemographicColumn,
    uniqueMetadataValuesByColumn,
  ]);

  return {
    selectedDemographicColumn,
    demographicColumnOptions,
    handleChangeDemographicColumn,
    demographicData,
  };
}

export function useOnAddToClass() {
  const { state: labeledSetState, setState: setLabeledSetState } =
    useLabeledSetContext();
  const { selected } = labeledSetState;

  const onAddToClass = useCallback(
    (classificationName: string) => {
      setLabeledSetState({
        ...labeledSetState,
        classifications: labeledSetState.classifications.map((entry) =>
          entry.name == classificationName
            ? {
                ...entry,
                examples: [
                  ...selected.map((example) => ({
                    ...example,
                    class: classificationName,
                  })),
                  ...removeSamples(entry.examples, selected),
                ],
              }
            : {
                ...entry,
                examples: removeSamples(entry.examples, selected),
              },
        ),
        displayed: removeSamples(labeledSetState.displayed, selected),
        queue: removeSamples(labeledSetState.queue, selected),
        skipped: removeSamples(labeledSetState.skipped, selected),
        selected: [],
        unsavedChanges: true,
      });
    },
    [labeledSetState, selected, setLabeledSetState],
  );

  return { onAddToClass };
}

export function useCanApplyModel(classifications: Classification[]) {
  const message =
    `You can apply the model when each class has at least ` +
    `${APPLY_MODEL_REQUIRED_SAMPLES} examples.`;

  const validModelCriteria = classifications.every(
    ({ examples, accuracy }) =>
      examples.length >= APPLY_MODEL_REQUIRED_SAMPLES && accuracy !== undefined,
  );

  return { enabled: validModelCriteria, message };
}

export function useLabeledSetStatus({
  classifications,
}: {
  classifications: Classification[];
}): LabeledSetStatus {
  const { enabled } = useCanApplyModel(classifications);

  if (enabled) {
    return LabeledSetStatus.Ready;
  } else if (
    classifications.some(
      (classification) => classification.examples.length < 10,
    )
  ) {
    return LabeledSetStatus.NeedsLabels;
  } else {
    return LabeledSetStatus.InProgress;
  }
}
