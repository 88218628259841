import {
  AppState,
  Auth0Provider as BaseAuth0Provider,
} from "@auth0/auth0-react";
import { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { getDevOverride } from "../dev-options";

export default function Auth0Provider({ children }: { children: ReactNode }) {
  const history = useHistory();

  const domain =
    getDevOverride("auth0Domain") ??
    (import.meta.env.VITE_AUTH0_DOMAIN as string);
  const clientId =
    getDevOverride("auth0ClientId") ??
    (import.meta.env.VITE_AUTH0_CLIENT_ID as string);
  const audience =
    getDevOverride("auth0Audience") ??
    (import.meta.env.VITE_AUTH0_AUDIENCE as string);

  const onRedirectCallback = (appState?: AppState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && audience)) {
    return null;
  }

  // Auth0 requires third party cookies in order to work. However, some browsers
  // are quite strict with this, making login not work. To get around this, they have
  // an option to store tokens in localStorage, which somehow gets around the issue
  // in ways I don't fully understand. However, it's not recommended because it is
  // a weaker security risk, so we do this only on browsers that really need it, and
  // we also configure refresh token rotations, as per the recommendation.
  // The better long-term solution is to have a custom domain with Auth0 so cookies
  // aren't "third party".
  // See https://auth0.com/docs/libraries/auth0-single-page-app-sdk
  // and https://community.auth0.com/t/safari-login-impossible-without-paid-plan/43980
  return (
    <BaseAuth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="memory"
      useRefreshTokens
    >
      {children}
    </BaseAuth0Provider>
  );
}
