import cx from "classnames";

export function selectContainerClassName({ open }: { open?: boolean } = {}) {
  return cx(
    "tw-border tw-rounded",
    "tw-flex tw-flex-row tw-gap-sm",
    "tw-group tw-relative",
    open && "tw-border-primary-500",
    "hover:tw-border-primary-500",
  );
}

export function selectButtonClassName(hasSelected: boolean) {
  return cx(
    "tw-flex-1 tw-max-w-full tw-pl-md tw-pr-xl tw-py-xs",
    "tw-rounded tw-bg-white tw-text-left",
    !hasSelected && "tw-text-gray-400",
  );
}

export function selectInputClassName() {
  return "tw-flex-1 tw-max-w-full tw-pl-md tw-pr-xl tw-py-xs tw-rounded tw-bg-white";
}

export function selectIconsContainerClassName({
  open,
}: { open?: boolean } = {}) {
  return cx(
    "tw-absolute tw-top-0 tw-right-0 tw-bottom-0",
    "tw-px-sm",
    "tw-flex tw-flex-row tw-items-center",
    open && "tw-text-primary-500",
    "group-hover:tw-text-primary-500",
  );
}

export function selectContentClassName() {
  return "tw-bg-white tw-border tw-rounded";
}
