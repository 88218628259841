import type { MapRow } from "apache-arrow";
import type { DefaultRecord } from "src/util/sql";

export enum SqlColumnType {
  varchar = "VARCHAR",
  boolean = "BOOLEAN",
  double = "DOUBLE",
  integer = "INTEGER",
  bigint = "BIGINT",
}

export type TypeFromSqlColumnType<T extends SqlColumnType> = {
  [SqlColumnType.varchar]: string;
  [SqlColumnType.boolean]: boolean;
  [SqlColumnType.double]: number;
  [SqlColumnType.integer]: number;
  [SqlColumnType.bigint]: bigint;
}[T];

type Histogram = MapRow<any, any>;

export type RowWithHistograms = DefaultRecord & {
  [key: `histogram_${string}`]: Histogram | undefined;
};

// A mapping of column names to values, used to specify which rows an update should
// apply to
export type Condition = Record<string, unknown>;
// Also a mapping of column names to values, specifying the new values to write to
// rows that are being updated
export type Update = Record<string, unknown>;

export interface TableUpload {
  source: string;
  data: Record<string, unknown>[];
}
