import sortBy from "lodash.sortby";
import { CellSampleMetadata } from "../types";
import {
  BranchDataNode,
  DataNode,
  LeafDataNode,
  TreeModificationStep,
} from "./types";

export function buildTreeToLevel(
  fullTree: DataNode,
  targetLevel: number,
): DataNode {
  // Primary function to take the entire fullTree and to "cut" it to a given
  // level. It will return a new tree with the same structure as the fullTree.
  // This function also can be used to add on inferred information to the tree such as
  // the next split node.

  // This is kind of wonky and there's no "real" reason to have the name as "10".
  // That is just how the data is actually returned from the cut tree endpoint.
  const data: BranchDataNode = {
    name: "10",
    type: "branch",
    level: 1,
    children: [],
    isNextCombine: false,
  };
  buildTreeToLevelHelper(fullTree, targetLevel, data);
  assignNextSplitCombines(data);
  return data;
}

function buildTreeToLevelHelper(
  fullTreeNode: DataNode,
  targetLevel: number,
  parent: DataNode,
): void {
  // This function will take a complete tree and return a tree up to the specified
  // level. It recursively constructs it and adds it to the argument parent.

  // Annoyingly we opted to change our syntax to use branch instead of node
  // on the frontend, but the backend is still returning internal nodes with the
  // type of node.
  if (fullTreeNode.type === "leaf" || parent.type === "leaf")
    throw new Error("Next parent node must be a branch");
  for (const child of fullTreeNode.children) {
    if (child.level >= targetLevel) {
      const newLeaf: DataNode = {
        name: child.name!.toString(),
        type: "leaf",
        level: child.level,
        cells: getCellsForAllChildren(child),
        isNextSplit: false,
        isManuallyConstructed: false,
      };
      parent.children.push(newLeaf);
    } else {
      const newChild: DataNode = {
        ...child,
        children: [],
        name: child.name!.toString(),
        isNextCombine: false,
        type: "branch",
      };
      parent.children.push(newChild);
      buildTreeToLevelHelper(child, targetLevel, newChild);
    }
  }
  return;
}

export function getCellsForAllChildren(data: DataNode): CellSampleMetadata[] {
  const cells: CellSampleMetadata[] = [];
  const nodes = findLeafNodes(data);
  nodes.forEach((node) => {
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (node.type !== "leaf")
      throw new Error("Node for listing cell indices must be a leaf");
    cells.push(...node.cells);
  });
  return "rank" in cells[0] ? sortBy(cells, "rank") : cells;
}

export function findLeafWithNextSplit(data: DataNode): LeafDataNode {
  const nodes: LeafDataNode[] = findLeafNodes(data);
  let minLevel = Number.MAX_SAFE_INTEGER;
  let minNode: LeafDataNode = nodes[0];
  nodes.forEach((node) => {
    if (node.level < minLevel) {
      minLevel = node.level;
      minNode = node;
    }
  });
  return minNode;
}

export function findNodeWithNextCombine(data: DataNode): BranchDataNode {
  const nodes: BranchDataNode[] = findBranchNodes(data);
  let maxLevel = Number.MIN_SAFE_INTEGER;
  let maxNode: BranchDataNode = nodes[0];
  nodes.forEach((node) => {
    if (node.level > maxLevel) {
      maxLevel = node.level;
      maxNode = node;
    }
  });
  return maxNode;
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function assignNextSplitCombines(data: DataNode): void {
  // Assigns the next split and combine nodes to the tree and clears out stale data.
  const leafNodes: LeafDataNode[] = findLeafNodes(data);
  leafNodes.forEach((leaf) => (leaf.isNextSplit = false));
  const nextSplitNode = findLeafWithNextSplit(data);
  const branchNodes: BranchDataNode[] = findBranchNodes(data);
  branchNodes.forEach((branch) => (branch.isNextCombine = false));
  const previousSplitNode: DataNode = findNodeWithNextCombine(data);
  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (nextSplitNode.type !== "leaf")
    throw new Error("Next leaf to split must be a leaf.");
  nextSplitNode.isNextSplit = true;
  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (previousSplitNode.type !== "branch")
    throw new Error("Previous split node must be a node.");
  previousSplitNode.isNextCombine = true;
}

export function findLeafNodes(
  data: DataNode,
  leafNodes: LeafDataNode[] = [],
): LeafDataNode[] {
  // This will push to the list of prospective leafNodes for all leaf nodes below data.
  if (data.type === "leaf") {
    leafNodes.push(data);
    return leafNodes;
  } else {
    data.children.forEach((child) => {
      findLeafNodes(child, leafNodes);
    });
  }
  return leafNodes;
}

export function findBranchNodes(
  data: DataNode,
  branchNodes: BranchDataNode[] = [],
): BranchDataNode[] {
  // This will push to the list of prospective branchNodes for all internal nodes below.
  if (data.type === "branch") {
    branchNodes.push(data);
    data.children.forEach((child) => {
      findBranchNodes(child, branchNodes);
    });
  }
  return branchNodes;
}

export function findDataNode(tree: DataNode, name: string): DataNode | null {
  // Helper function to get a node from a tree by name. Because it is a pointer
  // to the tree, it will modify the tree in place if we do stuff with it downstream.
  if (tree.name!.toString() == name.toString()) return tree;
  else {
    if (tree.type === "leaf") return null;
    for (const child of tree.children) {
      const node = findDataNode(child, name);
      if (node) return node;
    }
  }
  return null;
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function splitNode(
  tree: DataNode,
  fullTree: DataNode,
  name: string,
): DataNode {
  // This function will take a leaf node and split it into a node with its children
  // as new leaves.
  const node = findDataNode(tree, name);
  if (!node) return tree;
  if (node.type !== "leaf") return tree;
  const fullTreeNode = findDataNode(fullTree, name);
  if (!fullTreeNode) return tree;
  if (fullTreeNode.type === "leaf") return tree;
  const newNode: DataNode = {
    name: node.name as string,
    type: "branch",
    level: node.level,
    isNextCombine: false,
    children: [],
  };
  for (const child of fullTreeNode.children) {
    const newChild: DataNode = {
      level: child.level,
      name: child.name as string,
      type: "leaf",
      cells: getCellsForAllChildren(child),
      isNextSplit: false,
      isManuallyConstructed: true,
    };
    newNode.children.push(newChild);
  }
  return replaceDataNode(tree, newNode);
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function mergeNodeChildrenDown(tree: DataNode, name: string): DataNode {
  // This function will take a node and merge all of its children down into it so that
  //  it becomes a leaf node.
  const node = findDataNode(tree, name);
  if (!node) return tree;
  if (node.type !== "branch") return tree;
  const cells = getCellsForAllChildren(node);

  const newNode: DataNode = {
    name: node.name as string,
    type: "leaf",
    level: node.level,
    cells: cells,
    isNextSplit: false,
    isManuallyConstructed: true,
  };
  tree = replaceDataNode(tree, newNode);
  return tree;
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function updateNodeDisplayName(
  tree: DataNode,
  name: string,
  displayName?: string,
): DataNode {
  const node = findDataNode(tree, name);
  if (!node) return tree;

  const newNode: DataNode = {
    ...node,
    displayName,
  };
  tree = replaceDataNode(tree, newNode);
  return tree;
}

export function replaceDataNode(tree: DataNode, newNode: DataNode): DataNode {
  // Helper function to manually replace a node with a given name within the tree.
  // A lot of times we can get away with changing values on a pointer but if we
  // alter the node type it will sometimes complain.
  if (tree.name === newNode.name) return newNode;
  else {
    if (tree.type === "leaf") return tree;
    tree.children.forEach((child, index) => {
      if (child.name === newNode.name) {
        tree.children[index] = newNode;
      } else {
        replaceDataNode(child, newNode);
      }
    });
    return tree;
  }
}

export function getTreeWithModifications(
  tree: DataNode,
  fullTree: DataNode,
  modifications: TreeModificationStep[],
) {
  let newTree = tree;
  for (const modification of modifications) {
    switch (modification.type) {
      case "combine":
        newTree = mergeNodeChildrenDown(tree, modification.nodeName);
        break;

      case "split":
        newTree = splitNode(tree, fullTree, modification.nodeName);
        break;

      case "changeDisplayName":
        newTree = updateNodeDisplayName(
          tree,
          modification.nodeName,
          modification.displayName,
        );
        break;
    }
  }
  assignNextSplitCombines(newTree);
  return newTree;
}
