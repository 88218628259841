import * as Sentry from "@sentry/react";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import type { AccessToken } from "src/Auth0/accessToken";
import { useOptionalFeatureFlag } from "src/Workspace/feature-flags";
import { useActiveExperiment, useActiveWorkspace } from "src/Workspace/hooks";
import { ENV } from "src/env";
import { useAccessToken } from "src/hooks/auth0";
import { DatasetId, WorkspaceId } from "src/types";
import { useCurrentUser } from "src/user/hooks";
import invariant from "tiny-invariant";
import * as z from "zod";
import {
  type UserDatasetPrefs,
  type UserDatasetPrefsWithDefaults,
  type UserPrefs,
  type UserPrefsWithDefaults,
  type UserWorkspacePrefs,
  type UserWorkspacePrefsWithDefaults,
  userDatasetPrefsSchema,
  userPrefsSchema,
  userWorkspacePrefsSchema,
} from "./types";
import {
  fetchUserDatasetPrefs,
  fetchUserPrefs,
  fetchUserWorkspacePrefs,
  updateUserDatasetPrefs,
  updateUserPrefs,
  updateUserWorkspacePrefs,
} from "./utils";

function fillInDefaults<
  S extends z.ZodDefault<any>,
  T extends { [key: string]: any },
>(schema: S, prefs?: T | null) {
  if (prefs === null) {
    return null;
  }

  const result = schema.safeParse(prefs);
  if (result.success) {
    return result.data;
  }

  // If parsing failed, remove the bad keys and re-parse
  console.error(result.error);
  Sentry.captureException(result.error);

  const errorKeys = result.error.errors.map((err) => err.path).flat();
  const rawPrefs = prefs ?? ({} as T);
  const cleanedPrefs = Object.fromEntries(
    Object.keys(rawPrefs)
      .filter((key) => !errorKeys.includes(key))
      .map((key) => [key, rawPrefs[key as keyof T]]),
  );

  return schema.parse(cleanedPrefs);
}

type UserPrefsContext = {
  isLoadingPrefs: boolean;

  userPrefs: UserPrefsWithDefaults | null;
  userWorkspacePrefs: UserWorkspacePrefsWithDefaults | null;
  userDatasetPrefs: UserDatasetPrefsWithDefaults | null;

  setUserPref: <T extends keyof UserPrefsWithDefaults>(
    key: T,
    value: UserPrefsWithDefaults[T],
  ) => void;
  setUserWorkspacePref: <T extends keyof UserWorkspacePrefsWithDefaults>(
    key: T,
    value: UserWorkspacePrefsWithDefaults[T],
  ) => void;
  setUserDatasetPref: <T extends keyof UserDatasetPrefsWithDefaults>(
    key: T,
    value: UserDatasetPrefsWithDefaults[T],
  ) => void;
};

const PrefsContext = createContext<UserPrefsContext | null>(null);
PrefsContext.displayName = "PrefsContext";

export function usePrefsContext() {
  const context = useContext(PrefsContext);

  if (context === null) {
    throw new Error(
      "usePrefsContext must be used within <PrefsContextProvider />",
    );
  }

  return context;
}

export function PrefsContextProvider({ children }: { children: ReactNode }) {
  const accessToken = useAccessToken();
  const user = useCurrentUser();
  const workspace = useActiveWorkspace({ optional: true })?.id ?? null;
  const dataset = useActiveExperiment({ optional: true })?.id ?? null;
  const isPgEnabled = useOptionalFeatureFlag("use-postgres-image-store");

  const [userPrefs, setUserPrefs] = useState<UserPrefs | null>(null);
  const [userWorkspacePrefs, setUserWorkspacePrefs] =
    useState<UserWorkspacePrefs | null>(null);
  const [userDatasetPrefs, setUserDatasetPrefs] =
    useState<UserDatasetPrefs | null>(null);

  const isLoadingPrefs =
    isPgEnabled &&
    user !== null &&
    workspace !== null &&
    (dataset !== null
      ? [userDatasetPrefs, userWorkspacePrefs, userPrefs].some(
          (prefs) => prefs === null,
        )
      : [userWorkspacePrefs, userPrefs].some((prefs) => prefs === null));

  const getUserPrefs = useCallback(async (token: AccessToken) => {
    const prefs = await fetchUserPrefs(token);
    setUserPrefs(prefs);
  }, []);

  const updateCurrentWorkspaceDataset = useCallback(
    async (
      accessToken: AccessToken,
      workspaceId: WorkspaceId | null,
      datasetId: DatasetId | null,
    ) => {
      // Clear prefs when changing between workspaces
      setUserWorkspacePrefs(null);
      setUserDatasetPrefs(null);

      // No workspace also means no dataset
      if (workspaceId === null) {
        return;
      }

      const [workspacePrefs, datasetPrefs] = await Promise.all([
        fetchUserWorkspacePrefs(accessToken, workspaceId).catch(
          () => undefined,
        ),
        datasetId !== null
          ? fetchUserDatasetPrefs(accessToken, workspaceId, datasetId)
          : Promise.resolve(null),
      ]);

      setUserWorkspacePrefs(workspacePrefs);
      if (datasetPrefs !== null) {
        setUserDatasetPrefs(datasetPrefs);
      }
    },
    [],
  );

  useEffect(() => {
    if (userPrefs !== null) {
      return;
    }

    if (isPgEnabled) {
      getUserPrefs(accessToken);
    } else {
      // If we don't have a data DB to back prefs, just use defaults
      setUserPrefs({});
    }
  }, [isPgEnabled, accessToken, userPrefs, getUserPrefs]);

  useEffect(() => {
    if (isPgEnabled) {
      updateCurrentWorkspaceDataset(accessToken, workspace, dataset);
    } else {
      // If we don't have a data DB to back prefs, just use defaults
      setUserWorkspacePrefs({});
      setUserDatasetPrefs({});
    }
  }, [
    isPgEnabled,
    accessToken,
    workspace,
    dataset,
    updateCurrentWorkspaceDataset,
  ]);

  // For the setters, we set the pref optimistically within the context first,
  // then fire the async request to persist it
  const setUserPref = useCallback(
    (key: keyof UserPrefsWithDefaults, value: any) => {
      invariant(
        userPrefs,
        "Cannot update prefs before they've been initialized!",
      );

      const updatedPrefs = {
        ...userPrefs,
        [key]: value,
      };

      setUserPrefs(updatedPrefs);
      updateUserPrefs(accessToken, updatedPrefs);
    },
    [accessToken, userPrefs],
  );

  const setUserWorkspacePref = useCallback(
    (key: keyof UserWorkspacePrefsWithDefaults, value: any) => {
      invariant(
        workspace,
        "Cannot update workspace prefs with null workspace!",
      );
      invariant(
        userWorkspacePrefs,
        "Cannot update prefs before they've been initialized!",
      );

      const updatedPrefs = {
        ...userWorkspacePrefs,
        [key]: value,
      };

      setUserWorkspacePrefs(updatedPrefs);
      updateUserWorkspacePrefs(accessToken, workspace, updatedPrefs);
    },
    [accessToken, workspace, userWorkspacePrefs],
  );

  const setUserDatasetPref = useCallback(
    (key: keyof UserDatasetPrefsWithDefaults, value: any) => {
      invariant(workspace, "Cannot update dataset prefs with null workspace!");
      invariant(dataset, "Cannot update dataset prefs with null dataset!");
      invariant(
        userDatasetPrefs,
        "Cannot update prefs before they've been initialized!",
      );

      const updatedPrefs = {
        ...userDatasetPrefs,
        [key]: value,
      };

      setUserDatasetPrefs(updatedPrefs);
      updateUserDatasetPrefs(accessToken, workspace, dataset, updatedPrefs);
    },
    [accessToken, workspace, dataset, userDatasetPrefs],
  );

  const userPrefsWithDefaults = useMemo(() => {
    if (userPrefs === null) {
      return null;
    }

    const result = userPrefsSchema.safeParse(userPrefs);
    if (result.success) {
      return result.data;
    }

    // If parsing failed, remove the bad keys and re-parse
    console.error(result.error);
    Sentry.captureException(result.error);

    const errorKeys = result.error.errors.map((err) => err.path).flat();
    const rawPrefs = userPrefs ?? {};
    const cleanedPrefs = Object.fromEntries(
      Object.keys(rawPrefs)
        .filter((key) => !errorKeys.includes(key))
        .map((key) => [key, rawPrefs[key as keyof UserPrefs]]),
    );

    return userPrefsSchema.parse(cleanedPrefs);
  }, [userPrefs]);

  const userWorkspacePrefsWithDefaults = useMemo(
    () => fillInDefaults(userWorkspacePrefsSchema, userWorkspacePrefs),
    [userWorkspacePrefs],
  );

  const userDatasetPrefsWithDefaults = useMemo(
    () => fillInDefaults(userDatasetPrefsSchema, userDatasetPrefs),
    [userDatasetPrefs],
  );

  // Provide console management of prefs in dev
  useEffect(() => {
    if (ENV === "development") {
      (window as any).sDebug = {
        userPrefs: userPrefsWithDefaults,
        setUserPref,
        userWorkspacePrefs: userWorkspacePrefsWithDefaults,
        setUserWorkspacePref,
        userDatasetPrefs: userDatasetPrefsWithDefaults,
        setUserDatasetPref,
      };
    }
  }, [
    userPrefsWithDefaults,
    setUserPref,
    userDatasetPrefsWithDefaults,
    setUserDatasetPref,
    userWorkspacePrefsWithDefaults,
    setUserWorkspacePref,
  ]);

  return (
    <PrefsContext.Provider
      value={{
        isLoadingPrefs,
        userPrefs: userPrefsWithDefaults,
        userWorkspacePrefs: userWorkspacePrefsWithDefaults,
        userDatasetPrefs: userDatasetPrefsWithDefaults,
        setUserPref,
        setUserWorkspacePref,
        setUserDatasetPref,
      }}
    >
      {children}
    </PrefsContext.Provider>
  );
}
