/**
 * This is a fork of redux-query-sync, a library that enables bidirectional syncing of Redux state
 * to query parameters.
 *
 * This fork enables the use of multi-valued query parameters, but otherwise contains only cosmetic
 * code changes and minimal attempts at typing.
 *
 * Source: https://github.com/Treora/redux-query-sync/tree/2a2d08e92b2bf931196f97fdbffb0c5ccfb9b6c9.
 */
import { History, createBrowserHistory as createHistory } from "history";
import { StoreEnhancer } from "redux";
import { arrayEquals } from "@spring/core/utils";

type Configuration = {
  store: any;
  params: {
    [name: string]: Partial<{
      selector: any;
      action: any;
      defaultValue: any;
      stringToValue: (s: string) => any;
      arrayToValue: (a: any[]) => any;
      valueToString: (v: any) => string;
      valueToArray: (v: any) => any[];
      multiple: boolean;
    }>;
  };
  replaceState: boolean;
  initialTruth: "location" | "store";
  history?: History;
};

/**
 * Sets up bidirectional synchronisation between a Redux store and window location query parameters.
 */
function ReduxQuerySync({
  store,
  params,
  replaceState,
  initialTruth,
  history = createHistory(),
}: Configuration) {
  const { dispatch } = store;

  // Two bits of state used to not respond to self-induced updates.
  let ignoreLocationUpdate = false;
  let ignoreStateUpdate = false;

  // Keeps the last seen values for comparing what has changed.
  let lastQueryValues: any;

  // Helper function to support history module v3 as well as v4.
  function getLocation(history: any) {
    if (Object.hasOwnProperty.call(history, "location")) {
      return history.location;
    } else if (Object.hasOwnProperty.call(history, "getCurrentLocation")) {
      return history.getCurrentLocation();
    }
  }

  function getQueryValues(location: any) {
    const locationParams = new URLSearchParams(location.search);
    const queryValues: any = {};
    Object.keys(params).forEach((param) => {
      const {
        defaultValue,
        stringToValue = (s: string) => s,
        multiple,
        arrayToValue = (a: any[]) => a,
      } = params[param];
      const valueStringArray = locationParams.getAll(param);
      queryValues[param] =
        valueStringArray.length === 0
          ? defaultValue
          : multiple
            ? arrayToValue(valueStringArray.map(stringToValue))
            : stringToValue(valueStringArray[0]);
    });
    return queryValues;
  }

  function handleLocationUpdate(location: any) {
    // Support history v5
    if (location.location !== undefined) {
      location = location.location;
    }

    // Ignore the event if the location update was induced by ourselves.
    if (ignoreLocationUpdate) return;

    const state = store.getState();

    // Read the values of the watched parameters
    const queryValues = getQueryValues(location);

    // For each parameter value that changed, we dispatch the corresponding action.
    const actionsToDispatch: any = [];
    Object.keys(queryValues).forEach((param) => {
      const value = queryValues[param];
      const { multiple } = params[param];
      // Process the parameter both on initialisation and if it has changed since last time.
      // (should we just do this unconditionally?)
      if (
        lastQueryValues === undefined ||
        (multiple
          ? !arrayEquals(lastQueryValues[param], value)
          : lastQueryValues[param] !== value)
      ) {
        const { selector, action } = params[param];

        // Dispatch the action to update the state if needed.
        // (except on initialisation, this should always be needed)
        if (
          multiple
            ? !arrayEquals(selector(state), value)
            : selector(state) !== value
        ) {
          actionsToDispatch.push(action(value));
        }
      }
    });

    lastQueryValues = queryValues;

    ignoreStateUpdate = true;
    actionsToDispatch.forEach((action: any) => {
      dispatch(action);
    });
    ignoreStateUpdate = false;

    // Update the location the again: reducers may have e.g. corrected invalid values.
    handleStateUpdate({ replaceState: true });
  }

  function handleStateUpdate({ replaceState }: any) {
    if (ignoreStateUpdate) return;

    const state = store.getState();
    const location = getLocation(history);

    // Parse the current location's query string.
    const locationParams = new URLSearchParams(location.search);

    // Replace each configured parameter with its value in the state.
    Object.keys(params).forEach((param) => {
      const {
        selector,
        defaultValue,
        valueToString = (v: any) => `${v}`,
        multiple,
        valueToArray = (v: any[]) => v,
      } = params[param];
      const value = selector(state);
      if (
        multiple ? arrayEquals(value, defaultValue) : value === defaultValue
      ) {
        locationParams.delete(param);
      } else if (multiple) {
        locationParams.delete(param);
        valueToArray(value).forEach((v: any) =>
          locationParams.append(param, valueToString(v)),
        );
      } else {
        locationParams.set(param, valueToString(value));
      }
      lastQueryValues[param] = value;
    });
    const newLocationSearchString = `?${locationParams}`;
    const oldLocationSearchString = location.search || "?";

    // Only update location if anything changed.
    if (newLocationSearchString !== oldLocationSearchString) {
      // Update location (but prevent triggering a state update).
      ignoreLocationUpdate = true;
      const newLocation = {
        pathname: location.pathname,
        search: newLocationSearchString,
        hash: location.hash,
        state: location.state,
      };
      replaceState ? history.replace(newLocation) : history.push(newLocation);
      ignoreLocationUpdate = false;
    }
  }

  // Sync location to store on every location change, and vice versa.
  const unsubscribeFromLocation = history.listen(handleLocationUpdate);
  const unsubscribeFromStore = store.subscribe(() =>
    handleStateUpdate({ replaceState }),
  );

  // Sync location to store now, or vice versa, or neither.
  if (initialTruth === "location") {
    handleLocationUpdate(getLocation(history));
  } else {
    // Just set the last seen values to later compare what changed.
    lastQueryValues = getQueryValues(getLocation(history));
  }
  if (initialTruth === "store") {
    handleStateUpdate({ replaceState: true });
  }

  return function unsubscribe() {
    unsubscribeFromLocation();
    unsubscribeFromStore();
  };
}

export default function makeStoreEnhancer(
  config: Omit<Configuration, "store">,
): StoreEnhancer {
  return (storeCreator: any) => (reducer: any, initialState: any) => {
    // Create the store as usual.
    const store = storeCreator(reducer, initialState);

    // Hook up our listeners.
    ReduxQuerySync({ store, ...config });

    return store;
  };
}
