import useResizeObserver from "@react-hook/resize-observer";
import * as Sentry from "@sentry/react";
import {
  DependencyList,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { WorkspaceId } from "src/types";
import { useUserAndLocalStorageBackedState } from "./state-pool-store";

/**
 * Add an update guard around `setState`, to avoid updating state on no-ops.
 *
 * This allows for deeper equality checks beyond referential equality.
 */
export function addGuard<S>(
  setState: Dispatch<SetStateAction<S>>,
  areEqual: (prevState: S, nextState: S) => boolean,
): Dispatch<SetStateAction<S>> {
  return (setStateAction: SetStateAction<S>) => {
    return setState((prevState) => {
      const nextState: S =
        typeof setStateAction === "function"
          ? (setStateAction as (prevState: S) => S)(prevState)
          : setStateAction;
      if (areEqual(prevState, nextState)) {
        return prevState;
      } else {
        return nextState;
      }
    });
  };
}

/**
 * Add an update guard to `useMemo`, to enforce deeper equality semantics.
 */
export function useGuardedMemo<T>(
  factory: () => T,
  deps: DependencyList,
  areEqual: (prevValue: T, nextValue: T) => boolean,
): T {
  const ref = useRef<T | null>(null);
  return useMemo(
    () => {
      const nextValue = factory();
      if (ref.current) {
        if (areEqual(ref.current, nextValue)) {
          return ref.current;
        }
      }
      ref.current = nextValue;
      return nextValue;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );
}

type DisallowUndefined<T> = T extends undefined ? never : T;

/**
 * Convert an async function that generates a value to a "hook"-style value.
 *
 * Args:
 *    fn: The async function that generates the underlying value. Assumed to be stable.
 *    deps: The dependency list for caching state; if this changes, the function
 *        will be called again.
 *
 * Returns: the cached value from the async function, or undefined if it's not ready
 */
export function useAsyncValue<T>(
  fn: () => Promise<DisallowUndefined<T>>,
  deps: DependencyList,
): T | undefined {
  // It's assumed the factory function is stable except for when deps changes.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFn = useCallback(fn, deps);
  const [result, setResult] = useState<T | undefined>(undefined);

  useEffect(() => {
    let cancelled = false;

    setResult(undefined);
    memoizedFn().then((v) => !cancelled && setResult(v));

    return () => {
      cancelled = true;
    };
  }, [memoizedFn]);

  return result;
}

/**
 * Thin wrapper around the resize observer. The dimensions of the target element are returned
 * after each resize.
 *
 */
export function useSize(
  target: MutableRefObject<HTMLElement | null>,
  model: "border-box" | "content-box",
) {
  const [size, setSize] = useState<DOMRectReadOnly>();

  useLayoutEffect(() => {
    setSize(target.current?.getBoundingClientRect());
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry) =>
    setSize(
      model === "content-box"
        ? entry.contentRect
        : target.current?.getBoundingClientRect(),
    ),
  );
  return size;
}

/**
 * Utility to schedule eventual saves (i.e. writes to the server) which also saves
 * immediately if the component is unmounted, or if the wdinow is closed.
 *
 * Args:
 *    onSave: A function that performs a save action
 *    interval: Number of sections after a change that the save will happen automatically
 *
 * Returns: a function to be called when a change is made (to schedule a save)
 */
export function useThrottledSave<T>(
  onSave: (latest: T) => void,
  interval: number,
) {
  const unsaved = useRef<T | null>(null);
  const pendingSaveTimeout = useRef<null | number>(null);
  const save = useRef<(latest: T) => void>(onSave);
  save.current = onSave;

  const handleSave = useCallback(() => {
    if (unsaved.current !== null && pendingSaveTimeout.current !== null) {
      // Cancel any pending save
      window.clearTimeout(pendingSaveTimeout.current);

      save.current(unsaved.current);

      pendingSaveTimeout.current = null;
      unsaved.current = null;
      return true;
    } else {
      return false;
    }
  }, []);

  useEffect(() => {
    const beforeUnload = (e: BeforeUnloadEvent) => {
      if (handleSave()) {
        // If we triggered a save, show an alert warning about the potential unsaved
        // changes
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", beforeUnload, { capture: true });

    return () => {
      window.removeEventListener("beforeunload", beforeUnload, {
        capture: true,
      });

      // If we're getting unmounted, check for unsaved changes
      handleSave();
    };
  }, [handleSave]);

  return useCallback(
    (updated: T) => {
      if (pendingSaveTimeout.current !== null) {
        window.clearTimeout(pendingSaveTimeout.current);
      }

      unsaved.current = updated;
      pendingSaveTimeout.current = window.setTimeout(handleSave, interval);
    },
    [handleSave, interval],
  );
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type AsyncActionStatus =
  | {
      status: "idle";
      // cleanupFn only runs if component is still mounted when the async call completes
      start: (fn: () => Promise<void>, cleanupFn?: () => void) => void;
    }
  | {
      status: "in-progress";
    }
  | {
      status: "complete";
      reset: () => void;
    }
  | {
      status: "error";
      error: Error;
      reset: () => void;
    };

// Helps report / manage the status of an asynchronous action (e.g. downloading a file)
// The reported status start at "idle", transition to "in-progress" when an action is
// started, and will then transition to either a "complete" or "error" state depending
// on what the action resolves to.  The "complete" status will automatically transition
// back to "idle" after completeTransitionMs have elapsed.
export function useAsyncStatus({
  completeTransitionMs = 5000,
}: {
  completeTransitionMs?: number;
} = {}): AsyncActionStatus {
  const completeTransitionTimeout = useRef<number | undefined>();
  const mountedRef = useRef(true);

  const start = useCallback(
    async (fn: () => Promise<void>, cleanupFn?: () => void) => {
      setStatus({ status: "in-progress" });

      const reset = () => {
        window.clearTimeout(completeTransitionTimeout.current);
        setStatus({ status: "idle", start });
      };

      try {
        await fn();
        if (mountedRef.current) {
          setStatus({ status: "complete", reset });
          completeTransitionTimeout.current = window.setTimeout(
            reset,
            completeTransitionMs,
          );
        }
      } catch (ex) {
        if (mountedRef.current) {
          setStatus({
            status: "error",
            error: ex instanceof Error ? ex : new Error("unknown error"),
            reset,
          });
        }
        Sentry.captureException(ex);
      } finally {
        if (mountedRef.current) {
          cleanupFn?.();
        }
      }
    },
    [completeTransitionMs],
  );

  useEffect(
    () => () => {
      window.clearTimeout(completeTransitionTimeout.current);
      mountedRef.current = false;
    },
    [],
  );

  const [status, setStatus] = useState<AsyncActionStatus>({
    status: "idle",
    start,
  });

  return status;
}

export function useDefaultWorkspace() {
  return useUserAndLocalStorageBackedState<WorkspaceId | "">(
    "default-workspace",
    "",
  );
}

export function useLastDefinedValue<T>(latest: T | undefined): T | undefined {
  const lastValue = useRef<T | undefined>(latest);
  if (latest !== undefined) {
    lastValue.current = latest;
  }
  return lastValue.current;
}
