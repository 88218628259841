import cx from "classnames";
import { useMemo } from "react";
import { SharedMemoContextProvider } from "src/SharedMemo";
import { useDataset } from "src/hooks/datasets";
import { DatasetId, WorkspaceId } from "src/types";
import { Title } from "@spring/ui/typography";
import { MetadataTable } from "./MetadataTable";

export function AdminConsole({
  workspaceId,
  datasetId,
}: {
  workspaceId: WorkspaceId;
  datasetId: DatasetId;
}) {
  const datasetFetchable = useDataset({ dataset: datasetId });

  const dataset = useMemo(
    () =>
      (datasetFetchable?.successful ? datasetFetchable.value : undefined) ??
      null,
    [datasetFetchable],
  );

  return (
    <SharedMemoContextProvider realm="admin-console">
      <div className="tw-px-xl tw-py-sm tw-flex-row tw-relative tw-shadow">
        <div className="tw-flex-1 tw-flex tw-flex-col">
          <div className="tw-flex-1 tw-py-sm">
            <Title>{dataset?.name}</Title>
          </div>
        </div>
      </div>
      <div
        className={cx(
          "tw-px-xl tw-pb-xl tw-flex tw-flex-col tw-gap-md",
          "tw-h-[calc(100vh-theme(spacing.global-nav-height))]",
          "tw-bg-gray-50",
        )}
      >
        <MetadataTable
          className="tw-flex-1"
          workspace={workspaceId}
          dataset={datasetId}
        />
      </div>
    </SharedMemoContextProvider>
  );
}
