import cx from "classnames";
import { useCallback, useState } from "react";
import { ChevronLeft } from "react-feather";
import { titleCase } from "vega-lite";
import { groupBy } from "@spring/core/utils";
import Spinner from "../../../Common/Spinner";
import { LabMateRepresentationSummary } from "./LabMateRepresentationSummary";
import { PopupTargetText } from "./PopupTargetText";
import {
  GroupSignificanceRecordsType,
  KSTestEnrichmentRecord,
  LabMateView,
  RepresentationType,
  SignificanceRecord,
} from "./types";
import { formatSignificance } from "./util";

const SELECTABLE_TABS: string[] = ["Metadata", "Morphologies"];

export function LabMateSelectionSwitcher({
  significanceRecords,
  selectedView,
  enrichmentValues,
  featureName,
  hasPhenoFinderFeatures,
  disabled,
  onChangeView,
  onShowPopup,
  onClosePopup,
}: {
  significanceRecords: GroupSignificanceRecordsType;
  selectedView: LabMateView;
  enrichmentValues: KSTestEnrichmentRecord[] | null;
  featureName: string;
  hasPhenoFinderFeatures: boolean | null;
  disabled: boolean;
  onChangeView: (view: LabMateView) => void;
  onShowPopup: (
    e: HTMLElement,
    significanceRecords: SignificanceRecord[],
  ) => void;
  onClosePopup: () => void;
}) {
  const [selectedTab, setSelectedTab] = useState<string>(SELECTABLE_TABS[0]);

  const getLabMateDetails = useCallback(
    (direction: RepresentationType) => (
      <LabMateDetails
        significanceRecords={significanceRecords[direction]}
        direction={direction}
        onChangeView={onChangeView}
        onShowPopup={onShowPopup}
        onClosePopup={onClosePopup}
      />
    ),
    [significanceRecords, onShowPopup, onClosePopup, onChangeView],
  );
  const detailsView =
    selectedView === "over" || selectedView === "under"
      ? getLabMateDetails(selectedView)
      : null;
  if (disabled) {
    return null;
  }
  return (
    <div>
      <div className={"tw-flex-1 tw-flex tw-my-md tw-w-full"}>
        {SELECTABLE_TABS.map((tab) => {
          return (
            <div
              key={tab}
              onClick={() => {
                setSelectedTab(tab);
                onChangeView("summary");
              }}
              className={cx(
                "tw-border-b tw-py-xs tw-px-sm tw-text-lg",
                tab === selectedTab
                  ? "tw-text-purple  tw-border-purple "
                  : "tw-text-slate-500 tw-border-gray-300",
              )}
            >
              {tab}
            </div>
          );
        })}
      </div>
      {selectedTab === "Morphologies" ? (
        !hasPhenoFinderFeatures ? (
          <div className={"tw-text-sm"}>
            Go to{" "}
            <a
              className={"tw-text-purple tw-font-bold"}
              href={`pf`}
              target="_blank"
              rel="noreferrer noopener"
            >
              PhenoFinder
            </a>{" "}
            to find morphologies for your data and enable this feature!
          </div>
        ) : (
          <PhenoFinderEnrichmentDetails
            enrichmentValues={enrichmentValues}
            featureName={featureName}
          />
        )
      ) : detailsView !== null ? (
        detailsView
      ) : (
        <LabMateRepresentationSummary
          groupedSignificanceRecords={significanceRecords}
          onChangeView={onChangeView}
          onShowPopup={onShowPopup}
          onClosePopup={onClosePopup}
        />
      )}
    </div>
  );
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function LabMateDetails({
  significanceRecords,
  direction,
  onChangeView,
  onShowPopup,
  onClosePopup,
}: {
  significanceRecords: SignificanceRecord[];
  direction: RepresentationType;
  onChangeView: (view: LabMateView) => void;
  onShowPopup: (
    e: HTMLElement,
    significanceRecords: SignificanceRecord[],
  ) => void;
  onClosePopup: () => void;
}) {
  const significanceGroups = groupBy<SignificanceRecord>(
    significanceRecords,
    ({ significance }) => `p < ${formatSignificance(significance)}`,
  );

  // TODO: trim decimals
  return (
    <div>
      <div className="tw-mb-sm">
        {titleCase(direction)}represented values in the selection:
      </div>
      {Object.entries(significanceGroups).map(
        ([significanceGroupKey, significanceRecords]) => (
          <div key={significanceGroupKey}>
            <div className="tw-font-bold tw-text-slate-500 tw-mb-sm">
              {significanceGroupKey}
            </div>
            <div className="tw-px-sm tw-text-slate-500">
              {significanceRecords.map((r) => (
                <div
                  key={r.metadataColumn + r.value}
                  className="tw-flex tw-justify-between tw-mb-xs tw-items-center"
                >
                  <div className="tw-flex-[1.25] tw-overflow-hidden">
                    <PopupTargetText
                      text={
                        isNaN(parseFloat(r.value))
                          ? r.value
                          : Math.round(parseFloat(r.value) * 1000) / 1000
                      }
                      style={{
                        // margin: 0,
                        padding: "2px 4px",
                        fontSize: "unset",
                      }}
                      onMouseEnter={(e) =>
                        onShowPopup(e.currentTarget as HTMLElement, [r])
                      }
                      onMouseLeave={onClosePopup}
                    />
                  </div>
                  <div className="tw-text-slate-500 tw-flex-[2] tw-text-xs">
                    {r.metadataColumn}
                  </div>
                  <span className="tw-text-slate-500 tw-font-bold">
                    {r.relativeChangePct}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ),
      )}
      <div className="tw-bg-slate-50 tw-text-slate-500 tw-font-bold tw-py-xs">
        <button
          className="tw-flex tw-items-center tw-pl-md"
          onClick={() => onChangeView("summary")}
        >
          <ChevronLeft size={16} className="tw-mr-xs" />
          Back
        </button>
      </div>
    </div>
  );
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function PhenoFinderEnrichmentDetails({
  enrichmentValues,
  featureName,
}: {
  enrichmentValues: KSTestEnrichmentRecord[] | null;
  featureName: string;
}) {
  const valuesToShow =
    enrichmentValues
      ?.filter(
        (r) =>
          r.pValue * enrichmentValues.length <= 0.01 && r.medianRatio > 1.0,
      )
      .sort((a, b) => a.pValue - b.pValue)
      .slice(0, 5) ?? null;
  return (
    <div className={"tw-flex-1"}>
      {valuesToShow !== null ? (
        valuesToShow.length > 0 ? (
          <div>
            <div className={"tw-rounded tw-text-sm"}>
              The following{" "}
              <span className={"tw-font-bold"}>PhenoFinder morphologies</span>{" "}
              are{" "}
              <span className={"tw-font-bold"}>
                significantly over-represented{" "}
              </span>{" "}
              in your selection (click for example images):
            </div>
            {valuesToShow.map((r) => {
              const path = `measurements?featureColumns=${encodeURIComponent(
                r.name,
              )}&featureName=${encodeURIComponent(
                encodeURIComponent(featureName),
              )}&featureType=prediction&tab=image+distribution`;
              return (
                <div key={r.name} className={"tw-my-sm tw-flex-1 tw-truncate"}>
                  <a
                    className={
                      "tw-text-blue tw-text-sm tw-bg-slate-100 tw-rounded tw-p-sm"
                    }
                    href={path}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {r.name}
                  </a>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={"tw-mt-md tw-text-xs tw-text-slate-500"}>
            No significant morphology representation for this selection!
          </div>
        )
      ) : (
        <div className={"tw-ml-md"}>
          <Spinner />
        </div>
      )}
    </div>
  );
}
