import { BiBug } from "react-icons/bi";
import { Button } from "src/Common/Button";

export function BirdEatsBugButton({ className }: { className?: string }) {
  const handleClick = () => {
    if (window.birdeatsbug) {
      window.birdeatsbug.trigger();
    } else {
      console.error("Bird Eats Bug is not initialized");
    }
  };
  return (
    <Button size="sm" className={className} icon={BiBug} onClick={handleClick}>
      Report a bug
    </Button>
  );
}
