import * as Sentry from "@sentry/react";
import { useMemo } from "react";
import {
  MultiSimilarityScoreResult,
  MultiSimilarityScores,
  NormalizedSimilarityScore,
} from "src/MegaMap/types";
import { Failure, Fetchable } from "@spring/core/result";
import { FilterSqlClause } from "../Control/FilterSelector/types";
import { Similarities } from "../FeatureSetManagementPage/MultiFeature/types";
import { MetadataColumnValue, UntypedWellSampleMetadataRow } from "../types";
import { columnComparator } from "./sorting";
import {
  DB,
  DefaultRecord,
  RecordsArray,
  getTableColumns,
  queryDBAsRecords,
  sql,
  useQueryAsRecords,
} from "./sql";

/**
 * List columns that we want to be hidden from the dataset everywhere.
 *
 * This is different form "interesting" columns in that these should be considered an
 * implementation detail, or similar, whereas you might still want to view an
 * "uninteresting" column in the UI sometimes.
 *
 * TODO(colin): figure out a more systematic way to do this.
 */
export function columnsToHide(data: UntypedWellSampleMetadataRow[]): string[] {
  const toHide = [];
  // We always expect there to be a treatment_id and treatment_name column for datasets
  // with treatments. However, for blinded data, these might be the same, in which case
  // we should consider it an implementation detail that we have both and hide one.
  if (data.every((row) => row["treatment_id"] === row["treatment_name"])) {
    toHide.push("treatment_name");
  }

  return toHide;
}

export function inferInterestingColumns(
  data: UntypedWellSampleMetadataRow[],
): string[] {
  const allColumns = Object.keys(data[0]);
  const toHide = columnsToHide(data);
  return (
    allColumns
      .filter((columnName) => !toHide.includes(columnName))
      // Filter out some columns that aren't going to be interesting
      .filter((columnName) => columnName !== "well" && columnName !== "field")
      // Only show columns that have variation across the data
      .filter(
        (columnName) =>
          new Set(data.map((record) => record[columnName])).size > 1,
      )
      .sort(columnComparator)
  );
}

export async function inferInterestingColumnsDB(
  db: DB,
  prefilter?: FilterSqlClause,
  {
    filterLowVariationColumns = true,
    tableName = "sample_metadata",
  }: { filterLowVariationColumns?: boolean; tableName?: string } = {},
): Promise<string[]> {
  const valuesByColumn = await getUniqueValuesByColumnDB(
    db,
    prefilter,
    tableName,
  );
  const allColumns = Object.keys(valuesByColumn);

  let result = allColumns
    // Filter out some columns that aren't going to be interesting
    .filter((columnName) => columnName !== "well" && columnName !== "field");

  if (filterLowVariationColumns) {
    // Only show columns that have variation across the data
    result = result.filter((columnName) => valuesByColumn[columnName].size > 1);
  }

  return result.sort();
}

export type GroupedResult = {
  [group: string]: {
    [column: string]: MetadataColumnValue;
  };
};

export async function getValuesByWellDB(
  db: DB,
  columns: string[],
  prefilter?: FilterSqlClause,
): Promise<GroupedResult> {
  // This query assumes that for any given column, there can only be a singular value
  // per well.
  const columnsWithAgg = columns
    .map((c) => `FIRST("${c}") as '${c}'`)
    .join(",");
  const query = sql`
      SELECT plate, well, ${columnsWithAgg}
      FROM sample_metadata
      WHERE ${prefilter || "TRUE"}
      GROUP BY plate, well
  `;
  const records = await queryDBAsRecords<{ well: string } & DefaultRecord>(
    db,
    query,
  );
  const result: GroupedResult = {};
  for (const row of records) {
    const wellInfo: { [column: string]: MetadataColumnValue } = {};
    for (const column of columns) {
      const value = row[column];
      if (typeof value === "bigint") {
        wellInfo[column] = Number(value);
      } else {
        wellInfo[column] = value as MetadataColumnValue;
      }
    }
    result[row.well] = wellInfo;
  }
  return result;
}

export type SubGroupedResult = {
  [group: string]: {
    [subgroup: string]: {
      [column: string]: MetadataColumnValue;
    };
  };
};

// TODO(trisorus): This is pretty much the same as the method above but additionally keyed by plates –
// I don't think well IDs are unique? Should the other method also use data keyed by plate + well?
export async function getValuesByPlateAndWellDB(
  db: DB,
  columns: string[],
  prefilter?: FilterSqlClause,
): Promise<SubGroupedResult> {
  // This query assumes that for any given column, there can only be a singular value
  // per well.
  const columnsWithAgg = columns
    .map((c) => `FIRST("${c}") as '${c}'`)
    .join(",");
  const query = sql`
      SELECT plate, well, ${columnsWithAgg}
      FROM sample_metadata
      WHERE ${prefilter || "TRUE"}
      GROUP BY plate, well
  `;
  const records = await queryDBAsRecords<
    { well: string; plate: string } & DefaultRecord
  >(db, query);

  const result: SubGroupedResult = {};
  for (const row of records) {
    const wellInfo: { [column: string]: MetadataColumnValue } = {};
    for (const column of columns) {
      const value = row[column];
      if (typeof value === "bigint") {
        wellInfo[column] = Number(value);
      } else {
        wellInfo[column] = value as MetadataColumnValue;
      }
    }

    result[row.plate] = {
      // TODO(you): Fix this no-unnecessary-condition rule violation
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      ...(result[row.plate] ?? {}),
      [row.well]: wellInfo,
    };
  }
  return result;
}

/**
 * Get a grouping of unique values for sample metadata by columns.
 */
export function getUniqueValuesByColumn(
  sampleMetadata: UntypedWellSampleMetadataRow[],
): { [column: string]: Set<MetadataColumnValue> } {
  const values: { [column: string]: Set<MetadataColumnValue> } = {};
  for (const row of sampleMetadata) {
    for (const [column, value] of Object.entries(row)) {
      if (!(column in values)) {
        values[column] = new Set<number | string | null>();
      }
      values[column].add(value);
    }
  }
  // TODO(benkomalo): consider casting from a Set to a sorted list. Most clients
  // likely want to render the unique values somewhere and so need to sort or
  // map so there's often a cast at the callsite.
  return values;
}

export async function getUniqueValuesByColumnDB(
  sampleMetadataDB: DB,
  prefilter?: FilterSqlClause,
  tableName: string = "sample_metadata",
  columns?: string[],
): Promise<{ [column: string]: Set<string | number | null> }> {
  columns = columns ?? (await getTableColumns(sampleMetadataDB, tableName));
  const values: { [column: string]: Set<number | string | null> } = {};
  for (const column of columns) {
    // TODO(benkomalo): natural sorting for things like plate-1, plate-10, etc.
    values[column] = new Set(
      (
        await queryDBAsRecords<{ value: bigint | number | string | null }>(
          sampleMetadataDB,
          prefilter
            ? sql`SELECT "${column}" AS value FROM ${tableName} WHERE ${prefilter} GROUP BY "${column}" ORDER BY "${column}"`
            : sql`SELECT "${column}" AS value FROM ${tableName} GROUP BY "${column}" ORDER BY "${column}"`,
        )
      ).map(({ value }) => {
        if (typeof value == "bigint") {
          return Number(value);
        } else {
          return value;
        }
      }),
    );
  }
  return values;
}
/**
 * Returns an array of objects keys sorted by the length of their values (sets). Initially added as
 * a companion to getUniqueValuesByColumn.
 */
export function sortKeysByValueSize(uniqueGroupValues: {
  [p: string]: Set<MetadataColumnValue>;
}): string[] {
  const entries = Object.entries(uniqueGroupValues);
  return entries.sort((a, b) => a[1].size - b[1].size).map(([key]) => key);
}

export function isSimilaritiesResult(
  value: MultiSimilarityScores[string],
): value is MultiSimilarityScoreResult {
  return "type" in value;
}

export function isSimilaritiesScores(value: MultiSimilarityScores): value is {
  [referenceValue: string]: MultiSimilarityScoreResult;
} {
  for (const key in value) {
    return "type" in value[key];
  }
  return true;
}

export function isGroupedSimilaritiesScores(
  value: MultiSimilarityScores,
): value is {
  [group: string]: { [referenceValue: string]: MultiSimilarityScoreResult };
} {
  for (const key in value) {
    return !("type" in value[key]);
  }
  return true;
}

/**
 * Normalize the score values for a single reference similarity test.
 */
export function normalizeSimilarityScores(
  similarities: Similarities | null,
  positiveControlId?: string,
  negativeControlId?: string,
  getId?: (key: string) => string,
): NormalizedSimilarityScore[] | null {
  if (!similarities) {
    return null;
  }

  const entries = Object.entries(similarities)
    .map(([key, value]) => ({
      id: getId ? getId(key) : key,
      name: key,
      value,
    }))
    // Sort from high value to low
    .sort((a, b) => b.value - a.value);

  if (entries.length < 2) {
    // We can't show anything useful if we only have one other entry
    return null;
  }

  // If no positive control ID is provided, use naive linear normalization from 0.1 to 1.0.
  if (positiveControlId === undefined) {
    const max = entries[0].value;
    const min = entries[entries.length - 1].value;

    return entries.map((entry) => ({
      ...entry,
      value: (entry.value - min) / (max - min),
    }));
  }

  const positiveControlValue = similarities[positiveControlId];
  // Use an explicit negative control if provided, otherwise just use the min value.
  const negativeControlValue =
    negativeControlId !== undefined
      ? similarities[negativeControlId]
      : entries[entries.length - 1].value;

  const EPSILON = 1e-6;
  if (
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    negativeControlValue === undefined ||
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    positiveControlValue === undefined
  ) {
    Sentry.captureMessage(
      "No negative and positive control value in similarity scores",
    );
    return entries;
  } else if (positiveControlValue < negativeControlValue + EPSILON) {
    Sentry.captureMessage(
      "Bad positive control; similarity ranges nonsensical",
    );
    return entries;
  } else {
    const range = positiveControlValue - negativeControlValue;
    return entries.map((entry) => ({
      ...entry,
      value: (entry.value - negativeControlValue) / range,
    }));
  }
}

/**
 * Return metadata rows grouped by a column, returning only values unique to that group.
 *
 * e.g. if you group by "treatment_id", but each treatment has differing values of
 * "treatment_concentration", then "treatment_concentration" will not be included
 * in the resulting rows.
 */
// db is required
export function useGroupedMetadata(
  db: null,
  filter: FilterSqlClause | undefined,
  treatmentColumn: string | undefined,
  groupColumn: string | null,
  subgroupColumn: string | null,
): undefined;
// filter is required
export function useGroupedMetadata(
  db: DB | null,
  filter: undefined,
  treatmentColumn: string | undefined,
  groupColumn: string | null,
  subgroupColumn: string | null,
): undefined;
// treatmentColumn is required
export function useGroupedMetadata(
  db: DB | null,
  filter: FilterSqlClause | undefined,
  treatmentColumn: undefined,
  groupColumn: string | null,
  subgroupColumn: string | null,
): undefined;
export function useGroupedMetadata(
  db: DB | null,
  filter: FilterSqlClause | undefined,
  treatmentColumn: string | undefined,
  groupColumn: string | null,
  subgroupColumn: string | null,
): Fetchable<RecordsArray>;
export function useGroupedMetadata(
  db: DB | null,
  filter: FilterSqlClause | undefined,
  treatmentColumn: string | undefined,
  groupColumn: string | null,
  subgroupColumn: string | null,
): Fetchable<RecordsArray> {
  const rawColumnsQuery = useQueryAsRecords(
    db,
    sql`PRAGMA table_info('sample_metadata')`,
  );

  const columnsQueryResult = useMemo(
    () =>
      rawColumnsQuery?.map((results) =>
        results
          .map((row) => row.name as string)
          .filter((column) => column !== groupColumn),
      ),
    [rawColumnsQuery, groupColumn],
  );

  const query = useMemo(() => {
    if (!columnsQueryResult?.successful || !treatmentColumn || !filter) {
      return undefined;
    }

    // Collate all the columns and unique value counts per grouping.
    // This will be used to filter out columns which are non-unique per group.
    const columns = columnsQueryResult.value;
    const columnsClause = columns
      .filter((column) => !groupColumn || column !== groupColumn)
      .map(
        (column) => sql`
            FIRST("${column}") AS "${column}",
            COUNT(DISTINCT "${column}") as "_${column}_count"
        `,
      )
      .join(", ");

    const groupByColumns = [];
    if (groupColumn) {
      groupByColumns.push(groupColumn);
    }
    groupByColumns.push(treatmentColumn);
    if (subgroupColumn) {
      groupByColumns.push(subgroupColumn);
    }

    const sqlGroups = groupByColumns
      .map((column) => sql`"${column}"`)
      .join(", ");

    return sql`SELECT ${sqlGroups}, ${columnsClause}
      FROM (
        SELECT * FROM sample_metadata WHERE ${filter}
      )
      GROUP BY ${sqlGroups}
      ORDER BY ${sqlGroups}
    `;
  }, [
    columnsQueryResult,
    groupColumn,
    subgroupColumn,
    treatmentColumn,
    filter,
  ]);

  const groupQueryResult = useQueryAsRecords(db, query);

  return useMemo(() => {
    if (!columnsQueryResult || !groupQueryResult || !treatmentColumn) {
      return undefined;
    }
    if (!columnsQueryResult.successful) {
      return Failure.of(columnsQueryResult.error);
    }
    if (!groupQueryResult.successful) {
      return Failure.of(groupQueryResult.error);
    }

    const columns = columnsQueryResult.value;
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return groupQueryResult?.map((results) => {
      // Drop all columns that are non-unique per group, since it'd be confusing to
      // show their value.
      const columnsToKeep = new Set(columns);
      for (const column of columns) {
        for (const row of results) {
          // NOTE(danlec): If every value for the column is NULL, then the
          // COUNT(DISTINCT column_name) will return 0 for the count
          if (Number(row[`_${column}_count`]) > 1) {
            columnsToKeep.delete(column);
            break;
          }
        }
      }

      return results.map((row) => {
        const newRow = {
          [treatmentColumn]: row[treatmentColumn],
          ...(groupColumn ? { [groupColumn]: row[groupColumn] } : {}),
          ...(subgroupColumn ? { [subgroupColumn]: row[subgroupColumn] } : {}),
        };
        for (const column of columnsToKeep) {
          newRow[column] = row[column];
        }
        return newRow;
      });
    });
  }, [
    columnsQueryResult,
    groupColumn,
    groupQueryResult,
    subgroupColumn,
    treatmentColumn,
  ]);
}
