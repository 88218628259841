// TODO(benkomalo): we've now pulled in the use-debounce-callback npm package
// which has some more bells and whistles; consolidate with that.
/**
 * Debounce a function.
 *
 * @param fn - function to debounce.
 * @param time - time (in milliseconds) to which it should be debounced.
 */
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const debounce = <T>(
  fn: (...args: T[]) => void,
  time: number,
): ((...args: T[]) => void) => {
  let timeout: number;

  return function (this: any) {
    const functionCall = () => fn.apply(this, arguments as any);

    clearTimeout(timeout);
    // TODO(colin): timout should be a number or an opaque type, but setTimeout
    // here is being inferred as a NodeJS built-in type, which is not correct
    // for the browser. Fix.
    timeout = setTimeout(functionCall, time) as any;
  };
};

/**
 * Generate a random ID of a specified length.
 */
export function makeId(length: number = 4): string {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

/**
 * Generate an ID for a scoring methodology.
 */
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function generateId(prefix: string): string {
  return `${prefix}${makeId(18 - prefix.length)}`;
}

export function sum(n: number[]): number {
  return n.reduce(
    (accumulator: number, currentValue: number) => accumulator + currentValue,
    0,
  );
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function range(n: number): number[] {
  return new Array(n).fill(null).map((_, i) => i);
}

export function uniquify<T>(list: T[]): T[] {
  return Array.from(new Set(list));
}
