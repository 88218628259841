import cx from "classnames";

export function PrereleaseLabel({ className }: { className?: string } = {}) {
  return (
    <div
      className={cx(
        "tw-inline-block tw-px-sm tw-py-xs",
        "tw-rounded",
        "tw-bg-yellow-warning",
        "tw-text-xs tw-uppercase tw-font-semibold tw-tracking-wide",
        className,
      )}
    >
      Pre-release
    </div>
  );
}
