/**
 * Selector to identify a specific well in a plate.
 */
import { useEffect, useRef } from "react";
import { Select } from "src/Common/Select";
import { DatasetId } from "src/types";
import { Fetchable, Success } from "@spring/core/result";
import { useWells } from "../hooks/immunofluorescence";
import SelectorLabel from "./SelectorLabel";

export default function WellSelector({
  dataset,
  plate,
  well,
  // If specified, well use this list. Otherwise, fetch list from server.
  wells,
  onSelectWell,
  autoSelect,
}: {
  plate: string;
  dataset: DatasetId;
  well: string | null;
  wells?: string[];
  onSelectWell: (well: string | null) => void;
  autoSelect?: boolean;
}) {
  if (wells) {
    return (
      <WithSpecifiedWells
        well={well}
        wells={wells}
        onSelectWell={onSelectWell}
        autoSelect={autoSelect}
      />
    );
  } else {
    return (
      <WithRequest
        plate={plate}
        dataset={dataset}
        well={well}
        onSelectWell={onSelectWell}
        autoSelect={autoSelect}
      />
    );
  }
}

function WithSpecifiedWells({
  well,
  wells,
  onSelectWell,
  autoSelect,
}: {
  well: string | null;
  wells: string[];
  onSelectWell: (well: string | null) => void;
  autoSelect?: boolean;
}) {
  return (
    <WellSelectorImpl
      well={well}
      onSelectWell={onSelectWell}
      wells={Success.of<string[]>(wells)}
      autoSelect={autoSelect}
    />
  );
}

function WithRequest({
  dataset,
  plate,
  well,
  onSelectWell,
  autoSelect,
}: {
  plate: string;
  dataset: DatasetId;
  well: string | null;
  wells?: string[];
  onSelectWell: (well: string | null) => void;
  autoSelect?: boolean;
}) {
  const wells = useWells({ dataset, acquisition: plate });
  return (
    <WellSelectorImpl
      well={well}
      onSelectWell={onSelectWell}
      wells={wells}
      autoSelect={autoSelect}
    />
  );
}

function WellSelectorImpl({
  well,
  wells,
  onSelectWell,
  autoSelect,
}: {
  well: string | null;
  wells: Fetchable<string[]>;
  onSelectWell: (well: string | null) => void;
  autoSelect?: boolean;
}) {
  const hasSelected = useRef(false);
  useEffect(() => {
    if (autoSelect && !well && !hasSelected.current && wells?.successful) {
      hasSelected.current = true;
      onSelectWell(wells.unwrap()[0]);
    }
  }, [autoSelect, hasSelected, well, wells, onSelectWell]);

  return (
    <div className={"tw-flex tw-flex-row tw-items-center"}>
      <SelectorLabel extraClasses={"tw-w-16"}>Well</SelectorLabel>
      <Select
        name="wellSelector"
        className={"tw-flex-1 tw-placeholder-slate-800"}
        disabled={wells == null}
        placeholder="Select"
        items={
          wells?.successful
            ? wells.value.map((id) => ({ label: id, value: id }))
            : []
        }
        value={well ?? undefined}
        onChange={(well: string) => {
          hasSelected.current = true;
          onSelectWell(well);
        }}
        onClear={() => {
          onSelectWell(null);
        }}
        searchable
      />
    </div>
  );
}
