/**
 * A component to render embedding feature listings and allow users to select them.
 *
 * Selecting embedding features is complicated, because we generate embeddings on
 * a per-channel, per-plate basis. That means that there are multiple dimensions of
 * groupings that are relevant:
 *
 *  Grouping concept 1: A "Palette" -- stains co-occur with other specific stains, but
 *    not with others. So if a user selects a specific stain to analyze, we need to
 *    enforce that they subsequently select other stains that co-occur with the
 *    previous selections.
 *
 *  Grouping concept 2: A "plate group" -- While we generate the underlying FeatureSet
 *    artifacts on a per-plate basis, we try to abstract that from the user as much
 *    as possible so that they're only making choices about what they're analyzing
 *    at a higher level (e.g. "I want to look at the MitoTracker staining"). But
 *    we have to ensure that when they make that selection, we also carry over
 *    to see what plates are available for the selected stains.
 */
import cx from "classnames";
import { useCallback, useMemo, useState } from "react";
import SelectAllCheckbox from "./SelectAllCheckbox";
import SelectionListItem from "./SelectionListItem";
import {
  EmbeddingSelection,
  FeatureSetPlateGroup,
  isPlateBasedFeature,
} from "./types";
import { nameFromParts, stainFromName } from "./utils";

type Stain = string;
type Palette = string[];
type PaletteInfo = {
  stains: Palette;
  plates: string[];
};

/** A stringified version of a palette to use as dict keys. */
function encodedPaletteId(stains: Palette): string {
  return [...stains].sort().join("/");
}

/**
 * Given named FeatureSet's (e.g. "ImageNetEmbeddings - MitoTracker"), determine
 * the palettes from which they belong to.
 */
function regroupIntoPalettes(
  featureSets: FeatureSetPlateGroup[],
): PaletteInfo[] {
  const stainsByPlate: { [plate: string]: string[] } = {};

  for (const group of featureSets) {
    const { name } = group;
    const stain = stainFromName(name);

    // Register the stain with the plate's stains so that we can collate what palettes
    // are available.
    for (const featureSet of group.featureSets) {
      // Only consider plate-based feature sets for now.
      if (!isPlateBasedFeature(featureSet)) {
        // TODO(benkomalo): figure out what this means for histology? Can a single histology dataset
        // have multiple palettes? If so, what is the "organizing unit" of those palettes?
        continue;
      }

      const plate = featureSet.plate;
      if (!(plate in stainsByPlate)) {
        stainsByPlate[plate] = [];
      }
      stainsByPlate[plate].push(stain);
    }
  }

  const results: { [stringifiedPalette: string]: PaletteInfo } = {};
  for (const [plate, stains] of Object.entries(stainsByPlate)) {
    const encoded = encodedPaletteId(stains);
    if (!(encoded in results)) {
      results[encoded] = {
        stains,
        plates: [],
      };
    }
    results[encoded].plates.push(plate);
  }

  return Object.values(results);
}

/** Determine the indices of the palettes that are valid given the selected stains. */
function enabledPalettesFromStains(
  palettes: PaletteInfo[],
  stains: Stain[],
): number[] {
  return palettes
    .map<[PaletteInfo, number]>((palette, i) => [palette, i])
    .filter(([palette]) => {
      // A palette is still valid if all selected stains are contained in it.
      return stains.every((s) => palette.stains.includes(s));
    })
    .map(([, i]) => i);
}

function selectionFromState(
  baseName: string,
  stains: Stain[],
): EmbeddingSelection | null {
  if (!stains.length) {
    return null;
  }

  return {
    type: "embedding",
    names: stains.map((stain) => nameFromParts(baseName, stain)),
  };
}

export default function EmbeddingDetails({
  name,
  displayName,
  featureSets,
  multi,
  selection,
  disabled: embeddingsDisabled,
  onChangeSelection,
}: {
  name: string;
  displayName: string;
  featureSets: FeatureSetPlateGroup[];
  multi: boolean;
  selection: EmbeddingSelection | null;
  disabled?: boolean;
  onChangeSelection: (selection: EmbeddingSelection | null) => void;
}) {
  const regrouped = regroupIntoPalettes(featureSets);
  const isMultiPalette = regrouped.length > 1;
  const [selectedStains, setSelectedStains] = useState<Stain[]>(
    selection ? selection.names.map(stainFromName) : [],
  );
  const enabledPalettes = useMemo(
    () => enabledPalettesFromStains(regrouped, selectedStains),
    [regrouped, selectedStains],
  );
  const toggleStain = useCallback(
    (stain: Stain) => {
      let newlySelectedStains: Stain[];
      if (selectedStains.includes(stain)) {
        // Deselect the stain.
        newlySelectedStains = selectedStains.filter((s) => s !== stain);
      } else {
        // First, check to see if we _can_ enable the stain.
        const shouldEnable = regrouped.some((palette, i) => {
          if (!enabledPalettes.includes(i)) {
            return false;
          }
          return palette.stains.some((other) => stain === other);
        });
        if (!shouldEnable) {
          return;
        }
        newlySelectedStains = [...selectedStains, stain];
      }
      setSelectedStains(newlySelectedStains);
      onChangeSelection(selectionFromState(name, newlySelectedStains));
    },
    [name, selectedStains, enabledPalettes, regrouped, onChangeSelection],
  );
  const selectPalette = useCallback(
    (palette: PaletteInfo, index: number, selectAll: boolean) => {
      if (!enabledPalettes.includes(index)) {
        return;
      }
      const { stains } = palette;
      let newlySelectedStains: Stain[];
      if (selectAll) {
        const toEnable = stains.filter(
          (stain) => !selectedStains.includes(stain),
        );
        newlySelectedStains = [...selectedStains, ...toEnable];
      } else {
        newlySelectedStains = selectedStains.filter(
          (stain) => !stains.includes(stain),
        );
      }
      setSelectedStains(newlySelectedStains);
      onChangeSelection(selectionFromState(name, newlySelectedStains));
    },
    [
      name,
      selectedStains,
      setSelectedStains,
      onChangeSelection,
      enabledPalettes,
    ],
  );

  return (
    <div>
      <h1
        className={cx(
          "tw-text-xl tw-max-w-full tw-p-8 tw-pb-4 tw-truncate",
          embeddingsDisabled ? "tw-text-slate-300" : "tw-text-purple",
        )}
      >
        {displayName}
      </h1>
      <div
        className={cx(
          "tw-px-8 tw-pb-4 tw-mb-4 tw-border-b tw-text-sm",
          "tw-flex tw-flex-col tw-gap-sm",
          embeddingsDisabled ? "tw-text-slate-300" : "tw-text-slate-500",
        )}
      >
        <p>
          These high-dimensional "embeddings" are unbiased representations
          generated from proprietary AI models trained to extract salient visual
          features from biological datasets.
        </p>
        <p>
          Individual cells are first localized using nuclear staining, then the
          AI model is shown each channel progressively for each cell so that
          downstream analyses can incorporate the stains of interest depending
          on the relevant scientific questions.
        </p>
        {name.startsWith("Normalized") && (
          <p>
            These embeddings have been Z-score normalized on a per-plate basis.
          </p>
        )}
      </div>
      {regrouped.map((palette, i) => {
        const { stains } = palette;
        const disabled = embeddingsDisabled || !enabledPalettes.includes(i);
        stains.sort();
        return (
          <div key={i} className={"tw-py-2 tw-mb-4"}>
            <div className={"tw-flex tw-text-slate-500 tw-px-8"}>
              <div className={"tw-py-2 tw-flex-1 tw-text-sm tw-uppercase"}>
                {isMultiPalette ? `Palette ${i + 1}` : `Channels`}
              </div>
              {multi && !disabled && (
                <SelectAllCheckbox
                  value={stains.every((stain) =>
                    selectedStains.includes(stain),
                  )}
                  disabled={disabled}
                  onChange={(selectAll) => selectPalette(palette, i, selectAll)}
                />
              )}
            </div>
            {stains.map((stain) => (
              <SelectionListItem
                key={stain}
                disabled={disabled}
                selected={selectedStains.includes(stain)}
                onClick={disabled ? undefined : () => toggleStain(stain)}
                multi={multi}
              >
                <span className={"tw-text-sm"}>{stain}</span>
              </SelectionListItem>
            ))}
          </div>
        );
      })}
    </div>
  );
}
