import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppChromeNavState, AppChromeNavStyle, Tab } from "./types";

export function useTabState(): [Tab[], (tabs: Tab[]) => void] {
  const dispatch = useDispatch();
  const tabs = useSelector<AppChromeNavState, Tab[]>(
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (state) => state?.tabs || [],
  );
  const setTabs = useCallback(
    (tabs: Tab[]) => dispatch({ type: "set-tabs", tabs }),
    [dispatch],
  );

  return [tabs, setTabs];
}

type HandleTabSelectionFunction = (tabId: string | undefined) => void;
const TAB_SELECTION_LISTENERS: HandleTabSelectionFunction[] = [];

export function dispatchTabSelection(tabId: string) {
  TAB_SELECTION_LISTENERS.forEach((fn) => fn(tabId));
}

export function useTabListener(handler: HandleTabSelectionFunction) {
  useEffect(() => {
    TAB_SELECTION_LISTENERS.push(handler);

    return () => {
      const index = TAB_SELECTION_LISTENERS.indexOf(handler);
      TAB_SELECTION_LISTENERS.splice(index, 1);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function useAppNavStyle(): [
  AppChromeNavStyle,
  (style: AppChromeNavStyle) => void,
] {
  const dispatch = useDispatch();
  const style = useSelector<AppChromeNavState, AppChromeNavStyle>(
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (state) => state?.style || "raised",
  );
  const setStyle = useCallback(
    (style: AppChromeNavStyle) => dispatch({ type: "set-style", style }),
    [dispatch],
  );

  return [style, setStyle];
}
