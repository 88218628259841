import * as Dialog from "@radix-ui/react-dialog";
import cx from "classnames";
import pluralize from "pluralize";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Spinner from "src/Common/Spinner";
import { useActiveWorkspace } from "src/Workspace/hooks";
import { useAccessToken } from "src/hooks/auth0";
import { DatasetId } from "src/types";
import { Tooltip } from "@spring/ui/Tooltip";
import { DeprecatedButton } from "../Common/DeprecatedButton";
import { useDataset } from "../hooks/datasets";
import { usePalettes } from "../hooks/immunofluorescence";
import { useModelTrainingAndInferenceAreAllowed } from "../util/users";
import { useLabeledSetContext } from "./Context";
import { StainSelection } from "./StainSelection";
import { useCanApplyModel } from "./hooks";
import {
  getPlatesWithStains,
  reformatPalettes,
  requestInference,
} from "./util";

const PLATES_PER_HOUR_ESTIMATE: number = 100;
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function ApplyModelContents({
  dataset,
  onComplete,
}: {
  dataset: DatasetId;
  onComplete: () => void;
}) {
  enum InferenceStatus {
    "NULL",
    "SENDING",
    "SUCCESS",
    "FAILURE",
  }
  const datasetListing = useDataset({ dataset });
  const datasetName = datasetListing?.successful
    ? datasetListing.value?.name ?? dataset
    : dataset;
  const {
    state: { displayName, stains, selectedStains, id },
  } = useLabeledSetContext();
  const [name, setName] = useState(displayName);
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState<InferenceStatus>(InferenceStatus.NULL);
  const [showErrors, setShowErrors] = useState(false);
  const [inferenceStains, setInferenceStains] =
    useState<string[]>(selectedStains);
  const [mounted, setMounted] = useState(true);
  const palettes = usePalettes({ dataset });

  const workspace = useActiveWorkspace();
  const isApplyAllowed = useModelTrainingAndInferenceAreAllowed();

  const plates = useMemo(() => {
    if (!palettes?.successful) {
      return null;
    }

    const platesByPalette = reformatPalettes(palettes.value);

    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!platesByPalette) {
      return null;
    }

    return getPlatesWithStains(platesByPalette, inferenceStains);
  }, [inferenceStains, palettes]);

  const onChangeName = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => setName(e.currentTarget.value),
    [],
  );

  const onChangeDescription = useCallback<
    React.ChangeEventHandler<HTMLTextAreaElement>
  >((e) => setDescription(e.currentTarget.value), []);

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);

  const accessToken = useAccessToken();
  const workspaceId = workspace.id;

  const onSubmit = useCallback(async () => {
    if (!plates) {
      setStatus(InferenceStatus.FAILURE);
      return;
    }
    setStatus(InferenceStatus.SENDING);
    try {
      await requestInference(
        accessToken,
        name,
        description,
        inferenceStains,
        plates,
        workspaceId,
        id,
        dataset,
      );
      if (mounted) {
        setStatus(InferenceStatus.SUCCESS);
      }
    } catch (ex) {
      if (mounted) {
        setStatus(InferenceStatus.FAILURE);
      }
    }

    onComplete();
  }, [
    plates,
    dataset,
    description,
    id,
    inferenceStains,
    mounted,
    name,
    onComplete,
    workspaceId,
    accessToken,
    InferenceStatus.FAILURE,
    InferenceStatus.SUCCESS,
    InferenceStatus.SENDING,
  ]);

  const onShowErrors = useCallback(() => {
    setShowErrors(true);
  }, []);

  const invalidName = useMemo(() => !/\S/.test(name), [name]);
  const invalidDescription = useMemo(
    () => !/\S/.test(description),
    [description],
  );

  const submitDisabled = useMemo(
    () =>
      plates === null ||
      inferenceStains.length < 1 ||
      invalidName ||
      invalidDescription,
    [plates, inferenceStains, invalidDescription, invalidName],
  );
  const timingEstimateCopy: string =
    plates !== null
      ? plates.length < 10
        ? "a few minutes"
        : plates.length < PLATES_PER_HOUR_ESTIMATE
          ? `${pluralize(
              "minutes",
              Math.round((60 * plates.length) / PLATES_PER_HOUR_ESTIMATE),
              true,
            )}`
          : `${pluralize(
              "hour",
              Math.floor(plates.length / PLATES_PER_HOUR_ESTIMATE),
              true,
            )}`
      : "";
  const measurementsPath = `/workspace/${workspace.id}/e/${dataset}/measurements`;
  return status == InferenceStatus.SUCCESS ? (
    <div className="tw-flex tw-flex-col tw-gap-8">
      <div
        className={cx(
          "tw-text-[20px]",
          "tw-flex tw-items-center tw-justify-center",
        )}
      >
        {String.fromCodePoint(0x1f38a)} Your model is being applied{" "}
        {String.fromCodePoint(0x1f38a)}
      </div>
      <div className={"tw-text-gray-500 tw-text-center"}>
        Our machines are hard at work and your data should be available in{" "}
        {timingEstimateCopy}! Once it's done, you can check the{" "}
        <a
          className={"tw-text-purple"}
          href={measurementsPath}
          target="_blank"
          rel="noreferrer noopener"
        >
          Analyze
        </a>{" "}
        page to view your results.
      </div>

      <div>
        <Dialog.Close asChild>
          <DeprecatedButton variant="primary" className="tw-w-full">
            Okay
          </DeprecatedButton>
        </Dialog.Close>
      </div>
    </div>
  ) : (
    <>
      <div className="tw-text-lg">Apply model</div>
      <div className={"tw-text-sm"}>
        <span className={"tw-text-slate-500"}>{datasetName}</span>
      </div>

      <hr className="tw-my-md" />
      <label className="tw-inline-block tw-w-full">
        <div className={"tw-text-[18px] tw-pb-2"}>
          Name the resulting measurement
        </div>
        <input
          className={cx(
            "tw-w-full tw-bg-gray-100 tw-p-2 tw-border tw-rounded",
            showErrors && invalidName && "tw-border-red-500 tw-bg-red-100",
          )}
          type="text"
          placeholder={`e.g. ${displayName}`}
          value={name}
          onChange={onChangeName}
        ></input>
      </label>
      <hr className="tw-my-md" />
      <label className="tw-inline-block tw-w-full">
        <div className={"tw-text-[18px] tw-pb-2"}>Provide a description</div>
        <p className="tw-text-gray-500 tw-text-sm tw-mb-sm">
          A useful description helps you and collaborators keep track of what
          this is quantifying
        </p>
        <textarea
          className={cx(
            "tw-w-full tw-bg-gray-100 tw-p-2 tw-border tw-rounded",
            showErrors &&
              invalidDescription &&
              "tw-border-red-500 tw-bg-red-100",
          )}
          placeholder="A prediction of..."
          rows={3}
          value={description}
          onChange={onChangeDescription}
        ></textarea>
      </label>
      <hr className="tw-my-md" />
      <div className="tw-w-full tw-mb-8">
        <div className={"tw-text-[18px]"}>
          Select stains to use for inference
        </div>
        <p className="tw-text-gray-500 tw-my-sm">
          The model will be trained one last time using these stains and will
          then be applied to all cells in the dataset
        </p>
        {showErrors && inferenceStains.length < 1 ? (
          <p className={cx("tw-text-red-error tw-my-sm")}>
            You must select at least one stain.
          </p>
        ) : null}
        <StainSelection
          stains={stains}
          minCount={0}
          selection={inferenceStains}
          onChangeSelection={setInferenceStains}
        />
      </div>
      <DeprecatedButton
        variant="primary"
        className={cx("tw-w-full tw-h-10")}
        appearDisabled={submitDisabled}
        disabled={
          (showErrors && submitDisabled) ||
          status == InferenceStatus.SENDING ||
          !isApplyAllowed
        }
        onClick={submitDisabled ? onShowErrors : onSubmit}
      >
        {status == InferenceStatus.SENDING ? (
          <Spinner />
        ) : status == InferenceStatus.FAILURE ? (
          "Uh oh something went wrong... Try again!"
        ) : !isApplyAllowed ? (
          <Tooltip
            contents={
              <div className={"tw-max-w-[440px]"}>
                Application of model disabled in this demo workspace. Please
                contact support@springscience.com to get your own workspace and
                train your own models.
              </div>
            }
            showArrow
            side="top"
          >
            Apply the model
          </Tooltip>
        ) : (
          "Apply the model"
        )}
      </DeprecatedButton>
    </>
  );
}

export function ApplyModel({
  className,
  dataset,
  children,
}: {
  className?: string;
  dataset: DatasetId;
  children: ReactNode;
}) {
  const {
    state: { classifications },
  } = useLabeledSetContext();
  const [open, setOpen] = useState(false);
  const onComplete = useCallback(() => {
    // This function doesn't do anything now but in theory could in the future.
  }, []);
  const { enabled: isApplyModelEnabled, message: messageToShow } =
    useCanApplyModel(classifications);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Tooltip
        className="tw-w-64"
        contents={messageToShow}
        side="bottom"
        enabled={!isApplyModelEnabled}
        asChild
      >
        <Dialog.Trigger asChild>
          <DeprecatedButton
            className={className}
            disabled={!isApplyModelEnabled}
          >
            {children}
          </DeprecatedButton>
        </Dialog.Trigger>
      </Tooltip>
      <Dialog.Portal>
        <Dialog.Overlay
          className={cx(
            "tw-bg-slate-500/[0.5] tw-z-dialog tw-absolute tw-inset-0",
            "tw-overflow-auto",
            "tw-flex",
          )}
        >
          <Dialog.Content
            className={cx(
              "tw-p-8 tw-w-[640px] tw-bg-white tw-shadow-lg tw-rounded-lg tw-m-auto",
            )}
          >
            <ApplyModelContents onComplete={onComplete} dataset={dataset} />
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
