import { makeDatasetApi } from "src/hooks/api";
import { DB, makeCutTreesDB, makeFeatureDb } from "../util/sql";
import { DataNode } from "./types";

/**
 * Returns the latest cut trees dataframe for a dataset
 */
export const useCutTrees = makeDatasetApi("cut_trees/<cutTree>")<DB>(
  undefined,
  () => ({
    fetchKind: "blob",
    transform: makeCutTreesDB,
  }),
);

/**
 * Returns the stains used to create this cut tree
 */
export const useStains = makeDatasetApi("tree/<cutTree>/stains")<string[]>();

/**
 * Returns the latest cut trees dataframe for a dataset
 */
export const useTree = makeDatasetApi("tree/<cutTree>")<DataNode>();

/**
 * Returns the cells features that belong to the cells in this tree.
 */
export const useCellsFeatures = makeDatasetApi("tree/<cutTree>/features")<
  DB,
  { prefixes: string[] }
>(
  ({ prefixes }) => ({ prefixes }),
  () => ({
    fetchKind: "blob",
    transform: (blob: Blob) => makeFeatureDb(blob, null),
  }),
);
