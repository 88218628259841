import cx from "classnames";
import React, { useMemo } from "react";
import { Icon } from "react-feather";
import { classNameLookup } from "../util";

type ButtonVariant = "primary" | "secondary" | "danger";
export interface ButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  variant?: ButtonVariant;
  icon?: Icon;
  size?: "sm" | "md";
  borderless?: boolean;
  noSpacing?: boolean;
}

function variantClasses(
  variant: ButtonVariant,
  {
    borderless,
    disabled,
  }: {
    borderless: boolean;
    disabled: boolean;
  },
): string {
  if (borderless) {
    return borderlessVariantClasses(variant, { disabled });
  }

  const disabledSharedClasses = cx(
    // The secondary button has a border; give the others one too to make them appear the same size
    "tw-border tw-rounded",
    "tw-opacity-30",
  );

  const sharedClasses = cx(
    "tw-border tw-rounded",
    "focus:tw-outline-2 focus:tw-outline-offset-2",
    "active:tw-outline-2 active:tw-outline-offset-[-2px]",
  );

  switch (variant) {
    case "primary":
      if (disabled) {
        return cx(disabledSharedClasses, "tw-bg-primary-500 tw-text-white");
      } else {
        return cx(
          sharedClasses,
          "tw-bg-primary-500 tw-text-white tw-border-primary-500 tw-outline-primary-500",
          "hover:tw-bg-primary-800",
          "focus:tw-outline-primary-500",
          "active:tw-bg-primary-800 active:tw-outline-primary-500",
        );
      }

    case "secondary":
      if (disabled) {
        return cx(
          disabledSharedClasses,
          "tw-bg-white tw-text-gray-900 tw-border-gray-200",
        );
      } else {
        return cx(
          sharedClasses,
          "tw-bg-white tw-text-gray-900 tw-border-gray-200 tw-outline-gray-200",
          "hover:tw-bg-gray-200 hover:tw-border-gray-300",
          "hover:tw-outline-gray-300 hover:tw-outline-2 hover:tw-outline-offset-[-2px]",
          "focus:tw-outline-gray-400",
          "active:tw-bg-gray-200 active:tw-outline-gray-400",
        );
      }

    case "danger":
      if (disabled) {
        return cx(disabledSharedClasses, "tw-bg-red-500 tw-text-white");
      } else {
        return cx(
          sharedClasses,
          "tw-bg-red-500 tw-text-white tw-border-red-500 tw-outline-red-500",
          "hover:tw-bg-red-800",
          "focus:tw-outline-red-500",
          "active:tw-bg-red-800 active:tw-outline-black",
        );
      }
  }
}

function borderlessVariantClasses(
  variant: ButtonVariant,
  {
    disabled,
  }: {
    disabled: boolean;
  },
): string {
  // Use margin hacks to achieve the changing border styles without altering the size of the element
  const disabledSharedClasses = cx(
    "tw-border-b-2 tw-border-transparent tw-rounded-sm",
    "tw-opacity-30",
  );

  const sharedClasses = cx(
    "tw-border-b-2 tw-border-transparent tw-rounded-sm",
    "hover:tw-mb-[1px] hover:tw-border-b",
    "focus:tw-outline-2 focus:tw-outline-offset-2",
    "active:tw-mb-0 active:tw-border-b-2",
  );

  switch (variant) {
    case "primary":
      if (disabled) {
        return cx(disabledSharedClasses, "tw-text-primary-500");
      } else {
        return cx(
          sharedClasses,
          "tw-text-primary-500 tw-outline-primary-500",
          "hover:tw-border-primary-500",
          "focus:tw-outline-primary-500",
          "active:tw-border-primary-800",
        );
      }

    case "secondary":
      if (disabled) {
        return cx(disabledSharedClasses, "tw-text-gray-900");
      } else {
        return cx(
          sharedClasses,
          "tw-text-gray-900 tw-outline-gray-300",
          "hover:tw-border-gray-900",
          "focus:tw-outline-gray-300",
          "active:tw-border-gray-900",
        );
      }

    case "danger":
      if (disabled) {
        return cx(disabledSharedClasses, "tw-text-red-500");
      } else {
        return cx(
          sharedClasses,
          "tw-text-red-500 tw-outline-red-500",
          "hover:tw-text-red-800 hover:tw-border-red-800",
          "focus:tw-outline-red-500",
          "active:tw-text-red-800 active:tw-border-red-800",
        );
      }
  }
}

export function buttonClasses({
  size = "md",
  variant = "secondary",
  borderless = false,
  disabled = false,
  noSpacing = false,
}: ButtonProps = {}): string {
  return cx(
    "tw-flex tw-items-center",
    "tw-transition-colors motion-safe:tw-transition-outline tw-duration-75",
    // Set a default outline of 0 to make transitions smoother
    "tw-outline tw-outline-0",
    {
      "tw-p-sm": !borderless && !noSpacing && size == "sm",
      "tw-py-sm tw-px-md": !borderless && !noSpacing && size == "md",
    },
    classNameLookup(size, { sm: "tw-text-sm", md: "tw-text-base" }),
    variantClasses(variant, { borderless, disabled }),
  );
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      children,
      className,
      borderless,
      noSpacing,
      icon,
      size = "md",
      variant = "secondary",
      ...rest
    }: ButtonProps,
    ref,
  ) {
    const variantStyle = useMemo((): string => {
      const disabled = rest.disabled === true;
      return buttonClasses({ variant, size, borderless, disabled, noSpacing });
    }, [borderless, rest.disabled, size, variant, noSpacing]);

    const ButtonIcon = icon;

    return (
      <button ref={ref} className={cx(variantStyle, className)} {...rest}>
        {ButtonIcon !== undefined ? (
          <ButtonIcon size={16} className="tw-mr-sm" />
        ) : null}
        {children}
      </button>
    );
  },
);
