import cx from "classnames";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import Spinner from "src/Common/Spinner";
import { DatasetId } from "src/types";
import { ArgoJob } from "../hooks/argo";
import { ClassificationPill } from "./ClassificationPill";
import { useLabeledSetContext } from "./Context";
import { LabeledSetMenu } from "./LabeledSetMenu";
import { useLabeledSetStatus } from "./hooks";
import { LabeledSetStatus } from "./types";

interface Props {
  dataset: DatasetId;
  name: string;
  url: string;
  argoJob: ArgoJob;
}

export function LabeledSetRow({ dataset, name, url, argoJob }: Props) {
  const history = useHistory();
  const {
    state: { classifications, loaded },
  } = useLabeledSetContext();

  const onClick = useCallback<React.MouseEventHandler<HTMLTableRowElement>>(
    (e) => {
      if (e.target instanceof Element && e.target.closest("button")) {
        // Let buttons (e.g. the overflow menu) handle themselves
        return;
      }
      history.push(url);
    },
    [history, url],
  );

  const exampleCount = classifications.reduce(
    (sum, classification) => sum + classification.examples.length,
    0,
  );

  const status = useLabeledSetStatus({ classifications });

  const labelingStatusInfo = useMemo((): { message: string; color: string } => {
    switch (status) {
      case LabeledSetStatus.Ready:
        return { message: "Ready", color: "tw-text-green-500" };
      case LabeledSetStatus.NeedsLabels:
        return { message: "Needs Labels", color: "tw-text-red-500" };
      case LabeledSetStatus.InProgress:
        return { message: "In Progress", color: "tw-text-yellow-500" };
    }
  }, [status]);
  const applyStatusInfo = useMemo(():
    | { message: string; color: string }
    | undefined => {
    switch (argoJob.state) {
      case "loading":
        return undefined;
      case "unknown":
        return { message: "N/A", color: "tw-text-gray-500" };
      case "loaded": {
        switch (argoJob.payload.status) {
          case "Succeeded":
            return { message: "Applied", color: "tw-text-green-500" };
          case "Failed":
            return { message: "Failed", color: "tw-text-red-500" };
          case "Running":
            return { message: "Running", color: "tw-text-yellow-500" };
        }
      }
    }
  }, [argoJob]);
  return (
    <tr
      className={cx(
        "tw-bg-white tw-border-b",
        "hover:tw-bg-gray-50 hover:tw-border-b-gray-300",
      )}
      role="button"
      onClick={onClick}
    >
      <td className="tw-px-md tw-py-lg tw-whitespace-nowrap tw-max-w-[400px] tw-truncate">
        {name}
      </td>
      <td className="tw-px-md tw-py-lg tw-text-right">
        {loaded ? exampleCount : ""}
      </td>
      <td className="tw-px-md tw-py-lg tw-flex tw-flex-row tw-flex-wrap tw-gap-sm">
        {classifications.map((classification) => (
          <ClassificationPill
            key={classification.name}
            classification={classification}
          />
        ))}
      </td>
      <td className="tw-px-md tw-py-lg tw-text-right tw-whitespace-nowrap">
        <span className="tw-inline-flex tw-flex-row tw-items-center tw-gap-sm">
          <span>{labelingStatusInfo.message}</span>
          <span className={cx("tw-text-xs", labelingStatusInfo.color)}>
            &#9679;
          </span>
        </span>
      </td>
      <td className="tw-px-md tw-py-lg tw-text-right tw-whitespace-nowrap">
        {applyStatusInfo !== undefined ? (
          <span className="tw-inline-flex tw-flex-row tw-items-center tw-gap-sm">
            <span>{applyStatusInfo.message}</span>
            <span className={cx("tw-text-xs", applyStatusInfo.color)}>
              &#9679;
            </span>
          </span>
        ) : (
          <Spinner />
        )}
      </td>
      <td className="tw-w-2xl tw-relative">
        <LabeledSetMenu
          triggerClassName={cx(
            "tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center",
            "tw-text-slate-500",
          )}
          dataset={dataset}
          align="start"
          sideOffset={
            -40 /* Have the menu start somewhere inside the button, not at the bottom*/
          }
        />
      </td>
    </tr>
  );
}
