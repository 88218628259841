import * as Tabs from "@radix-ui/react-tabs";
import cx from "classnames";
import { ReactNode } from "react";

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type TabOption = {
  value: string;
  label?: string;
  title?: string;
};

export function TabStrip({
  value,
  options,
  extraClasses,
  ariaLabel,
  customRenderFunc,
}: {
  value: string;
  options: TabOption[];
  extraClasses?: string;
  ariaLabel?: string;
  customRenderFunc?: (
    value: string,
    label: string | undefined,
    isSelected: boolean,
  ) => ReactNode;
}) {
  return (
    <Tabs.List
      className={`tw-flex tw-flex-row ${extraClasses}`}
      aria-label={ariaLabel}
    >
      {options.map(({ value: optionValue, label, title }) => {
        return (
          <Tabs.Trigger
            key={optionValue}
            className={cx(
              "tw-flex-1 tw-p-2 tw-inline-flex tw-items-center tw-justify-center",
              optionValue === value && "tw-border-b-[3px] tw-border-purple",
              optionValue !== value && "tw-border-b tw-border-gray-300",
            )}
            value={optionValue}
          >
            {customRenderFunc ? (
              customRenderFunc(optionValue, label, optionValue === value)
            ) : (
              <span
                className={optionValue === value ? "tw-text-purple" : ""}
                title={title}
              >
                {label || optionValue}
              </span>
            )}
          </Tabs.Trigger>
        );
      })}
    </Tabs.List>
  );
}
