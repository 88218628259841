import { useCallback, useMemo } from "react";

function Stain({
  stain,
  onSelect,
  selected,
  disabled,
}: {
  stain: string;
  onSelect: (stain: string, enabled: boolean) => void;
  selected: boolean;
  disabled: boolean;
}) {
  const onChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      onSelect(stain, e.currentTarget.checked);
    },
    [onSelect, stain],
  );

  return (
    <label className="tw-block tw-py-1">
      <input
        type="checkbox"
        checked={selected}
        onChange={onChange}
        disabled={disabled}
        className="tw-mr-2"
      />{" "}
      {stain}
    </label>
  );
}
interface Props {
  stains: string[];
  minCount: number;
  selection: string[];
  onChangeSelection: (stains: string[]) => void;
}

export function StainSelection({
  stains,
  minCount,
  selection,
  onChangeSelection,
}: Props) {
  const onSelect = useCallback(
    (stain: string, enabled: boolean) => {
      const newSelection = selection.filter((s) => s !== stain);
      if (enabled) {
        newSelection.push(stain);
      }
      onChangeSelection(newSelection);
    },
    [onChangeSelection, selection],
  );

  const selectedSet = useMemo(() => new Set(selection), [selection]);

  return (
    <>
      {stains.map((stain) => {
        const selected = selectedSet.has(stain);

        return (
          <Stain
            key={stain}
            stain={stain}
            selected={selected}
            disabled={selected && selection.length <= minCount}
            onSelect={onSelect}
          />
        );
      })}
    </>
  );
}
