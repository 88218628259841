/**
 * Selector to identify a specific field in a plate.
 */
import { useEffect, useRef } from "react";
import "react-select-plus/dist/react-select-plus.css";
import { Select } from "src/Common/Select";
import { Field } from "src/imaging/types";
import { DatasetId } from "src/types";
import { trackEvent } from "../analytics";
import { useFields } from "../hooks/immunofluorescence";
import SelectorLabel from "./SelectorLabel";

export default function FieldSelector({
  dataset,
  plate,
  field,
  onSelectField,
  onClearField,
  autoSelect,
}: {
  plate: string;
  dataset: DatasetId;
  field: Field | null;
  onSelectField: (field: Field) => void;
  onClearField?: () => void;
  autoSelect?: boolean;
}) {
  const fields = useFields({ dataset, acquisition: plate });
  const hasSelected = useRef(false);
  useEffect(() => {
    if (autoSelect && !field && !hasSelected.current && fields?.successful) {
      hasSelected.current = true;
      onSelectField(fields.unwrap()[0]);
    }
  }, [autoSelect, field, fields, onSelectField]);
  return (
    <div className={"tw-flex tw-flex-row tw-items-center tw-flex-auto"}>
      <SelectorLabel extraClasses={"tw-w-16"}>Field</SelectorLabel>
      <Select
        name="fieldSelector"
        className={"tw-flex-1"}
        disabled={fields == null}
        placeholder="Select"
        items={fields?.successful ? fields.value : []}
        value={field ?? undefined}
        onChange={(field: Field) => {
          trackEvent({ id: "Select field of view", field });
          hasSelected.current = true;
          onSelectField(field);
        }}
        onClear={onClearField}
        searchable
      />
    </div>
  );
}
