import { Alert } from "src/Common/Alert";

export default function ErrorMessage({ error }: { error: Error }) {
  return (
    <Alert color="danger" style={{ margin: 0 }}>
      Encountered an error:
      <pre style={{ margin: 0, padding: 0, whiteSpace: "pre-wrap" }}>
        {error.toString()}
      </pre>
    </Alert>
  );
}
