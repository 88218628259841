/**
 * Page for creating and deleting API keys for the data export API.
 */
import * as Dialog from "@radix-ui/react-dialog";
import cx from "classnames";
import { useCallback, useContext, useState } from "react";
import { Copy, X } from "react-feather";
import { useAdminApi } from "src/hooks/api";
import { useAccessToken } from "src/hooks/auth0";
import { SWRResponse } from "swr";
import { DeprecatedButton } from "../Common/DeprecatedButton";
import Spinner from "../Common/Spinner";
import Strut from "../Common/Strut";
import ErrorMessage from "../Error/ErrorMessage";
import {
  fetchJson,
  mergeTraceInfo,
  sprFetch,
  useAuthenticatedFetch,
} from "../hooks/fetch";
import Close from "../icons/Close.svg";
import { TracingContext } from "../util/tracing";

type RawAPIKeyListItem = {
  keyId: string;
  createdOn: number;
};

type APIKeyListItem = {
  keyId: string;
  createdOn: Date;
};

type APIKeyWithSecret = {
  keyId: string;
  secret: string;
};

function APIKey({
  keyId,
  createdOn,
  onRevoke,
}: APIKeyListItem & { onRevoke: (keyId: string) => void }) {
  // TODO(colin): add revoke button in third column.
  // TODO(colin): nicer date format.
  return (
    <tr>
      <td>{keyId}</td>
      <td>{createdOn.toISOString()}</td>
      <td>
        <div className="tw-h-full tw-flex tw-justify-center">
          <button
            onClick={() => onRevoke(keyId)}
            aria-label={"Revoke API key"}
            title={"Revoke API key"}
          >
            <Close className={cx(`tw-w-4 tw-h-4 tw-text-slate-500`)} />
          </button>
        </div>
      </td>
    </tr>
  );
}

function CopyToClipboard({ value }: { value: string }) {
  return (
    <DeprecatedButton
      variant="transparent"
      onClick={() => navigator.clipboard.writeText(value)}
    >
      <Copy size={16} />
    </DeprecatedButton>
  );
}

function NewAPIKeyDialog({
  setCreatedAPIKey,
  apiKey,
}: {
  setCreatedAPIKey: (key: APIKeyWithSecret | null) => void;
  apiKey: APIKeyWithSecret;
}) {
  return (
    <Dialog.Root
      open={true}
      onOpenChange={() => {
        setCreatedAPIKey(null);
      }}
    >
      <Dialog.Portal>
        <Dialog.Overlay
          className={cx(
            "tw-bg-slate-500/[0.5] tw-z-dialog tw-fixed tw-inset-0",
            "tw-overflow-auto tw-p-4",
            "tw-flex",
          )}
        >
          <Dialog.Content
            className={cx(
              "tw-p-8 tw-w-[640px] tw-bg-white tw-shadow-lg tw-rounded-lg tw-m-auto",
              "tw-relative",
            )}
          >
            <Dialog.Close className="tw-absolute tw-top-0 tw-right-0 tw-p-4">
              <X size={16} />
            </Dialog.Close>
            <div className="tw-flex tw-flex-col tw-justify-start">
              Your API Key was successfully created!
              <Strut size={16} />
              Please copy the key ID and secret and treat the secret as you
              would a password. The secret cannot be displayed again after you
              close this dialog.
              <Strut size={16} />
              <label className="tw-flex tw-flex-row tw-justify-start tw-items-baseline">
                Key ID:
                <Strut size={4} />
                <input
                  type="text"
                  readOnly
                  value={apiKey.keyId}
                  className="tw-max-w-full tw-grow tw-font-mono tw-text-xs"
                />
                <CopyToClipboard value={apiKey.keyId} />
              </label>
              <Strut size={4} />
              <label className="tw-flex tw-flex-row tw-justify-start tw-items-baseline">
                Secret:
                <Strut size={4} />
                <input
                  type="text"
                  readOnly
                  value={apiKey.secret}
                  className="tw-max-w-full tw-grow tw-font-mono tw-text-xs"
                />
                <CopyToClipboard value={apiKey.secret} />
              </label>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default function APIKeyManagementPage() {
  const accessToken = useAccessToken();
  const traceInfo = useContext(TracingContext);
  const [createButtonEnabled, setCreateButtonEnabled] = useState<boolean>(true);
  const [createdAPIKey, setCreatedAPIKey] = useState<APIKeyWithSecret | null>(
    null,
  );
  const adminApi = useAdminApi();

  const { data, error, mutate }: SWRResponse<RawAPIKeyListItem[]> =
    useAuthenticatedFetch<RawAPIKeyListItem[]>(adminApi.url("api_keys", {}));

  const parsedData = data?.map(({ keyId, createdOn }) => ({
    keyId,
    createdOn: new Date(createdOn * 1000),
  }));
  const createAPIKey = useCallback(() => {
    setCreateButtonEnabled(false);
    fetchJson(
      adminApi.url("api_keys/create", {}),
      accessToken,
      mergeTraceInfo({ method: "POST" }, traceInfo),
    )
      .then(setCreatedAPIKey)
      .then(() => mutate())
      .finally(() => setCreateButtonEnabled(true));
  }, [adminApi, accessToken, traceInfo, mutate]);
  const revokeAPIKey = useCallback(
    (keyId: string) => {
      if (
        // eslint-disable-next-line no-alert
        window.confirm(
          `Are you sure you wish to revoke key ${keyId}? ` +
            "Any applications using it will immediately stop working.",
        )
      ) {
        sprFetch(
          adminApi.url("api_keys/<key>", { key: keyId }),
          accessToken,
          mergeTraceInfo({ method: "DELETE" }, traceInfo),
        ).then(() => mutate());
      }
    },
    [adminApi, accessToken, traceInfo, mutate],
  );
  const keysComponent = parsedData ? (
    <>
      {parsedData.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Key ID</th>
              <th>Date created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {parsedData.map((k) => (
              <APIKey key={k.keyId} onRevoke={revokeAPIKey} {...k} />
            ))}
          </tbody>
        </table>
      ) : (
        <span>You have no API keys.</span>
      )}
      <Strut size={16} />
      <DeprecatedButton
        className="tw-max-w-[175px]"
        onClick={createAPIKey}
        disabled={!createButtonEnabled}
      >
        Create an API key
      </DeprecatedButton>
    </>
  ) : error ? (
    <ErrorMessage error={error} />
  ) : (
    <Spinner />
  );
  return (
    <>
      <div className={"tw-flex tw-items-start tw-justify-start"}>
        <div className={"tw-w-3/12"} />
        <div className={"tw-my-4 tw-w-6/12 tw-flex tw-flex-col"}>
          {keysComponent}
        </div>
      </div>
      {createdAPIKey && (
        <NewAPIKeyDialog
          setCreatedAPIKey={setCreatedAPIKey}
          apiKey={createdAPIKey}
        />
      )}
    </>
  );
}
