import * as ContextMenu from "@radix-ui/react-context-menu";
import cx from "classnames";
import {
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from "react";
import { MinusSquare, RefreshCw } from "react-feather";
import type { AccessToken } from "src/Auth0/accessToken";
import { Button } from "src/Common/Button";
import { PulseGuiderRoot } from "src/Insights/PulseGuider";
import { useActiveWorkspace } from "src/Workspace/hooks";
import { useAccessToken } from "src/hooks/auth0";
import { DatasetId } from "src/types";
import Loader from "../Common/Loader";
import { FilterSqlClause } from "../Control/FilterSelector/types";
import { useFeatureFlag } from "../Workspace/feature-flags";
import { Workspace } from "../Workspace/types";
import {
  useFilteredPlates,
  useOutOfSamples,
  useRefillDisplayedWhenEmpty,
  useReplenishQueue,
} from "../hooks/examples";
import { ImageSet } from "../imaging/types";
import { useLabeledSetContext } from "./Context";
import { LabelClassMenu } from "./LabelClassMenu";
import { MagnifyingTwinkle } from "./MagnifyingTwinkle";
import { SelectableExampleGridViewV2 } from "./SelectableExampleGridView";
import { SAMPLE_BATCH_SIZE, SAMPLE_REFILL_SIZE } from "./constants";
import { useOnAddToClass } from "./hooks";
import { sortSamples } from "./sortSamples";
import {
  addSampleState,
  findSimilar,
  isNotEmpty,
  removeSamples,
  sortQueue,
} from "./util";

interface Props {
  className?: string;
  dataset: DatasetId;
  imageSet: ImageSet | null;
  otherMenuItems: ReactNode;
}

export interface SampleViewerInstance {
  clearSelection(): void;
}

function QueueLoader({
  dataset,
  plates,
  samplingFilter,
  workspace,
  accessToken,
}: {
  dataset: DatasetId;
  plates: [string, ...string[]];
  samplingFilter: FilterSqlClause | null;
  workspace: Workspace;
  accessToken: AccessToken;
}) {
  useReplenishQueue({
    dataset,
    plates,
    threshold: SAMPLE_BATCH_SIZE,
    numSamples: SAMPLE_REFILL_SIZE,
    samplingFilter,
    workspace,
    accessToken,
  });

  return <></>;
}

export const SampleViewer = forwardRef<SampleViewerInstance, Props>(
  function SampleViewer(
    { className, dataset, imageSet, otherMenuItems }: Props,
    ref,
  ) {
    const { state, setState, updateState } = useLabeledSetContext();
    const {
      samplingFilter,
      selected,
      stains,
      size,
      cropSize,
      displayed,
      neighbors,
      predictions,
      outOfSamples,
      queue,
      skipped,
      loadingError,
    } = state;

    const workspace = useActiveWorkspace();
    const accessToken = useAccessToken();
    const plates = useFilteredPlates(dataset, stains, samplingFilter);
    const showPredictions = useFeatureFlag("phenosorter-show-predictions");
    useEffect(() => {
      updateState((labeledSet) => ({
        ...labeledSet,
        selected: [],
      }));
    }, [dataset, updateState]);

    useRefillDisplayedWhenEmpty(SAMPLE_BATCH_SIZE);
    useOutOfSamples();
    const onClick = useCallback(() => {}, []);

    const onSetSelection = useCallback(
      (selected) => {
        setState({
          ...state,
          selected,
        });
      },
      [state, setState],
    );

    const displayedSortedWithSelection = useMemo(
      () => addSampleState(sortSamples(displayed, neighbors), selected),
      [displayed, neighbors, selected],
    );

    const canClearSelection = useMemo(
      () => displayedSortedWithSelection.some((sample) => sample.selected),
      [displayedSortedWithSelection],
    );

    const onClearSelection = useCallback(() => {
      onSetSelection([]);
    }, [onSetSelection]);

    useImperativeHandle(
      ref,
      () => ({
        clearSelection() {
          onClearSelection();
        },
      }),
      [onClearSelection],
    );

    const { onAddToClass } = useOnAddToClass();

    const similarCells = useMemo(() => {
      //
      if (selected.length === 0) {
        return [];
      }

      const candidates = [
        // The ones that they haven't selected could be similar
        ...removeSamples(displayed, selected),
        ...queue,
        ...skipped,
      ];

      return findSimilar(selected, candidates, neighbors, predictions);
    }, [displayed, neighbors, predictions, queue, selected, skipped]);

    const onFindSimilarCells = useCallback(() => {
      updateState((state) => {
        const newDisplayed = [...selected, ...similarCells];

        const newQueue = sortQueue(
          removeSamples(
            removeSamples([...displayed, ...queue], newDisplayed),
            skipped,
          ),
          neighbors,
        );
        const newSkipped = removeSamples(skipped, newDisplayed);

        return {
          ...state,
          displayed: newDisplayed,
          skipped: newSkipped,
          queue: newQueue,
        };
      });
    }, [
      displayed,
      neighbors,
      queue,
      selected,
      similarCells,
      updateState,
      skipped,
    ]);

    const disableFindSimilarCells = useMemo(
      () => similarCells.length === 0,
      [similarCells.length],
    );

    const onClearDisplayed = useCallback(() => {
      updateState((state) => ({
        ...state,
        displayed: [],
        selected: [],
        skipped: [...state.skipped, ...state.displayed],
        loadingError: null,
      }));
    }, [updateState]);

    return (
      <div className={cx("tw-flex tw-flex-col", className)}>
        {plates && isNotEmpty(plates) && (
          <QueueLoader
            workspace={workspace}
            dataset={dataset}
            accessToken={accessToken}
            plates={plates}
            samplingFilter={samplingFilter}
          />
        )}
        <div className="tw-px-8 tw-py-4 tw-flex tw-flex-wrap tw-gap-sm tw-items-center tw-border-b">
          <Button
            className="tw-whitespace-nowrap"
            onClick={onClearDisplayed}
            disabled={queue.length === 0}
          >
            <RefreshCw size={16} className="tw-mr-2" /> Get New Samples
          </Button>

          <PulseGuiderRoot
            guiderKey={"phenosorter-find-similar"}
            position={{
              corner: "top-right",
              offset: { x: 0, y: 0 },
            }}
            tooltipSide={"right"}
          >
            <Button
              onClick={onFindSimilarCells}
              disabled={disableFindSimilarCells}
              title={
                disableFindSimilarCells
                  ? selected.length === 0
                    ? "No cells were selected"
                    : "No similar cells were found"
                  : undefined
              }
              className="tw-group"
            >
              <MagnifyingTwinkle
                className={cx(
                  "tw-text-white tw-mr-2 tw-h-[24px]",
                  disableFindSimilarCells && "tw-opacity-50",
                )}
                animate={disableFindSimilarCells ? "off" : "hover"}
                mode="fill"
              />
              Find Similar Cells
            </Button>
          </PulseGuiderRoot>

          <Button
            className="tw-whitespace-nowrap"
            variant="secondary"
            onClick={onClearSelection}
            disabled={!canClearSelection}
          >
            <MinusSquare size={16} className="tw-mr-2" />
            Clear Selection
          </Button>

          <div className="tw-flex-1 tw-min-w-[8px]" />

          {otherMenuItems && (
            <div className="tw-flex tw-flex-row tw-gap-sm tw-justify-start">
              {otherMenuItems}
            </div>
          )}
        </div>
        {loadingError ? (
          <div className="tw-min-h-[240px] tw-flex-1 tw-flex tw-items-center tw-justify-center">
            An error occurred while loading samples: {loadingError.message}
          </div>
        ) : displayed.length > 0 ? (
          <ContextMenu.Root>
            <ContextMenu.Trigger className="tw-flex-1 tw-overflow-y-auto tw-z-0 tw-p-8">
              <SelectableExampleGridViewV2
                cropSize={cropSize}
                size={size}
                dataset={dataset}
                imageSet={imageSet}
                metadata={displayedSortedWithSelection}
                predictionMap={showPredictions ? state.predictions : null}
                onClick={onClick}
                onSetSelection={onSetSelection}
              />
            </ContextMenu.Trigger>

            <ContextMenu.Portal>
              <LabelClassMenu
                as={ContextMenu}
                onLabel={onAddToClass}
                showHeader={true}
              />
            </ContextMenu.Portal>
          </ContextMenu.Root>
        ) : outOfSamples ? (
          <div className="tw-min-h-[240px] tw-flex-1 tw-flex tw-items-center tw-justify-center">
            Couldn't find any more samples matching the current filter
          </div>
        ) : (
          <div className="tw-min-h-[240px] tw-flex-1 tw-flex tw-items-center tw-justify-center">
            <Loader />
          </div>
        )}
      </div>
    );
  },
);
