import * as Dialog from "@radix-ui/react-dialog";
import cx from "classnames";
import pluralize from "pluralize";
import React, { useCallback, useMemo, useState } from "react";
import { ArrowRight, Check, X } from "react-feather";
import { useHistory } from "react-router-dom";
import Spinner from "src/Common/Spinner";
import { useActiveWorkspace } from "src/Workspace/hooks";
import { useAccessToken } from "src/hooks/auth0";
import { DatasetId } from "src/types";
import { arrayEquals } from "@spring/core/utils";
import { Tooltip } from "@spring/ui/Tooltip/Tooltip";
import { DeprecatedButton } from "../Common/DeprecatedButton";
import Loader from "../Common/Loader";
import { usePalettes } from "../hooks/immunofluorescence";
import { PlateStains } from "../imaging/types";
import { useModelTrainingAndInferenceAreAllowed } from "../util/users";
import {
  Classification,
  NewLabeledSet,
  defaultLabeledSet,
  useLabeledSetsContext,
} from "./Context";
import { REQUIRED_CLASSES } from "./constants";
import { getPlatesWithStains, reformatPalettes, saveLabeledSet } from "./util";

function Stain({
  stain,
  plates,
  onToggle,
  selected,
}: {
  stain: string;
  plates: string[];
  onToggle: (stain: string, include: boolean) => void;
  selected: boolean;
}) {
  const onChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      onToggle(stain, e.currentTarget.checked);
    },
    [onToggle, stain],
  );

  return (
    <>
      <label className="tw-inline-block" role="button">
        <input type="checkbox" onChange={onChange} checked={selected} /> {stain}
      </label>
      <div>({pluralize("plate", plates.length, true)})</div>
    </>
  );
}

function StainSelection({
  palettes,
  stains,
  onChange,
  showErrors,
}: {
  palettes: PlateStains;
  stains: string[];
  onChange: (value: { stains: string[]; plates: string[] }) => void;
  showErrors: boolean;
}) {
  const platesByPalette = reformatPalettes(palettes);

  const availablePlates = useMemo(() => {
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!platesByPalette) {
      return null;
    }

    return getPlatesWithStains(platesByPalette, stains);
  }, [platesByPalette, stains]);

  const availablePlatesSet = useMemo(
    () => (availablePlates ? new Set(availablePlates) : null),
    [availablePlates],
  );

  const update = useCallback(
    (stains: string[]) => {
      onChange({
        stains,
        plates: getPlatesWithStains(platesByPalette, stains),
      });
    },
    [onChange, platesByPalette],
  );

  const onToggleStain = useCallback(
    (stain, include) => {
      if (include) {
        if (!stains.includes(stain)) {
          update([...stains, stain]);
        }
      } else {
        if (stains.includes(stain)) {
          update(stains.filter((other) => other !== stain));
        }
      }
    },
    [stains, update],
  );

  const onSelectAll = useCallback(() => {
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (platesByPalette) {
      update(Object.keys(platesByPalette));
    }
  }, [platesByPalette, update]);

  const onDeselectAll = useCallback(() => {
    update([]);
  }, [update]);

  return (
    <>
      <hr className="tw-my-md" />
      <div className={"tw-text-[18px] tw-pb-2"}>Select stains</div>
      <p className="tw-text-gray-500">
        Models must be trained, and generate predictions, for images with a
        consistent set of stains. Selecting stains ensures that all the images
        available for labeling have at least all of the stains selected applied.
      </p>
      <div className="tw-mb-2">
        {/* TODO(you): Fix this no-unnecessary-condition rule violation */}
        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
        {platesByPalette &&
          stains.length < Object.keys(platesByPalette).length && (
            <button
              className="tw-p-1 tw-px-2 tw-rounded tw-bg-gray-200 tw-mr-2"
              onClick={onSelectAll}
            >
              Select All
            </button>
          )}
        {stains.length > 0 && (
          <button
            className="tw-p-1 tw-px-2 tw-rounded tw-bg-gray-200 tw-mr-2"
            onClick={onDeselectAll}
          >
            Deselect All
          </button>
        )}
      </div>
      {/* TODO(you): Fix this no-unnecessary-condition rule violation */}
      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
      {platesByPalette && (
        <div
          className="tw-grid tw-p-2"
          style={{ gridTemplateColumns: "1fr auto" }}
          role="button"
        >
          {Object.entries(platesByPalette).map(([stain, plates]) => {
            return (
              <Stain
                key={stain}
                stain={stain}
                plates={
                  availablePlatesSet && stains.length > 0
                    ? plates.filter((plate) => availablePlatesSet.has(plate))
                    : plates
                }
                onToggle={onToggleStain}
                selected={stains.includes(stain)}
              />
            );
          })}
        </div>
      )}
      {availablePlates && (showErrors || stains.length > 0) && (
        <p
          className={cx(
            "tw-mt-2",
            (stains.length > 0 || showErrors) &&
              availablePlates.length === 0 &&
              "tw-text-red-500",
          )}
        >
          {/*NOTE: we don't use pluralize() here because we're also handling the
            is/has and are/have verb agreement*/}
          {availablePlates.length === 1
            ? "There is 1 plate that has all of the selected stains"
            : `There are ${availablePlates.length} plates that have all of the selected stains`}
        </p>
      )}
    </>
  );
}

function ClassInput({
  className,
  index,
  value,
  onSubmit,
  onInput,
  onDelete,
  onActivate,
  onDeactivate,
  onFocus,
  onBlur,
  valid,
  activated,
  required,
  error,
}: {
  className?: string;
  index: number;
  value: string;
  onSubmit: (index: number, value: string, selectNext: boolean) => void;
  onInput: (index: number, value: string) => void;
  onDelete: (index: number) => void;
  onActivate: (index: number) => void;
  onDeactivate: (index: number) => void;
  onFocus: (index: number) => void;
  onBlur: (index: number) => void;
  valid: boolean;
  activated: boolean;
  required: boolean;
  error: boolean;
}) {
  const onClassificationKeyDown = useCallback<
    React.KeyboardEventHandler<HTMLInputElement>
  >(
    (e) => {
      switch (e.key) {
        case "Enter": {
          onSubmit(index, e.currentTarget.value.trim(), true);
          break;
        }
        case "Backspace": {
          if (e.currentTarget.value.length === 0) {
            onDelete(index);
          }
          break;
        }
      }
    },
    [index, onDelete, onSubmit],
  );

  const onBlurHandler = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    (e) => {
      onSubmit(index, e.currentTarget.value.trim(), false);
      onBlur(index);
    },
    [index, onBlur, onSubmit],
  );

  const onFocusHandler = useCallback<
    React.FocusEventHandler<HTMLInputElement>
  >(() => {
    onFocus(index);
  }, [index, onFocus]);

  const onInputHandler = useCallback<
    React.KeyboardEventHandler<HTMLInputElement>
  >(
    (e) => {
      onInput(index, e.currentTarget.value);
    },
    [index, onInput],
  );

  const onMouseOver = useCallback<
    React.MouseEventHandler<HTMLDivElement>
  >(() => {
    onActivate(index);
  }, [index, onActivate]);

  const onMouseOut = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (
        !e.relatedTarget ||
        !e.currentTarget.contains(e.relatedTarget as Node)
      ) {
        onDeactivate(index);
      }
    },
    [index, onDeactivate],
  );

  const onDeleteHandler = useCallback(() => {
    onDelete(index);
  }, [index, onDelete]);

  const statusClass = cx(
    "tw-absolute tw--top-[4px] tw-right-[4px] tw-bg-white",
    "tw-w-[16px] tw-h-[16px] tw-rounded-[8px]",
    "tw-inline-flex tw-items-center tw-justify-center",
  );

  return (
    <div
      className="tw-inline-block tw-relative"
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <input
        className={cx(
          "tw-px-2 tw-py-1 tw-border tw-rounded tw-bg-gray-100",
          className,
          (error || (!valid && value)) && "tw-bg-red-100 tw-border-red-500",
        )}
        type="text"
        placeholder={`Class ${index + 1}`}
        onKeyDown={onClassificationKeyDown}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        value={value}
        onInput={onInputHandler}
      />
      {!required && (!valid || activated) ? (
        <span
          role="button"
          className={statusClass}
          onClick={onDeleteHandler}
          aria-label="Remove"
        >
          <X size={14} className="tw-inline-block" />
        </span>
      ) : (
        valid && (
          <span className={statusClass} aria-label="Valid">
            <Check
              size={14}
              className="tw-inline-block"
              color="rgb(139, 92, 246)"
            />
          </span>
        )
      )}
    </div>
  );
}

function ClassSelection({
  classes,
  onChange,
  showErrors,
}: {
  classes: string[];
  onChange: (value: string[]) => void;
  showErrors: boolean;
}) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [focusIndex, setFocusIndex] = useState<number | null>(null);

  const onEdit = useCallback(
    (index: number, value: string) => {
      onChange([
        ...classes.slice(0, index),
        value,
        ...classes.slice(index + 1),
      ]);
    },
    [classes, onChange],
  );

  const onDelete = useCallback(
    (index: number) => {
      onChange([...classes.slice(0, index), ...classes.slice(index + 1)]);
    },
    [classes, onChange],
  );

  const onDeactivate = useCallback(
    (index: number) => {
      if (index === activeIndex) {
        setActiveIndex(null);
      }
    },
    [activeIndex],
  );

  const displayedClasses = useMemo(() => {
    const displayed = [...classes];

    while (displayed.length < REQUIRED_CLASSES) {
      displayed.push("");
    }
    if (displayed.every((name) => /\S/.test(name))) {
      displayed.push("");
    }
    return displayed;
  }, [classes]);

  const onBlur = useCallback((index) => {
    // We want to make sure we call this after the component rerenders and the new input has focus,
    // otherwise we'll null this out when we don't want to. Wrapping in a timeout will push this
    // update to the end of the event queue.
    setTimeout(
      () => setFocusIndex((current) => (current === index ? null : current)),
      0,
    );
  }, []);

  const hasEnoughClasses =
    classes.filter((name) => /\S/.test(name)).length >= REQUIRED_CLASSES;
  const blanksRequired =
    displayedClasses.length <= REQUIRED_CLASSES ||
    displayedClasses.filter((name) => name === "").length === 1;

  return (
    <div>
      {displayedClasses.map((name, index) => (
        <ClassInput
          key={index}
          className="tw-mr-2 tw-mb-2"
          index={index}
          value={name}
          onInput={onEdit}
          onSubmit={onEdit}
          onDelete={onDelete}
          onActivate={setActiveIndex}
          onDeactivate={onDeactivate}
          onFocus={setFocusIndex}
          onBlur={onBlur}
          activated={index === activeIndex || index === focusIndex}
          required={blanksRequired && !/\S/.test(name)}
          valid={
            /\S/.test(name) &&
            !displayedClasses.some(
              (otherName, otherIndex) =>
                otherName === name && otherIndex !== index,
            )
          }
          error={
            showErrors && !hasEnoughClasses && !name && index < REQUIRED_CLASSES
          }
        />
      ))}
    </div>
  );
}

function AddLabeledSetContents({
  palettes,
  dataset,
}: {
  palettes: PlateStains;
  dataset: DatasetId;
}) {
  const { state, updateState } = useLabeledSetsContext();
  const { defaultCropSize } = state;
  const [name, setName] = useState("");
  const [saving, setSaving] = useState(false);
  const [classes, setClasses] = useState<string[]>([]);
  const [showErrors, setShowErrors] = useState(false);
  const accessToken = useAccessToken();
  const workspace = useActiveWorkspace();
  const creationAllowed = useModelTrainingAndInferenceAreAllowed();

  const shouldSpecifyStains = useMemo(() => {
    const [first, ...rest] = Object.values(palettes);

    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!first) {
      return false;
    }

    return rest.some((entry) => !arrayEquals(first, entry));
  }, [palettes]);

  const allPlates = useMemo(() => Object.keys(palettes), [palettes]);

  const [selectedStains, setSelectedStains] = useState<{
    stains: string[];
    plates: string[];
  }>(
    // If we determined that they don't need to manually specify stains (i.e. all the
    // plates have the same stains) then default the selected stains to those of the
    // first plate
    {
      stains: shouldSpecifyStains ? [] : Object.values(palettes)[0] ?? [],
      plates: allPlates,
    },
  );

  const history = useHistory();

  const validClasses = useMemo(() => {
    return Array.from(new Set(classes.filter((name) => /\S/.test(name))));
  }, [classes]);

  const onInput = useCallback<React.FormEventHandler<HTMLInputElement>>((e) => {
    setName(e.currentTarget.value);
  }, []);

  const onAddLabeledSet = useCallback(async () => {
    setSaving(true);

    const newLabeledSet: NewLabeledSet = {
      ...defaultLabeledSet,
      displayName: name,
      classifications: classes
        .filter((name) => /\S/.test(name))
        .map(
          (name): Classification => ({
            name,
            examples: [],
          }),
        ),
      stains: selectedStains.stains,
      selectedStains: selectedStains.stains,
      cropSize: defaultCropSize,
      defaultCropSize,
      loaded: true,
    };

    const result = await saveLabeledSet({
      accessToken,
      workspace,
      dataset,
      labeledSet: newLabeledSet,
    });

    updateState((state) => {
      return {
        ...state,
        labeledSets: [
          // TODO(you): Fix this no-unnecessary-condition rule violation
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          ...(state.labeledSets ?? []),
          { ...newLabeledSet, id: result.id, lastSaved: new Date() },
        ],
      };
    });

    history.push(`./pl/${result.id}`);
  }, [
    accessToken,
    workspace,
    name,
    classes,
    selectedStains.stains,
    dataset,
    defaultCropSize,
    updateState,
    history,
  ]);

  const onShowErrors = useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(() => {
    setShowErrors(true);
  }, []);

  const invalidName = useMemo(
    () =>
      !/\S/.test(name) ||
      state.labeledSets.some(
        (labeledSet) => !labeledSet.deleted && labeledSet.displayName === name,
      ),
    [name, state.labeledSets],
  );
  const addDisabled = useMemo(() => {
    return (
      invalidName ||
      validClasses.length < REQUIRED_CLASSES ||
      selectedStains.plates.length === 0 ||
      selectedStains.stains.length === 0
    );
  }, [
    invalidName,
    selectedStains.plates.length,
    selectedStains.stains.length,
    validClasses.length,
  ]);

  return (
    <>
      <div className="tw-text-[20px] tw-pb-2">
        Set up your new learned phenotype
      </div>
      <input
        className={cx(
          "tw-w-full tw-bg-gray-100 tw-p-2 tw-border tw-rounded",
          showErrors && invalidName && "tw-border-red-500 tw-bg-red-100",
        )}
        type="text"
        placeholder="Name your phenotype..."
        onInput={onInput}
      ></input>
      <hr className="tw-my-md" />
      <div className={"tw-text-[18px] tw-pb-2"}>
        Classes to organize images into
      </div>
      <p>
        Add at least two classes that you will categorize images into. You can
        add more classes later.
      </p>
      <ClassSelection
        classes={classes}
        onChange={setClasses}
        showErrors={showErrors}
      />
      {shouldSpecifyStains && (
        <StainSelection
          onChange={setSelectedStains}
          palettes={palettes}
          stains={selectedStains.stains}
          showErrors={showErrors}
        />
      )}
      <hr className="tw-my-md" />
      <DeprecatedButton
        variant="primary"
        className={cx("tw-w-full tw-h-10")}
        onClick={addDisabled ? onShowErrors : onAddLabeledSet}
        appearDisabled={addDisabled}
        disabled={(showErrors && addDisabled) || saving || !creationAllowed}
      >
        {saving ? (
          <Spinner />
        ) : creationAllowed ? (
          <ArrowRight
            size={16}
            className={cx(
              "tw-inline-block",
              !addDisabled && "tw-animate-bounce-horizontal",
            )}
          />
        ) : (
          <Tooltip
            contents={
              <div className={"tw-max-w-[440px]"}>
                Creating new learned phenotypes is disabled for this demo
                workspace. Please explore one of the previously made models, or
                contact support@springscience.com to get your own workspace and
                train your own models.
              </div>
            }
            showArrow
            side={"top"}
          >
            Create
          </Tooltip>
        )}
      </DeprecatedButton>
    </>
  );
}

export function AddLabeledSet({ dataset }: { dataset: DatasetId }) {
  const maybePalettes = usePalettes({ dataset });
  const palettes = useMemo(
    (): PlateStains | null =>
      maybePalettes?.successful ? maybePalettes.value : null,
    [maybePalettes],
  );

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <DeprecatedButton variant="primary">
          New Learned Phenotype
        </DeprecatedButton>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay
          className={cx(
            "tw-bg-slate-500/[0.5] tw-z-dialog tw-absolute tw-inset-0",
            "tw-overflow-auto",
            "tw-flex",
          )}
        >
          <Dialog.Content
            className={cx(
              "tw-p-8 tw-w-[640px] tw-bg-white tw-shadow-lg tw-rounded-lg tw-m-auto",
            )}
          >
            {palettes !== null ? (
              <AddLabeledSetContents palettes={palettes} dataset={dataset} />
            ) : (
              <Loader />
            )}
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
