/**
 * Convert from a hex color string to principal red, green, and blue values.
 */
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function hexToRgb(hex: string): { r: number; g: number; b: number } {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result == null) {
    throw Error(`Invalid color string: ${hex}`);
  }
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
}

/**
 * Set the opacity of a hex-encoded color.
 *
 * @param color a hex-encoded color string (e.g., "#ffffff").
 * @param opacity a float in [0, 1] to indicate the opacity.
 * @returns {string} a RGBA-encoded string with the opacity set.
 */
export function withOpacity(color: string, opacity: number): string {
  const { r, g, b } = hexToRgb(color);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type RGB = [number, number, number];

function gradientColor(
  value: number,
  lowColor: RGB,
  highColor: RGB,
  opacity?: number,
): string {
  const components = highColor.map((highValue, index) =>
    Math.round(lowColor[index] + (highValue - lowColor[index]) * value),
  );

  return opacity !== undefined
    ? `rgba(${components.join(",")},${opacity})`
    : `rgb(${components.join(",")})`;
}

export const FULL_GRADIENT: RGB[] = [
  // Blue
  [0x00, 0x00, 0xff],
  // Cyan
  [0x00, 0xff, 0xff],
  // Green
  [0x00, 0xff, 0x00],
  // Yellow
  [0xff, 0xff, 0x00],
  // Red
  [0xff, 0x00, 0x00],
];

export function colorForValue(
  value: number,
  colors: RGB[],
  opacity?: number,
): string {
  const pct = Math.max(0, Math.min(1, 1.0 - value));

  const colorSpacing = 1.0 / (colors.length - 1);
  const index = Math.floor(pct / colorSpacing);

  return gradientColor(
    (pct - index * colorSpacing) / colorSpacing,
    colors[index],
    colors[Math.min(colors.length - 1, index + 1)],
    opacity,
  );
}

export function cssGradient(
  colors: RGB[],
  direction: "90deg" | "180deg" | "-90deg",
): string {
  return `linear-gradient(${direction}, ${colors
    .map(
      (rgb, index) =>
        `rgb(${rgb.join(",")}) ${Math.round(
          (100 * index) / (colors.length - 1),
        )}%`,
    )
    .join(", ")})`;
}
