import cx from "classnames";
import { ReactNode } from "react";
import { Check } from "react-feather";

export function Item({
  id,
  selected,
  icon,
  secondary,
  children,
  disabled,
  highlight,
  height,
  onToggle,
  onHover,
}: {
  id: string;
  selected: boolean;
  icon?: ReactNode;
  secondary?: ReactNode;
  children: ReactNode;
  disabled?: boolean;
  highlight?: boolean;
  height: number;
  onToggle: (id: string) => void;
  onHover: () => void;
}) {
  return (
    <div
      key={id}
      className={cx(
        "tw-px-md tw-py-xs",
        "tw-flex tw-flex-row tw-gap-sm tw-items-center",
        highlight && "tw-bg-purple-300 tw-text-purple",
        disabled ? "tw-cursor-not-allowed tw-opacity-30" : "tw-cursor-pointer",
      )}
      style={{ height }}
      onClick={!disabled ? () => onToggle(id) : undefined}
      onPointerMove={!highlight && !disabled ? (e) => onHover() : undefined}
      onKeyDown={(e) => {
        switch (e.key) {
          case "Enter":
          case " ":
            onToggle(id);
            e.preventDefault();
            break;
        }
      }}
      role="option"
      tabIndex={disabled ? undefined : -1}
      ref={
        highlight ? (el) => el?.scrollIntoView({ block: "nearest" }) : undefined
      }
    >
      {icon && <div>{icon}</div>}
      <div className="tw-flex-1 tw-truncate">{children}</div>
      {secondary && (
        <div className="tw-text-xs tw-text-gray-300">{secondary}</div>
      )}
      {selected && (
        <div>
          <Check size={16} />
        </div>
      )}
    </div>
  );
}
