import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { ReactNode, useContext, useEffect, useState } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import APIKeyManagementPage from "./APIKeyManagementPage";
import { ApiDebugger } from "./ApiDebugger";
import AppChrome from "./AppChrome";
import { AppChromeMinimal } from "./AppChrome/AppChrome";
import {
  AccessTokenContextProvider,
  RequiresAuthentication,
} from "./Auth0/context";
import { GlobalEventTrackingContextProvider } from "./Common/EventTracker/Context";
import { FullScreenContainer } from "./Common/FullScreenContainer";
import { FullScreenLoader } from "./Common/FullScreenLoader";
import { PrefsContextProvider } from "./Common/Prefs/Context";
import { CornerMenu } from "./CornerMenu";
import { DBExplorer } from "./DBExplorer";
import LogoutButton from "./Home/LogoutButton";
import JoinPublicWorkspace from "./JoinPublicWorkspace/JoinPublicWorkspace";
import { LocalModificationsProvider } from "./LocalModifications";
import ManualTestHarness from "./ManualTestHarness";
import { MethodsContextProvider } from "./Methods/context";
import PageNotFound from "./PageNotFound";
import Shoebill from "./Shoebill";
import SplashScreen, { MobileSplashScreen } from "./SplashScreen";
import SpringThemePage from "./SpringThemePage";
import ToastContainer from "./Toast/ToastContainer";
import { ToastContextProvider } from "./Toast/context";
import { TrackAppLocation } from "./TrackAppLocation";
import { AppLocationId } from "./TrackAppLocation/types";
import Workspace from "./Workspace";
import { RedirectToDefaultWorkspace } from "./Workspace/Workspace";
import { WorkspaceContextProvider } from "./Workspace/context";
import {
  identifyUser as identifyMixpanelUser,
  initAnalytics,
} from "./analytics";
import { ENV } from "./env";
import { identifyUser as identifySentryUser } from "./error-monitoring";
import { useTrackPageview } from "./hooks/analytics";
import { UserContext, UserContextProvider } from "./user/context";
import { User } from "./user/types";
import { useTestUser } from "./util/test-user";
import { TracingContextProvider } from "./util/tracing";
import { useAreInternalFeaturesEnabled } from "./util/users";

function BlankLoggedOutPage() {
  const { error } = useAuth0();
  useTrackPageview({ id: AppLocationId.LoggedOut });

  if (error) {
    Sentry.captureException(error);
    return (
      <FullScreenContainer center>
        <div className={"tw-text-left"}>
          <p className={"tw-text-lg"}>
            Looks like you're unable to access our services right now.
          </p>
          <p className={"tw-mt-sm tw-flex tw-items-center"}>
            <span className={"tw-text-lg"}>
              Click here to log out and try again:
            </span>
            <div className={"tw-w-[120px] tw-ml-lg"}>
              <LogoutButton className={"tw-border tw-text-center tw-p-sm"} />
            </div>
          </p>
        </div>
      </FullScreenContainer>
    );
  } else {
    return <SplashScreen />;
  }
}

// A route for external pages to link to which will automatically prompt
// the user to log in
function LoginRedirect() {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  });

  return <FullScreenLoader />;
}

// Helper to wrap something in a flat list of components (instead having a bunch of
// nested components in the JSX)
function Wrappers({
  list,
  children,
}: {
  list: (({ children }: { children: ReactNode }) => JSX.Element)[];
  children: ReactNode;
}): JSX.Element {
  return list.reduceRight(
    (nestedChildren, Component) => <Component>{nestedChildren}</Component>,
    <>{children}</>,
  );
}

// Context providers and other wrappers for <App>
function AppWrappers({ children }: { children: ReactNode }) {
  const { isLoading, user: auth0User } = useAuth0<User>();
  const testUser = useTestUser();
  const user = testUser ?? auth0User;

  useEffect(() => {
    if (user) {
      initAnalytics();
      identifyMixpanelUser(user);
      identifySentryUser(user);
    }
  }, [user]);

  const isRootPage = useRouteMatch({ path: "/", exact: true });

  return isLoading ? (
    <AppChromeMinimal isLoading>
      <FullScreenLoader />
    </AppChromeMinimal>
  ) : !user ? (
    <AppChromeMinimal>
      {isRootPage ? <BlankLoggedOutPage /> : <RequiresAuthentication />}
    </AppChromeMinimal>
  ) : (
    <Wrappers
      list={[
        AccessTokenContextProvider,
        UserContextProvider,
        WorkspaceContextProvider,
        PrefsContextProvider,
        TracingContextProvider,
        GlobalEventTrackingContextProvider,
        TrackAppLocation,
        MobileGuard,
        MethodsContextProvider,
        LocalModificationsProvider,
        ToastContextProvider,
      ]}
    >
      <AppChrome user={user}>{children}</AppChrome>
    </Wrappers>
  );
}

function Routes() {
  const user = useContext(UserContext);
  const areInternalFeaturesEnabled = useAreInternalFeaturesEnabled();
  const isDevMode = ENV === "development";

  return (
    <Switch>
      <Route exact path="/">
        {user ? <RedirectToDefaultWorkspace /> : <BlankLoggedOutPage />}
      </Route>
      <Route path="/workspace/:id">
        <Workspace />
      </Route>
      <Route path="/explore/:id">
        <JoinPublicWorkspace />
      </Route>
      {/* Backwards compatibility since we shared the explore-workspaceId urls. */}
      <Route
        path="/explore-jump-cp"
        render={() => <Redirect to="/explore/jump-cp" />}
      />
      <Route
        path="/explore-inflammasome-benchmark"
        render={() => <Redirect to="/explore/inflammasome-benchmark" />}
      />
      <Route path="/login-redirect">
        <LoginRedirect />
      </Route>
      <Route path="/api-keys">
        <APIKeyManagementPage />
      </Route>
      {areInternalFeaturesEnabled && (
        <Route path="/shoebill">
          <Shoebill />
        </Route>
      )}
      {isDevMode && (
        <Route exact path="/theme">
          <SpringThemePage />
        </Route>
      )}
      {isDevMode && (
        <Route path="/manual-test">
          <ManualTestHarness />
        </Route>
      )}
      {isDevMode && (
        <Route path="/api-debugger">
          <ApiDebugger />
        </Route>
      )}
      {isDevMode && (
        <Route path="/db-explorer">
          <DBExplorer />
        </Route>
      )}
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
}

export default function App() {
  return (
    <AppWrappers>
      <Routes />
      <ToastContainer />
      <CornerMenu className="tw-z-50 tw-fixed tw-right-0 tw-bottom-0" />
    </AppWrappers>
  );
}

function MobileGuard({ children }: { children: ReactNode }) {
  const isMobile =
    /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  const [show, setShow] = useState<boolean>(isMobile);
  return show ? (
    <MobileSplashScreen onDismiss={() => setShow(false)} />
  ) : (
    <>{children}</>
  );
}
