/**
 * Interface to tracking user analytics (a facade over Mixpanel).
 */
import { User } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import { ENV } from "./env";
import { Field } from "./imaging/types";
import { DatasetId } from "./types";

export const ANALYTICS_ENABLED = ENV === "production";

let finishAnalyticsLoading: () => void;
const analyticsPromise = new Promise((resolve) => {
  finishAnalyticsLoading = () => resolve(undefined);
});

export async function waitForAnalytics() {
  await analyticsPromise;
}

/**
 * Initialize analytics tracking.
 */
export function initAnalytics(): void {
  if (ANALYTICS_ENABLED) {
    try {
      // Configure Mixpanel (user analytics).
      mixpanel.init("e2ec4eb448da46a450de883278fdfaf0", {
        api_host: "https://analytics.springscience.com",
        // Disable geolocation and some other forms of tracking.
        ip: false,
        save_referrer: false,
        // Use localStorage over cookies, to protect caching.
        persistence: "localStorage",
        // We only load analytics once the user is logged in, so we feel safe
        // ignoring Do Not Track.
        ignore_dnt: true,
      });
      finishAnalyticsLoading();
    } catch (error) {
      // Consider Mixpanel errors to be non-fatal, but report them to Sentry.
      Sentry.captureException(error);
    }
  }
}

/**
 * Set the User for analytics conversions.
 *
 * This can be called before or after logging conversion events; Mixpanel will handle
 * post-identification attribution.
 */
export async function identifyUser(user: User): Promise<void> {
  if (ANALYTICS_ENABLED) {
    try {
      await waitForAnalytics();
      if (user.sub) {
        mixpanel.identify(user.sub);
        mixpanel.people.set({ email: user.email });
      }
    } catch (error) {
      // Consider Mixpanel errors to be non-fatal, but report them to Sentry.
      Sentry.captureException(error);
    }
  }
}

export type EventDefinition =
  | {
      id: "Page view";
      pathname: string;
      hash: string;
      search: string;
    }
  | { id: "Select dataset"; dataset: DatasetId }
  | { id: "Select plate"; plate: string }
  | { id: "Select well"; well: string }
  | { id: "Select field of view"; field: Field }
  // Entered anything in a search query.
  | { id: "Search query enter" }
  | { id: "Search result navigate"; term: string }
  | { id: "Land on 404 page"; pathname: string }
  | { id: "Click button"; name: string }
  | { id: "Change select option"; name: string };

/**
 * Track an analytics event.
 */
export async function trackEvent(event: EventDefinition): Promise<void> {
  const { id, ...properties } = event;
  if (ANALYTICS_ENABLED) {
    try {
      await waitForAnalytics();
      mixpanel.track(id, properties);
    } catch (error) {
      // Consider Mixpanel errors to be non-fatal, but report them to Sentry.
      Sentry.captureException(error);
    }
  } else {
    // eslint-disable-next-line no-console
    console.log(
      `Logging event: '${id}' (${JSON.stringify(properties, undefined, 1)})`,
    );
  }
}
