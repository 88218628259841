import { makeDatasetApi } from "src/hooks/api";
import { Fetchable } from "@spring/core/result";

type ArgoJobType =
  | "ps_inference"
  | "pf_inference"
  | "ssc_inference"
  | "pf_clustering";

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type ArgoJobStatus = "Running" | "Failed" | "Succeeded";

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type ArgoJobPayload = {
  argo_job_type: string;
  source_artifact_id: string;
  argo_job_id: string;
  status: ArgoJobStatus;
  progress_start: number;
  progress_end: number;
};

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type ArgoJobStatusResponse = {
  [id: string]: ArgoJobPayload;
};

export const useArgoJobStatusByType = makeDatasetApi("argo_jobs/<argoJobType>")<
  ArgoJobStatusResponse,
  { argoJobType: ArgoJobType }
>();

export type ArgoJob =
  | { state: "loading" | "unknown" }
  | { state: "loaded"; payload: ArgoJobPayload };

export function getArgoJobFromResponse(
  sourceArtifactId: string,
  maybeArgoStatus: Fetchable<ArgoJobStatusResponse>,
): ArgoJob {
  return maybeArgoStatus === undefined
    ? // We haven't loaded job status yet
      { state: "loading" }
    : maybeArgoStatus.successful
      ? sourceArtifactId in maybeArgoStatus.value
        ? // We know the status of the job
          {
            state: "loaded",
            payload: maybeArgoStatus.value[sourceArtifactId],
          }
        : // The job wasn't found in the response
          { state: "unknown" }
      : // TODO: Separate case for the load failing?
        { state: "unknown" };
}
