import cx from "classnames";
import { Fetchable } from "@spring/core/result";
import { FilterSqlClause } from "../../Control/FilterSelector/types";
import { createSelectQueryForFilteredFeatures } from "../../hooks/features";
import { UntypedFeatures } from "../../types";
import { DB, useQueryAsRecords } from "../../util/sql";
import Histogram, { Highlight } from "../Histogram";

export default function DistributionHistogram({
  featuresDB,
  column,
  filter,
  highlights,
  onClick,
  shouldAnimate,
}: {
  featuresDB: Fetchable<DB>;
  column: string;
  filter: FilterSqlClause;
  highlights: Highlight[] | null;
  onClick: (value: number) => void;
  shouldAnimate: boolean;
}) {
  // Hackily fetch all features that satisfy the filter.
  // See TODO in Histogram to do a better job of this; we can bin in DuckDB directly.
  const features = useQueryAsRecords<UntypedFeatures[number]>(
    featuresDB?.successful ? featuresDB.value : undefined,
    createSelectQueryForFilteredFeatures("*", filter),
  );

  if (!features) {
    // TODO(benkomalo): put in some kind of fuzzy histogram placeholder? right now
    // it just reserves the vertical whitespace to minimize a "pop". If we put a
    // loader in, it seems to flicker and look worse, IMO, because the queries are so
    // fast.
    return <div className={"tw-w-full tw-h-[200px]"} />;
  } else if (!features.successful) {
    return (
      <div
        className={cx(
          "tw-w-full tw-h-[200px] tw-flex tw-items-center tw-justify-center",
          "tw-text-lg tw-text-red-error",
        )}
      >
        Oops. Something went wrong loading the data. Please reload the page to
        try again.
      </div>
    );
  } else {
    return (
      <Histogram
        data={features.value}
        column={column}
        height={200}
        highlights={highlights}
        animateFirstRender={shouldAnimate}
        onClick={onClick}
        hoverTooltipTitle="Click to view image group"
        canScrollHorizontally
      />
    );
  }
}
