import * as Popover from "@radix-ui/react-popover";
import cx from "classnames";
import { ReactNode } from "react";

export function PopoverMessage({
  isOpen,
  onOpenChange,
  children,
  contents,
  sideOffset = 0,
}: {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  children: ReactNode;
  contents: ReactNode;
  sideOffset?: number;
}) {
  return (
    <Popover.Root open={isOpen} onOpenChange={onOpenChange}>
      <Popover.Anchor asChild>{children}</Popover.Anchor>
      <Popover.Portal>
        <Popover.Content
          side="bottom"
          sideOffset={sideOffset}
          align="center"
          className={"tw-flex tw-flex-row tw-outline-none"}
        >
          <Popover.Arrow className={"tw-fill-purple tw-w-[16px] tw-h-[8px]"} />
          <div
            className={cx(
              "tw-rounded tw-bg-purple tw-text-white",
              "tw-text-sm tw-shadow-lg",
            )}
          >
            {contents}
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
