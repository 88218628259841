import * as Tabs from "@radix-ui/react-tabs";
import cx from "classnames";
import { useState } from "react";
import { ArrowLeft, ArrowRight } from "react-feather";
import { DatasetId } from "src/types";
import { DeprecatedButton } from "../../Common/DeprecatedButton";
import { ImageSet } from "../../imaging/types";
import { Classification } from "../Context";
import { DemographicColumnOption, DemographicData } from "../types";
import { ClassHeader } from "./ClassHeader";
import { ClassImages } from "./ClassImages";
import { ClassMetrics } from "./ClassMetrics";

type ExpandedClassTab = "images" | "metrics";

type ExpandedClassSidebarProps = React.HTMLProps<HTMLDivElement> & {
  classification: Classification;
  dataset: DatasetId;
  imageSet: ImageSet | null;
  onClose: () => void;
  demographicColumnOptions: Array<DemographicColumnOption>;
  selectedDemographicColumn: DemographicColumnOption | null;
  onChangeDemographicColumn: (option: DemographicColumnOption | null) => void;
  demographicData: DemographicData | null;
  previousClassificationName: string | null;
  nextClassificationName: string | null;
  onSelectClassification: (classificationName: string) => void;
};

export function ExpandedClassSidebar({
  classification,
  dataset,
  imageSet,
  onClose,
  demographicColumnOptions,
  selectedDemographicColumn,
  onChangeDemographicColumn,
  demographicData,
  previousClassificationName,
  nextClassificationName,
  onSelectClassification,
}: ExpandedClassSidebarProps) {
  const [currentTab, setCurrentTab] = useState<ExpandedClassTab>("images");

  const getTabClassNames = (tabName: ExpandedClassTab) =>
    cx(
      "tw-pb-1 tw-border-b-2 tw-font-sans tw-text-gray-600 tw-border-gray-300 tw-w-1/2",
      {
        "tw-text-purple-700 tw-border-purple-600": tabName === currentTab,
      },
    );

  const spacer = <div className="tw-w-1/2" />;

  return (
    <div
      className={cx(
        "tw-h-full tw-w-1/3 tw-max-w-[600px] tw-min-w-[550px] tw-overflow-hidden",
        "tw-flex tw-flex-col tw-p-6 tw-pb-20",
      )}
    >
      <div className="tw-flex tw-justify-between tw-space-x-8 tw-mb-4">
        {previousClassificationName !== null ? (
          <DeprecatedButton
            className={"tw-min-w-0 tw-w-fit tw-max-w-1/2"}
            onClick={() => onSelectClassification(previousClassificationName)}
          >
            <div className="tw-mr-2">
              <ArrowLeft size={16} />
            </div>
            <div className="tw-truncate">{previousClassificationName}</div>
          </DeprecatedButton>
        ) : (
          spacer
        )}

        {nextClassificationName !== null ? (
          <DeprecatedButton
            className={"tw-min-w-0 tw-w-fit tw-max-w-1/2"}
            onClick={() => onSelectClassification(nextClassificationName)}
          >
            <div className="tw-truncate">{nextClassificationName}</div>
            <div className="tw-ml-2">
              <ArrowRight size={16} />
            </div>
          </DeprecatedButton>
        ) : (
          spacer
        )}
      </div>

      <div
        className={cx(
          "tw-flex-1",
          "tw-flex tw-flex-col tw-overflow-hidden",
          "tw-border tw-rounded-md tw-shadow-lg",
        )}
      >
        <ClassHeader
          className="tw-flex-none tw-px-8 tw-pt-6"
          classification={classification}
          isExpanded={true}
          onToggleExpanded={onClose}
        />

        <Tabs.Root
          className="tw-flex-1 tw-flex tw-flex-col tw-overflow-hidden tw-mt-8"
          defaultValue={currentTab}
          onValueChange={(value) => setCurrentTab(value as ExpandedClassTab)}
        >
          <Tabs.List className="tw-flex-none tw-px-8">
            <Tabs.Trigger value="images" className={getTabClassNames("images")}>
              Images
            </Tabs.Trigger>
            <Tabs.Trigger
              value="metrics"
              className={getTabClassNames("metrics")}
            >
              Metrics
            </Tabs.Trigger>
          </Tabs.List>

          <Tabs.Content
            value="images"
            className="tw-flex-1 tw-flex tw-flex-col tw-overflow-hidden tw-py-8"
          >
            <ClassImages
              className="tw-flex-1"
              classification={classification}
              dataset={dataset}
              imageSet={imageSet}
            />
          </Tabs.Content>
          <Tabs.Content
            value="metrics"
            className="tw-flex-1 tw-flex tw-flex-col tw-overflow-hidden tw-py-8"
          >
            <ClassMetrics
              className="tw-flex-1 tw-overflow-y-auto"
              classification={classification}
              demographicColumnOptions={demographicColumnOptions}
              selectedDemographicColumn={selectedDemographicColumn}
              onChangeDemographicColumn={onChangeDemographicColumn}
              demographicData={demographicData}
            />
          </Tabs.Content>
        </Tabs.Root>
      </div>
    </div>
  );
}
