import { useMemo } from "react";
import { AccessToken } from "src/Auth0/accessToken";
import {
  useActiveExperimentId,
  useActiveWorkspaceId,
} from "src/Workspace/hooks";
import { DatasetId, WorkspaceId } from "src/types";
import {
  ApiClient,
  ApiFetchable,
  ApiPrefix,
  ClientOrBuilder,
  Falsy,
  QueryString,
  adminApi,
  datasetApi,
  examplesApi,
  immunofluorescenceApi,
  workspaceApi,
} from "src/util/api-client";
import {
  AdminRoutes,
  ApiUrlOptions,
  RouteEntry,
  RoutePlaceholders,
  RouteQuery,
  WorkspaceRoutes,
} from "src/util/api-client/types";
import { apiPath } from "src/util/api-client/util";
import { KeyedMutator } from "swr/dist/types";
import { Fetchable } from "@spring/core/result";
import { useAccessToken } from "./auth0";
import { useAuthenticatedFetchableWithMutate } from "./fetch";

export type CanSkip<T> =
  | ({ skip: true } & Partial<T>)
  | ({ skip?: boolean } & T);

function notSkipped<T>(options: CanSkip<T>): options is T & { skip?: false } {
  return !options.skip;
}

function useApiClient<Routes extends RouteEntry>({
  skip,
  getClient,
}: {
  skip?: boolean;
  getClient: (options: {
    accessToken: AccessToken;
  }) => ApiClient<Routes> | undefined;
}): ApiClient<Routes> | undefined {
  const accessToken = useAccessToken();

  return useMemo(
    () => (skip ? undefined : getClient({ accessToken })),
    [accessToken, getClient, skip],
  );
}

interface Skippable extends Record<string, unknown> {
  skip?: boolean;
}
interface SkippableUseApi<Routes extends RouteEntry, Options> {
  (): ApiClient<Routes>;
  (options: Options & { skip?: false }): ApiClient<Routes>;
  (options: Partial<Options> & { skip: true }): undefined;
  (options: Options): ApiClient<Routes> | undefined;
}

export function useWorkspaceIdFromContext(
  options: { workspace?: WorkspaceId | Falsy } & Skippable,
): WorkspaceId | undefined {
  const activeWorkspaceId = useActiveWorkspaceId({
    optional: options.skip || options.workspace !== undefined,
  });

  return options.workspace || activeWorkspaceId;
}

function useDatasetIdFromContext(
  options: { dataset?: DatasetId | Falsy } & Skippable,
): DatasetId | undefined {
  const activeExperimentId = useActiveExperimentId({
    optional: options.skip || options.dataset !== undefined,
  });
  return options.dataset || activeExperimentId;
}

export const useAdminApi = function useAdminApi(
  options: Skippable = {},
): ApiClient<AdminRoutes> | undefined {
  return useApiClient(
    useMemo(
      () => ({
        skip: options.skip,
        getClient: ({ accessToken }) =>
          adminApi({
            accessToken,
          }),
      }),
      [options.skip],
    ),
  );
} as SkippableUseApi<ApiPrefix<typeof adminApi>, Skippable>;

// NOTE(danlec): Intentionally exporting this; it needs to be visible for type
// declarations to be built
// ts-unused-exports:disable-next-line
export interface UseWorkspaceApiOptions extends Skippable {
  workspace?: WorkspaceId | Falsy;
}

export const useWorkspaceApi = function useWorkspaceApi(
  options: UseWorkspaceApiOptions = {},
): ApiClient<WorkspaceRoutes> | undefined {
  const workspace = useWorkspaceIdFromContext(options);

  return useApiClient(
    useMemo(
      () => ({
        skip: options.skip,
        getClient: ({ accessToken }) =>
          workspace
            ? workspaceApi({
                accessToken,
                workspace,
              })
            : undefined,
      }),
      [options.skip, workspace],
    ),
  );
} as SkippableUseApi<ApiPrefix<typeof workspaceApi>, UseWorkspaceApiOptions>;

export interface UseDatasetLikeApiOptions extends Skippable {
  workspace?: WorkspaceId | Falsy;
  dataset?: DatasetId | Falsy;
}

function makeUseDatasetLikeApi<Routes extends RouteEntry>(
  api: ClientOrBuilder<Routes, { workspace: WorkspaceId; dataset: DatasetId }>,
) {
  return function useDatasetLikeApi(options: UseDatasetLikeApiOptions = {}) {
    const workspace = useWorkspaceIdFromContext(options);
    const dataset = useDatasetIdFromContext(options);

    return useApiClient(
      useMemo(
        () => ({
          skip: options.skip,
          getClient: ({ accessToken }) =>
            workspace && dataset
              ? api({
                  accessToken,
                  workspace,
                  dataset,
                })
              : undefined,
        }),
        [dataset, options.skip, workspace],
      ),
    );
  } as SkippableUseApi<Routes, UseDatasetLikeApiOptions>;
}

type UseApiFetchableReturn = "verbose" | "fetchable" | "simple";

function useApiFetchable<T>(
  config: ApiFetchable<T> | Falsy,
  returnType: "simple",
): T | undefined;
function useApiFetchable<T>(
  config: ApiFetchable<T> | Falsy,
  returnType: "verbose",
): VerboseFetchable<T>;
function useApiFetchable<T>(
  config: ApiFetchable<T> | Falsy,
  returnType?: "fetchable",
): Fetchable<T>;
function useApiFetchable<T>(
  config: ApiFetchable<T> | Falsy,
  returnType: UseApiFetchableReturn,
): Fetchable<T> | VerboseFetchable<T> | T;
function useApiFetchable<T>(
  config: ApiFetchable<T> | Falsy,
  returnType: UseApiFetchableReturn = "fetchable",
): Fetchable<T> | VerboseFetchable<T> | T {
  config ||= { url: null };
  const { url, requestInit, configuration, fetchKind, transform, skip } =
    config;

  const [fetchable, mutate] = useAuthenticatedFetchableWithMutate<T>(
    skip ? null : url,
    requestInit,
    configuration,
    fetchKind,
    transform,
  );

  return useMemo(() => {
    switch (returnType) {
      case "simple":
        return fetchable?.successful ? fetchable.value : undefined;
      case "verbose":
        return {
          fetchable,
          skipped: skip ?? false,
          response: fetchable?.successful ? fetchable.value : undefined,
          mutate,
          loading: !skip && !fetchable,
        };
      case "fetchable":
        return fetchable;
    }
  }, [fetchable, mutate, returnType, skip]);
}

interface VerboseFetchable<Response> {
  fetchable: Fetchable<Response>;
  response: Response | undefined;
  loading: boolean;
  skipped: boolean;
  mutate: KeyedMutator<Response>;
}

// NOTE(danlec): Intentionally exporting this; it needs to be visible for type
// declarations to be built
// ts-unused-exports:disable-next-line
export interface UseApiFetchable<Options, Input, Response> {
  (
    options: Record<string, unknown> & Options & CanSkip<Input>,
    returnType: "simple",
  ): Response | undefined;
  (
    options: Record<string, unknown> & Options & CanSkip<Input>,
    returnType: "verbose",
  ): VerboseFetchable<Response>;
  (
    options: Record<string, unknown> & Options & CanSkip<Input>,
    returnType?: "fetchable",
  ): Fetchable<Response>;
}

/**
 * This takes a useSomethingApi and returns a function that can be used to make
 * fetchable based API helpers.
 *
 * For example:
 *
 * const makeFooApi = makeUseApiFetchableMaker(useFooApi);
 *
 * const useSomeFooRoute = makeFooApi("read/<id>/model")<SomeReturnType>();
 * const useSomeQueryRoute =
 *   makeFooApi("read/<id>/model")<SomeReturnType>(({ name: string }) => ({ name }));
 *
 * Then we can use the generated hook in a component/hook like this:
 *
 * const resultA = useSomeFooRoute({ id: ... })
 * const resultB = useSomeQueryRoute({ id: ..., name: ... })
 *
 * The generated hooks also support skipping, so you can always put
 *
 * const result = useSomeFooRoute({ skip: true })
 *
 * ... if you don't actually want to fetch the route but still want to obey the rules
 * of hooks
 */
function makeUseApiFetchableMaker<Route extends RouteEntry, Options>(
  useApi: SkippableUseApi<Route, Options>,
) {
  function makeUseFetchable<
    R extends Route["suffix"] = Route["suffix"],
    GetRouteOptions = unknown,
  >(route: R | ((options: GetRouteOptions) => R)) {
    return <Response = unknown, Input = unknown>(
      getQuery?: (input: Input) => RouteQuery<Route, R>,
      getOptions?: (
        input: Input,
      ) => Omit<ApiFetchable<Response> & ApiUrlOptions, "url">,
    ): UseApiFetchable<
      Options,
      Input & RoutePlaceholders<Route, R> & GetRouteOptions,
      Response
    > => {
      return ((
        options: Options &
          CanSkip<Input & RoutePlaceholders<Route, R> & GetRouteOptions>,
        returnType: UseApiFetchableReturn = "fetchable",
      ) => {
        const result = notSkipped(options)
          ? typeof route === "function"
            ? route(options)
            : route
          : null;

        const {
          route: fetchableRoute,
          query = undefined,
          options: fetchableOptions = undefined,
        } = result && notSkipped(options)
          ? {
              route: apiPath<Route, R>(result, options),
              query: getQuery?.(options),
              options: getOptions?.(options),
            }
          : { route: undefined };

        return useApiFetchable(
          useApi(options)?.fetchable<Response>(
            fetchableRoute,
            query as QueryString,
            fetchableOptions,
          ),
          returnType,
        );
      }) as UseApiFetchable<
        Options,
        Input & RoutePlaceholders<Route, R> & GetRouteOptions,
        Response
      >;
    };
  }

  return makeUseFetchable;
}

export const useDatasetApi = makeUseDatasetLikeApi(datasetApi);
export const useExamplesApi = makeUseDatasetLikeApi(examplesApi);
const useImmunofluorescenceApi = makeUseDatasetLikeApi(immunofluorescenceApi);

export const makeAdminApi = makeUseApiFetchableMaker(useAdminApi);
export const makeWorkspaceApi = makeUseApiFetchableMaker(useWorkspaceApi);
export const makeDatasetApi = makeUseApiFetchableMaker(useDatasetApi);
// TODO(danlec): Remove this if we really don't end up using it
// ts-unused-exports:disable-next-line
export const makeExamplesApi = makeUseApiFetchableMaker(useExamplesApi);
export const makeImmunofluorescenceApi = makeUseApiFetchableMaker(
  useImmunofluorescenceApi,
);
