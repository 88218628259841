import { AccessToken } from "src/Auth0/accessToken";
import { hasKeys } from "../has-keys";
import { toTypedString } from "../typed-string";
import { ApiClient, ApiUrlBuilder } from "./client";
import {
  ApiPath,
  ClientOrBuilder,
  JSONQueryString,
  QueryString,
  RouteEntry,
  RoutePlaceholders,
  UrlPath,
} from "./types";

function toSearchParams(query: JSONQueryString = {}): URLSearchParams {
  const params = new URLSearchParams();
  Object.entries(query).forEach(([key, value]) => {
    const values =
      value === undefined
        ? []
        : Array.isArray(value)
          ? value
          : value instanceof Set
            ? Array.from(value)
            : [value];

    values.forEach((v) => params.append(key, String(v)));
  });

  return params;
}

export function serializeQueryString(query: QueryString | undefined): string {
  const params =
    query instanceof URLSearchParams ? query : toSearchParams(query);

  const serialized = params.toString();
  return serialized ? `?${serialized}` : "";
}

export function clientOrBuilder<Routes extends RouteEntry, T = unknown>(
  getPrefix: (options: T) => UrlPath,
) {
  return ((options: T & { accessToken?: AccessToken }) => {
    const accessToken = options.accessToken;
    const prefix = getPrefix(options);
    if (accessToken) {
      return new ApiClient<Routes>({ accessToken, prefix });
    } else {
      return new ApiUrlBuilder<Routes>({ prefix });
    }
  }) as ClientOrBuilder<Routes, T>;
}

// Forms a UrlPath via a template where replacements are know to not be null/undefined
// and any inserted values are passed through encodeURIComponent
export function urlPath(
  text: TemplateStringsArray | string,
  ...values: string[]
): UrlPath {
  return toTypedString<UrlPath>(
    typeof text === "string"
      ? encodeURIComponent(text)
      : text
          .flatMap((text, index) => {
            if (index >= values.length) {
              return text;
            }
            const value = values[index];
            return [text, encodeURIComponent(value)];
          })
          .join("")
          .trim(),
  );
}

export function apiPath<Routes extends RouteEntry, R extends Routes["suffix"]>(
  route: R,
  params: RoutePlaceholders<Routes, R>,
): ApiPath<Routes> {
  return toTypedString<ApiPath<Routes>>(
    route.replace(/<(.*?)>/g, (all, placeholder: string) => {
      if (hasKeys(params, placeholder)) {
        const replacement = (params as Record<string, unknown>)[placeholder];
        if (
          typeof replacement === "string" ||
          typeof replacement === "number" ||
          typeof replacement === "boolean"
        ) {
          return encodeURIComponent(replacement);
        }
      }

      throw new Error(
        `Placeholder <${placeholder}> in route ${route} wasn't specified`,
      );
    }),
  );
}

export function handleAborted(reason: any) {
  if (reason instanceof DOMException && reason.name === "AbortError") {
    return;
  }
  throw reason;
}

export function jsonPostInit(body: unknown): RequestInit {
  return {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };
}
