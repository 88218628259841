import React, { ReactElement, ReactNode, Suspense, useMemo } from "react";
import { FullScreenLoader } from "src/Common/FullScreenLoader";
import { ViewStateContextProvider } from "src/MegaMap/ViewStateContext";
import { DatasetId } from "src/types";
import { FeatureSetSelection } from "../FeatureSelector/types";
import { extractWellAggregatedNamesFromSelection } from "../FeatureSelector/utils";
import ToastContainer from "../Toast/ToastContainer";
import { useFeatureFlag } from "../Workspace/feature-flags";
import { useComponentSpan } from "../util/tracing";
import { MULTI_FEATURE_TOAST_CONTAINER_ID } from "./FeatureSetManagementPage.constants";
import { SimilaritiesViewStateContextProvider } from "./MultiFeature/SimilaritiesViewStateContext";
import { UmapScatterPlot } from "./MultiFeatureSimilaritiesView";
import { MultiFeatureView } from "./views";

const MegaMap = React.lazy(() => import("../MegaMap"));

export const MegaMapContainer = React.forwardRef<
  HTMLDivElement,
  {
    dataset: DatasetId;
    features: FeatureSetSelection[];
    plates: string[];
  }
>(function MegaMapContainer({ dataset, features, plates }, ref) {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <div className="tw-h-full" ref={ref}>
        <ViewStateContextProvider>
          <MegaMap dataset={dataset} features={features} plates={plates} />
        </ViewStateContextProvider>
      </div>
    </Suspense>
  );
});

/**
 * A container component to render modules used to analyze 2+ numerical measurements.
 *
 * Note: it's still the case that the measurements come from a single FeatureSet source,
 * since many constituent modules make that assumption.
 */
export default function MultiFeatureSetDetails({
  dataset,
  plates,
  feature,
  selectedTab,
  onOpenFilterSelector,
  onSetFilterHeader,
  normalizationColumns,
  onChangeNormalizationColumns,
}: {
  dataset: DatasetId;
  plates: string[];
  feature: FeatureSetSelection;
  selectedTab: Exclude<MultiFeatureView, MultiFeatureView.Ranking>;
  onOpenFilterSelector: () => void;
  onSetFilterHeader: (header: ReactNode) => void;
  normalizationColumns: string[];
  onChangeNormalizationColumns: (columns: string[]) => void;
}) {
  const isUmapUserConfigSamplingEnabled = useFeatureFlag(
    "umap-user-config-sampling",
  );
  useComponentSpan("UmapScatterPlot", [dataset, plates, feature, selectedTab]);

  const featureNames = useMemo(
    () => extractWellAggregatedNamesFromSelection(feature),
    [feature],
  );
  const columns = useMemo(() => {
    switch (feature.type) {
      case "embedding":
        // Embeddings implicitly always include all columns, since individual column
        // analysis isn't usually meaningful.
        return [];

      case "numerical":
      case "prediction":
        return feature.columns;
    }
  }, [feature]);

  const content = useMemo((): ReactElement => {
    switch (selectedTab) {
      case MultiFeatureView.Similarities:
        return (
          <SimilaritiesViewStateContextProvider>
            <UmapScatterPlot
              dataset={dataset}
              plates={plates}
              features={featureNames}
              columns={columns}
              onOpenFilterSelector={onOpenFilterSelector}
              onSetFilterHeader={onSetFilterHeader}
              normalizationColumns={normalizationColumns}
              onChangeNormalizationColumns={onChangeNormalizationColumns}
            />
          </SimilaritiesViewStateContextProvider>
        );
    }
  }, [
    selectedTab,
    dataset,
    plates,
    featureNames,
    columns,
    onOpenFilterSelector,
    onSetFilterHeader,
    normalizationColumns,
    onChangeNormalizationColumns,
  ]);

  return (
    <div className="tw-h-full tw-relative">
      {content}
      {isUmapUserConfigSamplingEnabled ? null : (
        <div className="tw-absolute tw-inset-x-4 tw-top-0 tw-flex tw-justify-center">
          <ToastContainer
            id={MULTI_FEATURE_TOAST_CONTAINER_ID}
            position="custom"
            disableAutoDismiss
          />
        </div>
      )}
    </div>
  );
}
