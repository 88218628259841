/**
 * Selector for a metadata column in a Dataset.
 */
import { useEffect, useRef } from "react";
import Select from "react-select-plus";
import "react-select-plus/dist/react-select-plus.css";
import { DatasetId } from "src/types";
import { useDatasetSampleMetadata } from "../hooks/datasets";
import { columnsToHide, getUniqueValuesByColumn } from "../util/dataset-util";
import { columnComparator } from "../util/sorting";

export default function DatasetColumnSelector({
  dataset,
  plate,
  column,
  placeholder,
  onSelectColumn,
  autoSelect,
  omitColumns,
}: {
  dataset: DatasetId;
  plate?: string;
  column: string | null;
  placeholder: string;
  onSelectColumn: (column: string) => void;
  autoSelect?: boolean | "first" | "first-non-unique";
  omitColumns?: string[];
}) {
  const metadata = useDatasetSampleMetadata({ dataset, plate });
  const valuesByColumn = metadata?.map((unwrapped) => {
    const byColumn = getUniqueValuesByColumn(unwrapped.sampleMetadata);
    const toHide = columnsToHide(unwrapped.sampleMetadata);
    if (omitColumns || toHide.length > 0) {
      const combined = (omitColumns || []).concat(toHide);
      return Object.fromEntries(
        Object.entries(byColumn).filter(
          ([column]) => !combined.includes(column),
        ),
      );
    } else {
      return byColumn;
    }
  });
  const hasSelected = useRef(false);

  useEffect(() => {
    if (autoSelect && valuesByColumn?.successful && !hasSelected.current) {
      hasSelected.current = true;

      const unwrapped = valuesByColumn.value;
      switch (autoSelect) {
        case true:
        case "first": {
          onSelectColumn(Object.keys(valuesByColumn)[0]);
          break;
        }
        case "first-non-unique": {
          const firstNonUnique = Object.entries(unwrapped).find(
            ([, values]) => values.size > 1,
          );
          if (firstNonUnique) {
            onSelectColumn(firstNonUnique[0]);
          } else {
            // Fallback to first entry.
            onSelectColumn(Object.keys(unwrapped)[0]);
          }
          break;
        }
      }
    }
  }, [autoSelect, valuesByColumn, onSelectColumn]);

  return (
    <Select
      disabled={metadata == null}
      placeholder={`${placeholder}...`}
      simpleValue={true}
      options={valuesByColumn
        ?.map((unwrapped) =>
          Object.keys(unwrapped)
            .sort(columnComparator)
            .map((id) => ({
              label: id,
              value: id,
            })),
        )
        .orElse(() => [])}
      value={column}
      onChange={(column: string) => {
        hasSelected.current = true;
        onSelectColumn(column);
      }}
    />
  );
}

DatasetColumnSelector.defaultProps = {
  placeholder: "Column",
};
