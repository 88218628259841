/**
 * Loading throbber.
 */
import cx from "classnames";
import "loaders.css/loaders.css";
import { ReactNode } from "react";
import hexagon from "../assets/spinner.gif";

export function Center({
  children,
  extraClasses,
}: {
  children: ReactNode;
  extraClasses?: string;
}) {
  return (
    <div
      className={cx(
        "tw-flex tw-items-center tw-justify-center tw-w-full tw-h-full",
        extraClasses,
      )}
    >
      {children}
    </div>
  );
}

export default function Loader({
  className,
  size,
}: {
  className?: string;
  size?: "small" | "medium" | "large" | number;
}) {
  let width: number;
  if (size === "small") {
    width = 32;
  } else if (size === undefined || size === "medium") {
    width = 64;
  } else if (size === "large") {
    width = 96;
  } else {
    width = size;
  }

  return (
    <img src={hexagon} className={className} style={{ width, height: width }} />
  );
}
