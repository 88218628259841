/**
 * Component for viewing image data.
 *
 * Supports viewing single fields, entire wells, and entire plates.
 */
import * as Tabs from "@radix-ui/react-tabs";
import cx from "classnames";
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from "react";
import { Provider } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Field } from "src/imaging/types";
import { toNumericField } from "src/imaging/util";
import MultiChannelView from "src/immunofluorescence/MultiChannelView";
import {
  TimeSeriesContextProvider,
  useTimeSeriesContext,
} from "src/timeseries/Context";
import { TimeSeriesControls } from "src/timeseries/Controls";
import Strut from "../Common/Strut";
import { StickyControlsSidebar } from "../Control/ControlsSidebar";
import FieldSelector from "../Control/FieldSelector";
import PlateSelector from "../Control/PlateSelector";
import { TabStrip } from "../Control/TabStrip";
import WellSelector from "../Control/WellSelector";
import { PulseGuiderRoot } from "../Insights/PulseGuider";
import { useMethods } from "../Methods/hooks";
import { MethodSectionKey } from "../Methods/utils";
import history from "../history";
import { useAutoImageSet, useFields } from "../hooks/immunofluorescence";
import RenderSizeSlider from "../imaging/Control/RenderSizeSlider";
import { VisualizationContextProvider } from "../imaging/context";
import {
  ImageLoadEventBatchingContext,
  useDispatch,
} from "../imaging/state/hooks";
import configureStore, { clearChannelMap } from "../imaging/state/store";
import VisualizationControls from "../immunofluorescence/VisualizationControls";
import { useNestedRoute } from "../routing";
import { TimeSeriesMultiChannelView } from "../timeseries/TimeSeriesMultiChannelView";
import { DatasetId, MetadataColumnValue } from "../types";
import { useComponentSpan } from "../util/tracing";
import FullPlateView from "./FullPlateView";
import PlateMetadataLegend, {
  PlateMetadataLegendMap,
} from "./PlateMetadataLegend";
import WellGridView from "./WellGridView";
import WellMetadataTable from "./WellMetadataTable";
import { getGridLengthForFields } from "./scan-pattern";

type DisplayLevel = "field" | "well" | "plate";
type DisplayMode = "images" | "metadata";

// Minimum, maximum, and default image sizes for the DatasetLevel values.
const DISPLAY_LEVEL_SIZES: {
  [K in DisplayLevel]: { min: number; max: number; default: number };
} = {
  field: {
    min: 512,
    default: 1024,
    max: 2148,
  },
  well: {
    min: 256,
    default: 384,
    max: 1024,
  },
  plate: {
    min: 32,
    default: 48,
    max: 128,
  },
};

function displayLevelFromParams(
  plate: string | null,
  well: string | null,
  field: Field | null,
) {
  if (plate && well && field) {
    return "field";
  } else if (plate && well) {
    return "well";
  } else {
    return "plate";
  }
}

/**
 * Dynamically determine the width of an image cell based on a contentPane.
 */
function useCalculatedCellWidthToFit(
  displayLevel: DisplayLevel,
  dataset: DatasetId,
  plate: string | null,
  contentPane: HTMLElement | null,
) {
  // We only need to determine the # of fields in the well view; avoid a fetch.
  const fields = useFields(
    displayLevel === "well" && plate
      ? {
          dataset,
          acquisition: plate,
        }
      : { skip: true },
  );
  // HACK(benkomalo): defined in tw-p-8 rules below.
  const padding = 32 * 2;
  // HACK(davidsharff): only include breadcrumb height if we are sizing on height.
  const optionalBreadcrumbHeight =
    contentPane && contentPane.offsetHeight < contentPane.offsetWidth ? 60 : 0;

  let cellWidthToFit = null;
  if (displayLevel === "plate") {
    // For plates, we always just "fit" to the width and ignore the height. This is
    // because it's already very difficult to fit 384 well plates on a normal 14"
    // laptop, and if we fit on the height, I feel like it gets toooo small. It also
    // just feels more acceptable to scroll vertically.
    const contentWidth = contentPane?.offsetWidth;

    // TODO(benkomalo): wire in whether or not this is a 96- or 384-well plate so we can
    // adjust. Right now it just defaults to 384.
    const numColumns = 24;
    const labelWidth = 16;
    const gapWidth = 2;
    cellWidthToFit =
      contentWidth &&
      Math.floor(
        (contentWidth - padding - labelWidth - gapWidth * (numColumns - 1)) /
          numColumns,
      );
  } else if (displayLevel === "well") {
    // For wells, we fit to width or height.
    const gridSize = fields
      ?.map((unwrapped) => getGridLengthForFields(unwrapped.length))
      .orElse(() => 0);
    const gapWidth = 8;
    const contentWidth = contentPane
      ? Math.min(contentPane.offsetWidth, contentPane.offsetHeight)
      : 0;
    cellWidthToFit =
      contentWidth && gridSize
        ? (contentWidth -
            padding -
            optionalBreadcrumbHeight -
            gapWidth * (gridSize - 1)) /
          gridSize
        : null;
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (displayLevel === "field") {
    // For fields, we fit to width or height.
    cellWidthToFit = contentPane
      ? Math.min(contentPane.offsetWidth, contentPane.offsetHeight) -
        padding -
        optionalBreadcrumbHeight
      : null;
  }

  return cellWidthToFit;
}

function useSize(
  dataset: DatasetId,
  plate: string | null,
  displayLevel: DisplayLevel,
  contentPane: RefObject<HTMLElement>,
): [number | null, Dispatch<SetStateAction<number>>] {
  const cellWidthToFit = useCalculatedCellWidthToFit(
    displayLevel,
    dataset,
    plate,
    contentPane.current,
  );

  const prevDisplayLevel = useRef<DisplayLevel>(displayLevel);
  const [_size, setSize] = useState<number>(cellWidthToFit || -1);

  // HACK(benkomalo): useState has to use the same type for the state and setState
  // methods, so we use a -1 sentinel, but we signal to callers that the size *is*
  // nullable if the content pane isn't ready.
  let size: number | null = _size;
  if (size === -1) {
    size = null;
  }

  if (!size && cellWidthToFit) {
    setSize(cellWidthToFit);
  } else if (prevDisplayLevel.current !== displayLevel) {
    if (cellWidthToFit) {
      if (cellWidthToFit < DISPLAY_LEVEL_SIZES[displayLevel].min) {
        setSize(DISPLAY_LEVEL_SIZES[displayLevel].min);
      } else if (cellWidthToFit > DISPLAY_LEVEL_SIZES[displayLevel].max) {
        setSize(DISPLAY_LEVEL_SIZES[displayLevel].max);
      } else {
        setSize(cellWidthToFit);
      }
    } else {
      setSize(DISPLAY_LEVEL_SIZES[displayLevel].default);
    }
  }

  prevDisplayLevel.current = displayLevel;
  return [size, setSize];
}

function ImageViewer({
  dataset,
  plate,
  well,
  field,
  onSelectPlate,
  onSelectWell,
  onSelectField,
  onNavigateToWell,
  onNavigateToField,
}: {
  dataset: DatasetId;
  plate: string | null;
  well: string | null;
  field: Field | null;
  onSelectPlate: (plate: string | null) => void;
  onSelectWell: (well: string | null) => void;
  onSelectField: (field: Field | null) => void;
  onNavigateToWell: (well: string) => void;
  onNavigateToField: (field: Field) => void;
}) {
  const mainContentPaneRef = useRef(null);
  const dispatch = useDispatch();
  const displayLevel = displayLevelFromParams(plate, well, field);
  const [size, setSize] = useSize(
    dataset,
    plate,
    displayLevel,
    mainContentPaneRef,
  );
  const [mode, setMode] = useState<DisplayMode>("images");
  const [plateMetadataLegend, setPlateMetadataLegend] =
    useState<PlateMetadataLegendMap | null>(null);
  const [filteredLegendValues, setFilteredLegendValues] = useState<
    MetadataColumnValue[] | null
  >(null);

  const { maxTimepoint } = useTimeSeriesContext();

  useComponentSpan("DatasetViewer", [dataset, plate, well, field, size, mode]);
  const imageSet = useAutoImageSet({
    dataset,
    params: {
      imageSize: size,
      processingMode: "illumination-corrected",
    },
  });

  const onMetadataReady = useCallback((results) => {
    setPlateMetadataLegend(results);
    setFilteredLegendValues(null);
  }, []);

  const handleClickBreadcrumb = (crumbType: "plate" | "well") => {
    switch (crumbType) {
      case "plate": {
        dispatch({ type: "reset" });
        onSelectWell(null);
        return;
      }

      case "well": {
        dispatch({ type: "reset" });
        onSelectField(null);
        return;
      }

      default: {
        throw new Error(`Unhandled breadcrumb: ${crumbType}`);
      }
    }
  };

  return (
    <div className="tw-flex">
      <StickyControlsSidebar>
        {/* HACK(benkomalo): if changing padding,
            change useCalculatedCellWidthToFit above. */}
        <div className={"tw-p-8 tw-flex tw-flex-col"}>
          <span className={"tw-relative"}>
            <PlateSelector
              key={dataset}
              dataset={dataset}
              plate={plate}
              onSelectPlate={(plate) => {
                dispatch({ type: "reset" });
                onSelectPlate(plate);
              }}
              autoSelect
            />
          </span>
          {plate && (
            <>
              <Strut size={16} />
              <span className={"tw-relative"}>
                <WellSelector
                  key={`${dataset}_${plate}`}
                  dataset={dataset}
                  plate={plate}
                  well={well}
                  onSelectWell={(well) => {
                    dispatch({ type: "reset" });
                    onSelectWell(well);
                  }}
                />
              </span>
            </>
          )}
          {plate && well && (
            <>
              <Strut size={16} />
              <FieldSelector
                key={`${dataset}_${plate}`}
                dataset={dataset}
                plate={plate}
                field={field}
                onSelectField={(field) => {
                  dispatch({ type: "reset" });
                  onSelectField(field);
                }}
                onClearField={() => {
                  dispatch({ type: "reset" });
                  onSelectField(null);
                }}
              />
            </>
          )}
        </div>

        {plate && (
          <Tabs.Root
            value={mode}
            onValueChange={(value) => setMode(value as DisplayMode)}
            orientation="vertical"
          >
            <PulseGuiderRoot
              guiderKey={"metadata-tab"}
              position={{ corner: "top-right", offset: { x: -42, y: 16 } }}
              tooltipSide={"right"}
            >
              <TabStrip
                value={mode}
                options={[
                  { value: "images", label: "Images" },
                  { value: "metadata", label: "Metadata" },
                ]}
                ariaLabel={"Viewing mode"}
              />
            </PulseGuiderRoot>
            <Tabs.Content value="images" className={"tw-p-8"}>
              <PulseGuiderRoot
                guiderKey={"image-setting"}
                position={{ corner: "top-right", offset: { x: 0, y: 16 } }}
                tooltipSide={"right"}
              >
                <VisualizationControls
                  key={`${dataset}_${plate}`}
                  dataset={dataset}
                  plate={plate}
                />
              </PulseGuiderRoot>
              {size &&
                (displayLevel === "plate" ||
                  (well && displayLevel === "well") ||
                  (well && field && displayLevel === "field")) && (
                  <div className={"tw-mt-4"}>
                    <RenderSizeSlider
                      min={DISPLAY_LEVEL_SIZES[displayLevel].min}
                      max={DISPLAY_LEVEL_SIZES[displayLevel].max}
                      size={size}
                      onChange={setSize}
                    />
                  </div>
                )}
            </Tabs.Content>
            <Tabs.Content value="metadata" className={"tw-p-8"}>
              {displayLevel === "plate" && plateMetadataLegend && (
                <PlateMetadataLegend
                  key={plateMetadataLegend.column}
                  legend={plateMetadataLegend}
                  onFilterChanged={setFilteredLegendValues}
                />
              )}
              {well && (
                <WellMetadataTable
                  dataset={dataset}
                  plate={plate}
                  well={well}
                />
              )}
            </Tabs.Content>
          </Tabs.Root>
        )}
      </StickyControlsSidebar>
      <VisualizationContextProvider>
        <div
          className={
            "tw-flex-1 tw-h-[calc(100vh-theme(spacing.global-nav-height))] tw-overflow-auto tw-p-8"
          }
          ref={mainContentPaneRef}
        >
          {plate && (
            <div
              className="tw-flex tw-items-center tw-justify-between tw-mb-lg"
              style={{ maxWidth: `${size}px` }}
            >
              <SelectionBreadcrumbs
                className="tw-flex-none"
                plate={plate}
                well={well}
                field={field}
                onClickBreadcrumb={handleClickBreadcrumb}
              />

              {maxTimepoint > 0 &&
              size &&
              plate &&
              well &&
              field &&
              displayLevel === "field" ? (
                <TimeSeriesControls className="tw-ml-xl" />
              ) : null}
            </div>
          )}
          {size && plate && well && field && displayLevel === "field" ? (
            maxTimepoint > 0 ? (
              <TimeSeriesMultiChannelView
                dataset={dataset}
                plate={plate}
                well={well}
                field={field}
                imageSet={imageSet}
                size={size}
              />
            ) : (
              <MultiChannelView
                index={{
                  dataset,
                  plate,
                  well,
                  field: toNumericField(field),
                  t: 0,
                  z: 0,
                }}
                imageSet={imageSet}
                crop={null}
                size={size}
                showContextMenu
              />
            )
          ) : null}
          {size && plate && well && displayLevel === "well" && (
            <WellGridView
              index={{ dataset, plate, well }}
              imageSet={imageSet}
              size={size}
              onSelectField={onNavigateToField}
            />
          )}
          {size && plate && displayLevel === "plate" && (
            <FullPlateView
              key={`${dataset}_${plate}`}
              mode={mode}
              index={{ dataset, plate }}
              imageSet={imageSet}
              size={size}
              onSelectWell={onNavigateToWell}
              onMetadataReady={onMetadataReady}
              filteredLegendValues={filteredLegendValues}
            />
          )}
        </div>
      </VisualizationContextProvider>
    </div>
  );
}

export function SelectionBreadcrumbs({
  className,
  plate,
  well,
  field,
  onClickBreadcrumb,
}: {
  className?: string;
  plate: string;
  well: string | null;
  field: Field | null;
  onClickBreadcrumb?: (crumbType: "plate" | "well") => void;
}) {
  return (
    <div
      className={cx(
        "tw-inline-flex tw-justify-start tw-border tw-border-purple tw-rounded-lg tw-bg-white",
        className,
      )}
    >
      <Breadcrumb
        label={plate}
        includeSeparator={!!well}
        isAnchorCrumb
        onClick={
          onClickBreadcrumb &&
          (() => {
            if (well) {
              onClickBreadcrumb("plate");
            }
          })
        }
        disabled={!well || !onClickBreadcrumb}
      />
      {well && (
        <Breadcrumb
          label={well}
          includeSeparator={!!field}
          onClick={onClickBreadcrumb && (() => onClickBreadcrumb("well"))}
          disabled={!field || !onClickBreadcrumb}
        />
      )}
      {field && <Breadcrumb label={field} includeSeparator={false} disabled />}
    </div>
  );
}

// TODO(benkomalo): move this to own file.
export function Breadcrumb({
  label,
  includeSeparator,
  isAnchorCrumb,
  disabled,
  onClick,
}: {
  label: string;
  includeSeparator: boolean;
  isAnchorCrumb?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}) {
  return (
    <div
      className={cx(
        "tw-py-1 tw-px-2 tw-relative tw-text-purple",
        includeSeparator ? "tw-mr-6" : isAnchorCrumb ? "" : "tw-mr-2",
        !disabled && "hover:tw-text-purple-500",
      )}
      role={onClick ? "button" : "none"}
      onClick={onClick}
    >
      <span>{label}</span>
      {includeSeparator && <BreadcrumbSeparator />}
    </div>
  );
}

function BreadcrumbSeparator() {
  const separatorBorderWidth = "17px";
  return (
    <>
      {/*
        Note(davidsharff): create a full height chevron spacer composed of two overlapping (filled) triangles.
        The first uses the intended chevron color.
        The second matches container bg color and overlaps all but the outer right border.
      */}
      <span
        style={{
          position: "absolute",
          width: 0,
          height: 0,
          top: "-1px",
          right: "-34px",
          borderTop: `solid ${separatorBorderWidth} transparent`,
          borderRight: `solid ${separatorBorderWidth} transparent`,
          borderBottom: `solid ${separatorBorderWidth} transparent`,
          borderLeftWidth: `${separatorBorderWidth}`,
        }}
        className="tw-border-l tw-border-l-purple-500"
      />
      <span
        style={{
          position: "absolute",
          width: 0,
          height: 0,
          top: "-1px",
          right: "-33px",
          borderTop: `solid ${separatorBorderWidth} transparent`,
          borderRight: `solid ${separatorBorderWidth} transparent`,
          borderBottom: `solid ${separatorBorderWidth} transparent`,
          borderLeftWidth: `${separatorBorderWidth}`,
        }}
        className="tw-border-l tw-border-l-white"
      />
    </>
  );
}

function WithRoute(props: { dataset: DatasetId }) {
  type Params = {
    plate?: string;
    well?: string;
    field?: Field;
  };

  const { url } = useRouteMatch<{ url: string }>();
  const [params, setPath] = useNestedRoute<Params>(
    `${url}/:plate?/:well?/:field?`,
  );

  return (
    <ImageLoadEventBatchingContext.Provider value={false}>
      <TimeSeriesContextProvider
        dataset={props.dataset}
        plate={params.plate ?? null}
        well={params.well ?? null}
        field={params.field ?? null}
      >
        <ImageViewer
          plate={params.plate ?? null}
          well={params.well ?? null}
          field={params.field ?? null}
          onSelectPlate={(plate) => {
            // If we're going from an empty state to a selected state, it's likely
            // the initial auto-selection; don't preserve that in the history.
            const replace = !params.plate;
            setPath({ plate: plate ?? undefined }, clearChannelMap, replace);
          }}
          onSelectWell={(well) =>
            setPath({
              ...params,
              well: well ?? undefined,
              field: well ? params.field : undefined,
            })
          }
          onSelectField={(field) =>
            setPath({ ...params, field: field ?? undefined })
          }
          onNavigateToWell={(well) =>
            setPath({
              plate: params.plate,
              well,
            })
          }
          onNavigateToField={(field) =>
            setPath({
              plate: params.plate,
              well: params.well,
              field,
            })
          }
          {...props}
        />
      </TimeSeriesContextProvider>
    </ImageLoadEventBatchingContext.Provider>
  );
}

const store = configureStore(history);

export default function Wrapper({ dataset }: { dataset: DatasetId }) {
  useMethods(MethodSectionKey.imageViewer);

  return (
    <Provider store={store}>
      <WithRoute dataset={dataset} />
    </Provider>
  );
}
