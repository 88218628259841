import * as Popover from "@radix-ui/react-popover";
import cx from "classnames";
import { ReactNode, useCallback, useState } from "react";
import { ArrowRight } from "react-feather";
import { Link, useLocation } from "react-router-dom";
import { AnalyzeView, MenuView } from "src/FeatureSetManagementPage/views";
import { useFeatureSets } from "src/hooks/features";
import { QS, useTypedQueryParams } from "src/routing";
import { DatasetId, WorkspaceId } from "src/types";
import { measurementUrl } from "src/util/urls";
import { useAreInternalFeaturesEnabled } from "../util/users";
import { CREATE_VIEWS } from "./createViews";

interface StyledMenuProps extends MenuView {
  key: string;
  url: string;
  highlighted: boolean;
}

function styledMenu({
  views,
  trigger,
  onToggle,
  footerElement,
  isOpen,
}: {
  views: StyledMenuProps[];
  trigger: ReactNode;
  onToggle: (isOpen: boolean) => void;
  footerElement?: ReactNode;
  isOpen?: boolean;
}) {
  return (
    <Popover.Root open={isOpen} onOpenChange={onToggle}>
      {trigger}
      <Popover.Portal>
        <Popover.Content
          className={cx(
            "tw-border tw-bg-white tw-shadow-lg tw-p-lg tw-flex tw-flex-col tw-gap-md",
          )}
          align="end"
        >
          <div
            className={cx(
              "tw-grid tw-gap-lg",
              "md:tw-grid-cols-2",
              views.length <= 2 ? "tw-grid-cols-2" : "tw-grid-cols-3",
              "tw-overflow-y-auto",
            )}
          >
            {views.map((view) => (
              <Popover.Close key={view.key} asChild>
                <Link
                  to={view.url}
                  className={cx(
                    "tw-w-[300px] tw-h-[240px] tw-p-lg tw-rounded",
                    view.highlighted && "tw-bg-purple-100",
                    "hover:tw-bg-purple-200",
                    "tw-flex tw-flex-col tw-gap-sm",
                  )}
                >
                  <div className="tw-w-full tw-border-[16px] tw-rounded-lg tw-border-b-0 tw-border-gray-400 tw-mb-sm">
                    <div
                      className="tw-w-full tw-h-[80px] tw-bg-cover tw-bg-no-repeat"
                      style={{ backgroundImage: `url(${view.previewUrl})` }}
                    />
                  </div>
                  <div>{view.name}</div>
                  <div className="tw-text-left tw-text-sm tw-text-slate-500">
                    {view.description}
                  </div>
                </Link>
              </Popover.Close>
            ))}
          </div>
          {footerElement}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

// A Custom menu used in the top navigation bar (the "Analyze" option)
export function AnalyzeViewSelectorMenu({
  trigger,
  workspaceId,
  dataset,
  onToggle,
  views,
}: {
  trigger: ReactNode;
  workspaceId: WorkspaceId;
  dataset: DatasetId;
  onToggle: (isOpen: boolean) => void;
  views: AnalyzeView[];
}) {
  const [isOpen, setIsOpen] = useState(false);
  useFeatureSets(isOpen ? { dataset, workspace: workspaceId } : { skip: true });

  const handleToggle = useCallback(
    (newOpen: boolean) => {
      setIsOpen(newOpen);
      onToggle(newOpen);
    },
    [onToggle],
  );

  const [{ tab }] = useTypedQueryParams<{ tab: string }>({
    tab: QS.string(),
  });
  const internalFeatures = useAreInternalFeaturesEnabled();
  const filteredViews = views.filter(
    (view) =>
      !view.internalOnly || (internalFeatures && workspaceId !== "public-demo"),
  );

  const viewsToDisplay: StyledMenuProps[] = filteredViews.map((view) => ({
    ...view,
    url: measurementUrl(workspaceId, dataset, { tab: view.id }),
    key: view.id,
    highlighted: view.id === tab,
  }));
  return styledMenu({
    views: viewsToDisplay,
    trigger,
    onToggle: handleToggle,
    isOpen,
    footerElement: (
      <div className="tw-p-lg tw-text-slate-500">
        <div>Not sure where to start from?</div>
        <Popover.Close asChild>
          <Link
            to={measurementUrl(workspaceId, dataset, {
              tab: undefined,
            })}
            className="tw-text-purple tw-flex tw-flex-row tw-gap-xs tw-items-center"
          >
            Explore Features <ArrowRight size={12} />
          </Link>
        </Popover.Close>
      </div>
    ),
  });
}

export function CreateViewSelectorMenu({
  trigger,
  workspaceId,
  dataset,
  onToggle,
}: {
  trigger: ReactNode;
  workspaceId: WorkspaceId;
  dataset: DatasetId;
  onToggle: (isOpen: boolean) => void;
}) {
  const { pathname } = useLocation();
  const internalFeatures = useAreInternalFeaturesEnabled();
  const filteredViews = CREATE_VIEWS.filter(
    (view) =>
      !view.internalOnly || (internalFeatures && workspaceId !== "public-demo"),
  );
  const viewsToDisplay: StyledMenuProps[] = filteredViews.map((view) => ({
    ...view,
    url: `/workspace/${workspaceId}/e/${dataset}${view.path}`,
    key: view.appLocation.id,
    highlighted: pathname.startsWith(
      `/workspace/${workspaceId}/e/${dataset}${view.path}`,
    ),
  }));

  return styledMenu({
    views: viewsToDisplay,
    trigger,
    onToggle,
  });
}
