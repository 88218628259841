import React, { ComponentProps, useCallback, useRef } from "react";
import { Button } from "@spring/ui/Button";

export function Upload({
  onClick,
  accept,
  multiple,
  onUpload,
  ...props
}: ComponentProps<typeof Button> &
  Pick<React.InputHTMLAttributes<HTMLInputElement>, "accept" | "multiple"> & {
    onUpload: (files: FileList) => void;
  }) {
  const refInput = useRef<HTMLInputElement>(null);

  const handleClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      const input = refInput.current;
      if (input) {
        input.value = "";
        input.click();
      }

      onClick?.(e);
    },
    [onClick],
  );

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const files = e.currentTarget.files;
      if (files && files.length > 0) {
        onUpload(files);
      }
    },
    [onUpload],
  );

  return (
    <>
      <input
        ref={refInput}
        className="tw-hidden"
        type="file"
        accept={accept}
        multiple={multiple}
        onChange={handleChange}
      />
      <Button onClick={handleClick} {...props} />
    </>
  );
}

export function readFileAsText(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener("load", (e) => {
      if (e.target?.result && typeof e.target.result === "string") {
        resolve(e.target.result);
      }
    });

    reader.addEventListener("error", () => {
      reject(new Error("Failed to read file as text"));
    });

    reader.readAsText(file);
  });
}
