import * as Popover from "@radix-ui/react-popover";
import chroma from "chroma-js";
import cx from "classnames";
import { maxBy, minBy, sortBy } from "lodash";
import pluralize from "pluralize";
import { memo, useCallback, useMemo, useState } from "react";
import { ExternalLink, MoreHorizontal, X } from "react-feather";
import { useLocation } from "react-router-dom";
import { FixedSizeList as List, areEqual } from "react-window";
import { DeprecatedButton } from "src/Common/DeprecatedButton";
import { Operator } from "src/Control/FilterSelector/operations/filter-by";
import { SelectFilter } from "src/Control/FilterSelector/types";
import {
  createAnyOfFilter,
  createFilterSet,
} from "src/Control/FilterSelector/utils";
import { MetadataColors } from "src/FeatureSetManagementPage/types";
import { Group } from "src/ImageViewerNew/types";
import { UntypedWellSampleMetadataRow } from "src/types";
import invariant from "tiny-invariant";
import { Checkbox } from "@spring/ui/Checkbox";
import { Tooltip } from "@spring/ui/Tooltip";
import { getSampledFilterUrl, useGetWellIdForPoint } from "../utils";
import { LabMateClusterSummary } from "./LabMateClusterSummary";
import { renderMetadataValue } from "./util";

type Cluster = {
  clusterLabel: number;
  points: string[];
  metadataCountByValue: Record<string, number>;
  sortedMetadataValues: string[];
  clusterMetadata: UntypedWellSampleMetadataRow[];
};

export function LabMateClusters({
  clusters,
  clusterColors,
  hoveredCluster,
  metadataByKey,
  metadataColors,
  numWells,
  selectedMetadataColumn,
  onHoverCluster,
  onHoverValue,
  onSelectPoints,
}: {
  clusters: Array<[number, string[]]>;
  clusterColors: Map<number, string>;
  hoveredCluster: number | null;
  metadataByKey: Record<string, UntypedWellSampleMetadataRow>;
  metadataColors: MetadataColors;
  numWells: number;
  selectedMetadataColumn: string;
  onHoverCluster: (clusterLabel: number | null) => void;
  onSelectPoints: (points: Set<string>) => void;
  onHoverValue?: (key: string | null) => void;
}) {
  const clusterData: Array<Cluster> = useMemo(() => {
    return sortBy(clusters, ([clusterLabel]) => clusterLabel).map(
      ([clusterLabel, points]) => {
        const clusterMetadata = points.map((key) => metadataByKey[key]);

        const metadataCountByValue: { [value: string]: number } = {};
        for (const meta of clusterMetadata) {
          const value = renderMetadataValue(meta[selectedMetadataColumn]);
          metadataCountByValue[value] = metadataCountByValue[value] || 0;
          metadataCountByValue[value] += 1;
        }

        // Display metadata values with the highest count first
        const sortedMetadataValues = Object.keys(metadataCountByValue).sort(
          (valueA: string, valueB: string) =>
            metadataCountByValue[valueA] === metadataCountByValue[valueB]
              ? 0
              : metadataCountByValue[valueB] - metadataCountByValue[valueA],
        );

        return {
          clusterLabel,
          points,
          metadataCountByValue,
          sortedMetadataValues,
          clusterMetadata,
        };
      },
    );
  }, [clusters, metadataByKey, selectedMetadataColumn]);

  const clusterSummary = useMemo(() => {
    const firstCluster = clusterData[0];

    let clusterWithMinWells, clusterWithMaxWells;
    const getNumWells = ({ points }: Cluster) => points.length;
    // Only set a min/max if all values aren't the same
    if (
      clusterData.some(
        (cluster) => getNumWells(cluster) !== getNumWells(firstCluster),
      )
    ) {
      clusterWithMinWells = minBy(clusterData, getNumWells)?.clusterLabel;
      clusterWithMaxWells = maxBy(clusterData, getNumWells)?.clusterLabel;
    }

    let clusterWithMinValues, clusterWithMaxValues;
    const getNumValues = ({ metadataCountByValue }: Cluster) =>
      Object.keys(metadataCountByValue).length;
    // Only set a min/max if all values aren't the same
    if (
      clusterData.some(
        (cluster) => getNumValues(cluster) !== getNumValues(firstCluster),
      )
    ) {
      clusterWithMinValues = minBy(clusterData, getNumValues)?.clusterLabel;
      clusterWithMaxValues = maxBy(clusterData, getNumValues)?.clusterLabel;
    }

    return [
      { label: "Most wells", cluster: clusterWithMaxWells },
      { label: "Least wells", cluster: clusterWithMinWells },
      {
        label: `Most ${selectedMetadataColumn}s`,
        cluster: clusterWithMaxValues,
      },
      {
        label: `Least ${selectedMetadataColumn}s`,
        cluster: clusterWithMinValues,
      },
    ];
  }, [clusterData, selectedMetadataColumn]);

  const getWellId = useGetWellIdForPoint();

  const handleViewClusterImages = useCallback(() => {
    const clusterFilterGroups: Group<SelectFilter<string[]>>[] =
      clusterData.map(({ clusterLabel, clusterMetadata }) => {
        const groupWellIds: string[] = clusterMetadata.map(({ plate, well }) =>
          getWellId(plate, well),
        );

        const filters = [createAnyOfFilter("well_id", groupWellIds)];
        const filterSet = createFilterSet(filters, Operator.AND);
        const label = `Cluster ${clusterLabel + 1}`;

        return { id: label, label, filterSet };
      });

    const viewImagesUrl = getSampledFilterUrl(
      clusterFilterGroups,
      location.pathname,
    );

    invariant(viewImagesUrl);

    window.open(viewImagesUrl, "_blank");
  }, [clusterData, getWellId]);

  return (
    <>
      {clusterData.length > 0 ? (
        <>
          <LabMateClusterSummary
            clusterColors={clusterColors}
            numClusters={clusters.length}
            numWells={numWells}
            onHoverCluster={onHoverCluster}
            selectedMetadataColumn={selectedMetadataColumn}
            summaryItems={clusterSummary}
          />
          <DeprecatedButton
            borderless
            variant="primary"
            size="sm"
            onClick={handleViewClusterImages}
            className="tw-mr-sm"
          >
            <ExternalLink size={16} className="tw-mr-1" />
            View Images
          </DeprecatedButton>
        </>
      ) : null}

      <div className="tw-text-slate-500 tw-text-sm tw-mt-4 tw-grid tw-grid-cols-1 tw-gap-4">
        {clusterData.map(
          ({
            clusterLabel,
            points,
            metadataCountByValue,
            sortedMetadataValues,
            clusterMetadata,
          }) => {
            const clusterColor = clusterColors.get(clusterLabel) ?? "#666";
            return (
              <div
                key={clusterLabel}
                onMouseEnter={() => onHoverCluster(clusterLabel)}
                onMouseLeave={() => onHoverCluster(null)}
              >
                <div
                  className="tw-cursor-pointer tw-pb-1 tw-flex tw-flex-row tw-items-center tw-gap-1 tw-p-2"
                  style={{
                    background: chroma(clusterColor)
                      .alpha(hoveredCluster === clusterLabel ? 0.2 : 0.1)
                      .css(),
                  }}
                  onClick={() => onSelectPoints(new Set(points))}
                >
                  <span className="tw-font-bold tw-text-base">
                    Cluster {clusterLabel + 1}
                  </span>
                  <span>({points.length} wells)</span>
                </div>
                <PureClusterValueBars
                  key={clusterLabel}
                  clusterLabel={clusterLabel + 1}
                  clusterColor={clusterColor}
                  numPoints={points.length}
                  selectedMetadataColumn={selectedMetadataColumn}
                  clusterMetadata={clusterMetadata}
                  sortedMetadataValues={sortedMetadataValues}
                  metadataCountByValue={metadataCountByValue}
                  metadataColors={metadataColors}
                  onHoverValue={onHoverValue}
                />
              </div>
            );
          },
        )}
      </div>
    </>
  );
}

interface Bar {
  key: string;
  value: string;
  color: string;
  percentage: number;
  label: string | null;
  count: number;
}

const MAX_VISIBLE_BARS = 100;

function ClusterValueBars({
  clusterLabel,
  clusterColor,
  sortedMetadataValues,
  clusterMetadata,
  metadataCountByValue,
  selectedMetadataColumn,
  metadataColors,
  numPoints,
  onHoverValue,
}: {
  clusterLabel: number;
  clusterColor: string;
  sortedMetadataValues: string[];
  selectedMetadataColumn: string;
  clusterMetadata: UntypedWellSampleMetadataRow[];
  metadataCountByValue: { [value: string]: number };
  metadataColors: MetadataColors;
  numPoints: number;
  onHoverValue?: (key: string | null) => void;
}) {
  const clusterBackgroundColor = chroma(clusterColor).alpha(0.1).css();

  const bars = useMemo(() => {
    const bars: Bar[] = [];

    let barEndPercentage: number = 0;

    for (const metadataValue of sortedMetadataValues) {
      const percentage =
        (metadataCountByValue[metadataValue] / numPoints) * 100;
      barEndPercentage += percentage;

      const shouldShowLabel =
        // Hardcoding min bar width since calculating "did you truncate?" is tricky
        percentage > 15 &&
        // We don't want the label text to end too close to the edge, since we'll have
        // an overflow menu there – so if there's a bar that ends there, we want it to be
        // extra wide to make sure there's room for the text
        (barEndPercentage > 90 ? percentage >= 45 : true);

      bars.push({
        key: metadataValue,
        value: metadataValue,
        color: metadataColors[metadataValue],
        percentage,
        label: shouldShowLabel ? metadataValue : null,
        count: metadataCountByValue[metadataValue],
      });
    }
    return bars;
  }, [metadataColors, metadataCountByValue, numPoints, sortedMetadataValues]);

  const unlabeledBarCount = useMemo(
    () =>
      bars.reduce(
        (total, { label, count }) => total + (label === null ? count : 0),
        0,
      ),
    [bars],
  );

  const visibleBars = useMemo(() => {
    if (bars.length <= MAX_VISIBLE_BARS) {
      return bars;
    }

    const visible = bars.slice(0, MAX_VISIBLE_BARS);
    const hidden = bars.slice(MAX_VISIBLE_BARS);

    visible.push({
      key: "hidden values",
      value: pluralize("other value", hidden.length, true),
      color: "#ccc",
      percentage: hidden.reduce(
        (total, { percentage }) => total + percentage,
        0,
      ),
      label: null,
      count: hidden.reduce((total, { count }) => total + count, 0),
    });

    return visible;
  }, [bars]);

  return (
    <div>
      {/* Render the bars in their own line */}
      <div className="tw-flex">
        {visibleBars.map(({ value, color, percentage, count }) => {
          return (
            <Tooltip
              key={value}
              asChild={true}
              side="top"
              sideOffset={1}
              showArrow={true}
              className="tw-w-auto"
              contents={
                <div className="tw-leading-4">
                  <ValueLabelWithColor value={value} color={color} />
                  <div className="tw-text-sm">{percentage.toFixed(2)}%</div>
                </div>
              }
            >
              <div className="tw-min-w-0" style={{ flex: count }}>
                <div
                  className="tw-h-3"
                  style={{
                    backgroundColor: color,
                  }}
                />
              </div>
            </Tooltip>
          );
        })}
      </div>

      {/* Render the labels on a separate line – this ensures that we can properly align the overflow
          icon with the rest of the labels */}
      <div className="tw-flex">
        {visibleBars.map(({ value, label }) => {
          return label !== null ? (
            <div
              key={`label-${value}`}
              className="tw-min-w-0"
              style={{ flex: metadataCountByValue[value] }}
            >
              <div className="tw-mr-1 tw-overflow-hidden tw-select-none">
                <div className="tw-truncate">{label}</div>
              </div>
            </div>
          ) : null;
        })}

        <div
          className="tw-self-center tw-flex tw-justify-end"
          style={{ flex: unlabeledBarCount }}
        >
          <ClusterOverflowMenu
            label={clusterLabel}
            color={clusterBackgroundColor}
            numPoints={numPoints}
            selectedColumn={selectedMetadataColumn}
            clusterMetadata={clusterMetadata}
            values={bars}
            onHoverValue={onHoverValue}
          />
        </div>
      </div>
    </div>
  );
}
const PureClusterValueBars = memo(ClusterValueBars);

const Row = memo(
  ({
    index,
    style,
    data: { bars, selectedValuesSet, onHoverValue, handleCheckedChange },
  }: {
    index: number;
    style: React.CSSProperties;
    data: {
      bars: Bar[];
      selectedValuesSet: Set<string>;
      onHoverValue?: (value: string | null) => void;
      handleCheckedChange: (value: string, checked: boolean) => void;
    };
  }) => {
    const { value, percentage, color, key } = bars[index];
    const isChecked = selectedValuesSet.has(value);

    return (
      <div
        data-key={key}
        className={cx(
          "tw-flex tw-items-center tw-justify-between",
          "tw-border-b tw-py-2 tw-px-4",
          "tw-group tw-cursor-pointer",
        )}
        style={style}
        key={value}
        onMouseEnter={() => {
          onHoverValue?.(value);
        }}
        onMouseLeave={() => {
          onHoverValue?.(null);
        }}
        onClick={() => {
          handleCheckedChange(value, !isChecked);
        }}
      >
        <div
          className={cx(
            "tw-flex-1 tw-overflow-hidden",
            "tw-flex tw-items-center tw-gap-3",
          )}
        >
          <Checkbox
            checked={isChecked}
            onCheckedChange={(checked: boolean) =>
              handleCheckedChange(value, checked)
            }
          />
          <ValueLabelWithColor
            className="group-hover:tw-underline tw-flex-1"
            value={value}
            color={color}
            offset={1.5}
          />
        </div>
        <div className="tw-ml-2">{percentage.toFixed(2)}%</div>
      </div>
    );
  },
  areEqual,
);
Row.displayName = "Row";

const CLUSTER_OVERFLOW_ROW_HEIGHT = 36;
function ClusterOverflowMenu({
  color,
  label,
  numPoints,
  selectedColumn,
  clusterMetadata,
  values,
  onHoverValue,
}: {
  color: string;
  label: number;
  numPoints: number;
  selectedColumn: string;
  clusterMetadata: UntypedWellSampleMetadataRow[];
  values: Array<Bar>;
  onHoverValue?: (value: string | null) => void;
}) {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const selectedValuesSet: Set<string> = useMemo(() => {
    return new Set(selectedValues);
  }, [selectedValues]);

  const location = useLocation();

  const getWellId = useGetWellIdForPoint();

  const handleOpenChange = useCallback((open: boolean) => {
    // Reset selections when closing the menu
    if (!open) {
      setSelectedValues([]);
    }
  }, []);

  const handleCheckedChange = useCallback(
    (value, checked: boolean) => {
      if (checked) {
        setSelectedValues([...selectedValues, value]);
      } else {
        setSelectedValues(
          selectedValues.filter((selectedValue) => selectedValue !== value),
        );
      }
    },
    [selectedValues],
  );

  const clusterLabel = `Cluster ${label}`;

  const viewImagesUrl: string | null = useMemo(() => {
    if (selectedValuesSet.size === 0) {
      return null;
    }

    const orderedGroupValues = values
      .filter(({ value }) => selectedValuesSet.has(value))
      .map(({ value }) => value);

    const groups = orderedGroupValues.map((value) => {
      const groupWellIds = clusterMetadata
        .filter((meta) => `${meta[selectedColumn]}` === value)
        .map(({ plate, well }) => getWellId(plate, well));

      // TODO(trisorus): Should this also include the global filters in each image group?
      // The Comparisons view currently does not.
      const filters = [createAnyOfFilter("well_id", Array.from(groupWellIds))];
      const filterSet = createFilterSet(filters, Operator.AND);

      return { id: value, label: `${clusterLabel} - ${value}`, filterSet };
    });

    return getSampledFilterUrl(groups, location.pathname);
  }, [
    selectedValuesSet,
    values,
    location.pathname,
    clusterMetadata,
    clusterLabel,
    selectedColumn,
    getWellId,
  ]);

  return (
    <Popover.Root onOpenChange={handleOpenChange}>
      <Popover.Trigger>
        <MoreHorizontal size={16} />
      </Popover.Trigger>
      <Popover.Anchor />
      <Popover.Portal>
        <Popover.Content side="right" sideOffset={5}>
          <div
            className={cx(
              "tw-max-h-96 tw-flex tw-flex-col tw-overflow-hidden",
              "tw-rounded-md",
              "tw-bg-white tw-shadow-lg tw-border",
              "tw-font-sans tw-text-slate-500 tw-text-sm",
            )}
          >
            <div
              className={cx(
                "tw-flex tw-items-center tw-justify-between",
                "tw-py-2 tw-px-4",
              )}
              style={{ backgroundColor: color }}
            >
              <div className="tw-flex tw-items-center">
                <div className="tw-flex tw-flex-col tw-items-start">
                  <div>
                    <span className="tw-font-bold tw-text-base tw-leading-tight tw-mr-1">
                      {clusterLabel}
                    </span>{" "}
                    ({numPoints} wells)
                  </div>
                  <a
                    className={cx(
                      "tw-leading-tight tw-font-medium tw-no-underline tw-mt-xs",
                      viewImagesUrl === null
                        ? "tw-cursor-not-allowed tw-text-slate-400 hover:tw-text-slate-400"
                        : "tw-cursor-pointer tw-text-primary-500",
                    )}
                    target="_blank"
                    rel="noreferrer"
                    href={viewImagesUrl ?? undefined}
                  >
                    {
                      // Ensure we could create the url.
                      selectedValuesSet.size > 0 && viewImagesUrl === null ? (
                        "Reduce group selection to view images"
                      ) : (
                        <div className="tw-flex">
                          <ExternalLink size={16} className="tw-mr-xs" />
                          <span>View images</span>
                        </div>
                      )
                    }
                  </a>
                </div>
              </div>
              <Popover.Close className="tw-ml-2 tw-opacity-70">
                <X size={16} />
              </Popover.Close>
            </div>
            <List
              itemData={useMemo(
                () => ({
                  bars: values,
                  selectedValuesSet,
                  onHoverValue,
                  handleCheckedChange,
                }),
                [onHoverValue, selectedValuesSet, values, handleCheckedChange],
              )}
              itemCount={values.length}
              itemSize={CLUSTER_OVERFLOW_ROW_HEIGHT}
              height={Math.min(
                400,
                CLUSTER_OVERFLOW_ROW_HEIGHT * values.length,
              )}
              width={300}
              itemKey={(index, data) => data.bars[index].key}
            >
              {Row}
            </List>
          </div>

          <Popover.Arrow className="tw-stroke-2 tw-stroke-gray-200 tw-fill-gray-200" />
          <Popover.Arrow className="tw-fill-white -tw-translate-y-px" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

function ValueLabelWithColor({
  className,
  value,
  color,
  offset,
}: {
  className?: string;
  value: string | number;
  color: string;
  offset?: number;
}) {
  return (
    <div
      className={cx(
        "tw-font-bold tw-flex tw-items-center tw-overflow-hidden",
        className,
      )}
    >
      <div
        className={cx("tw-w-3 tw-h-3 tw-rounded-sm tw-mr-1 tw-shrink-0")}
        style={{
          backgroundColor: color,
          marginRight: offset,
        }}
      />
      <div className="tw-truncate" title={String(value)}>
        {value}
      </div>
    </div>
  );
}
