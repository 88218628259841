import { useCallback } from "react";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";
import { DatasetId } from "src/types";
import {
  ConfirmDelete,
  ConfirmDeleteSettings,
  Duplicate,
  DuplicateSettings,
  EditValue,
  MainMenu,
  OverflowMenu,
  RenameSettings,
} from "../Common/PopoverMenu";
import {
  LabeledSet,
  NewLabeledSet,
  useLabeledSetContext,
  useLabeledSetsContext,
} from "./Context";
import { deleteLabeledSet, saveLabeledSet } from "./util";

function RenameLabeledSet({ dataset }: { dataset: DatasetId }) {
  const { state: labeledSet, updateState } = useLabeledSetContext();
  const { state: labeledSetsState } = useLabeledSetsContext();

  return (
    <EditValue
      initialValue={labeledSet.displayName}
      onSubmit={useCallback(
        async ({
          newValue: newName,
          workspace,
          accessToken,
        }: RenameSettings) => {
          const newLabeledSet: LabeledSet = {
            ...labeledSet,
            displayName: newName.trim(),
            unsavedChanges: false,
          };

          updateState(() => newLabeledSet);

          await saveLabeledSet({
            accessToken,
            workspace,
            dataset,
            labeledSet: newLabeledSet,
          });
        },
        [dataset, labeledSet, updateState],
      )}
      validate={useCallback(
        (name) => {
          return labeledSetsState.labeledSets.some(
            (ls) =>
              ls.id !== labeledSet.id &&
              !ls.deleted &&
              ls.displayName === name.trim(),
          )
            ? {
                valid: false,
                message: "There is another labeled set with that name",
              }
            : { valid: true };
        },
        [labeledSet.id, labeledSetsState.labeledSets],
      )}
    />
  );
}

function DuplicateLabeledSet({ dataset }: { dataset: DatasetId }) {
  const { state: labeledSet } = useLabeledSetContext();
  const { state: labeledSetsState, updateState } = useLabeledSetsContext();

  const { path: basePath, params } = useRouteMatch<{
    id: string;
    dataset: DatasetId;
  }>();
  const history = useHistory();

  return (
    <Duplicate
      initialName={labeledSet.displayName}
      onSubmit={useCallback(
        async ({ newName, workspace, accessToken }: DuplicateSettings) => {
          const { id, ...labeledSetToDuplicate } = labeledSet;
          const newLabeledSet: NewLabeledSet = {
            ...labeledSetToDuplicate,
            displayName: newName.trim(),
            unsavedChanges: false,
          };

          const result = await saveLabeledSet({
            accessToken,
            workspace,
            dataset,
            labeledSet: newLabeledSet,
          });

          updateState((state) => ({
            ...state,
            labeledSets: [
              // TODO(you): Fix this no-unnecessary-condition rule violation
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              ...(state.labeledSets ?? []),
              { ...newLabeledSet, id: result.id, lastSaved: new Date() },
            ],
          }));

          history.push(generatePath(`${basePath}/${result.id}`, params));
        },

        [dataset, labeledSet, updateState, history, basePath, params],
      )}
      validate={useCallback(
        (name) => {
          return labeledSetsState.labeledSets.some(
            (ls) => !ls.deleted && ls.displayName === name.trim(),
          )
            ? {
                valid: false,
                message: "There is another labeled set with that name",
              }
            : { valid: true };
        },
        [labeledSetsState.labeledSets],
      )}
    />
  );
}

function ConfirmDeleteLabeledSet({
  dataset,
  onDeleteStart,
  onDeleteFinish,
}: {
  dataset: DatasetId;
  onDeleteStart?: () => void;
  onDeleteFinish?: () => void;
}) {
  const {
    state: { id },
    updateState,
  } = useLabeledSetContext();

  return (
    <ConfirmDelete
      onDeleteStart={onDeleteStart}
      onDeleteFinish={onDeleteFinish}
      onDelete={useCallback(
        async ({ accessToken, workspace }: ConfirmDeleteSettings) => {
          updateState((state) => ({
            ...state,
            deleted: true,
          }));

          await deleteLabeledSet({
            accessToken,
            workspace,
            dataset,
            labeledSetId: id,
          });
        },
        [dataset, id, updateState],
      )}
    />
  );
}

interface Props {
  align?: "start" | "center" | "end";
  className?: string;
  dataset: DatasetId;
  sideOffset?: number;
  triggerClassName?: string;
  onDeleteStart?: () => void;
  onDeleteFinish?: () => void;
}

export function LabeledSetMenu({
  align,
  className,
  dataset,
  sideOffset,
  triggerClassName,
  onDeleteStart,
  onDeleteFinish,
}: Props) {
  return (
    <OverflowMenu
      align={align}
      className={className}
      sideOffset={sideOffset}
      triggerClassName={triggerClassName}
    >
      <MainMenu
        className="tw-min-w-[300px]"
        items={{
          "Rename…": <RenameLabeledSet dataset={dataset} />,
          "Duplicate…": <DuplicateLabeledSet dataset={dataset} />,
          "Delete…": (
            <ConfirmDeleteLabeledSet
              dataset={dataset}
              onDeleteStart={onDeleteStart}
              onDeleteFinish={onDeleteFinish}
            />
          ),
        }}
      />
    </OverflowMenu>
  );
}
