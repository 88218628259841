import * as Sentry from "@sentry/react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { AppLocationId } from "src/TrackAppLocation/types";
import { makeAdminApi } from "src/hooks/api";
import { WorkspaceId } from "src/types";
import protectRoute from "../Auth0/protectRoute";
import { FullScreenContainer } from "../Common/FullScreenContainer";
import Loader from "../Common/Loader";
import { useTrackPageview } from "../hooks/analytics";

const useJoinPublicWorkspace = makeAdminApi("workspace/join/<workspace>")(
  undefined,
  () => ({
    configuration: {
      revalidateOnReconnect: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
    fetchKind: "blob",
    requestInit: { method: "POST" },
  }),
);

function JoinPublicWorkspace() {
  const { id } = useParams<{ id: WorkspaceId }>();

  const { search } = useLocation();
  const request = useJoinPublicWorkspace({ workspace: id });

  useTrackPageview({ id: AppLocationId.JoinPublicWorkspace, workspace: id });

  if (!request) {
    return (
      <FullScreenContainer center>
        <Loader />
      </FullScreenContainer>
    );
  } else if (request.successful) {
    // Force a hard refresh, instead of using <Redirect> because the list of workspaces
    // is expected to be an infrequently changing list and cached aggressively, so
    // instead of mucking that up and complicating it, just make this redirect a bit
    // slower.
    const params = new URLSearchParams(search);
    const cont = params.get("cont");
    // Support relative continue params.
    if (cont && cont.startsWith("/")) {
      window.location.href = `//${window.location.host}${cont}`;
    } else {
      window.location.href = `//${window.location.host}/workspace/${id}`;
    }
    return (
      <FullScreenContainer center>
        <Loader />
      </FullScreenContainer>
    );
  } else {
    // Failed join for unknown reasons?
    console.error(request.error);
    Sentry.captureException(request.error);
    return <Redirect to={"/"} />;
  }
}
export default protectRoute(JoinPublicWorkspace);
