import type { AccessToken } from "src/Auth0/accessToken";
import { DatasetId, WorkspaceId } from "src/types";
import { adminApi } from "src/util/api-client";
import { UserDatasetPrefs, UserPrefs, UserWorkspacePrefs } from "./types";

export function fetchUserPrefs(accessToken: AccessToken): Promise<UserPrefs> {
  return adminApi({ accessToken }).route("prefs").get().json<UserPrefs>();
}

export async function updateUserPrefs(
  accessToken: AccessToken,
  prefs: UserPrefs,
): Promise<void> {
  await adminApi({ accessToken }).route("prefs/update").post(prefs);
}

export function fetchUserWorkspacePrefs(
  accessToken: AccessToken,
  workspace: WorkspaceId,
): Promise<UserWorkspacePrefs> {
  return adminApi({ accessToken })
    .route("workspace/<workspace>/prefs", { workspace })
    .get()
    .json<UserWorkspacePrefs>();
}

export async function updateUserWorkspacePrefs(
  accessToken: AccessToken,
  workspace: WorkspaceId,
  prefs: UserWorkspacePrefs,
): Promise<void> {
  await adminApi({ accessToken })
    .route("workspace/<workspace>/prefs/update", { workspace })
    .post(prefs);
}

export function fetchUserDatasetPrefs(
  accessToken: AccessToken,
  workspace: WorkspaceId,
  dataset: DatasetId,
): Promise<UserDatasetPrefs> {
  return adminApi({ accessToken })
    .route("workspace/<workspace>/dataset/<dataset>/prefs", {
      workspace,
      dataset,
    })
    .get()
    .json<UserDatasetPrefs>();
}

export async function updateUserDatasetPrefs(
  accessToken: AccessToken,
  workspace: WorkspaceId,
  dataset: DatasetId,
  prefs: UserDatasetPrefs,
): Promise<void> {
  await adminApi({ accessToken })
    .route("workspace/<workspace>/dataset/<dataset>/prefs/update", {
      workspace,
      dataset,
    })
    .post(prefs);
}
