import { useHistory, useLocation } from "react-router-dom";
import {
  ConfirmDelete,
  Duplicate,
  EditValue,
  MainMenu,
  OverflowMenu,
} from "../Common/PopoverMenu";
import { useSupervisedLearningContext } from "./context";
import { SavedModelListing } from "./types";

/**
 * A hamburger menu which allows the user to rename, duplicate, or delete a model.
 */
export default function ItemMenu({ model }: { model: SavedModelListing }) {
  return (
    <OverflowMenu
      align={"end"}
      triggerClassName={
        "tw-absolute tw-inset-0 tw-flex tw-items-center tw-text-slate-500"
      }
    >
      <MainMenu
        items={{
          "Rename…": <RenameModel model={model} />,
          "Duplicate…": <DuplicateModel model={model} />,
          "Delete…": <ConfirmDeleteModel model={model} />,
        }}
      />
    </OverflowMenu>
  );
}

function RenameModel({ model }: { model: SavedModelListing }) {
  const { updateModel } = useSupervisedLearningContext();
  return (
    <EditValue
      initialValue={model.name}
      onSubmit={async ({ newValue: newName }) => {
        updateModel({
          ...model,
          name: newName,
        });
      }}
      validate={(name) => {
        // There aren't any restrictions on model name, really.
        return { valid: name !== model.name };
      }}
    />
  );
}

function DuplicateModel({ model }: { model: SavedModelListing }) {
  const history = useHistory();
  const { pathname } = useLocation();
  return (
    <Duplicate
      initialName={model.name}
      onSubmit={async ({ newName }) => {
        history.push(
          `${pathname}/edit?from=${model.id}&name=${encodeURIComponent(
            newName,
          )}`,
        );
      }}
      validate={(name) => {
        // There aren't any restrictions on model name, really.
        return { valid: name !== model.name };
      }}
    />
  );
}

function ConfirmDeleteModel({ model }: { model: SavedModelListing }) {
  const { deleteModel } = useSupervisedLearningContext();
  return (
    <ConfirmDelete
      onDelete={async () => {
        return deleteModel(model);
      }}
    />
  );
}
