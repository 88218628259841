import { useMemo } from "react";
import { ALL_CHANNEL_INDEXES } from "./constants";
import {
  ChannelMap,
  DatasetPlateWellFieldTZ,
  DatasetPlateWellFieldTZC,
  ImageSet,
} from "./types";

export interface DownloadHelpersSettings {
  canvasRef: React.MutableRefObject<HTMLCanvasElement | null>;
  filename?: string;
  imageSet: ImageSet | null;
  index: DatasetPlateWellFieldTZ;
  onDownloadChannel?: (
    imageSet: ImageSet,
    index: DatasetPlateWellFieldTZC,
  ) => void;
  channelMap: ChannelMap;
}

export class DownloadHelpers {
  private settings: DownloadHelpersSettings;

  private closed: boolean = false;

  constructor(settings: DownloadHelpersSettings) {
    this.settings = settings;
  }

  async getCanvasBlob(): Promise<Blob> {
    const canvas = this.settings.canvasRef.current;
    if (!canvas) {
      throw Error("Cannot download without Canvas reference.");
    }
    const dataURL = canvas.toDataURL();

    // Convert the Base64-encoded data to an object URL. As-is, the data URL is
    // likely too long to be usable for direct download.
    const response = await fetch(dataURL);
    return response.blob();
  }

  async getCanvasObjectUrl(): Promise<string> {
    return URL.createObjectURL(await this.getCanvasBlob());
  }

  /**
   * Handler to download the currently-rendered Canvas as a PNG.
   */
  async handleDownloadCanvas() {
    const url = await this.getCanvasObjectUrl();

    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!this.closed && document.body) {
      const { body } = document;
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = this.settings.filename || "composite.png";
      body.appendChild(a);
      a.click();
      body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  }
  /**
   * Handler to download raw TIFFs (i.e., the source images used to render the
   * currently-rendered Canvas).
   */
  handleDownloadRawImages() {
    const onDownloadChannel = this.settings.onDownloadChannel;
    if (!onDownloadChannel) {
      return;
    }

    ALL_CHANNEL_INDEXES.forEach((i) => {
      const mappedIndex = this.settings.channelMap[i];
      if (mappedIndex != null && this.settings.imageSet != null) {
        onDownloadChannel(this.settings.imageSet, {
          ...this.settings.index,
          c: mappedIndex,
        });
      }
    });
  }

  async handleCopyImage() {
    const blob = await this.getCanvasBlob();

    if (!this.closed) {
      navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob,
        }),
      ]);
    }
  }

  close() {
    this.closed = true;
  }
}

export function useDownloadHelpers(settings: DownloadHelpersSettings) {
  return useMemo(() => new DownloadHelpers(settings), [settings]);
}
