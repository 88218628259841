import { CellSampleMetadata } from "src/types";

export type BranchDataNode = {
  type: "branch";
  name: string;
  displayName?: string;
  value?: number;
  children: DataNode[];
  level: number;
  isNextCombine: boolean;
};

export type LeafDataNode = {
  type: "leaf";
  name: string;
  displayName?: string;
  value?: number;
  level: number;
  isNextSplit: boolean;
  isManuallyConstructed: boolean;
  cells: CellSampleMetadata[];
};

export type DataNode = BranchDataNode | LeafDataNode;

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type Cluster = LeafDataNode;
export type Clusters = Array<Cluster & { x: number; y: number }>;

export type ClusterMetadata = BaseClusterMetadata & ClusterMetadataStats;
export type ClusterFeatureData = BaseClusterFeatureData &
  ClusterFeatureDataStats;
export type ClusterVisualizationData = ClusterFeatureData | ClusterMetadata;
export type BaseClusterMetadata = {
  clusterName: string;
  value: string;
  count: number;
  numEntries: number;
};
export type BaseClusterFeatureData = {
  clusterName: string;
  values: number[];
};
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type ClusterMetadataStats = {
  ratio: number;
  uncorrectedPValue: number;
  oddsRatio: number;
};

export type ClusterFeatureDataStats = {
  uncorrectedPValue: number;
  q1: number;
  q3: number;
  median: number;
  mean: number;
  min: number;
  max: number;
};

export type VisualizationColumn = {
  name: string;
  type: "metadata" | "feature";
};

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type TreeModificationType = "split" | "combine" | "changeDisplayName";
export type TreeModificationStep =
  | {
      type: "split" | "combine";
      nodeName: string;
    }
  | {
      type: "changeDisplayName";
      nodeName: string;
      displayName?: string;
    };

export enum InferenceStatus {
  "NULL",
  "SENDING",
  "SUCCESS",
  "FAILURE",
}

export enum ClusteringStatus {
  "NULL",
  "SENDING",
  "SUCCESS",
  "FAILURE",
}

export type ChartMargin = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

export type ChartDimensionsConfig = {
  width: number;
  height: number;
  margin?: Partial<ChartMargin>;
};

export type ChartDimensions = {
  width: number;
  height: number;
  boundedHeight: number;
  boundedWidth: number;
  margin: ChartMargin;
};
