import { ReactNode } from "react";

export default function SelectorLabel({
  children,
  extraClasses,
}: {
  children: ReactNode;
  extraClasses?: string;
}) {
  return (
    <label
      className={`tw-inline-block tw-font-mono tw-uppercase tw-text-sm tw-text-slate-500 ${extraClasses}`}
    >
      {children}
    </label>
  );
}
