import useEventListener from "@use-it/event-listener";
import cx from "classnames";
import {
  CSSProperties,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { useMaybeActiveGuider } from "./hooks";

type Corner = "top-left" | "top-right" | "bottom-left" | "bottom-right";
type Offset = { x: number; y: number };
type Side = "top" | "bottom" | "left" | "right";

type AnchorPosition = {
  corner: Corner;
  offset?: Offset;
};

/**
 * A wrapper around an element that can be called out by a "guider" in an insight flow.
 *
 * When the insights/onboarding flow reaches a specific step that contains a guider
 * associated with the matched key, this wrapper will handle the rendering of the
 * guider.
 */
export function PulseGuiderRoot({
  guiderKey,
  className,
  children,
  position,
  tooltipSide,
}: {
  guiderKey: string;
  className?: string;
  children: ReactNode;
  position: AnchorPosition;
  tooltipSide: Side;
}) {
  const [guider, onDismiss] = useMaybeActiveGuider(guiderKey) ?? [
    undefined,
    undefined,
  ];
  const [state, setState] = useState<"pulse" | "open">("pulse");

  const ref = useRef<HTMLDivElement>(null);
  const handleMouseOverPulse = useCallback(() => setState("open"), []);
  const handleMouseLeavePulse = useCallback(() => setState("pulse"), []);

  return (
    <div
      ref={ref}
      className={cx("tw-relative", className)}
      onClickCapture={() => onDismiss?.()}
    >
      {children}
      {guider && (
        <PulsingDot
          onMouseEnter={handleMouseOverPulse}
          onMouseLeave={handleMouseLeavePulse}
          position={position}
        />
      )}
      {state === "open" &&
        guider &&
        ref.current &&
        createPortal(
          <HackyTooltip
            anchorEl={ref.current}
            position={position}
            side={tooltipSide}
          >
            {guider.content}
          </HackyTooltip>,
          document.body,
        )}
    </div>
  );
}

// TODO(benkomalo): I tried for a long time trying to get Radix Popover to work but
// constantly ran into problems getting it rendering correctly. It's possible there's
// a bad interaction with rendering it with the anchor being the absolutely positioned
// PulseDot, or something else I don't understand. Regardless: re-implementing a
// tooltip from scratch is a bad idea because there are a gazillion things I'm sure
// this doesn't do correctly, like detecting if it's about to overflow the viewport,
// handling focus, handling a11y properly, etc etc. If this insights flow sticks around
// we should probably try to get Radix to work.
/**
 * A standard tooltip that's got a fixed position.
 */
function HackyTooltip({
  anchorEl,
  children,
  position,
  side,
}: {
  anchorEl: HTMLElement;
  children: ReactNode;
  position: AnchorPosition;
  side: Side;
}) {
  const style: CSSProperties = useMemo(() => {
    const { top, left, right, bottom } = anchorEl.getBoundingClientRect();
    let { x: offsetX, y: offsetY } = position.offset ?? { x: 0, y: 0 };
    // TODO(benkomalo): not actually rendering an arrow right now but allocating
    // space for it.
    const arrowSize = 16;
    switch (side) {
      case "top":
        offsetY -= arrowSize;
        break;
      case "right":
        offsetX += arrowSize;
        break;
      case "bottom":
        offsetY += arrowSize;
        break;
      case "left":
        offsetX -= arrowSize;
        break;
    }
    switch (position.corner) {
      case "top-left":
        return {
          top: top + offsetY,
          left: left + offsetX,
        };

      case "top-right":
        return {
          top: top + offsetY,
          left: right + offsetX,
        };

      case "bottom-left":
        return {
          left: left + offsetX,
          top: bottom + offsetY,
        };

      case "bottom-right":
        return {
          left: right + offsetX,
          top: bottom + offsetY,
        };
    }
  }, [anchorEl, position, side]);

  return (
    <div
      className={cx(
        "tw-fixed tw-z-popup",
        "tw-w-[300px] tw-bg-purple tw-text-sm tw-text-white tw-p-md tw-rounded",
        ["left", "right"].includes(side) && "tw-translate-y-[-50%]",
        ["top", "bottom"].includes(side) && "tw-translate-x-[-50%]",
        side === "top" && "tw-translate-y-[-100%]",
        side === "left" && "tw-translate-x-[-100%]",
      )}
      style={style}
    >
      {children}
    </div>
  );
}

function PulsingDot({
  position,
  onMouseEnter,
  onMouseLeave,
}: {
  position: AnchorPosition;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const isOverElement = useRef(false);
  useEventListener(
    "mousemove",
    (e: MouseEvent) => {
      if (!ref.current) {
        return;
      }
      const x = e.clientX;
      const y = e.clientY;
      const { top, left, right, bottom } = ref.current.getBoundingClientRect();
      if (x >= left && x <= right && y >= top && y <= bottom) {
        onMouseEnter();
        isOverElement.current = true;
      } else {
        if (isOverElement.current) {
          onMouseLeave();
        }
        isOverElement.current = false;
      }
    },
    document.body,
  );
  const style: CSSProperties = useMemo(() => {
    const { x: offsetX, y: offsetY } = position.offset ?? { x: 0, y: 0 };
    const halfSize = 16;
    switch (position.corner) {
      case "top-left":
        return {
          top: offsetY - halfSize,
          left: offsetX - halfSize,
        };

      case "top-right":
        return {
          top: offsetY - halfSize,
          right: -offsetX - halfSize,
        };

      case "bottom-left":
        return {
          left: offsetX - halfSize,
          bottom: -offsetY - halfSize,
        };

      case "bottom-right":
        return {
          right: -offsetX - halfSize,
          bottom: -offsetY - halfSize,
        };
    }
  }, [position]);
  return (
    <div
      ref={ref}
      className={cx(
        "tw-absolute tw-w-[32px] tw-h-[32px] tw-cursor-pointer tw-z-popup",
      )}
      style={style}
    >
      <div className={"tw-relative"}>
        <div
          className={
            "tw-w-[16px] tw-h-[16px] tw-absolute tw-left-[8px] tw-top-[8px] tw-rounded-full tw-bg-purple tw-border-[2px] tw-border-purple-300"
          }
        />
        <div
          className={
            "tw-animate-dot-pulse tw-w-[32px] tw-h-[32px] tw-rounded-full tw-bg-purple tw-absolute"
          }
        />
      </div>
    </div>
  );
}
