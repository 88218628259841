import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { metadataToKey } from "src/imaging/util";
import { groupBy } from "@spring/core/utils";
import { FilterSqlClause } from "../../../Control/FilterSelector/types";
import { useFeatureFlag } from "../../../Workspace/feature-flags";
import {
  useFeatureSets,
  useSelectedPointEnrichment,
} from "../../../hooks/features";
import { isIndexColumn } from "../../../immunofluorescence/metadata";
import { DatasetId, UntypedWellSampleMetadataRow } from "../../../types";
import { MetadataColors, UmapRow } from "../../types";
import { MetadataSelection } from "../types";
import { LabMateClusters } from "./LabMateClusters";
import { LabMateSelectionSwitcher } from "./LabMateDetails";
import { LabMateLogo } from "./LabMateLogo";
import { LabMatePopup } from "./LabMatePopup";
import { LabMateSimilarity } from "./LabMateSimilarity";
import { PHENOFINDER_FEATURE_SET_NAME } from "./constants";
import {
  GroupSignificanceRecordsType,
  KSTestEnrichmentRecord,
  LabMateView,
  RepresentationType,
  SignificanceRecord,
} from "./types";
import {
  calculateSignificanceRecords,
  removeDuplicateFields,
  sortBySignificance,
} from "./util";

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type SelectedViewType =
  | "placeholder"
  | "clusters"
  | "summary"
  | "similarity"
  | "representation"
  | "over"
  | "under"
  | "phenofinder";

export function LabMate({
  dataset,
  filterSerialized,
  selectedPoints,
  metadata,
  umapData,
  scatterPoints,
  clusterColors,
  plates,
  features,
  coloringMetadataColumn,
  metadataColors,
  metadataSelections,
  hoveredKey,
  hoveredCluster,
  onSetHoverRef,
  onSelectPoints,
  onHoverKey,
  onToggleKey,
  onHoverCluster,
  children,
}: {
  dataset: DatasetId;
  filterSerialized?: FilterSqlClause;
  selectedPoints: Set<string>;
  metadata: UntypedWellSampleMetadataRow[];
  umapData?: UmapRow[];
  scatterPoints: { key: string; clusterLabel?: number }[] | null;
  clusterColors: Map<number, string>;
  plates: string[];
  features: string[];
  coloringMetadataColumn: string | null;
  metadataColors: MetadataColors;
  metadataSelections: MetadataSelection[];
  hoveredKey: string | null;
  hoveredCluster: number | null;
  onSetHoverRef: (elem: HTMLElement | null) => void;
  onSelectPoints: (keys: Set<string>) => void;
  onHoverKey?: (key: string | null) => void;
  onToggleKey?: (key: string) => void;
  onHoverCluster: (label: number | null) => void;
  children: (children: ReactElement) => ReactElement | null;
}) {
  const isSimilarityEnabled = useFeatureFlag("labmate-similarity-enabled");

  const [view, setView] = useState<LabMateView>("summary");
  const [popupRecords, setPopupRecords] = useState<SignificanceRecord[] | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeView = useCallback((view: LabMateView) => {
    setView(view);
  }, []);

  const featureSetListPromise = useFeatureSets({ dataset });
  const hasPhenoFinderFeatures = useMemo(() => {
    return featureSetListPromise?.successful
      ? featureSetListPromise.value[0].featureSets.some((featureSet) =>
          featureSet.includes(encodeURIComponent(PHENOFINDER_FEATURE_SET_NAME)),
        )
      : null;
  }, [featureSetListPromise]);

  const handleShowPopup = useCallback(
    (elem: HTMLElement, records: SignificanceRecord[]) => {
      onSetHoverRef(elem);
      setPopupRecords(records);
    },
    [onSetHoverRef],
  );
  const enrichmentPromise = useSelectedPointEnrichment({
    dataset,
    sqlFilter: filterSerialized ?? "TRUE",
    feature: `WellAggregated${encodeURIComponent(
      PHENOFINDER_FEATURE_SET_NAME,
    )}`,
    selectedPoints,
  });
  const enrichmentValues: KSTestEnrichmentRecord[] | null =
    enrichmentPromise?.successful ? enrichmentPromise.value : null;
  const handleClosePopup = useCallback(() => {
    onSetHoverRef(null);
    setPopupRecords(null);
  }, [onSetHoverRef]);

  const deduplicatedMetadata = useMemo(() => {
    return removeDuplicateFields(metadata);
  }, [metadata]);

  const allShownMetadata = useMemo(() => {
    return deduplicatedMetadata.filter((row) => plates.includes(row.plate));
  }, [deduplicatedMetadata, plates]);

  const selectedMetadata = useMemo(() => {
    return deduplicatedMetadata.filter((row: UntypedWellSampleMetadataRow) =>
      selectedPoints.has(metadataToKey(row)),
    );
  }, [deduplicatedMetadata, selectedPoints]);

  const metadataColumns = useMemo(() => {
    return Object.keys(allShownMetadata[0] || {}).filter(
      (metadataColumn) => !isIndexColumn(metadataColumn),
    );
  }, [allShownMetadata]);

  const metadataByKey = useMemo(() => {
    const metadataByKey: { [key: string]: UntypedWellSampleMetadataRow } = {};
    for (const row of allShownMetadata) {
      metadataByKey[metadataToKey(row)] = row;
    }
    return metadataByKey;
  }, [allShownMetadata]);

  const uniqueValuesByColumn = useMemo(() => {
    return Object.fromEntries(
      metadataColumns.map((column) => [
        column,
        new Set(allShownMetadata.map((o) => o[column])),
      ]),
    );
  }, [metadataColumns, allShownMetadata]);

  const significanceRecords: SignificanceRecord[] = useMemo(() => {
    return calculateSignificanceRecords({
      metadataColumns,
      uniqueValuesByColumn,
      selectedMetadata,
      allShownMetadata,
    });
  }, [
    metadataColumns,
    uniqueValuesByColumn,
    selectedMetadata,
    allShownMetadata,
  ]);

  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const significanceRecordsByColumn = significanceRecords
    ? groupBy(significanceRecords, ({ metadataColumn }) => metadataColumn)
    : {};

  const repTypes: RepresentationType[] = ["over", "under"];
  const groupedSignificanceRecords = repTypes.reduce((acc, moreOrLessKey) => {
    const matchingRecords = significanceRecords
      .filter(
        ({ representationType, metadataColumn }) =>
          representationType === moreOrLessKey &&
          (moreOrLessKey === "over" ||
            // Don't include exclusively reciprocal underrepresented values
            // (e.g. metadata X is composed of A,B, & C values, if A is overrepresented and B/C are both underrepresented, drop B/C.  / under Sample when Control and Sample are the only two types)
            significanceRecordsByColumn[metadataColumn].length <
              uniqueValuesByColumn[metadataColumn].size),
      )
      .sort(sortBySignificance);

    return matchingRecords.length > 0
      ? {
          ...acc,
          [moreOrLessKey]: matchingRecords,
        }
      : acc;
  }, {} as GroupSignificanceRecordsType);

  useEffect(() => {
    setView("summary");
  }, [selectedPoints]);

  const selectedValues = useMemo(() => {
    return metadataSelections
      .filter(
        (selection) =>
          // TODO(you): Fix this no-unnecessary-condition rule violation
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          selection.kind === "metadata" &&
          selection.key === coloringMetadataColumn,
      )
      .map((value) => value.value);
  }, [coloringMetadataColumn, metadataSelections]);

  const showClusters = useFeatureFlag("labmate-cluster-enabled");

  const clusterMap: Map<number, string[]> = useMemo(() => {
    const map = new Map();
    if (scatterPoints === null) {
      return map;
    }

    for (const point of scatterPoints) {
      const { key, clusterLabel } = point;

      if (clusterLabel != null) {
        let clusterPoints = map.get(clusterLabel);
        if (!clusterPoints) {
          clusterPoints = [];
          map.set(clusterLabel, clusterPoints);
        }

        clusterPoints.push(key);
      }
    }

    return map;
  }, [scatterPoints]);

  const totalPointsSelected = selectedPoints.size;

  // TODO(michaelwiest): Probably want to add the similarity view to this selector
  // as well. It is a bit weird that they aren't consolidated.
  const getSelectionSwitcher = useCallback(
    (view) => (
      <LabMateSelectionSwitcher
        significanceRecords={groupedSignificanceRecords}
        selectedView={view}
        enrichmentValues={enrichmentValues}
        featureName={PHENOFINDER_FEATURE_SET_NAME}
        onChangeView={handleChangeView}
        onShowPopup={handleShowPopup}
        onClosePopup={handleClosePopup}
        hasPhenoFinderFeatures={hasPhenoFinderFeatures}
        disabled={selectedPoints.size === metadata.length}
      />
    ),
    [
      groupedSignificanceRecords,
      handleShowPopup,
      handleClosePopup,
      handleChangeView,
      enrichmentValues,
      hasPhenoFinderFeatures,
      selectedPoints,
      metadata,
    ],
  );

  const clusters = useMemo(
    () => Array.from(clusterMap.entries()),
    [clusterMap],
  );

  const selectedViewType: SelectedViewType = useMemo(() => {
    if (showClusters && totalPointsSelected <= 1 && coloringMetadataColumn) {
      return "clusters";
    } else if (coloringMetadataColumn === null && totalPointsSelected <= 1) {
      return "placeholder";
    } else if (view === "summary") {
      return isSimilarityEnabled &&
        coloringMetadataColumn &&
        selectedValues.length >= 1 &&
        selectedValues.length <= 2
        ? "similarity"
        : "representation";
    } else if (enrichmentValues) {
      return "phenofinder";
    } else if (view === "over") {
      return "over";
      // TODO(you): Fix this no-unnecessary-condition rule violation
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (view === "under") {
      return "under";
    } else {
      throw new Error(`Unhandled LabMate view: ${view}`);
    }
  }, [
    coloringMetadataColumn,
    isSimilarityEnabled,
    selectedValues,
    showClusters,
    totalPointsSelected,
    view,
    enrichmentValues,
  ]);

  const clusterView = useMemo(() => {
    return selectedViewType === "clusters" && coloringMetadataColumn ? (
      <LabMateClusters
        clusters={clusters}
        clusterColors={clusterColors}
        hoveredCluster={hoveredCluster}
        metadataByKey={metadataByKey}
        metadataColors={metadataColors}
        numWells={dataset.length}
        selectedMetadataColumn={coloringMetadataColumn}
        onHoverCluster={onHoverCluster}
        onSelectPoints={onSelectPoints}
        onHoverValue={onHoverKey}
      />
    ) : null;
  }, [
    coloringMetadataColumn,
    clusters,
    clusterColors,
    dataset,
    hoveredCluster,
    metadataByKey,
    metadataColors,
    onHoverCluster,
    onSelectPoints,
    onHoverKey,
    selectedViewType,
  ]);

  // TODO (michaelwiest): probably want to give a separate message here when all points
  //  have been selected.
  const placeholderView = useMemo(() => {
    return (
      <div className="tw-text-slate-500 tw-text-sm tw-mt-sm">
        To get started, drag your mouse over a group of points or make a
        selection from{" "}
        <span className={"tw-font-mono tw-text-purple"}>COLOR BY</span> above.
      </div>
    );
  }, []);

  const similarityView = useMemo(() => {
    return selectedViewType === "similarity" && coloringMetadataColumn ? (
      <LabMateSimilarity
        filterSerialized={filterSerialized}
        umapData={umapData}
        referenceValue={selectedValues[0]}
        comparisonValue={
          selectedValues.length === 2 ? selectedValues[1] : undefined
        }
        metadataColors={metadataColors}
        idColumn={coloringMetadataColumn}
        plates={plates}
        features={features}
        hoveredKey={hoveredKey}
        onHover={onHoverKey}
        onToggle={onToggleKey}
        isLoading={isLoading}
        onLoading={setIsLoading}
      />
    ) : null;
  }, [
    filterSerialized,
    umapData,
    selectedValues,
    metadataColors,
    coloringMetadataColumn,
    plates,
    features,
    hoveredKey,
    onHoverKey,
    onToggleKey,
    isLoading,
    setIsLoading,
    selectedViewType,
  ]);

  const selectedPointViewer = useMemo(() => {
    return getSelectionSwitcher(view);
  }, [view, getSelectionSwitcher]);

  // TODO(davidsharff) handle no significant values when points are selected
  const selectedView = useMemo(() => {
    switch (selectedViewType) {
      case "clusters":
        return clusterView;
      case "placeholder":
        return placeholderView;
      case "similarity":
        return similarityView;
      case "representation":
      case "over":
      case "under":
      case "phenofinder":
        return selectedPointViewer;
      default:
        throw new Error(`Unhandled LabMate viewType: ${selectedViewType}`);
    }
  }, [
    selectedViewType,
    clusterView,
    placeholderView,
    similarityView,
    selectedPointViewer,
  ]);

  const useCustomHeader = selectedViewType === "similarity";

  return (
    <div className="tw-flex tw-flex-col tw-overflow-hidden tw-mt-md">
      <div className="tw-px-xl tw-py-md tw-border-t tw-bg-slate-50 tw-overflow-y-auto">
        {popupRecords !== null &&
          children(
            <LabMatePopup
              significanceRecords={popupRecords}
              shouldPrefixPct={view !== "summary"}
            />,
          )}

        {useCustomHeader ? null : (
          <div className="tw-h-[50px] tw-flex tw-items-baseline">
            <LabMateLogo animate={isLoading ? "always" : "off"} />
          </div>
        )}
        {selectedView}
      </div>
    </div>
  );
}
