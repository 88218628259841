/**
 * Component to render a placeholder for an empty dropdown.
 */
import { css } from "aphrodite";
import { DARK_GREY, shared } from "../../megamap-styles";

export default function EmptyList() {
  return (
    <div
      className={"tw-select-none tw-p-1 " + css(shared.quieter)}
      style={{ color: DARK_GREY }}
    >
      No results.
    </div>
  );
}
