/**
 * Component to render a selector for "select" filter conditions.
 */
import { css } from "aphrodite";
import { useRef, useState } from "react";
import { Overlay } from "react-overlays";
import DownCaret from "../../../icons/DownCaret.svg";
import { shared } from "../../../megamap-styles";
import DropdownList from "../../Dropdown/DropdownList";
import { OverlayBody } from "../OverlayParent";
import { SelectCondition } from "../operations/filter-by";

function getTitle(c: SelectCondition): string {
  switch (c) {
    case SelectCondition.IS:
    case SelectCondition.IS_NOT:
    case SelectCondition.IS_EMPTY:
    case SelectCondition.IS_NOT_EMPTY:
      return c;

    case SelectCondition.IS_ANY_OF:
    case SelectCondition.IS_NONE_OF:
      return `${c}...`;
  }
}
const CONDITIONS: Array<{ id: SelectCondition; title: string }> = Object.values(
  SelectCondition,
).map((value) => {
  return {
    id: value,
    title: getTitle(value),
  };
});

export function SelectConditionSelector({
  condition,
  onChange,
}: {
  condition: SelectCondition;
  onChange: (condition: SelectCondition) => void;
}) {
  const triggerRef = useRef(null);
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div
      className={"tw-flex tw-flex-none tw-overflow-visible tw-relative"}
      ref={containerRef}
    >
      <div
        className={
          "tw-flex tw-items-center tw-px-0.5 tw-mr-0.5 tw-rounded tw-cursor-pointer " +
          css(shared.textBlueFocus, shared.linkQuiet, shared.darken1Hover)
        }
        role={"button"}
        onClick={() => setIsOpen(true)}
        ref={triggerRef}
        aria-expanded={isOpen}
        tabIndex={0}
      >
        <div className={"tw-truncate tw-flex-auto"}>{condition}</div>
        <div className={"tw-flex-none tw-flex tw-items-center tw-ml-0.5"}>
          <DownCaret />
        </div>
      </div>
      <Overlay
        placement={"bottom-start"}
        show={isOpen}
        target={triggerRef}
        onHide={() => setIsOpen(false)}
        rootClose
      >
        {({ props }) => (
          <OverlayBody {...props} minWidth={80}>
            <DropdownList
              items={CONDITIONS}
              onClick={(item) => {
                onChange(item.id);
                setIsOpen(false);
              }}
              defaultValue={condition}
              searchable
              placeholder={"Find a filter operator"}
            />
          </OverlayBody>
        )}
      </Overlay>
    </div>
  );
}
