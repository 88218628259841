import { ValidatedSQL } from "src/util/sql";
import {
  CheckboxColumn,
  MultiSelectColumn,
  MultilineTextColumn,
  NumberColumn,
  SelectColumn,
  TextColumn,
} from "./backend-types";
import {
  BooleanCondition,
  MultiSelectCondition,
  NumberCondition,
  Operator,
  SelectCondition,
  TextCondition,
} from "./operations/filter-by";

export type Condition =
  | BooleanCondition
  | TextCondition
  | NumberCondition
  | SelectCondition
  | MultiSelectCondition;

type SelectFilterQueryText = number | string | (string | number)[];
export type SelectFilter<
  T extends SelectFilterQueryText = SelectFilterQueryText,
> = {
  // TODO(benkomalo): string metadata columns can also be empty/null, so the
  // typing here isn't great. Ideally the queryText would explicitly indicate nulls
  // but right now everything is being handled by the use of a string sentinel
  // indicating a null.
  type: "select";
  column: SelectColumn;
  queryText: T;
  condition: SelectCondition;
};

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type NestedFilter = {
  type: "nested";
  filterSet: FilterSet;
};

export type Filter =
  | {
      type: "text";
      column: TextColumn;
      queryText: string;
      condition: TextCondition;
    }
  | {
      type: "multilineText";
      column: MultilineTextColumn;
      queryText: string;
      condition: TextCondition;
    }
  | {
      type: "number";
      column: NumberColumn;
      queryText: string;
      condition: NumberCondition;
    }
  | {
      type: "checkbox";
      column: CheckboxColumn;
      queryText: boolean;
      condition: BooleanCondition;
    }
  | SelectFilter
  | {
      type: "multiSelect";
      column: MultiSelectColumn;
      queryText: (number | string)[];
      condition: MultiSelectCondition;
    }
  | NestedFilter;

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type FilterType = Filter["type"];
const FILTER_TYPES: Set<FilterType> = new Set([
  "checkbox",
  "multiSelect",
  "multilineText",
  "number",
  "select",
  "text",
  "nested",
]);

export function isFilterType(s: any): s is FilterType {
  return FILTER_TYPES.has(s);
}

type GetFilterValues<T extends Filter> = T extends NestedFilter
  ? Omit<T, "filterSet"> & {
      filterSet: {
        operator: Operator;
        filterValues: GetFilterValues<Filter>[];
      };
    }
  : Omit<T, "column"> & { columnId: string };

export type FilterValue = GetFilterValues<Filter>;

export type FilterSet<T extends Filter = Filter> =
  | {
      filters: T[];
      operator: Operator.AND;
      // With a conjunction, clients can optionally validate that the conjunction
      // does not lead to an empty/invalid result, and by doing so segment the filters
      // into two sublists: a normal one and an "ignored" one for filters it won't
      // apply.
      ignoredFilters: T[];
    }
  | {
      filters: T[];
      operator: Operator.OR;
    };

/**
 * A clause built up by a FilterSelector that can be used as a WHERE clause.
 */
export type FilterSqlClause = string;

/**
 * A FilterSet serialized as a single, URL-safe value to be used in URL params.
 */
export type FilterUrlState = string;

export function isNestedFilter(filter: Filter): filter is NestedFilter {
  return filter.type === "nested";
}

export interface ClauseEntry {
  id: `clause${number}` | "combined";
  clause: ValidatedSQL;
  index: number;
  ignored: boolean;
  isSubClause: boolean;
  operator: Operator;
}
