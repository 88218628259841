/**
 * Component for viewing feature overlays in image data.
 */
import cx from "classnames";
import { useEffect, useMemo, useRef, useState } from "react";
import { Sliders, ZoomIn } from "react-feather";
import { createStore } from "state-pool";
import { Fetchable } from "@spring/core/result";
import Loader, { Center } from "../Common/Loader";
import Strut from "../Common/Strut";
import ControlsSidebar from "../Control/ControlsSidebar";
import FieldSelector from "../Control/FieldSelector";
import WellSelector from "../Control/WellSelector";
import ErrorMessage from "../Error/ErrorMessage";
import OverlayExample from "../assets/screenshots/overlay-example.png";
import { useAutoImageSet, useSourceSize } from "../hooks/immunofluorescence";
import useWindowDimensions from "../hooks/utils";
import { FeaturePresentation, Field } from "../imaging/types";
import { toNumericField } from "../imaging/util";
import MultiChannelView from "../immunofluorescence/MultiChannelView";
import VisualizationControls from "../immunofluorescence/VisualizationControls";
import { useQueryParams } from "../routing";
import { DatasetId, UntypedWellSampleMetadataRow } from "../types";
import { columnComparator } from "../util/sorting";
import {
  DB,
  sql,
  useCacheableQueryAsRecords,
  useQueryAsRecords,
} from "../util/sql";
import FeaturePresentationOptions, {
  FilterParameters,
} from "./FeaturePresentationOptions";
import { useFeatureSetManagementContext } from "./context";
import { SelectedCell } from "./types";

const store = createStore();

type FilterableFeatures = Array<{
  well: string;
  field: Field;
  row: number;
  column: number;
  value: number;
  toShow: boolean;
}>;

export default function OverlayViewer({
  dataset,
  plate,
  featuresDB,
  featureSetColumn,
}: {
  dataset: DatasetId;
  plate: string;
  featuresDB: DB;
  featureSetColumn: string;
}) {
  const [queryParams, setQueryParams] = useQueryParams<{
    well?: string | null;
    overlaysField?: Field | null;
    cellColumn?: string;
    cellRow?: string;
  }>();

  const wells = useQueryAsRecords<{ well: string }>(
    featuresDB,
    sql`SELECT well FROM features GROUP BY well ORDER BY well`,
  )?.map((results) => results.map(({ well }) => well));

  const { metadataDB } = useFeatureSetManagementContext();

  const selectedWell = queryParams.well || null;
  const selectedField = queryParams.overlaysField || null;

  const fieldMetadataQuery = useQueryAsRecords<UntypedWellSampleMetadataRow>(
    metadataDB,
    selectedWell
      ? sql`SELECT * FROM sample_metadata as s WHERE s.plate = '${plate}' AND s.well = '${selectedWell}'`
      : undefined,
  );

  const metadata = fieldMetadataQuery?.successful
    ? fieldMetadataQuery.value[0]
    : null;

  const selectedCell: SelectedCell | null = getSelectedCellParam(queryParams);

  const storePrefix = `${dataset}#${plate}`;

  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);

  const imageContainerRef = useRef<HTMLDivElement | null>(null);

  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  const handleClearSelectedCell = () => {
    const { cellColumn, cellRow, ...rest } = queryParams;
    setQueryParams(rest);
  };

  const featureMinMax = useCacheableQueryAsRecords(
    featuresDB,
    selectedWell && selectedField
      ? sql`SELECT MAX("${featureSetColumn}") as max, MIN("${featureSetColumn}") as min
       FROM features WHERE well = '${selectedWell}' AND field = '${selectedField}'`
      : undefined,
  ).result?.map((untyped) => {
    return untyped.map((row) => ({
      min: row.min as number,
      max: row.max as number,
    }));
  });

  let minValue = featureMinMax?.successful ? featureMinMax.value[0].min : null;
  let maxValue = featureMinMax?.successful ? featureMinMax.value[0].max : null;

  // Check if we have a "prediction" in 0-1 and fix the bounds to 0-1.
  if (
    minValue != null &&
    minValue >= 0 &&
    minValue <= 1 &&
    maxValue != null &&
    maxValue >= 0 &&
    maxValue <= 1
  ) {
    minValue = 0.0;
    maxValue = 1.0;
  }

  const [filterParameters, setFilterParameters] =
    store.useState<FilterParameters>(`${storePrefix}#FilterParameters`, {
      default: {
        lowerBound: Number(minValue),
        upperBound: Number(maxValue),
      },
    });

  // Adding the ignore because adding setFilterParameters is not stable .
  useEffect(
    () =>
      setFilterParameters({
        upperBound: Number(maxValue),
        lowerBound: Number(minValue),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [minValue, maxValue],
  );
  const [featurePresentation, setFeaturePresentation] =
    store.useState<FeaturePresentation>(`${storePrefix}#FeaturePresentation`, {
      default: "dot",
    });

  // Change default to box presentation if a cell is selected on mount (dot is hard to find)
  useEffect(() => {
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (selectedCell?.row && selectedCell?.column) {
      setFeaturePresentation("box");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const imagePadding = 16;

  const imageContainerRect =
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    imageContainerRef?.current?.getBoundingClientRect();

  let imageSize = null;

  if (imageContainerRect) {
    const maxImageHeight = Math.max(
      windowHeight - imageContainerRect.top - window.scrollY - imagePadding * 2,
      0,
    );

    const maxImageWidth = Math.max(
      windowWidth - imageContainerRect.left - 20 - imagePadding * 2, // 20 = parent right padding
      0,
    );

    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    imageSize = imageContainerRect
      ? Math.min(maxImageHeight, maxImageWidth)
      : null;
  }

  // TODO(colin): ideally I think we'd fetch using `imageSize` to select the
  // image size, but I think there's another bug somewhere where we don't plot
  // the overlay correctly if we're not using the source image size.
  const sourceImageSize = useSourceSize({ dataset });

  const imageSet = useAutoImageSet({
    dataset,
    params: {
      imageSize: sourceImageSize?.unwrap() ?? null,
      processingMode: "illumination-corrected",
    },
  });

  const lowerBoundClause = filterParameters.lowerBound
    ? `("${featureSetColumn}" >= ${filterParameters.lowerBound})`
    : "TRUE";
  const upperBoundClause = filterParameters.upperBound
    ? `("${featureSetColumn}" < ${filterParameters.upperBound})`
    : "TRUE";

  const typedFeatures: Fetchable<FilterableFeatures> =
    useCacheableQueryAsRecords(
      featuresDB,
      selectedWell && selectedField
        ? sql`SELECT *, 
        (${lowerBoundClause} AND ${upperBoundClause}) AS toShow
        FROM features WHERE well = '${selectedWell}' AND field = '${selectedField}'`
        : undefined,
    ).result?.map((untyped) => {
      return untyped.map((row) => ({
        well: row.well as string,
        field: row.field as Field,
        row: Number(row.row),
        column: Number(row.column),
        value: row[featureSetColumn] as number,
        toShow: row.toShow as boolean,
      }));
    });

  const activeFeatures = typedFeatures?.successful
    ? typedFeatures.value.filter((row) => row.toShow)
    : null;
  const features = typedFeatures?.successful ? typedFeatures.value : null;

  const index = useMemo(
    () =>
      selectedWell && selectedField
        ? {
            dataset: dataset,
            plate: plate,
            well: selectedWell,
            field: toNumericField(selectedField),
            t: 0,
            z: 0,
          }
        : null,
    [dataset, plate, selectedWell, selectedField],
  );

  if (!wells) {
    return (
      <Center extraClasses={"tw-min-h-[600px]"}>
        <Loader />
      </Center>
    );
  } else if (!wells.successful) {
    return <ErrorMessage error={wells.error} />;
  } else if (typedFeatures && !typedFeatures.successful) {
    return <ErrorMessage error={typedFeatures.error} />;
  }

  return (
    <div
      className={cx(
        "tw-flex tw-flex-row",
        "tw-h-[calc(100vh-theme(spacing.global-nav-height)-theme(spacing.analyze-toolbar-height))]",
        "tw-overflow-y-hidden",
      )}
    >
      <ControlsSidebar>
        <div className={"tw-flex tw-flex-col"}>
          {/* Add z-index so that dropdowns aren't covered by single cell overlay on histogram. */}
          <div className={"tw-p-8 tw-flex tw-flex-col tw-z-20"}>
            <WellSelector
              dataset={dataset}
              plate={plate}
              well={selectedWell}
              wells={wells.value}
              onSelectWell={(well) => {
                const { cellColumn, cellRow, ...rest } = queryParams;
                setQueryParams({
                  ...rest,
                  well,
                });
              }}
              autoSelect
            />
            <Strut size={16} />
            {selectedWell && (
              <FieldSelector
                dataset={dataset}
                plate={plate}
                field={selectedField}
                onSelectField={(field) => {
                  const { cellColumn, cellRow, ...rest } = queryParams;
                  setQueryParams({
                    ...rest,
                    overlaysField: field,
                  });
                }}
                onClearField={() => {
                  const { cellColumn, cellRow, ...rest } = queryParams;
                  setQueryParams({
                    ...rest,
                    overlaysField: null,
                  });
                }}
                autoSelect
              />
            )}
          </div>
          <div className={"tw-p-8 tw-pt-0 tw-pb-4"}>
            <FeaturePresentationOptions
              featureName={featureSetColumn}
              minValue={minValue}
              maxValue={maxValue}
              typedFeatures={features}
              featureSetColumn={featureSetColumn}
              featurePresentation={featurePresentation}
              filterParameters={filterParameters}
              onChangeFeaturePresentation={setFeaturePresentation}
              onChangeFilterParameters={setFilterParameters}
              isCellSelected={!!selectedCell}
              onClearSelectedCell={handleClearSelectedCell}
            />
          </div>
        </div>
        {metadata && (
          <div className="tw-p-8 tw-pt-0 tw-grow tw-overflow-y-auto tw-text-sm">
            <div className="tw-font-mono tw-mb-4">{metadata.plate}</div>
            {Object.keys(metadata)
              .filter((key) => key !== "plate" && key !== "well")
              .sort(columnComparator)
              .map((key) => (
                <div key={key} className={"tw-mb-4 tw-flex"}>
                  <div
                    title={key}
                    className="tw-capitalize tw-text-slate-500 tw-truncate tw-mr-1"
                  >
                    {key}
                  </div>
                  <div
                    title={`${metadata[key]}`}
                    className="tw-truncate tw-font-mono tw-text-right tw-flex-1"
                    style={{ color: "#333" }}
                  >
                    {metadata[key] === null ? "<null>" : metadata[key]}
                  </div>
                </div>
              ))}
          </div>
        )}
      </ControlsSidebar>
      <div ref={imageContainerRef}>
        {index && (
          <div
            className={"tw-relative"}
            style={{
              // Hack(davidsharff): padding is used in sizing calc so we use an inline style to keep them in sync
              padding: imagePadding,
            }}
          >
            {imageSize !== null && (
              <>
                <MultiChannelView
                  index={index}
                  imageSet={imageSet}
                  crop={null}
                  size={imageSize}
                  features={typedFeatures?.successful ? activeFeatures : null}
                  featurePresentation={featurePresentation}
                  showDownloadControls={false}
                  selectedCell={selectedCell || undefined}
                />
                {/* TODO(emily): This just overlaps the image for now, since we don't
                 * resize the image to fit the viewport. Once that happens, make
                 * sure we reset the size when this is opened/closed. */}
                <div className="tw-absolute tw-top-8 tw-right-0 tw-pointer-events-none">
                  <button
                    className="tw-appearance-none tw-flex tw-items-center tw-m-4 tw-px-2 tw-py-1 tw-rounded tw-bg-slate-900/70 tw-text-slate-200 hover:tw-text-slate-100 hover:tw-bg-slate-900/90 tw-cursor-pointer tw-pointer-events-auto"
                    onClick={() => setSettingsOpen(!settingsOpen)}
                  >
                    Settings <Sliders size={16} className="tw-ml-2" />
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <div
        className={cx(
          "tw-border-l",
          settingsOpen ? "tw-visible" : "tw-invisible tw-w-0",
        )}
      >
        <ControlsSidebar>
          <div className={"tw-p-8 tw-pt-2"}>
            <VisualizationControls dataset={dataset} plate={plate} />
          </div>
        </ControlsSidebar>
      </div>
    </div>
  );
}

export function OverlayViewerPlaceholder({
  featureName,
}: {
  featureName: string;
}) {
  return (
    <div className={"tw-w-full tw-text-center tw-text-slate-500 tw-pb-12"}>
      <div className={"tw-text-xl tw-mt-8"}>
        View single-cell measurements overlaid on your imaging data here.
      </div>
      <div className={"tw-leading-loose"}>
        ({featureName} is not a single-cell measurement)
      </div>
      <div
        className={
          "tw-flex tw-flex-row tw-items-end tw-justify-center tw-py-12"
        }
      >
        <ZoomIn
          size={128}
          className={
            "tw-text-primary-500 tw-mr-[-100px] tw-scale-x-[-1] tw-relative"
          }
        />
        <img
          src={OverlayExample}
          width={400}
          height={400}
          className={"tw-shadow-[10px_35px_60px_0px_rgba(0,0,0,0.7)]"}
        />
      </div>
    </div>
  );
}

function getSelectedCellParam(queryParams: {
  cellColumn?: string;
  cellRow?: string;
}) {
  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const column = parseFloat(queryParams?.cellColumn || "");
  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const row = parseFloat(queryParams?.cellRow || "");

  return !Number.isNaN(column) && !Number.isNaN(row)
    ? {
        column,
        row,
      }
    : null;
}
