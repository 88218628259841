import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";

type ToastInfo = {
  key: string;
  contents: ReactNode;
  // TODO(davidsharff): this support was added to maintain existing behavior but should be dropped in the future. See:
  //   https://linear.app/spring-science/issue/SPR-2772/replace-toast-in-multi-feature-and-drop-custom-toast-container
  // Allow caller to use their own ToastContainer by matching this field to the ToastContainer id prop
  targetContainerId: "global" | string;
};

type ToastContext = {
  toast: ToastInfo | null;
  setToast: (
    key: string,
    contents: ReactNode,
    targetContainerId?: string,
  ) => void;
  dismissToast: (key: string) => void;
};

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const Context = createContext<Record<string, never> | ToastContext>({});

export function useToastContext(): Record<string, never> | ToastContext {
  return useContext(Context);
}

export function ToastContextProvider({ children }: { children: ReactNode }) {
  const [toast, _setToast] = useState<ToastInfo | null>(null);

  const setToast = useCallback(
    (key: string, contents: ReactNode, targetContainerId?: string) =>
      _setToast({
        key,
        contents,
        targetContainerId: targetContainerId || "global",
      }),
    [],
  );
  const dismissToast = useCallback((key: string) => {
    _setToast((toast) => (toast?.key === key ? null : toast));
  }, []);

  return (
    <Context.Provider
      value={{
        toast,
        setToast,
        dismissToast,
      }}
    >
      {children}
    </Context.Provider>
  );
}
