import * as Popover from "@radix-ui/react-popover";
import cx from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { FastForward, Icon, Pause, Play, Repeat, Rewind } from "react-feather";
import Spinner from "src/Common/Spinner";
import { useDebouncedCallback } from "use-debounce";
import { Slider } from "@spring/ui/Slider";
import { useTimeSeriesContext } from "./Context";

export function TimeSeriesControls({ className }: { className?: string }) {
  const {
    isLoaded,
    timepoints,
    currentTimepoint,
    maxTimepoint,
    imagesPerSecond,
    isPlaying,
    isLooping,
    startPlayback,
    pausePlayback,
    toggleLooping,
    stepNext,
    stepPrevious,
    jumpToTimepoint,
    setImagesPerSecond,
  } = useTimeSeriesContext();

  // We debounce updates, so the slider value may differ from the actual values while scrubbing
  const [sliderTimepointValue, setSliderTimepointValue] =
    useState<number>(currentTimepoint);
  const [sliderFps, setSliderFps] = useState<number>(imagesPerSecond);

  // When the user is not scrubbing, the player may be playing, in which case the
  // slider needs to stay in sync with the current timepoint
  const isScrubbingTimepoint = useRef<boolean>(false);
  useEffect(() => {
    if (isScrubbingTimepoint.current) {
      return;
    }

    if (sliderTimepointValue !== currentTimepoint) {
      setSliderTimepointValue(currentTimepoint);
    }
  }, [sliderTimepointValue, currentTimepoint]);

  const updateCurrentTimepoint = useCallback(
    (newTimepoint: number) => {
      isScrubbingTimepoint.current = false;
      jumpToTimepoint(newTimepoint);
    },
    [jumpToTimepoint],
  );
  const updateCurrentTimepointDebounced = useDebouncedCallback(
    updateCurrentTimepoint,
    100,
  );
  const handleTimepointSliderChange = useCallback(
    (newTimepoint: number) => {
      isScrubbingTimepoint.current = true;
      setSliderTimepointValue(newTimepoint);
      updateCurrentTimepointDebounced(newTimepoint);
    },
    [updateCurrentTimepointDebounced],
  );

  const updateCurrentFps = useCallback(
    (newFPS: number) => {
      setImagesPerSecond(newFPS);
    },
    [setImagesPerSecond],
  );
  const updateCurrentFpsDebounced = useDebouncedCallback(updateCurrentFps, 100);
  const handleFpsSliderChange = useCallback(
    (newFPS: number) => {
      setSliderFps(newFPS);
      updateCurrentFpsDebounced(newFPS);
    },
    [setSliderFps, updateCurrentFpsDebounced],
  );

  return (
    <div className={cx(className, "tw-w-full", "tw-flex tw-items-center")}>
      <div
        className={cx(
          "tw-flex tw-items-center tw-space-x-md",
          "tw-rounded tw-py-xs tw-px-sm",
          "tw-border-2 tw-border-slate-200",
        )}
      >
        {isLoaded ? (
          <>
            <TimeSeriesControlButton
              icon={Rewind}
              onClick={stepPrevious}
              disabled={currentTimepoint === 0}
            />
            <TimeSeriesControlButton
              icon={isPlaying ? Pause : Play}
              onClick={isPlaying ? pausePlayback : startPlayback}
            />
            <TimeSeriesControlButton
              icon={FastForward}
              onClick={stepNext}
              disabled={currentTimepoint === maxTimepoint}
            />
            <TimeSeriesControlButton
              icon={Repeat}
              onClick={toggleLooping}
              fill={false}
              active={isLooping}
            />
            <Popover.Root>
              <Popover.Trigger asChild>
                <button
                  className={cx(
                    "tw-w-[58px] tw-flex tw-justify-center tw-items-center tw-space-x-xs",
                    "tw-px-sm tw-py-xs tw-rounded tw-text-sm",
                    "tw-bg-slate-200",
                  )}
                >
                  <div
                    className={cx(
                      "tw-font-bold tw-leading-tight",
                      "tw-text-slate-900",
                    )}
                  >
                    {imagesPerSecond}
                  </div>
                  <div
                    className={cx(
                      "tw-text-xs tw-font-mono tw-leading-tight",
                      "tw-text-slate-600",
                    )}
                  >
                    FPS
                  </div>
                </button>
              </Popover.Trigger>
              <Popover.PopoverPortal>
                <Popover.Content
                  className={cx(
                    "tw-w-[120px]",
                    "tw-p-sm tw-rounded",
                    "tw-text-sm",
                    "tw-bg-white tw-border",
                  )}
                >
                  <Popover.PopoverArrow />
                  <Slider
                    min={1}
                    max={Math.min(30, timepoints.length)}
                    value={sliderFps}
                    onValueChange={handleFpsSliderChange}
                  />
                </Popover.Content>
              </Popover.PopoverPortal>
            </Popover.Root>
          </>
        ) : (
          <>
            <Spinner />
            <div>Loading…</div>
          </>
        )}
      </div>

      <div className={cx("tw-mx-md", "tw-flex-1")}>
        <Slider
          min={0}
          max={maxTimepoint}
          value={sliderTimepointValue}
          onValueChange={handleTimepointSliderChange}
        />
      </div>

      <div>
        {currentTimepoint} / {maxTimepoint}
      </div>
    </div>
  );
}

interface TimeSeriesControlButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: Icon;
  active?: boolean;
  fill?: boolean;
}

function TimeSeriesControlButton({
  icon,
  active = false,
  fill = true,
  ...rest
}: TimeSeriesControlButtonProps) {
  const ControlIcon = icon;
  return (
    <button {...rest}>
      <ControlIcon
        size={16}
        className={cx(
          rest.disabled
            ? "tw-text-slate-300"
            : active
              ? "tw-text-purple"
              : "tw-text-slate-600",
          {
            "tw-fill-slate-300": fill && rest.disabled,
            "tw-fill-slate-600": fill && !rest.disabled,
          },
        )}
      />
    </button>
  );
}
