import { User } from "@auth0/auth0-react";
import { ReactNode, useEffect } from "react";
import { Provider } from "react-redux";
import { FullScreenLoader } from "src/Common/FullScreenLoader";
import { useIsLoadingPrefs } from "src/hooks/prefs";
import ErrorBoundary from "../Error/ErrorBoundary";
import AppChromeNav, {
  AppChromeNavBase,
  AppChromeNavLoggedOut,
} from "./AppChromeNav";
import initializeBirdEatsBug from "./initialize-bird-eats-bug";
import store from "./store";

export default function AppChrome({
  user,
  children,
}: {
  user: User;
  children: ReactNode;
}) {
  const isLoadingPrefs = useIsLoadingPrefs();

  useEffect(() => {
    if (user.email) {
      // The email shouldn't ever change once it is set. However, it would be a noop if it did.
      initializeBirdEatsBug(user.email);
    }
  }, [user.email]);

  return (
    <Provider store={store}>
      <AppChromeNav user={user} />
      {isLoadingPrefs ? (
        <FullScreenLoader />
      ) : (
        <div className={"tw-pt-12"}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
      )}
    </Provider>
  );
}

export function AppChromeMinimal({
  isLoading = false,
  children,
}: {
  isLoading?: boolean;
  children: ReactNode;
}) {
  return (
    <>
      {isLoading ? <AppChromeNavBase /> : <AppChromeNavLoggedOut />}
      <div className={"tw-pt-12"}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>
    </>
  );
}
