/**
 * Component to render a list of items as a dropdown body.
 */
import { css } from "aphrodite";
import cx from "classnames";
import { useState } from "react";
import { shared } from "../../megamap-styles";
import KeypressListener from "../KeypressListener";
import DropdownListItem from "./DropdownListItem";
import EmptyList from "./EmptyList";
import List from "./List";
import SearchBox, { Styling } from "./SearchBox";
import { Item } from "./types";
import { firstAvailable, lastAvailable, wrapIndex } from "./utils";

const PAGE_SIZE = 100;

function filterItems<T>(items: Item<T>[], filterText: string): Item<T>[] {
  return items.filter(({ title }) => matches(title, filterText));
}

/**
 * @returns {@code true} if the string is valid given the query text.
 */
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function matches(value: string, queryText: string): boolean {
  return value.toLowerCase().includes(queryText.toLowerCase());
}

export default function DropdownList<T>({
  autoFocusSearch = true,
  items,
  defaultValue,
  searchable,
  placeholder,
  hideBorder,
  onClick,
  onPasteSearchInput,
}: {
  items: Item<T>[];
  defaultValue?: T;
  searchable?: Styling;
  placeholder?: string;
  onClick: (item: Item<T>) => void;
  onPasteSearchInput?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  hideBorder?: boolean;
  autoFocusSearch?: boolean;
}) {
  const [focusIndex, setFocusIndex] = useState<number | null>(
    defaultValue ? items.findIndex((item) => item.id === defaultValue) : null,
  );
  const [numVisibleItems, setNumVisibleItems] = useState<number>(PAGE_SIZE);
  const [filterText, setFilterText] = useState<string>("");
  const filteredItems =
    filterText.length > 0 ? filterItems(items, filterText) : items;
  const visibleItems = filteredItems.slice(0, numVisibleItems);
  const canShowMore = filteredItems.length > numVisibleItems;
  const numFocusableItems = visibleItems.length + (canShowMore ? 1 : 0);
  return (
    <div
      className={cx(
        "tw-bg-white",
        !hideBorder && "tw-rounded " + css(shared.stroked1),
      )}
      onMouseLeave={() => setFocusIndex(null)}
    >
      <KeypressListener
        code={"Enter"}
        onPress={() =>
          focusIndex == null
            ? undefined
            : focusIndex === visibleItems.length
              ? setNumVisibleItems(
                  (numVisibleItems) => numVisibleItems + PAGE_SIZE,
                )
              : onClick(visibleItems[focusIndex])
        }
      />
      <KeypressListener
        code={"ArrowUp"}
        onPress={() =>
          setFocusIndex((focusIndex) =>
            focusIndex === 0
              ? null
              : focusIndex == null
                ? lastAvailable(numFocusableItems)
                : wrapIndex(focusIndex - 1, numFocusableItems),
          )
        }
      />
      <KeypressListener
        code={"ArrowDown"}
        onPress={() =>
          setFocusIndex((focusIndex) =>
            focusIndex === numFocusableItems - 1
              ? null
              : focusIndex == null
                ? firstAvailable(numFocusableItems)
                : wrapIndex(focusIndex + 1, numFocusableItems),
          )
        }
      />
      {items.length > 0 ? (
        <>
          {searchable ? (
            <SearchBox
              placeholder={placeholder}
              filterText={filterText}
              styling={searchable}
              onChange={(filterText) => {
                if (filterText.length === 0) {
                  setFocusIndex(null);
                } else {
                  const willMatch = filterItems(items, filterText);
                  setFocusIndex(firstAvailable(willMatch.length));
                }
                setFilterText(filterText);
              }}
              onPasteSearchInput={onPasteSearchInput}
              autoFocus={autoFocusSearch}
            />
          ) : null}
          {visibleItems.length > 0 ? (
            <List>
              {visibleItems.map((item, index) => (
                <DropdownListItem
                  key={item.id as unknown as string}
                  focused={index === focusIndex}
                  onFocus={() => setFocusIndex(index)}
                  onClick={() => onClick(item)}
                  selected={item.selected}
                >
                  {item.node || item.title}
                </DropdownListItem>
              ))}
              {canShowMore ? (
                // TODO(benkomalo) use nicer + icon
                <DropdownListItem
                  focused={visibleItems.length === focusIndex}
                  onFocus={() => setFocusIndex(visibleItems.length)}
                  onClick={() =>
                    setNumVisibleItems(
                      (numVisibleItems) => numVisibleItems + PAGE_SIZE,
                    )
                  }
                >
                  <div className={"tw-ml-1 tw-text-sm"}>+ Show more</div>
                </DropdownListItem>
              ) : null}
            </List>
          ) : (
            <EmptyList />
          )}
        </>
      ) : (
        <EmptyList />
      )}
    </div>
  );
}
