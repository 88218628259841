import cx from "classnames";
import { useCallback, useState } from "react";
import { ChevronDown } from "react-feather";
import SelectionListItem from "./SelectionListItem";

export default function SelectableGroup({
  groupTitle,
  items,
  allSelectedItems,
  multi,
  disabled,
  onChangeAllSelectedItems,
  unprefixGroupTitleFromItems,
}: {
  groupTitle: string | null;
  items: string[];
  allSelectedItems: string[];
  multi: boolean;
  disabled: boolean;
  onChangeAllSelectedItems: (items: string[]) => void;
  unprefixGroupTitleFromItems?: boolean;
}) {
  const [expanded, setExpanded] = useState(true);
  const count = items.length;
  const selectedItems = allSelectedItems.filter((i) => items.includes(i));
  const countSelected = selectedItems.length;
  const areAllSelected = countSelected === count;

  const handleGroupTitleClick = useCallback(() => {
    if (areAllSelected) {
      onChangeAllSelectedItems(
        allSelectedItems.filter((i) => !items.includes(i)),
      );
    } else {
      const toAdd = items.filter((i) => !allSelectedItems.includes(i));
      onChangeAllSelectedItems([...allSelectedItems, ...toAdd]);
    }
  }, [items, areAllSelected, allSelectedItems, onChangeAllSelectedItems]);

  return (
    <div>
      {groupTitle && (
        <div
          className={cx(
            "tw-px-8 tw-py-2 tw-group tw-cursor-pointer hover:tw-bg-purple-100",
            areAllSelected && "tw-bg-purple-100",
            !multi && "tw-flex tw-flex-row tw-gap-sm",
          )}
        >
          <div
            className={cx(
              "tw-flex tw-text-sm",
              !multi && "tw-flex-1",
              areAllSelected && "tw-text-purple",
              disabled && "tw-text-slate-300",
            )}
            onClick={multi && !disabled ? handleGroupTitleClick : undefined}
          >
            {multi && !disabled && (
              <input
                type={"checkbox"}
                checked={areAllSelected}
                aria-label={`Select all items in ${groupTitle}`}
                className={cx(
                  areAllSelected
                    ? "tw-visible"
                    : "tw-invisible group-hover:tw-visible",
                  // TODO(benkomalo): custom purple styling. We don't have a common checkbox!
                  "tw-mr-2 tw-w-4",
                )}
                readOnly
              />
            )}
            {groupTitle}
          </div>
          <div
            className={cx(
              "tw-text-xs tw-flex tw-items-center tw-mt-xs tw-ml-lg hover:tw-underline",
              disabled ? "tw-text-slate-300" : "tw-text-slate-500",
            )}
            onClick={() => setExpanded(!expanded)}
          >
            {multi && !disabled && (
              <>
                {countSelected}/{count} selected
              </>
            )}
            <div
              className={cx(
                !expanded && "tw-rotate-180",
                "tw-ml-xs tw-transition-all",
              )}
            >
              <ChevronDown size={14} />
            </div>
          </div>
        </div>
      )}
      {expanded && (
        <>
          {items.map((item) => {
            const selected = selectedItems.includes(item);
            const toggleSelection = () => {
              if (selected) {
                onChangeAllSelectedItems(
                  allSelectedItems.filter((other) => other !== item),
                );
              } else {
                onChangeAllSelectedItems([...allSelectedItems, item]);
              }
            };

            return (
              <SelectionListItem
                key={item}
                selected={selected}
                onClick={() => toggleSelection()}
                title={item}
                indent={!!groupTitle}
                multi={multi}
                disabled={disabled}
              >
                <div className={"tw-text-sm tw-truncate tw-pr-1"}>
                  {groupTitle && unprefixGroupTitleFromItems
                    ? item.substring(groupTitle.length + 1)
                    : item}
                </div>
              </SelectionListItem>
            );
          })}
        </>
      )}
    </div>
  );
}
