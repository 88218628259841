import { useCallback, useMemo, useState } from "react";
import { CanSkip } from "src/hooks/api";
import { useDataset } from "src/hooks/datasets";
import {
  useAcquisitions,
  useAutoImageSet,
  usePalette,
  useWells,
} from "src/hooks/immunofluorescence";
import { EMPTY_CHANNEL_MAP, EMPTY_DISPLAY_RANGES } from "src/imaging/constants";
import { ChannelIndex, DisplayRanges, ImageMetadata } from "src/imaging/types";
import { defaultChannelMap } from "src/imaging/visualization";
import {
  DEFAULT_COVER_COLOR,
  WellCover,
  colorForCover,
  isWellCover,
  useSetCover,
} from "src/util/cover";
import { DatasetId, DatasetType } from "../types";

// We don't know all the contexts that we'll want to display a cover in, so when we
// are getting a default cover try to get one that's large enough for most cases
const MINIMUM_COVER_SOURCE_SIZE = 1024;

type CoverAndHandlers = [
  WellCover,
  // A callback to be called when a channel has been loaded, used in the case that
  // a default cover image is chosen and we don't yet know how to scale it
  (imageMetadata: ImageMetadata, channelIndex: ChannelIndex) => void,
  // A callback to be called when the images for the well have finished loading,
  // also used if a default cover image is chosen and we might want to write it back
  // to the server
  () => void,
];

type CoverWithoutHandlers = [WellCover, undefined, undefined];

type NoCover = [undefined, undefined, undefined];

export function useCover({
  dataset,
  datasetType,
  size,
  canvasRef,
}: {
  dataset: DatasetId;
  datasetType: DatasetType;
  size: number | undefined;
  canvasRef?: React.RefObject<HTMLCanvasElement>;
}): CoverAndHandlers | CoverWithoutHandlers | NoCover {
  const savedCover = useSavedCover(dataset);
  const defaultCoverAndHandlers = useDefaultImmunofluorescenceCover(
    !savedCover && size !== undefined && datasetType === "if_bf_plates"
      ? { dataset, size, canvasRef }
      : { skip: true },
  );

  if (savedCover) {
    return [savedCover, undefined, undefined];
  } else {
    return defaultCoverAndHandlers;
  }
}

// Gets the saved cover for a dataset, if one exists
function useSavedCover(dataset: DatasetId): WellCover | undefined {
  const listingFetch = useDataset({ dataset });
  const listing = listingFetch?.successful ? listingFetch.value : undefined;

  return useMemo((): WellCover | undefined => {
    if (!listing) {
      return undefined;
    }

    const cover = listing.cover;

    return cover && isWellCover(cover) ? cover : undefined;
  }, [listing]);
}

// Gets the default cover for a dataset; i.e. the center of field 0 of the first well
// on the first plate of the dataset
function useDefaultImmunofluorescenceCover(
  options: CanSkip<{
    dataset: DatasetId;
    size: number;
    canvasRef?: React.RefObject<HTMLCanvasElement>;
  }>,
): CoverAndHandlers | NoCover {
  const imageSet = useAutoImageSet(
    options.skip
      ? { skip: true }
      : {
          dataset: options.dataset,
          params: {
            imageSize: Math.max(options.size, MINIMUM_COVER_SOURCE_SIZE),
            processingMode: "illumination-corrected",
          },
        },
  );

  const plate =
    useAcquisitions(
      {
        dataset: options.dataset,
        skip: options.skip,
      },
      "simple",
    )?.[0] ?? null;

  const palette = usePalette(
    plate
      ? {
          dataset: options.dataset,
          acquisition: plate,
          skip: options.skip,
        }
      : { skip: true },
    "simple",
  );
  const well =
    useWells(
      plate
        ? {
            dataset: options.dataset,
            acquisition: plate,
            skip: options.skip,
          }
        : { skip: true },
      "simple",
    )?.[0] ?? null;

  const [displayRanges, setDisplayRanges] =
    useState<DisplayRanges>(EMPTY_DISPLAY_RANGES);

  const channelMap = useMemo(
    () => (palette ? defaultChannelMap(palette) : EMPTY_CHANNEL_MAP),
    [palette],
  );

  const handleChannel = useCallback(
    (imageMetadata: ImageMetadata, channelIndex: ChannelIndex) => {
      setDisplayRanges((displayRanges) => {
        const newDisplayRanges: DisplayRanges = [...displayRanges];
        newDisplayRanges[channelIndex] = imageMetadata.autoDisplayRange;
        return newDisplayRanges;
      });
    },
    [],
  );

  const cover = useMemo((): WellCover | undefined => {
    if (!plate || !well || !imageSet) {
      return undefined;
    }

    return {
      plate,
      well,
      field: 0,
      x: 0.5,
      y: 0.5,
      color: DEFAULT_COVER_COLOR,
      imageSet,
      channelMap,
      displayRanges,
    };
  }, [channelMap, displayRanges, imageSet, plate, well]);

  const setCover = useSetCover(options);

  const handleReady = useCallback(() => {
    if (!options.skip && cover) {
      const color = options.canvasRef
        ? colorForCover({ canvasRef: options.canvasRef })
        : DEFAULT_COVER_COLOR;
      // We don't have a cover saved, but we fetched a cover
      setCover({ ...cover, color });
    }
  }, [cover, options, setCover]);

  if (cover) {
    return [cover, handleChannel, handleReady];
  } else {
    return [undefined, undefined, undefined];
  }
}
