import cx from "classnames";
import { useCallback, useState } from "react";
import { Flag } from "react-feather";
import { Button } from "src/Common/Button";
import Spinner from "src/Common/Spinner";
import MultiChannelView from "src/immunofluorescence/MultiChannelView";
import { DatasetId } from "src/types";
import { Tooltip } from "@spring/ui/Tooltip";
import { Subtitle, Title } from "@spring/ui/typography";
import { ImageSet } from "../../imaging/types";
import { toNumericField } from "../../imaging/util";
import { useModelTrainingAndInferenceAreAllowed } from "../../util/users";
import { usePhenoFinderContext } from "../Context";
import { InferenceStatus } from "../types";
import { cleanUserInputString } from "../utils";
import { useEditClusterDisplayNames } from "./hooks";
import { getCellKey, getDefaultClusterDisplayName } from "./utils";

export function PublishModal({
  dataset,
  imageSet,
  onPublish,
  status,
}: {
  dataset: DatasetId;
  imageSet: ImageSet | null;
  onPublish: (description: string) => void;
  status: Exclude<InferenceStatus, InferenceStatus.SUCCESS>;
}) {
  // TODO(michaelwiest): Capture the errors and display them in the UI. Eg,
  // duplicate names or empty description.
  const [state, dispatch] = usePhenoFinderContext();
  const { clusters, description, defaultCropSize } = state;
  const [inputDescription, setInputDescription] = useState(description ?? "");
  const publishingAllowed = useModelTrainingAndInferenceAreAllowed();

  const {
    displayNames,
    onChangeDisplayName,
    onBlurDisplayNameInput,
    saveDisplayNameModifications,
  } = useEditClusterDisplayNames({ autoSaveState: false });

  const handleChangeDescription = useCallback((event) => {
    setInputDescription(event.target.value);
  }, []);

  const saveDescription = useCallback(() => {
    dispatch({
      type: "setDescription",
      description: cleanUserInputString(inputDescription) ?? "",
    });
  }, [dispatch, inputDescription]);

  const handleClickPublish = useCallback(() => {
    onPublish(inputDescription.trim());
  }, [onPublish, inputDescription]);

  const clusterNames = (clusters ?? []).map(
    (cluster, i) => cluster.displayName ?? getDefaultClusterDisplayName(i),
  );

  const canPublish =
    publishingAllowed &&
    inputDescription.trim().length > 0 &&
    status !== InferenceStatus.SENDING;
  const areInputsDisabled = status === InferenceStatus.SENDING;

  return (
    <div
      className={cx(
        "tw-h-[90vh] tw-w-[50vw] tw-min-w-[500px] tw-max-w-[750px]",
        "tw-flex tw-flex-col tw-overflow-hidden",
      )}
    >
      <div className={cx("tw-py-xl tw-flex-1 tw-overflow-auto")}>
        <div className="tw-px-xl">
          <Title>Publish Clusters</Title>
          <p className="tw-text-gray-500 tw-mt-md tw-mb-lg">
            By hitting{" "}
            <span className={"tw-text-purple tw-font-bold"}>Publish</span>,
            these cluster labels will be applied to all the single cells in your
            dataset. You can always come back here to edit these clusters later!
          </p>
          <hr />
          <div className="tw-my-lg">
            <Subtitle>Provide a description</Subtitle>

            <p className="tw-text-gray-500 tw-mb-md">
              A useful description helps you and collaborators keep track of
              what the feature is quantifying.
            </p>

            {/* TODO: Create a shared UI text input component for inputs */}
            <textarea
              className={cx(
                "tw-w-full tw-p-2 tw-border tw-rounded",
                "tw-border-gray-300 focus:tw-outline-primary-500",
              )}
              placeholder="Clusters optimized to demonstrate..."
              rows={3}
              // TODO(you): Fix this no-unnecessary-condition rule violation
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              value={inputDescription ?? undefined}
              onChange={handleChangeDescription}
              onBlur={saveDescription}
              disabled={areInputsDisabled}
            ></textarea>
          </div>
          <hr />
        </div>

        <div className="tw-my-lg tw-pr-xl">
          <div className="tw-px-xl tw-mb-md">
            <Subtitle>Cluster names</Subtitle>

            <p className="tw-text-gray-500 tw-mb-sm">
              Descriptive cluster names help you and collaborators keep track of
              the phenotype displayed in each cluster.
            </p>
          </div>

          <div className="tw-space-y-sm">
            {(clusters ?? []).map((cluster, i) => {
              const numClustersWithName = displayNames.filter(
                (name) => name === displayNames[i],
              ).length;
              const numOtherClustersWithSameName = numClustersWithName - 1;

              return (
                <div key={cluster.name} className={cx("tw-flex")}>
                  <div className="tw-w-8 tw-flex tw-items-center tw-justify-center">
                    {numOtherClustersWithSameName > 0 ? (
                      <Tooltip
                        className="tw-max-w-[280px]"
                        contents={
                          <p>
                            This cluster will be merged with{" "}
                            {numOtherClustersWithSameName} other{" "}
                            {numOtherClustersWithSameName === 1
                              ? "cluster that shares "
                              : "clusters that share "}
                            the same name.
                          </p>
                        }
                        side="left"
                        showArrow={true}
                        asChild={true}
                      >
                        <div
                          className={cx(
                            "tw-w-5 tw-h-5",
                            "tw-flex tw-items-center tw-justify-center",
                            "tw-bg-gray-500 tw-rounded-full",
                          )}
                        >
                          <Flag size={12} className="tw-text-white" />
                        </div>
                      </Tooltip>
                    ) : null}
                  </div>

                  <input
                    type="text"
                    title={clusterNames[i]}
                    value={displayNames[i]}
                    placeholder={getDefaultClusterDisplayName(i)}
                    onChange={(event) => {
                      onChangeDisplayName(i, event.target.value);
                    }}
                    onBlur={() => {
                      onBlurDisplayNameInput(i);
                      saveDisplayNameModifications();
                    }}
                    className={cx(
                      "tw-flex-1 tw-px-sm tw-mr-md",
                      "tw-rounded tw-border tw-border-gray-300",
                      "focus:tw-outline-primary-500",
                    )}
                    disabled={areInputsDisabled}
                  />

                  <div className="tw-flex tw-space-x-xs">
                    {cluster.cells.slice(0, 5).map((metadata) => {
                      return (
                        <MultiChannelView
                          index={{
                            dataset,
                            plate: metadata.plate,
                            well: metadata.well,
                            field: toNumericField(metadata.field),
                            t: 0,
                            z: 0,
                          }}
                          imageSet={imageSet}
                          crop={{
                            size: defaultCropSize!,
                            location: { x: metadata.row, y: metadata.column },
                          }}
                          key={getCellKey(metadata)}
                          size={48}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div
        className={cx("tw-border-t tw-p-md tw-mx-md tw-justify-end tw-pb-lg")}
      >
        <Button
          variant="primary"
          className={cx("tw-w-full tw-flex tw-items-center tw-justify-center")}
          onClick={handleClickPublish}
          disabled={!canPublish}
        >
          {status === InferenceStatus.SENDING ? (
            <Spinner />
          ) : status === InferenceStatus.FAILURE ? (
            "Uh oh something went wrong... Try again!"
          ) : publishingAllowed ? (
            "Publish"
          ) : (
            <Tooltip
              contents={
                <div className={"tw-max-w-[440px]"}>
                  Publishing clusters is unavailable in this demo workspace.
                  Please contact support@springscience.com to get your own
                  workspace.
                </div>
              }
              showArrow
              side={"top"}
            >
              Publish
            </Tooltip>
          )}
        </Button>
      </div>
    </div>
  );
}
