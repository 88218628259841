import * as ContextMenu from "@radix-ui/react-context-menu";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import cx from "classnames";
import { Ref, forwardRef, useMemo } from "react";
import { useLabeledSetContext } from "./Context";

export const LabelClassMenu = forwardRef(function LabelClassMenu(
  {
    excludedClassificationName,
    onLabel,
    onRemoveLabel,
    as: Component,
    showHeader = false,
  }: {
    excludedClassificationName?: string;
    onLabel: (classificationName: string) => void;
    onRemoveLabel?: () => void;
    as: typeof ContextMenu | typeof DropdownMenu;
    showHeader?: boolean;
  },
  ref: Ref<HTMLDivElement>,
) {
  const { state: labeledSetState } = useLabeledSetContext();
  const { classifications } = labeledSetState;

  const classificationNamesForLabeling = useMemo(() => {
    const names = classifications
      .map((otherClassification) => otherClassification.name)
      .sort();

    return excludedClassificationName === undefined
      ? names
      : names.filter((name) => name !== excludedClassificationName);
  }, [classifications, excludedClassificationName]);

  const getMenuItemClassName = (variant: "default" | "danger" = "default") =>
    cx("tw-cursor-pointer tw-rounded tw-px-3 tw-py-1", {
      "hover:tw-text-purple-700 hover:tw-bg-purple-200": variant === "default",
      "tw-text-red-500 hover:tw-bg-red-100 hover:tw-text-red-600":
        variant === "danger",
    });

  return (
    <Component.Content
      ref={ref}
      align="center"
      className={cx(
        "tw-min-w-[200px] tw-z-50 tw-px-3 tw-py-2",
        "tw-bg-white tw-rounded tw-border tw-border-gray-200 tw-font-sans tw-shadow-md",
      )}
    >
      {showHeader ? (
        <Component.Label className="tw-my-2 tw-px-3 tw-text-gray-400">
          Move Cells to Class
        </Component.Label>
      ) : null}
      {classificationNamesForLabeling.map((name) => (
        <Component.Item
          key={name}
          className={getMenuItemClassName()}
          onSelect={() => onLabel(name)}
        >
          {name}
        </Component.Item>
      ))}

      {onRemoveLabel !== undefined ? (
        <>
          <Component.Separator className="tw-my-2 tw-h-px tw-bg-gray-300" />

          <Component.Item
            className={getMenuItemClassName("danger")}
            onSelect={onRemoveLabel}
          >
            Remove from Class
          </Component.Item>
        </>
      ) : null}
    </Component.Content>
  );
});
