import { ReactElement, ReactNode, useMemo } from "react";
import { Provider } from "react-redux";
import { Field } from "src/imaging/types";
import { DatasetId } from "src/types";
import Loader from "../Common/Loader";
import ErrorMessage from "../Error/ErrorMessage";
import {
  inferLevelFromFeatureSetName,
  inferLevelFromFeatureSetRows,
} from "../FeatureSelector/utils";
import history from "../history";
import {
  useFeatureFilteredWellAggregatedFeature,
  useFetchFeaturesAsDB,
  useWellAggregatedFeature,
} from "../hooks/features";
import { VisualizationContextProvider } from "../imaging/context";
import configureStore from "../imaging/state/store";
import { useQueryParams } from "../routing";
import { sql, useQueryAsRecords } from "../util/sql";
import ComparisonsView from "./ComparisonsView";
import FeatureColumnDistributionsView from "./FeatureColumnDistributionsView";
import OverlayViewer, { OverlayViewerPlaceholder } from "./OverlayViewer";
import { useFeatureSetManagementContext } from "./context";
import { FeatureSetInfo, isPlateBasedFeatureSetId } from "./types";
import { SingleFeatureView } from "./views";

const store = configureStore(history);

type Props = {
  dataset: DatasetId;
  featureSetInfo: FeatureSetInfo;
  column: string;
  selectedTab: SingleFeatureView;
};

function FeatureSetColumnDetails({
  dataset,
  featureSetInfo,
  column,
  selectedTab,
}: Props) {
  const [queryParams, setQueryParams] = useQueryParams<{
    field: Field | null;
  }>();

  const { filterSerialized, featureFilter } = useFeatureSetManagementContext();

  const plateFeatures = useFetchFeaturesAsDB(
    selectedTab !== "comparisons" && isPlateBasedFeatureSetId(featureSetInfo)
      ? {
          dataset,
          featureSet: featureSetInfo.id,
          plate: featureSetInfo.plate,
          featureSetColumns: [column],
        }
      : {
          skip: true,
        },
  );

  const nonPlateBasedFeatureSet = useFetchFeaturesAsDB(
    !isPlateBasedFeatureSetId(featureSetInfo)
      ? {
          dataset,
          featureSet: featureSetInfo.id,
          plate: null,
          featureSetColumns: [column],
        }
      : {
          skip: true,
        },
  );

  const wellAggregatedFilteredFeatures = useWellAggregatedFeature(
    selectedTab === "comparisons" &&
      !featureFilter &&
      isPlateBasedFeatureSetId(featureSetInfo)
      ? {
          dataset,
          sqlFilter: filterSerialized,
          feature: `WellAggregated${featureSetInfo.name}`,
          column,
        }
      : { skip: true },
  );

  const wellAggregatedFeatureFilteredFeatures =
    useFeatureFilteredWellAggregatedFeature(
      featureFilter && isPlateBasedFeatureSetId(featureSetInfo)
        ? {
            dataset,
            feature: featureSetInfo.name,
            featureColumn: column,
            filterFeatureColumn: column,
            filterFeature: featureFilter.featureSet,
          }
        : { skip: true },
    );

  const features =
    plateFeatures ||
    nonPlateBasedFeatureSet ||
    wellAggregatedFeatureFilteredFeatures ||
    wellAggregatedFilteredFeatures;

  // Check the first row so we don't mount tabs until the db instance is up-to-date with the column selection.
  const firstRow = useQueryAsRecords(
    features?.successful ? features.value : null,
    sql`SELECT * FROM features LIMIT 1`,
  );

  const content = useMemo((): ReactElement => {
    if (!features || !firstRow?.successful) {
      return (
        <div
          className={
            "tw-min-h-[400px] tw-flex tw-flex-row tw-justify-center tw-items-center"
          }
        >
          <Loader />
        </div>
      );
    }

    if (!features.successful) {
      return <ErrorMessage error={features.error} />;
    }

    // TODO(davidsharff): the comparisons view needs to determine the level of the underlying
    // measurement for well aggregated features (to determine whether to link wells to the
    // overlays or plate viewer). Unfotunately, that means it needs to keep using the older
    // inference function that parses the feature set name.
    const level =
      selectedTab !== SingleFeatureView.Comparisons
        ? inferLevelFromFeatureSetRows(firstRow.value)
        : inferLevelFromFeatureSetName(featureSetInfo.name);

    const featuresDB = features.value;
    switch (selectedTab) {
      case SingleFeatureView.Comparisons:
        return (
          <ComparisonsView
            featuresDB={featuresDB}
            column={column}
            featureSetInfo={featureSetInfo}
            level={level}
          />
        );
      case SingleFeatureView.ImageDistribution:
        return (
          <ImageProvidersWrapper>
            <FeatureColumnDistributionsView
              featuresDB={featuresDB}
              dataset={dataset}
              featureLevel={level}
              featureSetInfo={featureSetInfo}
              column={column}
              onChangeField={(field) =>
                // TODO(davidsharff): do we still need this?
                setQueryParams({
                  ...queryParams,
                  field,
                })
              }
            />
          </ImageProvidersWrapper>
        );
      case SingleFeatureView.Overlays:
        return level === "cell" ? (
          <ImageProvidersWrapper>
            <OverlayViewer
              key={`${dataset}_${featureSetInfo.id}_${featureSetInfo.plate}`}
              dataset={dataset}
              plate={featureSetInfo.plate as string}
              featureSetColumn={column}
              featuresDB={featuresDB}
            />
          </ImageProvidersWrapper>
        ) : (
          <OverlayViewerPlaceholder
            featureName={decodeURIComponent(featureSetInfo.name)}
          />
        );
    }
  }, [
    column,
    dataset,
    featureSetInfo,
    features,
    firstRow,
    queryParams,
    selectedTab,
    setQueryParams,
  ]);

  return (
    <div className="tw-h-full tw-w-full tw-flex-1 tw-bg-white">{content}</div>
  );
}

function ImageProvidersWrapper({ children }: { children: ReactNode }) {
  return (
    <VisualizationContextProvider>{children}</VisualizationContextProvider>
  );
}

export default function WithStore(props: Props) {
  return (
    <Provider store={store}>
      <FeatureSetColumnDetails {...props} />
    </Provider>
  );
}
