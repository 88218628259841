import { ReactNode, createContext, useContext } from "react";
import { EMPTY_CHANNEL_MAP, EMPTY_DISPLAY_RANGES } from "./constants";
import { Action } from "./state/actions";
import { useDispatch, useVisualizationSettings } from "./state/hooks";
import {
  ChannelIndex,
  FetchSettings,
  ImageMetadata,
  VisualizationContextValue,
  VisualizationSettings,
} from "./types";

/**
 * Context to capture the request parameters needed to fetch from the underlying proxy.
 */
export const RequestContext = createContext<{
  hostUrl: string;
  requestInit: RequestInit;
}>({
  hostUrl: "",
  requestInit: {},
});

/**
 * Context to capture the image fetching state.
 */
export const FetchContext = createContext<FetchSettings>({
  processingMode: null,
  processingModes: null,
});

/**
 * Context to capture the image visualization state.
 */
const VisualizationContext = createContext<VisualizationContextValue>({
  visualizationSettings: {
    channelMap: EMPTY_CHANNEL_MAP,
    displayRanges: EMPTY_DISPLAY_RANGES,
    autoScale: false,
  },
  onLoadChannel: undefined,
});

/**
 * Generate an initial VisualizationContext from data in the Redux store.
 */
function fromStore(
  visualizationSettings: VisualizationSettings,
  dispatch: (action: Action) => void,
) {
  return {
    visualizationSettings,
    onLoadChannel: (imageMetadata: ImageMetadata, index: ChannelIndex) =>
      dispatch({ type: "load-channel", imageMetadata, index }),
  };
}

/**
 * Uses the VisualizationContext from data in the Redux store.
 */
function DefaultVisualizationContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const visualizationSettings = useVisualizationSettings();
  const dispatch = useDispatch();

  return (
    <VisualizationContextProvider
      value={fromStore(visualizationSettings, dispatch)}
    >
      {children}
    </VisualizationContextProvider>
  );
}

/**
 * Sets up a VisualizationContext.Provider, either using the provided value or
 * defaulting to using a value from the Redux store.
 */
export function VisualizationContextProvider({
  children,
  value,
}: {
  children: ReactNode;
  value?: VisualizationContextValue;
}) {
  if (value) {
    return (
      <VisualizationContext.Provider value={value}>
        {children}
      </VisualizationContext.Provider>
    );
  } else {
    return (
      <DefaultVisualizationContextProvider>
        {children}
      </DefaultVisualizationContextProvider>
    );
  }
}

/**
 * Gets the visualization context value
 */
export function useVisualizationContext() {
  return useContext(VisualizationContext);
}
