import React, { useCallback } from "react";
import { useEventTracker } from "src/hooks/analytics";
import { Button as UiButton } from "@spring/ui/Button";
import { ButtonProps } from "@spring/ui/Button/Button";
import { ENV } from "../env";

export const Button = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & { disableTracking?: boolean }
>(function Button({ disableTracking, onClick, ...rest }, ref) {
  const track = useEventTracker();
  const handleClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      let buttonName = rest.name ?? event.currentTarget.textContent?.trim();
      if (buttonName === undefined || buttonName === "") {
        buttonName = "unknown";
      }

      if (!disableTracking) {
        if (ENV === "development") {
          console.error(
            "Tracking click for unknown button! Either provide a name or set the disableTracking flag if you don't wish to track clicks on this button.",
          );
        }
        track({ id: "Click button", name: buttonName });
      }

      onClick?.(event);
    },
    [disableTracking, rest.name, onClick, track],
  );

  return <UiButton ref={ref} onClick={handleClick} {...rest} />;
});
