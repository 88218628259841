import { useEffect, useState } from "react";
import { hasKeys } from "src/util/has-keys";
import { Failure, Fetchable, Success } from "@spring/core/result";
import { DatasetId, MetadataColumnValue } from "../../types";
import { getUniqueValuesByColumnDB } from "../../util/dataset-util";
import { columnComparator } from "../../util/sorting";
import {
  DB,
  ValidatedSQL,
  queryDBAsRecords,
  sanitizedColumn,
  sanitizedMetadataValue,
  sanitizedTextValue,
  sql,
  sqlAnd,
  sqlOr,
} from "../../util/sql";
import {
  Choice,
  ColumnType,
  TypedColumn,
  checkColumnType,
} from "./backend-types";
import {
  BooleanCondition,
  MultiSelectCondition,
  NumberCondition,
  Operator,
  SelectCondition,
  TextCondition,
} from "./operations/filter-by";
import {
  Condition,
  Filter,
  FilterSet,
  FilterSqlClause,
  FilterUrlState,
  FilterValue,
  SelectFilter,
  isFilterType,
  isNestedFilter,
} from "./types";

// Exported only for testing.
/**
 * A sentinel used to indicate a null value in metadata columns.
 */
export const NULL_SENTINEL = "<null>";

export const EMPTY_FILTER_SET = {
  filters: [],
  // TODO(danlec): With the nested filtering it may be that OR is a more usefu
  // top level default
  operator: Operator.AND,
  ignoredFilters: [],
};

export function requiresQueryText(condition: Condition) {
  return !["is empty", "is not empty"].includes(condition);
}

export function allowsMultipleSelection(condition: SelectCondition): boolean {
  return condition === "is any of" || condition === "is none of";
}

function makeEnumChecker<T extends string>(enumClass: Record<string, string>) {
  const set = new Set<T | string>(Object.values(enumClass));
  return (value: unknown): value is T =>
    typeof value === "string" && set.has(value);
}

const isOperator = makeEnumChecker<Operator>(Operator);
const isTextCondition = makeEnumChecker<TextCondition>(TextCondition);
const isBooleanCondition = makeEnumChecker<BooleanCondition>(BooleanCondition);
const isNumberCondition = makeEnumChecker<NumberCondition>(NumberCondition);
const isSelectCondition = makeEnumChecker<SelectCondition>(SelectCondition);
const isMultiSelectCondition =
  makeEnumChecker<MultiSelectCondition>(MultiSelectCondition);

export function isActive(filter: Filter) {
  if (filter.type === "nested") {
    return filter.filterSet.filters.some(isActive);
  } else if (filter.type === "checkbox") {
    return true;
  } else if (requiresQueryText(filter.condition)) {
    if (String(filter.queryText).length === 0) {
      return false;
    }
  }

  if (filter.type === "number") {
    if (isNaN(filter.queryText as unknown as number)) {
      return false;
    }
  }

  return true;
}

export function getDefaultFilter(column: TypedColumn): Filter {
  switch (column.type) {
    case "text":
      return {
        column,
        type: column.type,
        queryText: "",
        condition: TextCondition.CONTAINS,
      };
    case "multilineText":
      return {
        column,
        type: column.type,
        queryText: "",
        condition: TextCondition.CONTAINS,
      };
    case "number":
      return {
        column,
        type: column.type,
        queryText: "",
        condition: NumberCondition.EQUALS,
      };
    case "checkbox":
      return {
        column,
        type: column.type,
        queryText: false,
        condition: BooleanCondition.IS,
      };
    case "select":
      return {
        column,
        type: column.type,
        queryText: [],
        condition: SelectCondition.IS_ANY_OF,
      };
    case "multiSelect":
      return {
        column,
        type: column.type,
        queryText: [],
        condition: MultiSelectCondition.HAS_ANY_OF,
      };
  }
}

export function getDefaultFilterColumn(
  columns: TypedColumn[],
  existingFilters: Filter[],
): TypedColumn {
  // By default, surface "Name".
  const nameColumn = columns.find(({ id }) => id === "compoundName");
  if (nameColumn) {
    return nameColumn;
  }

  // Otherwise, surface the first metadata column.
  return (
    // Prefer a column that hasn't already been used in the set
    columns.find(
      (column) =>
        !existingFilters.some(
          (filter) =>
            filter.type !== "nested" && filter.column.id === column.id,
        ),
    ) ?? columns[0]
  );
}

/**
 * @returns the {@code Choice} matching the given identifier, or undefined.
 */
export function findChoice(choices: Choice[], id: string): Choice | undefined {
  return choices.find((choice) => id === choice.id);
}

/**
 * @returns the {@code Choice} matching the given identifier.
 *
 * Throws if the identifier doesn't match any of the choices.
 */
export function toChoice(choices: Choice[], id: string): Choice {
  const choice = choices.find((choice) => id === choice.id);
  if (choice == null) {
    throw new Error(`Unable to find choice for ID: ${id}`);
  }
  return choice;
}

/**
 * Returns the filter columns for the sample metadata DB with
 * special handling to cleanup display names based on the dataset.
 */
export async function filterColumnSpecsFromDatasetMetadataDB(
  dataset: DatasetId,
  sampleMetadataDB: DB,
  prefilter?: FilterSqlClause,
): Promise<TypedColumn[]> {
  const valuePrettifier = (column: string, columnValue: MetadataColumnValue) =>
    getValuePrettifier(dataset, column)(columnValue);

  return await filterColumnSpecsFromDB(
    sampleMetadataDB,
    "sample_metadata",
    prefilter,
    valuePrettifier,
  );
}

/**
 * Returns the filter columns used by the data filter component
 * for any DB table. Includes optional handling to cleanup display
 * names based on the dataset.
 */
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export async function filterColumnSpecsFromDB(
  db: DB,
  tableName: string,
  prefilter?: FilterSqlClause,
  formatDisplayFn?: (column: string, value: MetadataColumnValue) => string,
): Promise<TypedColumn[]> {
  const valuesByColumn = await getUniqueValuesByColumnDB(
    db,
    prefilter,
    tableName,
  );
  const filteredColumns = Object.keys(valuesByColumn).filter(
    (column) => valuesByColumn[column].size > 1,
  );

  return filteredColumns.sort(columnComparator).map((column) => {
    const columnType = inferDatasetColumnType(column, valuesByColumn[column]);

    const idForSelect = (value: MetadataColumnValue) => {
      if (value === null) {
        return NULL_SENTINEL;
      } else {
        return value;
      }
    };

    const nameForSelect = (value: MetadataColumnValue) => {
      if (value === null) {
        return NULL_SENTINEL;
      } else {
        return formatDisplayFn ? formatDisplayFn(column, value) : String(value);
      }
    };
    return {
      id: column,
      name: column,
      type: columnType,

      typeOptions:
        columnType === "select"
          ? {
              choices: Array.from(valuesByColumn[column]).map((value) => ({
                id: idForSelect(value),
                name: nameForSelect(value),
                color: "blue",
              })),
            }
          : null,
    } as unknown as TypedColumn;
  });
}

/**
 * Translate a sample metadata column value to a prettified display name.
 */
function getValuePrettifier(
  dataset: DatasetId,
  column: string,
): (value: MetadataColumnValue) => string {
  if (dataset === "jump_cp-u2os-20221101" && column === "treatment_name") {
    // For the JUMP ORF dataset, the treatment values are very long gene names with
    // long descriptions, like
    // "A4GALT (alpha 1,4-galactosyltransferase (P blood group))", which makes
    // things overflow way too frequently. Just truncate to the gene name.
    return (value: MetadataColumnValue) =>
      value ? String(value).split("(")[0] : NULL_SENTINEL;
  }
  return (value: MetadataColumnValue) => String(value);
}

function inferDatasetColumnType(column: string, values: Set<any>): ColumnType {
  if (["plate", "well"].includes(column)) {
    return "select";
  }

  for (const value of values) {
    if (typeof value === "number") {
      // The number type is a free-form type with operators for =, >, etc.
      // In the most common case, though, numerical values represent a dose or
      // concentration, which are few in number and hard to remember precisely
      // (because they look like 0.012383). Therefore, bias towards a select
      // if there are only a small number of them.
      if (values.size <= 50) {
        return "select";
      } else {
        return "number";
      }
    } else if (typeof value === "boolean") {
      return "checkbox";
    }
  }
  return "select";
}

/**
 * Serialize a filter to a URI-safe value that can be used as a URL param.
 */
export function serializeToUrlParam(filterSet: FilterSet): FilterUrlState {
  const cleanFilterSet = ({ operator, filters }: FilterSet) => ({
    operator,
    filterValues: filters.map(cleanFilter),
  });

  const cleanFilter = (filter: Filter): FilterValue => {
    if (isNestedFilter(filter)) {
      const { type, filterSet } = filter;
      return {
        type,
        filterSet: cleanFilterSet(filterSet),
      };
    } else {
      const { type, column, queryText, condition } = filter;
      return {
        type,
        columnId: column.id,
        queryText,
        condition,
      } as FilterValue;
    }
  };

  return base64EncodeUnicode(JSON.stringify(cleanFilterSet(filterSet)));
}

// Wrap in a method with a return to enforce comprehensiveness of the switch
// statement by TypeScript.
function toFilter(
  maybeFilterValue: unknown,
  columns: TypedColumn[],
): Filter | null {
  if (
    !maybeFilterValue ||
    typeof maybeFilterValue !== "object" ||
    !hasKeys(maybeFilterValue, "type")
  ) {
    return null;
  }

  const filterType = maybeFilterValue.type;

  if (!isFilterType(filterType)) {
    return null;
  }

  if (filterType === "nested") {
    if (hasKeys(maybeFilterValue, "filterSet")) {
      const filterSet = maybeFilterValue.filterSet;
      if (typeof filterSet === "object") {
        const { operator, filterValues } = filterSet as {
          operator: unknown;
          filterValues: unknown;
        };

        const filters = Array.isArray(filterValues)
          ? filterValues.map((value) => toFilter(value, columns))
          : undefined;

        if (
          typeof operator === "string" &&
          isOperator(operator) &&
          filters &&
          filters.every((filter): filter is Filter => filter !== null)
        ) {
          return {
            type: "nested",
            filterSet: {
              operator,
              filters,
              ignoredFilters: [],
            },
          };
        }
      }
    }
    return null;
  }

  if (
    !("type" in maybeFilterValue) ||
    !("columnId" in maybeFilterValue) ||
    !("queryText" in maybeFilterValue) ||
    !("condition" in maybeFilterValue)
  ) {
    return null;
  }

  const { columnId } = maybeFilterValue;
  const column = columns.find(({ id }) => id === columnId);
  if (!column) {
    return null;
  }

  const { type, queryText, condition } = maybeFilterValue;
  if (typeof condition !== "string") {
    return null;
  }

  switch (type) {
    case "text": {
      if (
        isTextCondition(condition) &&
        typeof queryText === "string" &&
        checkColumnType(column, "text")
      ) {
        return { type, column, condition, queryText };
      }
      break;
    }
    case "multilineText": {
      if (
        isTextCondition(condition) &&
        typeof queryText === "string" &&
        checkColumnType(column, "multilineText")
      ) {
        return { type, column, condition, queryText };
      }
      break;
    }
    case "number": {
      if (
        isNumberCondition(condition) &&
        // Note: number filters are still typed as strings because we use an input
        // box in the UI for them? *shrug
        typeof queryText === "string" &&
        checkColumnType(column, "number")
      ) {
        return { type, column, condition, queryText };
      }
      break;
    }
    case "checkbox": {
      if (
        isBooleanCondition(condition) &&
        typeof queryText === "boolean" &&
        checkColumnType(column, "checkbox")
      ) {
        return { type, column, condition, queryText };
      }
      break;
    }
    case "select": {
      if (
        isSelectCondition(condition) &&
        (typeof queryText === "string" ||
          typeof queryText === "number" ||
          isArrayOfStringsOrNumbers(queryText)) &&
        checkColumnType(column, "select")
      ) {
        return { type, column, condition, queryText };
      }
      break;
    }
    case "multiSelect": {
      if (
        isMultiSelectCondition(condition) &&
        isArrayOfStringsOrNumbers(queryText) &&
        checkColumnType(column, "multiSelect")
      ) {
        return { type, column, condition, queryText };
      }
    }
  }

  return null;
}

/**
 * Deserialize a value serialized by serializeToUrlParam.
 *
 * If value is corrupt or invalid, returns null.
 *
 * @see serializeToUrlParam
 */
export function deserializeFromUrlParam(
  state: FilterUrlState,
  columns: TypedColumn[],
): FilterSet | null {
  let decoded;
  try {
    decoded = JSON.parse(base64DecodeUnicode(state));
  } catch (err) {
    return null;
  }
  if (!hasKeys(decoded, "operator", "filterValues")) {
    return null;
  }
  const { operator: maybeOperator, filterValues: maybeFilterValues } = decoded;
  if (!isOperator(maybeOperator) || !Array.isArray(maybeFilterValues)) {
    return null;
  }

  const filters: Filter[] = [];
  for (const maybeFilterValue of maybeFilterValues) {
    const filterValue = toFilter(maybeFilterValue, columns);

    if (!filterValue) {
      return null;
    }

    filters.push(filterValue);
  }

  switch (maybeOperator) {
    case Operator.AND:
      return {
        operator: maybeOperator,
        filters,
        ignoredFilters: [],
      };
    case Operator.OR:
      return {
        operator: maybeOperator,
        filters,
      };
  }
}

export function updateOperator(
  existing: FilterSet,
  operator: Operator,
  newFilters: Filter[] = [],
): FilterSet {
  switch (operator) {
    case Operator.AND:
      if (existing.operator === Operator.OR) {
        return {
          operator,
          filters: [...existing.filters, ...newFilters],
          ignoredFilters: [],
        };
      } else {
        return { ...existing, filters: [...existing.filters, ...newFilters] };
      }
    case Operator.OR:
      if (existing.operator === Operator.AND) {
        return {
          operator,
          filters: [
            ...existing.filters,
            ...existing.ignoredFilters,
            ...newFilters,
          ],
        };
      } else {
        return { ...existing, filters: [...existing.filters, ...newFilters] };
      }
  }
}

export function updateFilters(
  existing: FilterSet,
  filters: Filter[],
): FilterSet {
  const { operator } = existing;
  switch (operator) {
    case Operator.AND:
      return { operator, filters, ignoredFilters: [] };
    case Operator.OR:
      return { operator, filters };
  }
}

function isArrayOfStringsOrNumbers(value: any): value is (string | number)[] {
  return (
    Array.isArray(value) &&
    value.every(
      (value) => typeof value === "string" || typeof value === "number",
    )
  );
}

export function serializeToSqlClause(
  filterSet: FilterSet,
): FilterSqlClause & ValidatedSQL {
  const { operator } = filterSet;
  const filters = filterSet.filters.filter(isActive);

  if (filters.length == 0) {
    return sql`TRUE`;
  } else if (filters.length == 1) {
    return serializeToIndividualSqlClause(filters[0]);
  } else if (operator === "Or") {
    return sqlOr(
      filters
        .map(serializeToIndividualSqlClause)
        .map((clause) => sql`(${clause})`),
    );
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (operator === "And") {
    return sqlAnd(
      filters
        .map(serializeToIndividualSqlClause)
        .map((clause) => sql`(${clause})`),
    );
  } else {
    throw new Error("Unexpected operator");
  }
}

export function serializeToIndividualSqlClause(filter: Filter): ValidatedSQL {
  switch (filter.type) {
    case "nested":
      return serializeToSqlClause(filter.filterSet);
    case "checkbox":
      if (filter.queryText) {
        return sql`${sanitizedFilterColumn(filter)} = TRUE`;
      } else {
        return sql`${sanitizedFilterColumn(filter)} = FALSE`;
      }

    case "text":
    case "multilineText": {
      switch (filter.condition) {
        case TextCondition.CONTAINS:
          return sql`${sanitizedFilterColumn(
            filter,
          )} LIKE '%' || ${sanitizedTextValue(filter.queryText)} || '%'`;

        case TextCondition.DOES_NOT_CONTAIN:
          return sql`${coalesceNulls(
            sanitizedFilterColumn(filter),
          )} NOT LIKE '%' || ${sanitizedTextValue(filter.queryText)} || '%'`;

        case TextCondition.IS:
          return sql`${sanitizedFilterColumn(filter)} = ${sanitizedTextValue(
            filter.queryText,
          )}`;

        case TextCondition.IS_NOT:
          return sql`${coalesceNulls(
            sanitizedFilterColumn(filter),
          )} != ${sanitizedTextValue(filter.queryText)}`;

        case TextCondition.IS_EMPTY:
          return sqlOr([
            sql`${sanitizedFilterColumn(filter)} == ''`,
            sql`${sanitizedFilterColumn(filter)} IS NULL`,
          ]);

        case TextCondition.IS_NOT_EMPTY:
          return sqlAnd([
            sql`${sanitizedFilterColumn(filter)} != ''`,
            sql`${sanitizedFilterColumn(filter)} IS NOT NULL`,
          ]);
      }
    }

    case "number": {
      // Note: filter.queryText is typed as a `string`, even though semantically
      // it's expected to be a number. We coerce it here.
      const asNumber = Number(filter.queryText);
      if (isNaN(asNumber)) {
        return sql`FALSE`;
      }

      switch (filter.condition) {
        case NumberCondition.EQUALS:
          return sql`${sanitizedFilterColumn(filter)} = ${asNumber}`;

        case NumberCondition.NOT_EQUALS:
          return sql`${sanitizedFilterColumn(filter)} != ${asNumber}`;

        case NumberCondition.LESS_THAN:
          return sql`${sanitizedFilterColumn(filter)} < ${asNumber}`;

        case NumberCondition.GREATER_THAN:
          return sql`${sanitizedFilterColumn(filter)} > ${asNumber}`;

        case NumberCondition.LESS_THAN_OR_EQUALS:
          return sql`${sanitizedFilterColumn(filter)} <= ${asNumber}`;

        case NumberCondition.GREATER_THAN_OR_EQUALS:
          return sql`${sanitizedFilterColumn(filter)} >= ${asNumber}`;

        case NumberCondition.IS_EMPTY:
          return sql`${sanitizedFilterColumn(filter)} IS NULL`;

        case NumberCondition.IS_NOT_EMPTY:
          return sql`${sanitizedFilterColumn(filter)} IS NOT NULL`;
      }
    }

    case "select": {
      switch (filter.condition) {
        case SelectCondition.IS:
          if (filter.queryText === NULL_SENTINEL) {
            return sql`${sanitizedFilterColumn(filter)} IS NULL`;
          } else {
            return sql`${sanitizedFilterColumn(
              filter,
            )} = ${sanitizedTextOrNullSentinel(
              filter.queryText as string | number,
            )}`;
          }

        case SelectCondition.IS_NOT:
          if (filter.queryText === NULL_SENTINEL) {
            return sql`${sanitizedFilterColumn(filter)} IS NOT NULL`;
          } else {
            return sql`${coalesceNulls(
              sanitizedFilterColumn(filter),
            )} != ${sanitizedTextOrNullSentinel(
              filter.queryText as string | number,
            )}`;
          }

        case SelectCondition.IS_ANY_OF:
          return sanitizedInClause(
            sanitizedFilterColumn(filter),
            filter.queryText as (string | number)[],
          );

        case SelectCondition.IS_NONE_OF:
          return sanitizedNotInClause(
            sanitizedFilterColumn(filter),
            filter.queryText as (string | number)[],
          );

        case SelectCondition.IS_EMPTY:
          return sql`(${[
            `${sanitizedFilterColumn(filter)} == ''`,
            `${sanitizedFilterColumn(filter)} IS NULL`,
          ].join(" OR ")})`;

        case SelectCondition.IS_NOT_EMPTY:
          return sql`(${[
            `${sanitizedFilterColumn(filter)} != ''`,
            `${sanitizedFilterColumn(filter)} IS NOT NULL`,
          ].join(" AND ")})`;
      }
    }

    case "multiSelect": {
      // TODO(benkomalo): we never actually support multiSelecct. This was useful
      // in the MegaMap where certain metadata columns could be repeated, but we have
      // no such structured convention for Dataset metadata yet.
      throw new Error("Unimplemented");
    }
  }
}

function coalesceNulls(column: string) {
  return sql`COALESCE(${column}, '')`;
}

function sanitizedFilterColumn(
  filter: Filter & { column: TypedColumn },
): string {
  return `${sanitizedColumn(filter.column.id)}`;
}

function sanitizedTextOrNullSentinel(value: string | number): string {
  if (value === NULL_SENTINEL) {
    return "NULL";
  } else {
    return sanitizedMetadataValue(value);
  }
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function sanitizedInClause(
  column: string,
  values: (string | number)[],
): ValidatedSQL {
  const hasNulls = values.includes(NULL_SENTINEL);
  const hasNonNulls = values.some((v) => v !== NULL_SENTINEL);

  const nonNullClause = sql`${column} IN (${values
    .filter((v) => v !== NULL_SENTINEL)
    .map((v) => `${sanitizedTextOrNullSentinel(v)}`)
    .join(",")})`;

  if (hasNulls && hasNonNulls) {
    return sql`(${nonNullClause} OR ${column} IS NULL)`;
  } else if (hasNulls && !hasNonNulls) {
    return sql`${column} IS NULL`;
  } else if (!hasNulls && hasNonNulls) {
    return nonNullClause;
  } else {
    throw new Error("No values in clause");
  }
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function sanitizedNotInClause(
  column: string,
  values: (string | number)[],
): ValidatedSQL {
  const hasNulls = values.includes(NULL_SENTINEL);
  const hasNonNulls = values.some((v) => v !== NULL_SENTINEL);

  const nonNullClause = sql`${coalesceNulls(column)} NOT IN (${values
    .filter((v) => v !== NULL_SENTINEL)
    .map((v) => `${sanitizedTextOrNullSentinel(v)}`)
    .join(",")})`;

  if (hasNulls && hasNonNulls) {
    return sql`(${nonNullClause} AND ${column} IS NOT NULL)`;
  } else if (hasNulls && !hasNonNulls) {
    return sql`${column} IS NOT NULL`;
  } else if (!hasNulls && hasNonNulls) {
    return nonNullClause;
  } else {
    throw new Error("No values in clause");
  }
}

export async function validateFilterFetchable(
  metadataDB: Fetchable<DB>,
  filter: FilterSet,
): Promise<FilterSet> {
  if (!metadataDB?.successful) {
    return filter;
  }
  return validateFilter(metadataDB.value, filter);
}

export async function validateFilter(
  metadataDB: DB,
  filter: FilterSet,
  tableName = "sample_metadata",
): Promise<FilterSet> {
  if (filter.operator === Operator.OR) {
    return filter;
  }

  // TODO(danlec): With the nested filtering it may be that an OR filter may be more
  // useful at the top level

  const { filters } = filter;
  const subFilters: Filter[] = [];
  for (let i = 0; i < filters.length; i++) {
    const filter = filters[i];
    if (!isActive(filter)) {
      continue;
    }
    subFilters.push(filter);
    const serialized = serializeToSqlClause({
      filters: subFilters,
      operator: Operator.AND,
      ignoredFilters: [],
    });
    const query = sql`SELECT COUNT(1) AS n FROM ${tableName} WHERE ${serialized}`;
    const results = await queryDBAsRecords<{ n: bigint }>(metadataDB, query);
    if (results[0].n === 0n) {
      const accepted = filters.slice(0, i);
      const ignored = filters.slice(i);
      return {
        operator: Operator.AND,
        filters: accepted,
        ignoredFilters: ignored,
      };
    }
  }
  return {
    operator: Operator.AND,
    filters,
    ignoredFilters: [],
  };
}

/**
 * Returns data filter columns definitions with handling
 * specific to sample_metadata for the given dataset.
 */
export function useMetadataFilterColumns(
  dataset: DatasetId,
  metadata: Fetchable<DB>,
  plate: string | null,
): Fetchable<TypedColumn[]> {
  const [results, setResults] = useState<Fetchable<TypedColumn[]> | undefined>(
    undefined,
  );
  const prefilter = plate ? `plate = '${plate}'` : "TRUE";

  useEffect(() => {
    if (metadata) {
      if (metadata.successful) {
        filterColumnSpecsFromDatasetMetadataDB(
          dataset,
          metadata.value,
          prefilter,
        ).then(
          (columns) => setResults(Success.of(columns)),
          (err) => setResults(Failure.of(err)),
        );
      } else {
        setResults(Failure.of(metadata.error));
      }
    }
  }, [dataset, metadata, setResults, prefilter]);

  return results;
}
/**
 * Returns data filter columns for any db/table.
 */
export function useFilterColumns(db: DB, tableName: string) {
  const [filterColumns, setFilterColumns] = useState<TypedColumn[] | null>(
    null,
  );
  useEffect(() => {
    filterColumnSpecsFromDB(db, tableName).then((columns) => {
      setFilterColumns(columns);
    });
  }, [db, tableName]);

  return filterColumns;
}

/**
 * Encodes a UTF-8 string into a Base64 string.
 * This function is an alternative to btoa() for strings containing characters outside the Latin1 range.
 *
 * @param {string} str - The input string to encode.
 * @returns {string} - The Base64-encoded output string.
 */
function base64EncodeUnicode(str: string) {
  const encoder = new TextEncoder();

  // Use the TextEncoder instance to encode the input string to a Uint8Array
  const data = encoder.encode(str);

  // Convert the encoded data to a string with fromCharCode (which
  // will only use Latin1 chars) allowing btoa to safely convert to
  // Base64.

  const base64 = btoa(String.fromCharCode(...data));

  return base64;
}

/**
 * Decodes a Base64 string into a UTF-8 string.
 * This function is an alternative to atob() for strings containing characters outside the Latin1 range.
 *
 * @param {string} str - The Base64-encoded input string to decode.
 * @returns {string} - The decoded output string.
 */
function base64DecodeUnicode(str: string) {
  // Decode the Base64 string to a binary string with atob
  const data = atob(str);

  const decoder = new TextDecoder();

  // Convert the binary string to an array of character codes, then to a Uint8Array,
  // and finally decode it back to a string with the TextDecoder instance
  const original = decoder.decode(
    new Uint8Array(data.split("").map((char) => char.charCodeAt(0))),
  );

  return original;
}

/**
 * Create an "is any of" filter for the given metadata column and values.
 *
 * @param column
 * @param values
 * @returns - A Filter object to be included in a FilterSet.
 */
export function createAnyOfFilter<T extends SelectFilter["queryText"]>(
  column: string,
  values: T,
): SelectFilter<T> {
  return {
    column: {
      id: column,
      name: column,
      type: "select",
      typeOptions: {
        choices: [],
      },
    },
    type: "select",
    queryText: values,
    condition: SelectCondition.IS_ANY_OF,
  };
}

/**
 * Create a filter for the given column and value, inferring the type.
 *
 * @param column
 * @param value
 * @returns - A Filter object to be included in a FilterSet.
 */
export function createFilterForType(
  column: string,
  value: string,
): SelectFilter<string[]>;
export function createFilterForType(
  column: string,
  value: MetadataColumnValue,
): Filter;
export function createFilterForType(
  column: string,
  value: MetadataColumnValue,
) {
  switch (typeof value) {
    case "number":
      return createNumberFilter(column, value, NumberCondition.EQUALS);
    case "boolean":
      return createCheckboxFilter(column, value);
    default:
      // TODO(davidsharff): we could handle strings explicitly and add an ensureExhaustive(): never in the
      // default like Phenosorter.
      return createAnyOfFilter(column, [`${value}`]);
  }
}

/**
 * Create a numeric filter for the given column, value and condition.
 *
 * @param column
 * @param value
 * @param condition
 * @returns - A Filter object to be included in a FilterSet.
 */
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function createNumberFilter(
  column: string,
  value: number,
  condition: NumberCondition,
): Filter {
  return {
    type: "number",
    column: {
      id: column,
      name: column,
      type: "number",
      typeOptions: {},
    },
    queryText: `${value}`,
    condition,
  };
}

/**
 * Create a checkbox filter for the given column and bool value
 *
 * @param column
 * @param value
 * @returns - A Filter object to be included in a FilterSet.
 */
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function createCheckboxFilter(column: string, value: boolean): Filter {
  return {
    type: "checkbox",
    column: {
      id: column,
      name: column,
      type: "checkbox",
      typeOptions: {},
    },
    queryText: value,
    condition: BooleanCondition.IS,
  };
}

/**
 * Combine multiple filters into a single FilterSet.
 *
 * @param filters - An array of filters to be combined.
 * @param operator - The operator (AND/OR) to evaluate the combination of filters.
 * @returns - A FilterSet object containing the combined filters and the specified operator.
 */
export function createFilterSet<T extends Filter>(
  filters: T[],
  operator: Operator,
): FilterSet<T> {
  return {
    filters,
    operator,
    ignoredFilters: [],
  };
}

function filtersFromFilterSet(filterSet: FilterSet): {
  filter: Filter;
  ignored: boolean;
  operator: Operator;
  isSubClause: boolean;
}[] {
  return [
    ...filterSet.filters.map((filter) => ({ filter, ignored: false })),
    ...(filterSet.operator === Operator.AND
      ? filterSet.ignoredFilters
      : []
    ).map((filter) => ({ filter, ignored: true })),
  ].flatMap(
    ({
      filter,
      ignored,
    }): {
      filter: Filter;
      ignored: boolean;
      operator: Operator;
      isSubClause: boolean;
    }[] => {
      if (isNestedFilter(filter)) {
        return [
          { filter, ignored, operator: filterSet.operator, isSubClause: false },
          ...filtersFromFilterSet(filter.filterSet).map(
            ({ filter: subFilter }) => ({
              filter: subFilter,
              ignored,
              operator: filter.filterSet.operator,
              isSubClause: true,
            }),
          ),
        ];
      } else {
        return [
          { filter, ignored, operator: filterSet.operator, isSubClause: false },
        ];
      }
    },
  );
}

export function clausesFromFilterSet(filterSet: FilterSet): {
  clause: ValidatedSQL | undefined;
  ignored: boolean;
  operator: Operator;
  isSubClause: boolean;
}[] {
  return filtersFromFilterSet(filterSet).map(
    ({ filter, ignored, operator, isSubClause }) => ({
      clause: isActive(filter)
        ? serializeToIndividualSqlClause(filter)
        : undefined,
      ignored,
      operator,
      isSubClause,
    }),
  );
}
