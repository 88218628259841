import cx from "classnames";
import sampleSize from "lodash.samplesize";
import pluralize from "pluralize";
import { useCallback, useMemo } from "react";
import { Maximize2, Plus, X } from "react-feather";
import { Tooltip } from "@spring/ui/Tooltip";
import { DeprecatedButton } from "../../Common/DeprecatedButton";
import { ClassMenu } from "../ClassMenu";
import { Classification, useLabeledSetContext } from "../Context";
import { MagnifyingTwinkle } from "../MagnifyingTwinkle";
import {
  CLASS_CARD_TOP_N,
  CLASS_CARD_TO_FIND_SIMILAR,
  MIN_PREDICTION_CONFIDENCE,
  REQUIRED_SAMPLES,
} from "../constants";
import { useOnAddToClass } from "../hooks";
import { sortSamples } from "../sortSamples";
import { findSimilar, removeSamples, sortQueue } from "../util";

type ClassHeaderProps = React.HTMLProps<HTMLDivElement> & {
  className?: string;
  classification: Classification;
  isExpanded: boolean;
  onToggleExpanded: () => void;
  onSelectPreviousClassification?: () => void;
  onSelectNextClassification?: () => void;
};

export function ClassHeader({
  className,
  classification,
  isExpanded,
  onToggleExpanded,
}: ClassHeaderProps) {
  const { state: labeledSetState, updateState: updateLabeledSetState } =
    useLabeledSetContext();
  const { queue, neighbors, selected, skipped, predictions } = labeledSetState;
  const { examples } = classification;

  const { onAddToClass } = useOnAddToClass();
  const handleAddToClass = () => onAddToClass(classification.name);

  const isFindSimilarCellsEnabled = examples.length > 0;
  const hasSelectedCells = selected.length > 0;

  const sorted = useMemo(() => {
    if (neighbors.size == 0) {
      return [];
    }
    return sortSamples(examples, neighbors);
  }, [examples, neighbors]);

  const onFindSimilarCells = useCallback(() => {
    // There's a chance our find similar doesn't return any values in which case we
    // automatically clear the displayed area and show new unrelated cells. Perhaps
    // we need a message here in that case?
    const sampled = sampleSize(
      sorted.slice(0, CLASS_CARD_TOP_N),
      CLASS_CARD_TO_FIND_SIMILAR,
    );
    const candidates = [...queue, ...skipped];

    const similarCells = findSimilar(
      [...selected, ...sampled],
      candidates,
      neighbors,
      predictions,
      classification.name,
    );
    const cellsScoredHighly: boolean =
      predictions === null ||
      similarCells.some((cell) => {
        const maybePrediction = predictions
          .get(cell.id)
          ?.predictions.get(classification.name);
        return (
          maybePrediction !== undefined &&
          maybePrediction > MIN_PREDICTION_CONFIDENCE
        );
      });

    updateLabeledSetState((labeledSetState) => {
      const newDisplayed = [...selected, ...similarCells];

      const newQueue = !cellsScoredHighly
        ? []
        : sortQueue(
            removeSamples(removeSamples(queue, newDisplayed), skipped),
            neighbors,
          );
      const newSkipped = removeSamples(skipped, newDisplayed);

      return {
        ...labeledSetState,
        displayed: newDisplayed,
        skipped: newSkipped,
        queue: newQueue,
      };
    });
  }, [
    sorted,
    selected,
    neighbors,
    predictions,
    classification.name,
    queue,
    skipped,
    updateLabeledSetState,
  ]);

  return (
    <div className={cx(className)}>
      <div className="tw-flex tw-justify-between tw-items-center">
        <button
          className={cx(
            "tw-flex-1 tw-mb-0 tw-font-bold tw-text-gray-700 tw-text-left tw-truncate",
            isExpanded ? "tw-text-3xl" : "tw-text-2xl",
          )}
          onClick={onToggleExpanded}
        >
          {classification.name}
        </button>

        <div className="tw-flex-none tw-flex tw-items-center">
          <ClassMenu
            className="tw-mr-2 tw-text-gray-400 hover:tw-text-gray-700 tw-transition-colors"
            name={classification.name}
          />

          <DeprecatedButton
            variant="transparent"
            onClick={onToggleExpanded}
            borderless
          >
            {isExpanded ? <X size={16} /> : <Maximize2 size={16} />}
          </DeprecatedButton>
        </div>
      </div>

      <hr className="tw-flex-none tw-my-3 tw-border-gray-300" />

      <div className="tw-text-sm tw-my-4">
        {examples.length < REQUIRED_SAMPLES ? (
          <>
            Label{" "}
            {pluralize("more cell", REQUIRED_SAMPLES - examples.length, true)}{" "}
            to enable model training.
          </>
        ) : classification.accuracy ? (
          ""
        ) : (
          "Ready for model training."
        )}
      </div>

      <div className="tw-flex-none tw-flex tw-justify-between">
        <Tooltip
          enabled={!hasSelectedCells}
          contents="Select cells to add them to this class."
          side="bottom"
          asChild
        >
          <DeprecatedButton
            variant="primary"
            onClick={handleAddToClass}
            disabled={!hasSelectedCells}
            borderless
          >
            <Plus size={10} className="tw-mr-1" />{" "}
            <span>Add Cells to Class</span>
          </DeprecatedButton>
        </Tooltip>

        <Tooltip
          enabled={!isFindSimilarCellsEnabled}
          contents="Add cells to this class to find similar cells."
          side="bottom"
          asChild
        >
          <DeprecatedButton
            variant="primary"
            className="tw-group"
            onClick={onFindSimilarCells}
            disabled={!isFindSimilarCellsEnabled}
            borderless
          >
            <MagnifyingTwinkle
              className={cx(
                "tw-h-[24px] -tw-mt-2 tw-mr-1",
                isFindSimilarCellsEnabled ? "tw-opacity-100" : "tw-opacity-25",
              )}
              animate={isFindSimilarCellsEnabled ? "hover" : "off"}
              mode="corner"
            />
            <span>Find Similar Cells</span>
          </DeprecatedButton>
        </Tooltip>
      </div>

      <hr className="tw-flex-none tw-my-3 tw-border-gray-300" />

      <button
        className={cx(
          "tw-flex-none tw-w-full tw-flex tw-justify-between tw-my-2",
          "tw-text-gray-400 tw-text-sm",
          // You can only click this section to expand, not to collapse
          { "tw-cursor-pointer": !isExpanded },
        )}
        onClick={isExpanded ? undefined : onToggleExpanded}
        disabled={isExpanded}
      >
        <div>{examples.length} cells labeled</div>

        {classification.accuracy && (
          <Tooltip
            className="tw-w-64"
            contents={
              <p>
                Your trained model was able to correctly predict{" "}
                {(100 * classification.accuracy).toFixed(1)}% of these examples
                as “{classification.name}”.
              </p>
            }
            side="left"
            asChild
          >
            <div className="tw-cursor-help">
              {(100 * classification.accuracy).toFixed(1)}% accurate
            </div>
          </Tooltip>
        )}
      </button>
    </div>
  );
}
