import * as Sentry from "@sentry/react";
import { css } from "aphrodite";
import classNames from "classnames";
import { ReactNode, useEffect } from "react";
import { ERROR_MONITORING_ENABLED } from "../error-monitoring";
import { typography } from "../util/styles";

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function ErrorMessage({
  error,
  reportToSentry: _reportToSentry,
}: {
  error: Error;
  // Whether to send this error to sentry. Default: true
  reportToSentry?: boolean | undefined;
}) {
  const reportToSentry = _reportToSentry ?? true;
  useEffect(() => {
    if (reportToSentry && ERROR_MONITORING_ENABLED) {
      Sentry.captureException(error);
    }
  }, [error, reportToSentry]);
  return (
    <div className={classNames("tw-p-4 tw-flex tw-flex-col")}>
      <div className={classNames("tw-pb-2", css(typography.bold))}>
        Encountered an error.
      </div>
      <div>
        Please try refreshing the page. If that doesn't solve the issue, please
        let us know and include the following information:
      </div>
      <div>
        <pre className={"tw-whitespace-pre-wrap"}>{error.toString()}</pre>
      </div>
    </div>
  );
}

export default function ErrorBoundary({ children }: { children: ReactNode }) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => (
        <ErrorMessage error={error} reportToSentry={false} />
      )}
      showDialog={false}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
