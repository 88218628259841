import type { AccessToken } from "src/Auth0/accessToken";
import {
  CellSampleMetadata,
  DatasetId,
  FieldSampleMetadata,
  LabeledCellSampleMetadata,
  UnlabeledCellSampleMetadata,
  WorkspaceId,
} from "src/types";
import { examplesApi } from "src/util/api-client";
import { Workspace } from "../Workspace/types";
import { Field, PlateStains } from "../imaging/types";
import {
  Classification,
  LabeledSet,
  NeighborsMap,
  PredictionsMap,
} from "./Context";
import {
  IN_DEGREE_THRESHOLD,
  MAX_NEIGHBOR_LEVELS,
  SAMPLE_BATCH_SIZE,
} from "./constants";
import { SampleState } from "./types";

/**
 * Ensure that we've exhaustively checked cases in a union.
 *
 * By specifying the argument of this function as `never` and calling it in the
 * `else` clause of checking a union, we'll get a type error if there are any
 * cases remaining we haven't already checked. (Likewise, if the types are
 * wrong, we'll end up throwing a runtime error immediately.)
 */
function ensureExhaustive(arg: never): never {
  throw new Error(`Found unhandleable type ${arg} for sample metadata.`);
}

export function addIdToMetadata<T extends CellSampleMetadata>(
  metadata: T,
): T & { id: string } {
  return {
    id: keyForSample(metadata),
    ...metadata,
  };
}

export function removeSamples<T extends UnlabeledCellSampleMetadata>(
  list: T[],
  remove: UnlabeledCellSampleMetadata[],
): T[] {
  const toRemove = new Set(remove.map(({ id }) => id));
  return list.filter((entry) => !toRemove.has(entry.id));
}

/**
 * Function to convert a palette map from being keyed on the plate to instead being
 * keyed on the stain.
 */
export function reformatPalettes(paletteBlob: PlateStains): {
  [meta: string]: string[];
} {
  const stainToPlates: { [meta: string]: string[] } = {};
  Object.entries(paletteBlob).forEach(([plate, palette]) => {
    for (const stain of palette) {
      // If our stain is already in the map.
      // TODO(you): Fix this no-unnecessary-condition rule violation
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (stainToPlates[stain]) {
        stainToPlates[stain].push(plate);
      } else {
        stainToPlates[stain] = new Array(plate);
      }
    }
  });
  return stainToPlates;
}

/**
 * Provided some output from ReformatPalettes this will return a list of plates that
 * have all of the stains specified in the argument palette.
 */
export function getPlatesWithStains(
  platePaletteBlob: { [meta: string]: string[] },
  palette: string[],
): string[] {
  const candidatePlates: string[][] = [];
  // If for some reason we call with no palette then return an empty list.
  if (palette.length == 0) {
    return [];
  }
  for (const stain of palette) {
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (platePaletteBlob[stain]) {
      candidatePlates.push(platePaletteBlob[stain]);
    } else {
      // Push an empty array such that we get no matches.
      candidatePlates.push([]);
    }
  }
  const [first, ...rest] = candidatePlates;

  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return first
    ? rest.reduce(
        (intersection, other) => intersection.filter((v) => other.includes(v)),
        first,
      )
    : [];
}

export function groupClassifications(
  labels: LabeledCellSampleMetadata[],
  classNames: string[],
  accuracies: number[],
): Classification[] {
  const map: Map<string, LabeledCellSampleMetadata[]> = new Map();
  labels.forEach((metadata) => {
    const list = map.get(metadata.class);
    if (list) {
      list.push(metadata);
    } else {
      map.set(metadata.class, [metadata]);
    }
  });
  // If we haven't added all classes to the classifications then add an empty one.
  classNames.forEach((className) => {
    const list = map.get(className);
    if (!list) {
      map.set(className, []);
    }
  });
  const accMap = new Map(
    classNames.map((name, index) => [name, accuracies[index]]),
  );
  const classificationArray = Array.from(map.entries()).map(
    ([name, examples]): Classification => ({
      name: name,
      examples: examples,
      accuracy: accMap.get(name),
    }),
  );
  return classificationArray;
}

export function augmentClassificationsWithModelMetrics(
  classifications: Classification[],
  { precisions, recalls }: { precisions: number[]; recalls: number[] },
): Classification[] {
  return classifications.map((classification, i) => ({
    ...classification,
    precision: precisions[i],
    recall: recalls[i],
  }));
}

export function upsertLabeledSet(
  maybeList: LabeledSet[] | undefined,
  id: string,
  update: LabeledSet | ((existing: LabeledSet | undefined) => LabeledSet),
): LabeledSet[] {
  const list = maybeList ?? [];
  const found = list.find((labeledSet) => labeledSet.id === id);
  const updated = typeof update === "function" ? update(found) : update;

  if (found) {
    return list.map((entry) => (entry.id === id ? updated : entry));
  } else {
    return [...list, updated];
  }
}

/**
 * Return a unique key for a given sample.
 */
export function keyForSample<
  T extends CellSampleMetadata | FieldSampleMetadata,
>({ type, ...metadata }: T): string {
  let keys: string[];
  if (type === "field") {
    const myKeys: Array<keyof FieldSampleMetadata> = ["plate", "well", "field"];
    keys = myKeys;
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (type === "cell") {
    const myKeys: Array<keyof CellSampleMetadata> = [
      "plate",
      "well",
      "field",
      "row",
      "column",
    ];
    keys = myKeys;
  } else {
    ensureExhaustive(type);
  }
  return Object.entries(metadata)
    .filter((pair) => keys.includes(pair[0]))
    .sort((a, b) => b[0].localeCompare(a[0]))
    .map(([, value]) => value)
    .join("_");
}

export function withoutSampleState<T>({
  selected,
  ...rest
}: T & SampleState): T;
export function withoutSampleState<T>({ selected, ...rest }: T & SampleState) {
  return rest;
}

/**
 * Finds cells that we think might look visually similar to a list of example cells.
 * When we fetch examples, the server also gives us information about a given cells
 * neighbors and inDegree score.
 *
 * To find images that are similar to the provided examples, we first start by looking
 * at the neighbors to the examples and then possibly grow the list of images we
 * consider by including neighbors of neighbors until we have enough images that we
 * could return the requested count of images.  (We limit the number of hops we can
 * make, so it's possible that we just won't have enough)
 *
 * Once we have enough nearby images, we sort them to prefer one that are fewer hops
 * away from the initial examples (so e.g. we sort immediate neighbors first, neighbors
 * of neighbors next, etc) and then by the inDegreeScore.  We return at most the
 * requested count of images
 *
 * TODO (michaelwiest) expand the ability to use a trained model when there are a
 * collection of examples provided. A doable implementation is to compute a synthetic
 * class score array by weight the class scores for each example by their indegree.
 *
 * @param examples Examples of the kinds of cells that we're looking for
 * @param candidates Cells that we're searching to find ones similar to the examples
 * @param neighbors The neighbor and inDegree score mapping
 * @param predictionsData A map with the predicted class scores per cell-id
 * @param targetClass If specified, the class name to find similar samples to.
 * @param count The number of cells we'd like to get back
 * @param maxHops Starting with the neighbors of the examples, the number of times we
 * can expand our search to include neighbors of the cells we were considering previously
 * @returns At most "count" cells from the list of candidates that look similar to the
 * provided examples
 */
export function findSimilar(
  examples: UnlabeledCellSampleMetadata[],
  candidates: UnlabeledCellSampleMetadata[],
  neighbors: NeighborsMap,
  predictionsData: PredictionsMap | null = null,
  targetClass: string | null = null,
  count: number = SAMPLE_BATCH_SIZE,
  maxHops: number = MAX_NEIGHBOR_LEVELS,
): UnlabeledCellSampleMetadata[] {
  let considered = examples.map(({ id }) => id);

  const exampleIds = new Set(examples.map(({ id }) => id));
  const idToCandidate = new Map<
    UnlabeledCellSampleMetadata["id"],
    UnlabeledCellSampleMetadata
  >(
    candidates
      // We shouldn't be given candidates that are also examples, but filter
      // them out anyway
      .filter((candidate) => !exampleIds.has(candidate.id))
      .map((candidate) => [candidate.id, candidate]),
  );

  let eligible: UnlabeledCellSampleMetadata[] = [];

  const hopCount: Map<UnlabeledCellSampleMetadata["id"], number> = new Map(
    considered.map((id) => [id, 0]),
  );
  // Edge-case where the user may have made a new class before training a model.
  const classNameHasPredictions =
    targetClass !== null
      ? predictionsData?.get(candidates[0].id)?.predictions.has(targetClass)
      : false;
  // If we have a predicted class to find then use that.
  if (predictionsData && targetClass && classNameHasPredictions) {
    eligible = candidates
      .filter((candidate) => {
        if (predictionsData.has(candidate.id) && neighbors.has(candidate.id)) {
          return predictionsData.has(candidate.id);
        }
      })
      .sort(
        (a, b) =>
          predictionsData.get(b.id)!.predictions.get(targetClass)! -
            predictionsData.get(a.id)!.predictions.get(targetClass)! ||
          neighbors.get(b.id)!.inDegreeScore -
            neighbors.get(a.id)!.inDegreeScore,
      );
  } else {
    // Otherwise use the KNN similarities and in-degree score.
    for (let hops = 1; hops <= maxHops; hops += 1) {
      // Expand considered to include another level of neighbors
      considered = considered
        .map((id) => [id, ...(neighbors.get(id)?.neighbors ?? [])])
        .flat();
      considered.forEach((id) => {
        if (!hopCount.has(id)) {
          hopCount.set(id, hops);
        }
      });

      // Find unique neighbors that weren't examples
      eligible = Array.from(
        new Set(considered.filter((sample) => idToCandidate.has(sample))),
      )
        .map((id) => ({
          hops: hopCount.get(id),
          metadata: idToCandidate.get(id),
          inDegreeScore: neighbors.get(id)?.inDegreeScore,
        }))
        .filter(
          (
            entry,
          ): entry is {
            hops: number;
            metadata: UnlabeledCellSampleMetadata;
            inDegreeScore: number;
          } =>
            entry.hops !== undefined &&
            entry.inDegreeScore !== undefined &&
            entry.metadata !== undefined,
        )
        // Prefer matching class then fewer hops (i.e. closer neighbors)
        // then prefer higher inDegreeScore
        .sort((a, b) => a.hops - b.hops || b.inDegreeScore - a.inDegreeScore)
        .map(({ metadata }) => metadata);

      if (eligible.length >= count) {
        break;
      }
    }
  }
  return eligible.slice(0, count);
}

export function isNotEmpty<T>(input: T[]): input is T[] & [T, ...T[]] {
  return input.length > 0;
}

export function removeVersioning(input: string): string {
  // TODO (michaelwiest): We should use the logic from log-util.ts for consistency.
  return input.replace(/(-[^-]+){3}$/, "");
}

const FEATURE_PREFIX =
  "SingleCellRepLKNetL384In21kAvgPool128dMinMaxEmbeddings - ";

export function filteredFeatures(
  features: string[],
  stains: string[],
): string[] {
  return [
    ...new Set(
      features
        .filter((feature) => feature.startsWith(FEATURE_PREFIX))
        .map(removeVersioning)
        .filter((stain) => stains.includes(stain.replace(FEATURE_PREFIX, ""))),
    ),
  ];
}

export function cleanUpIndex({
  column,
  field,
  plate,
  row,
  well,
}: {
  column: number;
  field: Field;
  plate: string;
  row: number;
  well: string;
}) {
  return {
    column,
    field,
    plate,
    row,
    well,
  };
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function cleanUpClassifications(dirtyClassifications: Classification[]) {
  // Make sure that we are only keeping the keys of interest in our payload
  return dirtyClassifications.map((classification) => ({
    name: classification.name,
    examples: classification.examples.map(cleanUpIndex),
  }));
}

export function parallelMap<T, U>(
  list: T[],
  transform: (value: T) => Promise<U>,
  maxParallel: number,
  shouldAbort?: () => boolean,
): Promise<U[]> {
  let running = 0;
  let current = 0;
  let remaining = list.length;
  const values: (U | undefined)[] = new Array(list.length);

  return new Promise((resolve, reject) => {
    const check = () => {
      if (remaining === 0) {
        resolve(values as U[]);
        return;
      }

      while (
        running < maxParallel &&
        current < list.length &&
        // TODO(you): Fix this no-unnecessary-condition rule violation
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (!shouldAbort?.() || false)
      ) {
        const index = current;
        const value = list[index];

        current += 1;
        running += 1;

        transform(value)
          .then((result) => {
            values[index] = result;
            running -= 1;
            remaining -= 1;
            check();
          })
          .catch(reject);
      }
    };

    check();
  });
}

interface ModifyLabeledSet {
  accessToken: AccessToken;
  workspace: Workspace;
  dataset: DatasetId;
  labeledSet: Omit<LabeledSet, "id"> & Partial<Pick<LabeledSet, "id">>;
}

interface ModifyLabeledSetResponse {
  id: string;
  path: string;
  version: string;
}

export function saveLabeledSet({
  accessToken,
  workspace,
  dataset,
  labeledSet,
}: ModifyLabeledSet): Promise<ModifyLabeledSetResponse> {
  const {
    id,
    stains,
    stainChannelIndices,
    stainDisplayRanges,
    latestModelPath,
    classifications,
    displayName,
  } = labeledSet;

  const api = examplesApi({ accessToken, workspace: workspace.id, dataset });
  return (
    id
      ? api.route("<labeledSet>/update", { labeledSet: id })
      : api.route("create")
  )
    .post({
      labels: cleanUpClassifications(classifications),
      stains,
      stainChannelIndices,
      stainDisplayRanges,
      latestModelPath,
      accuracies: classifications.map(({ accuracy }) => accuracy),
      displayName,
    })
    .json<ModifyLabeledSetResponse>();
}
interface DeleteLabeledSet {
  accessToken: AccessToken;
  workspace: Workspace;
  dataset: DatasetId;
  labeledSetId: LabeledSet["id"];
}

export function deleteLabeledSet({
  accessToken,
  workspace,
  dataset,
  labeledSetId,
}: DeleteLabeledSet) {
  examplesApi({ accessToken, workspace: workspace.id, dataset })
    .route("<labeledSet>/archive", { labeledSet: labeledSetId })
    .post();
}

interface TrainLabeledSet {
  accessToken: AccessToken;
  workspace: Workspace;
  dataset: DatasetId;
  labeledSetId: LabeledSet["id"];
  featureSets: string[];
}
export interface ModelMetrics {
  confusion_matrix: number[][];
  precisions: number[];
  recalls: number[];
  latestFeatureSets: string[];
}
export interface TrainLabeledSetResponse extends ModelMetrics {
  class_names: string[];
  path: string;
  version: string;
}

export function trainLabeledSet({
  accessToken,
  workspace,
  dataset,
  labeledSetId,
  featureSets,
}: TrainLabeledSet): Promise<TrainLabeledSetResponse> {
  return examplesApi({ accessToken, workspace: workspace.id, dataset })
    .route("<labeledSet>/fit", { labeledSet: labeledSetId })
    .post(undefined, { featureSets })
    .json<TrainLabeledSetResponse>();
}

export async function requestInference(
  accessToken: AccessToken,
  name: string,
  description: string,
  stains: string[],
  plates: string[],
  workspace: WorkspaceId,
  labeledSetId: string,
  dataset: DatasetId,
) {
  await examplesApi({ accessToken, workspace, dataset })
    .route("<labeledSet>/request_inference", { labeledSet: labeledSetId })
    .post({
      name,
      description,
      stains,
      plates,
    })
    .finish();
}

export function addSampleState<T extends { id: string }>(
  list: T[],
  selected: T[],
): (T & SampleState)[] {
  const selectedIds = new Set<string>(selected.map(({ id }) => id));
  return list.map((entry: T) => ({
    ...entry,
    selected: selectedIds.has(entry.id),
  }));
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function validPalettes(palettes: PlateStains): PlateStains {
  return Object.fromEntries(
    Object.entries(palettes).map(([plate, stains]) => [plate, stains]),
  );
}

export function sortLabeledSets(labeledSets: LabeledSet[]): LabeledSet[] {
  return labeledSets.slice(0).sort((a, b) =>
    a.displayName.localeCompare(b.displayName, undefined, {
      sensitivity: "base",
    }),
  );
}

export function sortQueue(
  list: UnlabeledCellSampleMetadata[],
  neighbors: NeighborsMap,
): UnlabeledCellSampleMetadata[] {
  const samplesOverThreshold = list.filter(
    (sample) =>
      (neighbors.get(sample.id)?.inDegreeScore ?? 0) > IN_DEGREE_THRESHOLD,
  );

  const samplesUnderThreshold = removeSamples(list, samplesOverThreshold);

  return [
    // We want to use up the high inDegree samples first
    ...samplesOverThreshold,
    ...samplesUnderThreshold,
  ];
}

export function latestStainsFromFeatures(
  stains: string[],
  latestFeatureSets: string[],
): string[] {
  if (latestFeatureSets.length == 0) {
    return stains;
  }
  const matchingStains: string[] = [];

  stains.map((stain) => {
    if (
      latestFeatureSets.some(
        (featureSet) => featureSet.slice(-stain.length) == stain,
      )
    ) {
      matchingStains.push(stain);
    }
  });

  return matchingStains;
}
