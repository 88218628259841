import { ViewId } from "src/FeatureSetManagementPage/views";
import { DatasetId, WorkspaceId } from "src/types";

export function workspaceRootUrl(workspaceId: WorkspaceId) {
  return `/workspace/${workspaceId}`;
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function datasetRootUrl(workspaceId: WorkspaceId, dataset: DatasetId) {
  return `${workspaceRootUrl(workspaceId)}/e/${dataset}`;
}

export function experimentDefaultUrl(
  workspaceId: WorkspaceId,
  dataset: DatasetId,
) {
  return `${datasetRootUrl(workspaceId, dataset)}/data`;
}

export function adminConsoleUrl(workspaceId: WorkspaceId, dataset: DatasetId) {
  return `${datasetRootUrl(workspaceId, dataset)}/admin`;
}

export function measurementUrl(
  workspaceId: WorkspaceId,
  dataset: DatasetId,
  { tab }: { tab?: ViewId },
) {
  const queryString = tab
    ? `?${new URLSearchParams({
        tab: tab,
      })}`
    : "";

  return `${datasetRootUrl(workspaceId, dataset)}/measurements${queryString}`;
}
