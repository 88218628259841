import cx from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useLabeledSetContext } from "./Context";

interface Props {
  className?: string;
}

export function LabeledSetSaveStatus({ className }: Props) {
  const {
    state: { lastSaved },
  } = useLabeledSetContext();

  const [now, setNow] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now());
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const hidden = useMemo(
    () => (lastSaved ? now - lastSaved.valueOf() : Infinity) > 3000,
    [lastSaved, now],
  );

  return (
    <div
      className={cx(
        "tw-flex tw-flex-row tw-items-center tw-bg-purple-100 tw-py-2",
        "tw-px-4 tw-rounded-[50vh]",
        hidden && "tw-opacity-0 tw-transition-opacity",
        className,
      )}
    >
      {"Saving..."}
    </div>
  );
}
