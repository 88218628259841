import cx from "classnames";
import {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { useCSVDownloader } from "react-papaparse";
import { DeprecatedButton } from "../../Common/DeprecatedButton";
import { FullScreenContainer } from "../../Common/FullScreenContainer";
import Loader from "../../Common/Loader";
import { CollapsibleFilterSelector } from "../../Control/FilterSelector";
import { FilterSet } from "../../Control/FilterSelector/types";
import {
  EMPTY_FILTER_SET,
  serializeToSqlClause,
  updateFilters,
  updateOperator,
  useFilterColumns,
  validateFilter,
} from "../../Control/FilterSelector/utils";
import {
  DB,
  ValidatedSQL,
  queryDBAsRecords,
  sql,
  useQueryAsRecords,
} from "../../util/sql";
import {
  CellValue,
  Column,
  ColumnType,
  EditableTable,
  Row,
  SortColumn,
} from "./EditableTable";

const ROWS_PER_PAGE_OPTIONS = [50, 100, 500];

type EditableTableOptions = {
  onCommitChange: (value: CellValue, field: string, row: Row) => void;
};

export function DuckDBTable({
  db,
  tableName,
  editableTableOptions,
  createColumnsFromRow,
  headerComponent,
  hideDownload,
}: {
  db: DB;
  tableName: string;
  editableTableOptions?: EditableTableOptions;
  createColumnsFromRow?: (row: Row) => Column[];
  headerComponent?: React.ReactNode;
  hideDownload?: boolean;
}) {
  const { onCommitChange } = editableTableOptions || {};

  const [queryResult, setQueryResult] = useState<Row[] | null>(null);
  const [totalRowCount, setTotalRowCount] = useState<number | null>(null);

  const [tableColumns, setTableColumns] = useState<Column[] | null>(null);

  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0]);

  const [sortColumn, setSortColumn] = useState<SortColumn | null>(null);

  const [filterSet, setFilterSet] = useState<FilterSet>(EMPTY_FILTER_SET);

  const filterColumns = useFilterColumns(db, tableName);

  const filterSerialized = useMemo(
    () => serializeToSqlClause(filterSet),
    [filterSet],
  );

  useEffect(() => {
    const queryDb = async () => {
      const tableRowsQuery = createTableSelect(
        tableName,
        currentPage,
        rowsPerPage,
        filterSerialized,
        sortColumn,
      );
      const rowCountQuery = sql`SELECT COUNT(*) as total_rows FROM ${tableName} WHERE ${filterSerialized};`;
      const [queryRes, rowCount] = await Promise.all([
        queryDBAsRecords<Row>(db, tableRowsQuery),
        queryDBAsRecords<{ total_rows: bigint }>(db, rowCountQuery),
      ]);
      setQueryResult(queryRes);
      setTotalRowCount(Number(rowCount[0].total_rows));
    };
    queryDb();
  }, [db, tableName, filterSerialized, currentPage, rowsPerPage, sortColumn]);

  useEffect(() => {
    // Derive the column configuration object from the first row of data
    // using either the default creator or one provided by the caller.
    if (queryResult && tableColumns === null) {
      const columnCreator = createColumnsFromRow || defaultColumnCreator;
      setTableColumns(columnCreator(queryResult[0]));
    }
  }, [queryResult, tableColumns, createColumnsFromRow]);

  const isLoadingHeaderData = !filterColumns || !tableColumns;

  if (isLoadingHeaderData) {
    return (
      <FullScreenContainer center>
        <Loader />
      </FullScreenContainer>
    );
  }

  const validateAndSetFilter = (filter: FilterSet) => {
    validateFilter(db, filter, tableName).then((filterSet) => {
      setFilterSet(filterSet);
    });
  };

  const goToNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };
  const goToPrevPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleChangeRowsPerPage = (e: ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page after changing rows per page
  };

  const handleSortColumn = (sortColumn: SortColumn | null) => {
    // TODO: sort doesn't work well with numeric strings.
    // We should either address this in the response/db
    // creation or use column config to parse?
    setSortColumn(sortColumn);
  };

  // TODO(davidsharff): fix hardcoded height
  return (
    <>
      <div className="tw-flex tw-flex-col tw-h-[calc(100vh-theme(spacing.global-nav-height)-150px)]">
        {/* TODO(davidsharff): make header scroll away or move into a hover effect to save vertical space? */}
        <div className="tw-flex tw-justify-end tw-items-center tw-w-full tw-mb-4">
          <CollapsibleFilterSelector
            columns={filterColumns}
            filterSet={filterSet}
            onChangeFilters={(filters) =>
              validateAndSetFilter(updateFilters(filterSet, filters))
            }
            onChangeOperator={(operator, newFilters) =>
              validateAndSetFilter(
                updateOperator(filterSet, operator, newFilters),
              )
            }
            triggerClasses="tw-flex-1"
            depth={0}
            maxDepth={1}
            metadata={db}
          />
          {headerComponent}
          {!hideDownload && (
            <TableCSVDownloader db={db} tableName={tableName} />
          )}
        </div>
        {!queryResult || !totalRowCount ? (
          <FullScreenContainer center>
            <Loader />
          </FullScreenContainer>
        ) : (
          <TableMain
            rows={queryResult}
            tableColumns={tableColumns}
            onChangeSort={handleSortColumn}
            rowCount={totalRowCount}
            minRowsPerPage={ROWS_PER_PAGE_OPTIONS[0]}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            currentPage={currentPage}
            goToPrevPage={goToPrevPage}
            goToNextPage={goToNextPage}
            onCommitChange={onCommitChange}
          />
        )}
      </div>
    </>
  );
}

function TableMain({
  rows,
  tableColumns,
  onChangeSort,
  rowCount,
  minRowsPerPage,
  rowsPerPage,
  onChangeRowsPerPage,
  currentPage,
  goToPrevPage,
  goToNextPage,
  onCommitChange,
}: {
  rows: Row[];
  tableColumns: Column[];
  onChangeSort: (sortColumn: SortColumn | null) => void;
  rowCount: number;
  minRowsPerPage: number;
  rowsPerPage: number;
  onChangeRowsPerPage: ChangeEventHandler<HTMLSelectElement>;
  currentPage: number;
  goToPrevPage: () => void;
  goToNextPage: () => void;
  onCommitChange?: (value: CellValue, field: string, row: Row) => void;
}) {
  const totalPages = Math.ceil(rowCount / rowsPerPage);

  return (
    <>
      <div className="tw-flex-1 tw-overflow-y-auto">
        <EditableTable
          rows={rows}
          columns={tableColumns}
          onCommitChange={onCommitChange}
          onColumnSort={(sortColumn) => {
            // TODO: sort doesn't work well with numeric strings.
            // We should either address this in the arrow response
            // or use column config to parse?
            if (sortColumn !== null) {
              onChangeSort(sortColumn);
            } else {
              onChangeSort(null);
            }
          }}
        />
      </div>
      <div
        className={cx(
          "tw-flex tw-justify-start tw-items-center tw-mt-4 tw-bg-white",
          "tw-fixed tw-bottom-0 tw-w-full tw-border-t tw-py-4 tw-pr-4 tw-h-[70px]",
        )}
      >
        {rowCount > rowsPerPage && (
          <>
            <DeprecatedButton
              onClick={goToPrevPage}
              className="tw-mr-2"
              disabled={currentPage === 1}
              borderless
            >
              <ChevronLeft />
            </DeprecatedButton>
            <div className="tw-mr-2">{`Page ${currentPage} of ${totalPages}`}</div>
            <DeprecatedButton
              onClick={goToNextPage}
              className="tw-mr-2"
              disabled={currentPage === totalPages}
              borderless
            >
              <ChevronRight />
            </DeprecatedButton>
          </>
        )}
        {rowCount > minRowsPerPage && (
          <select
            value={rowsPerPage}
            onChange={onChangeRowsPerPage}
            className="tw-ml-4 tw-mr-6 tw-border tw-rounded tw-p-1"
          >
            {ROWS_PER_PAGE_OPTIONS.map((option) => (
              <option key={option} value={option}>
                {option} per page
              </option>
            ))}
          </select>
        )}
        <div>Total: {Number(rowCount)}</div>
      </div>
    </>
  );
}

function createTableSelect(
  tableName: string,
  currentPage: number,
  rowsPerPage: number,

  filterSerialized: string,
  sort: SortColumn | null,
): ValidatedSQL {
  const offset = (currentPage - 1) * rowsPerPage;

  const sortOrder = sort ? sql`ORDER BY "${sort.field}" ${sort.direction}` : "";

  return sql`
    SELECT * 
    FROM ${tableName} 
    WHERE ${filterSerialized}
    ${sortOrder} 
    LIMIT ${rowsPerPage} 
    OFFSET ${offset};
  `;
}

function defaultColumnCreator(row: Row): Column[] {
  return Object.keys(row).map((field) => {
    let type: ColumnType;
    switch (typeof row[field]) {
      case "bigint":
      case "number":
        type = ColumnType.Number;
        break;
      case "boolean":
        type = ColumnType.Checkbox;
        break;
      case "string":
        type = ColumnType.Text;
        break;
      default:
        throw new Error(`Unsupported data type for column: ${field}`);
    }

    return {
      headerContent: field,
      field,
      type: type,
    };
  });
}

type TableCSVDownloaderProps = {
  db: DB;
  tableName: string;
};

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function TableCSVDownloader({ db, tableName }: TableCSVDownloaderProps) {
  const { CSVDownloader, Type } = useCSVDownloader();

  // TODO(davidsharff): CSVDownloader accepts a cb for the data prop however it has to be
  // synchronous so we're just fetching all rows immediately until it becomes a problem.
  const selectAllResult = useQueryAsRecords<{ total_rows: bigint }>(
    db,
    sql`SELECT * FROM ${tableName};`,
  );

  if (selectAllResult && !selectAllResult.successful) {
    throw new Error("Could not prepopulate download data");
  }

  const data = selectAllResult?.value || null;

  // Ripped from the common Button component
  const primaryButtonClasses = cx(
    "tw-border tw-border-gray-200",
    "hover:tw-border-gray-300 hover:tw-shadow",
    "active:tw-border-gray-400",
    "tw-py-2 tw-px-4 tw-rounded",
  );
  return (
    <CSVDownloader
      type={Type.Button}
      filename={`${tableName}_export`}
      // bom prop is mentioned in docs as required for excel
      bom={true}
      data={data}
      className={primaryButtonClasses}
    >
      Download
    </CSVDownloader>
  );
}
