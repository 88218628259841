/**
 * Component to render an editable cell for a "checkbox"-type column.
 */
import { StyleDeclarationValue, StyleSheet, css } from "aphrodite";
import classNames from "classnames";
import { useState } from "react";
import { Check } from "react-feather";
import { shared } from "../../../megamap-styles";
import KeypressListener from "../../KeypressListener";

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const CHECKBOX_SIZE = 22;

export default function CheckboxCell({
  children,
  styles: parentStyles,
  onChange,
  isFocused,
}: {
  children: boolean;
  styles?: StyleDeclarationValue;
  onChange: (value: boolean) => void;
  isFocused?: boolean;
  isSelected?: boolean;
}) {
  const [hover, setHover] = useState<boolean>(false);
  return (
    <div
      className={"tw-flex tw-flex-auto"}
      style={isFocused ? { paddingRight: 1 } : {}}
    >
      {isFocused ? (
        <KeypressListener code={"Enter"} onPress={() => onChange(!children)} />
      ) : null}
      <div
        // HACK(charlie): Ensures that we don't get an `onClick` if the `onMouseDown` occurs before
        // focus.
        key={isFocused + ""}
        role="checkbox"
        className={classNames(
          "tw-flex tw-flex-auto tw-justify-center",
          isFocused && "tw-cursor-pointer",
        )}
        style={{
          paddingRight: 6,
          paddingLeft: 6,
          paddingTop: 2,
          paddingBottom: 2,
        }}
        aria-checked={children}
        onClick={isFocused ? () => onChange(!children) : undefined}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div
          aria-hidden={true}
          className={
            "tw-flex-none tw-flex-inline tw-items-center tw-justify-center tw-rounded tw-cursor-pointer tw-white tw-border " +
            css(
              hover || isFocused ? shared.borderThick : undefined,
              parentStyles,
            )
          }
          onMouseDown={(e) => e.stopPropagation()}
          onClick={() => onChange(!children)}
          style={{ width: CHECKBOX_SIZE, height: CHECKBOX_SIZE }}
        >
          <div
            className={
              "tw-flex-auto tw-flex tw-items-center tw-justify-center " +
              css(styles.fill)
            }
          >
            {children ? <Check width={17.6} height={17.6} /> : undefined}
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  fill: {
    color: "rgb(32, 201, 51)",
    fill: "rgb(32, 201, 51)",
  },
});
