import cx from "classnames";
import * as React from "react";

// Providing a simpler type interface for all the typography components
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TypographyProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  > {}

const titleSizeToTailwindClasses = {
  lg: "tw-text-2xl tw-leading-8",
  xl: "tw-text-5xl tw-leading-tighter",
  "2xl": "tw-text-7xl tw-leading-tighter",
};
const Title = React.forwardRef<
  HTMLSpanElement,
  TypographyProps & {
    size?: "lg" | "xl" | "2xl";
  }
>(({ children, className, size = "lg", ...props }, ref) => {
  return (
    <span
      ref={ref}
      className={cx(
        "tw-block",
        "tw-font-sans tw-font-normal",
        titleSizeToTailwindClasses[size],
        className,
      )}
      {...props}
    >
      {children}
    </span>
  );
});
Title.displayName = "Title";

const Subtitle = React.forwardRef<HTMLSpanElement, TypographyProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <span
        ref={ref}
        className={cx(
          "tw-block",
          "tw-font-sans tw-font-medium tw-text-base",
          className,
        )}
        {...props}
      >
        {children}
      </span>
    );
  },
);
Subtitle.displayName = "Subtitle";

const Text = React.forwardRef<HTMLSpanElement, TypographyProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <span
        ref={ref}
        className={cx("tw-font-sans tw-font-normal tw-text-base", className)}
        {...props}
      >
        {children}
      </span>
    );
  },
);
Text.displayName = "Text";

const Label = React.forwardRef<HTMLSpanElement, TypographyProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <span
        ref={ref}
        className={cx(
          "tw-block",
          "tw-font-mono tw-uppercase tw-font-normal",
          "tw-text-sm tw-leading-6 tw-tracking-wide",
          className,
        )}
        {...props}
      >
        {children}
      </span>
    );
  },
);
Label.displayName = "Label";

const Caption = React.forwardRef<
  HTMLSpanElement,
  TypographyProps & { weight?: "normal" | "bold" }
>(({ children, className, weight = "normal", ...props }, ref) => {
  return (
    <span
      ref={ref}
      className={cx(
        "tw-block",
        "tw-font-sans",
        weight === "bold" ? "tw-font-semibold" : "tw-font-normal",
        "tw-text-sm",
        className,
      )}
      {...props}
    >
      {children}
    </span>
  );
});
Caption.displayName = "Caption";

export { Title, Subtitle, Text, Label, Caption };
