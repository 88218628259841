import { ReactNode } from "react";
import { AnalyzeView, viewSupportsMultipleFeatures } from "./views";

export enum ViewSkeletonReason {
  NoFeaturesSelected,
  EmbeddingSelected,
  MultipleFeaturesSelected,
  MultipleColumnsSelected,
}

interface Props {
  view: AnalyzeView | undefined;
  reason: ViewSkeletonReason;
  onOpenFeatureSelector: () => void;
}

// Formatted text describing the reason the view can't be used with the current
// configuration
function reasonCopy(
  view: AnalyzeView | undefined,
  reason: ViewSkeletonReason,
  onOpenFeatureSelector: () => void,
): ReactNode {
  const measurementSelectorLink = (content: ReactNode) => (
    <button className="tw-underline" onClick={onOpenFeatureSelector}>
      {content}
    </button>
  );

  if (view === undefined) {
    return (
      <>
        {measurementSelectorLink("Select some measurements")} and a view will be
        automatically selected for you
      </>
    );
  }

  const name = view.name;

  switch (reason) {
    case ViewSkeletonReason.NoFeaturesSelected:
      if (viewSupportsMultipleFeatures(view)) {
        return (
          <>
            To use <strong>{name}</strong>,{" "}
            {measurementSelectorLink("select some measurements")}
          </>
        );
      } else {
        return (
          <>
            To use <strong>{name}</strong>,{" "}
            {measurementSelectorLink("select a measurement")}
          </>
        );
      }
    case ViewSkeletonReason.EmbeddingSelected:
      return (
        <>
          <strong>{name}</strong> can't be used with embeddings, please{" "}
          {measurementSelectorLink("select a different measurement")}
        </>
      );
    case ViewSkeletonReason.MultipleFeaturesSelected:
      return (
        <>
          <strong>{name}</strong> only works when a single feature is selected,
          please {measurementSelectorLink("narrow down your selection")}
        </>
      );
    case ViewSkeletonReason.MultipleColumnsSelected:
      return (
        <>
          <strong>{name}</strong> only works when a single column is selected,
          please {measurementSelectorLink("narrow down your selection")}
        </>
      );
  }
}

// A view to use when a view can't be used for some reason
export function ViewSkeleton({ view, reason, onOpenFeatureSelector }: Props) {
  if (!view) {
    return (
      <div className="tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-gray-100 tw-text-slate-500">
        <div className="tw-max-w-[500px] tw-p-xl tw-text-center tw-text-xl">
          {reasonCopy(view, reason, onOpenFeatureSelector)}
        </div>
      </div>
    );
  }

  return (
    <div className="tw-h-full tw-flex tw-flex-col tw-items-center tw-bg-gray-100">
      <div className="tw-w-full tw-p-xl tw-text-center tw-bg-white tw-text-xl">
        {reasonCopy(view, reason, onOpenFeatureSelector)}
      </div>
      <div className="tw-flex-1 tw-p-xl tw-w-full tw-flex tw-items-center tw-justify-center">
        <div className="tw-h-full tw-w-full tw-flex tw-flex-col tw-items-center">
          <div
            className="tw-w-full tw-h-full tw-max-h-[600px] tw-filter tw-grayscale tw-bg-no-repeat tw-bg-contain tw-bg-center"
            style={{ backgroundImage: `url(${view.previewUrl})` }}
          ></div>
          <div className="tw-p-lg tw-text-sm tw-text-slate-500">
            Example {view.name} view
          </div>
        </div>
      </div>
    </div>
  );
}
