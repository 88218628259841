import { useCallback, useEffect, useState } from "react";
import { DatasetId } from "src/types";
import { Failure, Result, Success } from "@spring/core/result";
import { useFeatureSetColumns } from "../hooks/features";
import {
  FeatureSetSelection,
  SerializedColumnIndices,
  UnreifiedFeatureSetSelection,
  isPlateBasedFeature,
} from "./types";
import { deserializeColumnIndices } from "./utils";

/**
 * A non-rendering component that helps reify feature selections by resolving columns.
 *
 * Feature selections may specify wanting "all" columns, but we need to ask the server
 * what those columns are. This component helps with that (since doing multiple fetches
 * in a loop can't be done with a hook).
 */
export default function ColumnsResolver({
  dataset,
  unreifiedFeatureSelections,
  onComplete,
}: {
  dataset: DatasetId;
  unreifiedFeatureSelections: UnreifiedFeatureSetSelection[];
  onComplete: (reified: FeatureSetSelection[]) => void;
}) {
  const [reifiedSelections, setReifiedSelections] = useState<{
    [key: string]: FeatureSetSelection;
  }>({});

  const handleSingleResult = useCallback(
    (key: string, result: Result<FeatureSetSelection>) => {
      if (result.successful) {
        setReifiedSelections((mapping) => ({
          ...mapping,
          [key]: result.value,
        }));
      }
      // TODO(benkomalo): handle the error case where columns fetching fails?
      // Pretty rare, but we'd get an indefinite loading spinner now.
    },
    [],
  );

  useEffect(() => {
    const results = unreifiedFeatureSelections
      .map((selection) => keyForReification(selection))
      .map((key) => reifiedSelections[key]);
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const isComplete = results.every((result) => !!result);
    if (isComplete) {
      onComplete(results);
    }
  }, [unreifiedFeatureSelections, reifiedSelections, onComplete]);

  return (
    <>
      {unreifiedFeatureSelections.map((selection) => {
        const key = keyForReification(selection);
        return (
          <SingleColumnsResolver
            key={key}
            stateKey={key}
            dataset={dataset}
            unreified={selection}
            onResult={handleSingleResult}
          />
        );
      })}
    </>
  );
}

function SingleColumnsResolver({
  stateKey,
  dataset,
  unreified,
  onResult,
}: {
  stateKey: string;
  dataset: DatasetId;
  unreified: UnreifiedFeatureSetSelection;
  onResult: (key: string, result: Result<FeatureSetSelection>) => void;
}) {
  const needsToResolveColumns =
    unreified.type !== "embedding" && !Array.isArray(unreified.columns);

  const columnsFetch = useFeatureSetColumns(
    needsToResolveColumns
      ? isPlateBasedFeature(unreified.featureSets[0])
        ? {
            dataset,
            plate: unreified.featureSets[0].plate,
            featureSet: unreified.featureSets[0].id,
          }
        : {
            dataset,
            featureSet: unreified.featureSets[0].id,
          }
      : { skip: true },
  );

  useEffect(() => {
    switch (unreified.type) {
      case "embedding":
        onResult(stateKey, Success.of(unreified));
        break;

      case "numerical":
      case "prediction":
        if (needsToResolveColumns) {
          if (columnsFetch?.successful) {
            if (unreified.columns === "all") {
              onResult(
                stateKey,
                columnsFetch.map((columns) => ({
                  ...unreified,
                  columns,
                  includesAllColumns: true,
                })),
              );
            } else {
              try {
                const indices = deserializeColumnIndices(
                  unreified.columns as SerializedColumnIndices,
                );
                onResult(
                  stateKey,
                  columnsFetch.map((columns) => ({
                    ...unreified,
                    columns: columns.filter((_, i) => indices.includes(i)),
                    includesAllColumns: false,
                  })),
                );
              } catch (e) {
                onResult(
                  stateKey,
                  Failure.of(
                    new Error(`Invalid column indices ${unreified.columns}`),
                  ),
                );
              }
            }
          }
        } else if (Array.isArray(unreified.columns)) {
          onResult(
            stateKey,
            Success.of({
              ...unreified,
              columns: unreified.columns,
              includesAllColumns: false,
            }),
          );
        }
    }
  }, [stateKey, columnsFetch, needsToResolveColumns, unreified, onResult]);

  return <></>;
}

function keyForReification(selection: UnreifiedFeatureSetSelection): string {
  switch (selection.type) {
    case "embedding":
      return `${selection.type}-${selection.names.join("-")}`;

    case "numerical":
    case "prediction": {
      const columns = JSON.stringify(selection.columns);
      return `${selection.type}-${selection.name}-${columns}`;
    }
  }
}
