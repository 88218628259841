import { useEffect, useState } from "react";
import type { KnownFeatureFlag } from "./Workspace/feature-flags";

interface Overrides {
  hostUrl?: string;
  auth0Domain?: string;
  auth0ClientId?: string;
  auth0Audience?: string;
}

export interface DevOptions {
  overrides?: Overrides;
  featureFlags?: {
    [key: string]: boolean;
  };
}

const DEV_OPTIONS_KEY = "devOptions";

let devOptions: DevOptions =
  import.meta.env.MODE === "development"
    ? (() => {
        try {
          const config = localStorage.getItem(DEV_OPTIONS_KEY);
          return config ? JSON.parse(config) : {};
        } catch (ex) {
          return {};
        }
      })()
    : {};

const subscribers: React.Dispatch<React.SetStateAction<number>>[] = [];
let lastUpdate: number = 0;

function forceDevOptionsUpdate() {
  lastUpdate += 1;
  subscribers.forEach((set) => set(lastUpdate));
}

export function updateDevOptions(updated: DevOptions) {
  devOptions = updated;
  localStorage.setItem(DEV_OPTIONS_KEY, JSON.stringify(updated));
  forceDevOptionsUpdate();
}

export function getDevOverride<T extends keyof Overrides>(
  key: T,
): Overrides[T] | null {
  return devOptions.overrides?.[key] ?? null;
}

export function getDevFeatureFlagOverride(
  flag: KnownFeatureFlag,
): boolean | null {
  return devOptions.featureFlags?.[flag] ?? null;
}

export function getDevOptions() {
  return devOptions;
}

export function useDevOptions() {
  const [, forceUpdate] = useState<number>(lastUpdate);

  useEffect(() => {
    subscribers.push(forceUpdate);

    return () => {
      const index = subscribers.indexOf(forceUpdate);
      if (index !== -1) {
        subscribers.splice(index, 1);
      }
    };
  }, []);

  return devOptions;
}
