import cx from "classnames";
import { ReactNode } from "react";

export default function ControlHeader({
  children,
  extraClasses,
}: {
  children: ReactNode;
  extraClasses?: string;
}) {
  return (
    <div
      className={cx(
        "tw-justify-self-start tw-mb-1 tw-uppercase tw-text-sm tw-text-slate-500",
        extraClasses,
      )}
    >
      {children}
    </div>
  );
}
