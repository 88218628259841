/**
 * Component to render a pill-like "choice" token.
 */
import { StyleSheet, css } from "aphrodite";
import { shared } from "../../../megamap-styles";
import {
  BACKGROUND_COLORS,
  TEXT_COLORS,
  TO_COLORS,
} from "../../../util/generic-color-palette";
import { Choice } from "../backend-types";

export default function ChoiceToken({ children }: { children: Choice }) {
  const [backgroundColor, textColor] = TO_COLORS[children.color];
  return (
    <span
      className={
        "tw-truncate tw-px-1 tw-inline-block " +
        css(shared.lineHeight4, shared.pill, styles.choiceToken)
      }
      style={{
        minWidth: 18,
        height: 19,
        fontSize: "13px",
        backgroundColor: BACKGROUND_COLORS[backgroundColor],
        color: TEXT_COLORS[textColor],
        fill: TEXT_COLORS[textColor],
      }}
    >
      <div className={"tw-flex-auto tw-truncate"} title={children.name}>
        {children.name}
      </div>
    </span>
  );
}

const styles = StyleSheet.create({
  choiceToken: { verticalAlign: "top" },
});
