/**
 * A wrapper class to coordinate sending image-rescaling tasks to a WebWorker.
 *
 * This class sends messages to an underlying WebWorker (via postMessage) and handles the response
 * from the WebWorker (via worker.addEventListener).
 */
import WebWorker from "./ImageRescaler.worker?worker";
import { ChannelMap, DisplayRange, RenderableImage } from "./types";

type Status = "idle" | "busy";

export default class ImageRescaler {
  queuedTasks: [string, () => void][];
  status: Status;
  worker: Worker;

  constructor() {
    // Initialize the worker state.
    this.queuedTasks = [];
    this.status = "idle";

    // Create the underlying WebWorker.
    this.worker = new WebWorker();
  }

  terminate() {
    this.worker.terminate();
  }

  /**
   * Rescale source images based on the given channel map and display ranges.
   *
   * The semantics of execution are such that if a task is already in-flight,
   * this execution will be queued up to run next; any existing, queued-up
   * executions will be canceled.
   *
   * @returns - a Promise that resolves to a populated Uint8ClampedArray with
   *            the composited pixel values.
   */
  execute(
    taskId: string,
    dstData: Uint8ClampedArray,
    imageDatas: Map<number, RenderableImage | null>,
    channelMap: ChannelMap,
    displayRanges: [
      DisplayRange | null,
      DisplayRange | null,
      DisplayRange | null,
      DisplayRange | null,
      DisplayRange | null,
      DisplayRange | null,
      DisplayRange | null,
    ],
  ): Promise<Uint8ClampedArray> {
    return new Promise((resolve) => {
      const taskFn = () => {
        const eventListener = (event: MessageEvent) => {
          // We use a new event listener for every execution (so, remove the
          // event listener as soon as it has gone off).
          this.worker.removeEventListener("message", eventListener);

          // Reconstruct the imageDatas.
          // This is dangerous but necessary given our use of WebWorker
          // transferables. When we send a data as a transferable object, the
          // calling context loses access to it. As a result, we need to
          // "restore" it here. This is only safe because the underlying
          // imageDatas array is never mutated by the parent component.
          const [dstDataBuffer, imageDataBuffers] = event.data;
          for (const [i] of imageDataBuffers) {
            const imageData = imageDatas.get(i) ?? null;
            if (
              imageData != null &&
              // TODO(you): Fix this no-unnecessary-condition rule violation
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              imageData.array != null &&
              // TODO(you): Fix this no-unnecessary-condition rule violation
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              imageData.array.buffer != null &&
              imageData.array.buffer.byteLength === 0
            ) {
              imageData.array = new Uint16Array(imageDataBuffers.get(i));
            }
          }

          resolve(new Uint8ClampedArray(dstDataBuffer));

          // Reset the the worker state, and run any queued tasks.
          this.status = "idle";
          this.runQueuedTask();
        };

        // Setup the listener, so that we can handle the response from the WebWorker.
        this.worker.addEventListener("message", eventListener);

        // Send any arrays as ArrayBuffers, to make them transferable. This
        // essentially allows us to pass them by reference to the web
        // worker, avoiding an expensive copy.
        // See:
        //   https://developers.google.com/web/updates/2011/12/Transferable-Objects-Lightning-Fast
        const buffers: Map<number, ArrayBuffer | null> = new Map();

        for (const [k, v] of imageDatas) {
          const buffer = v?.array.buffer;
          if (buffer != null) {
            buffers.set(k, buffer);
          }
        }

        const eventData = [channelMap, displayRanges, dstData.buffer, buffers];

        const transferables: Transferable[] = [dstData.buffer];
        for (const [, v] of buffers) {
          if (v != null) {
            transferables.push(v);
          }
        }

        // Kick off the underlying WebWorker.
        this.status = "busy";
        this.worker.postMessage(eventData, transferables);
      };

      // If we already have a queued update for the current image, replace it.
      const existingTaskIndex = this.queuedTasks.findIndex(
        ([existingTaskId]) => existingTaskId === taskId,
      );
      if (existingTaskIndex === -1) {
        this.queuedTasks.push([taskId, taskFn]);
      } else {
        this.queuedTasks.splice(existingTaskIndex, 1, [taskId, taskFn]);
      }

      this.runQueuedTask();
    });
  }

  runQueuedTask() {
    if (this.status === "idle") {
      const next = this.queuedTasks.shift();
      if (next) {
        const [, taskFn] = next;
        taskFn();
      }
    }
  }
}

const _MAX_WORKERS: number = 32;
const _WORKER_POOL: ImageRescaler[] = [];
const _REFERENCES: number[] = [];

/**
 * Get an ImageRescaler from the worker pool, to execute rescaling operations.
 */
export function getWorker(): ImageRescaler {
  if (_WORKER_POOL.length < _MAX_WORKERS) {
    // If we're not yet at maximum capacity, create and return a new WebWorker.
    const worker = new ImageRescaler();
    _WORKER_POOL.push(worker);
    _REFERENCES.push(1);
    return worker;
  } else {
    // Otherwise, return the least-utilized worker from the pool.
    let minIndex = 0;
    for (let i = 0; i < _REFERENCES.length; i++) {
      if (_REFERENCES[i] < _REFERENCES[minIndex]) {
        minIndex = i;
      }
    }
    _REFERENCES[minIndex]++;
    return _WORKER_POOL[minIndex];
  }
}

/**
 * Release an ImageRescaler back to the pool.
 *
 * If the worker has no remaining references, it will be terminated. As such, clients should not
 * interact with the provided worker after calling {@code releaseWorker}.
 *
 * TODO(charlie): A more optimal model here might be to assign to the pool on a per `execute`
 * basis rather than a per client (i.e., per image) basis. As-is, we can end up with an even
 * distribution across the workers if we happen to remove images from a page that aren't uniformly
 * distributed amongst the pool.
 */
export function releaseWorker(worker: ImageRescaler) {
  // Find the index of the worker in the worker pool.
  const index = _WORKER_POOL.indexOf(worker);
  if (index === -1) {
    console.error(
      "Attempted to release worker that couldn't be found in the worker pool.",
    );
    return;
  }
  if (_REFERENCES[index] <= 0) {
    console.error(
      "Attempted to release worker with a reference count of zero.",
    );
    return;
  }

  // Decrement the reference count (and terminate the worker if needed).
  _REFERENCES[index]--;
  if (_REFERENCES[index] === 0) {
    _REFERENCES.splice(index, 1);
    const [worker] = _WORKER_POOL.splice(index, 1);
    worker.terminate();
  }
}
