import { useDispatch, useVisualizationState } from "../state/hooks";
import { DisplayRange, Palette } from "../types";
import VisualizationControls from "./VisualizationControls";

export default function ConnectedVisualizationControls({
  palette,
  stainSubset,
  stainChannelIndices,
  stainDisplayRanges,
}: {
  palette: Palette | null;
  stainSubset?: string[];
  stainChannelIndices?: Record<string, number>;
  stainDisplayRanges?: Record<string, DisplayRange>;
}) {
  const visualizationState = useVisualizationState();
  const dispatch = useDispatch();
  return (
    <div className={"tw-flex tw-flex-col"}>
      <VisualizationControls
        onChangeChannel={(selectedValue, index) =>
          dispatch({
            type: "set-channel",
            selectedValue,
            index,
            stainDisplayRanges,
          })
        }
        onLoadPalette={(palette) =>
          dispatch({
            type: "load-palette",
            palette,
            stainSubset,
            stainChannelIndices,
            stainDisplayRanges,
          })
        }
        onChangeActiveRange={(displayRange, index) => {
          dispatch({ type: "set-active-range", displayRange, index });
        }}
        onToggleChannel={(index) => dispatch({ type: "toggle-channel", index })}
        onLockChannel={(index) => dispatch({ type: "lock-channel", index })}
        {...visualizationState}
        palette={palette}
        stainSubset={stainSubset}
      />
    </div>
  );
}
