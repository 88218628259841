import { User } from "../user/types";

// Check for a hard coded user in local storage - this is used by the smoke tests
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const { token: fixedToken, ...fixedUser } = (() => {
  try {
    // See dev/tools/ci/jobs/saas-smoke-tests/helpers.ts for where this gets set
    const raw = localStorage.getItem("fixedUser");
    if (raw) {
      return JSON.parse(raw) as User & {
        token: string;
      };
    }
  } catch (ex) {
    //Fall through to returning nothing
  }
  return { token: null };
})();

export function isTestUser() {
  return fixedToken !== null;
}

// See if we're running as the smoke test user
export function useTestUser(): User | null {
  return isTestUser() ? (fixedUser as User) : null;
}
