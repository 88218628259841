/**
 * Utility to help build sorting functions
 *
 * You can pass a list of standard sorting functions, functions that map to sortable
 * primitives, or keys that correspond to sortable primitives and buildSort will return
 * a function that sorts by each of the sorters (starting with the first one and using
 * subsequent ones as tie breakers)
 */

type SortableValue = string | number | boolean;
type ValueSorter<T> = (a: T) => SortableValue;
type StandardSorter<T> = (a: T, b: T) => number;

type SortableKeys<T> = {
  [key in keyof T]: T[key] extends SortableValue ? key : never;
}[keyof T];

type Sorter<T> = ValueSorter<T> | StandardSorter<T> | SortableKeys<T>;

function isStandardSorter<T>(sorter: Sorter<T>): sorter is StandardSorter<T> {
  return typeof sorter === "function" && sorter.length == 2;
}

function compareValues(aValue: unknown, bValue: unknown) {
  if (typeof aValue === "number" && typeof bValue === "number") {
    return aValue - bValue;
  } else if (typeof aValue === "string" && typeof bValue === "string") {
    return aValue.localeCompare(bValue, undefined, {
      sensitivity: "base",
    });
  } else if (typeof aValue === "boolean" && typeof bValue === "boolean") {
    return (aValue ? 0 : 1) - (bValue ? 0 : 1);
  } else {
    return 0;
  }
}

export function buildSort<T>(...sorters: Sorter<T>[]): StandardSorter<T> {
  return (a: T, b: T) => {
    for (const sorter of sorters) {
      const result =
        typeof sorter === "function"
          ? isStandardSorter(sorter)
            ? sorter(a, b)
            : compareValues(sorter(a), sorter(b))
          : compareValues(a[sorter], b[sorter]);

      if (result !== 0) {
        return result;
      }
    }

    return 0;
  };
}

// Helper function to invert a Sorter
// This returns a StandardSorter
export function invert<T>(
  sorter: Sorter<T>,
  invert: boolean = true,
): StandardSorter<T> {
  const original = buildSort(sorter);

  return invert ? (a: T, b: T) => -original(a, b) : original;
}
