import { Placement } from "@popperjs/core";
import classNames from "classnames";
import { CSSProperties, ReactNode } from "react";

export default function HoverPopup({
  children,
  setPopperElement,
  popperStyles,
  popperAttributes,
  popperState,
  setPopperArrowElement,
}: {
  children: ReactNode;
  popperStyles: { [key: string]: CSSProperties } | undefined;
  popperAttributes: { [key: string]: { [attr: string]: string } | undefined };
  popperState: {
    placement: Placement;
  } | null;
  setPopperElement: (node: HTMLDivElement | null) => void;
  setPopperArrowElement: (node: HTMLDivElement | null) => void;
}) {
  return (
    <div
      ref={setPopperElement}
      style={popperStyles?.popper}
      // TODO(you): Fix this no-unnecessary-condition rule violation
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      {...popperAttributes?.popper}
      className="tw-z-popup tw-pointer-events-none"
    >
      <div
        className={classNames(
          "tw-absolute tw-w-4 tw-h-20 -tw-mt-8 tw-pointer-events-auto",
          {
            "tw-left-[3px]": popperState?.placement === "right",
            "tw-right-[3px]": popperState?.placement === "left",
          },
        )}
        style={popperStyles?.arrow}
      />
      <div
        className={classNames("tw-relative tw-pointer-events-auto", {
          "tw-ml-4": popperState?.placement === "right",
          "tw-mr-4": popperState?.placement === "left",
          "tw-mx-4": !popperState?.placement,
        })}
      >
        <div className="tw-p-4 tw-rounded-xl tw-bg-slate-50 tw-border tw-border-purple tw-max-w-xs tw-break-words">
          {children}
        </div>
      </div>
      <div
        ref={setPopperArrowElement}
        className={classNames(
          "tw-absolute tw-w-4 tw-h-4 after:tw-absolute after:tw-w-full after:tw-h-full after:tw-rotate-45 after:tw-bg-slate-50 after:tw-border-purple",
          {
            "tw-left-2": popperState?.placement === "right",
            "after:tw-border-l": popperState?.placement === "right",
            "after:tw-border-b": popperState?.placement === "right",
            "tw-right-2": popperState?.placement === "left",
            "after:tw-border-r": popperState?.placement === "left",
            "after:tw-border-t": popperState?.placement === "left",
          },
        )}
        style={popperStyles?.arrow}
      />
    </div>
  );
}
