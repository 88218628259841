import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AccessToken } from "src/Auth0/accessToken";
import { FullScreenLoader } from "src/Common/FullScreenLoader";
import { fixedToken } from "../util/test-user";

const AccessTokenContext = createContext<{
  accessToken: AccessToken;
  refreshAccessToken: () => void;
} | null>(null);

export function useAccessTokenContext() {
  const context = useContext(AccessTokenContext);

  if (context === null) {
    throw new Error(
      "useAccessTokenContext must be used within <AccessTokenContextProvider />",
    );
  }

  return context;
}

export const RequiresAuthentication = withAuthenticationRequired(
  ({ children }: { children?: ReactNode }) => {
    return <>{children}</>;
  },
);

/*
 * Fetches an Auth0 access token silently for the current user if logged in and stores it in context.
 */
export function AccessTokenContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [token, setToken] = useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  const refreshAccessToken = useCallback(() => {
    // If we're authenticating as the test user, we never need to refresh the token
    if (fixedToken) {
      return;
    }

    getAccessTokenSilently().then(setToken, (error) => {
      if (String(error) !== "Error: Login required") {
        Sentry.captureException(error);
      }
    });
  }, [getAccessTokenSilently]);

  useEffect(() => {
    // If we're authenticating as the test user, just used the fixed token
    if (fixedToken) {
      setToken(fixedToken);
      return;
    }

    refreshAccessToken();
  }, [refreshAccessToken]);

  const accessToken = useMemo(
    () => (token ? new AccessToken(token) : null),
    [token],
  );

  return !accessToken ? (
    <FullScreenLoader />
  ) : (
    <AccessTokenContext.Provider value={{ accessToken, refreshAccessToken }}>
      {children}
    </AccessTokenContext.Provider>
  );
}
