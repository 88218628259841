import { useEffect } from "react";
import { useMethodsContext } from "./context";
import { MethodSectionKey } from "./utils";

/**
 * Hook that adds the "View methods" button to any part of the app.
 * Clicking it will open a modal displaying the text of the methods section that corresponds to the
 * provided key. Keys are the filenames (without extensions) of docs under /docs/methods.
 *
 * Example usage:
 *   useMethods(MethodSectionKey.phenosorter)
 */
export function useMethods(key: MethodSectionKey) {
  const { setActiveSectionKey } = useMethodsContext();

  useEffect(() => {
    setActiveSectionKey(key);

    return () => {
      setActiveSectionKey(null);
    };
  }, [key, setActiveSectionKey]);
}
