/**
 * Interface to error monitoring (a facade over Sentry).
 */
import { User } from "@auth0/auth0-react";
import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ENV } from "./env";

export const ERROR_MONITORING_ENABLED = ENV === "production";

/**
 * Initialize error monitoring.
 */
export function initErrorMonitoring() {
  if (ERROR_MONITORING_ENABLED) {
    Sentry.init({
      dsn: "https://420065ad666c4deea0a144d2bdcbacb8@o506944.ingest.sentry.io/6638355",
      dist: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist,
      release: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release,
      autoSessionTracking: true,
      integrations: [
        // Track load/navigation and fetches
        new BrowserTracing(),
        // Automatically capture exceptions for console.error() calls
        new CaptureConsole({
          levels: ["error"],
        }),
      ],
      tracesSampleRate: 0.5,
      sampleRate: 1.0,
      environment: ENV,
    });
  }
}

/**
 * Set the User for Sentry.
 */
export function identifyUser(user: User): void {
  if (ERROR_MONITORING_ENABLED) {
    if (user.sub) {
      Sentry.setUser({ id: user.sub, email: user.email });
    } else {
      Sentry.captureException(
        new Error("Auth0 User object is missing `user.sub` property."),
      );
    }
  }
}
