/**
 * Wrapper component for react-vega to apply consistent configuration.
 */
import { Vega } from "react-vega";
import { VegaProps } from "react-vega/lib/Vega";
import { getLogLevel } from "./utils";

export default function VegaLite(
  props: Omit<VegaProps, "logLevel" | "actions">,
) {
  return <Vega {...props} logLevel={getLogLevel()} actions={false} />;
}
