import { css } from "aphrodite";
import { useRef, useState } from "react";
import { Overlay } from "react-overlays";
import DownCaret from "../../../icons/DownCaret.svg";
import { shared } from "../../../megamap-styles";
import DropdownList from "../../Dropdown/DropdownList";
import { OverlayBody } from "../OverlayParent";
import { TypedColumn } from "../backend-types";

export default function FilterableSelector({
  column,
  columns,
  onChange,
}: {
  column: TypedColumn;
  columns: TypedColumn[];
  onChange: (column: TypedColumn) => void;
}) {
  const triggerRef = useRef(null);
  const containerRef = useRef(null);
  const overlayContainerRef = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div className={"tw-flex-auto tw-flex"}>
      <div
        ref={containerRef}
        className={
          "tw-flex-auto tw-flex tw-flex-col tw-relative tw-overflow-visible"
        }
      >
        <div
          className={
            "tw-cursor-pointer tw-flex tw-flex-auto tw-items-center tw-justify-end tw-px-1 tw-rounded " +
            css(shared.darken1Hover, shared.linkQuiet, shared.textBlueFocus)
          }
          onClick={() => setIsOpen(true)}
          ref={triggerRef}
          role={"button"}
          aria-expanded={isOpen}
          tabIndex={0}
        >
          <div className={"tw-truncate tw-flex-auto"}>{column.name}</div>
          <div className={"tw-flex-none tw-items-center tw-ml-1"}>
            <DownCaret />
          </div>
        </div>
        <div className="tw-relative" ref={overlayContainerRef}>
          <Overlay
            placement={"bottom-start"}
            show={isOpen}
            target={triggerRef}
            onHide={() => setIsOpen(false)}
            container={overlayContainerRef}
            rootClose
          >
            {({ props }) => (
              <OverlayBody {...props} minWidth={80}>
                <DropdownList
                  items={columns.map((column) => ({
                    ...column,
                    title: column.name,
                  }))}
                  onClick={(item) => {
                    onChange(item as unknown as TypedColumn);
                    setIsOpen(false);
                  }}
                  defaultValue={column.id}
                  searchable
                  placeholder={"Find a field"}
                />
              </OverlayBody>
            )}
          </Overlay>
        </div>
      </div>
    </div>
  );
}
