import { useMemo } from "react";
import { DatasetId } from "src/types";
import { Fetchable } from "@spring/core/result";
import { chooseImageSet } from "../imaging/image-sets";
import {
  Field,
  ImageSet,
  Palette,
  PlateStains,
  ProcessingTechnique,
  Timepoint,
} from "../imaging/types";
import {
  CanSkip,
  UseDatasetLikeApiOptions,
  makeImmunofluorescenceApi,
} from "./api";

/**
 * Hook to fetch list of acquisitions in a given dataset.
 */
export const useAcquisitions =
  makeImmunofluorescenceApi("acquisitions")<string[]>();

/**
 * Hook to fetch list of wells in a given acquisition.
 */
export const useWells = makeImmunofluorescenceApi("<acquisition>/wells")<
  string[]
>();

/**
 * Hook to fetch list of fields in a given acquisition.
 */
export const useFields = makeImmunofluorescenceApi("<acquisition>/fields")<
  Field[]
>();

/**
 * Hook to fetch list of timepoints in a given dataset.
 */
export const useDatasetTimepoints =
  makeImmunofluorescenceApi("timepoints")<Timepoint[]>();

/**
 * Hook to fetch list of timepoints for a given acquisition/well/field.
 */
export const useTimepoints = makeImmunofluorescenceApi(
  "<acquisition>/<well>/<field>/timepoints",
)<Timepoint[]>();

/**
 * Hook to fetch the palette for a given acquisition.
 */
export const usePalette = makeImmunofluorescenceApi(
  "<acquisition>/palette",
)<Palette>();

/**
 * Hook to fetch the palette for a given acquisition.
 */
export const usePalettes = makeImmunofluorescenceApi("palettes")<PlateStains>();

/**
 * Fetch the single-cell source image size for a given dataset.
 */
export function useSourceSize(
  apiOptions: UseDatasetLikeApiOptions = {},
): Fetchable<number> {
  return useImageSets(apiOptions)?.map((imSets) => {
    const sorted = [...imSets].sort((a, b) =>
      a.size > b.size ? -1 : a.size < b.size ? 1 : 0,
    );
    return sorted[0].size;
  });
}

/**
 * Hook to fetch list of image sets in a given dataset.
 *
 * For legacy reasons, this always returns null for subset. If you need subset,
 * use `useFullImageSets` instead.
 *
 * TODO(colin): unify these so that we only have the full variant.
 */
export const useImageSets =
  makeImmunofluorescenceApi("image_sets")<ImageSet[]>();

/**
 * Hook to fetch list of image sets in a given dataset.
 */
const useFullImageSets = makeImmunofluorescenceApi("image_sets")<ImageSet[]>(
  () => ({
    full: true,
  }),
);

/**
 * Hook to select the appropriate image set for the specified parameters.
 */
function useImageSet({
  imageSets,
  params,
  skip,
}: CanSkip<{
  imageSets: ImageSet[];
  params?: {
    imageSize?: number | null;
    processingMode?: ProcessingTechnique | null;
  };
}>): ImageSet | null {
  return skip ? null : chooseImageSet(imageSets, params ?? {});
}

/**
 * useImageSet from a choice of useImageSets() instead of manually passing them in.
 */
export function useAutoImageSet({
  params,
  ...apiOptions
}: CanSkip<{
  params: {
    imageSize?: number | null;
    processingMode?: ProcessingTechnique | null;
  };
}> &
  UseDatasetLikeApiOptions): ImageSet | null {
  const imageSets = useImageSets(apiOptions, "simple");
  return useImageSet(
    imageSets ? { imageSets, params, skip: apiOptions.skip } : { skip: true },
  );
}

/**
 * Hook to get the default mask image set for a given dataset and image size.
 * It prioritizes cell masks and falls back to nuclei masks if cell masks are not available.
 */
export function useDefaultMaskImageSet(
  dataset: DatasetId | null,
  imageSize: number | null,
): ImageSet | null {
  const allImageSets = useFullImageSets({
    dataset,
    skip: !dataset || !imageSize,
  });

  return useMemo(() => {
    if (allImageSets?.successful && imageSize) {
      return (
        getMaskImageSet(allImageSets.value, imageSize, "cells") ||
        getMaskImageSet(allImageSets.value, imageSize, "nuclei")
      );
    }
    return null;
  }, [allImageSets, imageSize]);
}

/**
 * Hook to get a specific type of mask image set for a given dataset, image size, and subset type.
 */
export function useMaskImageSetForType(
  dataset: DatasetId | null,
  imageSize: number | null,
  subset: "cells" | "nuclei" | null,
): ImageSet | null {
  const allImageSets = useFullImageSets({
    dataset,
    skip: !dataset || !imageSize || !subset,
  });

  return useMemo(() => {
    if (allImageSets?.successful && imageSize && subset) {
      return getMaskImageSet(allImageSets.value, imageSize, subset);
    }
    return null;
  }, [allImageSets, imageSize, subset]);
}

/**
 * Helper function to retrieve a mask image set based on the given subset type.
 * It selects the smallest image set larger than the given image size or the largest
 * available if none is larger.
 */
function getMaskImageSet(
  allImageSets: ImageSet[],
  imageSize: number,
  subset: "cells" | "nuclei",
): ImageSet | null {
  const maskSets = allImageSets.filter(
    (imSet) => imSet.processing === "masks" && imSet.subset === subset,
  );
  maskSets.sort((a, b) => a.size - b.size);
  const largeEnoughSets = maskSets.filter((set) => set.size >= imageSize);

  if (largeEnoughSets.length > 0) {
    return largeEnoughSets[0];
  } else if (maskSets.length > 0) {
    return maskSets[maskSets.length - 1];
  }
  return null;
}
