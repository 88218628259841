import { ComponentProps } from "react";
import { useCSVReader } from "react-papaparse";
import { DeprecatedButton } from "../../Common/DeprecatedButton";
import { CSVReaderResults } from "../types";

export function CSVUploader({
  onUploadAccepted,
  buttonText,
  buttonProps = {},
  showAcceptedFile = false,
}: {
  onUploadAccepted: (results: CSVReaderResults) => void;
  buttonText: string;
  buttonProps?: ComponentProps<typeof DeprecatedButton>;
  showAcceptedFile?: boolean;
}) {
  const { CSVReader } = useCSVReader();
  // TODO(davidsharff): ideally we could disable the button while the parse is happening
  // but I can't see how to manage that easily with react-papaparse
  return (
    <CSVReader
      onUploadAccepted={onUploadAccepted}
      config={{
        // TODO(davidsharff): modifying this prop will invalidate CSVReaderResults
        // Returns the results as an array of objects with the headers as the keys
        header: true,
      }}
    >
      {({ getRootProps, acceptedFile }: any) => (
        <>
          <DeprecatedButton {...getRootProps()} {...buttonProps}>
            {buttonText}
          </DeprecatedButton>
          {showAcceptedFile && <div>{acceptedFile && acceptedFile.name}</div>}
        </>
      )}
    </CSVReader>
  );
}
