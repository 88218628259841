/**
 * Tool to assign alpha-inclusive colors on a per-channel basis.
 */
import { AlphaPicker, HuePicker } from "react-color";
import { Color } from "src/imaging/types";
import Strut from "../../Common/Strut";

export default function OverlaySettingsTool({
  color,
  onChangeColor,
  disabled,
}: {
  color: Color;
  onChangeColor: (color: Color) => void;
  disabled: boolean;
}) {
  return (
    <div className={"tw-flex tw-flex-col tw-relative"}>
      {disabled && (
        <div className="tw-absolute tw-inset-x-0 tw-h-full tw-bg-white tw-z-10 tw-opacity-60" />
      )}
      <div className="tw-text-slate-600 tw-mb-3">Segmentation Mask</div>
      <div className={"tw-flex"}>
        <HuePicker
          height="16px"
          color={color}
          onChange={({ rgb }) =>
            onChangeColor({
              ...color,
              r: rgb.r,
              g: rgb.g,
              b: rgb.b,
            })
          }
        />
        <Strut size={16} />
        <AlphaPicker
          height="16px"
          color={color}
          onChange={({ rgb }) =>
            onChangeColor({
              ...color,
              a: rgb.a ?? 1,
            })
          }
        />
      </div>
    </div>
  );
}
