/**
 * Component to render a multi-selector as part of a filter query.
 *
 * Allows you to select any number of items from a fixed list.
 */
import { css } from "aphrodite";
import { useRef, useState } from "react";
import { Overlay } from "react-overlays";
import DownCaret from "../../../icons/DownCaret.svg";
import { DARK_GREY, shared } from "../../../megamap-styles";
import DropdownList from "../../Dropdown/DropdownList";
import { OverlayBody } from "../OverlayParent";
import { Choice } from "../backend-types";
import { findChoice } from "../utils";
import ChoiceToken from "./ChoiceToken";

export default function QuerySelectInput({
  queryText,
  choices,
  onChange,
}: {
  queryText: string;
  choices: Choice[];
  onChange: (queryText: string) => void;
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // TODO(benkomalo): handle paste events (MegaMap has this).

  return (
    <div
      ref={ref}
      tabIndex={0}
      role="button"
      aria-expanded={isOpen}
      className={
        "tw-cursor-pointer tw-flex tw-flex-auto tw-rounded tw-white tw-p-1 tw-text-sm " +
        css(
          shared.borderBlueFocus,
          shared.borderDarken2,
          shared.borderDarken3Hover,
          shared.borderThick,
          shared.noOutline,
        )
      }
      onClick={() => (isOpen ? undefined : setIsOpen(true))}
    >
      <div className={"tw-truncate tw-flex-auto"}>
        {queryText.length > 0 ? (
          <ChoiceToken>
            {findChoice(choices, queryText) || {
              id: queryText,
              name: `Unknown (${queryText})`,
              color: "foreignKey",
            }}
          </ChoiceToken>
        ) : (
          <span className={css(shared.quiet)} style={{ color: DARK_GREY }}>
            Select an option
          </span>
        )}
      </div>
      <div
        className={"tw-flex-none tw-flex tw-items-center tw-ml-0.5"}
        onClick={() => setIsOpen(!isOpen)}
      >
        <DownCaret />
      </div>
      <Overlay
        placement={"bottom-start"}
        show={isOpen}
        target={ref}
        onHide={() => setIsOpen(false)}
        rootClose
      >
        {({ props }) => (
          <OverlayBody {...props} minWidth={80}>
            <DropdownList
              items={choices.map((choice) => ({
                id: choice.id,
                title: choice.name,
                node: <ChoiceToken>{choice}</ChoiceToken>,
              }))}
              defaultValue={queryText}
              searchable
              placeholder={"Find an option"}
              onClick={(item) => {
                onChange(item.id);
                setIsOpen(false);
              }}
            />
          </OverlayBody>
        )}
      </Overlay>
    </div>
  );
}
