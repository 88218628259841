import { withAuthenticationRequired } from "@auth0/auth0-react";
import { ReactElement } from "react";
import { isTestUser } from "src/util/test-user";

export default function protectRoute(Component: () => ReactElement) {
  if (isTestUser()) {
    return Component;
  } else {
    return withAuthenticationRequired(Component);
  }
}
