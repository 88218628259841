import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { Track } from "src/Common/EventTracker/Track";
import { FullScreenLoader } from "src/Common/FullScreenLoader";
import { AppLocationId } from "src/TrackAppLocation/types";
import { useAccessToken } from "src/hooks/auth0";
import { useDefaultWorkspace } from "src/util/hooks";
import { workspaceRootUrl } from "src/util/urls";
import protectRoute from "../Auth0/protectRoute";
import { FullScreenContainer } from "../Common/FullScreenContainer";
import TrackAppLocation from "../Common/TrackAppLocation";
import ExperimentPage from "../ExperimentPage";
import ExperimentsDashboard from "../ExperimentsDashboard";
import { InsightsContextProvider } from "../Insights/context";
import PageNotFound from "../PageNotFound";
import { HOST_URL } from "../env";
import { useWorkspaces } from "../hooks/workspace";
import { RequestContext } from "../imaging/context";
import {
  useActiveExperimentFetchable,
  useActiveExperimentId,
  useActiveWorkspaceFetchable,
} from "./hooks";
import { getPreferredWorkspace } from "./util";

// Note: also requires updating list on backend
const PUBLIC_WORKSPACES = [
  "jump-cp",
  "utoronto",
  "inflammasome-benchmark",
  "chen-et-al-2023",
];

function NotFound({ message }: { message: string }) {
  return (
    <FullScreenContainer center>
      <div className={"tw-text-xl"}>{message}</div>
      <div className={"tw-text-xl"}>
        If you continue to see this contact{" "}
        <a href={"mailto:support@springdisc.com"}>support@springdisc.com</a>
      </div>
    </FullScreenContainer>
  );
}

/**
 * Homepage for a logged in user to view all of the tools available in their workspace.
 */
function Workspace() {
  const { path } = useRouteMatch();
  const { id } = useParams<{ id: string }>();
  const workspace = useActiveWorkspaceFetchable();
  const experimentId = useActiveExperimentId({ optional: true });
  const experiment = useActiveExperimentFetchable();
  const location = useLocation();

  const accessToken = useAccessToken();

  if (!workspace) {
    return <FullScreenLoader />;
  }

  if (!workspace.successful) {
    return (
      <NotFound message="Oops. Error loading workspace. Please try again later." />
    );
  }

  if (!workspace.value) {
    // Unable to resolve workspace. Could mean the user doesn't have access to it.
    // We don't disambiguate from forbidden vs missing workspace for security reasons,
    // so just show a 404 page.

    if (PUBLIC_WORKSPACES.includes(id)) {
      // ...except for our special-cased public workspaces that can be joined at will.
      const continuePath = `${location.pathname}${location.search}${location.hash}`;
      return (
        <Redirect
          to={`/explore/${id}?cont=${encodeURIComponent(continuePath)}`}
        />
      );
    }

    return (
      <FullScreenContainer center>
        <div className={"tw-text-xl"}>Unable to find workspace.</div>
        <div className={"tw-text-lg tw-mt-4"}>
          The workspace <span className={"tw-font-bold"}>{id}</span> does not
          exist or you do not have access to it.
        </div>
      </FullScreenContainer>
    );
  }

  // If we're also intending to load an experiment (i.e. we extracted an experiment id)
  // from the URL, then wait for that to be fully loaded as well
  if (experimentId && !experiment?.successful) {
    if (!experiment) {
      return <FullScreenLoader />;
    } else {
      return (
        <NotFound message="Oops. Error loading experiment. Please try again later." />
      );
    }
  }

  return (
    <InsightsContextProvider>
      <RequestContext.Provider
        value={{
          hostUrl: HOST_URL,
          requestInit: {
            headers: { Authorization: accessToken.header() },
          },
        }}
      >
        <Track global={{ workspace: workspace.value.id }}>
          <Switch>
            <Route exact path={path}>
              <TrackAppLocation
                appLocation={{
                  id: AppLocationId.ExperimentsDashboard,
                }}
              >
                <ExperimentsDashboard />
              </TrackAppLocation>
            </Route>
            <Route path={`${path}/e/:dataset`}>
              <ExperimentPage workspaceId={workspace.value.id} />
            </Route>
            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </Track>
      </RequestContext.Provider>
    </InsightsContextProvider>
  );
}

export function RedirectToDefaultWorkspace() {
  const workspaces = useWorkspaces();
  const [defaultWorkspace] = useDefaultWorkspace();

  if (!workspaces) {
    return <FullScreenLoader />;
  }

  if (
    defaultWorkspace &&
    workspaces.successful &&
    workspaces.value.some((workspace) => workspace.id === defaultWorkspace)
  ) {
    return <Redirect to={workspaceRootUrl(defaultWorkspace)} />;
  }

  // TODO(benkomalo): use localStorage to save the last workspace until we have a better
  // mechanism on the backend?
  return (
    workspaces
      .map(getPreferredWorkspace)
      // eslint-disable-next-line react/jsx-key
      .map((workspace) => <Redirect to={workspaceRootUrl(workspace.id)} />)
      .unwrap()
  );
}

export default protectRoute(Workspace);
