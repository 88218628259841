/**
 * Component to render a multi-selector as part of a filter query.
 *
 * Allows you to select any number of items from a fixed list.
 */
import { css } from "aphrodite";
import cx from "classnames";
import pluralize from "pluralize";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Overlay } from "react-overlays";
import Pill from "../../../Common/Pill";
import Strut from "../../../Common/Strut";
import { useToastContext } from "../../../Toast/context";
import DownCaret from "../../../icons/DownCaret.svg";
import { DARK_GREY, shared } from "../../../megamap-styles";
import DropdownList from "../../Dropdown/DropdownList";
import { OverlayBody } from "../OverlayParent";
import { Choice } from "../backend-types";
import { findChoice } from "../utils";
import ChoiceToken from "./ChoiceToken";

export default function QueryMultiSelectInput({
  className,
  queryText,
  choices,
  onChange,
}: {
  className?: string;
  queryText: string[];
  choices: Choice[];
  onChange: (queryText: string[]) => void;
}) {
  const ref = useRef(null);
  const [pendingQueryText, _setPendingQueryText] =
    useState<string[]>(queryText);
  const unsavedQueryText = useRef<string[] | null>(null);

  // Keep track of the most recently provided onChange handler; this lets us have a
  // stable handlers for flushing unsaved changes, toggling the open state, etc
  // and prevents our unmounting useEffect from having to fire due to the onChanged
  // handler changing
  const refOnChange = useRef(onChange);
  refOnChange.current = onChange;

  const [isOpen, _setIsOpen] = useState<boolean>(false);

  const { setToast } = useToastContext();

  const flushUnsavedChanges = useCallback(() => {
    if (unsavedQueryText.current !== null) {
      refOnChange.current(unsavedQueryText.current);
      unsavedQueryText.current = null;
    }
  }, []);

  const setPendingQueryText = useCallback((newQueryText: string[]) => {
    unsavedQueryText.current = newQueryText;
    _setPendingQueryText(newQueryText);
  }, []);

  const setIsOpen = useCallback(
    (newIsOpen: boolean) => {
      _setIsOpen(newIsOpen);
      // Save on close
      if (!newIsOpen) {
        flushUnsavedChanges();
      }
    },
    [flushUnsavedChanges],
  );

  // Save if the component becomes unmounted
  useEffect(() => () => flushUnsavedChanges(), [flushUnsavedChanges]);

  const dropdownItems = useMemo(
    () =>
      choices.map((choice) => ({
        id: choice.id,
        title: choice.name,
        node: (
          <>
            <Pill
              state={pendingQueryText.includes(choice.id) ? "on" : "off"}
              size="large"
            />
            <Strut size={"0.25rem"} />
            <ChoiceToken>{choice}</ChoiceToken>
          </>
        ),
      })),
    [choices, pendingQueryText],
  );

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const clipboardData = e.clipboardData.getData("text/plain");
    const likelyDelimiter = inferDelimiter(clipboardData);

    if (!likelyDelimiter) {
      return;
    }

    // Only prevent default if we found a delimiter and will try to auto-select values. Otherwise,
    // the paste should update the input as the new search string.
    e.preventDefault();

    const parsedData = clipboardData.split(likelyDelimiter);

    const choiceIds = parsedData.map(
      (idOrTitle) =>
        findChoiceByIdOrTitle(dropdownItems, idOrTitle) || idOrTitle,
    );

    const matchedCount = parsedData.filter((idOrTitle) =>
      findChoiceByIdOrTitle(dropdownItems, idOrTitle),
    ).length;

    setToast(
      parsedData.join("-"),
      `Matched ${matchedCount} of ${parsedData.length} ${pluralize(
        "choice",
        parsedData.length,
      )}`,
    );

    setPendingQueryText(choiceIds);
  };

  return (
    <div
      ref={ref}
      tabIndex={0}
      role="button"
      aria-expanded={isOpen}
      className={cx(
        "tw-flex tw-flex-auto tw-rounded tw-bg-white tw-cursor-pointer tw-p-1 tw-text-sm",
        css(
          shared.borderThick,
          shared.borderDarken2,
          shared.borderDarken3Hover,
          shared.borderBlueFocus,
          shared.noOutline,
        ),
        className,
      )}
      onClick={() => (isOpen ? undefined : setIsOpen(true))}
    >
      <div
        className={
          "tw-truncate tw-flex-auto tw-flex tw-flex-row tw-items-center"
        }
      >
        {pendingQueryText.length > 0 ? (
          <div
            className={cx(
              "tw-max-w-[400px] tw-overflow-hidden",
              "tw-flex tw-flex-row tw-flex-wrap tw-gap-xs",
              "tw-max-h-[60px] tw-overflow-y-auto",
            )}
          >
            {pendingQueryText.map((id) => (
              <span key={id} className={"tw-flex-none"}>
                <ChoiceToken>
                  {findChoice(choices, id) || {
                    id,
                    name: `Unknown (${id})`,
                    color: "foreignKey",
                  }}
                </ChoiceToken>
              </span>
            ))}
          </div>
        ) : (
          <span className={css(shared.quiet)} style={{ color: DARK_GREY }}>
            Select an option
          </span>
        )}
      </div>
      <div
        className={"tw-flex-none tw-flex tw-items-center tw-ml-1"}
        onClick={() => setIsOpen(!isOpen)}
      >
        <DownCaret />
      </div>
      <Overlay
        placement={"bottom-start"}
        show={isOpen}
        target={ref}
        onHide={() => setIsOpen(false)}
        rootClose
      >
        {({ props }) => (
          <OverlayBody {...props} minWidth={80}>
            <DropdownList
              items={dropdownItems}
              searchable
              placeholder={"Find an option"}
              onClick={(item) =>
                setPendingQueryText(
                  pendingQueryText.includes(item.id)
                    ? pendingQueryText.filter((id) => id !== item.id)
                    : [...pendingQueryText, item.id],
                )
              }
              onPasteSearchInput={handlePaste}
            />
          </OverlayBody>
        )}
      </Overlay>
    </div>
  );
}

function findChoiceByIdOrTitle(
  dropdownItems: { id: string; title: string }[],
  idOrTitle: string,
) {
  return dropdownItems.some(({ id }) => id === idOrTitle)
    ? idOrTitle
    : dropdownItems.find(
        ({ title }) =>
          title.toLowerCase().trim() === idOrTitle.toLowerCase().trim(),
      )?.id;
}

function inferDelimiter(input: string): RegExp | null {
  // Check for tabs, commas, and different types of line breaks:
  //  \n   Unix/Linu/Mac
  //  \r\n Windows.
  const delimiters = [/\t/, /,/, /\n/, /\r\n/];

  const mostCommon = delimiters.reduce(
    (acc, delimiter) => {
      const regex = new RegExp(delimiter, "g");
      const matches = input.match(regex);
      const count = matches ? matches.length : 0;
      if (count > acc.maxCount) {
        return { mostCommonDelimiter: delimiter, maxCount: count };
      }

      return acc;
    },
    { mostCommonDelimiter: null as RegExp | null, maxCount: 0 },
  );

  return mostCommon.mostCommonDelimiter;
}
