/**
 * Component to render an individual filter control.
 */
import { css } from "aphrodite";
import cx from "classnames";
import { useCallback, useMemo } from "react";
import { Plus } from "react-feather";
import { Button } from "src/Common/Button";
import { useFeatureSetManagementContext } from "src/FeatureSetManagementPage/context";
import { sanitizedColumn, sql, useQueryAsRecords } from "src/util/sql";
import FilterSelector from "..";
import Close from "../../../icons/Close.svg";
import { shared } from "../../../megamap-styles";
import { Choice, ColorTheme, TypedColumn } from "../backend-types";
import { Operator } from "../operations/filter-by";
import { Filter, FilterSet, isNestedFilter } from "../types";
import {
  allowsMultipleSelection,
  getDefaultFilter,
  getDefaultFilterColumn,
  requiresQueryText,
  serializeToSqlClause,
} from "../utils";
import { BooleanConditionSelector } from "./BooleanConditionSelector";
import FilterableSelector from "./FilterableSelector";
import { MultiSelectConditionSelector } from "./MultiSelectConditionSelector";
import { NumberConditionSelector } from "./NumberConditionSelector";
import { OperatorSelector } from "./OperatorSelector";
import QueryCheckboxInput from "./QueryCheckboxInput";
import QueryMultiSelectInput from "./QueryMultiSelectInput";
import QuerySelectInput from "./QuerySelectInput";
import QueryTextInput from "./QueryTextInput";
import { SelectConditionSelector } from "./SelectConditionSelector";
import { TextConditionSelector } from "./TextConditionSelector";

const DIMMED_COLOR: ColorTheme = "grayDark";

export default function FilterRow({
  index,
  depth,
  preFilter,
  filter,
  maxDepth,
  operator,
  columns,
  color,
  colorIndex,
  showColors,
  onChange,
  onChangeOperator,
  onChangeSelected,
  onRemove,
}: {
  index: number;
  // This is how deep in the nesting the row is, which lets us decide if further nesting
  // is allowed (we allow nesting up to the maxDepth)
  depth?: number;
  // How many levels deep we allow nesting
  maxDepth?: number;
  // Whatever filtering is being applied before this row
  preFilter?: FilterSet;
  filter: Filter;
  operator: Operator;
  columns: TypedColumn[];
  color?: string;
  colorIndex?: number;
  showColors?: boolean;
  onChange: (filter: Filter) => void;
  onChangeOperator: (operator: Operator) => void;
  onChangeSelected?: (index: number | null) => void;
  onRemove: () => void;
}) {
  const allowNesting =
    depth !== undefined && maxDepth !== undefined && depth < maxDepth;

  const onChangeQueryText = useCallback(
    (queryText: any) => {
      onChange({ ...filter, queryText });
    },
    [filter, onChange],
  );

  const { metadataDB } = useFeatureSetManagementContext();

  // For multiselect/select controls we can find values that will result in no
  // rows matching (we don't currently care what the count of rows is, just that at
  // least some rows exist)
  const records = useQueryAsRecords<{ value: string }>(
    metadataDB,
    preFilter && (filter.type === "multiSelect" || filter.type === "select")
      ? sql`SELECT ${sanitizedColumn(
          filter.column.id,
        )} AS value, COUNT(*) as count 
      FROM sample_metadata 
      WHERE ${serializeToSqlClause(preFilter)}
        GROUP BY ${sanitizedColumn(filter.column.id)}`
      : undefined,
  );

  const filterChoices = useMemo(() => {
    if (!records || !records.successful) {
      return (choices: Choice[]) => choices;
    }

    const valid = new Set(records.value.map((entry) => entry.value));

    return (choices: Choice[]) => {
      return choices.map((choice) =>
        valid.has(choice.id) ? choice : { ...choice, color: DIMMED_COLOR },
      );
    };
  }, [records]);

  return (
    <div
      className={cx(
        "tw-flex tw-mb-1 tw-pl-1",
        "tw-rounded-r",
        depth === 0 ? "hover:tw-bg-gray-100" : "hover:tw-bg-gray-200",
        isNestedFilter(filter) ? "tw-items-center tw-w-full" : "",
      )}
      style={{
        borderLeft: `4px solid ${color ?? "transparent"}`,
      }}
      onMouseEnter={() => onChangeSelected?.(colorIndex ?? null)}
      onMouseLeave={() => onChangeSelected?.(null)}
    >
      <div
        className={cx(
          "tw-flex tw-flex-row",
          isNestedFilter(filter) && "tw-flex-1",
        )}
      >
        <div className={"tw-flex tw-flex-none tw-items-center"}>
          <div className={"tw-flex-none tw-flex"}>
            <div
              className={
                "tw-cursor-pointer tw-flex tw-items-center tw-pr-1 " +
                css(
                  shared.linkUnquietFocusable,
                  shared.quiet,
                  shared.textBlueFocus,
                )
              }
              tabIndex={2}
              role={"button"}
              onClick={() => onRemove()}
            >
              <Close />
            </div>
          </div>
        </div>
        <div className={"tw-flex-none tw-flex"}>
          <OperatorSelector
            index={index}
            operator={operator}
            onChange={(operator) => onChangeOperator(operator)}
          />
        </div>
        <div className={"tw-flex-auto tw-flex"}>
          {isNestedFilter(filter) ? (
            <div
              className={cx(
                "tw-flex-1 tw-ml-xs tw-my-xs",
                "tw-border-slate-400 tw-border-l tw-rounded-lg",
              )}
            >
              <FilterSelector
                columns={columns}
                colorIndexOffset={(colorIndex ?? 0) + 1}
                depth={depth !== undefined ? depth + 1 : undefined}
                maxDepth={maxDepth}
                preFilter={preFilter}
                filterSet={filter.filterSet}
                showColors={showColors}
                onChangeFilters={(filters) =>
                  onChange(
                    filters.length === 1
                      ? filters[0]
                      : {
                          ...filter,
                          filterSet: { ...filter.filterSet, filters },
                        },
                  )
                }
                onChangeOperator={(operator, newFilters: Filter[] = []) =>
                  onChange({
                    ...filter,
                    filterSet:
                      operator === Operator.AND
                        ? {
                            ignoredFilters: [],
                            ...filter.filterSet,
                            operator,
                            filters: [
                              ...filter.filterSet.filters,
                              ...newFilters,
                            ],
                          }
                        : {
                            ...filter.filterSet,
                            operator,
                            filters: [
                              ...filter.filterSet.filters,
                              ...newFilters,
                            ],
                          },
                  })
                }
                onChangeSelected={onChangeSelected}
                metadata={metadataDB}
              />
            </div>
          ) : (
            <FilterableSelector
              column={filter.column}
              columns={columns}
              onChange={(column) => {
                if (
                  column.type === filter.column.type &&
                  !["select", "multiselect"].includes(column.type)
                ) {
                  // We can preserve the value if we're navigating to a different column
                  // that's the same type, and it's not a type that has a pre-enumerated
                  // set of values.
                  onChange({ ...filter, column } as Filter);
                } else {
                  // If we're going to a new type, always nuke the existing
                  onChange(getDefaultFilter(column));
                }
              }}
            />
          )}
        </div>
      </div>
      {!isNestedFilter(filter) && (
        <>
          <div className={"tw-flex tw-flex-auto"}>
            <div className={"tw-flex tw-flex-auto"}>
              {filter.type === "text" || filter.type === "multilineText" ? (
                <TextConditionSelector
                  condition={filter.condition}
                  onChange={(condition) =>
                    onChange({
                      ...filter,
                      condition,
                      queryText: requiresQueryText(condition)
                        ? filter.queryText
                        : "",
                    })
                  }
                />
              ) : null}
              {filter.type === "checkbox" ? (
                <BooleanConditionSelector condition={filter.condition} />
              ) : null}
              {filter.type === "number" ? (
                <NumberConditionSelector
                  condition={filter.condition}
                  onChange={(condition) =>
                    onChange({
                      ...filter,
                      condition,
                      queryText: requiresQueryText(condition)
                        ? filter.queryText
                        : "",
                    })
                  }
                />
              ) : null}
              {filter.type === "select" ? (
                <SelectConditionSelector
                  condition={filter.condition}
                  onChange={(condition) => {
                    // Determine if we can preserve the queryText or have to clear it out...
                    let queryText: number | string | (number | string)[];
                    if (allowsMultipleSelection(condition)) {
                      if (allowsMultipleSelection(filter.condition)) {
                        // If the previous and new condition allows multiple selection,
                        // keep it as is!
                        queryText = filter.queryText;
                      } else if (
                        requiresQueryText(filter.condition) &&
                        String(filter.queryText).length
                      ) {
                        // Going for single to multi converts to a singular list.
                        if (typeof filter.queryText === "number") {
                          queryText = [filter.queryText as number];
                        } else {
                          queryText = [filter.queryText as string];
                        }
                      } else {
                        // ...and otherwise we clear.
                        queryText = [];
                      }
                    } else {
                      if (
                        requiresQueryText(condition) &&
                        !allowsMultipleSelection(filter.condition)
                      ) {
                        // Going to a single selection can only preserve the value if we're
                        // also coming from a single selection.
                        queryText = filter.queryText;
                      } else {
                        queryText = "";
                      }
                    }
                    onChange({
                      ...filter,
                      condition,
                      queryText,
                    });
                  }}
                />
              ) : null}
              {filter.type === "multiSelect" ? (
                <MultiSelectConditionSelector
                  condition={filter.condition}
                  onChange={(condition) =>
                    onChange({
                      ...filter,
                      condition,
                      queryText: requiresQueryText(condition)
                        ? filter.queryText
                        : [],
                    })
                  }
                />
              ) : null}
              {!isNestedFilter(filter) &&
              requiresQueryText(filter.condition) ? (
                <div className="tw-flex-1 tw-flex tw-flex-row tw-items-center">
                  {filter.type === "multiSelect" ||
                  (filter.type === "select" &&
                    allowsMultipleSelection(filter.condition)) ? (
                    <QueryMultiSelectInput
                      className="tw-flex-1"
                      key={filter.column.id}
                      queryText={filter.queryText as string[]}
                      onChange={onChangeQueryText}
                      choices={filterChoices(filter.column.typeOptions.choices)}
                    />
                  ) : filter.type === "select" ? (
                    <QuerySelectInput
                      key={filter.column.id}
                      queryText={filter.queryText as string}
                      onChange={(queryText) =>
                        onChange({ ...filter, queryText })
                      }
                      choices={filterChoices(filter.column.typeOptions.choices)}
                    />
                  ) : filter.type === "checkbox" ? (
                    <QueryCheckboxInput
                      key={filter.column.id}
                      options={filter.column.typeOptions}
                      queryText={filter.queryText}
                      onChange={(queryText) =>
                        onChange({ ...filter, queryText })
                      }
                    />
                  ) : (
                    <QueryTextInput
                      key={filter.column.id}
                      queryText={filter.queryText}
                      onChange={(queryText) =>
                        onChange({ ...filter, queryText })
                      }
                    />
                  )}
                </div>
              ) : null}
            </div>
          </div>
          {allowNesting && (
            <>
              <Button
                size="sm"
                className="tw-text-slate-500 tw-ml-sm tw-h-[32px]"
                icon={Plus}
                onClick={() =>
                  onChange({
                    type: "nested",
                    filterSet: {
                      operator: Operator.AND,
                      filters: [
                        filter,
                        getDefaultFilter(
                          getDefaultFilterColumn(columns, [filter]),
                        ),
                      ],
                      ignoredFilters: [],
                    },
                  })
                }
                disableTracking={true}
              >
                And
              </Button>
              <Button
                size="sm"
                className="tw-text-slate-500 tw-ml-sm tw-h-[32px]"
                icon={Plus}
                onClick={() =>
                  onChange({
                    type: "nested",
                    filterSet: {
                      operator: Operator.OR,
                      filters: [
                        filter,
                        getDefaultFilter(
                          getDefaultFilterColumn(columns, [filter]),
                        ),
                      ],
                    },
                  })
                }
                disableTracking={true}
              >
                Or
              </Button>
            </>
          )}
        </>
      )}
    </div>
  );
}
