import React, { useContext } from "react";
import {
  ColumnsLookupFn,
  FeatureSetSelection,
} from "src/FeatureSelector/types";
import { FeatureSetsByType } from "src/FeatureSelector/utils";

interface FeatureSelectionContextValue {
  allFeatures: FeatureSetsByType;
  selections: FeatureSetSelection[];
  updateSelections: (
    selections: FeatureSetSelection[],
    getColumns: ColumnsLookupFn,
  ) => void;
}

const FeatureSelectionContext =
  React.createContext<FeatureSelectionContextValue | null>(null);

export const FeatureSelectionContextProvider = FeatureSelectionContext.Provider;

export function useFeatureSelection(): FeatureSelectionContextValue {
  const value = useContext(FeatureSelectionContext);

  if (!value) {
    throw new Error("Missing FeatureSelectionContext.Provider");
  }

  return value;
}
