import { Workspace } from "./types";

export function getPreferredWorkspace(workspaces: Workspace[]): Workspace {
  if (!workspaces.length) {
    throw new Error("No workspace available");
  }

  const nonPublic = workspaces.filter(
    ({ id }) => !["public", "public-demo"].includes(id),
  );
  if (!nonPublic.length) {
    return workspaces[0];
  }

  // These are "remaps" where a pilot gets graduated to a "real" workspace. In these
  // cases, if the user has access to the former, prefer the latter if they also have
  // access to it.
  const remaps: { [id: string]: string } = {
    "jump-cp-demo": "jump-cp",
    public: "public-demo",
  };

  // Bias towards the latest workspace, which likely reflects the most active work
  // for a user.
  const mostRecent = nonPublic[nonPublic.length - 1];
  if (mostRecent.id in remaps) {
    const preferred = nonPublic.find(({ id }) => id === remaps[mostRecent.id]);
    if (preferred) {
      return preferred;
    }
  }
  return mostRecent;
}
