import { memo, useCallback } from "react";
import { Tooltip } from "@spring/ui/Tooltip";
import { CHART_HEIGHT_PX, PVALUE_SIGNIFICANCE_THRESHOLD } from "../constants";
import { ClusterFeatureData } from "../types";

function BaseFeatureBoxPlots({
  data,
  xScale,
  yScale,
  numTotalRows,
  shouldHighlightMeaningfulMetadata,
}: {
  data: ClusterFeatureData[];
  xScale: d3.ScaleLinear<number, number>;
  yScale:
    | d3.ScaleOrdinal<string, number>
    | d3.ScaleBand<string>
    | ((value: string) => number);
  numTotalRows: number;
  shouldHighlightMeaningfulMetadata: boolean;
}) {
  return (
    <>
      {data.map((clusterData) => {
        const y = yScale(clusterData.clusterName);

        // If we just changed the number of clusters, the dendrogram might not be finished
        // rendering yet, so we don't know where the clusters are – skip drawing those bars
        if (y === undefined) {
          return;
        }

        return (
          <div key={clusterData.clusterName}>
            {/* Min/max line */}
            <svg
              className="tw-absolute tw-w-full"
              height={2}
              style={{
                top: y + CHART_HEIGHT_PX / 2,
              }}
            >
              <g>
                <line
                  className="tw-stroke-slate-300 tw-stroke-2"
                  x1={xScale(clusterData.min)}
                  x2={xScale(clusterData.max)}
                  y1={0}
                  y2={0}
                />
              </g>
            </svg>

            <FeatureBoxPlot
              xScale={xScale}
              y={y}
              data={clusterData}
              numTotalRows={numTotalRows}
              shouldHighlightMeaningfulMetadata={
                shouldHighlightMeaningfulMetadata
              }
            />

            {/* Median line */}
            <svg
              className="tw-absolute"
              width={2}
              height={CHART_HEIGHT_PX}
              style={{
                top: y,
                left: xScale(clusterData.median),
              }}
            >
              <g>
                <line
                  className="tw-stroke-slate-200 tw-stroke-2"
                  x1={0}
                  x2={0}
                  y1={0}
                  y2={CHART_HEIGHT_PX}
                />
              </g>
            </svg>
          </div>
        );
      })}
    </>
  );
}
export const FeatureBoxPlots = memo(BaseFeatureBoxPlots);

function FeatureBoxPlot({
  xScale,
  y,
  data,
  numTotalRows,
  shouldHighlightMeaningfulMetadata,
}: {
  xScale: d3.ScaleLinear<number, number>;
  y: number;
  data: ClusterFeatureData;
  numTotalRows: number;
  shouldHighlightMeaningfulMetadata: boolean;
}) {
  const getOpacityForPValue = useCallback(
    (uncorrectedPValue: number) => {
      return uncorrectedPValue <= PVALUE_SIGNIFICANCE_THRESHOLD / numTotalRows
        ? 1
        : 0.1;
    },
    [numTotalRows],
  );

  const correctedPValue = data.uncorrectedPValue * numTotalRows;

  return (
    <div
      className="tw-absolute"
      style={{
        top: y,
        left: xScale(data.q1),
      }}
    >
      <Tooltip
        contents={
          <div>
            <table className="tw-border-separate tw-border-spacing-y-0 tw-border-spacing-x-sm">
              <tbody>
                <tr>
                  <td>Q1</td>
                  <td>{data.q1.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Q3</td>
                  <td>{data.q3.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Median</td>
                  <td>{data.median.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Mean</td>
                  <td>{data.mean.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>
                    <i>p</i>-value
                  </td>
                  <td>
                    {correctedPValue === 0
                      ? 0
                      : correctedPValue < 0.001
                        ? correctedPValue.toExponential(3)
                        : correctedPValue.toFixed(3)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }
        showArrow={true}
      >
        <div
          className="tw-cursor-default tw-bg-blue"
          style={{
            height: CHART_HEIGHT_PX,
            width: xScale(data.q3) - xScale(data.q1),
            opacity: shouldHighlightMeaningfulMetadata
              ? getOpacityForPValue(data.uncorrectedPValue)
              : 1,
          }}
        />
      </Tooltip>
    </div>
  );
}
