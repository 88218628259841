import cx from "classnames";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { DeprecatedButton } from "../Common/DeprecatedButton";
import { colors } from "../util/styles";
import { useToastContext } from "./context";

// TODO(davidsharff): support stacked toasts
// TODO(davidsharff): props that would be nice to support in the future
//   position: "top-left" | "top-center" | "top-right" | "bottom-left" etc.
//   type: "info" | "warning" | "error"
//   fullWidth: boolean
//   delay: number
//   onClear: () => void // This could replace disableAutoDismiss
//   onClose: () => void

// The id is used to render toasts in one-off containers.
export default function ToastContainer({
  id = "global",
  position = "default",
  disableAutoDismiss = false,
}: {
  id?: string;
  position?: "default" | "top" | "custom";
  disableAutoDismiss?: boolean;
}) {
  const { toast, dismissToast } = useToastContext();
  if (toast && toast.targetContainerId === id) {
    const { key, contents } = toast;
    return (
      <div
        className={cx(
          "tw-flex tw-items-center tw-px-4 tw-py-2 tw-my-2 tw-mx-4",
          "tw-border tw-rounded-lg tw-shadow-lg tw-absolute tw-bg-white",
          position === "default" && "tw-right-4 tw-bottom-5",
          position === "top" && "tw-top-4 tw-left-[50%] tw-translate-x-[-50%]",
          "tw-text-slate-500",
        )}
      >
        <div className={"tw-flex-1"}>{contents}</div>
        <div className="tw-ml-2">
          {disableAutoDismiss ? (
            <DeprecatedButton
              className={"tw-px-4 tw-py-1 tw-bg-gray-200"}
              onClick={() => dismissToast(key)}
            >
              Clear
            </DeprecatedButton>
          ) : (
            <CountdownCircleTimer
              isPlaying
              duration={5}
              colors={[
                colors.purple,
                colors.magenta,
                colors.coral,
                colors.yellow,
              ]}
              colorsTime={[5, 3, 2, 0]}
              onComplete={() => {
                dismissToast(key);
                // Note(davidsharff): redundant but leaving here because it is a non-obvious API and
                // has additional options.
                return { shouldRepeat: false };
              }}
              size={25}
              strokeWidth={3}
            />
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
}
