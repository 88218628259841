import natsort from "natsort";

/**
 * A case-insensitive comparator that considers numerical values in strings.
 * Delegates to natsort.
 */
export const defaultComparator = natsort({
  insensitive: true,
});

/**
 * A comparator function to sort metadata column names.
 */
export function columnComparator(a: string, b: string): number {
  // Field is generally not included but may be present in select views (see Compare Images)
  for (const priorityColumn of ["plate", "well", "field", "treatment_id"]) {
    if (a === priorityColumn) {
      return -1;
    } else if (b === priorityColumn) {
      return 1;
    }
  }
  return defaultComparator(a, b);
}
