import {
  Bounds,
  RenderOptions,
  Renderer,
  RendererOptions,
  ScatterPlotPoint,
  ScatterPlotPointState,
} from "./types";
import { drawCircle, initCanvas, toPixel } from "./util";

interface RenderBackgroundOptions extends RendererOptions {
  canvas: HTMLCanvasElement;
  visiblePoints: ScatterPlotPoint[];
  pointStates: Record<string, ScatterPlotPointState>;
  pointSize: number;

  viewBounds: Bounds;

  canvasWidth: number;
  canvasHeight: number;
}

/**
 * Our strategy for highlighting cells is to draw them at the same position, at a larger
 * size or in a different color.  We can draw all of the cells once (in their unhighlighted
 * state) and then layer any highlights on top of it.
 *
 * We only need to re-draw the background in cases where the positions of the points change
 * relative to each other, i.e. when the size of the canvas changes and not during any
 * of the higher frequency events, like selections, group hovers or hovering over a point
 * or when the list of points changes (e.g. when some of them are hidden)
 *
 * When necessary, we can "fade out" all of the background by keeping the same rendering
 * and changing the opacity of the associated canvas element
 */
function renderBackground(options: RenderBackgroundOptions) {
  const { canvas, pointSize, visiblePoints, pointStates } = options;

  const ctx = initCanvas(canvas, options);
  if (!ctx) {
    return;
  }

  for (const pt of visiblePoints) {
    const { x, y } = toPixel(pt, options);
    drawCircle({
      ctx,
      x,
      y,
      color: pointStates[pt.key].color,
      pointSize,
    });
  }
}

export function BackgroundRenderer(
  refCanvas: React.RefObject<HTMLCanvasElement | null>,
): Renderer<RenderOptions, RendererOptions> {
  return {
    options: ({
      visiblePoints,
      pointStates,
      pointSize,
      viewBounds,
      canvasWidth,
      canvasHeight,
      initializedCanvas,
      initializedData,
    }): RenderBackgroundOptions | null =>
      refCanvas.current && initializedCanvas && initializedData
        ? {
            canvas: refCanvas.current,
            visiblePoints,
            pointStates,
            pointSize,
            viewBounds,
            canvasWidth,
            canvasHeight,
          }
        : null,
    render: renderBackground,
  };
}
