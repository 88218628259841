import cx from "classnames";
import React, {
  ForwardedRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Search } from "react-feather";
import { useDatasets } from "src/hooks/datasets";
import { WorkspaceId } from "src/types";
import { trackEvent } from "../analytics";
import { defaultComparator } from "../util/sorting";
import {
  DB,
  RecordsArray,
  ValidatedSQL,
  getTableColumns,
  queryDBAsRecords,
  sql,
  useThrottledCacheState,
} from "../util/sql";
import { SearchResultGroup, SearchResults } from "./types";
import { experimentsGroup, tokenize } from "./utils";

/**
 * Build a SQL sub-query on a single token of a search term.
 */
function queryForMetaDataColumn(
  column: string,
  term: string,
  scoreBase?: number,
): ValidatedSQL {
  if (scoreBase == undefined) {
    scoreBase = 0;
  }

  // Cast everything to lower case to simulate case-insensitive search.
  term = term.toLowerCase();

  // TODO(benkomalo): ideally we can use natsort to have a more sane ORDER BY result
  // (right now this returns "Compound-1, Compound-10, Compound-2, Compound-3, ...").

  // Use a simple ranking of strict-prefixing being the most important, then
  // prefixes where a match is found at the beginning of a token.
  return sql`SELECT
         "${column}",
         CASE WHEN LOWER("${column}") LIKE '${term}%' THEN ${scoreBase + 3}
              WHEN LOWER("${column}") LIKE '% ${term}%' THEN ${scoreBase + 2}
              WHEN LOWER("${column}") LIKE '%(${term}%' THEN ${scoreBase + 2}
              WHEN LOWER("${column}") LIKE '%-${term}%' THEN ${scoreBase + 2}
              WHEN LOWER("${column}") LIKE '%_${term}%' THEN ${scoreBase + 1}
         END AS score
     FROM search_index 
     WHERE LOWER("${column}") LIKE '%${term}%'
     GROUP BY "${column}"
     ORDER BY score DESC, "${column}"
     `;
}

/**
 * Build a SQL query based on the search query.
 */
function queryforSearch(column: string, search: string): ValidatedSQL {
  // First, tokenize the query naively. This makes it so that each token can be
  // used as an independent term, and each one can be a prefixer.
  const tokens = tokenize(search);

  // For each term, create a subquery to match on it and join them all together
  // and re-rank based on total score, but prioritize the main, untokenized term.
  // This makes it so that "tyrosine kin" will get prioritized over the "kin" prefix
  // (matching all kinases), but "tyro kin" still can help match "TYRosine KINase".
  const subQueries = [
    `(${queryForMetaDataColumn(column, search.trim(), 10)})`,
    ...tokens.map((token) => `(${queryForMetaDataColumn(column, token)})`),
  ];

  return sql`SELECT "${column}", SUM(score) AS total_score FROM (
    ${subQueries.join(" UNION ALL ")}
    ) GROUP BY "${column}" ORDER BY total_score DESC`;
}

function convertRawResultsToGroup(
  results: RecordsArray,
  column: string,
): SearchResultGroup {
  // All search entries are coerced to strings for convenience.
  const entries = results.map((row) => String(row[column]));
  entries.sort(defaultComparator);
  return {
    type: "metadata",
    resultType: column,
    column,
    entries,
  };
}

/**
 * A readonly version of the search box, styled in a similar way and sized identically.
 *
 * This is used to serve as a "placeholder" for the search input when the details
 * view is opened.
 */
export function ReadonlySearchBox({
  value,
  onBack,
}: {
  value: string;
  onBack: () => void;
}) {
  return (
    <div className={"tw-relative tw-py-4"}>
      <input
        className={cx(
          "tw-text-lg tw-p-2 tw-pl-12 tw-pr-8 tw-shadow tw-w-full",
          "tw-rounded-lg tw-border",
        )}
        value={value}
        disabled
      />
      <button
        aria-label={"Back to all results"}
        className={cx(
          "tw-flex tw-items-center tw-justify-center",
          "tw-w-[24px] tw-h-[28px]",
          "tw-absolute tw-left-4 tw-top-[50%] tw--mt-[14px] ",
          "tw-text-lg tw-bg-slate-100 tw-text-black",
        )}
        onClick={onBack}
      >
        &lt;
      </button>
    </div>
  );
}

const SearchInputBox = React.forwardRef(function _SearchInputBox(
  {
    onResultsChange,
    resultFocusId,
    db,
    workspaceId,
    onSearchIndexRequested,
    onFocus,
    onCancelSearch,
  }: {
    onResultsChange: (results: SearchResults | "loading" | null) => void;
    resultFocusId: string | null;
    db: DB | null;
    workspaceId: WorkspaceId;
    onSearchIndexRequested: () => void;
    onFocus?: () => void;
    onCancelSearch?: () => void;
  },
  ref: ForwardedRef<HTMLInputElement>,
) {
  const hasTriggeredAnalytics = useRef<boolean>(false);
  const [query, _setQuery] = useState<string | null>(null);
  const setQuery = useCallback(
    (query: string) => {
      if (!hasTriggeredAnalytics.current && query.trim().length > 0) {
        trackEvent({ id: "Search query enter" });
        hasTriggeredAnalytics.current = true;
      }
      _setQuery(query);
    },
    [_setQuery, hasTriggeredAnalytics],
  );

  const datasets = useDatasets({ workspace: workspaceId });

  // Execute the query stream.
  const hasDb = !!db;
  const searchFetchable = useThrottledCacheState<[string[], RecordsArray[]]>(
    `${query};;${hasDb}`,
    async () => {
      if (db && query && query.trim().length > 0) {
        const columns = await getTableColumns(db, "search_index");
        const searchColumns = columns.filter(
          (col) => !["dataset", "plate", "well"].includes(col),
        );
        const trimmed = query.trim();
        return [
          searchColumns,
          await Promise.all(
            searchColumns.map((column) =>
              queryDBAsRecords(db, queryforSearch(column, trimmed)),
            ),
          ),
        ];
      } else {
        return undefined;
      }
    },
  );

  useEffect(() => {
    if (query && !hasDb) {
      onResultsChange("loading");
    } else {
      if (query && searchFetchable.result?.successful && datasets?.successful) {
        const [searchColumns, rawResults] = searchFetchable.result.value;
        onResultsChange({
          query,
          groups: [
            experimentsGroup({ query, datasets: datasets.value, workspaceId }),
            ...rawResults.map((subResult, i) =>
              convertRawResultsToGroup(subResult, searchColumns[i]),
            ),
          ].filter((group) => group.entries.length > 0),
        });
      } else {
        onResultsChange(null);
      }
    }
  }, [query, hasDb, searchFetchable, onResultsChange, datasets, workspaceId]);

  return (
    <div className={"tw-relative"}>
      <input
        ref={ref}
        className={cx(
          "tw-shadow tw-w-full",
          "tw-text-lg sm:tw-text-base",
          "tw-py-2 tw-px-8 sm:tw-px-2",
          "tw-rounded-lg tw-border focus:tw-border-purple focus:tw-shadow-md tw-outline-none",
        )}
        onFocus={() => {
          // Everytime we re-focus, we reset analytics tracking so we can send a
          // new event.
          hasTriggeredAnalytics.current = false;
          onSearchIndexRequested();
          onFocus && onFocus();
        }}
        onMouseEnter={() => onSearchIndexRequested()}
        placeholder={"Search across all experiments..."}
        value={query || ""}
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={(e) => {
          switch (e.key) {
            case "Escape":
              // TODO(benkomalo): we shouldn't be able to do this when the "details"
              // page is open – escape on the details page should go "up" to the top
              // level listing.
              onCancelSearch && onCancelSearch();
              break;
          }
        }}
        aria-activedescendant={resultFocusId || undefined}
      />
      <Search
        size={16}
        className={
          "tw-absolute tw-right-8 tw-top-[50%] tw-mt-[-8px] tw-text-slate-500"
        }
      />
    </div>
  );
});
export default SearchInputBox;
