import cx from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  CornerDownLeft,
  Info,
  Minus,
  Plus,
  RotateCcw,
  Sliders,
} from "react-feather";
import { IoSparkles } from "react-icons/io5";
import { Button } from "src/Common/Button";
import { Select } from "src/Common/Select";
import { usePhenoFinderContext } from "src/PhenoFinder/Context";
import {
  findLeafWithNextSplit,
  findNodeWithNextCombine,
  getTreeWithModifications,
} from "src/PhenoFinder/treeUtils";
import {
  BranchDataNode,
  DataNode,
  LeafDataNode,
  TreeModificationStep,
  VisualizationColumn,
} from "src/PhenoFinder/types";
import { getChartData } from "src/PhenoFinder/utils";
import { DatasetId } from "src/types";
import invariant from "tiny-invariant";
import { Checkbox } from "@spring/ui/Checkbox";
import { Tooltip } from "@spring/ui/Tooltip";
import { Caption, Label, Text } from "@spring/ui/typography";
import { CollapsibleFilterSelector } from "../../Control/FilterSelector";
import { FilterSet } from "../../Control/FilterSelector/types";
import {
  updateFilters,
  updateOperator,
  validateFilter,
} from "../../Control/FilterSelector/utils";
import { styledStains } from "../../FeatureSelector/MeasurementsDetails";
import { PulseGuiderRoot } from "../../Insights/PulseGuider";
import { ImageSet } from "../../imaging/types";
import { DEFAULT_CUT_TREE_NAME } from "../constants";
import { useStains } from "../hooks";
import { PublishButton } from "./PublishButton";

export function ControlBar({
  dataset,
  imageSet,
  className,
  onToggleImageSettings,
  onToggleHighlightMetadata,
  shouldHighlightMetadata,
  onModifyTree,
  onHoverDataNode,
  onChangeFilter,
}: {
  dataset: DatasetId;
  imageSet: ImageSet | null;
  className?: string;
  onToggleImageSettings: () => void;
  onToggleHighlightMetadata: (shouldHighlight: boolean) => void;
  shouldHighlightMetadata: boolean;
  onModifyTree: (
    modification: TreeModificationStep,
    shouldScrollToNewClusters?: boolean,
  ) => void;
  onHoverDataNode: (node: DataNode | null) => void;
  onChangeFilter: (filter: FilterSet) => void;
}) {
  const [state, dispatch] = usePhenoFinderContext();
  const [hoveredButton, setHoveredButton] = useState<"plus" | "minus" | null>(
    null,
  );
  const {
    loaded,
    numClusters,
    fullTree,
    currentBaseTree,
    currentTree,
    modifications,
    sampleMetadataDB,
    featureDB,
    filterColumns,
    selectedVisualizationColumn,
    featureColumns,
    maxTreeLevel,
    filterSet,
  } = state;

  const metadataColumns = useMemo(
    () =>
      filterColumns !== null ? filterColumns.map((column) => column.id) : [],
    [filterColumns],
  );
  invariant(loaded, "Showing ControlBar before PhenoFinder state has loaded");
  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const selectedColumn = selectedVisualizationColumn ?? {
    name: metadataColumns[0],
    type: "metadata",
  };
  const validateAndSetFilter = (filter: FilterSet) => {
    validateFilter(sampleMetadataDB, filter).then((filter) => {
      onChangeFilter(filter);
    });
  };

  const handleChangeNumClusters = useCallback(
    async (newClusterNumber: number) => {
      // TODO(you): Fix this no-unnecessary-condition rule violation
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (currentTree === null || numClusters < 2) {
        return;
      }
      if (newClusterNumber > numClusters) {
        const node = findLeafWithNextSplit(currentTree);
        onModifyTree({ type: "split", nodeName: node.name }, true);
      } else {
        const node = findNodeWithNextCombine(currentTree);
        onModifyTree({ type: "combine", nodeName: node.name }, true);
      }
    },
    [currentTree, numClusters, onModifyTree],
  );

  const handleUndoModification = useCallback(async () => {
    // To undo, remove the last modification and re-apply the stack to the base tree
    // (Or, if no modifications, just use the base tree)
    const newModifications = modifications.slice(0, -1);
    const newTree =
      newModifications.length === 0
        ? structuredClone(currentBaseTree)
        : getTreeWithModifications(
            // Clone the tree so that we modify a new copy
            structuredClone(currentBaseTree),
            fullTree,
            modifications.slice(0, -1),
          );

    const chartData = await getChartData(
      sampleMetadataDB,
      featureDB,
      selectedVisualizationColumn,
      newTree,
      filterSet,
    );

    dispatch({
      type: "updateCurrentTree",
      modifications: newModifications,
      newTree,
      shouldScrollToNewClusters: true,
      chartData: chartData,
    });
  }, [
    dispatch,
    fullTree,
    featureDB,
    currentBaseTree,
    modifications,
    sampleMetadataDB,
    selectedVisualizationColumn,
    filterSet,
  ]);

  const handleReset = useCallback(async () => {
    invariant(loaded, "Unexpectedly resetting when data hasn't been loaded");

    const newModifications: TreeModificationStep[] = [];
    const newTree = structuredClone(currentBaseTree);
    const chartData = await getChartData(
      sampleMetadataDB,
      featureDB,
      selectedVisualizationColumn,
      newTree,
      filterSet,
    );
    dispatch({
      type: "updateCurrentTree",
      modifications: newModifications,
      newTree,
      chartData: chartData,
    });
  }, [
    dispatch,
    currentBaseTree,
    loaded,
    sampleMetadataDB,
    featureDB,
    selectedVisualizationColumn,
    filterSet,
  ]);

  const handleSelectColumn = useCallback(
    async (selectedColumn: string) => {
      // The selector shouldn't be available until these have been loaded
      // TODO(you): Fix this no-unnecessary-condition rule violation
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!sampleMetadataDB || !currentTree) {
        return;
      }
      const newColumn: VisualizationColumn = {
        name: selectedColumn,
        type: metadataColumns.includes(selectedColumn) ? "metadata" : "feature",
      };
      const newChartData = await getChartData(
        sampleMetadataDB,
        featureDB,
        newColumn,
        currentTree,
        filterSet,
      );

      dispatch({
        type: "selectColumn",
        selectedColumn: newColumn,
        chartData: newChartData,
      });
    },
    [
      dispatch,
      sampleMetadataDB,
      featureDB,
      currentTree,
      filterSet,
      metadataColumns,
    ],
  );
  const nextCombineNode: BranchDataNode | null = useMemo(() => {
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return currentTree !== null ? findNodeWithNextCombine(currentTree) : null;
  }, [currentTree]);

  const nextSplitNode: LeafDataNode | null = useMemo(() => {
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return currentTree !== null ? findLeafWithNextSplit(currentTree) : null;
  }, [currentTree]);

  useEffect(() => {
    if (hoveredButton === "plus") {
      onHoverDataNode(nextSplitNode);
    } else if (hoveredButton === "minus") {
      onHoverDataNode(nextCombineNode);
    } else {
      onHoverDataNode(null);
    }
  }, [
    numClusters,
    onHoverDataNode,
    setHoveredButton,
    nextSplitNode,
    hoveredButton,
    nextCombineNode,
  ]);

  const selectorGroups = useMemo(() => {
    const groups: { id: string; items: any }[] = [
      { id: "Metadata", items: metadataColumns },
    ];
    if (featureColumns) {
      if (featureColumns.length > 0) {
        groups.push({
          id: "Nuclei Features",
          items: featureColumns,
        });
      }
    } else {
      groups.push({
        id: "Nuclei Features",
        items: [{ value: "", text: "Loading...", disabled: true }],
      });
    }

    return groups;
  }, [featureColumns, metadataColumns]);

  const stains = useStains({ dataset, cutTree: DEFAULT_CUT_TREE_NAME });

  const handleHoveredButton = useCallback(
    async (hoveredButton: "plus" | "minus" | null) => {
      setHoveredButton(hoveredButton);
      if (hoveredButton === "plus") {
        onHoverDataNode(nextSplitNode);
      } else if (hoveredButton === "minus") {
        onHoverDataNode(nextCombineNode);
      } else {
        onHoverDataNode(null);
      }
    },
    [onHoverDataNode, nextCombineNode, nextSplitNode],
  );
  return (
    <div className={cx(className, "tw-fixed tw-z-10", "tw-w-full")}>
      <div
        className={cx(
          "tw-flex tw-items-center tw-justify-between",
          "tw-bg-white tw-p-md",
          "tw-border-b tw-border-slate-300",
        )}
      >
        <div className="tw-flex tw-space-x-sm tw-items-center">
          <div
            className={
              "tw-border-r-2 tw-flex tw-justify-end tw-items-center tw-px-md "
            }
          >
            <div className=" tw-min-w-[100px]">
              <Text>{numClusters} Clusters</Text>
            </div>
            <div>
              <Tooltip
                contents={
                  <div className={"tw-p-md tw-w-[300px]"}>
                    <div className={"tw-text-base tw-pb-md"}>
                      This clustering was performed using the following stains
                      as inputs:
                    </div>
                    {stains?.successful
                      ? styledStains({ stains: stains.value })
                      : "Uh oh can't load the stains... try refreshing the page!"}
                  </div>
                }
                showArrow={true}
                asChild={true}
              >
                <div
                  className={
                    "tw-flex tw-items-center tw-align-bottom tw-text-gray-500"
                  }
                >
                  <Info style={{ width: 18, height: 18 }} />
                </div>
              </Tooltip>
            </div>
          </div>
          <Tooltip
            contents={
              <div className={"tw-p-xs tw-max-w-[400px] tw-space-y-sm"}>
                <p>
                  Click the{" "}
                  <Plus
                    style={{ width: 18, height: 18 }}
                    className={" tw-align-middle tw-text-xs tw-inline-block"}
                  />{" "}
                  or{" "}
                  <Minus
                    style={{ width: 18, height: 18 }}
                    className={" tw-align-middle tw-text-xs tw-inline-block"}
                  />{" "}
                  icons to increase or decrease the number of clusters. These
                  will alter the tree at the{" "}
                  <IoSparkles
                    style={{ width: 12, height: 12 }}
                    className={
                      " tw-align-middle tw-text-xs tw-text-yellow tw-inline-block"
                    }
                  />{" "}
                  next best{" "}
                  <IoSparkles
                    style={{ width: 12, height: 12 }}
                    className={
                      " tw-align-middle tw-text-xs tw-text-yellow tw-inline-block"
                    }
                  />{" "}
                  location according to the underlying clustering algorithm.
                </p>
                <p>
                  You can also click on the tree below to split or combine
                  clusters of your choosing!
                </p>
                <p className={"tw-text-xs tw-mt-sm"}>
                  More information in the{" "}
                  <span className={"tw-font-bold tw-text-purple"}>Methods</span>{" "}
                  section below.
                </p>
              </div>
            }
            showArrow={true}
          >
            <div className="tw-flex tw-items-center tw-mr-md tw-px-md">
              <IoSparkles className="tw-text-sm tw-mr-sm tw-text-yellow" />
              <Label>Cluster Editor</Label>
            </div>
          </Tooltip>

          <Button
            disabled={numClusters <= 2}
            onClick={() => {
              handleChangeNumClusters(numClusters - 1);
            }}
            icon={Minus}
            onMouseOver={() => handleHoveredButton("minus")}
            onMouseLeave={() => handleHoveredButton(null)}
          >
            Remove
          </Button>

          <PulseGuiderRoot
            guiderKey={"phenofinder-cluster-controls"}
            position={{
              corner: "bottom-right",
            }}
            tooltipSide={"bottom"}
          >
            <Button
              onClick={() => {
                handleChangeNumClusters(numClusters + 1);
              }}
              icon={Plus}
              disabled={numClusters >= maxTreeLevel}
              onMouseOver={() => handleHoveredButton("plus")}
              onMouseLeave={() => handleHoveredButton(null)}
            >
              Add
            </Button>
          </PulseGuiderRoot>

          <Button
            disabled={modifications.length === 0}
            onClick={handleUndoModification}
            icon={CornerDownLeft}
          >
            Undo
          </Button>
        </div>

        <div className="tw-flex tw-space-x-sm">
          <Button
            disabled={modifications.length === 0}
            onClick={handleReset}
            icon={RotateCcw}
          >
            Reset
          </Button>
          <PublishButton dataset={dataset} imageSet={imageSet} />
        </div>
      </div>

      <div
        className={cx(
          "tw-w-full tw-p-md",
          "tw-flex tw-items-center tw-justify-between",
          "tw-bg-slate-100 tw-border-b tw-border-slate-300",
        )}
      >
        <div className={"tw-pl-md"}>
          <PulseGuiderRoot
            guiderKey={"phenofinder-image-controls"}
            position={{ corner: "top-right" }}
            tooltipSide={"right"}
          >
            <Button icon={Sliders} onClick={onToggleImageSettings}>
              Image
            </Button>
          </PulseGuiderRoot>
        </div>

        <div className="tw-flex tw-justify-end tw-flex-1 tw-items-center">
          <Tooltip
            contents={
              <div className={"tw-p-xs tw-space-y-sm"}>
                <p>
                  Filter the charts to only statistically significant results.
                </p>
                <p className={"tw-text-xs"}>
                  More information in the{" "}
                  <span className={"tw-font-bold tw-text-purple"}>Methods</span>{" "}
                  section below.
                </p>
              </div>
            }
            asChild={true}
            showArrow={true}
          >
            <label
              className="tw-flex tw-justify-end tw-items-center
          tw-space-x-sm tw-text-gray-500 tw-pr-md tw-border-gray-300 tw-border-r-2"
            >
              <Checkbox
                checked={shouldHighlightMetadata}
                onCheckedChange={onToggleHighlightMetadata}
                onClick={() => {}}
              />
              <Caption>Show significant changes</Caption>
            </label>
          </Tooltip>
          <div className="tw-items-center tw-justify-end  tw-flex tw-ml-md">
            <Label className="tw-text-slate-500 tw-mx-md tw-whitespace-nowrap">
              Visualize By
            </Label>
            <Select
              name="Visualize By"
              className="tw-w-1/2 tw-min-w-[300px]"
              placeholder="Choose..."
              grouped
              value={selectedColumn.name}
              groups={selectorGroups}
              onChange={handleSelectColumn}
            />
            <CollapsibleFilterSelector
              triggerClasses={cx(
                "tw-px-md tw-mx-md tw-py-1.5 tw-text-slate-500 tw-h-full tw-bg-white tw-max-w-[300px]",
                "tw-border tw-rounded hover:tw-border-gray-300 tw-border-gray-300",
              )}
              depth={0}
              maxDepth={1}
              showPreview={false}
              columns={filterColumns}
              filterSet={filterSet}
              onChangeFilters={(filters) =>
                validateAndSetFilter(updateFilters(filterSet, filters))
              }
              onChangeOperator={(operator, newFilters) =>
                validateAndSetFilter(
                  updateOperator(filterSet, operator, newFilters),
                )
              }
              metadata={sampleMetadataDB}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
