import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useActiveWorkspace } from "src/Workspace/hooks";
import { User } from "../user/types";
import { useTestUser } from "./test-user";

/**
 * Whether the current logged in user is an internal Spring user.
 *
 * Don't use this to enable/disable features directly; instead check
 * {@link useAreInternalFeaturesEnabled} below.
 *
 * NOTE(danlec): This is intentionally not exported so we don't accidentally use it
 * and create situations where we can't easily test what non-internal users see
 */
function useIsCurrentUserInternal() {
  const { user } = useAuth0<User>();
  const testUser = useTestUser();

  if (!user) {
    // Auth0 is async and may have a brief period where they're validating
    // credentials on page load.
    // Just default to false, accepting that this value can change/flicker.
    return false;
  }

  if (testUser !== null) {
    // We never consider the test user to be internal
    return false;
  }

  return (
    user.email.endsWith("@springdisc.com") ||
    ["elizabethylin@gmail.com", "elainee.zhou@gmail.com"].includes(user.email)
  );
}

/**
 * Whether we should show the "enable/disable internal features" configuration checkbox
 *
 * Don't use this to enable/disable features directly; instead check
 * {@link useAreInternalFeaturesEnabled} below.
 */
export function useIsInternalSpringFeaturesEnabledCheckboxVisible() {
  return useIsCurrentUserInternal();
}

const INTERNAL_FEATURES_KEY = "internal-features-enabled";
const BROWSER_SUPPORTS_BROWSER_CHANNEL = "BroadcastChannel" in window;

/**
 * Since the state is ultimately stored in localStorage, set up machinery to
 * coordinate between clients so they're notified of changes.
 */
function useSubscribeToUpdate(onUpdate: (enabled: boolean) => void) {
  // Note: this machinery is only used for internal developers, and so we don't care
  // if it doesn't work in browsers with no support for BroadcastChannel; just noop.
  const channel = useMemo(
    () =>
      BROWSER_SUPPORTS_BROWSER_CHANNEL
        ? new window.BroadcastChannel(INTERNAL_FEATURES_KEY)
        : null,
    [],
  );

  useEffect(() => {
    channel?.addEventListener("message", (e: { data: boolean }) =>
      onUpdate(e.data),
    );

    return () => channel?.close();
  }, [channel, onUpdate]);

  return useCallback(
    (enabled: boolean) => {
      channel?.postMessage(enabled);
    },
    [channel],
  );
}

export function useInternalFeaturesState(): [
  boolean,
  (enabled: boolean) => void,
] {
  const [state, setStateInternal] = useState(
    [null, "true"].includes(localStorage.getItem(INTERNAL_FEATURES_KEY)),
  );
  const signalUpdate = useSubscribeToUpdate(setStateInternal);
  const setState = useCallback(
    (enabled: boolean) => {
      localStorage.setItem(INTERNAL_FEATURES_KEY, String(enabled));
      setStateInternal(enabled);
      signalUpdate(enabled);
    },
    [signalUpdate],
  );
  return [state, setState];
}

/**
 * Whether internal Spring features are enabled.
 *
 * This is meant to be a lightweight, client side check for feature development.
 * It is not meant to be a secure check for permissions purposes.
 */
export function useAreInternalFeaturesEnabled() {
  const isUserInternal = useIsCurrentUserInternal();
  const [enabled] = useInternalFeaturesState();
  return isUserInternal && enabled;
}

/**
 * Determines whether or not various "write" operations are allowed.
 *
 * This is to protect demo workspaces from getting all cluttered up. Historically,
 * we haven't had a huge problem with DOSing or anything, and so this is mostly a UX
 * issue; this is not meant to be a secure check for permissions purposes (i.e.
 * a motivated user could still bypass this check via making API calls on JS console
 * and that's fine).
 */
export function useModelTrainingAndInferenceAreAllowed() {
  const { user } = useAuth0<User>();
  const workspace = useActiveWorkspace();
  const bypassDueToInternalFeaturesEnabled = useAreInternalFeaturesEnabled();
  const isDemoWorkspace = ["jump-cp", "inflammasomes-benchmark"].includes(
    workspace.id,
  );
  if (!isDemoWorkspace || bypassDueToInternalFeaturesEnabled) {
    return true;
  }

  switch (workspace.id) {
    case "jump-cp":
      // Allow broad users for JUMP.
      return user && user.email && user.email.endsWith("@broadinstitute.org");
  }
  return false;
}
