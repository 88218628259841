import { DatasetId } from "src/types";
import { Finding, Guider, InsightsEntry, InsightsKey } from "./types";

const markdown = import.meta.glob("./content/*/*.md", {
  as: "raw",
  eager: true,
});

const attributes = import.meta.glob("./content/*/*.md", {
  import: "attributes",
  eager: true,
});

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function validateGuider(raw: unknown): raw is Guider {
  if (typeof raw !== "object" || raw === null) {
    return false;
  }

  const { key, content } = raw as Guider;
  return typeof key === "string" && typeof content === "string";
}

export function initializeAllInsights(): Map<InsightsKey, InsightsEntry> {
  const insightsByKey: Map<InsightsKey, InsightsEntry> = new Map();

  // Main parent "insights" are put under sub-directories where the directory name
  // indicates the InsightKey, and the main.md file contains the content.
  Object.entries(markdown)
    .filter(([path]) => path.endsWith("/main.md"))
    .map(([path, markdownContent]) => {
      const key = /([^/]*)\/main\.md$/.exec(path)?.[1];

      const { title, dataset, date, snippet, snippetImage } = attributes[
        path
      ] as {
        title?: string;
        dataset?: DatasetId;
        date?: string;
        snippet?: string;
        snippetImage?: string;
      };
      if (!title || !dataset || !date || !snippet) {
        console.error(`Malformed insight content: ${path}`);
        return undefined;
      }

      const dateParsed = new Date(date);
      if (isNaN(dateParsed.getTime())) {
        console.error(`Malformed date for insight: ${path}`);
        return undefined;
      }

      return {
        key: key as InsightsKey,
        title,
        dataset,
        date: dateParsed,
        snippet,
        snippetImage,
        markdownContent: markdownContent as string,
        findings: [] as Finding[],
      };
    })
    .filter((entry): entry is InsightsEntry => entry !== undefined)
    .forEach((entry) => insightsByKey.set(entry.key, entry));

  // Content for constituent "Finding"s are under the same sub-directory as the parent
  // which is also keyed by InsightKey, but the files are named finding-<index>.md
  Object.entries(markdown)
    .filter(([path]) => !path.endsWith("/main.md"))
    .forEach(([path, markdownContent]) => {
      const match = /([^/]*)\/finding-([0-9]+)\.md$/.exec(path);
      const key = match?.[1];
      const indexRaw = match?.[2];
      const {
        title,
        path: appPath,
        side,
        guiders,
      } = attributes[path] as {
        title?: string;
        path?: string;
        side?: string;
        guiders?: unknown[];
      };
      if (!key || !appPath || indexRaw === undefined || !title) {
        console.error(`Malformed insight content (missing fields): ${path}`);
        return;
      }

      const index = Number(indexRaw);
      if (isNaN(index)) {
        console.error(`Malformed insight content (bad index): ${path}`);
        return;
      }

      if (side !== undefined && side !== "left" && side !== "right") {
        console.error(`Malformed insight content (bad side ${side}): ${path}`);
        return;
      }

      if (guiders && !Array.isArray(guiders)) {
        console.error(`Malformed insight content (guiders not array): ${path}`);
        return;
      }

      const topLevelEntry = insightsByKey.get(key as InsightsKey);
      if (!topLevelEntry) {
        console.error(`No matching top-level content for ${path}`);
        return;
      }

      topLevelEntry.findings[index] = {
        title,
        path: appPath,
        markdownContent: markdownContent as string,
        side: side ?? "left",
        guiders: guiders?.filter((guider) =>
          validateGuider(guider),
        ) as Guider[],
      };
    });

  return insightsByKey;
}
