import cx from "classnames";
import { Classification } from "./Context";

interface Props {
  className?: string;
  classification: Classification;
  onClick?(): void;
}

export function ClassificationPill({
  className,
  classification,
  onClick,
}: Props) {
  return (
    <span
      className={cx(
        "tw-rounded tw-px-3 tw-py-1 tw-inline-flex tw-items-center tw-gap-sm",
        classification.accuracy
          ? classification.accuracy > 0.5
            ? "tw-bg-green-100"
            : "tw-bg-red-100"
          : "tw-bg-gray-100",
        className,
      )}
      onClick={onClick}
      role={onClick ? "button" : "none"}
    >
      <span className="tw-whitespace-nowrap">{classification.name}</span>
      <span className="tw-text-slate-500">
        {classification.accuracy
          ? `${(100 * classification.accuracy).toFixed(1)}%`
          : `N/A`}
      </span>
      <span
        className={cx(
          "tw-text-xs",
          classification.accuracy
            ? classification.accuracy > 0.5
              ? "tw-text-green-500"
              : "tw-text-red-500"
            : "tw-text-gray-500",
        )}
      >
        &#9679;
      </span>
    </span>
  );
}
