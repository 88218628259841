/**
 * Component to render quality control data for a single plate.
 */
import { FullScreenContainer } from "src/Common/FullScreenContainer";
import { FullScreenLoader } from "src/Common/FullScreenLoader";
import { DatasetId } from "src/types";
import { ColorScheme } from "../Control/ColorSchemeSelector";
import {
  useFeatureSetColumns,
  useFeatureSetsForPlate,
  usePlateLevelFeatures,
} from "../hooks/features";
import { selectLatestImagesFeatureSet } from "../util/immunofluorescence-util";
import FeatureCorrelationsView from "./FeatureCorrelationsView";
import { FieldFeature, RegressionModel } from "./types";
import { getFeatureType, isProdQualityControlFeature } from "./util";

const DEFAULT_FEATURE_CATEGORIES = [
  "Count_Nuclei",
  "ImageQuality_MeanIntensity",
  "ImageQuality_PowerLogLogSlope",
];

export default function PlateDistributionsView({
  dataset,
  plate,

  regressionModel,

  wells,
}: {
  dataset: DatasetId;
  plate: string;

  regressionModel: RegressionModel;

  wells: string[];
}) {
  const colorScheme: ColorScheme = "yellowgreenblue";

  const selectedStain = null;
  const selectedCategories: string[] = DEFAULT_FEATURE_CATEGORIES;

  // Identify the quality control-relevant FeatureSet.
  const featureSets = useFeatureSetsForPlate({ dataset, plate });
  // TODO(benkomalo): this notion of "Images" feature set is a Spring-specific concept.
  // Figure out what it means to prioritize the featuresets for this view.
  const featureSet =
    featureSets?.map(selectLatestImagesFeatureSet).orElse(() => null) || null;

  const allFeatureSetColumns = useFeatureSetColumns(
    featureSet
      ? {
          dataset,
          plate,
          featureSet,
        }
      : { skip: true },
  );
  const qualityControlColumns =
    allFeatureSetColumns
      ?.map((columns) => columns.filter(isProdQualityControlFeature))
      .orElse(() => []) || null;

  // TODO(benkomalo): handle empty FeatureSet Columns
  if (qualityControlColumns && qualityControlColumns.length == 0) {
    throw new Error("Unable to determine columns for QC");
  }

  // Filter down to: (1) only those columns that match the currently-selected
  // stain, and (2) only those columns that match the currently-selected
  // category filters.
  const featureCategories = qualityControlColumns
    ? Array.from(
        new Set(
          qualityControlColumns.map(getFeatureType).map(({ prefix }) => prefix),
        ),
      )
    : null;

  const featureSetColumns = qualityControlColumns?.filter(
    (featureSetColumn) => {
      const featureType = getFeatureType(featureSetColumn);

      if (
        // TODO(you): Fix this no-unnecessary-condition rule violation
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        selectedStain &&
        featureType.stain(featureSetColumn) !== selectedStain
      ) {
        return false;
      }

      return selectedCategories.includes(featureType.prefix);
    },
  );

  // HACK(benkomalo): we don't want to make the fetch until we've fetched the
  // columns, but featureSetColumns is an optional argument to usePlateLevelFeatures,
  // so indicate we're not ready by nulling out the dataset.
  const features = usePlateLevelFeatures(
    qualityControlColumns && dataset,
    plate,
    featureSet,
    {
      featureSetColumns: qualityControlColumns ?? undefined,
      groupByColumns: ["plate", "well"],
      // The QC page will only ever fetch features with well/field indexing.
    },
  )?.map((untyped) => untyped as unknown as FieldFeature[]);

  if (featureSets?.successful && featureSet === null) {
    return (
      <FullScreenContainer center className="tw-text-xl">
        <p>Image features are required to generate QC reports.</p>
        <p>
          If you’re seeing this message in error, please contact{" "}
          <a href={"mailto:support@springdisc.com"}>support@springdisc.com.</a>
        </p>
      </FullScreenContainer>
    );
  }
  if (!featureCategories || !featureSetColumns || !featureSet || !features) {
    return <FullScreenLoader />;
  }

  return (
    <div className="tw-w-full tw-flex tw-flex-col tw-bg-gray-100 tw-overflow-auto tw-p-xl">
      <FeatureCorrelationsView
        colorScheme={colorScheme}
        dataset={dataset}
        metadataColumns={[]}
        featureSet={featureSet}
        featureSetColumns={featureSetColumns}
        features={features
          .orElse(() => [])
          .filter((f) => wells.includes(f.well))}
        plate={plate}
        stratifyColumn={null}
        regressionModel={regressionModel}
      />
    </div>
  );
}
