import cx from "classnames";
import { useCallback, useEffect, useState } from "react";
import { TypedColumn } from "src/Control/FilterSelector/backend-types";
import { DatasetId } from "src/types";
import { Failure, Fetchable, Success, combine } from "@spring/core/result";
import { CollapsibleFilterSelector } from "../Control/FilterSelector";
import { Operator } from "../Control/FilterSelector/operations/filter-by";
import { FilterSet, FilterSqlClause } from "../Control/FilterSelector/types";
import {
  filterColumnSpecsFromDatasetMetadataDB,
  serializeToSqlClause,
  updateFilters,
  updateOperator,
  validateFilterFetchable,
} from "../Control/FilterSelector/utils";
import { useDatasetSampleMetadataDB } from "../hooks/datasets";
import { usePalettes } from "../hooks/immunofluorescence";
import { DB } from "../util/sql";
import { useLabeledSetContext } from "./Context";
import { getPlatesWithStains, reformatPalettes } from "./util";

interface Props {
  className?: string;
  dataset: DatasetId;
}

/**
 * Given a set of stains, create a filter for a Dataset to limit to relevant plates.
 */
export function usePrefilterForStains(
  dataset: DatasetId,
  stains: string[],
): Fetchable<FilterSqlClause> {
  return usePalettes({ dataset })
    ?.map((palettes) => {
      const platesByStain = reformatPalettes(palettes);
      return new Set(getPlatesWithStains(platesByStain, stains));
    })
    .map((plates) => {
      const platesJoined = [...plates].map((plate) => `'${plate}'`).join(",");
      return `plate IN (${platesJoined})`;
    });
}

/**
 * Determine configuration parameters for a FilterSelector.
 *
 * Given a Dataset's sample_metadata, and a set of stains (which will pre-filter the
 * Dataset to potentially a certain subset of plates), create parameters (which depend
 * on things like the type and unique values of a column) to create a filter component.
 */
function useFilterColumns(
  dataset: DatasetId,
  stains: string[],
  metadataDB: Fetchable<DB>,
): Fetchable<TypedColumn[]> {
  const [results, setResults] = useState<Fetchable<TypedColumn[]>>(undefined);
  const fetch = combine({
    sampleMetadata: metadataDB,
    prefilter: usePrefilterForStains(dataset, stains),
  });

  useEffect(() => {
    if (fetch?.successful) {
      const { sampleMetadata, prefilter } = fetch.value;
      filterColumnSpecsFromDatasetMetadataDB(
        dataset,
        sampleMetadata,
        prefilter,
      ).then(
        (columns) => setResults(Success.of(columns)),
        (err) => setResults(Failure.of(err)),
      );
    }
    // HACK(benkomalo): specifying fetch as a dependency here results in infinite
    // updates, since it doesn't appear as if the results of combine() above is stable.
    // So instead, specify whether the fetch has returned, and the inputs to the fetch.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch?.successful, dataset, stains, setResults]);

  return results;
}

export function ImageFilter({ className, dataset }: Props) {
  const { state: modelState, updateState: updateModelState } =
    useLabeledSetContext();
  // TODO (michaelwiest): we should alter the default filter to be able to read from
  // the current state's filter. Right now if we remount the ImageFilter then the
  // existing filter gets cleared and we prompt a reload.
  const [filter, setFilter] = useState<FilterSet>({
    filters: [],
    operator: Operator.AND,
    ignoredFilters: [],
  });

  const { stains } = modelState;
  const metadataDB = useDatasetSampleMetadataDB({ dataset });
  const filterColumns = useFilterColumns(dataset, stains, metadataDB);
  const validateAndSetFilter = useCallback(
    (filter: FilterSet) => {
      validateFilterFetchable(metadataDB, filter).then(setFilter);
    },
    [metadataDB],
  );

  useEffect(() => {
    updateModelState((modelState) => {
      const newFilter = serializeToSqlClause(filter);

      if (newFilter !== modelState.samplingFilter) {
        return {
          ...modelState,
          samplingFilter: newFilter,
          // Clear out all the samples and data related to the old filter
          queue: [],
          displayed: [],
          selected: [],
          neighbors: new Map(),
          outOfSamples: false,
        };
      } else {
        return modelState;
      }
    });
  }, [filter, updateModelState]);

  if (!filterColumns) {
    return null; // TODO(benkomalo): maybe a really small/subtle spinner?
  } else if (!filterColumns.successful || !metadataDB?.successful) {
    return (
      <span
        className={cx(
          "tw-text-red-error tw-text-sm tw-flex tw-items-center",
          className,
        )}
      >
        Oops. Something went wrong. Please reload and try again.
      </span>
    );
  } else {
    return (
      <CollapsibleFilterSelector
        triggerClasses={className}
        columns={filterColumns.value}
        filterSet={filter}
        onChangeFilters={(filters) =>
          validateAndSetFilter(updateFilters(filter, filters))
        }
        onChangeOperator={(operator, newFilters) =>
          validateAndSetFilter(updateOperator(filter, operator, newFilters))
        }
        metadata={metadataDB.value}
      />
    );
  }
}
