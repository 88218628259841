import cx from "classnames";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ZoomIn } from "react-feather";
import { useExamplesApi } from "src/hooks/api";
import { DatasetId } from "src/types";
import { Subtitle, Title } from "@spring/ui/typography";
import { FullScreenContainer } from "../Common/FullScreenContainer";
import Loader from "../Common/Loader";
import {
  ArgoJob,
  getArgoJobFromResponse,
  useArgoJobStatusByType,
} from "../hooks/argo";
import { useDatasetMetadata, useHasSingleCellDataset } from "../hooks/datasets";
import { LabeledSetResponse } from "../hooks/examples";
import { CropContext } from "../imaging/cropping";
import { useComponentSpan } from "../util/tracing";
import { AddLabeledSet } from "./AddLabeledSet";
import {
  Classification,
  LabeledSetContextProvider,
  defaultLabeledSet,
  useLabeledSetsContext,
} from "./Context";
import { LabeledSetRow } from "./LabeledSetRow";
import { MAX_PARALLEL_GET_LABELED_SETS } from "./constants";
import {
  addIdToMetadata,
  groupClassifications,
  parallelMap,
  removeVersioning,
  sortLabeledSets,
} from "./util";

function baseName(gcsPath: string): string {
  const parts = gcsPath.split("/");
  return parts[parts.length - 1];
}

interface LabeledSetFromServer extends LabeledSetResponse {
  id: string;
  classifications: Classification[];
  loaded: boolean;
  lastSaved: Date;
  selectedStains: string[];
}

function PhenoSorterPlaceholder({ dataset }: { dataset: DatasetId }) {
  return (
    <div className={"tw-w-full tw-h-full tw-text-center tw-text-slate-500"}>
      <div className={"tw-text-xl tw-mt-8"}>
        Create custom phenotypes by labeling cells with PhenoSorter!
      </div>
      <div className={"tw-leading-loose"}>
        ({dataset} does not have any single cell locations available)
      </div>
      <div
        className={
          "tw-flex tw-flex-row tw-items-end tw-justify-center tw-mt-12"
        }
      >
        <ZoomIn
          size={128}
          className={
            "tw-text-purple-500 tw-mr-[-100px] tw-scale-x-[-1] tw-relative"
          }
        />
        <img
          src={"/phenosorter-example.png"}
          width={600}
          height={600}
          className={"tw-shadow-[10px_35px_60px_0px_rgba(0,0,0,0.7)]"}
        />
      </div>
    </div>
  );
}

export function LandingPage({ dataset }: { dataset: DatasetId }) {
  const {
    state: { labeledSets, loaded },
    updateState,
  } = useLabeledSetsContext();

  useComponentSpan("PhenotypicLearner/LandingPage", [dataset]);

  const maybeArgoStatus = useArgoJobStatusByType({
    dataset,
    argoJobType: "ps_inference",
  });
  const getArgoJob = useCallback(
    (id: string): ArgoJob => getArgoJobFromResponse(id, maybeArgoStatus),
    [maybeArgoStatus],
  );

  const [labeledSetsFromServer, setLabeledSetsFromServer] = useState<
    LabeledSetFromServer[] | undefined
  >();

  const datasetMetadata = useDatasetMetadata({ dataset });
  const maxCropSize = useContext(CropContext).sourceCropSize;
  const cropSize = datasetMetadata?.successful
    ? datasetMetadata.value?.single_cell_size
    : undefined;

  const api = useExamplesApi();

  useEffect(() => {
    const controller = new AbortController();
    let mounted = true;

    async function fetchData() {
      const gcsPaths = await api
        .route("list_labeled_sets")
        .get()
        .json<string[]>();

      if (!mounted) {
        return;
      }

      const pathSet = new Set(
        gcsPaths.map((name) => removeVersioning(baseName(name))),
      );
      const paths = Array.from(pathSet);

      const latestLabeledSets = (
        await parallelMap<string, LabeledSetFromServer | null>(
          paths,
          async (labeledSetId): Promise<LabeledSetFromServer | null> => {
            try {
              const labeledSet = await api
                .route("<labeledSet>", { labeledSet: labeledSetId })
                .get()
                .json<LabeledSetResponse>();

              const stains = labeledSet.stains;

              return {
                ...labeledSet,
                id: labeledSetId,
                classifications: groupClassifications(
                  labeledSet.labels.map(addIdToMetadata),
                  labeledSet.classNames,
                  labeledSet.accuracies,
                ),
                loaded: true,
                lastSaved: new Date(),
                stains,
                stainChannelIndices: labeledSet.stainChannelIndices,
                stainDisplayRanges: labeledSet.stainDisplayRanges,
                selectedStains: stains,
              };
            } catch (ex) {
              return null;
            }
          },
          MAX_PARALLEL_GET_LABELED_SETS,
          () => !mounted,
        )
      ).filter((ls): ls is LabeledSetFromServer => ls !== null);

      // TODO(you): Fix this no-unnecessary-condition rule violation
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (mounted) {
        setLabeledSetsFromServer(latestLabeledSets);
      }
    }

    fetchData();

    return () => {
      mounted = false;
      controller.abort();
    };
  }, [api]);

  useEffect(() => {
    if (!labeledSetsFromServer || datasetMetadata === undefined) {
      return;
    }

    const defaultCropSize = cropSize ?? maxCropSize / 2;
    updateState((state) => {
      const updatedLabledSets = labeledSetsFromServer.map(
        (serverLabeledSet) => {
          const existing = state.labeledSets.find(
            (ls) => ls.id === serverLabeledSet.id,
          );

          return existing
            ? { ...existing, ...serverLabeledSet, defaultCropSize }
            : {
                ...defaultLabeledSet,
                ...serverLabeledSet,
                defaultCropSize,
                cropSize: defaultCropSize,
              };
        },
      );

      const newLabeledSets = state.labeledSets.filter(
        (ls) =>
          !updatedLabledSets.some(
            (included) =>
              // TODO(you): Fix this no-unnecessary-condition rule violation
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              included.lastSaved &&
              Date.now() - included.lastSaved.valueOf() < 10000 &&
              included.id === ls.id,
          ),
      );

      return {
        ...state,
        labeledSets: [...updatedLabledSets, ...newLabeledSets],
        defaultCropSize,
        loaded: true,
      };
    });
  }, [
    labeledSetsFromServer,
    updateState,
    datasetMetadata,
    cropSize,
    maxCropSize,
  ]);

  const displayedLabeledSets = useMemo(
    () =>
      sortLabeledSets(
        labeledSets.filter(
          (ls) =>
            // We keep the record for deleted labeled sets so we continue to not display it
            // if it got deleted while we were loading labeled sets
            !ls.deleted,
        ),
      ),
    [labeledSets],
  );

  // The reason for the labeledSets.length > 0 || !loaded is that we don't want to run
  // This check if there are already labeledsets in which case we know there is a valid
  // single cell dataset.
  const maybeHasSingleCellDataset = useHasSingleCellDataset({
    dataset,
    skip: labeledSets.length > 0 || !loaded,
  });
  const hasSingleCellDataset = maybeHasSingleCellDataset?.successful
    ? maybeHasSingleCellDataset.value.exists
    : true;

  const isWaitingForSingleCellDatasetRequest =
    labeledSets.length === 0 && maybeHasSingleCellDataset === undefined;

  const headingClassName = cx(
    "tw-font-normal tw-uppercase tw-px-md tw-pt-lg tw-pb-sm",
    "tw-text-slate-500 tw-bg-gray-100",
    "tw-sticky tw-top-0 tw-z-[1]",
  );

  return (
    <FullScreenContainer>
      <div className="tw-h-full tw-flex tw-flex-col tw-overflow-y-auto">
        <div className="tw-flex tw-flex-row tw-items-center tw-p-8 tw-border-b">
          <h3 className="tw-flex-1 tw-text-3xl tw-font-medium tw-mb-sm">
            <Title>PhenoSorter</Title>
            <Subtitle className="tw-text-slate-500">
              Visual phenotype classifier
            </Subtitle>
          </h3>
          {loaded &&
          !isWaitingForSingleCellDatasetRequest &&
          hasSingleCellDataset ? (
            <div>
              <AddLabeledSet dataset={dataset} />
            </div>
          ) : null}
        </div>

        <div className="tw-bg-gray-100 tw-flex-1 tw-p-lg tw-pt-0 tw-relative tw-overflow-auto">
          {!loaded || isWaitingForSingleCellDatasetRequest ? (
            <div className="tw-flex-1 tw-flex tw-items-center tw-justify-center tw-h-full">
              <Loader size={"large"} className="tw-mix-blend-multiply" />
            </div>
          ) : labeledSets.length > 0 ? (
            <table className="tw-w-full">
              <thead>
                <tr className="tw-border-b">
                  <th className={cx(headingClassName, "tw-text-left")}>Name</th>
                  <th
                    className={cx(
                      headingClassName,
                      "tw-min-w-[160px] tw-text-right",
                    )}
                  >
                    Images Labeled
                  </th>
                  <th className={cx(headingClassName, "tw-text-left")}>
                    Classes
                  </th>
                  <th className={cx(headingClassName, "tw-text-right")}>
                    Labeling Status
                  </th>
                  <th className={cx(headingClassName, "tw-text-right")}>
                    Model Status
                  </th>
                  <th className={headingClassName} />
                  <th />
                </tr>
              </thead>
              <tbody>
                {displayedLabeledSets.map((labeledSet) => {
                  const { id, displayName: name } = labeledSet;
                  return (
                    <LabeledSetContextProvider key={id} id={id}>
                      <LabeledSetRow
                        key={id}
                        dataset={dataset}
                        url={`pl/${id}`}
                        name={name}
                        argoJob={getArgoJob(id)}
                      />
                    </LabeledSetContextProvider>
                  );
                })}
              </tbody>
            </table>
          ) : hasSingleCellDataset ? (
            <div className="tw-h-full tw-flex tw-items-center tw-justify-center">
              <p>You haven't labelled any data for modeling yet</p>
            </div>
          ) : (
            <PhenoSorterPlaceholder dataset={dataset}></PhenoSorterPlaceholder>
          )}
        </div>
      </div>
    </FullScreenContainer>
  );
}
