import * as queryString from "query-string";
import { DEFAULT_TIMEPOINT } from "src/timeseries/constants";
import {
  ChannelIndex,
  Field,
  Timepoint,
  VisualizationSettings,
  VisualizationState,
} from "./types";

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type Location = {
  row: number;
  column: number;
};

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type ParsedUrl = {
  url: string;
  baseUrl: string;
  bucket: string | null;
  path: string | null;
  location: Location | null;
  size: number | null;
};

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function toGCSUrl({
  bucket,
  path,
  location,
}: {
  bucket: string | null;
  path: string | null;
  location?: Location | null;
}): string {
  if (location) {
    return `gs://${bucket}/${path}@${location.row},${location.column}`;
  }
  return `gs://${bucket}/${path}`;
}

/**
 * Parse an encoded URL to determine where an image should be cropped.
 *
 * Some of our datasets use image URLs that reference full-scale images,
 * indexing into them by providing a centering coordinate as a suffix. This
 * method deconstructs those URLs to enable visualization of a specific location
 * within a broader image.
 *
 * @param url - a URL, optionally suffixed with @{row},{column}.
 *
 * @returns {ParsedUrl} - a ParsedUrl containing the true image URL along with
 *                        location information as to where it should be cropped,
 *                        if anywhere.
 */
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const parseUrl = (url: string): ParsedUrl => {
  const { url: baseUrl, query } = queryString.parseUrl(url);
  if (query.b && query.p) {
    const { b, p, s } = query;
    if (p.includes("@")) {
      const [basePath, location] = (p as string).split("@");
      const [row, column] = location.split(",");
      return {
        url: `${baseUrl}?${queryString.stringify({ b, p })}`,
        baseUrl,
        bucket: b as string,
        path: basePath,
        location: {
          row: Number(row),
          column: Number(column),
        },
        size: s ? Number.parseInt(s as string, 10) : null,
      };
    } else {
      return {
        url: `${baseUrl}?${queryString.stringify({ b, p })}`,
        baseUrl,
        bucket: b as string,
        path: p as string,
        location: null,
        size: s ? Number.parseInt(s as string, 10) : null,
      };
    }
  }

  return {
    url: url,
    baseUrl: url,
    bucket: null,
    path: null,
    location: null,
    size: null,
  };
};

/**
 * Infer the source image size from a proxy-ready URL.
 *
 * @param url - URL of an image to fetch from the GCS proxy.
 * @returns {number} - the size in pixels of the source image (that is, the
 *                     underlying image that would be fetched from GCS).
 */
export function inferSourceImageSize(url: string): number {
  const { query } = queryString.parseUrl(url);
  if (query.b && query.p) {
    if (query.s) {
      return Number.parseInt(query.s as string, 10);
    } else {
      const { p } = query;
      const match = /\/(\d+)px\//.exec(p as string);
      if (!match) {
        throw Error(`Unable to infer image size from URL: ${url}`);
      }
      return Number.parseInt(match[1]);
    }
  }
  throw Error(`Unable to infer image size from URL: ${url}`);
}

/**
 * Convert VisualizationState to VisualizationSettings.
 */
export function toVisualizationSettings({
  channelLoaded,
  showChannel,
  channelMap,
  displaySettings,
  renderMode,
}: Omit<
  Omit<VisualizationState, "lockChannel">,
  "preprocessingMode"
>): VisualizationSettings {
  return {
    channelMap: [
      showChannel[0] ? channelMap[0] : null,
      showChannel[1] ? channelMap[1] : null,
      showChannel[2] ? channelMap[2] : null,
      showChannel[3] ? channelMap[3] : null,
      showChannel[4] ? channelMap[4] : null,
      showChannel[5] ? channelMap[5] : null,
      showChannel[6] ? channelMap[6] : null,
    ],
    displayRanges: [
      channelMap[0] == null || !channelLoaded[0]
        ? null
        : displaySettings[0].activeRange,
      channelMap[1] == null || !channelLoaded[1]
        ? null
        : displaySettings[1].activeRange,
      channelMap[2] == null || !channelLoaded[2]
        ? null
        : displaySettings[2].activeRange,
      channelMap[3] == null || !channelLoaded[3]
        ? null
        : displaySettings[3].activeRange,
      channelMap[4] == null || !channelLoaded[4]
        ? null
        : displaySettings[4].activeRange,
      channelMap[5] == null || !channelLoaded[5]
        ? null
        : displaySettings[5].activeRange,
      channelMap[6] == null || !channelLoaded[6]
        ? null
        : displaySettings[6].activeRange,
    ],
    autoScale: renderMode === "per-image",
  };
}

/**
 * Type predicate filter that removes null values from a list.
 */
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function isChannelIndex(value: number): value is ChannelIndex {
  return (
    value === 0 ||
    value === 1 ||
    value === 2 ||
    value === 3 ||
    value === 4 ||
    value === 5 ||
    value === 6
  );
}

/**
 * Join a bunch of class strings together to avoid long lines.
 *
 * Example use:
 *   <div className={twcss(
 *       'tw-flex tw-flex-row',
 *       'tw-items-center',
 *       'tw-ml-4 tw-mr-4',
 *       disabled && 'tw-bg-gray-200',
 *   )}>
 *      ...
 *   </div>
 */
export function twcss(...classes: (string | undefined | null | false)[]) {
  return classes.filter((entry) => !!entry).join(" ");
}

/**
 * Convert a "f00"-style string field identifier to a number.
 */
export function toNumericField(field: Field): number {
  return Number.parseInt(field.slice(1), 10);
}

export function isField(field: unknown): field is Field {
  return typeof field === "string" && /^f\d+$/.test(field);
}

export function metadataToKey({
  plate,
  well,
  field,
  timepoint,
}: {
  plate: string;
  well: string;
  field?: Field;
  timepoint?: Timepoint;
}) {
  return field
    ? `${plate}##${well}##${field}##${timepoint ?? DEFAULT_TIMEPOINT}`
    : `${plate}##${well}##${timepoint ?? DEFAULT_TIMEPOINT}`;
}
