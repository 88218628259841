import * as Popover from "@radix-ui/react-popover";
import * as Portal from "@radix-ui/react-portal";
import cx from "classnames";
import React, {
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { MoreVertical } from "react-feather";
import type { AccessToken } from "src/Auth0/accessToken";
import { useActiveWorkspace } from "src/Workspace/hooks";
import { useAccessToken } from "src/hooks/auth0";
import { hasKeys } from "src/util/has-keys";
import { Workspace } from "../Workspace/types";
import { useSize } from "../util/hooks";
import { ButtonProps, DeprecatedButton } from "./DeprecatedButton";

export function MenuButton(
  props: React.ComponentPropsWithRef<typeof DeprecatedButton>,
) {
  return (
    <DeprecatedButton
      {...props}
      className="tw-w-full"
      variant="transparent"
      align="left"
    />
  );
}

interface CustomMenuEntry {
  labelNode?: ReactNode;
  contents: ReactNode;
}

function isCustomMenuEntry(
  entry: ReactNode | CustomMenuEntry,
): entry is CustomMenuEntry {
  return hasKeys(entry, "contents");
}

export function MainMenu<
  T extends {
    [key: string]: ReactNode | CustomMenuEntry;
  },
>({ className, items }: { className?: string; items: T }) {
  const [menu, setMenu] = useState<keyof T | undefined>(undefined);

  const activeSubmenu = useMemo(
    () => (menu ? items[menu] : undefined),
    [items, menu],
  );

  return (
    <div className={className}>
      {activeSubmenu
        ? isCustomMenuEntry(activeSubmenu)
          ? activeSubmenu.contents
          : activeSubmenu
        : Object.entries(items).map(([label, subMenu]) => (
            <MenuButton key={label} onClick={() => setMenu(label)}>
              {isCustomMenuEntry(subMenu) ? subMenu.labelNode ?? label : label}
            </MenuButton>
          ))}
    </div>
  );
}

interface Props {
  className?: string;
  children: ReactNode;
  reset?: () => void;
  trigger: JSX.Element;
  triggerClassName?: string;
  triggerIsButton?: boolean;
  onClose?: () => void;
  align?: "start" | "center" | "end";
  sideOffset?: number;
  isSameWidthAsTrigger?: boolean;
}

export function OverflowMenu({
  className,
  ...props
}: Omit<Props, "button" | "trigger" | "triggerIsButton">) {
  return (
    <PopoverMenu
      {...props}
      trigger={<MoreVertical size={16} className={className} />}
    ></PopoverMenu>
  );
}

const CloseButton = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithRef<ButtonProps>
>(function CloseButton(props, ref) {
  return (
    <Popover.Close asChild>
      <DeprecatedButton ref={ref} {...props} />
    </Popover.Close>
  );
});

export interface RenameSettings {
  newValue: string;
  accessToken: AccessToken;
  workspace: Workspace;
}

export type ValidationResult =
  | { valid: true }
  | { valid: false; message?: string };

export function EditValue({
  className,
  initialValue,
  onSubmit,
  validate,
  allowEmpty = false,
  multiLine = false,
  saveText = "Submit",
}: {
  className?: string;
  initialValue: string;
  onSubmit: (settings: RenameSettings) => Promise<void>;
  validate?: (newName: string) => ValidationResult;
  allowEmpty?: boolean;
  multiLine?: boolean;
  saveText?: string;
}) {
  const accessToken = useAccessToken();
  const workspace = useActiveWorkspace();
  const [newName, setNewName] = useState(initialValue);
  const refSubmit = useRef<HTMLButtonElement>(null);

  const validation = useMemo<ValidationResult>(() => {
    if (!allowEmpty && !/\S/.test(newName)) {
      return { valid: false };
    }
    return validate ? validate(newName) : { valid: true };
  }, [allowEmpty, newName, validate]);

  const onClickRename = useCallback(async () => {
    if (newName === initialValue || !validation.valid) {
      return;
    }

    await onSubmit({ newValue: newName, accessToken, workspace });
  }, [
    accessToken,
    initialValue,
    newName,
    onSubmit,
    validation.valid,
    workspace,
  ]);

  const onKeyDown = useCallback<React.KeyboardEventHandler<HTMLInputElement>>(
    (e) => {
      if (e.key === "Enter") {
        refSubmit.current?.click();
      }
    },
    [],
  );

  const onChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  >((e) => {
    setNewName(e.currentTarget.value);
  }, []);

  return (
    <div className={className}>
      <div className="tw-p-4 tw-pb-2">
        {multiLine ? (
          <textarea
            autoFocus
            className="tw-w-full tw-border tw-p-2"
            value={newName}
            onChange={onChange}
          />
        ) : (
          <input
            type="text"
            autoFocus
            className="tw-w-full tw-border tw-p-2"
            value={newName}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
        )}
      </div>
      {!validation.valid && (
        <div className="tw-px-4 tw-py-2 tw-text-red-500">
          {validation.message}
        </div>
      )}
      <div className="tw-grid tw-grid-cols-2 tw-gap-2 tw-p-4">
        <CloseButton
          variant="primary"
          onClick={onClickRename}
          disabled={newName === initialValue || !validation.valid}
          ref={refSubmit}
        >
          {saveText}
        </CloseButton>
        <CloseButton>Cancel</CloseButton>
      </div>
    </div>
  );
}

export interface DuplicateSettings {
  newName: string;
  accessToken: AccessToken;
  workspace: Workspace;
}

export function Duplicate({
  initialName,
  onSubmit,
  validate,
}: {
  initialName: string;
  onSubmit: (settings: DuplicateSettings) => Promise<void>;
  validate?: (newName: string) => ValidationResult;
}) {
  const defaultName = `${initialName} copy`;
  const accessToken = useAccessToken();
  const workspace = useActiveWorkspace();
  const [newName, setNewName] = useState(defaultName);
  const refSubmit = useRef<HTMLButtonElement>(null);

  const validation = useMemo<ValidationResult>(() => {
    if (!/\S/.test(newName)) {
      return { valid: false };
    }
    return validate ? validate(newName) : { valid: true };
  }, [newName, validate]);

  const onClickDuplicate = useCallback(async () => {
    if (!validation.valid) {
      return;
    }

    await onSubmit({
      newName: newName,
      accessToken,
      workspace,
    });
  }, [accessToken, newName, onSubmit, validation.valid, workspace]);

  const onKeyDown = useCallback<React.KeyboardEventHandler<HTMLInputElement>>(
    (e) => {
      if (e.key === "Enter") {
        refSubmit.current?.click();
      }
    },
    [],
  );

  const onChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setNewName(e.currentTarget.value);
    },
    [],
  );

  return (
    <>
      <div className="tw-p-4 tw-pb-2">
        <label
          className="tw-inline-block tw-text-sm tw-text-gray-500"
          htmlFor="duplicateSetName"
        >
          New Name
        </label>
        <input
          id="duplicateSetName"
          type="text"
          autoFocus
          className="tw-w-full tw-border tw-p-2"
          value={newName}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
      </div>
      {!validation.valid && (
        <div className="tw-px-4 tw-py-2 tw-text-red-500">
          {validation.message}
        </div>
      )}
      <div className="tw-grid tw-grid-cols-2 tw-gap-2 tw-p-4">
        <CloseButton
          variant="primary"
          onClick={onClickDuplicate}
          disabled={newName === initialName || !validation.valid}
          ref={refSubmit}
        >
          Duplicate
        </CloseButton>
        <CloseButton>Cancel</CloseButton>
      </div>
    </>
  );
}

export interface ConfirmDeleteSettings {
  accessToken: AccessToken;
  workspace: Workspace;
}

export function ConfirmDelete({
  onDelete,
  onDeleteStart,
  onDeleteFinish,
  message,
  error,
}: {
  onDelete: (settings: ConfirmDeleteSettings) => Promise<void>;
  onDeleteStart?: () => void;
  onDeleteFinish?: () => void;
  message?: string;
  error?: string;
}) {
  const accessToken = useAccessToken();
  const workspace = useActiveWorkspace();

  const onClickDelete = useCallback(async () => {
    onDeleteStart?.();

    await onDelete({ accessToken, workspace });

    onDeleteFinish?.();
  }, [accessToken, onDelete, onDeleteFinish, onDeleteStart, workspace]);

  return (
    <>
      {error ? (
        <div className="tw-p-4 tw-pb-2 tw-text-red-500">{error} </div>
      ) : (
        <div className="tw-p-4 tw-pb-2">
          {message ?? "Are you sure you want to delete this?"}
        </div>
      )}
      <div className="tw-grid tw-grid-cols-2 tw-gap-2 tw-p-4">
        <CloseButton
          variant="danger"
          onClick={onClickDelete}
          disabled={error !== undefined}
        >
          Delete
        </CloseButton>
        <CloseButton>Cancel</CloseButton>
      </div>
    </>
  );
}

export function PopoverMenu({
  trigger,
  triggerClassName,
  triggerIsButton,
  className,
  children,
  reset,
  onClose,
  align = "start",
  sideOffset,
  isSameWidthAsTrigger = false,
}: Props) {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const size = useSize(triggerRef, "border-box");

  const onClick = useCallback<React.MouseEventHandler<HTMLElement>>((e) => {
    e.stopPropagation();
  }, []);

  const onOpenChange = useCallback(
    (open: boolean) => {
      if (open) {
        reset?.();
      } else {
        onClose?.();
      }
    },
    [reset, onClose],
  );

  return (
    <Popover.Root onOpenChange={onOpenChange}>
      <Popover.Trigger
        ref={triggerRef}
        onClick={onClick}
        asChild={triggerIsButton}
        className={triggerClassName}
      >
        {trigger}
      </Popover.Trigger>
      <Portal.Root className="tw-relative tw-z-20">
        <Popover.Content
          align={align}
          sideOffset={sideOffset}
          style={isSameWidthAsTrigger ? { width: size?.width ?? 0 } : {}}
        >
          <div
            className={cx("tw-border tw-bg-white tw-shadow-lg", className)}
            onClick={onClick}
          >
            {children}
          </div>
        </Popover.Content>
      </Portal.Root>
    </Popover.Root>
  );
}
