import { ComponentType } from "react";
import { AppLocation, AppLocationId } from "src/TrackAppLocation/types";
import { DatasetId } from "src/types";
import { MenuView } from "../FeatureSetManagementPage/views";
import PhenoFinder from "../PhenoFinder";
import PhenotypicLearner from "../PhenotypicLearner";
import SupervisedLearning from "../SupervisedLearning";
import PreviewPhenoFinderUrl from "../img/preview-create-phenofinder.jpg?url";
import PreviewPhenoSorterUrl from "../img/preview-create-phenosorter.jpg?url";
import PreviewSupervisedLearner from "../img/preview-supervised-learner.jpg?url";

interface CreateView extends MenuView {
  appLocation: AppLocation;
  component: ComponentType<{ dataset: DatasetId }>;
  path: string;
}

export const CREATE_VIEWS: CreateView[] = [
  {
    appLocation: { id: AppLocationId.PhenoSorter },
    component: PhenotypicLearner,
    description:
      "Train a classifier to label single-cells based on user-observed phenotypes.",
    name: "PhenoSorter",
    previewUrl: PreviewPhenoSorterUrl,
    path: "/pl",
    internalOnly: false,
  },
  {
    appLocation: { id: AppLocationId.PhenoFinder },
    component: PhenoFinder,
    description:
      "Explore single-cell phenotypes in your data using unsupervised clustering.",
    name: "PhenoFinder",
    previewUrl: PreviewPhenoFinderUrl,
    path: "/pf",
    internalOnly: false,
  },
  {
    appLocation: { id: AppLocationId.SupervisedLearner },
    component: SupervisedLearning,
    description:
      "Train a classifier to label well-level data based upon experiment metadata.",
    name: "Supervised Learner",
    previewUrl: PreviewSupervisedLearner,
    path: "/sl",
    internalOnly: false,
  },
];
