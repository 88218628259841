/**
 * Display a manifest of images as a grid, using metadata for rows/columns.
 */
import cx from "classnames";
import { ReactNode } from "react";
import Close from "../../../icons/Close.svg";
import { AnnotatedEntry, Entry, GroupedEntries } from "./types";

const keyString = (keys: any[]) => keys.map((o) => o.toString()).join("::");

export function groupEntries(
  entries: Entry[],
  groupKeyFields: string[],
  valueFields: string[],
): GroupedEntries {
  const keyed: AnnotatedEntry[] = entries.map((entry) => {
    const rowKey = groupKeyFields.map((f) => entry.metadata[f] ?? "");
    const colKey = valueFields.map((f) => entry.metadata[f] ?? "");
    return {
      rowKey: rowKey,
      colKey: colKey,
      entry: entry,
    };
  });
  const grouped: { [key: string]: AnnotatedEntry[] } = {};
  keyed.forEach((entry) => {
    const keyStr = keyString(entry.rowKey);
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    grouped[keyStr] = grouped[keyStr]
      ? grouped[keyStr].concat([entry])
      : [entry];
  });

  return Object.entries(grouped).map(([key, entries]) => {
    return {
      key,
      entries,
    };
  });
}

// Compare two arrays in order by elements. Assumes they're the same length.
const compareArrays = <T extends object>(a: T[], b: T[]): 0 | 1 | -1 => {
  if (a.length === 0) {
    return 0;
  }
  if (a[0] < b[0]) {
    return -1;
  }
  if (a[0] > b[0]) {
    return 1;
  }
  return compareArrays(a.slice(1), b.slice(1));
};

const colSortFn = <T extends object>(
  a: { colKey: T[] },
  b: { colKey: T[] },
) => {
  return compareArrays(a.colKey, b.colKey);
};

/**
 * A component which renders groups of images in vertical grids.
 */
export function GroupedImageGrid(props: {
  children: (item: Entry) => ReactNode;

  groupedEntries: GroupedEntries;
  groupKeyFields: string[];
  valueFields: string[];
  showGroupLabels?: boolean;
  showValueLabels?: boolean;

  itemWidth: number;
  columnsPerGroup: number;

  onGroupMouseEnter?: (groupKey: any, data: Entry[]) => void;
  onGroupMouseLeave?: (groupKey: any) => void;

  onGroupClose?: (groupKey: any) => void;

  valueFormatter?: (value: any) => string;
}) {
  const onGroupMouseEnter = props.onGroupMouseEnter || (() => {});
  const onGroupMouseLeave = props.onGroupMouseLeave || (() => {});
  const onGroupClose = props.onGroupClose;
  const formatter = props.valueFormatter || keyString;

  const groupWidth =
    props.columnsPerGroup * props.itemWidth +
    (props.columnsPerGroup - 1) * 8 + // item gaps
    48; // container padding
  const totalWidth =
    props.groupedEntries.length * groupWidth +
    Math.max(props.groupedEntries.length - 1, 0) * 4; // group gaps
  return (
    <div
      className={"tw-flex tw-flex-row tw-items-start"}
      style={{ minWidth: totalWidth }}
    >
      {props.groupedEntries.map(({ key: groupKey, entries, color }, i) => {
        entries = entries.sort(colSortFn);
        const borderColor = color || "transparent";
        const removeButton = onGroupClose && (
          <button
            className={cx("tw-absolute tw-top-1 tw-right-1")}
            onClick={() => onGroupClose(groupKey)}
            aria-label={"Remove group"}
            title={"Remove group"}
          >
            <Close className={"tw-w-4 tw-h-4 tw-text-slate-500"} />
          </button>
        );
        const grid = (
          <div
            key={groupKey}
            className={
              "tw-relative tw-border-2 tw-m-2 tw-mt-0 tw-p-6 tw-rounded"
            }
            style={{
              borderColor: borderColor,
              minWidth: groupWidth,
            }}
            onMouseEnter={() => {
              onGroupMouseEnter(
                groupKey,
                entries.map(({ entry }) => entry),
              );
            }}
            onMouseLeave={() => {
              onGroupMouseLeave(groupKey);
            }}
          >
            {removeButton}
            <div
              className={"tw-grid tw-gap-[8px]"}
              style={{
                gridTemplateColumns: `repeat(${props.columnsPerGroup}, ${props.itemWidth}px)`,
              }}
            >
              {entries.map((col, j) => (
                <div className={"tw-flex"} key={`col${i}${j}`}>
                  {formatter(col.colKey) && props.showValueLabels && (
                    <div className={"tw-text-xs tw-text-slate-500"}>
                      {formatter(col.colKey)}
                    </div>
                  )}
                  {props.children(col.entry)}
                </div>
              ))}
            </div>
          </div>
        );

        if (props.showGroupLabels) {
          const minValue = formatter(entries[0].colKey);
          const maxValue = formatter(entries[entries.length - 1].colKey);
          const groupTitle = `${minValue} - ${maxValue}`;
          return (
            <div
              className={"tw-flex tw-flex-col tw-items-center"}
              key={groupKey}
            >
              <div className={"tw-font-mono tw-text-slate-500 tw-text-sm"}>
                {groupTitle}
              </div>
              {grid}
            </div>
          );
        } else {
          return grid;
        }
      })}
    </div>
  );
}
