import { ReactNode } from "react";
import { Item, Selectable } from "./types";

export function idForSelectable<T>(value: Selectable<T>): string {
  if (typeof value === "string") {
    return value;
  } else {
    return value.id ?? String(value.value);
  }
}

export function valueForSelectable<T>(selectable: Selectable<T>): T {
  if (isComplexSelectable(selectable)) {
    return selectable.value;
  } else {
    return selectable;
  }
}

export function nodeForSelectable<T>(selectable: Selectable<T>): ReactNode {
  switch (typeof selectable) {
    case "string":
    case "number":
      return selectable;
    default:
      return selectable.node ?? selectable.text ?? selectable.value;
  }
}

export function textForSelectable<T>(selectable: Selectable<T>): string {
  switch (typeof selectable) {
    case "string":
      return selectable;
    case "number":
      return String(selectable);
    default:
      return selectable.text ?? String(selectable.value);
  }
}

export function isComplexSelectable<T>(
  selectable: Selectable<T>,
): selectable is Selectable<T> & Item<T> {
  return typeof selectable === "object";
}

export function selectableExists<T>(
  selectable: Selectable<T> | undefined,
): selectable is Selectable<T> {
  return selectable !== undefined;
}
