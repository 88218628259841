// When passed an enum, returns a function that returns true if a given value is
// included in the enum (the return asserts that the provided value has the enum type)
//
// enum Color {
//   Red = "r",
//   Green = "g",
//   Blue = "b",
// }

// const isColor = enumChecker(Color);

// const userColor: string = "r";
// const color1: Color = userColor; // Typecheck fails, string not assignable to Color
// const color2: Color | null = isColor(userColor) ? userColor : null; // Works
export function enumChecker<T extends string>(valueMap: { [key: string]: T }) {
  const valueSet = new Set<string>(Object.values(valueMap));

  return (value: string | null | undefined): value is T => {
    return value !== null && value !== undefined && valueSet.has(value);
  };
}

// When passed an enum and a default value, returns a function that takes a string and
// will return the enum entry with a value that matches the string, or the default value
// if not match is found
//
// enum Color {
//   Red = "r",
//   Green = "g",
//   Blue = "b",
// }

// const toColor = ensureEnum(Color, Color.Red);
// const color1 = toColor("g"); // Color.Green
// const color2 = toColor("green"); // Color.Red (the default)
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function ensureEnum<T extends string>(
  valueMap: { [key: string]: T },
  defaultValue: T,
) {
  const checker = enumChecker(valueMap);

  return (value: string | null | undefined): T => {
    if (checker(value)) {
      return value;
    } else {
      return defaultValue;
    }
  };
}
