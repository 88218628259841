import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";

export default function LoginButton({ className }: { className?: string }) {
  const { loginWithRedirect } = useAuth0();

  const onLogin = useCallback(() => loginWithRedirect(), [loginWithRedirect]);

  return (
    <button onClick={onLogin} className={className}>
      Sign In
    </button>
  );
}
