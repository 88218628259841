import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import cx from "classnames";
import { useCallback, useState } from "react";
import { BarChart2, Check, ChevronDown, Download, Table } from "react-feather";
import { Button } from "src/Common/Button";
import Spinner from "src/Common/Spinner";
import { useAsyncStatus } from "src/util/hooks";
import invariant from "tiny-invariant";
import { Menu, MenuItem } from "@spring/ui/Menu";
import { Tooltip } from "@spring/ui/Tooltip";

type DownloadType = "feature" | "analysis";

export function DownloadMenu({
  onExportFeature,
  onDownloadAnalysis,
  canExportFeature,
}: {
  onExportFeature: () => Promise<void>;
  onDownloadAnalysis: (() => Promise<void>) | null;
  canExportFeature: boolean;
}) {
  const requestState = useAsyncStatus();
  const [inProgressDownloadType, setInProgressDownloadType] =
    useState<DownloadType | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleDownload = useCallback(
    (downloadType: DownloadType) => {
      if (requestState.status !== "idle") {
        return;
      }

      const downloadFn =
        downloadType === "feature" ? onExportFeature : onDownloadAnalysis;
      invariant(downloadFn);

      setIsOpen(false);
      setInProgressDownloadType(downloadType);
      requestState.start(downloadFn, () => setInProgressDownloadType(null));
    },
    [onExportFeature, onDownloadAnalysis, requestState],
  );

  const handleClickStatusButton = useCallback(
    (open: boolean) => {
      // Disable clicking while a request is in flight
      if (requestState.status === "in-progress") {
        return;
      }

      // Reset state when clicking after a completed request
      if (
        requestState.status === "error" ||
        requestState.status === "complete"
      ) {
        requestState.reset();
      }
      setIsOpen(open);
    },
    [requestState],
  );

  const statusButton = ((state) => {
    switch (state.status) {
      case "idle":
        return (
          <Button
            name="Download"
            variant="primary"
            icon={Download}
            borderless={true}
          >
            Download{" "}
            <ChevronDown
              className={cx(
                "tw-ml-xs",
                isOpen && "tw-transition-all tw-rotate-180",
              )}
              size={12}
            />
          </Button>
        );
      case "in-progress":
        return (
          <button
            className="tw-flex tw-items-center tw-space-x-sm"
            disabled={true}
          >
            <Spinner /> <span>Downloading {inProgressDownloadType}...</span>
          </button>
        );
      case "complete":
        return (
          <button className="tw-flex tw-items-center tw-space-x-sm tw-text-green-success">
            <Check /> <span>Download complete</span>
          </button>
        );
      case "error":
        return (
          <Button
            name="Download"
            variant="danger"
            title={`Error while downloading: ${state.error.message}`}
            borderless={true}
          >
            Download failed
          </Button>
        );
    }
  })(requestState);

  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={handleClickStatusButton}>
      <DropdownMenu.Trigger asChild>{statusButton}</DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content align="start" asChild>
          <Menu>
            {canExportFeature ? (
              <MenuItem
                icon={Table}
                onClick={() => handleDownload("feature")}
                description="Selected measurements"
              >
                Download measurement
              </MenuItem>
            ) : (
              <Tooltip
                side="left"
                contents="Only one feature may be downloaded at a time."
              >
                <MenuItem
                  icon={Table}
                  description="Selected measurements"
                  disabled={true}
                >
                  Download measurement
                </MenuItem>
              </Tooltip>
            )}

            <MenuItem
              icon={BarChart2}
              onClick={() => handleDownload("analysis")}
              description="Data for this view"
              disabled={onDownloadAnalysis === null}
            >
              Download results
            </MenuItem>
          </Menu>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
