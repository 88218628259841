import { Select } from "@spring/ui/Select";
import { VerticalControlSection } from "../../../Control/ControlSection";
import { STATISTICAL_CORRECTIONS, STATISTICAL_TESTS } from "../constants";
import {
  StatisticalCorrection,
  StatisticalCorrectionType,
  StatisticalTest,
  StatisticalTestType,
} from "../types";

export function StatisticalSettings({
  statisticalCorrection,
  onChangeStatisticalCorrection,
  statisticalTest,
  onChangeStatisticalTest,
  isLoading,
}: {
  statisticalCorrection: StatisticalCorrection;
  onChangeStatisticalCorrection: (
    statisticalCorrection: StatisticalCorrectionType,
  ) => void;
  statisticalTest: StatisticalTest;
  onChangeStatisticalTest: (statisticalTest: StatisticalTestType) => void;
  isLoading: boolean;
}) {
  return (
    <div className="tw-flex tw-flex-col tw-py-md tw-pl-sm">
      <VerticalControlSection>
        <div className=" tw-text-gray-500 tw-pb-sm">Statistical Test</div>
        <Select
          items={STATISTICAL_TESTS.map((item) => ({
            value: item.type,
            text: item.displayName,
          }))}
          value={statisticalTest.type}
          onChange={onChangeStatisticalTest}
          disabled={isLoading}
        />
      </VerticalControlSection>
      <VerticalControlSection>
        <div className=" tw-text-gray-500 tw-pb-sm">
          Multiple Hypothesis Correction
        </div>
        <Select
          items={STATISTICAL_CORRECTIONS.map((item) => ({
            value: item.type,
            text: item.displayName,
          }))}
          value={statisticalCorrection.type}
          onChange={onChangeStatisticalCorrection}
          disabled={isLoading}
        />
      </VerticalControlSection>

      <div className="tw-flex-1" />
    </div>
  );
}
