/**
 * "Shoebill" is an alias for the ingestion tool prototype and
 * will be officially renamed when the tool is released/stable.
 **/
import { Route, Switch, useRouteMatch } from "react-router-dom";
import PageNotFound from "../../PageNotFound";
import { Configurator } from "./Configurator";
import { IngestionDashboard } from "./IngestionDashboard";
import { LandingPage } from "./LandingPage";

export default function Shoebill() {
  const match = useRouteMatch();
  return (
    <div className="tw-h-[calc(100vh-theme(spacing.global-nav-height))] tw-relative">
      <div className="tw-p-8 tw-pt-0">
        <Switch>
          <Route exact path={match.path}>
            <LandingPage />
          </Route>
          <Route path={`${match.path}/:workspaceId/:datasetId/configurator`}>
            <Configurator />
          </Route>
          <Route path={`${match.path}/:workspaceId/:datasetId/dashboard`}>
            <IngestionDashboard />
          </Route>
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
