/**
 * Component to render a heatmap of arbitrary data.
 */
import { Component } from "react";
import { DownloadUrl } from "src/Common/DownloadLink";
import { TopLevelSpec as VlSpec } from "vega-lite";
import { arrayEquals } from "@spring/core/utils";
import { ColorScheme } from "../Control/ColorSchemeSelector";
import VegaLite from "../Vega/VegaLite";
import { toMetadataColumns } from "../util/vega-util";
import { Datum } from "./types";

type Props = {
  data: Datum[];
  transforms?: any[];
  colorScheme: ColorScheme;
  metricName: string;
  metricType: "ordinal" | "quantitative" | "nominal";
  rowNames: string[];
  columnNames: string[];
  // Context information used as a prefix on the download filename.
  // Typically experiment + plate.
  filenamePrefix: string;
  size: number;
};

export default class HeatMapRenderer extends Component<Props> {
  static defaultProps = {
    colorScheme: "plasma",
    metricType: "quantitative",
  };

  exportDataAsCSV(): string {
    const headerRow = [""].concat(this.props.columnNames);
    const otherRows = this.props.rowNames.map((row) =>
      [row].concat(
        this.props.columnNames.map((col) => this.csvDataForCell(row, col)),
      ),
    );
    const allRows = [headerRow, ...otherRows];
    // This is all number data, so we don't need to worry about escaping.
    return allRows.map((row) => row.join(",")).join("\n");
  }

  csvDataForCell(row: string, column: string): string {
    const d = this.props.data.filter(
      (e) => e.row === row && e.column === column,
    );
    if (d.length === 0 || d[0].value == null) {
      return "";
    }
    return d[0].value.toString(10);
  }

  shouldComponentUpdate(nextProps: Props) {
    // We currently update the component if any of the data or non-data props change.
    // There may be instances where the length of the new and old data matches
    // and in such case, the component will not update.
    // TODO(Hosny): Converting this component into a function.
    return (
      this.props.colorScheme !== nextProps.colorScheme ||
      this.props.metricName !== nextProps.metricName ||
      this.props.size !== nextProps.size ||
      this.props.data.length !== nextProps.data.length ||
      !arrayEquals(this.props.rowNames, nextProps.rowNames) ||
      !arrayEquals(this.props.columnNames, nextProps.columnNames) ||
      !arrayEquals(
        toMetadataColumns(this.props.data),
        toMetadataColumns(nextProps.data),
      )
    );
  }

  render() {
    const {
      data,
      metricName,
      metricType,
      transforms,
      colorScheme,
      rowNames,
      columnNames,
      size,
      filenamePrefix,
    } = this.props;

    const metadataColumns = toMetadataColumns(data);
    const flatData = data.map((d) => {
      const { metadata, ...rest } = d;
      return { ...metadata, ...rest };
    });
    const hasHref = data.some((row) => !!row.href);

    const spec: VlSpec = {
      $schema: "https://vega.github.io/schema/vega-lite/v4.json",
      data: {
        values: flatData,
      },
      mark: "rect",
      transform: transforms ? transforms : [],
      encoding: {
        y: {
          field: "row",
          type: "ordinal",
          scale: {
            domain: rowNames,
          },
        },
        x: {
          field: "column",
          type: "ordinal",
          axis: { orient: "top" },
          scale: {
            domain: columnNames,
          },
        },
        color: {
          field: "value",
          title: metricName,
          type: metricType,
          scale: {
            scheme: {
              name: colorScheme,
            },
          },
          legend: {
            title: null,
            orient: "left",
          },
        },
        tooltip: [
          {
            field: "value",
            title: metricName,
            type: metricType,
          },
          ...metadataColumns.map(
            (column) =>
              ({
                field: column,
                type: "ordinal",
              }) as any,
          ),
        ],
        ...(hasHref ? { href: { field: "href", type: "nominal" } } : {}),
      },
      autosize: {
        resize: true,
      },
      width: size * columnNames.length,
      height: size * rowNames.length,
    };

    return (
      <div className="tw-flex tw-flex-col tw-gap-sm tw-items-center">
        <VegaLite spec={spec} renderer={"canvas"} />
        <DownloadUrl
          href={
            "data:text/csv;charset=utf-8," +
            encodeURIComponent(this.exportDataAsCSV())
          }
          filename={`${filenamePrefix}_${metricName}.csv`}
          text="Download as CSV"
        />
      </div>
    );
  }
}
