import cx from "classnames";
import { useEffect, useMemo, useRef, useState } from "react";
import { Provider } from "react-redux";
import { useDatasetSampleMetadataDB } from "src/hooks/datasets";
import { Fetchable } from "@spring/core/result";
import { FullScreenContainer } from "../Common/FullScreenContainer";
import Loader from "../Common/Loader";
import { TypedColumn } from "../Control/FilterSelector/backend-types";
import {
  Filter,
  FilterSet,
  isNestedFilter,
} from "../Control/FilterSelector/types";
import {
  EMPTY_FILTER_SET,
  deserializeFromUrlParam,
  useMetadataFilterColumns,
} from "../Control/FilterSelector/utils";
import { useMethods } from "../Methods/hooks";
import { MethodSectionKey } from "../Methods/utils";
import history from "../history";
import {
  useDatasetTimepoints,
  useDefaultMaskImageSet,
} from "../hooks/immunofluorescence";
import { usePrevious } from "../hooks/utils";
import { VisualizationContextProvider } from "../imaging/context";
import { ImageLoadEventBatchingContext } from "../imaging/state/hooks";
import configureStore from "../imaging/state/store";
import { Color } from "../imaging/types";
import VisualizationControls from "../immunofluorescence/VisualizationControls";
import { useTypedQueryParams } from "../routing";
import { DatasetId, UntypedWellSampleMetadataRow } from "../types";
import {
  CachedResult,
  createTypeSafeTableFromBlob,
  sql,
  useCacheableQueryAsRecords,
} from "../util/sql";
import { useComponentSpan } from "../util/tracing";
import ImageGroup from "./components/ImageGroup";
import OverlaySettingsTool from "./components/OverlaySettingsToolbar";
import Toolbar from "./components/Toolbar";
import { GROUP_PARAM_DELIM, IMAGE_SIZES, SIDEBAR_WIDTH } from "./constants";
import { Group } from "./types";
import { convertFilterToGroupParam } from "./utils";

// TODO: use the group id in the group label so umap can set nice names
// TODO why aren't we showing a loading indicator within the groups?
function ImageViewer({ dataset }: { dataset: DatasetId }) {
  useComponentSpan("CompareImages", [dataset]);

  const [showControls, setShowControls] = useState<boolean>(false);
  const [showSegmentation, setShowSegmentation] = useState<boolean>(false);
  const [imageSize, setImageSize] = useState<number>(IMAGE_SIZES.min);

  const timepoints = useDatasetTimepoints({ dataset });

  const hasMultipleTimepoints = useMemo(() => {
    return timepoints?.successful ? timepoints.value.length > 1 : null;
  }, [timepoints]);

  const viewportRef = useRef<HTMLDivElement>(null);

  // Note(davidsharff): we decided to go with a single display option for the MVP. I'm leaving this around as a backdoor
  // for internal experimentation. We can remove it later if it isn't useful or we adopt the feature for customers (remember
  // to drop the maskColor state as well if applicable, and potentially the binarize filter in BinaryTiff.tsx)
  const paramDecoders = useMemo(
    () => ({
      showMaskControl: (value: string | undefined) => value === "true",
      serializedGroups: {
        defaultValue: [],
        // TODO(you): Fix this no-unnecessary-condition rule violation
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        fromArray: (value: string[]) => value || [],
      },
    }),
    [],
  );

  const [queryParams, setQueryParams] = useTypedQueryParams(paramDecoders);

  const { serializedGroups } = queryParams;

  const metadataDB = useDatasetSampleMetadataDB({
    dataset,
    groupBy: null,
    transform: makeMetadataWithWellIdDB,
  });

  const filterColumns = useMetadataFilterColumns(dataset, metadataDB, null);

  const [selectedMaskColor, setSelectedMaskColor] = useState<Color>({
    r: 255,
    g: 0,
    b: 0,
    a: 1,
  });

  const groups: Group[] = useMemo(
    () =>
      // TODO(you): Fix this no-unnecessary-condition rule violation
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      serializedGroups &&
      serializedGroups.length > 0 &&
      filterColumns?.successful
        ? serializedGroups.map((serializedFilterParam) => {
            // TODO: confirm this delim is safe with our encoding scheme
            const [id, serializedFilter, label] =
              serializedFilterParam.split(GROUP_PARAM_DELIM);

            const filterSet = deserializeFromUrlParam(
              serializedFilter,
              filterColumns.value,
            );

            return {
              id,
              filterSet: filterSet
                ? attachTypeOptions(filterSet, filterColumns.value)
                : EMPTY_FILTER_SET,
              ...(label ? { label } : {}),
            };
          })
        : [{ id: "id-default", filterSet: EMPTY_FILTER_SET }],
    [filterColumns, serializedGroups],
  );

  // Only used to determine if we should enable the segmentation control
  const defaultMask = useDefaultMaskImageSet(dataset, imageSize);

  // Scroll new groups into view
  const prevSerializedGroupsLen = usePrevious(serializedGroups.length);

  useEffect(() => {
    if (
      prevSerializedGroupsLen &&
      serializedGroups.length > prevSerializedGroupsLen
    ) {
      // Reset position in the event loop so the DOM has time to update
      setTimeout(
        () =>
          // TODO(you): Fix this no-unnecessary-condition rule violation
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          viewportRef?.current?.scrollTo({
            left: document.body.scrollWidth,
            behavior: "smooth",
          }),
        0,
      );
    }
  }, [prevSerializedGroupsLen, serializedGroups.length]);

  const arbitraryFirstPlateQuery = useCacheableQueryAsRecords(
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    metadataDB?.successful ? metadataDB?.value : null,
    sql`SELECT plate FROM sample_metadata LIMIT 1`,
  ) as CachedResult<Fetchable<UntypedWellSampleMetadataRow[]>>;

  const arbitraryFirstPlate = arbitraryFirstPlateQuery.result?.successful
    ? arbitraryFirstPlateQuery.result.value[0].plate
    : null;

  if (!filterColumns || !metadataDB || !arbitraryFirstPlateQuery.result) {
    return (
      <FullScreenContainer center>
        <Loader />
      </FullScreenContainer>
    );
  }

  if (
    !filterColumns.successful ||
    !metadataDB.successful ||
    !arbitraryFirstPlateQuery.result.successful ||
    !arbitraryFirstPlate
  ) {
    return (
      <FullScreenContainer center>
        <div className="tw-text-red-error">
          Oops. Something went wrong loading your data. Please refresh and try
          again.
        </div>
      </FullScreenContainer>
    );
  }

  const handleAddGroup = () => {
    const newGroups = [
      ...groups,
      {
        // IDs are used for stable UI keys since removing a group can cause liist reorder bugs.
        // Using a simple random number to keep the url short and reasonably avoiding collisions.
        id: `id-${Math.round(Math.random() * 100000)}`,
        filterSet: EMPTY_FILTER_SET,
      },
    ].map(convertFilterToGroupParam);

    setQueryParams({
      ...queryParams,
      serializedGroups: newGroups,
    });
  };

  const handleRemoveGroup = (id: string) => {
    setQueryParams({
      ...queryParams,
      serializedGroups: groups
        .filter((g) => g.id !== id)
        .map(convertFilterToGroupParam),
    });
  };

  const handleChangeFilterSet = (id: string, newFilterSet: FilterSet) => {
    setQueryParams({
      ...queryParams,
      serializedGroups: groups.map((g) =>
        g.id === id
          ? convertFilterToGroupParam({
              ...g,
              filterSet: newFilterSet,
            })
          : convertFilterToGroupParam(g),
      ),
    });
  };

  const handleChangeImageSize = (newSize: number) => {
    setImageSize(newSize);
  };

  return (
    <>
      <div
        className={cx(
          "tw-fixed tw-top-global-nav-height tw-overflow-y-auto",
          "tw-h-[calc(100vh-theme(spacing.global-nav-height))]",
          "tw-bg-white tw-z-30 tw-p-4 tw-border-r",
          "tw-transition-all tw-ease-in-out tw-duration-300",
        )}
        style={{
          width: SIDEBAR_WIDTH,
          left: showControls ? 0 : -SIDEBAR_WIDTH,
        }}
      >
        <div className="tw-p-4">
          {arbitraryFirstPlate && (
            <VisualizationControls
              key={`${dataset}_${arbitraryFirstPlate}`}
              dataset={dataset}
              plate={arbitraryFirstPlate}
            />
          )}
          {queryParams.showMaskControl && (
            <OverlaySettingsTool
              color={selectedMaskColor}
              onChangeColor={(color) => setSelectedMaskColor(color)}
              disabled={!showSegmentation}
            />
          )}
        </div>
      </div>
      <VisualizationContextProvider>
        <Toolbar
          onAddGroup={handleAddGroup}
          onToggleControls={() => setShowControls(!showControls)}
          onToggleSegmentation={() => setShowSegmentation(!showSegmentation)}
          isSegmentationEnabled={!!defaultMask}
          showSegmentation={showSegmentation}
          imageSize={imageSize}
          onChangeImageSize={handleChangeImageSize}
          isSidebarOpen={showControls}
        />

        <div
          className={cx(
            "tw-flex tw-p-8",
            // Makes the sidebar/margin toggle smooth
            "tw-transition-all tw-ease-in-out tw-duration-300",
            "tw-max-h-[calc(100vh-theme(spacing.compare-images-toolbar-height)-theme(spacing.global-nav-height))]",
            "tw-mt-compare-images-toolbar-height",
            "tw-overflow-y-hidden",
          )}
          style={{
            marginLeft: showControls ? SIDEBAR_WIDTH : 0,
          }}
          ref={viewportRef}
        >
          {groups.map(({ id, filterSet: filter, label }, i) => (
            <ImageGroup
              key={id}
              groupIndex={i}
              label={label}
              metadataDB={metadataDB}
              metadataColumns={filterColumns.value}
              dataset={dataset}
              filterSet={filter}
              onChangeFilterSet={(newFilterSet) =>
                handleChangeFilterSet(id, newFilterSet)
              }
              showSegmentation={showSegmentation}
              selectedMaskColor={selectedMaskColor}
              imageSize={imageSize}
              onRemoveGroup={
                i > 0 || groups.length > 1 ? () => handleRemoveGroup(id) : null
              }
              isLastGroup={i === groups.length - 1}
              showTimepoints={hasMultipleTimepoints ?? false}
            />
          ))}
        </div>
      </VisualizationContextProvider>
    </>
  );
}

const store = configureStore(history);
// TODO(davidsharff): why does the visualization hook break without this wrapper?
export default function Wrapper({ dataset }: { dataset: DatasetId }) {
  useMethods(MethodSectionKey.imageViewer);
  return (
    <Provider store={store}>
      <ImageLoadEventBatchingContext.Provider value={false}>
        <ImageViewer dataset={dataset} />
      </ImageLoadEventBatchingContext.Provider>
    </Provider>
  );
}

function attachTypeOptions(
  filterSet: FilterSet,
  filterColumns: TypedColumn[],
): FilterSet {
  return {
    ...filterSet,
    filters: filterSet.filters.map((f) =>
      isNestedFilter(f)
        ? {
            ...f,
            filterSet: attachTypeOptions(f.filterSet, filterColumns),
          }
        : {
            ...f,
            column: {
              ...f.column,
              typeOptions:
                filterColumns.find((fc) => fc.id === f.column.id)
                  ?.typeOptions ?? f.column.typeOptions,
            },
          },
    ) as Filter[],
  };
}

// TODO(davidsharff): can this be replaced with using postgres for the canonical well id?
/**
 * Transform the metadata blob into a table with a synthetic well_id column.
 *
 * The well_id column is a plate and well combo with an additional optimization to
 * use a numeric id for the plate (based on a sorted row number).
 */
const makeMetadataWithWellIdDB = async (blob: Blob) => {
  const { db, conn } = await createTypeSafeTableFromBlob(blob, "temp_metadata");

  // Use a sorted row number as the plate id so that the well_id will be consistent throughout
  // the app. This is required for UMAP to create accurate links.
  const platesData = await conn.query(sql`
      SELECT ROW_NUMBER() OVER (ORDER BY plate) AS id, plate
      FROM (
          SELECT DISTINCT plate FROM temp_metadata
      ) sub
  `);

  // Note(davidsharff): when populating a new table, I found collecting the records ahead of time
  // and inserting as an arrowTable to be significantly more performant than my attempts at dynamic
  // update queries.
  await conn.insertArrowTable(platesData, {
    name: "temp_unq_plates",
    create: true,
  });

  const updatedMetadata = await conn.query(sql`
      SELECT temp_metadata.*, CAST(temp_unq_plates.id AS VARCHAR) || temp_metadata.well AS well_id
      FROM temp_metadata
      JOIN temp_unq_plates ON temp_metadata.plate = temp_unq_plates.plate;
  `);

  await conn.insertArrowTable(updatedMetadata, {
    name: "sample_metadata",
    create: true,
  });

  await conn.query(sql`
        DROP TABLE temp_unq_plates;
        DROP TABLE temp_metadata;
    `);

  await conn.close();
  return db;
};
