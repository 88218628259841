import * as Dialog from "@radix-ui/react-dialog";
import cx from "classnames";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { X } from "react-feather";

interface Props {
  children: ReactNode;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onClose?: () => void;
  disableClose?: boolean;
}

const DialogComponent = ({
  children,
  open,
  setOpen,
  onClose,
  disableClose = false,
}: Props) => {
  useEffect(() => {
    let style: HTMLStyleElement | null = null;
    if (open) {
      // TODO(davidsharff): HACK. Avoid radix dependency spewing console errors due to a
      // body tag scroll style (https://github.com/radix-ui/primitives/issues/1670).
      // I've opened an issue with Radix (https://github.com/radix-ui/primitives/issues/2367) to
      // bump their dependency version of react-remove-scroll (the ultimate culprit) to address it

      style = document.createElement("style");
      document.head.appendChild(style);
      const sheet = style.sheet;

      sheet?.insertRule("body { overflow: visible !important; }", 0);
    }
    return () => {
      if (style) {
        document.head.removeChild(style);
      }
    };
  }, [open]);

  const handleCloseModal = useCallback(() => {
    if (disableClose) {
      return;
    }
    setOpen(false);
    onClose?.();
  }, [disableClose, onClose, setOpen]);

  return (
    <Dialog.Root
      open={open}
      onOpenChange={() => {
        // Dialog is controlled by caller so closing is the only change state.
        handleCloseModal();
      }}
    >
      <Dialog.Portal>
        <Dialog.Overlay
          className={cx(
            "tw-bg-slate-500/[0.5] tw-z-dialog tw-fixed tw-inset-0",
            "tw-flex tw-justify-center tw-items-center tw-overflow-scroll",
          )}
        >
          <Dialog.Content
            className={cx(
              "tw-bg-white tw-outline-none tw-rounded-md tw-relative tw-my-2",
            )}
          >
            {!disableClose && (
              <X
                className={cx(
                  "tw-absolute tw-right-2 tw-top-2 tw-cursor-pointer",
                  "hover:tw-bg-slate-700 tw-rounded-xl tw-h-[20px] tw-w-[20px] tw-text-white tw-z-20",
                )}
                onClick={() => handleCloseModal()}
              />
            )}
            {children}
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export function useDialog({ onClose }: { onClose?: () => void } = {}) {
  const [open, setOpen] = useState(false);

  const DialogComponentWithState = useCallback(
    ({
      children,
      disableClose,
    }: {
      children: ReactNode;
      // Give caller full control over close behavior
      disableClose?: boolean;
    }) => {
      return (
        <DialogComponent
          open={open}
          setOpen={setOpen}
          onClose={onClose}
          disableClose={disableClose}
        >
          {children}
        </DialogComponent>
      );
    },
    [onClose, open],
  );

  return {
    DialogComponent: DialogComponentWithState,
    setOpenDialog: setOpen,
    isDialogOpen: open,
  };
}
