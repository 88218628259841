import cx from "classnames";
import datalib from "datalib";
import lodashMax from "lodash.max";
import lodashMin from "lodash.min";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { buildSort, invert } from "src/util/build-sort";
import { omit } from "vega-lite";
import { groupByWithMap } from "@spring/core/utils";
import Strut from "../../../Common/Strut";
import {
  colorSchemeByWellMetadata,
  colorValuesByScheme,
} from "../../../Control/ColorSchemeSelector";
import DropdownList from "../../../Control/Dropdown/DropdownList";
import { inferTypeFromFeatureSetName } from "../../../FeatureSelector/utils";
import { useFeatureFlag } from "../../../Workspace/feature-flags";
import useWindowDimensions from "../../../hooks/utils";
import { FeatureLevel, MetadataColumnValue } from "../../../types";
import {
  getUniqueValuesByColumn,
  sortKeysByValueSize,
} from "../../../util/dataset-util";
import { defaultComparator } from "../../../util/sorting";
import {
  MetadataAndValue,
  StatisticalCorrection,
  StatisticalTest,
  XAxisSort,
  XAxisSortDirection,
} from "../types";
import BoxPlot from "./BoxPlot";

const sorter = (a: MetadataColumnValue, b: MetadataColumnValue) =>
  defaultComparator(a + "", b + "");

// TODO(davidsharff): even 6 could overflow the window on shorter displays.
const MAX_TOOLTIP_METADATA_COLUMNS = 6;
const MAX_INITIAL_BOX_PLOTS = 15;
const MAX_INITIAL_SUBGROUPS = 3;

type DomainAndColor = { domain: MetadataColumnValue; color: string };

type CalculatedValues = {
  pValue: number;
  mean: number;
};

function calculateTestValues(
  data: MetadataAndValue[],
  controlValues: number[],
  numberOfTests: number | null = null,
  correction: StatisticalCorrection,
  test: StatisticalTest,
): CalculatedValues {
  const values = data.map(({ value }) => value);
  const uncorrectedPValue = test.getTestValue({ values, controlValues });
  return {
    mean: datalib.mean(values),
    pValue: correction.getCorrectedPValue({ uncorrectedPValue, numberOfTests }),
  };
}

type GraphBodyBaseProps = {
  data: MetadataAndValue[];
  groupKey: string;
  subgroupKey: string | null;
  compareTo: MetadataColumnValue;
  showPoints: boolean;
  featureName: string;
  featureLevel: FeatureLevel;
  column: string;
  userAddedGroups: string[];
  userAddedSubgroups: string[];
  onAddUserGroup: (group: string) => void;
  onAddUserSubgroup: (subgroup: string) => void;
  yAxisMin: number | undefined;
  yAxisMax: number | undefined;
  xAxisSort: XAxisSort;
  xAxisSortDirection: XAxisSortDirection;
  statisticalCorrection: StatisticalCorrection;
  statisticalTest: StatisticalTest;
};

type GraphBodyProps = GraphBodyBaseProps & {
  tooltipMetadataColumns: string[];
};

function GraphBody({
  data,
  groupKey,
  subgroupKey,
  compareTo,
  showPoints,
  featureName,
  featureLevel,
  column,
  tooltipMetadataColumns,
  userAddedGroups,
  userAddedSubgroups,
  yAxisMin: yAxisMinSetting,
  yAxisMax: yAxisMaxSetting,
  xAxisSort,
  xAxisSortDirection,
  onAddUserGroup,
  onAddUserSubgroup,
  statisticalCorrection,
  statisticalTest,
}: GraphBodyProps) {
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  // Using a memo so we don't recalculate when a subgroup is added or the compareTo changes
  const groupedData: Map<MetadataColumnValue, MetadataAndValue[]> = useMemo(
    () => groupByWithMap<MetadataAndValue>(data, (o) => o[groupKey]),
    [groupKey, data],
  );

  // Go ahead and subgroup all the data when applicable. It isn't very expensive and is useful when creating the
  // domain options.
  const subgroupData: Map<
    MetadataColumnValue,
    Map<MetadataColumnValue, MetadataAndValue[]>
  > | null = subgroupKey ? createSubgroupData(groupedData, subgroupKey) : null;

  // TODO(benkomalo): avoid copy? This could be large.
  const groupEntries = [...groupedData];
  const pinnedGroupEntry = groupEntries.find(([gKey]) => gKey === compareTo)!;

  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const pinnedGroupValues = pinnedGroupEntry
    ? pinnedGroupEntry[1].map(({ value }) => value)
    : [];

  // TODO(benkomalo): this should probably be memoized?
  // Calculate the medians of all values since we need to sort by them to sample the
  // groups if there are too many.
  const groupMedians: Map<MetadataColumnValue, number> = new Map();
  groupedData.forEach((metadataAndValues, key) => {
    const values = metadataAndValues.map(({ value }) => value);
    groupMedians.set(key, datalib.median(values));
  });

  // defaultGroups are derived from sortedGroupEntries so they are combined in one memo.
  const sortedParentGroups = groupEntries.sort(
    buildSort<[MetadataColumnValue, MetadataAndValue[]]>(
      ([parentGroupKey]: [MetadataColumnValue]) => parentGroupKey === compareTo,
      invert(
        xAxisSort === XAxisSort.Median
          ? ([parentGroupKey]: [MetadataColumnValue]) =>
              groupMedians.get(parentGroupKey)!
          : xAxisSort === XAxisSort.AbsoluteDelta
            ? ([parentGroupKey]: [MetadataColumnValue]) =>
                Math.abs(
                  groupMedians.get(compareTo)! -
                    groupMedians.get(parentGroupKey)!,
                )
            : (
                [parentGroupKeyA]: [MetadataColumnValue],
                [parentGroupKeyB]: [MetadataColumnValue],
              ) =>
                defaultComparator(
                  String(parentGroupKeyA),
                  String(parentGroupKeyB),
                ),
        xAxisSortDirection === XAxisSortDirection.Desc,
      ),
    ),
  );

  const defaultGroupEntries = sortedParentGroups.slice(
    0,
    MAX_INITIAL_BOX_PLOTS,
  );
  const defaultGroups = defaultGroupEntries.map(
    ([parentGroup]) => parentGroup + "",
  );

  const numSubgroups = Array.from(subgroupData?.values() || []).reduce(
    (totalSize, childGroups) => totalSize + childGroups.size,
    0,
  );

  const visibleParentGroups = useMemo(
    () => [...new Set([...defaultGroups, ...userAddedGroups, compareTo + ""])],
    [compareTo, defaultGroups, userAddedGroups],
  );

  const { yAxisMin, yAxisMax } = useMemo((): {
    yAxisMin: number;
    yAxisMax: number;
  } => {
    if (yAxisMinSetting !== undefined && yAxisMaxSetting !== undefined) {
      return { yAxisMin: yAxisMinSetting, yAxisMax: yAxisMaxSetting };
    }
    const [yAxisMinValueFromData, yAxisMaxValueFromData] = getMinMaxValues(
      sortedParentGroups,
      visibleParentGroups,
    );

    return {
      yAxisMin: yAxisMinSetting ?? yAxisMinValueFromData,
      yAxisMax: yAxisMaxSetting ?? yAxisMaxValueFromData,
    };
  }, [
    sortedParentGroups,
    visibleParentGroups,
    yAxisMaxSetting,
    yAxisMinSetting,
  ]);

  const defaultSubgroups = subgroupData
    ? getDefaultSubgroups(
        subgroupData,
        // We can't use defaultGroupEntries because it doesn't contain user added groups.
        groupEntries.filter(([parentGroup]) =>
          visibleParentGroups.includes(parentGroup + ""),
        ),
      )
    : [];

  const visibleSubgroups =
    numSubgroups > 0
      ? [
          ...new Set([
            ...defaultSubgroups.map((subgroup) => subgroup + ""),
            ...userAddedSubgroups,
          ]),
        ]
      : [];

  // Used for the domain. It's surprisingly not an expensive operation.
  const sortedUniqueSubgroups = subgroupData
    ? getUniqueSubgroups(subgroupData).sort(sorter)
    : null;

  // TODO(benkomalo): avoid calling this on everything that isn't going to be rendered
  // if we're sampling.
  const domainAndColors: DomainAndColor[] = useMemo(
    () =>
      getDomainAndColors(
        sortedUniqueSubgroups || sortedParentGroups.map(([key]) => key),
      ),
    [sortedParentGroups, sortedUniqueSubgroups],
  );

  const visibleDomainAndColors = domainAndColors.filter(({ domain }) =>
    subgroupKey
      ? visibleSubgroups.includes(domain + "")
      : visibleParentGroups.includes(domain + ""),
  );

  // TODO(trisorus): Ideally we would include the aggregation method in the metadata and use that
  // as the source of truth, but this is a reliable stopgap solution (see SPR-2739)
  const yAxisLabel =
    inferTypeFromFeatureSetName(featureName) === "prediction"
      ? `Fraction of “${column}” cells per well`
      : // TODO(davidsharff): confirm the WellAggregated features really are using median and if that works for us here.
        //  My initial testing comparing the client-side AVG() values to the new ones showed fairly similiar results
        //  but on the extreme end the values were different by as much as 27% (tested on CompactMito - Positive in CRL)
        "Median per Well";

  const lastPinnedSubgroupRef = useRef<HTMLDivElement | null>(null);
  const lastSubgroupBoundingRect = subgroupKey
    ? // TODO(you): Fix this no-unnecessary-condition rule violation
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      lastPinnedSubgroupRef?.current?.getBoundingClientRect()
    : null;

  // TODO(davidsharff): update with the addition of the sidebar. Probably should use the viewport container div
  // div for width.
  // Scroll the entire graph if the pinned subgroups use more than 60% of the screen.
  const disableInnerScroll =
    !!lastSubgroupBoundingRect &&
    (lastSubgroupBoundingRect.x + lastSubgroupBoundingRect.width) /
      windowWidth >
      0.6;

  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const makePinnedGroupSticky = !!pinnedGroupEntry && !disableInnerScroll;
  const otherGroupsEntries = sortedParentGroups
    .slice(makePinnedGroupSticky ? 1 : 0)
    .filter(([groupVal]) =>
      visibleParentGroups.some(
        (visibleGroupVal) => visibleGroupVal === groupVal + "",
      ),
    );
  const numberOfComparisons = groupEntries.length - 1;

  const hideSignifianceStars = useFeatureFlag(
    "hide-significance-stars-comparisons-view",
  );

  return (
    <div className="tw-flex-1 tw-flex tw-flex-col tw-relative tw-pt-2">
      <div
        className={cx(
          "tw-flex tw-w-full",
          makePinnedGroupSticky ? "tw-overflow-x-hidden" : "tw-overflow-x-auto",
        )}
      >
        {makePinnedGroupSticky && (
          <div
            key={pinnedGroupEntry[0] + ""}
            className="tw-flex tw-flex-col tw-items-center tw-justify-between"
          >
            {subgroupKey ? (
              <>
                <SubgroupGraphs
                  ref={lastPinnedSubgroupRef}
                  subgroups={subgroupData!.get(pinnedGroupEntry[0])!}
                  visibleSubgroups={visibleSubgroups}
                  subgroupKey={subgroupKey}
                  yAxisIsVisible
                  yAxisLabel={yAxisLabel}
                  isPinnedGroup
                  parentGroupValue={pinnedGroupEntry[0]}
                  domainAndColors={domainAndColors}
                  windowWidth={windowWidth}
                  windowHeight={windowHeight}
                  sortedParentGroups={sortedParentGroups}
                  visibleParentGroups={visibleParentGroups}
                  tooltipMetadataColumns={tooltipMetadataColumns}
                  pinnedGroupValues={pinnedGroupValues}
                  column={column}
                  compareTo={compareTo}
                  featureLevel={featureLevel}
                  featureName={featureName}
                  showPoints={showPoints}
                  yAxisMin={yAxisMin}
                  yAxisMax={yAxisMax}
                  numberOfComparisons={1} // Kind of a dummy value.
                  statisticalCorrection={statisticalCorrection}
                  statisticalTest={statisticalTest}
                />
              </>
            ) : // TODO(you): Fix this no-unnecessary-condition rule violation
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            pinnedGroupEntry[0] !== undefined ? (
              <WrappedGraph
                key={pinnedGroupEntry[0] + ""}
                groupingKey={groupKey}
                groupingValue={pinnedGroupEntry[0]}
                data={pinnedGroupEntry[1]}
                yAxisIsVisible
                yAxisLabel={yAxisLabel}
                calculatedValues={
                  hideSignifianceStars
                    ? null
                    : calculateTestValues(
                        pinnedGroupEntry[1],
                        pinnedGroupValues,
                        numberOfComparisons,
                        statisticalCorrection,
                        statisticalTest,
                      )
                }
                domainAndColors={domainAndColors}
                windowWidth={windowWidth}
                windowHeight={windowHeight}
                tooltipMetadataColumns={tooltipMetadataColumns}
                pinnedGroupValues={pinnedGroupValues}
                column={column}
                compareTo={compareTo}
                featureLevel={featureLevel}
                featureName={featureName}
                showPoints={showPoints}
                yAxisMin={yAxisMin}
                yAxisMax={yAxisMax}
                statisticalCorrection={statisticalCorrection}
              />
            ) : null}
          </div>
        )}
        <div className="tw-flex tw-overflow-x-auto">
          {otherGroupsEntries.map(
            ([groupVal, groupValues], parentGroupIndex) => {
              const isPinnedGroup =
                disableInnerScroll && parentGroupIndex === 0;
              return (
                <div
                  key={groupVal + ""}
                  className="tw-flex tw-flex-col tw-items-center tw-justify-between"
                >
                  {subgroupKey ? (
                    <SubgroupGraphs
                      ref={lastPinnedSubgroupRef}
                      subgroups={subgroupData!.get(groupVal)!}
                      visibleSubgroups={visibleSubgroups}
                      subgroupKey={subgroupKey}
                      yAxisIsVisible={isPinnedGroup}
                      yAxisLabel={yAxisLabel}
                      isPinnedGroup={isPinnedGroup}
                      parentGroupValue={groupVal}
                      domainAndColors={domainAndColors}
                      windowWidth={windowWidth}
                      windowHeight={windowHeight}
                      sortedParentGroups={sortedParentGroups}
                      visibleParentGroups={visibleParentGroups}
                      tooltipMetadataColumns={tooltipMetadataColumns}
                      pinnedGroupValues={pinnedGroupValues}
                      column={column}
                      compareTo={compareTo}
                      featureLevel={featureLevel}
                      featureName={featureName}
                      showPoints={showPoints}
                      yAxisMin={yAxisMin}
                      yAxisMax={yAxisMax}
                      numberOfComparisons={numberOfComparisons}
                      statisticalCorrection={statisticalCorrection}
                      statisticalTest={statisticalTest}
                    />
                  ) : (
                    <WrappedGraph
                      key={groupVal + ""}
                      groupingKey={groupKey}
                      groupingValue={groupVal}
                      data={groupValues}
                      yAxisIsVisible={false}
                      yAxisLabel={yAxisLabel}
                      calculatedValues={
                        hideSignifianceStars
                          ? null
                          : calculateTestValues(
                              groupValues,
                              pinnedGroupValues,
                              numberOfComparisons,
                              statisticalCorrection,
                              statisticalTest,
                            )
                      }
                      domainAndColors={domainAndColors}
                      windowWidth={windowWidth}
                      windowHeight={windowHeight}
                      tooltipMetadataColumns={tooltipMetadataColumns}
                      pinnedGroupValues={pinnedGroupValues}
                      column={column}
                      compareTo={compareTo}
                      featureLevel={featureLevel}
                      featureName={featureName}
                      showPoints={showPoints}
                      yAxisMin={yAxisMin}
                      yAxisMax={yAxisMax}
                      statisticalCorrection={statisticalCorrection}
                    />
                  )}
                </div>
              );
            },
          )}
        </div>
        {domainAndColors.length && (
          <Legend
            title={subgroupKey || groupKey}
            domainAndColors={domainAndColors}
            visibleDomainAndColors={visibleDomainAndColors}
            onMakeGroupVisible={(groupOrSubgroupStr: string) => {
              if (subgroupKey) {
                onAddUserSubgroup(groupOrSubgroupStr);
              } else {
                onAddUserGroup(groupOrSubgroupStr);
              }
            }}
          />
        )}
      </div>
    </div>
  );
}

/**
 * A wrapper for the underlying BoxPlot which calculates and filters data.
 *
 * It's assumed that the data is tied to the groupingValue and groupingKey; if
 * the underlying data changes but the groupingValue or key are not, clients are
 * expected to unmount and remount a new instance.
 */
function WrappedGraph({
  groupingValue,
  groupingKey,
  data,
  yAxisIsVisible,
  yAxisLabel,
  calculatedValues,
  parentGroupValue,
  domainAndColors,
  windowWidth,
  windowHeight,
  showPoints,
  compareTo,
  featureName,
  featureLevel,
  column,
  tooltipMetadataColumns,
  pinnedGroupValues,
  yAxisMin,
  yAxisMax,
  statisticalCorrection,
}: {
  groupingValue: MetadataColumnValue;
  groupingKey: string;
  data: MetadataAndValue[];
  yAxisIsVisible: boolean;
  yAxisLabel: string;
  calculatedValues: CalculatedValues | null;
  parentGroupValue?: MetadataColumnValue | null;
  domainAndColors: DomainAndColor[];
  windowWidth: number;
  windowHeight: number;
  showPoints: boolean;
  compareTo: MetadataColumnValue;
  featureName: string;
  featureLevel: FeatureLevel;
  column: string;
  tooltipMetadataColumns: string[];
  pinnedGroupValues: number[];
  yAxisMin: number;
  yAxisMax: number;
  statisticalCorrection: StatisticalCorrection;
}) {
  const { domain, color } = domainAndColors.find(
    ({ domain }) => data[0][groupingKey] === domain,
  )!;
  const boxPlotWidth = calculateDefaultBoxplotWidth(windowWidth);
  const graphContainerHeight = calculateGraphContainerHeight(windowHeight);
  const pinnedGroupMean: number = datalib.mean(pinnedGroupValues);
  const filteredData = useMemo(
    () => data.filter((o) => o[groupingKey] === groupingValue),
    // See docstring for expectations on data being tied to
    // groupingKey and groupingValue.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupingKey, groupingValue],
  );

  return (
    <BoxPlot
      data={filteredData}
      groupKey={groupingKey}
      domain={[domain]}
      colors={[color]}
      yAxisMinValue={yAxisMin}
      yAxisMaxValue={yAxisMax}
      yAxisIsVisible={yAxisIsVisible}
      xAxisLabelRotationDeg={-45}
      showPoints={showPoints}
      yAxisTitle={yAxisIsVisible ? yAxisLabel : null}
      significanceValue={
        calculatedValues?.pValue !== undefined ? calculatedValues.pValue : null
      }
      statisticalCorrection={statisticalCorrection}
      mean={calculatedValues?.mean !== undefined ? calculatedValues.mean : null}
      compareTo={compareTo}
      compareToMean={compareTo === groupingValue + "" ? null : pinnedGroupMean}
      featureName={featureName}
      featureLevel={featureLevel}
      column={column}
      metadataColumns={tooltipMetadataColumns}
      graphContainerHeight={graphContainerHeight}
      boxPlotWidth={boxPlotWidth}
      parentGroupData={
        parentGroupValue
          ? {
              groupKey: groupingKey,
              groupValue: parentGroupValue,
            }
          : null
      }
    />
  );
}

const SubgroupGraphs = React.forwardRef<
  HTMLDivElement,
  {
    subgroups: Map<MetadataColumnValue, MetadataAndValue[]>;
    visibleSubgroups: string[];
    subgroupKey: string;
    yAxisLabel: string;
    yAxisIsVisible: boolean;
    isPinnedGroup: boolean;
    parentGroupValue: MetadataColumnValue;
    domainAndColors: DomainAndColor[];
    windowWidth: number;
    windowHeight: number;
    sortedParentGroups: [MetadataColumnValue, MetadataAndValue[]][];
    visibleParentGroups: string[];
    showPoints: boolean;
    compareTo: MetadataColumnValue;
    featureName: string;
    featureLevel: FeatureLevel;
    column: string;
    tooltipMetadataColumns: string[];
    pinnedGroupValues: number[];
    yAxisMin: number;
    yAxisMax: number;
    numberOfComparisons: number;
    statisticalCorrection: StatisticalCorrection;
    statisticalTest: StatisticalTest;
  }
>(function SubgroupGraphs(
  {
    subgroups,
    visibleSubgroups,
    subgroupKey,
    yAxisLabel,
    yAxisIsVisible,
    isPinnedGroup,
    parentGroupValue,
    domainAndColors,
    windowWidth,
    windowHeight,
    showPoints,
    compareTo,
    featureName,
    featureLevel,
    column,
    tooltipMetadataColumns,
    pinnedGroupValues,
    yAxisMin,
    yAxisMax,
    numberOfComparisons,
    statisticalCorrection,
    statisticalTest,
  },
  ref: React.Ref<HTMLDivElement>,
) {
  const thisGroupsVisibleSubgroups = [...subgroups].filter(([subgroupValue]) =>
    visibleSubgroups.includes(subgroupValue + ""),
  );
  const hideSignifianceStars = useFeatureFlag(
    "hide-significance-stars-comparisons-view",
  );

  return (
    <>
      <div className={"tw-flex tw-mx-4"}>
        {thisGroupsVisibleSubgroups
          .sort(([a], [b]) => sorter(a, b))
          .map(([subgroupValue, subgroupData], subgroupValueIndex) => (
            <div
              key={subgroupValue + ""}
              ref={
                isPinnedGroup &&
                subgroupValueIndex === thisGroupsVisibleSubgroups.length - 1
                  ? ref
                  : null
              }
            >
              <WrappedGraph
                key={subgroupValue + ""}
                groupingKey={subgroupKey}
                groupingValue={subgroupValue}
                data={subgroupData}
                yAxisLabel={yAxisLabel}
                yAxisIsVisible={yAxisIsVisible && subgroupValueIndex === 0}
                calculatedValues={
                  hideSignifianceStars || isPinnedGroup
                    ? null
                    : calculateTestValues(
                        subgroupData,
                        pinnedGroupValues,
                        numberOfComparisons,
                        statisticalCorrection,
                        statisticalTest,
                      )
                }
                parentGroupValue={parentGroupValue}
                domainAndColors={domainAndColors}
                windowWidth={windowWidth}
                windowHeight={windowHeight}
                showPoints={showPoints}
                compareTo={compareTo}
                featureName={featureName}
                featureLevel={featureLevel}
                column={column}
                tooltipMetadataColumns={tooltipMetadataColumns}
                pinnedGroupValues={pinnedGroupValues}
                yAxisMin={yAxisMin}
                yAxisMax={yAxisMax}
                statisticalCorrection={statisticalCorrection}
              />
            </div>
          ))}
      </div>
      <SubgroupFooter
        title={parentGroupValue}
        isPinnedGroup={isPinnedGroup}
        width={
          // Hack(davidsharff): we don't want this label to overflow, so I chose a reasonable
          // minimum total boxplot width (including whatever extra spacing vega adds).
          thisGroupsVisibleSubgroups.length * 68
        }
      />
    </>
  );
});

function SubgroupFooter({
  title,
  isPinnedGroup,
  width,
}: {
  title: MetadataColumnValue;
  isPinnedGroup: boolean;
  width: number;
}) {
  return (
    <div
      className={cx(isPinnedGroup && "tw-ml-[34px]")}
      style={{
        width,
      }}
    >
      <div className="tw-truncate tw-text-center">
        {
          // This could possibly be null
          title + ""
        }
      </div>
    </div>
  );
}

function Legend({
  title,
  domainAndColors,
  visibleDomainAndColors,
  onMakeGroupVisible,
}: {
  title: string;
  domainAndColors: DomainAndColor[];
  visibleDomainAndColors: DomainAndColor[];
  onMakeGroupVisible: (domain: string) => void;
}) {
  const [showLegendSearch, setShowLegendSearch] = useState(false);
  const legendSearchRef: any = useRef();

  const legendElems = visibleDomainAndColors.map(({ domain, color }) => (
    <LegendElem key={domain + ""} domain={domain} color={color} />
  ));

  const numHiddenItems = domainAndColors.length - visibleDomainAndColors.length;

  useEffect(() => {
    setShowLegendSearch(false);
  }, [domainAndColors]);

  // TODO(davidsharff): move max height to const and share with graph
  return (
    <div className="tw-ml-2 tw-max-w-[150px]">
      <div className="tw-truncate">{title}</div>
      <div className="tw-max-h-[calc(480px)] tw-overflow-y-auto">
        {legendElems}
      </div>
      {numHiddenItems > 0 && (
        <span
          ref={legendSearchRef}
          onClick={() => setShowLegendSearch(!showLegendSearch)}
          className={cx(
            "tw-cursor-pointer",
            numHiddenItems === 0 && "tw-hidden",
          )}
        >
          Show More ({numHiddenItems})
        </span>
      )}
      {showLegendSearch &&
        visibleDomainAndColors.length !== domainAndColors.length && (
          <DropdownList
            items={domainAndColors
              .filter(
                ({ domain }) =>
                  !visibleDomainAndColors.some(
                    (vDomain) => vDomain.domain === domain,
                  ),
              )
              .map(({ domain, color }) => ({
                id: domain + "",
                title: domain + "",
                color,
                node: (
                  <>
                    <Strut size={"0.25rem"} />
                    <LegendElem domain={domain} color={color} />
                  </>
                ),
              }))}
            searchable
            placeholder={"Find an option"}
            onClick={(item) => {
              onMakeGroupVisible(item.id);
            }}
          />
        )}
    </div>
  );
}

function LegendElem({ domain, color }: DomainAndColor) {
  // Note: added opacity matches the box plot color (which requires opacity for points to appear)
  const domainDisplay = domain === null ? "null" : String(domain);
  return (
    <div key={domainDisplay} className="tw-flex tw-items-center">
      <span
        className="tw-w-3 tw-h-3 tw-mr-1 tw-opacity-50"
        style={{ backgroundColor: color }}
      />
      <span className="tw-truncate tw-w-[150px]" title={domainDisplay}>
        {domainDisplay}
      </span>
    </div>
  );
}

type GraphBodyWrapperProps = GraphBodyBaseProps & {
  data: MetadataAndValue[];
  interestingColumns: string[];
};
// TODO(davidsharff): how should we name this and main component? The wrapper shouldn't exported
/**
 * Controls the data that does not change when a toolbar option is toggled.
 */
export default function GraphBodyWrapper({
  groupKey,
  subgroupKey,
  data,
  interestingColumns,
  statisticalCorrection,
  statisticalTest,
  ...rest
}: GraphBodyWrapperProps) {
  // TODO(benkomalo): move this to duckdb.
  const uniqueGroupValues = useMemo(() => {
    if (data.length > 0) {
      const baseKeys = Object.keys(data[0]); // Note: this approach assumes the same keys for all objects.
      const omitKeys = baseKeys.filter((k) => !interestingColumns.includes(k));
      const uniqueValuesByColumn = getUniqueValuesByColumn(data);

      return omit(uniqueValuesByColumn, omitKeys);
    }
    return [];
  }, [data, interestingColumns]);

  const tooltipMetadataColumns = useMemo(() => {
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return uniqueGroupValues
      ? sortKeysByValueSize(uniqueGroupValues)
          .filter((key) => key !== groupKey && key !== subgroupKey)
          .slice(0, MAX_TOOLTIP_METADATA_COLUMNS)
      : [];
  }, [groupKey, subgroupKey, uniqueGroupValues]);

  // TODO: send memo results into state in the child and/or parent so we can get a loading indicator
  return (
    <GraphBody
      key={`${groupKey}_${subgroupKey}`}
      groupKey={groupKey}
      subgroupKey={subgroupKey}
      data={data}
      tooltipMetadataColumns={tooltipMetadataColumns}
      statisticalCorrection={statisticalCorrection}
      statisticalTest={statisticalTest}
      {...rest}
    />
  );
}

function getDomainAndColors(groups: MetadataColumnValue[]): DomainAndColor[] {
  const metadataColors = colorValuesByScheme(
    groups,
    // TODO(benkomalo): colorSchemeByWellMetadata naively parses the data and looks for
    // things like determines the number of unique values, etc. We already know that
    // probably so should just hardcode a colour scheme?
    colorSchemeByWellMetadata(groups),
  );

  return groups.map((domain) => ({
    domain,
    // TODO(davidsharff): fallback is unnecessary. Fancier/explict typing of metadataColors would allow us to remove it.
    color: metadataColors.get(domain) || "#ccc",
  }));
}

function getMinMaxValues(
  sortedGroupEntries: [MetadataColumnValue, MetadataAndValue[]][],
  visibleParentGroups: string[],
): [number, number] {
  const featureValues = sortedGroupEntries
    .filter(([groupKey]) => visibleParentGroups.includes(groupKey + ""))
    .flatMap(([, groupValues]) => groupValues.map(({ value }) => value));

  return [lodashMin(featureValues) ?? 0, lodashMax(featureValues) ?? 0];
}

function calculateGraphContainerHeight(windowHeight: number) {
  const defaultScreenSize = 1000;
  const defaultGraphHeight = 500;

  return Math.max(defaultGraphHeight - (defaultScreenSize - windowHeight), 300);
}

function calculateDefaultBoxplotWidth(windowWidth: number) {
  // Note(davidsharff): I created these starting constants based on my own viewport dimensions:
  // 16 inch MBP with default resolution (looks like 1728 x 1117)
  const defaultScreenWidth = 1728;
  const defaultBoxPlotWidth = 50;

  const screenWidthOffsetPct =
    (windowWidth - defaultScreenWidth) / defaultScreenWidth;

  return Math.max(
    defaultBoxPlotWidth + defaultBoxPlotWidth * screenWidthOffsetPct,
    30,
  );
}

function getUniqueSubgroups(
  subgroupData: Map<
    MetadataColumnValue,
    Map<MetadataColumnValue, MetadataAndValue[]>
  >,
) {
  return Array.from(
    new Set(
      Array.from(subgroupData.values()).flatMap((subgroupMap) =>
        Array.from(subgroupMap.keys()),
      ),
    ),
  );
}

function createSubgroupData(
  groupedData: Map<MetadataColumnValue, MetadataAndValue[]>,
  subgroupKey: string,
): Map<MetadataColumnValue, Map<MetadataColumnValue, MetadataAndValue[]>> {
  const subgroupData = new Map();

  for (const [groupKey, groupValues] of groupedData.entries()) {
    const subgroups = groupByWithMap<MetadataAndValue>(
      groupValues,
      (o) => o[subgroupKey],
    );
    subgroupData.set(groupKey, subgroups);
  }

  return subgroupData;
}

/**
 * Samples subgroups for performance reasons to ensure we don't blowout the display with too many groups.
 *
 * Strategy:
 *
 *   1. All visible parent groups are guaranteed up to 3 subgroups. This may exceed the max but never
 *      by very much realistically (max = visible parents * 3). Care is taken to ensure subgroups are shared across
 *      parents to keep the total count as low as possible
 *
 *   2. If the minimum box plots are under our total threshold try adding new subgroups beginning with the least common
 *      groups to maximize the total visible groups for the least possible box plots.
 */
function getDefaultSubgroups(
  subgroupData: Map<
    MetadataColumnValue,
    Map<MetadataColumnValue, MetadataAndValue[]>
  >,
  visibleParentGroupEntries: [MetadataColumnValue, MetadataAndValue[]][],
) {
  const defaultSubgroups: MetadataColumnValue[] = [];
  const totalSubgroupCounts: Map<MetadataColumnValue, number> = new Map();

  visibleParentGroupEntries.forEach(([parentGroup]) => {
    const subgroupsMap = subgroupData.get(parentGroup);

    if (subgroupsMap) {
      let addedSubgroupsCount = 0;
      // Always sort the subgroups so that we don't add a unique group when one of this parent's subgroups has already been added.
      for (const subgroupKey of [...subgroupsMap.keys()].sort(sorter)) {
        if (addedSubgroupsCount < MAX_INITIAL_SUBGROUPS) {
          addedSubgroupsCount++;
          defaultSubgroups.push(subgroupKey);
        }

        totalSubgroupCounts.set(
          subgroupKey,
          1 + (totalSubgroupCounts.get(subgroupKey) || 0),
        );
      }
    }
  });

  // Tracks the total subgroups we can show by decrementing from the max limit.
  let numExtraSubgroupsAllowed =
    MAX_INITIAL_BOX_PLOTS - defaultSubgroups.length;

  if (numExtraSubgroupsAllowed > 0) {
    // Sort remaining subgroups from least to most common
    const sortedCountEntries = [...totalSubgroupCounts.entries()]
      .filter(([subgroupKey]) => !defaultSubgroups.includes(subgroupKey))
      .sort(([, count]) => count);

    for (
      let i = 0;
      i < sortedCountEntries.length && numExtraSubgroupsAllowed > 0;
      i++
    ) {
      const [subgroup, count] = sortedCountEntries[i];

      // Price is Right rule: decrement know to prevent going over the threshold.
      numExtraSubgroupsAllowed = numExtraSubgroupsAllowed - count;

      if (numExtraSubgroupsAllowed > 0) {
        defaultSubgroups.push(subgroup);
      }
    }
  }

  return Array.from(new Set(defaultSubgroups));
}
