import cx from "classnames";
import { ReactNode } from "react";
import { Plus, Sliders } from "react-feather";
import { Button } from "src/Common/Button";
import { Switch } from "@spring/ui/Switch";
import { Label } from "@spring/ui/typography";
import ControlSection from "../../Control/ControlSection";
import { IMAGE_SIZES, SIDEBAR_WIDTH } from "../constants";

const ZOOM_STEP_FACTOR = 0.1;

export default function Toolbar({
  onAddGroup,
  onToggleControls,
  onToggleSegmentation,
  isSegmentationEnabled,
  showSegmentation,
  imageSize,
  onChangeImageSize,
  isSidebarOpen,
}: {
  onAddGroup: () => void;
  onToggleControls: () => void;
  onToggleSegmentation: () => void;
  isSegmentationEnabled: boolean;
  showSegmentation: boolean;
  imageSize: number;
  onChangeImageSize: (size: number) => void;
  isSidebarOpen: boolean;
}) {
  const Header = ({ children }: { children: ReactNode }) => (
    <Label className="tw-pb-1 tw-text-slate-500">{children}</Label>
  );
  const baseLeftPadding = 16;
  return (
    <div
      className={cx(
        "tw-flex tw-border-b tw-shadow-md tw-fixed tw-w-full tw-bg-white tw-z-10",
        "tw-h-compare-images-toolbar-height tw-top-global-nav-height",
        "tw-transition-all tw-ease-in-out tw-duration-300",
      )}
      style={{
        paddingLeft: isSidebarOpen
          ? baseLeftPadding + SIDEBAR_WIDTH
          : baseLeftPadding,
      }}
    >
      <ControlSection extraClasses={"tw-flex-1 tw-border-r"}>
        <Header>View Images</Header>
        <div>
          {/*
               TODO(davidsharff): this doesn't use spring blue. I think we should hoist the phenosorter 
               button into our common components and add color support if needed  
            */}
          <Button size="sm" variant="primary" onClick={onAddGroup} icon={Plus}>
            Add image group
          </Button>
        </div>
      </ControlSection>
      <ControlSection extraClasses={"tw-border-r tw-w-[150px]"}>
        <Header>Zoom Level</Header>
        <ZoomControl imageSize={imageSize} onChange={onChangeImageSize} />
      </ControlSection>
      <ControlSection extraClasses={"tw-border-r"}>
        <Header>Segmentation</Header>
        <label className="tw-flex tw-items-center">
          <Switch
            checked={showSegmentation}
            disabled={!isSegmentationEnabled}
            onChange={onToggleSegmentation}
            extraClasses="tw-pr-2"
          />
          <Label className="tw-ml-[-6px] tw-text-slate-500">
            {showSegmentation ? "ON" : "OFF"}
          </Label>
        </label>
      </ControlSection>
      <ControlSection extraClasses="tw-items-center tw-w-[100px]">
        <Header>Dyes</Header>
        <Sliders
          size={16}
          className="tw-cursor-pointer tw-text-slate-500 tw-mt-1"
          onClick={onToggleControls}
        />
      </ControlSection>
    </div>
  );
}

function ZoomControl({
  imageSize,
  onChange,
}: {
  imageSize: number;
  onChange: (size: number) => void;
}) {
  const zoomPct = Math.round(
    ((imageSize - IMAGE_SIZES.min) / IMAGE_SIZES.min) * 100,
  );

  const canZoomIn = imageSize < IMAGE_SIZES.max;

  const canZoomOut = imageSize > IMAGE_SIZES.min;

  const handleIncreaseZoom = () => {
    if (canZoomIn) {
      const newImageSize = imageSize + IMAGE_SIZES.min * ZOOM_STEP_FACTOR;
      onChange(newImageSize);
    }
  };

  const handleDecreaseZoom = () => {
    if (canZoomOut) {
      const newImageSize = imageSize - IMAGE_SIZES.min * ZOOM_STEP_FACTOR;
      onChange(newImageSize);
    }
  };

  const ZoomButton = ({
    children,
    disabled,
    onClick,
  }: {
    children: React.ReactNode;
    disabled: boolean;
    onClick: () => void;
  }) => (
    <div
      onClick={onClick}
      className={cx(
        "tw-text-[22px] tw-px-2 tw-text-slate-500 tw-cursor-pointer",
        disabled && "tw-cursor-default",
      )}
    >
      {children}
    </div>
  );

  return (
    <div className="tw-flex tw-items-center tw-border tw-rounded tw-select-none">
      <ZoomButton disabled={!canZoomOut} onClick={handleDecreaseZoom}>
        -
      </ZoomButton>
      <div className="tw-basis-1/2 tw-text-center tw-text-xs tw-text-slate-500">
        {zoomPct}%
      </div>
      <ZoomButton disabled={!canZoomIn} onClick={handleIncreaseZoom}>
        +
      </ZoomButton>
    </div>
  );
}
