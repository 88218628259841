import { useEffect } from "react";
import { DatasetId } from "src/types";
import { parseTaggedString } from "@spring/core/log-utils";
import { Fetchable, Success } from "@spring/core/result";
import { CanSkip } from "../hooks/api";
import { useFeatureSetColumns } from "../hooks/features";
import { useFeatureSelectorContext } from "./context";

export function useCachedColumnsFetch({
  dataset,
  plate,
  featureSet,
  skip,
}: CanSkip<{
  dataset: DatasetId;
  plate?: string;
  featureSet: string;
}>): Fetchable<string[]> {
  const { columnsCache, updateColumnsCache } = useFeatureSelectorContext();
  const cacheKey = !skip ? parseTaggedString(featureSet)!.baseName : undefined;
  const inCache = cacheKey !== undefined && cacheKey in columnsCache;
  const fetch = useFeatureSetColumns(
    inCache || skip
      ? { skip: true }
      : {
          dataset,
          plate,
          featureSet,
        },
  );

  useEffect(() => {
    if (!inCache && cacheKey !== undefined && fetch?.successful) {
      updateColumnsCache(cacheKey, fetch.value);
    }
  }, [fetch, cacheKey, inCache, updateColumnsCache]);

  if (inCache) {
    return Success.of(columnsCache[cacheKey]);
  } else {
    return fetch;
  }
}
