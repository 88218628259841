import cx from "classnames";
import { InputHTMLAttributes, ReactNode } from "react";

export const Block = ({ children }: { children: ReactNode }) => (
  <div className="tw-my-4">{children}</div>
);

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
};

// TODO(davidsharff): reach out to design group to formalize/hoist an Input component
export const Input = ({ className, ...props }: InputProps) => {
  return (
    <input
      className={cx("tw-border tw-outline-none tw-p-1", className)}
      {...props}
    />
  );
};

const BorderedContainer = ({ children }: { children: ReactNode }) => (
  <div
    className={cx(
      "tw-p-lg tw-flex tw-flex-col tw-items-center tw-justify-center",
      "tw-border tw-border-dashed tw-border-gray-300 tw-rounded-lg",
    )}
  >
    {children}
  </div>
);

export const SplashScreenContainer = ({
  children,
  classNames,
}: {
  children: ReactNode;
  classNames?: string;
}) => (
  <div className={cx("tw-py-2xl", classNames)}>
    <BorderedContainer>{children}</BorderedContainer>
  </div>
);
