/**
 * Typed version of Object.values.
 */
export function typedValues<T>(obj: { [key: string]: T }): T[] {
  return Object.keys(obj).map((k) => obj[k]);
}

/**
 * Typed version of Object.typedKeys.
 */
export function typedKeys<T extends string, U>(obj: { [K in T]: U }): Array<T> {
  return Object.keys(obj).map((k) => k as T);
}

/**
 * Typed version of Object.entries.
 */
export function typedEntries<T>(obj: { [key: string]: T }): Array<[string, T]> {
  return Object.keys(obj).map((k) => [k, obj[k]]);
}

/**
 * Type predicate filter that removes null values from a list.
 */
export function notNull<TValue>(value: TValue | null): value is TValue {
  return value !== null && value !== undefined;
}

/**
 * @returns {boolean} - {@code true} if the two Arrays have equal contents.
 */
export function arrayEquals<T>(a: T[] | null, b: T[] | null): boolean {
  if (a === b) {
    return true;
  }
  if (a == null || b == null) {
    return false;
  }
  if (a.length !== b.length) {
    return false;
  }

  return a.every((ai, i) => ai === b[i]);
}

/**
 * Title cases a string.
 * Note: only handles words delimited by spaces (not camel, snake, etc.)
 */
export function toTitleCase(str: string): string {
  return str
    .split(" ")
    .map((word) =>
      word ? word[0].toUpperCase() + word.slice(1)?.toLowerCase() : "",
    )
    .join(" ");
}

/**
 * Group a collection of records with type T
 */
export function groupBy<T>(
  collection: T[],
  iteratee: (o: T) => string | number,
): Record<string, T[]> {
  return _groupBy(collection, iteratee, false) as Record<string, T[]>;
}

type MapKeyTypes = string | number | null | boolean;
export function groupByWithMap<T>(
  collection: T[],
  iteratee: (o: T) => MapKeyTypes,
): Map<MapKeyTypes, T[]> {
  return _groupBy(collection, iteratee, true) as Map<MapKeyTypes, T[]>;
}

function _groupBy<T>(
  collection: T[],
  iteratee: (o: T) => MapKeyTypes,
  shouldMap: boolean,
): Record<string, T[]> | Map<MapKeyTypes, T[]> {
  const groups = shouldMap
    ? new Map<MapKeyTypes, T[]>()
    : ({} as Record<string, T[]>);
  for (const o of collection) {
    const groupKey = iteratee(o);
    if (groups instanceof Map) {
      const group = groups.get(groupKey);
      if (group) {
        group.push(o);
      } else {
        groups.set(groupKey, [o]);
      }
    } else {
      groups[groupKey as string] = [...(groups[groupKey as string] || []), o];
    }
  }
  return groups;
}
