import { Location } from "history";
import isEqual from "lodash.isequal";
import pathToRegexp from "path-to-regexp";
import * as queryString from "query-string";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useGuardedMemo } from "./util/hooks";

// Mapped object type that requires that all keys are present, but allows them to be set to `null`
// or `undefined`.
type Nullable<T> = {
  readonly [P in keyof T]: T[P] | null | undefined;
};

type ArbitraryQuery = { [k: string]: any };

function useNestedParams<
  Params extends { [K in keyof Params]?: string },
  Query,
>(path: string, location: Location): [Partial<Params>, Partial<Query>] {
  const match = useRouteMatch<Params>(path);
  if (match == null) {
    throw Error(`No match found at path: ${path}`);
  }

  return [match.params, queryString.parse(location.search)];
}

function useNestedPath<Params extends { [K in keyof Params]?: string }, Query>(
  path: string,
  location: Location,
  queryParameters: (keyof Query)[],
): (
  params: Partial<Params>,
  query?: Partial<Query>,
  queryTransform?: (query: ArbitraryQuery) => ArbitraryQuery,
) => string {
  return (
    params: Partial<Params>,
    query?: Partial<Query>,
    queryTransform?: (query: ArbitraryQuery) => ArbitraryQuery,
  ) => {
    // Construct a version of `Query` with all keys present. The input `query` is a partial; this
    // ensures that we set any unspecified parameters to `null` and thus unset them in the URL.
    const filledQuery = Object.fromEntries(
      queryParameters.map((parameter) => [
        parameter,
        // TODO(you): Fix this no-unnecessary-condition rule violation
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        query ? (query[parameter] == null ? null : query[parameter]) : null,
      ]),
    ) as Nullable<Query>;
    const retainedQuery = queryTransform
      ? queryTransform(queryString.parse(location.search))
      : queryString.parse(location.search);
    let url = pathToRegexp.compile(path)(params);
    const q: string = queryString.stringify(
      {
        ...retainedQuery,
        ...filledQuery,
      },
      {
        skipNull: true,
      },
    );
    if (q.length > 0) {
      url += `?${q}`;
    }

    return url;
  };
}

function useNestedParamsWithTransform<
  Params extends { [K in keyof Params]?: string },
  RawQuery,
  Query,
>(
  path: string,
  location: Location,
  transform: (query: Partial<RawQuery>) => Partial<Query>,
): [Partial<Params>, Partial<Query>] {
  const match = useRouteMatch<Params>(path);
  if (match == null) {
    throw Error(`No match found at path: ${path}`);
  }

  return [match.params, transform(queryString.parse(location.search))];
}

function useNestedPathWithTransform<Params, RawQuery, Query>(
  path: string,
  location: Location,
  transform: (query: Partial<Query>) => Nullable<RawQuery>,
): (
  params: Partial<Params>,
  query?: Partial<Query>,
  queryTransform?: (query: ArbitraryQuery) => ArbitraryQuery,
) => string {
  return (
    params: Partial<Params>,
    query?: Partial<Query>,
    queryTransform?: (query: ArbitraryQuery) => ArbitraryQuery,
  ) => {
    let url = pathToRegexp.compile(path)(params);
    const retainedQuery = queryTransform
      ? queryTransform(queryString.parse(location.search))
      : queryString.parse(location.search);
    const q: string = queryString.stringify(
      {
        ...retainedQuery,
        ...transform(query || {}),
      },
      {
        skipNull: true,
      },
    );
    if (q.length > 0) {
      url += `?${q}`;
    }

    return url;
  };
}

/**
 * Hook to use and sync state to a parameterized route.
 */
export function useNestedRoute<Params extends { [K in keyof Params]?: string }>(
  path: string,
): [
  Partial<Params>,
  (
    params: Partial<Params>,
    queryTransform?: (query: ArbitraryQuery) => ArbitraryQuery,
    replace?: boolean,
  ) => void,
] {
  const location = useLocation();
  const history = useHistory();
  const nestedPath = useNestedPath<Params, Record<string, never>>(
    path,
    location,
    [],
  );
  const [params] = useNestedParams<Params, Record<string, never>>(
    path,
    location,
  );
  return [
    params,
    (
      params,
      queryTransform?: (query: ArbitraryQuery) => ArbitraryQuery,
      replace?: boolean,
    ) => {
      if (replace) {
        history.replace(nestedPath(params, undefined, queryTransform));
      } else {
        history.push(nestedPath(params, undefined, queryTransform));
      }
    },
  ];
}

/**
 * Hook to use and sync state to a parameterized route, with additional query parameters.
 *
 * Note that the list of query parameters is necessary to ensure that any unspecified values (i.e.,
 * missing keys) in the object passed to the update function are properly 'removed' from the URL.
 * The list of query parameters should be an exhaustive enumeration of the keys in the `Query`
 * object type.
 */
export function useNestedRouteWithQuery<
  Params extends { [K in keyof Params]?: string },
  Query,
>(
  path: string,
  queryParameters: (keyof Query)[],
): [
  Partial<Params>,
  Partial<Query>,
  (
    params: Partial<Params>,
    query?: Partial<Query>,
    queryTransform?: (query: ArbitraryQuery) => ArbitraryQuery,
  ) => void,
] {
  const location = useLocation();
  const history = useHistory();
  const nestedPath = useNestedPath<Params, Query>(
    path,
    location,
    queryParameters,
  );
  const [params, query] = useNestedParams<Params, Query>(path, location);
  return [
    params,
    query,
    (params, query, queryTransform) =>
      history.push(nestedPath(params, query, queryTransform)),
  ];
}

/**
 * Hook to use and sync state to a parameterized route, with additional query parameters and
 * transformations between URL-friendly and deserialized representations..
 */
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function useNestedRouteWithTransform<
  Params extends { [K in keyof Params]?: string },
  RawQuery,
  Query,
>(
  path: string,
  forwardTransform: (query: Partial<Query>) => Nullable<RawQuery>,
  reverseTransform: (query: Partial<RawQuery>) => Partial<Query>,
): [
  Partial<Params>,
  Partial<Query>,
  (
    params: Partial<Params>,
    query?: Partial<Query>,
    queryTransform?: (query: ArbitraryQuery) => ArbitraryQuery,
  ) => void,
] {
  const location = useLocation();
  const history = useHistory();
  const nestedPath = useNestedPathWithTransform<Params, RawQuery, Query>(
    path,
    location,
    forwardTransform,
  );
  const [params, query] = useNestedParamsWithTransform<Params, RawQuery, Query>(
    path,
    location,
    reverseTransform,
  );
  return [
    params,
    query,
    (params, query, queryTransform) =>
      history.push(nestedPath(params, query, queryTransform)),
  ];
}

/**
 * Hook: thin wrapper around the query-string package that returns parsed query key/val
 * pairs (akin to react router's route params), and a function to push a new query
 * string into the url via a setState style interface.
 */
export function useQueryParams<T>(): [T, (queryParams: T) => void] {
  const { search } = useLocation();
  const history = useHistory();

  const parsed: T = useMemo(() => queryString.parse(search), [search]);

  const setQueryParams = useCallback(
    (params: T) => {
      const newQueryString = queryString.stringify(params);

      history.push({
        search: `?${newQueryString}`,
      });
    },
    [history],
  );

  return [parsed, setQueryParams];
}

type ComplexParamDecoder<T> = {
  defaultValue: T;
  fromString?: (value: string) => T;
  fromArray?: (value: string[]) => T;
  encode?: (value: T) => string | string[];
};

export type ParamDecoders<T> = {
  [K in keyof T]:
    | // Simple decoder that just works on strings or undefined
    ((value: string | undefined) => T[K])
    // More complicated decoder that can handle strings or arrays of strings
    | ComplexParamDecoder<T[K]>;
};

/**
 * Some helper functions for building common ComplexParamDecoder for use with
 * useTypedQueryParams
 */
export const QS = {
  string<T = string, U = undefined>(
    defaultValue?: U,
    test?: (value: string) => value is string & T,
  ): ComplexParamDecoder<T | U> {
    return {
      defaultValue: defaultValue as U,
      fromString: (value): T | U => {
        if (!test) {
          return value as T & string;
        } else if (test(value)) {
          return value;
        } else {
          return defaultValue as U;
        }
      },
    };
  },
  strings<T = string>(
    test: (value: string) => value is string & T = (
      value: string,
    ): value is string & T => true,
  ): ComplexParamDecoder<T[]> {
    return {
      defaultValue: [],
      fromArray: (values): T[] => values.filter(test),
      fromString: (value): T[] => [value].filter(test),
    };
  },
  number<U = undefined>(defaultValue?: U): ComplexParamDecoder<number | U> {
    return {
      defaultValue: defaultValue as U,
      fromString: (value): number | U => {
        const parsed = parseFloat(value);
        if (Number.isNaN(parsed)) {
          return defaultValue as U;
        } else {
          return parsed;
        }
      },
    };
  },
  int<U = undefined>(defaultValue?: U): ComplexParamDecoder<number | U> {
    return {
      defaultValue: defaultValue as U,
      fromString: (value): number | U => {
        const parsed = parseInt(value);
        return Number.isNaN(parsed) ? (defaultValue as U) : parsed;
      },
    };
  },
  enum<T>(values: Record<string, T>, defaultValue: T): ComplexParamDecoder<T> {
    const allowedValues = new Set(Object.values(values));

    return {
      defaultValue,
      fromString: (value): T => {
        if (allowedValues.has(value as string & T)) {
          return value as string & T;
        } else {
          return defaultValue;
        }
      },
    };
  },
  json<T, U = T>({
    defaultValue,
    clean,
    validate,
  }: {
    defaultValue: U;
    clean?: (input: T | U) => T | U;
    validate?: (input: unknown) => input is T;
  }): ComplexParamDecoder<T | U> {
    // TODO(danlec) Add ability to validate JSON
    return {
      defaultValue,
      fromString(json): T | U {
        if (json != "") {
          try {
            const value = JSON.parse(json);
            if (!validate || validate(value)) {
              return value;
            }
          } catch (ex) {
            // ignore
          }
        }
        return defaultValue;
      },
      encode(value: T | U): string {
        const cleaned = clean ? clean(value) : value;
        return value === undefined ? "" : JSON.stringify(cleaned);
      },
    };
  },
  jsons<T>({
    clean,
    validate,
  }: {
    clean?: (input: T) => T;
    validate?: (input: unknown) => input is T;
  }): ComplexParamDecoder<T[]> {
    // TODO(danlec) Add ability to validate JSON
    return {
      defaultValue: [],
      fromString(json): T[] {
        try {
          const value = JSON.parse(json);
          if (!validate || validate(value)) {
            return [value];
          }
        } catch (ex) {
          // ignore
        }
        return [];
      },
      fromArray(jsons): T[] {
        return jsons
          .map((json) => {
            try {
              return JSON.parse(json);
            } catch (ex) {
              return null;
            }
          })
          .filter((value) => !validate || validate(value));
      },
      encode(values): string[] {
        return values.map((value) => {
          const cleaned = clean ? clean(value) : value;
          return JSON.stringify(cleaned);
        });
      },
    };
  },
} satisfies Record<string, (...args: any[]) => ComplexParamDecoder<any>>;

/**
 * Hook: typed wrapper around the query-string package that passes the query params through the provided "paramDecoder" functions
 * to create a key/val store of query strings and values (akin to react router's route params). The decoder is responsible for casting
 * the param from the url string to the desired type. Decoders can be a simple function that takes a string and returns the desired type,
 * or a more complex object that can handle strings or arrays of strings.
 *
 * Example:
 *   const [queryParams, setQueryParams] = useTypedQueryParams({ isActive: (value: string | undefined) => value === "true" });
 *   // Note: TS can correctly infer that isActive is a boolean
 *   const { isActive } = queryParams;
 *
 * Note: the setter will safely ignore any query strings in the url that are not in the decoder object.
 *
 * Note: the results will be memoized using useGuardedMemo, so that clients can
 * use them in React dependencies. If a custom comparator to define equality needs
 * to be specified, it can be passed as the second argument (but it will default
 * to basic equality using lodash).
 */
export function useTypedQueryParams<T extends Record<string, unknown>>(
  paramDecoders: ParamDecoders<T>,
  comparator: (a: T, b: T) => boolean = isEqual,
): [T, (update: Partial<T> | ((current: T) => Partial<T>)) => void] {
  const { search } = useLocation();
  const history = useHistory();

  const parsedQuery = useMemo(
    (): Record<string, string | string[]> => queryString.parse(search),
    [search],
  );

  const parsed: T = useGuardedMemo(
    () => {
      return Object.keys(paramDecoders).reduce((parsedParams, paramKey) => {
        const decoder = paramDecoders[paramKey as keyof T];
        const value = parsedQuery[paramKey];

        const decodedValue =
          typeof decoder === "function"
            ? decoder(
                // Simple decoders can only handle a single string or undefined
                Array.isArray(value) ? value[0] : value,
              )
            : complexDecoder(decoder, value);

        return {
          ...parsedParams,
          [paramKey]: decodedValue,
        };
      }, {} as T);
    },
    [search, paramDecoders],
    comparator,
  );

  const encode = useCallback(
    (params: Partial<T>): Record<string, string | string[]> => {
      return Object.fromEntries(
        Object.entries(params)
          .filter(([paramKey]) => paramDecoders.hasOwnProperty(paramKey))
          .map(([paramKey, value]) => {
            const decoder = paramDecoders[paramKey as keyof T];
            if (typeof decoder !== "function" && decoder.encode) {
              return [paramKey, decoder.encode(value as T[keyof T])];
            } else {
              return [paramKey, value];
            }
          }),
      );
    },
    [paramDecoders],
  );

  const setQueryParamsConfig = useMemo(
    () => ({
      encode,
      search,
      parsed,
      paramDecoders,
      push: history.push,
    }),
    [encode, history.push, paramDecoders, parsed, search],
  );

  const setQueryParamsConfigRef = useRef(setQueryParamsConfig);
  useEffect(() => {
    setQueryParamsConfigRef.current = setQueryParamsConfig;
  }, [setQueryParamsConfig]);

  const setQueryParams = useCallback<
    (update: Partial<T> | ((current: T) => Partial<T>)) => void
  >((update) => {
    const { encode, search, parsed, paramDecoders, push } =
      setQueryParamsConfigRef.current;

    const baseQueryParams = Object.entries(queryString.parse(search)).reduce(
      (acc, [key, value]) =>
        // TODO(you): Fix this no-unnecessary-condition rule violation
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        !paramDecoders[key as keyof T]
          ? {
              ...acc,
              [key]: value,
            }
          : acc,
      {},
    );

    const updatedParams =
      typeof update === "function" ? update(parsed) : update;

    const newQueryString = `?${queryString.stringify({
      ...baseQueryParams,
      ...parsed,
      ...encode(updatedParams),
    })}`;

    if (newQueryString !== search) {
      push({
        search: newQueryString,
      });
    }
  }, []);

  // Make sure the current query string includes any defaults or replacements of
  // invalid values that we used during parsing
  useEffect(() => {
    const encoded = encode(parsed);
    const updated = Object.fromEntries(
      Object.entries(encoded)
        .filter(([key, serialized]) => {
          return (
            queryString.stringify({ key: serialized }) !==
            queryString.stringify({ key: parsedQuery[key] })
          );
        })
        .map(([key]) => [key, parsed[key]]),
    ) as Partial<T>;

    if (Object.keys(updated).length > 0) {
      setQueryParams(updated);
    }
  }, [encode, parsed, parsedQuery, setQueryParams]);

  return [parsed, setQueryParams];
}

/**
 * Handles complex type decoding for query params using the provided ComplexParamDecoder.
 *
 * @param decoderOptions - An object containing:
 *   - defaultValue: The value to return when the input value is undefined.
 *   - fromString: An optional function to handle string values.
 *   - fromArray: An optional function to handle array of string values.
 * @param value - The query param value to decode.
 * @return Decoded value of type T.
 */
function complexDecoder<T>(
  { defaultValue, fromString, fromArray }: ComplexParamDecoder<T>,
  value: string | string[] | undefined,
): T {
  if (value === undefined) {
    return defaultValue;
  }

  if (Array.isArray(value)) {
    const array = value;

    if (fromArray) {
      return fromArray(array);
    } else if (fromString && array.length > 0) {
      console.error(
        `No fromArray decoder found for values ${value}. Returning: ${array[0]}`,
      );
      return fromString(array[0]);
    } else {
      return defaultValue;
    }
  } else {
    if (fromString) {
      return fromString(value);
    } else if (fromArray) {
      return fromArray([value]);
    } else {
      return defaultValue;
    }
  }
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type UnstructuredQueryParams = { [key: string]: string | string[] };

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function hasString(o: UnstructuredQueryParams, key: string): boolean {
  return typeof o[key] === "string";
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function hasArray(o: UnstructuredQueryParams, key: string): boolean {
  return Array.isArray(o[key]);
}
