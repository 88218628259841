import Loader from "./Loader";

/**
 * A version of the loader to be used when we're loading the main content of whichever
 * page we're on.
 *
 * It always uses the same size loader, and puts it in the same position in the window
 * which keeps the loader from jumping around as we're loading the hierarchy of things
 * needed to display the content.
 *
 * We're currently using position: absolute, we we expect that we're not inside
 * another positioned element.  (If this ends up being an issue for some reason we can
 * move to position: fixed)
 */
export function FullScreenLoader() {
  return (
    <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
      <Loader size="large" />
    </div>
  );
}
