import cx from "classnames";
import { CSSProperties, memo, useCallback, useMemo, useState } from "react";
import { ArrowLeft, Edit, ExternalLink } from "react-feather";
import { generatePath } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeGrid as Grid, GridChildComponentProps } from "react-window";
import { useActiveWorkspace } from "src/Workspace/hooks";
import { ImageSet } from "src/imaging/types";
import MultiChannelView from "src/immunofluorescence/MultiChannelView";
import { CellSampleMetadata, DatasetId } from "src/types";
import { getCellMaskPropsForCrop } from "src/util/get-mask-props-for-crop";
import invariant from "tiny-invariant";
import { Button } from "@spring/ui/Button";
import { Caption, Subtitle, Text } from "@spring/ui/typography";
import Spinner from "../../Common/Spinner";
import { saveLabeledSet } from "../../PhenotypicLearner/util";
import { useAccessToken } from "../../hooks/auth0";
import { toNumericField } from "../../imaging/util";
import { usePhenoFinderContext } from "../Context";
import { DEFAULT_CUT_TREE_NAME } from "../constants";
import { useStains } from "../hooks";
import { findDataNode } from "../treeUtils";
import {
  ClusterFeatureData,
  ClusterMetadata,
  ClusterVisualizationData,
} from "../types";
import { SingleFeatureChart } from "./SingleFeatureChart";
import { SingleMetadataChart } from "./SingleMetadataChart";
import { useEditClusterDisplayNames } from "./hooks";
import {
  convertLeafNodeToLabeledSet,
  getCellKey,
  getDefaultClusterDisplayName,
} from "./utils";

const GRID_PADDING_PX = 8;

type CellImageData = {
  dataset: DatasetId;
  numImagesPerRow: number;
  imageSet: ImageSet | null;
  imageSize: number;
  cropSize: number;
  cells: CellSampleMetadata[];
};

export function SelectedClusterPanel({
  className,
  style,
  dataset,
  onClosePanel,
  imageSet,
  imageSize,
  cropSize,
  dataForSelectedColumn,
  shouldHighlightMeaningfulMetadata,
}: {
  className?: string;
  style?: CSSProperties;
  dataset: DatasetId;
  onClosePanel: () => void;
  imageSet: ImageSet | null;
  imageSize: number;
  cropSize: number;
  dataForSelectedColumn: ClusterVisualizationData[];
  shouldHighlightMeaningfulMetadata: boolean;
}) {
  const [state] = usePhenoFinderContext();
  const {
    currentTree,
    clusters,
    selectedClusterName,
    totalNumCells,
    selectedVisualizationColumn,
    metadataColorScale,
    defaultCropSize,
  } = state;
  const [isCreatingLabeledSet, setIsCreatingLabeledSet] = useState(false);
  // This component should not be displayed without a current tree and selected cluster
  invariant(currentTree);
  invariant(selectedClusterName);
  invariant(clusters);
  const accessToken = useAccessToken();
  const workspace = useActiveWorkspace();

  const cluster = useMemo(() => {
    const node = findDataNode(currentTree, selectedClusterName);
    invariant(
      node?.type === "leaf",
      "SelectedClusterPanel shown without a leaf node selected",
    );
    return node;
  }, [currentTree, selectedClusterName]);

  const clusterIndex = useMemo(() => {
    return clusters.findIndex(
      (cluster) => cluster.name === selectedClusterName,
    );
  }, [clusters, selectedClusterName]);
  const handleCreateLabeledSet = async () => {
    setIsCreatingLabeledSet(true);
    const labeledSetCreationPayload = stains?.successful
      ? convertLeafNodeToLabeledSet(
          cluster,
          defaultClusterDisplayName,
          stains.value,
          defaultCropSize,
        )
      : null;
    const result = await saveLabeledSet({
      accessToken,
      workspace,
      dataset,
      labeledSet: labeledSetCreationPayload!,
    });
    const psPath = `/workspace/${workspace.id}/e/${dataset}/pl`;
    window.open(generatePath(`${psPath}/${result.id}`), "_blank");
    setIsCreatingLabeledSet(false);
  };

  const [isEditingDisplayName, setIsEditingDisplayName] = useState(false);
  const onEditDisplayName = useCallback(() => {
    setIsEditingDisplayName(true);
  }, []);

  const {
    displayNames,
    displayNamesErrors,
    onChangeDisplayName,
    onBlurDisplayNameInput,
  } = useEditClusterDisplayNames();

  const displayName = displayNames[clusterIndex];
  const defaultClusterDisplayName = getDefaultClusterDisplayName(clusterIndex);
  const displayNameError = displayNamesErrors[clusterIndex];
  const stains = useStains({ dataset, cutTree: DEFAULT_CUT_TREE_NAME });

  const handleChangeDisplayName = useCallback(
    (event) => {
      const displayName = event.target.value;
      onChangeDisplayName(clusterIndex, displayName);
    },
    [clusterIndex, onChangeDisplayName],
  );

  const handleBlurDisplayNameInput = useCallback(() => {
    onBlurDisplayNameInput(clusterIndex);
    setIsEditingDisplayName(false);
  }, [clusterIndex, onBlurDisplayNameInput]);

  const clusterData = useMemo(() => {
    return dataForSelectedColumn.filter(
      (clusterData) => clusterData.clusterName === cluster.name,
    );
  }, [cluster.name, dataForSelectedColumn]);

  const populationPercent = cluster.cells.length / totalNumCells!;

  const autoSizedGrid = useCallback(
    ({ height, width }) => {
      const numImagesPerRow = Math.floor(width / (imageSize + GRID_PADDING_PX));
      const numRows = Math.ceil(cluster.cells.length / numImagesPerRow);

      const itemData = {
        dataset,
        numImagesPerRow,
        imageSet,
        imageSize,
        cropSize,
        cells: cluster.cells,
      };

      return (
        <Grid
          itemData={itemData}
          height={height}
          width={width}
          columnWidth={imageSize + GRID_PADDING_PX}
          rowHeight={imageSize + GRID_PADDING_PX}
          columnCount={numImagesPerRow}
          rowCount={numRows}
        >
          {CellImage}
        </Grid>
      );
    },
    [imageSize, cluster.cells, dataset, imageSet, cropSize],
  );

  return (
    <div className={cx(className, "tw-py-md")} style={style}>
      <div
        className={cx(
          "tw-flex tw-flex-col tw-w-full tw-h-full",
          "tw-rounded",
          "tw-bg-white tw-border tw-border-slate-300",
        )}
      >
        <div className={cx("tw-p-md", "tw-border-b tw-border-slate-300")}>
          <div className={cx("tw-flex tw-items-center tw-justify-between")}>
            <div className="tw-flex tw-w-full tw-items-center">
              <button className="tw-mr-sm" onClick={onClosePanel}>
                <ArrowLeft size={24} />
              </button>

              <div
                className={cx(
                  "tw-flex-1",
                  "tw-flex tw-items-center tw-justify-between",
                )}
              >
                <div
                  className={cx(
                    "tw-flex-1 tw-w-full tw-mr-md",
                    "tw-flex tw-flex-col tw-items-baseline",
                  )}
                >
                  {isEditingDisplayName ? (
                    <input
                      type="text"
                      title={cluster.displayName}
                      value={displayName}
                      placeholder={defaultClusterDisplayName}
                      onChange={handleChangeDisplayName}
                      onBlur={handleBlurDisplayNameInput}
                      className={cx(
                        "tw-w-full tw-px-sm tw-py-xs",
                        "tw-font-medium",
                        "tw-rounded tw-border tw-border-transparent",
                        // TODO(you): Fix this no-unnecessary-condition rule violation
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        displayNameError !== null && "tw-text-red-error",
                        "hover:tw-border-gray-300",
                        "focus:tw-outline-primary-500",
                      )}
                      autoFocus={true}
                    />
                  ) : (
                    <button
                      className={cx(
                        "tw-flex tw-items-center tw-space-x-sm",
                        "tw-py-xs",
                      )}
                      onClick={onEditDisplayName}
                    >
                      <Subtitle>
                        {cluster.displayName ?? defaultClusterDisplayName}
                      </Subtitle>
                      <Edit className="tw-text-primary-500" size={16} />
                    </button>
                  )}
                </div>
                <Subtitle className="tw-text-primary-500">
                  {(populationPercent * 100).toFixed(1)}%
                </Subtitle>
                <Button
                  className={
                    "tw-ml-md tw-px-sm tw-w-[140px] tw-justify-center tw-items-center tw-text-sm tw-text-white"
                  }
                  variant="primary"
                  disabled={isCreatingLabeledSet || stains === undefined}
                  onClick={handleCreateLabeledSet}
                >
                  {isCreatingLabeledSet ? (
                    <Spinner />
                  ) : (
                    <div
                      className={
                        "tw-flex tw-flex-1 tw-justify-center tw-items-center"
                      }
                    >
                      PhenoSorter
                      <ExternalLink className={"tw-ml-sm"} size={14} />
                    </div>
                  )}
                </Button>
              </div>
            </div>
          </div>
          {/* TODO(you): Fix this no-unnecessary-condition rule violation */}
          {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
          {displayNameError !== null && (
            <Caption className="tw-ml-10 tw-text-red-error">
              Error: {displayNameError}
            </Caption>
          )}
        </div>
        {selectedVisualizationColumn.type === "metadata" ? (
          <div className="tw-m-md">
            <SingleMetadataChart
              data={clusterData as ClusterMetadata[]}
              colorScale={metadataColorScale}
              shouldHighlightMeaningfulMetadata={
                shouldHighlightMeaningfulMetadata
              }
              numTotalRows={dataForSelectedColumn!.length}
              displayName={displayName}
            />
          </div>
        ) : (
          <div className="tw-m-md">
            <SingleFeatureChart
              data={dataForSelectedColumn as ClusterFeatureData[]}
              clusterName={cluster.name}
              shouldHighlightMeaningfulMetadata={
                shouldHighlightMeaningfulMetadata
              }
              numTotalRows={clusters.length}
              selectedFeature={selectedVisualizationColumn.name}
              displayName={displayName}
            />
          </div>
        )}

        <div className="tw-mx-md">
          <Text className="tw-text-slate-500 tw-uppercase">
            {cluster.cells.length} images
          </Text>
        </div>
        <div className={cx("tw-flex-1 tw-overflow-auto", "tw-p-md tw-gap-sm")}>
          <AutoSizer>{autoSizedGrid}</AutoSizer>
        </div>
      </div>
    </div>
  );
}

/* Memoize the image component so that it doesn't completely rerender when stain settings change */
const CellImage = memo(
  ({
    data,
    columnIndex,
    rowIndex,
    style,
  }: GridChildComponentProps<CellImageData>) => {
    const { dataset, imageSet, numImagesPerRow, imageSize, cropSize, cells } =
      data;
    const i = rowIndex * numImagesPerRow + columnIndex;

    // It's possible that the last row will not be completely full; skip anything that
    // should be empty
    if (i >= cells.length) {
      return null;
    }

    const cellMetadata = cells[i];
    return (
      <div style={style}>
        <MultiChannelView
          index={{
            dataset,
            plate: cellMetadata.plate,
            well: cellMetadata.well,
            field: toNumericField(cellMetadata.field),
            t: 0,
            z: 0,
          }}
          imageSet={imageSet}
          key={getCellKey(cellMetadata)}
          size={imageSize}
          crop={{
            size: cropSize,
            location: {
              x: cellMetadata.row,
              y: cellMetadata.column,
            },
          }}
          {...getCellMaskPropsForCrop(cropSize)}
        />
      </div>
    );
  },
);
CellImage.displayName = "CellImage";
