import * as Dialog from "@radix-ui/react-dialog";
import useEventListener from "@use-it/event-listener";
import cx from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { Search } from "react-feather";
import { makeWorkspaceApi } from "src/hooks/api";
import { WorkspaceId } from "src/types";
import Loader from "../Common/Loader";
import { PulseGuiderRoot } from "../Insights/PulseGuider";
import { DB, makeSearchIndexDB } from "../util/sql";
import SearchInputBox from "./SearchInputBox";
import SearchResultsListing from "./SearchResultsListing";
import { SearchResults } from "./types";

type RenderMode = "inline" | "icon-with-floating-bar";

const useSearchIndex = makeWorkspaceApi("search_index")<DB>(undefined, () => ({
  requestInit: { redirect: "follow" },
  fetchKind: "blob",
  transform: makeSearchIndexDB,
}));

/**
 * A controller component which encases a search input box and search results.
 *
 * This component will initially just show a visible search box, but owns the chrome
 * around it that holds the input box and the results, and also coordinates state
 * between the children.
 */
export function SearchControlArea({
  workspaceId,
  onSearchAreaFocused,
  renderMode,
}: {
  workspaceId: WorkspaceId;
  onSearchAreaFocused?: (isShown: boolean) => void;
  renderMode: RenderMode;
}) {
  const rootEl = useRef(null);
  const [shouldFetchIndex, setShouldFetchIndex] = useState<boolean>(false);
  const onSearchIndexRequested = useCallback(
    () => setShouldFetchIndex(true),
    [],
  );
  const db = useSearchIndex(
    shouldFetchIndex ? { workspace: workspaceId } : { skip: true },
  );

  const [searchResults, setSearchResults] = useState<
    SearchResults | "loading" | null
  >(null);
  const [chromeVisible, setChromeVisible] = useState<boolean>(false);

  /**
   * The value of the "top level result" that's opened.
   *
   * This is tracked because the results view has two states: a "top level view"
   * and a "details view", and when the details view is opened, the search input
   * box gets hidden.
   */
  const [resultFocusId, setResultFocusId] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const updateResults = useCallback(
    (results: SearchResults | "loading" | null) => {
      setSearchResults(results);
      if (results) {
        setChromeVisible(true);
        onSearchAreaFocused && onSearchAreaFocused(true);
      }
    },
    [setSearchResults, setChromeVisible, onSearchAreaFocused],
  );

  const [resultDetailOpened, setResultDetailOpened] = useState<string | null>(
    null,
  );
  const setDetailOpened = useCallback(
    (id: string | null) => {
      setResultDetailOpened(id);
      if (id === null && inputRef.current) {
        // HACK(benkomalo): imperatively focus the search input box since if we're
        // going back from the details view to the top level view, we want to restore
        // the state of being able to edit the search query again.
        inputRef.current.focus();
      }
    },
    [setResultDetailOpened],
  );

  // Do a delayed auto-fetch of the search index, even without any interaction.
  useEffect(() => {
    if (shouldFetchIndex) {
      // Already triggered.
      return;
    }

    const timerId = setTimeout(() => {
      setShouldFetchIndex(true);
    }, 3000);
    return () => clearTimeout(timerId);
  }, [setShouldFetchIndex, shouldFetchIndex]);

  // Capture clicks outside the search area to see if we should "dismiss" the
  // results popdown. Note: we can't just listen to blur events on the input, because
  // we could also be navigating inside the search results itself (with down/right
  // arrows and so on), and that should work even if the input box is no longer
  // focused.
  const clickHandler = useCallback(
    (e) => {
      for (let el = e.target; el && el != document.body; el = el.parentNode) {
        if (el === rootEl.current) {
          return;
        }
      }
      setChromeVisible(false);
      setResultDetailOpened(null);
      onSearchAreaFocused && onSearchAreaFocused(false);
    },
    [setChromeVisible, onSearchAreaFocused, rootEl],
  );
  useEventListener("click", clickHandler, document.body, { capture: true });

  const contents = (
    <>
      <div
        className={cx("tw-w-full tw-p-4", resultDetailOpened && "tw-hidden")}
      >
        <SearchInputBox
          ref={inputRef}
          onResultsChange={updateResults}
          db={db?.successful ? db.value : null}
          onSearchIndexRequested={onSearchIndexRequested}
          onFocus={() => {
            setChromeVisible(true);
            onSearchAreaFocused && onSearchAreaFocused(true);
          }}
          resultFocusId={resultFocusId}
          workspaceId={workspaceId}
          onCancelSearch={() => {
            setChromeVisible(false);
            onSearchAreaFocused && onSearchAreaFocused(false);
          }}
        />
      </div>
      {chromeVisible && searchResults === "loading" && !db && (
        <div
          className={
            "tw-min-w-full tw-bg-white tw-h-[240px] tw-flex tw-items-center tw-justify-center"
          }
        >
          <Loader />
        </div>
      )}
      {chromeVisible && searchResults && db && !db.successful && (
        <div
          className={
            "tw-min-w-full tw-bg-white tw-h-[240px] tw-flex tw-items-center tw-justify-center"
          }
        >
          Oops. Something went wrong with your search. Please refresh and try
          again.
        </div>
      )}
      {searchResults &&
        searchResults != "loading" &&
        db?.successful &&
        chromeVisible && (
          <div className={"tw-min-w-full"}>
            <SearchResultsListing
              key={searchResults.query}
              workspaceId={workspaceId}
              results={searchResults}
              onResultFocused={setResultFocusId}
              onResultDetailOpened={setDetailOpened}
              onResultItemSelected={() => setChromeVisible(false)}
              db={db.unwrap()}
            />
          </div>
        )}
    </>
  );

  if (renderMode === "inline") {
    return (
      <div className={"tw-flex tw-flex-col tw-items-center tw-mt-2 tw--mb-4"}>
        <PulseGuiderRoot
          guiderKey={"homepage-search"}
          position={{
            corner: "top-left",
            offset: { x: 16, y: 16 },
          }}
          tooltipSide={"top"}
        >
          <div
            className={cx(
              // Z-index necessary since the search results "bleed outside" of its
              // container on top of the underlying homepage.
              "tw-relative tw-bg-white tw-z-10",
              "tw-w-[640px] sm:tw-w-full",
              chromeVisible && "tw-border tw-rounded-lg tw-shadow-lg",
              !chromeVisible && "tw-border tw-border-transparent",
            )}
            ref={rootEl}
          >
            {contents}
          </div>
        </PulseGuiderRoot>
      </div>
    );
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (renderMode == "icon-with-floating-bar") {
    return (
      <Dialog.Root open={chromeVisible} onOpenChange={setChromeVisible}>
        <Dialog.Trigger asChild>
          <button
            className={"tw-p-4 hover:tw-text-purple hover:tw-bg-purple-200"}
            onClick={() => setChromeVisible(true)}
            aria-label={"Search"}
          >
            <div className={"tw-flex tw-items-center tw-whitespace-nowrap"}>
              <Search size={16} /> <span className={"tw-ml-2"}>Search</span>
            </div>
          </button>
        </Dialog.Trigger>

        <Dialog.Portal>
          <Dialog.Overlay
            className={"tw-bg-slate-500/[0.5] tw-z-dialog tw-fixed tw-inset-0"}
          />
          <Dialog.Content
            className={cx(
              "tw-fixed tw-z-dialog tw-top-[50%] tw--mt-[220px] tw-left-[50%] " +
                "tw--ml-[320px] tw-w-[640px] sm:tw-ml-0 sm:tw-w-full",
              "tw-bg-white tw-shadow-lg tw-rounded-lg",
            )}
            ref={rootEl}
          >
            {contents}
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  } else {
    throw new Error(`Unexpected render mode ${renderMode}`);
  }
}
