/**
 * Non-rendering component to catch keyboard commands.
 */
import { useEffect } from "react";

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type Code =
  | "ArrowDown"
  | "ArrowLeft"
  | "ArrowRight"
  | "ArrowUp"
  | "Backspace"
  | "Digit0"
  | "Digit1"
  | "Digit2"
  | "Digit3"
  | "Digit4"
  | "Digit5"
  | "Digit6"
  | "Digit7"
  | "Digit8"
  | "Digit9"
  | "Enter"
  | "Escape"
  | "KeyZ"
  | "Slash"
  | "Space"
  | "Tab";

function handleKeyPress(
  spec: {
    code: Code;
    target: HTMLElement;
    intercept: (e: KeyboardEvent) => boolean;
    withShift: boolean;
    withMeta: boolean;
    requireExact: boolean;
  },
  onPress: () => void,
  e: KeyboardEvent,
) {
  if (
    (spec.requireExact ? e.target === spec.target : true) &&
    spec.intercept(e) &&
    e.code === spec.code &&
    e.shiftKey === spec.withShift &&
    (spec.withMeta ? e.metaKey || e.ctrlKey : !e.metaKey && !e.ctrlKey)
  ) {
    onPress();
    e.preventDefault();
  }
}

export default function KeypressListener({
  code,
  target,
  intercept,
  withShift,
  withMeta,
  requireExact,
  onPress,
}: {
  code: Code;
  // Target to which event listeners should be attached. Defaults to document.body.
  target: HTMLElement;
  // Optional filter callback which should return `true` if the event should be handled.
  intercept: (e: KeyboardEvent) => boolean;
  // Whether the keypress requires a Shift-key modifier.
  withShift: boolean;
  // Whether the keypress requires a Meta-key modifier. (We accept either the
  // Command or Control keys.)
  withMeta: boolean;
  // Whether to require that the event target is `target`. (If `false`, the
  // callback will be triggered when events are sent to children of `target`.)
  requireExact: boolean;
  onPress: () => void;
}) {
  useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) =>
      handleKeyPress(
        { code, target, intercept, withShift, withMeta, requireExact },
        onPress,
        e,
      );
    target.addEventListener("keydown", onKeyPress, false);
    return () => {
      target.removeEventListener("keydown", onKeyPress, false);
    };
  }, [target, code, intercept, withShift, withMeta, requireExact, onPress]);

  return null;
}

KeypressListener.defaultProps = {
  target: document.body,
  intercept: () => true,
  withShift: false,
  withMeta: false,
  requireExact: false,
};
