/**
 * Component to render all fields in a well in a simple grid.
 */
import Loader, { Center } from "../Common/Loader";
import ErrorMessage from "../Error/ErrorMessage";
import { useFields } from "../hooks/immunofluorescence";
import { DatasetPlateWell, Field, ImageSet } from "../imaging/types";
import { toNumericField } from "../imaging/util";
import MultiChannelView from "../immunofluorescence/MultiChannelView";
import { FieldSampleMetadata } from "../types";
import { getScanPattern } from "./scan-pattern";

/**
 * Convert from zero-indexed integral field values to zero-indexed formatted
 * strings.
 *
 * Ex) formatField(20) => 'f20'
 */
export function formatField(field: number): Field {
  return `f${("" + field).padStart(2, "0")}`;
}

/**
 * Find an entry within an array of metadata.
 */
function findEntry<T extends FieldSampleMetadata>(
  metadata: T[],
  plate: string,
  well: string,
  field: Field,
): T | null {
  for (const sample of metadata) {
    if (
      sample.plate === plate &&
      sample.well === well &&
      sample.field === field
    ) {
      return sample;
    }
  }
  return null;
}

function EmptyFieldView() {
  return <div style={{ border: "1px solid rgba(0, 0, 0, .125)" }} />;
}

export default function WellGridView({
  index,
  imageSet,
  size,
  onSelectField,
}: {
  index: DatasetPlateWell;
  imageSet: ImageSet | null;
  size: number;
  onSelectField: (field: Field) => void;
}) {
  const fields = useFields({
    dataset: index.dataset,
    acquisition: index.plate,
  });
  if (fields == null) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (!fields.successful) {
    return (
      <Center>
        <ErrorMessage error={fields.error} />;
      </Center>
    );
  }

  const scanPattern = fields
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    ?.map((fields) => getScanPattern(index.dataset, fields))
    .unwrap();
  const metadata = fields
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    ?.map((unwrapped) =>
      unwrapped.map(
        (field) =>
          ({
            type: "field",
            plate: index.plate,
            well: index.well,
            field,
          }) as FieldSampleMetadata,
      ),
    )
    .unwrap();
  return (
    <div className={"tw-flex tw-flex-row"}>
      <div
        className={"tw-grid tw-gap-[8px] tw-shrink"}
        style={{
          gridTemplateColumns: `repeat(${scanPattern[0].length}, 1fr)`,
          gridTemplateRows: `repeat(${scanPattern.length}, 1fr)`,
        }}
      >
        {scanPattern.flat().map((fieldIndex, arrayIndex) => {
          if (fieldIndex == null) {
            return <EmptyFieldView key={arrayIndex} />;
          }
          const field = formatField(fieldIndex);
          const match = findEntry(metadata, index.plate, index.well, field);
          const fullIndex = {
            ...index,
            field: toNumericField(field),
            t: 0,
            z: 0,
          };

          return match ? (
            <MultiChannelView
              index={fullIndex}
              imageSet={imageSet}
              crop={null}
              key={field}
              size={size}
              onClick={() => onSelectField(field)}
              showMagnification
              showContextMenu
            />
          ) : (
            <EmptyFieldView key={field} />
          );
        })}
      </div>
    </div>
  );
}
