/**
 * Component to render a selector for boolean filter conditions.
 */
import { BooleanCondition } from "../operations/filter-by";

export function BooleanConditionSelector({
  condition,
}: {
  condition: BooleanCondition;
}) {
  return (
    <div className={"tw-flex tw-flex-none tw-overflow-visible tw-relative"}>
      <div className={"tw-flex tw-items-center tw-px-1 tw-ml-1"}>
        <div className={"tw-truncate tw-flex-auto tw-text-right"}>
          {condition}
        </div>
      </div>
    </div>
  );
}
