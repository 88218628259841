import * as Dialog from "@radix-ui/react-dialog";
import cx from "classnames";
import { useCallback, useState } from "react";
import { Button } from "src/Common/Button";
import { useDialog } from "src/Common/useDialog";
import { useActiveWorkspaceId } from "src/Workspace/hooks";
import { useAccessToken } from "src/hooks/auth0";
import { DatasetId } from "src/types";
import { ImageSet } from "../../imaging/types";
import { usePhenoFinderContext } from "../Context";
import { DEFAULT_CUT_TREE_NAME } from "../constants";
import { InferenceStatus } from "../types";
import { requestInference } from "../utils";
import { PublishModal } from "./PublishModal";
import { getDefaultClusterDisplayName } from "./utils";

export function PublishButton({
  dataset,
  imageSet,
}: {
  dataset: DatasetId;
  imageSet: ImageSet | null;
}) {
  const accessToken = useAccessToken();
  const workspaceId = useActiveWorkspaceId();

  const [state] = usePhenoFinderContext();
  const { clusters, currentTree, fullTree } = state;

  const [status, setStatus] = useState<InferenceStatus>(InferenceStatus.NULL);
  const handleCloseModal = useCallback(() => {
    setStatus(InferenceStatus.NULL);
  }, []);

  const onSubmit = useCallback(
    async (description: string) => {
      if (currentTree === null || fullTree === null) {
        setStatus(InferenceStatus.FAILURE);
        return;
      }

      const defaultClusterNamesMap = new Map(
        (clusters ?? []).map((cluster, i) => [
          cluster.name,
          getDefaultClusterDisplayName(i),
        ]),
      );

      setStatus(InferenceStatus.SENDING);
      try {
        await requestInference(
          accessToken,
          // TODO(you): Fix this no-unnecessary-condition rule violation
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          description ?? "",
          defaultClusterNamesMap,
          currentTree,
          workspaceId,
          DEFAULT_CUT_TREE_NAME,
          dataset,
        );
        setStatus(InferenceStatus.SUCCESS);
      } catch (ex) {
        setStatus(InferenceStatus.FAILURE);
      }
    },
    [dataset, workspaceId, clusters, currentTree, fullTree, accessToken],
  );

  const { DialogComponent, setOpenDialog } = useDialog({
    onClose: handleCloseModal,
  });
  return (
    <>
      <Button
        variant="primary"
        onClick={() => {
          setOpenDialog(true);
        }}
        disabled={clusters === null}
      >
        Publish
      </Button>

      <DialogComponent>
        {status === InferenceStatus.SUCCESS ? (
          <SuccessModal dataset={dataset} />
        ) : (
          <PublishModal
            dataset={dataset}
            imageSet={imageSet}
            onPublish={onSubmit}
            status={status}
          />
        )}
      </DialogComponent>
    </>
  );
}

function SuccessModal({ dataset }: { dataset: DatasetId }) {
  const workspaceId = useActiveWorkspaceId();
  const measurementsPath = `/workspace/${workspaceId}/e/${dataset}/measurements`;
  const confettiEmoji = String.fromCodePoint(0x1f38a);
  return (
    <div className="tw-flex tw-flex-col tw-gap-8 tw-p-lg tw-m-lg tw-min-w-[500px] tw-max-w-[750px]">
      <div
        className={cx(
          "tw-text-[20px]",
          "tw-flex tw-items-center tw-justify-center",
        )}
      >
        {confettiEmoji} Your cluster labels are being published {confettiEmoji}
      </div>
      <div className={"tw-text-gray-500 tw-text-center"}>
        Our machines are hard at work and your data should be available soon!
        Once it's done, you can check the{" "}
        <a
          className={"tw-text-purple"}
          href={measurementsPath}
          target="_blank"
          rel="noreferrer noopener"
        >
          Analyze
        </a>{" "}
        page to view your results.
      </div>

      <div>
        <Dialog.Close asChild>
          <Button
            variant="primary"
            className="tw-w-full tw-flex tw-items-center tw-justify-center"
          >
            Okay
          </Button>
        </Dialog.Close>
      </div>
    </div>
  );
}
