import { AsyncDuckDB } from "@duckdb/duckdb-wasm";
import * as Tabs from "@radix-ui/react-tabs";
import cx from "classnames";
import pluralize from "pluralize";
import { useEffect, useMemo, useRef, useState } from "react";
import { FixedSizeGrid as Grid, GridChildComponentProps } from "react-window";
import { buildSort } from "src/util/build-sort";
import { enumChecker } from "src/util/enum";
import { useSize } from "src/util/hooks";
import { ValidatedSQL, sql, useQueryAsRecords } from "src/util/sql";
import { TabStrip } from "../TabStrip";
import { Operator } from "./operations/filter-by";
import { ClauseEntry } from "./types";

const WELL_SIZE = 8;
const PLATE_MIN_WIDTH = 250;
const PLATE_HEIGHT = 190;
const SMALL_PLATE_MIN_WIDTH = 180;
const SMALL_PLATE_HEIGHT = 120;

// TODO(danlec): Consolidate with PlateMap.tsx
/**
 * Shows a representation of how the different clauses of a filter match different
 * wells on a given plate
 */
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function PlatePreview({
  plate,
  wells,
  getColor,
  wellIds,
  dimensions,
  clauseByIndex,
  selectedIndex,
  style,
}: {
  plate: string;
  wells: Record<string, { included: boolean; hits: Set<number> }>;
  getColor: (index: number) => string;
  wellIds: string[];
  dimensions: { rows: number; columns: number };
  selectedIndex: number | null;
  clauseByIndex: Map<number, ClauseEntry>;
  style: React.CSSProperties;
}) {
  const hitCounts = useMemo(() => {
    const map: Map<number, number> = new Map();

    wellIds.forEach((key) => {
      // TODO(you): Fix this no-unnecessary-condition rule violation
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const hits = Array.from(wells[key]?.hits ?? []);
      hits.forEach((hit) => {
        map.set(hit, (map.get(hit) ?? 0) + 1);
      });
    });

    return map;
  }, [wells, wellIds]);

  const isSmallPlate = dimensions.columns <= 12;
  const width = isSmallPlate ? SMALL_PLATE_MIN_WIDTH : PLATE_MIN_WIDTH;
  const height = isSmallPlate ? SMALL_PLATE_HEIGHT : PLATE_HEIGHT;

  return (
    <div
      className="tw-flex tw-flex-col tw-gap-xs tw-items-center tw-justify-center"
      style={{ width, height, ...style }}
    >
      <div
        className="tw-grid tw-gap-[1px]"
        style={{
          gridTemplateColumns: `repeat(${dimensions.columns}, minmax(0, ${WELL_SIZE}px))`,
        }}
      >
        {wellIds.map((key) => {
          // TODO(you): Fix this no-unnecessary-condition rule violation
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          const allHits = Array.from(wells[key]?.hits ?? []).sort(
            buildSort(
              (a: number) => a === selectedIndex,
              (a: number, b: number) =>
                (hitCounts.get(a) ?? 0) - (hitCounts.get(b) ?? 0),
            ),
          );
          // Hits that aren't part of ignored clauses
          const notIgnoredHits = allHits.filter(
            (hit) => !clauseByIndex.get(hit)?.ignored,
          );

          // Prefer hits that didn't match everything in the plate
          const narrowHits = notIgnoredHits.filter(
            (hit) => hitCounts.get(hit) !== wellIds.length,
          );

          // Whether this well includes a hit for the clause being hovered over
          const hasSelectedIndex =
            selectedIndex !== null && allHits.includes(selectedIndex);

          const { style, className } = ((): {
            style?: React.CSSProperties;
            className?: string;
          } => {
            // TODO(you): Fix this no-unnecessary-condition rule violation
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            const wellHit = wells[key]?.included ?? false;
            const opacityClass = wellHit
              ? selectedIndex === null || hasSelectedIndex
                ? // This well is a hit, and we aren't hovering over a clause, or we have
                  // a hit for the clause we're hovering over
                  ""
                : // This well is a hit, but we're hovering over a different clause
                  "tw-opacity-30"
              : hasSelectedIndex
                ? // This well isn't a hit, but we're hovering over a clause we have a hit for
                  "tw-opacity-90"
                : // The well isn't a hit and we aren't hovering over anything
                  "tw-opacity-50";

            // If this well isn't a hit,  make it smaller
            const borderClass = !wellHit && "tw-border-[1px] tw-border-white";

            // Give well hits a small shadow
            const shadowClass = wellHit && "tw-shadow-sm";

            const baseClass = cx(
              "tw-rounded",
              opacityClass,
              borderClass,
              shadowClass,
            );

            if (allHits.length === 0) {
              // Show an unmatched well
              return {
                className: cx(baseClass, "tw-bg-gray-200"),
              };
            } else if (hasSelectedIndex) {
              // This well includes a hit for the clause that's currently being hovered
              // on, so color with that
              if (clauseByIndex.get(selectedIndex)?.ignored) {
                return {
                  className: cx(
                    opacityClass,
                    shadowClass,
                    "tw-border tw-rounded",
                  ),
                  style: { borderColor: getColor(selectedIndex) },
                };
              } else {
                return {
                  className: baseClass,
                  style: { background: getColor(selectedIndex) },
                };
              }
            } else if (
              wellHit &&
              allHits.some(
                (hit) => clauseByIndex.get(hit)?.operator === Operator.AND,
              )
            ) {
              // We've got a well hit on an AND, color it with the least common parent
              // clause
              const parentHit = allHits.find(
                (hit) => !clauseByIndex.get(hit)?.isSubClause,
              );
              return {
                className: baseClass,
                style: { background: getColor(parentHit ?? allHits[0]) },
              };
            } else if (clauseByIndex.get(allHits[0])?.isSubClause) {
              // We have a hit on a subclause, just show that
              return {
                className: baseClass,
                style: { background: getColor(allHits[0]) },
              };
            } else {
              // Otherwise try to color the well with one or two of the hits
              // NOTE: We prefer to do a single narrow hit rather than e.g. having a
              // narrow hit combined with the color for a clause that's matching
              // everything on the plate
              for (const hits of [narrowHits, notIgnoredHits]) {
                if (hits.length === 1) {
                  return {
                    className: baseClass,
                    style: { background: getColor(hits[0]) },
                  };
                } else if (hits.length >= 2) {
                  return {
                    className: baseClass,
                    style: {
                      background: `linear-gradient(to bottom right, ${hits
                        .slice(0, 2)
                        .map((hit) => `${getColor(hit)} 50%`)
                        .join(", ")}`,
                    },
                  };
                }
              }

              // We're showing one or more ignored hits
              return {
                className: cx(
                  opacityClass,
                  shadowClass,
                  "tw-border tw-rounded tw-opacity-50",
                ),
                style: { borderColor: getColor(allHits[0]) },
              };
            }
          })();

          return (
            <div
              key={key}
              className={className}
              style={{
                width: WELL_SIZE,
                height: WELL_SIZE,
                ...style,
              }}
            ></div>
          );
        })}
      </div>
      <div className="tw-text-xs tw-text-slate-500">{plate}</div>
    </div>
  );
}

interface CellProps {
  columnCount: number;
  selectedIndex: number | null;
  getColor: (clauseIndex: number) => string;
  plates: {
    plate: string;
    wells: Record<
      string,
      {
        // Is this well one of the wells that matched the overall filter?
        included: boolean;
        // The indices of the individual clauses that matched
        hits: Set<number>;
      }
    >;
  }[];
  clauseByIndex: Map<number, ClauseEntry>;
  dimensions: { rows: number; columns: number };
  wellIds: string[];
}

// Wrapper for the Cell displayed in the react-window Grid
function PlateCell({
  style,
  rowIndex,
  columnIndex,
  data: {
    columnCount,
    plates,
    getColor,
    selectedIndex,
    clauseByIndex,
    dimensions,
    wellIds,
  },
}: GridChildComponentProps<CellProps>) {
  const entry = plates[rowIndex * columnCount + columnIndex];
  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!entry) {
    return <div style={style}></div>;
  }

  const { plate, wells } = entry;

  return (
    <PlatePreview
      style={style}
      key={plate}
      plate={plate}
      wells={wells}
      getColor={getColor}
      selectedIndex={selectedIndex}
      clauseByIndex={clauseByIndex}
      dimensions={dimensions}
      wellIds={wellIds}
    />
  );
}

enum PreviewTab {
  None = "none",
  Matches = "matches",
  NoMatches = "no matches",
}

const isPreviewTab = enumChecker<PreviewTab>(PreviewTab);

/**
 * Shows a list of plates, with a graphical indication of how the different clauses
 * match different wells on the plate
 */
export function FilterPreview({
  sql: whereClause,
  clauses,
  getColor,
  operator,
  selectedIndex,
  metadata,
}: {
  sql: ValidatedSQL;
  clauses: {
    clause: ValidatedSQL | undefined;
    ignored: boolean;
    isSubClause: boolean;
    operator: Operator;
  }[];
  operator: Operator;
  getColor: (index: number) => string;
  selectedIndex: number | null;
  metadata: AsyncDuckDB;
}) {
  const [previewTab, setPreviewTab] = useState<PreviewTab>(PreviewTab.Matches);

  const testClauses = useMemo(
    (): {
      id: "combined" | `clause${number}`;
      clause: ValidatedSQL;
      index?: number;
      ignored: boolean;
      isSubClause: boolean;
      operator: Operator;
    }[] => [
      {
        id: "combined",
        clause: whereClause,
        ignored: false,
        isSubClause: false,
        operator,
      },
      ...clauses
        .map(({ clause, ignored, isSubClause, operator }, index) => {
          const id: `clause${number}` = `clause${index}`;
          return { id, clause, ignored, isSubClause, index, operator };
        })
        .filter(
          (
            entry,
          ): entry is {
            id: `clause${number}`;
            clause: ValidatedSQL;
            index: number;
            ignored: boolean;
            isSubClause: boolean;
            operator: Operator;
          } => entry.clause !== undefined,
        ),
    ],
    [clauses, operator, whereClause],
  );

  const clauseByIndex = useMemo(
    (): Map<number, ClauseEntry> =>
      new Map(
        testClauses
          .filter((entry): entry is ClauseEntry => entry.index !== undefined)
          .map((clause) => [clause.index, clause]),
      ),
    [testClauses],
  );

  const records = useQueryAsRecords<
    {
      plate: string;
      well: string;
      combined: number;
    } & { [key: `clause${number}`]: number | undefined }
  >(
    metadata,
    sql`SELECT plate, well, ${testClauses
      .map(
        ({ id, clause }) =>
          sql`MAX(CASE WHEN (${clause}) THEN 1 ELSE 0 END) AS ${id}`,
      )
      .join(", ")} from sample_metadata GROUP BY plate, well`,
  );

  const plates = useMemo(() => {
    const map: Record<
      string,
      Record<string, { included: boolean; hits: Set<number> }>
    > = {};

    if (records?.successful) {
      records.value.forEach((record) => {
        // TODO(you): Fix this no-unnecessary-condition rule violation
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const plateEntry = (map[record.plate] ??= {});
        plateEntry[record.well] = {
          included: record.combined === 1,
          hits: new Set(
            testClauses
              .filter(({ id }) => id !== "combined" && record[id] === 1)
              .map(({ index }) => index)
              .filter((index): index is number => index !== undefined),
          ),
        };
      });
    }

    return Object.entries(map)
      .map(([plate, wells]) => ({
        plate,
        wells,
        hasMatches: Object.values(wells).some((well) => well.included),
      }))
      .sort(buildSort("plate"));
  }, [records, testClauses]);

  const platesWithMatches = useMemo(
    () => plates.filter(({ hasMatches }) => hasMatches),
    [plates],
  );

  const platesWithoutMatches = useMemo(
    () => plates.filter(({ hasMatches }) => !hasMatches),
    [plates],
  );

  const wellCount = useMemo(() => {
    return plates
      .map(
        ({ wells }) =>
          Object.values(wells).filter(({ included }) => included).length,
      )
      .reduce((sum, count) => sum + count, 0);
  }, [plates]);

  const displayedPlates =
    previewTab === PreviewTab.Matches
      ? platesWithMatches
      : platesWithoutMatches;

  const refPreviewContainer = useRef<HTMLDivElement>(null);
  const previewSize = useSize(refPreviewContainer, "border-box");

  const wellIds = useMemo(
    () => Object.keys(displayedPlates[0]?.wells ?? {}).sort(),
    [displayedPlates],
  );
  const dimensions = useMemo(() => {
    const lastWell = wellIds.at(-1);
    const parts = lastWell && /^([A-Z])(\d+)$/.exec(lastWell);

    if (parts) {
      const [, lastRow, lastColumn] = parts;

      return {
        columns: parseInt(lastColumn),
        rows: lastRow.charCodeAt(0) - "A".charCodeAt(0) + 1,
      };
    } else {
      return { columns: 24, rows: 16 };
    }
  }, [wellIds]);

  const isSmallPlate = dimensions.columns <= 12;
  const plateMinWidth = isSmallPlate ? SMALL_PLATE_MIN_WIDTH : PLATE_MIN_WIDTH;
  const plateHeight = isSmallPlate ? SMALL_PLATE_HEIGHT : PLATE_HEIGHT;

  const columnCount =
    previewSize && Math.floor(previewSize.width / plateMinWidth);

  useEffect(() => {
    switch (previewTab) {
      case PreviewTab.Matches: {
        if (platesWithMatches.length === 0) {
          setPreviewTab(
            platesWithoutMatches.length > 0
              ? PreviewTab.NoMatches
              : PreviewTab.None,
          );
        }
        break;
      }
      case PreviewTab.NoMatches: {
        if (platesWithoutMatches.length === 0) {
          setPreviewTab(
            platesWithMatches.length > 0 ? PreviewTab.Matches : PreviewTab.None,
          );
        }
        break;
      }
      case PreviewTab.None: {
        if (platesWithMatches.length > 0) {
          setPreviewTab(PreviewTab.Matches);
        } else if (platesWithoutMatches.length > 0) {
          setPreviewTab(PreviewTab.NoMatches);
        }
      }
    }
  }, [platesWithMatches.length, platesWithoutMatches.length, previewTab]);

  return (
    <div>
      <Tabs.Root
        className="tw-min-w-[600px] tw-mt-sm"
        value={previewTab}
        onValueChange={(value) => isPreviewTab(value) && setPreviewTab(value)}
      >
        <TabStrip
          options={[
            ...(platesWithMatches.length > 0
              ? [
                  {
                    value: PreviewTab.Matches,
                    label: `${pluralize(
                      "matching plate",
                      platesWithMatches.length,
                      true,
                    )} (${pluralize("well", wellCount, true)})`,
                  },
                ]
              : []),
            ...(platesWithoutMatches.length > 0
              ? [
                  {
                    value: PreviewTab.NoMatches,
                    label: `${pluralize(
                      "plate",
                      platesWithoutMatches.length,
                      true,
                    )} without matches`,
                  },
                ]
              : []),
          ]}
          value={previewTab}
        />
      </Tabs.Root>

      <div
        className={cx("tw-w-full")}
        style={{ height: plateHeight * 1.2 }}
        ref={refPreviewContainer}
      >
        {displayedPlates.length > 0 ? (
          previewSize &&
          columnCount !== undefined && (
            <Grid
              columnCount={columnCount}
              columnWidth={previewSize.width / columnCount}
              rowHeight={plateHeight}
              overscanRowCount={2}
              rowCount={Math.ceil(displayedPlates.length / 2)}
              height={previewSize.height}
              width={previewSize.width}
              itemData={{
                columnCount,
                plates: displayedPlates,
                selectedIndex,
                getColor,
                clauseByIndex,
                wellIds,
                dimensions,
              }}
              useIsScrolling
            >
              {PlateCell}
            </Grid>
          )
        ) : (
          <div className="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center tw-text-slate-500">
            {previewTab === PreviewTab.Matches
              ? "There aren't any plates that have matches for your filter"
              : "All plates have some matches for your filter"}
          </div>
        )}
      </div>
    </div>
  );
}
