import cx from "classnames";
import { ReactNode, useCallback } from "react";

export default function MenuButton({
  onClick,
  disabled,
  children,
}: {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  children: ReactNode;
}) {
  return (
    <button
      className={cx(
        "tw-px-4 tw-py-2 tw-text-left tw-outline-none",
        "tw-flex tw-flex-row tw-items-center tw-justify-start",
        "tw-text-sm",
        "hover:tw-bg-gray-100/[0.5]",
        "active:tw-bg-gray-100/[0.8]",
        "focus:tw-bg-gray-100/[0.9]",
      )}
      onClick={useCallback(
        (e) => {
          e.stopPropagation();
          onClick?.(e);
        },
        [onClick],
      )}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
