import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { DatasetId, WorkspaceId } from "src/types";

export type TrackingContext = {
  [key: string]: string | number | boolean;
} & {
  workspace?: WorkspaceId;
  dataset?: DatasetId;
};

export const EventTrackingContext = createContext<TrackingContext | null>(null);
EventTrackingContext.displayName = "EventTrackingContext";

export function useEventTrackingContext() {
  const context = useContext(EventTrackingContext);

  if (context === null) {
    throw new Error(
      "useEventTrackingContext must be used within <EventTrackingContextProvider />",
    );
  }

  return context;
}

const GlobalEventTrackingContext = createContext<{
  // Returns method to pop the context back out
  pushGlobalContext: (context: TrackingContext) => () => void;
} | null>(null);
GlobalEventTrackingContext.displayName = "GlobalEventTrackingContext";

export function GlobalEventTrackingContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [pushedContexts, setPushedContexts] = useState<TrackingContext[]>([]);

  const globalContext = useMemo(() => {
    const contextKeys = new Set();
    pushedContexts.forEach((context) => {
      Object.keys(context).forEach((key) => {
        if (contextKeys.has(key)) {
          console.error(`Key ${key} defined multiple times in global context`);
        }
        contextKeys.add(key);
      });
    });

    return Object.assign({}, ...pushedContexts);
  }, [pushedContexts]);

  const pushGlobalContext = useCallback((context: TrackingContext) => {
    setPushedContexts((currentPushedContexts) => [
      ...currentPushedContexts,
      context,
    ]);

    return () => {
      setPushedContexts((currentPushedContexts) =>
        currentPushedContexts.filter(
          (otherContext) => otherContext !== context,
        ),
      );
    };
  }, []);

  return (
    <GlobalEventTrackingContext.Provider
      value={{
        pushGlobalContext,
      }}
    >
      <EventTrackingContext.Provider value={globalContext}>
        {children}
      </EventTrackingContext.Provider>
    </GlobalEventTrackingContext.Provider>
  );
}

export function useGlobalEventTrackingContext() {
  const context = useContext(GlobalEventTrackingContext);

  if (context === null) {
    throw new Error(
      "useGlobalEventTrackingContext must be used within <GlobalEventTrackingContextProvider />",
    );
  }

  return context;
}
