import * as Dialog from "@radix-ui/react-dialog";
import cx from "classnames";
import { BookOpen } from "react-feather";
import ReactMarkdown from "react-markdown";
import remarkFrontmatter from "remark-frontmatter";
import remarkGfm from "remark-gfm";
import { Button } from "src/Common/Button";
import { useMethodsContext } from "./context";
import { getMethodSectionContent } from "./utils";

export function MethodsButton({ className }: { className?: string }) {
  const { activeSectionKey } = useMethodsContext();

  // Don't show the button at all if there's no methods doc for the page
  if (activeSectionKey === null) {
    return null;
  }

  // This shouldn't happen, but just in case we don't have content generated for the key,
  // don't display the button
  const methodsContent = getMethodSectionContent(activeSectionKey);
  if (methodsContent === null) {
    return null;
  }

  const { title, markdownContent } = methodsContent;

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild={true}>
        <Button
          variant="primary"
          size="sm"
          className={className}
          icon={BookOpen}
        >
          View methods
        </Button>
      </Dialog.Trigger>

      <Dialog.Portal>
        <div
          className={cx(
            "tw-fixed tw-left-0 tw-top-0 tw-w-full tw-h-full tw-z-popup-overlay",
            "tw-bg-slate-400 tw-opacity-70",
          )}
        />
        <Dialog.Overlay
          className={cx(
            "tw-fixed tw-left-0 tw-top-0 tw-w-full tw-h-full tw-z-popup",
            "tw-flex tw-flex-col tw-justify-center",
          )}
        >
          <Dialog.Content
            className={cx(
              "tw-mx-auto tw-my-12 tw-w-1/2 tw-h-full tw-py-8",
              "tw-flex tw-flex-col tw-justify-between tw-overflow-hidden",
              "tw-font-sans tw-break-words tw-rounded-lg tw-bg-white tw-shadow-lg",
            )}
          >
            <div
              id="methods-content"
              className="tw-flex-1 tw-overflow-y-auto tw-px-xl"
            >
              <div className="tw-uppercase tw-text-slate-600">Methods</div>
              <div className="tw-text-4xl tw-text-slate-700 tw-my-4">
                {title}
              </div>
              <ReactMarkdown
                className="tw-text-slate-500"
                remarkPlugins={[remarkFrontmatter, remarkGfm]}
                components={{
                  h2: ({ children, className }) => (
                    <h2
                      className={cx(className, "tw-mt-lg tw-mb-sm tw-text-2xl")}
                    >
                      {children}
                    </h2>
                  ),
                  h3: ({ children, className }) => (
                    <h3
                      className={cx(
                        className,
                        "tw-mt-lg tw-mb-sm tw-text-lg tw-font-bold",
                      )}
                    >
                      {children}
                    </h3>
                  ),
                  p: ({ children, className }) => (
                    <p className={cx(className, "tw-mb-md")}>{children}</p>
                  ),
                  a: (linkProps) => {
                    return (
                      <a
                        {...linkProps}
                        className="tw-text-blue"
                        target={
                          linkProps.href?.startsWith("#") ? "_self" : "_blank"
                        }
                      />
                    );
                  },
                  ol: ({ children, className }) => (
                    <ol className={cx("tw-ml-xl tw-list-decimal", className)}>
                      {children}
                    </ol>
                  ),
                  ul: ({ children, className }) => (
                    <ul className={cx("tw-ml-xl tw-list-disc", className)}>
                      {children}
                    </ul>
                  ),
                }}
              >
                {markdownContent}
              </ReactMarkdown>
            </div>

            <div className="tw-flex-none">
              <hr className="tw-border-gray-200 tw-mt-0 tw-mb-md" />

              <div className="tw-px-8">
                <Dialog.Close asChild={true}>
                  <Button variant="primary" size="sm">
                    Back to the data
                  </Button>
                </Dialog.Close>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
