import { UntypedWellSampleMetadataRow } from "../../types";

export type GroupSelectionKey = "selectedGroup" | "selectedSubgroup";
// TODO(davidsharff): we should use the col name instead of "value"
export type MetadataAndValue = UntypedWellSampleMetadataRow & {
  value: number;
};

export enum XAxisSort {
  Median = "median",
  Alphabetical = "alphabetical",
  AbsoluteDelta = "absoluteDelta",
}

export enum XAxisSortDirection {
  Asc = "asc",
  Desc = "desc",
}

export enum StatisticalCorrectionType {
  Bonferroni = "bonferroni",
  None = "none",
}

export enum StatisticalTestType {
  MannWhitney = "mannWhitney",
  TTest = "tTest",
}

export interface GetCorrectedPValueOptions {
  uncorrectedPValue: number;
  numberOfTests?: number | null;
}

export interface StatisticalTestOptions {
  values: number[];
  controlValues: number[];
}

export type StatisticalCorrection = {
  type: StatisticalCorrectionType;
  displayName: string;
  getCorrectedPValue: (options: GetCorrectedPValueOptions) => number;
};

export type StatisticalTest = {
  type: StatisticalTestType;
  displayName: string;
  getTestValue: (options: StatisticalTestOptions) => number;
};
