import KDBush from "kdbush";
import {
  Bounds,
  DragPoint,
  RenderOptions,
  Renderer,
  RendererOptions,
  ScatterPlotPoint,
  ScatterPlotPointState,
} from "./types";
import {
  drawCircle,
  enclosedPoints,
  getSelectionBounds,
  initCanvas,
  toPixel,
} from "./util";

interface RenderOverlayOptions extends RendererOptions {
  canvas: HTMLCanvasElement;
  pointSize: number;
  hoverPoint: ScatterPlotPoint | null;
  points: ScatterPlotPoint[];
  pointsIndex: KDBush;
  visiblePoints: ScatterPlotPoint[];
  pointStates: Record<string, ScatterPlotPointState>;
  dragStart: DragPoint | null;
  dragEnd: DragPoint | null;
  viewBounds: Bounds;
  canvasWidth: number;
  canvasHeight: number;
  selectedPoints: Set<string>;
  refClusterHulls: React.MutableRefObject<Map<
    number,
    ScatterPlotPoint[]
  > | null>;
}

/**
 * Renders all of the highlighted states for points, including
 * - Highlighting due to a group being selected
 * - The selection box and the associated cells
 * - The cell the cursor is hovering over
 *
 * These are all drawn in a different color and/or larger size and are drawn on
 * top of the background layer.  This gets invalidated much more frequently, e.g.
 * when the position of the selection box changes or a different group is
 * highlighted
 */
function renderOverlay(options: RenderOverlayOptions) {
  const {
    canvas,
    visiblePoints,
    pointStates,
    pointSize,
    hoverPoint,
    selectedPoints,
  } = options;

  const ctx = initCanvas(canvas, options);
  if (!ctx) {
    return;
  }

  const drawPoint = ({
    alpha,
    color,
    pxX,
    pxY,
    scale,
  }: {
    alpha?: number;
    color: string;
    pxX: number;
    pxY: number;
    scale: number;
  }) => {
    drawCircle({
      ctx,
      x: pxX,
      y: pxY,
      color,
      pointSize,
      alpha,
      scale,
    });
  };

  const tempSelectedPoints = enclosedPoints(options);
  const anySelected = selectedPoints.size > 0;

  for (const pt of visiblePoints) {
    const { x: pxX, y: pxY } = toPixel(pt, options);

    const tempSelected = tempSelectedPoints.has(pt.key);
    const selected = selectedPoints.has(pt.key);

    const pointState = pointStates[pt.key];
    const { highlighted, color } = pointState;

    if (tempSelected) {
      drawPoint({
        color: "#777",
        pxX,
        pxY,
        scale: 1.1,
      });
    } else if (highlighted && selected) {
      drawPoint({
        color,
        pxX,
        pxY,
        scale: 1.5,
      });
    } else if (selected && !highlighted) {
      drawPoint({
        color,
        pxX,
        pxY,
        scale: 1.1,
      });
    } else if (highlighted && !selected && anySelected) {
      drawPoint({
        color,
        pxX,
        pxY,
        scale: 1.1,
      });
    } else if (highlighted && !selected && !anySelected) {
      drawPoint({
        color,
        pxX,
        pxY,
        scale: 1.5,
      });
    } else {
      // This point's location isn't occupied
      continue;
    }
  }

  if (hoverPoint) {
    const { x: pxX, y: pxY } = toPixel(hoverPoint, options);
    drawPoint({
      color: pointStates[hoverPoint.key].color,
      pxX,
      pxY,
      scale: 1.1,
    });
    drawPoint({
      color: pointStates[hoverPoint.key].color,
      alpha: 0.4,
      pxX,
      pxY,
      scale: 2,
    });
  }

  const bounds = getSelectionBounds(options);
  if (bounds) {
    ctx.strokeStyle = "#444";
    ctx.fillStyle = "rgba(0,0,0,.1)";

    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.rect(
      bounds.boundsLeft,
      bounds.boundsTop,
      bounds.boundsRight - bounds.boundsLeft,
      bounds.boundsBottom - bounds.boundsTop,
    );
    ctx.fill();
    ctx.stroke();
  }
}

export function OverlayRenderer(
  refCanvas: React.RefObject<HTMLCanvasElement | null>,
  refSelectedPoints: React.RefObject<Set<string>>,
  refClusterHulls: React.RefObject<Map<number, ScatterPlotPoint[]> | null>,
): Renderer<RenderOptions, RendererOptions> {
  return {
    options: ({
      pointSize,
      hoverPoint,
      points,
      pointsIndex,
      visiblePoints,
      pointStates,
      dragStart,
      dragEnd,
      canvasWidth,
      canvasHeight,
      viewBounds,
      initializedCanvas,
      initializedData,
    }): RenderOverlayOptions | null =>
      refCanvas.current &&
      refSelectedPoints.current &&
      initializedCanvas &&
      initializedData &&
      pointsIndex
        ? {
            canvas: refCanvas.current,
            pointSize,
            hoverPoint,
            points,
            pointsIndex,
            visiblePoints,
            pointStates,
            dragStart,
            dragEnd,
            canvasHeight,
            canvasWidth,
            viewBounds,
            selectedPoints: refSelectedPoints.current,
            refClusterHulls: refClusterHulls,
          }
        : null,
    render: renderOverlay,
  };
}
