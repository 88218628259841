import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { QS, useTypedQueryParams } from "src/routing";
import { MetadataColumnValue } from "src/types";
import { SortBy } from "./Controls/SortBySelector/types";
import { VariableSourceColumn } from "./Table/types";
import { ColorBy, ViewMode, ViewState, Visualization } from "./types";
import { normalizeOverviewColumns } from "./utils";

const defaultViewState: ViewState = {
  colorBy: ColorBy.ComparisonsRedGreen,
  columnVisibility: {},
  filterText: "",
  groupBy: null,
  highlighted: null,
  mode: ViewMode.Overview,
  overviewColorBy: null,
  overviewColumns: [],
  pins: {},
  subgroupBy: null,
  subgroupByReference: null,
  similarTo: [],
  scoreIds: [],
  sortBys: [],
  visualization: Visualization.ScatterPlot,
};

type Updater =
  | Partial<ViewState>
  | ((current: ViewState) => Partial<ViewState>);

interface ViewStateContextValue extends ViewState {
  update(update: Updater): void;
}

const ViewStateContext = createContext<ViewStateContextValue>({
  ...defaultViewState,
  update() {
    throw new Error("Missing ViewStateContext");
  },
});

export function useViewState() {
  return useContext(ViewStateContext);
}

function cleanColumn(value: VariableSourceColumn): VariableSourceColumn;
function cleanColumn(
  value: VariableSourceColumn | null,
): VariableSourceColumn | null;
function cleanColumn(
  value: VariableSourceColumn | null,
): VariableSourceColumn | null {
  if (!value) {
    return value;
  } else {
    return {
      id: value.id,
      source: value.source,
    };
  }
}

const handleVariableSourceColumn = {
  clean: cleanColumn,
  validate(raw: unknown): raw is VariableSourceColumn {
    if (!raw || typeof raw !== "object") {
      return false;
    }
    const testRaw = raw as Partial<VariableSourceColumn>;
    return (
      typeof testRaw.id === "string" &&
      testRaw.source !== undefined &&
      ["metadata", "features", "similarity"].includes(testRaw.source)
    );
  },
};

export function ViewStateContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [queryParams, setQueryParams] = useTypedQueryParams<
    {
      [key in Exclude<
        keyof ViewState,
        // We don't currently want to serialize these
        | "columnVisibility"
        | "filterText"
        | "highlighted"
        | "pins"
        | "sortBysOverride"
        // We serialize this under a different name
        | "overviewColumns"
        | "scoreIds"
      >]: ViewState[key];
    } & {
      overviewColumns: VariableSourceColumn[];
      scores: ViewState["scoreIds"];
    }
  >({
    colorBy: QS.enum(ColorBy, ColorBy.ComparisonsRedGreen),
    groupBy: QS.string<string, null>(null),
    mode: QS.enum(ViewMode, ViewMode.Overview),
    overviewColorBy: QS.json<VariableSourceColumn, null>({
      defaultValue: null,
      ...handleVariableSourceColumn,
    }),
    overviewColumns: QS.jsons<VariableSourceColumn>(handleVariableSourceColumn),
    subgroupBy: QS.string<string, null>(null),
    subgroupByReference: QS.string<MetadataColumnValue, null>(null),
    scores: QS.strings(),
    similarTo: QS.strings<MetadataColumnValue>(),
    sortBys: QS.jsons<SortBy>({
      clean(value) {
        return {
          column: { id: value.column.id, source: value.column.source },
          ascending: value.ascending,
        };
      },
      validate(raw): raw is SortBy {
        if (!raw || typeof raw !== "object") {
          return false;
        }
        const testRaw = raw as Partial<SortBy>;
        return (
          typeof testRaw.column === "object" &&
          typeof testRaw.column.id === "string" &&
          typeof testRaw.column.source === "string" &&
          typeof testRaw.ascending === "boolean"
        );
      },
    }),
    visualization: QS.enum(Visualization, Visualization.ScatterPlot),
  });

  // Convert overview columns from a list of zero or more columns to a list containing
  // exactly 0, 1 or 2 columns
  const overviewColumns = useMemo(
    () => normalizeOverviewColumns(queryParams.overviewColumns),
    [queryParams.overviewColumns],
  );

  const [state, setState] = useState<ViewState>({
    ...defaultViewState,
    ...queryParams,
    scoreIds: queryParams.scores,
    overviewColumns,
  });

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...state,
      scores: state.scoreIds,
    });
  }, [queryParams, setQueryParams, state]);

  const update = useCallback((update: Updater) => {
    setState((current) => ({
      ...current,
      ...(typeof update === "function" ? update(current) : update),
    }));
  }, []);

  const value = useMemo(
    () => ({
      ...state,
      update,
    }),
    [state, update],
  );

  return (
    <ViewStateContext.Provider value={value}>
      {children}
    </ViewStateContext.Provider>
  );
}
