/**
 * Component to render a search box to adorn a dropdown list.
 */
import { css } from "aphrodite";
import classNames from "classnames";
import { shared } from "../../megamap-styles";

export type Styling = "Header" | "Small" | true;

export default function SearchBox({
  autoFocus = true,
  filterText,
  placeholder,
  clearable,
  onChange,
  styling,
  onPasteSearchInput,
}: {
  filterText: string;
  placeholder?: string;
  clearable?: boolean;
  onChange: (filterText: string) => void;
  styling: Styling;
  onPasteSearchInput?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
}) {
  const height = styling === "Header" ? 29 : 32;

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    if (onPasteSearchInput) {
      onPasteSearchInput(e);
      // Clear any text since paste is the equivalent of a selection (i.e. not part of search)
      onChange("");
    }
  };

  return (
    <div
      className={classNames(
        "tw-flex tw-flex-auto tw-white tw-rounded",
        styling === "Small" && "tw-mx-1",
        styling === "Header" && "tw-mt-1 tw-mx-2",
        css(
          ...(styling === "Small"
            ? [shared.borderBottomThick, shared.borderDarken2]
            : []),
          ...(styling === "Header"
            ? [shared.borderBottomThick, shared.borderDarken2]
            : []),
        ),
      )}
      style={{ minHeight: height }}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <input
        ref={
          autoFocus
            ? (ref) => (ref ? ref.focus({ preventScroll: true }) : undefined)
            : undefined
        }
        type="text"
        placeholder={placeholder}
        className={classNames(
          "tw-flex-auto",
          styling === true && "tw-p-1",
          styling === "Header" && "tw-px-0 tw-py-1",
          css(
            shared.inputBase,
            ...(styling === "Small" ? [shared.small] : []),
            ...(styling === "Header" ? [shared.small] : []),
          ),
        )}
        style={{ height }}
        value={filterText}
        onChange={(e) => onChange(e.target.value)}
        onPaste={handlePaste}
      />
      {filterText && clearable ? (
        <div
          className={
            "tw-flex tw-flex-none tw-items-center tw-justify-center tw-p-0.5 tw-cursor-pointer " +
            css(shared.quieter, shared.linkUnquiet)
          }
          onClick={() => onChange("")}
        >
          X
        </div>
      ) : null}
    </div>
  );
}

SearchBox.defaultProps = { style: "Large", clearable: false };
