import { useCallback, useMemo } from "react";
import {
  ConfirmDelete,
  EditValue,
  MainMenu,
  OverflowMenu,
  RenameSettings,
  ValidationResult,
} from "../Common/PopoverMenu";
import { LabeledSet, useLabeledSetContext } from "./Context";

function RenameClassification({ name }: { name: string }) {
  const { state: labeledSet, updateState } = useLabeledSetContext();

  return (
    <EditValue
      initialValue={name}
      validate={useCallback(
        (newName: string): ValidationResult =>
          newName !== name &&
          labeledSet.classifications.some(
            (classification) => classification.name === newName,
          )
            ? { valid: false, message: "There is another class with that name" }
            : { valid: true },
        [labeledSet.classifications, name],
      )}
      onSubmit={useCallback(
        async ({ newValue: newName }: RenameSettings) => {
          const newLabeledSet: LabeledSet = {
            ...labeledSet,
            classifications: labeledSet.classifications.map((classification) =>
              classification.name === name
                ? {
                    ...classification,
                    name: newName,
                    examples: classification.examples.map((example) => ({
                      ...example,
                      class: name,
                    })),
                  }
                : classification,
            ),
          };

          updateState(() => newLabeledSet);

          // Let the auto-save take care of saving the change
        },
        [labeledSet, name, updateState],
      )}
      saveText="Rename"
    />
  );
}

function ConfirmDeleteClassification({
  name,
  onDeleteStart,
  onDeleteFinish,
}: {
  name: string;
  onDeleteStart?: () => void;
  onDeleteFinish?: () => void;
}) {
  const { state, setState } = useLabeledSetContext();

  return (
    <ConfirmDelete
      onDeleteStart={onDeleteStart}
      onDeleteFinish={onDeleteFinish}
      onDelete={useCallback(async () => {
        const deletedClassification = state.classifications.find(
          (classification) => classification.name === name,
        );

        if (deletedClassification) {
          const newLabeledSet: LabeledSet = {
            ...state,
            classifications: state.classifications.filter(
              (classification) => classification !== deletedClassification,
            ),
            // Return any examples back to the queue
            queue: deletedClassification.examples.map(
              ({ class: className, ...example }) => example,
            ),
          };

          setState(newLabeledSet);
        }

        // Let the auto-save take care of saving the change
      }, [name, setState, state])}
      error={useMemo(
        () =>
          state.classifications.length === 2
            ? "You must have at least two classes"
            : undefined,
        [state.classifications.length],
      )}
    />
  );
}

interface Props {
  className?: string;
  name: string;
}

export function ClassMenu({ className, name }: Props) {
  return (
    <OverflowMenu align="end" className={className}>
      <MainMenu
        className="tw-min-w-[300px]"
        items={{
          "Rename…": <RenameClassification name={name} />,
          "Delete…": <ConfirmDeleteClassification name={name} />,
        }}
      />
    </OverflowMenu>
  );
}
