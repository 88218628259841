/**
 * Wrapper around a variety of controls used to toggle visualization parameters.
 */
import { useEffect } from "react";
import {
  ChannelIndex,
  ChannelMap,
  DisplayRange,
  DisplaySettings,
  Palette,
  ProcessingTechnique,
  VisualizationState,
} from "../types";
import { ChannelControls } from "./ChannelControls";

export default function VisualizationControls({
  displaySettings,
  channelLoaded,
  channelMap,
  lockChannel,
  showChannel,
  palette,
  onChangeActiveRange,
  onChangeChannel,
  onLoadPalette,
  onLockChannel,
  onToggleChannel,
  stainSubset,
}: VisualizationState & {
  displaySettings: DisplaySettings[];
  channelLoaded: boolean[];
  channelMap: ChannelMap;
  lockChannel: boolean[];
  showChannel: boolean[];
  palette: Palette | null;
  onChangeChannel: (selectedValue: number | null, index: ChannelIndex) => void;
  onLoadPalette: (palette: Palette) => void;
  onChangeActiveRange: (activeRange: DisplayRange, index: ChannelIndex) => void;
  onToggleChannel: (index: ChannelIndex) => void;
  onLockChannel?: null | ((index: ChannelIndex) => void);
  onSetPreprocessingMode?:
    | null
    | ((preprocessingMode: ProcessingTechnique | null) => void);
  stainSubset?: null | string[];
}) {
  // TODO(benkomalo): restore "auto" and "reset" range, or remove from parents.
  useEffect(() => {
    if (palette != null) {
      onLoadPalette(palette);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [palette]);
  return (
    <div className={"tw-flex tw-flex-col"}>
      <ChannelControls
        palette={palette}
        channelMap={channelMap}
        displaySettings={displaySettings}
        onChangeActiveRange={onChangeActiveRange}
        enableChannelSettings={channelLoaded}
        enableChannel={showChannel}
        onToggleChannel={onToggleChannel}
        lockChannel={lockChannel}
        onLockChannel={onLockChannel}
        onChangeChannel={onChangeChannel}
        stainSubset={stainSubset}
      />
    </div>
  );
}

VisualizationControls.defaultProps = {
  showChannel: [true, true, true, true, true, true, true],
  channelLoaded: [true, true, true, true, true, true, true],
  lockChannel: [false, false, false, false, false, false, false],
};
