import { AccessToken } from "src/Auth0/accessToken";
import { HOST_URL } from "src/env";
import { sprFetch } from "src/hooks/fetch";
import {
  ApiFetchable,
  ApiPath,
  ApiRouteActions,
  ApiUrlOptions,
  Falsy,
  HasPlaceholders,
  NoPlaceholders,
  QueryString,
  RouteEntry,
  RoutePlaceholders,
  UrlPath,
} from "./types";
import { apiPath, serializeQueryString } from "./util";

export class ApiResponse {
  private pendingResponse: Promise<Response>;
  private controller: AbortController;

  constructor(pendingResponse: Promise<Response>, controller: AbortController) {
    this.pendingResponse = pendingResponse;
    this.controller = controller;
  }

  private async getResponse(): Promise<Response> {
    const response = await this.pendingResponse;
    if (response.status === 204) {
      throw new Error("Received an empty response");
    }
    return response;
  }

  async json<T>() {
    return (await this.getResponse()).json() as Promise<T>;
  }

  async text() {
    return (await this.getResponse()).text();
  }

  async blob() {
    return (await this.getResponse()).blob();
  }

  async download(filename: string) {
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(await this.blob());
    a.download = filename;
    a.dispatchEvent(new MouseEvent("click"));
  }

  raw() {
    return this.pendingResponse;
  }

  async finish() {
    await this.pendingResponse;
  }

  abort() {
    this.controller.abort();
  }
}

// NOTE(danlec): We don't use the Prefix "type", but it makes it a little easier to
// see what sort of prefix a given ApiClient / ApiUrlBuilder is using
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class ApiUrlBuilder<Routes extends RouteEntry> {
  private prefix: UrlPath;

  constructor({ prefix }: { prefix: UrlPath }) {
    this.prefix = prefix;
  }

  url<R extends Routes["suffix"]>(
    route: Exclude<R, ApiPath<RouteEntry>>,
    params: RoutePlaceholders<Routes, R>,
    query?: QueryString,
    options?: ApiUrlOptions,
  ): string {
    return this.apiPathUrl(apiPath(route, params), query, options);
  }

  protected apiPathUrl(
    path: ApiPath<Routes>,
    query?: QueryString,
    options?: ApiUrlOptions,
  ): string {
    return `${HOST_URL}/api/${options?.gpu ? "gpu-v0" : "v0"}/${
      this.prefix
    }${path}${serializeQueryString(query)}`;
  }

  fetchable<T>(
    route: ApiPath<Routes>,
    query?: QueryString,
    options?: Omit<ApiFetchable<T>, "url"> & ApiUrlOptions,
  ): ApiFetchable<T>;
  fetchable<T>(
    route: ApiPath<Routes> | Falsy,
    query?: QueryString,
    options?: Omit<ApiFetchable<T>, "url"> & ApiUrlOptions,
  ): ApiFetchable<T> | undefined;
  fetchable<T>(
    route: ApiPath<Routes> | Falsy,
    query: QueryString = {},
    options?: Omit<ApiFetchable<T>, "url"> & ApiUrlOptions,
  ): ApiFetchable<T> | undefined {
    return route
      ? {
          url: this.apiPathUrl(route, query, options),
          ...options,
        }
      : undefined;
  }
}
export class ApiClient<
  Routes extends RouteEntry,
> extends ApiUrlBuilder<Routes> {
  private accessToken: AccessToken;

  constructor(options: { accessToken: AccessToken; prefix: UrlPath }) {
    super(options);
    this.accessToken = options.accessToken;
  }

  private request({
    route,
    body,
    query,
    method,
  }: {
    route: ApiPath<Routes>;
    body?: unknown;
    query?: QueryString;
    method: "get" | "post" | "put" | "delete" | "patch";
  }): ApiResponse {
    const url = this.apiPathUrl(route, query);

    const controller = new AbortController();

    const response = sprFetch(url, this.accessToken, {
      signal: controller.signal,
      ...(method === "get"
        ? { method }
        : {
            method,
            ...(body
              ? {
                  headers: { "content-type": "application/json" },
                  body: JSON.stringify(body),
                }
              : {}),
          }),
    });

    return new ApiResponse(response, controller);
  }

  route<R extends NoPlaceholders<Routes["suffix"]>>(
    route: R,
  ): ApiRouteActions<Routes, R>;
  route<R extends HasPlaceholders<Routes["suffix"]>>(
    route: R,
    params: RoutePlaceholders<Routes, R>,
  ): ApiRouteActions<Routes, R>;
  route<R extends Routes["suffix"]>(
    route: R,
    params: RoutePlaceholders<Routes, R> | undefined = undefined,
  ): ApiRouteActions<Routes, R> {
    const path = apiPath(route, params ?? ({} as RoutePlaceholders<Routes, R>));
    const base = { route: path };

    return {
      get: (query: QueryString = {}) => {
        return this.request({
          method: "get",
          ...base,
          query,
        });
      },
      post: (body: unknown = undefined, query: QueryString = {}) => {
        return this.request({
          method: "post",
          ...base,
          body,
          query,
        });
      },
      put: (body: unknown = undefined, query: QueryString = {}) => {
        return this.request({
          method: "put",
          ...base,
          body,
          query,
        });
      },
      patch: (body: unknown = undefined, query: QueryString = {}) => {
        return this.request({
          method: "patch",
          ...base,
          body,
          query,
        });
      },
      delete: () => {
        return this.request({
          method: "delete",
          ...base,
        });
      },
    } as ApiRouteActions<Routes, R>;
  }
}
