/**
 * Selector for a plate.
 */
import { useEffect, useRef } from "react";
import { Select } from "src/Common/Select";
import { DatasetId } from "src/types";
import { Success } from "@spring/core/result";
import { trackEvent } from "../analytics";
import { useAcquisitions } from "../hooks/immunofluorescence";
import { defaultComparator } from "../util/sorting";
import SelectorLabel from "./SelectorLabel";

export default function PlateSelector({
  dataset,
  plate,
  plates,
  onSelectPlate,
  autoSelect,
  showLabel,
}: {
  plate: string | null;
  plates?: string[];
  dataset: DatasetId;
  onSelectPlate: (plate: string) => void;
  autoSelect?: boolean;
  showLabel?: boolean;
}) {
  const fetchPlates = useAcquisitions({ dataset, skip: plates !== undefined });
  const acquisitions = (plates ? Success.of(plates) : fetchPlates)?.map(
    (results) => results.sort(defaultComparator),
  );
  const hasSelected = useRef(false);
  useEffect(() => {
    if (
      autoSelect &&
      !plate &&
      !hasSelected.current &&
      acquisitions?.successful
    ) {
      hasSelected.current = true;
      onSelectPlate(acquisitions.unwrap()[0]);
    }
    // Don't require onSelectPlate to be a stable callback, and hasSelected isn't
    // a real dependency here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSelect, plate, acquisitions]);
  return (
    <div className={"tw-flex tw-flex-row tw-items-center"}>
      {(showLabel || showLabel === undefined) && (
        <SelectorLabel extraClasses="tw-w-16">Plate</SelectorLabel>
      )}
      <Select
        name="plateSelector"
        className={"tw-flex-1"}
        disabled={acquisitions == null}
        placeholder="Select"
        items={acquisitions?.successful ? acquisitions.value : []}
        value={plate ?? undefined}
        onChange={(plate: string) => {
          trackEvent({ id: "Select plate", plate });
          hasSelected.current = true;
          onSelectPlate(plate);
        }}
        searchable
      />
    </div>
  );
}
