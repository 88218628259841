import cx from "classnames";
import * as d3 from "d3";
import lodashMax from "lodash.max";
import { useCallback, useMemo } from "react";
import { CHART_HEIGHT_PX } from "src/PhenoFinder/constants";
import { ClusterFeatureData } from "src/PhenoFinder/types";
import invariant from "tiny-invariant";
import { FeatureBoxPlots } from "./FeatureBoxPlots";
import { useChartDimensions } from "./hooks";

export function SingleFeatureChart({
  className,
  data,
  clusterName,
  shouldHighlightMeaningfulMetadata,
  numTotalRows,
  selectedFeature,
  displayName,
}: {
  className?: string;
  data: ClusterFeatureData[];
  clusterName: string;
  shouldHighlightMeaningfulMetadata: boolean;
  numTotalRows: number;
  selectedFeature: string;
  displayName: string;
}) {
  // We need all the data to calculate the max value, but we're only going to render one;
  // if the max computation becomes expensive, we could compute it once and cache in state
  const maxValue = useMemo(() => lodashMax(data.map((row) => row.max)), [data]);
  invariant(maxValue);

  const singleChartData = useMemo(
    () => data.filter((data) => data.clusterName === clusterName),
    [data, clusterName],
  );

  const [setContainerRef, chartDimensions] = useChartDimensions();
  const { width, boundedWidth, height, boundedHeight, margin } =
    chartDimensions;

  const xScale = useMemo(
    () => d3.scaleLinear().domain([0, maxValue]).range([0, boundedWidth]),
    [boundedWidth, maxValue],
  );

  // Since we only ever show one chart, we can use a constant y scale
  const yScale = useCallback(() => 0, []);

  const axis = useMemo(() => {
    return (
      <>
        {xScale.ticks(5).map((value, i) => (
          <g key={i}>
            <text
              className="tw-fill-slate-500"
              x={xScale(value)}
              y={CHART_HEIGHT_PX + 16}
              textAnchor={i === 0 ? "left" : i === 5 ? "end" : "middle"}
              alignmentBaseline="central"
              fontSize={14}
            >
              {value}
            </text>
          </g>
        ))}

        <g>
          <text
            className="tw-fill-slate-700 tw-font-bold"
            x={boundedWidth / 2 + margin.left}
            y={CHART_HEIGHT_PX + 42}
            textAnchor="middle"
            alignmentBaseline="central"
            fontSize={14}
          >
            {selectedFeature} in {displayName}
          </text>
        </g>
      </>
    );
  }, [xScale, boundedWidth, margin, selectedFeature, displayName]);

  return (
    <div
      className={cx(className, "tw-relative tw-w-full")}
      style={{ height: CHART_HEIGHT_PX * 2.5 }}
      ref={setContainerRef}
    >
      <div className="tw-absolute tw-w-full tw-h-full">
        <div
          className="tw-relative"
          style={{
            width: boundedWidth,
            height: boundedHeight,
            transform: `translateX(${margin.left}px) translateY(${margin.top}px)`,
          }}
        >
          <FeatureBoxPlots
            data={singleChartData}
            xScale={xScale}
            yScale={yScale}
            numTotalRows={numTotalRows}
            shouldHighlightMeaningfulMetadata={
              shouldHighlightMeaningfulMetadata
            }
          />
        </div>
      </div>

      <svg width={width} height={height}>
        <g
          width={boundedWidth}
          height={boundedHeight}
          transform={`translate(${[margin.left, margin.top].join(",")})`}
        >
          {axis}
        </g>
      </svg>
    </div>
  );
}
