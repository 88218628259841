/**
 * Component to render a dashboard listing all experiments the user has access to.
 */
import cx from "classnames";
import { ReactNode, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Link } from "react-router-dom";
import { FullScreenLoader } from "src/Common/FullScreenLoader";
import { DatasetCover } from "src/DatasetCover";
import { useActiveWorkspace } from "src/Workspace/hooks";
import { useWorkspaces } from "src/hooks/workspace";
import { useDefaultWorkspace } from "src/util/hooks";
import { toTypedString } from "src/util/typed-string";
import { experimentDefaultUrl } from "src/util/urls";
import { useAppNavStyle, useTabState } from "../AppChrome/hooks";
import { FullScreenContainer } from "../Common/FullScreenContainer";
import { useInsightsContext } from "../Insights/context";
import { useFetchInsights } from "../Insights/hooks";
import { InsightsEntry } from "../Insights/types";
import { useFeatureFlag } from "../Workspace/feature-flags";
import { useDataset, useDatasets } from "../hooks/datasets";
import CardBg1 from "../img/card-bg-1.svg?url";
import CardBg2 from "../img/card-bg-2.svg?url";
import CardBg3 from "../img/card-bg-3.svg?url";
import CardBg4 from "../img/card-bg-4.svg?url";
import CardBg5 from "../img/card-bg-5.svg?url";
import { SearchControlArea } from "../search/SearchControlArea";
import { DatasetId, DatasetListing, WorkspaceId } from "../types";
import { useAreInternalFeaturesEnabled } from "../util/users";
import { ExperimentOverflowMenu } from "./ExperimentOverflowMenu";

const CARD_BGS = [CardBg1, CardBg2, CardBg3, CardBg4, CardBg5];

// TODO(trisorus): Theoretically we could add a special "placeholder" dataset type that can
// provide this functionality
function getHackyDatasetPlaceholders(
  workspaceId: WorkspaceId,
): DatasetListing[] {
  if (workspaceId === "jump-cp") {
    return [
      {
        id: toTypedString<DatasetId>("jump_cp-u2os-compounds"),
        type: "if_bf_plates",
        name: "JUMP Target Compounds",
        description:
          "JUMP Target Compounds dataset. U2OS cells exposed a long list of compounds with diverse targets.",
        status: "live",
      },
    ];
  } else if (workspaceId === "public-demo") {
    return [
      {
        id: toTypedString<DatasetId>("placeholder-1"),
        type: "if_bf_plates",
        name: "Placeholder dataset 1",
        description:
          "This is an example of another dataset that could be in your workspace.",
        status: "live",
      },
      {
        id: toTypedString<DatasetId>("placeholder-2"),
        type: "if_bf_plates",
        name: "Placeholder dataset 2",
        description:
          "This is yet another dataset that could be in your workspace.",
        status: "live",
      },
    ];
  } else {
    return [];
  }
}

export default function ExperimentsDashboard() {
  const areInternalFeaturesEnabled = useAreInternalFeaturesEnabled();

  const datasets = useDatasets({
    includePrerelease: areInternalFeaturesEnabled,
  });
  const [, setTabs] = useTabState();
  const [, setAppNavStyle] = useAppNavStyle();

  const [defaultWorkspaceId, setDefaultWorkspaceId] = useDefaultWorkspace();

  const workspace = useActiveWorkspace();
  const isSearchAvailable = useFeatureFlag("search-enabled");
  const showExperimentOverflowMenu = !useFeatureFlag(
    "disable-experiment-renaming",
  );

  const showInsightsIfAvailable = areInternalFeaturesEnabled;
  const insights = useFetchInsights(
    showInsightsIfAvailable ? workspace.id : null,
  );
  const { activeInsight } = useInsightsContext();
  const visibleInsights = insights?.successful
    ? insights.value.filter(
        (insight) => !activeInsight || insight !== activeInsight,
      )
    : [];

  // HACK(benkomalo): focus state is sort of owned by the child search component,
  // but it gets passed up here to us so we can show/hide sibling elements.
  const [searchAreaFocused, setSearchAreaFocused] = useState<boolean>(false);

  useEffect(() => {
    setAppNavStyle("flat");
  }, [setAppNavStyle]);

  useEffect(() => {
    setTabs([]);
  }, [setTabs]);

  const workspaces = useWorkspaces();

  if (!datasets) {
    return <FullScreenLoader />;
  }

  if (!datasets.successful) {
    return (
      <FullScreenContainer center>
        <div className={"tw-text-xl"}>
          Unable to retrieve experiments right now:
        </div>
        <div className={"tw-text-xl"}>{datasets.error.toString()}</div>
      </FullScreenContainer>
    );
  }

  const hackyPlaceholders = getHackyDatasetPlaceholders(workspace.id);
  const cardCount = datasets.value.length + hackyPlaceholders.length;

  return (
    <div className="tw-min-h-[calc(100vh-theme(spacing.global-nav-height))] tw-flex tw-flex-col">
      <div
        className={
          "tw-flex tw-items-center tw-justify-center tw-pt-xl tw-px-xl sm:tw-px-sm"
        }
      >
        {workspace.logo_url && (
          <img src={workspace.logo_url} width={140} height={140} />
        )}
        <h1 className={"tw-text-center tw-text-4xl tw-font-medium tw-mb-sm"}>
          {workspace.name}
        </h1>
      </div>
      {isSearchAvailable && (
        // The search area at the top is a bit funky; there is an outer container
        // encasing a search box that has padding and a negative margin. This outer
        // container is invisible in the dormant state, but appears with a border
        // when search is being interacted with, which is why we use padding/negative
        // margin – we don't want the rest of the page to move when we make the
        // container visible.
        // Additionally: we set a specific height and have the contents "bleed out"
        // so that the results pop down don't push the layout of the contents of the
        // page below.
        <div className={"tw-h-[78px] tw-overflow-visible tw-my-md"}>
          <SearchControlArea
            workspaceId={workspace.id}
            onSearchAreaFocused={setSearchAreaFocused}
            renderMode={"inline"}
          />
        </div>
      )}
      {visibleInsights.length > 0 && (
        <div className={"tw-bg-gray-100 tw-px-xl tw-py-lg tw-relative"}>
          <div className={"tw-text-xl tw-mb-md"}>Guided Tours</div>

          {visibleInsights.map((insight) => {
            return (
              <InsightCard
                key={insight.key}
                insight={insight}
                datasets={datasets.value}
              />
            );
          })}

          {searchAreaFocused && (
            <div
              className={cx("tw-absolute tw-inset-0", "tw-bg-[#ffffff66]")}
            ></div>
          )}
        </div>
      )}

      <div className={cx("tw-relative tw-mt-8 tw-px-xl")}>
        {insights?.successful && insights.value.length > 0 && (
          <div className={"tw-text-xl tw-my-lg"}>Experiments</div>
        )}
        <div
          className={cx(
            cardCount >= 1 && "tw-grid tw-grid-flow-row",
            cardCount === 2 && "tw-grid-cols-2 tw-gap-4",
            cardCount >= 3 && "tw-grid-cols-3 tw-gap-4",
            "sm:tw-grid-cols-1",
          )}
        >
          {datasets.value.map((listing, i) => {
            const { id, name, description } = listing;
            return (
              <ExperimentCard
                key={id}
                dataset={listing}
                url={experimentDefaultUrl(workspace.id, id)}
                index={i}
                style={cardCount === 1 ? "large" : "normal"}
                menu={
                  showExperimentOverflowMenu && (
                    <ExperimentOverflowMenu
                      className="tw-px-md tw-pt-[40px] tw-pb-md tw-text-slate-500"
                      {...listing}
                    />
                  )
                }
              >
                <div
                  className={cx(
                    cardCount === 1 ? "tw-text-2xl" : "tw-text-xl",
                    "tw-text-slate-900",
                  )}
                >
                  {name}
                </div>

                <div className={"tw-mt-md tw-text-slate-600"}>
                  {description}
                </div>
              </ExperimentCard>
            );
          })}
          {getHackyDatasetPlaceholders(workspace.id).map((listing, i) => {
            const { id, name, description } = listing;
            return (
              <ExperimentCard
                key={id}
                dataset={listing}
                index={i + datasets.value.length}
                style={cardCount === 1 ? "large" : "normal"}
                fade
              >
                <div className={"tw-flex-1 tw-flex tw-flex-col"}>
                  <div className={"tw-text-slate-500 tw-text-lg"}>{name}</div>
                  <div className={"tw-text-slate-500 tw-mt-4"}>
                    {description}
                  </div>
                  <div className={"tw-flex-1"} />
                  <div
                    className={
                      "tw-text-purple-500 tw-mt-4 tw-text-right tw-text-sm"
                    }
                  >
                    Coming soon
                  </div>
                </div>
              </ExperimentCard>
            );
          })}
        </div>
      </div>
      {workspaces?.successful && workspaces.value.length > 1 && (
        <div className="tw-flex-1 tw-px-xl tw-py-md tw-flex tw-flex-row tw-justify-center tw-items-end tw-text-slate-500 tw-text-sm">
          {workspace.id === defaultWorkspaceId ? (
            "This is your default workspace"
          ) : (
            <button onClick={() => setDefaultWorkspaceId(workspace.id)}>
              Make this your default workspace
            </button>
          )}
        </div>
      )}

      {searchAreaFocused && (
        <div
          className={cx("tw-absolute tw-inset-0", "tw-bg-[#ffffffaa]")}
        ></div>
      )}
    </div>
  );
}

function ExperimentCard({
  dataset,
  index,
  style,
  fade,
  children,
  url,
  menu,
}: {
  dataset: DatasetListing;
  index: number;
  style: "large" | "normal";
  fade?: boolean;
  children: ReactNode;
  url?: string;
  menu?: ReactNode;
}) {
  const background = CARD_BGS[index % CARD_BGS.length];

  const contents = (
    <>
      {dataset.status === "prerelease" ? (
        <div
          className={cx(
            "tw-flex tw-justify-center tw-items-center tw-h-md",
            "tw-bg-yellow-warning",
            "tw-text-xs tw-uppercase tw-font-semibold tw-tracking-wide",
          )}
        >
          Pre-release
        </div>
      ) : (
        <div className="tw-h-md tw-bg-white" />
      )}

      <div
        className={cx(
          "tw-px-xl tw-pb-xl tw-pt-md tw-bg-white",
          style === "large" && "tw-w-[480px]",
          style === "normal" && "tw-flex-1",
        )}
      >
        {children}
      </div>

      <div
        className={cx(
          "tw-bg-cover tw-bg-no-repeat tw-overflow-hidden",
          style === "large" && "tw-flex-1 tw-max-h-[380px]",
          style === "normal" && "tw-h-[160px] sm:tw-h-[48px]",
          fade && "tw-opacity-50",
        )}
        style={{
          backgroundImage: `url(${background})`,
        }}
      >
        <ErrorBoundary fallback={<div></div>}>
          <DatasetCover
            className="tw-w-full tw-h-full"
            dataset={dataset}
            index={index}
            hoverEffect="zoom"
          />
        </ErrorBoundary>
      </div>
    </>
  );

  return (
    <div className="tw-relative tw-group">
      {url ? (
        <Link
          className={cx(
            "tw-h-full",
            "tw-border tw-rounded-lg tw-overflow-hidden tw-no-underline tw-col-auto hover:tw-border-purple-500",
            fade ? "tw-shadow" : "tw-shadow-lg",
            style == "large"
              ? "tw-flex tw-min-h-[380px]"
              : "tw-flex tw-flex-col",
          )}
          to={url}
        >
          {contents}
        </Link>
      ) : (
        <div
          className={cx(
            "tw-h-full",
            "tw-border tw-rounded-lg tw-overflow-hidden tw-col-auto",
            fade ? "tw-shadow" : "tw-shadow-lg",
            style == "large"
              ? "tw-flex tw-min-h-[380px]"
              : "tw-flex tw-flex-col",
          )}
        >
          {contents}
        </div>
      )}

      {menu && (
        <div className="tw-absolute tw-top-0 tw-right-0 tw-z-10">{menu}</div>
      )}
    </div>
  );
}

function InsightCard({
  insight,
  datasets,
}: {
  insight: InsightsEntry;
  datasets: DatasetListing[];
}) {
  const { setActiveInsight } = useInsightsContext();
  const dataset =
    datasets.find(({ id }) => id === insight.dataset)?.id ?? insight.dataset;
  const datasetInfo = useDataset({ dataset });
  const datasetName = datasetInfo?.successful
    ? datasetInfo.value?.name ?? dataset
    : dataset;
  const heroImageUrl = insight.snippetImage;
  return (
    <button
      className={cx(
        "tw-p-lg tw-mb-sm tw-text-left tw-w-full",
        "tw-border hover:tw-border-purple tw-rounded-lg tw-shadow tw-bg-white",
        "tw-flex sm:tw-flex-col",
      )}
      onClick={() => setActiveInsight(insight.key)}
    >
      <div
        className={
          "tw-flex-[2] tw-mr-lg sm:tw-flex-none sm:tw-mr-0 sm:tw-mb-md"
        }
      >
        <div className={"tw-text-lg sm:tw-text-base"}>{insight.title}</div>
        <div className={"tw-text-sm tw-text-slate-500 tw-mb-md"}>
          {datasetName}
        </div>
        <div className={"tw-text-slate-500"}>{insight.snippet}</div>
      </div>
      <div className={"tw-flex-[1] sm:tw-flex-none"}>
        {heroImageUrl && <img src={heroImageUrl} className={"tw-w-full"} />}
      </div>
    </button>
  );
}
