import { useState } from "react";
import { CheckCircle } from "react-feather";
import { DatasetId, WorkspaceId } from "src/types";
import { Caption, Subtitle } from "../../../../../workspaces/ui/src/typography";
import { DeprecatedButton } from "../../Common/DeprecatedButton";
import Loader from "../../Common/Loader";
import { useDialog } from "../../Common/useDialog";
import { useValidateImages } from "../hooks";
import { ImageValidationError } from "../types";
import { Block, Input } from "./helpers";

type ValidateImageResponse = {
  image_errors: ImageValidationError[];
};

export function ImageValidator({
  workspaceId,
  datasetId,
  className,
}: {
  workspaceId: WorkspaceId;
  datasetId: DatasetId;
  className?: string;
}) {
  const { DialogComponent, setOpenDialog } = useDialog();
  const [numberOfImages, setNumberOfImages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [imageErrors, setImageErrors] = useState<ImageValidationError[] | null>(
    null,
  );
  const [requestErrorMsg, setRequestErrorMsg] = useState<string | null>(null);

  const validateImages = useValidateImages(workspaceId, datasetId);

  const handleCheckImages = async () => {
    try {
      setIsLoading(true);
      setRequestErrorMsg(null);
      const response: ValidateImageResponse =
        await validateImages(numberOfImages);

      setImageErrors(response.image_errors);
    } catch (e) {
      console.error(e);
      const msg =
        e instanceof Error
          ? e.message
          : "Error validating images. See console for more details";
      setRequestErrorMsg(msg);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={className}>
      <DeprecatedButton variant="secondary" onClick={() => setOpenDialog(true)}>
        <div className="tw-flex tw-items-center">Check Images</div>
      </DeprecatedButton>
      <DialogComponent>
        <div className="tw-p-4">
          <Subtitle>Check Images</Subtitle>
          <Caption>
            Randomly sample image paths and check for corrupted tiffs.
          </Caption>
          <Block>
            {isLoading ? (
              <div>
                <div className="tw-text-sm tw-font-bold">
                  Sample Size: {numberOfImages}
                </div>
                <Loader />
              </div>
            ) : (
              <>
                <div className="tw-text-sm">Sample Size:</div>
                <Input
                  type="number"
                  value={numberOfImages}
                  onChange={(e) => setNumberOfImages(parseInt(e.target.value))}
                  min={1}
                  max={25}
                />
              </>
            )}
          </Block>
          {requestErrorMsg && (
            <div className="tw-text-red-error">{requestErrorMsg}</div>
          )}
          {imageErrors && !isLoading && !requestErrorMsg && (
            <Block>
              {imageErrors.length === 0 ? (
                <div className="tw-text-sm tw-flex tw-items-center">
                  <CheckCircle className="tw-text-green-success tw-mr-2" />
                  <div>No corrupt images found.</div>
                </div>
              ) : (
                <>
                  <div className="tw-text-sm tw-font-bold">
                    Found {imageErrors.length} corrupted images:
                  </div>
                  <ul className="tw-overflow-scroll tw-max-h-[300px] tw-max-w-[800px]">
                    {/* TODO(davidsharff): consider dropping the paths from the descriptions server-side */}
                    {imageErrors.map((error, index) => (
                      <li
                        key={index}
                        className="tw-text-xs tw-mb-2 tw-whitespace-nowrap"
                      >
                        <div>{error.path}</div>
                        <div className="tw-ml-2 tw-mt-2 tw-text-red-error">
                          {error.description}
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </Block>
          )}
          <Block>
            <div className="tw-flex tw-justify-end ">
              <DeprecatedButton
                variant="primary"
                onClick={handleCheckImages}
                disabled={isLoading}
                className="tw-mr-2"
              >
                Check
              </DeprecatedButton>
              <DeprecatedButton
                variant="secondary"
                onClick={() => setOpenDialog(false)}
                disabled={isLoading}
              >
                Close
              </DeprecatedButton>
            </div>
          </Block>
        </div>
      </DialogComponent>
    </div>
  );
}
