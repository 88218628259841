import cx from "classnames";
import { useCallback, useMemo } from "react";
import { Plus } from "react-feather";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "src/Common/Button";
import { DatasetId } from "src/types";
import { Subtitle, Title } from "@spring/ui/typography";
import { FullScreenContainer } from "../Common/FullScreenContainer";
import Spinner from "../Common/Spinner";
import {
  SelectedEmbeddingInfo,
  SelectedMeasurementInfo,
} from "../FeatureSelector/CollapsedFeatureSelectorInfo";
import {
  groupFeaturesByPrefix,
  useFeatureSetsGrouped,
} from "../FeatureSelector/utils";
import {
  ArgoJob,
  getArgoJobFromResponse,
  useArgoJobStatusByType,
} from "../hooks/argo";
import ItemMenu from "./ItemMenu";
import { useSupervisedLearningContext } from "./context";
import { SavedModelListing } from "./types";

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export enum StatusColor {
  green = "tw-text-green-500",
  red = "tw-text-red-500",
  yellow = "tw-text-yellow-500",
  gray = "tw-text-gray-500",
}

export default function LandingPage({ dataset }: { dataset: DatasetId }) {
  const history = useHistory();
  const { pathname } = useLocation();
  const { models } = useSupervisedLearningContext();
  const maybeArgoStatus = useArgoJobStatusByType({
    dataset,
    argoJobType: "ssc_inference",
  });
  const getArgoJob = useCallback(
    (id: string): ArgoJob => getArgoJobFromResponse(id, maybeArgoStatus),
    [maybeArgoStatus],
  );

  const allFeatures = useFeatureSetsGrouped(dataset);
  const allEmbeddingPrefixes = useMemo(() => {
    if (!allFeatures || !allFeatures.successful) {
      return [];
    }
    return Object.keys(groupFeaturesByPrefix(allFeatures.value["embedding"]));
  }, [allFeatures]);

  /** Table header. */
  const Header = useCallback(
    ({ children }) => (
      <th className={"tw-font-normal tw-pb-sm tw-px-md"}>{children}</th>
    ),
    [],
  );

  /** Table cell. */
  const Cell = useCallback(
    ({ children }) => <td className={"tw-p-md tw-relative"}>{children}</td>,
    [],
  );

  return (
    <FullScreenContainer>
      <div className="tw-flex tw-p-lg tw-justify-between tw-items-center">
        <div className="tw-flex tw-flex-col">
          <Title>Supervised Learning</Title>
          <Subtitle className={"tw-text-gray-500"}>
            Build custom classifiers based on well labels
          </Subtitle>
        </div>
        <Button
          icon={Plus}
          variant={"primary"}
          className={"tw-flex"}
          onClick={() => {
            history.push(`${pathname}/edit`);
          }}
        >
          New Model
        </Button>
      </div>

      <div
        className={
          "tw-bg-gray-100 tw-p-lg tw-flex-1 tw-flex tw-justify-center tw-items-start"
        }
      >
        {models.length === 0 && (
          <div
            className={
              "tw-w-full tw-h-[400px] tw-bg-white tw-flex tw-items-center tw-justify-center tw-flex-col"
            }
          >
            <div className={"tw-text-xl tw-text-slate-500"}>
              You don't have any models for this dataset yet.
            </div>
            <Button
              variant={"primary"}
              className={"tw-mt-8"}
              onClick={() => {
                history.push(`${pathname}/edit`);
              }}
            >
              Click here to get started
            </Button>
          </div>
        )}
        {models.length > 0 && (
          <table className="tw-w-full tw-text-left">
            <thead className="tw-text-gray-500 tw-uppercase tw-text-sm">
              <tr>
                <Header>Name</Header>
                <Header>Classes</Header>
                <Header>Features</Header>
                <Header>Status</Header>
              </tr>
            </thead>
            <tbody className={"tw-bg-white"}>
              {models.map((model) => (
                <tr
                  key={model.id}
                  className={cx(
                    "tw-border-b tw-border-gray-300",
                    "hover:tw-bg-gray-50",
                  )}
                  role={"button"}
                  onClick={() => {
                    history.push(
                      `${pathname}/edit/${encodeURIComponent(model.id)}`,
                    );
                  }}
                >
                  <Cell>{model.name}</Cell>
                  <Cell>
                    <div className={"tw-max-w-[420px] tw-truncate"}>
                      {model.config.data?.classes
                        .map(({ name }) => name)
                        .join(", ")}
                    </div>
                  </Cell>
                  <Cell>
                    <>
                      {model.config.featureInputs?.map((selection) => {
                        switch (selection.type) {
                          case "embedding":
                            return (
                              <SelectedEmbeddingInfo
                                key={selection.names.join("-")}
                                selection={selection}
                                allEmbeddingPrefixes={allEmbeddingPrefixes}
                              />
                            );
                          case "numerical":
                          case "prediction":
                            return (
                              <SelectedMeasurementInfo
                                key={selection.name}
                                selection={selection}
                              />
                            );
                        }
                      })}
                    </>
                  </Cell>
                  <Cell>
                    <StatusColumn
                      model={model}
                      argoJob={getArgoJob(model.id)}
                    />
                  </Cell>
                  <Cell>
                    <ItemMenu model={model} />
                  </Cell>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </FullScreenContainer>
  );
}

function StatusColumn({
  model,
  argoJob,
}: {
  model: SavedModelListing;
  argoJob: ArgoJob;
}) {
  switch (model.status) {
    case "incomplete":
      return MessageWithColor("Incomplete", StatusColor.gray);
    case "pending":
      return MessageWithColor("Training", StatusColor.green);
    case "trained": {
      switch (model.result.status) {
        case "success":
          return MessageWithColor("Trained", StatusColor.green);
        case "failure":
          return MessageWithColor("Training Error", StatusColor.red);
      }
    }
    case "applied": {
      return <ApplyStatus argoJob={argoJob} />;
    }
  }
}

function ApplyStatus({ argoJob }: { argoJob: ArgoJob }) {
  const applyStatusInfo = useMemo(():
    | { message: string; color: StatusColor }
    | undefined => {
    switch (argoJob.state) {
      case "loading":
        return undefined;
      case "unknown":
        return { message: "N/A", color: StatusColor.gray };
      case "loaded": {
        switch (argoJob.payload.status) {
          case "Succeeded":
            return { message: "Applied", color: StatusColor.green };
          case "Failed":
            return { message: "Apply Error", color: StatusColor.red };
          case "Running":
            return { message: "Applying", color: StatusColor.yellow };
        }
      }
    }
  }, [argoJob]);
  return applyStatusInfo !== undefined ? (
    MessageWithColor(applyStatusInfo.message, applyStatusInfo.color)
  ) : (
    <Spinner />
  );
}

function MessageWithColor(message: string, color: StatusColor) {
  return (
    <span className="tw-inline-flex tw-flex-row tw-items-center tw-gap-sm">
      <span>{message}</span>
      <span className={cx("tw-text-xs", color)}>&#9679;</span>
    </span>
  );
}
