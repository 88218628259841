import type { ViewId } from "src/FeatureSetManagementPage/views";
import { WorkspaceId } from "src/types";

export enum AppLocationId {
  ExperimentsDashboard = "Experiments Dashboard",
  JoinPublicWorkspace = "Join Public Workspace",
  LoggedOut = "Logged Out",
  ExplorePlates = "Explore Plates",
  ExploreSlides = "Explore Slides",
  CompareImages = "Compare Images",
  QualityControlReport = "Quality Control Report",
  PhenoSorter = "PhenoSorter",
  PhenoFinder = "PhenoFinder",
  SupervisedLearner = "Supervised Learner",
  Measurements = "Measurements",
  AdminConsole = "Admin Console",
  Unknown = "Unknown",
}

interface BaseAppLocation<
  T extends AppLocationId = AppLocationId,
  S extends string = string,
> {
  id: T;
  subPage?: S;
  workspace?: WorkspaceId;
}

type MeasurementsLocation = BaseAppLocation<AppLocationId.Measurements, ViewId>;

interface UnknownLocation extends BaseAppLocation<AppLocationId.Unknown> {
  pathname: string;
  search: string;
}

export type AppLocation =
  | UnknownLocation
  | MeasurementsLocation
  | BaseAppLocation<
      Exclude<AppLocationId, AppLocationId.Unknown | AppLocationId.Measurements>
    >;
