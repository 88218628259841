import { ReactNode, useMemo, useState } from "react";
import { CheckCircle, Lock, XCircle } from "react-feather";
import { useRouteMatch } from "react-router-dom";
import { DatasetId, WorkspaceId } from "src/types";
import { toTitleCase } from "@spring/core/utils";
import { Button } from "@spring/ui/Button";
import { Caption, Subtitle, Title } from "@spring/ui/typography";
import { FullScreenContainer } from "../../Common/FullScreenContainer";
import Loader from "../../Common/Loader";
import { useDialog } from "../../Common/useDialog";
import { useToastContext } from "../../Toast/context";
import { uniquify } from "../../util/function-util";
import { DB, sql, useQueryAsRecords } from "../../util/sql";
import {
  useFetchClientMetadata,
  useFetchIngestionManifests,
  useFetchManifestPlates,
  useUpdateManifestPlates,
} from "../hooks";
import { PlatePathMap } from "../types";
import { DuckDBTable } from "./DuckDBTable";
import { Column, ColumnType, Row } from "./EditableTable";
import { Input, SplashScreenContainer } from "./helpers";

type RegexResult =
  | {
      kind: "match";
      result: string;
    }
  | {
      kind: "error";
      message: string;
    };

type RegexResults = {
  matchedPart: RegexResult;
  capturedPart: RegexResult;
  replacedPart: RegexResult;
};

type PlatePathMapProps = {
  manifestDB: DB;
  metadataPlates: string[];
  onSubmit: (platePaths: PlatePathMap[]) => void;
  onCancel: () => void;
};

export function AssignPlates() {
  const match = useRouteMatch<{
    workspaceId: WorkspaceId;
    datasetId: DatasetId;
  }>();
  const { workspaceId, datasetId } = match.params;
  const { setToast } = useToastContext();
  const [isUploading, setIsUploading] = useState(false);

  const { DialogComponent, setOpenDialog } = useDialog();

  const [metadataDBReq] = useFetchClientMetadata(workspaceId, datasetId, [
    "plate",
  ]);
  const [manifestDBReq, refetchManifest] = useFetchIngestionManifests(
    workspaceId,
    datasetId,
    ["path", "plate"],
  );
  const [plateDetailsDBReq, refetchPlateDetails] = useFetchManifestPlates(
    workspaceId,
    datasetId,
  );

  const updateManifestPlates = useUpdateManifestPlates(workspaceId, datasetId);

  const metadataPlatesQuery = useQueryAsRecords<{ plate: string }>(
    metadataDBReq?.successful ? metadataDBReq.value : null,
    sql`SELECT DISTINCT plate FROM client_metadata;`,
  );

  if (metadataDBReq && !metadataDBReq.successful) {
    if (metadataDBReq.error.message === "File not found") {
      return (
        <SplashScreenContainer classNames="tw-text-gray-500">
          <Lock className="tw-w-12 tw-h-12" />
          <Title className="tw-my-md">Metadata Required</Title>
          <Caption>
            Please upload the client metadata file before assigning plates.
          </Caption>
        </SplashScreenContainer>
      );
    }

    console.error(metadataDBReq.error);
    return <div>Oops. Something went wrong fetching client metadata.</div>;
  }

  if (
    !metadataDBReq ||
    !manifestDBReq ||
    !plateDetailsDBReq ||
    !metadataPlatesQuery ||
    isUploading
  ) {
    return (
      <FullScreenContainer center>
        <Loader />
      </FullScreenContainer>
    );
  }

  // TODO(davidsharff): this could probably be done more cleveryly with a helper fn (we may already have one)
  if (
    !manifestDBReq.successful ||
    !plateDetailsDBReq.successful ||
    !metadataPlatesQuery.successful
  ) {
    const fetchableError = !manifestDBReq.successful
      ? manifestDBReq.error
      : !plateDetailsDBReq.successful
        ? plateDetailsDBReq.error
        : !metadataPlatesQuery.successful
          ? metadataPlatesQuery.error
          : null;
    console.error(fetchableError);
    return <div>Oops. Something went wrong fetching data for the page.</div>;
  }

  const manifestDB = manifestDBReq.value;
  const plateDetailsDB = plateDetailsDBReq.value;
  const metadataPlates = metadataPlatesQuery.value.map(({ plate }) => plate);

  const handleSubmitPlatePathMaps = async (platePathMaps: PlatePathMap[]) => {
    try {
      setIsUploading(true);

      const responseMsg: string = await updateManifestPlates(platePathMaps);

      await Promise.all([refetchManifest(), refetchPlateDetails()]);

      if (responseMsg === "success") {
        setToast("upload-manifest", "Manifest sucessfully uploaded.");
      }

      return responseMsg;
    } catch (e) {
      setToast(
        "upload-manifest",
        "Failed to upload manifest. View console for more details.",
      );
      console.error(e);
    } finally {
      setOpenDialog(false);
      setIsUploading(false);
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-h-full tw-p-lg">
      <div className="tw-mb-lg">
        <div className="tw-flex tw-items-center tw-justify-between tw-mb-lg">
          <Title>Plate Details</Title>
          <Button
            className="tw-self-center"
            onClick={() => setOpenDialog(true)}
            variant="primary"
          >
            Assign Plates
          </Button>
        </div>
        <DuckDBTable
          db={plateDetailsDB}
          tableName="manifest_plates"
          createColumnsFromRow={plateDetailsColumnCreator}
          hideDownload
        />
      </div>
      <DialogComponent>
        <div className="tw-flex tw-flex-col tw-w-[calc(50vw)] tw-max-w-[calc(50vw)] tw-p-lg">
          <Title className="tw-mb-2">Map Paths to Plates</Title>
          <Caption className="">
            Map canonical plate names to image file paths using regular
            expressions.
          </Caption>
          <PlatePathMapper
            manifestDB={manifestDB}
            metadataPlates={metadataPlates}
            onSubmit={handleSubmitPlatePathMaps}
            onCancel={() => setOpenDialog(false)}
          />
        </div>
      </DialogComponent>
    </div>
  );
}

function plateDetailsColumnCreator(row: Row): Column[] {
  const priorityKeys = ["plate", "in_metadata", "is_single_palette"];

  const sortedKeys = Object.keys(row).sort((a, b) => {
    if (priorityKeys.includes(a) && priorityKeys.includes(b)) {
      return priorityKeys.indexOf(a) - priorityKeys.indexOf(b);
    }
    if (priorityKeys.includes(a)) {
      return -1;
    }
    if (priorityKeys.includes(b)) {
      return 1;
    }
    return 0;
  });

  return sortedKeys
    .filter((key) => key !== "__index_level_0__")
    .map((field) => {
      let type: ColumnType;
      switch (typeof row[field]) {
        case "bigint":
        case "number":
          type = ColumnType.Number;
          break;
        case "boolean":
          type = ColumnType.Checkbox;
          break;
        case "string":
          type = ColumnType.Text;
          break;
        default:
          throw new Error(`Unsupported data type for column: ${field}`);
      }

      return {
        headerContent: toTitleCase(field.split("_").join(" ")),
        field,
        type: type,
        align:
          typeof row[field] === "number" || field === "unique_field_counts"
            ? "right"
            : typeof row[field] === "boolean"
              ? "center"
              : "left",
        ...(typeof row[field] === "boolean"
          ? {
              cellFormatter: (value) =>
                value ? (
                  <CheckCircle className="tw-text-purple tw-h-5 tw-w-5 tw-mx-auto" />
                ) : (
                  <XCircle className="tw-text-red-500 tw-h-5 tw-w-5 tw-mx-auto" />
                ),
            }
          : {}),
      };
    });
}

function PlatePathMapper({
  manifestDB,
  metadataPlates,
  onSubmit,
  onCancel,
}: PlatePathMapProps) {
  const [regexInput, setRegexInput] = useState<string>("");
  const [replaceRegexInput, setReplaceRegexInput] = useState<string>("");
  const [regexResults, setRegexResults] = useState<RegexResults | null>(null);
  const [platePathMaps, setPlatePathMaps] = useState<PlatePathMap[]>([]);

  const pathnameQuery = useQueryAsRecords<{ path: string; plate: string }>(
    manifestDB,
    sql`SELECT path, plate FROM manifest;`,
  );

  const [pathnames, examplePath] = useMemo(() => {
    const rows = pathnameQuery?.successful ? pathnameQuery.value : [];
    const pathnames = rows.map(({ path }) => path);

    const examplePath =
      rows.find((r) => !metadataPlates.includes(r.plate))?.path ||
      pathnames[0] ||
      "";

    return [pathnames, examplePath];
  }, [metadataPlates, pathnameQuery]);

  const handleSubmit = () => {
    onSubmit(platePathMaps);
  };

  const updateRegexInfo = (regex: string, replaceRegex?: string) => {
    setPlatePathMaps([]);
    if (!regex) {
      setRegexResults(null);
      return;
    }

    try {
      const { match, capturedGroups, replacedPart } = applyRegexFindReplace(
        examplePath,
        regex,
        replaceRegex,
      );

      const matchedResult: RegexResult = match
        ? createMatchResult(match)
        : createErrorResult("No match");
      const capturedResult: RegexResult =
        capturedGroups.length > 0
          ? createMatchResult(capturedGroups.join(", "))
          : createErrorResult("No capture");
      const replacedResult: RegexResult = replaceRegex
        ? createMatchResult(replacedPart || "")
        : createErrorResult("<none>");

      setRegexResults({
        matchedPart: matchedResult,
        capturedPart: capturedResult,
        replacedPart: replacedResult,
      });
    } catch (error) {
      setRegexResults({
        matchedPart: createErrorResult("Invalid regex"),
        capturedPart: createErrorResult("Invalid regex"),
        replacedPart: createErrorResult(""),
      });
    }
  };

  const handleRegexInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newRegexInput = event.target.value;
    setRegexInput(newRegexInput);
    updateRegexInfo(newRegexInput, replaceRegexInput);
  };

  const handleReplaceRegexInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newReplaceRegexInput = event.target.value;
    setReplaceRegexInput(newReplaceRegexInput);
    updateRegexInfo(regexInput, newReplaceRegexInput);
  };

  const handleRegexSubmit = () => {
    const newPlatePathMaps: PlatePathMap[] = [];

    pathnames.forEach((path) => {
      const { replacedPart } = applyRegexFindReplace(
        path,
        regexInput,
        replaceRegexInput,
      );

      if (replacedPart) {
        newPlatePathMaps.push({
          plate: replacedPart,
          path: path,
        });
      }
    });

    setPlatePathMaps(newPlatePathMaps);
  };

  const getResultDisplay = (r?: RegexResult) => {
    if (!r) {
      return "";
    }
    return r.kind === "match" ? r.result : r.message;
  };

  const uniquePlates = useMemo(() => {
    const allPlates = platePathMaps.map((ppm) => ppm.plate);
    return uniquify(allPlates);
  }, [platePathMaps]);

  const getMatchedExampleSegments = () => {
    if (regexResults?.matchedPart.kind !== "match") {
      return [
        {
          text: examplePath,
          match: false,
        },
      ];
    }

    const matchText = regexResults.matchedPart.result;

    const unmatchedSegments = examplePath
      .split(matchText)
      .map((text) => ({ text, match: false }));

    return unmatchedSegments.flatMap((segment, i) => [
      segment,
      ...(i < unmatchedSegments.length - 1
        ? [{ text: matchText, match: true }]
        : []),
    ]);
  };

  const { capturedPart, replacedPart } = regexResults || {};

  return (
    <>
      <div className="tw-max-w-[200px] tw-flex tw-flex-col tw-space-y-md tw-mb-md">
        <Input
          type="text"
          value={regexInput}
          onChange={handleRegexInputChange}
          placeholder="Find regex"
          className="tw-w-full tw-mt-md"
        />
        <Input
          type="text"
          value={replaceRegexInput}
          onChange={handleReplaceRegexInputChange}
          placeholder="Replace regex"
          className="tw-w-full"
        />
        <div>
          <Button onClick={handleRegexSubmit}>Find Matches</Button>
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-space-y-md">
        <div>
          <Subtitle>Example Path:</Subtitle>{" "}
          {examplePath ? (
            <>
              {getMatchedExampleSegments().map((segment, i) => (
                <span
                  key={segment.text + i}
                  className={segment.match ? "tw-bg-purple-300" : ""}
                >
                  <span>{segment.text}</span>
                </span>
              ))}
            </>
          ) : (
            <Loader />
          )}
        </div>
        <DetailRow>
          <Subtitle>Captured Parts:</Subtitle>
          <div>{getResultDisplay(capturedPart)}</div>
        </DetailRow>
        <DetailRow>
          <Subtitle>Replace Result:</Subtitle>
          <div>{getResultDisplay(replacedPart)}</div>
        </DetailRow>
      </div>
      {uniquePlates.length > 0 && (
        <div className="tw-flex tw-flex-col tw-space-y-md tw-mt-md">
          <DetailRow>
            <Subtitle>Paths Matched:</Subtitle>
            <div>
              {`${platePathMaps.length.toLocaleString()} of ${pathnames.length.toLocaleString()}`}
            </div>
          </DetailRow>
          <DetailRow>
            <Subtitle>
              Plates Found ({uniquePlates.length.toLocaleString()}):
            </Subtitle>
          </DetailRow>
          <div className="tw-relative tw-overflow-auto tw-flex-1 tw-max-h-[250px] tw-w-[250px]">
            <table className="tw-w-full tw-text-sm tw-text-left tw-text-gray-500">
              <thead>
                <tr className="tw-text-xs tw-text-gray-700 tw-uppercase tw-bg-gray-200 tw-sticky tw-top-0">
                  <th className="tw-sticky tw-top-0 tw-px-4 tw-py-2 tw-bg-gray-200 tw-z-10">
                    Plate
                  </th>
                  <th className="tw-sticky tw-top-0 tw-px-4 tw-py-2 tw-w-[120px] tw-text-center tw-bg-gray-200 tw-z-10">
                    In Metadata?
                  </th>
                </tr>
              </thead>
              <tbody>
                {uniquePlates.map((match, index) => (
                  <tr key={index} className="tw-border-b tw-border-gray-300">
                    <td className="tw-px-4 tw-py-2">{match}</td>
                    <td className="tw-px-4 tw-py-2 tw-w-[120px] tw-flex tw-justify-center">
                      {metadataPlates.includes(match) ? (
                        <CheckCircle className="tw-text-purple tw-h-5 tw-w-5" />
                      ) : (
                        <XCircle className="tw-text-red-500 tw-h-5 tw-w-5" />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <div className="tw-flex tw-justify-end tw-space-x-md tw-mt-lg">
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={platePathMaps.length === 0}
        >
          Submit
        </Button>
      </div>
    </>
  );
}

function DetailRow({ children }: { children: ReactNode }) {
  return <div className="tw-flex tw-space-x-xs">{children}</div>;
}

function createMatchResult(result: string): RegexResult {
  return { kind: "match", result };
}

function createErrorResult(message: string): RegexResult {
  return { kind: "error", message };
}

function applyRegexFindReplace(
  inputStr: string,
  regexStr: string,
  replaceStr?: string,
): {
  match: string | null;
  capturedGroups: string[];
  replacedPart: string | null;
} {
  const regex = new RegExp(regexStr);
  const match = inputStr.match(regex);

  if (!match) {
    return { match: null, capturedGroups: [], replacedPart: null };
  }

  let replacedPart = replaceStr ? replaceStr : null;
  if (replacedPart) {
    for (let i = 1; i < match.length; i++) {
      replacedPart = replacedPart.replace(new RegExp(`\\$${i}`, "g"), match[i]);
    }
    replacedPart = replacedPart.toLowerCase();
  }

  return {
    match: match[0],
    capturedGroups: match.slice(1),
    replacedPart: replacedPart,
  };
}
