/**
 * Utilities to apply filter-by operations.
 */
export enum TextCondition {
  CONTAINS = "contains",
  DOES_NOT_CONTAIN = "does not contain",
  IS = "is",
  IS_NOT = "is not",
  IS_EMPTY = "is empty",
  IS_NOT_EMPTY = "is not empty",
}

export enum NumberCondition {
  EQUALS = "=",
  NOT_EQUALS = "≠",
  LESS_THAN = "<",
  GREATER_THAN = ">",
  LESS_THAN_OR_EQUALS = "≤",
  GREATER_THAN_OR_EQUALS = "≥",
  IS_EMPTY = "is empty",
  IS_NOT_EMPTY = "is not empty",
}

export enum BooleanCondition {
  IS = "is",
}

export enum SelectCondition {
  IS = "is",
  IS_NOT = "is not",
  IS_ANY_OF = "is any of",
  IS_NONE_OF = "is none of",
  IS_EMPTY = "is empty",
  IS_NOT_EMPTY = "is not empty",
}

export enum MultiSelectCondition {
  HAS_ANY_OF = "has any of",
  HAS_ALL_OF = "has all of",
  IS_EXACTLY = "is exactly",
  HAS_NONE_OF = "has none of",
  HAS_AT_LEAST_TWO_OF = "has at least two of",
  HAS_AT_LEAST_THREE_OF = "has at least three of",
  IS_EMPTY = "is empty",
  IS_NOT_EMPTY = "is not empty",
}

export enum Operator {
  AND = "And",
  OR = "Or",
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type Filter<T> =
  | {
      type: "text";
      key: (target: T) => string | null | undefined;
      queryText: string;
      condition: TextCondition;
    }
  | {
      type: "number";
      key: (target: T) => number | null | undefined;
      queryText: string;
      condition: NumberCondition;
    }
  | {
      type: "checkbox";
      key: (target: T) => boolean | null | undefined;
      queryText: boolean;
      condition: BooleanCondition;
    }
  | {
      type: "select";
      key: (target: T) => string | null | undefined;
      queryText: string[];
      condition: SelectCondition;
    }
  | {
      type: "multiSelect";
      key: (target: T) => string[] | null | undefined;
      queryText: string[];
      condition: MultiSelectCondition;
    };
