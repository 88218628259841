interface Props {
  animate: "always" | "hover" | "off";
  mode: "corner" | "fill";
  className?: string;
}

export function MagnifyingTwinkle({ animate, className, mode }: Props) {
  const animationClassName =
    animate === "always"
      ? "tw-animate-sparkle"
      : animate === "hover"
        ? "group-hover:tw-animate-sparkle"
        : undefined;
  return (
    <svg
      {...(mode === "corner"
        ? { viewBox: "0 0 49 52" }
        : { viewBox: "16 0 36 32", width: 20, height: 32 })}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.5 0C41.5 4.14214 38.1421 7.5 34 7.5H41.5L34 7.5C38.1409 7.5 41.4979 10.8558 41.5 14.9962L41.5 15C41.5 10.8588 44.8564 7.50146 48.9973 7.5L49 7.5C44.8588 7.5 41.5015 4.1436 41.5 0.00270261V0Z"
        fill="#FFAE10"
        className={animationClassName}
        style={{ animationDuration: "1.11s", transformOrigin: "38px 6px" }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37 14.5C37 18.6421 33.6421 22 29.5 22H37L29.5 22C33.6409 22 36.9979 25.3558 37 29.4962L37 29.5C37 25.3588 40.3564 22.0015 44.4973 22L44.5 22C40.3588 22 37.0015 18.6436 37 14.5027V14.5Z"
        fill="#FFAE10"
        className={animationClassName}
        style={{ animationDuration: "1.21s", transformOrigin: "36px 21px" }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5 2.5C27.5 8.29899 22.799 13 17 13C22.7649 13 27.4447 17.6459 27.4995 23.3978V23.5C27.4995 23.4759 27.4996 23.4518 27.4998 23.4277C27.4999 23.4503 27.5 23.4729 27.5 23.4955V23.3979C27.5548 17.6474 32.2322 13.0024 37.995 13H38C32.201 13 27.5 8.29899 27.5 2.5Z"
        fill="#FFAE10"
        className={animationClassName}
        style={{ animationDuration: "1.31s", transformOrigin: "29px 16px" }}
      />
      <path
        d="M16.0417 44.7083C22.485 44.7083 27.7083 39.485 27.7083 33.0417C27.7083 26.5983 22.485 21.375 16.0417 21.375C9.59834 21.375 4.375 26.5983 4.375 33.0417C4.375 39.485 9.59834 44.7083 16.0417 44.7083Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.625 47.625L24.2812 41.2812"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
