import { AsyncDuckDB } from "@duckdb/duckdb-wasm";
import { TypedColumn } from "../Control/FilterSelector/backend-types";
import { Operator } from "../Control/FilterSelector/operations/filter-by";
import { FilterSet } from "../Control/FilterSelector/types";
import { DEFAULT_NUM_CLUSTERS } from "./constants";
import { findLeafNodes, getTreeWithModifications } from "./treeUtils";
import {
  ClusterMetadata,
  ClusterVisualizationData,
  Clusters,
  DataNode,
  TreeModificationStep,
  VisualizationColumn,
} from "./types";
import {
  cleanUserInputString,
  consolidateDisplayNameModifications,
  getColorScaleForMetadata,
} from "./utils";

export type PhenoFinderState =
  | {
      loaded: false;
      fullTree: DataNode | null;
      totalNumCells: number | null;
      numClusters: number;
      modifications: TreeModificationStep[];
      newClusterNames: string[];
      shouldScrollToNewClusters: boolean;
      currentBaseTree: DataNode | null;
      currentTree: DataNode | null;
      defaultCropSize: number | null;
      clusters: null;
      selectedClusterName: null;
      sampleMetadataDB: AsyncDuckDB | null;
      featureDB: AsyncDuckDB | null;
      featureColumns: string[] | null;
      selectedVisualizationColumn: VisualizationColumn | null;
      filterColumns: TypedColumn[] | null;
      chartData: ClusterVisualizationData[] | null;
      metadataColorScale: d3.ScaleOrdinal<string, string, string> | null;
      maxTreeLevel: number | null;
      hoveredDataNode: DataNode | null;
      filterSet: FilterSet;
      description: string | null;
    }
  | {
      loaded: true;
      fullTree: DataNode;
      totalNumCells: number;
      numClusters: number;
      modifications: TreeModificationStep[];
      newClusterNames: string[];
      shouldScrollToNewClusters: boolean;
      currentBaseTree: DataNode;
      currentTree: DataNode;
      defaultCropSize: number;
      // These clusters contain x,y coordinates and are ordered per the dendrogram
      // We use them to draw the metadata charts in alignment with the dendrogram
      clusters: Clusters | null;
      selectedClusterName: string | null;
      sampleMetadataDB: AsyncDuckDB;
      featureDB: AsyncDuckDB | null;
      featureColumns: string[] | null;
      selectedVisualizationColumn: VisualizationColumn;
      filterColumns: TypedColumn[];
      chartData: ClusterVisualizationData[];
      metadataColorScale: d3.ScaleOrdinal<string, string, string>;
      maxTreeLevel: number;
      hoveredDataNode: DataNode | null;
      filterSet: FilterSet;
      description: string | null;
    };

export const initialPhenoFinderState: PhenoFinderState = {
  loaded: false,
  fullTree: null,
  totalNumCells: null,
  numClusters: DEFAULT_NUM_CLUSTERS,
  currentBaseTree: null,
  modifications: [],
  newClusterNames: [],
  shouldScrollToNewClusters: false,
  currentTree: null,
  clusters: null,
  selectedClusterName: null,
  sampleMetadataDB: null,
  featureDB: null,
  featureColumns: null,
  filterColumns: [],
  selectedVisualizationColumn: null,
  chartData: null,
  metadataColorScale: null,
  defaultCropSize: null,
  maxTreeLevel: null,
  hoveredDataNode: null,
  filterSet: {
    filters: [],
    operator: Operator.AND,
    ignoredFilters: [],
  },
  description: null,
};

export type PhenoFinderStateReducerAction =
  | {
      type: "loadInitialTree";
      fullTree: DataNode;
      currentBaseTree: DataNode;
    }
  | {
      type: "loadSampleMetadata";
      sampleMetadataDB: AsyncDuckDB;
      filterColumns: TypedColumn[];
    }
  | {
      type: "loadFeatures";
      featureDB: AsyncDuckDB;
      featureColumns: string[];
    }
  | {
      type: "loadFeaturesFailure";
    }
  | {
      type: "loadDefaultMetadataForSelectedColumn";
      chartData: ClusterMetadata[];
    }
  | {
      type: "loadDefaultCropSize";
      defaultCropSize: number;
    }
  | {
      type: "updateCurrentTree";
      modifications: TreeModificationStep[];
      newTree: DataNode;
      chartData: ClusterVisualizationData[];
      shouldScrollToNewClusters?: boolean;
    }
  | {
      type: "updateClusters";
      clusters: Clusters;
    }
  | {
      type: "updateClusterDisplayName";
      clusterName: string;
      displayName: string | undefined;
    }
  | {
      type: "applyDisplayNameModifications";
      modifications: TreeModificationStep[];
    }
  | {
      type: "selectCluster";
      clusterName: string;
    }
  | {
      type: "clearSelectedCluster";
    }
  | {
      type: "selectColumn";
      selectedColumn: VisualizationColumn;
      chartData: ClusterVisualizationData[];
    }
  | {
      type: "setHoveredDataNode";
      node: DataNode | null;
    }
  | {
      type: "changeFilter";
      filter: FilterSet;
      chartData: ClusterVisualizationData[];
    }
  | {
      type: "setDescription";
      description: string;
    };

function isStateFullyLoaded(state: PhenoFinderState) {
  return (
    state.fullTree !== null &&
    state.defaultCropSize !== null &&
    state.sampleMetadataDB !== null &&
    state.selectedVisualizationColumn !== null &&
    state.chartData !== null
  );
}

export function phenoFinderStateReducer(
  state: PhenoFinderState,
  action: PhenoFinderStateReducerAction,
): PhenoFinderState {
  switch (action.type) {
    case "loadInitialTree": {
      const leafNodes = findLeafNodes(action.fullTree);
      const totalNumCells = leafNodes.reduce((numCells, node) => {
        return numCells + node.cells.length;
      }, 0);
      const maxTreeLevel = leafNodes
        .map((node) => node.level)
        .reduce((a, b) => Math.max(a, b));

      const newState = {
        ...state,
        fullTree: action.fullTree,
        totalNumCells,
        currentBaseTree: action.currentBaseTree,
        // TODO: Do not load any modifications initially
        // This will change once we start persisting modifications
        currentTree: action.currentBaseTree,
        maxTreeLevel,
      };

      if (isStateFullyLoaded(newState)) {
        newState.loaded = true;
      }

      return newState;
    }

    case "loadSampleMetadata": {
      const newState = {
        ...state,
        sampleMetadataDB: action.sampleMetadataDB,
        filterColumns: action.filterColumns,
        selectedVisualizationColumn: {
          name: action.filterColumns[0].id,
          type: "metadata",
        } as VisualizationColumn,
      };

      if (isStateFullyLoaded(newState)) {
        newState.loaded = true;
      }

      return newState;
    }

    case "loadFeatures": {
      const newState = {
        ...state,
        featureDB: action.featureDB,
        featureColumns: action.featureColumns,
      };

      return newState;
    }
    case "loadFeaturesFailure": {
      const newState = {
        ...state,
        featureDB: null,
        featureColumns: [],
      };

      return newState;
    }
    case "loadDefaultMetadataForSelectedColumn": {
      const newState = {
        ...state,
        chartData: action.chartData,
        metadataColorScale: getColorScaleForMetadata(action.chartData),
      };

      if (isStateFullyLoaded(newState)) {
        newState.loaded = true;
      }

      return newState;
    }

    case "loadDefaultCropSize": {
      const newState = {
        ...state,
        defaultCropSize: action.defaultCropSize,
      };

      if (isStateFullyLoaded(newState)) {
        newState.loaded = true;
      }

      return newState;
    }

    case "updateCurrentTree": {
      if (!state.loaded) {
        return state;
      }

      const oldLeafNodeNames = new Set(
        findLeafNodes(state.currentTree).map((node) => node.name),
      );

      const leafNodes = findLeafNodes(action.newTree);
      const newLeafNodeNames = leafNodes
        .filter((node) => !oldLeafNodeNames.has(node.name))
        .map((node) => node.name);

      const newState = {
        ...state,
        modifications: [...action.modifications],
        newClusterNames: newLeafNodeNames,
        shouldScrollToNewClusters: action.shouldScrollToNewClusters ?? false,
        currentTree: action.newTree,
        selectedClusterName: null,
        numClusters: leafNodes.length,
        chartData: action.chartData,
      };
      if (state.selectedVisualizationColumn.type === "metadata") {
        newState.metadataColorScale = getColorScaleForMetadata(
          action.chartData as ClusterMetadata[],
        );
      }
      return newState;
    }

    case "updateClusters": {
      if (!state.loaded) {
        return state;
      }

      return {
        ...state,
        clusters: action.clusters,
      };
    }

    case "updateClusterDisplayName": {
      if (!state.loaded) {
        return state;
      }

      const newModification = {
        type: "changeDisplayName",
        nodeName: action.clusterName,
        displayName: action.displayName,
      } as TreeModificationStep;

      const newTree = getTreeWithModifications(
        { ...state.currentTree },
        state.fullTree,
        [newModification],
      );

      const newModifications = consolidateDisplayNameModifications([
        ...state.modifications,
        newModification,
      ]);

      return {
        ...state,
        currentTree: newTree,
        modifications: newModifications,
      };
    }

    case "applyDisplayNameModifications": {
      if (!state.loaded) {
        return state;
      }

      const newTree = getTreeWithModifications(
        { ...state.currentTree },
        state.fullTree,
        action.modifications,
      );

      const newModifications = consolidateDisplayNameModifications([
        ...state.modifications,
        ...action.modifications,
      ]);

      return {
        ...state,
        currentTree: newTree,
        modifications: newModifications,
      };
    }

    case "selectCluster": {
      if (!state.loaded) {
        return state;
      }

      return {
        ...state,
        selectedClusterName: action.clusterName,
      };
    }

    case "clearSelectedCluster": {
      if (!state.loaded) {
        return state;
      }

      return {
        ...state,
        selectedClusterName: null,
      };
    }

    case "selectColumn": {
      if (!state.loaded) {
        return state;
      }
      const newState = {
        ...state,
        selectedVisualizationColumn: action.selectedColumn,
        chartData: action.chartData,
      };
      if (action.selectedColumn.type === "metadata") {
        newState.metadataColorScale = getColorScaleForMetadata(
          action.chartData as ClusterMetadata[],
        );
      }
      return newState;
    }
    case "changeFilter": {
      if (!state.loaded) {
        return state;
      }
      const newState = {
        ...state,
        filterSet: action.filter,
        chartData: action.chartData,
      };
      if (state.selectedVisualizationColumn.type === "metadata") {
        newState.metadataColorScale = getColorScaleForMetadata(
          action.chartData as ClusterMetadata[],
        );
      }
      return newState;
    }
    case "setHoveredDataNode": {
      if (!state.loaded) {
        return state;
      }
      return {
        ...state,
        hoveredDataNode: action.node,
      };
    }
    case "setDescription": {
      const cleanedDescription = cleanUserInputString(action.description);
      return {
        ...state,
        description: cleanedDescription ?? null,
      };
    }
  }
}
