import cx from "classnames";
import { ReactNode, useRef, useState } from "react";
import { ArrowDown } from "react-feather";
import { generateId } from "../../util/function-util";

export enum ColumnType {
  Text = "text",
  Number = "number",
  Checkbox = "checkbox",
}

export type CellValue = string | number | boolean | null | undefined;

type Alignments = "left" | "center" | "right";
export type Column = {
  headerContent: ReactNode;
  field: string;
  isReadOnly?: boolean;
  type: ColumnType;
  cellFormatter?: (value: CellValue, field: string, row: Row) => ReactNode;
  align?: Alignments;
};

export type Row = {
  [key: string]: CellValue;
};

export type SortColumn = {
  field: string;
  direction: "ASC" | "DESC";
};

type TableProps = {
  rows: Row[];
  columns: Column[];
  onColumnSort?: (sortState: SortColumn | null) => void;
  onCommitChange?: (value: CellValue, field: string, row: Row) => void;
};
// TODO(davidsharff): make columns sticky
export function EditableTable({
  rows,
  columns,
  onColumnSort,
  onCommitChange,
}: TableProps) {
  const rowIds = useRef(new WeakMap()).current;

  // Note(davidsharff): I didn't want to rely on the row index as the key
  // nor require the caller to provide an id field.
  const getRowId = (row: Row) => {
    // New row: generate and return an id
    if (!rowIds.has(row)) {
      const newId = generateId("r");
      rowIds.set(row, newId);
      return newId;
    }
    return rowIds.get(row);
  };

  const [sortColumn, setSortColumn] = useState<SortColumn | null>(null);
  const canColumnSort = !!onColumnSort;

  const handleClickColumnHeader = (col: Column) => {
    // TODO(davidsharff): this is a little awkward since the comp is intended
    // to be used by a parent housing paginated DuckDB data. So we let the parent
    // sort but still track the state here for the UI.
    if (canColumnSort) {
      const newSortColumn: SortColumn | null =
        col.field === sortColumn?.field
          ? sortColumn.direction === "ASC"
            ? {
                field: col.field,
                direction: "DESC",
              }
            : null
          : {
              field: col.field,
              direction: "ASC",
            };

      setSortColumn(newSortColumn);
      onColumnSort(newSortColumn);
    }
  };

  return (
    <table>
      <thead>
        <tr>
          {columns.map((col) => (
            <th
              key={col.field}
              className={cx(
                "tw-min-w-[100px] tw-relative tw-pr-6 tw-truncate",
                {
                  "tw-bg-slate-100": col.isReadOnly,
                  "tw-cursor-pointer": canColumnSort,
                },
                getAlignClass(col.align),
              )}
              onClick={() => handleClickColumnHeader(col)}
            >
              {col.headerContent}
              {onColumnSort && (
                <ArrowDown
                  className={cx(
                    "tw-absolute tw-top-1 tw-right-0 tw-transform tw-transition-transform",
                    {
                      "tw-rotate-180":
                        sortColumn?.field === col.field &&
                        sortColumn.direction === "DESC",
                      "tw-opacity-0": sortColumn?.field !== col.field,
                    },
                    "tw-h-[20px] tw-w-[20px]",
                  )}
                />
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={getRowId(row)}>
            {columns.map((col) => {
              return (
                <Cell
                  key={col.field}
                  value={row[col.field]}
                  isReadOnly={col.isReadOnly}
                  cellFormatter={
                    col.cellFormatter
                      ? (val: CellValue) => {
                          if (!col.cellFormatter) {
                            throw new Error(
                              `No cell formatter for ${col.field}`,
                            );
                          }

                          return col.cellFormatter(val, col.field, row);
                        }
                      : undefined
                  }
                  onCommitChange={
                    onCommitChange
                      ? (val: CellValue) => onCommitChange(val, col.field, row)
                      : undefined
                  }
                  align={col.align || "left"}
                />
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function Cell({
  value,
  isReadOnly = false,
  cellFormatter,
  onCommitChange,
  align,
}: {
  value: CellValue;
  isReadOnly?: boolean;
  cellFormatter?: (value: CellValue) => ReactNode;
  onCommitChange?: (value: CellValue) => void;
  align: Alignments;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [pendingValue, setPendingValue] = useState<CellValue>(null);

  // TODO(davidsharff): consider waiting to close until the server responds with a success
  // or doing an optimisitic update that can rollback.
  const handleCommitChange = () => {
    if (pendingValue !== null && onCommitChange) {
      onCommitChange(pendingValue);
    }

    setPendingValue(null);
    setIsEditing(false);
  };

  const handleStartEdit = () => {
    if (!isReadOnly && !isEditing && onCommitChange) {
      setIsEditing(true);
      setPendingValue(value);
    }
  };

  const formatter = cellFormatter || defaultCellFormatter;

  return (
    <td
      className={cx(
        "tw-min-w-[100px] tw-pr-6",
        {
          "tw-bg-slate-100": isReadOnly,
        },
        getAlignClass(align),
      )}
      onDoubleClick={handleStartEdit}
    >
      {isEditing ? (
        // TODO(davidsharff): we'll eventually need different inputs per type
        <input
          type="text"
          value={`${pendingValue}`}
          onChange={(e) => setPendingValue(e.target.value)}
          onBlur={handleCommitChange}
          autoFocus
          className="tw-w-full tw-pr-2"
        />
      ) : (
        formatter(value)
      )}
    </td>
  );
}

// TODO(davidsharff): default should be based on col.type
function getAlignClass(align: Alignments | undefined) {
  return align === "center"
    ? "tw-text-center"
    : align === "right"
      ? "tw-text-right"
      : "tw-text-left";
}

function defaultCellFormatter(val: CellValue) {
  return typeof val === "bigint"
    ? Number(val)
    : typeof val === "boolean"
      ? val
        ? "Yes"
        : "No"
      : val;
}
