// TODO: move to global types
import { MetadataColumnValue } from "src/types";
import { Similarities } from "../FeatureSetManagementPage/MultiFeature/types";
import { SortBy } from "./Controls/SortBySelector/types";
import { RowPins, VariableSourceColumn } from "./Table/types";
import { Features, Fetchable, HitKindCounts, RowID } from "./backend/types";

export type NormalizedSimilarityScore = {
  id: string;
  name: string;
  value: number;
};

export enum ColorBy {
  ComparisonsRedGreen = "comparison-rd-gn",
  ComparisonsRedBlue = "comparison-rd-bu",
  Delta = "delta",
}

export enum ViewMode {
  Overview = "overview",
  Table = "table",
}

export enum Visualization {
  ScatterPlot = "scatterplot",
  Histogram = "histogram",
}

export type ComparisonsBlob = {
  comparisons: {
    [key: string]: { [column: string]: number | undefined } | undefined;
  };
  scales: {
    [column: string]: { minDiff: number; maxDiff: number } | undefined;
  };
};

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type ColumnVisibility = {
  [id: string]: boolean;
};

export interface ViewState {
  colorBy: ColorBy;
  columnVisibility: ColumnVisibility;
  // Text currently being searched for
  filterText: string;
  groupBy: string | null;
  // The id of the search result that the user has navigated to
  highlighted: string | null;
  mode: ViewMode;
  overviewColorBy: VariableSourceColumn | null;
  overviewColumns:
    | []
    | [VariableSourceColumn]
    | [VariableSourceColumn, VariableSourceColumn];
  pins: RowPins;
  subgroupBy: string | null;
  subgroupByReference: MetadataColumnValue | null;
  similarTo: MetadataColumnValue[];
  scoreIds: string[];
  sortBys: SortBy[];
  // Optionally temporarily override whatever we're sorting by with a different sort
  // configuration.  This is used when editing scores, to sort by the score being
  // edited
  sortBysOverride?: SortBy[];
  visualization: Visualization;
}

export type MultiSimilarityScoreResult =
  | {
      type: "success";
      results: Similarities;
    }
  | { type: "failure"; results: string };

export type MultiSimilarityScores =
  | {
      [referenceValue: string]: MultiSimilarityScoreResult;
    }
  | {
      [group: string]: {
        [referenceValue: string]: MultiSimilarityScoreResult;
      };
    };

export type FeatureScales = {
  [featureName: string]: {
    minDiff: number;
    maxDiff: number;
  };
};

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type FeatureData = {
  features: Fetchable<Features>;
  hitKindCounts: Fetchable<HitKindCounts>;
};

export type MetadataStats = {
  id: string;
  type: "text" | "number" | "checkbox";
  values: Map<unknown, number>;
  // If this value is defined, it means we have some values that aren't numeric, so if
  // we are in a context where we need to represent the value as a number (e.g. as a
  // position on the x or y axis) we need to look the number representation up
  valueAsNumber: Map<unknown, number> | undefined;
  interesting: boolean;
};
/**
 * A map from `RowGroup` ID to the `RowID` values of its `.children`.
 */
export type MergeMap = { [group: string]: RowID[] };
