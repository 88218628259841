import cx from "classnames";
import React, { useMemo } from "react";
import { classNameLookup } from "@spring/ui/util";

type ButtonVariant = "primary" | "secondary" | "transparent" | "danger";
export interface ButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  appearDisabled?: boolean;
  variant?: ButtonVariant;
  size?: "sm" | "md" | "lg";
  align?: "left" | "center";
  borderless?: boolean;
}

function variantClasses(
  variant: ButtonVariant,
  {
    borderless,
    disabled,
  }: {
    borderless: boolean;
    disabled: boolean;
  },
): string {
  if (borderless) {
    return borderlessVariantClasses(variant, { disabled });
  }

  switch (variant) {
    case "primary":
      if (disabled) {
        return cx("tw-bg-purple-400 tw-text-white");
      } else {
        return cx(
          "tw-bg-purple tw-text-white",
          "hover:tw-bg-purple-800",
          "active:tw-bg-purple-900",
        );
      }

    case "secondary":
      if (disabled) {
        return cx("tw-border tw-text-gray-200");
      } else {
        return cx(
          "tw-border tw-border-gray-200",
          "hover:tw-border-gray-300 hover:tw-shadow",
          "active:tw-border-gray-400",
        );
      }

    case "transparent":
      if (disabled) {
        return "tw-text-gray-200";
      } else {
        return cx("hover:tw-bg-gray-100/[0.5]");
      }

    case "danger":
      if (disabled) {
        return cx("tw-bg-red-300 tw-text-white");
      } else {
        return cx(
          "tw-bg-red-500 tw-text-white",
          "hover:tw-bg-red-800",
          "active:tw-bg-red-900",
        );
      }
  }
}

function borderlessVariantClasses(
  variant: ButtonVariant,
  {
    disabled,
  }: {
    disabled: boolean;
  },
): string {
  switch (variant) {
    case "primary":
      if (disabled) {
        return cx("tw-text-gray-300");
      } else {
        return cx(
          "tw-text-purple-800",
          "hover:tw-text-purple-600",
          "active:tw-text-purple-900",
        );
      }

    case "secondary":
      if (disabled) {
        return cx("tw-text-gray-300");
      } else {
        return cx("hover:tw-text-purple-800", "active:tw-text-purple-600");
      }

    case "transparent":
      if (disabled) {
        return cx("tw-text-gray-300");
      } else {
        return cx(
          "tw-text-gray-400",
          "hover:tw-text-gray-700",
          "active:tw-text-gray-500",
        );
      }

    case "danger":
      if (disabled) {
        return cx("tw-text-red-200");
      } else {
        return cx(
          "tw-text-red-600",
          "hover:tw-text-red-500",
          "active:tw-text-red-700",
        );
      }
  }
}

export function buttonClasses({
  size = "md",
  variant = "secondary",
  align = "center",
  borderless = false,
  disabled = false,
}: ButtonProps = {}): string {
  return cx(
    "tw-flex tw-items-center tw-transition-colors",
    align === "left" ? "tw-justify-start" : "tw-justify-center",
    { "tw-rounded tw-py-2 tw-px-4": !borderless },
    classNameLookup(size, {
      lg: "tw-text-lg",
      md: "tw-text-base",
      sm: "tw-text-sm",
    }),
    variantClasses(variant, { borderless, disabled }),
  );
}

/**
 * @deprecated We're in the process of phasing out this component in favor of the new
 * one that aligns with our UI library and supports click tracking out of the box.
 * Do not use this button for new projects! The new Button has been moved to src/Common/Button
 */
export const DeprecatedButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps
>(function Button(
  {
    align,
    appearDisabled,
    className,
    borderless,
    size,
    variant,
    ...rest
  }: ButtonProps,
  ref,
) {
  const variantStyle = useMemo((): string => {
    const disabled = rest.disabled === true || appearDisabled;
    return buttonClasses({ variant, size, align, borderless, disabled });
  }, [align, appearDisabled, borderless, rest.disabled, size, variant]);

  return (
    <button
      ref={ref}
      className={cx(variantStyle, className)}
      {...rest}
    ></button>
  );
});
