import cx from "classnames";
import isEqual from "lodash.isequal";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button } from "src/Common/Button";
import { DatasetId } from "src/types";
import Loader from "../Common/Loader";
import CollapsedFeatureSelectorInfo from "../FeatureSelector/CollapsedFeatureSelectorInfo";
import FeatureSelectorMenuContents from "../FeatureSelector/FeatureSelectorMenuContents";
import { Context } from "../FeatureSelector/context";
import { ColumnsLookupFn, FeatureSetSelection } from "../FeatureSelector/types";
import {
  FeatureSetsByType,
  areSelectionsEqual,
  groupFeaturesByPrefix,
  useFeatureSetsGrouped,
} from "../FeatureSelector/utils";
import { EditorStepCommonProps, ModelTrainingConfig } from "./types";

type SuggestedFeature = {
  title: string;
  description: string;
  underlying: FeatureSetSelection[];
};

export default function FeaturesConfigurator({
  dataset,
  config,
  setConfig,
  onReadyToAdvanceChanged,
}: EditorStepCommonProps & {
  dataset: DatasetId;
  config: Partial<ModelTrainingConfig>;
  setConfig: Dispatch<SetStateAction<Partial<ModelTrainingConfig>>>;
  // TODO(benkomalo): is there some eslint bug? if I don't repeat this here (even
  // though it's defined in EditorStepCommonProps) it errors out.
  onReadyToAdvanceChanged: (ready: boolean) => void;
}) {
  const allFeatures = useFeatureSetsGrouped(dataset);
  const features = useMemo(() => {
    if (!allFeatures?.successful) {
      return allFeatures;
    }

    return allFeatures.map((unfiltered) => {
      return {
        ...unfiltered,
        // Remove out pre-normalized embeddings. We pre-compute these as sort of a hack
        // for customers since we don't have a "self sever normalization" product,
        // but it's confusing to include them in here when we have normalization
        // support.
        embedding: unfiltered["embedding"].filter(
          (f) => !f.name.includes("Normalized"),
        ),
      };
    });
  }, [allFeatures]);

  const suggestions = useMemo<SuggestedFeature[]>(() => {
    if (!features?.successful) {
      return [];
    }

    const suggestions: SuggestedFeature[] = [];
    const unwrapped = features.value;

    // Embeddings
    const embeddingsByPrefix = groupFeaturesByPrefix(unwrapped["embedding"]);
    const embeddingsPrefixes = Object.keys(embeddingsByPrefix);
    if (embeddingsPrefixes.length > 0) {
      // Prefer the first unnormalized one, but otherwise just take any one.
      const suggestedPrefix =
        embeddingsPrefixes.find((prefix) => !prefix.startsWith("Normalized")) ??
        embeddingsPrefixes[0];
      suggestions.push({
        title: "Unbiased image embeddings",
        description:
          "AI-powered representation best suited for unbiased analyses.",
        underlying: [
          {
            type: "embedding",
            names: embeddingsByPrefix[suggestedPrefix].map((fs) => fs.name),
          },
        ],
      });
    }

    // CellProfiler-like features.
    unwrapped["numerical"]
      .filter(({ name }) => ["Cells", "Nuclei", "Cytoplasm"].includes(name))
      .forEach(({ name, featureSets }) => {
        suggestions.push({
          title: `${name} measurements`,
          description:
            "Traditional image-based measurements covering a spectrum of morphological and intensity features.",
          underlying: [
            {
              type: "numerical",
              // TODO(benkomalo): needs to resolve columns to get column names.
              // It's not strictly necessary since the backend will parse
              // includeAllColumns and the right things will happen, but the type
              // requires it, and the UI might want to render a preview of the columns
              // (which is why the type is defined that way).
              columns: [],
              includesAllColumns: true,
              name,
              featureSets,
            },
          ],
        });
      });

    return suggestions;
  }, [features]);

  const [topLevelSelection, setTopLevelSelection] = useState<
    SuggestedFeature | "custom" | null
  >(null);

  const selectedFeatures = useMemo(
    () => config.featureInputs ?? [],
    [config.featureInputs],
  );
  const setSelectedFeatures = useCallback(
    (selection: FeatureSetSelection[]) => {
      setConfig((config) => ({
        ...config,
        featureInputs: selection,
      }));
    },
    [setConfig],
  );

  const setSuggestionSelection = useCallback(
    (selection: SuggestedFeature | null) => {
      setSelectedFeatures(selection?.underlying ?? []);
      setTopLevelSelection(selection);
    },
    [setSelectedFeatures],
  );

  useEffect(() => {
    if (
      !topLevelSelection &&
      config.featureInputs &&
      config.featureInputs.length > 0
    ) {
      for (const suggestion of suggestions) {
        if (isEqual(suggestion.underlying, config.featureInputs)) {
          setTopLevelSelection(suggestion);
          return;
        }
      }

      setTopLevelSelection("custom");
    }
  }, [topLevelSelection, suggestions, config]);

  useEffect(() => {
    onReadyToAdvanceChanged(selectedFeatures.length > 0);
  }, [selectedFeatures, onReadyToAdvanceChanged]);

  const SuggestionButton = useCallback(
    ({
      name,
      selected,
      onSelect,
      children,
    }: {
      name: string;
      selected: boolean;
      onSelect: () => void;
      children: ReactNode;
    }) => {
      return (
        <Button
          name={name}
          className={cx(
            "tw-w-full tw-relative",
            selected && "tw-border-purple tw-border-2 tw-text-purple",
          )}
          onClick={onSelect}
        >
          <div
            aria-hidden
            className={cx(
              "tw-absolute tw-right-4 tw-top-[50%] -tw-mt-[15px]",
              "tw-w-[28px] tw-h-[28px]",
              "tw-flex tw-items-center tw-justify-center",
              "tw-bg-purple tw-rounded-full tw-text-white tw-font-bold",
              "tw-transition-opacity tw-duration-[50ms]",
              selected ? "tw-opacity-100" : "tw-opacity-0",
            )}
          >
            ✓
          </div>
          <div
            className={
              "tw-p-2 tw-flex tw-flex-col tw-items-start tw-text-left tw-pr-8"
            }
          >
            {children}
          </div>
        </Button>
      );
    },
    [],
  );

  if (!features) {
    return (
      <div
        className={
          "tw-flex tw-w-full tw-h-full tw-justify-center tw-items-center"
        }
      >
        <Loader />
      </div>
    );
  }

  if (!features.successful) {
    return (
      <div
        className={
          "tw-flex tw-w-full tw-h-full tw-justify-center tw-items-center"
        }
      >
        Oops. Something went wrong when loading your data. Please refresh and
        try again.
      </div>
    );
  }

  return (
    <div className={"tw-flex tw-border-b tw-h-full"}>
      {topLevelSelection !== "custom" && (
        <div className={"tw-w-[50%] tw-border-r tw-p-8"}>
          <div className={"tw-text-lg"}>
            What measurements do you want to start with?
            <div className={"tw-mt-4 tw-grid tw-grid-cols-1 tw-gap-4"}>
              {suggestions.map((suggestion) => {
                const { title, description } = suggestion;
                return (
                  <SuggestionButton
                    key={title}
                    name={title}
                    selected={isEqual(topLevelSelection, suggestion)}
                    onSelect={() => {
                      setSuggestionSelection(suggestion);
                    }}
                  >
                    <div>{title}</div>
                    <div className={"tw-text-sm tw-opacity-60"}>
                      {description}
                    </div>
                  </SuggestionButton>
                );
              })}
              <SuggestionButton
                name="Custom measurements"
                selected={false}
                onSelect={() => {
                  setSelectedFeatures([]);
                  setTopLevelSelection("custom");
                }}
              >
                <div className={"tw-flex-1 tw-self-start"}>
                  + Custom measurements
                </div>
              </SuggestionButton>
            </div>
          </div>
        </div>
      )}
      {topLevelSelection === "custom" && (
        <div className={"tw-p-8 tw-flex-1"}>
          <button
            className={"tw-text-sm tw-text-gray-500 tw-mb-md"}
            onClick={() => {
              setTopLevelSelection(null);
              setSelectedFeatures([]);
            }}
          >
            <span className={"tw-text-black tw-underline"}>Back</span> to all
            measurements
          </button>
          <InlineFeatureSelector
            dataset={dataset}
            features={features.value}
            selections={selectedFeatures}
            onChangeSelections={setSelectedFeatures}
          />
        </div>
      )}
    </div>
  );
}

function InlineFeatureSelector({
  dataset,
  features,
  selections,
  onChangeSelections,
}: {
  dataset: DatasetId;
  features: FeatureSetsByType;
  selections: FeatureSetSelection[];
  onChangeSelections: (
    selections: FeatureSetSelection[],
    getColumns: ColumnsLookupFn,
  ) => void;
}) {
  const [columnsCache, setColumnsCache] = useState<{ [key: string]: string[] }>(
    {},
  );
  const updateColumnsCache = useCallback(
    (key: string, columns: string[]) => {
      setColumnsCache((cache) => ({
        ...cache,
        [key]: columns,
      }));
    },
    [setColumnsCache],
  );

  const updateSelections = useCallback(
    (selections: FeatureSetSelection[]) => {
      onChangeSelections(
        selections,
        (featureName: string) => columnsCache[featureName],
      );
    },
    [onChangeSelections, columnsCache],
  );

  const handleRemoveSelection = useCallback(
    (selection: FeatureSetSelection) => {
      updateSelections(
        selections.filter((s) => !areSelectionsEqual(s, selection)),
      );
    },
    [updateSelections, selections],
  );

  return (
    <Context.Provider
      value={{
        dataset,
        columnsCache,
        updateColumnsCache,
      }}
    >
      <div className={"tw-h-full tw-flex tw-flex-col"}>
        <div className={"tw-border tw-rounded-t"}>
          <CollapsedFeatureSelectorInfo
            allFeatures={features}
            selections={selections}
            onRemoveSelection={handleRemoveSelection}
            renderMode={"full"}
            multi
          />
        </div>
        <div className={"tw-border tw-border-t-0 tw-flex-1 tw-overflow-y-auto"}>
          <FeatureSelectorMenuContents
            className="tw-flex-1 tw-overflow-hidden"
            dataset={dataset}
            features={features}
            selections={selections}
            onChangeSelections={updateSelections}
            multi
          />
        </div>
      </div>
    </Context.Provider>
  );
}
