import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { QS, useTypedQueryParams } from "src/routing";
import { SelectionKind, ViewState } from "./types";

const defaultViewState: ViewState = {
  colorBy: null,
  groupBy: null,
  selections: [],
};

type Updater =
  | Partial<ViewState>
  | ((current: ViewState) => Partial<ViewState>);

interface ViewStateContextValue extends ViewState {
  update(update: Updater): void;
}

const SimilaritiesViewStateContext = createContext<ViewStateContextValue>({
  ...defaultViewState,
  update() {
    throw new Error("Missing ViewStateContext");
  },
});

export function useSimilaritiesViewState() {
  return useContext(SimilaritiesViewStateContext);
}

export function SimilaritiesViewStateContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [queryParams, setQueryParams] = useTypedQueryParams({
    // Use the same query param as MegaMap for the selected metadata column (known in similarities
    // as "color by" – in MegaMap, the colorBy query param is used for something different)
    treatmentColumn: QS.string<string, null>(null),
    groupBy: QS.string<string, null>(null),
    selections: QS.jsons<SelectionKind>({
      clean(value) {
        switch (value.kind) {
          case "scatterplot":
            return {
              kind: value.kind,
              points: value.points,
            };

          case "metadata":
            return {
              kind: value.kind,
              key: value.key,
              value: value.value,
            };
        }
      },
      validate(raw): raw is SelectionKind {
        if (!raw || typeof raw !== "object") {
          return false;
        }

        const testRaw = raw as Partial<SelectionKind>;
        if (
          typeof testRaw.kind !== "string" ||
          // TODO(you): Fix this no-unnecessary-condition rule violation
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          (testRaw.kind !== "scatterplot" && testRaw.kind !== "metadata")
        ) {
          return false;
        }

        switch (testRaw.kind) {
          case "scatterplot":
            return typeof testRaw.points === "object";

          case "metadata":
            return (
              typeof testRaw.key === "string" &&
              typeof testRaw.value === "string"
            );
        }
      },
    }),
  });

  const [state, setState] = useState({
    ...defaultViewState,
    colorBy: queryParams.treatmentColumn,
    groupBy: queryParams.groupBy,
    selections: queryParams.selections,
  });

  useEffect(() => {
    const desiredQueryParams = {
      ...queryParams,
      treatmentColumn: state.colorBy,
      groupBy: state.groupBy,
      // We only persist metadata selections in the query params because arbitrary scatterplot
      // selections can easily break the URL length limit. Long-term we should persist view configs
      // in a DB to avoid needing to put so much state within the URL.
      selections: state.selections.filter(
        (selection) => selection.kind === "metadata",
      ),
    };

    setQueryParams(desiredQueryParams);
  }, [queryParams, setQueryParams, state]);

  const update = useCallback((update: Updater) => {
    setState((current) => ({
      ...current,
      ...(typeof update === "function" ? update(current) : update),
    }));
  }, []);

  const value = useMemo(
    () => ({
      ...state,
      update,
    }),
    [state, update],
  );

  return (
    <SimilaritiesViewStateContext.Provider value={value}>
      {children}
    </SimilaritiesViewStateContext.Provider>
  );
}
