/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/**
 * Create, configure, and customize the Bird Eats Bug reporting widget.
 *
 * Most of the code is a copy/paste from their SDK docs:
 * https://sdk.birdeatsbug.com/v2/docs/
 */
export default function initializeBirdEatsBug(userEmail) {
  // Create a queue, but don't obliterate an existing one!
  const birdeatsbug = (window.birdeatsbug = window.birdeatsbug || []);
  // If the real SDK is already on the page, return.
  if (birdeatsbug.initialize) return;
  // If the snippet was invoked already, show an error.
  if (birdeatsbug.invoked) {
    // TODO(you): Fix this no-unnecessary-condition rule violation
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (window.console && console.error) {
      console.error("birdeatsbug snippet included twice.");
    }
    return;
  }
  // Invoked flag, to make sure the snippet is never invoked twice.
  birdeatsbug.invoked = true;
  // A list of the methods in the SDK to stub.
  birdeatsbug.methods = [
    "setOptions",
    "trigger",
    "resumeSession",
    "takeScreenshot",
    "startRecording",
    "stopRecording",
    "stopSession",
    "uploadSession",
    "deleteSession",
  ];
  // Define a factory to create stubs. These are placeholders
  // for methods in the SDK so that you never have to wait
  // for it to load to record method calls. The `method` is
  // stored as the first argument, so we can replay the data.
  birdeatsbug.factory = function (method) {
    return function () {
      const args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      birdeatsbug.push(args);
      return birdeatsbug;
    };
  };
  // For each of our methods, generate a queueing stub.
  for (let i = 0; i < birdeatsbug.methods.length; i++) {
    const key = birdeatsbug.methods[i];
    birdeatsbug[key] = birdeatsbug.factory(key);
  }
  // Define a method to load the SDK
  // and that will be sure to only ever load it once.
  birdeatsbug.load = function () {
    // Create an async script element.
    const script = document.createElement("script");
    script.type = "module";
    script.async = true;
    script.src = "https://sdk.birdeatsbug.com/v2/core.js";
    // Insert the script next to the first script element.
    const mountJsBefore =
      // TODO(you): Fix this no-unnecessary-condition rule violation
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      document.getElementsByTagName("script")[0] || document.body.firstChild;
    mountJsBefore.parentNode.insertBefore(script, mountJsBefore);

    // Create a style element
    const style = document.createElement("link");
    style.rel = "stylesheet";
    style.type = "text/css";
    style.href = "https://sdk.birdeatsbug.com/v2/style.css";
    // Insert the styles before the 1st style
    const mountCssBefore =
      document.querySelector('link[rel="stylesheet"]') || mountJsBefore;
    mountCssBefore.parentNode.insertBefore(style, mountCssBefore);
  };
  // Load SDK
  birdeatsbug.load();
  // Pass options
  window.birdeatsbug.setOptions({
    publicAppId: "ae96a167-8ad8-4e9a-a738-757162b3b560",
    ui: {
      watermark: false,
      previewScreen: {
        email: false,
        title: "required",
        description: "required",
        visualProofButtons: {
          screenRecording: true,
        },
      },
      // We implement a custom trigger button
      defaultButton: false,
    },
    hooks: {
      beforeUpload: decorateDataForLinear,
    },
    user: { email: userEmail },
  });
}

async function decorateDataForLinear(sessionData) {
  // Hack(davidsharff): use p tag to create a new line in Linear. Officially Linear only supports markdown and most HTML
  // don't get through (at least we know it is santized). For some reason no other new line tactic I could devise would work.
  // Note, we're not any different than the user when injecting at this stage. If they provided markdown or an accepted HTML tag,
  // it'd make through to Linear.
  sessionData.session.description = `
    <p>Author: ${sessionData.session.uploaderEmail}</p>
    ${sessionData.session.description}
    `;
  return sessionData;
}
