import { CLUSTER_CARD_HEIGHT_PX } from "src/PhenoFinder/constants";
import { CellSampleMetadata } from "src/types";
import {
  Classification,
  LabeledSet,
  defaultLabeledSet,
} from "../../PhenotypicLearner/Context";
import { keyForSample } from "../../PhenotypicLearner/util";
import { ChartMargin, LeafDataNode } from "../types";

export function getVisualizationHeight(
  numClusters: number,
  margin: Partial<ChartMargin> = {},
) {
  // To avoid squishing the chart vertically, we allow it to scroll, so set the height based on
  // the data instead of fitting it to the screen size
  const boundedHeight = (CLUSTER_CARD_HEIGHT_PX + 16) * numClusters;
  const height = boundedHeight + (margin.top ?? 0) + (margin.bottom ?? 0);
  return [boundedHeight, height];
}

export function getCellKey(metadata: CellSampleMetadata) {
  return `${metadata.plate}-${metadata.well}-${metadata.field}@${metadata.row},${metadata.column}`;
}

export function getDefaultClusterDisplayName(clusterNumber: number) {
  return `Cluster ${clusterNumber + 1}`;
}

export function convertLeafNodeToLabeledSet(
  leafNode: LeafDataNode,
  defaultDisplayName: string,
  stains: string[],
  defaultCropSize: number,
): Omit<LabeledSet, "id"> {
  const displayName: string = leafNode.displayName ?? defaultDisplayName;
  const labeledClassifications: Classification[] = [
    {
      name: displayName,
      examples: leafNode.cells.map((cellMetadata) => ({
        ...cellMetadata,
        class: displayName,
        id: keyForSample(cellMetadata),
      })),
    },
  ];
  labeledClassifications.push({
    name: "Not " + displayName,
    examples: [],
  });
  return {
    ...defaultLabeledSet,
    displayName: displayName + " Refinement",
    classifications: labeledClassifications,
    stains: stains,
    selectedStains: stains,
    size: defaultCropSize,
  };
}
