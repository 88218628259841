/**
 * Component to render an individual item in a dropdown.
 */
import { css } from "aphrodite";
import cx from "classnames";
import { ReactNode, useEffect, useRef } from "react";
import { shared } from "../../megamap-styles";

export default function DropdownListItem({
  children,
  focused,
  onFocus,
  onBlur,
  onClick,
  selected,
  selectedClassName,
}: {
  children: ReactNode;
  focused: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
  selected?: boolean;
  selectedClassName?: string;
}) {
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (focused) {
      if (ref.current) {
        ref.current.scrollIntoView({ block: "nearest" });
      }
    }
  }, [focused]);

  return (
    <li
      ref={ref}
      role="option"
      className={cx(
        "tw-px-2 tw-py-1 tw-text-sm ",
        "tw-flex tw-items-center ",
        "tw-overflow-hidden tw-cursor-pointer tw-select-none",
        selected && !focused
          ? selectedClassName ?? "tw-text-gray-600 tw-bg-purple-200"
          : selectedClassName
            ? undefined
            : "tw-text-gray-600",
        "last:tw-pb-2",
        focused && (selectedClassName ?? css(shared.darken1)),
      )}
      onClick={onClick}
      onMouseMove={onFocus}
      onMouseLeave={onBlur}
      onMouseDown={(e) => e.stopPropagation()}
    >
      {children}
    </li>
  );
}
