/**
 * Component to render a multi-channel image.
 */
import * as Popover from "@radix-ui/react-popover";
import * as Portal from "@radix-ui/react-portal";
import cx from "classnames";
import * as queryString from "query-string";
import React, {
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Copy, Download, DownloadCloud, Image } from "react-feather";
import { useFeatureFlag } from "src/Workspace/feature-flags";
import { useActiveWorkspace } from "src/Workspace/hooks";
import { useWorkspaceApi } from "src/hooks/api";
import { useDataset } from "src/hooks/datasets";
import {
  useAutoImageSet,
  useDefaultMaskImageSet,
  useMaskImageSetForType,
} from "src/hooks/immunofluorescence";
import BinaryTiff from "src/imaging/Control/BinaryTiff";
import {
  colorForCover,
  useCanSetDatasetCover,
  useSetCover,
} from "src/util/cover";
import MenuButton from "../Control/MenuButton";
import { SelectedCell } from "../FeatureSetManagementPage/types";
import MultiChannelImage from "../imaging/MultiChannelImage";
import { useVisualizationContext } from "../imaging/context";
import {
  DownloadHelpersSettings,
  useDownloadHelpers,
} from "../imaging/download-helpers";
import {
  Color,
  CropOptions,
  DatasetPlateWellFieldTZ,
  DatasetPlateWellFieldTZC,
  FeaturePresentation,
  ImageSet,
} from "../imaging/types";
import { Features, WorkspaceId } from "../types";

function Container({
  children,
  className,
  forwardRef,
  href,
  size,
  onClick,
}: {
  children?: ReactElement;
  className?: string;
  forwardRef?: string | { [K: string]: any } | null;
  href?: string;
  size: number;
  onClick?: (e: MouseEvent) => void;
}) {
  return href ? (
    <a
      ref={forwardRef as any}
      className={className}
      style={{
        backgroundColor: "#F9F9F9",
        width: size,
        height: size,
      }}
      href={href}
      onClick={onClick}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  ) : (
    <div
      ref={forwardRef as any}
      className={className}
      style={{
        backgroundColor: "#2a2a2a",
        width: size,
        height: size,
        cursor: onClick ? "pointer" : undefined,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

/** Menu button for custom context menu on images. */
function ContextMenuButton({
  onClick,
  disabled,
  children,
}: {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  children: ReactNode;
}) {
  return (
    <Popover.Close asChild>
      <MenuButton onClick={onClick} disabled={disabled}>
        {children}
      </MenuButton>
    </Popover.Close>
  );
}

function useContextMenu(settings: DownloadHelpersSettings) {
  const [contextMenuPos, setContextMenuPos] = useState<{
    left: number;
    top: number;
  } | null>(null);

  const downloadHelpers = useDownloadHelpers(settings);

  const onContextMenu = useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (!e.altKey && !e.ctrlKey && !e.metaKey && !e.shiftKey) {
      e.preventDefault();
      setContextMenuPos({
        left: e.nativeEvent.offsetX,
        top: e.nativeEvent.offsetY,
      });
    }
  }, []);

  const onOpenChange = useCallback((open: boolean) => {
    if (open) {
      setContextMenuPos({ left: 0, top: 0 });
    } else {
      setContextMenuPos(null);
    }
  }, []);

  const onClickDownload = useCallback(
    () => downloadHelpers.handleDownloadCanvas(),
    [downloadHelpers],
  );

  const onClickDownloadRaw = useCallback(
    () => downloadHelpers.handleDownloadRawImages(),
    [downloadHelpers],
  );

  const onClickCopy = useCallback(
    () => downloadHelpers.handleCopyImage(),
    [downloadHelpers],
  );

  return {
    contextMenuPos,
    onContextMenu,
    onOpenChange,
    onClickDownload,
    onClickDownloadRaw,
    onClickCopy,
  };
}

const ZOOM_FACTOR = 6;
const MAGNIFIER_SIZE = 100;
const WHITE_RING_SIZE = 2;
const BLACK_RING_SIZE = 1;

// Shows a magnified view of the part of a multichannel image that the cursor is
// hovering on
function Magnifier({
  size,
  image,
  index,
  crop,
  imageSet: imageSetIn,
}: {
  size: number;
  image: ReactElement;
  index: DatasetPlateWellFieldTZ;
  crop: CropOptions | null;
  imageSet: ImageSet;
}) {
  const [cursorLocation, setCursorLocation] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [showZoom, setShowZoom] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const zoomTimeout = useRef<number | null>(null);
  const zoomContainerRef = useRef<HTMLDivElement>(null);

  useEffect(
    () => () => {
      if (zoomTimeout.current !== null) {
        window.clearTimeout(zoomTimeout.current);
        zoomTimeout.current = null;
      }
    },
    [],
  );
  const zoomSize = size * ZOOM_FACTOR;

  const imageSet = useAutoImageSet({
    dataset: index.dataset,
    params: {
      // Use a larger image size (if it exceeds the size we're already using)
      imageSize: Math.max(zoomSize, imageSetIn.size),
      processingMode: imageSetIn.processing,
    },
  });

  return (
    <div
      className="tw-relative"
      ref={zoomContainerRef}
      style={{ width: size, height: size }}
      onMouseMove={useCallback((e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        setCursorLocation({
          x: e.clientX - rect.left,
          y: e.clientY - rect.top,
        });

        if (zoomTimeout.current === null) {
          // Wait briefly before showing the magnifier so we don't pop it up
          // when someone is just moving their cursor across the image
          zoomTimeout.current = window.setTimeout(() => {
            setShowZoom(true);
          }, 20);
        }
      }, [])}
      onMouseLeave={useCallback(() => {
        setCursorLocation(null);
        setShowZoom(false);
        setHasLoaded(false);
        if (zoomTimeout.current !== null) {
          clearTimeout(zoomTimeout.current);
          zoomTimeout.current = null;
        }
      }, [])}
    >
      {image}
      {cursorLocation && showZoom && zoomContainerRef.current && (
        <Portal.Root>
          <div
            className={cx(
              "tw-absolute tw-overflow-hidden",
              "tw-rounded-full tw-border tw-border-black",
              "tw-bg-black",
              !hasLoaded && "tw-invisible",
            )}
            style={{
              boxShadow: "0 4px 6px rgba(0,0,0,.5)",
              borderWidth: BLACK_RING_SIZE,
              width: MAGNIFIER_SIZE + BLACK_RING_SIZE * 2,
              height: MAGNIFIER_SIZE + BLACK_RING_SIZE * 2,
              left:
                zoomContainerRef.current.getBoundingClientRect().left +
                cursorLocation.x,
              top:
                zoomContainerRef.current.getBoundingClientRect().top +
                cursorLocation.y +
                12,
            }}
          >
            <div
              className={cx("tw-absolute")}
              style={{
                width: zoomSize,
                height: zoomSize,
                left:
                  -(
                    zoomSize *
                    (cursorLocation.x + WHITE_RING_SIZE + BLACK_RING_SIZE)
                  ) /
                    size +
                  (MAGNIFIER_SIZE + WHITE_RING_SIZE) / 2,
                top:
                  -(
                    zoomSize *
                    (cursorLocation.y + WHITE_RING_SIZE + BLACK_RING_SIZE)
                  ) /
                    size +
                  (MAGNIFIER_SIZE + WHITE_RING_SIZE) / 2,
              }}
            >
              <MultiChannelView
                key="image"
                index={index}
                imageSet={imageSet}
                crop={crop}
                size={zoomSize}
                showDownloadControls={false}
                showCursorAnnotations={false}
                showMagnification={false}
                onReady={() => setHasLoaded(true)}
              />
            </div>
            <div
              className="tw-absolute tw-inset-[1px] tw-border tw-border-white tw-rounded-full tw-opacity-70"
              style={{ borderWidth: WHITE_RING_SIZE }}
            />
          </div>
        </Portal.Root>
      )}
    </div>
  );
}

type BaseMultiChannelView = {
  index: DatasetPlateWellFieldTZ;
  imageSet: ImageSet | null;
  crop: CropOptions | null;
  featurePresentation?: FeaturePresentation | null;
  features?: Features | null;
  forwardRef?: string | { [K: string]: any } | null;
  href?: string;
  // Either a size threshold or a boolean
  showContextMenu?: number | boolean;
  showCursorAnnotations?: boolean;
  showDownloadControls?: boolean;
  showMagnification?: boolean;
  size: number;
  onClick?: (e: MouseEvent) => void;
  onReady?: () => void;
  selectedCell?: SelectedCell;
};

type MultiChannelViewNoMasks = BaseMultiChannelView & {
  showMasks?: undefined | false;
  maskOptions?: undefined;
};

type MultiChannelViewWithMasks = BaseMultiChannelView & {
  showMasks: true;
  maskOptions?: {
    subset?: "cells" | "nuclei";
    maskColor?: Color;
    targetMaskCoordinates?: { row: number; col: number };
    invertBackground?: boolean;
  };
};

export default function MultiChannelView({
  index,
  imageSet,
  crop: rawCrop,
  featurePresentation,
  features,
  forwardRef,
  href,
  showContextMenu,
  showCursorAnnotations,
  showDownloadControls,
  showMagnification,
  size,
  onClick,
  onReady,
  selectedCell,
  maskOptions,
}: MultiChannelViewNoMasks | MultiChannelViewWithMasks) {
  const { plate, well, field } = index;
  const crop = rawCrop
    ? {
        ...rawCrop,
        location: {
          // Some datasets like JUMP Target ORF have non-integer crop locations which are not
          // accepted by the backend. Ensure integer locations here for safety.
          x: Math.round(rawCrop.location.x),
          y: Math.round(rawCrop.location.y),
        },
      }
    : rawCrop;
  const { visualizationSettings, onLoadChannel } = useVisualizationContext();
  const { displayRanges, channelMap, autoScale } = visualizationSettings;
  const workspace = useActiveWorkspace();
  const workspaceApi = useWorkspaceApi();
  const canSetDatasetCover = useCanSetDatasetCover();
  const shouldShowSingleCellMasks = useFeatureFlag("show-single-cell-masks");

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const channels: number[] = channelMap.filter(
    (it): it is number => it != null,
  );

  const shouldShowMasks =
    shouldShowSingleCellMasks ||
    (maskOptions && !maskOptions.targetMaskCoordinates);

  // Get the specified mask subset else try for the default.
  const targetSegmentationImageSet = useMaskImageSetForType(
    shouldShowMasks && maskOptions && maskOptions.subset ? index.dataset : null,
    imageSet?.size ?? null,
    maskOptions?.subset ?? null,
  );

  const defaultSegmentationImageSet = useDefaultMaskImageSet(
    shouldShowMasks && maskOptions && !maskOptions.subset
      ? index.dataset
      : null,
    imageSet?.size ?? null,
  );

  const segmentationImageSet =
    targetSegmentationImageSet || defaultSegmentationImageSet;

  const onDownloadChannel = useCallback(
    (imageSet: ImageSet, channelIndex: DatasetPlateWellFieldTZC) => {
      const { plate, well, field, t, c, z } = channelIndex;
      const filename = `${plate}_${well}_${field}_${c}_${t}_${z}.tiff`;

      workspaceApi
        .route("fetch_image")
        .get({
          index: JSON.stringify(channelIndex),
          image_set: JSON.stringify({
            processing: imageSet.processing,
            subset: imageSet.subset,
            image_size_px: imageSet.size,
          }),
        })
        .download(filename);
    },
    [workspaceApi],
  );

  const {
    contextMenuPos,
    onClickCopy,
    onClickDownload,
    onClickDownloadRaw,
    onContextMenu,
    onOpenChange,
  } = useContextMenu({
    imageSet,
    index,
    canvasRef,
    channelMap,
    filename: `${plate}_${well}_${field}.png`,
    onDownloadChannel,
  });

  const contextMenuOpen = contextMenuPos !== null;
  const datasetListing = useDataset({
    dataset: index.dataset,
    skip: !contextMenuOpen,
  });

  const setCover = useSetCover({
    dataset: index.dataset,
    skip: !contextMenuOpen,
  });
  const handleMakeCover = useCallback(async () => {
    if (
      !datasetListing?.successful ||
      !datasetListing.value ||
      !contextMenuPos ||
      !imageSet
    ) {
      return;
    }

    const x = Number((contextMenuPos.left / size).toFixed(4));
    const y = Number((contextMenuPos.top / size).toFixed(4));
    const color = colorForCover({ x, y, canvasRef });

    setCover({
      plate,
      well,
      field,
      imageSet,
      channelMap,
      displayRanges,
      color,
      x,
      y,
    });
  }, [
    channelMap,
    contextMenuPos,
    datasetListing,
    displayRanges,
    field,
    imageSet,
    plate,
    setCover,
    size,
    well,
  ]);

  if (imageSet == null || channels.length === 0) {
    return <Container forwardRef={forwardRef} size={size} />;
  }

  // Condition used by MultiChannelImage to determine if the controls are
  // displayed
  const overrideContextMenu =
    showContextMenu !== undefined &&
    (typeof showContextMenu === "boolean"
      ? showContextMenu
      : size >= showContextMenu);

  const image = (
    <MultiChannelImage
      // NOTE(danlec): Having the key here helps prevent react from unmounting this
      // component when overrideContextMenu changes value
      key="image"
      imageSet={imageSet}
      index={index}
      workspaceId={workspace.id}
      displayRanges={displayRanges}
      autoScale={autoScale}
      size={size}
      crop={crop}
      channelMap={channelMap}
      annotations={features}
      annotationStyle={featurePresentation}
      showDownloadControls={showDownloadControls}
      showCursorAnnotations={showCursorAnnotations}
      onLoadChannel={onLoadChannel}
      filename={`${plate}_${well}_${field}.png`}
      onDownloadChannel={onDownloadChannel}
      selectedCell={selectedCell}
      forwardedRef={canvasRef}
      onContextMenu={overrideContextMenu ? onContextMenu : undefined}
      onReady={onReady}
    />
  );

  const imageWithMagnification = showMagnification ? (
    <Magnifier
      // NOTE(danlec): Having the key here helps prevent react from unmounting this
      // component when overrideContextMenu changes value
      key="magnifier"
      size={size}
      image={image}
      index={index}
      crop={crop}
      imageSet={imageSet}
    />
  ) : (
    image
  );

  return (
    <MaybeMaskWrapper
      index={index}
      workspaceId={workspace.id}
      segmentationImageSet={segmentationImageSet ?? null}
      imageSize={size}
      crop={crop}
      targetMaskCoordinates={maskOptions?.targetMaskCoordinates}
      maskColor={maskOptions?.maskColor}
      invertBackground={maskOptions?.invertBackground ?? false}
    >
      <Container
        forwardRef={forwardRef}
        className="tw-relative"
        size={size}
        href={
          href
            ? `${href}?${queryString.stringify({
                c: channelMap,
              })}`
            : undefined
        }
        onClick={onClick}
      >
        {overrideContextMenu ? (
          <Popover.Root
            open={contextMenuPos !== null}
            onOpenChange={onOpenChange}
          >
            <Popover.Anchor
              className="tw-absolute"
              style={contextMenuPos ?? undefined}
            ></Popover.Anchor>
            {imageWithMagnification}
            <Popover.Portal>
              <Popover.Content
                align="start"
                className="tw-bg-white tw-flex tw-flex-col tw-border tw-shadow"
              >
                <ContextMenuButton onClick={onClickDownload}>
                  <Download className="tw-mr-2" /> Download Composite Image
                </ContextMenuButton>
                <ContextMenuButton onClick={onClickDownloadRaw}>
                  <DownloadCloud className="tw-mr-2" /> Download Channels as
                  Images
                </ContextMenuButton>
                <ContextMenuButton onClick={onClickCopy}>
                  <Copy className="tw-mr-2" /> Copy Image
                </ContextMenuButton>
                {canSetDatasetCover && (
                  <ContextMenuButton onClick={handleMakeCover}>
                    <Image className="tw-mr-2" /> Use as Dataset Cover
                  </ContextMenuButton>
                )}
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        ) : (
          // Keep enough of the structure the same that we don't unmount the image
          // when we switch between overrideContextMenu being true and false
          <Popover.Root>{imageWithMagnification}</Popover.Root>
        )}
      </Container>
    </MaybeMaskWrapper>
  );
}

function MaybeMaskWrapper({
  workspaceId,
  index,
  segmentationImageSet,
  imageSize, // TODO: confusing param name. This is display size currently.
  crop,
  targetMaskCoordinates,
  maskColor,
  invertBackground = false,
  children,
}: {
  workspaceId: WorkspaceId;
  children: ReactElement;
  index: DatasetPlateWellFieldTZ;
  segmentationImageSet: ImageSet | null;
  imageSize: number;
  crop: CropOptions | null;
  targetMaskCoordinates?: { row: number; col: number };
  maskColor?: Color; // TODO
  invertBackground?: boolean;
}) {
  const fullIndex: DatasetPlateWellFieldTZC = {
    ...index,
    c: 0,
  };

  const href = segmentationImageSet
    ? `/api/v0/workspace/${workspaceId}/fetch_image?` +
      queryString.stringify({
        index: JSON.stringify(fullIndex),
        image_set: JSON.stringify({
          processing: segmentationImageSet.processing,
          subset: segmentationImageSet.subset,
          image_size_px: segmentationImageSet.size,
        }),
        ...(crop ? { crop_options: JSON.stringify(crop) } : {}),
      })
    : null;

  /**
   * To binarize an image, multiply such that any values > 0 get cast to the max
   * value, and values at 0 remain at 0.
   */
  const binarizeColorMatrix = (
    <>
      {maskColor ? (
        <feColorMatrix
          type="matrix"
          values={`${maskColor.r / 255} 0 0 0 0
           0 ${maskColor.g / 255} 0 0 0
           0 0 ${maskColor.b / 255} 0 0
           ${maskColor.a} 0 0 0 0`}
        />
      ) : (
        // Default to White
        <feColorMatrix
          type="matrix"
          values={`1 0 0 0 0
            0 1 0 0 0
            0 0 1 0 0
            1 0 0 0 0`}
        />
      )}
    </>
  );

  const filterId = maskColor ? toColorId(maskColor) : "default";

  return (
    <div className="tw-relative">
      {children}
      {href && (
        <>
          <svg style={{ display: "none" }}>
            <defs>
              <filter
                key={filterId}
                id={filterId}
                colorInterpolationFilters="sRGB"
              >
                {binarizeColorMatrix}
              </filter>
            </defs>
          </svg>
          <BinaryTiff
            href={href}
            style={{
              width: imageSize,
              height: imageSize,
              filter: `url(#${filterId})`,
            }}
            className="tw-absolute tw-left-0 tw-top-0"
            targetMaskCoordinates={targetMaskCoordinates}
            invertBackground={invertBackground}
          />
        </>
      )}
    </div>
  );
}

function toColorId(color: Color): string {
  return `c${color.r}_${color.g}_${color.b}_${color.a}`;
}
