import { getDevFeatureFlagOverride, useDevOptions } from "../dev-options";
import { useActiveWorkspace } from "./hooks";
import { Workspace } from "./types";

const FlagDefaults = {
  "multi-feature-dataset-filter": false,
  "always-show-full-embedding-name": false,
  "search-enabled": false,
  "labmate-cluster-enabled": false,
  "labmate-similarity-enabled": false,
  "umap-groupby-enabled": false,
  "phenosorter-show-predictions": false,
  "api-keys-shown": false,
  "umap-user-config-sampling": true,
  "umap-sampled-by-default": false,
  "hide-significance-stars-comparisons-view": false,
  "disable-experiment-renaming": false,
  "use-postgres-image-store": false,
  "use-postgres-argo-logging": false,
  "feature-filtering-enabled": false,
  "edit-metadata": false,
  "show-single-cell-masks": false,
};

export type KnownFeatureFlag = keyof typeof FlagDefaults;
export const KNOWN_FEATURE_FLAGS = Object.keys(
  FlagDefaults,
) as KnownFeatureFlag[];

export function featureFlagValue(
  context: Workspace | undefined | null,
  flag: KnownFeatureFlag,
): boolean {
  return (
    context?.config.devserver_activate_all_features ||
    context?.config.active_feature_flags.includes(flag) ||
    FlagDefaults[flag]
  );
}

/**
 * Check to see whether a feature flag is active in the current workspace.
 *
 * Throws if there is no active workspace context.
 */
export function useFeatureFlag(flag: KnownFeatureFlag): boolean {
  const context = useActiveWorkspace();
  // Causes a re-render when the dev options change
  useDevOptions();

  return getDevFeatureFlagOverride(flag) ?? featureFlagValue(context, flag);
}

/**
 * Check to see whether a feature flag is active in the current workspace.
 *
 * Uses the default value if there is no active workspace context or dev
 * override. (This is useful for feature flagging the app chrome, e.g.)
 */
export function useOptionalFeatureFlag(flag: KnownFeatureFlag): boolean {
  const workspace = useActiveWorkspace({ optional: true });
  // Causes a re-render when the dev options change
  useDevOptions();
  return getDevFeatureFlagOverride(flag) ?? featureFlagValue(workspace, flag);
}
