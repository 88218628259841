import {
  Dispatch,
  ReactNode,
  Reducer,
  createContext,
  useContext,
  useReducer,
} from "react";
import {
  PhenoFinderState,
  PhenoFinderStateReducerAction,
  initialPhenoFinderState,
  phenoFinderStateReducer,
} from "./state";

const PhenoFinderContext = createContext<
  [PhenoFinderState, Dispatch<PhenoFinderStateReducerAction>] | null
>(null);
PhenoFinderContext.displayName = "PhenoFinderContext";

export function PhenoFinderContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [state, dispatch] = useReducer<
    Reducer<PhenoFinderState, PhenoFinderStateReducerAction>
  >(phenoFinderStateReducer, initialPhenoFinderState);

  return (
    <PhenoFinderContext.Provider value={[state, dispatch]}>
      {children}
    </PhenoFinderContext.Provider>
  );
}

export function usePhenoFinderContext() {
  const context = useContext(PhenoFinderContext);

  if (context === null) {
    throw new Error(
      "usePhenoFinderContext must be used within <PhenoFinderContextProvider />",
    );
  }

  return context;
}
