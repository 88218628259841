import chroma from "chroma-js";
import { Label } from "@spring/ui/typography";

export function LabMateClusterSummary({
  clusterColors,
  numClusters,
  numWells,
  onHoverCluster,
  selectedMetadataColumn,
  summaryItems,
}: {
  clusterColors: Map<number, string>;
  numClusters: number;
  numWells: number;
  onHoverCluster: (cluster: number | null) => void;
  selectedMetadataColumn: string;
  summaryItems: Array<{ label: string; cluster?: number }>;
}) {
  return (
    <div className="tw-my-md tw-text-sm">
      <p className="tw-m-0">
        The following shows the K-Means clustering of {numWells} wells using the
        UMAP components. There are <strong>{numClusters} clusters</strong>{" "}
        grouped by <strong>{selectedMetadataColumn}</strong>.
      </p>

      <div className="tw-my-md tw-grid tw-grid-rows-2 tw-grid-cols-2 tw-gap-4">
        {summaryItems.map(({ label, cluster }) => (
          <div key={label}>
            <Label className="tw-text-slate-500 tw-truncate" title={label}>
              {label}
            </Label>
            {cluster === undefined ? (
              "Equally represented"
            ) : (
              <ClusterLabel
                cluster={cluster}
                color={clusterColors.get(cluster)}
                onMouseEnter={() => onHoverCluster(cluster)}
                onMouseLeave={() => onHoverCluster(null)}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

function ClusterLabel({
  cluster,
  color = "#666",
  onMouseEnter,
  onMouseLeave,
}: {
  cluster: number;
  color?: string;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}) {
  // Clusters are 0-indexed, but in the UI we number them starting from 1
  const getClusterNumber = (cluster?: number) =>
    cluster === undefined ? undefined : cluster + 1;

  // The color used in the value bars and on the UMAP are lower opacity, so we lower it here too
  // for a closer match
  const backgroundColor = chroma(color).alpha(0.25).css();

  return (
    <div
      className="tw-inline-flex tw-items-baseline tw-space-x-1 tw-cursor-pointer tw-group"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="tw-flex tw-items-center tw-justify-center">
        <div
          className="tw-w-2.5 tw-h-2.5 tw-rounded-sm"
          style={{
            backgroundColor,
          }}
        ></div>
      </div>
      <div className="group-hover:tw-underline">
        Cluster {getClusterNumber(cluster)}
      </div>
    </div>
  );
}
