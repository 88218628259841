/**
 * Component to render an on/off-like pill control.
 */
import classNames from "classnames";

type Size = "small" | "large";

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export type State = "on" | "off" | "mixed";

const PILL_MULTIPLIERS: { [K in Size]: number } = {
  small: 1,
  large: 1.5,
};

const CIRCLE_MULTIPLIERS: { [K in Size]: number } = {
  small: 1,
  large: 2,
};

export default function Pill({ state, size }: { state: State; size: Size }) {
  return (
    <div
      role={"checkbox"}
      className={classNames(
        "tw-p-0.5 tw-flex tw-flex-none tw-rounded-full tw-transition-all",
        state === "on" && "tw-justify-end tw-bg-green-500",
        state === "mixed" && "tw-justify-center tw-bg-green-200",
        state === "off" && "tw-justify-start tw-bg-gray-300",
      )}
      style={{
        height: 8 * PILL_MULTIPLIERS[size],
        width: 12.8 * PILL_MULTIPLIERS[size],
      }}
    >
      <div
        className={"tw-bg-white tw-flex-none tw-rounded-[50%]"}
        style={{
          width: 4 * CIRCLE_MULTIPLIERS[size],
        }}
      />
    </div>
  );
}

Pill.defaultProps = {
  size: "small",
};
