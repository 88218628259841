import { getDevOverride } from "./dev-options";
import { DatasetId, WorkspaceId } from "./types";
import { toTypedString } from "./util/typed-string";

/**
 * Global configuration options and parameters.
 */
export const ENV: "production" | "development" | "test" = import.meta.env
  .MODE as "production" | "development" | "test";

const BACKEND: "production" | "local" = import.meta.env.BACKEND as
  | "production"
  | "local";

// Expected port at which the devserver is run.
const DEVSERVER_PORT = 8082;

export const HOST_URL = ((window as { HOST_URL?: string })["HOST_URL"] =
  getDevOverride("hostUrl") ??
  (BACKEND === "production" ? "" : `http://localhost:${DEVSERVER_PORT}`));

/**
 * A hard-coded configuration of the single-cell crop size (in pixels) to display for a
 * given workspace + dataset.
 *
 * TODO(colin): create some sort of configuration system or CMS (or move this info to
 * the dataset itself) and remove.
 */
const CROP_SIZES: {
  [workspace: WorkspaceId]: { [dataset: DatasetId]: number } | undefined;
} = {
  [toTypedString<WorkspaceId>("bch")]: {
    [toTypedString<DatasetId>("bch-mitos-20230120")]: 192,
  },
  [toTypedString<WorkspaceId>("mitoemotion")]: {
    [toTypedString<DatasetId>("mitoemotion-mefs-20230315")]: 512,
    [toTypedString<DatasetId>("mitoemotion-mefs-20230328")]: 512,
  },
};

// Most images are 128px by default so this is a 1.5x zoom-out of that.
export const DEFAULT_CROP_SIZE_PX: number = 192;

/**
 * Get the crop size (in pixels) for single cells in the given dataset.
 *
 * (Size is used to crop down from the source image size.)
 */
export function sourceCropSizeForDataset(
  workspace: WorkspaceId,
  dataset: DatasetId,
): number {
  return CROP_SIZES[workspace]?.[dataset] ?? DEFAULT_CROP_SIZE_PX;
}
