import cx from "classnames";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import remarkFrontmatter from "remark-frontmatter";
import remarkGfm from "remark-gfm";

export default function Markdown({
  children,
  className,
}: {
  children: string;
  className?: string;
}) {
  return (
    <ReactMarkdown
      className={className ?? "tw-text-slate-500"}
      remarkPlugins={[remarkFrontmatter, remarkGfm]}
      components={{
        h2: ({ children, className }) => (
          <h2 className={cx(className, "tw-mt-lg tw-mb-sm tw-text-2xl")}>
            {children}
          </h2>
        ),
        h3: ({ children, className }) => (
          <h3
            className={cx(
              className,
              "tw-mt-lg tw-mb-sm tw-text-lg tw-font-bold",
            )}
          >
            {children}
          </h3>
        ),
        p: ({ children, className }) => (
          <p className={cx(className, "tw-mb-md")}>{children}</p>
        ),
        a: (linkProps) => {
          const href = linkProps.href;
          if (href && href.startsWith("/")) {
            return <Link {...linkProps} to={href} className={"tw-text-blue"} />;
          } else {
            return (
              <a
                {...linkProps}
                className="tw-text-blue"
                target={linkProps.href?.startsWith("#") ? "_self" : "_blank"}
              />
            );
          }
        },
        ol: ({ children, className }) => (
          <ol className={cx("tw-ml-xl tw-list-decimal", className)}>
            {children}
          </ol>
        ),
        ul: ({ children, className }) => (
          <ul className={cx("tw-ml-xl tw-list-disc", className)}>{children}</ul>
        ),
        img: ({ src, alt }) => (
          <CustomImageWithHackilyEncodedPropsInAlt src={src} alt={alt} />
        ),
      }}
    >
      {children}
    </ReactMarkdown>
  );
}

/**
 * Custom image tag rendering to expose customizability.
 *
 * Since the markdown supported by React markdown is meant to be safe by default,
 * it doesn't support HTML (and hence doesn't support <img> tags) without a plugin.
 * That plugin is unfortunately, incompatible with the remarkFrontmatter plugin we
 * use, so we're left on our own to customize images.
 *
 * This component does that by using custom conventions encoded in the alt string of
 * the images.
 *
 * Examples:
 *  - A normal image: ![alt text](https://example.com/image.png)
 *  - An image that's sized: ![alt text (128x96)](https://example.com/image.png)
 */
function CustomImageWithHackilyEncodedPropsInAlt({
  src,
  alt,
  className,
}: {
  src?: string;
  alt?: string;
  className?: string;
}) {
  // Always make images inline by default, since the default behaviour of block
  // is too limiting.
  className = cx(className, "tw-inline-block");
  const match = /(.*) (\(\d+x\d+\))?/.exec(alt ?? "");
  if (match && match[2]) {
    const [width, height] = match[2].slice(1, match[2].length - 1).split("x");
    if (!isNaN(Number(width)) && !isNaN(Number(height))) {
      return (
        <img
          src={src}
          alt={match[1]}
          width={width}
          height={height}
          className={className}
        />
      );
    } else {
      return <img src={src} alt={alt} className={className} />;
    }
  }
  return <img src={src} alt={alt} className={className} />;
}
