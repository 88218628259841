import { memo, useCallback } from "react";
import { Tooltip } from "@spring/ui/Tooltip";
import { CHART_HEIGHT_PX, PVALUE_SIGNIFICANCE_THRESHOLD } from "../constants";
import { ClusterMetadata } from "../types";

function BaseMetadataBars({
  stacks,
  xScale,
  yScale,
  colorScale,
  numTotalRows,
  shouldHighlightMeaningfulMetadata,
}: {
  stacks: d3.Series<[string, d3.InternMap<string, ClusterMetadata>], string>[];
  xScale: d3.ScaleLinear<number, number>;
  yScale:
    | d3.ScaleOrdinal<string, number>
    | d3.ScaleBand<string>
    | ((value: string) => number);
  colorScale: d3.ScaleOrdinal<string, string, string>;
  numTotalRows: number;
  shouldHighlightMeaningfulMetadata: boolean;
}) {
  return (
    <>
      {stacks.map((subgroup) => {
        const metadataValue = subgroup.key;
        return (
          <div key={metadataValue}>
            {subgroup.map((value) => {
              const [leftX, rightX] = value;
              const [clusterName] = value.data;
              const y = yScale(clusterName);

              // If we just changed the number of clusters, the dendrogram might not be finished
              // rendering yet, so we don't know where the clusters are – skip drawing those bars
              if (y === undefined) {
                return;
              }
              // If this value doesn't have data, skip drawing the width-0 bar
              const barData = value.data[1].get(metadataValue);
              if (barData === undefined || yScale(clusterName) === undefined) {
                return;
              }

              return (
                <MetadataBar
                  key={clusterName}
                  x={xScale(leftX)}
                  y={y}
                  width={xScale(rightX) - xScale(leftX)}
                  color={colorScale(metadataValue)}
                  data={barData}
                  numTotalRows={numTotalRows}
                  shouldHighlightMeaningfulMetadata={
                    shouldHighlightMeaningfulMetadata
                  }
                />
              );
            })}
          </div>
        );
      })}
    </>
  );
}
export const MetadataBars = memo(BaseMetadataBars);

function MetadataBar({
  x,
  y,
  width,
  color,
  data,
  numTotalRows,
  shouldHighlightMeaningfulMetadata,
}: {
  x: number;
  y: number;
  width: number;
  color: string;
  data: ClusterMetadata;
  numTotalRows: number;
  shouldHighlightMeaningfulMetadata: boolean;
}) {
  const getOpacityForPValue = useCallback(
    (uncorrectedPValue: number) => {
      return uncorrectedPValue <= PVALUE_SIGNIFICANCE_THRESHOLD / numTotalRows
        ? 1
        : 0.1;
    },
    [numTotalRows],
  );

  const correctedPValue = data.uncorrectedPValue * numTotalRows;

  return (
    <div
      className="tw-absolute"
      style={{
        top: y,
        left: x,
      }}
    >
      <Tooltip
        contents={
          <div>
            <div className="tw-flex tw-items-center tw-mb-xs tw-px-sm">
              <div
                className="tw-w-3 tw-h-3 tw-rounded-sm tw-mr-xs"
                style={{
                  backgroundColor: color,
                }}
              />
              <span className="tw-font-bold tw-mr-xs">
                {data.numEntries === 1
                  ? data.value
                  : `${data.numEntries} other values`}
              </span>
            </div>

            <table className="tw-border-separate tw-border-spacing-y-0 tw-border-spacing-x-sm">
              {data.numEntries === 1 ? (
                <tbody>
                  <tr>
                    <td>Count</td>
                    <td>{data.count}</td>
                  </tr>

                  <tr>
                    <td>% of cluster</td>
                    <td>{(data.ratio * 100).toFixed(2)}%</td>
                  </tr>
                  <tr>
                    <td>
                      <i>p</i>-value
                    </td>
                    <td>
                      {correctedPValue === 0
                        ? 0
                        : correctedPValue < 0.001
                          ? correctedPValue.toExponential(3)
                          : correctedPValue.toFixed(3)}
                    </td>
                  </tr>
                  <tr>
                    <td>Odds ratio</td>
                    <td>{data.oddsRatio.toFixed(2)}</td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td>Count</td>
                    <td>{data.count}</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        }
        showArrow={true}
      >
        <div
          className="tw-cursor-default"
          style={{
            height: CHART_HEIGHT_PX,
            width,
            backgroundColor: color,
            opacity: shouldHighlightMeaningfulMetadata
              ? getOpacityForPValue(data.uncorrectedPValue)
              : 1,
          }}
        />
      </Tooltip>
    </div>
  );
}
