// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const INITIAL_SIZE = 1000;
export const CLOSE_DISTANCE = 2;
export const CANVAS_PADDING = 20;
export const LEGEND_MARGIN = 12;
export const LEGEND_COLLAPSED_HEIGHT = 42;
export const CLICK_TOLERANCE = 2;
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const CLUSTER_RADIUS = 60;
