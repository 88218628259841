import { ChannelMap, Palette, RgbMultiplier } from "./types";

type StainColor = "BLUE" | "GREEN" | "RED" | "YELLOW" | "GRAYSCALE";

type StainPredicate = (stain: string) => boolean;

function containsTerm(term: string): StainPredicate {
  const re = new RegExp(`\\b${term}\\b`, "i");
  return (stain: string) => re.test(stain);
}

// Exported only for tests.
// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const STANDARDIZED_STAIN_ASSIGNMENTS: Record<
  StainColor,
  StainPredicate[]
> = {
  BLUE: [
    containsTerm("DNA"),
    containsTerm("DAPI"),
    containsTerm("Hoechst"),
    containsTerm("Nucleus"),
    containsTerm("Blue"),
  ],
  GREEN: [
    containsTerm("Actin"),
    containsTerm("Phalloidin"),
    containsTerm("AGP"), // "Actin, Golgi, Plasma" joint channel in Cell Painting
    // TODO(trisorus): This is hacky and bad and will be removed very soon in favor
    // of being able to set default settings per dataset
    containsTerm("Cell Metabolism 1"),
    containsTerm("Green"),
  ],
  RED: [
    containsTerm("Mito"),
    containsTerm("Mitotracker"),
    containsTerm("Concanavalin A"),
    containsTerm("ConA"),
    // TODO(trisorus): This is hacky and bad and will be removed very soon in favor
    // of being able to set default settings per dataset
    containsTerm("Cell Metabolism 2"),
    containsTerm("Deep Red"),
    containsTerm("Red"),
  ],
  YELLOW: [],
  GRAYSCALE: [],
};
export const rgbMultipliers: Record<StainColor, RgbMultiplier> = {
  BLUE: [0, 0, 1],
  GREEN: [0, 1, 0],
  RED: [1, 0, 0],
  YELLOW: [1, 1, 0],
  GRAYSCALE: [0, 0, 0],
};

function equals(a: RgbMultiplier, b: RgbMultiplier): boolean {
  return a[0] === b[0] && a[1] === b[1] && a[2] === b[2];
}

function isBrightfield(stain: string): boolean {
  return stain.toLowerCase().includes("brightfield");
}

function isUnstained(stain: string): boolean {
  return stain.toLowerCase().includes("unstained");
}

/**
 * Return the default channel map for a given palette and, optionally, a subset of stains.
 *
 * The logic is as follows: for each of the red, blue, and green channels, we first look to see if
 * any of our preferred stains are available (e.g., "Hoechst" goes in blue); if not, then in each
 * case, we fallback to looking for a stain whose RGB multiplier matches that channel.
 */
export function defaultChannelMap(
  palette: Palette,
  options: {
    stainSubset?: string[];
    stainChannelIndices?: Record<string, number>;
  } = {},
): ChannelMap {
  const isMissingIndex = (index: number) => index === -1;
  const isStainIndexIncluded = (index: number) =>
    options.stainSubset === undefined
      ? true // If we're not defining a subset, then any stain in the palette is included
      : options.stainSubset.includes(palette.stains[index]);

  const stainIndexesAlreadyAssigned = new Set();

  // If we have saved stain/channel assignments, process those
  if (options.stainChannelIndices !== undefined) {
    const channelMap: ChannelMap = Array(7).fill(null) as ChannelMap;

    Object.entries(options.stainChannelIndices).forEach(
      ([savedStain, channelIndex]) => {
        // We don't assign stains that aren't in the subset
        const stainIndex = palette.stains.findIndex(
          (stain, index) => stain === savedStain && isStainIndexIncluded(index),
        );

        channelMap[channelIndex] = isMissingIndex(stainIndex)
          ? null
          : stainIndex;
      },
    );

    // Only use this assignment if it results in some stains being assigned; otherwise, continue
    // with finding defaults
    if (channelMap.some((stain) => stain !== null)) {
      return channelMap;
    }
  }

  const findDefaultStainIndexForColor = (color: StainColor) => {
    // First, check our list of preferred stains.
    let stainIndex = palette.stains.findIndex(
      (stain, index) =>
        isStainIndexIncluded(index) &&
        STANDARDIZED_STAIN_ASSIGNMENTS[color].some((predicate) =>
          predicate(stain),
        ),
    );

    // Next, look for any (unused) channel that slots in based on its RGB multiplier.
    if (isMissingIndex(stainIndex)) {
      stainIndex = palette.multipliers.findIndex(
        (rgbMultiplier, index) =>
          isStainIndexIncluded(index) &&
          !stainIndexesAlreadyAssigned.has(index) &&
          equals(rgbMultiplier, rgbMultipliers[color]) &&
          !isBrightfield(palette.stains[index]) &&
          !isUnstained(palette.stains[index]),
      );
    }

    if (!isMissingIndex(stainIndex)) {
      stainIndexesAlreadyAssigned.add(stainIndex);
    }

    return stainIndex;
  };

  const blueIndex = findDefaultStainIndexForColor("BLUE");
  let greenIndex = findDefaultStainIndexForColor("GREEN");
  let redIndex = findDefaultStainIndexForColor("RED");

  // If we have nothing to put in red or green, but we have something in
  // yellow, substitute it in.
  if ([redIndex, greenIndex].every(isMissingIndex)) {
    const yellowIndex = findDefaultStainIndexForColor("YELLOW");
    redIndex = greenIndex = yellowIndex;
  }

  // If we have only one of red or green, and we have another stain not assigned
  // that is not "Brightfield" or "unstained," slot it in the other one of these
  // channels.
  if (isMissingIndex(redIndex) && !isMissingIndex(greenIndex)) {
    for (const [index, stain] of palette.stains.entries()) {
      if (
        !stainIndexesAlreadyAssigned.has(index) &&
        isStainIndexIncluded(index) &&
        !isBrightfield(stain) &&
        !isUnstained(stain)
      ) {
        redIndex = index;
        stainIndexesAlreadyAssigned.add(index);
        break;
      }
    }
  }

  if (!isMissingIndex(redIndex) && isMissingIndex(greenIndex)) {
    for (const [index, stain] of palette.stains.entries()) {
      if (
        !stainIndexesAlreadyAssigned.has(index) &&
        isStainIndexIncluded(index) &&
        !isBrightfield(stain) &&
        !isUnstained(stain)
      ) {
        greenIndex = index;
        stainIndexesAlreadyAssigned.add(index);
        break;
      }
    }
  }

  // If we have no other stains, check for a brightfield to assign to grayscale
  let grayscaleIndex: number = -1;
  if ([redIndex, greenIndex, blueIndex].every(isMissingIndex)) {
    grayscaleIndex = palette.stains.findIndex(
      (stain, index) => isStainIndexIncluded(index) && isBrightfield(stain),
    );
  }

  return [
    isMissingIndex(redIndex) ? null : redIndex,
    isMissingIndex(greenIndex) ? null : greenIndex,
    isMissingIndex(blueIndex) ? null : blueIndex,
    null,
    null,
    null,
    isMissingIndex(grayscaleIndex) ? null : grayscaleIndex,
  ];
}
