import { useCallback } from "react";
import {
  EditValue,
  MainMenu,
  MenuButton,
  OverflowMenu,
  RenameSettings,
} from "src/Common/PopoverMenu";
import {
  getPerWorkspaceId,
  useLocalModifications,
} from "src/LocalModifications";
import { useActiveWorkspaceId } from "src/Workspace/hooks";
import { useDatasetApi } from "src/hooks/api";
import { DatasetId } from "src/types";
import { Cover, useCanSetDatasetCover } from "src/util/cover";
import { LOCAL_CACHE_TTL, SERVER_CACHE_TTL } from "./constants";

interface Props {
  className?: string;
  id: DatasetId;
  name: string;
  description?: string | null;
  cover?: Cover | null;
}

// A menu that provides options to rename a dataset/experiment and edit the the
// description
export function ExperimentOverflowMenu({
  className,
  id,
  name,
  description,
  cover,
}: Props) {
  const workspaceId = useActiveWorkspaceId();
  const { addModification } = useLocalModifications("dataset-display");

  const api = useDatasetApi({ dataset: id });

  const writeDisplaySettings = useCallback(
    async ({
      newName,
      newDescription,
      newCover,
    }: {
      newName?: string;
      newDescription?: string;
      newCover?: Cover;
    }) => {
      const body = {
        ...(newName ? { display_name: newName } : {}),
        ...(newDescription ? { description: newDescription } : {}),
        ...(newCover ? { cover: newCover } : {}),
      };

      addModification({
        id: getPerWorkspaceId(workspaceId, id),
        type: "dataset-display",
        description: body.description ?? description ?? "",
        name: body.display_name ?? name,
        cover: body.cover ?? cover ?? {},
        expires: Date.now() + SERVER_CACHE_TTL + LOCAL_CACHE_TTL,
      });
      api.route("dataset_display").put(body);
    },
    [addModification, api, cover, description, id, name, workspaceId],
  );

  const handleEditName = useCallback(
    ({ newValue, ...settings }: RenameSettings) => {
      return writeDisplaySettings({
        ...settings,
        newName: newValue,
      });
    },
    [writeDisplaySettings],
  );

  const handleEditDescription = useCallback(
    ({ newValue, ...settings }: RenameSettings) => {
      return writeDisplaySettings({
        ...settings,
        newDescription: newValue,
      });
    },
    [writeDisplaySettings],
  );

  const canSetDatasetCover = useCanSetDatasetCover();

  const handleClearCover = useCallback(() => {
    return writeDisplaySettings({
      newCover: {},
    });
  }, [writeDisplaySettings]);

  return (
    <OverflowMenu triggerClassName={className}>
      <MainMenu
        className="tw-min-w-[300px]"
        items={{
          "Edit name…": (
            <EditValue initialValue={name} onSubmit={handleEditName} />
          ),
          "Edit description…": (
            <EditValue
              className="tw-min-w-[460px]"
              initialValue={description ?? ""}
              onSubmit={handleEditDescription}
              saveText="Update"
              allowEmpty
              multiLine
            />
          ),
          ...(canSetDatasetCover
            ? {
                "Edit cover…": (
                  <MenuButton onClick={handleClearCover}>
                    Clear Cover
                  </MenuButton>
                ),
              }
            : {}),
        }}
      />
    </OverflowMenu>
  );
}
