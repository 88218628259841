/**
 * Component to summarize outcomes of QC checks.
 */
import cx from "classnames";
import pluralize from "pluralize";
import { CheckOutcome } from "./types";

export default function CheckOutcomesStatusBar({
  checkOutcomes,
}: {
  checkOutcomes: CheckOutcome[];
}) {
  const numPasses = Object.values(checkOutcomes).filter(
    (status) => status == null,
  ).length;
  const numWarnings = Object.values(checkOutcomes).filter(
    (status) => status === "warning",
  ).length;
  const numErrors = Object.values(checkOutcomes).filter(
    (status) => status === "error",
  ).length;

  return (
    <div
      className={cx(
        "tw-flex tw-px-lg tw-py-sm tw-rounded-lg",
        numErrors > 0
          ? "tw-bg-red-error tw-text-white"
          : numWarnings > 0
            ? "tw-bg-yellow-warning"
            : numPasses > 0
              ? "tw-bg-green-success tw-text-black"
              : "tw-bg-gray-200",
      )}
    >
      {numErrors > 0 && numWarnings > 0
        ? `${pluralize("error", numErrors, true)} and ${pluralize(
            "warning",
            numWarnings,
            true,
          )} found.`
        : numErrors > 0
          ? `${pluralize("error", numErrors, true)} found.`
          : numWarnings > 0
            ? `${pluralize("warning", numWarnings, true)} found.`
            : numPasses > 0
              ? "No QC issues found."
              : "Checking..."}
    </div>
  );
}
