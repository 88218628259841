import { AppLocation } from "src/TrackAppLocation/types";
import { useTrackPageview } from "../hooks/analytics";
import { Track } from "./EventTracker/Track";

export default function TrackAppLocation({
  appLocation,
  children,
}: {
  appLocation: AppLocation | undefined;
  children?: React.ReactNode;
}) {
  useTrackPageview(appLocation);

  return (
    <Track
      global={{
        page: appLocation?.id,
        subpage: appLocation?.subPage,
      }}
    >
      {children}
    </Track>
  );
}
