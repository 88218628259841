import * as Popover from "@radix-ui/react-popover";
import cx from "classnames";
import {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { X } from "react-feather";
import { VariableSizeList as List, areEqual } from "react-window";
import { useDebouncedCallback } from "use-debounce";
import { Item } from "./Item";
import { SpinningChevron } from "./SpinningChevron";
import {
  selectButtonClassName,
  selectContainerClassName,
  selectContentClassName,
  selectIconsContainerClassName,
  selectInputClassName,
} from "./classNames";
import {
  GroupedMultiProps,
  GroupedProps,
  MultiProps,
  Selectable,
  SingleProps,
} from "./types";
import {
  idForSelectable,
  isComplexSelectable,
  nodeForSelectable,
  textForSelectable,
  valueForSelectable,
} from "./util";

export type SelectProps<T> =
  | SingleProps<T>
  | GroupedProps<T>
  | MultiProps<T>
  | GroupedMultiProps<T>;

const DIVIDER = <div className="tw-h-[1px] tw-bg-gray-300 tw-mt-[4px]" />;
const DIVIDER_HEIGHT = 8;
const TYPEAHEAD_CLEAR_MS = 1000;
const MAX_LIST_HEIGHT = 800;
// Space reserved for a theoretical header (i.e. a global header at the top of the app)
const HEADER_HEIGHT = 64;
const DEFAULT_ITEM_HEIGHT = 36;
const DEFAULT_HEADING_HEIGHT = 28;
const SHOW_SELECTED_SECTION_THRESHOLD = 10;

interface RowEntry {
  node: ReactNode;
  height: number;
  key: string;
  canFocus: boolean;
  text?: string;
  id?: string;
  selected?: boolean;
}

const Row = memo(
  ({
    index,
    style,
    data,
  }: {
    index: number;
    style: React.CSSProperties;
    data: RowEntry[];
  }) => (
    <div style={style} data-key={data[index].key}>
      {data[index].node}
    </div>
  ),
  areEqual,
);
Row.displayName = "Row";

export function Select<T>(props: SelectProps<T>) {
  const [open, setOpenWithoutNotifying] = useState(props.open ?? false);
  const [filter, setFilter] = useState("");
  const [focusKey, setFocusKey] = useState<string | null>(null);

  const typeaheadSearch = useRef<string>("");
  const typeaheadTimeout = useRef<number>(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<List>(null);
  const scrollOffsetRef = useRef<number>(0);

  const { multi, grouped, onClear, onChange, onOpenChanged } = props;

  const setOpen = useCallback(
    (open: boolean) => {
      setOpenWithoutNotifying(open);
      onOpenChanged?.(open);
    },
    [onOpenChanged],
  );

  useEffect(() => {
    if (props.open !== undefined) {
      setOpenWithoutNotifying(props.open);
    }
  }, [props.open]);

  const allSelectables = useMemo(
    () =>
      props.grouped
        ? props.groups.flatMap((group) => group.items)
        : props.items,
    [props],
  );

  const selectableById = useMemo(
    () =>
      new Map(
        allSelectables.map((selectable) => [
          idForSelectable(selectable),
          selectable,
        ]),
      ),
    [allSelectables],
  );

  const groupById = useMemo(
    () =>
      props.grouped
        ? new Map(
            props.groups.flatMap((group) =>
              group.items.map((item) => [idForSelectable(item), group]),
            ),
          )
        : new Map(),
    [props],
  );

  const selectedValuesSet = useMemo(() => {
    return new Set(
      props.multi
        ? props.values
        : props.value !== undefined
        ? [props.value]
        : [],
    );
  }, [props]);

  const selectedIds = useMemo(() => {
    const selectableByValue = new Map(
      allSelectables.map((selectable) => [
        valueForSelectable(selectable),
        selectable,
      ]),
    );

    return Array.from(selectedValuesSet)
      .map((value) => selectableByValue.get(value))
      .filter(
        (selectable): selectable is Selectable<T> => selectable !== undefined,
      )
      .map(idForSelectable);
  }, [allSelectables, selectedValuesSet]);

  const selectedIdSet = useMemo(() => new Set(selectedIds), [selectedIds]);
  const isSelected = useCallback(
    (item: Selectable<T>) => selectedIdSet.has(idForSelectable(item)),
    [selectedIdSet],
  );

  const firstSelected = useMemo(
    () =>
      selectedIds
        .map((id) => selectableById.get(id))
        .find(
          (selectable): selectable is Selectable<T> => selectable !== undefined,
        ),
    [selectableById, selectedIds],
  );

  const valuesAfterMultiToggle = useCallback(
    (id: string) => {
      const item = selectableById.get(id);

      const updatedIds = item
        ? selectedValuesSet.has(valueForSelectable(item))
          ? selectedIds.filter((testId) => testId !== id)
          : [...selectedIds, id]
        : selectedIds;

      const selectables = updatedIds
        .map((selectableId) => selectableById.get(selectableId))
        .filter(
          (selectable): selectable is Selectable<T> => selectable !== undefined,
        );

      return {
        values: selectables.map((selectable) => valueForSelectable(selectable)),
        selectables,
      };
    },
    [selectableById, selectedIds, selectedValuesSet],
  );

  const handleGroupedMultiValueToggle = useCallback(
    (id: string, multiProps: GroupedMultiProps<T>) => {
      const { values, selectables } = valuesAfterMultiToggle(id);

      const groupById = new Map(
        multiProps.groups.flatMap((group) =>
          group.items.map((item) => [idForSelectable(item), group]),
        ),
      );

      multiProps.onChange(
        values,
        selectables.map((selectable) => ({
          item: selectable,
          group: groupById.get(idForSelectable(selectable))!,
        })),
      );
    },
    [valuesAfterMultiToggle],
  );

  const handleMultiValueToggle = useCallback(
    (id: string, multiProps: MultiProps<T>) => {
      const { values, selectables } = valuesAfterMultiToggle(id);
      multiProps.onChange(values, selectables);
    },
    [valuesAfterMultiToggle],
  );

  const handleSingleValueToggle = useCallback(
    (id: string, singleProps: SingleProps<T>) => {
      const selectable = selectableById.get(id);
      if (selectable) {
        singleProps.onChange(valueForSelectable(selectable), selectable);
      }
      setOpen(false);
    },
    [selectableById, setOpen],
  );

  const handleGroupedValueToggle = useCallback(
    (id: string, groupedProps: GroupedProps<T>) => {
      for (const group of groupedProps.groups) {
        const selectable = group.items.find(
          (item) => idForSelectable(item) === id,
        );
        if (selectable) {
          groupedProps.onChange(
            valueForSelectable(selectable),
            selectable,
            group,
          );
          break;
        }
      }
      setOpen(false);
    },
    [setOpen],
  );

  // Does a selectable match the current search
  const selectableMatchesSearch = useCallback(
    (selectable: Selectable<T> | undefined) =>
      selectable !== undefined &&
      (!filter ||
        textForSelectable(selectable)
          .toLowerCase()
          .includes(filter.toLowerCase())),
    [filter],
  );

  const includeSelectedSection = useMemo(() => {
    return (
      props.multi && allSelectables.length > SHOW_SELECTED_SECTION_THRESHOLD
    );
  }, [allSelectables.length, props.multi]);

  // In multi mode, when items are selected/deselected they get added to a "Selected"
  // section at the top of the list.  This will cause the items below the selected
  // section to move up or down, causing the item you just clicked to move out from
  // under the cursor - this function adjusts to scroll position to account for that
  const adjustScroll = useCallback(
    (id: string) => {
      if (!includeSelectedSection) {
        // We aren't showing the section so we don't have to adjust the scroll
        return;
      }

      const selectable = selectableById.get(id);
      if (!selectable || !listRef.current) {
        return;
      }
      // Height of the item itself
      const itemHeight =
        (isComplexSelectable(selectable) ? selectable.height : undefined) ??
        DEFAULT_ITEM_HEIGHT;

      // Height of the "selected" heading and the divider that
      // get added for the first selection (or removed if the last
      // selected item is cleared)
      const otherHeight = DEFAULT_HEADING_HEIGHT + DIVIDER_HEIGHT;

      const isAddingItem = !selectedIdSet.has(id);

      // Change in height from the item itself being added / removed
      const itemHeightDelta = isAddingItem ? itemHeight : -itemHeight;

      // Count of items currently in the selected section
      const previousSelectedCount = selectedIds
        .map((id) => selectableById.get(id))
        .filter(selectableMatchesSearch).length;

      // Change in height from possibly adding/removing the heading
      const otherDelta =
        isAddingItem && previousSelectedCount === 0
          ? // We're adding the first selected item
            otherHeight
          : !isAddingItem && previousSelectedCount === 1
          ? // We're removing the last selected item
            -otherHeight
          : // We didn't add/remove the heading
            0;

      listRef.current.scrollTo(
        scrollOffsetRef.current + itemHeightDelta + otherDelta,
      );
    },
    [
      includeSelectedSection,
      selectableById,
      selectableMatchesSearch,
      selectedIdSet,
      selectedIds,
    ],
  );

  const handleValueToggle = useCallback(
    (id: string, maintainScrollPosition: boolean = false) => {
      // The onChange event has a different signature depending on whether
      // it's a `multi` or `grouped`
      if (props.multi) {
        if (props.grouped) {
          handleGroupedMultiValueToggle(id, props);
        } else {
          handleMultiValueToggle(id, props);
        }
      } else {
        if (props.grouped) {
          handleGroupedValueToggle(id, props);
        } else {
          handleSingleValueToggle(id, props);
        }
      }

      if (maintainScrollPosition) {
        adjustScroll(id);
      }
    },
    [
      adjustScroll,
      handleGroupedMultiValueToggle,
      handleGroupedValueToggle,
      handleMultiValueToggle,
      handleSingleValueToggle,
      props,
    ],
  );

  const handleClearSelected = useCallback(() => {
    // Single selects always defer to the caller's onClear
    if (!multi) {
      onClear?.();
      return;
    }

    // Multi selects will default to setting an empty list on clear
    if (grouped) {
      onClear
        ? onClear()
        : onChange(
            [],
            allSelectables.map((selectable) => ({
              item: selectable,
              group: groupById.get(idForSelectable(selectable))!,
            })),
          );
    } else {
      onClear ? onClear() : onChange([], allSelectables);
    }
  }, [multi, grouped, onClear, onChange, allSelectables, groupById]);

  // Output a group of items, possibly under a heading
  const outputGroup = useCallback(
    (
      groupId: string,
      heading: ReactNode | undefined,
      headingHeight: number | undefined,
      items: Selectable<T>[],
    ): RowEntry[] => {
      const filteredItems = items.filter(selectableMatchesSearch);

      const output: RowEntry[] = [];

      if (filteredItems.length === 0) {
        return output;
      }

      if (heading) {
        output.push({
          node: (
            <div className="tw-text-xs tw-px-md tw-pt-sm tw-pb-xs tw-text-gray-500 tw-truncate">
              {heading}
            </div>
          ),
          height: headingHeight ?? DEFAULT_HEADING_HEIGHT,
          key: `${groupId}-heading`,
          canFocus: false,
        });
      }

      output.push(
        ...filteredItems.map((item) => {
          const id = idForSelectable(item);
          const key = `${groupId}-${id}`;
          const complex = isComplexSelectable(item) ? item : undefined;
          const height = complex?.height ?? DEFAULT_ITEM_HEIGHT;

          return {
            node: (
              <Item
                key={id}
                id={id}
                selected={isSelected(item)}
                icon={complex?.icon}
                secondary={complex?.secondaryNode}
                highlight={key === focusKey}
                disabled={complex?.disabled}
                onHover={() => setFocusKey(key)}
                onToggle={(id) => handleValueToggle(id, groupId !== "selected")}
                height={height}
              >
                {nodeForSelectable(item)}
              </Item>
            ),
            id,
            height,

            key,
            text: textForSelectable(item),
            canFocus: complex ? !complex.disabled : true,
            selected: isSelected(item),
          };
        }),
        {
          node: DIVIDER,
          height: DIVIDER_HEIGHT,
          key: `${groupId}-divider`,
          canFocus: false,
        },
      );

      return output;
    },
    [focusKey, handleValueToggle, isSelected, selectableMatchesSearch],
  );

  const items = useMemo(() => {
    const output: RowEntry[] = [];

    if (props.heading) {
      output.push({
        node: (
          <div className="tw-text-xs tw-px-md tw-py-sm tw-text-gray-500 tw-overflow-auto">
            {props.heading}
          </div>
        ),
        height: props.headingHeight ?? 48,
        key: "heading",
        canFocus: false,
      });
      output.push({
        node: DIVIDER,
        height: DIVIDER_HEIGHT,
        key: "heading-divider",
        canFocus: false,
      });
    }

    if (props.multi && selectedIds.length > 0 && includeSelectedSection) {
      output.push(
        ...outputGroup(
          "selected",
          `Selected (${selectedIds.length})`,
          28,
          selectedIds
            .map((id) => selectableById.get(id))
            .filter(
              (selectable): selectable is Selectable<T> =>
                selectable !== undefined,
            ),
        ),
      );
    }

    const resultsOutput = [];
    if (props.grouped) {
      props.groups.forEach((group) => {
        resultsOutput.push(
          ...outputGroup(
            group.id,
            group.node ?? group.id,
            group.height,
            group.items,
          ),
        );
      });
    } else {
      resultsOutput.push(
        ...outputGroup("all", undefined, undefined, props.items),
      );
    }

    if (resultsOutput.at(-1)?.node === DIVIDER) {
      resultsOutput.pop();
    }

    if (resultsOutput.length > 0) {
      output.push(...resultsOutput);
    } else {
      output.push({
        key: "no-results",
        node: (
          <div className="tw-h-full tw-flex tw-flex-row tw-items-center tw-justify-center tw-text-gray-500">
            None
          </div>
        ),
        height: 64,
        canFocus: false,
      });
    }

    return output;
  }, [includeSelectedSection, outputGroup, props, selectableById, selectedIds]);

  const moveHighlightTo = useCallback(
    (key: string | null) => {
      setFocusKey(key);
      if (key !== null) {
        const index = items.findIndex((item) => item.key === key);
        if (index !== -1) {
          listRef.current?.scrollToItem(index);
        }
      }
    },
    [items],
  );

  const togglePopover = useCallback(
    (shouldOpen) => {
      if (open === shouldOpen) {
        // We're already in the right state
        return;
      }
      setOpen(shouldOpen);
      if (shouldOpen) {
        setFilter("");
        const firstSelected = items.find((item) => item.selected);
        if (firstSelected) {
          moveHighlightTo(firstSelected.key);
        } else {
          setFocusKey(null);
        }
        typeaheadSearch.current = "";
        clearTimeout(typeaheadTimeout.current);
      }
    },
    [items, moveHighlightTo, open, setOpen],
  );

  // Toggle the popover immediately and then ignore any subsequent toggles for 300ms
  const togglePopoverThrottled = useDebouncedCallback(togglePopover, 300, {
    leading: true,
    trailing: false,
  });

  const focusableItems = useMemo(
    () => items.filter((item) => item.canFocus),
    [items],
  );

  // Select something before or after the current selection
  const moveHighlightBy = useCallback(
    (delta: number) => {
      const candidates = focusableItems;

      const currentIndex = candidates.findIndex(
        (item) => item.canFocus && item.key === focusKey,
      );

      const newIndex = Math.max(
        0,
        Math.min(candidates.length - 1, currentIndex + delta),
      );

      moveHighlightTo(candidates[newIndex].key);
    },
    [focusKey, focusableItems, moveHighlightTo],
  );

  const handleKeyDown: React.KeyboardEventHandler = useCallback(
    (e) => {
      switch (e.key) {
        case "ArrowDown": {
          if (!open) {
            togglePopover(true);
          } else {
            moveHighlightBy(1);
          }
          e.preventDefault();
          break;
        }
        case "ArrowUp": {
          if (!open) {
            togglePopover(true);
          } else {
            moveHighlightBy(-1);
          }
          e.preventDefault();
          break;
        }
        case "Enter":
        case "Tab":
        case " ": {
          if (e.key === "Tab" && !open) {
            // They're trying to get to a different control
            return;
          }
          if (e.currentTarget instanceof HTMLButtonElement && !open) {
            togglePopover(true);
            e.preventDefault();
            return;
          }
          if (e.key === " " && e.currentTarget instanceof HTMLInputElement) {
            // They're typing a space into the search box
            return;
          }
          const item =
            focusKey !== null &&
            items.find(
              (item) => item.canFocus && item.key === focusKey && item.id,
            );
          if (item && item.id !== undefined) {
            handleValueToggle(item.id);
          }
          e.preventDefault();
          break;
        }
        case "Escape": {
          if (open) {
            setOpen(false);
          }
          break;
        }

        default: {
          // If we don't have a search input, we jump to whichever item starts with
          // the text they've typed
          if (
            !props.searchable &&
            !(e.altKey || e.metaKey || e.ctrlKey) &&
            e.key.length === 1
          ) {
            typeaheadSearch.current += e.key;

            clearTimeout(typeaheadTimeout.current);
            typeaheadTimeout.current = window.setTimeout(
              () => (typeaheadSearch.current = ""),
              TYPEAHEAD_CLEAR_MS,
            );

            const search = typeaheadSearch.current.toLowerCase();
            if (search) {
              const firstMatch = items.find(
                (item) =>
                  item.canFocus && item.text?.toLowerCase().startsWith(search),
              );

              if (firstMatch && firstMatch.key !== focusKey) {
                moveHighlightTo(firstMatch.key);
              }
            }
          }
        }
      }
    },
    [
      open,
      togglePopover,
      moveHighlightBy,
      focusKey,
      items,
      handleValueToggle,
      setOpen,
      props.searchable,
      moveHighlightTo,
    ],
  );

  const itemSize = useCallback((index: number) => items[index].height, [items]);

  const allItemsHeight = useMemo(
    () => items.reduce((sum, item) => sum + item.height, 0),
    [items],
  );

  // Try to constrain the height of the list to something that will fit regardless
  // of where the select is in the window
  const listHeight = Math.min(
    MAX_LIST_HEIGHT,
    allItemsHeight,

    (window.innerHeight -
      HEADER_HEIGHT -
      (containerRef.current?.getBoundingClientRect().height ?? 0)) /
      2,
  );

  useEffect(() => {
    // Reset the react-window so it doesn't use old heights
    listRef.current?.resetAfterIndex(0);
  }, [items]);

  useEffect(() => {
    if (
      // We haven't selected anything yet
      focusKey == null ||
      // We can't see the thing we'd selected previously
      !items.some((entry) => entry.canFocus && entry.key === focusKey)
    ) {
      moveHighlightTo(items.find((entry) => entry.canFocus)?.key ?? null);
    }
  }, [focusKey, items, moveHighlightTo]);

  const toggleAndFocusInput = useCallback(() => {
    const newOpen = !open;
    togglePopoverThrottled(newOpen);
    if (newOpen && props.searchable) {
      setTimeout(() => inputRef.current?.focus(), 0);
    }
  }, [open, props.searchable, togglePopoverThrottled]);

  return (
    <Popover.Root open={open} onOpenChange={togglePopoverThrottled}>
      <Popover.Anchor asChild>
        <div
          role="listbox"
          className={cx(selectContainerClassName({ open }), props.className)}
          style={{
            // Default item height with a border
            minHeight: DEFAULT_ITEM_HEIGHT + 2,
          }}
          ref={containerRef}
        >
          <button
            className="tw-absolute tw-w-0 tw-h-0"
            type="button"
            disabled={props.disabled}
            onFocus={(e) => {
              // As the first labelable element, this even fires if we're inside a
              // <label> that has been clicked
              togglePopoverThrottled(!open);
            }}
          />
          {open && props.searchable ? (
            <input
              type="text"
              className={selectInputClassName()}
              placeholder={props.searchPlaceholder ?? props.placeholder}
              value={filter}
              onChange={(e) => setFilter(e.currentTarget.value)}
              ref={inputRef}
              onKeyDown={handleKeyDown}
              autoFocus
            />
          ) : (
            <button
              className={selectButtonClassName(selectedIds.length > 0)}
              type="button"
              disabled={props.disabled}
              onClick={toggleAndFocusInput}
              onKeyDown={handleKeyDown}
              ref={buttonRef}
            >
              {props.multi && selectedIds.length > 1 ? (
                `Selected (${selectedIds.length})`
              ) : firstSelected ? (
                <div className="tw-flex tw-flex-row tw-items-center tw-gap-sm">
                  {isComplexSelectable(firstSelected) && firstSelected.icon && (
                    <div>{firstSelected.icon}</div>
                  )}
                  <div className="tw-truncate">
                    {nodeForSelectable(firstSelected)}
                  </div>
                </div>
              ) : (
                props.placeholder
              )}
            </button>
          )}
          <div className={selectIconsContainerClassName({ open })}>
            {!props.disabled &&
            (props.multi || props.onClear) &&
            selectedIds.length > 0 ? (
              <button
                onClick={() => handleClearSelected()}
                className="tw-mr-xs"
              >
                <X
                  size={14}
                  className={cx("tw-text-gray-400 hover:tw-text-red-error")}
                />
              </button>
            ) : null}

            <div role="button" onClick={toggleAndFocusInput}>
              <SpinningChevron isOpen={open} />
            </div>
          </div>
        </div>
      </Popover.Anchor>
      <Popover.Portal>
        <Popover.Content
          className={selectContentClassName()}
          style={{
            maxHeight: listHeight,
            minWidth: containerRef.current?.getBoundingClientRect().width,
          }}
          avoidCollisions
          collisionPadding={2}
          side="bottom"
          sideOffset={4}
          onOpenAutoFocus={(e) => e.preventDefault()}
          onInteractOutside={(e) => {
            if (!containerRef.current) {
              return;
            }

            if (
              // Did we click inside the container element?
              (e.target instanceof Node &&
                containerRef.current.contains(e.target)) ||
              // Did we click inside a label that contains the container element?
              (e.target instanceof Element &&
                e.target.closest("label")?.contains(containerRef.current))
            ) {
              e.preventDefault();
            }
          }}
          onKeyDown={handleKeyDown}
        >
          <List
            ref={listRef}
            height={listHeight}
            itemCount={items.length}
            itemSize={itemSize}
            itemData={items}
            width="100%"
            itemKey={(index, data) => data[index].key}
            onScroll={(e) => (scrollOffsetRef.current = e.scrollOffset)}
          >
            {Row}
          </List>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
