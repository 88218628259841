import cx from "classnames";
import { ReactNode } from "react";

/**
 * A simple container that has width 100% and full viewport minus global nav height.
 */
export function FullScreenContainer({
  center,
  children,
  className,
}: {
  center?: boolean;
  children: ReactNode;
  className?: string;
}) {
  const centerStyles =
    center && "tw-flex tw-flex-col tw-items-center tw-justify-center";
  return (
    <div
      className={cx(
        "tw-w-full tw-h-[calc(100vh-theme(spacing.global-nav-height))]",
        centerStyles,
        className,
      )}
    >
      {children}
    </div>
  );
}
