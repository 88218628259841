import { useEffect, useState } from "react";
import { useActiveWorkspace } from "src/Workspace/hooks";
import { useAccessToken } from "src/hooks/auth0";
import { DatasetId } from "src/types";
import { useModelTrainingAndInferenceAreAllowed } from "../util/users";
import { Classification, useLabeledSetContext } from "./Context";
import { saveLabeledSet } from "./util";

function arrayEquals(a: string[], b: string[]): boolean {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

export function useAutoSave(
  dataset: DatasetId,
  labeledSetId: string | undefined,
  newSampleCutoff = 20,
) {
  const saveAllowed = useModelTrainingAndInferenceAreAllowed();
  const { state: labeledSetState, updateState: updateLabeledSetState } =
    useLabeledSetContext();
  // This corresponds to the last-saved labeledSetState
  const [lastClassification, setLastClassification] = useState(
    labeledSetState.classifications,
  );
  // Checks around context variables.
  const workspace = useActiveWorkspace();
  if (labeledSetId == null) {
    throw new Error("No labeled set ID");
  }
  const accessToken = useAccessToken();

  // Whether or not our save criteria is met.
  const saveFlag: boolean = shouldSave(
    labeledSetState.classifications,
    lastClassification,
    newSampleCutoff,
  );

  useEffect(() => {
    // Check if our classes have changed.
    if (saveFlag && saveAllowed) {
      setLastClassification(labeledSetState.classifications);
      saveLabeledSet({
        accessToken,
        workspace,
        dataset,
        labeledSet: labeledSetState,
      }).then(() => {
        updateLabeledSetState((labeledSetState) => ({
          ...labeledSetState,
          lastSaved: new Date(),
          unsavedChanges: false,
        }));
      });
    }
  }, [
    saveFlag,
    labeledSetState.classifications,
    labeledSetState.stains,
    dataset,
    labeledSetId,
    updateLabeledSetState,
    labeledSetState,
    workspace,
    accessToken,
    saveAllowed,
  ]);

  return null;
}

function shouldSave(
  currentClassification: Classification[],
  lastClassification: Classification[],
  newSampleCutoff: number,
): boolean {
  // This function checks if the classes or labels have changed sufficiently to warrant
  // a save to the cloud.
  const existingClasses = Object.values(lastClassification).map(
    (entry) => entry.name,
  );
  const maybeNewClasses = Object.values(currentClassification).map(
    (entry) => entry.name,
  );
  const numExistingLabels = Object.values(lastClassification)
    .map((entry) => entry.examples)
    .flat().length;
  const numMaybeNewLabels = Object.values(currentClassification)
    .map((entry) => entry.examples)
    .flat().length;

  // Check that we have at least two classes.
  const atLeastTwoClass: boolean =
    Object.values(currentClassification).map((entry) => entry.examples).length >
    1;
  // If there are other pieces of logic then we can add them here.
  const sanitySaveCheck: boolean = atLeastTwoClass;
  // We have two cases to check for. One is that our class labels have changed.
  // This can be either a rename, a deletion or an addition of a class.
  // The second situation to check for is that the number of labels has changed by
  // at least newSampleCutoff.
  let logicPassed: boolean = false;
  if (!arrayEquals(existingClasses, maybeNewClasses) && sanitySaveCheck) {
    logicPassed = true;
  } else if (
    // Check if we have enough new samples loaded.
    Math.abs(numMaybeNewLabels - numExistingLabels) >= newSampleCutoff &&
    sanitySaveCheck
  ) {
    logicPassed = true;
  }

  return logicPassed && sanitySaveCheck;
}
