export function wrapIndex(i: number, n: number): number {
  return ((i % n) + n) % n;
}

export function firstAvailable(n: number): number | null {
  if (n === 0) {
    return null;
  } else {
    return 0;
  }
}

export function lastAvailable(n: number): number | null {
  if (n === 0) {
    return null;
  } else {
    return n - 1;
  }
}
