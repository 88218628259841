import React, { useEffect, useState } from "react";
import { ChevronRight, RefreshCw } from "react-feather";
import { useRouteMatch } from "react-router-dom";
import { FullScreenContainer } from "src/Common/FullScreenContainer";
import Loader from "src/Common/Loader";
import { useDialog } from "src/Common/useDialog";
import { DatasetId, WorkspaceId } from "src/types";
import { Button } from "@spring/ui/Button";
import { Label, Subtitle, Title } from "@spring/ui/typography";
import {
  useEditIngestionMetadata,
  useFetchIngestionMetadata,
  useFetchPipelineStatuses,
  useRunCopyImagesAndCreateDataset,
  useSingleCellIngestionGraph,
} from "../hooks";
import { EditIngestionMetadataBody, PipelineStatus } from "../types";
import { Input } from "./helpers";

export function IngestionDashboard() {
  const match = useRouteMatch<{
    workspaceId: WorkspaceId;
    datasetId: DatasetId;
  }>();
  const { workspaceId, datasetId } = match.params;

  const [selectedStatusId, setSelectedStatusId] = useState<string | null>(null);

  const [ingestionMetadataReq, refetchIngestionMetadata] =
    useFetchIngestionMetadata(workspaceId, datasetId);

  const editIngestionMetadata = useEditIngestionMetadata(
    workspaceId,
    datasetId,
  );

  // TODO(davidsharff): consider if we want to "skip" the fetch until we know that the user has
  // run a pipeline at least once (from the ingestionMetadataReq)
  const [pipelineStatusesReq, refetchPipelineStatuses] =
    useFetchPipelineStatuses(workspaceId, datasetId);

  const [isRefetchingStatuses, setIsRefetchingStatuses] = useState(false);

  const copyImagesAndCreateDataset = useRunCopyImagesAndCreateDataset(
    workspaceId,
    datasetId,
  );

  const multiPlateIngestionGraph = useSingleCellIngestionGraph(
    workspaceId,
    datasetId,
  );

  if (!ingestionMetadataReq) {
    return (
      <FullScreenContainer center>
        <Loader />
      </FullScreenContainer>
    );
  }

  if (pipelineStatusesReq && !pipelineStatusesReq.successful) {
    console.error(
      `Error fetching pipeline statuses: ${pipelineStatusesReq.error}`,
    );
    return <div>Oops. Error fetchiing pipeline statues.</div>;
  }

  if (!ingestionMetadataReq.successful) {
    console.error(
      `Error fetching ingestion metadata: ${ingestionMetadataReq.error}`,
    );
    return <div>Oops. Error fetching ingestion metadata.</div>;
  }

  const pipelineStatuses = pipelineStatusesReq?.successful
    ? pipelineStatusesReq.value
    : null;

  const ingestionMetadata = ingestionMetadataReq.value;

  const {
    source_image_size,
    image_size,
    single_cell_size,
    nuclear_stain_name,
  } = ingestionMetadata;

  const isMissingSetting =
    source_image_size === null ||
    image_size === null ||
    single_cell_size === null ||
    !nuclear_stain_name;

  const handleCopyImagesAndCreateDataset = () => {
    if (
      source_image_size === null ||
      image_size === null ||
      single_cell_size === null
    ) {
      return;
    }
    runPipeline(() =>
      copyImagesAndCreateDataset(
        source_image_size,
        image_size,
        single_cell_size,
      ),
    );
  };

  const handleMultiPlateIngestionGraph = () => {
    if (
      source_image_size === null ||
      single_cell_size === null ||
      !nuclear_stain_name
    ) {
      return;
    }
    runPipeline(() =>
      multiPlateIngestionGraph(
        source_image_size,
        single_cell_size,
        nuclear_stain_name,
      ),
    );
  };

  const runPipeline = async (cb: () => Promise<unknown>) => {
    try {
      // We will ultimately refresh statuses but want to lock the UI now to avoid double clickking runs
      // of multiple pipelines.
      setIsRefetchingStatuses(true);
      await cb();
      await refetchPipelineStatuses();
    } catch (e) {
      console.error(e);
    } finally {
      setIsRefetchingStatuses(false);
    }
  };

  const toggleStatusDrawer = (argoJobId: string) => {
    setSelectedStatusId(selectedStatusId === argoJobId ? null : argoJobId);
  };

  const handleClickRefreshStatuses = async () => {
    try {
      setIsRefetchingStatuses(true);
      await refetchPipelineStatuses();
    } finally {
      setIsRefetchingStatuses(false);
    }
  };

  const handleSaveIngestionMetadata = async (
    patch: EditIngestionMetadataBody,
  ) => {
    await editIngestionMetadata(patch);
    await refetchIngestionMetadata();
  };

  const canRunPipeline =
    !isMissingSetting &&
    !isRefetchingStatuses &&
    // Disable the pipeline buttons until we know that either:
    // 1) They have never ran a pipeline
    // 2) The user can see the latest pipeline statuses
    // #1 avoids the long load time when first accessing the dashboard for a new dataset.
    (ingestionMetadata.argo_job_ids.length === 0 || !!pipelineStatuses);

  return (
    <>
      <Titlebar
        sourceImageSize={source_image_size || null}
        imageSize={image_size || null}
        singleCellSize={single_cell_size || null}
        nuclearStainName={nuclear_stain_name || ""}
        shouldForceOpenSettings={isMissingSetting}
        onSaveIngestionMetadata={handleSaveIngestionMetadata}
      />

      <div className="tw-space-y-md">
        <div>
          <Subtitle className="tw-mb-4">Run Pipeline</Subtitle>
          <Button
            onClick={handleCopyImagesAndCreateDataset}
            disabled={!canRunPipeline}
            className="tw-text-center"
            variant="secondary"
          >
            Copy Images and Create Dataset
          </Button>
        </div>

        <div>
          <Button
            onClick={handleMultiPlateIngestionGraph}
            disabled={!canRunPipeline}
            className="tw-text-center"
            variant="secondary"
          >
            Single Cell Ingestion Graph
          </Button>
        </div>
      </div>
      <div className="tw-flex tw-items-center tw-justify-between">
        <Subtitle className="tw-my-md">Pipeline Statuses</Subtitle>
        <Button
          onClick={handleClickRefreshStatuses}
          disabled={isRefetchingStatuses}
        >
          <RefreshCw className="tw-h-[16px] tw-w-[16px]" />
        </Button>
      </div>
      {pipelineStatuses === null || isRefetchingStatuses ? (
        <Loader />
      ) : (
        pipelineStatuses.map((pipelineStatus) => (
          <StatusDrawer
            key={pipelineStatus.argo_job_id}
            pipelineStatus={pipelineStatus}
            toggleJobDetails={toggleStatusDrawer}
            isSelected={selectedStatusId === pipelineStatus.argo_job_id}
          />
        ))
      )}
    </>
  );
}

type StatusDrawerProps = {
  pipelineStatus: PipelineStatus;
  toggleJobDetails: (jobId: string) => void;
  isSelected: boolean;
};

const StatusDrawer: React.FC<StatusDrawerProps> = ({
  pipelineStatus: job,
  toggleJobDetails,
  isSelected,
}) => {
  const tableCellClass = "tw-border tw-px-4 tw-py-2";

  const titleFieldClass = "tw-flex tw-items-center tw-flex-[.75] tw-space-x-xs";

  return (
    <div className="tw-border tw-border-gray-300 tw-rounded tw-p-4 tw-mb-4">
      <div
        className="tw-cursor-pointer tw-flex tw-items-center tw-justify-between"
        onClick={() => toggleJobDetails(job.argo_job_id)}
      >
        <ChevronRight
          className={`tw-transition-transform tw-duration-500 tw-mr-md tw-text-primary-800 ${
            isSelected ? "tw-rotate-90" : "tw-rotate-0"
          }`}
        />
        <div className={titleFieldClass}>
          <Label>Job ID:</Label> <Label>{job.argo_job_id}</Label>
        </div>
        <div className={titleFieldClass}>
          <Label>Status:</Label> <Label>{job.status}</Label>
        </div>
        <div className={titleFieldClass}>
          <Label>Started:</Label> <Label>{job.started_at}</Label>
        </div>
        <div className={titleFieldClass}>
          <Label>Finished:</Label> <Label>{job.finished_at}</Label>
        </div>
      </div>
      <div
        className={`tw-overflow-hidden tw-transition-all tw-duration-500 tw-ease-in-out tw-max-h-0 ${
          isSelected && "tw-max-h-[500px]"
        }`}
      >
        <Subtitle className="tw-mt-4">Child Tasks</Subtitle>
        <table className="tw-mt-4 tw-w-full tw-border-collapse">
          <thead>
            <tr className="tw-bg-gray-200">
              <th className={tableCellClass}>ID</th>
              <th className={tableCellClass}>Display Name</th>
              <th className={tableCellClass}>Type</th>
              <th className={tableCellClass}>Phase</th>
              <th className={tableCellClass}>Started</th>
              <th className={tableCellClass}>Finished</th>
              <th className={tableCellClass}>Progress Start</th>
              <th className={tableCellClass}>Progress End</th>
              <th className={tableCellClass}>Message</th>
            </tr>
          </thead>
          <tbody>
            {job.child_tasks.map((task) => (
              <tr key={task.id}>
                <td className={tableCellClass}>{task.id}</td>
                <td className={tableCellClass}>{task.display_name}</td>
                <td className={tableCellClass}>{task.type}</td>
                <td className={tableCellClass}>{task.phase}</td>
                <td className={tableCellClass}>{task.started_at}</td>
                <td className={tableCellClass}>{task.finished_at}</td>
                <td className={tableCellClass}>{task.progress_start}</td>
                <td className={tableCellClass}>{task.progress_end}</td>
                <td className={tableCellClass}>{task.message}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function Titlebar({
  sourceImageSize,
  imageSize,
  singleCellSize,
  nuclearStainName,
  shouldForceOpenSettings,
  onSaveIngestionMetadata,
}: {
  sourceImageSize: number | null;
  imageSize: number | null;
  singleCellSize: number | null;
  nuclearStainName: string;
  shouldForceOpenSettings: boolean;
  onSaveIngestionMetadata: (
    metadataPatch: EditIngestionMetadataBody,
  ) => Promise<void>;
}) {
  const [pendingSettings, setPendingSettings] = useState<{
    sourceImageSize: number | null;
    imageSize: number | null;
    singleCellSize: number | null;
    nuclearStainName: string;
  }>({
    sourceImageSize: sourceImageSize,
    imageSize: imageSize,
    singleCellSize: singleCellSize,
    nuclearStainName: nuclearStainName,
  });

  // Destructure the individual fields for easy access
  const {
    sourceImageSize: pendingSourceImageSize,
    imageSize: pendingImageSize,
    singleCellSize: pendingSingleCellSize,
    nuclearStainName: pendingNuclearStainName,
  } = pendingSettings;

  const { DialogComponent, setOpenDialog, isDialogOpen } = useDialog();

  useEffect(() => {
    if (!isDialogOpen && shouldForceOpenSettings) {
      setOpenDialog(true);
    }
  }, [shouldForceOpenSettings, isDialogOpen, setOpenDialog]);

  const handleSourceImageSizeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) =>
    setPendingSettings({
      ...pendingSettings,
      sourceImageSize: e.target.value === "" ? null : Number(e.target.value),
    });

  const handleChangeImageSize = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPendingSettings({
      ...pendingSettings,
      imageSize: e.target.value === "" ? null : Number(e.target.value),
    });

  const handleChangeSingleCellSize = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPendingSettings({
      ...pendingSettings,
      singleCellSize: e.target.value === "" ? null : Number(e.target.value),
    });

  const handleChangeNuclearStainName = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) =>
    setPendingSettings({
      ...pendingSettings,
      nuclearStainName: e.target.value,
    });

  const handleSaveEdit = async () => {
    try {
      await onSaveIngestionMetadata({
        source_image_size: pendingSourceImageSize,
        image_size: pendingImageSize,
        single_cell_size: pendingSingleCellSize,
        nuclear_stain_name: pendingNuclearStainName,
      });

      setOpenDialog(false);
    } catch (e) {
      // TODO(davidsharff): notify the user of the error
      console.error(e);
      setPendingSettings({
        sourceImageSize: sourceImageSize,
        imageSize: imageSize,
        singleCellSize: singleCellSize,
        nuclearStainName: nuclearStainName,
      });
    }
  };

  // Your inputClassName remains the same
  const inputClassName =
    "tw-border tw-border-gray-300 tw-rounded tw-p-2 tw-text-gray-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500";

  const isMissingPendingSetting =
    pendingSourceImageSize === null ||
    pendingImageSize === null ||
    pendingSingleCellSize === null ||
    !pendingNuclearStainName;

  return (
    <>
      <div className="tw-bg-white tw-py-md tw-flex tw-justify-between tw-items-center tw-border-b tw-border-gray-300 tw-mb-sm">
        <Title className="tw-text-lg tw-font-semibold">
          Ingestion Dashboard
        </Title>

        {!isDialogOpen && (
          <div className="tw-ml-4 tw-flex tw-items-center">
            <Label className="tw-mr-2">Source Image Size:</Label>
            <Label className="tw-mr-4">{pendingSourceImageSize || "N/A"}</Label>
            <Label className="tw-mr-2">Image Size:</Label>
            <Label className="tw-mr-4">{pendingImageSize || "N/A"}</Label>
            <Label className="tw-mr-2">Single Cell Size:</Label>
            <Label className="tw-mr-4">{pendingSingleCellSize || "N/A"}</Label>
            <Label className="tw-mr-2">Nuclear Stain Name:</Label>
            <Label>{pendingNuclearStainName || "N/A"}</Label>
          </div>
        )}
        <Button onClick={() => setOpenDialog(true)} className="tw-ml-auto">
          Edit Settings
        </Button>
      </div>
      <DialogComponent disableClose={shouldForceOpenSettings}>
        <div className="tw-bg-white tw-p-8 tw-rounded-lg tw-max-w-md tw-mx-auto tw-w-[300px]">
          <Title className="tw-text-lg tw-mb-4">Pipeline Settings</Title>
          <div className="tw-flex tw-flex-col tw-gap-5">
            <label className="tw-flex tw-flex-col">
              <Subtitle className="tw-mb-2">Source Image Size</Subtitle>
              <Input
                type="number"
                value={
                  pendingSourceImageSize === null ? "" : pendingSourceImageSize
                }
                onChange={handleSourceImageSizeChange}
                className={inputClassName}
              />
            </label>
            <label className="tw-flex tw-flex-col">
              <Subtitle className="tw-mb-2">Image Size</Subtitle>
              <Input
                type="number"
                value={pendingImageSize === null ? "" : pendingImageSize}
                onChange={handleChangeImageSize}
                className={inputClassName}
              />
            </label>
            <label className="tw-flex tw-flex-col">
              <Subtitle className="tw-mb-2">Single Cell Size</Subtitle>
              <Input
                type="number"
                value={
                  pendingSingleCellSize === null ? "" : pendingSingleCellSize
                }
                onChange={handleChangeSingleCellSize}
                className={inputClassName}
              />
            </label>
            <label className="tw-flex tw-flex-col">
              <Subtitle className="tw-mb-2">Nuclear Stain Name</Subtitle>
              <Input
                value={pendingNuclearStainName}
                onChange={handleChangeNuclearStainName}
                className={inputClassName}
              />
            </label>
            {/* TODO(davidsharff): it could be nice to add a cancel button but it would only be applicable once the values were set initially. */}
            <Button
              onClick={handleSaveEdit}
              disabled={isMissingPendingSetting}
              className="tw-mt-sm tw-justify-center"
              variant="primary"
            >
              Done
            </Button>
          </div>
        </div>
      </DialogComponent>
    </>
  );
}
