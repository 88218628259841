/**
 * Component to render a selector for text-based filter conditions.
 */
import { css } from "aphrodite";
import { useRef, useState } from "react";
import { Overlay } from "react-overlays";
import DownCaret from "../../../icons/DownCaret.svg";
import { shared } from "../../../megamap-styles";
import DropdownList from "../../Dropdown/DropdownList";
import { OverlayBody } from "../OverlayParent";
import { TextCondition } from "../operations/filter-by";

const CONDITIONS: Array<{ id: TextCondition; title: string }> = Object.values(
  TextCondition,
).map((value) => ({
  id: value,
  title: value,
}));

export function TextConditionSelector({
  condition,
  onChange,
}: {
  condition: TextCondition;
  onChange: (condition: TextCondition) => void;
}) {
  const triggerRef = useRef(null);
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div
      className={"tw-flex tw-flex-none tw-overflow-visible tw-relative"}
      ref={containerRef}
    >
      <div
        className={
          "tw-flex tw-items-center tw-px-0.5 tw-mr-0.5 tw-rounded tw-cursor-pointer " +
          css(shared.textBlueFocus, shared.linkQuiet, shared.darken1Hover)
        }
        role={"button"}
        onClick={() => setIsOpen(true)}
        ref={triggerRef}
        aria-expanded={isOpen}
        tabIndex={0}
      >
        <div className={"tw-truncate tw-flex-auto"}>{condition}</div>
        <div className={"tw-flex-none tw-flex tw-items-center tw-ml-0.5"}>
          <DownCaret />
        </div>
      </div>
      <Overlay
        placement={"bottom-start"}
        show={isOpen}
        target={triggerRef}
        onHide={() => setIsOpen(false)}
        rootClose
      >
        {({ props }) => (
          <OverlayBody {...props} minWidth={80}>
            <DropdownList
              items={CONDITIONS}
              onClick={(item) => {
                onChange(item.id);
                setIsOpen(false);
              }}
              defaultValue={condition}
              searchable
              placeholder={"Find a filter operator"}
            />
          </OverlayBody>
        )}
      </Overlay>
    </div>
  );
}
