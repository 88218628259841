import * as RadixTooltip from "@radix-ui/react-tooltip";
import cx from "classnames";
import * as React from "react";

type TooltipProps = {
  asChild?: boolean;
  contents: React.ReactNode;
  enabled?: boolean;
  showArrow?: boolean;
  solid?: boolean;
  unstyled?: boolean;
};

const Tooltip = React.forwardRef<
  React.ElementRef<typeof RadixTooltip.Content>,
  RadixTooltip.TooltipContentProps & TooltipProps
>(
  (
    {
      asChild,
      className,
      children,
      contents,
      collisionPadding = 5,
      enabled = true,
      showArrow,
      sideOffset = 5,
      solid = false,
      unstyled = false,
      ...props
    },
    ref,
  ) => {
    const styleClasses = cx(
      "tw-p-sm tw-rounded tw-shadow-md",
      solid ? "tw-bg-slate-50" : "tw-bg-slate-50/95",
      "tw-border tw-border-slate-400",
      "tw-text-sm tw-text-slate-500",
    );

    return enabled ? (
      <RadixTooltip.Provider delayDuration={0} disableHoverableContent={true}>
        <RadixTooltip.Root>
          <RadixTooltip.TooltipTrigger asChild={asChild}>
            {children}
          </RadixTooltip.TooltipTrigger>

          <RadixTooltip.Portal>
            <RadixTooltip.Content
              ref={ref}
              sideOffset={sideOffset}
              collisionPadding={collisionPadding}
              className={cx(
                "tw-overflow-hidden",
                {
                  [styleClasses]: !unstyled,
                },
                className,
              )}
              {...props}
            >
              {contents}

              {showArrow ? (
                // Hack: Use one arrow filled in with the border color to act as the border, and another
                // filled in with the background color on top. This lets us have the arrow triangle without
                // a border on the part connecting it to the tooltip body.
                <>
                  <RadixTooltip.Arrow className="tw-stroke-1 tw-stroke-slate-400 tw-fill-slate-400" />
                  <RadixTooltip.Arrow className="tw-fill-slate-50/95 -tw-translate-y-px" />
                </>
              ) : null}
            </RadixTooltip.Content>
          </RadixTooltip.Portal>
        </RadixTooltip.Root>
      </RadixTooltip.Provider>
    ) : (
      <>{children}</>
    );
  },
);
Tooltip.displayName = RadixTooltip.Content.displayName;

export { Tooltip };
