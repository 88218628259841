import cx from "classnames";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { AssignPlates } from "./AssignPlates";
import { ClientMetadataTable } from "./ClientMetadataTable";
import { ImageNameParsing } from "./ImageNameParsing";
import { Publish } from "./Publish";
import { Validate } from "./Validate";

const tabs = [
  { name: "Parse Image Names", Component: ImageNameParsing },
  { name: "Upload Metadata", Component: ClientMetadataTable },
  { name: "Assign Plates", Component: AssignPlates },
  { name: "Validate", Component: Validate },
  { name: "Publish", Component: Publish },
];

export function Configurator() {
  const match = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <>
      <div className="tw-flex tw-border-b">
        {tabs.map(({ name }) => (
          <Link
            key={name}
            to={`${match.url}/${name.toLowerCase()}`}
            className={cx(
              "tw-py-2 tw-pt-4 tw-text-blue hover:tw-bg-slate-200 tw-cursor-pointer tw-flex-1 tw-text-center",
              {
                "tw-bg-slate-100": pathname.endsWith(name.toLocaleLowerCase()),
              },
            )}
          >
            {name}
          </Link>
        ))}
      </div>
      <Switch>
        {tabs.map(({ name, Component }) => (
          <Route key={name} path={`${match.path}/${name.toLowerCase()}`}>
            <Component />
          </Route>
        ))}
        <Route
          exact
          path={match.path}
          render={() => (
            <Redirect
              to={`${pathname}/${encodeURIComponent(
                tabs[0].name.toLowerCase(),
              )}`}
            />
          )}
        />
      </Switch>
    </>
  );
}
