import { useAuth0 } from "@auth0/auth0-react";
import { DropDownMenuButton } from "../Common/DropDownMenu";
import { clearAllUserSpecificLocalStores } from "../util/state-pool-store";

export default function LogoutButton({ className }: { className?: string }) {
  const { logout } = useAuth0();
  return (
    <DropDownMenuButton
      className={className}
      onClick={() => {
        try {
          clearAllUserSpecificLocalStores();
        } finally {
          logout({ returnTo: window.location.origin });
        }
      }}
    >
      Log Out
    </DropDownMenuButton>
  );
}
