import cx from "classnames";

export default function Spinner({ ariaLabel }: { ariaLabel?: string }) {
  return (
    <div
      className={"tw-animate-spin tw-inline-block"}
      aria-label={ariaLabel}
      aria-hidden={!!ariaLabel}
    >
      <div
        className={cx(
          "tw-w-md tw-h-md tw-rounded-full",
          "tw-border-2 tw-border-gray-300 tw-border-l-gray-600",
        )}
      />
    </div>
  );
}
