import React, { ReactNode, useMemo } from "react";
import { createStore } from "state-pool";
import { useCurrentUser } from "../user/hooks";

function clearAllKeys(prefix: string) {
  const keys = [];
  for (let i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i);
    if (key?.startsWith(prefix)) {
      keys.push(key);
    }
  }
  keys.forEach((key) => window.localStorage.removeItem(key));
}

/**
 * Create a state-pool store that writes through to window.localStorage.
 *
 * Mostly cribbed from the example at https://www.npmjs.com/package/state-pool
 */
function createLocalStorageBackedStore(
  prefix: string,
  debounceTimeMs?: number,
) {
  let timerId: any = null;

  const store = createStore();
  store.persist({
    PERSIST_ENTIRE_STORE: true,
    saveState: function (key, value, isInitialSet) {
      const doStateSaving = () => {
        try {
          const serializedState = JSON.stringify(value);
          window.localStorage.setItem(`${prefix}::${key}`, serializedState);
        } catch (e) {
          console.warn(`Unable to write ${key} to local storage store.`);
        }
      };

      if (isInitialSet || !debounceTimeMs) {
        // We don't debounce saving state since it's the initial set
        // so it's called only once and we need our storage to be updated
        // right away
        doStateSaving();
      } else {
        // Here we debounce saving state because it's the update and this function
        // is called every time the store state changes. However, it should not
        // be called too often because it triggers the expensive `JSON.stringify` operation.
        clearTimeout(timerId);
        timerId = setTimeout(doStateSaving, debounceTimeMs);
      }
    },

    loadState: function (key) {
      try {
        const serializedState = window.localStorage.getItem(
          `${prefix}::${key}`,
        );
        if (serializedState === null) {
          // No state saved
          return undefined;
        }
        return JSON.parse(serializedState);
      } catch (err) {
        // Failed to load state
        return undefined;
      }
    },

    removeState: function (key) {
      window.localStorage.removeItem(`${prefix}::${key}`);
    },

    clearStorage: function () {
      clearAllKeys(`${prefix}::`);
    },
  });

  return store;
}

const USER_STATE_POOL_STORE_PREFIX = "usps";

/**
 * Like useState but written to localStorage and prefixed with the current user info.
 *
 * Important: this is not cleared out in any systematic way, and should not be used for
 * any sensitive user data!
 */
export function useUserAndLocalStorageBackedState<T>(
  key: string,
  initialValue: T,
): [
  state: T,
  setState: (value: T) => void,
  updateState: (updater: (currentState: T) => T) => void,
] {
  const user = useCurrentUser();
  if (user === null) {
    throw new Error("Unable to initialize store without active user");
  }
  const store = useMemo(() => {
    // TODO(benkomalo): would be better if this was the ID but I guess we don't have it.
    const store = createLocalStorageBackedStore(
      `${USER_STATE_POOL_STORE_PREFIX}::${user.email}`,
    );
    return store;
  }, [user]);

  const [value, setState, updateState] = store.useState<T>(key, {
    default: initialValue,
    persist: true,
  });

  // TODO(benkomalo): state pool store's setters aren't stable, unfortunately, so
  // we can try wraping un useCallback here once we confirm it doesn't capture state.
  return [value, setState, updateState];
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function useInitializeLocalStorageBackedState<T>() {
  const user = useCurrentUser();

  return (key: string, value: T) => {
    if (user === null) {
      throw new Error("Unable to initialize store without active user");
    }

    const store = createLocalStorageBackedStore(
      `${USER_STATE_POOL_STORE_PREFIX}::${user.email}`,
    );

    store.setState(key, value, { persist: true });
  };
}

export function clearAllUserSpecificLocalStores() {
  clearAllKeys(USER_STATE_POOL_STORE_PREFIX);
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export interface StatePoolReadWrite<T> {
  state: T;
  setState: (value: T) => void;
  updateState: (updater: (currentState: T) => T) => void;
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export function StatePoolContext<T>(sharedPrefix: string, initialValue: T) {
  const defaultValue: StatePoolReadWrite<T> = {
    state: initialValue,
    setState: () => {
      throw new Error("no state provided");
    },
    updateState: () => {
      throw new Error("no state provided");
    },
  };

  const Context = React.createContext<StatePoolReadWrite<T>>({
    ...defaultValue,
  });

  const Provider = ({
    prefix,
    value,
    children,
  }: {
    prefix?: string;
    value?: Partial<T>;
    children: ReactNode;
  }) => {
    const store = useMemo(() => createStore(), []);
    const [state, setState, updateState] = store.useState<T>(
      prefix ? [sharedPrefix, prefix].join(":") : sharedPrefix,
      { default: { ...initialValue, ...value } },
    );

    const sp: StatePoolReadWrite<T> = { state, setState, updateState };
    return React.createElement(Context.Provider, { value: sp }, children);
  };

  return { Provider, Context };
}
