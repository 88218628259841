/**
 * Utilities for performance tracing component renders.
 *
 * Because our app makes a lot of server requests per client page, it can be
 * difficult to get an overall sense of perceived performance using server
 * metrics only. This module tries to help unify these via two means:
 *
 * 1. keeping track of client-side context (URL and a random ID) that can be
 *    sent as headers to the server with every request and subsequently added as
 *    labels on server-side tracing spans to allow filtering to a single page's
 *    requests.
 *
 * 2. using this same context to allow tracking and recording client-side spans
 *    for component renders.
 *
 * Note that we don't use the client-side trace IDs as the server-side trace IDs
 * as well because this would end up being very confusing and ultimately
 * counterproductive as the traces from many requests would interleave.
 *
 * (1) happens automatically when using `useFetch` and friends.
 *
 * (2) happens via a hook that you can call from your component:
 *
 * `useComponentSpan("MyComponentName", [dep1, dep2, dep3]);`
 *
 * This will record a span for the component that starts when the component
 * begins rendering and finishes after the last useEffect-time call for that
 * component happens for a given set of dependencies. Whenever one of the passed
 * dependencies changes (here, [dep1, dep2, dep3]), we end the old span (using
 * the endTime as the last time useEffect ran, so that we don't record idle time
 * while someone is just looking at the page) and begin a new one.
 */
import React, {
  ReactNode,
  createElement,
  useContext,
  useEffect,
  useRef,
} from "react";
import type { AccessToken } from "src/Auth0/accessToken";
import { useAccessToken } from "src/hooks/auth0";
import { v4 as uuidv4 } from "uuid";
import { perfApi } from "./api-client";

type TracingSpan = {
  readonly startTime: number;
  endTime: number;
  readonly name: string;
  readonly root: TraceInfo;
};

export type TraceInfo = {
  readonly id: string;
  readonly clientURL: string;
};

function newTraceId(): string {
  return uuidv4().replaceAll("-", "");
}

function useRootContextData(): TraceInfo {
  const root = useRef<TraceInfo>({
    id: newTraceId(),
    clientURL: window.location.href,
  });

  if (root.current.clientURL !== window.location.href) {
    root.current = {
      id: newTraceId(),
      clientURL: window.location.href,
    };
  }
  return root.current;
}

export const TracingContext = React.createContext<TraceInfo | null>(null);

export function TracingContextProvider(props: { children: ReactNode }) {
  const traceInfo = useRootContextData();
  return createElement(TracingContext.Provider, { ...props, value: traceInfo });
}

// TODO(colin): tune this after we've iterated on this infra sufficiently.
const samplingFrequency = 1;

async function sendSpan(span: TracingSpan, accessToken: AccessToken) {
  if (parseFloat(`0.${span.root.id}`) < samplingFrequency) {
    await perfApi({ accessToken }).route("span/record").post(span).finish();
  }
}

export function useComponentSpan(name: string, deps: unknown[]) {
  const token = useAccessToken();
  const traceInfo = useContext(TracingContext);
  const span = useRef<TracingSpan | null>(
    traceInfo
      ? {
          startTime: Date.now(),
          endTime: Date.now(),
          name: name,
          root: traceInfo,
        }
      : null,
  );

  const newRenderTimeSpan = traceInfo
    ? {
        startTime: Date.now(),
        endTime: Date.now(),
        name: name,
        root: traceInfo,
      }
    : null;

  // Note: important to do this here rather than in the on-every-render
  // useEffect so that we start timing when the render starts, rather than after
  // the draw, when useEffect will execute.
  if (span.current == null && traceInfo != null) {
    span.current = newRenderTimeSpan;
  }

  useEffect(() => {
    if (span.current != null) {
      span.current.endTime = Date.now();
    }
    const timeout = window.setTimeout(() => {
      if (span.current != null) {
        sendSpan(span.current, token);
        span.current = null;
      }
    }, 90 * 1000);

    return () => {
      window.clearTimeout(timeout);
    };
  });

  useEffect(() => {
    return () => {
      if (span.current != null) {
        sendSpan(span.current, token);
        span.current = newRenderTimeSpan;
      }
    };
    // Hook rules ignored to avoid the dependency on newRenderTimeSpan, which we
    // cannot include here if we want to get correct spans.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, traceInfo, token, ...deps]);
}
