import { useMemo } from "react";
import { Fetchable } from "@spring/core/result";
import { Workspace } from "../Workspace/types";
import { makeAdminApi } from "./api";

const useWorkspacesRaw = makeAdminApi("workspaces")<Workspace[]>(
  undefined,
  () => ({
    configuration: {
      // This is not a cached endpoint, but is also not a frequently changing
      // endpoint, so from SWR's perspective, it's always stale and is re-feteched
      // quite a lot. By specifying this, it means we won't re-fetch on subsequent
      // calls, but if the user refreshes the page, or if there are other events
      // (like network disconnect/reconnect), it can still refresh.
      revalidateIfStale: false,
    },
  }),
);
/**
 * Hook to fetch the list of workspaces to which the current user has access.
 */
export function useWorkspaces(): Fetchable<Workspace[]> {
  const fetchable = useWorkspacesRaw({});

  return useMemo(
    () =>
      fetchable?.map((workspaces) =>
        workspaces.map((workspace) => {
          // HACK(benkomalo): inject a logo here before we have proper storage on server
          let logo_url: string | null = null;
          if (workspace.id === "jump-cp-demo" || workspace.id === "jump-cp") {
            logo_url =
              "https://user-images.githubusercontent.com/32319/191581284-6a33966f-325a-4e87-bd22-612e50e2a908.png";
          }
          return {
            ...workspace,
            logo_url,
          };
        }),
      ),
    [fetchable],
  );
}
