import { TimeSeriesState } from "./state";

const NUM_SECONDS_TO_CACHE_AHEAD = 5;

export function getNewCacheState({
  currentTimepoint,
  timepoints,
  maxTimepoint,
  imagesPerSecond,
  cachedTimepoints,
  areAllTimepointsCached,
}: Pick<
  TimeSeriesState,
  | "currentTimepoint"
  | "timepoints"
  | "maxTimepoint"
  | "imagesPerSecond"
  | "cachedTimepoints"
  | "areAllTimepointsCached"
>): Pick<TimeSeriesState, "cachedTimepoints" | "areAllTimepointsCached"> {
  const isExistingCacheValid = cachedTimepoints.length === maxTimepoint;

  // If the current cache is valid and full, no need to update it
  if (isExistingCacheValid && areAllTimepointsCached) {
    return { cachedTimepoints, areAllTimepointsCached };
  }

  // If the current cache isn't valid, create a new one
  const newCache = isExistingCacheValid
    ? [...cachedTimepoints]
    : new Array(timepoints.length).fill(false);

  // Preload seconds before and after the current timepoint
  const numToCache = imagesPerSecond * NUM_SECONDS_TO_CACHE_AHEAD;
  const minTimepointToCache = Math.max(0, currentTimepoint - numToCache);
  const maxTimepointToCache = Math.min(
    currentTimepoint + numToCache,
    maxTimepoint,
  );

  let didUpdateCache = false;
  for (let i = minTimepointToCache; i <= maxTimepointToCache; i++) {
    if (newCache[i] == false) {
      newCache[i] = true;
      didUpdateCache = true;
    }
  }

  const nextCache = didUpdateCache ? newCache : cachedTimepoints;
  return {
    cachedTimepoints: nextCache,
    areAllTimepointsCached: didUpdateCache
      ? nextCache.every((i) => i === true)
      : areAllTimepointsCached,
  };
}
