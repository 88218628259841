import "./css/index.css";
import ReactDOM from "react-dom";
import { BrowserRouter, Router } from "react-router-dom";
import { SWRConfig } from "swr";
import App from "./App";
import Auth0Provider from "./Auth0/Auth0Provider";
import { initDayJs } from "./dayjs";
import { initErrorMonitoring } from "./error-monitoring";
import history from "./history";

// Initialize Sentry (for error monitoring).
initErrorMonitoring();

// Initialize DayJS extensions.
initDayJs();

const rootEl = document.getElementById("root");
if (rootEl == null) {
  throw Error("Cannot find root element.");
}

ReactDOM.render(
  <SWRConfig
    value={{
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    }}
  >
    <BrowserRouter>
      <Router history={history}>
        <Auth0Provider>
          <App />
        </Auth0Provider>
      </Router>
    </BrowserRouter>
  </SWRConfig>,
  rootEl,
);
