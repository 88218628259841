import { Placement } from "@popperjs/core";
import { CSSProperties } from "react";
import { ChevronRight, ExternalLink } from "react-feather";
import { useActiveWorkspace } from "src/Workspace/hooks";
import { DatasetId, UntypedWellSampleMetadataRow } from "../../types";
import HoverPopup from "../HoverPopup";
import { renderMetadataValue } from "./utils";

export function PointHoverPopup({
  point,
  coloringMetadataColumn,
  dataset,
  interestingMetadataColumns,
  groupByColumn,
  metadataColors,
  popperStyles,
  popperAttributes,
  popperState,
  setPopperElement,
  setPopperArrowElement,
  showCluster,
}: {
  point: {
    x: number;
    y: number;
    metadata: UntypedWellSampleMetadataRow;
    clusterLabel?: number;
  };
  coloringMetadataColumn: string | null;
  dataset: DatasetId;
  interestingMetadataColumns: string[];
  groupByColumn: string | null;
  metadataColors: { [value: string]: string };
  popperStyles: { [key: string]: CSSProperties } | undefined;
  popperAttributes: { [key: string]: { [attr: string]: string } | undefined };
  popperState: {
    placement: Placement;
  } | null;
  setPopperElement: (node: HTMLDivElement | null) => void;
  setPopperArrowElement: (node: HTMLDivElement | null) => void;
  showCluster: boolean;
}) {
  const workspace = useActiveWorkspace();

  const title = groupByColumn ? (
    <>
      {groupByColumn}:{" "}
      <span className="tw-font-bold">
        {renderMetadataValue(point.metadata[groupByColumn])}
      </span>
    </>
  ) : (
    <>
      <div>Plate {point.metadata.plate}</div>
      <div className="tw-flex tw-items-center tw-mx-1">
        <ChevronRight size={16} />
      </div>
      <div>Well {point.metadata.well}</div>
    </>
  );

  return (
    <HoverPopup
      popperStyles={popperStyles}
      popperAttributes={popperAttributes}
      popperState={popperState}
      setPopperElement={setPopperElement}
      setPopperArrowElement={setPopperArrowElement}
    >
      <div className="tw-flex tw-flex-col tw-overflow-hidden tw-max-h-[70vh]">
        <div>
          <div className="tw-flex">{title}</div>
          {coloringMetadataColumn &&
            coloringMetadataColumn !== "plate" &&
            coloringMetadataColumn !== "well" && (
              <div className="tw-truncate">
                {coloringMetadataColumn}:{" "}
                <span
                  className="tw-inline-block tw-w-3 tw-h-3 tw-rounded"
                  style={{
                    backgroundColor:
                      // TODO(you): Fix this no-unnecessary-condition rule violation
                      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                      point.metadata[coloringMetadataColumn] !== undefined
                        ? metadataColors[
                            renderMetadataValue(
                              point.metadata[coloringMetadataColumn],
                            )
                          ]
                        : "transparent",
                  }}
                />{" "}
                <span className="tw-font-bold">
                  {renderMetadataValue(point.metadata[coloringMetadataColumn])}
                </span>
              </div>
            )}
          <div>
            <a
              href={`/workspace/${encodeURIComponent(
                workspace.id,
              )}/e/${encodeURIComponent(dataset)}/data/${encodeURIComponent(
                point.metadata.plate,
              )}/${encodeURIComponent(point.metadata.well)}`}
              target="_blank"
              className="tw-flex tw-items-center tw-no-underline tw-text-purple hover:tw-text-purple hover:tw-underline"
            >
              <span>
                {groupByColumn ? "View sample images" : "View well images"}
              </span>
              <ExternalLink size={14} className="tw-ml-1 tw-text-sm" />
            </a>
          </div>
          <div className="-tw-mx-4 tw-my-4 tw-border-t tw-border-slate-400" />
          {showCluster && point.clusterLabel !== undefined && (
            <>
              <div key="cluster" className="tw-truncate">
                Cluster:{" "}
                <span className="tw-font-bold">
                  Cluster {point.clusterLabel + 1}
                </span>
              </div>
              <div className="-tw-mx-4 tw-my-4 tw-border-t tw-border-slate-400" />
            </>
          )}
        </div>
        <div className="tw-flex-1 tw-overflow-y-auto">
          {interestingMetadataColumns.map(
            (columnName) =>
              // TODO(you): Fix this no-unnecessary-condition rule violation
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              point.metadata[columnName] !== undefined &&
              columnName !== "plate" &&
              columnName !== coloringMetadataColumn && (
                <div key={columnName} className="tw-truncate tw-text-sm">
                  {columnName}:{" "}
                  <span className="tw-font-bold">
                    {renderMetadataValue(point.metadata[columnName])}
                  </span>
                </div>
              ),
          )}
        </div>
        <div>
          <div className="-tw-mx-4 tw-my-4 tw-border-t tw-border-slate-400" />
          <div className="tw-text-xs">
            {groupByColumn
              ? `This point represents an average of the data for the given ${groupByColumn}.`
              : "This point represents an average of the data for all of the cells within the given well."}
          </div>
        </div>
      </div>
    </HoverPopup>
  );
}
