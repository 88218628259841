import { arrayEquals } from "@spring/core/utils";

// A single value we've cached
export class StoredValue<T = unknown, D extends unknown[] = unknown[]> {
  public value: T;
  public dependencies: D;
  private references: number;
  private onInactive: () => void;
  private onCleanup: undefined | ((value: T) => void);

  constructor({
    value,
    dependencies,
    onInactive,
    onCleanup,
  }: {
    value: T;
    dependencies: D;
    onInactive: () => void;
    onCleanup?: (value: T) => void;
  }) {
    this.value = value;
    this.dependencies = dependencies;
    this.references = 0;
    this.onInactive = onInactive;
    this.onCleanup = onCleanup;
  }

  addReference() {
    this.references += 1;
  }

  removeReference() {
    this.references -= 1;
    if (this.references === 0) {
      this.onInactive();
    }
  }

  active() {
    return this.references > 0;
  }

  cleanup() {
    this.onCleanup?.(this.value);
  }
}

// All the values we've cached for a given id
export class StoredValueEntries<T = unknown, D extends unknown[] = unknown[]> {
  private capacity: number | undefined;
  private entries: StoredValue<T, D>[];
  private areDependenciesEqual: ((a: D, b: D) => boolean) | undefined;

  constructor(
    capacity?: number,
    areDependenciesEqual?: (a: D, b: D) => boolean,
  ) {
    this.capacity = capacity;
    this.entries = [];
    this.areDependenciesEqual = areDependenciesEqual;
  }

  private removeValue(value: StoredValue<T, D>) {
    value.cleanup();

    const index = this.entries.indexOf(value);
    if (index !== -1) {
      this.entries.splice(index, 1);
    }
  }

  private checkCapacity(reserve: number = 0) {
    if (this.capacity === undefined) {
      return;
    }

    while (this.entries.length > this.capacity - reserve) {
      // See if we can remove any un-used values
      const inactive = this.entries.find((entry) => !entry.active());
      if (inactive) {
        this.removeValue(inactive);
      } else {
        break;
      }
    }
  }

  private findWithDependencies(dependencies: D) {
    return this.entries.find((entry) => {
      if (this.areDependenciesEqual) {
        return this.areDependenciesEqual(entry.dependencies, dependencies);
      } else {
        return arrayEquals(entry.dependencies, dependencies);
      }
    });
  }

  private add({
    value,
    dependencies,
    onCleanup,
  }: {
    value: T;
    dependencies: D;
    onCleanup?: (value: T) => void;
  }): StoredValue<T, D> {
    const newEntry = new StoredValue<T, D>({
      value,
      dependencies,
      onInactive: () => this.checkCapacity(),
      onCleanup,
    });

    // Free up a spot for the new entry
    this.checkCapacity(1);

    this.entries.push(newEntry);

    return newEntry;
  }

  ensure({
    initialize,
    dependencies,
    onCleanup,
  }: {
    initialize: (...dependencies: D) => T;
    dependencies: D;
    onCleanup?: (value: T) => void;
  }) {
    return (
      // If we already have an entry for these dependencies, return that
      this.findWithDependencies(dependencies) ??
      // Otherwise make a new entry and return that
      this.add({ value: initialize(...dependencies), dependencies, onCleanup })
    );
  }

  cleanup() {
    this.entries.forEach((entry) => entry.cleanup());
  }
}
