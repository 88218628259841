/**
 * Component to render a feature's title and help tooltip along with a child.
 */
import { StyleSheet, css } from "aphrodite";
import classNames from "classnames";
import { ReactNode } from "react";
import { HelpCircle } from "react-feather";
import { Tooltip } from "@spring/ui/Tooltip";
import { Title } from "@spring/ui/typography";
import { getFeatureType } from "./util";

export default function LabeledFeatureView({
  children,
  featureSetColumn,
}: {
  children: ReactNode;
  featureSetColumn: string;
}) {
  return (
    <div
      className={
        "tw-w-full tw-flex tw-flex-col tw-gap-md tw-group tw-bg-white tw-p-lg"
      }
    >
      <Title className="tw-flex tw-flex-row tw-gap-sm">
        <span>{getFeatureType(featureSetColumn).title(featureSetColumn)}</span>

        <Tooltip
          side="right"
          showArrow
          contents={getFeatureType(featureSetColumn).description(
            featureSetColumn,
          )}
        >
          <HelpCircle
            id={featureSetColumn}
            className={classNames(
              css(styles.hoverLink),
              "tw-w-8 tw-opacity-0 group-hover:tw-opacity-100",
            )}
          ></HelpCircle>
        </Tooltip>
      </Title>
      {children}
    </div>
  );
}

const styles = StyleSheet.create({
  headerLink: {
    color: "inherit",
    textDecoration: "none",
    ":hover": {
      textDecoration: "underline",
    },
  },
  hoverLink: {
    color: "#DDD",
    padding: "0 4px",
    textDecoration: "none",
    ":hover": {
      color: "#444",
      background: "#EAEAEA",
    },
  },
});
