/**
 * Selector for (Vega) color schemes.
 *
 * For now, only includes continuous color schemes.
 *
 * See: https://vega.github.io/vega/docs/schemes/.
 */
import Select from "react-select-plus";
import "react-select-plus/dist/react-select-plus.css";
import * as vega from "vega";

export type ColorScheme =
  | "blueorange"
  | "blues"
  | "category10"
  | "greens"
  | "orangered"
  | "purples"
  | "redblue"
  | "reds"
  | "redyellowblue"
  | "redyellowgreen"
  | "tableau10"
  | "tableau20"
  | "viridis"
  | "yellowgreenblue";

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export default function ColorSchemeSelector({
  colorScheme,
  onSelectColorScheme,
}: {
  colorScheme: ColorScheme | null;
  onSelectColorScheme: (colorScheme: ColorScheme) => void;
}) {
  return (
    <Select
      placeholder="Color scheme..."
      simpleValue={true}
      value={colorScheme}
      clearable={false}
      options={[
        {
          label: "Sequential",
          options: [
            { label: "blues", value: "blues" },
            { label: "greens", value: "greens" },
            { label: "reds", value: "reds" },
            { label: "purples", value: "purples" },
            { label: "orangered", value: "orangered" },
            { label: "yellowgreenblue", value: "yellowgreenblue" },
            { label: "viridis", value: "viridis" },
          ],
        },
        {
          label: "Divergent",
          options: [
            { label: "blueorange", value: "blueorange" },
            { label: "redblue", value: "redblue" },
            { label: "redyellowblue", value: "redyellowblue" },
            { label: "redyellowgreen", value: "redyellowgreen" },
          ],
        },
        {
          label: "Categorical",
          options: [
            { label: "tableau10", value: "tableau10" },
            { label: "tableau20", value: "tableau20" },
            { label: "category10", value: "category10" },
          ],
        },
      ]}
      onChange={(colorScheme: ColorScheme) => {
        onSelectColorScheme(colorScheme);
      }}
    />
  );
}

/**
 * Parses the metadata column to decide on a colorScheme based on column values.
 * A logical follow-up to this is to more rigorously parse strings to look for
 * concentration units and normalize and extract the numerical values.
 */
export function colorSchemeByWellMetadata(
  values: any[],
  minForContinuous = 3,
): ColorScheme {
  const uniqueVals = [...new Set(values)];
  const isCategorical = uniqueVals.some((it) => typeof it !== "number");
  // If we have categorical data then default to a Tableau colorScheme. Or if we only
  // have a handful of continuous values.
  if (isCategorical || uniqueVals.length <= minForContinuous)
    return uniqueVals.length <= 10 ? "tableau10" : "tableau20";
  // If any of our data are less than zero then let's assume a divergent colormap.
  // This logic here could be MUCH more nuanced in the future.
  if (uniqueVals.some((floatVal) => floatVal < 0)) {
    return "blueorange";
  }
  return "viridis";
}
/**
 * Manually maps a given value (string or number) to a vega-derived RGB value.
 */
export function colorValuesByScheme(
  values: any[],
  colorScheme: ColorScheme,
): Map<string | number | boolean | null, string> {
  const mapping: Map<string | number, string> = new Map();
  const scheme = vega.scheme(colorScheme);
  if (typeof scheme === "function") {
    // Continuous colour scheme. Sort the values for clean coloring.
    // TODO(danlec) This is mutating the values array being passed in
    values = values.sort((a, b) => a - b);
    for (const value of values) {
      mapping.set(
        value,
        scheme((1 + values.indexOf(value)) / (values.length + 1)),
      );
    }
  } else {
    values
      .slice()
      // Sort the values as strings first, so we have a consistent coloring for
      // the same set of groups, even if we're actually presenting them as being sorted
      // by something else (e.g. by median)
      .slice(0)
      .sort((a, b) => String(a).localeCompare(String(b)))
      .forEach((value, i) => {
        mapping.set(value, scheme[i % scheme.length]);
      });
  }
  return mapping;
}
