import * as Dialog from "@radix-ui/react-dialog";
import cx from "classnames";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { CheckCircle } from "react-feather";
import { Button } from "src/Common/Button";
import { useActiveWorkspaceId } from "src/Workspace/hooks";
import { useAccessToken } from "src/hooks/auth0";
import { DatasetId } from "src/types";
import { Tooltip } from "@spring/ui/Tooltip";
import { useToastContext } from "../Toast/context";
import { useDataset } from "../hooks/datasets";
import { SUPERVISED_LEARNING_TOAST_ID } from "./constants";
import { useSupervisedLearningContext } from "./context";
import { SavedModelListing } from "./types";
import { submitInferenceJob } from "./utils";

export default function ApplyModel({
  dataset,
  model,
  children,
}: {
  dataset: DatasetId;
  model: SavedModelListing;
  children: ReactNode;
}) {
  const workspaceId = useActiveWorkspaceId();
  const accessToken = useAccessToken();
  const canBeApplied =
    model.status === "trained" && model.result.status === "success";
  const datasetListing = useDataset({ dataset });
  const datasetName = datasetListing?.successful
    ? datasetListing.value?.name ?? dataset
    : dataset;
  const { refreshModels } = useSupervisedLearningContext();

  const disabledReason = useMemo(() => {
    if (model.status === "trained" && model.result.status === "failure") {
      return `There was an error training your model: ${model.result.error}`;
    } else if (model.status === "pending") {
      return "Model is currently training.";
    } else if (model.status === "applied") {
      return "Model already applied. Results will be automatically be made available in the Analyze tab when ready.";
    }
  }, [model]);

  const [open, setOpen] = useState(false);
  const [name, setName] = useState(model.name);
  const [description, setDescription] = useState("");
  const { setToast } = useToastContext();

  const [requestStatus, setRequestStatus] = useState<
    "idle" | "submitted" | "success" | "failure"
  >("idle");

  const submitRequest = useCallback(() => {
    setRequestStatus("submitted");
    submitInferenceJob(
      workspaceId,
      dataset,
      accessToken,
      model,
      name,
      description,
    ).then(
      () => {
        setOpen(false);
        setRequestStatus("success");
        refreshModels();

        setToast(
          `applied-${model.id}`,
          <div className={"tw-w-[380px] tw-flex"}>
            <div className={"tw-p-sm tw-text-purple-500"}>
              <CheckCircle />
            </div>
            <div className={"tw-flex-1"}>
              <div className={"tw-p-sm"}>
                Congrats! Your model is being applied.
              </div>

              <div className={"tw-text-sm tw-p-sm"}>
                This will take a few minutes. Your results will be ready in the
                rest of the application and you can find them in the Analyze tab
                when ready.
              </div>
            </div>
          </div>,
          SUPERVISED_LEARNING_TOAST_ID,
        );
      },
      () => {
        setRequestStatus("failure");
      },
    );
  }, [
    workspaceId,
    dataset,
    accessToken,
    model,
    name,
    description,
    refreshModels,
    setToast,
  ]);

  const button = disabledReason ? (
    <Tooltip
      contents={<div className={"tw-max-w-[320px]"}>{disabledReason}</div>}
      side={"bottom"}
      showArrow
      asChild
    >
      <Button variant={"primary"} disabled={!canBeApplied}>
        {children}
      </Button>
    </Tooltip>
  ) : (
    <Button variant={"primary"} disabled={!canBeApplied}>
      {children}
    </Button>
  );

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>{button}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay
          className={cx(
            "tw-bg-slate-500/[0.5] tw-z-dialog tw-absolute tw-inset-0",
            "tw-overflow-auto",
            "tw-flex",
          )}
        >
          <Dialog.Content
            className={cx(
              "tw-p-8 tw-w-[640px] tw-bg-white tw-shadow-lg tw-rounded-lg tw-m-auto",
            )}
          >
            <div className={"tw-text-lg"}>Apply model</div>
            <div className={"tw-text-sm"}>
              <span className={"tw-text-slate-500"}>{datasetName}</span>
            </div>

            <hr className="tw-my-md" />

            <label className={"tw-my-md"}>
              <div className={"tw-mb-sm"}>Name the resulting measurement</div>
              <input
                className={"tw-border tw-p-sm tw-w-full"}
                placeholder={`e.g. ${model.name} predictions`}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </label>

            <hr className="tw-my-md" />

            <label className={"tw-my-md"}>
              <div className={"tw-mb-sm"}>Provide a description</div>
              <p className="tw-text-gray-500 tw-text-sm tw-mb-sm">
                A useful description helps you and collaborators keep track of
                what this is quantifying
              </p>
              <textarea
                className={cx(
                  "tw-w-full tw-bg-gray-100 tw-p-2 tw-border tw-rounded",
                )}
                placeholder="A prediction of..."
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </label>

            {requestStatus === "failure" && (
              <div className={"tw-text-red-500"}>
                There was an error submitting the request.
              </div>
            )}

            <div className={"tw-flex tw-items-center tw-mt-lg"}>
              <div className={"tw-flex-1"} />
              <Button
                variant="secondary"
                className={"tw-mr-sm"}
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={
                  name.trim().length === 0 ||
                  requestStatus === "submitted" ||
                  requestStatus === "success"
                }
                onClick={submitRequest}
              >
                {requestStatus === "submitted" ? "Submitting..." : "Apply"}
              </Button>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
