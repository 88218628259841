import { useHistory, useRouteMatch } from "react-router-dom";
import { Subtitle } from "../../../../../workspaces/ui/src/typography";
import { DeprecatedButton } from "../../Common/DeprecatedButton";
import { useTypedQueryParams } from "../../routing";
import { Block } from "./helpers";

const PARAM_DECODERS = {
  workspaceId: (value: string | undefined) => value || "",
  date: (value: string | undefined) => value || "",
  cellType: (value: string | undefined) => value || "",
};

export function LandingPage() {
  const match = useRouteMatch();
  const [queryParams, setQueryParams] = useTypedQueryParams(PARAM_DECODERS);
  const { workspaceId, date, cellType } = queryParams;
  const history = useHistory();

  const handleChange = (key: string, value: string) => {
    setQueryParams({ ...queryParams, [key]: value });
  };

  const datasetParts = [workspaceId, cellType, date.replace(/-/g, "")];
  const datasetId = datasetParts.join("-");

  const handleNavToConfigurator = () => {
    history.push(`${match.path}/${workspaceId}/${datasetId}/configurator`);
  };

  const inputClasses = "tw-border tw-outline-none tw-w-full";

  return (
    <div className="tw-w-[180px]">
      <Block>
        <Subtitle>Workspace</Subtitle>
        <input
          type="text"
          value={workspaceId}
          onChange={(e) => handleChange("workspaceId", e.target.value)}
          className={inputClasses}
        />
      </Block>

      <Block>
        <Subtitle>Date</Subtitle>
        <input
          type="date"
          value={date}
          onChange={(e) => handleChange("date", e.target.value)}
          className={inputClasses}
        />
      </Block>

      <Block>
        <Subtitle>Cell Type</Subtitle>
        <input
          type="text"
          value={cellType}
          onChange={(e) => handleChange("cellType", e.target.value)}
          className={inputClasses}
        />
      </Block>
      {/* TODO(davidsharff): do we have a button link? */}
      <DeprecatedButton
        variant="primary"
        disabled={datasetParts.some((part) => !part)}
        onClick={handleNavToConfigurator}
        className="tw-mt-8 tw-w-full"
      >
        Configure Ingestion
      </DeprecatedButton>
    </div>
  );
}
