import { User } from "@auth0/auth0-react";
import * as Popover from "@radix-ui/react-popover";
import cx from "classnames";
import { useCallback, useMemo, useState } from "react";
import { workspaceRootUrl } from "src/util/urls";
import { Checkbox } from "@spring/ui/Checkbox";
import DropDownMenu, {
  DropDownMenuButton,
  DropDownMenuExternalLink,
  DropDownMenuLink,
  DropDownText,
} from "../Common/DropDownMenu";
import { DeveloperOptions } from "../DeveloperOptions";
import LogoutButton from "../Home/LogoutButton";
import { useOptionalFeatureFlag } from "../Workspace/feature-flags";
import { Workspace } from "../Workspace/types";
import { getDevOverride } from "../dev-options";
import { ENV, HOST_URL } from "../env";
import {
  useInternalFeaturesState,
  useIsInternalSpringFeaturesEnabledCheckboxVisible,
} from "../util/users";

export default function ProfileDropDown({
  user,
  workspaces,
  showName,
}: {
  user: User;
  workspaces: Workspace[] | undefined;
  showName: boolean;
}) {
  const isDevMode = ENV === "development";
  const [isOpen, setIsOpen] = useState(false);
  const [isDevOptionsOpen, setIsDevOptionsOpen] = useState(false);
  const showAPIKeys = useOptionalFeatureFlag("api-keys-shown");
  const showInternalFeaturesCheckbox =
    useIsInternalSpringFeaturesEnabledCheckboxVisible();
  const [internalFeaturesEnabled, setInternalFeaturesEnabled] =
    useInternalFeaturesState();

  const onCloseMenu = useCallback(() => setIsOpen(false), []);
  const onOpenDeveloperOptions = useCallback(() => {
    onCloseMenu();
    setIsDevOptionsOpen(true);
  }, [onCloseMenu]);
  const onCloseDeveloperOptions = useCallback(() => {
    setIsDevOptionsOpen(false);
  }, []);

  const visibleWorkspaces = useMemo((): Workspace[] => {
    const baseWorkspaces = (workspaces || []).filter(
      ({ id }) => id !== "public",
    );
    // Remove the "public-demo" workspace only if someone is already in another
    // workspace.
    if (baseWorkspaces.length === 1) {
      return baseWorkspaces;
    }
    return baseWorkspaces.filter(({ id }) => id !== "public-demo");
  }, [workspaces]);

  return (
    <>
      <DropDownMenu
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={onCloseMenu}
        align="end"
        PopoverTrigger={
          <Popover.Trigger
            className={cx(
              "tw-flex tw-flex-row tw-items-center tw-h-full tw-px-4 tw-mx-4 hover:tw-bg-purple-200",
              isOpen && "tw-bg-purple-200",
            )}
          >
            {showName && <div className={"tw-mr-4"}>{user.name}</div>}
            <div
              className={cx(
                "tw-w-8 tw-rounded-[theme(spacing.4)] tw-inline-block tw-overflow-hidden",
              )}
            >
              <img
                className={"tw-w-8 tw-inline-block"}
                src={user.picture}
                aria-label={user.name}
                alt=""
              />
            </div>
          </Popover.Trigger>
        }
        sections={[
          // The "key" params are to appease eslint, unfortunately.
          <DropDownText key="email" className={"tw-text-gray-500"}>
            {user.email}
          </DropDownText>,
          ...(visibleWorkspaces.length > 0
            ? [
                <div
                  key="workspaces"
                  className="tw-py-2 tw-max-h-[316px] tw-overflow-y-auto"
                >
                  {visibleWorkspaces.map(({ id, name }) => (
                    <DropDownMenuLink
                      key={id}
                      to={workspaceRootUrl(id)}
                      className={cx(
                        "tw-block tw-no-underline tw-leading-loose tw-text-purple hover:tw-text-purple-500 tw-max-w-full tw-truncate",
                        visibleWorkspaces.length > 8
                          ? "tw-py-0"
                          : visibleWorkspaces.length > 4
                            ? "tw-py-1"
                            : "tw-py-2",
                      )}
                      onClick={
                        // Note(davidsharff): having to do this is unfortunate. We could bring back a version of the old hack
                        // inside DropDown and inspect the tagName onClick to determine if the menu should close.
                        onCloseMenu
                      }
                    >
                      {name}
                    </DropDownMenuLink>
                  ))}
                </div>,
              ]
            : []),
          ...(isDevMode
            ? [
                <DropDownMenuButton
                  key="_"
                  onClick={onOpenDeveloperOptions}
                  className="tw-flex tw-flex-col"
                >
                  <div>&#x1F6E0; Developer Options</div>
                  <div className="tw-text-xs tw-text-gray-400 tw-mt-2">
                    {getDevOverride("hostUrl") ?? HOST_URL}
                  </div>
                </DropDownMenuButton>,
              ]
            : []),
          ...(showInternalFeaturesCheckbox
            ? [
                <DropDownText key="internal-features-setting">
                  <label className={"tw-text-red-500 tw-flex tw-items-center"}>
                    Internal Spring features enabled
                    <Checkbox
                      className={"tw-mx-2"}
                      checked={internalFeaturesEnabled}
                      onCheckedChange={setInternalFeaturesEnabled}
                    />
                  </label>
                </DropDownText>,
              ]
            : []),
          <DropDownMenuExternalLink
            key="about"
            href={"https://www.springscience.com"}
            target={"_blank"}
            className={"tw-no-underline tw-text-current"}
            onClick={onCloseMenu}
          >
            About Spring
          </DropDownMenuExternalLink>,
          ...(showAPIKeys
            ? [
                <DropDownMenuLink
                  key="api-keys"
                  to="/api-keys"
                  onClick={onCloseMenu}
                  className={"tw-no-underline tw-text-current"}
                >
                  Manage API keys
                </DropDownMenuLink>,
              ]
            : []),
          <LogoutButton key="logout" />,
        ]}
      />
      {isDevMode && isDevOptionsOpen && (
        <DeveloperOptions onClose={onCloseDeveloperOptions} />
      )}
    </>
  );
}
