import cx from "classnames";
import { MouseEventHandler, ReactNode, useState } from "react";

export default function SelectionListItem({
  children,
  disabled,
  selected,
  onClick,
  multi,
  title,
  indent,
}: {
  children: ReactNode;
  multi: boolean;
  disabled?: boolean;
  selected?: boolean;
  onClick?: MouseEventHandler;
  title?: string;

  /**
   * If specified, will indent the contents by 2em.
   * Useful for creating lists that are nested/have multiple levels of indenting.
   */
  indent?: boolean;
}) {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={cx(
        "tw-flex",
        "tw-py-3 tw-overflow-hidden",
        indent ? "tw-pl-12 tw-pr-8" : "tw-px-8",
        selected && "tw-bg-purple-100",
        disabled ? "tw-text-gray-300" : "hover:tw-bg-purple-100",
        !disabled && selected && "tw-text-purple tw-border-purple",
      )}
      role={onClick ? "button" : "none"}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      title={title}
    >
      {multi && (
        <input
          type={"checkbox"}
          checked={selected}
          className={cx(
            !hovered && !selected && "tw-invisible",
            // TODO(benkomalo): custom purple styling. We don't have a common checkbox!
            "tw-mr-2 tw-w-4",
          )}
          readOnly
        />
      )}
      {children}
    </div>
  );
}
