import { MetadataColumnValue } from "../types";

export type SampleState = {
  selected: boolean;
};

export type DemographicColumnOption = {
  label: string;
  value: string;
};

export type DemographicData = Array<{
  value: MetadataColumnValue;
  classificationName: string;
  count: number;
}>;

export enum LabeledSetStatus {
  Ready,
  InProgress,
  NeedsLabels,
}

export interface NextStep {
  caption: string;
  onClick?: () => void;
  disabled?: boolean;
}
