/**
 * Component to render plate-wise correlations for an individual feature.
 *
 * Includes a heatmap, along with row- and column-wise correlations.
 */
import { useEffect, useMemo } from "react";
import { useActiveWorkspaceId } from "src/Workspace/hooks";
import { DatasetId } from "src/types";
import { workspaceRootUrl } from "src/util/urls";
import Strut from "../Common/Strut";
import { ColorScheme } from "../Control/ColorSchemeSelector";
import ErrorBoundary from "../Error/ErrorBoundary";
import { COLUMNS, RINGS, ROWS } from "../util/immunofluorescence-util";
import { dataToVega, normalizeDomain } from "../util/vega-util";
import CategoricalScatterPlotRenderer from "./CategoricalScatterPlotRenderer";
import CorrelationsRenderer from "./CorrelationsRenderer";
import HeatMapRenderer from "./HeatMapRenderer";
import type {
  CheckOutcome,
  Datum,
  FieldFeature,
  RegressionModel,
} from "./types";

type Props = {
  colorScheme: ColorScheme;
  dataset: DatasetId;
  featureSet: string;
  featureSetColumn: string;
  features: FieldFeature[];
  metadataColumns: string[];
  plate: string;
  size: number;
  stratifyOn: string | null;
  regressionModel: RegressionModel;
  onCheckComplete: (context: string, status: CheckOutcome) => void;
  onClearChecks: (featureSetColumn: string) => void;
};

// TODO(Hosny): Size reduced from 16 to 12 to fit in new edge distance plot. There may
//  be a better way to do this via styling.
const BOX_SIZE_PX = 12;

/**
 * @returns {boolean} - {@code true} if the features array contains data for the
 *                      given metadata column.
 */
const hasMetadataForColumn = (
  features: FieldFeature[],
  metadataColumn: string,
): boolean => {
  return (
    features.length > 0 &&
    features.some((datum) => datum.hasOwnProperty(metadataColumn))
  );
};

// Metrics on which we want to flag outliers by donor.
// TODO(colin): these maybe belong somewhere else and should then be passed
// in as props or used from a constants file?
const outlierChecks = [
  { metricName: "Count_Nuclei", target: "animal_id" },
  { metricName: "Count_Nuclei", target: "donor_id" },
];

export default function FeatureCorrelationView(props: Props) {
  const {
    dataset,
    plate,
    features,
    featureSetColumn,
    metadataColumns,
    colorScheme,
    size,
    stratifyOn,
    regressionModel,
    onCheckComplete,
    onClearChecks,
  } = props;

  useEffect(() => {
    return () => onClearChecks(featureSetColumn);
  }, [onClearChecks, featureSetColumn]);

  // Convert features to a format that's more amenable to Vega. (Namely, need
  // to split up well into separate row and column.)
  const id = useActiveWorkspaceId();
  const hrefBase = workspaceRootUrl(id);
  const data: Datum[] = useMemo(
    () =>
      dataToVega(
        dataset,
        plate,
        metadataColumns,
        features,
        featureSetColumn,
        hrefBase,
      ),
    [dataset, plate, metadataColumns, features, featureSetColumn, hrefBase],
  );

  const handleCheckCompleteForContext = useMemo(
    () =>
      Object.fromEntries(
        [
          "rowCorrelations",
          "columnCorrelations",
          "ringCorrelations",
          "donorOutliers",
        ].map((context) => [
          context,
          (status: CheckOutcome) => onCheckComplete(context, status),
        ]),
      ),
    [onCheckComplete],
  );

  return (
    <div className={"tw-flex tw-items-end tw-gap-lg"}>
      <ErrorBoundary>
        <HeatMapRenderer
          data={data}
          colorScheme={colorScheme}
          metricName={featureSetColumn}
          rowNames={ROWS}
          columnNames={COLUMNS}
          size={size}
          filenamePrefix={`${dataset}_${plate}`}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <CorrelationsRenderer
          data={data}
          metricName={featureSetColumn}
          target={"row"}
          domain={ROWS}
          regressionModel={regressionModel}
          size={size}
          onCheckComplete={handleCheckCompleteForContext.rowCorrelations}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <CorrelationsRenderer
          data={data}
          metricName={featureSetColumn}
          target={"column"}
          domain={COLUMNS}
          regressionModel={regressionModel}
          size={size}
          xLabelAngle={-90}
          showYLabels={false}
          onCheckComplete={handleCheckCompleteForContext.columnCorrelations}
        />
      </ErrorBoundary>

      {data[0].distanceFromEdge && (
        <ErrorBoundary>
          <CorrelationsRenderer
            data={data}
            metricName={featureSetColumn}
            target={"distanceFromEdge"}
            domain={RINGS}
            regressionModel={regressionModel}
            size={size}
            showYLabels={false}
            xLabel={"distance from edge (wells)"}
            onCheckComplete={handleCheckCompleteForContext.ringCorrelations}
          />
        </ErrorBoundary>
      )}

      {stratifyOn && // Validate that the provided data has material values for the
        // column on which we're stratifying. We may be in the midst of a data
        // re-fetch, in which case we are waiting for those values to be
        // filled in.
        hasMetadataForColumn(features, stratifyOn) && (
          <>
            <Strut />
            <CategoricalScatterPlotRenderer
              data={data}
              metricName={featureSetColumn}
              target={stratifyOn}
              domain={normalizeDomain(
                data.map((datum) => datum.metadata[stratifyOn]),
              )}
              size={size}
              outlierChecks={outlierChecks}
              onCheckComplete={handleCheckCompleteForContext.donorOutliers}
            />
          </>
        )}
    </div>
  );
}

FeatureCorrelationView.defaultProps = {
  colorScheme: "yellowgreenblue",
  size: BOX_SIZE_PX,
};
