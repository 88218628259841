/**
 * Component for viewing quality control data for an individual plate.
 */
import cx from "classnames";
import { useCallback, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { DownloadUrl } from "src/Common/DownloadLink";
import { Select } from "src/Common/Select";
import SelectorLabel from "src/Control/SelectorLabel";
import { useActiveWorkspaceId } from "src/Workspace/hooks";
import { DatasetId, WorkspaceId } from "src/types";
import { workspaceApi } from "src/util/api-client";
import FilterSelector from "../Control/FilterSelector";
import { TypedColumn } from "../Control/FilterSelector/backend-types";
import { Operator } from "../Control/FilterSelector/operations/filter-by";
import { FilterSet } from "../Control/FilterSelector/types";
import {
  filterColumnSpecsFromDB,
  serializeToSqlClause,
  updateFilters,
  updateOperator,
  validateFilter,
} from "../Control/FilterSelector/utils";
import PlateSelector from "../Control/PlateSelector";
import { useMethods } from "../Methods/hooks";
import { MethodSectionKey } from "../Methods/utils";
import { useDatasetSampleMetadataDB } from "../hooks/datasets";
import { useNestedRouteWithQuery } from "../routing";
import { columnMatchesValueClause } from "../util/sql";
import { useComponentSpan } from "../util/tracing";
import StandardQualityControlReport, {
  Tab,
} from "./StandardQualityControlReport";
import { RegressionModel } from "./types";
import { queryWells } from "./util";

function DownloadAllLink({
  workspaceId: workspace,
  dataset,
  plate,
}: {
  workspaceId: WorkspaceId;
  dataset: DatasetId;
  plate: string;
}) {
  const downloadAllFilename = `${dataset}-${plate}-image-features.csv`;

  return (
    <DownloadUrl
      href={workspaceApi({ workspace }).url(
        "qc/download/<experiment>/<plate>/<filename>.csv",
        { experiment: dataset, plate, filename: downloadAllFilename },
      )}
      filename={downloadAllFilename}
      text="Download as CSV"
    />
  );
}

const EMPTY_FILTER = {
  filters: [],
  operator: Operator.AND,
  ignoredFilters: [],
};

function QualityControlReport({
  dataset,
  plate,
  tab,
  onSelectPlate,
  onSelectTab,
}: {
  dataset: DatasetId;
  plate: string | null;
  tab: Tab;
  onSelectPlate: (plate: string) => void;
  onSelectTab: (tab: Tab) => void;
}) {
  const [regressionModel, setRegressionModel] =
    useState<RegressionModel>("linear");
  const workspaceId = useActiveWorkspaceId();

  const fetchResults = useDatasetSampleMetadataDB({ dataset });

  const [wells, setWells] = useState<string[]>([]);

  const [filter, setFilter] = useState<FilterSet>(EMPTY_FILTER);
  const [filterColumns, setFilterColumns] = useState<TypedColumn[] | null>(
    null,
  );

  useEffect(() => {
    fetchResults?.successful &&
      filterColumnSpecsFromDB(
        fetchResults.value,
        "sample_metadata",
        columnMatchesValueClause("plate", plate),
      ).then((columns) => {
        setFilterColumns(columns);
      });
  }, [fetchResults, plate]);

  useEffect(() => {
    fetchResults?.successful &&
      queryWells(fetchResults.value, serializeToSqlClause(filter)).then(
        setWells,
      );
  }, [fetchResults, plate, filter]);

  const validateAndSetFilter = useCallback(
    (filter: FilterSet) => {
      fetchResults?.successful &&
        validateFilter(fetchResults.value, filter).then(setFilter);
    },
    [fetchResults],
  );

  useComponentSpan("QualityControlReport", [dataset, plate, tab]);
  return (
    <div
      className={cx(
        "tw-h-[calc(100vh-theme(spacing.global-nav-height))]",
        "tw-flex tw-flex-col",
      )}
    >
      <div
        className={
          "tw-px-xl tw-py-lg tw-flex tw-flex-row tw-items-center tw-gap-lg"
        }
      >
        <div className="tw-flex tw-items-center tw-space-x-xl">
          <div className="tw-min-w-[360px]">
            <PlateSelector
              dataset={dataset}
              plate={plate}
              onSelectPlate={(...args) => onSelectPlate(...args)}
              autoSelect
            />
          </div>
          <div className="tw-flex tw-items-center tw-space-x-md tw-w-[200px]">
            <SelectorLabel>Curve Fit</SelectorLabel>
            <Select
              name="regressionModel"
              className="tw-flex-1"
              value={regressionModel}
              onChange={setRegressionModel}
              items={["linear", "cubic"]}
            />
          </div>
        </div>
        <div className="tw-flex-1" />
        {workspaceId && plate && (
          <DownloadAllLink
            workspaceId={workspaceId}
            dataset={dataset}
            plate={plate}
          />
        )}
      </div>
      <div className={"tw-px-xl tw-border-b tw-pb-lg"}>
        {fetchResults?.successful && filterColumns ? (
          <div className={"tw-flex tw-items-center tw-gap-x-4"}>
            <SelectorLabel>FILTER</SelectorLabel>
            <FilterSelector
              columns={filterColumns}
              metadata={fetchResults.value}
              filterSet={filter}
              showColors
              onChangeFilters={(filters) =>
                validateAndSetFilter(updateFilters(filter, filters))
              }
              onChangeOperator={(operator, newFilters) =>
                validateAndSetFilter(
                  updateOperator(filter, operator, newFilters),
                )
              }
              // Passing this as an empty string to avoid the default placeholder
              // "No filters applied" from showing up, as we are technically filtering
              // by plate.
              placeholder={" "}
            />
          </div>
        ) : null}
      </div>
      <div
        className={cx(
          "tw-flex-1 tw-w-full tw-flex tw-flex-col",
          "tw-overflow-hidden",
        )}
      >
        {plate ? (
          <StandardQualityControlReport
            dataset={dataset}
            plate={plate}
            wells={wells}
            tab={tab}
            onSelectTab={onSelectTab}
            regressionModel={regressionModel}
          />
        ) : null}
      </div>
    </div>
  );
}

export default function QualityControlReportWithRoute({
  dataset,
}: {
  dataset: DatasetId;
}) {
  useMethods(MethodSectionKey.qualityControl);

  type Params = {
    plate: string;
  };

  type Query = {
    t: Tab;
  };

  const match = useRouteMatch();
  const [params, query, setPath] = useNestedRouteWithQuery<Params, Query>(
    `${match.url}/:plate?/`,
    ["t"],
  );

  return (
    <QualityControlReport
      key={`${dataset}_${params.plate || ""}`}
      dataset={dataset}
      plate={params.plate ?? null}
      tab={query.t ?? "plate"}
      onSelectPlate={(plate) => setPath({ ...params, plate })}
      onSelectTab={(tab) => setPath(params, { ...query, t: tab })}
    />
  );
}
