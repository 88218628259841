import cx from "classnames";

export default function SelectAllCheckbox({
  value,
  onChange,
  disabled,
}: {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}) {
  return (
    <label
      className={cx(
        "tw-flex tw-items-center tw-cursor-pointer tw-select-none",
        disabled && "tw-text-gray-500",
      )}
    >
      Select all
      <input
        className="tw-ml-2 tw-w-4 tw-h-4"
        type="checkbox"
        disabled={disabled}
        checked={value}
        onChange={() => onChange(!value)}
      />
    </label>
  );
}
