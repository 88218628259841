import cx from "classnames";
import * as React from "react";
import { Check, Icon } from "react-feather";
import { ButtonProps, LinkProps } from "src/types";
import { Caption } from "../typography";

type MenuItemProps = {
  size?: "sm" | "base";
  icon?: Icon;
  selected?: boolean;
  suffix?: React.ReactNode;
  disabled?: boolean;
  description?: string;
};

// TODO(trisorus): It would be nice to define the MenuItem polymorphically
// (accept as="button" or as="a") - the TypeScript has been painful, so for now
// keep MenuItem and MenuItemLink in sync
const MenuItem = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & MenuItemProps
>(
  (
    {
      children,
      className,
      disabled,
      icon,
      size = "base",
      selected,
      suffix,
      description,
      ...props
    },
    ref,
  ) => {
    const MenuIcon = icon;
    return (
      <button
        ref={ref}
        className={cx(
          "tw-flex tw-flex-col tw-items-start",
          size == "sm" ? "tw-p-sm" : "tw-px-md tw-py-sm",
          "tw-bg-white",
          disabled
            ? "tw-text-slate-400 hover:tw-text-slate-400"
            : "tw-text-slate-900 hover:tw-bg-primary-200 hover:tw-text-primary-500",
          disabled ? "tw-cursor-not-allowed" : "tw-cursor-pointer",
          "tw-font-sans tw-text-base tw-no-underline",
          "focus-visible:tw-outline-none",
          className,
        )}
        {...props}
      >
        <div
          className={cx("tw-flex tw-items-center tw-justify-between tw-gap-sm")}
        >
          <div className={cx("tw-flex tw-items-center tw-gap-sm")}>
            {MenuIcon !== undefined ? <MenuIcon size={14} /> : null} {children}
          </div>
          {suffix ?? null}
          {selected ? <Check size={14} /> : null}
        </div>
        {description !== undefined ? (
          <Caption
            className={cx(
              "tw-text-slate-400",
              MenuIcon !== undefined && "tw-ml-[22px]",
            )}
          >
            {description}
          </Caption>
        ) : null}
      </button>
    );
  },
);
MenuItem.displayName = "MenuItem";

const LinkMenuItem = React.forwardRef<
  HTMLAnchorElement,
  LinkProps & MenuItemProps
>(
  (
    {
      children,
      className,
      disabled,
      icon,
      size = "base",
      selected,
      suffix,
      ...props
    }: LinkProps & MenuItemProps,
    ref,
  ) => {
    const MenuIcon = icon;
    return (
      <a
        ref={ref}
        className={cx(
          "tw-flex tw-items-center tw-justify-between tw-gap-sm",
          size == "sm" ? "tw-p-sm" : "tw-px-md tw-py-sm",
          "tw-bg-white",
          disabled
            ? "tw-text-slate-400 hover:tw-text-slate-400"
            : "tw-text-slate-900 hover:tw-bg-primary-200 hover:tw-text-primary-500",
          disabled ? "tw-cursor-not-allowed" : "tw-cursor-pointer",
          "tw-font-sans tw-text-base tw-no-underline",
          "focus-visible:tw-outline-none",
          className,
        )}
        target="_blank"
        rel="noreferrer"
        {...props}
      >
        <div className={cx("tw-flex tw-items-center tw-gap-sm")}>
          {MenuIcon !== undefined ? <MenuIcon size={14} /> : null} {children}
        </div>
        {suffix ?? null}
        {selected ? <Check size={14} /> : null}
      </a>
    );
  },
);
LinkMenuItem.displayName = "LinkMenuItem";

export { LinkMenuItem, MenuItem };
