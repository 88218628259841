import React, { ReactNode, useContext, useMemo } from "react";
import { FilterSqlClause } from "../Control/FilterSelector/types";
import { DEFAULT_CROP_SIZE_PX } from "../env";
import { DisplayRange } from "../imaging/types";
import {
  LabeledCellSampleMetadata,
  UnlabeledCellSampleMetadata,
} from "../types";
import { InMemoryContext, InMemoryReadWrite } from "../util/in-memory-store";
import { upsertLabeledSet } from "./util";

export interface Classification {
  name: string;
  examples: LabeledCellSampleMetadata[];
  accuracy?: number;
  precision?: number;
  recall?: number;
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export interface Neighbors {
  inDegreeScore: number;
  neighbors: UnlabeledCellSampleMetadata["id"][];
}

export interface Prediction {
  // The key is the predicted class name and the number is its prediction score.
  predictions: Map<string, number>;
  predictedClass: string;
}

export type NeighborsMap = Map<UnlabeledCellSampleMetadata["id"], Neighbors>;
export type PredictionsMap = Map<UnlabeledCellSampleMetadata["id"], Prediction>;

export interface LabeledSet {
  displayName: string;
  id: string;

  queue: UnlabeledCellSampleMetadata[];
  skipped: UnlabeledCellSampleMetadata[];
  displayed: UnlabeledCellSampleMetadata[];
  loadingError: Error | null;

  selected: UnlabeledCellSampleMetadata[];
  samplingFilter: FilterSqlClause | null;
  smallSamplingFilter: FilterSqlClause | null;

  predictions: PredictionsMap | null;
  neighbors: NeighborsMap;

  stains: string[];
  selectedStains: string[];
  stainChannelIndices: Record<string, number>;
  stainDisplayRanges: Record<string, DisplayRange>;
  classifications: Classification[];
  confusionMatrix: number[][] | null;

  showVisualizationControls: boolean;

  latestModelPath: string;
  latestFeatureSets: string[];

  lastSaved: Date | null;
  unsavedChanges: boolean;
  loaded: boolean;
  deleted?: boolean;
  size: number;
  cropSize: number;
  defaultCropSize: number;
  outOfSamples: boolean;
  smallN: boolean;
}

export type NewLabeledSet = Omit<LabeledSet, "id">;

type LabeledSetsState = {
  labeledSets: LabeledSet[];
  defaultCropSize: number;
  loaded: boolean;
};

export const defaultLabeledSet: Omit<LabeledSet, "displayName" | "id"> = {
  classifications: [],
  confusionMatrix: null,
  showVisualizationControls: false,
  samplingFilter: "TRUE",
  smallSamplingFilter: null,
  loadingError: null,
  queue: [],
  skipped: [],
  displayed: [],
  stains: [],
  selectedStains: [],
  stainChannelIndices: {},
  stainDisplayRanges: {},
  selected: [],
  neighbors: new Map(),
  predictions: new Map(),
  lastSaved: null,
  unsavedChanges: false,
  loaded: false,
  size: 128,
  cropSize: DEFAULT_CROP_SIZE_PX,
  defaultCropSize: DEFAULT_CROP_SIZE_PX,
  latestModelPath: "",
  latestFeatureSets: [],
  outOfSamples: false,
  smallN: false,
};

const LabeledSetContext = React.createContext<InMemoryReadWrite<LabeledSet>>({
  get state(): LabeledSet {
    throw new Error("no state provided");
  },
  setState() {
    throw new Error("no state provided");
  },
  updateState() {
    throw new Error("no state provided");
  },
});

export function useLabeledSetContext(): InMemoryReadWrite<LabeledSet> {
  return useContext(LabeledSetContext);
}

// TODO(you): Fix this no-unused-exports rule violation
// ts-unused-exports:disable-next-line
export const {
  Provider: LabeledSetsContextProvider,
  Context: LabeledSetsContext,
} = InMemoryContext<LabeledSetsState>({
  labeledSets: [],
  defaultCropSize: DEFAULT_CROP_SIZE_PX,
  loaded: false,
});

export function useLabeledSetsContext(): InMemoryReadWrite<LabeledSetsState> {
  return useContext(LabeledSetsContext);
}

// We keep an in-memory representation of the labeled sets that we've already loaded
// (or have just created, and possibly haven't been saved to the server yet).  This
// provider makes the specific labeled set that we're operating on more accessible via
// the LabeledSetContext - the provided state/setState/updateState functions operate on
// the specific labeled set with the given id
export function LabeledSetContextProvider({
  id,
  children,
}: {
  id: string;
  children: ReactNode;
}) {
  const { state, setState, updateState } = useContext(LabeledSetsContext);

  const labeledSetState = useMemo(() => {
    return (
      state.labeledSets.find((labeledSet) => labeledSet.id === id) ?? {
        ...defaultLabeledSet,
        id,
        displayName: "",
      }
    );
  }, [id, state.labeledSets]);

  const setLabeledSetState = useMemo(() => {
    return (updated: LabeledSet) => {
      setState({
        ...state,
        labeledSets: upsertLabeledSet(state.labeledSets, id, updated),
      });
    };
  }, [id, setState, state]);

  const updateLabeledSetState = useMemo(() => {
    return (updater: (current: LabeledSet) => LabeledSet) => {
      updateState((labeledSetsState) => ({
        ...labeledSetsState,
        labeledSets: upsertLabeledSet(
          labeledSetsState.labeledSets,
          id,
          (existing) =>
            updater(existing ?? { ...defaultLabeledSet, id, displayName: id }),
        ),
      }));
    };
  }, [id, updateState]);

  const value: InMemoryReadWrite<LabeledSet> = useMemo(() => {
    return {
      state: labeledSetState,
      setState: setLabeledSetState,
      updateState: updateLabeledSetState,
    };
  }, [setLabeledSetState, labeledSetState, updateLabeledSetState]);

  return React.createElement(LabeledSetContext.Provider, { value }, children);
}
