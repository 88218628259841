import { buildSort } from "src/util/build-sort";
import {
  Bounds,
  GetClosestPointFromDifferentCluster,
  RenderOptions,
  Renderer,
  RendererOptions,
  ScatterPlotPoint,
} from "./types";
import { initCanvas, toPixel } from "./util";

interface RenderClustersOptions extends RendererOptions {
  canvas: HTMLCanvasElement;
  visiblePoints: ScatterPlotPoint[];
  getClosestPointFromDifferentCluster: GetClosestPointFromDifferentCluster;
  pointSize: number;

  viewBounds: Bounds;

  clusterColors: Map<number, string>;
  hoverCluster: number | null;

  canvasWidth: number;
  canvasHeight: number;
}

const CLUSTER_RADIUS = 60;

function renderClusters(options: RenderClustersOptions) {
  const {
    canvas,
    getClosestPointFromDifferentCluster,
    clusterColors,
    hoverCluster,
    visiblePoints,
  } = options;

  const ctx = initCanvas(canvas, options);
  if (!ctx) {
    return;
  }

  // Create a Map from cluster number to points
  const clusters: Map<
    number,
    { key: string; x: number; y: number; locX: number; locY: number }[]
  > = new Map();

  const sampledPoints: {
    key: string;
    x: number;
    y: number;
    cluster: number;
    locX: number;
    locY: number;
  }[] = [];

  for (const pt of visiblePoints) {
    if (pt.clusterLabel === undefined) {
      continue;
    }
    const { x, y, locX, locY } = toPixel(pt, options);
    sampledPoints.push({
      key: pt.key,
      x,
      y,
      cluster: pt.clusterLabel,
      locX,
      locY,
    });
  }

  for (const pt of sampledPoints) {
    let pointsInCluster = clusters.get(pt.cluster);
    if (!pointsInCluster) {
      pointsInCluster = [];
      clusters.set(pt.cluster, pointsInCluster);
    }
    pointsInCluster.push(pt);
  }

  const clusterKeys = Array.from(clusters.keys()).sort();

  // If the closest neighbor is more than this distance away, we know it won't affect
  // the radius of a cluster background circle
  const radiusDistance =
    2 *
    CLUSTER_RADIUS *
    Math.max(
      (options.viewBounds.maxX - options.viewBounds.minX) / options.canvasWidth,
      (options.viewBounds.maxY - options.viewBounds.minY) /
        options.canvasHeight,
    );

  // If a point is this close to another point that we drew a cluster circle around,
  // then we won't draw another circle.
  const closePointThreshold2 = Math.pow(
    CLUSTER_RADIUS /
      (visiblePoints.length > 100_000
        ? 1
        : visiblePoints.length > 50_000
          ? 2
          : visiblePoints.length > 20_000
            ? 3
            : 4),
    2,
  );

  clusterKeys.forEach((cluster) => {
    const points = clusters.get(cluster);
    if (!points) {
      return;
    }

    // TODO(trisorus): This fallback is here just in case, but in reality the map should be
    // complete – perhaps better typing could resolve this
    ctx.fillStyle = clusterColors.get(cluster) ?? "#777";
    ctx.globalAlpha = cluster === hoverCluster ? 1.0 : 0.5;

    ctx.beginPath();
    let lastPt: { locX: number; locY: number; radius2: number } | null = null;

    // Sort the points so we group values from nearby y values together, to increase
    // the chances we can skip checking a point due to it being enclosed by a recently
    // drawn circle
    const yDivisor = CLUSTER_RADIUS / 3;
    points.sort(
      buildSort<{
        locY: number;
        locX: number;
      }>(({ locY }: { locY: number }) => Math.floor(locY / yDivisor), "locX"),
    );

    for (const pt of points) {
      // If the last point was close by, this one will be enclosed in the same circle,
      // so we can skip drawing it
      if (
        lastPt &&
        Math.pow(lastPt.locX - pt.locX, 2) +
          Math.pow(lastPt.locY - pt.locY, 2) <
          lastPt.radius2
      ) {
        continue;
      }

      // Check how close the closest neighbor is – if it's closer than the cluster radius,
      // we'll need to draw a smaller circle
      let minDist2 = Infinity;
      const closestPoint = getClosestPointFromDifferentCluster(
        pt.key,
        radiusDistance,
      );
      if (closestPoint !== null) {
        const { locX: closestPointLocX, locY: closestPointLocY } = toPixel(
          closestPoint,
          options,
        );
        minDist2 =
          Math.pow(pt.locX - closestPointLocX, 2) +
          Math.pow(pt.locY - closestPointLocY, 2);
      }

      const pointSize = Math.max(
        hoverCluster === cluster ? 12 : 8,
        Math.min(CLUSTER_RADIUS, Math.floor(Math.sqrt(minDist2) / 2)),
      );
      ctx.moveTo(pt.x, pt.y);
      ctx.arc(pt.x, pt.y, pointSize, 0, Math.PI * 2);
      if (visiblePoints.length > 10_000) {
        // Only bother skipping circles if we've got a lot of points visible
        lastPt = {
          ...pt,
          radius2: Math.min(closePointThreshold2, pointSize * pointSize),
        };
      }
    }
    ctx.fill();
  });
}

export function ClusterRenderer(
  refCanvas: React.RefObject<HTMLCanvasElement | null>,
): Renderer<RenderOptions, RendererOptions> {
  return {
    options: ({
      clusterColors,
      visiblePoints,
      getClosestPointFromDifferentCluster,
      pointSize,
      viewBounds,
      canvasWidth,
      canvasHeight,
      initializedCanvas,
      initializedData,
      hoverCluster,
    }): RenderClustersOptions | null =>
      refCanvas.current && initializedCanvas && initializedData
        ? {
            canvas: refCanvas.current,
            clusterColors,
            visiblePoints,
            getClosestPointFromDifferentCluster,
            pointSize,
            viewBounds,
            canvasWidth,
            canvasHeight,
            hoverCluster,
          }
        : null,
    render: renderClusters,
  };
}
