import { useCallback } from "react";
import { WorkspaceId } from "src/types";
import { Fetchable, Success } from "@spring/core/result";
import { useInsightsContext } from "./context";
import { Guider, InsightsEntry } from "./types";

export function useFetchInsights(
  workspaceId: WorkspaceId | null,
): Fetchable<InsightsEntry[]> {
  const { _allInsights } = useInsightsContext();

  switch (workspaceId) {
    case "springinternal": {
      const insight = _allInsights.get("spring-u2os-20230525-cell-death");
      return Success.of(insight ? [insight] : []);
    }
    case "jump-cp": {
      const insight = _allInsights.get("jump_cp-u2os-20221101-cell-death");
      return Success.of(insight ? [insight] : []);
    }
  }
  return Success.of([]);
}

export function useMaybeActiveGuider(
  key: string,
): [Guider, () => void] | undefined {
  const context = useInsightsContext();
  const dismiss = useCallback(() => context.dismissGuider(key), [key, context]);
  // TODO(you): Fix this no-unnecessary-condition rule violation
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!context?.activeGuiders) {
    return undefined;
  }
  const guider = context.activeGuiders.find((g) => g.key === key);
  if (!guider) {
    return undefined;
  }
  return [guider, dismiss];
}
