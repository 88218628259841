import cx from "classnames";
import dl from "datalib";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ReactNode, useMemo, useRef, useState } from "react";
import { UntypedFeatures } from "../types";

export type Highlight = {
  valueStart: number;
  valueEnd: number;
  color: string;
  label?: string;
};

const NUMBER_FORMATTER = new Intl.NumberFormat(undefined, {
  compactDisplay: "short",
});

/**
 * A simple Histogram that assumes the provided data is immutable.
 *
 * If you expect to change the data, create a new instance (via Ract id) since for
 * performance reasons this caches internal state based on input.
 */
export default function Histogram({
  data,
  column,
  width,
  height,
  highlights,
  binColorRange,
  onClick,
  lowValDefault,
  highValDefault,
  animateFirstRender,
  overlay,
  hoverTooltipTitle,
  canScrollHorizontally,
}: {
  data: UntypedFeatures;
  column: string;
  width?: number;
  height?: number;
  highlights: Highlight[] | null;
  binColorRange?: Highlight | null;
  onClick?: (value: number) => void;
  lowValDefault?: number | null;
  highValDefault?: number | null;
  animateFirstRender?: boolean;
  overlay?: ReactNode;
  hoverTooltipTitle?: string;
  canScrollHorizontally?: boolean;
}) {
  const [hoverValue, setHoverValue] = useState<number | null>(null);
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(
    animateFirstRender ?? false,
  );

  const sorted = useMemo(() => {
    data.sort((a, b) => {
      const value_a = a[column] || -Infinity;
      const value_b = b[column] || -Infinity;
      if (value_a > value_b) {
        return 1;
      } else if (value_a < value_b) {
        return -1;
      } else {
        return 0;
      }
    });

    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const backgroundColor: string = "#b2bac4";
  const chartRef = useRef<Highcharts.Chart | null>(null);
  const [mouseXY, setMouseXY] = useState<[number, number] | null>(null);

  // For large datasets, there are always some small percentage of outliers that skew
  // results in annoying ways. For now, always just take the 0.1th percentile and 99.9th
  // percentile as extreme clips. We can alternatively pass explicit limits.
  const N = sorted.length;
  const trueMin = Number(sorted[0][column]);
  const lowVal = lowValDefault
    ? Number(lowValDefault)
    : Number(sorted[Math.floor(N * 0.001)][column]);
  const highVal = highValDefault
    ? Number(highValDefault)
    : Number(sorted[Math.floor(N * 0.999)][column]);
  const trueMax = Number(sorted[N - 1][column]);
  const nBins = 50;
  // TODO(benkomalo): replace this with SQL and duckdb?
  const hist = useMemo(() => {
    const values = sorted.map((row) => Number(row[column]));
    return dl.histogram(values, {
      min: lowVal,
      max: highVal,
      step: (highVal - lowVal) / (nBins - 2),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options: Highcharts.Options = useMemo(
    () => ({
      title: { text: undefined },
      credits: { enabled: false },
      series: [
        {
          type: "area",
          name: "count",
          // TODO(benkomalo): make a shared color palette file for dynamic plots.
          data: hist.map(
            ({ value, count }: { value: number; count: number }) => [
              value,
              count,
            ],
          ),
          zones: binColorRange
            ? [
                {
                  value: binColorRange.valueStart,
                  color: backgroundColor,
                },
                {
                  value: binColorRange.valueEnd,
                  color: "#6210FF",
                },
                {
                  color: backgroundColor,
                },
              ]
            : undefined,
          color: backgroundColor,
          zoneAxis: "x",
          marker: {
            enabled: false,
          },
          showInLegend: false,
          borderWidth: 0,
          animation: shouldAnimate && {
            complete: () => {
              setShouldAnimate(false);
            },
          },
        },
      ],
      xAxis: {
        minorTicks: true,
        min: lowVal,
        max: highVal,
        plotLines: highlights
          ? highlights.map((highlight) => ({
              value: highlight.valueStart,
              width: 2,
              color: highlight.color,
              zIndex: 3,
            }))
          : [],
        labels: {
          style: {
            fontSize: "10px",
            color: "#64748b",
          },
        },
      },
      yAxis: {
        title: undefined,
        labels: {
          style: {
            fontSize: "10px",
            color: "#64748b",
          },
        },
      },
      chart: {
        animation: false,
        width: width,
        height: height ?? 300,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingRight: 0,
        events: {
          // Note(davidsharff): see https://github.com/highcharts/highcharts/issues/11089#issuecomment-652423131
          // Strangely enough, this was the approach recommended by a contributor to update the container overflow style.
          // I couldn't find a real configuration setting to update either the scroll or the ability to dynamically
          // "squish" the histogram when the container div shrinks. You can change the width but it will blank out the
          // graph for potentially multiple seconds.
          // TODO(davidsharff): I couldn't get this to play nicely. The keys are there but I made them null safe
          //  to align with the any typing.
          load: (chart: any) => {
            if (canScrollHorizontally) {
              const renderToStyle = chart?.target?.renderTo?.style;
              if (renderToStyle) {
                renderToStyle.overflow = "";
              }
            }
          },
        },
      },
    }),
    [
      hist,
      height,
      width,
      highlights,
      binColorRange,
      lowVal,
      highVal,
      shouldAnimate,
      canScrollHorizontally,
    ],
  );

  return (
    <div
      className={cx(
        "tw-relative",
        canScrollHorizontally && "tw-overflow-x-scroll",
      )}
    >
      {overlay && (
        <div className="tw-absolute tw-h-full tw-w-full tw-z-10 tw-flex tw-flex-col tw-justify-center tw-items-center">
          <div
            className={cx(
              "tw-p-2 tw-flex tw-flex-col tw-justify-center tw-items-center",
              "tw-text-slate-500 tw-opacity-90 tw-ml-[20px] tw-bg-gray-50",
            )}
          >
            {overlay}
          </div>
        </div>
      )}
      <HighchartsReact
        allowChartUpdate={true}
        highcharts={Highcharts}
        options={options}
        callback={(chart: Highcharts.Chart) => {
          chartRef.current = chart;
        }}
      />
      <div
        className={"tw-absolute tw-inset-0 tw-overflow-visible"}
        onMouseMove={(e) => {
          if (!chartRef.current) {
            return;
          }
          const { x: containerX, y: containerY } = (
            e.target as HTMLElement
          ).getBoundingClientRect();
          // TODO(benkomalo): something is wonky here because the bars from the
          //  histogram go furgher left than what we can hover.
          // https://github.com/spring-discovery/spring-experiments/pull/6317#discussion_r1002115395
          const xPixelsInContainer = e.clientX - containerX;
          const yPixelsInContainer = e.clientY - containerY;
          const chart = chartRef.current;
          const xAxis = chart.axes[0];
          let xValue = xAxis.toValue(xPixelsInContainer);
          if (xValue < trueMin) {
            xValue = trueMin;
          }
          if (xValue > trueMax) {
            xValue = trueMax;
          }
          setMouseXY([xPixelsInContainer, yPixelsInContainer]);
          setHoverValue(xValue);
        }}
        onMouseLeave={() => {
          setMouseXY(null);
          setHoverValue(null);
        }}
        onClick={
          onClick &&
          ((e) => {
            if (!chartRef.current) {
              return;
            }

            const { x: containerX } = (
              e.target as HTMLElement
            ).getBoundingClientRect();
            const xPixelsInContainer = e.clientX - containerX;
            const chart = chartRef.current;
            const xAxis = chart.axes[0];
            const xValue = xAxis.toValue(xPixelsInContainer);
            if (xValue < trueMin || xValue >= trueMax) {
              return;
            }
            onClick(xValue);
          })
        }
      />
      {hoverValue != null && chartRef.current && (
        <div
          className={cx(
            "tw-absolute tw-top-0 tw-bottom-[20px] tw-pointer-events-none",
            "tw-w-[2px]",
            "tw-bg-purple",
          )}
          style={{ left: chartRef.current.axes[0].toPixels(hoverValue, false) }}
        />
      )}
      {hoverValue != null && mouseXY && chartRef.current && (
        <div
          className={cx(
            "tw-absolute tw-p-2 tw-text-xs",
            "tw-border tw-rounded tw-bg-white tw-pointer-events-none",
            "tw-whitespace-nowrap",
          )}
          style={{
            left: mouseXY[0] + 20,
            top: mouseXY[1],
          }}
        >
          {hoverTooltipTitle && <div>{hoverTooltipTitle}</div>}
          <div className={"tw-font-mono tw-text-[10px] tw-text-slate-500"}>
            {NUMBER_FORMATTER.format(hoverValue)}
          </div>
        </div>
      )}
    </div>
  );
}
