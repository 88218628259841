interface Props {
  animate: "always" | "hover" | "off";
  className?: string;
}

export function FlaskTwinkle({ animate, className }: Props) {
  const animationClassName =
    animate === "always"
      ? "tw-animate-sparkle"
      : animate === "hover"
        ? "group-hover:tw-animate-sparkle"
        : undefined;

  return (
    <svg
      viewBox="0 16 66 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M28.566 45.3633H23.0654C22.4537 45.3633 21.9578 45.8592 21.9578 46.4709C21.9578 46.621 21.9273 46.7696 21.8681 46.9076L13.3635 66.7265L11.9913 70.7317C11.3828 72.5076 11.8204 74.4747 13.1243 75.8252L14.3866 77.1325C15.2491 78.0259 16.4101 78.5705 17.6512 78.6111C24.0554 78.8209 35.7871 78.5207 37.6426 78.5207C40.2209 78.5207 41.0803 76.2954 42.5844 73.625C44.0884 70.9546 39.7912 63.8335 37.6426 60.7181C36.3556 58.852 34.6671 53.4595 33.479 49.155C32.8666 46.9362 30.8678 45.3633 28.566 45.3633Z"
        fill="#FFAE10"
      />
      <path
        d="M27.5 26H32.258C33.9766 26 35.9104 27.3352 35.9104 29.338C35.9104 31.3408 33.7487 31.9864 32.7004 33.3436C31.841 34.4563 32.258 42.3339 32.258 44.4703C32.258 49.1434 39.0257 64.4982 41.4322 68.0588C43.8386 71.6193 43.642 74.5122 41.4322 76.515C39.2223 78.5178 38.0719 78.7404 33.1174 78.7404C31.6134 78.7404 24.106 78.7404 21.9574 78.7404"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="square"
      />
      <path
        d="M26.5 26H22.1248C20.4062 26 18.4724 27.3352 18.4724 29.338C18.4724 31.3408 20.6341 31.9864 21.6824 33.3436C22.5418 34.4563 22.1248 42.3339 22.1248 44.4703C22.1248 49.1434 15.3571 64.4982 12.9506 68.0588C10.5442 71.6193 10.7408 74.5122 12.9506 76.515C15.1605 78.5178 16.3109 78.7404 21.2654 78.7404C22.7694 78.7404 23.1993 78.7404 25.3479 78.7404"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="square"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.5 26C58.5 30.1421 55.1421 33.5 51 33.5C55.1409 33.5 58.4979 36.8558 58.5 40.9962V41C58.5 36.8588 61.8564 33.5015 65.9973 33.5H66C61.8588 33.5 58.5015 30.1436 58.5 26.0027V26Z"
        fill="#FFAE10"
        className={animationClassName}
        style={{ animationDuration: "1.11s", transformOrigin: "57px 33px" }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54 40.5C54 44.6421 50.6421 48 46.5 48C50.6409 48 53.9979 51.3558 54 55.4962V55.5C54 51.3588 57.3564 48.0015 61.4973 48H61.5C57.3588 48 54.0015 44.6436 54 40.5027V40.5Z"
        fill="#FFAE10"
        className={animationClassName}
        style={{ animationDuration: "1.21s", transformOrigin: "52px 47px" }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.5 28.5C44.5 34.299 39.799 39 34 39L44.4995 39V39H34C39.7649 39 44.4447 43.6459 44.4995 49.3978V49.5C44.4995 49.4759 44.4996 49.4518 44.4998 49.4277C44.4999 49.4503 44.5 49.4729 44.5 49.4955V49.3979C44.5548 43.6474 49.2322 39.0024 54.995 39H55C49.201 39 44.5 34.299 44.5 28.5Z"
        fill="#FFAE10"
        className={animationClassName}
        style={{ animationDuration: "1.31s", transformOrigin: "43px 41px" }}
      />
    </svg>
  );
}
